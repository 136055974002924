import React from 'react';
import classNames from 'classnames';
import Typography from '@lib/components/Typography/Typography';
import Collapse from '@lib/components/Collapse/Collapse';
import styles from './DashboardTitleHeader.module.scss';

type Props = {
  actions?: React.ReactNode;
  children?: React.ReactNode;
  title?: React.ReactNode;
  titleClassName?: string;
  withBottomSpace?: boolean;
};

export default function DashboardTitleHeader(props: Props) {
  const {
    actions,
    children,
    title,
    titleClassName,
    withBottomSpace = true,
  } = props;
  return (
    <div
      className={classNames(styles.header, {
        [styles.withBottomSpace]: withBottomSpace,
      })}
    >
      <div>
        {title && typeof title === 'string' && (
          <Collapse minHeight={120} useCropBlurWhite>
            <Typography
              variant="h2"
              className={classNames(styles.title, titleClassName)}
            >
              {title}
            </Typography>
          </Collapse>
        )}
        {title && typeof title === 'object' && title}
        {children}
      </div>
      {!!actions && <div className={styles.headerActions}>{actions}</div>}
    </div>
  );
}
