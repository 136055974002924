import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { Values } from '@lib/interfaces/form';
import UserFieldNames from '@lib/enums/fieldNames/userFieldNames';
import getSiteMemberships from './getSiteMemberships';

export default function getPreparedDataFromValues<T>(
  values: Values,
  prevValues: T,
) {
  const phoneNumberValue = _get(values, UserFieldNames.PhoneNumber);
  const phoneNumber = _get(phoneNumberValue, 'number');
  const password = _get(values, UserFieldNames.Password);
  return {
    email: _get(values, UserFieldNames.Email),
    firstName: _get(values, UserFieldNames.FirstName),
    lastName: _get(values, UserFieldNames.LastName),
    personalId: _get(values, UserFieldNames.PersonalId),
    username: _get(values, UserFieldNames.Username),
    password: password ?? undefined,
    phoneNumber: _isEmpty(phoneNumber) ? undefined : phoneNumberValue,
    role: _get(values, `${UserFieldNames.Role}.value`),
    siteMemberships: getSiteMemberships(values, prevValues),
    teamId: _get(values, `${UserFieldNames.Team}.value`, null),
  };
}
