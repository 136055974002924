import React from 'react';
import { useTranslation } from 'react-i18next';
import Chip, { ChipTypes } from '@lib/components/Chip/Chip';

export default function OfflineLabel() {
  const { t } = useTranslation();
  return (
    <Chip
      type={ChipTypes.chipGray}
      label={t('offline')}
      leftIcon="wifi_off"
      fill={false}
    />
  );
}
