import React from 'react';
import { useTranslation } from 'react-i18next';
import { Counter } from 'graphql-common';
import Typography from '@lib/components/Typography/Typography';
import {
  getMeasurementAbbreviation,
  getMeasurementLabel,
} from '@lib/utils/measure';
import MessageBox, {
  SupportiveMessageColor,
} from '@lib/components/SupportiveMessage/SupportiveMessageBox';
import { getFormattedDate } from '@lib/utils/dateTimeHelpers';
import { dateTimeFormatEU } from '@lib/enums/dateTime';
import styles from './AssetCounterInfoView.module.scss';

type Props = {
  counter?: Counter;
};

export default function AssetCounterInfoView(props: Props) {
  const { counter } = props;
  const { t } = useTranslation();
  if (!counter) return null;
  const measureUnitAbbreviation = getMeasurementAbbreviation(
    counter.measureType,
    counter.measureUnit,
    t,
    true,
  );
  return (
    <div className={styles.root}>
      <div>
        <Typography variant="h3">{counter.name}</Typography>
        <Typography variant="caption">{counter.fullCode}</Typography>
      </div>
      <div>
        <Typography variant="caption">
          {getMeasurementLabel(counter.measureType, counter.measureUnit, t)}
        </Typography>
      </div>
      {counter.latestIndex !== undefined &&
        counter.latestIndex !== null &&
        counter.lastCheckAt && (
          <MessageBox color={SupportiveMessageColor.Blue}>
            <div>
              <Typography variant="caption">
                {t('the-last-index')}:{' '}
                <strong>
                  {counter.latestIndex} {measureUnitAbbreviation}
                </strong>
                <br />
                {t('check-date')}:{' '}
                <strong>
                  {getFormattedDate(counter.lastCheckAt, dateTimeFormatEU)}
                </strong>
              </Typography>
            </div>
          </MessageBox>
        )}
    </div>
  );
}
