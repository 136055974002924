import React from 'react';
import { useTranslation } from 'react-i18next';
import _get from 'lodash/get';
import Modal from '@lib/components/Modal/Modal';
import Button, { ButtonTypes } from '@lib/components/Button/Button';
import ActionsRow from '@lib/components/ActionsRow/ActionsRow';
import { PlanState } from '@lib/enums/plan';
import { IconName, ModalIconColor } from '@lib/components/Modal/enums';
import getFormFields from '@lib/components/UpgradePlanModal/utils/getFormField';
import Form, {
  ActionsProps,
} from '@lib/components/ReactHookForm/FormContainer';
import { materialSymbolsOutlined } from '@lib/enums/common';
import { Values } from '@lib/interfaces/form';
import { useCompanyPlanChangeRequestMutation } from '@lib/graphql-common';
import toast from '@lib/components/Toast/Toast';
import getAppUrlWithDomain from '@lib/utils/getAppUrlWithDomain';
import { SYSTEM_APP_URLS } from '@lib/constants/urls';
import Trans from '@lib/components/Trans/Trans';

const { VITE_SYSTEM_PLATFORM_URL: SYSTEM_PLATFORM } = import.meta.env;

interface Props {
  isOpen: boolean;
  onClose: () => void;
  planState?: PlanState;
  companyId: string;
}

const modalData = {
  [PlanState.Attention]: {
    title: 'upgrade-plan-modal-title-attention',
    description: 'upgrade-plan-modal-text-attention',
    icon: IconName.ErrorRed,
    iconColor: ModalIconColor.Red,
  },
  [PlanState.Warning]: {
    title: 'upgrade-plan-modal-title-warning',
    description: 'upgrade-plan-modal-text-warning',
    icon: IconName.WarningYellow,
    iconColor: ModalIconColor.Yellow,
  },
  [PlanState.Locked]: {
    title: 'upgrade-plan-modal-title-locked',
    description: 'upgrade-plan-modal-text-locked',
    icon: IconName.Lock,
    iconColor: ModalIconColor.Blue,
  },
  [PlanState.None]: {
    title: 'title',
    description: 'text',
    icon: IconName.ErrorRed,
    iconColor: ModalIconColor.Blue,
  },
};

export default function UpgradePlanModal(props: Props) {
  const { isOpen, onClose, planState = PlanState.None, companyId } = props;
  const { t } = useTranslation();
  const onConfirm = () => {};
  const { title, description, icon, iconColor } = modalData[planState];
  const isModalOpen = planState === PlanState.None ? false : isOpen;
  const fields = getFormFields({ t });
  const [companyPlanChangeRequest] = useCompanyPlanChangeRequestMutation({
    onCompleted: () => {
      toast({ content: t('upgrade-plan-success') });
      onClose();
    },
    onError: (error) => {
      toast({ content: error.message || 'Oops! Something went wrong' });
    },
  });
  const onSubmit = (values: Values) => {
    const message = _get(values, 'message') as string;
    const contactEmail = _get(values, 'contactEmail') as string;
    const companyProfileLink = getAppUrlWithDomain(
      SYSTEM_APP_URLS.dashboard.companies.view.getDynamicPath({
        pathParts: { id: companyId },
      }),
      SYSTEM_PLATFORM,
    );
    companyPlanChangeRequest({
      variables: {
        input: { params: { message, companyProfileLink, contactEmail } },
      },
    });
  };
  const actions = ({ isDirty }: ActionsProps) => (
    <ActionsRow
      actions={[
        <Button
          onClick={onClose}
          buttonType={ButtonTypes.secondaryOutlined}
          buttonText={t('cancel')}
          leftIcon="close"
          fullWidth
        />,
        <Button
          type="submit"
          onClick={onConfirm}
          buttonType={ButtonTypes.primaryFilled}
          buttonText={t('send-request')}
          leftIcon="send"
          leftIconClassName={materialSymbolsOutlined}
          fullWidth
          disabled={!isDirty}
        />,
      ]}
    />
  );
  return (
    <Modal
      description={<Trans i18nKey={description} />}
      icon={icon}
      iconColor={iconColor}
      isOpen={isModalOpen}
      onClose={onClose}
      title={t(title)}
    >
      <Form
        formId="upgrade-plan"
        fields={fields}
        defaultValues={{}}
        actions={actions}
        onSubmit={onSubmit}
      />
    </Modal>
  );
}
