import React from 'react';
import { TFunction } from 'i18next';
import { NavLink } from 'react-router-dom';
import _get from 'lodash/get';
import { FormCategory } from 'graphql-common';
import TextCell from '@lib/components/Table/commonComponents/TextCell/TextCell';
import ChipCell from '@lib/components/Table/commonComponents/ChipCell/ChipCell';
import { CellProps, Column, TableAction } from '@lib/components/Table/types';
import { ChipTypes } from '@lib/components/Chip/Chip';
import { IconButtonTypes } from '@lib/components/IconButton/IconButton';
import LinkComponent from '@lib/components/Link/Link';
import CollapseCell from '@lib/components/Table/commonComponents/CollapseCell/CollapseCell';
import { APP_URLS } from 'constants/urls';

type ColumnsArgs = {
  onEditHandler: (v: FormCategory) => void;
  t: TFunction<'translation', undefined>;
  isViewOnly: boolean;
  isTasksAvailable: boolean;
};

export const getColumns = ({
  onEditHandler,
  t,
  isViewOnly,
  isTasksAvailable,
}: ColumnsArgs) => {
  const result: Column<FormCategory>[] = [
    {
      id: 'name',
      Header: t('name'),
      accessor: 'name',
      Cell: ({ item }: CellProps<FormCategory>) => {
        const label = _get(item, 'name');
        const type = _get(item, 'color') as ChipTypes;
        if (!label || !type) return '-';
        return (
          <ChipCell
            label={label}
            type={type}
            leftIcon="circle"
            onClick={isViewOnly ? undefined : () => onEditHandler(item)}
          />
        );
      },
      maxWidth: 250,
      minWidth: 250,
      width: 250,
      sortable: true,
    },
  ];
  if (!isTasksAvailable) {
    result.push(
      {
        id: 'formTemplatesCount',
        Header: t('forms-total'),
        accessorFn: ({ item }: CellProps<FormCategory>) =>
          item.formTemplatesCount || '0',
        Cell: (props: CellProps<FormCategory>) => <TextCell {...props} />,
        maxWidth: 250,
        minWidth: 130,
        width: 170,
        sortable: true,
      },
      {
        id: 'linkedForms',
        Header: t('linked-forms'),
        accessorFn: ({ item }: CellProps<FormCategory>) => {
          const { formTemplates } = item;
          return formTemplates.collection.map(
            ({ id, name, currentVersion }) => (
              <LinkComponent
                key={id}
                to={APP_URLS.dashboard.formTemplates.viewVersion.getDynamicPath(
                  {
                    pathParts: { id, versionId: currentVersion?.id || '' },
                  },
                )}
                component={NavLink}
              >
                {name}
              </LinkComponent>
            ),
          );
        },
        Cell: (props: CellProps<FormCategory>) => (
          <CollapseCell {...props} minHeight={20} />
        ),
      },
    );
  }
  return result;
};

type ActionsMenuArgs = {
  onEditHandler: (v: FormCategory) => void;
  onDelete: (v: FormCategory) => void;
  t: TFunction<'translation', undefined>;
  isViewOnly: boolean;
};

export function getActions({
  onEditHandler,
  onDelete,
  t,
  isViewOnly,
}: ActionsMenuArgs): TableAction<FormCategory>[] {
  return isViewOnly
    ? []
    : [
        {
          id: 'edit-action',
          icon: 'edit',
          onClick: (item: FormCategory) => onEditHandler(item),
          type: IconButtonTypes.PrimaryOutlined,
        },
        {
          id: 'delete-action',
          icon: 'delete',
          onClick: (item: FormCategory) => onDelete(item),
          type: IconButtonTypes.PrimaryOutlined,
          predictorFnDisable: (item: FormCategory) =>
            item.formTemplatesCount > 0 || !item.canDestroy.value,
          predictorFnDisableTooltipProps: () => ({
            body: t('cannot-delete-form-category'),
            placement: 'left',
            maxWidth: 150,
          }),
        },
      ];
}
