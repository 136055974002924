import _get from 'lodash/get';
import { LanguageEnum } from 'graphql-common';
import { Values } from '@lib/interfaces/form';
import UserFieldNames from '@lib/enums/fieldNames/userFieldNames';
import { getLangLabel } from 'utils/lang';

export default function getDefaultLangFormValuesFromQueryData(
  data?: Values,
): Values {
  const value = _get(data, 'lang') as LanguageEnum;
  return {
    [UserFieldNames.Lang]: { value, label: getLangLabel(value) },
  };
}
