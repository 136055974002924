import {
  AssetCounterMeasuresGraphDataQuery,
  AssetCounterMeasuresGraphDataQueryVariables,
  DateRangeInputObject,
  useAssetCounterMeasuresGraphDataQuery,
} from 'graphql-common';
import { ApolloError } from '@apollo/client/errors';
import _get from 'lodash/get';
import { GetUrlParams } from '@lib/enums/urls';
import { ListQueryHookProps } from './types';

export type UseAssetCountersQueryHookResult = {
  data: AssetCounterMeasuresGraphDataQuery['data'] | undefined;
  error: ApolloError | undefined;
  firstLoading: boolean;
  loading: boolean;
  refetch: () => Promise<unknown>;
};

type Args = ListQueryHookProps & {
  assetId: string;
  counterId: string;
  dateRange: DateRangeInputObject;
};

export default function useAssetCounterMeasureGraphDataQueryHook({
  assetId,
  counterId,
  dateRange,
  fetchPolicy = 'cache-and-network',
  filterParams,
  skip,
  usePrevious = true,
}: Args): UseAssetCountersQueryHookResult {
  const periodStart =
    (_get(filterParams, GetUrlParams.PeriodStart) as string) ||
    dateRange?.startDate;
  const periodEnd =
    (_get(filterParams, GetUrlParams.PeriodEnd) as string) ||
    dateRange?.endDate;
  const queryVariables: AssetCounterMeasuresGraphDataQueryVariables = {
    assetId,
    counterId,
    dateRange: {
      startDate: periodStart,
      endDate: periodEnd,
    },
  };

  const query = useAssetCounterMeasuresGraphDataQuery({
    fetchPolicy,
    variables: queryVariables,
    skip,
  });
  const { loading, data, previousData, refetch, error } = query;
  const queryData = usePrevious ? data || previousData : data;
  const firstLoading = loading && previousData === undefined;

  return {
    refetch,
    data: queryData?.data,
    error,
    firstLoading,
    loading,
  };
}
