export enum InterventionViewMode {
  ViewAction = 'viewAction',
  Add = 'add',
  AddAction = 'addAction',
  Edit = 'edit',
  EditAction = 'editAction',
  View = 'view',
  ViewActions = 'viewActions',
}

export enum InterventionActionViewMode {
  Details = 'details',
  Timeline = 'timeline',
  History = 'history',
}
