import { useState } from 'react';
import { Documentation } from 'graphql-common';

export interface UseModalManagementResult {
  closeAllModals: () => void;
  closeDocumentationPreviewModal: () => void;
  isDocumentationDestroyModalOpened: boolean;
  isDocumentationPreviewModalOpened: Documentation | null;
  openDocumentationDestroyModal: () => void;
  openDocumentationPreviewModal: (item: Documentation) => void;
}

export default function useModalManagement(): UseModalManagementResult {
  const [
    isDocumentationDestroyModalOpened,
    setDocumentationDestroyModalOpened,
  ] = useState(false);
  const openDocumentationDestroyModal = () =>
    setDocumentationDestroyModalOpened(true);
  const closeDocumentationDestroyModal = () =>
    setDocumentationDestroyModalOpened(false);

  const [
    isDocumentationPreviewModalOpened,
    setDocumentationPreviewModalOpened,
  ] = useState<Documentation | null>(null);
  const openDocumentationPreviewModal = (item: Documentation) =>
    setDocumentationPreviewModalOpened(item);
  const closeDocumentationPreviewModal = () =>
    setDocumentationPreviewModalOpened(null);

  const closeAllModals = () => {
    closeDocumentationDestroyModal();
    closeDocumentationPreviewModal();
  };

  return {
    closeAllModals,
    closeDocumentationPreviewModal,
    isDocumentationDestroyModalOpened,
    isDocumentationPreviewModalOpened,
    openDocumentationDestroyModal,
    openDocumentationPreviewModal,
  };
}
