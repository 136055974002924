import { useTranslation } from 'react-i18next';
import {
  useAssetCounterBulkQrCodeCreateMutation,
  useAssetCounterCreateMutation,
  useAssetCounterDestroyMutation,
  useAssetCounterMeasureCreateMutation,
  useAssetCounterResetToZeroMutation,
  useAssetCounterUpdateMutation,
} from 'graphql-common';
import { showGraphQLErrors } from '@lib/utils/graphQLErrors';

export default function useCounterMutations() {
  const { t } = useTranslation();

  // Asset nomenclature mutations
  const [assetCounterCreateMutation, assetCounterCreateMutationResult] =
    useAssetCounterCreateMutation({
      onError: (error) => showGraphQLErrors(error, t),
    });

  const [assetCounterUpdateMutation, assetCounterUpdateMutationResult] =
    useAssetCounterUpdateMutation({
      onError: (error) => showGraphQLErrors(error, t),
    });

  const [assetCounterDestroyMutation] = useAssetCounterDestroyMutation({
    onError: (error) => showGraphQLErrors(error, t),
  });

  const [
    assetCounterMeasureCreateMutation,
    assetCounterMeasureCreateMutationResult,
  ] = useAssetCounterMeasureCreateMutation({
    onError: (error) => showGraphQLErrors(error, t),
  });

  const [
    assetCounterResetToZeroMutation,
    assetCounterResetToZeroMutationResult,
  ] = useAssetCounterResetToZeroMutation({
    onError: (error) => showGraphQLErrors(error, t),
  });

  // QR code
  const [qrCodeCreateMutation, qrCodeCreateMutationResult] =
    useAssetCounterBulkQrCodeCreateMutation();

  return {
    assetCounterCreateMutation,
    assetCounterCreateMutationResult,
    assetCounterDestroyMutation,
    assetCounterMeasureCreateMutation,
    assetCounterMeasureCreateMutationResult,
    assetCounterResetToZeroMutation,
    assetCounterResetToZeroMutationResult,
    assetCounterUpdateMutation,
    assetCounterUpdateMutationResult,
    qrCodeCreateMutation,
    qrCodeCreateMutationResult,
  };
}
