import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import _get from 'lodash/get';
import { ComponentEnum, LevelEnum, User } from 'graphql-common';
import BreadCrumbs from '@lib/components/BreadCrumbs/BreadCrumbs';
import { useTableItemsBulkSelect } from '@lib/hooks/useTableItemsBulkSelect';
import { getBreadCrumbsDataByKey } from 'utils/breadcrumbs';
import PageHeader from '@lib/layouts/DashboardLayout/PageHeader/PageHeader';
import Button from '@lib/components/Button/Button';
import CircleLoader from '@lib/components/CircleLoader/CircleLoader';
import PageBody from '@lib/layouts/DashboardLayout/PageBody/PageBody';
import Search from '@lib/components/Search/Search';
import Table from '@lib/components/Table/Table';
import UserModalForm from 'components/UserModalForm/UserModalForm';
import ConfirmationModal from '@lib/components/ConfirmationModal/ConfirmationModal';
import ErrorPage from '@lib/components/ErrorPage/ErrorPage';
import getStatusFromError from '@lib/utils/getStatusFromError';
import useModalManagement, { Modals } from '@lib/hooks/useModalManagement';
import useSearchParam from '@lib/hooks/useSearchParam';
import usePaginationParams from '@lib/hooks/usePaginationParams';
import useUsersQueryHook from 'utils/fetch/useUsersQueryHook';
import useOutletContext, { ContextProps } from 'hooks/useOutletContext';
import ViewOnlyChip from '@lib/components/ViewOnlyChip/ViewOnlyChip';
import CompanyPlanBanner from '@lib/components/CompanyPlanBanner/CompanyPlanBanner';
import useWhoamiQueryHook from 'utils/fetch/useWhoamiQueryHook';
import { IconName } from '@lib/components/Modal/enums';
import {
  calculateActiveUsersPercentage,
  getPlanState,
} from '@lib/utils/accessesManagement';
import { PlanState } from '@lib/enums/plan';
import getUsersTableActionsForSelected from './utils/getUsersTableActionsForSelected';
import getUsersTableColumns from './utils/getUsersTableColumns';
import getUsersTableActionsMenu from './utils/getUsersTableActionsMenu';
import useUsersActions from './utils/useUsersActions';

const modals = [
  Modals.UserFormModal,
  Modals.UserDeleteModal,
  Modals.MoveMembersToTeamModal,
];

function UsersContainer() {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [searchQuery] = useSearchParam();
  const [paginationParams] = usePaginationParams();

  const { currentUser, refetch: refetchWhoami } = useWhoamiQueryHook({
    includeFullData: false,
  });
  const activeUsersCount = _get(currentUser, 'company.activeUsersCount');
  const usersLimit = _get(currentUser, 'company.plan.usersLimit');
  const activeUsersPercentage = calculateActiveUsersPercentage(
    activeUsersCount || 0,
    usersLimit,
  );
  const planState = getPlanState(activeUsersPercentage);

  const { getUserAccessByComponent, handleOpenPlanModal }: ContextProps =
    useOutletContext();
  const isUserCreateAvailable = planState !== PlanState.Attention;

  const isViewOnly =
    getUserAccessByComponent(ComponentEnum.UsersManagement) === LevelEnum.Read;

  const useUsersQueryHookResult = useUsersQueryHook({
    searchQuery,
    paginationParams,
    includeFullData: true,
  });

  const useBulkSelectResult = useTableItemsBulkSelect<User>();
  const useModalManagementResult = useModalManagement(modals, () =>
    useBulkSelectResult.handleSelectSingleTableItem(null),
  );
  const { isModalOpened, openModal, closeModal } = useModalManagementResult;
  const {
    currentUserId,
    onActivate,
    onBulkActivate,
    onBulkDeactivate,
    onConfirmedDelete,
    onDeactivate,
    onDelete,
    onEdit,
    onResendEmailConfirmationHandler,
    onResendRegistrationConfirmationHandler,
    onSubmitUserFormCallback,
    onTeamMembersUpdate,
    onView,
    openTeamPage,
    selectedUserId,
    teamsLoadOptions,
    openUserEditAccessesPage,
    onConfirmedTeamMembersUpdate,
  } = useUsersActions({
    refetchWhoami,
    useUsersQueryHookResult,
    useModalManagementResult,
    useBulkSelectResult,
  });

  const columns = getUsersTableColumns({
    currentUserId,
    isViewOnly,
    onResendEmailConfirmationHandler,
    onResendRegistrationConfirmationHandler,
    openTeamPage,
    t,
  });
  const tableActionsMenu = getUsersTableActionsMenu({
    currentUserId,
    onActivate,
    onDeactivate,
    onDelete,
    onEdit,
    onEditAccesses: openUserEditAccessesPage,
    onView,
    t,
    isViewOnly,
  });
  const tableActionsForSelected = getUsersTableActionsForSelected({
    teamsLoadOptions,
    onBulkActivate,
    onBulkDeactivate,
    onTeamMembersUpdate,
    t,
    selectedItemsCount: useBulkSelectResult.getSelectedItemsCount(
      useUsersQueryHookResult.totalCount,
    ),
    isViewOnly,
  });

  return (
    <>
      <BreadCrumbs data={getBreadCrumbsDataByKey({ pathname, t })} />
      {useUsersQueryHookResult.error ? (
        <ErrorPage
          status={getStatusFromError(useUsersQueryHookResult.error)}
          description={useUsersQueryHookResult.error?.message}
        />
      ) : (
        <>
          <PageHeader title={t('team-members')}>
            {isViewOnly ? (
              <ViewOnlyChip />
            ) : (
              <Button
                buttonText={t('add-team-member')}
                leftIcon={isUserCreateAvailable ? 'add' : 'lock'}
                onClick={() => openModal(Modals.UserFormModal)}
                disabled={!isUserCreateAvailable}
              />
            )}
          </PageHeader>
          {useUsersQueryHookResult.firstLoading ? (
            <CircleLoader />
          ) : (
            <PageBody>
              {isViewOnly ? null : (
                <CompanyPlanBanner
                  activeUsersCount={activeUsersCount}
                  planState={planState}
                  handleOpenPlanModal={handleOpenPlanModal}
                  usersLimit={usersLimit}
                  activeUsersPercentage={activeUsersPercentage}
                />
              )}
              <Search placeholder={t('users-search-placeholder')} />
              <Table
                actionsMenu={tableActionsMenu}
                actionsForSelected={tableActionsForSelected}
                columns={columns}
                counterText={t('team-member')}
                data={useUsersQueryHookResult.collection}
                fullHeight
                id="users"
                loading={useUsersQueryHookResult.loading}
                totalEntries={useUsersQueryHookResult.totalCount}
                getIsRowDisabled={({ setup, verified, deactivatedAt }) =>
                  !setup || !verified || !!deactivatedAt
                }
                useBulkSelectResult={useBulkSelectResult}
              />
            </PageBody>
          )}
          <UserModalForm
            isOpen={isModalOpened(Modals.UserFormModal)}
            onClose={() => {
              closeModal(Modals.UserFormModal);
              useBulkSelectResult.handleSelectSingleTableItem(null);
            }}
            onSubmitUserFormCallback={onSubmitUserFormCallback}
            selectedUserId={selectedUserId}
            title={
              selectedUserId ? t('edit-team-member') : t('add-team-member')
            }
          />
          <ConfirmationModal
            confirmButtonLabel={t('yes-delete')}
            description={t('delete-user-text')}
            icon={IconName.Delete}
            isOpen={isModalOpened(Modals.UserDeleteModal)}
            onClose={() => closeModal(Modals.UserDeleteModal)}
            onConfirm={onConfirmedDelete}
            title={t('are-you-sure')}
          />
          <ConfirmationModal
            confirmButtonLabel={t('yes-move-users')}
            description={t('move-users--to-team-text')}
            icon={IconName.Delete}
            isOpen={isModalOpened(Modals.MoveMembersToTeamModal)}
            onClose={() => closeModal(Modals.MoveMembersToTeamModal)}
            onConfirm={onConfirmedTeamMembersUpdate}
            title={t('are-you-sure')}
          />
        </>
      )}
    </>
  );
}

export default UsersContainer;
