import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TFunction } from 'i18next';
import { ApolloError } from '@apollo/client/errors';
import _toNumber from 'lodash/toNumber';
import _get from 'lodash/get';
import { useLocalStorage } from 'usehooks-ts';
import { Asset, Counter } from 'graphql-common';
import { Values } from '@lib/interfaces/form';
import AssetCounterIndexFieldNames from '@lib/enums/fieldNames/assetCounterIndexFieldNames';
import toast from '@lib/components/Toast/Toast';
import { APP_URLS } from 'constants/urls';
import { CounterIndexAction } from 'components/AssetCounterIndexModalForm/enums';
import useCounterMutations from 'routes/Assets/Asset/utils/useCounterMutations';
import useAssetCounterQueryHook from 'utils/fetch/useAssetCounterQueryHook';
import useAssetCounterMeasuresQueryHook from 'utils/fetch/useAssetCounterMeasuresQueryHook';
import useAssetCounterMeasureGraphDataQueryHook from 'utils/fetch/useAssetCounterMeasureGraphDataQueryHook';
import usePaginationParams from '@lib/hooks/usePaginationParams';
import useFilterParams from '@lib/hooks/useFilterParams';
import {
  COUNTER_MONITORING_LAST_ASSET_ID,
  COUNTER_MONITORING_LAST_COUNTER_IDS,
} from '@lib/enums/localStorageKeys';
import useAssetWithCountersQueryHook from 'utils/fetch/useAssetWithCountersQueryHook';
import { getDefaultPeriod } from 'routes/Assets/AssetCounters/utils/getDefaultPeriod';
import useAssetsQueryHook from 'utils/fetch/useAssetsQueryHook';
import { GetUrlParams } from '@lib/enums/urls';
import { UseModalManagementResult } from './useModalManagement';
import { AssetsSidebarView, CounterMonitoringView } from '../enums';

type Args = {
  t: TFunction<'translation', undefined>;
  useModalManagementResult: UseModalManagementResult;
  view: CounterMonitoringView;
};

export default function useActions(args: Args) {
  const { t, useModalManagementResult, view } = args;

  const navigate = useNavigate();
  const [filterParams] = useFilterParams();
  const [paginationParams] = usePaginationParams();
  const useMutationsResult = useCounterMutations();
  const [defaultAssetId, setDefaultAssetId] = useLocalStorage(
    COUNTER_MONITORING_LAST_ASSET_ID,
    '',
  );
  const [defaultCounterIdsMap, setDefaultCounterIdsMap] = useLocalStorage(
    COUNTER_MONITORING_LAST_COUNTER_IDS,
    {},
  );

  const defaultDateRange = getDefaultPeriod();
  const { startDate, endDate } = defaultDateRange;
  const defaultPeriodValue =
    startDate && endDate ? [startDate, endDate] : undefined;
  const defaultCounterId =
    defaultCounterIdsMap && defaultAssetId
      ? defaultCounterIdsMap[defaultAssetId]
      : undefined;

  // State
  const [sidebarView, setSideBarView] = useState<AssetsSidebarView>(
    AssetsSidebarView.Assets,
  );

  // Asset
  const useAssetQueryHookResult = useAssetWithCountersQueryHook({
    id: defaultAssetId,
    includeFullData: false,
    onError: () => {
      setDefaultAssetId('');
      const newDefaultCounterIdsMap = { ...defaultCounterIdsMap };
      delete newDefaultCounterIdsMap[defaultAssetId];
      setDefaultCounterIdsMap(newDefaultCounterIdsMap);
    },
  });
  const useAssetsQueryHookResult = useAssetsQueryHook({
    withCountersOnly: true,
    paginationParams: {
      [GetUrlParams.Page]: '1',
      [GetUrlParams.PerPage]: '1',
    },
  });

  // Counters
  const useCounterQueryHookResult = useAssetCounterQueryHook({
    id: defaultCounterId,
    assetId: defaultAssetId,
    skip: !(defaultCounterId && defaultAssetId),
    onError: () => {
      setDefaultAssetId('');
      const newDefaultCounterIdsMap = { ...defaultCounterIdsMap };
      delete newDefaultCounterIdsMap[defaultAssetId];
      setDefaultCounterIdsMap(newDefaultCounterIdsMap);
      useModalManagementResult.closeAllModals();
    },
  });

  // Data
  const useAssetCounterMeasuresQueryHookResult =
    useAssetCounterMeasuresQueryHook({
      assetId: defaultAssetId,
      counterId: defaultCounterId,
      paginationParams,
      filterParams,
      dateRange: defaultDateRange,
      skip:
        !defaultCounterId ||
        !defaultAssetId ||
        view !== CounterMonitoringView.Table,
    });
  const useAssetCounterMeasureGraphDataQueryHookResult =
    useAssetCounterMeasureGraphDataQueryHook({
      assetId: defaultAssetId,
      counterId: defaultCounterId,
      dateRange: defaultDateRange,
      filterParams,
      skip:
        !defaultCounterId ||
        !defaultAssetId ||
        view !== CounterMonitoringView.Graph,
    });

  const onCloseModal = () => {
    useModalManagementResult.closeAllModals();
    useMutationsResult.assetCounterMeasureCreateMutationResult.reset();
    useMutationsResult.assetCounterResetToZeroMutationResult.reset();
  };

  const onAdd = () => {
    navigate(APP_URLS.dashboard.assets.add.path);
  };

  const onSelectAsset = (asset: Asset) => {
    setDefaultAssetId(asset.id);
    setSideBarView(AssetsSidebarView.Counters);
  };

  const onSelectCounter = (counter: Counter, asset?: Asset) => {
    if (!!counter && !!asset) {
      setDefaultCounterIdsMap({
        ...defaultCounterIdsMap,
        [defaultAssetId]: counter.id,
      });
    }
  };

  const onShowSideBarAssets = () => {
    setSideBarView(AssetsSidebarView.Assets);
  };

  const onAddIndex = () => {
    useModalManagementResult.openCounterIndexFormModal();
    useCounterQueryHookResult.refetch();
  };

  const onCreateIntervention = () => {
    navigate(
      APP_URLS.dashboard.interventions.add.getPathWithQuery({
        [GetUrlParams.AssetId]: useAssetQueryHookResult.data?.id,
        [GetUrlParams.SiteAreaId]: useAssetQueryHookResult.data?.siteArea?.id,
        [GetUrlParams.SiteId]: useAssetQueryHookResult.data?.site?.id,
      }),
    );
  };

  const onCounterMeasureCreate = (values: Values) => {
    useMutationsResult.assetCounterMeasureCreateMutation({
      variables: {
        assetId: defaultAssetId,
        attributes: {
          index: _toNumber(_get(values, AssetCounterIndexFieldNames.Index)),
          counterId: defaultCounterId,
        },
      },
      onCompleted: () => {
        onCloseModal();
        useAssetCounterMeasuresQueryHookResult.refetch();
        useAssetCounterMeasureGraphDataQueryHookResult.refetch();
        toast({ content: t('update-asset-counter-success') });
      },
    });
  };

  const onCounterResetToZero = () => {
    useMutationsResult.assetCounterResetToZeroMutation({
      variables: {
        assetId: defaultAssetId,
        id: defaultCounterId,
      },
      onCompleted: () => {
        onCloseModal();
        useAssetCounterMeasuresQueryHookResult.refetch();
        useAssetCounterMeasureGraphDataQueryHookResult.refetch();
        toast({ content: t('update-asset-counter-success') });
      },
    });
  };

  const onCounterIndexFormSubmit = (values: Values) => {
    useMutationsResult.assetCounterMeasureCreateMutationResult.reset();
    useMutationsResult.assetCounterResetToZeroMutationResult.reset();
    const action = _get(values, [AssetCounterIndexFieldNames.Action, 'value']);
    if (action === CounterIndexAction.Index) {
      onCounterMeasureCreate(values);
    } else if (action === CounterIndexAction.Zero) {
      onCounterResetToZero();
    }
  };

  const assetCounterIndexMutationError: ApolloError | undefined =
    _get(useMutationsResult, [
      'assetCounterMeasureCreateMutationResult',
      'error',
    ]) ||
    _get(useMutationsResult, [
      'assetCounterResetToZeroMutationResult',
      'error',
    ]);

  const assetCounterIndexMutationLoading =
    _get(useMutationsResult, [
      'assetCounterMeasureCreateMutationResult',
      'loading',
    ]) ||
    _get(useMutationsResult, [
      'assetCounterResetToZeroMutationResult',
      'loading',
    ]);

  useEffect(() => {
    if (defaultAssetId) {
      setSideBarView(AssetsSidebarView.Counters);
    }
  }, [defaultAssetId]);

  return {
    assetCounterIndexMutationError,
    assetCounterIndexMutationLoading,
    defaultAssetId,
    defaultCounterId,
    defaultPeriodValue,
    onAdd,
    onAddIndex,
    onCloseModal,
    onCounterIndexFormSubmit,
    onCreateIntervention,
    onSelectAsset,
    onSelectCounter,
    onShowSideBarAssets,
    sidebarView,
    useAssetCounterMeasureGraphDataQueryHookResult,
    useAssetCounterMeasuresQueryHookResult,
    useAssetQueryHookResult,
    useCounterQueryHookResult,
    useAssetsQueryHookResult,
  };
}
