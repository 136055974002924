export default function getIncrementedCode(code: string): string {
  // Regular expression to find the last number in the string
  const regex = /(\d+)(?!.*\d)/;

  // Check if the code contains a number
  if (regex.test(code)) {
    // Replace the last number found with an incremented value
    return code.replace(regex, (match) => {
      // Parse the matched number, increment it, and format it with leading zeros
      const num = parseInt(match, 10) + 1;
      const numStr = match.replace(/^\d+/, num.toString()); // Replace leading digits in the match with incremented number
      // Preserve leading zeros
      return numStr.padStart(match.length, '0');
    });
  }

  // Return the original code if no number is found
  return code;
}
