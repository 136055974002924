import React, { useMemo, useRef } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  ComponentEnum,
  Counter,
  Documentation,
  Intervention,
  LevelEnum,
  Nomenclature,
} from 'graphql-common';
import { SubmitAction } from '@lib/enums/form';
import {
  UseBulkSelectResult,
  useTableItemsBulkSelect,
} from '@lib/hooks/useTableItemsBulkSelect';
import CircleLoader from '@lib/components/CircleLoader/CircleLoader';
import BreadCrumbs from '@lib/components/BreadCrumbs/BreadCrumbs';
import { FormRef } from '@lib/components/ReactHookForm/types';
import ErrorPage from '@lib/components/ErrorPage/ErrorPage';
import getStatusFromError from '@lib/utils/getStatusFromError';
import AssetMenuBar from 'components/AssetMenuBar/AssetMenuBar';
import { getBreadCrumbsDataByKey } from 'utils/breadcrumbs';
import useOutletContext, { ContextProps } from 'hooks/useOutletContext';
import AssetNomenclatureContainer from './AssetNomenclatureContainer';
import AssetCountersContainer from './AssetCountersContainer';
import AssetDetailsContainer from './AssetDetailsContainer';
import AssetDocumentationsContainer from './AssetDocumentationsContainer';
import AssetInterventionsContainer from './AssetInterventionsContainer';
import useSearchListQueries from './utils/useSearchListQueries';
import useModalManagement from './utils/useModalManagement';
import useActions from './utils/useActions';
import { AssetViewMode } from './enums';

type Props = {
  viewMode: AssetViewMode;
};

export default function AssetContainer({ viewMode }: Props) {
  const { id = '' } = useParams();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const formRef = useRef<FormRef>();
  const submitNewItemRef = useRef<SubmitAction>();

  const { getUserAccessByComponent }: ContextProps = useOutletContext();
  const isViewOnly =
    getUserAccessByComponent(ComponentEnum.AssetsManagement) === LevelEnum.Read;
  const isCounterViewOnly =
    getUserAccessByComponent(ComponentEnum.AssetsCounterReading) ===
    LevelEnum.LimitedRead;

  const useBulkSelectResult = useTableItemsBulkSelect<
    Nomenclature | Counter | Documentation | Intervention
  >();

  const useModalManagementResult = useModalManagement();

  const useActionsResult = useActions({
    id,
    viewMode,
  });
  const { assetQueryHook } = useActionsResult;

  const useSearchListQueriesResult = useSearchListQueries();

  const menuBar = useMemo(
    () => (
      <AssetMenuBar
        id={id}
        counters={assetQueryHook.counters}
        onChange={useBulkSelectResult.handleUnselectAllTableItems}
      />
    ),
    [
      assetQueryHook.counters,
      id,
      useBulkSelectResult.handleUnselectAllTableItems,
    ],
  );

  if (assetQueryHook.firstLoading) {
    return <CircleLoader />;
  }

  if (assetQueryHook.error) {
    return (
      <ErrorPage
        status={getStatusFromError(assetQueryHook.error)}
        description={assetQueryHook.error.message}
      />
    );
  }

  return (
    <>
      <BreadCrumbs
        data={getBreadCrumbsDataByKey({
          pathname,
          t,
          entity: assetQueryHook.data || undefined,
        })}
      />
      {viewMode === AssetViewMode.View && (
        <AssetDetailsContainer
          formRef={formRef}
          menuBar={menuBar}
          useActionsResult={useActionsResult}
          useModalManagementResult={useModalManagementResult}
          useSearchListQueriesResult={useSearchListQueriesResult}
          viewMode={viewMode}
          isViewOnly={isViewOnly}
        />
      )}
      {viewMode === AssetViewMode.Nomenclatures && (
        <AssetNomenclatureContainer
          formRef={formRef}
          menuBar={menuBar}
          submitNewItemRef={submitNewItemRef}
          useActionsResult={useActionsResult}
          useBulkSelectResult={
            useBulkSelectResult as UseBulkSelectResult<Nomenclature>
          }
          useModalManagementResult={useModalManagementResult}
          useSearchListQueriesResult={useSearchListQueriesResult}
          viewMode={viewMode}
          isViewOnly={isViewOnly}
        />
      )}
      {viewMode === AssetViewMode.Counters && (
        <AssetCountersContainer
          formRef={formRef}
          menuBar={menuBar}
          submitNewItemRef={submitNewItemRef}
          useActionsResult={useActionsResult}
          useBulkSelectResult={
            useBulkSelectResult as UseBulkSelectResult<Counter>
          }
          useModalManagementResult={useModalManagementResult}
          useSearchListQueriesResult={useSearchListQueriesResult}
          viewMode={viewMode}
          isViewOnly={isViewOnly}
          isCounterViewOnly={isCounterViewOnly}
        />
      )}
      {viewMode === AssetViewMode.Documentation && (
        <AssetDocumentationsContainer
          id={id}
          menuBar={menuBar}
          useActionsResult={useActionsResult}
          useBulkSelectResult={
            useBulkSelectResult as UseBulkSelectResult<Documentation>
          }
          useModalManagementResult={useModalManagementResult}
          viewMode={viewMode}
          isViewOnly={isViewOnly}
        />
      )}
      {viewMode === AssetViewMode.Interventions && (
        <AssetInterventionsContainer
          id={id}
          menuBar={menuBar}
          useActionsResult={useActionsResult}
          useBulkSelectResult={
            useBulkSelectResult as UseBulkSelectResult<Intervention>
          }
          viewMode={viewMode}
        />
      )}
    </>
  );
}
