import React, { forwardRef, memo, useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import { TimePicker } from 'antd';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import dayjs, { Dayjs } from 'dayjs';
import { PickerRef } from 'rc-picker/lib/interface';
import { timeFormatForApi, timePickerDateFormat } from '@lib/enums/dateTime';
import {
  formatTimeToISODateString,
  getFormattedDate,
} from '@lib/utils/dateTimeHelpers';
import MaterialIcon from '@lib/components/MaterialIcon/MaterialIcon';
import withFloatingLabel, {
  FloatingLabelProps,
} from '@lib/hocs/withFloatingLabel';
import Button, {
  ButtonSizes,
  ButtonTypes,
} from '@lib/components/Button/Button';
import isMobile from '@lib/utils/isMobile';
import styles from './DateTimePicker.module.scss';

type Props = {
  disabled?: boolean;
  hasError?: boolean;
  name?: string;
  onChange: (v: string[]) => void;
  size?: SizeType;
  value?: string[];
};

const TimeRangePickerCommon = forwardRef(
  (props: Props & FloatingLabelProps, ref: React.ForwardedRef<PickerRef>) => {
    const {
      disabled,
      onChange,
      setIsFocused,
      name,
      value,
      hasError,
      size = 'large',
    } = props;
    const [isDropdownOpened, setDropdownOpened] = useState(false);
    const { phone, tablet } = isMobile();
    const isMobileDevice = phone || tablet;

    let selected: [Dayjs | null, Dayjs | null] | null = null;
    const isValueExists = !!(Array.isArray(value) && value?.[0] && value?.[1]);
    if (isValueExists) {
      selected = [
        dayjs(formatTimeToISODateString(value[0])),
        dayjs(formatTimeToISODateString(value[1])),
      ];
    }

    const shouldUseOnBlur = !(isValueExists || isDropdownOpened);
    const onBlurHandler = shouldUseOnBlur
      ? () => setIsFocused(false)
      : undefined;

    const onFocusHandler = () => {
      setIsFocused(true);
    };

    const onChangeHandler = useCallback(
      (v: [Dayjs | null, Dayjs | null] | null) => {
        const newValue = ['', ''];
        if (v && Array.isArray(v) && v.length) {
          v.forEach((date, index) => {
            if (date) {
              const res = getFormattedDate(date.toDate(), timeFormatForApi);
              newValue[index] = res || '';
            }
          });
        } else if (isMobileDevice) {
          setIsFocused(false);
        }
        onChange(newValue);
      },
      [isMobileDevice, onChange, setIsFocused],
    );

    const components = useMemo(() => {
      const button = ({ children, disabled: btnDisabled, onClick }) => {
        return (
          <Button
            buttonText={children}
            buttonSize={ButtonSizes.small}
            buttonType={ButtonTypes.primaryFilled}
            disabled={btnDisabled}
            onClick={onClick}
            useRipple={false}
          />
        );
      };
      return { button };
    }, []);

    const className = classNames(styles.picker, {
      [styles.datePickerFilled]: isValueExists,
    });

    return (
      <TimePicker.RangePicker
        allowClear={{ clearIcon: <MaterialIcon icon="cancel" /> }}
        allowEmpty
        className={className}
        components={components}
        disabled={disabled}
        format={timePickerDateFormat}
        name={name}
        needConfirm
        onBlur={onBlurHandler}
        onChange={onChangeHandler}
        onFocus={onFocusHandler}
        placeholder={['', '']}
        ref={ref}
        showNow={false}
        size={size}
        status={hasError ? 'error' : undefined}
        value={selected}
        onOpenChange={setDropdownOpened}
        inputReadOnly={isMobileDevice}
      />
    );
  },
);

export default memo(withFloatingLabel(TimeRangePickerCommon));
