import React from 'react';
import { useTranslation } from 'react-i18next';
import { ApolloError } from '@apollo/client/errors';
import { InterventionCategory } from 'graphql-common';
import Button, { ButtonTypes } from '@lib/components/Button/Button';
import Table from '@lib/components/Table/Table';
import CircleLoader from '@lib/components/CircleLoader/CircleLoader';
import PageHeader from '@lib/layouts/DashboardLayout/PageHeader/PageHeader';
import Search from '@lib/components/Search/Search';
import PageBody from '@lib/layouts/DashboardLayout/PageBody/PageBody';
import ConfirmationModal from '@lib/components/ConfirmationModal/ConfirmationModal';
import ListEmptyState from '@lib/components/ListEmptyState/ListEmptyState';
import useSearchParam from '@lib/hooks/useSearchParam';
import ErrorPage from '@lib/components/ErrorPage/ErrorPage';
import Trans from '@lib/components/Trans/Trans';
import getStatusFromError from '@lib/utils/getStatusFromError';
import Modal from '@lib/components/Modal/Modal';
import { Values } from '@lib/interfaces/form';
import CategoryForm from 'components/InterventionCategory/CategoryForm';
import getDefaultValuesFromQueryData from 'components/InterventionCategory/utils/getDefaultValuesFromQueryData';
import ViewOnlyChip from '@lib/components/ViewOnlyChip/ViewOnlyChip';
import { IconName } from '@lib/components/Modal/enums';
import { getActions, getColumns } from './utils/tableHelpers';

interface Props {
  closeCategoryModal: () => void;
  closeDeleteModal: () => void;
  categories: InterventionCategory[];
  categoriesError?: ApolloError;
  categoriesFirstLoading: boolean;
  categoriesLoading: boolean;
  categoryMutationError?: ApolloError;
  categoryMutationLoading: boolean;
  categoryData?: InterventionCategory | null;
  categoryQueryError?: ApolloError;
  categoryQueryLoading: boolean;
  isCategoryModalOpened: boolean;
  isDeleteModalOpened: boolean;
  onAddHandler: () => void;
  onConfirmedDelete: () => void;
  onDelete: (item: InterventionCategory) => void;
  onEditHandler: (item?: InterventionCategory) => void;
  onSubmitAssetCategoryForm: (values: Values) => void;
  selectedCategory: InterventionCategory | null;
  totalAssetCategoriesCount: number;
  isInterventionsLocked: boolean;
  isInterventionCategoriesViewOnly: boolean;
}

function InterventionCategories(props: Props) {
  const {
    closeCategoryModal,
    closeDeleteModal,
    categories,
    categoriesError,
    categoriesFirstLoading,
    categoriesLoading,
    categoryMutationError,
    categoryMutationLoading,
    categoryData,
    categoryQueryError,
    categoryQueryLoading,
    selectedCategory,
    isCategoryModalOpened,
    isDeleteModalOpened,
    onAddHandler,
    onConfirmedDelete,
    onDelete,
    onEditHandler,
    onSubmitAssetCategoryForm,
    totalAssetCategoriesCount,
    isInterventionsLocked,
    isInterventionCategoriesViewOnly,
  } = props;
  const { t } = useTranslation();
  const [searchQuery] = useSearchParam();

  const tableColumns = getColumns({
    t,
    onEditHandler,
    isInterventionsLocked,
    isInterventionCategoriesViewOnly,
  });
  const tableActions = getActions({
    t,
    onEditHandler,
    onDelete,
    isInterventionCategoriesViewOnly,
  });

  if (categoriesError) {
    return (
      <ErrorPage
        status={getStatusFromError(categoriesError)}
        description={categoriesError.message}
      />
    );
  }

  const emptyState = (
    <ListEmptyState
      title={t('no-categories-yet')}
      text={t('no-categories-text')}
      actions={
        isInterventionCategoriesViewOnly
          ? []
          : [
              <Button
                key="add"
                buttonText={t('create-new-category')}
                buttonType={ButtonTypes.primaryFilled}
                leftIcon="add"
                onClick={onAddHandler}
              />,
            ]
      }
    />
  );

  return (
    <>
      <PageHeader title={t('intervention-categories')}>
        {isInterventionCategoriesViewOnly ? (
          <ViewOnlyChip />
        ) : (
          <Button
            buttonText={t('create-new-category')}
            leftIcon="add"
            onClick={onAddHandler}
          />
        )}
      </PageHeader>
      <PageBody>
        {categoriesFirstLoading ? (
          <CircleLoader />
        ) : (
          <>
            {(!!categories?.length || searchQuery) && (
              <Search
                placeholder={t('intervention-categories-search-placeholder')}
              />
            )}
            <Table
              actions={tableActions}
              columns={tableColumns}
              counterText={t('categories')}
              data={categories}
              emptyState={emptyState}
              fullHeight
              id="intervention-categories"
              loading={categoriesLoading}
              totalEntries={totalAssetCategoriesCount}
            />
          </>
        )}
        <Modal
          isOpen={isCategoryModalOpened}
          title={
            selectedCategory
              ? t('edit-intervention-category')
              : t('create-new-intervention-category')
          }
          onClose={closeCategoryModal}
        >
          <CategoryForm
            defaultValues={
              selectedCategory && categoryData
                ? getDefaultValuesFromQueryData(categoryData)
                : {}
            }
            graphQLErrors={
              (categoryQueryError || categoryMutationError)?.graphQLErrors
            }
            loading={categoryQueryLoading || categoryMutationLoading}
            onCancel={closeCategoryModal}
            onSubmit={onSubmitAssetCategoryForm}
          />
        </Modal>
        <ConfirmationModal
          isOpen={isDeleteModalOpened}
          onClose={closeDeleteModal}
          onConfirm={onConfirmedDelete}
          title={t('are-you-sure')}
          description={
            <Trans
              i18nKey="delete-category-text"
              values={{ name: selectedCategory?.name }}
            />
          }
          confirmButtonLabel={t('yes-delete')}
          icon={IconName.Delete}
          cancelButtonLabel={t('cancel')}
        />
      </PageBody>
    </>
  );
}

export default InterventionCategories;
