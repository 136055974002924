import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { ComponentEnum, LevelEnum, Team } from 'graphql-common';
import BreadCrumbs from '@lib/components/BreadCrumbs/BreadCrumbs';
import { useTableItemsBulkSelect } from '@lib/hooks/useTableItemsBulkSelect';
import { getBreadCrumbsDataByKey } from 'utils/breadcrumbs';
import PageHeader from '@lib/layouts/DashboardLayout/PageHeader/PageHeader';
import Button from '@lib/components/Button/Button';
import CircleLoader from '@lib/components/CircleLoader/CircleLoader';
import PageBody from '@lib/layouts/DashboardLayout/PageBody/PageBody';
import Search from '@lib/components/Search/Search';
import Table from '@lib/components/Table/Table';
import ConfirmationModal from '@lib/components/ConfirmationModal/ConfirmationModal';
import useModalManagement, { Modals } from '@lib/hooks/useModalManagement';
import Trans from '@lib/components/Trans/Trans';
import useOutletContext, { ContextProps } from 'hooks/useOutletContext';
import ViewOnlyChip from '@lib/components/ViewOnlyChip/ViewOnlyChip';
import { IconName } from '@lib/components/Modal/enums';
import getTableActionsMenu from './utils/getTableActionsMenu';
import getTeamsTableColumns from './utils/getTeamsTableColumns';
import useTeamsActions from './utils/useTeamsActions';

const modals = [Modals.TeamDestroyModal];

export default function TeamsContainer() {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const { getUserAccessByComponent }: ContextProps = useOutletContext();
  const isViewOnly =
    getUserAccessByComponent(ComponentEnum.UsersManagement) === LevelEnum.Read;
  const useBulkSelectResult = useTableItemsBulkSelect<Team>();
  const useModalManagementResult = useModalManagement(modals, () =>
    useBulkSelectResult.handleSelectSingleTableItem(null),
  );
  const { closeModal, isModalOpened } = useModalManagementResult;
  const {
    teamsQueryHookResult,
    onConfirmedDelete,
    onCreate,
    onDelete,
    onEdit,
    onView,
  } = useTeamsActions({
    useModalManagementResult,
    useBulkSelectResult,
  });

  const tableActionsMenu = getTableActionsMenu({
    t,
    onView,
    onEdit,
    onDelete,
  });
  const tableColumns = getTeamsTableColumns({
    onView,
    t,
  });

  return (
    <>
      <BreadCrumbs data={getBreadCrumbsDataByKey({ pathname, t })} />
      <PageHeader title={t('team-members')}>
        {isViewOnly ? (
          <ViewOnlyChip />
        ) : (
          <Button
            buttonText={t('create-team')}
            leftIcon="add"
            onClick={() => onCreate()}
          />
        )}
      </PageHeader>
      {teamsQueryHookResult.firstLoading ? (
        <CircleLoader />
      ) : (
        <PageBody>
          <Search placeholder={t('teams-search-placeholder')} />
          <Table
            actionsMenu={tableActionsMenu}
            columns={tableColumns}
            counterText={t('teams')}
            data={teamsQueryHookResult.collection}
            fullHeight
            id="teams"
            loading={teamsQueryHookResult.loading}
            totalEntries={teamsQueryHookResult.totalCount}
          />
        </PageBody>
      )}
      <ConfirmationModal
        confirmButtonLabel={t('yes-delete')}
        description={<Trans i18nKey="delete-team-text" />}
        icon={IconName.Delete}
        isOpen={isModalOpened(Modals.TeamDestroyModal)}
        onClose={() => closeModal(Modals.TeamDestroyModal)}
        onConfirm={onConfirmedDelete}
        title={t('are-you-sure')}
      />
    </>
  );
}
