import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { BaseItem, Column } from '@lib/components/Table/types';
import Tooltip from '@lib/components/Tooltip/Tooltip';
import MaterialIcon from '@lib/components/MaterialIcon/MaterialIcon';
import {
  ORDERING_DIRECTION_ASC,
  ORDERING_DIRECTION_DESC,
} from '@lib/enums/urls';
import isTextCropped from '@lib/utils/isTextCropped';
import styles from './TableHead.module.scss';

type Props<T extends BaseItem> = {
  column: Column<T>;
  index: number;
  onOrderingChange: (column: Column<T>) => void;
  orderingField?: string;
  orderingDirection?: string;
};

function TableHeadCell<T extends BaseItem>(props: Props<T>) {
  const { column, index, onOrderingChange, orderingField, orderingDirection } =
    props;
  const textRef = useRef<HTMLDivElement>(null);
  const [isTooltipDisabled, setIsTooltipDisabled] = useState(true);

  const {
    Header,
    bordered,
    hideHeader,
    id,
    maxWidth,
    minWidth,
    sortable,
    width,
    isCustomHeader,
    isSingleLineHeader,
  } = column;
  const cellStyle = {
    width,
    minWidth,
    maxWidth,
  };
  const cellClassName = classNames(column.cellClassName, {
    [styles.sortingCell]: sortable,
    [styles.borderedCell]: bordered,
    [styles.singleLineCell]: isSingleLineHeader,
  });
  const onCellClick = sortable ? () => onOrderingChange(column) : undefined;

  useEffect(() => {
    const textRefEl = textRef.current;
    if (textRefEl) {
      setIsTooltipDisabled(hideHeader ? true : !isTextCropped(textRefEl));
    }
  }, [hideHeader, Header]);

  return (
    <th
      id={`${id}--${index}`}
      className={cellClassName}
      style={cellStyle}
      onClick={onCellClick}
    >
      {isCustomHeader ? (
        Header
      ) : (
        <div>
          <Tooltip
            body={Header}
            disabled={isTooltipDisabled}
            contentClassName={sortable ? styles.sortingCellContent : undefined}
          >
            {hideHeader ? null : (
              <>
                <div className={styles.headerCellContent} ref={textRef}>
                  {Header}
                </div>
                {sortable && (
                  <MaterialIcon
                    icon="arrow_downward"
                    size="md-16"
                    className={classNames(styles.sortingIcon, {
                      [styles.sortingIconAsc]:
                        orderingField === id &&
                        orderingDirection === ORDERING_DIRECTION_ASC,
                      [styles.sortingIconDesc]:
                        orderingField === id &&
                        orderingDirection === ORDERING_DIRECTION_DESC,
                    })}
                  />
                )}
              </>
            )}
          </Tooltip>
        </div>
      )}
    </th>
  );
}

export default TableHeadCell;
