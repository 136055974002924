import { useTranslation } from 'react-i18next';
import {
  useAssetBulkQrCodeCreateMutation,
  useAssetNomenclatureCreateMutation,
  useAssetNomenclatureDestroyMutation,
  useAssetNomenclatureUpdateMutation,
} from 'graphql-common';
import { showGraphQLErrors } from '@lib/utils/graphQLErrors';

export default function useNomenclatureMutations() {
  const { t } = useTranslation();

  // Asset nomenclature mutations
  const [
    assetNomenclatureCreateMutation,
    assetNomenclatureCreateMutationResult,
  ] = useAssetNomenclatureCreateMutation({
    onError: (error) => showGraphQLErrors(error, t),
  });

  const [
    assetNomenclatureUpdateMutation,
    assetNomenclatureUpdateMutationResult,
  ] = useAssetNomenclatureUpdateMutation({
    onError: (error) => showGraphQLErrors(error, t),
  });

  const [assetNomenclatureDestroyMutation] =
    useAssetNomenclatureDestroyMutation({
      onError: (error) => showGraphQLErrors(error, t),
    });

  // QR code
  const [qrCodeCreateMutation, qrCodeCreateMutationResult] =
    useAssetBulkQrCodeCreateMutation();

  return {
    assetNomenclatureCreateMutation,
    assetNomenclatureCreateMutationResult,
    assetNomenclatureDestroyMutation,
    assetNomenclatureUpdateMutation,
    assetNomenclatureUpdateMutationResult,
    qrCodeCreateMutation,
    qrCodeCreateMutationResult,
  };
}
