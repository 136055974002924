import { FilterGroupingEnum } from 'graphql-common';

interface Predicate {
  cont?: string;
  contAny?: string[];
  eq?: string;
}

export interface Filter {
  [k: string]: {
    grouping: FilterGroupingEnum;
    predicate: Predicate;
    filterGroup?: string;
  };
}

export interface FilterSchema {
  fieldName: string;
  predicateType?: 'cont' | 'eq';
  filterGroup?: string;
}

export default function createSearchFilters(
  searchQuery: string | null,
  schema: FilterSchema[],
): Filter[] {
  const filters: Filter[] = [];
  if (searchQuery) {
    schema.forEach(
      ({ fieldName, predicateType = 'cont', filterGroup = 'searchQuery' }) => {
        if (fieldName && predicateType) {
          const predicate: Predicate = {};
          if (predicateType === 'cont') {
            predicate.contAny = searchQuery
              .split(' ')
              .filter((word) => word.trim().length > 0);
          } else {
            predicate[predicateType] = searchQuery;
          }
          const filter: Filter = {
            [fieldName]: {
              grouping: FilterGroupingEnum.Or,
              predicate,
              filterGroup,
            },
          };
          filters.push(filter);
        }
      },
    );
  }

  return filters;
}
