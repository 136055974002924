import _isEmpty from 'lodash/isEmpty';
import _omit from 'lodash/omit';
import _get from 'lodash/get';
import { AttachmentCuInputObject } from 'graphql-common';
import { Values } from '@lib/interfaces/form';

export default function mergeAndUnlinkAttachedFiles(
  values: Values,
  prevValues: Values,
  fieldName: string,
) {
  const attachmentsAttached: AttachmentCuInputObject[] = [];
  if (!values || !prevValues || !fieldName) return attachmentsAttached;
  const attachments = _get(values, fieldName, []) as Values[];
  const prevAttachments = _get(prevValues, fieldName, []) as Values[];
  if (attachments && Array.isArray(attachments)) {
    attachments.forEach((attachment) => {
      let attachmentValue;
      if (attachment === null) {
        attachmentValue = { _destroy: true };
      } else if (!_isEmpty(attachment?.url)) {
        attachmentValue = _omit(attachment, 'url');
      } else {
        attachmentValue = _omit(attachment, 'file');
      }
      // Don't send attachment if attachment exists and hasn't changed (has urls).
      if (attachmentValue && !attachmentValue?.urls) {
        attachmentsAttached.push(attachmentValue as AttachmentCuInputObject);
      }
      if (_get(attachment, '_destroy')) {
        attachmentsAttached.push({
          id: _get(attachment, 'id') as string,
          _destroy: true,
        });
      }
    });
  } else if (prevAttachments && Array.isArray(prevAttachments)) {
    prevAttachments.forEach((attachment) => {
      const id = _get(attachment, 'id') as string;
      if (id) attachmentsAttached.push({ id, _destroy: true });
    });
  }
  return attachmentsAttached;
}
