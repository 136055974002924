import _get from 'lodash/get';
import { useNavigate, useParams } from 'react-router-dom';
import { Site, User, useSiteQuery } from 'graphql-common';
import usePaginationParams from '@lib/hooks/usePaginationParams';
import { UseBulkSelectResult } from '@lib/hooks/useTableItemsBulkSelect';
import useOutletContext, { ContextProps } from 'hooks/useOutletContext';
import useSiteMembershipsQueryHook from 'utils/fetch/useSiteMembershipsQueryHook';
import { APP_URLS } from 'constants/urls';
import { UseModalManagementResult } from './useSiteUsersModalManagement';

interface UseActionsProps {
  useBulkSelectResult: UseBulkSelectResult<User>;
  useModalManagementResult: UseModalManagementResult;
}

export default function useSiteUsersActions(props: UseActionsProps) {
  const { id: siteId = '' } = useParams();
  const [paginationParams] = usePaginationParams();
  const navigate = useNavigate();
  const { useBulkSelectResult, useModalManagementResult } = props;
  const { selectedSingleTableItem, handleSelectSingleTableItem } =
    useBulkSelectResult;
  const { closeUserFormModal, openUserFormModal } = useModalManagementResult;

  const selectedUserId = selectedSingleTableItem?.id || null;
  const outletContext: ContextProps = useOutletContext();
  const currentUserId = _get(outletContext, 'user.id');

  // Site
  const siteQuery = useSiteQuery({
    skip: !siteId,
    variables: { findBy: { id: siteId } },
  });
  const siteData = _get(siteQuery, 'data.site') as Site;
  const siteLoading = _get(siteQuery, 'loading');
  const siteError = _get(siteQuery, 'error');

  // Site Memberships
  const {
    collection,
    previousData,
    refetchUsers,
    siteUsersLoading,
    totalCount,
  } = useSiteMembershipsQueryHook({ paginationParams, siteId });

  const openUserEditModal = (item: User) => {
    handleSelectSingleTableItem(item);
    openUserFormModal();
  };

  const openUserViewPage = (item: User) => {
    navigate(
      APP_URLS.dashboard.users.view.getDynamicPath({
        pathParts: { id: item.id },
      }),
    );
  };

  const onSubmitUserFormCallback = () => {
    closeUserFormModal();
    refetchUsers();
  };

  const openTeamPage = (teamId: string) => {
    navigate(
      APP_URLS.dashboard.teams.view.getDynamicPath({
        pathParts: { id: teamId },
      }),
    );
  };

  return {
    collection,
    currentUserId,
    openUserEditModal,
    onSubmitUserFormCallback,
    previousData,
    selectedUserId,
    siteData,
    siteError,
    siteLoading,
    siteUsersLoading,
    totalCount,
    openTeamPage,
    openUserViewPage,
  };
}
