import { useEffect, useRef, useState, RefObject } from 'react';

type UseHoverType<T extends HTMLElement> = [RefObject<T>, boolean];

const useHover = <T extends HTMLElement>(): UseHoverType<T> => {
  const [value, setValue] = useState<boolean>(false);
  const ref = useRef<T>(null);
  const handleMouseOver = (): void => setValue(true);
  const handleMouseOut = (): void => setValue(false);
  useEffect(() => {
    const node = ref.current;
    node?.addEventListener('mouseover', handleMouseOver);
    node?.addEventListener('mouseout', handleMouseOut);
    return () => {
      node?.removeEventListener('mouseover', handleMouseOver);
      node?.removeEventListener('mouseout', handleMouseOut);
    };
  }, []);
  return [ref, value];
};

export default useHover;
