import { useCallback, useEffect, useState } from 'react';
import _isEqual from 'lodash/isEqual';
import usePrevious from '@lib/hooks/usePrevious';
import useFilterParams from '@lib/hooks/useFilterParams';
import useSearchParam from '@lib/hooks/useSearchParam';
import useTabParam from '@lib/hooks/useTabParam';

export interface UseBulkSelectResult<T> {
  getSelectedItemsCount: (totalCount: number) => number;
  handleSelectAllTableItems: () => void;
  handleSelectOnlyOneTableItem: (item: T) => void;
  handleSelectSingleTableItem: (item: T | null) => void;
  handleSelectTableItem: (item: T) => void;
  handleUnselectAllTableItems: () => void;
  handleUnselectTableItem: (item: T) => void;
  isAllTableItemsSelected: boolean;
  selectedSingleTableItem: T | null;
  selectedTableItems: T[];
  unselectedTableItems: T[];
}

export function useTableItemsBulkSelect<T>(): UseBulkSelectResult<T> {
  const [filterParams] = useFilterParams();
  const [searchQuery] = useSearchParam();
  const [tab] = useTabParam();
  const prevFilterParams = usePrevious(filterParams);
  const prevSearchQuery = usePrevious(searchQuery);
  const prevTab = usePrevious(tab);
  const [selectedSingleTableItem, setSelectedSingleTableItem] =
    useState<T | null>(null);
  const [selectedTableItems, setSelectedTableItems] = useState<T[]>([]);
  const [unselectedTableItems, setUnselectedTableItems] = useState<T[]>([]);
  const [isAllTableItemsSelected, setIsAllTableItemsSelected] =
    useState<boolean>(false);

  const resetItemsState = useCallback(() => {
    setSelectedTableItems([]);
    setUnselectedTableItems([]);
    setSelectedSingleTableItem(null);
  }, []);

  const handleSelectAllTableItems = useCallback(() => {
    setIsAllTableItemsSelected(true);
    resetItemsState();
  }, [resetItemsState]);

  const handleUnselectAllTableItems = useCallback(() => {
    setIsAllTableItemsSelected(false);
    resetItemsState();
  }, [resetItemsState]);

  const handleSelectSingleTableItem = useCallback((item: T | null) => {
    setSelectedSingleTableItem(item);
  }, []);

  const handleSelectTableItem = (item: T) => {
    if (isAllTableItemsSelected) {
      setUnselectedTableItems(
        unselectedTableItems.filter(
          (unselectedItem) => unselectedItem !== item,
        ),
      );
    } else {
      setSelectedTableItems([...selectedTableItems, item]);
    }
  };

  const handleSelectOnlyOneTableItem = (item: T) => {
    setSelectedTableItems([item]);
  };

  const handleUnselectTableItem = (item: T) => {
    if (isAllTableItemsSelected) {
      setUnselectedTableItems([...unselectedTableItems, item]);
    } else {
      setSelectedTableItems(
        selectedTableItems.filter((selectedItem) => selectedItem !== item),
      );
    }
  };

  const getSelectedItemsCount = (totalCount: number) => {
    return isAllTableItemsSelected && totalCount && totalCount > 0
      ? totalCount - unselectedTableItems.length
      : selectedTableItems.length;
  };

  useEffect(() => {
    if (
      prevSearchQuery !== searchQuery ||
      prevTab !== tab ||
      !_isEqual(filterParams, prevFilterParams)
    ) {
      handleUnselectAllTableItems();
    }
  }, [
    filterParams,
    handleUnselectAllTableItems,
    prevFilterParams,
    prevSearchQuery,
    prevTab,
    searchQuery,
    tab,
  ]);

  return {
    getSelectedItemsCount,
    handleSelectAllTableItems,
    handleSelectOnlyOneTableItem,
    handleSelectSingleTableItem,
    handleSelectTableItem,
    handleUnselectAllTableItems,
    handleUnselectTableItem,
    isAllTableItemsSelected,
    selectedSingleTableItem,
    selectedTableItems,
    unselectedTableItems,
  };
}
