import React, { useRef } from 'react';
import { FormRef } from '@lib/components/ReactHookForm/FormContainer';
import AssetDocumentation from 'components/AssetDocumentation/AssetDocumentation';
import useModalManagement from 'routes/Assets/AssetDocumentation/utils/useModalManagement';
import BreadCrumbs from '@lib/components/BreadCrumbs/BreadCrumbs';
import getDefaultValuesFromQueryData, {
  DEFAULT_BULK_VALUES,
} from './utils/getDefaultValuesFromQueryData';
import useActions from './utils/useActions';

export default function AssetDocumentationContainer() {
  const formRef = useRef<FormRef>();
  const useModalManagementResult = useModalManagement();
  const useActionsResult = useActions({ useModalManagementResult });
  const {
    breadCrumbsData,
    mutationError,
    documentationQueryHookResult,
    isCreatePage,
    loading,
    onConfirmedDocumentationDestroy,
    onCreateTag,
    onSubmit,
    useSearchListQueriesResult,
  } = useActionsResult;

  const defaultValues = isCreatePage
    ? DEFAULT_BULK_VALUES
    : getDefaultValuesFromQueryData(documentationQueryHookResult.data);

  return (
    <>
      <BreadCrumbs data={breadCrumbsData} />
      <AssetDocumentation
        defaultValues={defaultValues}
        documentError={documentationQueryHookResult.error}
        formRef={formRef}
        graphQLErrors={mutationError?.graphQLErrors}
        isCreatePage={isCreatePage}
        loading={loading}
        onConfirmedDocumentationDestroy={onConfirmedDocumentationDestroy}
        onCreateTag={onCreateTag}
        onSubmit={onSubmit}
        tagsLoadOptions={useSearchListQueriesResult.tagsLoadOptions}
        useModalManagementResult={useModalManagementResult}
      />
    </>
  );
}
