import _get from 'lodash/get';
import { FormCategoryCreateInputObject } from 'graphql-common';
import { Values } from '@lib/interfaces/form';
import FormTemplateCategoryFieldNames from '@lib/enums/fieldNames/formTemplateCategoryFieldNames';

export default function getPreparedFormCategoryDataFromValues(values: Values) {
  return {
    [FormTemplateCategoryFieldNames.Name]: _get(values, 'name'),
    [FormTemplateCategoryFieldNames.Color]: _get(values, 'color.value'),
  } as unknown as FormCategoryCreateInputObject;
}
