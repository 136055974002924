import React from 'react';
import Chip, { ChipSizes, ChipTypes } from '@lib/components/Chip/Chip';
import { useTranslation } from 'react-i18next';

export default function ViewOnlyChip() {
  const { t } = useTranslation();
  return (
    <Chip
      label={t('view-only')}
      leftIcon="lock"
      type={ChipTypes.chipBlue}
      size={ChipSizes.large}
    />
  );
}
