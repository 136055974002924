import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { GraphQLErrors } from '@apollo/client/errors';
import Form, { FormRef } from '@lib/components/ReactHookForm/FormContainer';
import Button, { ButtonTypes } from '@lib/components/Button/Button';
import ActionsRow from '@lib/components/ActionsRow/ActionsRow';
import formStyles from '@lib/assets/styles/Form.module.scss';
import { ComponentEnum, PolicyEnum, User } from 'graphql-common';
import { Values } from '@lib/interfaces/form';
import FieldNames from '@lib/enums/fieldNames/userFieldNames';
import {
  getAccessCategoriesScopeIds,
  getAccessesValues,
  getEmptyAccessesValues,
} from '@lib/utils/accessesManagement';
import useSearchListQueries from '../utils/useSearchListQueries';
import getUserAccessesFormFields from '../utils/getUserAccessesFormFields';

type Props = {
  loading: boolean;
  user: User;
  onSubmit: (values: Values) => void;
  onCancel: () => void;
  graphQLErrors?: GraphQLErrors;
};

export default function UserAccessesForm(props: Props) {
  const { user, loading, onSubmit, graphQLErrors, onCancel } = props;
  const { personalAccesses, team } = user;
  const { accesses: teamAccesses } = team;
  const { t } = useTranslation();
  const formRef = useRef<FormRef>();
  const useSearchListQueriesResult = useSearchListQueries();

  const teamAccessesValues = getAccessesValues(teamAccesses);
  const personalAccessesValues = getAccessesValues(
    personalAccesses,
    PolicyEnum.User,
  );
  const defaultValues = {
    [FieldNames.Accesses]: {
      ...teamAccessesValues,
      ...personalAccessesValues,
    },
    [FieldNames.TeamAccesses]: {
      ...getEmptyAccessesValues(),
      ...teamAccessesValues,
    },
  };

  const personalInterventionCategoriesIds = getAccessCategoriesScopeIds(
    personalAccesses,
    ComponentEnum.InterventionsManagementCategoriesScope,
  );
  const teamInterventionCategoriesIds = getAccessCategoriesScopeIds(
    teamAccesses,
    ComponentEnum.InterventionsManagementCategoriesScope,
  );
  const personalAssetCategoriesIds = getAccessCategoriesScopeIds(
    personalAccesses,
    ComponentEnum.AssetsManagementCategoriesScope,
  );
  const teamAssetCategoriesIds = getAccessCategoriesScopeIds(
    teamAccesses,
    ComponentEnum.AssetsManagementCategoriesScope,
  );

  const fields = getUserAccessesFormFields({
    personalInterventionCategoriesIds: personalAccessesValues[
      ComponentEnum.InterventionsManagementCategoriesScope
    ]?.itemIds
      ? personalInterventionCategoriesIds
      : teamInterventionCategoriesIds,
    teamInterventionCategoriesIds,
    personalAssetCategoriesIds: personalAccessesValues[
      ComponentEnum.AssetsManagementCategoriesScope
    ]?.itemIds
      ? personalAssetCategoriesIds
      : teamAssetCategoriesIds,
    teamAssetCategoriesIds,
    getAssetCategoriesLoadOptions:
      useSearchListQueriesResult.getAssetCategoriesLoadOptions,
    getInterventionCategoriesLoadOptions:
      useSearchListQueriesResult.getInterventionCategoriesLoadOptions,
  });

  const actionsComponent = () => (
    <ActionsRow
      className={formStyles.formActions}
      actions={[
        <Button
          onClick={() => onCancel()}
          buttonType={ButtonTypes.secondaryOutlined}
          buttonText={t('cancel')}
          leftIcon="close"
          fullWidth
        />,
        <Button
          buttonType={ButtonTypes.primaryFilled}
          buttonText={t('save-changes')}
          leftIcon="check"
          type="submit"
          fullWidth
        />,
      ]}
    />
  );

  return (
    <Form
      actionsComponent={actionsComponent}
      defaultValues={defaultValues}
      fields={fields}
      formId="team"
      formRef={formRef}
      graphQLErrors={graphQLErrors}
      loading={loading}
      onSubmit={onSubmit}
    />
  );
}
