import { useEffect } from 'react';
import { useBreadcrumbContext } from './BreadCrumbsContext';
import { BreadCrumb } from './types';

interface BreadcrumbsProps {
  data: BreadCrumb[];
}

function BreadCrumbs({ data }: BreadcrumbsProps) {
  const { setBreadcrumbs } = useBreadcrumbContext();

  useEffect(() => {
    setBreadcrumbs(data);
  }, [data, setBreadcrumbs]);

  return null;
}

export default BreadCrumbs;
