import { User, UsersQueryVariables, useUsersQuery } from 'graphql-common';
import _get from 'lodash/get';
import { GetUrlParams } from '@lib/enums/urls';
import { ListQueryHookProps } from './types';

export default function useSiteMembershipsQueryHook({
  paginationParams,
  siteId,
}: ListQueryHookProps & { siteId: string }) {
  const page = _get(paginationParams, GetUrlParams.Page);
  const perPage = _get(paginationParams, GetUrlParams.PerPage);
  const orderingField = _get(paginationParams, GetUrlParams.OrderingField);
  const orderingDirection = _get(
    paginationParams,
    GetUrlParams.OrderingDirection,
  );
  const usersQueryVariables: UsersQueryVariables = {
    scope: {
      custom: {
        byCore: {
          siteId,
          onlyActive: true,
        },
      },
    },
    page: Number(page),
    limit: Number(perPage),
    includeTeamData: true,
  };
  if (orderingField && orderingDirection) {
    usersQueryVariables.sorts = {
      [orderingField]: orderingDirection,
    };
  }
  const usersQuery = useUsersQuery({
    fetchPolicy: 'cache-and-network',
    variables: usersQueryVariables,
  });
  const {
    loading: siteUsersLoading,
    data,
    previousData,
    refetch: refetchUsers,
  } = usersQuery;
  const queryData = data || previousData;
  const collection = _get(queryData, 'data.collection', []) as User[];
  const metadata = _get(queryData, 'data.metadata');
  const totalCount = _get(metadata, 'totalCount', 0);

  return {
    collection,
    previousData,
    refetchUsers,
    siteUsersLoading,
    totalCount,
  };
}
