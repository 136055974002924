import React, { memo } from 'react';
import classNames from 'classnames';
import _get from 'lodash/get';
import { FormTemplateQuestionField } from 'graphql-common';
import QuestionFieldPreviewByKind from 'components/FormTemplateBuilder/QuestionFieldPreviewByKind/QuestionFieldPreviewByKind';
import { kindFieldsWithoutPreviewInEditMode } from 'components/FormTemplateBuilder/constants';
import { ExtendedQuestion } from 'components/FormTemplateBuilder/types';
import FormTemplateFieldNames from '@lib/enums/fieldNames/formTemplateFieldNames';
import styles from './QuestionFieldsPreview.module.scss';

interface Props {
  fields: FormTemplateQuestionField[];
  isEditView?: boolean;
  value: ExtendedQuestion;
}

function QuestionFieldsPreview(props: Props) {
  const { fields, isEditView, value } = props;
  return fields?.map(({ kind, options, settings, ...rest }) => {
    return !(
      isEditView && kindFieldsWithoutPreviewInEditMode.includes(kind)
    ) ? (
      <div
        key={kind}
        className={classNames({ [styles.fieldPreviewItem]: isEditView })}
      >
        <QuestionFieldPreviewByKind
          kind={kind}
          options={options}
          settings={
            settings || {
              [FormTemplateFieldNames.AllowDisplayLastAnswer]: _get(
                rest,
                FormTemplateFieldNames.AllowDisplayLastAnswer,
              ),
              [FormTemplateFieldNames.LimitToOneRespPerRow]: _get(
                rest,
                FormTemplateFieldNames.LimitToOneRespPerRow,
              ),
              [FormTemplateFieldNames.LimitToOneRespPerColumn]: _get(
                rest,
                FormTemplateFieldNames.LimitToOneRespPerColumn,
              ),
            }
          }
          required={!!value?.required}
        />
      </div>
    ) : null;
  });
}

export default memo(QuestionFieldsPreview);
