import React from 'react';
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import Checkbox from '@lib/components/CheckBox/CheckBox';
import { UseBulkSelectResult } from '@lib/hooks/useTableItemsBulkSelect';
import { BaseItem, TableProps } from '../../types';
import { isItemsMatch } from '../../utils';
import RowActionsMenuCell from './RowActionsMenuCell/RowActionsMenuCell';
import RowActionsCell from './RowActionsCell/RowActionsCell';
import TableBodyCell from './TableBodyCell';
import styles from './TableBody.module.scss';

type Props<T extends BaseItem> = {
  hasActionsColumn: boolean;
  hasActionsMenuColumn: boolean;
  hasSelectColumn: boolean;
  rowItem: T;
};

export default function TableBodyRow<T extends BaseItem>(
  props: Props<T> & TableProps<T>,
) {
  const {
    actions,
    actionsMenu,
    columns,
    getRowClassName,
    hasActionsColumn,
    hasActionsMenuColumn,
    hasSelectColumn,
    getIsRowDisabled,
    getIsRowSelected = () => false,
    onRowClick,
    rowItem,
    useBulkSelectResult = {} as UseBulkSelectResult<T>,
  } = props;
  const {
    isAllTableItemsSelected,
    selectedTableItems,
    handleSelectTableItem,
    handleUnselectTableItem,
    unselectedTableItems,
  } = useBulkSelectResult;
  const isDisabled = getIsRowDisabled && getIsRowDisabled(rowItem);
  const isSelectedByBulkSelect =
    (isAllTableItemsSelected &&
      !unselectedTableItems?.some((selectedItem) =>
        isItemsMatch(selectedItem, rowItem),
      )) ||
    selectedTableItems?.some((selectedItem) =>
      isItemsMatch(selectedItem, rowItem),
    );
  const handleRowCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.checked) {
      handleSelectTableItem(rowItem);
    } else {
      handleUnselectTableItem(rowItem);
    }
  };

  return (
    <tr
      data-key={rowItem.id}
      className={classNames(
        {
          // [styles.disabled]: isDisabled,
          [styles.trSelected]:
            isSelectedByBulkSelect || getIsRowSelected(rowItem),
          [styles.trClickable]: onRowClick,
        },
        getRowClassName && getRowClassName(rowItem),
      )}
      onClick={onRowClick ? () => onRowClick(rowItem) : undefined}
    >
      {hasSelectColumn && (
        <td className={styles.selectCell}>
          <div className={styles.tdContent}>
            <Checkbox
              id={`table-body-checkbox-${rowItem.id}`}
              name={`table-body-checkbox-${rowItem.id}`}
              checked={isSelectedByBulkSelect}
              onChange={handleRowCheckboxChange}
            />
          </div>
        </td>
      )}
      {columns.map((column) => (
        <TableBodyCell
          key={uuidv4()}
          column={column}
          rowItem={rowItem}
          isDisabled={isDisabled}
          tableProps={props}
        />
      ))}
      {hasActionsColumn && <RowActionsCell actions={actions} item={rowItem} />}
      {hasActionsMenuColumn && (
        <RowActionsMenuCell actionsMenu={actionsMenu} item={rowItem} />
      )}
    </tr>
  );
}
