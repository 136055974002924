import React from 'react';
import { useTranslation } from 'react-i18next';
import Button, { ButtonTypes } from '@lib/components/Button/Button';
import ActionsRow from '@lib/components/ActionsRow/ActionsRow';
import { materialSymbolsOutlined } from '@lib/enums/common';

interface Props {
  loading: boolean;
  onCancel: () => void;
}

function ScheduleTaskActions(props: Props) {
  const { loading, onCancel } = props;
  const { t } = useTranslation();
  return (
    <ActionsRow
      actions={[
        <Button
          key="cancel"
          buttonType={ButtonTypes.secondaryOutlined}
          buttonText={t('cancel')}
          leftIcon="close"
          onClick={onCancel}
          fullWidth
          disabled={loading}
        />,
        <Button
          key="submit"
          type="submit"
          buttonType={ButtonTypes.primaryFilled}
          buttonText={t('save')}
          leftIcon="check"
          leftIconClassName={materialSymbolsOutlined}
          fullWidth
          isLoading={loading}
        />,
      ]}
    />
  );
}

export default ScheduleTaskActions;
