import React, { useEffect, useState } from 'react';
import { NavLink, Outlet, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import _get from 'lodash/get';
import { useLocalStorage } from 'usehooks-ts';
import { SystemAdmin, User } from 'graphql-common';
import Menu, { NavItem } from '@lib/components/Menu/Menu';
import ErrorBoundary from '@lib/components/ErrorBoundary/ErrorBoundary';
import BreadCrumbsDisplay from '@lib/components/BreadCrumbs/BreadCrumbsDisplay';
import IconButton, {
  IconButtonSizes,
  IconButtonTypes,
} from '@lib/components/IconButton/IconButton';
import AccountPreview from '@lib/components/AccountPreview/AccountPreview';
import {
  dashboardModalId,
  downloadLinkId,
  emptyHiddenInputId,
} from '@lib/enums/selectors';
import ScrollToTop from '@lib/components/ScrollToTop/ScrollToTop';
import isMobile from '@lib/utils/isMobile';
import ErrorPage from '@lib/components/ErrorPage/ErrorPage';
import { ErrorPageStatusEnum } from '@lib/components/ErrorPage/enums';
import useWindowDimensions from '@lib/hooks/useWindowDimensions';
import ReleaseTracker from '@lib/components/ReleaseTreacker/ReleaseTracker';
import Tooltip from '@lib/components/Tooltip/Tooltip';
import Logo from '@lib/components/Logo/Logo';
import LogoImage from '@lib/assets/images/logo-image.svg?react';
import { DASHBOARD_LAYOUT_IS_COLLAPSED } from '@lib/enums/localStorageKeys';
import UpgradePlanModal from '@lib/components/UpgradePlanModal/UpgradePlanModal';
import { PlanState } from '@lib/enums/plan';
import styles from './DashboardLayout.module.scss';

const {
  VITE_PLATFORM_URL: domain,
  VITE_RELEASE_VERSION: releaseVersion,
  VITE_RELEASE_TRACKER_SECONDS: releaseTrackerSeconds,
  MODE: mode,
} = import.meta.env;

interface Props {
  captureException: (error: Error, response?: unknown) => void;
  logoUrl: string;
  navItems: NavItem<undefined>[];
  onLogoutHandler: () => void;
  profileMenuItems: NavItem<undefined>[];
  sideBarClassName?: string;
  tools?: React.ReactNode;
  outletContext: object;
  user: User | SystemAdmin;
}

function DashboardLayout(props: Props): React.ReactElement {
  const { t } = useTranslation();
  const [isBlockedUI, setBlockedUI] = useState(false);
  const { phone, tablet } = isMobile();
  const {
    captureException,
    logoUrl,
    navItems,
    onLogoutHandler,
    profileMenuItems,
    sideBarClassName,
    tools,
    user,
    outletContext,
  } = props;
  const companyId = _get(user, 'company.id', '') as string;
  const location = useLocation();
  const { width } = useWindowDimensions();
  const [isCollapsed, setCollapsed] = useLocalStorage(
    DASHBOARD_LAYOUT_IS_COLLAPSED,
    false,
  );
  const [planModalType, setPlanModalType] = useState<PlanState | undefined>();

  const handleExpanded = () => {
    setCollapsed((prevState: boolean) => !prevState);
  };

  const handleClosePlanModal = () => setPlanModalType(undefined);
  const handleOpenPlanModal = (state: PlanState) => setPlanModalType(state);

  const contextProps = {
    ...outletContext,
    handleOpenPlanModal,
    onLogoutHandler,
    user,
  };

  useEffect(() => {
    setBlockedUI(width <= 1024 || phone || tablet);
  }, [phone, tablet, width]);

  if (isBlockedUI) {
    return (
      <ErrorPage
        status={ErrorPageStatusEnum.DesktopOnly}
        logoUrl={logoUrl}
        useSwitcher
      />
    );
  }

  return (
    <>
      <ScrollToTop />
      <ReleaseTracker
        captureException={captureException}
        domain={domain}
        releaseVersion={releaseVersion}
        releaseTrackerSeconds={releaseTrackerSeconds}
        mode={mode}
      />
      <UpgradePlanModal
        companyId={companyId}
        isOpen={!!planModalType}
        planState={planModalType}
        onClose={handleClosePlanModal}
      />
      <div
        className={classNames(styles.root, {
          [styles.rootCollapsed]: isCollapsed,
        })}
      >
        <div
          className={classNames(
            styles.sideBar,
            { [styles.sideBarCollapsed]: isCollapsed },
            sideBarClassName,
          )}
        >
          <div className={styles.toggleBtn}>
            <Tooltip
              body={t(isCollapsed ? 'expand' : 'collapse')}
              key={isCollapsed ? 'expand' : 'collapse'}
              placement="right"
            >
              <IconButton
                icon={isCollapsed ? 'chevron_right' : 'chevron_left'}
                onClick={handleExpanded}
                rounded
                type={IconButtonTypes.SecondaryOutlined}
                size={IconButtonSizes.Small}
              />
            </Tooltip>
          </div>
          <div className={styles.sideBarTopBlock}>
            <NavLink
              to={logoUrl}
              className={
                isCollapsed ? styles.sideBarLogoSmall : styles.sideBarLogo
              }
            >
              {isCollapsed ? <LogoImage /> : <Logo />}
            </NavLink>
          </div>
          <Menu
            navItems={navItems}
            isCollapsed={isCollapsed}
            itemWrapClassName={styles.menuItemWrapClassName}
          />
        </div>
        <div className={styles.content}>
          <div className={styles.header}>
            <BreadCrumbsDisplay />
            <div className={styles.tools}>
              {tools}
              {/* <IconButton icon="help" symbolsOutlined /> */}
              <AccountPreview user={user} menuItems={profileMenuItems} />
            </div>
          </div>
          <div className={styles.body}>
            <ErrorBoundary
              location={location}
              captureException={captureException}
            >
              <Outlet context={contextProps} />
            </ErrorBoundary>
          </div>
        </div>
        <div
          style={{
            position: 'fixed',
            left: '-100vw',
            top: '50%',
            zIndex: 9999,
          }}
        >
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a id={downloadLinkId} download>
            Download link
          </a>
          <input type="text" id={emptyHiddenInputId} />
        </div>
      </div>
      <div id={dashboardModalId} />
    </>
  );
}

export default DashboardLayout;
