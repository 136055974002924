import React, { memo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import _get from 'lodash/get';
import _replace from 'lodash/replace';
import { GroupEnum } from 'graphql-common';
import Typography from '@lib/components/Typography/Typography';
import MaterialIcon from '@lib/components/MaterialIcon/MaterialIcon';
import Tooltip from '@lib/components/Tooltip/Tooltip';
import {
  FieldArrayItemRenderAddMore,
  FieldArrayItemRenderEmptyState,
  FieldArrayItemType,
} from '@lib/components/ReactHookForm/types';
import FormTemplateFieldNames from '@lib/enums/fieldNames/formTemplateFieldNames';
import styles from '../QuestionField/QuestionField.module.scss';

interface ChildrenProps {
  fieldItem: FieldArrayItemType;
  index: number;
}

interface Props {
  children: (props: ChildrenProps) => React.ReactNode;
  fields: FieldArrayItemType[];
  parentName: string;
  renderAddMore: FieldArrayItemRenderAddMore;
  renderEmptyState: FieldArrayItemRenderEmptyState;
  renderSettings?: React.ReactNode;
}

function QuestionMinMaxField(props: Props) {
  const {
    children,
    fields,
    parentName,
    renderAddMore,
    renderSettings,
    renderEmptyState,
  } = props;
  const { t } = useTranslation();
  const { getValues } = useFormContext();
  const values = getValues();
  const { options } = _get(
    values,
    _replace(parentName, `.${FormTemplateFieldNames.Options}`, ''),
    {},
  );

  const isOptionVisible = (index: number, group: GroupEnum) =>
    options?.[index]?.group === group;

  return (
    <div>
      <div>
        <div className={styles.titleWrap}>
          <Typography variant="body">
            {t('minimum-value-response-validation')}
          </Typography>
          <Tooltip body={t('response-validation-tooltip')} maxWidth={160}>
            <MaterialIcon
              icon="info"
              symbolsOutlined
              className={styles.infoIcon}
            />
          </Tooltip>
        </div>
        {renderEmptyState({ group: GroupEnum.Min })}
        {fields.map((fieldItem, index) =>
          isOptionVisible(index, GroupEnum.Min)
            ? children({ fieldItem, index })
            : null,
        )}
        {renderAddMore({ group: GroupEnum.Min })}
      </div>
      <div>
        <div className={styles.titleWrap}>
          <Typography variant="body">
            {t('maximum-value-response-validation')}
          </Typography>
          <Tooltip body={t('response-validation-tooltip')} maxWidth={160}>
            <MaterialIcon
              icon="info"
              symbolsOutlined
              className={styles.infoIcon}
            />
          </Tooltip>
        </div>
        {renderEmptyState({ group: GroupEnum.Max })}
        {fields.map((fieldItem, index) =>
          isOptionVisible(index, GroupEnum.Max)
            ? children({ fieldItem, index })
            : null,
        )}
        {renderAddMore({ group: GroupEnum.Max })}
        {renderSettings}
      </div>
    </div>
  );
}

export default memo(QuestionMinMaxField);
