import React from 'react';
import { useTranslation } from 'react-i18next';
import _get from 'lodash/get';
import { LazyQueryExecFunction } from '@apollo/client';
import * as Yup from 'yup';
import { SiteAreasSearchListQuery } from 'graphql-common';
import { getOptionShape } from '@lib/utils/yup';
import SiteAndAreaOptionsAddItemComponent from 'components/DashboardInfoFilter/SiteAndAreaOptionsAddItemComponent';
import { ButtonSizes } from '@lib/components/Button/Button';
import SiteAndAreaFieldOption from 'components/DashboardInfoFilter/SiteAndAreaFieldOption';
import Select, { SelectProps } from '@lib/components/Select/Select';
import {
  FieldChangeCallBackArgs,
  FieldItem,
  Values,
} from '@lib/interfaces/form';
import { getSiteAreasLoadOptions } from 'utils/fetch/siteAreasSearchList';
import formStyles from '@lib/assets/styles/Form.module.scss';
import TasksFilterFieldNames from '@lib/enums/fieldNames/tasksFilterFieldNames';

type UseFieldsProps = {
  sitesLoadOptions: SelectProps['loadOptions'];
  fetchSiteAreasSearchListLazyQuery: LazyQueryExecFunction<
    SiteAreasSearchListQuery,
    any
  >;
  handleClose?: () => void;
};

export default function useFilterFields({
  sitesLoadOptions,
  fetchSiteAreasSearchListLazyQuery,
  handleClose,
}: UseFieldsProps): FieldItem[] {
  const { t } = useTranslation();
  return [
    {
      name: TasksFilterFieldNames.Filter,
      label: t('site-comma-area'),
      subFieldsEntity: 'site',
      fieldComponents: {
        FieldArrayAddItemComponent: (props) => (
          <SiteAndAreaOptionsAddItemComponent
            {...props}
            onCancel={handleClose}
            buttonSize={ButtonSizes.small}
          />
        ),
        FieldArrayItemWrapperComponent: SiteAndAreaFieldOption,
      },
      subFieldsMinLength: 1,
      subFieldsMaxLength: 5,
      subFields: [
        {
          name: TasksFilterFieldNames.Site,
          label: t('site'),
          element: Select,
          validation: Yup.object()
            .shape(getOptionShape(t, true))
            .required(t('required-field-error')),
          componentProps: {
            isAsync: true,
            loadOptions: sitesLoadOptions,
            defaultOptions: true,
          },
          fieldChangeCallBack: ({
            type,
            name,
            setValue,
          }: FieldChangeCallBackArgs) => {
            const nameArray = name?.split('.');
            const fieldName =
              name && nameArray ? nameArray[nameArray.length - 1] : name;
            if (
              type === 'change' &&
              name &&
              fieldName === TasksFilterFieldNames.Site
            ) {
              const siteAreaIdsName = name.replace(
                TasksFilterFieldNames.Site,
                TasksFilterFieldNames.SiteAreaIds,
              );
              setValue(siteAreaIdsName, []);
            }
          },
          hideErrorsSpace: true,
          formItemClassName: formStyles.formColumn,
        },
        {
          name: TasksFilterFieldNames.SiteAreaIds,
          label: t('areas-label'),
          element: Select,
          validation: Yup.array().of(Yup.object().shape(getOptionShape(t))),
          componentProps: {
            isAsync: true,
            isMulti: true,
            getLoadOptions: (name, values) => {
              const siteFieldName = name.replace(
                TasksFilterFieldNames.SiteAreaIds,
                `${TasksFilterFieldNames.Site}.value`,
              );
              const siteId = _get(values, siteFieldName, '') as string;
              return getSiteAreasLoadOptions(
                fetchSiteAreasSearchListLazyQuery,
                siteId,
              );
            },
            defaultOptions: true,
            disabledTooltipProps: {
              body: t('choose-site-first'),
            },
          },
          isDisabledFn: (values: Values, name?: string) => {
            const siteOptionName = name?.replace(
              TasksFilterFieldNames.SiteAreaIds,
              `${TasksFilterFieldNames.Site}.value`,
            );
            if (!siteOptionName) return false;
            const siteOption = _get(values, siteOptionName);
            return !siteOption;
          },
          getFieldKeyFn: (values: Values, name?: string) => {
            if (!name) return TasksFilterFieldNames.SiteAreaIds;
            const siteFieldName = name.replace(
              TasksFilterFieldNames.SiteAreaIds,
              `${TasksFilterFieldNames.Site}.value`,
            );
            const siteId = _get(values, siteFieldName) as string;
            if (siteId) return `${TasksFilterFieldNames.Site}-${siteId}`;
            return TasksFilterFieldNames.SiteAreaIds;
          },
          hideErrorsSpace: true,
          formItemClassName: formStyles.formColumn,
        },
      ],
    },
  ];
}
