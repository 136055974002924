import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ApolloError } from '@apollo/client/errors';
import {
  Team,
  TeamCreateInputObject,
  TeamUpdateInputObject,
  User,
} from 'graphql-common';
import { Values } from '@lib/interfaces/form';
import toast from '@lib/components/Toast/Toast';
import { BreadCrumb } from '@lib/components/BreadCrumbs/types';
import FieldNames from '@lib/enums/fieldNames/teamFieldNames';
import { UseModalManagementResult } from '@lib/hooks/useModalManagement';
import { APP_URLS } from 'constants/urls';
import { ListQueryHookResult, SingleQueryHookResult } from 'utils/fetch/types';
import { getBreadCrumbsDataByKey } from 'utils/breadcrumbs';
import { DEFAULT_LIST_PARAMS } from '@lib/enums/urls';
import getTeamPreparedDataFromValues from './getTeamPreparedDataFromValues';
import useMutations from './useMutations';
import { TeamViewMode } from '../enums';
import getTeamDefaultValuesFromQueryData from './getTeamDefaultValuesFromQueryData';

type Args = {
  useModalManagementResult: UseModalManagementResult;
  useUsersQueryHookResult: ListQueryHookResult<User>;
  viewMode: TeamViewMode;
  useTeamQueryHookResult: SingleQueryHookResult<Team>;
};

export type UseTeamActionsResult = {
  breadCrumbsData: BreadCrumb[];
  defaultValues: Values;
  id: string;
  isCreatePage: boolean;
  loading: boolean;
  mutationError: ApolloError | undefined;
  onConfirmedTeamDestroy: () => void;
  onSubmit: (values: Values) => void;
  onViewChange: (newViewMode: TeamViewMode) => void;
};

export default function useTeamActions({
  viewMode,
  useModalManagementResult,
  useUsersQueryHookResult,
  useTeamQueryHookResult,
}: Args): UseTeamActionsResult {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { id = '' } = useParams();
  const { t } = useTranslation();
  const useMutationsResult = useMutations();
  const isCreatePage = viewMode === TeamViewMode.Add;

  // Users
  const { allIds: membersIds, refetchAllIds } = useUsersQueryHookResult;

  const defaultValues = isCreatePage
    ? getTeamDefaultValuesFromQueryData(undefined, [])
    : getTeamDefaultValuesFromQueryData(
        useTeamQueryHookResult.data,
        membersIds,
      );

  // Actions
  const onTeamCreate = (values: TeamCreateInputObject) => {
    useMutationsResult.teamCreateMutation({
      variables: {
        attributes: values,
      },
      onCompleted: (res) => {
        const newId = res?.data?.id;
        toast({ content: t('create-team-success') });
        if (newId) {
          navigate(
            APP_URLS.dashboard.teams.view.getDynamicPath({
              pathParts: { id: newId },
            }),
          );
        }
      },
    });
  };

  const onTeamUpdate = (values: TeamUpdateInputObject) => {
    useMutationsResult.teamUpdateMutation({
      variables: {
        id,
        attributes: values,
      },
      onCompleted: () => {
        toast({ content: t('update-team-success') });
        refetchAllIds();
        navigate(
          APP_URLS.dashboard.teams.view.getDynamicPath({
            pathParts: { id },
          }),
        );
      },
    });
  };

  const onSubmit = (values: Values) => {
    if (viewMode === TeamViewMode.Add) {
      onTeamCreate(getTeamPreparedDataFromValues(values, defaultValues));
    } else if (viewMode === TeamViewMode.Edit) {
      onTeamUpdate(
        getTeamPreparedDataFromValues(values, {
          ...defaultValues,
          [FieldNames.Users]: membersIds.map(({ id: assigneeId }) => ({
            value: assigneeId,
          })),
        } as Values),
      );
    }
  };

  const onConfirmedTeamDestroy = () => {
    useModalManagementResult.closeAllModals();
    toast({
      content: t('destroy-team-success'),
      closeCallback: () => {
        useMutationsResult.teamDestroyMutation({
          variables: { id },
          onCompleted: () => {
            navigate(APP_URLS.dashboard.teams.index.getPathWithQuery());
          },
        });
      },
    });
  };

  const onViewChange = (newViewMode: TeamViewMode) => {
    if (newViewMode === TeamViewMode.ViewDetails) {
      navigate(
        APP_URLS.dashboard.teams.view.getDynamicPath({
          pathParts: { id },
        }),
      );
    } else if (newViewMode === TeamViewMode.ViewMembers) {
      navigate(
        APP_URLS.dashboard.teams.members.getDynamicPath({
          pathParts: { id },
          params: { ...DEFAULT_LIST_PARAMS },
        }),
      );
    } else if (newViewMode === TeamViewMode.Edit) {
      navigate(
        APP_URLS.dashboard.teams.edit.getDynamicPath({
          pathParts: { id },
        }),
      );
    }
  };

  const loading =
    useTeamQueryHookResult.loading ||
    useMutationsResult.teamCreateMutationResult.loading ||
    useMutationsResult.teamUpdateMutationResult.loading;

  const mutationError =
    useMutationsResult.teamCreateMutationResult.error ||
    useMutationsResult.teamUpdateMutationResult.error;

  const breadCrumbsData = getBreadCrumbsDataByKey({
    pathname,
    t,
    entity: useTeamQueryHookResult.data || { id },
  });

  return {
    breadCrumbsData,
    defaultValues,
    id,
    isCreatePage,
    loading,
    mutationError,
    onConfirmedTeamDestroy,
    onSubmit,
    onViewChange,
  };
}
