import React from 'react';
import { Site } from 'graphql-common';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Typography from '@lib/components/Typography/Typography';
import MaterialIcon from '@lib/components/MaterialIcon/MaterialIcon';
import Button, {
  ButtonSizes,
  ButtonTypes,
} from '@lib/components/Button/Button';
import Tooltip from '@lib/components/Tooltip/Tooltip';
import Image from '@lib/components/Image/Image';
import Placeholder from 'assets/images/site-preview-placeholder.svg?react';
import { APP_URLS } from 'constants/urls';
import styles from './SitePreview.module.scss';

function SitePreview(props: Site) {
  const { t } = useTranslation();
  const {
    id,
    name,
    code,
    locationPhotoAttached,
    siteAreasCount,
    usersCount,
    user,
  } = props;
  const locationPhotoUrl =
    locationPhotoAttached?.urls?.medium ||
    locationPhotoAttached?.urls?.original;
  const viewLink = APP_URLS.dashboard.sites.view.getDynamicPath({
    pathParts: {
      id: `${id}`,
    },
  });
  return (
    <div className={styles.root}>
      <NavLink to={viewLink} className={styles.image}>
        {locationPhotoUrl && (
          <span className={styles.imageWrap}>
            <Image src={locationPhotoUrl} alt={name} />
          </span>
        )}
        <Placeholder />
      </NavLink>
      <div className={styles.content}>
        <Typography variant="caption" className={styles.code}>
          {code || t('no-code')}
        </Typography>
        <Typography variant="h3" className={styles.title}>
          <NavLink to={viewLink}>{name}</NavLink>
        </Typography>
      </div>
      <div className={styles.properties}>
        <div className={styles.property}>
          <MaterialIcon icon="location_on" size="md-16" symbolsOutlined />
          <Typography variant="caption">
            {siteAreasCount} {t('areas')}
          </Typography>
        </div>
        <div className={styles.propertiesSeparator} />
        <div className={styles.property}>
          <MaterialIcon icon="person" size="md-16" symbolsOutlined />
          <Typography variant="caption">
            {usersCount} {t('employees').toLowerCase()}
          </Typography>
        </div>
      </div>
      <div className={styles.footer}>
        <Button
          buttonText={t('view-details')}
          buttonType={ButtonTypes.primaryOutlined}
          buttonSize={ButtonSizes.small}
          leftIcon="visibility"
          to={viewLink}
        />
        {user === null && (
          <Tooltip body={t('responsible-person-lost')}>
            <MaterialIcon
              icon="warning"
              size="md-18"
              className={styles.warningIcon}
            />
          </Tooltip>
        )}
      </div>
    </div>
  );
}

export default SitePreview;
