import React from 'react';
import Menu, { MenuProps } from '@lib/components/Menu/Menu';
import Popover, { PopoverProps } from '@lib/components/Popover/Popover';
import Button, { ButtonProps } from '@lib/components/Button/Button';
import styles from './ButtonWithDropDown.module.scss';

type Props<T> = {
  buttonProps?: ButtonProps;
  popoverProps?: Omit<PopoverProps, 'children' | 'body'>;
  className?: string;
  navItems: MenuProps<T>['navItems'];
};

export default function ButtonWithDropDown<T>(props: Props<T>) {
  const { buttonProps, popoverProps, className, navItems } = props;
  const popoverBody = () => (
    <div className={styles.popoverBody}>
      <Menu navItems={navItems} itemClassName={styles.menuItemClassName} />
    </div>
  );
  return (
    <div className={className}>
      <Popover {...popoverProps} body={popoverBody}>
        {({ handleToggle, isOpen }) => (
          <div>
            <Button
              {...buttonProps}
              onClick={() => handleToggle()}
              isFocused={isOpen}
              rightIcon={isOpen ? 'arrow_drop_up' : 'arrow_drop_down'}
            />
          </div>
        )}
      </Popover>
    </div>
  );
}
