import React from 'react';
import { TFunction } from 'i18next';
import { Asset } from 'graphql-common';
import TextCell from '@lib/components/Table/commonComponents/TextCell/TextCell';
import { CellProps } from '@lib/components/Table/types';
import AssetPreviewCell from '@lib/components/Table/commonComponents/AssetPreviewCell/AssetPreviewCell';
import { APP_URLS } from 'constants/urls';

type ColumnsArgs = {
  t: TFunction<'translation', undefined>;
};

export default function getAssetsColumns({ t }: ColumnsArgs) {
  return [
    {
      id: 'code',
      Header: t('code'),
      accessor: 'code',
      Cell: (props: CellProps<Asset>) => <TextCell {...props} />,
      minWidth: 110,
      maxWidth: 110,
      width: 110,
      sortable: true,
    },
    {
      id: 'name',
      Header: t('name'),
      accessor: 'name',
      Cell: ({ item }: CellProps<Asset>) => (
        <AssetPreviewCell
          icon={
            item.iconAttached?.urls?.small ||
            item.iconAttached?.urls?.original ||
            item.iconAttached?.fileTag
          }
          name={item.name}
          url={APP_URLS.dashboard.assets.view.getDynamicPath({
            pathParts: {
              id: `${item.id}`,
            },
          })}
        />
      ),
      maxWidth: 270,
      sortable: true,
    },
  ];
}
