import React, { memo, useEffect } from 'react';
import { FormTemplateQuestion } from 'graphql-common';
import classNames from 'classnames';
import _get from 'lodash/get';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import Transition from 'react-transition-group/Transition';
import { useFormContext } from 'react-hook-form';
import WhiteBox from '@lib/components/WhiteBox/WhiteBox';
import DragDrop from '@lib/assets/icons/dragDrop.svg?react';
import QuestionTypeChip from 'components/FormTemplateBuilder/QuestionTypeChip/QuestionTypeChip';
import FormTemplateFieldNames from '@lib/enums/fieldNames/formTemplateFieldNames';
import QuestionFieldsPreview from '../QuestionFieldsPreview/QuestionFieldsPreview';
import FormTemplateBuilderQuestionEdit from '../FormTemplateBuilderQuestionEdit/FormTemplateBuilderQuestionEdit';
import FormTemplateBuilderQuestionView from '../FormTemplateBuilderQuestionView/FormTemplateBuilderQuestionView';
import styles from '../FormTemplateBuilderForm/FormTemplateBuilderForm.module.scss';

const duration = 150;

const defaultStyle = {
  transition: `${duration}ms ease-in-out`,
  opacity: 0,
};

const transitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

interface Props {
  children: React.ReactNode;
  dragHandleProps?: DraggableProvidedDragHandleProps | null;
  errorsCount?: number;
  isDraggable: boolean;
  isFocused?: boolean;
  onClick?: () => void;
  onDuplicate: () => void;
  onRemove: () => void;
  parentIndex: number;
  parentName: string;
  value: FormTemplateQuestion;
}

function FormTemplateBuilderQuestion(props: Props) {
  const {
    children,
    dragHandleProps,
    errorsCount,
    isDraggable,
    isFocused,
    onClick,
    onDuplicate,
    onRemove,
    parentIndex,
    parentName,
    value,
  } = props;
  const fields = _get(value, FormTemplateFieldNames.Fields, []);
  const showAttachments = _get(
    value,
    FormTemplateFieldNames.ShowAttachments,
    false,
  );
  const attachmentsAttached = _get(
    value,
    FormTemplateFieldNames.AttachmentsAttached,
    [],
  );

  const { setValue } = useFormContext();

  useEffect(() => {
    if (!isFocused && showAttachments && attachmentsAttached?.length === 0) {
      setValue(
        `${parentName}.${parentIndex}.${FormTemplateFieldNames.ShowAttachments}`,
        false,
      );
    }
  }, [
    attachmentsAttached,
    isFocused,
    parentIndex,
    parentName,
    setValue,
    showAttachments,
  ]);

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div onClick={onClick}>
      <WhiteBox
        className={classNames(styles.questionFormWrap, {
          [styles.questionFormWrapFocused]: isFocused,
          [styles.questionFormWrapWithError]: errorsCount,
        })}
        verticalPaddings="custom"
      >
        {isDraggable && (
          <div {...dragHandleProps} className={styles.questionDragDrop}>
            <DragDrop />
          </div>
        )}
        <QuestionTypeChip
          kind={_get(fields, [0, FormTemplateFieldNames.Kind])}
        />
        {isFocused ? (
          <Transition in={isFocused} timeout={duration} unmountOnExit>
            {(transitionState) => (
              <div
                style={{
                  ...defaultStyle,
                  ...transitionStyles[transitionState],
                }}
              >
                <FormTemplateBuilderQuestionEdit
                  onDuplicate={onDuplicate}
                  onRemove={onRemove}
                  parentIndex={parentIndex}
                  parentName={parentName}
                  value={value}
                >
                  {children}
                  <QuestionFieldsPreview
                    fields={fields}
                    isEditView={isFocused}
                    value={value}
                  />
                </FormTemplateBuilderQuestionEdit>
              </div>
            )}
          </Transition>
        ) : (
          <Transition in={!isFocused} timeout={duration} unmountOnExit>
            {(transitionState) => (
              <div
                style={{
                  ...defaultStyle,
                  ...transitionStyles[transitionState],
                }}
              >
                <FormTemplateBuilderQuestionView value={value}>
                  <QuestionFieldsPreview fields={fields} value={value} />
                </FormTemplateBuilderQuestionView>
              </div>
            )}
          </Transition>
        )}
      </WhiteBox>
    </div>
  );
}

export default memo(FormTemplateBuilderQuestion);
