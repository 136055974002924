import React, { memo } from 'react';
import _get from 'lodash/get';
import _noop from 'lodash/noop';
import { Values } from '@lib/interfaces/form';
import MaterialIcon from '@lib/components/MaterialIcon/MaterialIcon';
import {
  ConditionEnum,
  FormTemplateQuestionFieldsKindEnum,
} from 'graphql-common';
import Typography from '@lib/components/Typography/Typography';
import Checkbox from '@lib/components/CheckBox/CheckBox';
import RadioButton from '@lib/components/RadioButton/RadioButton';
import {
  conditionIcons,
  kindFieldsWithCheckboxPreview,
  kindFieldsWithCondition,
  kindFieldsWithRadioButtonPreview,
} from '../constants';
import styles from './QuestionFieldOption.module.scss';

interface Props {
  kind: FormTemplateQuestionFieldsKindEnum;
  parentIndex: number;
  parentValue: Values[];
}

function QuestionFieldOptionPreviewIcon(props: Props) {
  const { kind, parentIndex, parentValue } = props;
  const isConditionVisible = kindFieldsWithCondition.includes(kind);
  const conditionValue = _get(
    parentValue,
    [parentIndex, 'condition'],
    '',
  ) as ConditionEnum;
  const conditionIcon = conditionIcons[conditionValue];

  if (kindFieldsWithCheckboxPreview.includes(kind)) {
    const id = `check-box-demo-${kind}-${parentIndex}`;
    return (
      <div className={styles.optionPreviewIcon}>
        <Checkbox onChange={_noop} name={id} checked={false} id={id} disabled />
      </div>
    );
  }

  if (kindFieldsWithRadioButtonPreview.includes(kind)) {
    const id = `check-box-demo-${kind}-${parentIndex}`;
    return (
      <div className={styles.optionPreviewIcon}>
        <RadioButton
          onChange={_noop}
          name={id}
          checked={false}
          id={id}
          disabled
        />
      </div>
    );
  }

  if (kind === FormTemplateQuestionFieldsKindEnum.ImportantNotice) {
    return (
      <div className={styles.optionPreviewIcon}>
        <div className={styles.optionPreviewIconWarning}>
          <MaterialIcon icon="warning" size="md-16" />
        </div>
      </div>
    );
  }

  if (isConditionVisible) {
    return (
      <div className={styles.optionPreviewIcon}>
        <Typography variant="body" strong>
          {conditionIcon}
        </Typography>
      </div>
    );
  }
  return null;
}

export default memo(QuestionFieldOptionPreviewIcon);
