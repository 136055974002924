import { ApolloError, GraphQLErrors } from '@apollo/client/errors';
import _get from 'lodash/get';
import { ErrorPageStatusEnum } from '@lib/components/ErrorPage/enums';

export default function getStatusFromError(
  error: ApolloError,
): ErrorPageStatusEnum {
  const graphQLErrors: GraphQLErrors = _get(error, 'graphQLErrors', []);
  const networkError = _get(error, 'networkError');
  if (
    Array.isArray(graphQLErrors) &&
    graphQLErrors.some(
      ({ extensions }) => extensions?.code === 'NOT_FOUND_ERROR',
    )
  ) {
    return ErrorPageStatusEnum.Status404;
  }
  if (
    networkError &&
    'statusCode' in networkError &&
    networkError.statusCode === 503
  ) {
    return ErrorPageStatusEnum.Status503;
  }
  if (
    networkError instanceof TypeError &&
    networkError.message === 'Failed to fetch'
  ) {
    return ErrorPageStatusEnum.Status503;
  }
  return ErrorPageStatusEnum.Status500;
}
