import _get from 'lodash/get';
import {
  DashboardTasksQueryVariables,
  Task,
  TaskFilterInputObject,
  TaskScopeCustomInputObject,
  TaskScopeNameEnum,
  useDashboardTaskIdsLazyQuery,
  useDashboardTasksQuery,
} from 'graphql-common';
import { GetUrlParams } from '@lib/enums/urls';
import createSearchFilters from '@lib/utils/createSearchFilters';
import getTaskCounterData from '@lib/utils/getTaskCounterData';
import { ListQueryHookProps } from './types';

export default function useDashboardTasksQueryHook({
  customScope,
  isApprovals,
  paginationParams,
  searchQuery,
  skip,
  tabParam,
}: ListQueryHookProps & {
  isApprovals?: boolean;
  customScope?: TaskScopeCustomInputObject;
}) {
  const page = _get(paginationParams, GetUrlParams.Page);
  const perPage = _get(paginationParams, GetUrlParams.PerPage);
  const orderingField = _get(paginationParams, GetUrlParams.OrderingField);
  const orderingDirection = _get(
    paginationParams,
    GetUrlParams.OrderingDirection,
  );
  const scope = isApprovals
    ? TaskScopeNameEnum.MyApprovals
    : (tabParam as TaskScopeNameEnum);
  const tasksQueryVariables: DashboardTasksQueryVariables = {
    page: Number(page),
    limit: Number(perPage),
    scope: { name: scope, custom: customScope },
  };

  // Adjust parameters based on scope
  switch (scope) {
    case TaskScopeNameEnum.Active:
      tasksQueryVariables.includeDeadlineAt = true;
      tasksQueryVariables.includeFormTemplate = true;
      tasksQueryVariables.includeLastCheckAt = true;
      break;
    case TaskScopeNameEnum.Pending:
      tasksQueryVariables.includeDeadlineAt = true;
      tasksQueryVariables.includeFormTemplate = true;
      break;
    case TaskScopeNameEnum.DeadlineMissed:
      tasksQueryVariables.includeDeadlineAt = true;
      tasksQueryVariables.includeDeadlineMissedReason = true;
      tasksQueryVariables.includeLateFor = true;
      break;
    case TaskScopeNameEnum.Completed:
      tasksQueryVariables.includeCompletedAt = true;
      tasksQueryVariables.includeCompletion = true;
      tasksQueryVariables.includeDeadlineMissedReason = true;
      tasksQueryVariables.includeFormTemplate = true;
      tasksQueryVariables.includeLateFor = true;
      break;
    case TaskScopeNameEnum.Archived:
      tasksQueryVariables.includeArchivedAt = true;
      tasksQueryVariables.includeFormTemplate = true;
      break;
    case TaskScopeNameEnum.MyApprovals:
      tasksQueryVariables.includeDeadlineAt = true;
      tasksQueryVariables.includeFormTemplate = true;
      break;
    default:
      break;
  }

  const filters: TaskFilterInputObject[] = [];
  if (searchQuery) {
    filters.push(
      ...createSearchFilters(searchQuery, [
        { fieldName: 'name' },
        { fieldName: 'formCategoryName' },
        { fieldName: 'siteName' },
        { fieldName: 'siteAreaName' },
        { fieldName: 'siteCode' },
        { fieldName: 'siteAreaCode' },
        { fieldName: 'priority', predicateType: 'eq' },
      ]),
    );
  }
  if (filters.length) {
    tasksQueryVariables.filters = filters;
  }
  if (orderingField && orderingDirection) {
    tasksQueryVariables.sorts = {
      [orderingField]: orderingDirection,
    };
  }
  const tasksQuery = useDashboardTasksQuery({
    fetchPolicy: 'no-cache',
    variables: tasksQueryVariables,
    skip,
  });
  const [fetchTaskIds] = useDashboardTaskIdsLazyQuery({
    fetchPolicy: 'no-cache',
    variables: {
      onlyIds: true,
      filters: tasksQueryVariables.filters,
      scope: tasksQueryVariables.scope,
    },
  });
  const {
    loading: tasksLoading,
    data,
    previousData,
    refetch: refetchTasks,
    error: tasksError,
  } = tasksQuery;
  const queryData = data || previousData;
  const { tasks, ...taskCounterData } = queryData || {};
  const tasksFirstLoading = tasksLoading && previousData === undefined;
  const metadata = _get(queryData, 'tasks.metadata');
  const totalTasksCount = _get(metadata, 'totalCount', 0);
  const totalPages = _get(metadata, 'totalPages', 1);

  return {
    fetchTaskIds,
    refetchTasks,
    taskCounterData: getTaskCounterData(taskCounterData),
    tasks: _get(tasks, 'collection', []) as Task[],
    tasksError,
    tasksFirstLoading,
    tasksLoading,
    totalPages,
    totalTasksCount,
  };
}
