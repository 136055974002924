import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import { GetUrlParams, PAGINATION_PARAMS } from '@lib/enums/urls';
import usePaginationParams from '@lib/hooks/usePaginationParams';

function useTabParam(defaultTab?: string, defaultPaginationParams?: object) {
  const [tabParam, setTabParam] = useQueryParam(
    GetUrlParams.Tab,
    withDefault(StringParam, defaultTab),
  );
  const [, setPaginationParams] = usePaginationParams();
  const onSetTabParam = (v: string, paginationParams?: object) => {
    setTabParam(v);
    setPaginationParams({
      ...(paginationParams || defaultPaginationParams || PAGINATION_PARAMS),
      [GetUrlParams.Page]: '1',
    });
  };
  return [tabParam, onSetTabParam] as const;
}

export default useTabParam;
