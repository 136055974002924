import React from 'react';
import { FormTemplateQuestionFieldsKindEnum } from 'graphql-common';
import SingleLine from 'assets/images/field-single-line.svg?react';
import MultiLine from 'assets/images/field-multi-line.svg?react';
import YesNo from 'assets/images/field-yes-no.svg?react';

export default function getQuestionTypeProps(
  kind: FormTemplateQuestionFieldsKindEnum,
) {
  switch (kind) {
    case FormTemplateQuestionFieldsKindEnum.Section:
      return {
        icon: undefined,
        nameTranslationKey: 'section',
      };
    case FormTemplateQuestionFieldsKindEnum.Input:
      return {
        leftIconComponent: <SingleLine />,
        nameTranslationKey: 'single-line',
      };
    case FormTemplateQuestionFieldsKindEnum.TextArea:
      return {
        leftIconComponent: <MultiLine />,
        nameTranslationKey: 'multi-line',
      };
    case FormTemplateQuestionFieldsKindEnum.NumberInput:
      return {
        icon: '123',
        nameTranslationKey: 'number-value',
      };
    case FormTemplateQuestionFieldsKindEnum.MinMax:
      return {
        icon: 'arrow_range',
        leftIconSymbolsOutlined: true,
        nameTranslationKey: 'number-range',
      };
    case FormTemplateQuestionFieldsKindEnum.Date:
      return {
        icon: 'calendar_today',
        nameTranslationKey: 'date',
      };
    case FormTemplateQuestionFieldsKindEnum.DateRange:
      return {
        icon: 'calendar_month',
        nameTranslationKey: 'date-range',
      };
    case FormTemplateQuestionFieldsKindEnum.Time:
      return {
        icon: 'schedule',
        nameTranslationKey: 'time',
      };
    case FormTemplateQuestionFieldsKindEnum.TimeRange:
      return {
        icon: 'timelapse',
        nameTranslationKey: 'time-range',
      };
    case FormTemplateQuestionFieldsKindEnum.Datetime:
      return {
        icon: 'calendar_clock',
        leftIconSymbolsOutlined: true,
        nameTranslationKey: 'date-and-time',
      };
    case FormTemplateQuestionFieldsKindEnum.RadioButton:
      return {
        icon: 'radio_button_checked',
        nameTranslationKey: 'single-select',
      };
    case FormTemplateQuestionFieldsKindEnum.SingleSelect:
      return {
        icon: 'arrow_drop_down_circle',
        leftIconSymbolsOutlined: true,
        nameTranslationKey: 'dropdown',
      };
    case FormTemplateQuestionFieldsKindEnum.Checkbox:
      return {
        icon: 'check_box',
        leftIconSymbolsOutlined: true,
        nameTranslationKey: 'multi-select',
      };
    case FormTemplateQuestionFieldsKindEnum.Rating:
      return {
        icon: 'star',
        leftIconSymbolsOutlined: true,
        nameTranslationKey: 'rating',
      };
    case FormTemplateQuestionFieldsKindEnum.CheckboxButtonGrid:
      return {
        icon: 'view_list',
        leftIconSymbolsOutlined: true,
        nameTranslationKey: 'input-table',
      };
    case FormTemplateQuestionFieldsKindEnum.DecisionBox:
      return {
        icon: 'check_box_outline_blank',
        nameTranslationKey: 'decision-box',
      };
    case FormTemplateQuestionFieldsKindEnum.Attachment:
      return {
        icon: 'image',
        leftIconSymbolsOutlined: true,
        nameTranslationKey: 'attachment',
      };
    case FormTemplateQuestionFieldsKindEnum.YesNo:
      return {
        leftIconComponent: <YesNo />,
        nameTranslationKey: 'yes-no',
      };
    case FormTemplateQuestionFieldsKindEnum.ImportantNotice:
      return {
        icon: 'warning',
        leftIconSymbolsOutlined: true,
        nameTranslationKey: 'important-notice',
      };
    default:
      return undefined;
  }
}
