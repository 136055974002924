import { TFunction } from 'i18next';
import { GetLoadOptions } from '@lib/components/Select/types';
import { Steps } from '@lib/enums/form';
import getScheduleFields from './getScheduleFields';
import getTaskFields from './getTaskFields';

export type FormFieldsArgs = {
  getApproversLoadOptions: GetLoadOptions;
  getAssetsLoadOptions: GetLoadOptions;
  getSiteAreasLoadOptions: GetLoadOptions;
  getSitesLoadOptions: GetLoadOptions;
  getTemplateVersionsLoadOptions: GetLoadOptions;
  getTemplatesLoadOptions: GetLoadOptions;
  isCreateForm?: boolean;
  onCreateNewFormTemplate: () => void;
  onViewFormTemplate: (
    templateFormId: string,
    templateFormVersionId: string,
  ) => void;
  t: TFunction<'translation', undefined>;
};

export default function useFields(args: FormFieldsArgs) {
  const { t } = args;
  return [
    // Step 1
    ...getTaskFields(args),
    // Step 2
    ...getScheduleFields({ step: Steps.step2, t }),
  ];
}
