import { TFunction } from 'i18next';
import { Counter } from 'graphql-common';
import { getMeasurementUnitLabel } from '@lib/utils/measure';

export default function getPageTitle(
  data: Counter,
  t: TFunction<'translation', undefined>,
) {
  if (!data) return '';
  const unitLabel = getMeasurementUnitLabel(
    data.measureType,
    data.measureUnit,
    t,
  );
  return `${data?.name || ''} ${unitLabel}`;
}
