import { useState } from 'react';
import { Documentation } from 'graphql-common';

export interface UseModalManagementResult {
  closeAllModals: () => void;
  closeCategoryFormModal: () => void;
  closeCounterDestroyModal: () => void;
  closeCounterFormModal: () => void;
  closeCounterIndexFormModal: () => void;
  closeDeleteModal: () => void;
  closeDocumentationDestroyModal: () => void;
  closeDocumentationPreviewModal: () => void;
  closeGetQrCodeFormModal: () => void;
  closeNomenclatureDestroyModal: () => void;
  closeNomenclatureFormModal: () => void;
  isCategoryFormModalOpened: boolean;
  isCounterDestroyModalOpened: boolean;
  isCounterFormModalOpened: boolean;
  isCounterIndexFormModalOpened: boolean;
  isDeleteModalOpened: boolean;
  isDocumentationDestroyModalOpened: boolean;
  isDocumentationPreviewModalOpened: Documentation | null;
  isNomenclatureDestroyModalOpened: boolean;
  isNomenclatureFormModalOpened: boolean;
  isQrCodeFormModalOpened: boolean;
  openCategoryFormModal: () => void;
  openCounterDestroyModal: () => void;
  openCounterFormModal: () => void;
  openCounterIndexFormModal: () => void;
  openDeleteModal: () => void;
  openDocumentationDestroyModal: () => void;
  openDocumentationPreviewModal: (item: Documentation | null) => void;
  openGetQrCodeFormModal: () => void;
  openNomenclatureDestroyModal: () => void;
  openNomenclatureFormModal: () => void;
  isInterventionDestroyModalOpened: boolean;
  openInterventionDestroyModal: () => void;
  closeInterventionDestroyModal: () => void;
}

export default function useModalManagement(): UseModalManagementResult {
  const [isDeleteModalOpened, setDeleteModalOpened] = useState(false);
  const openDeleteModal = () => setDeleteModalOpened(true);
  const closeDeleteModal = () => setDeleteModalOpened(false);

  const [isQrCodeFormModalOpened, setQrCodeFormModalOpened] = useState(false);
  const openGetQrCodeFormModal = () => setQrCodeFormModalOpened(true);
  const closeGetQrCodeFormModal = () => setQrCodeFormModalOpened(false);

  const [isCategoryFormModalOpened, setCategoryFormModalOpened] =
    useState(false);
  const openCategoryFormModal = () => setCategoryFormModalOpened(true);
  const closeCategoryFormModal = () => setCategoryFormModalOpened(false);

  const [isNomenclatureFormModalOpened, setNomenclatureFormModalOpened] =
    useState(false);
  const openNomenclatureFormModal = () => setNomenclatureFormModalOpened(true);
  const closeNomenclatureFormModal = () =>
    setNomenclatureFormModalOpened(false);

  const [isNomenclatureDestroyModalOpened, setNomenclatureDestroyModalOpened] =
    useState(false);
  const openNomenclatureDestroyModal = () =>
    setNomenclatureDestroyModalOpened(true);
  const closeNomenclatureDestroyModal = () =>
    setNomenclatureDestroyModalOpened(false);

  const [isCounterFormModalOpened, setCounterFormModalOpened] = useState(false);
  const openCounterFormModal = () => setCounterFormModalOpened(true);
  const closeCounterFormModal = () => setCounterFormModalOpened(false);

  const [isCounterIndexFormModalOpened, setCounterIndexFormModalOpened] =
    useState(false);
  const openCounterIndexFormModal = () => setCounterIndexFormModalOpened(true);
  const closeCounterIndexFormModal = () =>
    setCounterIndexFormModalOpened(false);

  const [isCounterDestroyModalOpened, setCounterDestroyModalOpened] =
    useState(false);
  const openCounterDestroyModal = () => setCounterDestroyModalOpened(true);
  const closeCounterDestroyModal = () => setCounterDestroyModalOpened(false);

  const [
    isDocumentationDestroyModalOpened,
    setDocumentationDestroyModalOpened,
  ] = useState(false);
  const openDocumentationDestroyModal = () =>
    setDocumentationDestroyModalOpened(true);
  const closeDocumentationDestroyModal = () =>
    setDocumentationDestroyModalOpened(false);

  const [
    isDocumentationPreviewModalOpened,
    setDocumentationPreviewModalOpened,
  ] = useState<Documentation | null>(null);
  const openDocumentationPreviewModal = (item: Documentation | null) =>
    setDocumentationPreviewModalOpened(item);
  const closeDocumentationPreviewModal = () =>
    setDocumentationPreviewModalOpened(null);

  const [isInterventionDestroyModalOpened, setInterventionDestroyModalOpened] =
    useState(false);
  const openInterventionDestroyModal = () =>
    setInterventionDestroyModalOpened(true);
  const closeInterventionDestroyModal = () =>
    setInterventionDestroyModalOpened(false);

  const closeAllModals = () => {
    closeCategoryFormModal();
    closeCounterDestroyModal();
    closeCounterDestroyModal();
    closeCounterFormModal();
    closeCounterIndexFormModal();
    closeDeleteModal();
    closeDocumentationDestroyModal();
    closeDocumentationPreviewModal();
    closeGetQrCodeFormModal();
    closeNomenclatureDestroyModal();
    closeNomenclatureFormModal();
    closeInterventionDestroyModal();
  };

  return {
    closeAllModals,
    closeCategoryFormModal,
    closeCounterDestroyModal,
    closeCounterFormModal,
    closeCounterIndexFormModal,
    closeDeleteModal,
    closeDocumentationDestroyModal,
    closeDocumentationPreviewModal,
    closeGetQrCodeFormModal,
    closeNomenclatureDestroyModal,
    closeNomenclatureFormModal,
    isCategoryFormModalOpened,
    isCounterDestroyModalOpened,
    isCounterFormModalOpened,
    isCounterIndexFormModalOpened,
    isDeleteModalOpened,
    isDocumentationDestroyModalOpened,
    isDocumentationPreviewModalOpened,
    isNomenclatureDestroyModalOpened,
    isNomenclatureFormModalOpened,
    isQrCodeFormModalOpened,
    openCategoryFormModal,
    openCounterDestroyModal,
    openCounterFormModal,
    openCounterIndexFormModal,
    openDeleteModal,
    openDocumentationDestroyModal,
    openDocumentationPreviewModal,
    openGetQrCodeFormModal,
    openNomenclatureDestroyModal,
    openNomenclatureFormModal,
    isInterventionDestroyModalOpened,
    openInterventionDestroyModal,
    closeInterventionDestroyModal,
  };
}
