import { Values } from '@lib/interfaces/form';

export default function getFullName(
  item?: { firstName: string; lastName: string } | null | Values,
) {
  if (!item) return '-';
  const { firstName, lastName } = item;
  if (!firstName && !lastName) return '-';
  return `${firstName} ${lastName}`;
}
