import React, { memo, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import _isNil from 'lodash/isNil';
import { BaseItem, CellProps } from '@lib/components/Table/types';
import Typography from '@lib/components/Typography/Typography';
import Tooltip from '@lib/components/Tooltip/Tooltip';
import isTextCropped from '@lib/utils/isTextCropped';
import styles from './TextCell.module.scss';

interface Props {
  multiLine?: boolean;
}

function TextCell<T extends BaseItem>(props: CellProps<T> & Props) {
  const { value, disabled, multiLine = false } = props;
  const textRef = useRef<HTMLDivElement>(null);
  const [isTooltipDisabled, setIsTooltipDisabled] = useState(true);

  useEffect(() => {
    const textRefEl = textRef.current;
    if (textRefEl) {
      setIsTooltipDisabled(!value || !isTextCropped(textRefEl));
    }
  }, [value]);

  return (
    <div>
      <Tooltip body={value} disabled={isTooltipDisabled}>
        <Typography
          variant="caption"
          className={classNames(styles.rootTextCell, {
            [styles.disabled]: disabled,
          })}
        >
          <div
            ref={textRef}
            className={classNames({
              [styles.croppedTextSingleLine]: !multiLine,
              [styles.croppedTextMultiLine]: multiLine,
            })}
          >
            {_isNil(value) ? '-' : value}
          </div>
        </Typography>
      </Tooltip>
    </div>
  );
}

export default memo(TextCell) as <T extends BaseItem>(
  props: CellProps<T> & Props,
) => JSX.Element;
