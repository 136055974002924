import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import {
  ComponentEnum,
  Intervention,
  InterventionScopeNameEnum,
  LevelEnum,
} from 'graphql-common';
import { useTableItemsBulkSelect } from '@lib/hooks/useTableItemsBulkSelect';
import BreadCrumbs from '@lib/components/BreadCrumbs/BreadCrumbs';
import useSearchParam from '@lib/hooks/useSearchParam';
import PageHeader from '@lib/layouts/DashboardLayout/PageHeader/PageHeader';
import Button, { ButtonTypes } from '@lib/components/Button/Button';
import EmptyState from '@lib/components/EmptyState/EmptyState';
import PageBody from '@lib/layouts/DashboardLayout/PageBody/PageBody';
import CircleLoader from '@lib/components/CircleLoader/CircleLoader';
import Search from '@lib/components/Search/Search';
import Table from '@lib/components/Table/Table';
import ListEmptyState from '@lib/components/ListEmptyState/ListEmptyState';
import ErrorPage from '@lib/components/ErrorPage/ErrorPage';
import getStatusFromError from '@lib/utils/getStatusFromError';
import ConfirmationModal from '@lib/components/ConfirmationModal/ConfirmationModal';
import Trans from '@lib/components/Trans/Trans';
import TabBar from '@lib/components/TabBar/TabBar';
import { getBreadCrumbsDataByKey } from 'utils/breadcrumbs';
import useOutletContext, { ContextProps } from 'hooks/useOutletContext';
import ViewOnlyChip from '@lib/components/ViewOnlyChip/ViewOnlyChip';
import { IconName } from '@lib/components/Modal/enums';
import { PlanState } from '@lib/enums/plan';
import getEmptyStateTextKeys from './utils/getEmptyStateTextKeys';
import getTableTabs from './utils/getTableTabs';
import getTableColumns from './utils/getTableColumns';
import getTableActionsMenu from './utils/getTableActionsMenu';
import getTableActionsForSelected from './utils/getTableActionsForSelected';
import useActions from './utils/useActions';
import useModalManagement from './utils/useModalManagement';

export default function InterventionsContainer() {
  const { t } = useTranslation();
  const { pathname, search } = useLocation();
  const [searchQuery] = useSearchParam();

  const {
    getUserAccessByCompany,
    getUserAccessByComponent,
    handleOpenPlanModal,
  }: ContextProps = useOutletContext();
  const isViewLocked =
    getUserAccessByCompany(ComponentEnum.InterventionsManagement) ===
    LevelEnum.LimitedRead;
  const isViewOnly =
    getUserAccessByComponent(ComponentEnum.InterventionsManagement) ===
    LevelEnum.Read;

  const useBulkSelectResult = useTableItemsBulkSelect<Intervention>();
  const useModalManagementResult = useModalManagement();

  const useInterventionActionsResult = useActions({
    includeFullSearchFilters: true,
    skipInterventionsFetch: isViewLocked,
    pathname,
    search,
    searchQuery,
    useBulkSelectResult,
    useModalManagementResult,
  });
  const {
    tabParam,
    onTabChange,
    interventionsQueryHook,
    onConfirmedInterventionDestroy,
    onConfirmedInterventionClose,
    openCreateInterventionPage,
    openViewInterventionPage,
    openEditInterventionPage,
    openInterventionCloseModal,
    openInterventionDestroyModal,
  } = useInterventionActionsResult;

  const tableColumns = getTableColumns({
    t,
  });
  const tableActionsMenu = getTableActionsMenu({
    onView: openViewInterventionPage,
    onEdit: openEditInterventionPage,
    onClose: openInterventionCloseModal,
    onDelete: openInterventionDestroyModal,
    isViewOnly,
    t,
  });
  const tableActionsForSelected = getTableActionsForSelected({
    tabParam,
    onBulkClose: useModalManagementResult.openInterventionCloseModal,
    selectedItemsCount: useBulkSelectResult?.getSelectedItemsCount(
      interventionsQueryHook.totalCount,
    ),
    t,
    isViewOnly,
  });
  const tableTabs = getTableTabs({
    t,
    interventionsCounter: interventionsQueryHook.counters,
  });
  const { totalCount } = interventionsQueryHook;
  const bulkSelectedItemsCount = totalCount
    ? useBulkSelectResult.getSelectedItemsCount(totalCount)
    : 0;

  const [emptyStateTitle, emptyStateText] = getEmptyStateTextKeys(tabParam);
  const emptyState = (
    <ListEmptyState
      title={t(emptyStateTitle)}
      text={<Trans i18nKey={emptyStateText} />}
      actions={
        isViewOnly || tabParam === InterventionScopeNameEnum.Closed
          ? []
          : [
              <Button
                key="add"
                buttonText={t('create-intervention')}
                buttonType={ButtonTypes.primaryFilled}
                leftIcon="add"
                onClick={() => openCreateInterventionPage()}
              />,
            ]
      }
    />
  );
  const lockState = (
    <EmptyState
      icon={IconName.Lock}
      title={t('lock-state-title-interventions')}
      text={<Trans i18nKey="lock-state-text-interventions" />}
      actions={[
        <Button
          key="upgrade"
          buttonText={t('upgrade-plan')}
          buttonType={ButtonTypes.primaryFilled}
          onClick={() => handleOpenPlanModal(PlanState.Locked)}
        />,
      ]}
    />
  );

  if (interventionsQueryHook.error) {
    return (
      <ErrorPage
        status={getStatusFromError(interventionsQueryHook.error)}
        description={interventionsQueryHook.error?.message}
      />
    );
  }

  return (
    <>
      <BreadCrumbs data={getBreadCrumbsDataByKey({ pathname, t })} />
      <PageHeader title={t('interventions')}>
        {isViewOnly ? (
          <ViewOnlyChip />
        ) : (
          <Button
            buttonText={t('create-intervention')}
            leftIcon={isViewLocked ? 'lock' : 'add'}
            onClick={() => openCreateInterventionPage()}
            disabled={isViewLocked}
          />
        )}
      </PageHeader>
      <PageBody>
        {isViewLocked ? (
          <div>{lockState}</div>
        ) : (
          <div>
            {interventionsQueryHook.firstLoading ? (
              <CircleLoader />
            ) : (
              <>
                <TabBar
                  items={tableTabs}
                  active={tabParam}
                  onActiveChange={onTabChange}
                />
                {(!!interventionsQueryHook.collection?.length ||
                  searchQuery) && (
                  <Search placeholder={t('interventions-search-placeholder')} />
                )}
                <Table
                  actionsForSelected={tableActionsForSelected}
                  actionsMenu={tableActionsMenu}
                  columns={tableColumns}
                  data={interventionsQueryHook.collection}
                  emptyState={emptyState}
                  fullHeight
                  id="interventions"
                  loading={interventionsQueryHook.loading}
                  totalEntries={interventionsQueryHook.totalCount}
                  useBulkSelectResult={useBulkSelectResult}
                />
              </>
            )}
          </div>
        )}
      </PageBody>
      <ConfirmationModal
        confirmButtonLabel={t('yes-delete')}
        description={<Trans i18nKey="intervention-delete-text" />}
        icon={IconName.Delete}
        isOpen={useModalManagementResult.isInterventionDestroyModalOpened}
        onClose={useModalManagementResult.closeAllModals}
        onConfirm={onConfirmedInterventionDestroy}
        title={t('are-you-sure')}
      />
      <ConfirmationModal
        confirmButtonLabel={t('yes-close')}
        description={
          <Trans
            i18nKey={
              bulkSelectedItemsCount > 1
                ? 'interventions-close-text'
                : 'intervention-close-text'
            }
          />
        }
        icon={IconName.Warning}
        isOpen={useModalManagementResult.isInterventionCloseModalOpened}
        onClose={useModalManagementResult.closeAllModals}
        onConfirm={onConfirmedInterventionClose}
        title={t('are-you-sure')}
      />
    </>
  );
}
