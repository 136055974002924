import { APP_URLS } from 'constants/urls';
import { TFunction } from 'i18next';
import { BreadCrumb } from '@lib/components/BreadCrumbs/types';
import { Values } from '@lib/interfaces/form';
import { NEW } from '@lib/enums/common';
import { DEFAULT_LIST_PARAMS } from '@lib/enums/urls';

type Options = {
  pathname: string;
  t: TFunction<'translation', undefined>;
  entity?: Values;
};

export function getBreadCrumbsDataByKey({ pathname, t, entity }: Options) {
  const dashboardItem = {
    label: t('dashboard'),
    path: APP_URLS.dashboard.overview.getPathWithQuery(),
  };
  const sitesItem = {
    label: t('sites'),
    path: APP_URLS.dashboard.sites.index.getPathWithQuery(),
  };
  const viewSiteItem = {
    label: t('view-site'),
    path: APP_URLS.dashboard.sites.view.getDynamicPath({
      pathParts: {
        id: `${entity?.id}`,
      },
    }),
  };
  const formTemplatesItem = {
    label: t('form-templates'),
    path: APP_URLS.dashboard.formTemplates.index.getPathWithQuery(),
  };
  const assetsItem = {
    label: t('assets'),
    path: APP_URLS.dashboard.assets.index.getPathWithQuery(),
  };
  const tasksItem = {
    label: t('tasks'),
    path: APP_URLS.dashboard.tasks.index.getPathWithQuery(),
  };
  const interventionsItem = {
    label: t('interventions'),
    path: APP_URLS.dashboard.interventions.index.getPathWithQuery(),
  };
  const teamsItem = {
    label: t('teams'),
    path: APP_URLS.dashboard.teams.index.getPathWithQuery(),
  };
  const viewTeamItem = {
    label: t('team-details'),
    path: APP_URLS.dashboard.teams.view.getDynamicPath({
      pathParts: {
        id: `${entity?.id}`,
      },
    }),
  };
  const usersItem = {
    label: t('members'),
    path: APP_URLS.dashboard.users.index.getPathWithQuery(),
  };

  const breadcrumbMap = [
    {
      condition: APP_URLS.dashboard.overview.isTheSameUrlAs(pathname),
      data: [
        {
          label: t('dashboard'),
        },
      ],
    },
    {
      condition: APP_URLS.dashboard.timeline.isTheSameUrlAs(pathname),
      data: [
        {
          label: t('dashboard'),
        },
      ],
    },
    {
      condition: APP_URLS.dashboard.profile.isTheSameUrlAs(pathname),
      data: [
        dashboardItem,
        {
          label: t('profile'),
        },
      ],
    },
    {
      condition: APP_URLS.dashboard.users.index.isTheSameUrlAs(pathname),
      data: [
        dashboardItem,
        {
          label: t('members'),
        },
      ],
    },
    {
      condition: APP_URLS.dashboard.users.view.isTheSameUrlAs(pathname, {
        pathParts: {
          id: `${entity?.id}`,
        },
      }),
      data: [
        dashboardItem,
        usersItem,
        {
          label: t('view-personal-info'),
        },
      ],
    },
    {
      condition: APP_URLS.dashboard.users.viewAccess.isTheSameUrlAs(pathname, {
        pathParts: {
          id: `${entity?.id}`,
        },
      }),
      data: [
        dashboardItem,
        usersItem,
        {
          label: t('view-access-rights'),
        },
      ],
    },
    {
      condition: APP_URLS.dashboard.users.editAccess.isTheSameUrlAs(pathname, {
        pathParts: {
          id: `${entity?.id}`,
        },
      }),
      data: [
        dashboardItem,
        usersItem,
        {
          label: t('view-membership'),
          path: APP_URLS.dashboard.users.view.getDynamicPath({
            pathParts: { id: `${entity?.id}` },
          }),
        },
        {
          label: t('edit-access-rights'),
        },
      ],
    },
    {
      condition: APP_URLS.dashboard.teams.index.isTheSameUrlAs(pathname),
      data: [
        dashboardItem,
        {
          label: t('teams'),
        },
      ],
    },
    {
      condition: APP_URLS.dashboard.teams.add.isTheSameUrlAs(pathname),
      data: [
        dashboardItem,
        teamsItem,
        {
          label: t('create-team'),
        },
      ],
    },
    {
      condition: APP_URLS.dashboard.teams.edit.isTheSameUrlAs(pathname, {
        pathParts: {
          id: `${entity?.id}`,
        },
      }),
      data: [
        dashboardItem,
        teamsItem,
        {
          label: t('edit-team'),
        },
      ],
    },
    {
      condition: APP_URLS.dashboard.teams.view.isTheSameUrlAs(pathname, {
        pathParts: {
          id: `${entity?.id}`,
        },
      }),
      data: [
        dashboardItem,
        teamsItem,
        {
          label: t('view-team'),
        },
      ],
    },
    {
      condition: APP_URLS.dashboard.teams.members.isTheSameUrlAs(pathname, {
        pathParts: {
          id: `${entity?.id}`,
        },
      }),
      data: [
        dashboardItem,
        teamsItem,
        viewTeamItem,
        {
          label: t('team-members'),
        },
      ],
    },
    {
      condition: APP_URLS.dashboard.sites.index.isTheSameUrlAs(pathname),
      data: [
        dashboardItem,
        {
          label: t('sites'),
        },
      ],
    },
    {
      condition: APP_URLS.dashboard.sites.add.isTheSameUrlAs(pathname),
      data: [
        dashboardItem,
        {
          label: t('sites'),
          path: APP_URLS.dashboard.sites.index.getPathWithQuery(),
        },
        {
          label: t('create-new-site'),
        },
      ],
    },
    {
      condition: APP_URLS.dashboard.sites.edit.isTheSameUrlAs(pathname, {
        pathParts: {
          id: `${entity?.id}`,
        },
      }),
      data: [
        dashboardItem,
        sitesItem,
        {
          label: entity?.name,
        },
      ],
    },
    {
      condition: APP_URLS.dashboard.sites.view.isTheSameUrlAs(pathname, {
        pathParts: {
          id: `${entity?.id}`,
        },
      }),
      data: [
        dashboardItem,
        sitesItem,
        {
          label: entity?.name,
        },
      ],
    },
    {
      condition: APP_URLS.dashboard.sites.areas.isTheSameUrlAs(pathname, {
        pathParts: {
          id: `${entity?.id}`,
        },
      }),
      data: [
        dashboardItem,
        sitesItem,
        viewSiteItem,
        {
          label: t('site-areas'),
        },
      ],
    },
    {
      condition: APP_URLS.dashboard.sites.users.isTheSameUrlAs(pathname, {
        pathParts: {
          id: `${entity?.id}`,
        },
      }),
      data: [
        dashboardItem,
        sitesItem,
        viewSiteItem,
        {
          label: t('team'),
        },
      ],
    },
    {
      condition: APP_URLS.dashboard.sites.viewCodeOfConduct.isTheSameUrlAs(
        pathname,
        {
          pathParts: {
            id: `${entity?.id}`,
          },
        },
      ),
      data: [
        dashboardItem,
        sitesItem,
        viewSiteItem,
        {
          label: t('view-code-of-conduct'),
        },
      ],
    },
    {
      condition: APP_URLS.dashboard.sites.editCodeOfConduct.isTheSameUrlAs(
        pathname,
        {
          pathParts: {
            id: `${entity?.id}`,
          },
        },
      ),
      data: [
        dashboardItem,
        sitesItem,
        viewSiteItem,
        {
          label: t('edit-code-of-conduct'),
        },
      ],
    },
    {
      condition:
        APP_URLS.dashboard.formTemplates.index.isTheSameUrlAs(pathname),
      data: [
        dashboardItem,
        {
          label: t('form-templates'),
        },
      ],
    },
    {
      condition:
        APP_URLS.dashboard.formTemplates.categories.isTheSameUrlAs(pathname),
      data: [
        dashboardItem,
        formTemplatesItem,
        {
          label: t('categories'),
        },
      ],
    },
    {
      condition: APP_URLS.dashboard.formTemplates.add.isTheSameUrlAs(pathname),
      data: [
        dashboardItem,
        formTemplatesItem,
        {
          label: t('create-new-form'),
        },
      ],
    },
    {
      condition: APP_URLS.dashboard.formTemplates.editVersion.isTheSameUrlAs(
        pathname,
        {
          pathParts: {
            id: `${entity?.id}`,
            versionId: `${entity?.versionId}`,
          },
        },
      ),
      data: [
        dashboardItem,
        formTemplatesItem,
        {
          label: entity?.name,
        },
      ],
    },
    {
      condition: APP_URLS.dashboard.formTemplates.viewVersion.isTheSameUrlAs(
        pathname,
        {
          pathParts: {
            id: `${entity?.id}`,
            versionId: `${entity?.versionId}`,
          },
        },
      ),
      data: [
        dashboardItem,
        formTemplatesItem,
        {
          label: entity?.name,
        },
      ],
    },
    {
      condition:
        APP_URLS.dashboard.formTemplates.viewLinkedTasks.isTheSameUrlAs(
          pathname,
          {
            pathParts: {
              id: `${entity?.id}`,
              versionId: `${entity?.versionId}`,
            },
          },
        ),
      data: [
        dashboardItem,
        formTemplatesItem,
        {
          label: t('view-details'),
          path: APP_URLS.dashboard.formTemplates.viewVersion.getDynamicPath({
            pathParts: {
              id: `${entity?.id}`,
              versionId: `${entity?.versionId}`,
            },
          }),
        },
        {
          label: entity?.name,
        },
      ],
    },
    {
      condition: APP_URLS.dashboard.formTemplates.duplicate.isTheSameUrlAs(
        pathname,
        {
          pathParts: {
            id: `${entity?.id}`,
            versionId: `${entity?.versionId}`,
          },
        },
      ),
      data: [
        dashboardItem,
        formTemplatesItem,
        {
          label: t('duplicate-form-template'),
          path: APP_URLS.dashboard.formTemplates.viewVersion.getDynamicPath({
            pathParts: {
              id: `${entity?.id}`,
              versionId: `${entity?.versionId}`,
            },
          }),
        },
        {
          label: entity?.name,
        },
      ],
    },
    {
      condition: APP_URLS.dashboard.assets.index.isTheSameUrlAs(pathname),
      data: [
        dashboardItem,
        {
          label: t('assets'),
        },
      ],
    },
    {
      condition: APP_URLS.dashboard.assets.categories.isTheSameUrlAs(pathname),
      data: [
        dashboardItem,
        assetsItem,
        {
          label: t('categories'),
        },
      ],
    },
    {
      condition: APP_URLS.dashboard.assets.add.isTheSameUrlAs(pathname),
      data: [
        dashboardItem,
        assetsItem,
        {
          label: t('add-new-asset'),
        },
      ],
    },
    {
      condition: APP_URLS.dashboard.assets.edit.isTheSameUrlAs(pathname, {
        pathParts: {
          id: `${entity?.id}`,
        },
      }),
      data: [
        dashboardItem,
        assetsItem,
        {
          label: entity?.name,
        },
      ],
    },
    {
      condition: APP_URLS.dashboard.assets.view.isTheSameUrlAs(pathname, {
        pathParts: {
          id: `${entity?.id}`,
        },
      }),
      data: [
        dashboardItem,
        assetsItem,
        {
          label: entity?.name,
        },
      ],
    },
    {
      condition: APP_URLS.dashboard.assets.nomenclature.isTheSameUrlAs(
        pathname,
        {
          pathParts: {
            id: `${entity?.id}`,
          },
        },
      ),
      data: [
        dashboardItem,
        assetsItem,
        {
          label: t('view-asset'),
          path: APP_URLS.dashboard.assets.view.getDynamicPath({
            pathParts: {
              id: `${entity?.id}`,
            },
          }),
        },
        {
          label: t('nomenclature'),
        },
      ],
    },
    {
      condition: APP_URLS.dashboard.assets.counter.isTheSameUrlAs(pathname, {
        pathParts: {
          id: `${entity?.id}`,
        },
      }),
      data: [
        dashboardItem,
        assetsItem,
        {
          label: t('view-asset'),
          path: APP_URLS.dashboard.assets.view.getDynamicPath({
            pathParts: {
              id: `${entity?.id}`,
            },
          }),
        },
        {
          label: t('counters'),
        },
      ],
    },
    {
      condition: APP_URLS.dashboard.assets.counters.isTheSameUrlAs(pathname, {
        pathParts: {
          id: `${entity?.id}`,
        },
      }),
      data: [
        dashboardItem,
        assetsItem,
        {
          label: t('counter-monitoring'),
        },
      ],
    },
    {
      condition: APP_URLS.dashboard.assets.documentation.isTheSameUrlAs(
        pathname,
        {
          pathParts: {
            id: `${entity?.id}`,
          },
        },
      ),
      data: [
        dashboardItem,
        assetsItem,
        {
          label: t('view-asset'),
          path: APP_URLS.dashboard.assets.view.getDynamicPath({
            pathParts: {
              id: `${entity?.id}`,
            },
          }),
        },
        {
          label: t('documentation'),
        },
      ],
    },
    {
      condition:
        APP_URLS.dashboard.interventions.index.isTheSameUrlAs(pathname),
      data: [
        dashboardItem,
        {
          label: t('interventions'),
        },
      ],
    },
    {
      condition:
        APP_URLS.dashboard.interventions.categories.isTheSameUrlAs(pathname),
      data: [
        dashboardItem,
        interventionsItem,
        {
          label: t('categories'),
        },
      ],
    },
    {
      condition: APP_URLS.dashboard.interventions.add.isTheSameUrlAs(pathname),
      data: [
        dashboardItem,
        interventionsItem,
        {
          label: t('create-intervention'),
        },
      ],
    },
    {
      condition: APP_URLS.dashboard.interventions.edit.isTheSameUrlAs(
        pathname,
        {
          pathParts: {
            id: `${entity?.id}`,
          },
        },
      ),
      data: [
        dashboardItem,
        interventionsItem,
        {
          label: entity?.name,
        },
      ],
    },
    {
      condition: APP_URLS.dashboard.interventions.view.isTheSameUrlAs(
        pathname,
        {
          pathParts: {
            id: `${entity?.id}`,
          },
        },
      ),
      data: [
        dashboardItem,
        interventionsItem,
        {
          label: entity?.name,
        },
      ],
    },
    {
      condition: APP_URLS.dashboard.interventions.viewActions.isTheSameUrlAs(
        pathname,
        {
          pathParts: {
            id: `${entity?.id}`,
          },
        },
      ),
      data: [
        dashboardItem,
        interventionsItem,
        {
          label: t('view-intervention'),
          path: APP_URLS.dashboard.interventions.view.getDynamicPath({
            pathParts: {
              id: `${entity?.id}`,
            },
          }),
        },
        {
          label: entity?.name,
        },
      ],
    },
    {
      condition: APP_URLS.dashboard.interventions.actionAdd.isTheSameUrlAs(
        pathname,
        {
          pathParts: {
            id: `${entity?.id}`,
          },
        },
      ),
      data: [
        dashboardItem,
        interventionsItem,
        {
          label: t('view-intervention'),
          path: APP_URLS.dashboard.interventions.viewActions.getDynamicPath({
            pathParts: {
              id: `${entity?.id}`,
            },
          }),
        },
        {
          label: t('create-an-action'),
        },
      ],
    },
    {
      condition: APP_URLS.dashboard.interventions.actionEdit.isTheSameUrlAs(
        pathname,
        {
          pathParts: {
            id: `${entity?.interventionId}`,
            actionId: `${entity?.id}`,
          },
        },
      ),
      data: [
        dashboardItem,
        interventionsItem,
        {
          label: t('view-intervention'),
          path: APP_URLS.dashboard.interventions.viewActions.getDynamicPath({
            pathParts: {
              id: `${entity?.interventionId}`,
            },
          }),
        },
        {
          label: t('edit-an-action'),
        },
      ],
    },
    {
      condition: APP_URLS.dashboard.interventions.actionView.isTheSameUrlAs(
        pathname,
        {
          pathParts: {
            id: `${entity?.interventionId}`,
            actionId: `${entity?.id}`,
          },
        },
      ),
      data: [
        dashboardItem,
        interventionsItem,
        {
          label: t('view-intervention'),
          path: APP_URLS.dashboard.interventions.viewActions.getDynamicPath({
            pathParts: {
              id: `${entity?.interventionId}`,
            },
          }),
        },
        {
          label: t('view-an-action'),
        },
      ],
    },
    {
      condition: APP_URLS.dashboard.assets.interventions.isTheSameUrlAs(
        pathname,
        {
          pathParts: {
            id: `${entity?.id}`,
          },
        },
      ),
      data: [
        dashboardItem,
        assetsItem,
        {
          label: t('view-asset'),
          path: APP_URLS.dashboard.assets.view.getDynamicPath({
            pathParts: {
              id: `${entity?.id}`,
            },
          }),
        },
        {
          label: t('interventions'),
        },
      ],
    },
    {
      condition: APP_URLS.dashboard.assets.document.isTheSameUrlAs(pathname, {
        pathParts: {
          id: `${entity?.assetId}`,
          documentId: `${entity?.id}`,
        },
      }),
      data: [
        dashboardItem,
        assetsItem,
        {
          label: t('view-asset'),
          path: APP_URLS.dashboard.assets.view.getDynamicPath({
            pathParts: {
              id: `${entity?.assetId}`,
            },
          }),
        },
        {
          label: t('documentation'),
          path: APP_URLS.dashboard.assets.documentation.getDynamicPath({
            pathParts: {
              id: `${entity?.assetId}`,
            },
            params: {
              ...DEFAULT_LIST_PARAMS,
            },
          }),
        },
        {
          label: t('edit-document'),
        },
      ],
    },
    {
      condition: APP_URLS.dashboard.assets.document.isTheSameUrlAs(pathname, {
        pathParts: {
          id: `${entity?.assetId}`,
          documentId: NEW,
        },
      }),
      data: [
        dashboardItem,
        assetsItem,
        {
          label: t('view-asset'),
          path: APP_URLS.dashboard.assets.view.getDynamicPath({
            pathParts: {
              id: `${entity?.assetId}`,
            },
          }),
        },
        {
          label: t('documentation'),
          path: APP_URLS.dashboard.assets.documentation.getDynamicPath({
            pathParts: {
              id: `${entity?.assetId}`,
            },
            params: {
              ...DEFAULT_LIST_PARAMS,
            },
          }),
        },
        {
          label: t('upload-document'),
        },
      ],
    },
    {
      condition: APP_URLS.dashboard.tasks.index.isTheSameUrlAs(pathname),
      data: [
        dashboardItem,
        {
          label: t('tasks'),
        },
      ],
    },
    {
      condition: APP_URLS.dashboard.tasks.add.isTheSameUrlAs(pathname),
      data: [
        dashboardItem,
        tasksItem,
        {
          label: t('create-task'),
        },
      ],
    },
    {
      condition: APP_URLS.dashboard.tasks.edit.isTheSameUrlAs(pathname, {
        pathParts: {
          id: `${entity?.id}`,
        },
      }),
      data: [
        dashboardItem,
        tasksItem,
        {
          label: t('task-details'),
          path: APP_URLS.dashboard.tasks.view.getDynamicPath({
            pathParts: {
              id: `${entity?.id}`,
            },
          }),
        },
        {
          label: entity?.name,
        },
      ],
    },
    {
      condition: APP_URLS.dashboard.tasks.duplicate.isTheSameUrlAs(pathname, {
        pathParts: {
          id: `${entity?.id}`,
        },
      }),
      data: [
        dashboardItem,
        tasksItem,
        {
          label: t('duplicate-task'),
          path: APP_URLS.dashboard.tasks.view.getDynamicPath({
            pathParts: {
              id: `${entity?.id}`,
            },
          }),
        },
        {
          label: entity?.name,
        },
      ],
    },
    {
      condition: APP_URLS.dashboard.tasks.view.isTheSameUrlAs(pathname, {
        pathParts: {
          id: `${entity?.id}`,
        },
      }),
      data: [
        dashboardItem,
        tasksItem,
        {
          label: entity?.name,
        },
      ],
    },
    {
      condition: APP_URLS.dashboard.tasks.formView.isTheSameUrlAs(pathname, {
        pathParts: {
          id: `${entity?.id}`,
        },
      }),
      data: [
        dashboardItem,
        tasksItem,
        {
          label: t('task-details'),
          path: APP_URLS.dashboard.tasks.view.getDynamicPath({
            pathParts: {
              id: `${entity?.id}`,
            },
          }),
        },
        {
          label: entity?.name,
        },
      ],
    },
    {
      condition: APP_URLS.dashboard.tasks.reporting.isTheSameUrlAs(pathname, {
        pathParts: {
          id: `${entity?.id}`,
        },
      }),
      data: [
        dashboardItem,
        tasksItem,
        {
          label: t('task-details'),
          path: APP_URLS.dashboard.tasks.view.getDynamicPath({
            pathParts: {
              id: `${entity?.id}`,
            },
          }),
        },
        {
          label: t('reporting'),
        },
      ],
    },
    {
      condition: APP_URLS.dashboard.tasks.interventions.isTheSameUrlAs(
        pathname,
        {
          pathParts: {
            id: `${entity?.id}`,
          },
        },
      ),
      data: [
        dashboardItem,
        tasksItem,
        {
          label: t('task-details'),
          path: APP_URLS.dashboard.tasks.view.getDynamicPath({
            pathParts: {
              id: `${entity?.id}`,
            },
          }),
        },
        {
          label: t('interventions'),
        },
      ],
    },
    {
      condition: APP_URLS.dashboard.tasks.approvals.isTheSameUrlAs(pathname),
      data: [
        dashboardItem,
        {
          label: t('approvals'),
        },
      ],
    },
  ];

  const matchedCrumb = breadcrumbMap.find((crumb) => crumb.condition);
  return (matchedCrumb ? matchedCrumb.data : []) as BreadCrumb[];
}
