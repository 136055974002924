import _get from 'lodash/get';
import { InterventionCategory } from 'graphql-common';
import { Values } from '@lib/interfaces/form';
import FieldNames from '@lib/enums/fieldNames/interventionCategoryFieldNames';

export default function getDefaultValuesFromQueryData(
  data: InterventionCategory,
): Values {
  return {
    [FieldNames.Id]: _get(data, 'id', null),
    [FieldNames.Name]: _get(data, 'name', ''),
    [FieldNames.Color]: { value: _get(data, 'color', null) },
    [FieldNames.Description]: _get(data, 'description', ''),
  };
}
