import React from 'react';
import { useTranslation } from 'react-i18next';
import _get from 'lodash/get';
import {
  ComponentEnum,
  useAuthenticationSessionDestroyMutation,
  useDashboardTaskCounterQuery,
} from 'graphql-common';
import { useApolloClient } from '@apollo/client';
import toast from '@lib/components/Toast/Toast';
import DashboardLayoutCommon from '@lib/layouts/DashboardLayout/DashboardLayout';
import { captureException } from 'utils/captureException';
import useWhoamiQueryHook from 'utils/fetch/useWhoamiQueryHook';
import { useLocation, useParams } from 'react-router-dom';
import getNavItems from 'layouts/DashboardLayout/utils/getNavItems';
import getProfileMenuItems from 'layouts/DashboardLayout/utils/getProfileMenuItems';
import PlatformSwitcher, {
  Platforms,
} from '@lib/components/PlatformSwitcher/PlatformSwitcher';
import {
  checkUserAccessByComponent,
  checkUserAccessForPage,
  checkUserAccessItemIdsByComponent,
} from '@lib/utils/accesses';
import getHomePageUrl from 'layouts/DashboardLayout/utils/getHomePageUrl';

const { VITE_AUTH_PLATFORM_URL: AUTH_PLATFORM } = import.meta.env;

function DashboardLayout(): React.ReactElement {
  const { t } = useTranslation();
  const apolloClient = useApolloClient();
  const { pathname } = useLocation();
  const { id, ...restParams } = useParams();

  const { currentUser } = useWhoamiQueryHook({
    fetchPolicy: 'cache-only',
  });

  const getUserAccessByUrl = (pathnameStr: string) =>
    checkUserAccessForPage(currentUser?.finalAccesses ?? [], pathnameStr);
  const getUserAccessByComponent = (component: ComponentEnum) =>
    checkUserAccessByComponent(currentUser?.finalAccesses ?? [], component);
  const getUserAccessItemIdsByComponent = (component: ComponentEnum) =>
    checkUserAccessItemIdsByComponent(
      currentUser?.finalAccesses ?? [],
      component,
    );
  const getUserAccessByCompany = (component: ComponentEnum) =>
    checkUserAccessByComponent(
      currentUser?.company.plan.accesses ?? [],
      component,
    );
  const logoUrl = getHomePageUrl(currentUser?.finalAccesses ?? []);

  const countersQuery = useDashboardTaskCounterQuery();
  const myApprovalsCounter = _get(
    countersQuery,
    'data.myApprovals.metadata.totalCount',
    0,
  );

  const callbackOnLogout = () => {
    localStorage.clear();
    window.location.href = AUTH_PLATFORM;
  };

  const [logoutMutation] = useAuthenticationSessionDestroyMutation({
    onCompleted: () => {
      apolloClient.clearStore().then(callbackOnLogout);
    },
    onError: (error: Error) => {
      toast({ content: error.message || 'Oops! Something went wrong' });
      callbackOnLogout();
    },
  });

  const onLogoutHandler = () => logoutMutation({ variables: { input: {} } });

  const navItems = getNavItems({
    getUserAccessByCompany,
    getUserAccessByComponent,
    id,
    myApprovalsCounter,
    pathname,
    t,
    urlParams: restParams,
  });

  const profileMenuItems = getProfileMenuItems({ onLogoutHandler, t });

  const outletContext = {
    getUserAccessByUrl,
    getUserAccessByComponent,
    getUserAccessByCompany,
    getUserAccessItemIdsByComponent,
  };

  return (
    <DashboardLayoutCommon
      captureException={captureException}
      logoUrl={logoUrl}
      navItems={navItems}
      onLogoutHandler={onLogoutHandler}
      profileMenuItems={profileMenuItems}
      user={currentUser}
      outletContext={outletContext}
      tools={
        <PlatformSwitcher
          selected={Platforms.Admin}
          available={Platforms.User}
        />
      }
    />
  );
}

export default DashboardLayout;
