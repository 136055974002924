import { SelectOption } from '@lib/components/Select/types';

interface Item extends Omit<SelectOption, 'value'> {
  id: string;
  unlink?: boolean | null;
  value?: SelectOption['value'];
}

export type Items = Item[] | null;

function mergeAndUnlinkMultiSelectValues(
  oldItems?: Items,
  newItems?: Items,
): Item[] | null {
  const oldValues = oldItems || [];
  const newValues = newItems || [];

  // Create a map of old values for efficient lookup
  const oldValuesMap = new Map<string, Item>();
  oldValues.forEach((item) => {
    oldValuesMap.set(item.id, item);
  });

  // Filter new values which are not in old values
  const addedValues = newValues.filter(
    (newItem) => !oldValuesMap.has(newItem.id),
  );

  // Find items in old values that are not in new values and have them unlinked
  const unlinkedValues: Item[] = [];
  oldValues.forEach((oldItem) => {
    if (!newValues.find((newItem) => newItem.id === oldItem.id)) {
      unlinkedValues.push({ id: oldItem.id, unlink: true });
    }
  });

  const result = [...addedValues, ...unlinkedValues];
  if (result.length === 0) return null;
  return result;
}

export default mergeAndUnlinkMultiSelectValues;
