import {
  CheckboxButtonGridValue,
  CheckboxButtonGroupOption,
} from '@lib/components/CheckboxButtonGrid/types';
import isValueExists from '@lib/components/CheckboxButtonGrid/utils/isValueExists';

export default function getNewValues(
  fieldValue: CheckboxButtonGridValue,
  row: CheckboxButtonGroupOption,
  column: CheckboxButtonGroupOption,
  limitToOneRespPerRow?: boolean,
  limitToOneRespPerColumn?: boolean,
) {
  const newValue = { valueId: column.id, optionId: row.id };
  const valueExists = isValueExists(fieldValue, row, column);
  if (limitToOneRespPerRow && limitToOneRespPerColumn) {
    return [
      ...fieldValue.filter(
        ({ valueId, optionId }) =>
          !(valueId === column.id || optionId === row.id),
      ),
      newValue,
    ];
  }
  if (limitToOneRespPerRow) {
    return [
      ...fieldValue.filter(({ optionId }) => optionId !== row.id),
      newValue,
    ];
  }
  if (limitToOneRespPerColumn) {
    return [
      ...fieldValue.filter(({ valueId }) => valueId !== column.id),
      newValue,
    ];
  }
  if (valueExists) {
    return fieldValue.filter(
      ({ valueId, optionId }) =>
        !(valueId === column.id && optionId === row.id),
    );
  }
  return [...fieldValue, newValue];
}
