import _get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import {
  FormTemplateVersion,
  useFormTemplateVersionQuery,
} from 'graphql-common';
import { NEW } from '@lib/enums/common';
import { showGraphQLErrors } from '@lib/utils/graphQLErrors';
import { getFormTemplateVersionValuesFromQueryData } from 'routes/FormTemplate/utils/getFormTemplateValuesFromQueryData';
import { FormTemplateMode } from 'routes/FormTemplate/enums';

type Args = {
  duplicatedVersionId?: string;
  id: string;
  versionId: string;
  viewMode: FormTemplateMode;
};

export default function useFormTemplateVersionQueryHook(args: Args) {
  const { id, versionId, duplicatedVersionId, viewMode } = args;
  const { t } = useTranslation();

  const formTemplateVersionQuery = useFormTemplateVersionQuery({
    fetchPolicy: 'network-only',
    skip: !(id && versionId) || (versionId === NEW && !duplicatedVersionId),
    variables: {
      id:
        duplicatedVersionId && versionId === NEW
          ? duplicatedVersionId
          : versionId,
      formTemplateId: id,
    },
    onError: (error) => showGraphQLErrors(error, t),
  });
  const formTemplateVersion = _get(
    formTemplateVersionQuery,
    'data.data',
  ) as FormTemplateVersion;
  const formTemplateVersionData = getFormTemplateVersionValuesFromQueryData(
    formTemplateVersion,
    viewMode === FormTemplateMode.Duplicate ||
      (viewMode === FormTemplateMode.EditVersion && versionId === NEW),
  );
  const formTemplateVersionLoading = _get(formTemplateVersionQuery, 'loading');
  const formTemplateVersionError = _get(formTemplateVersionQuery, 'error');
  const formTemplateVersionRefetch = _get(formTemplateVersionQuery, 'refetch');
  const formTemplateVersionDataStatus = _get(formTemplateVersionData, 'status');

  return {
    formTemplateVersion,
    formTemplateVersionData,
    formTemplateVersionDataStatus,
    formTemplateVersionError,
    formTemplateVersionLoading,
    formTemplateVersionRefetch,
  };
}
