import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ComponentEnum, FormCategory, LevelEnum } from 'graphql-common';
import FormTemplateCategories from 'components/FormTemplateCategories/FormTemplateCategories';
import BreadCrumbs from '@lib/components/BreadCrumbs/BreadCrumbs';
import usePrevious from '@lib/hooks/usePrevious';
import { getBreadCrumbsDataByKey } from 'utils/breadcrumbs';
import useOutletContext, { ContextProps } from 'hooks/useOutletContext';
import useActions from './utils/useActions';
import useModalManagement from './utils/useModalManagement';

function FormTemplateCategoriesContainer() {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const [selectedCategory, setSelectedCategory] = useState<FormCategory | null>(
    null,
  );

  const { getUserAccessByComponent }: ContextProps = useOutletContext();
  const isViewOnly =
    getUserAccessByComponent(ComponentEnum.FormCategoriesManagement) ===
    LevelEnum.Read;
  const isTasksAvailable =
    getUserAccessByComponent(ComponentEnum.TasksManagement) ===
    LevelEnum.LimitedRead;

  const {
    closeCategoryModal,
    closeDeleteModal,
    isCategoryModalOpened,
    isDeleteModalOpened,
    openCategoryModal,
    openDeleteModal,
  } = useModalManagement({ setSelectedCategory });
  const isCategoryModalOpenedPrev = usePrevious(isCategoryModalOpened);

  const {
    formCategories,
    formCategoriesError,
    formCategoriesFirstLoading,
    formCategoriesLoading,
    formCategoryData,
    formCategoryMutationError,
    formCategoryMutationLoading,
    formCategoryMutationReset,
    formCategoryQueryError,
    formCategoryQueryLoading,
    onConfirmedDelete,
    onDeleteHandler,
    onEditHandler,
    onSubmitFormCategoryForm,
    totalFormCategoriesCount,
  } = useActions({
    closeCategoryModal,
    closeDeleteModal,
    openCategoryModal,
    openDeleteModal,
    selectedCategory,
    setSelectedCategory,
  });

  useEffect(() => {
    if (isCategoryModalOpenedPrev && !isCategoryModalOpened) {
      formCategoryMutationReset();
    }
  }, [
    isCategoryModalOpened,
    formCategoryMutationReset,
    isCategoryModalOpenedPrev,
  ]);

  return (
    <>
      <BreadCrumbs data={getBreadCrumbsDataByKey({ pathname, t })} />
      <FormTemplateCategories
        closeCategoryModal={closeCategoryModal}
        closeDeleteModal={closeDeleteModal}
        formCategories={formCategories}
        formCategoriesError={formCategoriesError}
        formCategoriesFirstLoading={formCategoriesFirstLoading}
        formCategoriesLoading={formCategoriesLoading}
        formCategoryMutationError={formCategoryMutationError}
        formCategoryMutationLoading={formCategoryMutationLoading}
        formCategoryData={formCategoryData as FormCategory}
        formCategoryQueryError={formCategoryQueryError}
        formCategoryQueryLoading={formCategoryQueryLoading}
        isCategoryModalOpened={isCategoryModalOpened}
        isDeleteModalOpened={isDeleteModalOpened}
        onAddHandler={openCategoryModal}
        onConfirmedDelete={onConfirmedDelete}
        onDelete={onDeleteHandler}
        onEditHandler={onEditHandler}
        onSubmitFormCategoryForm={onSubmitFormCategoryForm}
        selectedCategory={selectedCategory}
        totalFormCategoriesCount={totalFormCategoriesCount}
        isViewOnly={isViewOnly}
        isTasksAvailable={isTasksAvailable}
      />
    </>
  );
}

export default FormTemplateCategoriesContainer;
