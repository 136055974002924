import React from 'react';
import {
  components as DefaultSelectComponents,
  ContainerProps,
} from 'react-select';
import classNames from 'classnames';
import styles from '../Select.module.scss';

function SelectContainer({ children, ...props }: ContainerProps): JSX.Element {
  const { isFocused, selectProps, getValue } = props;
  // @ts-ignore TODO: fix it (https://react-select.com/typescript#custom-select-props)
  const { hasError, isMulti } = selectProps;
  const value = getValue();
  return (
    <DefaultSelectComponents.SelectContainer
      {...props}
      className={classNames(styles.selectContainer, {
        focused: isFocused,
        [styles.selectHasError]: hasError,
        [styles.selectHasValue]: value?.length,
        isMulti,
      })}
    >
      {children}
    </DefaultSelectComponents.SelectContainer>
  );
}

export default SelectContainer;
