import { useLocation, useNavigate } from 'react-router-dom';
import {
  Task,
  TaskCompletionStatusEnum,
  TaskScopeNameEnum,
  useTaskCompletionStatusUpdateMutation,
} from 'graphql-common';
import { useTranslation } from 'react-i18next';
import { APP_URLS } from 'constants/urls';
import usePaginationParams from '@lib/hooks/usePaginationParams';
import useSearchParam from '@lib/hooks/useSearchParam';
import useTabParam from '@lib/hooks/useTabParam';
import { showGraphQLErrors } from '@lib/utils/graphQLErrors';
import toast from '@lib/components/Toast/Toast';
import useFilterParams from '@lib/hooks/useFilterParams';
import useDashboardOverviewTasksQueryHook from 'utils/fetch/useDashboardOverviewTasksQueryHook';
import useDashboardTimelineTasksQueryHook from 'utils/fetch/useDashboardTimelineTasksQueryHook';
import useDashboardTasksCompletionsQueryHook from 'utils/fetch/useDashboardTasksCompletionsQueryHook';
import usePrevious from '@lib/hooks/usePrevious';
import { GetUrlParams } from '@lib/enums/urls';
import _get from 'lodash/get';

export default function useActions() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [tabParam, setTabParam] = useTabParam(TaskScopeNameEnum.DashboardAll);
  const prevTabParam = usePrevious(tabParam);
  const [searchQuery] = useSearchParam();
  const [filterParams] = useFilterParams();
  const [paginationParams] = usePaginationParams();
  const isTimeline = pathname === APP_URLS.dashboard.timeline.path;

  // Tasks
  const {
    refetchTasks: refetchOverviewTasks,
    taskCounterData: overviewTaskCounterData,
    tasks: overviewTasks,
    tasksError: overviewTasksError,
    tasksFirstLoading: overviewTasksFirstLoading,
    tasksLoading: overviewTasksLoading,
    totalTasksCount: totalOverviewTasksCount,
  } = useDashboardOverviewTasksQueryHook({
    searchQuery,
    filterParams,
    paginationParams,
    tabParam,
    skip: isTimeline,
  });
  const {
    // refetchTasks: refetchTimelineTasks,
    taskCounterData: timelineTaskCounterData,
    tasks: timelineTasks,
    tasksError: timelineTasksError,
    tasksFirstLoading: timelineTasksFirstLoading,
    tasksLoading: timelineTasksLoading,
    totalTasksCount: totalTimelineTasksCount,
  } = useDashboardTimelineTasksQueryHook({
    searchQuery,
    filterParams,
    paginationParams,
    tabParam,
    skip: !isTimeline,
  });
  const {
    loading: timelineLoading,
    data: timelineData,
    dataGroupBy: timelineGroupBy,
  } = useDashboardTasksCompletionsQueryHook({
    tasks: timelineTasks,
    filterParams,
    loading: timelineTasksLoading,
    skip: !isTimeline || timelineTasksLoading,
  });

  // Bulk Scheduling
  const [taskResultStateUpdateMutation, taskResultStateUpdateResult] =
    useTaskCompletionStatusUpdateMutation({
      onCompleted: ({ data: responseData }) => {
        if (responseData) {
          toast({ content: t('task-result-state-successfully-updated') });
        }
        refetchOverviewTasks();
      },
      onError: (error) => showGraphQLErrors(error, t),
    });
  const {
    error: taskResultStateUpdateError,
    loading: taskResultStateUpdateLoading,
  } = taskResultStateUpdateResult;

  // Actions
  const onViewHandler = (task?: Task) => {
    if (task) {
      const { id } = task;
      navigate(
        APP_URLS.dashboard.tasks.view.getDynamicPath({
          pathParts: { id },
        }),
      );
    }
  };

  const onResultStateChangeHandler = (
    taskId: string,
    status: TaskCompletionStatusEnum,
  ) => {
    if (taskId && status) {
      taskResultStateUpdateMutation({
        variables: { taskId, attributes: { status } },
      });
    }
  };

  const onCreateIntervention = (item?: Task) => {
    navigate(
      APP_URLS.dashboard.interventions.add.getPathWithQuery({
        [GetUrlParams.AssetId]: item?.assetId,
        [GetUrlParams.SiteAreaId]: item?.siteArea?.id,
        [GetUrlParams.SiteId]: item?.site?.id,
        [GetUrlParams.TaskId]: item?.id,
      }),
    );
  };

  const onViewIntervention = (item?: Task) => {
    const interventionId = _get(item, 'intervention.id');
    if (interventionId) {
      navigate(
        APP_URLS.dashboard.interventions.view.getDynamicPath({
          pathParts: { id: interventionId },
        }),
      );
    }
  };

  const taskCounterData = isTimeline
    ? timelineTaskCounterData
    : overviewTaskCounterData;
  const tasks = isTimeline ? timelineTasks : overviewTasks;
  const tasksError = isTimeline ? timelineTasksError : overviewTasksError;
  const tasksFirstLoading = isTimeline
    ? timelineTasksFirstLoading
    : overviewTasksFirstLoading;
  const tasksLoading = isTimeline ? timelineTasksLoading : overviewTasksLoading;
  const totalTasksCount = isTimeline
    ? totalTimelineTasksCount
    : totalOverviewTasksCount;

  return {
    onCreateIntervention,
    onViewIntervention,
    onResultStateChangeHandler,
    onViewHandler,
    prevTabParam,
    setTabParam,
    tabParam,
    taskCounterData,
    taskResultStateUpdateError,
    taskResultStateUpdateLoading,
    tasks,
    tasksError,
    tasksFirstLoading,
    tasksLoading,
    timelineData,
    timelineGroupBy,
    timelineLoading,
    totalTasksCount,
  };
}
