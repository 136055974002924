import React from 'react';
import _get from 'lodash/get';
import { TFunction } from 'i18next';
import { User } from 'graphql-common';
import { CellProps } from '@lib/components/Table/types';
import TextCell from '@lib/components/Table/commonComponents/TextCell/TextCell';
import UserPreviewCell from '@lib/components/Table/commonComponents/UserPreviewCell/UserPreviewCell';
import ChipCell from '@lib/components/Table/commonComponents/ChipCell/ChipCell';
import { ChipTypes } from '@lib/components/Chip/Chip';
import { getRoleOption } from '@lib/utils/roles';

type Args = {
  t: TFunction<'translation', undefined>;
};

export default function getTableColumns({ t }: Args) {
  return [
    {
      id: 'personalId',
      Header: 'ID',
      accessor: 'personalId',
      Cell: (props: CellProps<User>) => <TextCell {...props} />,
      minWidth: 80,
      width: 80,
      sortable: true,
    },
    {
      id: 'fullName',
      Header: t('name'),
      Cell: ({ item }: CellProps<User>) => (
        <UserPreviewCell
          avatar={
            item.avatarAttached?.urls?.small ||
            item.avatarAttached?.urls?.original
          }
          name={`${item.firstName} ${item.lastName}`}
          email={item.email}
        />
      ),
      minWidth: 308,
      width: 308,
      sortable: true,
    },
    {
      id: 'role',
      Header: t('role'),
      accessorFn: ({ item }: CellProps<User>) => {
        const role = getRoleOption(item.role)?.labelKey;
        return role ? t(role) : '-';
      },
      Cell: (props: CellProps<User>) => <TextCell {...props} />,
      maxWidth: 150,
      minWidth: 150,
      width: 150,
      sortable: true,
    },
    {
      id: 'status',
      Header: '',
      Cell: ({ item }: CellProps<User>) => {
        const deactivated = _get(item, 'deactivated', false);
        if (deactivated) {
          return (
            <ChipCell
              label={t('deactivated')}
              leftIcon="do_not_disturb_on"
              leftIconSymbolsOutlined
              type={ChipTypes.chipDisabled}
            />
          );
        }
        return '';
      },
      maxWidth: 150,
      minWidth: 130,
      width: 130,
    },
  ];
}
