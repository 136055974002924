import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ComponentEnum, InterventionCategory, LevelEnum } from 'graphql-common';
import InterventionCategories from 'components/InterventionCategories/InterventionCategories';
import BreadCrumbs from '@lib/components/BreadCrumbs/BreadCrumbs';
import usePrevious from '@lib/hooks/usePrevious';
import { getBreadCrumbsDataByKey } from 'utils/breadcrumbs';
import useOutletContext, { ContextProps } from 'hooks/useOutletContext';
import useActions from './utils/useActions';
import useModalManagement from './utils/useModalManagement';

function InterventionCategoriesContainer() {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const [selectedCategory, setSelectedCategory] =
    useState<InterventionCategory | null>(null);

  const { getUserAccessByComponent }: ContextProps = useOutletContext();
  const isInterventionsLocked =
    getUserAccessByComponent(ComponentEnum.InterventionsManagement) ===
    LevelEnum.LimitedRead;
  const isInterventionCategoriesViewOnly =
    getUserAccessByComponent(ComponentEnum.InterventionCategoriesManagement) ===
    LevelEnum.Read;

  const {
    closeCategoryModal,
    closeDeleteModal,
    isCategoryModalOpened,
    isDeleteModalOpened,
    openCategoryModal,
    openDeleteModal,
  } = useModalManagement({ setSelectedCategory });
  const isCategoryModalOpenedPrev = usePrevious(isCategoryModalOpened);

  const {
    categoryMutationReset,
    categoryQuery,
    onConfirmedDelete,
    onDeleteHandler,
    onEditHandler,
    onSubmitAssetCategoryForm,
    categoryMutationError,
    categoryMutationLoading,
    useInterventionCategoriesQueryHookResult,
  } = useActions({
    closeCategoryModal,
    closeDeleteModal,
    openCategoryModal,
    openDeleteModal,
    selectedCategory,
    setSelectedCategory,
  });

  useEffect(() => {
    if (isCategoryModalOpenedPrev && !isCategoryModalOpened) {
      categoryMutationReset();
    }
  }, [isCategoryModalOpened, categoryMutationReset, isCategoryModalOpenedPrev]);

  return (
    <>
      <BreadCrumbs data={getBreadCrumbsDataByKey({ pathname, t })} />
      <InterventionCategories
        isInterventionsLocked={isInterventionsLocked}
        isInterventionCategoriesViewOnly={isInterventionCategoriesViewOnly}
        closeCategoryModal={closeCategoryModal}
        closeDeleteModal={closeDeleteModal}
        categories={useInterventionCategoriesQueryHookResult.collection}
        categoriesError={useInterventionCategoriesQueryHookResult.error}
        categoriesFirstLoading={
          useInterventionCategoriesQueryHookResult.firstLoading
        }
        categoriesLoading={useInterventionCategoriesQueryHookResult.loading}
        categoryMutationError={categoryMutationError}
        categoryMutationLoading={categoryMutationLoading}
        categoryData={categoryQuery.data?.data as InterventionCategory}
        categoryQueryError={categoryQuery.error}
        categoryQueryLoading={categoryQuery.loading}
        isCategoryModalOpened={isCategoryModalOpened}
        isDeleteModalOpened={isDeleteModalOpened}
        onAddHandler={openCategoryModal}
        onConfirmedDelete={onConfirmedDelete}
        onDelete={onDeleteHandler}
        onEditHandler={onEditHandler}
        onSubmitAssetCategoryForm={onSubmitAssetCategoryForm}
        selectedCategory={selectedCategory}
        totalAssetCategoriesCount={
          useInterventionCategoriesQueryHookResult.totalCount
        }
      />
    </>
  );
}

export default InterventionCategoriesContainer;
