export function getMinutesCountFromTime(time: string): number | null {
  if (!time) {
    return null;
  }

  const [hours, minutes] = time.split(':').map(Number);

  if (
    Number.isNaN(hours) ||
    Number.isNaN(minutes) ||
    minutes < 0 ||
    minutes > 59
  ) {
    throw new Error('Invalid time format');
  }

  return hours * 60 + minutes;
}
