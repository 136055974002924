import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  AssetCategory,
  useAssetCategoryCreateMutation,
  useAssetCategoryDestroyMutation,
  useAssetCategoryQuery,
  useAssetCategoryUpdateMutation,
} from 'graphql-common';
import toast from '@lib/components/Toast/Toast';
import usePaginationParams from '@lib/hooks/usePaginationParams';
import onDestroyListItemCallBack from '@lib/utils/onDestroyListItemCallBack';
import { showGraphQLErrors } from '@lib/utils/graphQLErrors';
import useSearchParam from '@lib/hooks/useSearchParam';
import useAssetCategoriesQueryHook from 'utils/fetch/useAssetCategoriesQueryHook';
import { Values } from '@lib/interfaces/form';
import _get from 'lodash/get';
import AssetFieldNames from '@lib/enums/fieldNames/assetCategoryFieldNames';
import { useCallback } from 'react';
import getPreparedAssetCategoryDataFromValues from './getPreparedAssetCategoryDataFromValues';

interface UseActionsProps {
  closeCategoryModal: () => void;
  closeDeleteModal: () => void;
  id?: string | null;
  openCategoryModal: () => void;
  openDeleteModal: () => void;
  selectedCategory: AssetCategory | null;
  setSelectedCategory: (v: AssetCategory) => void;
}

export default function useActions(props: UseActionsProps) {
  const {
    closeCategoryModal,
    closeDeleteModal,
    openCategoryModal,
    openDeleteModal,
    selectedCategory,
    setSelectedCategory,
  } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { search, pathname } = useLocation();
  const [searchQuery] = useSearchParam();
  const [paginationParams] = usePaginationParams();

  // Asset categories
  const {
    refetch: refetchAssetCategories,
    error: assetCategoriesError,
    firstLoading: assetCategoriesFirstLoading,
    collection: assetCategories,
    totalCount: totalAssetCategoriesCount,
    totalPages,
    loading: assetCategoriesLoading,
  } = useAssetCategoriesQueryHook({
    searchQuery,
    paginationParams,
  });

  // Asset category
  const categoryQuery = useAssetCategoryQuery({
    skip: selectedCategory === null,
    variables: {
      id: selectedCategory?.id || '',
    },
  });
  const [assetCategoryCreateMutation, assetCategoryCreateMutationResult] =
    useAssetCategoryCreateMutation({
      onCompleted: () => {
        refetchAssetCategories();
        if (closeCategoryModal) closeCategoryModal();
        toast({ content: t('create-asset-category-success') });
      },
      onError: (error) => showGraphQLErrors(error, t),
    });
  const [assetCategoryUpdateMutation, assetCategoryUpdateMutationResult] =
    useAssetCategoryUpdateMutation({
      onCompleted: () => {
        refetchAssetCategories();
        if (closeCategoryModal) closeCategoryModal();
        toast({ content: t('update-asset-category-success') });
      },
      onError: (error) => showGraphQLErrors(error, t),
    });

  // Delete
  const [destroyMutation] = useAssetCategoryDestroyMutation({
    onCompleted: () => {
      closeDeleteModal();
      onDestroyListItemCallBack({
        collection: assetCategories,
        navigate,
        pathname,
        refetch: refetchAssetCategories,
        search,
        totalPages,
      });
    },
    onError: (error) => showGraphQLErrors(error, t),
  });

  const onEditHandler = (v?: AssetCategory) => {
    if (v) {
      setSelectedCategory(v);
      openCategoryModal();
    }
  };

  const onDeleteHandler = (v: AssetCategory) => {
    setSelectedCategory(v);
    openDeleteModal();
  };

  const onDestroyHandler = (id?: string) => {
    if (id) {
      destroyMutation({
        variables: {
          id,
        },
      });
    }
  };

  const onConfirmedDelete = () => {
    closeDeleteModal();
    toast({
      content: t('destroy-category-success'),
      closeCallback: () => {
        if (selectedCategory) {
          onDestroyHandler(selectedCategory?.id);
        }
      },
    });
  };

  const onAssetCategoryCreate = (values: Values) => {
    const attributes = getPreparedAssetCategoryDataFromValues(values);
    assetCategoryCreateMutation({ variables: { attributes } });
  };

  const onAssetCategoryUpdate = (values: Values) => {
    const attributes = getPreparedAssetCategoryDataFromValues(values);
    const refreshQrPrefixForAssets = _get(
      values,
      AssetFieldNames.RefreshQrPrefixForAssets,
      false,
    ) as boolean;
    assetCategoryUpdateMutation({
      variables: {
        attributes,
        id: selectedCategory?.id || '',
        refreshQrPrefixForAssets,
      },
    });
  };

  const {
    loading: assetCategoryQueryLoading,
    error: assetCategoryQueryError,
    data: assetCategoryData,
  } = categoryQuery;
  const {
    error: assetCategoryCreateMutationError,
    loading: assetCategoryCreateMutationLoading,
    reset: assetCategoryCreateMutationReset,
  } = assetCategoryCreateMutationResult;
  const {
    error: assetCategoryUpdateMutationError,
    loading: assetCategoryUpdateMutationLoading,
    reset: assetCategoryUpdateMutationReset,
  } = assetCategoryUpdateMutationResult;

  const assetCategoryMutationError = selectedCategory
    ? assetCategoryUpdateMutationError
    : assetCategoryCreateMutationError;
  const assetCategoryMutationLoading = selectedCategory
    ? assetCategoryUpdateMutationLoading
    : assetCategoryCreateMutationLoading;
  const assetCategoryMutationReset = useCallback(() => {
    assetCategoryUpdateMutationReset();
    assetCategoryCreateMutationReset();
  }, [assetCategoryCreateMutationReset, assetCategoryUpdateMutationReset]);
  const onSubmitAssetCategoryForm = selectedCategory
    ? onAssetCategoryUpdate
    : onAssetCategoryCreate;

  return {
    assetCategories,
    assetCategoriesError,
    assetCategoriesFirstLoading,
    assetCategoriesLoading,
    assetCategoryData: assetCategoryData?.data,
    assetCategoryMutationError,
    assetCategoryMutationLoading,
    assetCategoryMutationReset,
    assetCategoryQueryError,
    assetCategoryQueryLoading,
    onConfirmedDelete,
    onDeleteHandler,
    onEditHandler,
    onSubmitAssetCategoryForm,
    totalAssetCategoriesCount,
  };
}
