import { LazyQueryExecFunction } from '@apollo/client';
import {
  FilterGroupingEnum,
  SortDirectionEnum,
  Task,
  TaskFilterInputObject,
  TaskScopeInputObject,
  TasksSearchListQuery,
  TasksSearchListQueryVariables,
} from 'graphql-common';
import { DEFAULT_SEARCH_LIST_VARIABLES } from '@lib/enums/urls';
import getLoadOptionsResult from '@lib/utils/getLoadOptionsResult';
import createSearchFilters from '@lib/utils/createSearchFilters';
import { LoadOptionsParams } from '@lib/components/Select/types';

type FetchLazyQuery = LazyQueryExecFunction<TasksSearchListQuery, any>;

const fetchSearchList = async (
  searchQuery: string,
  fetchLazyQuery: FetchLazyQuery,
  page: number,
  scope: TaskScopeInputObject,
  selectedSiteIds: string[],
  selectedSiteAreaIds?: string[],
  selectedAssetIds?: string[],
) => {
  const options: { variables: TasksSearchListQueryVariables } = {
    variables: {
      ...DEFAULT_SEARCH_LIST_VARIABLES,
      page,
      scope,
      sorts: { name: SortDirectionEnum.Asc },
    },
  };
  const filters: TaskFilterInputObject[] = [];
  if (searchQuery) {
    filters.push(
      ...createSearchFilters(searchQuery, [
        { fieldName: 'name' },
        { fieldName: 'formCategoryName' },
        { fieldName: 'siteName' },
        { fieldName: 'siteAreaName' },
        { fieldName: 'siteCode' },
        { fieldName: 'siteAreaCode' },
        { fieldName: 'priority', predicateType: 'eq' },
        { fieldName: 'deadlineAt', predicateType: 'eq' },
      ]),
    );
  }
  if (selectedSiteIds.length) {
    filters.push({
      siteId: {
        grouping: FilterGroupingEnum.And,
        predicate: {
          in: selectedSiteIds,
        },
        filterGroup: 'siteFilter',
      },
    });
  }
  if (selectedSiteAreaIds?.length) {
    filters.push({
      siteAreaId: {
        grouping: FilterGroupingEnum.And,
        predicate: {
          in: selectedSiteAreaIds,
        },
        filterGroup: 'siteAreaFilter',
      },
    });
  }
  if (selectedAssetIds?.length) {
    filters.push({
      assetId: {
        grouping: FilterGroupingEnum.And,
        predicate: {
          in: selectedAssetIds,
        },
        filterGroup: 'assetFilter',
      },
    });
  }
  if (filters.length) {
    options.variables.filters = filters;
  }
  const { data } = await fetchLazyQuery(options);
  return getLoadOptionsResult(
    data,
    (dataItem: Task) => ({
      value: dataItem.id,
      label: dataItem.name,
      data: {
        formTemplateVersion: dataItem.formTemplateVersion,
      },
    }),
    searchQuery,
  );
};

export const getTasksLoadOptions =
  ({
    fetchLazyQuery,
    scope,
    selectedSiteIds,
    selectedSiteAreaIds,
    selectedAssetIds,
  }: {
    fetchLazyQuery: FetchLazyQuery;
    scope: TaskScopeInputObject;
    selectedSiteIds: string[];
    selectedSiteAreaIds?: string[];
    selectedAssetIds?: string[];
  }) =>
  (searchQuery: string, loadedOptions: unknown, params: LoadOptionsParams) =>
    fetchSearchList(
      searchQuery,
      fetchLazyQuery,
      params?.page || 1,
      scope,
      selectedSiteIds,
      selectedSiteAreaIds,
      selectedAssetIds,
    );
