import React from 'react';
import { TFunction } from 'i18next';
import { FieldChangeCallBackArgs, Values } from '@lib/interfaces/form';
import _get from 'lodash/get';
import Typography from '@lib/components/Typography/Typography';
import {
  MAX_TEXT_EDITOR_FIELD_LENGTH,
  MAX_TEXT_FIELD_LENGTH,
  Steps,
} from '@lib/enums/form';
import Input from '@lib/components/Input/Input';
import * as Yup from 'yup';
import Select, {
  SelectComponents,
  SelectProps,
} from '@lib/components/Select/Select';
import { getOptionShape } from '@lib/utils/yup';
import { getTaskPriorityOptions } from '@lib/utils/priority';
import styles from '@lib/assets/styles/Form.module.scss';
import { GetLoadOptions } from '@lib/components/Select/types';
import UserOption from '@lib/components/Select/components/UserOption';
import AreaOption from '@lib/components/Select/components/AreaOption';
import AssetOption from '@lib/components/Select/components/AssetOption';
import UserSingleValue from '@lib/components/Select/components/UserSingleValue';
import AssetSingleValue from '@lib/components/Select/components/AssetSingleValue';
import ChipSingleValue from '@lib/components/Select/components/ChipSingleValue';
import TaskAssignationsOptionsAddItemComponent from 'routes/Task/TaskAssignationsOptionsAddItemComponent/TaskAssignationsOptionsAddItemComponent';
import TaskAssignationsFieldOptions from 'routes/Task/TaskAssignationsFieldOptions/TaskAssignationsFieldOptions';
import { FieldNames } from 'routes/Task/enums';
import VersionOption from 'components/VersionOption/VersionOption';
import Button, {
  ButtonSizes,
  ButtonTypes,
} from '@lib/components/Button/Button';
import TextEditor from '@lib/components/TextEditor/TextEditor';
import { getFieldValidation } from '@lib/utils/validationUtils';

type TaskFieldsArgs = {
  getApproversLoadOptions: GetLoadOptions;
  getAssetsLoadOptions: GetLoadOptions;
  getSiteAreasLoadOptions: GetLoadOptions;
  getSitesLoadOptions: GetLoadOptions;
  getTemplateVersionsLoadOptions?: GetLoadOptions;
  getTemplatesLoadOptions?: GetLoadOptions;
  isCreateForm?: boolean;
  onCreateNewFormTemplate?: () => void;
  onViewFormTemplate?: (
    templateFormId: string,
    templateFormVersionId: string,
  ) => void;
  step?: Steps;
  t: TFunction<'translation', undefined>;
};

const defaultSelectEntityBySiteIdProps = {
  isDisabledFn: (values: Values) =>
    _get(values, `${FieldNames.site}.value`) === undefined,
  getFieldKeyFn: (values: Values, name?: string) => {
    const selectedSiteId = _get(values, `${FieldNames.site}.value`);
    if (selectedSiteId) return `${name}-site-${selectedSiteId}`;
    return FieldNames.site;
  },
};

export default function getTaskFields({
  getApproversLoadOptions,
  getAssetsLoadOptions,
  getSiteAreasLoadOptions,
  getSitesLoadOptions,
  getTemplateVersionsLoadOptions,
  getTemplatesLoadOptions,
  isCreateForm,
  step = Steps.step1,
  onCreateNewFormTemplate,
  onViewFormTemplate,
  t,
}: TaskFieldsArgs) {
  return [
    {
      name: 'task-details',
      customRender: () => (
        <Typography variant="h3">{t('task-details')}</Typography>
      ),
      step,
    },
    {
      name: FieldNames.taskName,
      label: t('task-name'),
      element: Input,
      validation: Yup.string()
        .trim()
        .max(MAX_TEXT_FIELD_LENGTH, t('max-text-length-field-error'))
        .required(t('required-field-error')),
      step,
    },
    {
      name: FieldNames.taskDescription,
      label: t('task-description'),
      element: TextEditor,
      validation: getFieldValidation(false, MAX_TEXT_EDITOR_FIELD_LENGTH, t),
      step,
    },
    {
      name: FieldNames.priority,
      label: t('task-priority'),
      element: Select,
      validation: Yup.object()
        .shape(getOptionShape(t, true))
        .required(t('required-field-error')),
      componentProps: {
        options: getTaskPriorityOptions(),
        isSearchable: false,
      },
      step,
    },
    {
      name: `${FieldNames.template}-title`,
      customRender: () => (
        <Typography variant="h3" className={styles.blockStart}>
          {t('attach-form-template')}
        </Typography>
      ),
      step,
      isVisibleFn: () =>
        !!getTemplatesLoadOptions && !!getTemplateVersionsLoadOptions,
    },
    {
      name: FieldNames.template,
      label: t('form-template'),
      element: Select,
      validation: Yup.object()
        .shape(getOptionShape(t, true))
        .required(t('required-field-error')),
      componentProps: {
        isAsync: true,
        isClearable: false,
        getLoadOptions: getTemplatesLoadOptions,
        defaultOptions: false,
        createNewOptionProps: {
          title: t('create-new'),
          onCLick: onCreateNewFormTemplate,
        },
      } as SelectProps,
      fieldChangeCallBack: ({
        type,
        setValue,
        name,
      }: FieldChangeCallBackArgs) => {
        if (type === 'change' && name === FieldNames.template) {
          setValue(FieldNames.templateVersion, null, { shouldValidate: false });
        }
      },
      step,
      formItemClassName: styles.formColumn9,
      useKeyRefreshOnWindowFocus: true,
      isVisibleFn: () =>
        !!getTemplatesLoadOptions && !!getTemplateVersionsLoadOptions,
    },
    {
      name: FieldNames.templateVersion,
      label: t('version'),
      element: Select,
      validation: Yup.object()
        .shape(getOptionShape(t, true))
        .required(t('required-field-error')),
      componentProps: {
        isAsync: true,
        isClearable: false,
        getLoadOptions: getTemplateVersionsLoadOptions,
        defaultOptions: false,
        components: {
          Option: VersionOption,
        },
        disabledTooltipProps: {
          body: t('choose-form-template-first'),
        },
      } as SelectProps,
      isDisabledFn: (values: Values, name?: string) => {
        if (!name) return false;
        const depsName = name.replace(
          FieldNames.templateVersion,
          `${FieldNames.template}.value`,
        );
        const depsValue = _get(values, depsName);
        return depsValue === undefined;
      },
      step,
      formItemClassName: styles.formColumn3,
      getFieldKeyFn: (values: Values, name?: string) => {
        const templateId = _get(values, `${FieldNames.template}.value`);
        if (templateId) {
          return `${name}-${FieldNames.templateVersion}-${templateId}`;
        }
        return FieldNames.templateVersion;
      },
      useKeyRefreshOnWindowFocus: true,
      isVisibleFn: () =>
        !!getTemplatesLoadOptions && !!getTemplateVersionsLoadOptions,
    },
    {
      name: `${FieldNames.templateVersion}-view-btn`,
      customRender: (values: Values) =>
        onViewFormTemplate ? (
          <Button
            buttonText={t('view-template')}
            buttonType={ButtonTypes.primaryOutlined}
            buttonSize={ButtonSizes.small}
            rightIcon="arrow_outward"
            onClick={() =>
              onViewFormTemplate(
                _get(values, [FieldNames.template, 'value']),
                _get(values, [FieldNames.templateVersion, 'value']),
              )
            }
          />
        ) : (
          <div />
        ),
      isVisibleFn: (values: Values) =>
        onViewFormTemplate &&
        _get(values, [FieldNames.template, 'value']) &&
        _get(values, [FieldNames.templateVersion, 'value']) &&
        !!getTemplatesLoadOptions &&
        !!getTemplateVersionsLoadOptions,
      step,
    },
    {
      name: `${FieldNames.assignations}-title`,
      customRender: () => (
        <Typography variant="h3" className={styles.blockStart}>
          {t('assignation')}
        </Typography>
      ),
      step,
    },
    {
      name: FieldNames.site,
      label: t('site'),
      element: Select,
      validation: Yup.object()
        .shape(getOptionShape(t, true))
        .required(t('required-field-error')),
      componentProps: {
        isAsync: true,
        getLoadOptions: getSitesLoadOptions,
        defaultOptions: false,
        components: {
          SingleValue: ChipSingleValue,
        },
      } as SelectProps,
      fieldChangeCallBack: ({
        type,
        setValue,
        name,
      }: FieldChangeCallBackArgs) => {
        if (type === 'change' && name === FieldNames.site) {
          setValue(
            FieldNames.assignations,
            [{ [FieldNames.siteArea]: null, [FieldNames.asset]: null }],
            {
              shouldValidate: false,
            },
          );
          setValue(FieldNames.approver, null, { shouldValidate: false });
        }
      },
      step,
    },
    {
      name: FieldNames.assignations,
      subFieldsEntity: 'option',
      subFieldsMinLength: 1,
      subFieldsMaxLength: isCreateForm ? undefined : 1,
      fieldComponents: {
        FieldArrayAddItemComponent: TaskAssignationsOptionsAddItemComponent,
        FieldArrayEmptyStateComponent: TaskAssignationsOptionsAddItemComponent,
        FieldArrayItemWrapperComponent: TaskAssignationsFieldOptions,
      },
      subFields: [
        {
          name: FieldNames.siteArea,
          label: t('area'),
          element: Select,
          validation: Yup.object()
            .shape(getOptionShape(t, true))
            .required(t('required-field-error')),
          componentProps: {
            isAsync: true,
            getLoadOptions: getSiteAreasLoadOptions,
            defaultOptions: false,
            components: {
              Option: AreaOption,
              SingleValue: ChipSingleValue,
            },
            disabledTooltipProps: {
              body: t('choose-site-first'),
            },
          } as SelectProps,
          formItemClassName: styles.formColumn,
          fieldChangeCallBack: ({
            type,
            setValue,
            name,
          }: FieldChangeCallBackArgs) => {
            if (
              type === 'change' &&
              name &&
              name.indexOf(FieldNames.siteArea) > -1
            ) {
              setValue(
                name.replace(FieldNames.siteArea, FieldNames.asset),
                null,
                { shouldValidate: true },
              );
            }
          },
          ...defaultSelectEntityBySiteIdProps,
          step,
          hideErrorsSpace: true,
        },
        {
          name: FieldNames.asset,
          label: t('asset'),
          element: Select,
          validation: Yup.object().shape(getOptionShape(t)).nullable(),
          componentProps: {
            isAsync: true,
            getLoadOptions: getAssetsLoadOptions,
            defaultOptions: false,
            components: {
              Option: AssetOption,
              SingleValue: AssetSingleValue,
            } as unknown as SelectComponents,
            disabledTooltipProps: {
              body: t('choose-site-area-first'),
            },
          } as SelectProps,
          isDisabledFn: (values: Values, name?: string) => {
            if (!name) return false;
            const depsName = name.replace(
              FieldNames.asset,
              `${FieldNames.siteArea}.value`,
            );
            const depsValue = _get(values, depsName);
            return depsValue === undefined;
          },
          getFieldKeyFn: (values: Values, name?: string) => {
            if (!name) return FieldNames.asset;
            const selectedSiteId = _get(values, [FieldNames.site, 'value']);
            const depsName = name.replace(
              FieldNames.asset,
              `${FieldNames.siteArea}.value`,
            );
            const depsValue = _get(values, depsName);
            if (selectedSiteId && depsValue)
              return `${FieldNames.site}-${selectedSiteId}-${FieldNames.siteArea}-${depsValue}`;
            if (depsValue) return `${FieldNames.siteArea}-${depsValue}`;
            if (selectedSiteId) return `${FieldNames.site}-${selectedSiteId}`;
            return _get(
              values,
              `${FieldNames.siteArea}.value`,
              FieldNames.siteArea,
            ) as string;
          },
          formItemClassName: styles.formColumn,
          step,
          hideErrorsSpace: true,
        },
      ],
      step,
    },
    {
      name: FieldNames.approver,
      label: t('approver'),
      element: Select,
      validation: Yup.object()
        .shape(getOptionShape(t, true))
        .required(t('required-field-error')),
      componentProps: {
        isAsync: true,
        getLoadOptions: getApproversLoadOptions,
        defaultOptions: false,
        components: {
          Option: UserOption,
          SingleValue: UserSingleValue,
        } as unknown as SelectComponents,
        disabledTooltipProps: {
          body: t('choose-site-first'),
        },
      } as SelectProps,
      ...defaultSelectEntityBySiteIdProps,
      step,
    },
  ];
}
