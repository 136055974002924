import React from 'react';
import {
  components as DefaultSelectComponents,
  InputProps,
} from 'react-select';
import styles from '../Select.module.scss';

function Input(props: InputProps) {
  const { children } = props;
  return (
    <DefaultSelectComponents.Input {...props} className={styles.selectInput}>
      {children}
    </DefaultSelectComponents.Input>
  );
}

export default Input;
