import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import _get from 'lodash/get';
import classNames from 'classnames';
import { FilterGroupingEnum, InterventionAction, User } from 'graphql-common';
import getFullName from '@lib/utils/getFullName';
import Image from '@lib/components/Image/Image';
import AvatarIcon from '@lib/assets/icons/avatar.svg?react';
import { getFormattedDate, transformDate } from '@lib/utils/dateTimeHelpers';
import EntityViewStyles from '@lib/assets/styles/EntityView.module.scss';
import MaterialIcon from '@lib/components/MaterialIcon/MaterialIcon';
import Typography from '@lib/components/Typography/Typography';
import UsersAvatarsPreview from '@lib/components/UsersAvatarsPreview/UsersAvatarsPreview';
import ActionsMenu from '@lib/components/ActionsMenu/ActionsMenu';
import TabBar from '@lib/components/TabBar/TabBar';
import { TabBarItem as TabBarItemType } from '@lib/components/TabBar/constants';
import Carousel from '@lib/components/Carousel/Carousel';
import DocumentationPreview from '@lib/components/DocumentationPreview/DocumentationPreview';
import { DocumentationPreviewEnum } from '@lib/components/DocumentationPreview/enums';
import InterventionActionStatusChip from '@lib/components/InterventionActionStatusChip/InterventionActionStatusChip';
import { IconButtonTypes } from '@lib/components/IconButton/IconButton';
import { getTimeFromMinutesCount } from '@lib/utils/getTimeFromMinutesCount';
import useUsersQueryHook from 'utils/fetch/useUsersQueryHook';
import TextEditor from '@lib/components/TextEditor/TextEditor';
import { UseModalManagementResult } from '../utils/useModalManagement';
import { UseInterventionActionsResult } from '../utils/useInterventionActions';
import InterventionActionHistory from '../InterventionActionHistory/InterventionActionHistory';
import getInterventionActionViewTabs from '../utils/getInterventionActionViewTabs';
import getInterventionActionActionsMenu from '../utils/getInterventionActionActionsMenu';
import { InterventionActionViewMode } from '../enums';
import styles from './InterventionActionView.module.scss';

function UserPreview(props: { user: User | null }) {
  const { user } = props;
  if (!user?.id) return null;
  const { avatarAttached } = user;
  const fullName = getFullName(user);
  const url =
    _get(avatarAttached, 'urls.small') || _get(avatarAttached, 'urls.original');
  const userAvatar = url ? <Image src={url} /> : <AvatarIcon />;
  return (
    <div className={styles.userPreview}>
      {userAvatar}{' '}
      <Typography variant="caption" strong>
        {fullName}
      </Typography>
    </div>
  );
}

function DateUpdated(props: { date: string }) {
  const { date } = props;
  const { t } = useTranslation();
  return (
    <Typography variant="caption" className={styles.dateUpdated}>
      {t('updated')} {getFormattedDate(date)}
    </Typography>
  );
}

function Assignees(props: {
  usersData: User[];
  usersTotalCount: number;
  openInterventionAssigneesModal: () => void;
}) {
  const { usersData, usersTotalCount, openInterventionAssigneesModal } = props;
  const { t } = useTranslation();
  if (!usersTotalCount || !usersData?.length) return null;
  return (
    <div
      className={classNames(
        EntityViewStyles.infoItem,
        EntityViewStyles.infoItemCentered,
      )}
    >
      <p className={EntityViewStyles.infoItemLabel}>
        <MaterialIcon icon="person" symbolsOutlined />
        <Typography variant="caption">{t('assignees-label')}</Typography>
      </p>
      <div
        className={classNames(
          EntityViewStyles.infoItemContent,
          EntityViewStyles.infoItemContentFullWidth,
        )}
      >
        {usersData?.length ? (
          <UsersAvatarsPreview
            users={usersData}
            usersTotalCount={usersTotalCount}
            usersVisibleCount={4}
            onClick={openInterventionAssigneesModal}
            useChipForSingleUser
          />
        ) : (
          <Typography variant="body">-</Typography>
        )}
      </div>
    </div>
  );
}

export default function InterventionActionView({
  interventionAction,
  useModalManagementResult,
  useInterventionActionsResult,
  isViewOnly,
}: {
  interventionAction: InterventionAction;
  useModalManagementResult: UseModalManagementResult;
  useInterventionActionsResult: UseInterventionActionsResult;
  isViewOnly: boolean;
}) {
  const { t } = useTranslation();
  const [viewMode, onViewChange] = useState(InterventionActionViewMode.Details);
  const {
    attachmentsAttached,
    canDestroy,
    canUpdate,
    createdAt,
    createdBy,
    estimatedDate,
    estimatedTime,
    id,
    logisticTime,
    durationTime,
    text,
    updatedAt,
    closedAt,
  } = interventionAction;
  const actionsMenu = getInterventionActionActionsMenu({
    onEdit: canUpdate?.value
      ? () =>
          useInterventionActionsResult.openEditActionPage(interventionAction)
      : undefined,
    onDelete: canDestroy?.value
      ? () =>
          useModalManagementResult.openInterventionActionDestroyModal(
            interventionAction,
          )
      : undefined,
    t,
  });
  const tabs = getInterventionActionViewTabs({ t });
  const usersQueryHookResult = useUsersQueryHook({
    skip: !id,
    additionalFilters: [
      {
        interventionActionAssigneesActionId: {
          grouping: FilterGroupingEnum.Or,
          predicate: { eq: id },
        },
      },
    ],
  });
  const isAssigneesVisible = !(
    usersQueryHookResult.firstLoading || usersQueryHookResult.error
  );
  return (
    <div className={styles.actionView}>
      <div className={styles.actionViewHeader}>
        <div>
          <UserPreview user={createdBy} />
          <DateUpdated date={updatedAt || createdAt} />
        </div>
        <div>
          <InterventionActionStatusChip
            interventionAction={interventionAction}
            onStatusChangeHandler={
              useInterventionActionsResult.onActionStatusChangeHandler
            }
            isViewOnly={isViewOnly}
          />
        </div>
        <div>
          <ActionsMenu
            actions={actionsMenu}
            iconButtonType={IconButtonTypes.SecondaryFilled}
            iconButtonRounded
          />
        </div>
      </div>
      {isAssigneesVisible && (
        <div className={styles.actionAssignees}>
          <Assignees
            usersData={usersQueryHookResult.collection}
            usersTotalCount={usersQueryHookResult.totalCount}
            openInterventionAssigneesModal={() =>
              useModalManagementResult.openInterventionActionAssigneesModal(
                interventionAction,
              )
            }
          />
        </div>
      )}
      <TabBar
        items={tabs}
        active={viewMode}
        onActiveChange={({ value }: TabBarItemType) =>
          onViewChange(value as InterventionActionViewMode)
        }
        className={styles.tabBar}
      />
      <div className={styles.actionViewBody}>
        {InterventionActionViewMode.Details === viewMode && (
          <div>
            {text ? (
              <TextEditor
                value={text}
                readOnly
                className={styles.actionDescription}
                useCropBlurGray
              />
            ) : (
              <Typography variant="body" className={styles.actionDescription}>
                {text || '-'}
              </Typography>
            )}
            {!!attachmentsAttached?.length && (
              <Carousel
                withWrap
                key={`action-${id}-attachments-${attachmentsAttached.length}`}
                spaceBetween={16}
                slidesPerView="auto"
              >
                {attachmentsAttached.map((attachment) => (
                  <DocumentationPreview
                    key={uuidv4()}
                    attachment={attachment}
                    view={DocumentationPreviewEnum.BlockCard}
                    onClick={() =>
                      useModalManagementResult.openAttachmentPreviewModal(
                        attachment,
                      )
                    }
                  />
                ))}
              </Carousel>
            )}
          </div>
        )}
        {InterventionActionViewMode.Timeline === viewMode && (
          <div className={styles.actionViewBodyContent}>
            {createdAt ? (
              <div className={EntityViewStyles.infoItem}>
                <p className={EntityViewStyles.infoItemLabel}>
                  <MaterialIcon icon="calendar_today" symbolsOutlined />
                  <Typography variant="caption">{t('date-created')}</Typography>
                </p>
                <div className={EntityViewStyles.infoItemContent}>
                  <Typography variant="body">
                    {getFormattedDate(createdAt)}
                  </Typography>
                </div>
              </div>
            ) : null}
            {estimatedDate ? (
              <div className={EntityViewStyles.infoItem}>
                <p className={EntityViewStyles.infoItemLabel}>
                  <MaterialIcon icon="flag" symbolsOutlined />
                  <Typography variant="caption">
                    {t('estimated-date')}
                  </Typography>
                </p>
                <div className={EntityViewStyles.infoItemContent}>
                  <Typography variant="body">
                    {estimatedDate
                      ? getFormattedDate(
                          transformDate(new Date(estimatedDate), false, true),
                        )
                      : '-'}
                  </Typography>
                </div>
              </div>
            ) : null}
            {estimatedTime ? (
              <div className={EntityViewStyles.infoItem}>
                <p className={EntityViewStyles.infoItemLabel}>
                  <MaterialIcon icon="history_toggle_off" symbolsOutlined />
                  <Typography variant="caption">
                    {t('estimated-time')}
                  </Typography>
                </p>
                <div className={EntityViewStyles.infoItemContent}>
                  <Typography variant="body">
                    {getTimeFromMinutesCount(estimatedTime)}
                  </Typography>
                </div>
              </div>
            ) : null}
            {durationTime ? (
              <div className={EntityViewStyles.infoItem}>
                <p className={EntityViewStyles.infoItemLabel}>
                  <MaterialIcon icon="schedule" symbolsOutlined />
                  <Typography variant="caption">{t('time-spent')}</Typography>
                </p>
                <div className={EntityViewStyles.infoItemContent}>
                  <Typography variant="body">
                    {getTimeFromMinutesCount(durationTime)}
                  </Typography>
                </div>
              </div>
            ) : null}
            {logisticTime ? (
              <div className={EntityViewStyles.infoItem}>
                <p className={EntityViewStyles.infoItemLabel}>
                  <MaterialIcon icon="local_shipping" symbolsOutlined />
                  <Typography variant="caption">
                    {t('logistic-time')}
                  </Typography>
                </p>
                <div className={EntityViewStyles.infoItemContent}>
                  <Typography variant="body">
                    {getTimeFromMinutesCount(logisticTime)}
                  </Typography>
                </div>
              </div>
            ) : null}
            {closedAt ? (
              <div className={EntityViewStyles.infoItem}>
                <p className={EntityViewStyles.infoItemLabel}>
                  <MaterialIcon icon="event_available" symbolsOutlined />
                  <Typography variant="caption">{t('closed-at')}</Typography>
                </p>
                <div className={EntityViewStyles.infoItemContent}>
                  <Typography variant="body">
                    {getFormattedDate(closedAt)}
                  </Typography>
                </div>
              </div>
            ) : null}
          </div>
        )}
        {InterventionActionViewMode.History === viewMode && (
          <InterventionActionHistory id={id} key={updatedAt} />
        )}
      </div>
    </div>
  );
}
