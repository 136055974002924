import React, { forwardRef, memo, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import Uppy from '@uppy/core';
import { Dashboard } from '@uppy/react';
import XHR from '@uppy/xhr-upload';
import ImageEditor from '@uppy/image-editor';
import '@uppy/core/dist/style.css';
// import '@uppy/dashboard/dist/style.css';
import '@uppy/image-editor/dist/style.css';
import English from '@uppy/locales/lib/en_US';
import French from '@uppy/locales/lib/fr_FR';
import Modal from '@lib/components/Modal/Modal';
import { getMaxFileSize } from '@lib/enums/mimeTypes';
import { getSessionTokenCookie } from '@lib/utils/cookieUtils';
import styles from './FileUploaderUppyXHR.module.scss';
import './dashboard.css';

// @ts-ignore
const { VITE_API_URL: API_DOMAIN } = import.meta.env;

export enum UploaderView {
  modal = 'modal',
  dragDrop = 'dragDrop',
}

export interface AttachmentValueFileMetadata {
  size?: number;
  filename?: string;
  mimeType?: string;
}

export interface AttachmentValueFile {
  id?: string;
  metadata?: AttachmentValueFileMetadata;
  storage?: string;
}

export interface AttachmentValue {
  id?: string;
  url?: string;
  fileTag?: string;
  urls?: {
    original: string;
    large: string;
    small: string;
  };
  filename?: string;
  file?: AttachmentValueFile;
  _destroy?: boolean;
}

type OnChangeSingle = (variables: AttachmentValue) => void;
type OnChangeMultiple = (variables: AttachmentValue[]) => void;

interface Props {
  allowedFileTypes: string[];
  className?: string;
  maxFileSize?: number;
  maxNumberOfFiles?: number;
  note?: string;
  onChange: OnChangeSingle | OnChangeMultiple;
  openButton: ({
    onClick,
  }: {
    onClick: () => void;
  }) => React.JSX.Element | null;
  openUploaderModalOnInit?: boolean;
  type: UploaderView;
  value?: AttachmentValue | AttachmentValue[];
}

function getAttachmentVariables(file: AttachmentValueFile): AttachmentValue {
  const filename = _get(file, 'response.body.data.metadata.filename', '');
  return {
    // id: file.id,
    filename,
    file: {
      id: _get(file, 'response.body.data.id', ''),
      storage: _get(file, 'response.body.data.storage', 'cache') as string,
      metadata: {
        size: _get(file, 'response.body.data.metadata.size', 1),
        filename,
        mimeType: _get(file, 'response.body.data.metadata.mime_type', ''),
      },
    },
    url: _get(file, 'response.body.url', '') as string,
  };
}

const FileUploaderUppyXHR = forwardRef<HTMLInputElement, Props>(
  (props, ref) => {
    const {
      allowedFileTypes,
      className,
      maxFileSize = 5242880,
      maxNumberOfFiles = 1,
      note,
      onChange,
      openButton,
      openUploaderModalOnInit = false,
      type,
      value,
    } = props;
    const { t, i18n } = useTranslation();
    const currentLocale = i18n.language;
    const [modalOpen, setModalOpen] = useState(
      _isEmpty(value) ? openUploaderModalOnInit : false,
    );
    const [uppyInstance, setUppyInstance] = useState<Uppy | null>(null);

    const handleOpen = () => setModalOpen(true);
    const handleClose = () => setModalOpen(false);

    useEffect(() => {
      const token = getSessionTokenCookie();

      const companionHeaders = {
        AUTHORIZATION: `Bearer ${token}`,
      };

      const uppy = new Uppy({
        id: 'uppy',
        restrictions: {
          maxFileSize,
          allowedFileTypes,
          maxNumberOfFiles,
        },
        autoProceed: false,
        // debug: MODE === 'development',
      })
        .use(XHR, {
          endpoint: `${API_DOMAIN}/files/upload`,
          headers: companionHeaders,
        })
        .use(ImageEditor, {});

      uppy.on('file-added', (file) => {
        const fileType = file.extension ? `.${file.extension}` : '';
        const maxAllowedSize = getMaxFileSize(fileType);
        if (file.size > maxAllowedSize) {
          uppy.removeFile(file.id);
          uppy.info(
            t('file-add-validation-error', {
              name: file.name,
              size: maxAllowedSize / 1024 / 1024,
              type: fileType,
            }),
            'error',
            8000,
          );
        }
      });

      uppy.on('complete', (result) => {
        const isSuccessful = _get(result, 'successful.length', 0) > 0;
        if (maxNumberOfFiles === 1) {
          const file = _get(result, 'successful.0');
          if (file?.id) {
            const newValue = getAttachmentVariables(file);
            (onChange as OnChangeSingle)(newValue);
          }
        } else if (maxNumberOfFiles > 1) {
          const files = _get(result, 'successful');
          const newValue: AttachmentValue[] = [];
          files.forEach((file) => {
            if (file?.id) {
              newValue.push(getAttachmentVariables(file));
            }
          });
          if (value && Array.isArray(value)) {
            (onChange as OnChangeMultiple)([...value, ...newValue]);
          } else {
            (onChange as OnChangeMultiple)(newValue);
          }
        }
        if (type === UploaderView.modal && isSuccessful) {
          handleClose();
        }
      });

      uppy.on('upload-error', (file, error, response) => {
        // TODO: Handle error, maybe by showing an alert to the user
        // eslint-disable-next-line no-console
        console.error('Failed to upload', file?.name, error, response);
      });

      setUppyInstance(uppy);

      return () => {
        uppy.close();
      };
    }, [
      allowedFileTypes,
      maxFileSize,
      maxNumberOfFiles,
      onChange,
      t,
      type,
      value,
    ]);

    if (uppyInstance === null) return null;

    const getLocale = () => {
      if (currentLocale === 'en') return English;
      return French;
    };

    const uppyDashboard = (
      <div className={classNames(styles.root, className)}>
        <Dashboard
          uppy={uppyInstance}
          note={note}
          proudlyDisplayPoweredByUppy={false}
          plugins={['ImageEditor']}
          showSelectedFiles
          showRemoveButtonAfterComplete
          locale={getLocale()}
          // @ts-ignore TODO: fix this
          mobileNativeCamera
        />
      </div>
    );

    return (
      <div>
        <input type="hidden" ref={ref} />
        {type === UploaderView.modal && (
          <>
            {openButton({ onClick: handleOpen })}
            <Modal
              isOpen={modalOpen}
              onClose={handleClose}
              title={
                maxNumberOfFiles > 1 ? t('upload-files') : t('upload-file')
              }
            >
              {uppyDashboard}
            </Modal>
          </>
        )}
        {type === UploaderView.dragDrop && uppyDashboard}
      </div>
    );
  },
);

export default memo(FileUploaderUppyXHR);
