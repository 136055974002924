import _isNil from 'lodash/isNil';
import {
  Access,
  AccessCuInputObject,
  ComponentEnum,
  LevelEnum,
  PolicyEnum,
  ScopeLevelEnum,
} from 'graphql-common';
import mergeAndUnlinkMultiSelectValues from '@lib/utils/mergeAndUnlinkMultiSelectValues';
import { PlanState } from '@lib/enums/plan';

export function componentHasScopeLevel(component: ComponentEnum) {
  return (
    component === ComponentEnum.InterventionsManagementCategoriesScope ||
    component === ComponentEnum.AssetsManagementCategoriesScope
  );
}

export enum AccessesFieldType {
  FullOptions = 'fullOptions',
  ShortOptions = 'shortOptions',
  InterventionCategoriesSelect = 'interventionCategoriesSelect',
  AssetCategoriesSelect = 'assetCategoriesSelect',
}

export type AccessesFieldValues = { [key: string]: AccessesFieldType }[];

export function getAccessesOrder() {
  return [
    ComponentEnum.SitesManagement,
    ComponentEnum.UsersManagement,
    ComponentEnum.TasksManagement,
    ComponentEnum.TasksPerforming,
    ComponentEnum.FormCategoriesManagement,
    ComponentEnum.InterventionsManagement,
    ComponentEnum.InterventionsManagementCategoriesScope,
    ComponentEnum.InterventionCategoriesManagement,
    ComponentEnum.AssetsManagement,
    ComponentEnum.AssetsManagementCategoriesScope,
    ComponentEnum.AssetsCounterReading,
    ComponentEnum.AssetCategoriesManagement,
  ];
}

type AccessField = {
  value: ComponentEnum;
  labelTransKey: string;
  descriptionTransKey?: string;
  fieldType: AccessesFieldType;
  subOptions?: AccessField[];
};

export const InterventionsManagementAccessConfig = {
  value: ComponentEnum.InterventionsManagement,
  labelTransKey: 'intervention-management',
  descriptionTransKey: 'intervention-management-description',
  fieldType: AccessesFieldType.FullOptions,
};

export function getAccessesMap(): { [key: string]: AccessField } {
  return {
    [ComponentEnum.SitesManagement]: {
      value: ComponentEnum.SitesManagement,
      labelTransKey: 'site-management',
      descriptionTransKey: 'site-management-description',
      fieldType: AccessesFieldType.FullOptions,
    },
    [ComponentEnum.UsersManagement]: {
      value: ComponentEnum.UsersManagement,
      labelTransKey: 'employee-management',
      descriptionTransKey: 'employee-management-description',
      fieldType: AccessesFieldType.FullOptions,
    },
    [ComponentEnum.TasksManagement]: {
      value: ComponentEnum.TasksManagement,
      labelTransKey: 'task-management',
      descriptionTransKey: 'task-management-description',
      fieldType: AccessesFieldType.FullOptions,
    },
    [ComponentEnum.TasksPerforming]: {
      value: ComponentEnum.TasksPerforming,
      labelTransKey: 'performing-tasks',
      descriptionTransKey: 'performing-tasks-description',
      fieldType: AccessesFieldType.ShortOptions,
    },
    [ComponentEnum.FormCategoriesManagement]: {
      value: ComponentEnum.FormCategoriesManagement,
      labelTransKey: 'forms-categories-management',
      fieldType: AccessesFieldType.FullOptions,
    },
    [ComponentEnum.InterventionsManagement]:
      InterventionsManagementAccessConfig,
    [ComponentEnum.InterventionsManagementCategoriesScope]: {
      value: ComponentEnum.InterventionsManagementCategoriesScope,
      labelTransKey: 'intervention-categories-scope-management',
      descriptionTransKey:
        'intervention-categories-scope-management-description',
      fieldType: AccessesFieldType.InterventionCategoriesSelect,
    },
    [ComponentEnum.InterventionCategoriesManagement]: {
      value: ComponentEnum.InterventionCategoriesManagement,
      labelTransKey: 'intervention-categories-management',
      fieldType: AccessesFieldType.FullOptions,
    },
    [ComponentEnum.AssetsManagement]: {
      value: ComponentEnum.AssetsManagement,
      labelTransKey: 'equipment-management',
      descriptionTransKey: 'equipment-management-description',
      fieldType: AccessesFieldType.FullOptions,
    },
    [ComponentEnum.AssetsManagementCategoriesScope]: {
      value: ComponentEnum.AssetsManagementCategoriesScope,
      labelTransKey: 'equipment-categories-scope-management',
      descriptionTransKey: 'equipment-categories-scope-management-description',
      fieldType: AccessesFieldType.AssetCategoriesSelect,
    },
    [ComponentEnum.AssetsCounterReading]: {
      value: ComponentEnum.AssetsCounterReading,
      labelTransKey: 'counter-readings',
      descriptionTransKey: 'counter-readings-description',
      fieldType: AccessesFieldType.ShortOptions,
    },
    [ComponentEnum.AssetCategoriesManagement]: {
      value: ComponentEnum.AssetCategoriesManagement,
      labelTransKey: 'equipment-categories-management',
      fieldType: AccessesFieldType.FullOptions,
    },
  };
}

export function getAccessesManagementOptions() {
  const accessMap = getAccessesMap();
  return [
    { ...accessMap[ComponentEnum.SitesManagement] },
    { ...accessMap[ComponentEnum.UsersManagement] },
    {
      ...accessMap[ComponentEnum.TasksManagement],
      subOptions: [
        { ...accessMap[ComponentEnum.TasksPerforming] },
        { ...accessMap[ComponentEnum.FormCategoriesManagement] },
      ],
    },
    {
      ...accessMap[ComponentEnum.InterventionsManagement],
      subOptions: [
        { ...accessMap[ComponentEnum.InterventionsManagementCategoriesScope] },
        { ...accessMap[ComponentEnum.InterventionCategoriesManagement] },
      ],
    },
    {
      ...accessMap[ComponentEnum.AssetsManagement],
      subOptions: [
        { ...accessMap[ComponentEnum.AssetsManagementCategoriesScope] },
        { ...accessMap[ComponentEnum.AssetsCounterReading] },
        { ...accessMap[ComponentEnum.AssetCategoriesManagement] },
      ],
    },
  ];
}

export function getAccessLevelOptions() {
  return [
    {
      value: LevelEnum.Write,
      labelTransKey: 'access-level-editor',
    },
    {
      value: LevelEnum.Read,
      labelTransKey: 'access-level-viewer',
    },
    {
      value: LevelEnum.LimitedRead,
      labelTransKey: 'access-level-restricted',
      data: {
        isNegative: true,
      },
    },
  ];
}

export function getAccessLevelShortOptions() {
  return [
    {
      value: LevelEnum.Write,
      labelTransKey: 'allowed',
    },
    {
      value: LevelEnum.LimitedRead,
      labelTransKey: 'access-level-restricted',
      data: {
        isNegative: true,
      },
    },
  ];
}

export function getAccessLevelOption(level: LevelEnum) {
  return getAccessLevelOptions().find(({ value }) => value === level);
}

export function getAccessCategoriesScopeIds(
  data: Access[] | null,
  findByComponent: ComponentEnum,
) {
  if (!data || !data?.length || !findByComponent) return [];
  const catData = data.find(({ component }) => component === findByComponent);
  return catData?.itemIds || [];
}

export function getAccessesPreparedDataFromValues(
  accessesValues: AccessesFieldValues,
  prevAccessesValues: AccessesFieldValues,
  useDestroy?: boolean,
): AccessCuInputObject[] {
  const result: AccessCuInputObject[] = [];
  getAccessesOrder().forEach((component) => {
    const access = accessesValues[component];
    const prevAccess = prevAccessesValues[component];
    const itemOptions = mergeAndUnlinkMultiSelectValues(
      prevAccess?.items?.map((item) => ({ ...item, id: item.value })),
      access?.items?.map((item) => ({ ...item, id: item.value })),
    );
    const hasScopeLevel = componentHasScopeLevel(component);
    const items = itemOptions
      ? itemOptions.map(({ id, unlink }) => ({ id, unlink }))
      : undefined;
    let scopeLevel: ScopeLevelEnum | undefined;
    if (hasScopeLevel) {
      if (itemOptions === null) {
        scopeLevel = access?.scopeLevel || ScopeLevelEnum.All;
      } else if (items?.length) {
        scopeLevel = ScopeLevelEnum.Selected;
      } else {
        scopeLevel = ScopeLevelEnum.All;
      }
    }
    if (access) {
      result.push({
        component,
        items,
        level: hasScopeLevel ? undefined : access.level?.value,
        scopeLevel,
      });
    } else if (prevAccess && !access && useDestroy) {
      result.push({
        component,
        _destroy: true,
      });
    }
  });
  return result;
}

export function getEmptyAccessesValues() {
  const value = {};
  getAccessesOrder().forEach((component) => {
    value[component] = {
      component,
      items: [],
      level: getAccessLevelOption(LevelEnum.LimitedRead),
      scopeLevel: componentHasScopeLevel(component)
        ? ScopeLevelEnum.All
        : undefined,
      policy: PolicyEnum.Team,
    };
  });
  return value;
}

export function getAccessesValues(
  accesses: Access[] | null,
  policy: PolicyEnum = PolicyEnum.Team,
) {
  const result = {};
  if (accesses === null) return result;
  accesses.forEach((access) => {
    result[access.component] = {
      ...access,
      items: (access.itemIds || []).map((item) => ({
        value: item,
      })),
      level: access.level ? getAccessLevelOption(access.level) : undefined,
      policy,
      isCustom: policy === PolicyEnum.User,
    };
  });
  return result;
}

export function calculateActiveUsersPercentage(
  activeUsersCount: number,
  usersLimit?: number | null,
): number {
  if (_isNil(usersLimit)) {
    return 0;
  }
  if (usersLimit === 0) {
    return 100;
  }
  return Math.round((activeUsersCount * 100) / usersLimit);
}

export function getPlanState(activeUsersPercentage: number) {
  let type = PlanState.None;
  if (activeUsersPercentage >= 75) type = PlanState.Warning;
  if (activeUsersPercentage >= 100) type = PlanState.Attention;
  return type;
}
