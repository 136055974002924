import { Task, useDashboardTaskQuery } from 'graphql-common';
import { WatchQueryFetchPolicy } from '@apollo/client';
import { ApolloError } from '@apollo/client/errors';

type Args = {
  id: string;
  skip?: boolean;
  fetchPolicy?: WatchQueryFetchPolicy;
};

export type UseTaskQueryHookResult = {
  data: Task | null | undefined;
  error: ApolloError | undefined;
  firstLoading: boolean;
  loading: boolean;
  refetch: () => Promise<unknown>;
};

export default function useTaskQueryHook(args: Args): UseTaskQueryHookResult {
  const { id, skip, fetchPolicy = 'cache-and-network' } = args;
  const query = useDashboardTaskQuery({
    skip: !id || skip,
    variables: {
      id,
    },
    fetchPolicy,
  });
  const { data: queryData, refetch, loading, error, previousData } = query;
  const data = queryData || previousData;
  const taskData = data?.data as Task;
  const firstLoading = loading && queryData === undefined;

  return {
    data: taskData,
    error,
    firstLoading,
    loading,
    refetch,
  };
}
