import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import _get from 'lodash/get';
import { ApolloError } from '@apollo/client/errors';
import {
  AssetCounterBulkQrCodeCreateMutation,
  Nomenclature,
  NomenclatureCreateInputObject,
  NomenclatureUpdateInputObject,
} from 'graphql-common';
import AssetNomenclatureFieldNames from '@lib/enums/fieldNames/assetNomenclatureFieldNames';
import onDestroyListItemCallBack from '@lib/utils/onDestroyListItemCallBack';
import { UseBulkSelectResult } from '@lib/hooks/useTableItemsBulkSelect';
import tableItemsBulkAction from '@lib/utils/tableItemsBulkAction';
import usePaginationParams from '@lib/hooks/usePaginationParams';
import getAllTableItemsIds from '@lib/utils/getAllTableItemsIds';
import { FormRefType } from '@lib/components/ReactHookForm/types';
import useSearchParam from '@lib/hooks/useSearchParam';
import toast from '@lib/components/Toast/Toast';
import { SubmitAction } from '@lib/enums/form';
import { Values } from '@lib/interfaces/form';
import { APP_URLS } from 'constants/urls';
import useAssetNomenclaturesQueryHook, {
  UseAssetNomenclaturesQueryHookResult,
} from 'utils/fetch/useAssetNomenclaturesQueryHook';
import useAssetNomenclatureQueryHook, {
  UseAssetNomenclatureQueryHookResult,
} from 'utils/fetch/useAssetNomenclatureQueryHook';
import { MutationResult } from '@apollo/client';
import { AssetViewMode } from '../enums';
import { UseActionsResult } from './useActions';
import useNomenclatureMutations from './useNomenclatureMutations';
import useQrCodeActions from './useQrCodeActions';
import getPreparedAssetNomenclatureDataFromValues from './getPreparedAssetNomenclatureDataFromValues';
import { UseModalManagementResult } from './useModalManagement';

interface UseActionsProps {
  useActionsResult: UseActionsResult;
  formRef: FormRefType;
  submitNewItemRef: React.MutableRefObject<SubmitAction | undefined>;
  useModalManagementResult: UseModalManagementResult;
  useBulkSelectResult?: UseBulkSelectResult<Nomenclature>;
  viewMode: AssetViewMode;
}

export interface UseNomenclatureActionsResult {
  assetNomenclatureMutationError: ApolloError | undefined;
  assetNomenclatureMutationLoading: boolean;
  nomenclatureQueryHook: UseAssetNomenclatureQueryHookResult;
  nomenclaturesQueryHook: UseAssetNomenclaturesQueryHookResult;
  onCloseModal: () => void;
  onConfirmedNomenclatureDestroy: () => void;
  onNomenclatureFormSubmit: (values: Values) => void;
  onQrCodeFormSubmit: (values: Values) => void;
  openAsset: (item?: Nomenclature) => void;
  openGetQrCodeFormModal: (item?: Nomenclature) => void;
  openNomenclatureBulkDestroyModal: () => void;
  openNomenclatureDestroyModal: (item?: Nomenclature) => void;
  openNomenclatureEditModal: (item?: Nomenclature) => void;
  qrCodeCreateMutationResult: MutationResult<AssetCounterBulkQrCodeCreateMutation>;
}

export default function useNomenclatureActions(
  props: UseActionsProps,
): UseNomenclatureActionsResult {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const [searchQuery] = useSearchParam();
  const [paginationParams] = usePaginationParams();

  const {
    formRef,
    submitNewItemRef,
    useModalManagementResult,
    useBulkSelectResult,
    viewMode,
    useActionsResult,
  } = props;

  const { assetQueryHook, assetId: currentAssetId } = useActionsResult;

  // Nomenclature
  const nomenclatureQueryHook = useAssetNomenclatureQueryHook({
    id: useBulkSelectResult?.selectedSingleTableItem?.id,
    mainAssetId: currentAssetId,
    skip:
      !(currentAssetId && useBulkSelectResult?.selectedSingleTableItem?.id) ||
      viewMode !== AssetViewMode.Nomenclatures,
  });
  const nomenclaturesQueryHook = useAssetNomenclaturesQueryHook({
    paginationParams,
    searchQuery,
    skip: viewMode !== AssetViewMode.Nomenclatures,
    mainAssetId: currentAssetId,
  });

  // Mutations
  const useMutationsResult = useNomenclatureMutations();

  // Actions
  const onCloseModal = () => {
    useBulkSelectResult?.handleUnselectAllTableItems();
    useModalManagementResult.closeAllModals();
    useMutationsResult.assetNomenclatureCreateMutationResult.reset();
    useMutationsResult.assetNomenclatureUpdateMutationResult.reset();
  };

  const onGetAllIds = () =>
    getAllTableItemsIds<Nomenclature>({
      fetchIds: nomenclaturesQueryHook.fetchIds,
      useBulkSelectResult:
        useBulkSelectResult as UseBulkSelectResult<Nomenclature>,
    });

  const onNomenclatureCreate = (values: Values) => {
    useMutationsResult.assetNomenclatureCreateMutation({
      variables: {
        attributes:
          getPreparedAssetNomenclatureDataFromValues<NomenclatureCreateInputObject>(
            {
              values,
              mainAssetId: currentAssetId,
            },
          ),
      },
      onCompleted: () => {
        if (submitNewItemRef.current === SubmitAction.CreateAndNew) {
          formRef.current?.reset({
            [AssetNomenclatureFieldNames.ComponentAsset]: null,
            [AssetNomenclatureFieldNames.Quantity]: null,
          });
        } else {
          useModalManagementResult.closeNomenclatureFormModal();
        }
        assetQueryHook.refetch();
        nomenclaturesQueryHook.refetch();
        toast({ content: t('create-asset-nomenclature-success') });
      },
    });
  };

  const onNomenclatureEdit = (values: Values) => {
    if (currentAssetId) {
      useMutationsResult.assetNomenclatureUpdateMutation({
        variables: {
          id: _get(useBulkSelectResult, ['selectedSingleTableItem', 'id'], ''),
          mainAssetId: currentAssetId,
          attributes:
            getPreparedAssetNomenclatureDataFromValues<NomenclatureUpdateInputObject>(
              {
                values,
                isEdit: true,
              },
            ),
        },
        onCompleted: () => {
          onCloseModal();
          nomenclaturesQueryHook.refetch();
          toast({ content: t('update-asset-nomenclature-success') });
        },
      });
    }
  };

  const onNomenclatureFormSubmit = (
    values: Values,
    isEditNomenclatureForm?: boolean,
  ) => {
    if (isEditNomenclatureForm) {
      onNomenclatureEdit(values);
    } else {
      onNomenclatureCreate(values);
    }
  };

  const openNomenclatureDestroyModal = (item?: Nomenclature) => {
    if (item?.id) {
      useBulkSelectResult?.handleSelectSingleTableItem(item);
      useModalManagementResult.openNomenclatureDestroyModal();
    }
  };

  const openNomenclatureBulkDestroyModal = () => {
    useModalManagementResult.openNomenclatureDestroyModal();
  };

  const openNomenclatureEditModal = (item?: Nomenclature) => {
    if (item?.id) {
      useBulkSelectResult?.handleSelectSingleTableItem(item);
      useModalManagementResult.openNomenclatureFormModal();
    }
  };

  const openAsset = (item?: Nomenclature) => {
    if (item?.componentAsset?.id) {
      navigate(
        APP_URLS.dashboard.assets.view.getDynamicPath({
          pathParts: { id: item.componentAsset.id },
        }),
      );
    }
  };

  const onConfirmedNomenclatureDestroy = () => {
    useModalManagementResult.closeNomenclatureDestroyModal();
    toast({
      content: t('destroy-asset-nomenclature-success'),
      closeCallback: () => {
        if (currentAssetId) {
          tableItemsBulkAction<Nomenclature>({
            action: (idsValue?: string[]) => {
              if (idsValue) {
                useMutationsResult.assetNomenclatureDestroyMutation({
                  variables: { ids: idsValue, mainAssetId: currentAssetId },
                  onCompleted: () => {
                    useBulkSelectResult?.handleUnselectAllTableItems();
                    onDestroyListItemCallBack({
                      collection: nomenclaturesQueryHook.collection,
                      navigate,
                      pathname,
                      refetch: () => {
                        assetQueryHook.refetch();
                        nomenclaturesQueryHook.refetch();
                      },
                      search,
                      totalPages: nomenclaturesQueryHook.totalPages,
                    });
                  },
                });
              }
            },
            useBulkSelectResult,
            onGetAllIds,
          });
        }
      },
    });
  };

  // Get QR codes
  const { onQrCodeCreateAction } = useQrCodeActions({
    onCompleted: onCloseModal,
    qrCodeCreateMutation: useMutationsResult.qrCodeCreateMutation,
  });

  const onNomenclatureQrCodeCreate = (values: Values, ids: string[]) => {
    onQrCodeCreateAction(values, ids);
  };

  const onNomenclatureQrCodesCreate = (values: Values) => {
    const action = (ids: string[]) => onQrCodeCreateAction(values, ids);
    tableItemsBulkAction<Nomenclature>({
      action,
      useBulkSelectResult,
      onGetAllIds,
    });
  };

  const onQrCodeFormSubmit = (values: Values) => {
    if (useBulkSelectResult?.selectedSingleTableItem?.id) {
      onNomenclatureQrCodeCreate(values, [
        useBulkSelectResult.selectedSingleTableItem.id,
      ]);
    } else if (
      useBulkSelectResult?.getSelectedItemsCount(
        nomenclaturesQueryHook.totalCount,
      )
    ) {
      onNomenclatureQrCodesCreate(values);
    }
  };

  const openGetQrCodeFormModal = (item?: Nomenclature) => {
    if (item) useBulkSelectResult?.handleSelectSingleTableItem(item);
    useModalManagementResult.openGetQrCodeFormModal();
  };

  const assetNomenclatureMutationLoading = !!(
    _get(useMutationsResult, [
      'assetNomenclatureCreateMutationResult',
      'loading',
    ]) ||
    _get(useMutationsResult, ['assetNomenclatureUpdateMutation', 'loading'])
  );
  const assetNomenclatureMutationError =
    _get(useMutationsResult, [
      'assetNomenclatureCreateMutationResult',
      'error',
    ]) ||
    _get(useMutationsResult, ['assetNomenclatureUpdateMutation', 'error']);

  return {
    openGetQrCodeFormModal,
    onQrCodeFormSubmit,
    assetNomenclatureMutationError,
    assetNomenclatureMutationLoading,
    nomenclatureQueryHook,
    nomenclaturesQueryHook,
    onCloseModal,
    onConfirmedNomenclatureDestroy,
    onNomenclatureFormSubmit,
    openAsset,
    openNomenclatureBulkDestroyModal,
    openNomenclatureDestroyModal,
    openNomenclatureEditModal,
    qrCodeCreateMutationResult: useMutationsResult.qrCodeCreateMutationResult,
  };
}
