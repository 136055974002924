import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  FieldValues,
  UseFieldArrayAppend,
  useFormContext,
} from 'react-hook-form';
import _get from 'lodash/get';
import _replace from 'lodash/replace';
import classNames from 'classnames';
import {
  ConditionEnum,
  FormTemplateQuestionFieldOptionsValidityHintEnum,
  GroupEnum,
} from 'graphql-common';
import Button, {
  ButtonSizes,
  ButtonTypes,
} from '@lib/components/Button/Button';
import Popover from '@lib/components/Popover/Popover';
import Menu from '@lib/components/Menu/Menu';
import getConditionNavItems from 'components/FormTemplateBuilder/utils/getConditionNavItems';
import getValidityHintOptionByValue from 'components/FormTemplateBuilder/utils/getValidityHintOptionByValue';
import { AppendValue } from '@lib/components/ReactHookForm/types';
import FormTemplateFieldNames from '@lib/enums/fieldNames/formTemplateFieldNames';
import {
  kindFieldsWithAddOption,
  kindFieldsWithCondition,
  kindFieldsWithPreviewForEditMode,
  kindFieldsWithValidityHint,
} from '../constants';
import styles from '../QuestionFieldOptionsAddItemComponent/QuestionFieldOptionsAddItemComponent.module.scss';

interface Props {
  append: UseFieldArrayAppend<FieldValues, string>;
  appendValue: AppendValue;
  isAddMoreVisible: boolean;
  parentName: string;
}

function QuestionFieldOptionsEmptyStateComponent({
  append,
  appendValue = {},
  isAddMoreVisible,
  parentName,
}: Props) {
  const { t } = useTranslation();
  const { getValues } = useFormContext();
  const values = getValues();
  const fieldValue = _get(
    values,
    _replace(parentName, `.${FormTemplateFieldNames.Options}`, ''),
  );
  const kind = _get(fieldValue, FormTemplateFieldNames.Kind);
  const options = _get(fieldValue, FormTemplateFieldNames.Options, []);
  const className = classNames(styles.optionAddBtn, {
    [styles.optionAddBtnWithTopPadding]: options?.length,
    [styles.optionAddBtnWithBottomPadding]:
      kindFieldsWithPreviewForEditMode.includes(kind),
  });
  if (!append || !kindFieldsWithAddOption.includes(kind)) return null;
  const getButtonText = () => {
    const group = _get(appendValue, FormTemplateFieldNames.Group);
    if (group === GroupEnum.Rows) return t('add-row');
    if (group === GroupEnum.Columns) return t('add-column');
    return t('add-option');
  };
  if (!isAddMoreVisible) {
    return (
      <div className={className}>
        <Button
          buttonText={getButtonText()}
          buttonType={ButtonTypes.secondaryOutlined}
          buttonSize={ButtonSizes.small}
          leftIcon="add"
          disabled
        />
      </div>
    );
  }
  if (kindFieldsWithCondition.includes(kind)) {
    const onClickMenuItem = (condition: ConditionEnum) => {
      const newAppendValue = {
        ...appendValue,
        values: condition === ConditionEnum.Between ? ['', ''] : [''],
      };
      append({
        ...newAppendValue,
        validityHint: getValidityHintOptionByValue(
          FormTemplateQuestionFieldOptionsValidityHintEnum.Success,
        ),
        condition,
      });
    };
    const popoverBody = () => (
      <div className={styles.popoverBody}>
        <Menu
          navItems={getConditionNavItems(onClickMenuItem, t)}
          itemClassName={styles.menuItemClassName}
        />
      </div>
    );
    return (
      <div className={className}>
        <Popover body={popoverBody}>
          {({ handleToggle, isOpen }) => (
            <div>
              <Button
                buttonText={getButtonText()}
                buttonType={ButtonTypes.secondaryOutlined}
                buttonSize={ButtonSizes.small}
                leftIcon="add"
                onClick={() => handleToggle()}
                isFocused={isOpen}
              />
            </div>
          )}
        </Popover>
      </div>
    );
  }
  if (kindFieldsWithValidityHint.includes(kind)) {
    return (
      <div className={className}>
        <Button
          buttonText={getButtonText()}
          buttonType={ButtonTypes.secondaryOutlined}
          buttonSize={ButtonSizes.small}
          leftIcon="add"
          onClick={() =>
            append({
              ...appendValue,
              validityHint: getValidityHintOptionByValue(
                FormTemplateQuestionFieldOptionsValidityHintEnum.Success,
              ),
            })
          }
        />
      </div>
    );
  }
  return (
    <div className={className}>
      <Button
        buttonText={getButtonText()}
        buttonType={ButtonTypes.secondaryOutlined}
        buttonSize={ButtonSizes.small}
        leftIcon="add"
        onClick={() => append(appendValue)}
      />
    </div>
  );
}

export default QuestionFieldOptionsEmptyStateComponent;
