import { TFunction } from 'i18next';
import { User } from 'graphql-common';
import { NavItem, NavItemTypeEnum } from '@lib/components/Menu/Menu';
import Icons from '@lib/components/Icons/Icons';

type ActionsMenuArgs = {
  currentUserId?: string;
  onActivate?: (item?: User) => void;
  onDeactivate?: (item?: User) => void;
  onEditAccesses?: (item?: User) => void;
  onDelete?: (item?: User) => void;
  onEdit: (item?: User) => void;
  t: TFunction<'translation', undefined>;
  isViewOnly: boolean;
};

export default function getUserActionsMenu({
  currentUserId,
  onActivate,
  onDeactivate,
  onEditAccesses,
  onDelete,
  onEdit,
  isViewOnly,
  t,
}: ActionsMenuArgs) {
  const result: NavItem<User>[] = [];
  if (!isViewOnly) {
    result.push({
      id: 'edit-action',
      title: t('edit-details'),
      onClick: onEdit,
      leftIcon: Icons.edit,
      type: NavItemTypeEnum.Secondary,
      predictorFnDisable: (item?: User) => currentUserId === item?.id,
    });
  }
  if (!!onEditAccesses && !isViewOnly) {
    result.push({
      id: 'edit-access-action',
      title: t('edit-access-rights'),
      onClick: onEditAccesses,
      leftIcon: Icons.edit,
      type: NavItemTypeEnum.Secondary,
      predictorFnVisible: (item?: User) => currentUserId !== item?.id,
    });
  }
  if (!!onDeactivate && !isViewOnly) {
    result.push({
      id: 'deactivate-action',
      title: t('deactivate'),
      onClick: onDeactivate,
      leftIcon: Icons.deactivate,
      type: NavItemTypeEnum.Secondary,
    });
  }
  if (!!onActivate && !isViewOnly) {
    result.push({
      id: 'activate-action',
      title: t('activate'),
      onClick: onActivate,
      leftIcon: Icons.activate,
      type: NavItemTypeEnum.Secondary,
    });
  }
  // if (false) {
  //   result.push({
  //     id: 'delete-action',
  //     title: t('delete'),
  //     onClick: onDelete,
  //     leftIcon: Icons.delete,
  //     type: NavItemTypeEnum.Secondary,
  //     predictorFnDisable: (item?: User) => currentUserId === item?.id,
  //   })
  // }
  return result;
}
