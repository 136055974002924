import React from 'react';
import { TFunction } from 'i18next';
import _without from 'lodash/without';
import _get from 'lodash/get';
import { v4 as uuidv4 } from 'uuid';
import {
  FormAnswer,
  FormTemplateQuestionFieldOption,
  FormTemplateQuestionFieldsKindEnum,
  GroupEnum,
  RespGroupEnum,
  User,
} from 'graphql-common';
import { CellProps, Column } from '@lib/components/Table/types';
import TextCell from '@lib/components/Table/commonComponents/TextCell/TextCell';
import {
  convertTimeToHHmm,
  getFormattedDate,
} from '@lib/utils/dateTimeHelpers';
import UserPreviewCell from '@lib/components/Table/commonComponents/UserPreviewCell/UserPreviewCell';
import CollapseCell from '@lib/components/Table/commonComponents/CollapseCell/CollapseCell';
import { getValidityHintChipIcon } from '@lib/components/Select/components/ValidityHintOption';
import { dateTimeFormatEU } from '@lib/enums/dateTime';
import Chip, { ChipTypes } from '@lib/components/Chip/Chip';
import Rating from '@lib/components/Rating/Rating';
import Placeholder from '@lib/assets/images/preview-placeholder.svg';
import Image from '@lib/components/Image/Image';
import TextCellWithIcon from '@lib/components/Table/commonComponents/TextCell/TextCellWithIcon';
import styles from './TaskReportingView.module.scss';

const cellWidth = 150;
const largeCellWidth = 200;
const validityHintCellWidth = 60;

type ColumnsArgs = {
  hasValidityHint?: boolean;
  kind: FormTemplateQuestionFieldsKindEnum;
  options: FormTemplateQuestionFieldOption[];
  t: TFunction<'translation', undefined>;
};

export const getColumns = ({
  hasValidityHint,
  kind,
  options,
  t,
}: ColumnsArgs) => {
  return _without(
    [
      hasValidityHint
        ? {
            id: 'validityHint',
            Header: '',
            accessorFn: ({ item }: CellProps<FormAnswer>) =>
              item.validityHint ? item.validityHint : undefined,
            Cell: ({ value }: CellProps<FormAnswer>) =>
              getValidityHintChipIcon(value as string),
            width: validityHintCellWidth,
            maxWidth: validityHintCellWidth,
            minWidth: validityHintCellWidth,
          }
        : undefined,
      {
        id: 'createdAt',
        Header: t('check-date'),
        accessorFn: ({ item }: CellProps<FormAnswer>) =>
          item.createdAt
            ? getFormattedDate(item.createdAt, dateTimeFormatEU)
            : '-',
        Cell: (props: CellProps<FormAnswer>) => <TextCell {...props} />,
        sortable: true,
        width: cellWidth,
        maxWidth: cellWidth,
        minWidth: cellWidth,
      },
      {
        id: 'performerFullName',
        Header: t('performer'),
        Cell: (props: CellProps<FormAnswer>) => {
          const { item } = props;
          return item.performer ? (
            <UserPreviewCell
              avatar={
                item.performer.avatarAttached?.urls?.small ||
                item.performer.avatarAttached?.urls?.original
              }
              name={`${item.performer.firstName} ${item.performer.lastName}`}
              email={item.performer.email}
            />
          ) : (
            <TextCell {...props} value="-" />
          );
        },
        width: cellWidth * 2,
        maxWidth: cellWidth * 2,
        minWidth: cellWidth * 2,
        sortable: true,
      },
      [
        FormTemplateQuestionFieldsKindEnum.Input,
        FormTemplateQuestionFieldsKindEnum.TextArea,
      ].includes(kind)
        ? {
            id: 'response',
            Header: t('response'),
            accessor: 'resp.0.value',
            Cell: (props: CellProps<FormAnswer>) => {
              const { value } = props;
              if (!value) return <TextCell {...props} value="-" />;
              return <CollapseCell {...props} minHeight={20} />;
            },
          }
        : undefined,
      [FormTemplateQuestionFieldsKindEnum.NumberInput].includes(kind)
        ? {
            id: 'respNumberInputValue',
            Header: t('response'),
            accessor: 'resp.0',
            Cell: (props: CellProps<FormAnswer>) => {
              const optionValue = _get(props, 'value.value');
              const validityHint = _get(props, 'value.validityHint');
              if (optionValue === undefined || optionValue === null)
                return <TextCell {...props} value="-" />;
              return (
                <TextCellWithIcon
                  {...props}
                  className={styles.cellWithValidityHint}
                  icon={getValidityHintChipIcon(validityHint)}
                  value={optionValue}
                />
              );
            },
            sortable: true,
          }
        : undefined,
      kind === FormTemplateQuestionFieldsKindEnum.YesNo
        ? {
            id: 'respYesNoYesSelected',
            Header: t('yes'),
            accessorFn: ({ item }: CellProps<FormAnswer>) =>
              _get(item, 'resp', []).find(
                ({ group }) => group && group === RespGroupEnum.Yes,
              ),
            Cell: (props: CellProps<FormAnswer>) => {
              const optionSelected = _get(props, 'value.selected');
              const validityHint = _get(props, 'value.validityHint');
              if (!optionSelected) return <TextCell {...props} value="-" />;
              return getValidityHintChipIcon(validityHint);
            },
            width: cellWidth * 2,
            sortable: true,
          }
        : undefined,
      kind === FormTemplateQuestionFieldsKindEnum.YesNo
        ? {
            id: 'respYesNoNoSelected',
            Header: t('no'),
            accessorFn: ({ item }: CellProps<FormAnswer>) =>
              _get(item, 'resp', []).find(
                ({ group }) => group && group === RespGroupEnum.No,
              ),
            Cell: (props: CellProps<FormAnswer>) => {
              const optionSelected = _get(props, 'value.selected');
              const validityHint = _get(props, 'value.validityHint');
              if (!optionSelected) return <TextCell {...props} value="-" />;
              return getValidityHintChipIcon(validityHint);
            },
            width: cellWidth * 2,
            sortable: true,
          }
        : undefined,
      kind === FormTemplateQuestionFieldsKindEnum.MinMax
        ? {
            id: 'respMinMaxMinValue',
            Header: t('min'),
            accessorFn: ({ item }: CellProps<FormAnswer>) =>
              _get(item, 'resp', []).find(
                ({ group }) => group && group === RespGroupEnum.Min,
              ),
            Cell: (props: CellProps<FormAnswer>) => {
              const optionValue = _get(props, 'value.value');
              const validityHint = _get(props, 'value.validityHint');
              return (
                <TextCellWithIcon
                  {...props}
                  className={styles.cellWithValidityHint}
                  icon={getValidityHintChipIcon(validityHint)}
                  value={optionValue}
                />
              );
            },
            width: cellWidth * 2,
            sortable: true,
          }
        : undefined,
      kind === FormTemplateQuestionFieldsKindEnum.MinMax
        ? {
            id: 'respMinMaxMaxValue',
            Header: t('max'),
            accessorFn: ({ item }: CellProps<FormAnswer>) =>
              _get(item, 'resp', []).find(
                ({ group }) => group && group === RespGroupEnum.Max,
              ),
            Cell: (props: CellProps<FormAnswer>) => {
              const optionValue = _get(props, 'value.value');
              const validityHint = _get(props, 'value.validityHint');
              return (
                <TextCellWithIcon
                  {...props}
                  className={styles.cellWithValidityHint}
                  icon={getValidityHintChipIcon(validityHint)}
                  value={optionValue}
                />
              );
            },
            width: cellWidth * 2,
            sortable: true,
          }
        : undefined,
      kind === FormTemplateQuestionFieldsKindEnum.Date
        ? {
            id: 'respDateValue',
            Header: t('date'),
            accessorFn: ({ item }: CellProps<User>) =>
              getFormattedDate(_get(item, 'resp.0.value')),
            Cell: (props: CellProps<FormAnswer>) => <TextCell {...props} />,
            sortable: true,
          }
        : undefined,
      kind === FormTemplateQuestionFieldsKindEnum.DateRange
        ? {
            id: 'respDateRangeStartDate',
            Header: t('start-date'),
            accessorFn: ({ item }: CellProps<FormAnswer>) => {
              const resp = _get(item, 'resp', []);
              const itemResp = resp.find(
                ({ group }) => group && group === RespGroupEnum.StartDate,
              )?.value;
              return itemResp ? getFormattedDate(itemResp) : undefined;
            },
            Cell: (props: CellProps<FormAnswer>) => <TextCell {...props} />,
            width: cellWidth * 2,
            sortable: true,
          }
        : undefined,
      kind === FormTemplateQuestionFieldsKindEnum.DateRange
        ? {
            id: 'respDateRangeEndDate',
            Header: t('end-date'),
            accessorFn: ({ item }: CellProps<FormAnswer>) => {
              const resp = _get(item, 'resp', []);
              const itemResp = resp.find(
                ({ group }) => group && group === RespGroupEnum.EndDate,
              )?.value;
              return itemResp ? getFormattedDate(itemResp) : undefined;
            },
            Cell: (props: CellProps<FormAnswer>) => <TextCell {...props} />,
            width: cellWidth * 2,
            sortable: true,
          }
        : undefined,
      kind === FormTemplateQuestionFieldsKindEnum.Time
        ? {
            id: 'respTimeValue',
            Header: t('time'),
            accessorFn: ({ item }: CellProps<FormAnswer>) => {
              const value = _get(item, 'resp', [])?.[0]?.value;
              return convertTimeToHHmm(value);
            },
            Cell: (props: CellProps<FormAnswer>) => <TextCell {...props} />,
            sortable: true,
          }
        : undefined,
      kind === FormTemplateQuestionFieldsKindEnum.TimeRange
        ? {
            id: 'respTimeRangeStartTime',
            Header: t('start-time'),
            accessorFn: ({ item }: CellProps<FormAnswer>) => {
              const value = _get(item, 'resp', []).find(
                ({ group }) => group && group === RespGroupEnum.StartTime,
              )?.value;
              return convertTimeToHHmm(value);
            },
            Cell: (props: CellProps<FormAnswer>) => <TextCell {...props} />,
            width: cellWidth * 2,
            sortable: true,
          }
        : undefined,
      kind === FormTemplateQuestionFieldsKindEnum.TimeRange
        ? {
            id: 'respTimeRangeEndTime',
            Header: t('end-time'),
            accessorFn: ({ item }: CellProps<FormAnswer>) => {
              const value = _get(item, 'resp', []).find(
                ({ group }) => group && group === RespGroupEnum.EndTime,
              )?.value;
              return convertTimeToHHmm(value);
            },
            Cell: (props: CellProps<FormAnswer>) => <TextCell {...props} />,
            width: cellWidth * 2,
            sortable: true,
          }
        : undefined,
      kind === FormTemplateQuestionFieldsKindEnum.Datetime
        ? {
            id: 'respDatetimeValue',
            Header: t('date-and-time'),
            accessorFn: ({ item }: CellProps<User>) =>
              getFormattedDate(_get(item, 'resp.0.value'), dateTimeFormatEU),
            Cell: (props: CellProps<FormAnswer>) => <TextCell {...props} />,
            sortable: true,
          }
        : undefined,
      kind === FormTemplateQuestionFieldsKindEnum.Rating
        ? {
            id: 'respRatingValue',
            Header: t('rating'),
            accessor: 'resp.0.value',
            Cell: (props: CellProps<FormAnswer>) => {
              const { value } = props;
              return value ? (
                <Rating
                  value={{
                    value: parseInt(value as string, 10),
                    label: value as string,
                  }}
                  disabledHover
                />
              ) : (
                <TextCell {...props} value="-" />
              );
            },
            sortable: true,
          }
        : undefined,
      kind === FormTemplateQuestionFieldsKindEnum.Attachment
        ? {
            id: 'attachment',
            Header: t('attachment'),
            accessor: 'attachmentsAttached',
            Cell: ({ value }: CellProps<FormAnswer>) => {
              if (!Array.isArray(value) || !value?.length) return '-';
              return (
                <div className={styles.images}>
                  {value?.map((itemValue) => {
                    const url =
                      itemValue?.urls?.large ||
                      itemValue?.urls?.original ||
                      itemValue?.url;
                    if (_get(itemValue, '_destroy')) return null;
                    return (
                      <div key={uuidv4()}>
                        {(url || Placeholder) && (
                          <Image src={url || Placeholder} />
                        )}
                      </div>
                    );
                  })}
                </div>
              );
            },
          }
        : undefined,
      ...([
        FormTemplateQuestionFieldsKindEnum.SingleSelect,
        FormTemplateQuestionFieldsKindEnum.Checkbox,
        FormTemplateQuestionFieldsKindEnum.DecisionBox,
        FormTemplateQuestionFieldsKindEnum.RadioButton,
      ].includes(kind)
        ? options.map(({ values, id: itemOptionId }) => ({
            id: itemOptionId,
            Header: values[0],
            accessorFn: ({ item }: CellProps<FormAnswer>) =>
              _get(item, 'resp', []).find(
                ({ optionId }) => optionId === itemOptionId,
              ),
            Cell: (props: CellProps<FormAnswer>) => {
              const value = _get(props, 'value');
              const isSelected = _get(value, 'selected', false);
              const validityHint = _get(value, 'validityHint');
              if (
                isSelected &&
                kind === FormTemplateQuestionFieldsKindEnum.DecisionBox
              ) {
                return (
                  <Chip type={ChipTypes.chipBlue} leftIcon="check_circle" />
                );
              }
              if (isSelected) {
                return getValidityHintChipIcon(validityHint);
              }
              return <TextCell {...props} value="-" />;
            },
            minWidth: largeCellWidth,
          }))
        : []),
      ...(kind === FormTemplateQuestionFieldsKindEnum.CheckboxButtonGrid
        ? options
            .filter(({ group }) => group === GroupEnum.Columns)
            .map(({ values, id: itemOptionId }) => ({
              id: itemOptionId,
              Header: values[0],
              accessorFn: ({ item }: CellProps<FormAnswer>) =>
                _get(item, 'resp', []),
              Cell: (props: CellProps<FormAnswer>) => {
                let value = '-';
                const columnResp = _get(props, 'value', []);
                const rows = options.filter(
                  ({ group }) => group === GroupEnum.Rows,
                );
                if (Array.isArray(columnResp)) {
                  const columns = columnResp.filter(
                    ({ valueId }) => valueId === itemOptionId,
                  );
                  if (columns?.length) {
                    return (
                      <div>
                        {columns.map((column) => {
                          const optionId = _get(column, 'optionId', '');
                          const validityHint = _get(column, 'validityHint');
                          const rowValues = rows.filter(
                            ({ id }) => id === optionId,
                          );
                          return rowValues.map((row) => {
                            value = _get(row, ['values', 0]);
                            return (
                              <div
                                key={uuidv4()}
                                className={styles.cellWithValidityHint}
                              >
                                <TextCellWithIcon
                                  {...props}
                                  icon={getValidityHintChipIcon(validityHint)}
                                  value={value}
                                  multiLine
                                />
                              </div>
                            );
                          });
                        })}
                      </div>
                    );
                  }
                }
                return <TextCell {...props} value={value} />;
              },
              minWidth: largeCellWidth,
            }))
        : []),
      [
        FormTemplateQuestionFieldsKindEnum.YesNo,
        FormTemplateQuestionFieldsKindEnum.MinMax,
        FormTemplateQuestionFieldsKindEnum.Time,
        FormTemplateQuestionFieldsKindEnum.TimeRange,
        FormTemplateQuestionFieldsKindEnum.Date,
        FormTemplateQuestionFieldsKindEnum.DateRange,
        FormTemplateQuestionFieldsKindEnum.Datetime,
        FormTemplateQuestionFieldsKindEnum.Rating,
        FormTemplateQuestionFieldsKindEnum.Attachment,
        FormTemplateQuestionFieldsKindEnum.DecisionBox,
      ].includes(kind)
        ? {
            id: 'empty-cell',
            Header: '',
            Cell: () => '',
          }
        : undefined,
    ],
    undefined,
  ) as Column<FormAnswer>[];
};
