import React, { memo } from 'react';
import { useFormContext } from 'react-hook-form';
import classNames from 'classnames';
import _get from 'lodash/get';
import _replace from 'lodash/replace';
import IconButton, {
  IconButtonTypes,
} from '@lib/components/IconButton/IconButton';
import styles from './OptionsFieldOption.module.scss';

interface Props {
  onRemove: () => void;
  parentName: string;
  subFieldsMinLength?: number;
  children: React.ReactNode;
}

function OptionsFieldOption(props: Props) {
  const { onRemove, parentName, subFieldsMinLength, children } = props;
  const { getValues } = useFormContext();
  const values = getValues();
  const fieldValue = _get(values, _replace(parentName, '.options', ''));
  const isDeleteBtnVisible = subFieldsMinLength
    ? fieldValue?.length > subFieldsMinLength
    : true;

  return (
    <div
      className={classNames(styles.optionWrap, {
        [styles.optionWrapWithDelete]: isDeleteBtnVisible,
      })}
    >
      {children}
      {isDeleteBtnVisible && (
        <IconButton
          icon="close"
          rounded
          type={IconButtonTypes.TertiaryFilled}
          onClick={onRemove}
          className={styles.optionDeleteBtn}
        />
      )}
    </div>
  );
}

export default memo(OptionsFieldOption);
