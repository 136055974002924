import {
  AssetCategoriesQueryVariables,
  AssetCategory,
  AssetCategoryFilterInputObject,
  useAssetCategoriesQuery,
} from 'graphql-common';
import _get from 'lodash/get';
import { GetUrlParams } from '@lib/enums/urls';
import createSearchFilters from '@lib/utils/createSearchFilters';
import { ApolloError } from '@apollo/client/errors';
import { ListQueryHookProps } from './types';

export type UseAssetCategoriesQueryHookResult = {
  collection: AssetCategory[];
  error?: ApolloError;
  firstLoading: boolean;
  loading: boolean;
  refetch: () => void;
  totalCount: number;
  totalPages: number;
};

export default function useAssetCategoriesQueryHook({
  paginationParams,
  searchQuery,
}: ListQueryHookProps): UseAssetCategoriesQueryHookResult {
  const page = _get(paginationParams, GetUrlParams.Page);
  const perPage = _get(paginationParams, GetUrlParams.PerPage);
  const orderingField = _get(paginationParams, GetUrlParams.OrderingField);
  const orderingDirection = _get(
    paginationParams,
    GetUrlParams.OrderingDirection,
  );
  const assetCategoriesQueryVariables: AssetCategoriesQueryVariables = {
    page: Number(page),
    limit: Number(perPage),
  };
  const filters: AssetCategoryFilterInputObject[] = [];
  if (searchQuery) {
    filters.push(...createSearchFilters(searchQuery, [{ fieldName: 'name' }]));
  }
  if (filters.length) {
    assetCategoriesQueryVariables.filters = filters;
  }
  if (orderingField && orderingDirection) {
    assetCategoriesQueryVariables.sorts = {
      [orderingField]: orderingDirection,
    };
  }
  const query = useAssetCategoriesQuery({
    fetchPolicy: 'cache-and-network',
    variables: assetCategoriesQueryVariables,
  });
  const { loading, data, previousData, refetch, error } = query;
  const queryData = data || previousData;
  const firstLoading = loading && previousData === undefined;
  const collection = _get(queryData, 'data.collection', []) as AssetCategory[];
  const metadata = _get(queryData, 'data.metadata');
  const totalCount = _get(metadata, 'totalCount', 0);
  const totalPages = _get(metadata, 'totalPages', 1);
  return {
    refetch,
    error,
    firstLoading,
    collection,
    totalCount,
    totalPages,
    loading,
  };
}
