import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import _get from 'lodash/get';
import {
  Site,
  SiteArea,
  SiteUpdateInputObject,
  useSiteAreaBulkDestroyMutation,
  useSiteAreaBulkQrCodeCreateMutation,
  useSiteAreaQuery,
  useSiteAreaUpdateMutation,
  useSiteQuery,
  useSiteUpdateMutation,
  useUsersSearchListLazyQuery,
} from 'graphql-common';
import toast from '@lib/components/Toast/Toast';
import { Values } from '@lib/interfaces/form';
import onDestroyListItemCallBack from '@lib/utils/onDestroyListItemCallBack';
import useSearchParam from '@lib/hooks/useSearchParam';
import { SubmitAction } from '@lib/enums/form';
import usePaginationParams from '@lib/hooks/usePaginationParams';
import { FormRef } from '@lib/components/ReactHookForm/FormContainer';
import { UseBulkSelectResult } from '@lib/hooks/useTableItemsBulkSelect';
import tableItemsBulkAction from '@lib/utils/tableItemsBulkAction';
import getAllTableItemsIds from '@lib/utils/getAllTableItemsIds';
import { getSiteMembershipsLoadOptions as getSiteMembershipsLoadOptionsFn } from 'utils/fetch/siteMembershipsSearchList';
import useSiteAreasQueryHook from 'utils/fetch/useSiteAreasQueryHook';
import useQrCodeActions from 'routes/Assets/Asset/utils/useQrCodeActions';
import getSiteAreaPreparedDataFromValues from './getSiteAreaPreparedDataFromValues';
import { UseModalManagementResult } from './useSiteAreasModalManagement';

interface UseActionsProps {
  formNewAreaRef: React.MutableRefObject<FormRef | undefined>;
  siteId?: string;
  submitNewAreaRef: React.MutableRefObject<SubmitAction | undefined>;
  useBulkSelectResult: UseBulkSelectResult<SiteArea>;
  useModalManagementResult: UseModalManagementResult;
}

export default function useSiteAreasActions(props: UseActionsProps) {
  const {
    formNewAreaRef,
    siteId = '',
    submitNewAreaRef,
    useBulkSelectResult,
    useModalManagementResult,
  } = props;

  const {
    selectedSingleTableItem,
    handleUnselectAllTableItems,
    handleSelectSingleTableItem,
  } = useBulkSelectResult;

  const {
    closeGetQrCodeModal,
    closeSiteAreaCreateModal,
    closeSiteAreaDeleteModal,
    closeSiteAreaModal,
    openGetQrCodeModal,
    openSiteAreaDeleteModal,
    openSiteAreaEditModal,
    openSiteAreaViewModal,
  } = useModalManagementResult;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { search, pathname } = useLocation();
  const [searchQuery] = useSearchParam();
  const [paginationParams] = usePaginationParams();

  // Site
  const siteQuery = useSiteQuery({
    skip: !siteId,
    variables: { findBy: { id: siteId } },
  });
  const siteData = _get(siteQuery, 'data.site') as Site;
  const siteLoading = _get(siteQuery, 'loading');
  const siteError = _get(siteQuery, 'error');

  // Areas
  const {
    collection,
    fetchSiteAreaIds,
    previousSiteAreas,
    refetchSiteAreas,
    siteAreasLoading,
    totalCount,
    totalPages,
  } = useSiteAreasQueryHook({ searchQuery, paginationParams, siteId });

  const onGetAllSiteAreaIds = () =>
    getAllTableItemsIds<SiteArea>({
      fetchIds: fetchSiteAreaIds,
      useBulkSelectResult,
    });

  // Area
  const areaId = selectedSingleTableItem?.id || '';
  const siteAreaQuery = useSiteAreaQuery({
    skip: !areaId || !siteId,
    variables: { findBy: { id: areaId }, siteFindBy: { id: siteId } },
  });
  const siteAreaData = _get(
    siteAreaQuery,
    'data.siteArea',
    selectedSingleTableItem,
  ) as SiteArea;
  const siteAreaName = _get(siteAreaData, 'name', '') as string;
  const siteAreaError = _get(siteAreaQuery, 'error');

  // Site Memberships
  const [fetchUsersSearchListLazyQuery] = useUsersSearchListLazyQuery({
    fetchPolicy: 'cache-and-network',
  });

  const getSiteMembershipsLoadOptions = () =>
    getSiteMembershipsLoadOptionsFn(fetchUsersSearchListLazyQuery, siteId);

  // Create area
  const [createMutation, createMutationResult] = useSiteUpdateMutation({
    onCompleted: () => {
      refetchSiteAreas();
      handleUnselectAllTableItems();
      if (submitNewAreaRef.current === SubmitAction.CreateAndClose) {
        closeSiteAreaCreateModal();
        toast({ content: t('create-site-area-success') });
      } else if (
        submitNewAreaRef.current === SubmitAction.CreateAndNew &&
        formNewAreaRef?.current
      ) {
        formNewAreaRef.current?.reset();
        toast({ content: t('create-site-area-success-and-new') });
      }
    },
    onError: (error) => {
      toast({ content: error.message || 'Oops! Something went wrong' });
    },
  });
  const {
    error: createSiteAreaMutationError,
    loading: createSiteAreaLoading,
    reset: createSiteAreaReset,
  } = createMutationResult;
  const onCreate = (values: Values) => {
    const newSiteData: Values = {
      areas: [values],
    };
    createMutation({
      variables: {
        id: siteId,
        attributes: newSiteData as SiteUpdateInputObject,
      },
    });
  };

  // Update area
  const [updateMutation, updateMutationResult] = useSiteAreaUpdateMutation({
    onCompleted: () => {
      toast({ content: t('update-site-area-success') });
      closeSiteAreaModal();
      refetchSiteAreas();
      handleUnselectAllTableItems();
    },
    onError: (error) => {
      toast({ content: error.message || 'Oops! Something went wrong' });
    },
  });
  const {
    error: updateSiteAreaMutationError,
    loading: updateSiteAreaMutationLoading,
    reset: updateSiteAreaReset,
  } = updateMutationResult;

  // Errors
  const graphQLErrors =
    createSiteAreaMutationError?.graphQLErrors ||
    updateSiteAreaMutationError?.graphQLErrors;

  // Destroy area
  const [destroyMutation] = useSiteAreaBulkDestroyMutation({
    onCompleted: (_response, clientOptions) => {
      closeSiteAreaDeleteModal();
      handleUnselectAllTableItems();
      onDestroyListItemCallBack({
        collection,
        collectionForDestroy:
          clientOptions?.variables?.input?.params?.ids || [],
        navigate,
        pathname,
        refetch: refetchSiteAreas,
        search,
        totalPages,
      });
    },
    onError: (error) => {
      toast({ content: error.message || 'Oops! Something went wrong' });
    },
  });

  const onDestroyHandler = (ids: string[]) => {
    if (ids.length) {
      destroyMutation({
        variables: {
          input: {
            siteId,
            ids,
          },
        },
      });
    }
  };

  const onDelete = (item?: SiteArea) => {
    if (item?.id) {
      handleSelectSingleTableItem(item);
      openSiteAreaDeleteModal();
    }
  };

  const onBulkDelete = () => {
    openSiteAreaDeleteModal();
  };

  const onConfirmedDelete = () => {
    closeSiteAreaDeleteModal();
    toast({
      content: t('destroy-site-area-success'),
      closeCallback: () => {
        tableItemsBulkAction<SiteArea>({
          action: onDestroyHandler,
          useBulkSelectResult,
          onGetAllIds: onGetAllSiteAreaIds,
        });
      },
    });
  };

  // Get QR codes
  const [qrCodeCreateMutation, qrCodeCreateMutationResult] =
    useSiteAreaBulkQrCodeCreateMutation();
  const { onQrCodeCreateAction } = useQrCodeActions({
    onCompleted: () => {
      closeGetQrCodeModal();
      handleUnselectAllTableItems();
    },
    qrCodeCreateMutation,
    variables: { siteId },
  });
  const onQrCodeCreate = (values: Values) => {
    const action = (ids: string[]) => onQrCodeCreateAction(values, ids);
    tableItemsBulkAction<SiteArea>({
      action,
      useBulkSelectResult,
      onGetAllIds: onGetAllSiteAreaIds,
    });
  };

  const getQrCode = (item: SiteArea) => {
    handleSelectSingleTableItem(item);
    openGetQrCodeModal();
  };

  const getQrCodesForSelected = () => {
    openGetQrCodeModal();
  };

  const onViewHandler = (item?: SiteArea) => {
    if (item?.id) {
      handleSelectSingleTableItem(item);
      openSiteAreaViewModal();
    }
  };

  const onEditHandler = (item?: SiteArea) => {
    if (item?.id) {
      handleSelectSingleTableItem(item);
      openSiteAreaEditModal();
    }
  };

  const onUpdateHandler = (values: Values) => {
    if (values?.id) {
      updateMutation({
        variables: {
          id: values.id as string,
          siteId,
          attributes: getSiteAreaPreparedDataFromValues(values),
        },
      });
    }
  };

  return {
    collection,
    createSiteAreaLoading,
    createSiteAreaReset,
    getQrCode,
    getQrCodesForSelected,
    getSiteMembershipsLoadOptions,
    graphQLErrors,
    onBulkDelete,
    onConfirmedDelete,
    onCreate,
    onDelete,
    onEditHandler,
    onQrCodeCreate,
    onUpdateHandler,
    onViewHandler,
    previousSiteAreas,
    qrCodeCreateMutationResult,
    siteAreaData,
    siteAreaError,
    siteAreaName,
    siteAreasLoading,
    siteData,
    siteError,
    siteLoading,
    totalCount,
    updateSiteAreaMutationLoading,
    updateSiteAreaReset,
  };
}
