import _get from 'lodash/get';
import {
  Task,
  useDashboardTaskShortQuery,
  useFormAnswerReportingInfoQuery,
} from 'graphql-common';

type UseQueriesProps = {
  taskId?: string;
  versionParams?: { versionId?: string | null };
};

export default function useReporting(props: UseQueriesProps) {
  const { taskId = '', versionParams } = props;

  const taskQuery = useDashboardTaskShortQuery({
    variables: { id: taskId },
    skip: !taskId,
    fetchPolicy: 'network-only',
  });
  const taskData = _get(taskQuery, 'data.data') as Task;
  const templateVersionId =
    versionParams?.versionId || _get(taskData, 'formTemplateVersion.id');
  const taskLoading = _get(taskQuery, 'loading');
  const taskError = _get(taskQuery, 'error');
  const variables = { taskId, templateVersionId };

  const reportingInfoQuery = useFormAnswerReportingInfoQuery({
    fetchPolicy: 'network-only',
    skip: !taskId || !templateVersionId,
    variables,
  });
  const reportingInfoData = _get(reportingInfoQuery, 'data.data', []);
  const reportingLoading = _get(reportingInfoQuery, 'loading', false);
  const reportingError = _get(reportingInfoQuery, 'error');
  const reportingInfo = [...reportingInfoData].sort(
    (a, b) => a.position - b.position,
  );

  return {
    reportingError,
    reportingInfo,
    reportingLoading,
    taskData,
    taskError,
    taskLoading,
  };
}
