import { LazyQueryExecFunction } from '@apollo/client';

import {
  FormTemplate,
  FormTemplateFilterInputObject,
  FormTemplateScopeNameEnum,
  FormTemplatesSearchListQuery,
  FormTemplatesSearchListQueryVariables,
  SortDirectionEnum,
} from 'graphql-common';
import { DEFAULT_SEARCH_LIST_VARIABLES } from '@lib/enums/urls';
import getLoadOptionsResult from '@lib/utils/getLoadOptionsResult';
import createSearchFilters from '@lib/utils/createSearchFilters';
import { LoadOptionsParams } from '@lib/components/Select/types';

type FetchLazyQuery = LazyQueryExecFunction<FormTemplatesSearchListQuery, any>;

const fetchSearchList = async (
  searchQuery: string,
  fetchLazyQuery: FetchLazyQuery,
  page: number,
) => {
  const options: { variables: FormTemplatesSearchListQueryVariables } = {
    variables: {
      ...DEFAULT_SEARCH_LIST_VARIABLES,
      page,
      sorts: { name: SortDirectionEnum.Asc },
      scopeName: FormTemplateScopeNameEnum.Active,
    },
  };
  const filters: FormTemplateFilterInputObject[] = [];
  if (searchQuery) {
    filters.push(
      ...createSearchFilters(searchQuery, [
        { fieldName: 'name' },
        { fieldName: 'categoryName' },
      ]),
    );
  }
  if (filters.length) {
    options.variables.filters = filters;
  }
  const { data } = await fetchLazyQuery(options);
  return getLoadOptionsResult(
    data,
    (dataItem: FormTemplate) => ({
      value: dataItem.id,
      label: dataItem.name,
    }),
    searchQuery,
  );
};

export const getFormTemplatesLoadOptions =
  (fetchLazyQuery: FetchLazyQuery) =>
  (searchQuery: string, loadedOptions: unknown, params: LoadOptionsParams) =>
    fetchSearchList(searchQuery, fetchLazyQuery, params?.page || 1);
