import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormTemplateQuestionFieldsKindEnum } from 'graphql-common';
import Chip, { ChipTypes } from '@lib/components/Chip/Chip';
import getQuestionTypeProps from 'components/FormTemplateBuilder/utils/getQuestionTypeProps';
import classNames from 'classnames';
import styles from '../FormTemplateBuilderForm/FormTemplateBuilderForm.module.scss';

interface Props {
  className?: string;
  kind: FormTemplateQuestionFieldsKindEnum;
}

function QuestionTypeChip({ className, kind }: Props) {
  const { t } = useTranslation();
  const questionTypeProps = getQuestionTypeProps(kind);
  return questionTypeProps ? (
    <div className={classNames(styles.typeChip, className)}>
      <Chip
        label={t(questionTypeProps.nameTranslationKey)}
        leftIcon={questionTypeProps.icon}
        leftIconComponent={questionTypeProps.leftIconComponent}
        leftIconSymbolsOutlined={questionTypeProps.leftIconSymbolsOutlined}
        type={ChipTypes.chipBlack}
      />
    </div>
  ) : undefined;
}

export default QuestionTypeChip;
