import { TFunction } from 'i18next';
import { Team } from 'graphql-common';
import { NavItem, NavItemTypeEnum } from '@lib/components/Menu/Menu';
import Icons from '@lib/components/Icons/Icons';

type ActionsMenuArgs = {
  onView: (v?: Team) => void;
  onEdit: (v?: Team) => void;
  onDelete: (v?: Team) => void;
  t: TFunction<'translation', undefined>;
};

export default function getTableActionsMenu({
  onView,
  onEdit,
  onDelete,
  t,
}: ActionsMenuArgs): NavItem<Team>[] {
  return [
    {
      id: 'view-action',
      title: t('view'),
      onClick: (item?: Team) => onView(item),
      leftIcon: Icons.view,
      type: NavItemTypeEnum.Secondary,
    },
    {
      id: 'edit-action',
      title: t('edit'),
      onClick: (item?: Team) => onEdit(item),
      leftIcon: Icons.edit,
      type: NavItemTypeEnum.Secondary,
      predictorFnVisible: (item) => !!item?.canUpdate?.value,
    },
    {
      id: 'delete-action',
      title: t('delete'),
      onClick: (item?: Team) => onDelete(item),
      leftIcon: Icons.delete,
      type: NavItemTypeEnum.Secondary,
      predictorFnVisible: (item) => !!item?.canDestroy?.value,
    },
  ];
}
