import { TFunction } from 'i18next';
import _without from 'lodash/without';
import { Intervention } from 'graphql-common';
import { NavItem, NavItemTypeEnum } from '@lib/components/Menu/Menu';
import Icons from '@lib/components/Icons/Icons';

type Args = {
  onEdit?: (v: Intervention) => void;
  onDelete?: (v: Intervention) => void;
  t: TFunction<'translation', undefined>;
  isViewOnly: boolean;
};

export default function getInterventionViewPageActionsMenu({
  onEdit,
  onDelete,
  t,
  isViewOnly,
}: Args) {
  return _without(
    [
      onEdit
        ? {
            id: 'edit-action',
            title: t('edit'),
            onClick: (item: Intervention) => onEdit(item),
            leftIcon: Icons.edit,
            type: NavItemTypeEnum.Secondary,
            predictorFnVisible: () => !isViewOnly,
          }
        : undefined,
      onDelete
        ? {
            id: 'delete-action',
            title: t('delete'),
            onClick: (item: Intervention) => onDelete(item),
            leftIcon: Icons.delete,
            type: NavItemTypeEnum.Secondary,
            predictorFnVisible: () => !isViewOnly,
          }
        : undefined,
    ],
    undefined,
  ) as NavItem<Intervention>[];
}
