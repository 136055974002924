import React from 'react';
import { useTranslation } from 'react-i18next';
import Button, { ButtonTypes } from '@lib/components/Button/Button';
import ActionsRow from '@lib/components/ActionsRow/ActionsRow';

interface Props {
  isEditForm?: boolean;
  onCancel: () => void;
  loading?: boolean;
}

function CategoryFormActions(props: Props) {
  const { isEditForm, onCancel, loading } = props;
  const { t } = useTranslation();
  return (
    <ActionsRow
      actions={[
        <Button
          buttonType={ButtonTypes.secondaryOutlined}
          buttonText={t('cancel')}
          leftIcon="close"
          onClick={onCancel}
          fullWidth
          disabled={loading}
        />,
        <Button
          type="submit"
          buttonType={ButtonTypes.primaryFilled}
          buttonText={isEditForm ? t('save-changes') : t('create')}
          leftIcon="check"
          fullWidth
          isLoading={loading}
        />,
      ]}
    />
  );
}

export default CategoryFormActions;
