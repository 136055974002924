import React from 'react';
import classNames from 'classnames';
import Typography, { Variant } from '@lib/components/Typography/Typography';
import CountUp from '@lib/components/CountUp/CountUp';
import styles from './CountMark.module.scss';

export enum CountMarkTypes {
  green = 'green',
  blue = 'blue',
  red = 'red',
  orange = 'orange',
}

export enum CountMarkSizes {
  large = 'large',
  medium = 'medium',
  small = 'small',
}

type Props = {
  disabled?: boolean;
  size?: CountMarkSizes;
  type?: CountMarkTypes;
  useCountUp?: boolean;
  value?: string | number | null;
};

export default function CountMark(props: Props) {
  const {
    disabled,
    size = CountMarkSizes.medium,
    type = CountMarkTypes.blue,
    useCountUp = false,
    value,
  } = props;
  if (value === undefined || value === null) return null;
  let variant: Variant = 'caption';
  let strong = false;
  if (size === CountMarkSizes.large) {
    variant = 'body';
    strong = true;
  }
  return (
    <div
      className={classNames(styles.counter, {
        [styles[type]]: true,
        [styles[size]]: true,
        [styles.disabled]: disabled,
      })}
    >
      <Typography variant={variant} strong={strong}>
        {typeof value === 'number' && useCountUp ? (
          <CountUp end={value} duration={3} />
        ) : (
          value
        )}
      </Typography>
    </div>
  );
}
