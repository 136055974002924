import { TaskTimelineGroupByEnum } from 'graphql-common';
import {
  parseISO,
  startOfDay,
  startOfYear,
  startOfMonth,
  endOfDay,
  endOfYear,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  differenceInCalendarDays,
  isAfter,
} from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import {
  addMonthsFn,
  addWeeksFn,
  addYearsFn,
  CET,
  revertTransformDate,
  subMonthsFn,
  subWeeksFn,
  subYearsFn,
} from '@lib/utils/dateTimeHelpers';

export const getPeriodStartDate = (
  dateValue: string,
  view: TaskTimelineGroupByEnum,
) => {
  const date = utcToZonedTime(parseISO(dateValue), CET);
  let periodStartDate = startOfDay(date);

  switch (view) {
    case TaskTimelineGroupByEnum.Year:
      periodStartDate = startOfYear(date);
      break;
    case TaskTimelineGroupByEnum.Month:
      periodStartDate = startOfMonth(date);
      break;
    case TaskTimelineGroupByEnum.Week:
      periodStartDate = startOfWeek(date, { weekStartsOn: 1 }); // Monday as the start of the week
      break;
    default:
      break;
  }

  return zonedTimeToUtc(periodStartDate, CET);
};

export const getPeriodEndDate = (
  dateValue: string,
  view: TaskTimelineGroupByEnum,
) => {
  const date = utcToZonedTime(parseISO(dateValue), CET);
  let periodEndDate = endOfDay(date);

  switch (view) {
    case TaskTimelineGroupByEnum.Year:
      periodEndDate = endOfYear(date);
      break;
    case TaskTimelineGroupByEnum.Month:
      periodEndDate = endOfMonth(date);
      break;
    case TaskTimelineGroupByEnum.Week:
      periodEndDate = endOfWeek(date, { weekStartsOn: 1 }); // Sunday as the end of the week
      break;
    default:
      break;
  }

  return periodEndDate;
};

export function getDecrementedNextEndDate(
  nextEndDate: Date,
  view: TaskTimelineGroupByEnum,
) {
  if (view === TaskTimelineGroupByEnum.Year) {
    return subYearsFn(nextEndDate, 1);
  }
  if (view === TaskTimelineGroupByEnum.Month) {
    return subMonthsFn(nextEndDate, 1);
  }
  if (view === TaskTimelineGroupByEnum.Week) {
    return subWeeksFn(nextEndDate, 1);
  }
  return undefined;
}

export function getIncrementedNextEndDate(
  nextEndDate: Date,
  view: TaskTimelineGroupByEnum,
) {
  if (view === TaskTimelineGroupByEnum.Year) {
    return addYearsFn(nextEndDate, 1);
  }
  if (view === TaskTimelineGroupByEnum.Month) {
    return addMonthsFn(nextEndDate, 1);
  }
  if (view === TaskTimelineGroupByEnum.Week) {
    return addWeeksFn(nextEndDate, 1);
  }
  return undefined;
}

export function getMinDate(
  periodStartParam: string | null | undefined,
  view: TaskTimelineGroupByEnum,
) {
  if (periodStartParam) {
    const comparedDate = new Date(periodStartParam);
    if (view === TaskTimelineGroupByEnum.Week) {
      return startOfWeek(comparedDate, { weekStartsOn: 1 });
    }
    if (view === TaskTimelineGroupByEnum.Month) {
      return startOfMonth(comparedDate);
    }
    if (view === TaskTimelineGroupByEnum.Year) {
      return startOfYear(comparedDate);
    }
  }
  return undefined;
}

export function getMaxDate(
  periodEndParam: string | null | undefined,
  view: TaskTimelineGroupByEnum,
) {
  const date = periodEndParam
    ? new Date(periodEndParam)
    : new Date().toISOString();
  const comparedDate = new Date(date);
  if (view === TaskTimelineGroupByEnum.Week) {
    return endOfWeek(comparedDate, { weekStartsOn: 1 });
  }
  if (view === TaskTimelineGroupByEnum.Month) {
    return endOfMonth(comparedDate);
  }
  if (view === TaskTimelineGroupByEnum.Year) {
    return endOfYear(comparedDate);
  }
  return new Date();
}

export function getIsDecrementDisabled(
  endDate: string | null | undefined,
  periodStartParam: string | null | undefined,
  view: TaskTimelineGroupByEnum,
) {
  if (endDate && periodStartParam) {
    const nextEndDate = getDecrementedNextEndDate(
      new Date(revertTransformDate(endDate)),
      view,
    );
    return nextEndDate
      ? differenceInCalendarDays(nextEndDate, new Date(periodStartParam)) < 0
      : false;
  }
  return false;
}

export function getIsIncrementDisabled(
  endDate: string | null | undefined,
  periodEndParam: string | null | undefined,
  view: TaskTimelineGroupByEnum,
) {
  if (endDate && periodEndParam) {
    const nextEndDate = getIncrementedNextEndDate(
      new Date(revertTransformDate(endDate)),
      view,
    );
    let comparedDate = new Date(periodEndParam);
    if (view === TaskTimelineGroupByEnum.Week) {
      comparedDate = endOfWeek(comparedDate, { weekStartsOn: 1 });
    } else if (view === TaskTimelineGroupByEnum.Month) {
      comparedDate = endOfMonth(comparedDate);
    } else if (view === TaskTimelineGroupByEnum.Year) {
      comparedDate = endOfYear(comparedDate);
    }
    return nextEndDate ? isAfter(nextEndDate, comparedDate) : false;
  }
  return !(
    endDate &&
    differenceInCalendarDays(
      new Date(),
      new Date(revertTransformDate(endDate)),
    ) > 0
  );
}
