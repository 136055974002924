import React, { useRef, useLayoutEffect, useState } from 'react';

type Item = { value: string; key?: string };
type Items = Item[];

export interface ItemElement extends HTMLDivElement {
  dataset: {
    id: string;
  };
}

type RenderItemProps = {
  ref: (node: ItemElement) => void;
  isVisible: boolean;
  item: Item;
  key: string;
};
type RenderItem = ({
  ref,
  isVisible,
  item,
  key,
}: RenderItemProps) => React.ReactNode;
type RenderMoreItemsProps = { items: Items; item: Item };
type RenderMoreItems = ({
  items,
  item,
}: RenderMoreItemsProps) => React.ReactNode;

type Props = {
  className?: string;
  items: Items;
  renderItem: RenderItem;
  renderMoreItems: RenderMoreItems;
  offsetWidth: number;
  withoutCrop?: boolean;
};

function getItemKey(item: Item) {
  return item.value || item.key || '';
}

function CroppedRow(props: Props) {
  const {
    className,
    items,
    renderItem,
    renderMoreItems,
    offsetWidth,
    withoutCrop,
  } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  const itemRefs = useRef<Map<string, ItemElement>>(new Map());
  const [visibleIds, setVisibleIds] = useState<string[]>(
    items.map((item) => getItemKey(item)),
  );
  const hasMore = visibleIds.length !== items.length;

  useLayoutEffect(() => {
    if (withoutCrop) {
      setVisibleIds(items.map((item) => getItemKey(item)));
      return () => {};
    }
    const observer = new IntersectionObserver(
      (entries) => {
        const visibleSet = new Set(visibleIds);
        entries.forEach((entry) => {
          const target = entry.target as ItemElement;
          if (entry.intersectionRatio === 1) {
            visibleSet.add(target.dataset.id);
          } else {
            visibleSet.delete(target.dataset.id);
          }
        });
        setVisibleIds([...visibleSet]);
      },
      {
        root: containerRef.current,
        threshold: [1],
        rootMargin: `0px -${offsetWidth}px 0px 0px`,
      },
    );
    [...itemRefs.current.values()].forEach((el) => {
      observer.observe(el);
    });
    return () => observer && observer.disconnect();
  }, [visibleIds.length]); // eslint-disable-line

  // Hide overflowing elements on mount because IntersectionObserver callback has small delay
  const itemsKey = items.map((item) => getItemKey(item)).join('.');
  useLayoutEffect(() => {
    if (containerRef.current) {
      const visibleSet = new Set(items.map((item) => getItemKey(item)));
      const containerWidth = containerRef.current.getBoundingClientRect().width;
      [...itemRefs.current.values()].forEach((el) => {
        if (
          el.offsetLeft + el.getBoundingClientRect().width >
          containerWidth - offsetWidth
        ) {
          visibleSet.delete(el.dataset.id);
        }
      });
      setVisibleIds([...visibleSet]);
    }
  }, [items, itemsKey, offsetWidth]);

  return (
    <div className={className} ref={containerRef}>
      {items.map((item, index) => {
        const itemKey = getItemKey(item);
        const isPrevVisible =
          items[index - 1] && visibleIds.includes(items[index - 1].value);
        const isVisible = visibleIds.includes(itemKey);
        const refFunc = (node: ItemElement) => {
          if (node) {
            itemRefs.current.set(itemKey, node);
          } else {
            itemRefs.current.delete(itemKey);
          }
        };
        const itemComponent = renderItem({
          item,
          ref: refFunc,
          isVisible,
          key: itemKey,
        });
        if (hasMore && isPrevVisible && !isVisible) {
          return (
            <React.Fragment key={itemKey}>
              {renderMoreItems({
                item,
                items: items.filter(
                  (moreItem) => !visibleIds.includes(moreItem.value),
                ),
              })}
              {itemComponent}
            </React.Fragment>
          );
        }
        return itemComponent;
      })}
    </div>
  );
}

export default CroppedRow;
