import React, { memo } from 'react';
import CroppedRow from '@lib/components/CroppedRow/CroppedRow';
import classNames from 'classnames';
import styles from './TabBar.module.scss';
import TabBarMoreItems from './TabBarMoreItems';
import { ActiveItem, TabBarItem as TabBarItemType } from './constants';
import TabBarItem from './TabBarItem';

const MORE_ITEMS_WIDTH = 52;
const OFFSET_WIDTH = MORE_ITEMS_WIDTH + 16;

type Props = {
  active?: ActiveItem;
  className?: string;
  items: TabBarItemType[];
  onActiveChange: (item: TabBarItemType) => void;
  withoutCrop?: boolean;
  useSidePaddings?: boolean;
};

function TabBar({
  active,
  className,
  useSidePaddings,
  items,
  onActiveChange,
  withoutCrop,
}: Props) {
  return (
    <CroppedRow
      className={classNames(
        styles.root,
        { [styles.rootWithSidePaddings]: useSidePaddings },
        className,
        'TabBarComponent',
      )}
      items={items}
      offsetWidth={OFFSET_WIDTH}
      renderItem={({ key, ...props }) => (
        // @ts-ignore // TODO: fix this
        <TabBarItem
          key={key}
          active={active}
          onActiveChange={onActiveChange}
          {...props}
        />
      )}
      renderMoreItems={(props) => (
        <TabBarMoreItems
          active={active}
          onActiveChange={onActiveChange}
          {...props}
        />
      )}
      withoutCrop={withoutCrop}
    />
  );
}

export default memo(TabBar);
