import React from 'react';
import _get from 'lodash/get';
import { Attachment, Documentation } from 'graphql-common';
import DocumentationPreview from '@lib/components/DocumentationPreview/DocumentationPreview';
import AssetDocumentFieldNames from '@lib/enums/fieldNames/assetDocumentFieldNames';
import IconButton from '@lib/components/IconButton/IconButton';
import styles from './DocumentationPreviewWrap.module.scss';

type Props = {
  name: string;
  isCreatePage?: boolean;
  prevValues: Documentation;
  values: Documentation;
  onPreviewAction?: (v: Documentation) => void;
};

export default function DocumentationPreviewWrap(props: Props) {
  const { onPreviewAction, values, name, prevValues, isCreatePage } = props;
  const attachmentAttached = _get(values, name) as Attachment;
  const isViewAvailable = !!onPreviewAction && !!attachmentAttached?.id;
  const customName =
    (_get(prevValues, AssetDocumentFieldNames.Name) as string) ||
    (_get(
      values,
      name.replace(
        AssetDocumentFieldNames.Document,
        AssetDocumentFieldNames.Name,
      ),
    ) as string);
  const preview = (
    <DocumentationPreview
      attachment={attachmentAttached}
      customName={customName}
      useDefaultPdfPreview={isCreatePage}
    />
  );
  if (isViewAvailable) {
    return (
      <div className={styles.documentationPreviewWithBtn}>
        {preview}
        <IconButton
          icon="visibility"
          onClick={() => onPreviewAction(values)}
          symbolsOutlined
          className={styles.viewButton}
        />
      </div>
    );
  }
  return <div className={styles.documentationPreview}>{preview}</div>;
}
