import useAssetWithCountersQueryHook, {
  UseAssetQueryHookResult,
} from 'utils/fetch/useAssetWithCountersQueryHook';
import { AssetViewMode } from 'routes/Assets/Asset/enums';

interface UseActionsProps {
  id?: string;
  viewMode: AssetViewMode;
}

export interface UseActionsResult {
  assetQueryHook: UseAssetQueryHookResult;
  assetId: string;
}

export default function useActions(props: UseActionsProps): UseActionsResult {
  const { id = '', viewMode } = props;

  // Asset
  const assetQueryHook = useAssetWithCountersQueryHook({
    id,
    includeFullData:
      viewMode === AssetViewMode.View || viewMode === AssetViewMode.Edit,
  });

  return {
    assetId: id,
    assetQueryHook,
  };
}
