import { FormTemplateQuestionFieldOptionsValidityHintEnum } from 'graphql-common';

export default function getValidityHintOptions() {
  return [
    {
      labelKey: 'validity-hint-optimal',
      value: FormTemplateQuestionFieldOptionsValidityHintEnum.Success,
    },
    {
      labelKey: 'validity-hint-warning',
      value: FormTemplateQuestionFieldOptionsValidityHintEnum.Warning,
    },
    {
      labelKey: 'validity-hint-critical',
      value: FormTemplateQuestionFieldOptionsValidityHintEnum.Failure,
    },
  ];
}
