import React, { memo } from 'react';
import classNames from 'classnames';
import { useFormContext } from 'react-hook-form';
import _get from 'lodash/get';
import _replace from 'lodash/replace';
import { GroupEnum } from 'graphql-common';
import Select from '@lib/components/Select/Select';
import ValidityHintOption from '@lib/components/Select/components/ValidityHintOption';
import ValidityHintSingleValue from '@lib/components/Select/components/ValidityHintOptionSingleValue';
import FormItem from '@lib/components/ReactHookForm/FormItem';
import {
  AppendValue,
  FieldArrayItemRenderAddMore,
  FieldArrayItemType,
} from '@lib/components/ReactHookForm/types';
import getValidityHintOptions from 'components/FormTemplateBuilder/utils/getValidityHintOptions';
import getSortedFields from 'components/FormTemplateBuilder/QuestionCheckboxButtonGridField/utils/getSortedFields';
import getAppendCallback from 'components/FormTemplateBuilder/QuestionCheckboxButtonGridField/utils/getAppendCallback';
import getLastPosition from 'components/FormTemplateBuilder/QuestionCheckboxButtonGridField/utils/getLastPosition';
import FormTemplateFieldNames from '@lib/enums/fieldNames/formTemplateFieldNames';
import getAppendValue from './utils/getAppendValue';
import styles from './QuestionRadioButtonGridField.module.scss';

interface ChildrenProps {
  fieldItem: FieldArrayItemType;
  index: number;
  groupIndex: number;
}

interface Props {
  children: (props: ChildrenProps) => React.ReactNode;
  fields: FieldArrayItemType[];
  parentName: string;
  renderAddMore: FieldArrayItemRenderAddMore;
  renderSettings?: React.ReactNode;
}

function QuestionCheckboxButtonGridField(props: Props) {
  const { children, fields, parentName, renderAddMore, renderSettings } = props;
  const { getValues, setValue } = useFormContext();
  const values = getValues();
  const parentValue = _get(
    values,
    _replace(parentName, `.${FormTemplateFieldNames.Options}`, ''),
    {},
  );
  const options = _get(parentValue, FormTemplateFieldNames.Options);
  const validityHintConfig = _get(
    parentValue,
    FormTemplateFieldNames.ValidityHintConfig,
  );
  const { rows, columns } = getSortedFields(fields, options);

  const getRenderAddMoreArgs = (
    group: GroupEnum,
  ): [AppendValue, () => void] => {
    const lastPosition = getLastPosition(fields);
    return [
      getAppendValue(group, lastPosition),
      getAppendCallback(
        group,
        lastPosition,
        validityHintConfig,
        parentName,
        setValue,
      ),
    ];
  };

  const getAddMore = (group: GroupEnum) => {
    const [appendValue, appendCallback] = getRenderAddMoreArgs(group);
    return renderAddMore(appendValue, appendCallback);
  };

  return (
    <div className={styles.gridRoot}>
      <div className={styles.gridWrap}>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>&#160;</th>
              {columns.map((column, groupIndex) => (
                <th key={column.fieldItem.position}>
                  <div className={styles.tdContent}>
                    {children({ ...column, groupIndex })}
                  </div>
                </th>
              ))}
              <th>
                <div
                  className={classNames(styles.tdContent, styles.tdBtnContent)}
                >
                  {getAddMore(GroupEnum.Columns)}
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {rows.map((row, groupIndex) => (
              <tr key={row.fieldItem.position}>
                <td>
                  <div className={styles.tdContent}>
                    {children({ ...row, groupIndex })}
                  </div>
                </td>
                {columns.map((column) => (
                  <td key={column.fieldItem.position}>
                    <div className={styles.validityHintWrap}>
                      <FormItem
                        name={`${parentName.replace(FormTemplateFieldNames.Options, FormTemplateFieldNames.ValidityHintConfig)}.${row.fieldItem.position}-${column.fieldItem.position}`}
                        element={Select}
                        componentProps={{
                          options: getValidityHintOptions(),
                          isClearable: false,
                          isSearchable: false,
                          fullWidthMenu: false,
                          hasBorder: false,
                          components: {
                            Option: ValidityHintOption,
                            SingleValue: ValidityHintSingleValue,
                          },
                          usePortal: true,
                        }}
                        hideErrorsSpace
                      />
                    </div>
                  </td>
                ))}
                <td>&#160;</td>
              </tr>
            ))}
            <tr>
              <td>
                <div
                  className={classNames(styles.tdContent, styles.tdBtnContent)}
                >
                  {getAddMore(GroupEnum.Rows)}
                </div>
              </td>
              {columns.map((column) => (
                <td key={column.fieldItem.position}>&#160;</td>
              ))}
              <td>&#160;</td>
            </tr>
          </tbody>
        </table>
      </div>
      {renderSettings}
    </div>
  );
}

export default memo(QuestionCheckboxButtonGridField);
