import React from 'react';
import _get from 'lodash/get';
import _find from 'lodash/find';
import classNames from 'classnames';
import {
  components as DefaultSelectComponents,
  OptionProps,
} from 'react-select';
import { SelectOption } from '@lib/components/Select/types';
import MaterialIcon from '@lib/components/MaterialIcon/MaterialIcon';
import styles from '../Select.module.scss';

function AreaOption({ children, ...restProps }: OptionProps): JSX.Element {
  const { isSelected, isMulti, options } = restProps;
  const value = _get(restProps, 'value', '');
  const selectedOption = _find(
    options,
    ({ value: optionValue }) => value === optionValue,
  ) as SelectOption;
  const { data } = selectedOption || {};
  const isPoorWifi = _get(data, 'poorWifi');
  return (
    <DefaultSelectComponents.Option
      {...restProps}
      className={classNames(styles.entityOption, {
        active: isSelected,
        [styles.optionWithCheckbox]: isMulti,
      })}
    >
      {isMulti && (
        <span className={styles.optionCheckbox}>
          <MaterialIcon
            icon={isSelected ? 'check_box' : 'check_box_outline_blank'}
            size="md-18"
          />
        </span>
      )}
      <span className={styles.entityOptionLabelWrap}>
        <span
          className={classNames(styles.entityOptionLabel, {
            [styles.optionLabelWithOfflineIcon]: isPoorWifi,
          })}
        >
          {children}
          {isPoorWifi && (
            <span className={styles.offlineIcon}>
              <MaterialIcon icon="wifi_off" size="md-16" />
            </span>
          )}
        </span>
      </span>
    </DefaultSelectComponents.Option>
  );
}

export default AreaOption;
