import { TFunction } from 'i18next';
import { UserViewMode } from 'routes/Users/enums';

type Args = {
  t: TFunction<'translation', undefined>;
};

export default function getUserViewPageTabs({ t }: Args) {
  return [
    {
      label: t('personal-info'),
      value: UserViewMode.View,
    },
    {
      label: t('access-rights'),
      value: UserViewMode.ViewAccess,
    },
  ];
}
