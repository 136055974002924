import { Task, Timeline } from 'graphql-common';
import _find from 'lodash/find';

export default function mergeTasksWithTimeline(
  tasks: Task[],
  timelineData: Timeline[],
): (Task & { timeline?: Timeline })[] {
  return tasks.map((task) => {
    const timelineItem = _find(
      timelineData,
      (timeline: Timeline) => timeline.id === task.id,
    );
    return {
      ...task,
      timeline: timelineItem,
    };
  });
}
