import React from 'react';
import { useTranslation } from 'react-i18next';
import { Task } from 'graphql-common';
import { FieldItem, Values } from '@lib/interfaces/form';
import { ApolloError } from '@apollo/client/errors';
import PageBody from '@lib/layouts/DashboardLayout/PageBody/PageBody';
import Form from '@lib/components/ReactHookForm/FormContainer';
import DashboardContentMaxWidth from '@lib/layouts/DashboardLayout/DashboardContentMaxWidth/DashboardContentMaxWidth';
import WhiteBox from '@lib/components/WhiteBox/WhiteBox';
import CircleLoader from '@lib/components/CircleLoader/CircleLoader';
import PageHeader from '@lib/layouts/DashboardLayout/PageHeader/PageHeader';
import ErrorPage from '@lib/components/ErrorPage/ErrorPage';
import getStatusFromError from '@lib/utils/getStatusFromError';
import getPrefilledQuestions from 'components/FormTemplateBuilder/utils/getPrefilledQuestions';
import getTaskValuesFormFromQueryData from 'components/Task/utils/getTaskValuesFormFromQueryData';
import { FormRefType } from '@lib/components/ReactHookForm/types';
import TaskEditFormActions from './TaskEditFormActions';

interface Props {
  updateMutationError?: ApolloError;
  taskError?: ApolloError;
  taskLoading?: boolean;
  updateTaskLoading: boolean;
  taskData: Task;
  formFields: FieldItem[];
  formRef: FormRefType;
  onSubmit: (values: Values) => void;
}

function TaskEdit(props: Props) {
  const {
    updateMutationError,
    updateTaskLoading,
    formFields,
    formRef,
    onSubmit,
    taskData,
    taskError,
    taskLoading,
  } = props;
  const { t } = useTranslation();

  const isContentVisible = !taskLoading && !taskError;

  if (taskError) {
    return (
      <ErrorPage
        status={getStatusFromError(taskError)}
        description={taskError?.message}
      />
    );
  }

  return (
    <DashboardContentMaxWidth>
      {isContentVisible && <PageHeader title={t('edit-task-details')} />}
      <PageBody withoutHeader={!isContentVisible}>
        {taskLoading ? (
          <CircleLoader />
        ) : (
          <WhiteBox verticalPaddings="large">
            <Form
              actionsComponent={TaskEditFormActions}
              defaultValues={getTaskValuesFormFromQueryData(taskData)}
              fields={formFields}
              formId="task-edit"
              formRef={formRef}
              graphQLErrors={updateMutationError?.graphQLErrors}
              graphQLErrorsReplacePathMap={{
                name: 'taskName',
                description: 'taskDescription',
                'formTemplate.name': 'name',
                'formTemplate.description': 'description',
                'formTemplate.questions': 'questions',
              }}
              loading={updateTaskLoading}
              onSubmit={onSubmit}
              sourceFields={{
                questions: getPrefilledQuestions({ t }),
              }}
            />
          </WhiteBox>
        )}
      </PageBody>
    </DashboardContentMaxWidth>
  );
}

export default TaskEdit;
