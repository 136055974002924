import React from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { Task } from 'graphql-common';
import WhiteBox from '@lib/components/WhiteBox/WhiteBox';
import DashboardTitleHeader from '@lib/layouts/DashboardLayout/DashboardTitleHeader/DashboardTitleHeader';
import Button, { ButtonTypes } from '@lib/components/Button/Button';
import ActionsMenu from '@lib/components/ActionsMenu/ActionsMenu';
import MaterialIcon from '@lib/components/MaterialIcon/MaterialIcon';
import TextEditor from '@lib/components/TextEditor/TextEditor';
import Typography from '@lib/components/Typography/Typography';
import EntityViewStyles from '@lib/assets/styles/EntityView.module.scss';
import Table from '@lib/components/Table/Table';
import { IconButtonTypes } from '@lib/components/IconButton/IconButton';
import CountMark, { CountMarkTypes } from '@lib/components/CountMark/CountMark';
import DocumentationPreview from '@lib/components/DocumentationPreview/DocumentationPreview';
import Carousel from '@lib/components/Carousel/Carousel';
import { DocumentationPreviewEnum } from '@lib/components/DocumentationPreview/enums';
import { UppyDocumentationAllowedFileTypes } from '@lib/enums/mimeTypes';
import FilesUploader from '@lib/components/FilesUploader/FilesUploader';
import CircleLoaderWrapper from '@lib/components/CircleLoaderWrapper/CircleLoaderWrapper';
import ViewOnlyChip from '@lib/components/ViewOnlyChip/ViewOnlyChip';
import getInterventionViewPageActionsMenu from '../utils/getInterventionViewPageActionsMenu';
import { UseInterventionActionsResult } from '../utils/useInterventionActions';
import { UseModalManagementResult } from '../utils/useModalManagement';
import getTaskTableColumns from '../utils/getTaskTableColumns';
import { InterventionViewMode } from '../enums';

type Props = {
  useModalManagementResult: UseModalManagementResult;
  useInterventionActionsResult: UseInterventionActionsResult;
  isViewOnly: boolean;
};

export default function InterventionViewDetails({
  useModalManagementResult,
  useInterventionActionsResult,
  isViewOnly,
}: Props) {
  const { t } = useTranslation();
  const {
    interventionQueryHookResult,
    loading,
    onAttachFiles,
    onViewChange,
    onViewTask,
    taskQueryHookResult,
  } = useInterventionActionsResult;
  const { data } = interventionQueryHookResult;
  const {
    name,
    description,
    attachmentsAttached,
    canDestroy,
    canClose,
    canUpdate,
  } = data || {};
  const isDestroyAvailable = canDestroy?.value;
  const isCloseAvailable = canClose?.value;
  const isUpdateAvailable = canUpdate?.value;

  const { data: task, loading: taskLoading } = taskQueryHookResult;

  const actionsMenu = getInterventionViewPageActionsMenu({
    t,
    onEdit: isUpdateAvailable
      ? () => onViewChange(InterventionViewMode.Edit)
      : undefined,
    onDelete: isDestroyAvailable
      ? useModalManagementResult.openInterventionDestroyModal
      : undefined,
    isViewOnly,
  });
  const taskTableColumns = getTaskTableColumns({
    t,
    onViewTask,
  });

  const isMenuVisible = actionsMenu.length > 0;

  return (
    <CircleLoaderWrapper isLoading={loading}>
      <WhiteBox>
        <DashboardTitleHeader
          title={name}
          actions={[
            isCloseAvailable && !isViewOnly ? (
              <Button
                key="close"
                buttonText={t('close')}
                buttonType={ButtonTypes.primaryFilled}
                onClick={() =>
                  useModalManagementResult.openInterventionCloseModal()
                }
                disabled={loading}
              />
            ) : undefined,
            isMenuVisible ? (
              <ActionsMenu
                key="actions-menu"
                actions={actionsMenu}
                disabled={loading}
              />
            ) : undefined,
            isViewOnly ? <ViewOnlyChip /> : undefined,
          ]}
        />
        {!!description && (
          <div className={EntityViewStyles.infoItemCol}>
            <p className={EntityViewStyles.infoItemTitle}>
              <MaterialIcon icon="description" symbolsOutlined />
              <Typography variant="caption">{t('description')}</Typography>
            </p>
            <div className={EntityViewStyles.infoItemContent}>
              <TextEditor
                readOnly
                value={description}
                className={EntityViewStyles.textEditor}
              />
            </div>
          </div>
        )}
        <div className={EntityViewStyles.infoItemCol}>
          <div className={EntityViewStyles.infoItemHeader}>
            <div className={EntityViewStyles.infoItemTitle}>
              <MaterialIcon icon="attach_file" symbolsOutlined />
              <Typography
                variant="caption"
                className={EntityViewStyles.infoItemLabelText}
                component="div"
              >
                {t('attachments')}
                <CountMark
                  value={attachmentsAttached?.length || 0}
                  type={CountMarkTypes.blue}
                />
              </Typography>
            </div>
            {!isViewOnly && (
              <FilesUploader
                // @ts-ignore
                onChange={onAttachFiles}
                value={[]}
                uploadButtonProps={{
                  buttonText: t('attach'),
                  buttonType: ButtonTypes.primaryOutlined,
                  leftIcon: 'attach_file',
                  fullWidth: true,
                }}
                allowedFileTypes={UppyDocumentationAllowedFileTypes}
                note={t('files-upload-validation-msg', {
                  sizeImg: 5,
                  sizePdf: 25,
                })}
              />
            )}
          </div>
          {!!attachmentsAttached?.length && (
            <div
              className={EntityViewStyles.infoItemContent}
              key={attachmentsAttached?.length}
            >
              <div className={EntityViewStyles.infoItemContentFullWidth}>
                <Carousel withWrap spaceBetween={16} slidesPerView="auto">
                  {attachmentsAttached.map((attachment) => (
                    <DocumentationPreview
                      key={uuidv4()}
                      attachment={attachment}
                      view={DocumentationPreviewEnum.BlockCard}
                      onClick={() =>
                        useModalManagementResult.openAttachmentPreviewModal(
                          attachment,
                        )
                      }
                    />
                  ))}
                </Carousel>
              </div>
            </div>
          )}
        </div>
        {!!task?.id && (
          <div className={EntityViewStyles.infoItemCol}>
            <p className={EntityViewStyles.infoItemTitle}>
              <MaterialIcon icon="task_alt" symbolsOutlined />
              <Typography variant="caption">{t('linked-task')}</Typography>
            </p>
            <div className={EntityViewStyles.infoItemContent}>
              <Table
                actions={[
                  {
                    id: 'view-action',
                    icon: 'visibility',
                    onClick: (item: Task) => onViewTask(item?.id),
                    type: IconButtonTypes.PrimaryOutlined,
                  },
                ]}
                columns={taskTableColumns}
                data={[task]}
                id="task"
                loading={taskLoading}
                totalEntries={1}
              />
            </div>
          </div>
        )}
      </WhiteBox>
    </CircleLoaderWrapper>
  );
}
