import React from 'react';
import { useTranslation } from 'react-i18next';
import { GraphQLErrors } from '@apollo/client/errors';
import { Values } from '@lib/interfaces/form';
import Form, {
  ActionsProps,
} from '@lib/components/ReactHookForm/FormContainer';
import Modal from '@lib/components/Modal/Modal';
import CategoryForm from 'components/AssetCategory/CategoryForm';
import { GetLoadOptions } from '@lib/components/Select/types';
import { SubmitAction } from '@lib/enums/form';
import { UseModalManagementResult } from 'routes/Assets/Asset/utils/useModalManagement';
import { UseAssetActionsResult } from 'routes/Assets/Asset/utils/useAssetActions';
import { FormRefType } from '@lib/components/ReactHookForm/types';
import { getFormFields } from './utils/getFormFields';
import AssetFormActions from './AssetFormActions';
import styles from './AssetForm.module.scss';

type Props = {
  categoryGraphQLErrors?: GraphQLErrors;
  categoryLoading: boolean;
  isCategoriesViewOnly: boolean;
  formRef: FormRefType;
  getAssetCategoriesLoadOptions: GetLoadOptions;
  getSiteAreasLoadOptions: GetLoadOptions;
  getSitesLoadOptions: GetLoadOptions;
  graphQLErrors?: GraphQLErrors;
  isEditForm?: boolean;
  loading: boolean;
  onSubmit: (values: Values) => void;
  submitNewAssetRef: React.MutableRefObject<SubmitAction | undefined>;
} & Pick<UseAssetActionsResult, 'defaultValues' | 'onAssetCategoryCreate'> &
  Pick<
    UseModalManagementResult,
    | 'closeCategoryFormModal'
    | 'isCategoryFormModalOpened'
    | 'openCategoryFormModal'
  >;

function AssetForm(props: Props) {
  const {
    categoryGraphQLErrors,
    categoryLoading,
    closeCategoryFormModal,
    defaultValues,
    formRef,
    getAssetCategoriesLoadOptions,
    getSiteAreasLoadOptions,
    getSitesLoadOptions,
    graphQLErrors,
    isCategoriesViewOnly,
    isCategoryFormModalOpened,
    isEditForm = false,
    loading,
    onAssetCategoryCreate,
    onSubmit,
    openCategoryFormModal,
    submitNewAssetRef,
  } = props;
  const { t } = useTranslation();

  const fields = getFormFields({
    getAssetCategoriesLoadOptions,
    getSiteAreasLoadOptions,
    getSitesLoadOptions,
    isCategoriesViewOnly,
    isEditForm,
    openCategoryFormModal,
    t,
  });

  const formActions: (props: ActionsProps) => React.JSX.Element = (
    actionsComponentProps,
  ) => (
    <AssetFormActions
      {...actionsComponentProps}
      isEditForm={!!defaultValues?.id}
      submitNewAssetRef={submitNewAssetRef}
    />
  );

  return (
    <div className={styles.wrap}>
      <Form
        formId="asset"
        actions={formActions}
        defaultValues={defaultValues}
        fields={fields}
        formRef={formRef}
        graphQLErrors={graphQLErrors}
        loading={loading}
        onSubmit={onSubmit}
      />
      <Modal
        isOpen={isCategoryFormModalOpened}
        title={t('create-new-asset-category')}
        onClose={closeCategoryFormModal}
      >
        <CategoryForm
          defaultValues={{}}
          graphQLErrors={categoryGraphQLErrors}
          loading={categoryLoading}
          onCancel={closeCategoryFormModal}
          onSubmit={onAssetCategoryCreate}
        />
      </Modal>
    </div>
  );
}

export default AssetForm;
