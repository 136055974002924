import {
  Team,
  TeamFilterInputObject,
  TeamsQueryVariables,
  useTeamsIdsLazyQuery,
  useTeamsIdsQuery,
  useTeamsQuery,
} from 'graphql-common';
import _get from 'lodash/get';
import { GetUrlParams } from '@lib/enums/urls';
import createSearchFilters from '@lib/utils/createSearchFilters';
import { ListQueryHookProps, ListQueryHookResult } from './types';

export default function useTeamsQueryHook({
  fetchPolicy = 'cache-and-network',
  paginationParams,
  orderingParams,
  searchQuery,
  skip,
  usePrevious = true,
  skipGetAllIds = true,
}: ListQueryHookProps): ListQueryHookResult<Team> {
  const page = _get(paginationParams, GetUrlParams.Page);
  const perPage = _get(paginationParams, GetUrlParams.PerPage);
  const orderingField = _get(orderingParams, GetUrlParams.OrderingField);
  const orderingDirection = _get(
    orderingParams,
    GetUrlParams.OrderingDirection,
  );
  const queryVariables: TeamsQueryVariables = {
    page: Number(page),
    limit: Number(perPage),
  };
  const filters: TeamFilterInputObject[] = [];
  if (searchQuery) {
    filters.push(
      ...createSearchFilters(searchQuery, [
        { fieldName: 'name' },
        { fieldName: 'usersFullName' },
      ]),
    );
  }
  if (filters.length) {
    queryVariables.filters = filters;
  }
  if (orderingField && orderingDirection) {
    queryVariables.sorts = {
      [orderingField]: orderingDirection,
    };
  }
  const query = useTeamsQuery({
    fetchPolicy,
    variables: queryVariables,
    skip,
  });
  const allIdsQuery = useTeamsIdsQuery({
    fetchPolicy: 'no-cache',
    variables: {
      onlyIds: true,
      filters: queryVariables.filters,
    },
    skip: skipGetAllIds,
  });
  const [fetchIds] = useTeamsIdsLazyQuery({
    fetchPolicy: 'no-cache',
    variables: {
      onlyIds: true,
      filters: queryVariables.filters,
    },
  });
  const { loading, data, previousData, refetch, error } = query;
  const queryData = usePrevious ? data || previousData : data;
  const firstLoading = loading && previousData === undefined;
  const collection = _get(queryData, 'data.collection', []) as Team[];
  const metadata = _get(queryData, 'data.metadata');
  const totalCount = _get(metadata, 'totalCount', 0);
  const totalPages = _get(metadata, 'totalPages', 1);

  return {
    fetchIds,
    refetch,
    collection,
    error,
    firstLoading,
    loading,
    totalPages,
    totalCount,
    allIds: _get(allIdsQuery, ['data', 'data', 'collection'], []),
    refetchAllIds: allIdsQuery.refetch,
  };
}
