import React from 'react';
import _get from 'lodash/get';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  EarlyCompletionUnitEnum,
  RecurringRuleCountdownTypeEnum,
  RecurringRuleRuleTypeEnum,
  RecurringRuleSchemaOptionEnum,
} from 'graphql-common';
import Trans from '@lib/components/Trans/Trans';
import SupportiveMessage from '@lib/components/SupportiveMessage/SupportiveMessage';
import {
  getFormattedDate,
  subDaysFn,
  subMonthsFn,
  subWeeksFn,
} from '@lib/utils/dateTimeHelpers';
import pluralizeUtil from '@lib/utils/pluralize';
import { EndRepeatUnit, FieldNames } from 'routes/Task/enums';
import { Option } from '@lib/interfaces/form';
import numberToOrdinal from '@lib/utils/numberToOrdinal';
import _toNumber from 'lodash/toNumber';

function getEarlyOrderDate(
  deadlineAt: Date,
  earlyCompletionUnit: string,
  earlyCompletionAmount: number,
): Date | undefined {
  try {
    if (!deadlineAt || !earlyCompletionUnit || !earlyCompletionAmount)
      return undefined;
    if (earlyCompletionUnit === EarlyCompletionUnitEnum.Day) {
      return subDaysFn(deadlineAt, earlyCompletionAmount);
    }
    if (earlyCompletionUnit === EarlyCompletionUnitEnum.Week) {
      return subWeeksFn(deadlineAt, earlyCompletionAmount);
    }
    if (earlyCompletionUnit === EarlyCompletionUnitEnum.Month) {
      return subMonthsFn(deadlineAt, earlyCompletionAmount);
    }
  } catch (e) {
    console.warn(e);
  }
  return undefined;
}

function getRecurringRuleTypeName(
  recurringRuleType: RecurringRuleRuleTypeEnum,
) {
  switch (recurringRuleType) {
    case RecurringRuleRuleTypeEnum.Daily:
      return 'day';
    case RecurringRuleRuleTypeEnum.Weekly:
      return 'week';
    case RecurringRuleRuleTypeEnum.Monthly:
      return 'month';
    case RecurringRuleRuleTypeEnum.Yearly:
      return 'year';
    default:
      return '';
  }
}

export default function FrequencyFormMessage() {
  const values = useWatch();
  const { t } = useTranslation();
  const recurringRuleEnabled = _get(
    values,
    FieldNames.recurringRuleEnabled,
    false,
  );
  const earlyCompletionEnabled = _get(
    values,
    FieldNames.earlyCompletionEnabled,
    false,
  );
  const endRepeatEnabled = _get(values, FieldNames.endRepeatEnabled, false);
  const recurringRuleType = _get(
    values,
    `${FieldNames.recurringRuleRuleType}.value`,
  );
  const recurringRuleCountdownType = _get(
    values,
    `${FieldNames.recurringRuleCountdownType}.value`,
  );
  const recurringRuleExcludedDayOfWeek = _get(
    values,
    FieldNames.recurringRuleExcludedDayOfWeek,
  );
  const recurringRuleInterval = _get(values, FieldNames.recurringRuleInterval);
  const recurringRuleDayOfWeek = _get(
    values,
    FieldNames.recurringRuleDayOfWeek,
  );
  const repeatMonthlyType = _get(
    values,
    `${FieldNames.repeatMonthlyType}.value`,
  );
  const repeatYearlyType = _get(values, `${FieldNames.repeatYearlyType}.value`);
  const repeatMonthlyEachDayOfMonth = _get(
    values,
    FieldNames.repeatMonthlyEachDayOfMonth,
  ) as Option[];
  const repeatMonthlyRepeatOnOptions = _get(
    values,
    FieldNames.repeatMonthlyRepeatOnOptions,
  );
  const repeatYearlyEachMonthOfYear = _get(
    values,
    FieldNames.repeatYearlyEachMonthOfYear,
  );
  const repeatYearlyEachDayOfMonth = _get(
    values,
    FieldNames.repeatYearlyEachDayOfMonth,
  ) as Option[];
  const repeatYearlyRepeatOnOptions = _get(
    values,
    FieldNames.repeatYearlyRepeatOnOptions,
  );
  const repeatYearlyRepeatOnMonthOfYear = _get(
    values,
    FieldNames.repeatYearlyRepeatOnMonthOfYear,
  );
  const earlyCompletionAmount = _get(
    values,
    FieldNames.earlyCompletionAmount,
    false,
  );
  const earlyCompletionUnit = _get(
    values,
    `${FieldNames.earlyCompletionType}.value`,
    false,
  );
  const endRepeatType = _get(
    values,
    `${FieldNames.endRepeatType}.value`,
    false,
  );
  const deadlineAt = _get(values, FieldNames.deadlineAt);
  const endTime = _get(values, FieldNames.endRepeatDate, false);
  const endsAfterOccurrences = _get(
    values,
    FieldNames.endRepeatAfterOccurrences,
    false,
  );
  const orderDate = earlyCompletionEnabled
    ? getEarlyOrderDate(
        deadlineAt,
        earlyCompletionUnit,
        _toNumber(earlyCompletionAmount),
      )
    : deadlineAt;
  const frequencyRepeatUnit = pluralizeUtil(
    t(getRecurringRuleTypeName(recurringRuleType)).toLowerCase(),
    recurringRuleInterval,
  );
  const recurringRuleExcludedDayOfWeekText =
    recurringRuleExcludedDayOfWeek?.length ? (
      <div>
        <Trans
          i18nKey="frequency-execute"
          values={{
            amount: recurringRuleInterval,
            unit: frequencyRepeatUnit,
          }}
        />
        :{' '}
        {recurringRuleExcludedDayOfWeek
          .map((option: Option[]) =>
            t(
              _get(option, 'data.nikLabelKey') ||
                _get(option, 'data.fullLabelKey') ||
                _get(option, 'labelKey', ''),
            ),
          )
          .join(', ')}
      </div>
    ) : (
      ''
    );
  const text = (
    <>
      {earlyCompletionEnabled ? (
        <div>
          <Trans
            i18nKey={
              recurringRuleEnabled
                ? 'frequency-first-order-date'
                : 'frequency-order-date'
            }
            values={{ date: getFormattedDate(orderDate?.toISOString()) }}
          />
        </div>
      ) : (
        <div>
          <Trans
            i18nKey={
              recurringRuleEnabled
                ? 'frequency-first-order-date'
                : 'frequency-order-date'
            }
            values={{ date: getFormattedDate(deadlineAt) }}
          />
        </div>
      )}
      {deadlineAt && (
        <div>
          <Trans
            i18nKey={
              recurringRuleEnabled
                ? 'frequency-first-deadline-date'
                : 'frequency-deadline-date'
            }
            values={{ date: getFormattedDate(deadlineAt) }}
          />
        </div>
      )}
      {recurringRuleEnabled && (
        <div>
          {(() => {
            const everyPart = (
              <>
                {t('every').toLowerCase()}{' '}
                {+recurringRuleInterval > 1 ? `${recurringRuleInterval} ` : ''}
                {frequencyRepeatUnit}.
              </>
            );
            switch (recurringRuleType) {
              case RecurringRuleRuleTypeEnum.Daily:
                return (
                  <>
                    <div>
                      <Trans
                        i18nKey="frequency-repeat"
                        values={{
                          amount: recurringRuleInterval,
                          unit: frequencyRepeatUnit,
                        }}
                      />
                    </div>
                    <div>
                      <Trans
                        i18nKey={
                          recurringRuleCountdownType ===
                          RecurringRuleCountdownTypeEnum.Completion
                            ? 'frequency-repeat-countdown-completion-point'
                            : 'frequency-repeat-countdown-deadline-point'
                        }
                      />
                    </div>
                  </>
                );
              case RecurringRuleRuleTypeEnum.Weekly:
                if (recurringRuleDayOfWeek?.length) {
                  return (
                    <>
                      <Trans
                        i18nKey="frequency-repeat"
                        values={{
                          amount: recurringRuleInterval,
                          unit: frequencyRepeatUnit,
                        }}
                      />{' '}
                      <Trans i18nKey="frequency-repeat-on" />{' '}
                      {recurringRuleDayOfWeek
                        .map((option: Option[]) =>
                          t(_get(option, 'data.nikLabelKey', '')),
                        )
                        .join(', ')}
                    </>
                  );
                }
                return (
                  <>
                    <Trans
                      i18nKey="frequency-repeat"
                      values={{
                        amount: recurringRuleInterval,
                        unit: frequencyRepeatUnit,
                      }}
                    />{' '}
                    <Trans i18nKey="frequency-repeat-after-completion" />
                  </>
                );
              case RecurringRuleRuleTypeEnum.Monthly:
                if (
                  repeatMonthlyType === RecurringRuleSchemaOptionEnum.Each &&
                  repeatMonthlyEachDayOfMonth?.length
                ) {
                  return (
                    <>
                      <Trans
                        i18nKey="frequency-repeat"
                        values={{
                          amount: repeatMonthlyEachDayOfMonth
                            .map(({ value }: Option) =>
                              numberToOrdinal(Number(value)),
                            )
                            .join(', '),
                          unit: t('day').toLowerCase(),
                        }}
                      />{' '}
                      {everyPart}{' '}
                      {repeatMonthlyEachDayOfMonth.some(
                        ({ value }) => value && +value >= 29,
                      )
                        ? t('last-month-days-warning-msg')
                        : null}
                    </>
                  );
                }
                if (
                  repeatMonthlyType === RecurringRuleSchemaOptionEnum.OnThe &&
                  !!repeatMonthlyRepeatOnOptions.length
                ) {
                  return (
                    <>
                      {t('frequency-repeat-every')}{' '}
                      {repeatMonthlyRepeatOnOptions.map(
                        (option: unknown, index: number) => (
                          <>
                            {index > 0 && `${t('and')} `}
                            {_get(option, [
                              FieldNames.repeatMonthlyRepeatOnOption1,
                              'label',
                            ])}{' '}
                            {_get(option, [
                              FieldNames.repeatMonthlyRepeatOnOption2,
                              'label',
                            ])}{' '}
                          </>
                        ),
                      )}{' '}
                      {everyPart}
                    </>
                  );
                }
                return (
                  <>
                    <Trans
                      i18nKey="frequency-repeat"
                      values={{
                        amount: recurringRuleInterval,
                        unit: frequencyRepeatUnit,
                      }}
                    />{' '}
                    <Trans i18nKey="frequency-repeat-after-completion" />
                  </>
                );
              case RecurringRuleRuleTypeEnum.Yearly:
                if (
                  repeatYearlyType === RecurringRuleSchemaOptionEnum.Each &&
                  repeatYearlyEachDayOfMonth?.length &&
                  repeatYearlyEachMonthOfYear?.length
                ) {
                  return (
                    <>
                      {t('frequency-repeat-every')}{' '}
                      {repeatYearlyEachDayOfMonth
                        .map(({ value }: Option) =>
                          numberToOrdinal(Number(value)),
                        )
                        .join(', ')}{' '}
                      {t('of')}{' '}
                      {repeatYearlyEachMonthOfYear.map(
                        ({ labelKey, label }: Option, index: number) => (
                          <>
                            {index > 0 && `${t(', ')} `}
                            {labelKey ? t(labelKey) : label}
                          </>
                        ),
                      )}{' '}
                      {everyPart}{' '}
                      {repeatYearlyEachDayOfMonth.some(
                        ({ value }) => value && +value >= 29,
                      )
                        ? t('last-month-days-warning-msg')
                        : null}
                    </>
                  );
                }
                if (
                  repeatYearlyType === RecurringRuleSchemaOptionEnum.OnThe &&
                  repeatYearlyRepeatOnOptions?.length &&
                  repeatYearlyRepeatOnMonthOfYear?.length
                ) {
                  return (
                    <>
                      {t('frequency-repeat-every')}{' '}
                      {repeatYearlyRepeatOnOptions.map(
                        (option: unknown, index: number) => (
                          <>
                            {index > 0 && `${t('and')} `}
                            {_get(option, [
                              FieldNames.repeatYearlyRepeatOnOption1,
                              'label',
                            ])}{' '}
                            {_get(option, [
                              FieldNames.repeatYearlyRepeatOnOption2,
                              'label',
                            ])}{' '}
                          </>
                        ),
                      )}{' '}
                      {t('of')}{' '}
                      {repeatYearlyRepeatOnMonthOfYear.map(
                        ({ labelKey, label }: Option, index: number) => (
                          <>
                            {index > 0 && `${t(', ')} `}
                            {labelKey ? t(labelKey) : label}
                          </>
                        ),
                      )}{' '}
                      {everyPart}
                    </>
                  );
                }
                return (
                  <>
                    <Trans
                      i18nKey="frequency-repeat"
                      values={{
                        amount: recurringRuleInterval,
                        unit: frequencyRepeatUnit,
                      }}
                    />{' '}
                    <Trans i18nKey="frequency-repeat-after-completion" />
                  </>
                );
              default:
                return null;
            }
          })()}
        </div>
      )}
      {endRepeatEnabled && endRepeatType === EndRepeatUnit.date && endTime && (
        <div>
          <Trans
            i18nKey="frequency-end-date"
            values={{
              date: getFormattedDate(endTime),
            }}
          />
        </div>
      )}
      {endRepeatEnabled && endRepeatType === EndRepeatUnit.completions && (
        <div>
          <Trans
            i18nKey="frequency-end-date-completions"
            values={{
              amount: endsAfterOccurrences,
            }}
          />
        </div>
      )}
      {recurringRuleExcludedDayOfWeekText}
    </>
  );
  if (!orderDate && !deadlineAt && !recurringRuleEnabled) return <div />;
  return <SupportiveMessage text={text} />;
}
