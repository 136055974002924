import React from 'react';
import { TFunction } from 'i18next';
import { Nomenclature } from 'graphql-common';
import TextCell from '@lib/components/Table/commonComponents/TextCell/TextCell';
import { CellProps } from '@lib/components/Table/types';
import AssetPreviewCell from '@lib/components/Table/commonComponents/AssetPreviewCell/AssetPreviewCell';
import { APP_URLS } from 'constants/urls';
import Typography from '@lib/components/Typography/Typography';
import Tooltip from '@lib/components/Tooltip/Tooltip';
import IconButton, {
  IconButtonTypes,
} from '@lib/components/IconButton/IconButton';

type ColumnsArgs = {
  getQrCode: (v: Nomenclature) => void;
  t: TFunction<'translation', undefined>;
};

export default function getTableColumns({ getQrCode, t }: ColumnsArgs) {
  return [
    {
      id: 'componentAssetCode',
      Header: t('code'),
      accessor: 'componentAsset.code',
      Cell: (props: CellProps<Nomenclature>) => <TextCell {...props} />,
      minWidth: 150,
      maxWidth: 150,
      width: 150,
      sortable: true,
    },
    {
      id: 'componentAssetName',
      Header: t('name'),
      accessor: 'name',
      Cell: ({ item }: CellProps<Nomenclature>) =>
        item.componentAsset ? (
          <AssetPreviewCell
            icon={
              item.componentAsset.iconAttached?.urls?.small ||
              item.componentAsset.iconAttached?.urls?.original ||
              item.componentAsset.iconAttached?.fileTag
            }
            name={item.componentAsset.name}
            url={APP_URLS.dashboard.assets.view.getDynamicPath({
              pathParts: {
                id: `${item.componentAsset.id}`,
              },
            })}
            usesAsNomenclatureCount={
              item.componentAsset.usesAsNomenclatureCount
            }
          />
        ) : (
          <Typography variant="body">-</Typography>
        ),
      minWidth: 350,
      maxWidth: 350,
      width: 350,
      sortable: true,
    },
    {
      id: 'quantity',
      Header: t('quantity'),
      accessor: 'quantity',
      Cell: (props: CellProps<Nomenclature>) => <TextCell {...props} />,
      minWidth: 110,
      width: 110,
      sortable: true,
    },
    {
      id: 'componentAssetCode',
      Header: t('qr-code'),
      accessor: 'componentAsset.fullCode',
      Cell: (props: CellProps<Nomenclature>) => <TextCell {...props} />,
    },
    {
      id: 'qrCode',
      Header: '',
      accessor: 'qrCode',
      Cell: ({ item }: CellProps<Nomenclature>) => (
        <div>
          <Tooltip body={t('download-qr-code')}>
            <IconButton
              type={IconButtonTypes.PrimaryFilled}
              onClick={() => getQrCode(item)}
              icon="download"
              symbolsOutlined
            />
          </Tooltip>
        </div>
      ),
      width: 1,
    },
  ];
}
