import React, { memo, useCallback, useRef, useState } from 'react';
import classNames from 'classnames';
import Pagination from '@lib/components/Pagination/Pagination';
import TableEmptyState from '@lib/components/Table/components/TableEmptyState/TableEmptyState';
import CircleLoader from '@lib/components/CircleLoader/CircleLoader';
import Typography from '@lib/components/Typography/Typography';
import pluralizeUtil from '@lib/utils/pluralize';
import { UseBulkSelectResult } from '@lib/hooks/useTableItemsBulkSelect';
import {
  checkHasActionsColumn,
  checkHasActionsMenuColumn,
  checkHasSelectColumn,
} from '@lib/components/Table/utils';
import useEventListener from '@lib/hooks/useEventListener';
import TableHead from './components/TableHead/TableHead';
import TableBody from './components/TableBody/TableBody';
import TableFooter from './components/TableFooter/TableFooter';
import { BaseItem, TableProps } from './types';
import styles from './Table.module.scss';

function Table<T extends BaseItem>(props: TableProps<T>) {
  const {
    actionsForSelected,
    className,
    bordered = true,
    components,
    counterText,
    data,
    fullHeight,
    id,
    loading,
    paginationState,
    rounded = true,
    tableContainerClassName,
    totalEntries = 0,
    useBulkSelectResult = {} as UseBulkSelectResult<T>,
  } = props;
  const {
    unselectedTableItems = [],
    selectedTableItems = [],
    isAllTableItemsSelected = false,
  } = useBulkSelectResult;
  const componentRef = useRef<HTMLDivElement>(null);
  const [componentHeight, setComponentHeight] = useState<number | null>(null);

  const isTableVisible = !!totalEntries && !!data.length;
  const isEmptyStateVisible = !isTableVisible;
  const isPaginationVisible = isTableVisible && totalEntries > data.length;
  const isCounterVisible = !!totalEntries && !!counterText;
  const { TopHeadComponent } = components || {};
  const isTopHeadComponentVisible =
    isTableVisible && TopHeadComponent !== undefined;

  const hasActionsColumn = checkHasActionsColumn(props);
  const hasActionsMenuColumn = checkHasActionsMenuColumn(props);
  const hasSelectColumn = checkHasSelectColumn(props);

  const isSelectAllCheckboxIndeterminate =
    hasSelectColumn &&
    ((isAllTableItemsSelected &&
      unselectedTableItems.length !== 0 &&
      unselectedTableItems.length < totalEntries) ||
      (selectedTableItems.length > 0 &&
        selectedTableItems.length < totalEntries));
  const isSelectAllCheckboxChecked =
    (isAllTableItemsSelected && unselectedTableItems.length < totalEntries) ||
    selectedTableItems.length > 0;

  const updateComponentHeight = useCallback(() => {
    if (fullHeight && componentRef.current) {
      const windowHeight = window.innerHeight;

      // Use getBoundingClientRect to get the position relative to the viewport
      const rect = componentRef.current.getBoundingClientRect();
      const componentOffsetTop = rect.top + window.scrollY; // Absolute position from the top of the page

      const { parentElement } = componentRef.current;
      const parentStyle = parentElement
        ? window.getComputedStyle(parentElement)
        : { paddingTop: '0', paddingBottom: '0' };
      const paddingTop = parseInt(parentStyle.paddingTop, 10) || 0;
      const paddingBottom = parseInt(parentStyle.paddingBottom, 10) || 0;

      const newComponentHeight =
        windowHeight - componentOffsetTop - paddingTop - paddingBottom;
      setComponentHeight(newComponentHeight);
    }
  }, [fullHeight]);

  useEventListener('resize', updateComponentHeight);

  return (
    <div
      className={classNames(
        styles.root,
        {
          [styles.rootWithCounter]: isCounterVisible,
          [styles.rootWithScroll]: !!componentHeight,
        },
        className,
      )}
      data-test-id={id ? `table__${id}` : undefined}
      ref={componentRef}
      style={{ maxHeight: componentHeight ? `${componentHeight}px` : 'auto' }}
    >
      {loading && (
        <span className={styles.tableLoader}>
          <CircleLoader size={48} width={4} />
        </span>
      )}
      {isCounterVisible && (
        <Typography variant="caption" className={styles.tableCounter}>
          {totalEntries} {pluralizeUtil(counterText, totalEntries)}
        </Typography>
      )}
      {isTopHeadComponentVisible && (
        <div className={styles.topHead}>
          <TopHeadComponent />
        </div>
      )}
      {isEmptyStateVisible ? (
        <TableEmptyState {...props} />
      ) : (
        <>
          {!!actionsForSelected?.length && isSelectAllCheckboxChecked && (
            <div
              className={classNames(styles.actionsRow, {
                [styles.actionsRowWhenCounter]: isCounterVisible,
              })}
            >
              {actionsForSelected.map((item) => item)}
            </div>
          )}
          <div
            className={classNames(
              styles.tableContainer,
              {
                [styles.tableContainerWithTopHead]: isTopHeadComponentVisible,
                [styles.tableContainerWithPagination]: isPaginationVisible,
                [styles.tableContainerWithLoading]: loading,
                [styles.tableContainerWithRounded]: rounded,
                [styles.tableContainerWithBordered]: bordered,
              },
              tableContainerClassName,
            )}
          >
            <table className={styles.table}>
              <TableHead
                {...props}
                hasActionsColumn={hasActionsColumn}
                hasActionsMenuColumn={hasActionsMenuColumn}
                hasSelectColumn={hasSelectColumn}
                isSelectAllCheckboxChecked={isSelectAllCheckboxChecked}
                isSelectAllCheckboxIndeterminate={
                  isSelectAllCheckboxIndeterminate
                }
              />
              <TableBody {...props} />
              <TableFooter {...props} />
            </table>
          </div>
        </>
      )}
      {isPaginationVisible && (
        <div
          className={classNames(styles.pagination, {
            [styles.paginationWithRounded]: rounded,
            [styles.paginationWithBordered]: bordered,
          })}
        >
          <Pagination {...props} paginationState={paginationState} />
        </div>
      )}
    </div>
  );
}

export default memo(Table) as <T extends BaseItem>(
  props: TableProps<T>,
) => JSX.Element;
