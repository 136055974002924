enum UserFieldNames {
  Accesses = 'accesses',
  Assignations = 'assignations',
  Email = 'email',
  FirstName = 'firstName',
  GenerateNewPassword = 'generateNewPassword',
  Lang = 'lang',
  LastName = 'lastName',
  Password = 'password',
  PersonalId = 'personalId',
  PhoneNumber = 'phoneNumber',
  Role = 'role',
  SiteMemberships = 'siteMemberships',
  Team = 'team',
  TeamAccesses = 'team-accesses',
  UnconfirmedEmail = 'unconfirmedEmail',
  Username = 'systemName',
}

export default UserFieldNames;
