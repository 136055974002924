import { TFunction } from 'i18next';
import { MeasureTypeEnum, MeasureUnitEnum } from 'graphql-common';

export function getMeasureTypeOptions() {
  return [
    {
      labelKey: 'measure-type-distance',
      value: MeasureTypeEnum.Distance,
    },
    {
      labelKey: 'measure-type-energy',
      value: MeasureTypeEnum.Energy,
    },
    {
      labelKey: 'measure-type-mass',
      value: MeasureTypeEnum.Mass,
    },
    {
      labelKey: 'measure-type-quantity',
      value: MeasureTypeEnum.Quantity,
    },
    {
      labelKey: 'measure-type-time',
      value: MeasureTypeEnum.Time,
    },
  ];
}

export function getMeasureDistanceUnitOptions() {
  return [
    {
      labelKey: 'measure-unit-centimeter',
      value: MeasureUnitEnum.Centimeter,
    },
    {
      labelKey: 'measure-unit-meter',
      value: MeasureUnitEnum.Meter,
    },
    {
      labelKey: 'measure-unit-kilometer',
      value: MeasureUnitEnum.Kilometer,
    },
    {
      labelKey: 'measure-unit-foot',
      value: MeasureUnitEnum.Foot,
    },
    {
      labelKey: 'measure-unit-yard',
      value: MeasureUnitEnum.Yard,
    },
    {
      labelKey: 'measure-unit-mile',
      value: MeasureUnitEnum.Mile,
    },
  ];
}

export function getMeasureEnergyUnitOptions() {
  return [
    {
      labelKey: 'measure-unit-kilowatt-hour',
      value: MeasureUnitEnum.KilowattHour,
    },
  ];
}

export function getMeasureMassUnitOptions() {
  return [
    {
      labelKey: 'measure-unit-gram',
      value: MeasureUnitEnum.Gram,
    },
    {
      labelKey: 'measure-unit-kilogram',
      value: MeasureUnitEnum.Kilogram,
    },
    {
      labelKey: 'measure-unit-ton',
      value: MeasureUnitEnum.Ton,
    },
  ];
}

export function getMeasureQuantityUnitOptions() {
  return [
    {
      labelKey: 'measure-unit-piece',
      value: MeasureUnitEnum.Piece,
    },
  ];
}

export function getMeasureTimeUnitOptions() {
  return [
    {
      labelKey: 'measure-unit-second',
      value: MeasureUnitEnum.Second,
    },
    {
      labelKey: 'measure-unit-minute',
      value: MeasureUnitEnum.Minute,
    },
    {
      labelKey: 'measure-unit-hour',
      value: MeasureUnitEnum.Hour,
    },
  ];
}

export function getMeasureUnitOptionsByType(type: MeasureTypeEnum | undefined) {
  if (type === MeasureTypeEnum.Distance) {
    return getMeasureDistanceUnitOptions();
  }
  if (type === MeasureTypeEnum.Energy) {
    return getMeasureEnergyUnitOptions();
  }
  if (type === MeasureTypeEnum.Mass) {
    return getMeasureMassUnitOptions();
  }
  if (type === MeasureTypeEnum.Quantity) {
    return getMeasureQuantityUnitOptions();
  }
  if (type === MeasureTypeEnum.Time) {
    return getMeasureTimeUnitOptions();
  }
  return [];
}

export function getMeasureUnitOption(
  type: MeasureTypeEnum,
  unit: MeasureUnitEnum,
) {
  const options = getMeasureUnitOptionsByType(type);
  return options.find((o) => o.value === unit);
}

/**
 * Extracts an abbreviation from a string.
 *
 * @param input - The input string containing an abbreviation in parentheses.
 * @param removeParentheses - Optional parameter. If set to `true`, returns the abbreviation without parentheses.
 *                            Defaults to `false`, returns with parentheses.
 * @returns Extracted abbreviation either with or without parentheses. If no parentheses are found, returns an empty string.
 */
export function extractAbbreviation(
  input: string,
  removeParentheses = false,
): string {
  // Updated regular expression to handle nested and empty parentheses
  const regex = /\(+([^)]*)\)+/;
  const match = input.match(regex);
  if (!match) {
    return '';
  }
  return removeParentheses ? match[1] : match[0];
}

export function getMeasurementUnitLabel(
  type: MeasureTypeEnum,
  unit: MeasureUnitEnum,
  t: TFunction<'translation', undefined>,
) {
  const unitLabelKey = getMeasureUnitOptionsByType(type).find(
    ({ value }) => value === unit,
  )?.labelKey;
  if (!unitLabelKey) return '';
  return extractAbbreviation(t(unitLabelKey));
}

export function getMeasurementLabel(
  type: MeasureTypeEnum,
  unit: MeasureUnitEnum,
  t: TFunction<'translation', undefined>,
) {
  const typeLabelKey = getMeasureTypeOptions().find(
    ({ value }) => value === type,
  )?.labelKey;
  const unitLabelKey = getMeasureUnitOptionsByType(type).find(
    ({ value }) => value === unit,
  )?.labelKey;
  if (!typeLabelKey || !unitLabelKey) return '';
  return `${t(typeLabelKey)} ${extractAbbreviation(t(unitLabelKey))}`;
}

export function getMeasurementAbbreviation(
  type: MeasureTypeEnum | undefined,
  unit: MeasureUnitEnum | undefined,
  t: TFunction<'translation', undefined>,
  removeParentheses?: boolean,
) {
  const unitLabelKey = getMeasureUnitOptionsByType(type).find(
    ({ value }) => value === unit,
  )?.labelKey;
  if (!unitLabelKey) return null;
  return extractAbbreviation(t(unitLabelKey), removeParentheses);
}
