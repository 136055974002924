import {
  EarlyCompletionUnitEnum,
  RecurringRuleCountdownTypeEnum,
  RecurringRuleRuleTypeEnum,
  RecurringRuleSchemaOptionEnum,
} from 'graphql-common';
import { EndRepeatUnit } from 'routes/Task/enums';

export const earlyCompletionUnitOptions = [
  { value: EarlyCompletionUnitEnum.Day, labelKey: 'days-before' },
  { value: EarlyCompletionUnitEnum.Week, labelKey: 'weeks-before' },
  { value: EarlyCompletionUnitEnum.Month, labelKey: 'months-before' },
];
export const ruleTypeOptions = [
  { value: RecurringRuleRuleTypeEnum.Daily, labelKey: 'repeat-daily' },
  { value: RecurringRuleRuleTypeEnum.Weekly, labelKey: 'repeat-weekly' },
  { value: RecurringRuleRuleTypeEnum.Monthly, labelKey: 'repeat-monthly' },
  { value: RecurringRuleRuleTypeEnum.Yearly, labelKey: 'repeat-yearly' },
];
export const endRepeatOptions = [
  { value: EndRepeatUnit.date, labelKey: 'on-date' },
  { value: EndRepeatUnit.completions, labelKey: 'after-completions' },
];
export const repeatDayOptions = [
  {
    value: 1,
    labelKey: 'day-short-monday',
    data: { fullLabelKey: 'days-monday', nikLabelKey: 'day-nic-monday' },
  },
  {
    value: 2,
    labelKey: 'day-short-tuesday',
    data: { fullLabelKey: 'days-tuesday', nikLabelKey: 'day-nic-tuesday' },
  },
  {
    value: 3,
    labelKey: 'day-short-wednesday',
    data: { fullLabelKey: 'days-wednesday', nikLabelKey: 'day-nic-wednesday' },
  },
  {
    value: 4,
    labelKey: 'day-short-thursday',
    data: { fullLabelKey: 'days-thursday', nikLabelKey: 'day-nic-thursday' },
  },
  {
    value: 5,
    labelKey: 'day-short-friday',
    data: { fullLabelKey: 'days-friday', nikLabelKey: 'day-nic-friday' },
  },
  {
    value: 6,
    labelKey: 'day-short-saturday',
    data: { fullLabelKey: 'days-saturday', nikLabelKey: 'day-nic-saturday' },
  },
  {
    value: 0,
    labelKey: 'day-short-sunday',
    data: { fullLabelKey: 'days-sunday', nikLabelKey: 'day-nic-sunday' },
  },
];
export const repeatMonthDaysOptions = Array.from(
  { length: 31 },
  (_, i) => i + 1,
).map((value) => ({
  value,
  label: value,
}));
export const repeatMonthlyUnitOptions = [
  { value: RecurringRuleSchemaOptionEnum.Each, labelKey: 'each' },
  { value: RecurringRuleSchemaOptionEnum.OnThe, labelKey: 'on-the' },
];
export const recurringRuleDayOfMonthOptions = [
  { labelKey: 'order-option-first', value: 1 },
  { labelKey: 'order-option-second', value: 2 },
  { labelKey: 'order-option-third', value: 3 },
  { labelKey: 'order-option-fourth', value: 4 },
  { labelKey: 'order-option-last', value: -1 },
];
export const recurringRuleDayOfMonthOptionDayOfWeekOptions = [
  { labelKey: 'day-monday', value: 1 },
  { labelKey: 'day-tuesday', value: 2 },
  { labelKey: 'day-wednesday', value: 3 },
  { labelKey: 'day-thursday', value: 4 },
  { labelKey: 'day-friday', value: 5 },
  { labelKey: 'day-saturday', value: 6 },
  { labelKey: 'day-sunday', value: 0 },
  { labelKey: 'day', value: -1 },
];
export const monthOptions = [
  { labelKey: 'month-jan', value: 1 },
  { labelKey: 'month-fed', value: 2 },
  { labelKey: 'month-mar', value: 3 },
  { labelKey: 'month-apr', value: 4 },
  { labelKey: 'month-may', value: 5 },
  { labelKey: 'month-jun', value: 6 },
  { labelKey: 'month-jul', value: 7 },
  { labelKey: 'month-aug', value: 8 },
  { labelKey: 'month-sep', value: 9 },
  { labelKey: 'month-oct', value: 10 },
  { labelKey: 'month-nov', value: 11 },
  { labelKey: 'month-dec', value: 12 },
];
export const repeatYearlyUnitOptions = [
  { value: RecurringRuleSchemaOptionEnum.Each, labelKey: 'each' },
  { value: RecurringRuleSchemaOptionEnum.OnThe, labelKey: 'on-the' },
];
export const countdownTypeOptions = [
  { value: RecurringRuleCountdownTypeEnum.Deadline, labelKey: 'deadline' },
  { value: RecurringRuleCountdownTypeEnum.Completion, labelKey: 'completion' },
];
