import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { ApolloError } from '@apollo/client/errors';
import _get from 'lodash/get';
import PageBody from '@lib/layouts/DashboardLayout/PageBody/PageBody';
import { Values } from '@lib/interfaces/form';
import DashboardContentMaxWidth from '@lib/layouts/DashboardLayout/DashboardContentMaxWidth/DashboardContentMaxWidth';
import ErrorPage from '@lib/components/ErrorPage/ErrorPage';
import getStatusFromError from '@lib/utils/getStatusFromError';
import FormTemplateBuilderMainBlockView from 'components/FormTemplateBuilder/FormTemplateBuilderView/FormTemplateBuilderMainBlockView';
import FormTemplateBuilderQuestionsView from 'components/FormTemplateBuilder/FormTemplateBuilderView/FormTemplateBuilderQuestionsView';
import FormTemplateBuilderViewMenu from 'components/FormTemplateBuilder/FormTemplateBuilderView/FormTemplateBuilderViewMenu';
import getVersionActions from 'components/FormTemplateBuilder/FormTemplateBuilderView/utils/getVersionActions';
import CircleLoaderWrapper from '@lib/components/CircleLoaderWrapper/CircleLoaderWrapper';
import { SelectOption } from '@lib/components/Select/types';
import getTemplateActions from './utils/getTemplateActions';

interface Props {
  formTemplateData: Values;
  formTemplateError?: ApolloError;
  formTemplateId: string;
  formTemplateLoading: boolean;
  formTemplateVersionData: Values;
  loadingState: boolean;
  onCreateNewFormTemplateVersion: () => void;
  onCreateTaskHandler: () => void;
  onDuplicateHandler: () => void;
  onDuplicateVersionHandler: () => void;
  onEditHandler: () => void;
  onEditVersionHandler: () => void;
  onFormTemplateArchiveHandler: () => void;
  onFormTemplateBulkRestoreHandler: () => void;
  onFormTemplateVersionArchiveHandler: () => void;
  onFormTemplateVersionRestoreHandler: () => void;
  onViewFormTemplateVersion: (versionId: string) => void;
  openDestroyModal: () => void;
  openVersionDestroyModal: () => void;
  selectedVersionOption: SelectOption;
  isViewOnly: boolean;
}

function FormTemplateBuilderView(props: Props) {
  const {
    formTemplateData,
    formTemplateError,
    formTemplateId,
    formTemplateLoading,
    formTemplateVersionData,
    loadingState,
    onCreateNewFormTemplateVersion,
    onCreateTaskHandler,
    onDuplicateHandler,
    onDuplicateVersionHandler,
    onEditHandler,
    onEditVersionHandler,
    onFormTemplateArchiveHandler,
    onFormTemplateVersionArchiveHandler,
    onFormTemplateVersionRestoreHandler,
    onViewFormTemplateVersion,
    openDestroyModal,
    onFormTemplateBulkRestoreHandler,
    openVersionDestroyModal,
    selectedVersionOption,
    isViewOnly,
  } = props;

  const { t } = useTranslation();

  const actions = getTemplateActions({
    onDuplicateHandler,
    onEditHandler: _get(formTemplateData, 'canUpdate', false)
      ? onEditHandler
      : undefined,
    openDestroyModal: _get(formTemplateData, 'canDestroy', false)
      ? openDestroyModal
      : undefined,
    onArchiveHandler: _get(formTemplateData, 'canArchive', false)
      ? onFormTemplateArchiveHandler
      : undefined,
    t,
  });

  const versionActions = getVersionActions({
    onAddHandler: _get(formTemplateVersionData, 'canRestore', false)
      ? onCreateNewFormTemplateVersion
      : undefined,
    onEditHandler: _get(formTemplateVersionData, 'canUpdate', false)
      ? onEditVersionHandler
      : undefined,
    onDuplicateHandler: onDuplicateVersionHandler,
    onArchiveHandler: _get(formTemplateVersionData, 'canArchive', false)
      ? onFormTemplateVersionArchiveHandler
      : undefined,
    openDestroyModal: _get(formTemplateVersionData, 'canDestroy', false)
      ? openVersionDestroyModal
      : undefined,
    t,
  });

  if (formTemplateError) {
    return (
      <ErrorPage
        status={getStatusFromError(formTemplateError)}
        description={formTemplateError?.message}
      />
    );
  }

  return (
    <PageBody withoutHeader>
      <FormTemplateBuilderViewMenu formTemplateData={formTemplateData} />
      <DashboardContentMaxWidth>
        <CircleLoaderWrapper isLoading={formTemplateLoading || loadingState}>
          <FormTemplateBuilderMainBlockView
            onFormTemplateBulkRestoreHandler={onFormTemplateBulkRestoreHandler}
            actions={actions}
            formTemplateData={formTemplateData}
            formTemplateId={formTemplateId}
            formTemplateVersionData={formTemplateVersionData}
            loadingState={loadingState}
            onDuplicateHandler={onDuplicateHandler}
            onCreateNewFormTemplateVersion={onCreateNewFormTemplateVersion}
            onCreateTaskHandler={onCreateTaskHandler}
            onFormTemplateVersionRestoreHandler={
              onFormTemplateVersionRestoreHandler
            }
            onViewFormTemplateVersion={onViewFormTemplateVersion}
            selectedVersionOption={selectedVersionOption}
            showVersionInfo
            versionActions={versionActions}
            isViewOnly={isViewOnly}
          />
          <FormTemplateBuilderQuestionsView
            formTemplateVersionData={formTemplateVersionData}
          />
        </CircleLoaderWrapper>
      </DashboardContentMaxWidth>
    </PageBody>
  );
}

export default memo(FormTemplateBuilderView);
