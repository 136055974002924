import React, { ForwardedRef, forwardRef } from 'react';
import classNames from 'classnames';
import styles from './WhiteBox.module.scss';

interface Props {
  children: React.ReactNode;
  className?: string;
  verticalPaddings?: 'large' | 'middle' | 'small' | 'custom';
  horizontalPaddings?: 'large' | 'middle' | 'small' | 'custom';
}

const WhiteBox = forwardRef(
  (props: Props, ref: ForwardedRef<HTMLDivElement>) => {
    const {
      children,
      className,
      verticalPaddings = 'middle',
      horizontalPaddings = 'middle',
    } = props;
    return (
      <div
        className={classNames(
          styles.wrap,
          {
            [styles.wrapWithLargeVerticalPaddings]:
              verticalPaddings === 'large',
            [styles.wrapWithMiddleVerticalPaddings]:
              verticalPaddings === 'middle',
            [styles.wrapWithSmallVerticalPaddings]:
              verticalPaddings === 'small',
            [styles.wrapWithLargeHorizontalPaddings]:
              horizontalPaddings === 'large',
            [styles.wrapWithMiddleHorizontalPaddings]:
              horizontalPaddings === 'middle',
            [styles.wrapWithSmallHorizontalPaddings]:
              horizontalPaddings === 'small',
          },
          className,
        )}
        ref={ref}
      >
        {children}
      </div>
    );
  },
);

export default WhiteBox;
