import { TFunction } from 'i18next';
import { SiteArea } from 'graphql-common';
import { NavItem, NavItemTypeEnum } from '@lib/components/Menu/Menu';
import Icons from '@lib/components/Icons/Icons';

type ActionsMenuArgs = {
  onViewHandler: (area?: SiteArea) => void;
  onEditHandler: (area?: SiteArea) => void;
  onDelete: (area?: SiteArea) => void;
  t: TFunction<'translation', undefined>;
  isViewOnly: boolean;
};

export default function getSiteAreasActionsMenu({
  onViewHandler,
  onEditHandler,
  onDelete,
  t,
  isViewOnly,
}: ActionsMenuArgs): NavItem<SiteArea>[] {
  return [
    {
      id: 'view-action',
      title: t('view'),
      onClick: (item?: SiteArea) => onViewHandler(item),
      leftIcon: Icons.view,
      type: NavItemTypeEnum.Secondary,
    },
    {
      id: 'edit-action',
      title: t('edit'),
      onClick: (item?: SiteArea) => onEditHandler(item),
      leftIcon: Icons.edit,
      type: NavItemTypeEnum.Secondary,
      predictorFnVisible: () => !isViewOnly,
    },
    {
      id: 'delete-action',
      title: t('delete'),
      onClick: (item?: SiteArea) => onDelete(item),
      leftIcon: Icons.delete,
      type: NavItemTypeEnum.Secondary,
      predictorFnVisible: () => !isViewOnly,
    },
  ];
}
