import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { FormTemplateQuestionFieldsKindEnum } from 'graphql-common';
import Typography from '@lib/components/Typography/Typography';
import MaterialIcon from '@lib/components/MaterialIcon/MaterialIcon';
import Tooltip from '@lib/components/Tooltip/Tooltip';
import {
  AppendValue,
  FieldArrayItemRenderAddMore,
  FieldArrayItemRenderEmptyState,
  FieldArrayItemType,
} from '@lib/components/ReactHookForm/types';
import styles from '../QuestionField/QuestionField.module.scss';

interface ChildrenProps {
  fieldItem: FieldArrayItemType;
  index: number;
}

interface Props {
  appendValue?: AppendValue;
  children: (props: ChildrenProps) => React.ReactNode;
  fields: FieldArrayItemType[];
  kind: FormTemplateQuestionFieldsKindEnum;
  renderAddMore: FieldArrayItemRenderAddMore;
  renderSettings?: React.ReactNode;
  renderEmptyState: FieldArrayItemRenderEmptyState;
}

function QuestionGeneralField(props: Props) {
  const {
    appendValue,
    children,
    fields,
    kind,
    renderAddMore,
    renderSettings,
    renderEmptyState,
  } = props;
  const { t } = useTranslation();

  return (
    <>
      {kind === FormTemplateQuestionFieldsKindEnum.Checkbox && (
        <Typography variant="body" className={styles.title}>
          {t('multi-select-options')}
        </Typography>
      )}
      {kind === FormTemplateQuestionFieldsKindEnum.RadioButton && (
        <Typography variant="body" className={styles.title}>
          {t('single-select-options')}
        </Typography>
      )}
      {kind === FormTemplateQuestionFieldsKindEnum.SingleSelect && (
        <Typography variant="body" className={styles.title}>
          {t('dropdown-options')}
        </Typography>
      )}
      {kind === FormTemplateQuestionFieldsKindEnum.YesNo && (
        <Typography variant="body" className={styles.title}>
          {t('options')}
        </Typography>
      )}
      {kind === FormTemplateQuestionFieldsKindEnum.NumberInput && (
        <div className={styles.titleWrap}>
          <Typography variant="body">{t('response-validation')}</Typography>
          <Tooltip body={t('response-validation-tooltip')} maxWidth={160}>
            <MaterialIcon
              icon="info"
              symbolsOutlined
              className={styles.infoIcon}
            />
          </Tooltip>
        </div>
      )}
      {renderEmptyState(appendValue)}
      {fields.map((fieldItem, index) => children({ fieldItem, index }))}
      {renderAddMore(appendValue)}
      {renderSettings}
    </>
  );
}

export default memo(QuestionGeneralField);
