import React, { memo } from 'react';
import _noop from 'lodash/noop';
import { BaseItem } from '@lib/components/Table/types';
import { NavItem } from '@lib/components/Menu/Menu';
import ActionsMenu from '@lib/components/ActionsMenu/ActionsMenu';
import styles from './RowActionsMenuCell.module.scss';

interface RowActionsMenuCellProps<T extends BaseItem> {
  item: T;
  actionsMenu?: NavItem<T>[];
}

const getActionCellWidth = (actionsCount: number) => {
  const containerPadding = 36;
  const iconWidth = 32;
  const iconMargin = 4;
  return (
    containerPadding + iconWidth * actionsCount + iconMargin * actionsCount - 1
  );
};

function RowActionsMenuCell<T extends BaseItem>({
  actionsMenu = [],
  item,
}: RowActionsMenuCellProps<T>) {
  const preparedActions = actionsMenu.reduce((acc, action) => {
    const {
      onClick = _noop,
      predictorFnDisable,
      predictorFnVisible,
      predictorFnTo,
      ...restAction
    } = action;

    acc.push({
      ...restAction,
      predictorFnDisable,
      predictorFnVisible: predictorFnVisible
        ? () => predictorFnVisible(item)
        : undefined,
      predictorFnTo: predictorFnTo ? () => predictorFnTo(item) : undefined,
      onClick: () => onClick(item),
      disabled: predictorFnDisable ? predictorFnDisable(item) : undefined,
    });

    return acc;
  }, [] as NavItem<T>[]);
  return (
    <td
      className={styles.cell}
      style={{
        width: getActionCellWidth(preparedActions?.length ?? 0),
      }}
    >
      <div>
        <ActionsMenu actions={preparedActions} />
      </div>
    </td>
  );
}

export default memo(RowActionsMenuCell) as <T extends BaseItem>(
  props: RowActionsMenuCellProps<T>,
) => JSX.Element;
