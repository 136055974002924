import React from 'react';
import {
  components as DefaultSelectComponents,
  ControlProps,
} from 'react-select';
import classNames from 'classnames';
import Button, {
  ButtonSizes,
  ButtonTypes,
} from '@lib/components/Button/Button';
import styles from '../Select.module.scss';

export default function ButtonControl(props: ControlProps): JSX.Element {
  const { children, ...restProps } = props;
  const { selectProps, isFocused } = restProps;
  // @ts-ignore
  const { menuIsOpen, isDisabled } = selectProps;
  const controlClassName = classNames(styles.control, styles.buttonControl, {
    isFocused,
    isDisabled,
    menuIsOpen,
  });
  return (
    <DefaultSelectComponents.Control
      {...restProps}
      className={controlClassName}
    >
      <Button
        buttonText={children}
        buttonType={ButtonTypes.primaryOutlined}
        buttonSize={ButtonSizes.large}
        isFocused={menuIsOpen}
        rightIcon={menuIsOpen ? 'arrow_drop_up' : 'arrow_drop_down'}
      />
    </DefaultSelectComponents.Control>
  );
}
