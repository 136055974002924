import React from 'react';
import { TFunction } from 'i18next';
import _get from 'lodash/get';
import { FormTemplate, FormTemplateScopeNameEnum } from 'graphql-common';
import TextCell from '@lib/components/Table/commonComponents/TextCell/TextCell';
import ChipCell from '@lib/components/Table/commonComponents/ChipCell/ChipCell';
import { CellProps, Column } from '@lib/components/Table/types';
import TextLinkCell from '@lib/components/Table/commonComponents/TextLinkCell/TextLinkCell';
import Button, { ButtonTypes } from '@lib/components/Button/Button';
import { getFormattedDate } from '@lib/utils/dateTimeHelpers';
import { APP_URLS } from 'constants/urls';
import FormTemplateFieldNames from '@lib/enums/fieldNames/formTemplateFieldNames';

type ColumnsArgs = {
  formTemplateScope: FormTemplateScopeNameEnum;
  onCreateTaskHandler: (v: FormTemplate) => void;
  onRestoreHandler: (v: FormTemplate) => void;
  t: TFunction<'translation', undefined>;
  isViewOnly: boolean;
};

const getTableColumns = ({
  formTemplateScope,
  t,
  onCreateTaskHandler,
  onRestoreHandler,
  isViewOnly,
}: ColumnsArgs) => {
  const result: Column<FormTemplate>[] = [
    {
      id: 'name',
      Header: t('name'),
      accessor: FormTemplateFieldNames.Name,
      Cell: (props: CellProps<FormTemplate>) => {
        const formTemplateId = props?.item.id;
        const formTemplateVersionId = props?.item.currentVersion?.id || '';
        return (
          <TextLinkCell
            {...props}
            to={APP_URLS.dashboard.formTemplates.viewVersion.getDynamicPath({
              pathParts: {
                id: formTemplateId,
                versionId: formTemplateVersionId,
              },
            })}
          />
        );
      },
      maxWidth: 250,
      sortable: true,
    },
    {
      id: 'categoryName',
      Header: t('form-category'),
      Cell: (props: CellProps<FormTemplate>) => {
        const label = _get(
          props,
          `item.${FormTemplateFieldNames.Category}.name`,
        );
        const type = _get(
          props,
          `item.${FormTemplateFieldNames.Category}.color`,
        );
        if (!label || !type) return '-';
        return <ChipCell label={label} type={type} leftIcon="circle" />;
      },
      maxWidth: 250,
      minWidth: 130,
      width: 170,
      sortable: true,
    },
    {
      id: 'versionsCount',
      Header: t('versions'),
      accessor: 'versionsCount',
      Cell: (props: CellProps<FormTemplate>) => <TextCell {...props} />,
      maxWidth: 170,
      minWidth: 130,
      width: 170,
      sortable: true,
    },
  ];
  if (formTemplateScope === FormTemplateScopeNameEnum.Active) {
    result.push(
      {
        id: 'visibleLinkedTasksCount',
        Header: t('linked-tasks'),
        accessor: 'visibleLinkedTasksCount',
        Cell: (props: CellProps<FormTemplate>) => {
          const formTemplateId = props?.item.id;
          const formTemplateVersionId = props?.item.currentVersion?.id || '';
          return (
            <TextLinkCell
              {...props}
              to={APP_URLS.dashboard.formTemplates.viewLinkedTasks.getDynamicPath(
                {
                  pathParts: {
                    id: formTemplateId,
                    versionId: formTemplateVersionId,
                  },
                },
              )}
            />
          );
        },
        maxWidth: 170,
        minWidth: 130,
        width: 170,
        sortable: true,
      },
      {
        id: 'createdAt',
        Header: t('date-created'),
        accessorFn: ({ item }: CellProps<FormTemplate>) =>
          getFormattedDate(item.createdAt),
        Cell: (props: CellProps<FormTemplate>) => <TextCell {...props} />,
        maxWidth: 170,
        minWidth: 130,
        width: 170,
        sortable: true,
      },
    );
    if (!isViewOnly) {
      result.push({
        id: 'createTask',
        Header: '',
        Cell: ({ item }: CellProps<FormTemplate>) => (
          <div>
            <Button
              buttonText={t('create-task')}
              buttonType={ButtonTypes.primaryOutlined}
              onClick={() => onCreateTaskHandler(item)}
              leftIcon="task_alt"
            />
          </div>
        ),
        width: 1,
      });
    }
  } else {
    result.push({
      id: 'archivedAt',
      Header: t('archiving-date'),
      accessorFn: ({ item }: CellProps<FormTemplate>) =>
        getFormattedDate(item.archivedAt),
      Cell: (props: CellProps<FormTemplate>) => <TextCell {...props} />,
      maxWidth: 170,
      minWidth: 130,
      width: 170,
      sortable: true,
    });
    if (!isViewOnly) {
      result.push({
        id: 'restoreFormTemplate',
        Header: '',
        Cell: ({ item }: CellProps<FormTemplate>) => (
          <div>
            <Button
              buttonText={t('restore')}
              buttonType={ButtonTypes.primaryOutlined}
              onClick={() => onRestoreHandler(item)}
              leftIcon="task_alt"
            />
          </div>
        ),
        width: 1,
      });
    }
  }
  return result;
};

export default getTableColumns;
