import React from 'react';
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import styles from '@lib/components/Table/components/TableBody/TableBody.module.scss';
import CellWithActions from '@lib/components/Table/components/TableBody/CellWithActions/CellWithActions';
import {
  BaseItem,
  CellContentAlign,
  Column,
  TableProps,
} from '@lib/components/Table/types';
import CellContent from '@lib/components/Table/components/TableBody/CellContent';

type Props<T extends BaseItem> = {
  column: Column<T>;
  rowItem: T;
  isDisabled?: boolean;
  tableProps: TableProps<T>;
};

export default function TableBodyCell<T extends BaseItem>(props: Props<T>) {
  const { column, rowItem, isDisabled, tableProps } = props;

  const {
    bordered,
    maxWidth,
    minWidth,
    width,
    actions,
    contentAlign,
    prohibitDisabled,
    cellClassName,
    noWrap,
  } = column;
  const cellStyle = {
    width,
    minWidth,
    maxWidth,
    whiteSpace: noWrap ? 'nowrap' : undefined,
  };
  const cellElClassName = classNames(cellClassName, {
    [styles.borderedCell]: bordered,
  });
  const cellContentClassName = classNames(styles.tdContent, {
    [styles.tdContentCenter]: contentAlign === CellContentAlign.Center,
    [styles.tdContentEnd]: contentAlign === CellContentAlign.End,
    [styles.tdContentDisabled]: isDisabled && !prohibitDisabled,
  });

  return (
    <td key={uuidv4()} className={cellElClassName} style={cellStyle}>
      {actions ? (
        <CellWithActions
          column={column}
          item={rowItem}
          tableProps={tableProps}
        />
      ) : (
        <div className={cellContentClassName}>
          <CellContent column={column} item={rowItem} tableProps={tableProps} />
        </div>
      )}
    </td>
  );
}
