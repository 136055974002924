import React, { memo } from 'react';

interface Props {
  alt?: string;
  className?: string;
  src?: string | null;
}

function Image(props: Props) {
  const { alt, className, src } = props;
  if (!src) return null;
  return <img src={src} alt={alt} className={className} />;
}

export default memo(Image);
