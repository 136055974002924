import { TFunction } from 'i18next';
import { User } from 'graphql-common';
import { NavItem, NavItemTypeEnum } from '@lib/components/Menu/Menu';
import Icons from '@lib/components/Icons/Icons';

type ActionsMenuArgs = {
  currentUserId?: string;
  onActivate: (item?: User) => void;
  onDeactivate: (item?: User) => void;
  onDelete: (item?: User) => void;
  onEdit: (item?: User) => void;
  onEditAccesses: (item?: User) => void;
  onView?: (item?: User) => void;
  t: TFunction<'translation', undefined>;
  isViewOnly: boolean;
};

export default function getUsersTableActionsMenu({
  currentUserId,
  onActivate,
  onDeactivate,
  onDelete,
  onEdit,
  onEditAccesses,
  onView,
  t,
  isViewOnly,
}: ActionsMenuArgs): NavItem<User>[] {
  return [
    {
      id: 'view-action',
      title: t('view'),
      onClick: onView,
      leftIcon: Icons.view,
      type: NavItemTypeEnum.Secondary,
      predictorFnDisable: (item?: User) => currentUserId === item?.id,
    },
    {
      id: 'edit-action',
      title: t('edit'),
      onClick: onEdit,
      leftIcon: Icons.edit,
      type: NavItemTypeEnum.Secondary,
      predictorFnDisable: (item?: User) => currentUserId === item?.id,
      predictorFnVisible: () => !isViewOnly,
    },
    {
      id: 'edit-access-action',
      title: t('edit-access-rights'),
      onClick: onEditAccesses,
      leftIcon: Icons.edit,
      type: NavItemTypeEnum.Secondary,
      predictorFnDisable: (item?: User) => currentUserId === item?.id,
      predictorFnVisible: () => !isViewOnly,
    },
    {
      id: 'deactivate-action',
      title: t('deactivate'),
      onClick: onDeactivate,
      leftIcon: Icons.deactivate,
      type: NavItemTypeEnum.Secondary,
      predictorFnDisable: (item?: User) => currentUserId === item?.id,
      predictorFnVisible: (item?: User) => !item?.deactivatedAt && !isViewOnly,
    },
    {
      id: 'activate-action',
      title: t('activate'),
      onClick: onActivate,
      leftIcon: Icons.activate,
      type: NavItemTypeEnum.Secondary,
      predictorFnVisible: (item?: User) => !!item?.deactivatedAt && !isViewOnly,
    },
    {
      id: 'delete-action',
      title: t('delete'),
      onClick: onDelete,
      leftIcon: Icons.delete,
      type: NavItemTypeEnum.Secondary,
      predictorFnDisable: (item?: User) => currentUserId === item?.id,
      predictorFnVisible: () => false, // TODO: use it when needed
    },
  ];
}
