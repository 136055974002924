import { useEffect } from 'react';
import _get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import {
  useCountriesQuery,
  User,
  UserCreateInputObject,
  UserUpdateInputObject,
  useSitesSearchListLazyQuery,
  useTeamsSearchListLazyQuery,
  useUserCreateMutation,
  useUserQuery,
  useUserUpdateMutation,
} from 'graphql-common';
import { Values } from '@lib/interfaces/form';
import getPreparedDataFromValues from 'routes/Users/utils/getPreparedDataFromValues';
import getAppUrlWithDomain from '@lib/utils/getAppUrlWithDomain';
import toast from '@lib/components/Toast/Toast';
import { APP_URLS } from 'constants/urls';
import { getSitesLoadOptions as getSitesLoadOptionsFn } from 'utils/fetch/sitesSearchList';
import getDefaultValuesFromQueryData from 'routes/Users/utils/getDefaultValuesFromQueryData';
import { getTeamsLoadOptions as getTeamsLoadOptionsFn } from 'utils/fetch/teamsSearchList';

const { VITE_AUTH_PLATFORM_URL: AUTH_PLATFORM } = import.meta.env;

interface Props {
  onSubmitCallback: (v?: User | null) => void;
  isOpen: boolean;
  selectedUserId?: string | null;
}

export default function useActions(props: Props) {
  const { selectedUserId, isOpen, onSubmitCallback } = props;
  const { t } = useTranslation();

  // User query
  const userQuery = useUserQuery({
    skip: !selectedUserId,
    fetchPolicy: 'cache-and-network',
    variables: {
      id: selectedUserId || '',
      includeFullData: true,
    },
  });
  const selectedUser = _get(userQuery, 'data.data') as User;
  const userLoading = _get(userQuery, 'loading');
  const userError = _get(userQuery, 'error');

  // Countries query
  const countriesQuery = useCountriesQuery({
    fetchPolicy: 'cache-first',
  });
  const countries = _get(countriesQuery, 'data.data', []);
  const countriesCodes = countries.map(({ code }) => code.toLowerCase());
  const countriesError = _get(countriesQuery, 'error');

  // Sites query
  const [fetchSitesSearchListLazyQuery] = useSitesSearchListLazyQuery({
    fetchPolicy: 'cache-and-network',
  });

  const sitesLoadOptions = getSitesLoadOptionsFn(fetchSitesSearchListLazyQuery);

  // Teams query
  const [fetchTeamsSearchListLazyQuery] = useTeamsSearchListLazyQuery({
    fetchPolicy: 'cache-and-network',
  });

  const teamsLoadOptions = getTeamsLoadOptionsFn(fetchTeamsSearchListLazyQuery);

  // Mutations
  const [createMutation, createMutationResult] = useUserCreateMutation({
    onCompleted: (response) => {
      toast({ content: t('create-team-member-success') });
      onSubmitCallback(response?.data as User);
    },
    onError: (error) => {
      toast({ content: error.message || 'Oops! Something went wrong' });
    },
  });

  const [updateMutation, updateMutationResult] = useUserUpdateMutation({
    onCompleted: (response) => {
      toast({ content: t('update-team-member-success') });
      onSubmitCallback(response?.data as User);
    },
    onError: (error) => {
      toast({ content: error.message || 'Oops! Something went wrong' });
    },
  });

  const onCreateHandler = (values: UserCreateInputObject) => {
    createMutation({
      variables: {
        attributes: values,
        redirectUrl: getAppUrlWithDomain(
          APP_URLS.setup.finishRegistration.path,
          AUTH_PLATFORM,
        ),
      },
    });
  };

  const onUpdateHandler = (values: UserUpdateInputObject) => {
    if (selectedUserId) {
      updateMutation({
        variables: {
          id: selectedUserId,
          attributes: values,
          finishRegistrationRedirectUrl: getAppUrlWithDomain(
            APP_URLS.setup.finishRegistration.path,
            AUTH_PLATFORM,
          ),
          confirmEmailRedirectUrl: getAppUrlWithDomain(
            APP_URLS.setup.confirmEmail.path,
            AUTH_PLATFORM,
          ),
        },
      });
    }
  };

  const onSubmitUserForm = (values: Values) => {
    const prevData = {
      ...selectedUser,
    };
    const prevValues = getDefaultValuesFromQueryData(t, prevData);
    const newValues = getPreparedDataFromValues(values, prevValues);
    if (selectedUserId) {
      onUpdateHandler(newValues as UserUpdateInputObject);
    } else {
      onCreateHandler(newValues as UserCreateInputObject);
    }
  };

  const {
    error: createMutationError,
    loading: createUserLoading,
    reset: createUserReset,
  } = createMutationResult;
  const {
    error: updateMutationError,
    loading: updateUserLoading,
    reset: updateUserReset,
  } = updateMutationResult;
  const graphQLErrors = selectedUserId
    ? updateMutationError?.graphQLErrors
    : createMutationError?.graphQLErrors;
  const loading = userLoading || createUserLoading || updateUserLoading;

  useEffect(() => {
    if (!isOpen) {
      createUserReset();
      updateUserReset();
    }
  }, [createUserReset, isOpen, updateUserReset]);

  return {
    countriesCodes,
    countriesError,
    graphQLErrors,
    loading,
    onSubmitUserForm,
    selectedUser,
    sitesLoadOptions,
    userError,
    userLoading,
    teamsLoadOptions,
  };
}
