import React from 'react';
import { TFunction } from 'i18next';
import _get from 'lodash/get';
import _toLower from 'lodash/toLower';
import _without from 'lodash/without';
import _isEmpty from 'lodash/isEmpty';
import _upperFirst from 'lodash/upperFirst';
import {
  Task,
  TaskDeadlineMissedReasonEnum,
  TaskScopeNameEnum,
  TaskStatusEnum,
} from 'graphql-common';
import { CellProps, Column } from '@lib/components/Table/types';
import TextCell from '@lib/components/Table/commonComponents/TextCell/TextCell';
import ChipCell from '@lib/components/Table/commonComponents/ChipCell/ChipCell';
import TextLinkCell from '@lib/components/Table/commonComponents/TextLinkCell/TextLinkCell';
import CountMark, {
  CountMarkSizes,
  CountMarkTypes,
} from '@lib/components/CountMark/CountMark';
import { APP_URLS } from 'constants/urls';
import Chip, { ChipTypes } from '@lib/components/Chip/Chip';
import { getFormattedDate } from '@lib/utils/dateTimeHelpers';
import doNotRepeatIconSrc from '@lib/assets/icons/custom_do_not_repeat.svg';
import { getPriorityChipType } from '@lib/utils/priority';
import TaskStatusChip from '@lib/components/TaskStatusChip/TaskStatusChip';
import { getValidityHintChipIcon } from '@lib/components/Select/components/ValidityHintOption';
import TaskDeadlineMissedReasonChip from 'components/TaskDeadlineMissedReasonChip/TaskDeadlineMissedReasonChip';
import styles from '../TasksContainer.module.scss';

type ColumnsArgs = {
  isApprovals?: boolean;
  onStatusChangeHandler?: (id: string, v: TaskStatusEnum) => void;
  onDeadlineMissedReasonChangeHandler?: (
    id: string,
    v: TaskDeadlineMissedReasonEnum,
  ) => void;
  t: TFunction<'translation', undefined>;
  taskBulkDeadlineMissedReasonUpdateLoading: boolean;
  taskScope?: TaskScopeNameEnum;
};

const getTableColumns = ({
  isApprovals,
  t,
  taskBulkDeadlineMissedReasonUpdateLoading,
  taskScope,
  onStatusChangeHandler,
  onDeadlineMissedReasonChangeHandler,
}: ColumnsArgs) =>
  _without(
    [
      taskScope === TaskScopeNameEnum.Completed
        ? {
            id: 'completion.validityHint',
            Header: ' ',
            accessor: 'completion.validityHint',
            Cell: ({ value }: CellProps<Task>) =>
              getValidityHintChipIcon(value as string),
            width: 44,
          }
        : undefined,
      {
        id: 'recurringRule',
        Header: ' ',
        accessor: 'recurringRuleId',
        Cell: (props: CellProps<Task>) => {
          const isRepeatable = !_isEmpty(_get(props, 'value'));
          return (
            <Chip
              className={styles.repeatStatusChip}
              type={isRepeatable ? ChipTypes.chipBlue : ChipTypes.chipGray}
              leftIcon={isRepeatable ? 'repeat' : undefined}
              leftImage={isRepeatable ? undefined : doNotRepeatIconSrc}
              tooltip={isRepeatable ? t('recurring') : t('non-recurring')}
              asBlockElement
            />
          );
        },
        width: 44,
      },
      {
        id: 'name',
        Header: t('name'),
        accessor: 'name',
        Cell: (props: CellProps<Task>) => (
          <TextLinkCell
            {...props}
            to={APP_URLS.dashboard.tasks.view.getDynamicPath({
              pathParts: {
                id: props?.item.id,
              },
            })}
          />
        ),
        sortable: true,
        minWidth: 300,
        maxWidth: 300,
      },
      taskScope !== TaskScopeNameEnum.DeadlineMissed
        ? {
            id: 'formCategoryName',
            Header: t('form-category'),
            Cell: (props: CellProps<Task>) => {
              const label = _get(props, 'item.formTemplate.category.name');
              const type = _get(props, 'item.formTemplate.category.color');
              if (!label || !type) return '-';
              return <ChipCell label={label} type={type} leftIcon="circle" />;
            },
            sortable: true,
            maxWidth: 200,
            minWidth: 200,
            width: 200,
          }
        : undefined,
      {
        id: 'siteName',
        Header: t('site'),
        accessor: 'site',
        Cell: (props: CellProps<Task>) => {
          const label = _get(props, 'item.site.name', '');
          if (!label) return '-';
          return <ChipCell label={label} type={ChipTypes.chipGray} />;
        },
        sortable: true,
        width: 200,
        maxWidth: 200,
        minWidth: 200,
      },
      {
        id: 'siteAreaName',
        Header: t('area'),
        accessor: 'siteArea',
        Cell: (props: CellProps<Task>) => {
          const label = _get(props, 'item.siteArea.name', '');
          if (!label) return '-';
          return <ChipCell label={label} type={ChipTypes.chipGray} />;
        },
        sortable: true,
        width: 200,
        maxWidth: 200,
        minWidth: 200,
      },
      {
        id: 'priority',
        Header: t('priority'),
        accessor: 'priority',
        Cell: (props: CellProps<Task>) => {
          const priority = _get(props, 'item.priority');
          const label = _upperFirst(_toLower(priority));
          if (!label) return '-';
          return (
            <ChipCell
              label={label}
              type={getPriorityChipType(priority)}
              leftIcon="circle"
            />
          );
        },
        sortable: true,
        width: 120,
        maxWidth: 120,
        minWidth: 120,
      },
      taskScope === TaskScopeNameEnum.Active
        ? {
            id: 'lastCheckAt',
            Header: t('last-check'),
            accessorFn: ({ item }: CellProps<Task>) =>
              item.lastCheckAt ? getFormattedDate(item.lastCheckAt) : '-',
            Cell: (props: CellProps<Task>) => <TextCell {...props} />,
            sortable: true,
            minWidth: 150,
            width: 150,
          }
        : undefined,
      taskScope === TaskScopeNameEnum.Active ||
      taskScope === TaskScopeNameEnum.Pending ||
      isApprovals
        ? {
            id: 'deadlineAt',
            Header: t('next-check'),
            accessorFn: ({ item }: CellProps<Task>) =>
              item.deadlineAt ? getFormattedDate(item.deadlineAt) : '-',
            Cell: (props: CellProps<Task>) => <TextCell {...props} />,
            sortable: true,
            width: 150,
            maxWidth: 150,
            minWidth: 150,
          }
        : undefined,
      taskScope === TaskScopeNameEnum.Active ||
      taskScope === TaskScopeNameEnum.Pending ||
      isApprovals
        ? {
            id: 'status',
            Header: t('status'),
            accessor: 'status',
            Cell: ({ item }: CellProps<Task>) => (
              <TaskStatusChip
                task={item}
                onStatusChangeHandler={
                  isApprovals &&
                  item.status !== TaskStatusEnum.Completed &&
                  !!onStatusChangeHandler
                    ? onStatusChangeHandler
                    : undefined
                }
              />
            ),
            sortable: true,
            width: 150,
            maxWidth: 150,
            minWidth: 150,
          }
        : undefined,
      taskScope === TaskScopeNameEnum.Archived
        ? {
            id: 'archivedAt',
            Header: t('archiving-date'),
            accessorFn: ({ item }: CellProps<Task>) =>
              item.archivedAt ? getFormattedDate(item.archivedAt) : '-',
            Cell: (props: CellProps<Task>) => <TextCell {...props} />,
            sortable: true,
            width: 150,
            maxWidth: 150,
            minWidth: 150,
          }
        : undefined,
      taskScope === TaskScopeNameEnum.Completed
        ? {
            id: 'completedAt',
            Header: t('completion-date'),
            accessorFn: ({ item }: CellProps<Task>) =>
              item.completedAt ? getFormattedDate(item.completedAt) : '-',
            Cell: (props: CellProps<Task>) => <TextCell {...props} />,
            sortable: true,
            width: 150,
            maxWidth: 150,
            minWidth: 150,
          }
        : undefined,
      taskScope === TaskScopeNameEnum.Completed
        ? {
            id: 'deadlineMissedReason',
            Header: t('deadline-missed'),
            Cell: (props: CellProps<Task>) => {
              const { item } = props;
              const { lateFor } = item;
              if (lateFor) {
                return (
                  <div className={styles.deadLineMissedCell}>
                    <CountMark
                      value={`${lateFor}${t('day-short')}`}
                      type={CountMarkTypes.red}
                      size={CountMarkSizes.large}
                    />
                    <TaskDeadlineMissedReasonChip
                      loading={taskBulkDeadlineMissedReasonUpdateLoading}
                      onDeadlineMissedReasonChangeHandler={
                        onDeadlineMissedReasonChangeHandler
                      }
                      task={item}
                    />
                  </div>
                );
              }
              return <TextCell {...props} />;
            },
            sortable: true,
            minWidth: 150,
            maxWidth: 150,
          }
        : undefined,
      taskScope === TaskScopeNameEnum.DeadlineMissed
        ? {
            id: 'deadlineAt',
            Header: t('deadline'),
            accessorFn: ({ item }: CellProps<Task>) =>
              item.deadlineAt ? getFormattedDate(item.deadlineAt) : '-',
            Cell: (props: CellProps<Task>) => <TextCell {...props} />,
            sortable: true,
            width: 150,
            maxWidth: 150,
            minWidth: 150,
          }
        : undefined,
      taskScope === TaskScopeNameEnum.DeadlineMissed
        ? {
            id: 'lateFor',
            Header: t('late-for'),
            accessor: 'lateFor',
            Cell: (props: CellProps<Task>) => {
              const { value } = props;
              if (value)
                return (
                  <CountMark
                    value={`${value}${t('day-short')}`}
                    type={CountMarkTypes.red}
                    size={CountMarkSizes.large}
                  />
                );
              return <TextCell {...props} />;
            },
            sortable: true,
            minWidth: 100,
            maxWidth: 150,
          }
        : undefined,
      taskScope === TaskScopeNameEnum.DeadlineMissed
        ? {
            id: 'deadlineMissedReason',
            Header: t('reason'),
            accessor: 'deadlineMissedReason',
            Cell: ({ item }: CellProps<Task>) => (
              <TaskDeadlineMissedReasonChip
                loading={taskBulkDeadlineMissedReasonUpdateLoading}
                onDeadlineMissedReasonChangeHandler={
                  onDeadlineMissedReasonChangeHandler
                }
                task={item}
              />
            ),
            sortable: true,
            minWidth: 150,
            maxWidth: 150,
          }
        : undefined,
    ],
    undefined,
  ) as Column<Task>[];

export default getTableColumns;
