import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ComponentEnum, LevelEnum } from 'graphql-common';
import { Values } from '@lib/interfaces/form';
import toast from '@lib/components/Toast/Toast';
import { APP_URLS } from 'constants/urls';
import SiteComponent, { SiteViewMode } from 'components/Site/Site';
import BreadCrumbs from '@lib/components/BreadCrumbs/BreadCrumbs';
import { FormRef } from '@lib/components/ReactHookForm/FormContainer';
import { getBreadCrumbsDataByKey } from 'utils/breadcrumbs';
import useOutletContext, { ContextProps } from 'hooks/useOutletContext';
import useSiteModalManagement from './utils/useSiteModalManagement';
import useSiteActions from './utils/useSiteActions';

interface Props {
  viewMode: SiteViewMode;
}

function SiteContainer({ viewMode }: Props) {
  const { id } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const formRef = useRef<FormRef>();

  const { getUserAccessByComponent }: ContextProps = useOutletContext();
  const isViewOnly =
    getUserAccessByComponent(ComponentEnum.SitesManagement) === LevelEnum.Read;

  const {
    closeCodeOfConductDeleteModal,
    closeSiteDeleteModal,
    closeUserFormModal,
    isCodeOfConductDeleteModalOpened,
    isSiteDeleteModalOpened,
    isUserFormModalOpened,
    openCodeOfConductDeleteModal,
    openSiteDeleteModal,
    openUserFormModal,
  } = useSiteModalManagement();

  const {
    countriesCodes,
    createMutationError,
    createSiteLoading,
    usersLoadOptions,
    onConfirmedDelete,
    onCreate,
    onCreateUserCallback,
    onDestroyCodeOfConductHandler,
    onEdit,
    siteData,
    siteError,
    siteLoading,
    updateMutationError,
    updateSiteLoading,
  } = useSiteActions({
    id,
    closeSiteDeleteModal,
    formRef,
    closeUserFormModal,
  });

  const onViewCodeOfConduct = () =>
    navigate(
      APP_URLS.dashboard.sites.viewCodeOfConduct.getDynamicPath({
        pathParts: {
          id: `${id}`,
        },
      }),
    );

  const onEditCodeOfConduct = () =>
    navigate(
      APP_URLS.dashboard.sites.editCodeOfConduct.getDynamicPath({
        pathParts: {
          id: `${id}`,
        },
      }),
    );

  const onConfirmedDeleteCodeOfConduct = () => {
    closeCodeOfConductDeleteModal();
    toast({
      content: t('destroy-code-of-conduct-success'),
      closeCallback: onDestroyCodeOfConductHandler,
    });
  };

  return (
    <>
      <BreadCrumbs
        data={getBreadCrumbsDataByKey({
          pathname,
          t,
          entity: siteData as Values,
        })}
      />
      <SiteComponent
        closeCodeOfConductDeleteModal={closeCodeOfConductDeleteModal}
        closeSiteDeleteModal={closeSiteDeleteModal}
        closeUserFormModal={closeUserFormModal}
        countriesCodes={countriesCodes}
        createMutationError={createMutationError}
        createSiteLoading={createSiteLoading}
        formRef={formRef}
        isCodeOfConductDeleteModalOpened={isCodeOfConductDeleteModalOpened}
        isSiteDeleteModalOpened={isSiteDeleteModalOpened}
        isUserFormModalOpened={isUserFormModalOpened}
        isViewOnly={isViewOnly}
        onConfirmedDelete={onConfirmedDelete}
        onConfirmedDeleteCodeOfConduct={onConfirmedDeleteCodeOfConduct}
        onCreate={onCreate}
        onCreateUserCallback={onCreateUserCallback}
        onDelete={openSiteDeleteModal}
        onDeleteCodeOfConduct={openCodeOfConductDeleteModal}
        onEdit={onEdit}
        onEditCodeOfConduct={onEditCodeOfConduct}
        onViewCodeOfConduct={onViewCodeOfConduct}
        openUserFormModal={openUserFormModal}
        siteData={siteData}
        siteError={siteError}
        siteLoading={siteLoading}
        updateMutationError={updateMutationError}
        updateSiteLoading={updateSiteLoading}
        usersLoadOptions={usersLoadOptions}
        viewMode={viewMode}
      />
    </>
  );
}

export default SiteContainer;
