import {
  InterventionActionStatusEnum,
  InterventionStatusEnum,
} from 'graphql-common';
import { ChipTypes } from '@lib/components/Chip/Chip';

export function getInterventionStatusOptions() {
  return [
    {
      labelKey: 'planned',
      value: InterventionStatusEnum.Planned,
      color: ChipTypes.chipBlue,
    },
    {
      labelKey: 'in-progress',
      value: InterventionStatusEnum.InProgress,
      color: ChipTypes.chipOrange,
    },
    {
      labelKey: 'in-check',
      value: InterventionStatusEnum.InCheck,
      color: ChipTypes.chipOrange,
    },
    {
      labelKey: 'classified-sats-continued',
      value: InterventionStatusEnum.ClassifiedSatsContinued,
      color: ChipTypes.chipOrange,
    },
    {
      labelKey: 'material-ordered',
      value: InterventionStatusEnum.MaterialOrdered,
      color: ChipTypes.chipViolet,
    },
    {
      labelKey: 'material-received',
      value: InterventionStatusEnum.MaterialReceived,
      color: ChipTypes.chipViolet,
    },
    {
      labelKey: 'closed',
      value: InterventionStatusEnum.Closed,
      color: ChipTypes.chipGreen,
    },
  ];
}

export function getInterventionStatusOption(status: InterventionStatusEnum) {
  const options = getInterventionStatusOptions();
  return options.find((option) => option.value === status);
}

export function getInterventionStatusChipType(status: InterventionStatusEnum) {
  const option = getInterventionStatusOption(status);
  return option?.color || ChipTypes.chipDisabled;
}

export function getInterventionActionStatusOptions() {
  return [
    {
      labelKey: 'planned',
      value: InterventionActionStatusEnum.Planned,
      color: ChipTypes.chipBlue,
    },
    {
      labelKey: 'in-progress',
      value: InterventionActionStatusEnum.InProgress,
      color: ChipTypes.chipOrange,
    },
    {
      labelKey: 'in-check',
      value: InterventionActionStatusEnum.InCheck,
      color: ChipTypes.chipOrange,
    },
    {
      labelKey: 'classified-sats-continued',
      value: InterventionActionStatusEnum.ClassifiedSatsContinued,
      color: ChipTypes.chipOrange,
    },
    {
      labelKey: 'material-ordered',
      value: InterventionActionStatusEnum.MaterialOrdered,
      color: ChipTypes.chipViolet,
    },
    {
      labelKey: 'material-received',
      value: InterventionActionStatusEnum.MaterialReceived,
      color: ChipTypes.chipViolet,
    },
    {
      labelKey: 'closed',
      value: InterventionActionStatusEnum.Closed,
      color: ChipTypes.chipGreen,
    },
  ];
}

export function getInterventionActionStatusOption(
  status: InterventionActionStatusEnum,
) {
  const options = getInterventionActionStatusOptions();
  return options.find((option) => option.value === status);
}

export function getInterventionActionStatusChipType(
  status: InterventionActionStatusEnum,
) {
  const option = getInterventionActionStatusOption(status);
  return option?.color || ChipTypes.chipDisabled;
}
