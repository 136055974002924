import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  components as DefaultSelectComponents,
  MultiValueGenericProps,
} from 'react-select';
import _get from 'lodash/get';
import Chip, { ChipSizes } from '@lib/components/Chip/Chip';
import { getAllOption } from '@lib/enums/form';
import styles from '../Select.module.scss';

function MultiValueContainer({ children, ...props }: MultiValueGenericProps) {
  const { t } = useTranslation();
  const { data } = props;
  const { label, value, color } = data;
  if (children === null) return children;
  const allOption = getAllOption(t);
  if (value === allOption.value) return allOption.label;
  return (
    <DefaultSelectComponents.MultiValueContainer {...props}>
      <div className={styles.multiValueContainerBox}>
        <Chip
          label={label}
          leftIcon={color ? 'circle' : undefined}
          rightIconComponent={_get(children, 1)}
          size={ChipSizes.medium}
          type={color}
        />
      </div>
    </DefaultSelectComponents.MultiValueContainer>
  );
}

export default MultiValueContainer;
