import React from 'react';
import { useTranslation } from 'react-i18next';
import { Site } from 'graphql-common';
import Typography from '@lib/components/Typography/Typography';
import EmptyState from '@lib/components/EmptyState/EmptyState';
import styles from './ConductCodeView.module.scss';

interface Props {
  siteData: Site;
}

function ConductCodeView(props: Props) {
  const { siteData } = props;
  const { siteConductCodeSections = [] } = siteData || {};
  const { t } = useTranslation();

  if (!siteConductCodeSections?.length) {
    return (
      <EmptyState
        text={t('no-code-of-conduct-text')}
        title={t('no-data-yet')}
      />
    );
  }

  return (
    <div>
      {siteConductCodeSections.map(({ id: sectionId, body, title }) => (
        <div key={sectionId} className={styles.conductCodeSectionsContent}>
          <Typography variant="body" strong>
            {title}
          </Typography>
          <Typography variant="body" className={styles.conductCodeSectionsBody}>
            <pre>{body}</pre>
          </Typography>
        </div>
      ))}
    </div>
  );
}

export default ConductCodeView;
