import { useTagsLazyQuery } from 'graphql-common';
import { getTagsLoadOptions as getTagsLoadOptionsFn } from 'utils/fetch/tagsSearchList';

export default function useSearchListQueries() {
  // Tags
  const [fetchTagsSearchListLazyQuery] = useTagsLazyQuery({
    fetchPolicy: 'network-only',
  });

  const tagsLoadOptions = getTagsLoadOptionsFn(fetchTagsSearchListLazyQuery);

  return {
    tagsLoadOptions,
  };
}
