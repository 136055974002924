import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { LazyQueryExecFunction } from '@apollo/client';
import { JobStatusEnum, JobStatusesQuery, Job } from 'graphql-common';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';
import { downloadLinkId } from '@lib/enums/selectors';
import { QR_CODE_JIDS } from '@lib/enums/localStorageKeys';
import usePrevious from '@lib/hooks/usePrevious';
import toast from '@lib/components/Toast/Toast';

interface Props {
  fetchJobStatus: LazyQueryExecFunction<JobStatusesQuery, any>;
  stopPolling: () => void;
}

const downloadHandler = (updatedJob: Job) => {
  const payload = updatedJob?.payload as string;
  let downloadLink: string;
  try {
    if (payload) {
      const parsedPayload = JSON.parse(payload);
      downloadLink = _get(parsedPayload, 'link');
      const link = document.getElementById(downloadLinkId);
      if (downloadLink && link) {
        link.setAttribute('href', downloadLink);
        link.click();
        link.setAttribute('href', '');
      }
    }
  } catch (error) {
    console.warn(error);
  }
};

const useDownloadQrCodeWatcher = ({ fetchJobStatus, stopPolling }: Props) => {
  const { t } = useTranslation();
  let parsedJobIds: string[];
  try {
    parsedJobIds = JSON.parse(localStorage.getItem(QR_CODE_JIDS) || '[]');
  } catch (error) {
    parsedJobIds = [];
  }
  const [jobIds, setJobIds] = useState<string[]>(parsedJobIds);
  const prevJobIds = usePrevious(jobIds);
  const [jobs, setJobs] = useState<Job[]>([]);

  const setJobId = (newJob: Job) => {
    const existingJids = jobIds;
    const newJobId = newJob.jid;
    if (newJobId && !existingJids.includes(newJobId)) {
      const newJids = [...existingJids, newJobId];
      localStorage.setItem(QR_CODE_JIDS, JSON.stringify(newJids));
      if (newJids?.length) setJobIds(newJids);
      setJobs([...jobs, newJob]); // Add new job to state
    }
  };

  const removeJobId = (jidToRemove: string) => {
    setJobIds((currentJids) => {
      const updatedJids = currentJids.filter((jid) => jid !== jidToRemove);
      localStorage.setItem(QR_CODE_JIDS, JSON.stringify(updatedJids));
      return updatedJids;
    });
    setJobs((currentJobs) =>
      currentJobs.filter((job) => job.jid !== jidToRemove),
    ); // Remove job from state
  };

  useEffect(() => {
    if (jobIds.length) {
      fetchJobStatus({
        variables: { jobs: jobIds.map((jid) => ({ jid })) },
        onCompleted: (response) => {
          response.data.forEach((updatedJob) => {
            const updatedJobStatus = updatedJob?.status;
            const updatedJobId = updatedJob?.jid;
            if (updatedJobId) {
              setJobs((currentJobs) => {
                const jobIndex = currentJobs.findIndex(
                  (job) => job.jid === updatedJob.jid,
                );
                if (jobIndex > -1) {
                  const newJobs = [...currentJobs];
                  newJobs[jobIndex] = updatedJob;
                  return newJobs;
                }
                return currentJobs;
              });

              if (
                updatedJobStatus &&
                [
                  JobStatusEnum.Complete,
                  JobStatusEnum.Failed,
                  JobStatusEnum.Interrupted,
                  JobStatusEnum.Stopped,
                ].includes(updatedJobStatus)
              ) {
                removeJobId(updatedJobId);
                downloadHandler(updatedJob);
                if (updatedJobStatus === JobStatusEnum.Complete) {
                  toast({ content: t('get-qr-codes-downloaded') });
                } else {
                  toast({ content: t('get-qr-codes-error') });
                }
              }
            }
          });
        },
        onError: (error) => {
          console.error(error);
          jobIds.forEach((jid) => {
            removeJobId(jid);
          });
          toast({ content: t('get-qr-codes-error') });
        },
      });
    }
  }, [jobIds, fetchJobStatus, t]);

  useEffect(() => {
    if (
      !_isEqual(jobIds, prevJobIds) &&
      prevJobIds?.length &&
      jobIds.length === 0
    ) {
      stopPolling();
    }
  }, [jobIds, prevJobIds, stopPolling]);

  return { setJobId, jobs };
};

export default useDownloadQrCodeWatcher;
