enum TasksFilterFieldNames {
  Asset = 'asset',
  Filter = 'filter',
  FormCategory = 'formCategory',
  Period = 'period',
  Priority = 'priority',
  Site = 'site',
  SiteArea = 'siteArea',
  SiteAreaIds = 'siteAreaIds',
}

export default TasksFilterFieldNames;
