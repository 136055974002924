import _get from 'lodash/get';
import { Nomenclature, useAssetNomenclatureQuery } from 'graphql-common';
import { ApolloError } from '@apollo/client/errors';

type Args = {
  id?: string;
  mainAssetId?: string;
  skip?: boolean;
};

export type UseAssetNomenclatureQueryHookResult = {
  data: Nomenclature;
  loading: boolean;
  error: ApolloError | undefined;
};

export default function useAssetNomenclatureQueryHook(
  args: Args,
): UseAssetNomenclatureQueryHookResult {
  const { id = '', mainAssetId = '', skip = true } = args;
  const query = useAssetNomenclatureQuery({
    skip,
    variables: { id, mainAssetId },
    fetchPolicy: 'network-only',
  });
  const data = _get(query, 'data.data') as Nomenclature;
  const loading = _get(query, 'loading');
  const error = _get(query, 'error');

  return {
    data,
    loading,
    error,
  };
}
