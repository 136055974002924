import { useState } from 'react';
import { Attachment, InterventionAction } from 'graphql-common';

export interface UseModalManagementResult {
  closeAllModals: () => void;
  closeAttachmentPreviewModal: () => void;
  closeCategoryFormModal: () => void;
  closeInterventionActionAssigneesModal: () => void;
  closeInterventionActionCloseModal: () => void;
  closeInterventionActionDestroyModal: () => void;
  closeInterventionAssigneesModal: () => void;
  closeInterventionCloseModal: () => void;
  isAttachmentPreviewModalOpened: Attachment | null;
  isCategoryFormModalOpened: boolean;
  isInterventionActionAssigneesModalOpened: InterventionAction | null;
  isInterventionActionCloseModalOpened: InterventionAction | null;
  isInterventionActionDestroyModalOpened: InterventionAction | null;
  isInterventionAssigneesModalOpened: boolean;
  isInterventionCloseModalOpened: boolean;
  isInterventionDestroyModalOpened: boolean;
  openAttachmentPreviewModal: (v: Attachment | null) => void;
  openCategoryFormModal: () => void;
  openInterventionActionAssigneesModal: (v: InterventionAction | null) => void;
  openInterventionActionCloseModal: (v: InterventionAction | null) => void;
  openInterventionActionDestroyModal: (v: InterventionAction | null) => void;
  openInterventionAssigneesModal: () => void;
  openInterventionCloseModal: () => void;
  openInterventionDestroyModal: () => void;
}

export default function useModalManagement(): UseModalManagementResult {
  const [isInterventionDestroyModalOpened, setInterventionDestroyModalOpened] =
    useState(false);
  const openInterventionDestroyModal = () =>
    setInterventionDestroyModalOpened(true);
  const closeInterventionDestroyModal = () =>
    setInterventionDestroyModalOpened(false);

  const [
    isInterventionActionDestroyModalOpened,
    setInterventionActionDestroyModalOpened,
  ] = useState<InterventionAction | null>(null);
  const openInterventionActionDestroyModal = (v: InterventionAction | null) =>
    setInterventionActionDestroyModalOpened(v);
  const closeInterventionActionDestroyModal = () =>
    setInterventionActionDestroyModalOpened(null);

  const [isInterventionCloseModalOpened, setInterventionCloseModalOpened] =
    useState(false);
  const openInterventionCloseModal = () =>
    setInterventionCloseModalOpened(true);
  const closeInterventionCloseModal = () =>
    setInterventionCloseModalOpened(false);

  const [
    isInterventionActionCloseModalOpened,
    setInterventionActionCloseModalOpened,
  ] = useState<InterventionAction | null>(null);
  const openInterventionActionCloseModal = (v: InterventionAction | null) =>
    setInterventionActionCloseModalOpened(v);
  const closeInterventionActionCloseModal = () =>
    setInterventionActionCloseModalOpened(null);

  const [isAttachmentPreviewModalOpened, setAttachmentPreviewModalOpened] =
    useState<Attachment | null>(null);
  const openAttachmentPreviewModal = (v: Attachment | null) =>
    setAttachmentPreviewModalOpened(v);
  const closeAttachmentPreviewModal = () =>
    setAttachmentPreviewModalOpened(null);

  const [isCategoryFormModalOpened, setCategoryFormModalOpened] =
    useState(false);
  const openCategoryFormModal = () => setCategoryFormModalOpened(true);
  const closeCategoryFormModal = () => setCategoryFormModalOpened(false);

  const [
    isInterventionAssigneesModalOpened,
    setInterventionAssigneesModalOpened,
  ] = useState(false);
  const openInterventionAssigneesModal = () =>
    setInterventionAssigneesModalOpened(true);
  const closeInterventionAssigneesModal = () =>
    setInterventionAssigneesModalOpened(false);

  const [
    isInterventionActionAssigneesModalOpened,
    setInterventionActionAssigneesModalOpened,
  ] = useState<InterventionAction | null>(null);
  const openInterventionActionAssigneesModal = (v: InterventionAction | null) =>
    setInterventionActionAssigneesModalOpened(v);
  const closeInterventionActionAssigneesModal = () =>
    setInterventionActionAssigneesModalOpened(null);

  const closeAllModals = () => {
    closeAttachmentPreviewModal();
    closeCategoryFormModal();
    closeInterventionActionAssigneesModal();
    closeInterventionActionCloseModal();
    closeInterventionActionDestroyModal();
    closeInterventionAssigneesModal();
    closeInterventionCloseModal();
    closeInterventionDestroyModal();
  };

  return {
    closeAllModals,
    closeAttachmentPreviewModal,
    closeCategoryFormModal,
    closeInterventionActionAssigneesModal,
    closeInterventionActionCloseModal,
    closeInterventionActionDestroyModal,
    closeInterventionAssigneesModal,
    closeInterventionCloseModal,
    isAttachmentPreviewModalOpened,
    isCategoryFormModalOpened,
    isInterventionActionAssigneesModalOpened,
    isInterventionActionCloseModalOpened,
    isInterventionActionDestroyModalOpened,
    isInterventionAssigneesModalOpened,
    isInterventionCloseModalOpened,
    isInterventionDestroyModalOpened,
    openAttachmentPreviewModal,
    openCategoryFormModal,
    openInterventionActionAssigneesModal,
    openInterventionActionCloseModal,
    openInterventionActionDestroyModal,
    openInterventionAssigneesModal,
    openInterventionCloseModal,
    openInterventionDestroyModal,
  };
}
