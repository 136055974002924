import { RoleEnum } from 'graphql-common';

export function getRolesOptions() {
  return [
    {
      labelKey: 'user',
      value: RoleEnum.User,
    },
    {
      labelKey: 'full-admin',
      value: RoleEnum.FullAdmin,
    },
  ];
}

export function getRoleOption(role: RoleEnum) {
  return getRolesOptions().find(({ value }) => value === role);
}
