import React from 'react';
import _get from 'lodash/get';
import classNames from 'classnames';
import {
  components as DefaultSelectComponents,
  OptionProps,
} from 'react-select';
import styles from '../Select.module.scss';

function CountryOption({ children, ...restProps }: OptionProps): JSX.Element {
  const { isSelected, isMulti } = restProps;
  const value = _get(restProps, 'value', '');
  return (
    <DefaultSelectComponents.Option
      {...restProps}
      className={classNames(styles.countryOption, {
        active: isSelected,
        [styles.optionWithCheckbox]: isMulti,
      })}
    >
      <span className={classNames(styles.flag, styles[value.toLowerCase()])} />
      {children}
    </DefaultSelectComponents.Option>
  );
}

export default CountryOption;
