import React from 'react';
import { useTranslation } from 'react-i18next';
import { GraphQLErrors } from '@apollo/client/errors';
import { Counter } from 'graphql-common';
import { FieldItem, Values } from '@lib/interfaces/form';
import Form, {
  ActionsProps,
} from '@lib/components/ReactHookForm/FormContainer';
import replaceNullsWithUndefined from '@lib/utils/replaceNullsWithUndefined';
import Modal from '@lib/components/Modal/Modal';
import { GetLoadOptions } from '@lib/components/Select/types';
import { SubmitAction } from '@lib/enums/form';
import assetCounterFieldNames from '@lib/enums/fieldNames/assetCounterFieldNames';
import { FormRefType } from '@lib/components/ReactHookForm/types';
import getDefaultCounterFormValuesFromQueryData from './utils/getDefaultCounterFormValuesFromQueryData';
import AssetCounterModalFormActions from './AssetCounterModalFormActions';
import getFormFields from './utils/getFormFields';

export interface FormProps {
  assetCounter?: Counter;
  getAssetsLoadOptions: GetLoadOptions;
  graphQLErrors?: GraphQLErrors;
  isEditForm?: boolean;
  isOpen: boolean;
  loading: boolean;
  onCancel: () => void;
  onClose: () => void;
  onSubmit: (values: Values, isEditForm?: boolean) => void;
  submitNewItemRef: React.MutableRefObject<SubmitAction | undefined>;
  formRef: FormRefType;
}

const defaultValues = {
  [assetCounterFieldNames.Name]: '',
  [assetCounterFieldNames.Code]: '',
  [assetCounterFieldNames.MeasureType]: null,
  [assetCounterFieldNames.MeasureUnit]: null,
  [assetCounterFieldNames.ProhibitLowerIndexThanPrevious]: null,
};

export default function AssetCounterModalForm(props: FormProps) {
  const {
    assetCounter,
    getAssetsLoadOptions,
    graphQLErrors,
    isEditForm,
    isOpen,
    loading,
    onCancel,
    onClose,
    onSubmit,
    submitNewItemRef,
    formRef,
  } = props;
  const { t } = useTranslation();

  const fields: FieldItem[] = getFormFields({
    getAssetsLoadOptions,
    isEditForm,
    t,
  });

  const defaultFormValues = assetCounter
    ? replaceNullsWithUndefined(
        getDefaultCounterFormValuesFromQueryData(assetCounter, t),
      )
    : defaultValues;

  const actionsComponent: (props: ActionsProps) => React.JSX.Element = (
    actionsComponentProps,
  ) => (
    <AssetCounterModalFormActions
      {...actionsComponentProps}
      isEditForm={isEditForm}
      submitNewItemRef={submitNewItemRef}
      onCancel={onCancel}
    />
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t(isEditForm ? 'edit-counter' : 'add-counter')}
      titleAlign="left"
    >
      <Form
        actions={actionsComponent}
        actionsComponentProps={{ onCancel }}
        defaultValues={defaultFormValues}
        fields={fields}
        formId="counter"
        formRef={formRef}
        graphQLErrors={graphQLErrors}
        loading={loading}
        onSubmit={(values) => onSubmit(values, isEditForm)}
      />
    </Modal>
  );
}
