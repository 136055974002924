import React from 'react';
import Chip, { ChipTypes } from '@lib/components/Chip/Chip';
import { FormTemplateVersionStatusEnum } from 'graphql-common';
import { useTranslation } from 'react-i18next';

type Props = {
  status: FormTemplateVersionStatusEnum;
};

export default function FormTemplateStatusChip(props: Props) {
  const { status } = props;
  const { t } = useTranslation();

  let label = t('active');
  let type = ChipTypes.chipGreen;
  if (status === FormTemplateVersionStatusEnum.Archived) {
    label = t('archived');
    type = ChipTypes.chipDisabled;
  } else if (status === FormTemplateVersionStatusEnum.Unused) {
    label = t('unused');
    type = ChipTypes.chipWhite;
  }
  return <Chip type={type} label={label} leftIcon="circle" />;
}
