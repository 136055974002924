import React, { Suspense } from 'react';
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { ApolloProvider } from '@apollo/client';
import ApolloClient from 'graphql-common/ApolloClient';
import DashboardLayout from 'layouts/DashboardLayout/DashboardLayout';
import { APP_URLS } from 'constants/urls';
import { SiteViewMode } from 'components/Site/Site';
import { BreadCrumbsProvider } from '@lib/components/BreadCrumbs/BreadCrumbsContext';
import CircleLoader from '@lib/components/CircleLoader/CircleLoader';
import AntdConfigProvider from '@lib/components/Antd/ConfigProvider';
import { ErrorPageStatusEnum } from '@lib/components/ErrorPage/enums';
import { UserViewMode } from 'routes/Users/enums';
import Dashboard from './Dashboard/DashboardContainer';
import { FormTemplateMode } from './FormTemplate/enums';
import FormTemplates from './FormTemplates/FormTemplatesContainer';
import FormTemplateBuilderFormContainer from './FormTemplate/FormTemplateBuilderFormContainer';
import FormTemplateCategories from './FormTemplateCategories/FormTemplateCategoriesContainer';
import FormTemplateViewContainer from './FormTemplate/FormTemplateViewContainer';
import { InterventionViewMode } from './Intervention/enums';
import Profile from './Profile/ProfileContainer';
import Tasks from './Tasks/TasksContainer';
import TasksApprovals from './TasksApprovals/TasksApprovalsContainer';
import TaskView from './Task/TaskViewContainer';
import Users from './Users/UsersContainer';
import User from './Users/UserContainer';
import Sites from './Sites/SitesContainer';
import Site from './Sites/SiteContainer';
import SiteAreas from './Sites/SiteAreasContainer';
import SiteUsers from './Sites/SiteUsersContainer';
import AssetDocumentation from './Assets/AssetDocumentation/AssetDocumentation';
import Assets from './Assets/AssetList/AssetListContainer';
import AssetView from './Assets/Asset/AssetContainer';
import AssetForm from './Assets/Asset/AssetFormContainer';
import { AssetViewMode } from './Assets/Asset/enums';
import AssetCounters from './Assets/AssetCounters/AssetCountersContainer';
import AssetCategories from './Assets/AssetCategories/AssetCategoriesContainer';
import InterventionCategories from './InterventionCategories/InterventionCategoriesContainer';
import Intervention from './Intervention/InterventionContainer';
import InterventionAction from './Intervention/InterventionActionContainer';
import TaskCreate from './Task/TaskCreateContainer';
import TaskEdit from './Task/TaskEditContainer';
import TaskFormView from './Task/TaskFormViewContainer';
import TaskReportingView from './Task/TaskReportingViewContainer';
import TaskInterventions from './Task/TaskInterventionsContainer';
import Interventions from './Interventions/InterventionsContainer';
import Teams from './Teams/TeamsContainer';
import Team from './Teams/TeamContainer';
import { TeamViewMode } from './Teams/enums';
import ErrorPage from './ErrorPage/ErrorPage';
import NotFoundPage from './NotFoundPage/NotFoundPage';
import ProtectedRoute from './ProtectedRoute';

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter([
  {
    path: '/',
    element: <ProtectedRoute />,
    children: [
      {
        path: '/',
        element: <DashboardLayout />,
        children: [
          {
            path: APP_URLS.dashboard.overview.path,
            element: <Dashboard />,
          },
          {
            path: APP_URLS.dashboard.timeline.path,
            element: <Dashboard />,
          },
          {
            path: APP_URLS.dashboard.profile.path,
            element: <Profile />,
          },
          {
            path: APP_URLS.dashboard.users.index.path,
            element: <Users />,
          },
          {
            path: APP_URLS.dashboard.users.view.path,
            element: <User viewMode={UserViewMode.View} />,
          },
          {
            path: APP_URLS.dashboard.users.viewAccess.path,
            element: <User viewMode={UserViewMode.ViewAccess} />,
          },
          {
            path: APP_URLS.dashboard.users.editAccess.path,
            element: <User viewMode={UserViewMode.EditAccess} />,
          },
          {
            path: APP_URLS.dashboard.teams.index.path,
            element: <Teams />,
          },
          {
            path: APP_URLS.dashboard.teams.add.path,
            element: <Team viewMode={TeamViewMode.Add} />,
          },
          {
            path: APP_URLS.dashboard.teams.edit.path,
            element: <Team viewMode={TeamViewMode.Edit} />,
          },
          {
            path: APP_URLS.dashboard.teams.view.path,
            element: <Team viewMode={TeamViewMode.ViewDetails} />,
          },
          {
            path: APP_URLS.dashboard.teams.members.path,
            element: <Team viewMode={TeamViewMode.ViewMembers} />,
          },
          {
            path: APP_URLS.dashboard.sites.index.path,
            element: <Sites />,
          },
          {
            path: APP_URLS.dashboard.sites.add.path,
            element: <Site viewMode={SiteViewMode.add} />,
          },
          {
            path: APP_URLS.dashboard.sites.edit.getPathWithQuery(),
            element: <Site viewMode={SiteViewMode.edit} />,
          },
          {
            path: APP_URLS.dashboard.sites.view.getPathWithQuery(),
            element: <Site viewMode={SiteViewMode.view} />,
          },
          {
            path: APP_URLS.dashboard.sites.viewCodeOfConduct.path,
            element: <Site viewMode={SiteViewMode.viewCodeOfConduct} />,
          },
          {
            path: APP_URLS.dashboard.sites.editCodeOfConduct.path,
            element: <Site viewMode={SiteViewMode.editCodeOfConduct} />,
          },
          {
            path: APP_URLS.dashboard.sites.areas.getPathWithQuery(),
            element: <SiteAreas />,
          },
          {
            path: APP_URLS.dashboard.sites.users.getPathWithQuery(),
            element: <SiteUsers />,
          },
          {
            path: APP_URLS.dashboard.formTemplates.index.path,
            element: <FormTemplates />,
          },
          {
            path: APP_URLS.dashboard.formTemplates.archived.path,
            element: <FormTemplates />,
          },
          {
            path: APP_URLS.dashboard.formTemplates.categories.path,
            element: <FormTemplateCategories />,
          },
          {
            path: APP_URLS.dashboard.formTemplates.add.path,
            element: (
              <FormTemplateBuilderFormContainer
                viewMode={FormTemplateMode.Add}
              />
            ),
          },
          {
            path: APP_URLS.dashboard.formTemplates.edit.path,
            element: (
              <FormTemplateBuilderFormContainer
                viewMode={FormTemplateMode.Edit}
              />
            ),
          },
          {
            path: APP_URLS.dashboard.formTemplates.editVersion.path,
            element: (
              <FormTemplateBuilderFormContainer
                viewMode={FormTemplateMode.EditVersion}
                key={FormTemplateMode.EditVersion}
              />
            ),
          },
          {
            path: APP_URLS.dashboard.formTemplates.viewVersion.path,
            element: (
              <FormTemplateViewContainer viewMode={FormTemplateMode.View} />
            ),
          },
          {
            path: APP_URLS.dashboard.formTemplates.viewLinkedTasks.path,
            element: (
              <FormTemplateViewContainer
                viewMode={FormTemplateMode.ViewLinkedTasks}
              />
            ),
          },
          {
            path: APP_URLS.dashboard.formTemplates.duplicate.path,
            element: (
              <FormTemplateBuilderFormContainer
                viewMode={FormTemplateMode.Duplicate}
              />
            ),
          },
          {
            path: APP_URLS.dashboard.assets.index.path,
            element: <Assets />,
          },
          {
            path: APP_URLS.dashboard.assets.categories.path,
            element: <AssetCategories />,
          },
          {
            path: APP_URLS.dashboard.interventions.index.path,
            element: <Interventions />,
          },
          {
            path: APP_URLS.dashboard.interventions.add.path,
            element: <Intervention viewMode={InterventionViewMode.Add} />,
          },
          {
            path: APP_URLS.dashboard.interventions.edit.path,
            element: <Intervention viewMode={InterventionViewMode.Edit} />,
          },
          {
            path: APP_URLS.dashboard.interventions.view.path,
            element: <Intervention viewMode={InterventionViewMode.View} />,
          },
          {
            path: APP_URLS.dashboard.interventions.viewActions.path,
            element: (
              <Intervention viewMode={InterventionViewMode.ViewActions} />
            ),
          },
          {
            path: APP_URLS.dashboard.interventions.actionAdd.path,
            element: (
              <InterventionAction viewMode={InterventionViewMode.AddAction} />
            ),
          },
          {
            path: APP_URLS.dashboard.interventions.actionEdit.path,
            element: (
              <InterventionAction viewMode={InterventionViewMode.EditAction} />
            ),
          },
          {
            path: APP_URLS.dashboard.interventions.actionView.path,
            element: (
              <InterventionAction viewMode={InterventionViewMode.ViewAction} />
            ),
          },
          {
            path: APP_URLS.dashboard.interventions.categories.path,
            element: <InterventionCategories />,
          },
          {
            path: APP_URLS.dashboard.assets.add.path,
            element: <AssetForm viewMode={AssetViewMode.Add} />,
          },
          {
            path: APP_URLS.dashboard.assets.edit.getPathWithQuery(),
            element: <AssetForm viewMode={AssetViewMode.Edit} />,
          },
          {
            path: APP_URLS.dashboard.assets.view.getPathWithQuery(),
            element: <AssetView viewMode={AssetViewMode.View} />,
          },
          {
            path: APP_URLS.dashboard.assets.nomenclature.path,
            element: <AssetView viewMode={AssetViewMode.Nomenclatures} />,
          },
          {
            path: APP_URLS.dashboard.assets.counter.path,
            element: <AssetView viewMode={AssetViewMode.Counters} />,
          },
          {
            path: APP_URLS.dashboard.assets.documentation.path,
            element: <AssetView viewMode={AssetViewMode.Documentation} />,
          },
          {
            path: APP_URLS.dashboard.assets.interventions.path,
            element: <AssetView viewMode={AssetViewMode.Interventions} />,
          },
          {
            path: APP_URLS.dashboard.assets.counters.path,
            element: <AssetCounters />,
          },
          {
            path: APP_URLS.dashboard.assets.document.path,
            element: <AssetDocumentation />,
          },
          {
            path: APP_URLS.dashboard.tasks.index.path,
            element: <Tasks />,
          },
          {
            path: APP_URLS.dashboard.tasks.approvals.path,
            element: <TasksApprovals />,
          },
          {
            path: APP_URLS.dashboard.tasks.add.path,
            element: <TaskCreate />,
          },
          {
            path: APP_URLS.dashboard.tasks.edit.getPathWithQuery(),
            element: <TaskEdit />,
          },
          {
            path: APP_URLS.dashboard.tasks.duplicate.getPathWithQuery(),
            element: <TaskCreate isDuplicate />,
          },
          {
            path: APP_URLS.dashboard.tasks.view.getPathWithQuery(),
            element: <TaskView />,
          },
          {
            path: APP_URLS.dashboard.tasks.formView.getPathWithQuery(),
            element: <TaskFormView />,
          },
          {
            path: APP_URLS.dashboard.tasks.reporting.getPathWithQuery(),
            element: <TaskReportingView />,
          },
          {
            path: APP_URLS.dashboard.tasks.interventions.path,
            element: <TaskInterventions />,
          },
          {
            path: APP_URLS.dashboard.unauthorized.path,
            element: <NotFoundPage status={ErrorPageStatusEnum.Status401} />,
          },
          {
            path: APP_URLS.dashboard.accessDenied.path,
            element: <ErrorPage status={ErrorPageStatusEnum.AccessDenied} />,
          },
          {
            path: APP_URLS.dashboard.index.path,
            index: true,
            element: <Navigate to={APP_URLS.dashboard.profile.path} replace />,
          },
          {
            path: '*',
            element: <NotFoundPage />,
          },
        ],
      },
    ],
  },
]);

function App(): React.ReactElement {
  return (
    <ApolloProvider client={ApolloClient}>
      <AntdConfigProvider>
        <BreadCrumbsProvider>
          <Suspense fallback={<CircleLoader />}>
            <RouterProvider router={router} />
          </Suspense>
        </BreadCrumbsProvider>
      </AntdConfigProvider>
    </ApolloProvider>
  );
}

export default App;
