import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import MaterialIcon from '@lib/components/MaterialIcon/MaterialIcon';
import Tooltip from '@lib/components/Tooltip/Tooltip';
import styles from './ClipboardCopy.module.scss';

interface Props {
  children: React.ReactNode;
  copyText: string;
}

function ClipboardCopy(props: Props) {
  const { children, copyText } = props;
  const { t } = useTranslation();
  const [isCopied, setIsCopied] = useState(false);

  // This is the function we wrote earlier
  async function copyTextToClipboard(text: string) {
    if ('clipboard' in navigator) {
      const clipboard = navigator.clipboard as Clipboard;
      return clipboard.writeText(text);
    }
    return document.execCommand('copy', true, text);
  }

  // onClick handler function for the copy button
  const handleCopyClick = () => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(copyText)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1000);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
      });
  };

  return (
    <div className={styles.root}>
      {children}
      <input type="text" value={copyText} readOnly />
      <div className={styles.iconWrap}>
        {isCopied ? (
          <Tooltip body={t('copied')}>
            <MaterialIcon icon="check" className={styles.icon} />
          </Tooltip>
        ) : (
          <Tooltip body={t('copy')}>
            <MaterialIcon
              icon="content_copy"
              onClick={handleCopyClick}
              className={styles.icon}
            />
          </Tooltip>
        )}
      </div>
    </div>
  );
}

export default ClipboardCopy;
