import { LazyQueryExecFunction } from '@apollo/client';
import {
  FormCategoriesSearchListQuery,
  FormCategoriesSearchListQueryVariables,
  FormCategory,
  FormCategoryFilterInputObject,
  SortDirectionEnum,
} from 'graphql-common';
import { DEFAULT_SEARCH_LIST_VARIABLES } from '@lib/enums/urls';
import getLoadOptionsResult from '@lib/utils/getLoadOptionsResult';
import createSearchFilters from '@lib/utils/createSearchFilters';
import { LoadOptionsParams } from '@lib/components/Select/types';

type FetchLazyQuery = LazyQueryExecFunction<FormCategoriesSearchListQuery, any>;

const fetchSearchList = async (
  searchQuery: string,
  fetchLazyQuery: FetchLazyQuery,
  page: number,
) => {
  const options: { variables: FormCategoriesSearchListQueryVariables } = {
    variables: {
      ...DEFAULT_SEARCH_LIST_VARIABLES,
      page,
      sorts: { name: SortDirectionEnum.Asc },
    },
  };
  const filters: FormCategoryFilterInputObject[] = [];
  if (searchQuery) {
    filters.push(...createSearchFilters(searchQuery, [{ fieldName: 'name' }]));
  }
  if (filters.length) {
    options.variables.filters = filters;
  }
  const { data } = await fetchLazyQuery(options);
  return getLoadOptionsResult(
    data,
    (dataItem: FormCategory) => ({
      value: dataItem.id,
      label: dataItem.name,
      color: dataItem.color,
    }),
    searchQuery,
  );
};

export const getFormCategoriesLoadOptions =
  (fetchLazyQuery: FetchLazyQuery) =>
  (searchQuery: string, loadedOptions: unknown, params: LoadOptionsParams) =>
    fetchSearchList(searchQuery, fetchLazyQuery, params?.page || 1);
