import {
  History,
  HistorySourceableTypeEnum,
  InterventionActionHistoriesQueryVariables,
  useInterventionActionHistoriesQuery,
} from 'graphql-common';
import _get from 'lodash/get';
import { GetUrlParams } from '@lib/enums/urls';
import { ApolloError } from '@apollo/client/errors';
import { ListQueryHookProps } from './types';

export type UseAssetInterventionsQueryHookResult = {
  collection: History[];
  error: ApolloError | undefined;
  firstLoading: boolean;
  loading: boolean;
  refetch: () => Promise<unknown>;
  totalCount: number;
  totalPages: number;
  currentPage: number;
};

type Args = ListQueryHookProps & {
  interventionActionId: string;
};

export default function useInterventionActionHistoriesQueryHook({
  fetchPolicy = 'network-only',
  paginationParams,
  orderingParams,
  skip,
  usePrevious = true,
  interventionActionId,
}: Args): UseAssetInterventionsQueryHookResult {
  const page = _get(paginationParams, GetUrlParams.Page);
  const perPage = _get(paginationParams, GetUrlParams.PerPage);
  const orderingField = _get(orderingParams, GetUrlParams.OrderingField);
  const orderingDirection = _get(
    orderingParams,
    GetUrlParams.OrderingDirection,
  );
  const queryVariables: InterventionActionHistoriesQueryVariables = {
    page: Number(page),
    limit: Number(perPage),
    sourceableId: interventionActionId,
    sourceableType: HistorySourceableTypeEnum.InterventionAction,
  };
  if (orderingField && orderingDirection) {
    queryVariables.sorts = {
      [orderingField]: orderingDirection,
    };
  }
  const query = useInterventionActionHistoriesQuery({
    fetchPolicy,
    variables: queryVariables,
    skip,
  });
  const { loading, data, previousData, refetch, error } = query;
  const queryData = usePrevious ? data || previousData : data;
  const firstLoading = loading && previousData === undefined;
  const collection = _get(queryData, 'data.collection', []) as History[];
  const metadata = _get(queryData, 'data.metadata');
  const totalCount = _get(metadata, 'totalCount', 0);
  const totalPages = _get(metadata, 'totalPages', 1);
  const currentPage = _get(metadata, 'currentPage', 1);

  return {
    collection,
    error,
    firstLoading,
    loading,
    refetch,
    totalCount,
    totalPages,
    currentPage,
  };
}
