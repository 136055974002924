import React, { useRef } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import BreadCrumbs from '@lib/components/BreadCrumbs/BreadCrumbs';
import { getBreadCrumbsDataByKey } from 'utils/breadcrumbs';
import { FormRef } from '@lib/components/ReactHookForm/FormContainer';
import FormTemplateBuilderForm from 'components/FormTemplateBuilder/FormTemplateBuilderForm/FormTemplateBuilderForm';
import useSelectedVersionOption from 'routes/FormTemplate/utils/useSelectedVersionOption';
import FormTemplateModals from 'routes/FormTemplate/FormTemplateModals';
import useSearchListQueries from 'routes/FormTemplate/utils/useSearchListQueries';
import useOutletContext, { ContextProps } from 'hooks/useOutletContext';
import { ComponentEnum, LevelEnum } from 'graphql-common';
import getFormTemplateFormFields from './utils/getFormTemplateFormFields';
import useModalManagement from './utils/useModalManagement';
import useActions from './utils/useActions';
import { FormTemplateMode } from './enums';

interface Props {
  viewMode: FormTemplateMode;
}

export default function FormTemplateBuilderFormContainer(props: Props) {
  const { viewMode } = props;
  const formRef = useRef<FormRef>();
  const { id = '', versionId = '' } = useParams();
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const { getUserAccessByComponent }: ContextProps = useOutletContext();
  const isCategoriesViewOnly =
    getUserAccessByComponent(ComponentEnum.FormCategoriesManagement) ===
    LevelEnum.Read;

  const useModalManagementResult = useModalManagement();
  const {
    closeCategoryFormModal,
    isCategoryFormModalOpened,
    openCategoryFormModal,
  } = useModalManagementResult;

  const useActionsResult = useActions({
    useModalManagementResult,
    formRef,
    id,
    versionId,
    viewMode,
  });
  const {
    formCategoryCreateMutationError,
    formCategoryCreateMutationLoading,
    formTemplateCreateMutationLoading,
    formTemplateData,
    formTemplateError,
    formTemplateGraphQLErrors,
    formTemplateLoading,
    formTemplateUpdateMutationLoading,
    formTemplateVersionData,
    onCancelForm,
    onFormCategoryCreate,
    onSubmitForm,
    onVersionFieldChangeCallBack,
    onViewHandler,
  } = useActionsResult;

  const selectedVersionOption = useSelectedVersionOption({
    formTemplateVersionData,
    t,
    versionId,
    viewMode,
  });

  const { getFormCategoriesLoadOptions, getTasksLoadOptions } =
    useSearchListQueries({ id });

  const formFields = getFormTemplateFormFields({
    formTemplateId: id,
    getFormCategoriesLoadOptions,
    getTasksLoadOptions,
    isCategoriesViewOnly,
    onVersionFieldChangeCallBack,
    openCategoryFormModal,
    selectedVersionOption,
    t,
    viewMode,
  });

  const breadCrumbsData = getBreadCrumbsDataByKey({
    entity: {
      versionId: formTemplateData.versionId || versionId,
      id: formTemplateData.id || id,
      name: formTemplateData.name,
    },
    pathname,
    t,
  });

  return (
    <>
      <BreadCrumbs data={breadCrumbsData} />
      <FormTemplateBuilderForm
        closeCategoryFormModal={closeCategoryFormModal}
        formCategoryCreateMutationError={formCategoryCreateMutationError}
        formCategoryCreateMutationLoading={formCategoryCreateMutationLoading}
        formFields={formFields}
        formRef={formRef}
        formTemplateCreateMutationLoading={formTemplateCreateMutationLoading}
        formTemplateData={formTemplateData}
        formTemplateVersionData={formTemplateVersionData}
        formTemplateError={formTemplateError}
        formTemplateGraphQLErrors={formTemplateGraphQLErrors}
        formTemplateLoading={formTemplateLoading}
        formTemplateUpdateMutationLoading={formTemplateUpdateMutationLoading}
        isCategoryFormModalOpened={isCategoryFormModalOpened}
        onCancelForm={onCancelForm}
        onFormCategoryCreate={onFormCategoryCreate}
        onSubmitForm={onSubmitForm}
        onViewHandler={onViewHandler}
        viewMode={viewMode}
      />
      <FormTemplateModals
        useActionsResult={useActionsResult}
        useModalManagementResult={useModalManagementResult}
      />
    </>
  );
}
