import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import IconButton from '@lib/components/IconButton/IconButton';
import { BaseItem, TableAction } from '@lib/components/Table/types';
import Tooltip from '@lib/components/Tooltip/Tooltip';
import styles from './RowActionsCell.module.scss';

interface RowActionsCellProps<T extends BaseItem> {
  item: T;
  actions?: TableAction<T>[];
}

const getActionCellWidth = (actionsCount: number) => {
  const containerPadding = 36;
  const iconWidth = 32;
  const iconMargin = 4;
  return (
    containerPadding + iconWidth * actionsCount + iconMargin * actionsCount - 1
  );
};

function ActionIconButton<T extends BaseItem>(props: {
  action: TableAction<T>;
  item: T;
}) {
  const { action, item } = props;
  const {
    id,
    icon,
    type,
    onClick,
    predictorFnDisable,
    predictorFnDisableTooltipProps,
  } = action;
  const isDisabled = predictorFnDisable ? predictorFnDisable(item) : false;
  const disableTooltipProps = predictorFnDisableTooltipProps
    ? predictorFnDisableTooltipProps(item)
    : undefined;
  const content = (
    <IconButton
      disabled={isDisabled}
      icon={icon}
      key={id}
      onClick={() => onClick(item)}
      symbolsOutlined
      type={type}
    />
  );
  if (isDisabled && disableTooltipProps) {
    return <Tooltip {...disableTooltipProps}>{content}</Tooltip>;
  }
  return content;
}

function RowActionsCell<T extends BaseItem>({
  actions = [],
  item,
}: RowActionsCellProps<T>) {
  const filteredActions = actions.filter(({ predictorFnVisible }) =>
    predictorFnVisible ? predictorFnVisible(item) : true,
  );
  return (
    <td
      className={styles.cell}
      style={{
        width: getActionCellWidth(actions?.length ?? 0),
      }}
    >
      <div>
        {filteredActions.map((action) => (
          <ActionIconButton action={action} item={item} key={uuidv4()} />
        ))}
      </div>
    </td>
  );
}

export default RowActionsCell;
