import _get from 'lodash/get';
import { User, useWhoamiQuery } from 'graphql-common';
import { WatchQueryFetchPolicy } from '@apollo/client';
import useOutletContext, { ContextProps } from 'hooks/useOutletContext';

type Args = {
  fetchPolicy?: WatchQueryFetchPolicy;
  includeFullData?: boolean;
};

export default function useWhoamiQueryHook(args?: Args) {
  const { fetchPolicy, includeFullData = true } = args || {};
  const outletContext: ContextProps = useOutletContext();
  const onLogoutHandler = _get(outletContext, 'onLogoutHandler');
  const { data, error, refetch, loading, called, networkStatus } =
    useWhoamiQuery({
      variables: { includeFullData },
      fetchPolicy: fetchPolicy || 'cache-and-network',
      errorPolicy: 'all',
      notifyOnNetworkStatusChange: true,
      onError: (e) => {
        const graphQLErrors = _get(e, 'graphQLErrors', []);
        const authError = graphQLErrors.find(
          ({ extensions }) => extensions.code === 'AUTHENTICATION_ERROR',
        );
        if (onLogoutHandler && authError) onLogoutHandler();
      },
    });
  const currentUser = _get(data, 'whoami.resource') as User;
  const currentUserDeactivatedAt = currentUser?.deactivatedAt;
  const currentUserEmail = currentUser?.email;
  const currentUserId = currentUser?.id;
  const currentUserLang = currentUser?.lang;
  const currentUserUnconfirmedEmail = currentUser?.unconfirmedEmail;
  const currentUserUsername = currentUser?.username;
  const firstLoading = loading && data === undefined;
  return {
    called,
    currentUser,
    currentUserDeactivatedAt,
    currentUserEmail,
    currentUserId,
    currentUserLang,
    currentUserUnconfirmedEmail,
    currentUserUsername,
    data,
    error,
    firstLoading,
    loading,
    networkStatus,
    refetch,
  };
}
