import React, { forwardRef, memo } from 'react';
import { useTranslation } from 'react-i18next';
import Button, {
  ButtonSizes,
  ButtonTypes,
} from '@lib/components/Button/Button';
import FileUploaderUppyAwsS3, {
  AttachmentValue,
  UploaderView,
} from '@lib/components/FileUploaderUppyXHR/FileUploaderUppyXHR';
import { UppyImageAllowedFileTypes } from '@lib/enums/mimeTypes';
import Placeholder from '@lib/assets/images/preview-placeholder.svg';
import Image from '@lib/components/Image/Image';
import { materialSymbolsOutlined } from '@lib/enums/common';
import styles from './ImageUploader.module.scss';

export type ImageUploaderProps = {
  className?: string;
  onChange: (variables: unknown) => void;
  value?: AttachmentValue;
};

const ImageUploader = forwardRef(
  (props: ImageUploaderProps, ref: React.ForwardedRef<HTMLInputElement>) => {
    const { className, value, onChange } = props;
    const url = value?.urls?.large || value?.urls?.original || value?.url;
    const { t } = useTranslation();
    const uploadButton = ({ onClick }: { onClick: () => void }) => (
      <Button
        onClick={onClick}
        buttonText={url ? t('edit-image') : t('upload-image')}
        buttonType={ButtonTypes.primaryOutlined}
        leftIcon="edit"
        leftIconClassName={materialSymbolsOutlined}
        buttonSize={ButtonSizes.small}
      />
    );

    return (
      <div className={className}>
        <div className={styles.img}>
          {(url || Placeholder) && <Image src={url || Placeholder} />}
        </div>
        <div className={styles.body}>
          <FileUploaderUppyAwsS3
            allowedFileTypes={UppyImageAllowedFileTypes}
            openButton={uploadButton}
            ref={ref}
            onChange={onChange}
            type={UploaderView.modal}
            note={t('image-upload-validation-msg', { size: 5 })}
          />
          {url !== undefined && (
            <Button
              buttonText={t('delete')}
              buttonType={ButtonTypes.negativeOutlined}
              leftIcon="delete"
              leftIconClassName={materialSymbolsOutlined}
              buttonSize={ButtonSizes.small}
              onClick={() => onChange(null)}
            />
          )}
        </div>
      </div>
    );
  },
);

export default memo(ImageUploader);
