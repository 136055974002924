import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button, { ButtonTypes } from '@lib/components/Button/Button';
import ActionsRow from '@lib/components/ActionsRow/ActionsRow';
import styles from '@lib/assets/styles/Form.module.scss';

type Props = {
  isCreatePage: boolean;
};

function AssetDocumentationsFormActions({ isCreatePage }: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <ActionsRow
      className={styles.formActions}
      actions={[
        <Button
          onClick={() => navigate(-1)}
          buttonType={ButtonTypes.secondaryOutlined}
          buttonText={t('cancel')}
          leftIcon="close"
          fullWidth
        />,
        <Button
          buttonType={ButtonTypes.primaryFilled}
          buttonText={t(isCreatePage ? 'save' : 'save-changes')}
          leftIcon="check"
          type="submit"
          fullWidth
        />,
      ]}
    />
  );
}

export default AssetDocumentationsFormActions;
