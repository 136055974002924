import React, { forwardRef, memo } from 'react';
import { CheckboxProps } from '@lib/components/CheckBox/CheckBox';
import classNames from 'classnames';
import Typography from '@lib/components/Typography/Typography';
import styles from './CheckBoxAsToggle.module.scss';

const CheckBoxAsToggle = forwardRef(
  (props: CheckboxProps, ref: React.ForwardedRef<HTMLInputElement>) => {
    const { label = '', checked, onChange, disabled, name } = props;
    const className = classNames(styles.toggleRoot, {
      [styles.toggleRootChecked]: checked,
    });
    return (
      <>
        <input
          ref={ref}
          id={`${name}-toggle`}
          name={name}
          type="checkbox"
          checked={checked}
          onChange={onChange}
          disabled={disabled}
          className={styles.input}
        />
        <label
          htmlFor={`${name}-toggle`}
          className={classNames({
            [styles.toggleGrid]: label,
            [styles.toggleWrap]: !label,
          })}
        >
          <div className={className} />
          {!!label && (
            <Typography variant="body" strong>
              {label}
            </Typography>
          )}
        </label>
      </>
    );
  },
);

export default memo(CheckBoxAsToggle);
