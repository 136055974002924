import {
  SiteArea,
  SiteAreaFilterInputObject,
  SiteAreasQueryVariables,
  useSiteAreaIdsLazyQuery,
  useSiteAreasQuery,
} from 'graphql-common';
import _get from 'lodash/get';
import { GetUrlParams } from '@lib/enums/urls';
import createSearchFilters from '@lib/utils/createSearchFilters';
import { ListQueryHookProps } from './types';

export default function useSiteAreasQueryHook({
  paginationParams,
  searchQuery,
  siteId,
}: ListQueryHookProps & { siteId: string }) {
  const page = _get(paginationParams, GetUrlParams.Page);
  const perPage = _get(paginationParams, GetUrlParams.PerPage);
  const orderingField = _get(paginationParams, GetUrlParams.OrderingField);
  const orderingDirection = _get(
    paginationParams,
    GetUrlParams.OrderingDirection,
  );
  const siteAreasQueryVariables: SiteAreasQueryVariables = {
    siteId,
    page: Number(page),
    limit: Number(perPage),
  };
  if (orderingField && orderingDirection) {
    siteAreasQueryVariables.sorts = {
      [orderingField]: orderingDirection,
    };
  }
  const filters: SiteAreaFilterInputObject[] = [];
  if (searchQuery) {
    filters.push(
      ...createSearchFilters(searchQuery, [
        { fieldName: 'name' },
        { fieldName: 'code' },
      ]),
    );
  }
  if (filters.length) {
    siteAreasQueryVariables.filters = filters;
  }
  const siteAreasQuery = useSiteAreasQuery({
    fetchPolicy: 'cache-and-network',
    variables: siteAreasQueryVariables,
  });
  const [fetchSiteAreaIds] = useSiteAreaIdsLazyQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      filters: siteAreasQueryVariables.filters,
      siteId: siteAreasQueryVariables.siteId,
    },
  });
  const {
    loading: siteAreasLoading,
    data: siteAreas,
    previousData: previousSiteAreas,
    refetch: refetchSiteAreas,
  } = siteAreasQuery;
  const queryData = siteAreas || previousSiteAreas;
  const siteAreasFirstLoading =
    siteAreasLoading && previousSiteAreas === undefined;
  const collection = _get(queryData, 'data.collection', []) as SiteArea[];
  const metadata = _get(queryData, 'data.metadata');
  const totalCount = _get(metadata, 'totalCount', 0);
  const totalPages = _get(metadata, 'totalPages', 1);

  return {
    collection,
    fetchSiteAreaIds,
    previousSiteAreas,
    refetchSiteAreas,
    siteAreasFirstLoading,
    siteAreasLoading,
    totalCount,
    totalPages,
  };
}
