import { Values } from '@lib/interfaces/form';
import { FormCategory } from 'graphql-common';

export function getDefaultValuesFromQueryData(data: FormCategory): Values {
  const { id, name, color } = data;
  return {
    id,
    name,
    color: { value: color },
  };
}
