import { useState } from 'react';

export default function useSiteModalManagement() {
  const [isUserFormModalOpened, setUserFormModalOpened] = useState(false);
  const [isSiteDeleteModalOpened, setSiteDeleteModalOpened] = useState(false);
  const [isCodeOfConductDeleteModalOpened, setCodeOfConductDeleteModalOpened] =
    useState(false);

  const openUserFormModal = () => setUserFormModalOpened(true);
  const closeUserFormModal = () => setUserFormModalOpened(false);
  const openSiteDeleteModal = () => setSiteDeleteModalOpened(true);
  const closeSiteDeleteModal = () => setSiteDeleteModalOpened(false);
  const openCodeOfConductDeleteModal = () =>
    setCodeOfConductDeleteModalOpened(true);
  const closeCodeOfConductDeleteModal = () =>
    setCodeOfConductDeleteModalOpened(false);

  return {
    closeCodeOfConductDeleteModal,
    closeSiteDeleteModal,
    closeUserFormModal,
    isCodeOfConductDeleteModalOpened,
    isSiteDeleteModalOpened,
    isUserFormModalOpened,
    openCodeOfConductDeleteModal,
    openSiteDeleteModal,
    openUserFormModal,
  };
}
