import { Values } from '@lib/interfaces/form';

export default function replaceNullsWithUndefined(
  obj: any,
  skipNames?: string[],
): Values {
  if (typeof obj === 'object' && obj !== null) {
    const newObj = {}; // Create a new object

    Object.keys(obj).forEach((key) => {
      const skipValueReplace = (skipNames || [])?.includes(key);
      if (obj[key] === null && !skipValueReplace) {
        newObj[key] = undefined;
      } else if (
        typeof obj[key] === 'object' &&
        !Array.isArray(obj[key]) &&
        !skipValueReplace
      ) {
        // @ts-ignore // TODO: check array case
        newObj[key] = replaceNullsWithUndefined(obj[key]);
      } else {
        newObj[key] = obj[key]; // Copy other properties as-is
      }
    });

    return newObj; // Return the new object
  }

  return obj; // Return the original input if it's not an object
}
