enum InterventionActionFieldNames {
  Assignee = 'assignee',
  AttachmentsAttached = 'attachmentsAttached',
  Category = 'category',
  Description = 'description',
  DurationTime = 'durationTime',
  EstimatedDate = 'estimatedDate',
  EstimatedTime = 'estimatedTime',
  Id = 'id',
  LogisticTime = 'logisticTime',
  Site = 'site',
  Status = 'status',
}

export default InterventionActionFieldNames;
