import React from 'react';
import { useTranslation } from 'react-i18next';
import { Site, User } from 'graphql-common';
import Table from '@lib/components/Table/Table';
import WhiteBox from '@lib/components/WhiteBox/WhiteBox';
import DashboardContentMaxWidth from '@lib/layouts/DashboardLayout/DashboardContentMaxWidth/DashboardContentMaxWidth';
import CircleLoader from '@lib/components/CircleLoader/CircleLoader';
import PageBody from '@lib/layouts/DashboardLayout/PageBody/PageBody';
import { ApolloError } from '@apollo/client/errors';
import ErrorPage from '@lib/components/ErrorPage/ErrorPage';
import getStatusFromError from '@lib/utils/getStatusFromError';
import UserModalForm from 'components/UserModalForm/UserModalForm';
import SiteMenuBar from 'components/SiteMenuBar/SiteMenuBar';
import DashboardTitleHeader from '@lib/layouts/DashboardLayout/DashboardTitleHeader/DashboardTitleHeader';
import ViewOnlyChip from '@lib/components/ViewOnlyChip/ViewOnlyChip';
import getTableActions from './utils/getTableActions';
import getTableColumns from './utils/getTableColumns';

interface Props {
  closeUserFormModal: () => void;
  collection: User[];
  currentUserId: string;
  isUserFormModalOpened: boolean;
  isViewOnly: boolean;
  onSubmitUserFormCallback: () => void;
  openTeamPage: (id: string) => void;
  openUserEditModal: (item: User) => void;
  openUserViewPage: (item: User) => void;
  selectedUserId: string | null;
  siteData: Site;
  siteError?: ApolloError;
  siteId: string;
  siteLoading?: boolean;
  siteUsersCalled: boolean;
  siteUsersLoading: boolean;
  totalEntries: number;
}

function SiteUsers(props: Props) {
  const {
    closeUserFormModal,
    collection,
    currentUserId,
    isUserFormModalOpened,
    isViewOnly,
    openUserEditModal,
    onSubmitUserFormCallback,
    openUserViewPage,
    openTeamPage,
    selectedUserId,
    siteData,
    siteError,
    siteId,
    siteLoading,
    siteUsersCalled,
    siteUsersLoading,
    totalEntries,
  } = props;
  const { t } = useTranslation();
  const tableActions = getTableActions({
    currentUserId,
    openUserEditModal,
    openUserViewPage,
    isViewOnly,
  });
  const tableColumns = getTableColumns({ openTeamPage, t });

  if (siteLoading) {
    return <CircleLoader />;
  }

  if (siteError) {
    return (
      <ErrorPage
        status={getStatusFromError(siteError)}
        description={siteError.message}
      />
    );
  }

  return (
    <>
      <PageBody withoutHeader>
        <SiteMenuBar id={siteId} />
        <DashboardContentMaxWidth>
          <WhiteBox>
            <DashboardTitleHeader
              title={siteData?.name}
              actions={isViewOnly ? <ViewOnlyChip key="view" /> : undefined}
            />
            {siteUsersLoading && collection.length === 0 && !siteUsersCalled ? (
              <CircleLoader />
            ) : (
              <Table
                actions={tableActions}
                columns={tableColumns}
                counterText={t('team-member')}
                data={collection}
                id="team-members"
                totalEntries={totalEntries}
              />
            )}
          </WhiteBox>
        </DashboardContentMaxWidth>
      </PageBody>
      <UserModalForm
        isOpen={isUserFormModalOpened}
        onClose={closeUserFormModal}
        onSubmitUserFormCallback={onSubmitUserFormCallback}
        selectedUserId={selectedUserId}
        title={t('edit-team-member')}
      />
    </>
  );
}

export default SiteUsers;
