import React from 'react';
import { useTranslation } from 'react-i18next';
import FormItem from '@lib/components/ReactHookForm/FormItem';
import Typography from '@lib/components/Typography/Typography';
import CheckBoxAsToggle from '@lib/components/CheckBoxAsToggle/CheckBoxAsToggle';
import AssetCounterFieldNames from '@lib/enums/fieldNames/assetCounterFieldNames';
import Trans from '@lib/components/Trans/Trans';
import CheckBoxSingleBool from '@lib/components/CheckBoxSingleBool/CheckBoxSingleBool';
import styles from './ProhibitLowerIndexThanPreviousField.module.scss';

export default function ProhibitLowerIndexThanPreviousField() {
  const { t } = useTranslation();
  return (
    <div className={styles.root}>
      <FormItem
        name={AssetCounterFieldNames.ProhibitLowerIndexThanPrevious}
        element={CheckBoxSingleBool}
        componentProps={{
          CheckBoxComponent: CheckBoxAsToggle,
          options: [{ label: 'checked', value: 'checked' }],
          fullWidth: false,
        }}
        formItemClassName={styles.field}
      />
      <div>
        <Typography variant="body" strong>
          {t('prohibit-lower-index')}
        </Typography>
        <Typography variant="caption">
          <Trans i18nKey="prohibit-lower-index-text" />
        </Typography>
      </div>
    </div>
  );
}
