import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { NEW } from '@lib/enums/common';
import {
  DocumentationCreateInputObject,
  DocumentationUpdateInputObject,
  Tag,
} from 'graphql-common';
import { Values } from '@lib/interfaces/form';
import useAssetDocumentationQueryHook from 'utils/fetch/useAssetDocumentationQueryHook';
import useMutations from 'routes/Assets/AssetDocumentation/utils/useMutations';
import { APP_URLS } from 'constants/urls';
import toast from '@lib/components/Toast/Toast';
import { showGraphQLErrors } from '@lib/utils/graphQLErrors';
import { GraphQLErrors } from '@apollo/client/errors';
import { DEFAULT_LIST_PARAMS } from '@lib/enums/urls';
import { getBreadCrumbsDataByKey } from 'utils/breadcrumbs';
import _get from 'lodash/get';
import getPreparedUpdateFromValues from './getPreparedUpdateFromValues';
import getPreparedCreateFromValues from './getPreparedCreateFromValues';
import useSearchListQueries from './useSearchListQueries';
import { UseModalManagementResult } from './useModalManagement';

type Args = {
  useModalManagementResult: UseModalManagementResult;
};

export default function useActions({ useModalManagementResult }: Args) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { id: assetId = '', documentId = '' } = useParams();
  const isCreatePage = documentId === NEW;

  const useSearchListQueriesResult = useSearchListQueries();
  const { t } = useTranslation();
  const useMutationsResult = useMutations();

  const documentationQueryHookResult = useAssetDocumentationQueryHook({
    id: documentId,
    assetId,
    skip: isCreatePage,
  });

  const onBulkCreate = (values: DocumentationCreateInputObject[]) => {
    useMutationsResult.documentationBulkCreateMutation({
      variables: {
        assetId,
        attributes: values,
      },
      onCompleted: () => {
        toast({ content: t('create-asset-documentation-success') });
        navigate(
          APP_URLS.dashboard.assets.documentation.getDynamicPath({
            pathParts: { id: assetId },
            params: { ...DEFAULT_LIST_PARAMS },
          }),
        );
      },
    });
  };

  const onUpdate = (values: DocumentationUpdateInputObject) => {
    useMutationsResult.documentationUpdateMutation({
      variables: {
        assetId,
        id: documentId,
        attributes: values,
      },
      onCompleted: () => {
        toast({ content: t('update-asset-documentation-success') });
        navigate(
          APP_URLS.dashboard.assets.documentation.getDynamicPath({
            pathParts: { id: assetId },
            params: { ...DEFAULT_LIST_PARAMS },
          }),
        );
      },
    });
  };

  const onSubmit = (values: Values) => {
    if (isCreatePage) {
      onBulkCreate(getPreparedCreateFromValues(values));
    } else {
      onUpdate(
        getPreparedUpdateFromValues(
          values,
          documentationQueryHookResult.data as Values,
        ),
      );
    }
  };

  const onCreateTag = (
    name: string,
    callback: (data: Tag | null) => void,
    errorCallback: (errors: GraphQLErrors) => void,
  ) => {
    useMutationsResult.tagCreateMutation({
      variables: {
        attributes: {
          name,
        },
      },
      onCompleted: (res) => {
        toast({ content: t('create-tag-success') });
        callback(res?.data as Tag);
      },
      onError: (error) => {
        const reason = _get(error, [
          'graphQLErrors',
          0,
          'extensions',
          'details',
          0,
          'meta',
          'tag',
          'reason',
        ]);
        if (reason !== 'taken') {
          showGraphQLErrors(error, t);
        }
        errorCallback(error.graphQLErrors);
      },
    });
  };

  const onConfirmedDocumentationDestroy = () => {
    useModalManagementResult.closeAllModals();
    toast({
      content: t('destroy-asset-documentation-success'),
      closeCallback: () => {
        useMutationsResult.documentationBulkDestroyMutation({
          variables: { ids: [documentId], assetId },
          onCompleted: () => {
            navigate(
              APP_URLS.dashboard.assets.documentation.getDynamicPath({
                pathParts: { id: assetId },
                params: { ...DEFAULT_LIST_PARAMS },
              }),
            );
          },
        });
      },
    });
  };

  const loading =
    documentationQueryHookResult.loading ||
    useMutationsResult.documentationCreateMutationResult.loading ||
    useMutationsResult.documentationUpdateMutationResult.loading;

  const mutationError =
    useMutationsResult.documentationCreateMutationResult.error ||
    useMutationsResult.documentationUpdateMutationResult.error;

  const breadCrumbsData = getBreadCrumbsDataByKey({
    pathname,
    t,
    entity: documentationQueryHookResult.data || { assetId },
  });

  return {
    breadCrumbsData,
    mutationError,
    documentationQueryHookResult,
    isCreatePage,
    loading,
    onConfirmedDocumentationDestroy,
    onCreateTag,
    onSubmit,
    useSearchListQueriesResult,
  };
}
