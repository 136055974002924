import React from 'react';
import {
  components as DefaultSelectComponents,
  ValueContainerProps,
} from 'react-select';
import _get from 'lodash/get';
import styles from '../Select.module.scss';

export default function ButtonValueContainer(
  props: ValueContainerProps,
): JSX.Element {
  const { children, ...valueContainerProps } = props;
  const { selectProps } = valueContainerProps;
  const buttonText = _get(selectProps, 'buttonText');

  if (Array.isArray(children)) {
    return (
      <DefaultSelectComponents.ValueContainer
        {...valueContainerProps}
        className={styles.valueContainer}
      >
        <span>
          {buttonText}
          <span>{children[1]}</span>
        </span>
      </DefaultSelectComponents.ValueContainer>
    );
  }

  return (
    <DefaultSelectComponents.ValueContainer
      {...valueContainerProps}
      className={styles.valueContainer}
    >
      {children}
    </DefaultSelectComponents.ValueContainer>
  );
}
