import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TabBar from '@lib/components/TabBar/TabBar';
import { TabBarItem as TabBarItemType } from '@lib/components/TabBar/constants';
import { UseAssetQueryHookResult } from 'utils/fetch/useAssetWithCountersQueryHook';
import useOutletContext, { ContextProps } from 'hooks/useOutletContext';
import { ComponentEnum, LevelEnum } from 'graphql-common';
import getTabs from './utils/getTabs';
import styles from './AssetMenuBar.module.scss';

interface Props {
  counters: UseAssetQueryHookResult['counters'];
  id: string;
  onChange: () => void;
}

export default function AssetMenuBar(props: Props) {
  const { counters, id, onChange } = props;
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { getUserAccessByComponent, getUserAccessByCompany }: ContextProps =
    useOutletContext();
  const isInterventionsLocked =
    getUserAccessByCompany(ComponentEnum.InterventionsManagement) ===
      LevelEnum.LimitedRead ||
    getUserAccessByComponent(ComponentEnum.InterventionsManagement) ===
      LevelEnum.LimitedRead;
  const tabs = getTabs({
    isInterventionsLocked,
    t,
    id,
    counters,
  });
  const onActiveChange = ({ value }: TabBarItemType) => {
    onChange();
    navigate(value);
  };
  return (
    <div className={styles.menuRoot}>
      <TabBar
        items={tabs}
        active={pathname}
        onActiveChange={onActiveChange}
        useSidePaddings
      />
    </div>
  );
}
