import { useParams } from 'react-router-dom';
import { FilterGroupingEnum, Team, User } from 'graphql-common';
import useSearchParam from '@lib/hooks/useSearchParam';
import usePaginationParams from '@lib/hooks/usePaginationParams';
import useFilterParams from '@lib/hooks/useFilterParams';
import useUsersQueryHook from 'utils/fetch/useUsersQueryHook';
import { TeamViewMode } from 'routes/Teams/enums';
import useTeamQueryHook from 'utils/fetch/useTeamQueryHook';
import { ListQueryHookResult, SingleQueryHookResult } from 'utils/fetch/types';

export type UseTeamQueriesResult = {
  useUsersQueryHookResult: ListQueryHookResult<User>;
  useTeamQueryHookResult: SingleQueryHookResult<Team>;
};

export default function useTeamQueries({
  viewMode,
}: {
  viewMode: TeamViewMode;
}): UseTeamQueriesResult {
  const { id = '' } = useParams();
  const [searchQuery] = useSearchParam();
  const [filterParams] = useFilterParams();
  const [paginationParams] = usePaginationParams();

  const useUsersQueryHookResult = useUsersQueryHook({
    paginationParams,
    searchQuery,
    filterParams,
    includeFinalAccesses: true,
    includeShortData: true,
    skip: viewMode !== TeamViewMode.ViewMembers,
    skipGetAllIds: !(
      viewMode === TeamViewMode.Edit ||
      viewMode === TeamViewMode.ViewDetails ||
      viewMode === TeamViewMode.ViewMembers
    ),
    additionalFilters: [
      {
        teamId: {
          grouping: FilterGroupingEnum.And,
          predicate: { eq: id },
        },
      },
    ],
    getWithAllIds: true,
  });

  const useTeamQueryHookResult = useTeamQueryHook({
    id,
    skip: viewMode === TeamViewMode.Add,
    fetchPolicy: 'cache-and-network',
  });

  return { useUsersQueryHookResult, useTeamQueryHookResult };
}
