import React from 'react';
import { useTranslation } from 'react-i18next';
import { FieldValues, UseFieldArrayAppend } from 'react-hook-form';
import { ButtonSizes } from '@lib/components/Button/Button';
import { AppendValue } from '@lib/components/ReactHookForm/types';
import FilesUploader from '@lib/components/FilesUploader/FilesUploader';
import { UppyDocumentationAllowedFileTypes } from '@lib/enums/mimeTypes';
import AssetDocumentFieldNames from '@lib/enums/fieldNames/assetDocumentFieldNames';
import _get from 'lodash/get';
import { getFileName } from '@lib/utils/files';
import styles from './AddDocumentationAction.module.scss';

interface Props {
  append: UseFieldArrayAppend<FieldValues, string>;
  appendValue: AppendValue;
  isAddMoreVisible: boolean;
}

export default function AddDocumentationAction({
  append,
  appendValue,
  isAddMoreVisible,
}: Props) {
  const { t } = useTranslation();
  const onChange = (files: unknown) => {
    if (Array.isArray(files) && files.length) {
      files.forEach((fileItem) => {
        append({
          ...appendValue,
          [AssetDocumentFieldNames.Document]: fileItem,
          [AssetDocumentFieldNames.Name]: getFileName(
            _get(fileItem, 'file.metadata.filename', ''),
          ),
        });
      });
    }
  };
  if (!isAddMoreVisible) return null;
  return (
    <div className={styles.addItemBtnWrapper}>
      <FilesUploader
        onChange={onChange}
        value={[]}
        uploadButtonProps={{
          buttonText: t('upload-files'),
          buttonSize: ButtonSizes.large,
          leftIcon: 'upload',
          fullWidth: true,
        }}
        allowedFileTypes={UppyDocumentationAllowedFileTypes}
        note={t('files-upload-validation-msg', { sizeImg: 5, sizePdf: 25 })}
      />
    </div>
  );
}
