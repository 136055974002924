import React from 'react';
import { useTranslation } from 'react-i18next';
import { GraphQLErrors } from '@apollo/client/errors';
import PageHeader from '@lib/layouts/DashboardLayout/PageHeader/PageHeader';
import PageBody from '@lib/layouts/DashboardLayout/PageBody/PageBody';
import Button, { ButtonTypes } from '@lib/components/Button/Button';
import DashboardContentMaxWidth from '@lib/layouts/DashboardLayout/DashboardContentMaxWidth/DashboardContentMaxWidth';
import { FormRefType } from '@lib/components/ReactHookForm/types';
import { APP_URLS } from 'constants/urls';
import AssetForm from 'components/Asset/AssetForm/AssetForm';
import { UseActionsResult } from 'routes/Assets/Asset/utils/useActions';
import { UseModalManagementResult } from 'routes/Assets/Asset/utils/useModalManagement';
import { UseSearchListQueriesResult } from 'routes/Assets/Asset/utils/useSearchListQueries';
import { SubmitAction } from '@lib/enums/form';
import { UseAssetActionsResult } from 'routes/Assets/Asset/utils/useAssetActions';

type Props = {
  formRef: FormRefType;
  graphQLErrors?: GraphQLErrors;
  submitNewAssetRef: React.MutableRefObject<SubmitAction | undefined>;
  useActionsResult: UseActionsResult;
  useAssetActionsResult: UseAssetActionsResult;
  useModalManagementResult: UseModalManagementResult;
  useSearchListQueriesResult: UseSearchListQueriesResult;
  isCategoriesViewOnly: boolean;
};

export default function AssetEdit(props: Props) {
  const { t } = useTranslation();

  const {
    formRef,
    submitNewAssetRef,
    useActionsResult,
    useAssetActionsResult,
    useModalManagementResult,
    useSearchListQueriesResult,
    isCategoriesViewOnly,
  } = props;
  const { assetQueryHook } = useActionsResult;
  const {
    assetCategoryCreateMutationError,
    assetCategoryCreateMutationLoading,
    defaultValues,
    onAssetCategoryCreate,
    onEdit,
    assetMutationLoading,
    assetMutationError,
  } = useAssetActionsResult;
  const {
    closeCategoryFormModal,
    isCategoryFormModalOpened,
    openCategoryFormModal,
  } = useModalManagementResult;
  const {
    getAssetCategoriesLoadOptions,
    getSiteAreasLoadOptions,
    getSitesLoadOptions,
  } = useSearchListQueriesResult;

  return (
    <DashboardContentMaxWidth>
      <PageHeader title={t('edit-asset')}>
        <Button
          buttonText={t('view-asset')}
          buttonType={ButtonTypes.primaryOutlined}
          leftIcon="visibility"
          to={APP_URLS.dashboard.assets.view.getDynamicPath({
            pathParts: {
              id: `${defaultValues.id}`,
            },
          })}
        />
      </PageHeader>
      <PageBody>
        <AssetForm
          categoryGraphQLErrors={
            assetCategoryCreateMutationError?.graphQLErrors
          }
          categoryLoading={assetCategoryCreateMutationLoading}
          closeCategoryFormModal={closeCategoryFormModal}
          defaultValues={defaultValues}
          formRef={formRef}
          getAssetCategoriesLoadOptions={getAssetCategoriesLoadOptions}
          getSiteAreasLoadOptions={getSiteAreasLoadOptions}
          getSitesLoadOptions={getSitesLoadOptions}
          graphQLErrors={assetMutationError?.graphQLErrors}
          isCategoryFormModalOpened={isCategoryFormModalOpened}
          isEditForm
          loading={assetMutationLoading || assetQueryHook.loading}
          onAssetCategoryCreate={onAssetCategoryCreate}
          onSubmit={onEdit}
          openCategoryFormModal={openCategoryFormModal}
          submitNewAssetRef={submitNewAssetRef}
          isCategoriesViewOnly={isCategoriesViewOnly}
        />
      </PageBody>
    </DashboardContentMaxWidth>
  );
}
