import React from 'react';
import { useTranslation } from 'react-i18next';
import { ApolloError } from '@apollo/client/errors';
import { AssetCategory } from 'graphql-common';
import Button, { ButtonTypes } from '@lib/components/Button/Button';
import Table from '@lib/components/Table/Table';
import CircleLoader from '@lib/components/CircleLoader/CircleLoader';
import PageHeader from '@lib/layouts/DashboardLayout/PageHeader/PageHeader';
import Search from '@lib/components/Search/Search';
import PageBody from '@lib/layouts/DashboardLayout/PageBody/PageBody';
import ConfirmationModal from '@lib/components/ConfirmationModal/ConfirmationModal';
import ListEmptyState from '@lib/components/ListEmptyState/ListEmptyState';
import useSearchParam from '@lib/hooks/useSearchParam';
import ErrorPage from '@lib/components/ErrorPage/ErrorPage';
import Trans from '@lib/components/Trans/Trans';
import getStatusFromError from '@lib/utils/getStatusFromError';
import Modal from '@lib/components/Modal/Modal';
import { Values } from '@lib/interfaces/form';
import CategoryForm from 'components/AssetCategory/CategoryForm';
import getDefaultValuesFromQueryData from 'components/AssetCategory/utils/getDefaultValuesFromQueryData';
import ViewOnlyChip from '@lib/components/ViewOnlyChip/ViewOnlyChip';
import { IconName } from '@lib/components/Modal/enums';
import { getActions, getColumns } from './utils/tableHelpers';

interface Props {
  closeCategoryModal: () => void;
  closeDeleteModal: () => void;
  assetCategories: AssetCategory[];
  assetCategoriesError?: ApolloError;
  assetCategoriesFirstLoading: boolean;
  assetCategoriesLoading: boolean;
  assetCategoryMutationError?: ApolloError;
  assetCategoryMutationLoading: boolean;
  assetCategoryData?: AssetCategory | null;
  assetCategoryQueryError?: ApolloError;
  assetCategoryQueryLoading: boolean;
  isCategoryModalOpened: boolean;
  isDeleteModalOpened: boolean;
  onAddHandler: () => void;
  onConfirmedDelete: () => void;
  onDelete: (item: AssetCategory) => void;
  onEditHandler: (item?: AssetCategory) => void;
  onSubmitAssetCategoryForm: (values: Values) => void;
  selectedCategory: AssetCategory | null;
  totalAssetCategoriesCount: number;
  isAssetCategoriesViewOnly: boolean;
  isAssetsLocked: boolean;
}

function AssetCategories(props: Props) {
  const {
    closeCategoryModal,
    closeDeleteModal,
    assetCategories,
    assetCategoriesError,
    assetCategoriesFirstLoading,
    assetCategoriesLoading,
    assetCategoryMutationError,
    assetCategoryMutationLoading,
    assetCategoryData,
    assetCategoryQueryError,
    assetCategoryQueryLoading,
    selectedCategory,
    isCategoryModalOpened,
    isDeleteModalOpened,
    onAddHandler,
    onConfirmedDelete,
    onDelete,
    onEditHandler,
    onSubmitAssetCategoryForm,
    totalAssetCategoriesCount,
    isAssetCategoriesViewOnly,
    isAssetsLocked,
  } = props;
  const { t } = useTranslation();
  const [searchQuery] = useSearchParam();

  const tableColumns = getColumns({
    t,
    onEditHandler,
    isAssetCategoriesViewOnly,
    isAssetsLocked,
  });
  const tableActions = getActions({
    t,
    onEditHandler,
    onDelete,
    isAssetCategoriesViewOnly,
  });

  if (assetCategoriesError) {
    return (
      <ErrorPage
        status={getStatusFromError(assetCategoriesError)}
        description={assetCategoriesError.message}
      />
    );
  }

  const emptyState = (
    <ListEmptyState
      title={t('no-categories-yet')}
      text={t('no-categories-text')}
      actions={
        isAssetCategoriesViewOnly
          ? []
          : [
              <Button
                key="add"
                buttonText={t('create-new-category')}
                buttonType={ButtonTypes.primaryFilled}
                leftIcon="add"
                onClick={onAddHandler}
              />,
            ]
      }
    />
  );

  return (
    <>
      <PageHeader title={t('asset-categories')}>
        {isAssetCategoriesViewOnly ? (
          <ViewOnlyChip />
        ) : (
          <Button
            buttonText={t('create-new-category')}
            leftIcon="add"
            onClick={onAddHandler}
          />
        )}
      </PageHeader>
      <PageBody>
        {assetCategoriesFirstLoading ? (
          <CircleLoader />
        ) : (
          <>
            {(!!assetCategories?.length || searchQuery) && (
              <Search placeholder={t('asset-categories-search-placeholder')} />
            )}
            <Table
              actions={tableActions}
              columns={tableColumns}
              counterText={t('categories')}
              data={assetCategories}
              emptyState={emptyState}
              fullHeight
              id="asset-categories"
              loading={assetCategoriesLoading}
              totalEntries={totalAssetCategoriesCount}
            />
          </>
        )}
        <Modal
          isOpen={isCategoryModalOpened}
          title={
            selectedCategory
              ? t('edit-asset-category')
              : t('create-new-asset-category')
          }
          onClose={closeCategoryModal}
        >
          <CategoryForm
            defaultValues={
              selectedCategory && assetCategoryData
                ? getDefaultValuesFromQueryData(assetCategoryData)
                : {}
            }
            graphQLErrors={
              (assetCategoryQueryError || assetCategoryMutationError)
                ?.graphQLErrors
            }
            loading={assetCategoryQueryLoading || assetCategoryMutationLoading}
            onCancel={closeCategoryModal}
            onSubmit={onSubmitAssetCategoryForm}
          />
        </Modal>
        <ConfirmationModal
          isOpen={isDeleteModalOpened}
          onClose={closeDeleteModal}
          onConfirm={onConfirmedDelete}
          title={t('are-you-sure')}
          description={
            <Trans
              i18nKey="delete-category-text"
              values={{ name: selectedCategory?.name }}
            />
          }
          confirmButtonLabel={t('yes-delete')}
          icon={IconName.Delete}
          cancelButtonLabel={t('cancel')}
        />
      </PageBody>
    </>
  );
}

export default AssetCategories;
