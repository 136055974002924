import React, { memo } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Typography from '@lib/components/Typography/Typography';
import Placeholder from '@lib/assets/icons/img-placeholder.svg?react';
import Tooltip from '@lib/components/Tooltip/Tooltip';
import MaterialIcon from '@lib/components/MaterialIcon/MaterialIcon';
import { useTranslation } from 'react-i18next';
import styles from './AssetPreviewCell.module.scss';

interface Props {
  icon?: string | null;
  url: string;
  name: string;
  disabled?: boolean;
  usesAsNomenclatureCount?: number;
}

function AssetPreviewCell(props: Props) {
  const { t } = useTranslation();
  const { icon, url, name, disabled, usesAsNomenclatureCount = 0 } = props;
  const iconRender = icon ? <img src={icon} alt="" /> : <Placeholder />;

  return (
    <Link to={url}>
      <div
        className={classNames(styles.wrap, { [styles.wrapDisabled]: disabled })}
      >
        <div className={styles.icon}>{iconRender}</div>
        <div className={styles.info}>
          <Typography variant="caption" strong>
            {usesAsNomenclatureCount > 1 && (
              <div className={styles.warningIcon}>
                <Tooltip body={t('asset-as-nomenclature-text')}>
                  <MaterialIcon icon="warning" symbolsOutlined size="md-16" />
                </Tooltip>
              </div>
            )}
            {name}
          </Typography>
        </div>
      </div>
    </Link>
  );
}

export default memo(AssetPreviewCell);
