import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useLocalStorage } from 'usehooks-ts';
import _isNil from 'lodash/isNil';
import _get from 'lodash/get';
import Typography from '@lib/components/Typography/Typography';
import Button from '@lib/components/Button/Button';
import IconButton from '@lib/components/IconButton/IconButton';
import { PlanState } from '@lib/enums/plan';
import { UPGRADE_PLAN_BANNER } from '@lib/enums/localStorageKeys';
import ErrorIcon from '@lib/assets/icons/extended_error_red.svg?react';
import WarningIcon from '@lib/assets/icons/extended_warning_yellow.svg?react';
import styles from './CompanyPlanBanner.module.scss';

export default function CompanyPlanBanner({
  activeUsersCount,
  handleOpenPlanModal,
  usersLimit,
  activeUsersPercentage,
  planState,
}: {
  activeUsersCount: number | null | undefined;
  handleOpenPlanModal: (state: PlanState) => void;
  usersLimit: number | null | undefined;
  activeUsersPercentage: number;
  planState: PlanState;
}) {
  const { t } = useTranslation();
  const [banner, setBanner] = useLocalStorage(UPGRADE_PLAN_BANNER, {
    planState: PlanState.None,
    opened: false,
  });
  const closedPlanState = _get(banner, 'planState');

  const openPlanModal = () => handleOpenPlanModal(planState);
  const onClose = () => setBanner({ planState, opened: false });

  useEffect(() => {
    if (planState === PlanState.None) {
      setBanner({ planState, opened: false });
    } else if (planState !== closedPlanState) {
      setBanner({ planState, opened: true });
    }
  }, [planState, setBanner, closedPlanState]);

  if (
    _isNil(activeUsersCount) ||
    _isNil(usersLimit) ||
    planState === PlanState.None ||
    !_get(banner, 'opened')
  ) {
    return null;
  }
  return (
    <div
      className={classNames(styles.wrap, {
        [styles.wrapWarning]: planState === PlanState.Warning,
        [styles.wrapAttention]: planState === PlanState.Attention,
      })}
    >
      <div className={classNames(styles.icon)}>
        <div>
          {planState === PlanState.Warning && <WarningIcon />}
          {planState === PlanState.Attention && <ErrorIcon />}
        </div>
      </div>
      <div>
        <div className={styles.title}>
          <Typography variant="body" strong>
            {activeUsersCount}/{usersLimit}
          </Typography>
          <Typography variant="caption">{t('active-team-members')}</Typography>
        </div>
        <div className={styles.progress}>
          <span style={{ width: `${activeUsersPercentage}%` }} />
        </div>
        <Typography variant="caption" className={styles.desc}>
          {planState === PlanState.Warning && t('plan-limit-desc-almost')}
          {planState === PlanState.Attention && t('plan-limit-desc-reached')}
        </Typography>
      </div>
      <div className={styles.actions}>
        <Button buttonText={t('upgrade-plan')} onClick={openPlanModal} />
        <IconButton icon="close" onClick={onClose} />
      </div>
    </div>
  );
}
