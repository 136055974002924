import _get from 'lodash/get';
import AssetFieldNames from '@lib/enums/fieldNames/assetFieldNames';
import { Value, Values } from '@lib/interfaces/form';
import { ChipTypes } from '@lib/components/Chip/Chip';
import QrCodePrefix from '@lib/enums/qrCodePrefix';

export default function getDefaultValuesFromQueryData(data?: Values): Values {
  const category = _get(data, AssetFieldNames.Category);
  const categoryOption = {
    value: _get(category, 'id'),
    label: _get(category, 'name'),
    color: _get(category, 'color'),
    data: {
      prefix: _get(category, 'prefix', QrCodePrefix.Asset),
    },
  } as unknown as Value;
  const site = _get(data, AssetFieldNames.Site);
  const siteOption = {
    value: _get(site, 'id'),
    label: _get(site, 'name'),
  } as unknown as Value;
  const siteArea = _get(data, AssetFieldNames.SiteArea);
  const siteAreaOption = {
    value: _get(siteArea, 'id'),
    label: _get(siteArea, 'name'),
  } as unknown as Value;
  const qrCodePrefixOption = {
    value: _get(data, 'qrPrefix', QrCodePrefix.Asset),
    label: _get(data, 'qrPrefix', QrCodePrefix.Asset),
    color: ChipTypes.chipDisabled,
  };
  return {
    [AssetFieldNames.Category]: category ? categoryOption : null,
    [`${AssetFieldNames.Category}-default`]: category ? categoryOption : null,
    [AssetFieldNames.QrCodePrefix]: category ? qrCodePrefixOption : null,
    [`${AssetFieldNames.QrCodePrefix}-default`]: category
      ? qrCodePrefixOption
      : null,
    [AssetFieldNames.Code]: _get(data, AssetFieldNames.Code) || '',
    [AssetFieldNames.Description]:
      _get(data, AssetFieldNames.Description) || '',
    [AssetFieldNames.FullCode]: _get(data, AssetFieldNames.FullCode) || '',
    [AssetFieldNames.IconAttached]: _get(data, AssetFieldNames.IconAttached),
    [AssetFieldNames.Id]: _get(data, AssetFieldNames.Id),
    [AssetFieldNames.Manufacturer]:
      _get(data, AssetFieldNames.Manufacturer) || '',
    [AssetFieldNames.Model]: _get(data, AssetFieldNames.Model) || '',
    [AssetFieldNames.Name]: _get(data, AssetFieldNames.Name) || '',
    [AssetFieldNames.PoorWifi]: _get(data, AssetFieldNames.PoorWifi, false),
    [AssetFieldNames.SerialNumber]:
      _get(data, AssetFieldNames.SerialNumber) || '',
    [AssetFieldNames.ServiceDate]:
      _get(data, AssetFieldNames.ServiceDate) || '',
    [AssetFieldNames.Site]: site ? siteOption : null,
    [AssetFieldNames.SiteArea]: siteArea ? siteAreaOption : null,
  };
}
