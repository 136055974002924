import React from 'react';
import { useTranslation } from 'react-i18next';
import { User } from 'graphql-common';
import WhiteBox from '@lib/components/WhiteBox/WhiteBox';
import DashboardTitleHeader from '@lib/layouts/DashboardLayout/DashboardTitleHeader/DashboardTitleHeader';
import Button, { ButtonTypes } from '@lib/components/Button/Button';
import ListEmptyState from '@lib/components/ListEmptyState/ListEmptyState';
import CircleLoader from '@lib/components/CircleLoader/CircleLoader';
import ErrorPage from '@lib/components/ErrorPage/ErrorPage';
import getStatusFromError from '@lib/utils/getStatusFromError';
import Search from '@lib/components/Search/Search';
import Table from '@lib/components/Table/Table';
import useSearchParam from '@lib/hooks/useSearchParam';
import { useTableItemsBulkSelect } from '@lib/hooks/useTableItemsBulkSelect';
import { UseModalManagementResult } from '@lib/hooks/useModalManagement';
import { Modals } from '@lib/enums/modals';
import getUsersTableActionsMenu from 'routes/Users/utils/getUsersTableActionsMenu';
import getUsersTableActionsForSelected from 'routes/Users/utils/getUsersTableActionsForSelected';
import useUsersActions from 'routes/Users/utils/useUsersActions';
import UserModalForm from 'components/UserModalForm/UserModalForm';
import { UseTeamQueriesResult } from 'routes/Teams/utils/useTeamQueries';
import ViewOnlyChip from '@lib/components/ViewOnlyChip/ViewOnlyChip';
import ConfirmationModal from '@lib/components/ConfirmationModal/ConfirmationModal';
import { IconName } from '@lib/components/Modal/enums';
import getTeamMembersTableColumns from '../utils/getTeamMembersTableColumns';
import { UseTeamActionsResult } from '../utils/useTeamActions';
import { TeamViewMode } from '../enums';

type Props = {
  useModalManagementResult: UseModalManagementResult;
  useTeamActionsResult: UseTeamActionsResult;
  useTeamQueriesResult: UseTeamQueriesResult;
  isViewOnly: boolean;
};

export default function TeamViewMembers({
  useModalManagementResult,
  useTeamActionsResult,
  useTeamQueriesResult,
  isViewOnly,
}: Props) {
  const { t } = useTranslation();
  const [searchQuery] = useSearchParam();
  const useBulkSelectResult = useTableItemsBulkSelect<User>();
  const { useUsersQueryHookResult, useTeamQueryHookResult } =
    useTeamQueriesResult;
  const { onViewChange } = useTeamActionsResult;
  const { data } = useTeamQueryHookResult;
  const { name, canUpdate } = data || {};
  const isUpdateAvailable = canUpdate?.value;

  const { isModalOpened, closeModal } = useModalManagementResult;

  const {
    currentUserId,
    onActivate,
    onBulkActivate,
    onBulkDeactivate,
    onDeactivate,
    onDelete,
    onEdit,
    onResendEmailConfirmationHandler,
    onResendRegistrationConfirmationHandler,
    onSubmitUserFormCallback,
    onTeamMembersUpdate,
    openUserEditAccessesPage,
    openUserViewPage,
    selectedUserId,
    teamsLoadOptions,
    onConfirmedTeamMembersUpdate,
  } = useUsersActions({
    useUsersQueryHookResult,
    useBulkSelectResult,
    useModalManagementResult,
  });

  const { collection, loading, firstLoading, error, totalCount } =
    useUsersQueryHookResult;

  const tableColumns = getTeamMembersTableColumns({
    t,
    onResendEmailConfirmationHandler,
    onResendRegistrationConfirmationHandler,
    isViewOnly,
  });
  const tableActionsMenu = getUsersTableActionsMenu({
    currentUserId,
    onActivate,
    onDeactivate,
    onDelete,
    onEdit,
    onEditAccesses: openUserEditAccessesPage,
    onView: openUserViewPage,
    t,
    isViewOnly,
  });
  const tableActionsForSelected = getUsersTableActionsForSelected({
    onBulkActivate,
    onBulkDeactivate,
    onTeamMembersUpdate,
    selectedItemsCount: useBulkSelectResult.getSelectedItemsCount(totalCount),
    t,
    teamsLoadOptions,
    isViewOnly,
  });

  const isEmptyStateVisible = collection.length === 0 && !error && !loading;
  const emptyState = isEmptyStateVisible ? (
    <ListEmptyState
      title={t('no-members-yet')}
      text={t('no-members-text')}
      actions={
        isUpdateAvailable
          ? [
              <Button
                key="edit"
                buttonText={t('edit-team')}
                buttonType={ButtonTypes.primaryFilled}
                leftIcon="edit"
                onClick={() => onViewChange(TeamViewMode.Edit)}
                disabled={loading}
              />,
            ]
          : []
      }
    />
  ) : null;

  const isErrorStateVisible = !!error && !loading;
  const errorState = isErrorStateVisible ? (
    <ErrorPage
      status={getStatusFromError(error)}
      description={error?.message}
    />
  ) : null;

  const isContentVisible = !error && !firstLoading && collection?.length;
  const content = isContentVisible ? (
    <>
      {(!!collection?.length || searchQuery) && (
        <Search placeholder={t('member-search-placeholder')} />
      )}
      <Table
        actionsForSelected={tableActionsForSelected}
        actionsMenu={tableActionsMenu}
        columns={tableColumns}
        data={collection}
        emptyState={emptyState}
        fullHeight
        id="users"
        loading={loading}
        totalEntries={totalCount}
        useBulkSelectResult={useBulkSelectResult}
      />
    </>
  ) : null;

  return (
    <>
      <WhiteBox>
        <DashboardTitleHeader
          title={name}
          actions={[
            isUpdateAvailable && !isViewOnly ? (
              <Button
                key="edit"
                buttonText={t('edit')}
                buttonType={ButtonTypes.primaryOutlined}
                leftIcon="edit"
                onClick={() => onViewChange(TeamViewMode.Edit)}
                disabled={loading}
              />
            ) : undefined,
            isViewOnly ? <ViewOnlyChip key="view" /> : undefined,
          ]}
        />
        {firstLoading && <CircleLoader />}
        {errorState}
        {emptyState}
        {content}
      </WhiteBox>
      <UserModalForm
        isOpen={isModalOpened(Modals.UserFormModal)}
        onClose={() => closeModal(Modals.UserFormModal)}
        onSubmitUserFormCallback={onSubmitUserFormCallback}
        selectedUserId={selectedUserId}
        title={selectedUserId ? t('edit-team-member') : t('add-team-member')}
      />
      <ConfirmationModal
        confirmButtonLabel={t('yes-move-users')}
        description={t('move-users--to-team-text')}
        icon={IconName.Delete}
        isOpen={isModalOpened(Modals.MoveMembersToTeamModal)}
        onClose={() => closeModal(Modals.MoveMembersToTeamModal)}
        onConfirm={onConfirmedTeamMembersUpdate}
        title={t('are-you-sure')}
      />
    </>
  );
}
