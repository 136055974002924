import React, { forwardRef, memo, useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import { DatePicker, DatePickerProps } from 'antd';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import { PickerRef } from 'rc-picker/lib/interface';
import dayjs, { Dayjs } from 'dayjs';
import { isBefore } from 'date-fns';
import { dateTimePickerDateFormat } from '@lib/enums/dateTime';
import { revertTransformDate, transformDate } from '@lib/utils/dateTimeHelpers';
import MaterialIcon from '@lib/components/MaterialIcon/MaterialIcon';
import withFloatingLabel, {
  FloatingLabelProps,
} from '@lib/hocs/withFloatingLabel';
import Button, {
  ButtonSizes,
  ButtonTypes,
} from '@lib/components/Button/Button';
import isMobile from '@lib/utils/isMobile';
import styles from './DateTimePicker.module.scss';

type Props = {
  disabled?: boolean;
  hasError?: boolean;
  minDate?: DatePickerProps['minDate'];
  name?: string;
  onChange: (v: string) => void;
  selectEndOfTheDay?: boolean;
  size?: SizeType;
  value?: string;
};

const DatePickerCommon = forwardRef(
  (props: Props & FloatingLabelProps, ref: React.ForwardedRef<PickerRef>) => {
    const {
      disabled,
      onChange,
      name,
      value,
      minDate,
      selectEndOfTheDay,
      hasError,
      size = 'large',
      setIsFocused,
    } = props;
    const [isDropdownOpened, setDropdownOpened] = useState(false);
    const { phone, tablet } = isMobile();
    const isMobileDevice = phone || tablet;

    let selected: Dayjs | null = null;
    const isValueExists = !!value;
    if (isValueExists) {
      selected = dayjs(revertTransformDate(value));
    }

    let disabledDate: DatePickerProps['disabledDate'];
    if (minDate) {
      disabledDate = (current) =>
        current && isBefore(current.toDate(), dayjs(minDate).toDate());
    }

    const shouldUseOnBlur = !(isValueExists || isDropdownOpened);
    const onBlurHandler = shouldUseOnBlur
      ? () => setIsFocused(false)
      : undefined;

    const onFocusHandler = () => {
      setIsFocused(true);
    };

    const onChangeHandler = useCallback(
      (v: Dayjs | null) => {
        let newValue = '';
        if (v) {
          newValue = transformDate(v.toDate(), selectEndOfTheDay);
        } else if (isMobileDevice) {
          setIsFocused(false);
        }
        onChange(newValue);
      },
      [isMobileDevice, onChange, selectEndOfTheDay, setIsFocused],
    );

    const components = useMemo(() => {
      const button = ({ children, disabled: btnDisabled, onClick }) => {
        return (
          <Button
            buttonText={children}
            buttonSize={ButtonSizes.small}
            buttonType={ButtonTypes.primaryFilled}
            disabled={btnDisabled}
            onClick={onClick}
            useRipple={false}
          />
        );
      };
      return { button };
    }, []);

    const className = classNames(styles.picker, {
      [styles.datePickerFilled]: !!value,
    });

    return (
      <DatePicker
        allowClear={{ clearIcon: <MaterialIcon icon="cancel" /> }}
        className={className}
        components={components}
        disabled={disabled}
        disabledDate={disabledDate}
        format={dateTimePickerDateFormat}
        name={name}
        needConfirm
        onBlur={onBlurHandler}
        onChange={onChangeHandler}
        onFocus={onFocusHandler}
        placeholder=""
        popupClassName={styles.dateTimePickerPopup}
        ref={ref}
        showNow={false}
        showTime
        size={size}
        status={hasError ? 'error' : undefined}
        value={selected}
        onOpenChange={setDropdownOpened}
        inputReadOnly={isMobileDevice}
      />
    );
  },
);

export default memo(withFloatingLabel(DatePickerCommon));
