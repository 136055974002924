import _get from 'lodash/get';
import { Counter, useAssetCounterQuery } from 'graphql-common';
import { ApolloError } from '@apollo/client/errors';

type Args = {
  id?: string;
  assetId?: string;
  skip?: boolean;
  onError?: (error: ApolloError) => void;
};

export type UseAssetCounterQueryHookResult = {
  data: Counter;
  loading: boolean;
  error: ApolloError | undefined;
  refetch: () => void;
};

export default function useAssetCounterQueryHook(
  args: Args,
): UseAssetCounterQueryHookResult {
  const { id = '', assetId = '', skip = true, onError } = args;
  const query = useAssetCounterQuery({
    skip,
    variables: { findBy: { id }, assetFindBy: { id: assetId } },
    fetchPolicy: 'network-only',
    onError,
  });
  const { loading, error, refetch } = query;
  const data = _get(query, 'data.data') as Counter;

  return {
    data,
    loading,
    error,
    refetch,
  };
}
