import _get from 'lodash/get';
import { Task } from 'graphql-common';
import { FieldNames } from 'routes/Task/enums';
import getEarlyCompletionValuesFromQueryData from 'components/Task/utils/getEarlyCompletionValuesFromQueryData';
import getRecurringRuleValuesFromQueryData from 'components/Task/utils/getRecurringRuleValuesFromQueryData';

export default function getTaskScheduleValuesFromQueryData(taskData: Task) {
  return {
    [FieldNames.deadlineAt]: _get(taskData, 'deadlineAt'),
    ...getEarlyCompletionValuesFromQueryData(taskData),
    ...getRecurringRuleValuesFromQueryData(taskData),
  };
}
