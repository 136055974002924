import { InterventionScopeNameEnum } from 'graphql-common';

export default function getEmptyStateTextKeys(
  tabParam?: InterventionScopeNameEnum | string,
) {
  switch (tabParam) {
    case InterventionScopeNameEnum.OpenedMyParticipation:
      return ['no-my-interventions-yet', 'no-my-interventions-text'];
    case InterventionScopeNameEnum.Opened:
      return ['no-opened-interventions-yet', 'no-opened-interventions-text'];
    case InterventionScopeNameEnum.Closed:
      return [
        'no-closed-interventions-yet',
        'no-closed-interventions-text-admin',
      ];
    default:
      return ['no-interventions-yet', 'no-interventions-text'];
  }
}
