import React from 'react';
import _get from 'lodash/get';
import { User } from 'graphql-common';
import Chip, { ChipSizes, ChipTypes } from '@lib/components/Chip/Chip';
import getFullName from '@lib/utils/getFullName';
import AvatarIcon from '@lib/assets/icons/avatar.svg';

export interface Props {
  user: User;
  rightIconComponent?: React.ReactNode;
}

function UserChip({ user, rightIconComponent }: Props) {
  if (!user) return null;
  let leftImage = AvatarIcon;
  const small = _get(user, ['avatarAttached', 'urls', 'small']);
  const original = _get(user, ['avatarAttached', 'urls', 'original']);
  if (small || original) {
    leftImage = small || original;
  }
  return (
    <Chip
      type={ChipTypes.chipGray}
      label={getFullName(user)}
      leftImage={leftImage}
      leftImageRounded
      rounded
      size={ChipSizes.medium}
      rightIconComponent={rightIconComponent}
    />
  );
}

export default UserChip;
