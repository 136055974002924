import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { RoleEnum, User } from 'graphql-common';
import EntityViewStyles from '@lib/assets/styles/EntityView.module.scss';
import MaterialIcon from '@lib/components/MaterialIcon/MaterialIcon';
import Typography from '@lib/components/Typography/Typography';
import Chip, { ChipTypes } from '@lib/components/Chip/Chip';
import styles from './UserInfoView.module.scss';

export default function UserInfoView({
  user,
  openTeamPage,
}: {
  user: User;
  openTeamPage: (id: string) => void;
}) {
  const { t } = useTranslation();
  const { email, sites, personalId, phoneNumber, team, deactivatedAt, role } =
    user;
  const isFullAdmin = role === RoleEnum.FullAdmin;

  let sitesRender;
  if (isFullAdmin) {
    sitesRender = <Chip type={ChipTypes.chipGray} label={t('all')} />;
  } else {
    sitesRender = sites.collection?.length ? (
      sites.collection.map((site) => (
        <Chip key={site.id} type={ChipTypes.chipGray} label={site.name} />
      ))
    ) : (
      <Typography variant="body">-</Typography>
    );
  }

  return (
    <div>
      <div className={classNames(EntityViewStyles.infoList, styles.infoList)}>
        <div className={EntityViewStyles.infoItem}>
          <p className={EntityViewStyles.infoItemLabel}>
            <MaterialIcon icon="clock_loader_20" symbolsOutlined />
            <Typography variant="caption">{t('status')}</Typography>
          </p>
          <div className={EntityViewStyles.infoItemContent}>
            {deactivatedAt ? (
              <Chip
                type={ChipTypes.chipRed}
                label={t('deactivated')}
                leftIcon="do_not_disturb_on"
              />
            ) : (
              <Chip
                type={ChipTypes.chipGreen}
                label={t('active')}
                leftIcon="circle"
              />
            )}
          </div>
        </div>
        <div className={EntityViewStyles.infoItem}>
          <p className={EntityViewStyles.infoItemLabel}>
            <MaterialIcon icon="hexagon" symbolsOutlined />
            <Typography variant="caption">{t('personal-id')}</Typography>
          </p>
          <div className={EntityViewStyles.infoItemContent}>
            <Typography variant="body">{personalId || '-'}</Typography>
          </div>
        </div>
        <div className={EntityViewStyles.infoItem}>
          <p className={EntityViewStyles.infoItemLabel}>
            <MaterialIcon icon="mail" symbolsOutlined />
            <Typography variant="caption">{t('email')}</Typography>
          </p>
          <div className={EntityViewStyles.infoItemContent}>
            <Typography variant="body">{email || '-'}</Typography>
          </div>
        </div>
        <div className={EntityViewStyles.infoItem}>
          <p className={EntityViewStyles.infoItemLabel}>
            <MaterialIcon icon="call" symbolsOutlined />
            <Typography variant="caption">{t('phone-number')}</Typography>
          </p>
          <div className={EntityViewStyles.infoItemContent}>
            <Typography variant="body">
              {phoneNumber?.international || '-'}
            </Typography>
          </div>
        </div>
        <div className={EntityViewStyles.infoItem}>
          <p className={EntityViewStyles.infoItemLabel}>
            <MaterialIcon icon="home_pin" symbolsOutlined />
            <Typography variant="caption">{t('sites-label')}</Typography>
          </p>
          <div
            className={classNames(
              EntityViewStyles.infoItemContent,
              EntityViewStyles.infoItemContentRow,
            )}
          >
            {sitesRender}
          </div>
        </div>
        <div className={EntityViewStyles.infoItem}>
          <p className={EntityViewStyles.infoItemLabel}>
            <MaterialIcon icon="group" symbolsOutlined />
            <Typography variant="caption">{t('team')}</Typography>
          </p>
          <div className={EntityViewStyles.infoItemContent}>
            {team?.id ? (
              <Chip
                label={team.name}
                type={(team.color as ChipTypes) || ChipTypes.chipGray}
                leftIcon="circle"
                onClick={() => openTeamPage(team.id)}
              />
            ) : (
              <Typography variant="body">-</Typography>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
