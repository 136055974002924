import React, { ForwardedRef, forwardRef } from 'react';
import classNames from 'classnames';
import _get from 'lodash/get';
import Transition from 'react-transition-group/Transition';
import Typography from '@lib/components/Typography/Typography';
import { useWatch } from 'react-hook-form';
import CheckBoxSingleBool from '@lib/components/CheckBoxSingleBool/CheckBoxSingleBool';
import CheckBoxAsToggle from '@lib/components/CheckBoxAsToggle/CheckBoxAsToggle';
import FormItem from '@lib/components/ReactHookForm/FormItem';
import { Values } from '@lib/interfaces/form';
import styles from './FrequencyFormFields.module.scss';

const duration = 150;

const defaultStyle = {
  transition: `${duration}ms ease-in-out`,
  opacity: 0,
};

const transitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

export interface FrequencyFormFieldsWrapperProps {
  children: React.ReactNode;
  description?: string;
  isVisibleFn?: (values: Values) => boolean;
  name: string;
  title: string;
}

const FrequencyFormFieldsWrapper = forwardRef(
  (
    props: FrequencyFormFieldsWrapperProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const { children, title, description, name, isVisibleFn } = props;
    const watch = useWatch();
    const enableCheckboxFieldName = `${name}_enabled`;
    const isChecked = !!_get(watch, enableCheckboxFieldName);
    const isVisible = isVisibleFn ? isVisibleFn(watch) : true;
    if (!isVisible) return null;
    return (
      <div
        className={classNames(styles.fieldRoot, {
          [styles.fieldRootEnabled]: isChecked,
        })}
        ref={ref}
      >
        <div>
          <FormItem
            name={enableCheckboxFieldName}
            element={CheckBoxSingleBool}
            componentProps={{
              options: [{ label: 'checked', value: 'checked' }],
              CheckBoxComponent: CheckBoxAsToggle,
            }}
            formItemClassName={styles.fieldToggleFormItem}
          />
        </div>
        <div>
          <div className={styles.fieldContent}>
            <Typography variant="body" strong>
              {title}
            </Typography>
            {description && (
              <Typography variant="caption" className={styles.fieldDescription}>
                {description}
              </Typography>
            )}
          </div>
          <Transition in={isChecked} timeout={duration} unmountOnExit>
            {(transitionState) => (
              <div
                style={{
                  ...defaultStyle,
                  // @ts-ignore
                  ...transitionStyles[transitionState],
                }}
                className={styles.fieldChildren}
              >
                {children}
              </div>
            )}
          </Transition>
        </div>
      </div>
    );
  },
);

export default FrequencyFormFieldsWrapper;
