import { ApolloError, GraphQLErrors } from '@apollo/client/errors';
import toast from '@lib/components/Toast/Toast';
import _get from 'lodash/get';
import { TFunction } from 'i18next';

export function getFullMessages(
  graphQLErrors: GraphQLErrors,
  t: TFunction<'translation', undefined>,
): string {
  // @ts-ignore
  return (graphQLErrors || [])
    .map(({ extensions }) => {
      if (
        _get(extensions, 'details.task_status.0') ===
        'status_transition_denied_for_task'
      ) {
        return t('error-data-outdated');
      }
      const fullMessages = _get(extensions, 'fullMessages', []) as string[];
      if (fullMessages.length) {
        return fullMessages.join(' ');
      }
      return '';
    })
    .join(' ')
    .trim();
}

export function showGraphQLErrors(
  error: ApolloError,
  t: TFunction<'translation', undefined>,
) {
  const fullMessages = getFullMessages(error.graphQLErrors, t);
  toast({
    content: fullMessages || error.message || t('something-went-wrong'),
  });
}
