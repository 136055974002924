import React, { forwardRef, memo, useEffect, useRef } from 'react';
import classNames from 'classnames';
import CountMark from '@lib/components/CountMark/CountMark';
import { ActiveItem, TabBarItem as TabBarItemType } from './constants';
import styles from './TabBar.module.scss';

type Props = {
  active?: ActiveItem;
  isVisible: boolean;
  item: TabBarItemType;
  onActiveChange: (item: TabBarItemType) => void;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TabBarItem = forwardRef<HTMLButtonElement, Props>((props: Props, _) => {
  const { active, isVisible, item, onActiveChange } = props;
  const ref = useRef<HTMLButtonElement | null>(null);
  const { label, counter, counterType, disabled, hasError, value, type } = item;
  const isActive =
    active && (value === active || value.split('?')?.[0] === active);
  useEffect(() => {
    if (isActive && ref.current) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'end',
      });
    }
  }, [isActive, ref]);
  return (
    <button
      type="button"
      key={value}
      onClick={() => !disabled && isVisible && onActiveChange(item)}
      data-id={value}
      className={classNames(styles.item, type && styles[type], {
        [styles.active]: isActive,
        [styles.disabled]: disabled,
        [styles.hasError]: hasError,
        [styles.hasLabel]: !!label,
        [styles.hidden]: !isVisible,
      })}
      ref={ref}
    >
      {label && <span>{label}</span>}
      {counter !== undefined && (
        <CountMark value={counter} disabled={!isActive} type={counterType} />
      )}
    </button>
  );
});

export default memo(TabBarItem);
