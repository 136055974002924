import React from 'react';
import _get from 'lodash/get';
import { TFunction } from 'i18next';
import { Team } from 'graphql-common';
import { CellProps } from '@lib/components/Table/types';
import TextCell from '@lib/components/Table/commonComponents/TextCell/TextCell';
import ChipCell from '@lib/components/Table/commonComponents/ChipCell/ChipCell';
import { ChipTypes } from '@lib/components/Chip/Chip';

type Args = {
  onView: (v: Team) => void;
  t: TFunction<'translation', undefined>;
};

export default function getTeamsTableColumns({ onView, t }: Args) {
  return [
    {
      id: 'name',
      Header: t('name'),
      Cell: (props: CellProps<Team>) => {
        const { item } = props;
        const id = _get(item, 'id') as string;
        const label = _get(item, 'name');
        const type = _get(item, 'color') as ChipTypes;
        if (!id || !label || !type) return '-';
        return (
          <ChipCell
            label={label}
            type={type}
            leftIcon="circle"
            onClick={() => onView(item)}
          />
        );
      },
      minWidth: 308,
      maxWidth: 398,
      width: 308,
      sortable: true,
    },
    {
      id: 'visibleUsersCount',
      Header: t('members-total'),
      accessor: 'visibleUsersCount',
      Cell: (props: CellProps<Team>) => <TextCell {...props} />,
      maxWidth: 150,
      minWidth: 130,
      width: 130,
    },
  ];
}
