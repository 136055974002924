import React from 'react';
import { BaseItem, TableProps } from '@lib/components/Table/types';
import { checkHasActionsColumn, getColumnsCount } from '../../utils';
import styles from './TableFooter.module.scss';

function TableFooter<T extends BaseItem>(props: TableProps<T>) {
  const { Footer, columns, data } = props;
  const hasColumnsFooter = columns.some((column) => column.Footer);
  const hasTableFooter = !!Footer;

  if (!hasColumnsFooter && !hasTableFooter) return null;

  const hasActionsColumn = checkHasActionsColumn(props);

  return (
    <tfoot className={styles.footer}>
      {hasColumnsFooter && (
        <tr>
          {columns.map((column, index) => {
            const ColumnFooter = column.Footer || null;
            return (
              <td
                // eslint-disable-next-line react/no-array-index-key
                key={index}
              >
                {ColumnFooter && <ColumnFooter data={data} />}
              </td>
            );
          })}
          {hasActionsColumn && <td> </td>}
        </tr>
      )}
      {hasTableFooter && (
        <tr>
          <td colSpan={getColumnsCount(props)}>
            <Footer />
          </td>
        </tr>
      )}
    </tfoot>
  );
}

export default TableFooter;
