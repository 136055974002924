import React from 'react';
import * as Yup from 'yup';
import { TFunction } from 'i18next';
import _without from 'lodash/without';
import _get from 'lodash/get';
import RadioButtonGroup, {
  RadioButtonGroupOption,
  RadioButtonWrapViews,
} from '@lib/components/RadioButtonGroup/RadioButtonGroup';
import Input from '@lib/components/Input/Input';
import RadioButtonAsChipColor from '@lib/components/RadioButtonAsChipColor/RadioButtonAsChipColor';
import { ChipTypes } from '@lib/components/Chip/Chip';
import {
  MAX_ASSET_PREFIX_CODE_FIELD_LENGTH,
  MAX_TEXT_FIELD_LENGTH,
} from '@lib/enums/form';
import { getOptionShape } from '@lib/utils/yup';
import { FieldItem, Values } from '@lib/interfaces/form';
import { transformToUpperCase } from '@lib/utils/formHelpers';
import AssetFieldNames from '@lib/enums/fieldNames/assetCategoryFieldNames';
import CheckBoxSingleBool from '@lib/components/CheckBoxSingleBool/CheckBoxSingleBool';
import SupportiveMessage, {
  SupportiveMessageColor,
} from '@lib/components/SupportiveMessage/SupportiveMessage';

type IndustryFormFieldsArgs = {
  isEditForm?: boolean;
  t: TFunction<'translation', undefined>;
};

export default function getCategoryFormFields({
  isEditForm,
  t,
}: IndustryFormFieldsArgs): FieldItem[] {
  return _without(
    [
      {
        name: AssetFieldNames.Name,
        label: t('category-name'),
        element: Input,
        validation: Yup.string()
          .trim()
          .max(MAX_TEXT_FIELD_LENGTH, t('max-text-length-field-error'))
          .required(t('required-field-error')),
        componentProps: {
          autoFocus: true,
        },
      },
      {
        name: AssetFieldNames.Prefix,
        label: t('asset-prefix'),
        element: Input,
        validation: Yup.string()
          .trim()
          .max(
            MAX_ASSET_PREFIX_CODE_FIELD_LENGTH,
            t('max-text-length-field-error-4'),
          )
          .notOneOf(['z', 'Z'], t('no-z-or-Z-error')),
        componentProps: {
          beforeValueChange: transformToUpperCase,
        },
        hideErrorsSpace: isEditForm,
      },
      isEditForm
        ? {
            name: AssetFieldNames.RefreshQrPrefixForAssets,
            label: t('update-assets-prefix-from-category'),
            element: CheckBoxSingleBool,
            componentProps: {
              options: [{ label: 'checked', value: 'checked' }],
            },
          }
        : undefined,
      isEditForm
        ? {
            name: `${AssetFieldNames.RefreshQrPrefixForAssets}-message`,
            customRender: () => (
              <div style={{ marginTop: '-24px' }}>
                <SupportiveMessage
                  text={t('update-assets-prefix-from-category-notice')}
                  color={SupportiveMessageColor.Yellow}
                />
              </div>
            ),
            isVisibleFn: (values: Values) =>
              _get(values, AssetFieldNames.RefreshQrPrefixForAssets),
          }
        : undefined,
      {
        name: AssetFieldNames.Color,
        label: t('label-color'),
        element: RadioButtonGroup,
        validation: Yup.object()
          .shape(getOptionShape(t, true))
          .required(t('required-field-error')),
        componentProps: {
          options: (
            Object.keys(ChipTypes) as Array<keyof typeof ChipTypes>
          ).map((chipType) => ({
            value: ChipTypes[chipType],
            label: ChipTypes[chipType],
          })) as RadioButtonGroupOption[],
          RadioButtonComponent: RadioButtonAsChipColor,
          wrapView: RadioButtonWrapViews.colors,
        },
      },
    ],
    undefined,
  ) as FieldItem[];
}
