import _get from 'lodash/get';
import { LanguageEnum } from 'graphql-common';
import { Values } from '@lib/interfaces/form';
import UserFieldNames from '@lib/enums/fieldNames/userFieldNames';

export default function getPreparedDataFromValues(values: Values) {
  return {
    ...values,
    lang: _get(values, `${UserFieldNames.Lang}.value`) as LanguageEnum,
  };
}
