import React from 'react';
import { useTranslation } from 'react-i18next';
import ActionsRow from '@lib/components/ActionsRow/ActionsRow';
import Button, {
  ButtonTypes,
  ButtonSizes,
} from '@lib/components/Button/Button';

interface Props {
  buttonSize?: ButtonSizes;
  className?: string;
  loading?: boolean;
  onCancel?: () => void;
}

export default function FilterFormActions(props: Props) {
  const {
    buttonSize = ButtonSizes.large,
    className,
    loading,
    onCancel,
  } = props;
  const { t } = useTranslation();
  return (
    <ActionsRow
      className={className}
      actions={[
        <Button
          buttonSize={buttonSize}
          buttonText={t('cancel')}
          buttonType={ButtonTypes.secondaryOutlined}
          disabled={loading}
          fullWidth
          leftIcon="close"
          onClick={onCancel}
        />,
        <Button
          buttonSize={buttonSize}
          buttonText={t('apply')}
          buttonType={ButtonTypes.primaryFilled}
          fullWidth
          isLoading={loading}
          leftIcon="check"
          type="submit"
        />,
      ]}
    />
  );
}
