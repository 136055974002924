import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import BreadCrumbs from '@lib/components/BreadCrumbs/BreadCrumbs';
import { getBreadCrumbsDataByKey } from 'utils/breadcrumbs';
import FormTemplateBuilderTasksView from 'components/FormTemplateBuilder/FormTemplateBuilderView/FormTemplateBuilderTasksView';
import FormTemplateModals from './FormTemplateModals';
import { UseActionsResults } from './utils/useActions';
import { ModalManagementResult } from './utils/useModalManagement';

interface Props {
  useActionsResult: UseActionsResults;
  useModalManagementResult: ModalManagementResult;
  isViewOnly: boolean;
}

export default function FormTemplateTasksViewContainer(props: Props) {
  const { useActionsResult, useModalManagementResult, isViewOnly } = props;
  const { id = '', versionId = '' } = useParams();
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const { openDestroyModal } = useModalManagementResult;

  const {
    formTemplateData,
    formTemplateError,
    formTemplateLoading,
    formTemplateVersionData,
    loadingState,
    onCreateTaskHandler,
    onDuplicateHandler,
    onEditHandler,
    onFormTemplateArchiveHandler,
    onTaskFormTemplateVersionBulkChange,
    onTaskFormTemplateVersionChange,
    tasksQueryHook,
    useBulkSelectResult,
  } = useActionsResult;

  const breadCrumbsData = getBreadCrumbsDataByKey({
    entity: {
      versionId: formTemplateData?.versionId || versionId,
      id: formTemplateData?.id || id,
      name: formTemplateData?.name,
    },
    pathname,
    t,
  });

  return (
    <>
      <BreadCrumbs data={breadCrumbsData} />
      <FormTemplateBuilderTasksView
        formTemplateData={formTemplateData}
        formTemplateError={formTemplateError}
        formTemplateId={id}
        formTemplateLoading={formTemplateLoading}
        formTemplateVersionData={formTemplateVersionData}
        isViewOnly={isViewOnly}
        loadingState={loadingState}
        onCreateTaskHandler={onCreateTaskHandler}
        onDuplicateHandler={onDuplicateHandler}
        onEditHandler={onEditHandler}
        onFormTemplateArchiveHandler={onFormTemplateArchiveHandler}
        onTaskFormTemplateVersionBulkChange={
          onTaskFormTemplateVersionBulkChange
        }
        onTaskFormTemplateVersionChange={onTaskFormTemplateVersionChange}
        openDestroyModal={openDestroyModal}
        tasksQueryHook={tasksQueryHook}
        useBulkSelectResult={useBulkSelectResult}
      />
      <FormTemplateModals
        useActionsResult={useActionsResult}
        useModalManagementResult={useModalManagementResult}
      />
    </>
  );
}
