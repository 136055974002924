import _get from 'lodash/get';
import { Documentation, useDocumentationQuery } from 'graphql-common';
import { ApolloError } from '@apollo/client/errors';

type Args = {
  id?: string;
  assetId?: string;
  skip?: boolean;
};

export type UseAssetDocumentationQueryHookResult = {
  data: Documentation;
  loading: boolean;
  error: ApolloError | undefined;
};

export default function useAssetDocumentationQueryHook(
  args: Args,
): UseAssetDocumentationQueryHookResult {
  const { id = '', assetId = '', skip = true } = args;
  const query = useDocumentationQuery({
    skip,
    variables: { id, assetId },
    fetchPolicy: 'network-only',
  });
  const data = _get(query, 'data.data') as Documentation;
  const loading = _get(query, 'loading');
  const error = _get(query, 'error');

  return {
    data,
    loading,
    error,
  };
}
