import React from 'react';
import LinkComponent, { LinkTypes } from '@lib/components/Link/Link';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

export default function UserAccessFieldReset({ name }: { name: string }) {
  const { t } = useTranslation();
  const { setValue } = useFormContext();
  const onReset = () => {
    setValue(name, {}, { shouldValidate: true, shouldTouch: true });
  };
  return (
    <LinkComponent type={LinkTypes.primaryBlue} onClick={onReset}>
      {t('reset-to-team-rights')}
    </LinkComponent>
  );
}
