import { TFunction } from 'i18next';
import { InterventionViewMode } from 'routes/Intervention/enums';

type Args = {
  t: TFunction<'translation', undefined>;
  actionsCounter: number;
};

export default function getInterventionViewPageTabs({
  t,
  actionsCounter = 0,
}: Args) {
  return [
    {
      label: t('details'),
      value: InterventionViewMode.View,
    },
    {
      label: t('actions'),
      value: InterventionViewMode.ViewActions,
      counter: actionsCounter,
    },
  ];
}
