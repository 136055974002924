import { useState } from 'react';

export type ModalManagementResult = {
  closeArchiveModal: () => void;
  closeCategoryFormModal: () => void;
  closeDestroyModal: () => void;
  closeVersionArchiveModal: () => void;
  closeVersionDestroyModal: () => void;
  isArchiveModalOpened: boolean;
  isCategoryFormModalOpened: boolean;
  isDestroyModalOpened: boolean;
  isVersionArchiveModalOpened: boolean;
  isVersionDestroyModalOpened: boolean;
  openArchiveModal: () => void;
  openCategoryFormModal: () => void;
  openDestroyModal: () => void;
  openVersionArchiveModal: () => void;
  openVersionDestroyModal: () => void;
};

export default function useModalManagement(): ModalManagementResult {
  const [isCategoryFormModalOpened, setCategoryFormModalOpened] =
    useState(false);
  const [isDestroyModalOpened, setDestroyModalOpened] = useState(false);
  const [isVersionDestroyModalOpened, setVersionDestroyModalOpened] =
    useState(false);
  const [isArchiveModalOpened, setArchiveModalOpened] = useState(false);
  const [isVersionArchiveModalOpened, setVersionArchiveModalOpened] =
    useState(false);

  const closeArchiveModal = () => setArchiveModalOpened(false);
  const closeVersionArchiveModal = () => setVersionArchiveModalOpened(false);
  const closeCategoryFormModal = () => setCategoryFormModalOpened(false);
  const closeDestroyModal = () => setDestroyModalOpened(false);
  const closeVersionDestroyModal = () => setVersionDestroyModalOpened(false);

  const openArchiveModal = () => setArchiveModalOpened(true);
  const openVersionArchiveModal = () => setVersionArchiveModalOpened(true);
  const openCategoryFormModal = () => setCategoryFormModalOpened(true);
  const openDestroyModal = () => setDestroyModalOpened(true);
  const openVersionDestroyModal = () => setVersionDestroyModalOpened(true);

  return {
    closeArchiveModal,
    closeCategoryFormModal,
    closeDestroyModal,
    closeVersionArchiveModal,
    closeVersionDestroyModal,
    isArchiveModalOpened,
    isCategoryFormModalOpened,
    isDestroyModalOpened,
    isVersionArchiveModalOpened,
    isVersionDestroyModalOpened,
    openArchiveModal,
    openCategoryFormModal,
    openDestroyModal,
    openVersionArchiveModal,
    openVersionDestroyModal,
  };
}
