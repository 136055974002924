import React, { forwardRef, memo, useMemo } from 'react';
import { TFunction } from 'i18next';
import _get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import RadioButton from '@lib/components/RadioButton/RadioButton';
import RadioButtonAsButton from '@lib/components/RadioButtonAsButton/RadioButtonAsButton';
import styles from './RadioButtonGroup.module.scss';

export enum RadioButtonWrapViews {
  row = 'row',
  rowCentered = 'rowCentered',
  list = 'list',
  colors = 'colors',
  rating = 'rating',
}

export interface RadioButtonGroupOption {
  label?: string;
  labelKey?: string;
  value: string | number;
}

export interface RadioButtonGroupProps {
  RadioButtonComponent?: React.JSX.ElementType;
  disabled?: boolean;
  hasError?: boolean;
  itemClass?: string;
  label?: React.ReactNode;
  name?: string;
  onChange?: (v: RadioButtonGroupOption) => void;
  options: RadioButtonGroupOption[];
  radioButtonComponentProps?: { [key: string]: unknown };
  value?: RadioButtonGroupOption;
  wrapClass?: string;
  wrapView?: RadioButtonWrapViews;
}

function getOptionLabel(
  option: RadioButtonGroupOption,
  t: TFunction<'translation', undefined>,
) {
  if (option?.labelKey) return t(option.labelKey);
  return _get(option, 'label', '') as string;
}

// TODO: use or remove ref
const RadioButtonGroup = forwardRef(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  (props: RadioButtonGroupProps, ref: React.ForwardedRef<HTMLInputElement>) => {
    const {
      RadioButtonComponent = RadioButton,
      hasError,
      itemClass,
      label,
      name: fieldName,
      onChange,
      options,
      radioButtonComponentProps = {},
      value: fieldValue,
      wrapClass,
      wrapView = RadioButtonWrapViews.list,
      ...rest
    } = props;
    const { t } = useTranslation();
    const hasLabel = !!label && typeof label === 'string';
    const hasCustomLabel = !!label && typeof label !== 'string';

    const preparedOptions = useMemo(
      () =>
        options.map((option) => ({
          ...option,
          label: getOptionLabel(option, t),
        })),
      [options, t],
    );

    const onChangeHandler = (v: RadioButtonGroupOption) => {
      if (onChange) onChange(v);
    };

    return (
      <div
        className={classNames(styles.groupRoot, {
          [styles.rootWithLabel]: hasLabel,
        })}
      >
        {hasLabel && <span className={styles.groupLabel}>{label}</span>}
        {hasCustomLabel && label}
        <div
          className={classNames(wrapClass, {
            [styles.defaultRow]: wrapView === RadioButtonWrapViews.row,
            [styles.defaultRowCentered]:
              wrapView === RadioButtonWrapViews.rowCentered,
            [styles.colorsRow]: wrapView === RadioButtonWrapViews.colors,
            [styles.ratingRow]: wrapView === RadioButtonWrapViews.rating,
            [styles.list]: wrapView === RadioButtonWrapViews.list,
            [styles.listWithButtons]:
              wrapView === RadioButtonWrapViews.list &&
              RadioButtonComponent === RadioButtonAsButton,
          })}
        >
          {preparedOptions.map((option, index) => {
            const { value, label: optionLabel, ...restOptionProps } = option;
            const isChecked = fieldValue?.value === value;
            return (
              <div className={itemClass} key={uuidv4()}>
                <RadioButtonComponent
                  {...rest}
                  {...restOptionProps}
                  {...radioButtonComponentProps}
                  checked={isChecked}
                  hasError={hasError}
                  id={value}
                  index={index}
                  label={optionLabel}
                  name={fieldName}
                  onChange={() => onChangeHandler(option)}
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  },
);

export default memo(RadioButtonGroup);
