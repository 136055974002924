import React from 'react';
import _get from 'lodash/get';
import _find from 'lodash/find';
import {
  components as DefaultSelectComponents,
  SingleValueProps,
} from 'react-select';
import Chip, { ChipSizes, ChipTypes } from '@lib/components/Chip/Chip';
import { Option } from '@lib/interfaces/form';
import { SelectOption } from '../types';
import styles from '../Select.module.scss';

function ChipSingleValue({
  children,
  ...restProps
}: SingleValueProps): JSX.Element {
  const { options } = restProps;
  const isDisabled = _get(restProps, 'selectProps.isDisabled');
  let option = _get(restProps, 'selectProps.value') as Option;
  if (!option?.value || !option?.label) {
    const value = _get(restProps, 'selectProps.value.value', '');
    const selectedOption = _find(
      options,
      ({ value: optionValue }) => value === optionValue,
    ) as SelectOption;
    if (selectedOption) option = selectedOption;
  }
  const label = _get(option, 'label');
  const type = _get(option, 'color', ChipTypes.chipGray);
  return (
    <DefaultSelectComponents.SingleValue
      {...restProps}
      className={styles.singleValue}
    >
      <div className={styles.singleValueBox}>
        <Chip
          type={isDisabled ? ChipTypes.chipDisabled : type}
          size={ChipSizes.medium}
          // @ts-ignore
          label={label}
        />
      </div>
    </DefaultSelectComponents.SingleValue>
  );
}

export default ChipSingleValue;
