import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import _get from 'lodash/get';
import { Team, useTeamDestroyMutation } from 'graphql-common';
import toast from '@lib/components/Toast/Toast';
import usePaginationParams from '@lib/hooks/usePaginationParams';
import onDestroyListItemCallBack from '@lib/utils/onDestroyListItemCallBack';
import useSearchParam from '@lib/hooks/useSearchParam';
import { UseBulkSelectResult } from '@lib/hooks/useTableItemsBulkSelect';
import {
  Modals,
  UseModalManagementResult,
} from '@lib/hooks/useModalManagement';
import useTeamsQueryHook from 'utils/fetch/useTeamsQueryHook';
import useOutletContext, { ContextProps } from 'hooks/useOutletContext';
import { APP_URLS } from 'constants/urls';

export default function useTeamsActions(props: {
  useBulkSelectResult: UseBulkSelectResult<Team>;
  useModalManagementResult: UseModalManagementResult;
}) {
  const { useBulkSelectResult, useModalManagementResult } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { search, pathname } = useLocation();
  const [searchQuery] = useSearchParam();
  const [paginationParams] = usePaginationParams();
  const { selectedSingleTableItem, handleSelectSingleTableItem } =
    useBulkSelectResult;
  const { closeModal, openModal } = useModalManagementResult;

  const selectedTeamId = selectedSingleTableItem?.id || null;
  const outletContext: ContextProps = useOutletContext();
  const currentTeamId = _get(outletContext, 'user.id');

  // Teams
  const teamsQueryHookResult = useTeamsQueryHook({
    searchQuery,
    paginationParams,
    orderingParams: paginationParams,
  });

  const [destroyMutation] = useTeamDestroyMutation({
    onCompleted: () => {
      closeModal(Modals.TeamDestroyModal);
      onDestroyListItemCallBack({
        collection: teamsQueryHookResult.collection,
        navigate,
        pathname,
        refetch: teamsQueryHookResult.refetch,
        search,
        totalPages: teamsQueryHookResult.totalPages,
      });
    },
    onError: (error) => {
      toast({ content: error.message || 'Oops! Something went wrong' });
    },
  });

  const onCreate = () => {
    navigate(APP_URLS.dashboard.teams.add.path);
  };

  const onView = (item?: Team) => {
    if (item?.id) {
      navigate(
        APP_URLS.dashboard.teams.view.getDynamicPath({
          pathParts: { id: item.id },
        }),
      );
    }
  };

  const onEdit = (item?: Team) => {
    if (item?.id) {
      navigate(
        APP_URLS.dashboard.teams.edit.getDynamicPath({
          pathParts: { id: item.id },
        }),
      );
    }
  };

  const onDelete = (item?: Team) => {
    if (item?.id) {
      handleSelectSingleTableItem(item);
      openModal(Modals.TeamDestroyModal);
    }
  };

  const onConfirmedDelete = () => {
    closeModal(Modals.TeamDestroyModal);
    toast({
      content: t('destroy-team-member-success'),
      closeCallback: () => {
        if (selectedTeamId) {
          destroyMutation({
            variables: {
              id: selectedTeamId,
            },
          });
        }
      },
    });
  };

  return {
    teamsQueryHookResult,
    currentTeamId,
    onConfirmedDelete,
    onCreate,
    onDelete,
    onEdit,
    onView,
    selectedTeamId,
  };
}
