import React from 'react';
import classNames from 'classnames';
import {
  components as DefaultSelectComponents,
  SingleValueProps,
} from 'react-select';
import _get from 'lodash/get';
import MaterialIcon from '@lib/components/MaterialIcon/MaterialIcon';
import styles from '../Select.module.scss';

function SingleValue({ children, ...restProps }: SingleValueProps) {
  // @ts-ignore
  const { data } = restProps;
  const icon = _get(data, 'data.icon');
  return (
    <DefaultSelectComponents.SingleValue
      {...restProps}
      className={classNames({
        [styles.singleValueWithIcon]: !!icon,
      })}
    >
      {icon && (
        <span className={styles.optionCheckbox}>
          <MaterialIcon icon={icon} size="md-24" symbolsOutlined />
        </span>
      )}
      <span className={styles.singleValueChild}>{children}</span>
    </DefaultSelectComponents.SingleValue>
  );
}

export default SingleValue;
