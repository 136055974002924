import React from 'react';
import IconButton from '@lib/components/IconButton/IconButton';
import Image from '@lib/components/Image/Image';
import { downloadLinkId } from '@lib/enums/selectors';
import styles from './ImageViewer.module.scss';

interface Props {
  fileUrl: string;
  downloadUrl: string;
  onFullScreenToggle?: () => void;
  isFullScreen?: string;
}

export default function ImageViewer(props: Props) {
  const { fileUrl, downloadUrl, onFullScreenToggle, isFullScreen } = props;

  const handleDownload = () => {
    const link = document.getElementById(downloadLinkId);
    if (downloadUrl && link) {
      link.setAttribute('href', downloadUrl);
      link.click();
      link.setAttribute('href', '');
    }
  };

  const handlePrint = () => {
    const printFrame = document.createElement('iframe');
    printFrame.style.position = 'absolute';
    printFrame.style.width = '0px';
    printFrame.style.height = '0px';
    printFrame.style.border = 'none';
    printFrame.style.visibility = 'hidden';

    document.body.appendChild(printFrame);

    const frameDoc = printFrame.contentWindow?.document;
    if (frameDoc) {
      frameDoc.open();
      frameDoc.write(`
        <html>
          <head>
            <style>
              @media print {
                body {
                  margin: 0;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  height: 100vh;
                }
                img {
                  max-width: 100%;
                  height: auto;
                }
                @page {
                  size: auto;
                  margin: 0;
                }
                header, footer {
                  display: none;
                }
              }
            </style>
          </head>
          <body>
            <img src="${fileUrl}" alt="Image for print" />
          </body>
        </html>
        `);
      frameDoc.close();
      printFrame.contentWindow?.focus();
      printFrame.contentWindow?.print();
    }

    // Remove the iframe after printing
    document.body.removeChild(printFrame);
  };

  return (
    <div>
      <div className={styles.toolbar}>
        <div className={styles.actionsRow}>
          <IconButton
            icon={isFullScreen ? 'fullscreen_exit' : 'fullscreen'}
            onClick={onFullScreenToggle}
            symbolsOutlined
          />
        </div>
        <div className={styles.actionsRow}>
          <IconButton icon="print" onClick={handlePrint} symbolsOutlined />
          <IconButton
            icon="download"
            onClick={handleDownload}
            symbolsOutlined
          />
        </div>
      </div>
      <div className={styles.imageWrap}>
        <Image src={fileUrl} alt="Image" className={styles.image} />
      </div>
    </div>
  );
}
