import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { ApolloError } from '@apollo/client/errors';
import PageBody from '@lib/layouts/DashboardLayout/PageBody/PageBody';
import PageHeader from '@lib/layouts/DashboardLayout/PageHeader/PageHeader';
import Button, { ButtonTypes } from '@lib/components/Button/Button';
import Form from '@lib/components/ReactHookForm/FormContainer';
import { FieldItem, Values } from '@lib/interfaces/form';
import Modal from '@lib/components/Modal/Modal';
import CategoryForm from 'components/FormCategory/CategoryForm';
import { FormTemplateMode } from 'routes/FormTemplate/enums';
import ErrorPage from '@lib/components/ErrorPage/ErrorPage';
import getStatusFromError from '@lib/utils/getStatusFromError';
import FormTemplateFieldNames from '@lib/enums/fieldNames/formTemplateFieldNames';
import { FormRefType } from '@lib/components/ReactHookForm/types';
import FormTemplateBuilderActions from '../FormTemplateBuilderActions/FormTemplateBuilderActions';
import FormTemplateBuilderDefaultQuestions from '../FormTemplateBuilderDefaultQuestions/FormTemplateBuilderDefaultQuestions';
import getPrefilledQuestions from '../utils/getPrefilledQuestions';
import styles from './FormTemplateBuilderForm.module.scss';

interface Props {
  closeCategoryFormModal: () => void;
  formCategoryCreateMutationError?: ApolloError;
  formCategoryCreateMutationLoading: boolean;
  formFields: FieldItem[];
  formRef: FormRefType;
  formTemplateCreateMutationLoading: boolean;
  formTemplateData: Values;
  formTemplateVersionData: Values;
  formTemplateError?: ApolloError;
  formTemplateGraphQLErrors?: ApolloError;
  formTemplateLoading: boolean;
  formTemplateUpdateMutationLoading: boolean;
  isCategoryFormModalOpened: boolean;
  onCancelForm: () => void;
  onFormCategoryCreate: (values: Values) => void;
  onSubmitForm: (values: Values) => void;
  onViewHandler: () => void;
  viewMode: FormTemplateMode;
}

function FormTemplateBuilderForm(props: Props) {
  const {
    closeCategoryFormModal,
    formCategoryCreateMutationError,
    formCategoryCreateMutationLoading,
    formFields,
    formRef,
    formTemplateCreateMutationLoading,
    formTemplateData,
    formTemplateVersionData,
    formTemplateError,
    formTemplateGraphQLErrors,
    formTemplateLoading,
    formTemplateUpdateMutationLoading,
    isCategoryFormModalOpened,
    onCancelForm,
    onFormCategoryCreate,
    onSubmitForm,
    onViewHandler,
    viewMode,
  } = props;

  const { t } = useTranslation();

  const prefilledQuestions = getPrefilledQuestions({ t });

  const loading =
    formTemplateCreateMutationLoading ||
    formTemplateUpdateMutationLoading ||
    formTemplateLoading;

  if (formTemplateError) {
    return (
      <ErrorPage
        status={getStatusFromError(formTemplateError)}
        description={formTemplateError?.message}
      />
    );
  }

  const getPageTitle = () => {
    const srt = t('form-builder');
    if (viewMode === FormTemplateMode.Duplicate) {
      return `${srt} (${t('create-duplicate')})`;
    }
    return srt;
  };

  return (
    <div className={styles.builderGrid}>
      <div>
        <PageHeader title={getPageTitle()}>
          {viewMode === FormTemplateMode.Edit ||
          viewMode === FormTemplateMode.EditVersion ? (
            <Button
              buttonText={t('preview')}
              buttonType={ButtonTypes.primaryOutlined}
              leftIcon="visibility"
              onClick={onViewHandler}
            />
          ) : (
            <div />
          )}
        </PageHeader>
        <PageBody>
          <div>
            <Form
              defaultValues={{
                ...formTemplateData,
                questions: formTemplateVersionData?.questions,
              }}
              actionsComponent={FormTemplateBuilderActions}
              actionsComponentProps={{
                onCancelForm,
                viewMode,
              }}
              fields={formFields}
              formRef={formRef}
              onSubmit={onSubmitForm}
              renderAfterForm={
                viewMode === FormTemplateMode.Add ||
                viewMode === FormTemplateMode.Duplicate ||
                viewMode === FormTemplateMode.EditVersion
                  ? FormTemplateBuilderDefaultQuestions
                  : undefined
              }
              sourceFields={{
                questions: prefilledQuestions,
              }}
              loading={loading}
              graphQLErrors={formTemplateGraphQLErrors?.graphQLErrors}
              graphQLErrorsReplacePathMap={{
                'version.questions': FormTemplateFieldNames.Questions,
                questions: FormTemplateFieldNames.Questions,
              }}
            />
          </div>
        </PageBody>
      </div>
      <Modal
        isOpen={isCategoryFormModalOpened}
        title={t('create-new-form-category')}
        onClose={closeCategoryFormModal}
      >
        <CategoryForm
          defaultValues={{}}
          graphQLErrors={formCategoryCreateMutationError?.graphQLErrors}
          loading={formCategoryCreateMutationLoading}
          onCancel={closeCategoryFormModal}
          onSubmit={onFormCategoryCreate}
        />
      </Modal>
    </div>
  );
}

export default memo(FormTemplateBuilderForm);
