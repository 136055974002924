import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import TaskEdit from 'components/Task/TaskEdit';
import { FormRef } from '@lib/components/ReactHookForm/types';
import BreadCrumbs from '@lib/components/BreadCrumbs/BreadCrumbs';
import { getBreadCrumbsDataByKey } from 'utils/breadcrumbs';
import useSearchListQueries from 'routes/Task/utils/useSearchListQueries';
import getTaskFields from 'routes/Task/utils/useFields/getTaskFields';
import _get from 'lodash/get';
import useActions from './utils/useActions';

export default function TaskEditContainer() {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const formRef = useRef<FormRef>();

  const {
    onUpdate,
    onViewFormTemplate,
    taskData,
    taskError,
    taskLoading,
    updateMutationError,
    updateTaskLoading,
  } = useActions({
    formRef,
  });

  const {
    getApproversLoadOptions,
    getAssetsLoadOptions,
    getSiteAreasLoadOptions,
    getSitesLoadOptions,
    getTemplateVersionsLoadOptions,
    getTemplatesLoadOptions,
  } = useSearchListQueries();

  const canFormTemplateUpdate = _get(
    taskData,
    'formTemplate.canUpdate.value',
    false,
  );
  const formFields = getTaskFields({
    getApproversLoadOptions,
    getAssetsLoadOptions,
    getSiteAreasLoadOptions,
    getSitesLoadOptions,
    getTemplateVersionsLoadOptions: canFormTemplateUpdate
      ? getTemplateVersionsLoadOptions
      : undefined,
    getTemplatesLoadOptions: canFormTemplateUpdate
      ? getTemplatesLoadOptions
      : undefined,
    onViewFormTemplate,
    t,
  });

  const breadCrumbsData = getBreadCrumbsDataByKey({
    pathname,
    t,
    entity: { name: taskData?.name, id: taskData?.id },
  });

  return (
    <>
      <BreadCrumbs data={breadCrumbsData} />
      <TaskEdit
        updateMutationError={updateMutationError}
        updateTaskLoading={updateTaskLoading}
        formFields={formFields}
        formRef={formRef}
        onSubmit={onUpdate}
        taskData={taskData}
        taskError={taskError}
        taskLoading={taskLoading}
      />
    </>
  );
}
