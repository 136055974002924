import React, { useEffect, useState } from 'react';
import { GlobalWorkerOptions } from 'pdfjs-dist';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { printPlugin } from '@react-pdf-viewer/print';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import '@react-pdf-viewer/print/lib/styles/index.css';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/full-screen/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';
import IconButton from '@lib/components/IconButton/IconButton';
import Typography from '@lib/components/Typography/Typography';
import { downloadLinkId } from '@lib/enums/selectors';
import styles from './PDFViewer.module.scss';

const workerUrl =
  'https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js';

interface Props {
  fileUrl: string;
  downloadUrl?: string;
  onFullScreenToggle?: () => void;
  isFullScreen?: boolean;
}

export default function PDFViewer(props: Props) {
  const { fileUrl, downloadUrl, onFullScreenToggle, isFullScreen } = props;
  const [isLoading, setIsLoading] = useState(true);
  const printPluginInstance = printPlugin();
  const zoomPluginInstance = zoomPlugin();
  const pageNavigationPluginInstance = pageNavigationPlugin();

  const { print } = printPluginInstance;
  const { ZoomIn, ZoomOut, zoomTo } = zoomPluginInstance;
  const { GoToNextPage, GoToPreviousPage, CurrentPageLabel, NumberOfPages } =
    pageNavigationPluginInstance;

  const handleDownload = () => {
    const link = document.getElementById(downloadLinkId);
    if (downloadUrl && link) {
      link.setAttribute('href', downloadUrl);
      link.click();
      link.setAttribute('href', '');
    }
  };

  useEffect(() => {
    // Configure pdfjs-dist worker
    GlobalWorkerOptions.workerSrc = workerUrl;
  }, []);

  useEffect(() => {
    zoomTo(1);
  }, [isFullScreen, zoomTo]);

  return (
    <div>
      <div className={styles.toolbar}>
        <div className={styles.actionsRow}>
          <div className={styles.actionsRow}>
            <GoToPreviousPage>
              {({ onClick }) => (
                <IconButton
                  icon="navigate_before"
                  onClick={onClick}
                  symbolsOutlined
                  disabled={isLoading}
                />
              )}
            </GoToPreviousPage>
            <Typography variant="body">
              {isLoading ? 0 : <CurrentPageLabel />} / <NumberOfPages />
            </Typography>
            <GoToNextPage>
              {({ onClick }) => (
                <IconButton
                  icon="navigate_next"
                  onClick={onClick}
                  symbolsOutlined
                  disabled={isLoading}
                />
              )}
            </GoToNextPage>
          </div>
          {onFullScreenToggle && (
            <IconButton
              icon={isFullScreen ? 'fullscreen_exit' : 'fullscreen'}
              onClick={onFullScreenToggle}
              symbolsOutlined
              disabled={isLoading}
            />
          )}
          <ZoomIn>
            {({ onClick }) => (
              <IconButton
                icon="zoom_in"
                onClick={onClick}
                symbolsOutlined
                disabled={isLoading}
              />
            )}
          </ZoomIn>
          <ZoomOut>
            {({ onClick }) => (
              <IconButton
                icon="zoom_out"
                onClick={onClick}
                symbolsOutlined
                disabled={isLoading}
              />
            )}
          </ZoomOut>
        </div>
        <div className={styles.actionsRow}>
          <IconButton
            icon="print"
            onClick={print}
            symbolsOutlined
            disabled={isLoading}
          />
          <IconButton
            icon="download"
            onClick={handleDownload}
            symbolsOutlined
            disabled={isLoading}
          />
        </div>
      </div>
      <Worker workerUrl={workerUrl}>
        <div
          className={isFullScreen ? styles.viewerWrapFull : styles.viewerWrap}
        >
          <Viewer
            fileUrl={fileUrl}
            plugins={[
              pageNavigationPluginInstance,
              printPluginInstance,
              zoomPluginInstance,
            ]}
            onDocumentLoad={() => {
              setIsLoading(false);
            }}
          />
        </div>
      </Worker>
    </div>
  );
}
