import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Chart from 'react-apexcharts';
import { CounterMeasureGraphData } from 'graphql-common';
import ListEmptyState from '@lib/components/ListEmptyState/ListEmptyState';
import Button, { ButtonTypes } from '@lib/components/Button/Button';
import CircleLoader from '@lib/components/CircleLoader/CircleLoader';
import WhiteBox from '@lib/components/WhiteBox/WhiteBox';
import mapDataToChart from './utils/mapDataToChart';
import './AssetCounterMeasuresGraphView.module.scss';

type Props = {
  data?: CounterMeasureGraphData[];
  loading?: boolean;
  onAddIndex: () => void;
};

export default function AssetCounterMeasuresGraphView(props: Props) {
  const { onAddIndex, data = [], loading } = props;
  const { t } = useTranslation();

  const isEmpty = !data || data.length === 0;
  const chartData = mapDataToChart(data, t);

  if (loading) {
    return <CircleLoader />;
  }

  if (isEmpty) {
    return (
      <ListEmptyState
        title={t('no-measures-yet')}
        text={<Trans i18nKey="no-measures-text" components={{ br: <br /> }} />}
        actions={[
          <Button
            key="add"
            leftIcon="add"
            buttonType={ButtonTypes.primaryFilled}
            buttonText={t('add-index')}
            onClick={onAddIndex}
            fullWidth
          />,
        ]}
      />
    );
  }

  return (
    <WhiteBox verticalPaddings="small" horizontalPaddings="small">
      <Chart
        options={chartData.options}
        series={chartData.series}
        type="line"
        width="100%"
      />
    </WhiteBox>
  );
}
