import {
  CheckboxButtonGridValue,
  CheckboxButtonGroupOption,
} from '@lib/components/CheckboxButtonGrid/types';

export default function isValueExists(
  fieldValue: CheckboxButtonGridValue,
  row: CheckboxButtonGroupOption,
  column: CheckboxButtonGroupOption,
) {
  return !!(
    row.id &&
    column.id &&
    fieldValue &&
    fieldValue.some((fieldValueItem) => {
      return (
        fieldValueItem.valueId === column.id &&
        fieldValueItem.optionId === row.id
      );
    })
  );
}
