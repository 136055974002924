import React from 'react';
import {
  components as DefaultSelectComponents,
  MultiValueRemoveProps,
} from 'react-select';
import MaterialIcon from '@lib/components/MaterialIcon/MaterialIcon';

function MultiValueRemove({ children, ...props }: MultiValueRemoveProps) {
  return (
    <DefaultSelectComponents.MultiValueRemove {...props}>
      <MaterialIcon icon="cancel" size="md-24" />
    </DefaultSelectComponents.MultiValueRemove>
  );
}

export default MultiValueRemove;
