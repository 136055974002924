import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ComponentEnum, LevelEnum, Team } from 'graphql-common';
import BreadCrumbs from '@lib/components/BreadCrumbs/BreadCrumbs';
import ConfirmationModal from '@lib/components/ConfirmationModal/ConfirmationModal';
import Trans from '@lib/components/Trans/Trans';
import ErrorPage from '@lib/components/ErrorPage/ErrorPage';
import getStatusFromError from '@lib/utils/getStatusFromError';
import { useTableItemsBulkSelect } from '@lib/hooks/useTableItemsBulkSelect';
import useModalManagement, { Modals } from '@lib/hooks/useModalManagement';
import useTeamQueries from 'routes/Teams/utils/useTeamQueries';
import useOutletContext, { ContextProps } from 'hooks/useOutletContext';
import { IconName } from '@lib/components/Modal/enums';
import TeamFormPage from './TeamFormPage/TeamFormPage';
import TeamViewPage from './TeamViewPage/TeamViewPage';
import useTeamActions from './utils/useTeamActions';
import { TeamViewMode } from './enums';

const modals = [
  Modals.UserFormModal,
  Modals.UserDeleteModal,
  Modals.TeamDestroyModal,
];

type Props = {
  viewMode: TeamViewMode;
};

export default function TeamContainer({ viewMode }: Props) {
  const { t } = useTranslation();

  const { getUserAccessByComponent }: ContextProps = useOutletContext();
  const isViewOnly =
    getUserAccessByComponent(ComponentEnum.UsersManagement) === LevelEnum.Read;

  const useTeamQueriesResult = useTeamQueries({
    viewMode,
  });
  const { useUsersQueryHookResult, useTeamQueryHookResult } =
    useTeamQueriesResult;

  const useBulkSelectResult = useTableItemsBulkSelect<Team>();
  const useModalManagementResult = useModalManagement(modals, () =>
    useBulkSelectResult.handleSelectSingleTableItem(null),
  );
  const useTeamActionsResult = useTeamActions({
    viewMode,
    useTeamQueryHookResult,
    useModalManagementResult,
    useUsersQueryHookResult,
  });
  const { breadCrumbsData, onConfirmedTeamDestroy, onViewChange } =
    useTeamActionsResult;
  const { closeAllModals, isModalOpened } = useModalManagementResult;

  const isForm =
    viewMode === TeamViewMode.Add || viewMode === TeamViewMode.Edit;
  const isView =
    viewMode === TeamViewMode.ViewDetails ||
    viewMode === TeamViewMode.ViewMembers;
  const canUpdate = useTeamQueryHookResult?.data?.canUpdate;

  useEffect(() => {
    if (viewMode === TeamViewMode.Edit && !!canUpdate && !canUpdate.value) {
      onViewChange(TeamViewMode.ViewDetails);
    }
  }, [canUpdate, onViewChange, viewMode]);

  if (useTeamQueryHookResult.error) {
    return (
      <ErrorPage
        status={getStatusFromError(useTeamQueryHookResult.error)}
        description={useTeamQueryHookResult.error?.message}
      />
    );
  }

  return (
    <>
      <BreadCrumbs data={breadCrumbsData} />
      {isForm && (
        <TeamFormPage
          useTeamActionsResult={useTeamActionsResult}
          useModalManagementResult={useModalManagementResult}
          useTeamQueriesResult={useTeamQueriesResult}
        />
      )}
      {isView && (
        <TeamViewPage
          viewMode={viewMode}
          useTeamActionsResult={useTeamActionsResult}
          useModalManagementResult={useModalManagementResult}
          useTeamQueriesResult={useTeamQueriesResult}
          isViewOnly={isViewOnly}
        />
      )}
      <ConfirmationModal
        confirmButtonLabel={t('yes-delete')}
        description={<Trans i18nKey="team-delete-text" />}
        icon={IconName.Delete}
        isOpen={isModalOpened(Modals.TeamDestroyModal)}
        onClose={closeAllModals}
        onConfirm={onConfirmedTeamDestroy}
        title={t('are-you-sure')}
      />
    </>
  );
}
