import React from 'react';
import { TFunction } from 'i18next';
import Button, { ButtonTypes } from '@lib/components/Button/Button';
import { materialSymbolsOutlined } from '@lib/enums/common';

type Args = {
  getQrCodesForSelected: () => void;
  onBulkDelete: () => void;
  selectedItemsCount?: number;
  t: TFunction<'translation', undefined>;
  isViewOnly: boolean;
};

export default function getTableActionsForSelected(args: Args) {
  const {
    getQrCodesForSelected,
    onBulkDelete,
    selectedItemsCount = 0,
    t,
    isViewOnly,
  } = args;
  return [
    <Button
      key="bulk-codes"
      buttonText={selectedItemsCount > 1 ? t('get-qr-codes') : t('get-qr-code')}
      buttonType={ButtonTypes.primaryOutlined}
      onClick={getQrCodesForSelected}
      leftIcon="qr_code"
    />,
    isViewOnly ? undefined : (
      <Button
        key="bulk-delete"
        buttonText={`${t('delete-selected')} (${selectedItemsCount})`}
        buttonType={ButtonTypes.negativeOutlined}
        leftIcon="delete"
        leftIconClassName={materialSymbolsOutlined}
        onClick={onBulkDelete}
      />
    ),
  ];
}
