import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import _get from 'lodash/get';
import _replace from 'lodash/replace';
import { FormTemplateQuestionFieldsKindEnum } from 'graphql-common';
import FormItem from '@lib/components/ReactHookForm/FormItem';
import CheckBoxSingleBool from '@lib/components/CheckBoxSingleBool/CheckBoxSingleBool';
import formStyles from '@lib/components/ReactHookForm/Form.module.scss';
import QuestionGeneralField from 'components/FormTemplateBuilder/QuestionGeneralField/QuestionGeneralField';
import QuestionCheckboxButtonGridField from 'components/FormTemplateBuilder/QuestionCheckboxButtonGridField/QuestionRadioButtonGridField';
import {
  AppendValue,
  FieldArrayItemRenderAddMore,
  FieldArrayItemRenderEmptyState,
  FieldArrayItemType,
} from '@lib/components/ReactHookForm/types';
import FormTemplateFieldNames from '@lib/enums/fieldNames/formTemplateFieldNames';
import {
  kindFieldsWithAllowDisplayLastAnswer,
  kindFieldsWithResponseLimitByGroup,
} from 'components/FormTemplateBuilder/constants';
import QuestionMinMaxField from '../QuestionMinMaxField/QuestionMinMaxField';

interface ChildrenProps {
  fieldItem: FieldArrayItemType;
  index: number;
}

interface Props {
  appendValue?: AppendValue;
  children: (props: ChildrenProps) => React.ReactNode;
  fields: FieldArrayItemType[];
  parentName: string;
  renderAddMore: FieldArrayItemRenderAddMore;
  renderEmptyState: FieldArrayItemRenderEmptyState;
}

function QuestionField(props: Props) {
  const {
    appendValue,
    children,
    fields,
    parentName,
    renderAddMore,
    renderEmptyState,
  } = props;
  const { t } = useTranslation();
  const { getValues } = useFormContext();
  const values = getValues();
  const kind = _get(
    values,
    _replace(
      parentName,
      `.${FormTemplateFieldNames.Options}`,
      `.${FormTemplateFieldNames.Kind}`,
    ),
    {},
  );

  const isAllowDisplayLastAnswerFieldVisible =
    kindFieldsWithAllowDisplayLastAnswer.includes(kind);
  const isAllowResponseLimits =
    kindFieldsWithResponseLimitByGroup.includes(kind);
  const allowDisplayLastAnswerField = isAllowDisplayLastAnswerFieldVisible ? (
    <FormItem
      name={_replace(
        parentName,
        FormTemplateFieldNames.Options,
        FormTemplateFieldNames.AllowDisplayLastAnswer,
      )}
      element={CheckBoxSingleBool}
      componentProps={{
        id: `${parentName}-${FormTemplateFieldNames.AllowDisplayLastAnswer}`,
        label: t('allow-last-statement'),
      }}
    />
  ) : undefined;
  const allowResponseLimits = isAllowResponseLimits ? (
    <>
      <FormItem
        name={_replace(
          parentName,
          FormTemplateFieldNames.Options,
          FormTemplateFieldNames.LimitToOneRespPerRow,
        )}
        element={CheckBoxSingleBool}
        componentProps={{
          id: `${parentName}-${FormTemplateFieldNames.LimitToOneRespPerRow}`,
          label: t('allow-limit-response-per-row'),
        }}
        hideErrorsSpace
      />
      <FormItem
        name={_replace(
          parentName,
          FormTemplateFieldNames.Options,
          FormTemplateFieldNames.LimitToOneRespPerColumn,
        )}
        element={CheckBoxSingleBool}
        componentProps={{
          id: `${parentName}-${FormTemplateFieldNames.LimitToOneRespPerColumn}`,
          label: t('allow-limit-response-per-col'),
        }}
      />
    </>
  ) : undefined;
  const renderSettings =
    isAllowDisplayLastAnswerFieldVisible || isAllowResponseLimits ? (
      <div className={formStyles.formItems}>
        {allowDisplayLastAnswerField}
        {allowResponseLimits}
      </div>
    ) : undefined;

  if (kind === FormTemplateQuestionFieldsKindEnum.MinMax) {
    return (
      <QuestionMinMaxField
        fields={fields}
        parentName={parentName}
        renderAddMore={renderAddMore}
        renderSettings={renderSettings}
        renderEmptyState={renderEmptyState}
      >
        {children}
      </QuestionMinMaxField>
    );
  }
  if (kind === FormTemplateQuestionFieldsKindEnum.CheckboxButtonGrid) {
    return (
      <QuestionCheckboxButtonGridField
        fields={fields}
        parentName={parentName}
        renderAddMore={renderAddMore}
        renderSettings={renderSettings}
      >
        {children}
      </QuestionCheckboxButtonGridField>
    );
  }
  return (
    <QuestionGeneralField
      appendValue={appendValue}
      fields={fields}
      kind={kind}
      renderAddMore={renderAddMore}
      renderSettings={renderSettings}
      renderEmptyState={renderEmptyState}
    >
      {children}
    </QuestionGeneralField>
  );
}

export default memo(QuestionField);
