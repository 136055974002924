import { GroupEnum } from 'graphql-common';
import { AppendValue } from '@lib/components/ReactHookForm/types';
import FormTemplateFieldNames from '@lib/enums/fieldNames/formTemplateFieldNames';

export default function getAppendValue(
  group: GroupEnum,
  lastPosition: number,
): AppendValue {
  return {
    [FormTemplateFieldNames.Group]: group,
    [FormTemplateFieldNames.Values]: [''],
    [FormTemplateFieldNames.Position]: lastPosition,
  };
}
