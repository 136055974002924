import React, { memo } from 'react';
import _get from 'lodash/get';
import classNames from 'classnames';
import { Attachment } from 'graphql-common';
import Image from '@lib/components/Image/Image';
import Typography from '@lib/components/Typography/Typography';
import { formatBytes, getFormatFromMimeType } from '@lib/utils/files';
import PdfPreviewPlaceholderBlock from '@lib/assets/images/pdf-preview-placeholder-block.svg';
import PdfPreviewPlaceholder from '@lib/assets/images/pdf-preview-placeholder.svg';
import { DocumentationPreviewEnum } from './enums';
import styles from './DocumentationPreview.module.scss';

type Props = {
  customName?: string;
  attachment: Attachment | null;
  onClick?: () => void;
  useDefaultPdfPreview?: boolean;
  view?: DocumentationPreviewEnum;
};

function DocumentationPreview(props: Props) {
  const {
    customName,
    attachment,
    onClick,
    useDefaultPdfPreview,
    view = DocumentationPreviewEnum.RowCard,
  } = props;
  if (!attachment) return null;
  const mimeType =
    (_get(attachment, 'file.metadata.mimeType', '') as string) ||
    (_get(attachment, 'fileData.data.metadata.mimeType', '') as string);
  const size =
    (_get(attachment, 'file.metadata.size', 0) as number) ||
    (_get(attachment, 'fileData.data.metadata.size', 0) as number);
  const filename =
    customName ||
    (_get(attachment, 'filename', '') as string) ||
    (_get(attachment, 'file.metadata.filename', '') as string) ||
    (_get(attachment, 'fileData.data.metadata.filename', '') as string);
  const fileFormat = getFormatFromMimeType(mimeType);

  const defaultPdfPreview =
    view === DocumentationPreviewEnum.BlockCard
      ? PdfPreviewPlaceholderBlock
      : PdfPreviewPlaceholder;

  const getImgUrl = () => {
    if (useDefaultPdfPreview && fileFormat === 'pdf') {
      return defaultPdfPreview;
    }
    if (fileFormat === 'pdf') {
      return (
        _get(attachment, 'url') ||
        _get(attachment, ['urls', 'imagePreview']) ||
        defaultPdfPreview
      );
    }
    return (
      _get(attachment, 'url') ||
      _get(attachment, ['urls', 'imagePreview']) ||
      _get(attachment, ['urls', 'small']) ||
      _get(attachment, ['fileData', 'url'])
    );
  };

  return (
    <div
      className={classNames({
        [styles.rowCard]: view === DocumentationPreviewEnum.RowCard,
        [styles.blockCard]: view === DocumentationPreviewEnum.BlockCard,
      })}
    >
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div
        className={classNames(styles.documentationPreview, {
          [styles.documentationPreviewLink]: onClick,
        })}
        onClick={onClick}
      >
        <div className={styles.documentationPreviewImage}>
          <Image src={getImgUrl()} />
          <svg viewBox="0 0 1 1" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="1" height="1" />
          </svg>
        </div>
        <div className={styles.documentationPreviewInfoWrap}>
          <Typography
            variant="body"
            className={styles.documentationPreviewName}
          >
            {filename}
          </Typography>
          <div className={styles.documentationPreviewInfo}>
            {mimeType && (
              <Typography variant="caption">
                {fileFormat.toUpperCase()}
              </Typography>
            )}
            {mimeType && size && (
              <span className={styles.documentationPreviewInfoSeparator} />
            )}
            {size && (
              <Typography variant="caption">{formatBytes(size)}</Typography>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(DocumentationPreview);
