import { CounterMeasureGraphData } from 'graphql-common';
import { TFunction } from 'i18next';
import { ApexOptions } from 'apexcharts';
import { getFormattedDate } from '@lib/utils/dateTimeHelpers';
import { dateFormat } from '@lib/enums/dateTime';
import fr from 'apexcharts/dist/locales/fr.json';
import en from 'apexcharts/dist/locales/en.json';

export default function mapDataToChart(
  data: CounterMeasureGraphData[],
  t: TFunction<'translation', undefined>,
): { options: ApexCharts.ApexOptions; series: ApexOptions['series'] } {
  const formattedCategories = data.map(
    (item) => getFormattedDate(item.createdAt, dateFormat), // Ensure proper date formatting here
  );
  return {
    options: {
      chart: {
        id: 'counter-measure-bar',
        locales: [fr, en],
        defaultLocale: 'en',
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: false,
            selection: true,
            zoom: false,
            zoomin: true,
            zoomout: true,
            pan: false,
            reset: false,
            customIcons: [],
          },
          autoSelected: 'zoom',
        },
        animations: {
          enabled: true,
          easing: 'easeinout',
          speed: 800,
          animateGradually: {
            enabled: true,
            delay: 150,
          },
          dynamicAnimation: {
            enabled: false,
            speed: 350,
          },
        },
        fontFamily: 'Inter',
      },
      xaxis: {
        categories: formattedCategories,
        labels: {
          style: {
            colors: '#5A5A5A',
            fontSize: '10px',
          },
        },
        axisBorder: {
          color: '#D6D8F0',
        },
        axisTicks: {
          color: '#D6D8F0',
        },
        tooltip: {
          enabled: false,
        },
      },
      yaxis: [
        {
          title: {
            text: t('gap'),
            offsetX: -8,
            style: {
              color: '#214EEB',
              fontSize: '13px',
            },
          },
          labels: {
            style: {
              colors: '#214EEB',
              fontSize: '10px',
            },
          },
        },
        {
          title: {
            text: t('cumulative-usage'),
            offsetX: 8,
            style: {
              color: '#00B67A',
              fontSize: '13px',
            },
          },
          labels: {
            style: {
              colors: '#00B67A',
              fontSize: '10px',
            },
          },
          opposite: true,
        },
      ],
      colors: ['#214EEB', '#00B67A'],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
        width: [0, 3],
      },
      fill: {
        opacity: [0.85, 1],
      },
      grid: {
        borderColor: '#D6D8F0',
        strokeDashArray: 4,
      },
      legend: {
        position: 'bottom',
        fontSize: '13px',
        horizontalAlign: 'center',
        labels: {
          colors: '#24273A',
        },
        markers: {
          size: 8,
          strokeWidth: 0,
          offsetX: -8,
          offsetY: 0,
        },
        itemMargin: {
          horizontal: 16,
          vertical: 0,
        },
        onItemClick: {
          toggleDataSeries: false,
        },
        onItemHover: {
          highlightDataSeries: false,
        },
      },
      markers: {
        size: 5,
        colors: ['#00B67A'],
        onClick: undefined,
      },
      tooltip: {
        intersect: false,
      },
    },
    series: [
      {
        name: t('gap'),
        type: 'column',
        data: data.map((item) => item.gap),
      },
      {
        name: t('cumulative-usage'),
        type: 'line',
        data: data.map((item) => item.cumulativeUsage),
      },
    ],
  };
}
