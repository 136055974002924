import { User } from 'graphql-common';
import { IconButtonTypes } from '@lib/components/IconButton/IconButton';

type Args = {
  currentUserId: string;
  isViewOnly: boolean;
  openUserEditModal: (item: User) => void;
  openUserViewPage: (item: User) => void;
};

export default function getTableActions(args: Args) {
  const { isViewOnly, currentUserId, openUserEditModal, openUserViewPage } =
    args;
  return isViewOnly
    ? [
        {
          id: 'view-action',
          onClick: openUserViewPage,
          icon: 'visibility',
          type: IconButtonTypes.PrimaryOutlined,
          predictorFnDisable: (item: User) => currentUserId === item.id,
        },
      ]
    : [
        {
          id: 'edit-action',
          onClick: openUserEditModal,
          icon: 'edit',
          type: IconButtonTypes.PrimaryOutlined,
          predictorFnDisable: (item: User) => currentUserId === item.id,
        },
      ];
}
