import _get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import { FetchResult } from '@apollo/client';
import {
  FormTemplateQuery,
  Task,
  useFormTemplateVersionArchiveMutation,
  useFormTemplateVersionCreateMutation,
  useFormTemplateVersionDestroyMutation,
  useFormTemplateVersionRestoreMutation,
  useFormTemplateVersionUpdateMutation,
  useTaskBulkTemplateVersionUpdateMutation,
} from 'graphql-common';
import { APP_URLS } from 'constants/urls';
import toast from '@lib/components/Toast/Toast';
import { showGraphQLErrors } from '@lib/utils/graphQLErrors';
import { useNavigate } from 'react-router-dom';
import { UseBulkSelectResult } from '@lib/hooks/useTableItemsBulkSelect';
import { UseLoadingStateResult } from '@lib/hooks/useLoadingState';
import { FormTemplateTasksQueryHookResult } from 'utils/fetch/useFormTemplateTasksQueryHook';

type Props = {
  formTemplateRefetch: () => Promise<FetchResult<FormTemplateQuery>>;
  formTemplateRefetchHandler: () => void;
  id: string;
  tasksQueryHook: FormTemplateTasksQueryHookResult;
  useBulkSelectResult: UseBulkSelectResult<Task>;
  useLoadingStateResult: UseLoadingStateResult;
  versionId: string;
};

export default function useTemplateVersionMutations(props: Props) {
  const {
    formTemplateRefetch,
    formTemplateRefetchHandler,
    id,
    tasksQueryHook,
    useBulkSelectResult,
    useLoadingStateResult,
    versionId,
  } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [
    formTemplateVersionCreateMutation,
    formTemplateVersionCreateMutationResult,
  ] = useFormTemplateVersionCreateMutation({
    onCompleted: (response) => {
      const { id: newId } = { ...response.data };
      formTemplateRefetchHandler();
      if (newId) {
        navigate(
          APP_URLS.dashboard.formTemplates.viewVersion.getDynamicPath({
            pathParts: {
              id,
              versionId: newId,
            },
          }),
          { replace: true },
        );
      } else {
        navigate(APP_URLS.dashboard.formTemplates.index.getPathWithQuery());
      }
      toast({ content: t('create-form-template-version-success') });
    },
    onError: (error) => showGraphQLErrors(error, t),
  });

  const [
    formTemplateVersionUpdateMutation,
    formTemplateVersionUpdateMutationResult,
  ] = useFormTemplateVersionUpdateMutation({
    onCompleted: () => {
      navigate(
        APP_URLS.dashboard.formTemplates.viewVersion.getDynamicPath({
          pathParts: {
            id,
            versionId,
          },
        }),
      );
      toast({ content: t('update-form-template-version-success') });
    },
    onError: (error) => showGraphQLErrors(error, t),
  });

  const [formTemplateVersionArchiveMutation] =
    useFormTemplateVersionArchiveMutation({
      onCompleted: () => {
        formTemplateRefetchHandler();
      },
      onError: (error) => showGraphQLErrors(error, t),
    });

  const [formTemplateVersionRestoreMutation] =
    useFormTemplateVersionRestoreMutation({
      onCompleted: () => {
        toast({ content: t('restore-form-template-version-success') });
        formTemplateRefetchHandler();
      },
      onError: (error) => showGraphQLErrors(error, t),
    });

  const [formTemplateVersionDestroyMutation] =
    useFormTemplateVersionDestroyMutation({
      onCompleted: () => {
        toast({ content: t('destroy-form-template-version-success') });
        formTemplateRefetch()
          .then((res) => {
            const nextCurrentVersionId = _get(res, [
              'data',
              'data',
              'currentVersion',
              'id',
            ]);
            useLoadingStateResult.setLoadingState(false);
            if (nextCurrentVersionId) {
              navigate(
                APP_URLS.dashboard.formTemplates.viewVersion.getDynamicPath({
                  pathParts: { id, versionId: nextCurrentVersionId },
                }),
              );
            } else {
              navigate(
                APP_URLS.dashboard.formTemplates.index.getPathWithQuery(),
              );
            }
          })
          .catch(() => {
            navigate(APP_URLS.dashboard.formTemplates.index.getPathWithQuery());
          });
      },
      onError: (error) => {
        showGraphQLErrors(error, t);
        useLoadingStateResult.setLoadingState(false);
      },
    });

  const [taskBulkTemplateVersionUpdateMutation] =
    useTaskBulkTemplateVersionUpdateMutation({
      onCompleted: () => {
        toast({ content: t('update-tasks-form-template-version-success') });
        tasksQueryHook.refetchTasks().finally(() => {
          useLoadingStateResult.setLoadingState(false);
          useBulkSelectResult.handleUnselectAllTableItems();
        });
      },
      onError: (error) => {
        showGraphQLErrors(error, t);
        useLoadingStateResult.setLoadingState(false);
      },
    });

  return {
    formTemplateVersionArchiveMutation,
    formTemplateVersionCreateMutation,
    formTemplateVersionCreateMutationResult,
    formTemplateVersionDestroyMutation,
    formTemplateVersionRestoreMutation,
    formTemplateVersionUpdateMutation,
    formTemplateVersionUpdateMutationResult,
    taskBulkTemplateVersionUpdateMutation,
  };
}
