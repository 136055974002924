import { TFunction } from 'i18next';
import { Documentation } from 'graphql-common';
import { NavItem, NavItemTypeEnum } from '@lib/components/Menu/Menu';
import Icons from '@lib/components/Icons/Icons';

type ActionsMenuArgs = {
  onDelete: (v?: Documentation) => void;
  onDownload: (v?: Documentation) => void;
  onEdit: (v?: Documentation) => void;
  t: TFunction<'translation', undefined>;
  isViewOnly: boolean;
};

export default function getTableActionsMenu({
  onDelete,
  onDownload,
  onEdit,
  t,
  isViewOnly,
}: ActionsMenuArgs): NavItem<Documentation>[] {
  return [
    {
      id: 'edit-action',
      title: t('edit'),
      onClick: (item?: Documentation) => onEdit(item),
      leftIcon: Icons.edit,
      type: NavItemTypeEnum.Secondary,
      predictorFnVisible: () => !isViewOnly,
    },
    {
      id: 'download',
      title: t('download'),
      onClick: (item?: Documentation) => onDownload(item),
      leftIcon: Icons.download,
      type: NavItemTypeEnum.Secondary,
    },
    {
      id: 'delete-action',
      title: t('delete'),
      onClick: (item?: Documentation) => onDelete(item),
      leftIcon: Icons.delete,
      type: NavItemTypeEnum.Secondary,
      predictorFnVisible: () => !isViewOnly,
    },
  ];
}
