import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormTemplateQuestionFieldsKindEnum, Task } from 'graphql-common';
import { ApolloError } from '@apollo/client/errors';
import PageBody from '@lib/layouts/DashboardLayout/PageBody/PageBody';
import TaskMenuBar from 'components/TaskMenuBar/TaskMenuBar';
import WhiteBox from '@lib/components/WhiteBox/WhiteBox';
import Typography from '@lib/components/Typography/Typography';
import CircleLoader from '@lib/components/CircleLoader/CircleLoader';
import ErrorPage from '@lib/components/ErrorPage/ErrorPage';
import TaskReportingViewByQuestion from 'components/TaskReporting/TaskReportingViewByQuestion';
import EmptyState from '@lib/components/EmptyState/EmptyState';
import getStatusFromError from '@lib/utils/getStatusFromError';
import _get from 'lodash/get';
import entityViewStyles from '@lib/assets/styles/EntityView.module.scss';
import classNames from 'classnames';
import FormTemplateVersionSelect from 'components/FormTemplateVersionSelect/FormTemplateVersionSelect';
import { InputSizes } from '@lib/components/Input/enums';
import VersionIcon from '@lib/assets/icons/version_icon.svg?react';
import { SelectProps } from '@lib/components/Select/Select';
import { SelectOption } from '@lib/components/Select/types';
import ChipControl from '@lib/components/Select/components/ChipControl';
import ChipValueContainerForChipControl from '@lib/components/Select/components/ChipValueContainerForChipControl';
import { IconName } from '@lib/components/Modal/enums';
import styles from './TaskReportingView.module.scss';

type ReportingInfo = {
  kind: FormTemplateQuestionFieldsKindEnum;
  position: number;
  fieldId: string;
  questionName?: string | null;
  questionDescription?: string | null;
};

type Props = {
  id?: string;
  onVersionChange: SelectProps['onChange'];
  reportingError?: ApolloError;
  reportingInfo: ReportingInfo[];
  reportingLoading?: boolean;
  selectedVersionOption: SelectOption;
  taskData?: Task;
  taskError?: ApolloError;
  taskLoading?: boolean;
};

export function TaskReportingView(props: Props) {
  const { t } = useTranslation();
  const {
    id,
    onVersionChange,
    reportingError,
    reportingInfo,
    reportingLoading,
    selectedVersionOption,
    taskData,
    taskError,
    taskLoading,
  } = props;
  const taskName = _get(taskData, 'name');

  const formTemplateId = _get(taskData, 'formTemplate.id', '');
  const templateVersionId =
    (selectedVersionOption?.value as string) ||
    _get(taskData, 'formTemplateVersion.id', '');

  if (taskError) {
    return (
      <ErrorPage
        status={getStatusFromError(taskError)}
        description={taskError?.message}
      />
    );
  }
  if (reportingError) {
    return (
      <ErrorPage
        status={getStatusFromError(reportingError)}
        description={reportingError?.message}
      />
    );
  }
  if (!id) {
    return <ErrorPage status={404} />;
  }

  return (
    <PageBody withoutHeader>
      <TaskMenuBar id={id} />
      {taskLoading ? (
        <CircleLoader />
      ) : (
        <>
          {taskName && (
            <WhiteBox>
              <Typography variant="h2">{taskName}</Typography>
              <hr className={entityViewStyles.hrLine} />
              <div
                className={classNames(
                  entityViewStyles.infoItem,
                  entityViewStyles.infoItemCentered,
                )}
              >
                <p className={entityViewStyles.infoItemLabel}>
                  <VersionIcon />
                  <Typography variant="caption">{t('version')}</Typography>
                </p>
                <div className={entityViewStyles.infoItemContentBox}>
                  {selectedVersionOption ? (
                    <FormTemplateVersionSelect
                      formTemplateId={formTemplateId}
                      onChange={onVersionChange}
                      value={selectedVersionOption}
                      components={{
                        Control: ChipControl,
                        ValueContainer: ChipValueContainerForChipControl,
                      }}
                      size={InputSizes.large}
                    />
                  ) : (
                    <Typography variant="body">-</Typography>
                  )}
                </div>
              </div>
            </WhiteBox>
          )}
          {reportingLoading ? (
            <CircleLoader />
          ) : (
            <div>
              {reportingInfo?.length ? (
                reportingInfo.map(
                  ({
                    kind,
                    fieldId,
                    position,
                    questionName,
                    questionDescription,
                  }) => (
                    <TaskReportingViewByQuestion
                      fieldId={fieldId}
                      key={position}
                      kind={kind}
                      questionDescription={questionDescription}
                      questionName={questionName}
                      taskId={id}
                      templateVersionId={templateVersionId}
                    />
                  ),
                )
              ) : (
                <EmptyState
                  icon={IconName.Cancel}
                  text={t('no-task-reporting-text')}
                  title={t('no-task-reporting-yet')}
                  rootClassName={styles.emptyState}
                />
              )}
            </div>
          )}
        </>
      )}
    </PageBody>
  );
}
