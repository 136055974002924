import { TFunction } from 'i18next';
import { NavItem, NavItemTypeEnum } from '@lib/components/Menu/Menu';
import { Counter } from 'graphql-common';
import Icons from '@lib/components/Icons/Icons';

type ActionsMenuArgs = {
  onAddIndex: (v?: Counter) => void;
  onCreateIntervention: (v?: Counter) => void;
  t: TFunction<'translation', undefined>;
};

export default function getCounterActionsMenu({
  onAddIndex,
  onCreateIntervention,
  t,
}: ActionsMenuArgs): NavItem<Counter>[] {
  return [
    {
      id: 'add-index',
      title: t('add-index'),
      onClick: (item) => onAddIndex(item),
      leftIcon: Icons.add,
      type: NavItemTypeEnum.Secondary,
    },
    {
      id: 'create-intervention',
      title: t('create-intervention'),
      onClick: (item) => onCreateIntervention(item),
      leftIcon: Icons.build,
      type: NavItemTypeEnum.Secondary,
    },
  ];
}
