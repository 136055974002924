import _get from 'lodash/get';
import {
  DashboardFullTaskQueryResult,
  DashboardTaskFormTemplateQueryResult,
  DashboardTaskQueryResult,
  DashboardTaskScheduleQueryResult,
  FormTemplate,
  FormTemplateVersion,
  Task,
  TaskBulkCreateSameMutationResult,
  TaskBulkSchedulingMutationResult,
  TaskBulkUpdateStatusMutationResult,
  TaskUpdateMutationResult,
} from 'graphql-common';

type Args = {
  createMutationResult?: TaskBulkCreateSameMutationResult;
  fullTaskQuery: DashboardFullTaskQueryResult;
  taskBulkSchedulingResult: TaskBulkSchedulingMutationResult;
  taskBulkUpdateStatusResult: TaskBulkUpdateStatusMutationResult;
  taskFormTemplateQuery: DashboardTaskFormTemplateQueryResult;
  taskQuery: DashboardTaskQueryResult;
  taskScheduleQuery: DashboardTaskScheduleQueryResult;
  updateMutationResult: TaskUpdateMutationResult;
};

export default function getData(args: Args) {
  const {
    createMutationResult,
    fullTaskQuery,
    taskBulkSchedulingResult,
    taskBulkUpdateStatusResult,
    taskFormTemplateQuery,
    taskQuery,
    taskScheduleQuery,
    updateMutationResult,
  } = args;
  const taskData = _get(taskQuery, 'data.data') as Task;
  const fullTaskData = _get(fullTaskQuery, 'data.data') as Task;
  const taskScheduleData = _get(taskScheduleQuery, 'data.data') as Task;
  const taskDataSiteId =
    _get(taskData, 'site.id') || _get(fullTaskData, 'site.id');
  const taskDataSiteAreaId =
    _get(taskData, 'siteArea.id') || _get(fullTaskData, 'siteArea.id');
  const taskDataForFormTemplate = _get(
    taskFormTemplateQuery,
    'data.data',
  ) as Task;
  const taskFormTemplate = _get(
    taskFormTemplateQuery,
    'data.data.formTemplate',
  ) as unknown as FormTemplate;
  const taskFormTemplateVersion = _get(
    taskFormTemplateQuery,
    'data.data.formTemplateVersion',
  ) as unknown as FormTemplateVersion;
  const taskFormTemplateId =
    _get(taskData, 'formTemplate.id') ||
    _get(fullTaskData, 'formTemplate.id') ||
    _get(taskDataForFormTemplate, 'formTemplate.id');
  const taskFormTemplateVersionId =
    _get(taskData, 'formTemplateVersion.id') ||
    _get(fullTaskData, 'formTemplateVersion.id') ||
    _get(taskDataForFormTemplate, 'formTemplateVersion.id');
  const { error: taskBulkSchedulingError, loading: taskBulkSchedulingLoading } =
    taskBulkSchedulingResult;
  const taskError = _get(taskQuery, 'error');
  const taskLoading = _get(taskQuery, 'loading');
  const fullTaskError = _get(fullTaskQuery, 'error');
  const fullTaskLoading = _get(fullTaskQuery, 'loading');
  const taskScheduleError = _get(taskScheduleQuery, 'error');
  const taskScheduleLoading = _get(taskScheduleQuery, 'loading');
  const taskFormTemplateError = _get(taskFormTemplateQuery, 'error');
  const taskFormTemplateLoading = _get(taskFormTemplateQuery, 'loading');
  const createMutationError = _get(createMutationResult, 'error');
  const createTaskLoading = _get(createMutationResult, 'loading');
  const updateMutationError = _get(updateMutationResult, 'error');
  const updateTaskLoading = _get(updateMutationResult, 'loading');
  const taskBulkUpdateStatusError = _get(taskBulkUpdateStatusResult, 'error');
  const taskBulkUpdateStatusLoading = _get(
    taskBulkUpdateStatusResult,
    'loading',
  );

  return {
    createMutationError,
    createTaskLoading,
    fullTaskData,
    fullTaskError,
    fullTaskLoading,
    taskBulkSchedulingError,
    taskBulkSchedulingLoading,
    taskBulkUpdateStatusError,
    taskBulkUpdateStatusLoading,
    taskData,
    taskDataForFormTemplate,
    taskDataSiteAreaId,
    taskDataSiteId,
    taskError,
    taskFormTemplate,
    taskFormTemplateError,
    taskFormTemplateId,
    taskFormTemplateLoading,
    taskFormTemplateVersion,
    taskFormTemplateVersionId,
    taskLoading,
    taskScheduleData,
    taskScheduleError,
    taskScheduleLoading,
    updateMutationError,
    updateTaskLoading,
  };
}
