import _get from 'lodash/get';
import {
  User,
  UserFilterInputObject,
  UsersQueryVariables,
  useUsersIdsLazyQuery,
  useUsersIdsQuery,
  useUsersQuery,
} from 'graphql-common';
import { GetUrlParams } from '@lib/enums/urls';
import createSearchFilters from '@lib/utils/createSearchFilters';
import { ListQueryHookProps, ListQueryHookResult } from './types';

type Args = {
  includeFinalAccesses?: boolean;
  includeFullData?: boolean;
  includePersonalAccesses?: boolean;
  includeShortData?: boolean;
  includeTeamAccesses?: boolean;
};

export default function useUsersQueryHook({
  additionalFilters,
  includeFinalAccesses = false,
  includeFullData = false,
  includePersonalAccesses = false,
  includeShortData = false,
  includeTeamAccesses = false,
  paginationParams,
  searchQuery,
  skip,
  skipGetAllIds = true,
}: ListQueryHookProps & Args): ListQueryHookResult<User> {
  const page = _get(paginationParams, GetUrlParams.Page);
  const perPage = _get(paginationParams, GetUrlParams.PerPage);
  const orderingField = _get(paginationParams, GetUrlParams.OrderingField);
  const orderingDirection = _get(
    paginationParams,
    GetUrlParams.OrderingDirection,
  );
  const usersQueryVariables: UsersQueryVariables = {
    page: Number(page),
    limit: Number(perPage),
    includeFinalAccesses,
    includeFullData,
    includePersonalAccesses,
    includeTeamAccesses,
    includeShortData,
  };
  const filters: UserFilterInputObject[] = [];
  if (searchQuery) {
    filters.push(
      ...createSearchFilters(searchQuery, [
        { fieldName: 'email' },
        { fieldName: 'role', predicateType: 'eq' },
        { fieldName: 'fullName' },
        { fieldName: 'personalId' },
        { fieldName: 'memberAssignedSitesName' },
      ]),
    );
  }
  if (additionalFilters?.length) {
    filters.push(...additionalFilters);
  }
  if (filters.length) {
    usersQueryVariables.filters = filters;
  }
  if (orderingField && orderingDirection) {
    usersQueryVariables.sorts = {
      [orderingField]: orderingDirection,
    };
  }
  const usersQuery = useUsersQuery({
    fetchPolicy: 'cache-and-network',
    variables: usersQueryVariables,
    skip,
  });
  const allIdsQuery = useUsersIdsQuery({
    fetchPolicy: 'no-cache',
    variables: {
      onlyIds: true,
      filters: usersQueryVariables.filters,
    },
    skip: skipGetAllIds,
  });
  const [fetchIds] = useUsersIdsLazyQuery({
    fetchPolicy: 'no-cache',
    variables: {
      onlyIds: true,
      filters: usersQueryVariables.filters,
    },
  });
  const { loading, data, previousData, refetch, error } = usersQuery;
  const queryData = data || previousData;
  const firstLoading = loading && previousData === undefined;
  const collection = _get(queryData, 'data.collection', []) as User[];
  const metadata = _get(queryData, 'data.metadata');
  const totalCount = _get(metadata, 'totalCount', 0);
  const totalPages = _get(metadata, 'totalPages', 1);
  return {
    collection,
    error,
    fetchIds,
    firstLoading,
    loading,
    refetch,
    totalCount,
    totalPages,
    allIds: _get(allIdsQuery, ['data', 'data', 'collection'], []),
    refetchAllIds: allIdsQuery.refetch,
  };
}
