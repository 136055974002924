import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  FormCategory,
  useFormCategoryCreateMutation,
  useFormCategoryDestroyMutation,
  useFormCategoryQuery,
  useFormCategoryUpdateMutation,
} from 'graphql-common';
import toast from '@lib/components/Toast/Toast';
import usePaginationParams from '@lib/hooks/usePaginationParams';
import onDestroyListItemCallBack from '@lib/utils/onDestroyListItemCallBack';
import { showGraphQLErrors } from '@lib/utils/graphQLErrors';
import useSearchParam from '@lib/hooks/useSearchParam';
import useFormTemplateCategoriesQueryHook from 'utils/fetch/useFormCategoriesQueryHook';
import { Values } from '@lib/interfaces/form';
import getPreparedFormCategoryDataFromValues from 'routes/FormTemplate/utils/getPreparedFormCategoryDataFromValues';
import { useCallback } from 'react';

interface UseActionsProps {
  closeCategoryModal: () => void;
  closeDeleteModal: () => void;
  id?: string | null;
  openCategoryModal: () => void;
  openDeleteModal: () => void;
  selectedCategory: FormCategory | null;
  setSelectedCategory: (v: FormCategory) => void;
}

export default function useActions(props: UseActionsProps) {
  const {
    closeCategoryModal,
    closeDeleteModal,
    openCategoryModal,
    openDeleteModal,
    selectedCategory,
    setSelectedCategory,
  } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { search, pathname } = useLocation();
  const [searchQuery] = useSearchParam();
  const [paginationParams] = usePaginationParams();

  // Form categories
  const {
    refetchFormCategories,
    formCategoriesError,
    formCategoriesFirstLoading,
    formCategories,
    totalFormCategoriesCount,
    totalPages,
    formCategoriesLoading,
  } = useFormTemplateCategoriesQueryHook({
    searchQuery,
    paginationParams,
  });

  // Form category
  const categoryQuery = useFormCategoryQuery({
    skip: selectedCategory === null,
    variables: {
      id: selectedCategory?.id || '',
    },
  });
  const [formCategoryCreateMutation, formCategoryCreateMutationResult] =
    useFormCategoryCreateMutation({
      onCompleted: () => {
        refetchFormCategories();
        if (closeCategoryModal) closeCategoryModal();
        toast({ content: t('create-form-template-category-success') });
      },
      onError: (error) => showGraphQLErrors(error, t),
    });
  const [formCategoryUpdateMutation, formCategoryUpdateMutationResult] =
    useFormCategoryUpdateMutation({
      onCompleted: () => {
        refetchFormCategories();
        if (closeCategoryModal) closeCategoryModal();
        toast({ content: t('update-form-template-category-success') });
      },
      onError: (error) => showGraphQLErrors(error, t),
    });

  // Delete
  const [destroyMutation] = useFormCategoryDestroyMutation({
    onCompleted: () => {
      closeDeleteModal();
      onDestroyListItemCallBack({
        collection: formCategories,
        navigate,
        pathname,
        refetch: refetchFormCategories,
        search,
        totalPages,
      });
    },
    onError: (error) => showGraphQLErrors(error, t),
  });

  const onEditHandler = (v?: FormCategory) => {
    if (v) {
      setSelectedCategory(v);
      openCategoryModal();
    }
  };

  const onDeleteHandler = (v: FormCategory) => {
    setSelectedCategory(v);
    openDeleteModal();
  };

  const onDestroyHandler = (id?: string) => {
    if (id) {
      destroyMutation({
        variables: {
          id,
        },
      });
    }
  };

  const onConfirmedDelete = () => {
    closeDeleteModal();
    toast({
      content: t('destroy-category-success'),
      closeCallback: () => {
        if (selectedCategory) {
          onDestroyHandler(selectedCategory?.id);
        }
      },
    });
  };

  const onFormCategoryCreate = (values: Values) => {
    const attributes = getPreparedFormCategoryDataFromValues(values);
    formCategoryCreateMutation({ variables: { attributes } });
  };

  const onFormCategoryUpdate = (values: Values) => {
    const attributes = getPreparedFormCategoryDataFromValues(values);
    formCategoryUpdateMutation({
      variables: { attributes, id: selectedCategory?.id || '' },
    });
  };

  const {
    loading: formCategoryQueryLoading,
    error: formCategoryQueryError,
    data: formCategoryData,
  } = categoryQuery;
  const {
    error: formCategoryCreateMutationError,
    loading: formCategoryCreateMutationLoading,
    reset: formCategoryCreateMutationReset,
  } = formCategoryCreateMutationResult;
  const {
    error: formCategoryUpdateMutationError,
    loading: formCategoryUpdateMutationLoading,
    reset: formCategoryUpdateMutationReset,
  } = formCategoryUpdateMutationResult;

  const formCategoryMutationError = selectedCategory
    ? formCategoryUpdateMutationError
    : formCategoryCreateMutationError;
  const formCategoryMutationLoading = selectedCategory
    ? formCategoryUpdateMutationLoading
    : formCategoryCreateMutationLoading;
  const formCategoryMutationReset = useCallback(() => {
    formCategoryUpdateMutationReset();
    formCategoryCreateMutationReset();
  }, [formCategoryUpdateMutationReset, formCategoryCreateMutationReset]);
  const onSubmitFormCategoryForm = selectedCategory
    ? onFormCategoryUpdate
    : onFormCategoryCreate;

  return {
    formCategories,
    formCategoriesError,
    formCategoriesFirstLoading,
    formCategoriesLoading,
    formCategoryData: formCategoryData?.data,
    formCategoryMutationError,
    formCategoryMutationLoading,
    formCategoryMutationReset,
    formCategoryQueryError,
    formCategoryQueryLoading,
    onConfirmedDelete,
    onDeleteHandler,
    onEditHandler,
    onSubmitFormCategoryForm,
    totalFormCategoriesCount,
  };
}
