import { useState } from 'react';

export interface UseModalManagementResult {
  closeAllModals: () => void;
  closeCounterIndexFormModal: () => void;
  isCounterIndexFormModalOpened: boolean;
  openCounterIndexFormModal: () => void;
}

export default function useModalManagement(): UseModalManagementResult {
  const [isCounterIndexFormModalOpened, setCounterIndexFormModalOpened] =
    useState(false);
  const openCounterIndexFormModal = () => setCounterIndexFormModalOpened(true);
  const closeCounterIndexFormModal = () =>
    setCounterIndexFormModalOpened(false);

  const closeAllModals = () => {
    closeCounterIndexFormModal();
  };

  return {
    closeAllModals,
    closeCounterIndexFormModal,
    isCounterIndexFormModalOpened,
    openCounterIndexFormModal,
  };
}
