import { Values } from '@lib/interfaces/form';
import _get from 'lodash/get';
import mergeAndUnlinkMultiSelectValues, {
  Items,
} from '@lib/utils/mergeAndUnlinkMultiSelectValues';
import { DocumentationUpdateInputObject } from 'graphql-common';
import AssetDocumentFieldNames from '@lib/enums/fieldNames/assetDocumentFieldNames';
import { SelectOption } from '@lib/components/Select/types';

export default function getPreparedUpdateFromValues(
  values: Values,
  oldValues: Values,
) {
  let attachmentAttached: object | undefined;
  if (_get(values, `${AssetDocumentFieldNames.Document}.file.id`)) {
    attachmentAttached = {
      file: {
        id: _get(values, `${AssetDocumentFieldNames.Document}.file.id`),
        storage: _get(
          values,
          `${AssetDocumentFieldNames.Document}.file.storage`,
        ),
        metadata: _get(
          values,
          `${AssetDocumentFieldNames.Document}.file.metadata`,
        ),
      },
    };
  }
  const tags = _get(values, AssetDocumentFieldNames.Tags, []) as SelectOption[];
  const newTags = tags.map(({ value }: SelectOption) => ({
    id: `${value}`,
  }));
  return {
    attachmentAttached,
    documentationTags: mergeAndUnlinkMultiSelectValues(
      _get(oldValues, 'tags', []) as Items,
      newTags,
    ),
    name: _get(values, AssetDocumentFieldNames.Name),
  } as DocumentationUpdateInputObject;
}
