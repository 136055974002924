import { TFunction } from 'i18next';
import RadioButtonGroup, {
  RadioButtonGroupOption,
  RadioButtonWrapViews,
} from '@lib/components/RadioButtonGroup/RadioButtonGroup';
import Input from '@lib/components/Input/Input';
import RadioButtonAsChipColor from '@lib/components/RadioButtonAsChipColor/RadioButtonAsChipColor';
import { ChipTypes } from '@lib/components/Chip/Chip';
import { MAX_TEXT_FIELD_LENGTH } from '@lib/enums/form';
import { FieldItem } from '@lib/interfaces/form';
import FieldNames from '@lib/enums/fieldNames/interventionCategoryFieldNames';
import { InputTypes } from '@lib/components/Input/enums';
import {
  getFieldValidation,
  getOptionValidation,
} from '@lib/utils/validationUtils';

type IndustryFormFieldsArgs = {
  t: TFunction<'translation', undefined>;
};

export default function getCategoryFormFields({
  t,
}: IndustryFormFieldsArgs): FieldItem[] {
  return [
    {
      name: FieldNames.Name,
      label: t('category-name'),
      element: Input,
      validation: getFieldValidation(true, MAX_TEXT_FIELD_LENGTH, t),
      componentProps: {
        autoFocus: true,
      },
    },
    {
      name: FieldNames.Description,
      label: t('description'),
      element: Input,
      componentProps: {
        type: InputTypes.textarea,
      },
      validation: getFieldValidation(false, MAX_TEXT_FIELD_LENGTH, t),
    },
    {
      name: FieldNames.Color,
      label: t('label-color'),
      element: RadioButtonGroup,
      validation: getOptionValidation(true, t),
      componentProps: {
        options: (Object.keys(ChipTypes) as Array<keyof typeof ChipTypes>).map(
          (chipType) => ({
            value: ChipTypes[chipType],
            label: ChipTypes[chipType],
          }),
        ) as RadioButtonGroupOption[],
        RadioButtonComponent: RadioButtonAsChipColor,
        wrapView: RadioButtonWrapViews.colors,
      },
    },
  ];
}
