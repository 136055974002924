import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AssetCategory, ComponentEnum, LevelEnum } from 'graphql-common';
import usePrevious from '@lib/hooks/usePrevious';
import AssetCategories from 'components/AssetCategories/AssetCategories';
import BreadCrumbs from '@lib/components/BreadCrumbs/BreadCrumbs';
import { getBreadCrumbsDataByKey } from 'utils/breadcrumbs';
import useOutletContext, { ContextProps } from 'hooks/useOutletContext';
import useActions from './utils/useActions';
import useModalManagement from './utils/useModalManagement';

function AssetCategoriesContainer() {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const [selectedCategory, setSelectedCategory] =
    useState<AssetCategory | null>(null);

  const { getUserAccessByComponent }: ContextProps = useOutletContext();
  const isAssetsLocked =
    getUserAccessByComponent(ComponentEnum.AssetsManagement) ===
    LevelEnum.LimitedRead;
  const isAssetCategoriesViewOnly =
    getUserAccessByComponent(ComponentEnum.AssetCategoriesManagement) ===
    LevelEnum.Read;

  const {
    closeCategoryModal,
    closeDeleteModal,
    isCategoryModalOpened,
    isDeleteModalOpened,
    openCategoryModal,
    openDeleteModal,
  } = useModalManagement({ setSelectedCategory });
  const isCategoryModalOpenedPrev = usePrevious(isCategoryModalOpened);

  const {
    assetCategories,
    assetCategoriesError,
    assetCategoriesFirstLoading,
    assetCategoriesLoading,
    assetCategoryData,
    assetCategoryMutationError,
    assetCategoryMutationLoading,
    assetCategoryMutationReset,
    assetCategoryQueryError,
    assetCategoryQueryLoading,
    onConfirmedDelete,
    onDeleteHandler,
    onEditHandler,
    onSubmitAssetCategoryForm,
    totalAssetCategoriesCount,
  } = useActions({
    closeCategoryModal,
    closeDeleteModal,
    openCategoryModal,
    openDeleteModal,
    selectedCategory,
    setSelectedCategory,
  });

  useEffect(() => {
    if (isCategoryModalOpenedPrev && !isCategoryModalOpened) {
      assetCategoryMutationReset();
    }
  }, [
    isCategoryModalOpened,
    assetCategoryMutationReset,
    isCategoryModalOpenedPrev,
  ]);

  return (
    <>
      <BreadCrumbs data={getBreadCrumbsDataByKey({ pathname, t })} />
      <AssetCategories
        closeCategoryModal={closeCategoryModal}
        closeDeleteModal={closeDeleteModal}
        assetCategories={assetCategories}
        assetCategoriesError={assetCategoriesError}
        assetCategoriesFirstLoading={assetCategoriesFirstLoading}
        assetCategoriesLoading={assetCategoriesLoading}
        assetCategoryMutationError={assetCategoryMutationError}
        assetCategoryMutationLoading={assetCategoryMutationLoading}
        assetCategoryData={assetCategoryData as AssetCategory}
        assetCategoryQueryError={assetCategoryQueryError}
        assetCategoryQueryLoading={assetCategoryQueryLoading}
        isCategoryModalOpened={isCategoryModalOpened}
        isDeleteModalOpened={isDeleteModalOpened}
        onAddHandler={openCategoryModal}
        onConfirmedDelete={onConfirmedDelete}
        onDelete={onDeleteHandler}
        onEditHandler={onEditHandler}
        onSubmitAssetCategoryForm={onSubmitAssetCategoryForm}
        selectedCategory={selectedCategory}
        totalAssetCategoriesCount={totalAssetCategoriesCount}
        isAssetCategoriesViewOnly={isAssetCategoriesViewOnly}
        isAssetsLocked={isAssetsLocked}
      />
    </>
  );
}

export default AssetCategoriesContainer;
