import React, { useState } from 'react';
import { SiteArea } from 'graphql-common';
import { UseBulkSelectResult } from '@lib/hooks/useTableItemsBulkSelect';
import { SubmitAction } from '@lib/enums/form';

interface UseModalManagementProps {
  submitNewAreaRef: React.MutableRefObject<SubmitAction | undefined>;
  useBulkSelectResult: UseBulkSelectResult<SiteArea>;
}

export interface UseModalManagementResult {
  closeGetQrCodeModal: () => void;
  closeSiteAreaCreateModal: () => void;
  closeSiteAreaDeleteModal: () => void;
  closeSiteAreaModal: () => void;
  isGetQrCodeModalOpened: boolean;
  isSiteAreaCreateModalOpened: boolean;
  isSiteAreaDeleteModalOpened: boolean;
  isSiteAreaEditModalOpened: boolean;
  isSiteAreaViewModalOpened: boolean;
  openGetQrCodeModal: () => void;
  openSiteAreaCreateModal: () => void;
  openSiteAreaDeleteModal: () => void;
  openSiteAreaEditModal: () => void;
  openSiteAreaViewModal: () => void;
}

export default function useSiteAreasModalManagement(
  props: UseModalManagementProps,
): UseModalManagementResult {
  const { submitNewAreaRef, useBulkSelectResult } = props;
  const { handleSelectSingleTableItem } = useBulkSelectResult;

  // State
  const [isSiteAreaCreateModalOpened, setSiteAreaCreateModalOpened] =
    useState(false);
  const [isSiteAreaViewModalOpened, setSiteAreaViewModalOpened] =
    useState(false);
  const [isSiteAreaEditModalOpened, setSiteAreaEditModalOpened] =
    useState(false);
  const [isSiteAreaDeleteModalOpened, setSiteAreaDeleteModalOpened] =
    useState(false);
  const [isGetQrCodeModalOpened, setGetQrCodeModalOpened] = useState(false);

  // Actions
  const openSiteAreaCreateModal = () => {
    setSiteAreaCreateModalOpened(true);
  };
  const openSiteAreaViewModal = () => {
    setSiteAreaEditModalOpened(false);
    setSiteAreaViewModalOpened(true);
  };
  const openSiteAreaEditModal = () => {
    setSiteAreaViewModalOpened(false);
    setSiteAreaEditModalOpened(true);
  };
  const openSiteAreaDeleteModal = () => setSiteAreaDeleteModalOpened(true);
  const openGetQrCodeModal = () => setGetQrCodeModalOpened(true);
  const closeSiteAreaCreateModal = () => {
    setSiteAreaCreateModalOpened(false);
    submitNewAreaRef.current = undefined;
  };
  const closeSiteAreaModal = () => {
    setSiteAreaViewModalOpened(false);
    setSiteAreaEditModalOpened(false);
    handleSelectSingleTableItem(null);
  };
  const closeSiteAreaDeleteModal = () => {
    setSiteAreaDeleteModalOpened(false);
    handleSelectSingleTableItem(null);
  };
  const closeGetQrCodeModal = () => {
    setGetQrCodeModalOpened(false);
    handleSelectSingleTableItem(null);
  };

  return {
    closeGetQrCodeModal,
    closeSiteAreaCreateModal,
    closeSiteAreaDeleteModal,
    closeSiteAreaModal,
    isGetQrCodeModalOpened,
    isSiteAreaCreateModalOpened,
    isSiteAreaDeleteModalOpened,
    isSiteAreaEditModalOpened,
    isSiteAreaViewModalOpened,
    openGetQrCodeModal,
    openSiteAreaCreateModal,
    openSiteAreaDeleteModal,
    openSiteAreaEditModal,
    openSiteAreaViewModal,
  };
}
