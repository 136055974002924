import React from 'react';
import { useTranslation } from 'react-i18next';
import { Site, SiteArea } from 'graphql-common';
import { ApolloError } from '@apollo/client/errors';
import Button, { ButtonTypes } from '@lib/components/Button/Button';
import Table from '@lib/components/Table/Table';
import CircleLoader from '@lib/components/CircleLoader/CircleLoader';
import WhiteBox from '@lib/components/WhiteBox/WhiteBox';
import DashboardContentMaxWidth from '@lib/layouts/DashboardLayout/DashboardContentMaxWidth/DashboardContentMaxWidth';
import Search from '@lib/components/Search/Search';
import PageBody from '@lib/layouts/DashboardLayout/PageBody/PageBody';
import { materialSymbolsOutlined } from '@lib/enums/common';
import ListEmptyState from '@lib/components/ListEmptyState/ListEmptyState';
import { NavItem } from '@lib/components/Menu/Menu';
import useSearchParam from '@lib/hooks/useSearchParam';
import { Column } from '@lib/components/Table/types';
import ErrorPage from '@lib/components/ErrorPage/ErrorPage';
import getStatusFromError from '@lib/utils/getStatusFromError';
import { UseBulkSelectResult } from '@lib/hooks/useTableItemsBulkSelect';
import SiteMenuBar from 'components/SiteMenuBar/SiteMenuBar';
import DashboardTitleHeader from '@lib/layouts/DashboardLayout/DashboardTitleHeader/DashboardTitleHeader';
import ViewOnlyChip from '@lib/components/ViewOnlyChip/ViewOnlyChip';

interface Props {
  collection: SiteArea[];
  openSiteAreaCreateModal: () => void;
  siteAreaError?: ApolloError;
  siteAreasCalled: boolean;
  siteAreasLoading: boolean;
  siteData: Site;
  siteError?: ApolloError;
  siteId: string;
  siteLoading?: boolean;
  tableActionsForSelected?: React.ReactNode[];
  tableActionsMenu?: NavItem<SiteArea>[];
  tableColumns: Column<SiteArea>[];
  totalEntries: number;
  useBulkSelectResult: UseBulkSelectResult<SiteArea>;
  isViewOnly: boolean;
}

function SiteAreas(props: Props) {
  const {
    collection,
    openSiteAreaCreateModal,
    siteAreaError,
    siteAreasCalled,
    siteAreasLoading,
    siteData,
    siteError,
    siteId,
    siteLoading,
    tableActionsForSelected,
    tableActionsMenu,
    tableColumns,
    totalEntries,
    useBulkSelectResult,
    isViewOnly,
  } = props;
  const { t } = useTranslation();
  const [searchQuery] = useSearchParam();

  const isLoading =
    siteAreasLoading && collection.length === 0 && !siteAreasCalled;

  if (siteLoading) {
    return <CircleLoader />;
  }

  if (siteError) {
    return (
      <ErrorPage
        status={getStatusFromError(siteError)}
        description={siteError?.message}
      />
    );
  }

  if (siteAreaError) {
    return (
      <ErrorPage
        status={getStatusFromError(siteAreaError)}
        description={siteAreaError?.message}
      />
    );
  }

  const emptyState = (
    <ListEmptyState
      title={t('no-arias-yet')}
      text={t('no-arias-text')}
      actions={
        isViewOnly
          ? []
          : [
              <Button
                key="add"
                buttonText={t('create-site-area')}
                buttonType={ButtonTypes.primaryFilled}
                leftIcon="add"
                onClick={openSiteAreaCreateModal}
              />,
            ]
      }
    />
  );

  return (
    <PageBody withoutHeader>
      <SiteMenuBar id={siteId} />
      <DashboardContentMaxWidth>
        <WhiteBox>
          <DashboardTitleHeader
            title={siteData?.name}
            actions={
              isViewOnly ? (
                <ViewOnlyChip key="view" />
              ) : (
                <Button
                  key="add"
                  buttonText={t('create-new-site-area')}
                  buttonType={ButtonTypes.primaryFilled}
                  onClick={openSiteAreaCreateModal}
                  leftIcon="add"
                  leftIconClassName={materialSymbolsOutlined}
                />
              )
            }
          />
          {isLoading ? (
            <CircleLoader />
          ) : (
            <>
              {((!!totalEntries && !!collection.length) || searchQuery) && (
                <Search placeholder={t('site-areas-search-placeholder')} />
              )}
              <Table
                actionsForSelected={tableActionsForSelected}
                actionsMenu={tableActionsMenu}
                columns={tableColumns}
                counterText={t('areas')}
                data={collection}
                emptyState={emptyState}
                id="siteAreas"
                loading={siteAreasLoading}
                totalEntries={totalEntries}
                useBulkSelectResult={useBulkSelectResult}
              />
            </>
          )}
        </WhiteBox>
      </DashboardContentMaxWidth>
    </PageBody>
  );
}

export default SiteAreas;
