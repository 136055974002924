import { InterventionAction, useInterventionActionQuery } from 'graphql-common';
import { WatchQueryFetchPolicy } from '@apollo/client';
import { ApolloError } from '@apollo/client/errors';

type Args = {
  id?: string;
  interventionId?: string;
  skip?: boolean;
  fetchPolicy?: WatchQueryFetchPolicy;
};

export type UseAssetInterventionActionQueryHookResult = {
  data: InterventionAction;
  loading: boolean;
  error: ApolloError | undefined;
  refetch: () => void;
  firstLoading: boolean;
};

export default function useInterventionActionQueryHook(
  args: Args,
): UseAssetInterventionActionQueryHookResult {
  const {
    id = '',
    interventionId = '',
    skip = true,
    fetchPolicy = 'network-only',
  } = args;
  const query = useInterventionActionQuery({
    skip,
    variables: { id, interventionId },
    fetchPolicy,
  });
  const { refetch, error, loading, data: queryData, previousData } = query;
  const data = queryData || previousData;
  const firstLoading = loading && queryData === undefined;

  return {
    data: data?.data as InterventionAction,
    loading,
    error,
    refetch,
    firstLoading,
  };
}
