import { Values } from '@lib/interfaces/form';
import _get from 'lodash/get';
import { InterventionActionCreateInputObject } from 'graphql-common';
import FieldNames from '@lib/enums/fieldNames/interventionActionFieldNames';
import mergeAndUnlinkMultiSelectValues from '@lib/utils/mergeAndUnlinkMultiSelectValues';
import { getMinutesCountFromTime } from '@lib/utils/getMinutesCountFromTime';
import { SelectOption } from '@lib/components/Select/types';
import mergeAndUnlinkAttachedFiles from '@lib/utils/mergeAndUnlinkAttachedFiles';
import getUniqueMentionIds from '@lib/utils/getUniqueMentionIds';

export default function getInterventionActionPreparedFromValues(
  values: Values,
  prevValues: Values = {},
) {
  const assigneesValue = (_get(values, FieldNames.Assignee) ||
    []) as SelectOption[];
  const prevAssigneesValue = (_get(prevValues, FieldNames.Assignee) ||
    []) as SelectOption[];
  const assignees = assigneesValue.map(({ value }) => ({
    id: `${value}`,
  }));
  const prevAssignees = prevAssigneesValue.map(({ value }) => ({
    id: `${value}`,
  }));

  const attachmentsAttached = mergeAndUnlinkAttachedFiles(
    values,
    prevValues,
    FieldNames.AttachmentsAttached,
  );

  const pevMentions = getUniqueMentionIds(prevValues?.text).map((id) => ({
    id,
  }));
  const newMentions = getUniqueMentionIds(
    _get(values, FieldNames.Description),
  ).map((id) => ({ id }));
  const mentions = mergeAndUnlinkMultiSelectValues(pevMentions, newMentions);

  return {
    status: _get(values, [FieldNames.Status, 'value']),
    text: _get(values, FieldNames.Description),
    attachmentsAttached: attachmentsAttached.length
      ? attachmentsAttached
      : undefined,
    durationTime: getMinutesCountFromTime(
      _get(values, FieldNames.DurationTime, '') as string,
    ),
    estimatedTime: getMinutesCountFromTime(
      _get(values, FieldNames.EstimatedTime, '') as string,
    ),
    estimatedDate: _get(values, FieldNames.EstimatedDate),
    logisticTime: getMinutesCountFromTime(
      _get(values, FieldNames.LogisticTime, '') as string,
    ),
    assignees: mergeAndUnlinkMultiSelectValues(prevAssignees, assignees),
    textMentions: mentions ?? undefined,
  } as InterventionActionCreateInputObject;
}
