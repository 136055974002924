import { StringParam, useQueryParams } from 'use-query-params';
import { GetUrlParams } from '@lib/enums/urls';

const useFilterParams = () =>
  useQueryParams({
    [GetUrlParams.AssetId]: StringParam,
    [GetUrlParams.AssetIds]: StringParam,
    [GetUrlParams.AssetName]: StringParam,
    [GetUrlParams.AssigneeIds]: StringParam,
    [GetUrlParams.CounterId]: StringParam,
    [GetUrlParams.CounterName]: StringParam,
    [GetUrlParams.DuplicatedVersionId]: StringParam,
    [GetUrlParams.EndDate]: StringParam,
    [GetUrlParams.FormCategoryIds]: StringParam,
    [GetUrlParams.InterventionCategoryIds]: StringParam,
    [GetUrlParams.PeriodEnd]: StringParam,
    [GetUrlParams.PeriodStart]: StringParam,
    [GetUrlParams.Priority]: StringParam,
    [GetUrlParams.SiteAndArea]: StringParam,
    [GetUrlParams.SiteAreaId]: StringParam,
    [GetUrlParams.SiteAreaIds]: StringParam,
    [GetUrlParams.SiteAreaName]: StringParam,
    [GetUrlParams.SiteId]: StringParam,
    [GetUrlParams.SiteIds]: StringParam,
    [GetUrlParams.SiteName]: StringParam,
    [GetUrlParams.TaskId]: StringParam,
    [GetUrlParams.UserId]: StringParam,
    [GetUrlParams.View]: StringParam,
  });

export default useFilterParams;
