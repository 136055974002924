import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import _get from 'lodash/get';
import { MutationResult } from '@apollo/client';
import { ApolloError } from '@apollo/client/errors';
import {
  AssetCounterBulkQrCodeCreateMutation,
  AssetCreateInputObject,
  AssetUpdateInputObject,
} from 'graphql-common';
import toast from '@lib/components/Toast/Toast';
import { Values } from '@lib/interfaces/form';
import replaceNullsWithUndefined from '@lib/utils/replaceNullsWithUndefined';
import { FormRefType } from '@lib/components/ReactHookForm/types';
import { APP_URLS } from 'constants/urls';
import { SubmitAction } from '@lib/enums/form';
import { AssetViewMode } from '../enums';
import { UseActionsResult } from './useActions';
import getPreparedAssetCategoryDataFromValues from '../../AssetCategories/utils/getPreparedAssetCategoryDataFromValues';
import getPreparedAssetDataFromValues from './getPreparedAssetDataFromValues';
import { UseModalManagementResult } from './useModalManagement';
import useQrCodeActions from './useQrCodeActions';
import getDefaultValuesFromQueryData from './getDefaultValuesFromQueryData';
import useAssetMutations from './useAssetMutations';

interface UseActionsProps {
  formRef: FormRefType;
  submitNewItemRef?: React.MutableRefObject<SubmitAction | undefined>;
  useModalManagementResult: UseModalManagementResult;
  viewMode: AssetViewMode;
  useActionsResult: UseActionsResult;
}

export interface UseAssetActionsResult {
  assetCategoryCreateMutationError: ApolloError | undefined;
  assetCategoryCreateMutationLoading: boolean;
  assetMutationError: ApolloError | undefined;
  assetMutationLoading: boolean;
  defaultValues: Values;
  onAssetCategoryCreate: (values: Values) => void;
  onAssetQrCodeCreate: (values: Values) => void;
  onCloseModal: () => void;
  onConfirmedDelete: () => void;
  onCreate: (values: Values) => void;
  onEdit: (values: Values) => void;
  onEditHandler: () => void;
  qrCodeCreateMutationResult: MutationResult<AssetCounterBulkQrCodeCreateMutation>;
}

export default function useAssetActions(
  props: UseActionsProps,
): UseAssetActionsResult {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    useActionsResult,
    formRef,
    submitNewItemRef,
    useModalManagementResult,
  } = props;

  const { assetQueryHook, assetId: currentAssetId } = useActionsResult;

  const defaultValues = getDefaultValuesFromQueryData(
    replaceNullsWithUndefined(assetQueryHook.data),
  );

  // Mutations
  const useMutationsResult = useAssetMutations({
    formRef,
    submitNewItemRef,
    useModalManagementResult,
  });

  // Actions
  const onCloseModal = () => {
    useModalManagementResult.closeAllModals();
  };

  const onCreate = (values: Values) => {
    useMutationsResult.assetCreateMutation({
      variables: {
        attributes: getPreparedAssetDataFromValues(
          values,
          true,
        ) as AssetCreateInputObject,
      },
    });
  };

  const onEdit = (values: Values) => {
    if (currentAssetId) {
      useMutationsResult.assetUpdateMutation({
        variables: {
          id: currentAssetId,
          attributes: getPreparedAssetDataFromValues(
            values,
          ) as AssetUpdateInputObject,
        },
      });
    }
  };

  const onAssetCategoryCreate = (values: Values) => {
    const attributes = getPreparedAssetCategoryDataFromValues(values);
    useMutationsResult.assetCategoryCreateMutation({
      variables: { attributes },
    });
  };

  const onConfirmedDelete = () => {
    useModalManagementResult.closeDeleteModal();
    toast({
      content: t('destroy-asset-success'),
      closeCallback: () => {
        if (currentAssetId) {
          useMutationsResult.destroyAssetMutation({
            variables: { input: { ids: [currentAssetId] } },
          });
        }
      },
    });
  };

  const onEditHandler = () =>
    navigate(
      APP_URLS.dashboard.assets.edit.getDynamicPath({
        pathParts: {
          id: currentAssetId,
        },
      }),
    );

  // Get QR codes
  const { onQrCodeCreateAction } = useQrCodeActions({
    onCompleted: onCloseModal,
    qrCodeCreateMutation: useMutationsResult.qrCodeCreateMutation,
  });

  const onAssetQrCodeCreate = (values: Values) => {
    onQrCodeCreateAction(values, [currentAssetId]);
  };

  const assetCategoryCreateMutationLoading = _get(useMutationsResult, [
    'assetCategoryCreateMutationResult',
    'loading',
  ]);
  const assetCategoryCreateMutationError = _get(useMutationsResult, [
    'assetCategoryCreateMutationResult',
    'error',
  ]);
  const assetMutationLoading =
    _get(useMutationsResult, ['assetCreateMutationResult', 'loading']) ||
    _get(useMutationsResult, ['assetUpdateMutationResult', 'loading']);
  const assetMutationError =
    _get(useMutationsResult, ['assetCreateMutationResult', 'error']) ||
    _get(useMutationsResult, ['assetUpdateMutationResult', 'error']);

  return {
    assetCategoryCreateMutationError,
    assetCategoryCreateMutationLoading,
    assetMutationError,
    assetMutationLoading,
    defaultValues,
    onAssetCategoryCreate,
    onAssetQrCodeCreate,
    onCloseModal,
    onConfirmedDelete,
    onCreate,
    onEdit,
    onEditHandler,
    qrCodeCreateMutationResult: useMutationsResult.qrCodeCreateMutationResult,
  };
}
