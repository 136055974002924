import { Value, Values } from '@lib/interfaces/form';
import _get from 'lodash/get';
import { DocumentationCreateInputObject } from 'graphql-common';
import AssetDocumentFieldNames from '@lib/enums/fieldNames/assetDocumentFieldNames';
import { SelectOption } from '@lib/components/Select/types';

export default function getPreparedCreateFromValues(values: Values) {
  const documents = _get(
    values,
    AssetDocumentFieldNames.Documents,
    [],
  ) as Value[];
  return documents.map((document: any) => {
    let attachmentAttached: object | undefined;
    if (_get(document, `${AssetDocumentFieldNames.Document}.file.id`)) {
      attachmentAttached = {
        filename: _get(
          document,
          `${AssetDocumentFieldNames.Document}.filename`,
        ),
        file: {
          id: _get(document, `${AssetDocumentFieldNames.Document}.file.id`),
          storage: _get(
            document,
            `${AssetDocumentFieldNames.Document}.file.storage`,
          ),
          metadata: _get(
            document,
            `${AssetDocumentFieldNames.Document}.file.metadata`,
          ),
        },
      };
    }
    return {
      attachmentAttached,
      documentationTags: _get(document, AssetDocumentFieldNames.Tags).map(
        ({ value }: SelectOption) => ({
          id: value,
        }),
      ),
      name: _get(document, AssetDocumentFieldNames.Name),
    } as DocumentationCreateInputObject;
  });
}
