import { BaseItem, CellProps, Column } from '@lib/components/Table/types';
import { Task, Timeline } from 'graphql-common';
import { TFunction } from 'i18next';
import _without from 'lodash/without';
import TextLinkCell from '@lib/components/Table/commonComponents/TextLinkCell/TextLinkCell';
import { APP_URLS } from 'constants/urls';
import _get from 'lodash/get';
import ChipCell from '@lib/components/Table/commonComponents/ChipCell/ChipCell';
import { ChipTypes } from '@lib/components/Chip/Chip';
import React from 'react';

type TimelineTableColumnsArgs = {
  columns: Column<Timeline & BaseItem>[];
  isFullTableView: boolean;
  t: TFunction<'translation', undefined>;
};

const getTimelineTableColumns = ({
  columns,
  isFullTableView,
  t,
}: TimelineTableColumnsArgs) => {
  return _without(
    [
      {
        id: 'name',
        Header: t('name'),
        accessor: 'name',
        Cell: (props: CellProps<Task>) => (
          <TextLinkCell
            {...props}
            to={APP_URLS.dashboard.tasks.view.getDynamicPath({
              pathParts: {
                id: props?.item.id,
              },
            })}
          />
        ),
        sortable: true,
        maxWidth: 400,
        minWidth: 200,
      },
      isFullTableView
        ? {
            id: 'siteName',
            Header: t('site'),
            Cell: (props: CellProps<Task>) => {
              const label = _get(props, 'item.site.name', '');
              if (!label) return '-';
              return <ChipCell label={label} type={ChipTypes.chipGray} />;
            },
            sortable: true,
            width: 160,
            maxWidth: 160,
            minWidth: 160,
          }
        : undefined,
      isFullTableView
        ? {
            id: 'siteAreaName',
            Header: t('area'),
            Cell: (props: CellProps<Task>) => {
              const label = _get(props, 'item.siteArea.name', '');
              if (!label) return '-';
              return <ChipCell label={label} type={ChipTypes.chipGray} />;
            },
            sortable: true,
            width: 160,
            maxWidth: 160,
            minWidth: 160,
          }
        : undefined,
      isFullTableView
        ? {
            id: 'category',
            Header: t('category'),
            Cell: (props: CellProps<Task>) => {
              const label = _get(props, 'item.formTemplate.category.name', '');
              const color = _get(
                props,
                'item.formTemplate.category.color',
                '',
              ) as ChipTypes;
              if (!label) return '-';
              return <ChipCell label={label} type={color} leftIcon="circle" />;
            },
            sortable: true,
            width: 160,
            maxWidth: 160,
            minWidth: 160,
          }
        : undefined,
      ...columns,
    ],
    undefined,
  ) as Column<Task>[];
};

export default getTimelineTableColumns;
