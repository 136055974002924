import React, { useState, useEffect, useRef } from 'react';
import { Popover as ReactPopover } from 'antd';
import { TooltipPlacement } from 'antd/es/tooltip';
import { TooltipProps } from 'rc-tooltip/lib/Tooltip';
import { MenuLinkClassName } from '@lib/components/Menu/Menu';
import styles from './Popover.module.scss';

export type ChildProps = {
  handleToggle: (v?: boolean) => void;
  isOpen: boolean;
};

export type PopoverStateProps = { handleClose: () => void };

export type PopoverBody = ({ handleClose }: PopoverStateProps) => JSX.Element;

export type PopoverTrigger = TooltipProps['trigger'];

export type PopoverProps = {
  width?: number;
  children: (props: ChildProps) => React.JSX.Element;
  body: PopoverBody;
  placement?: TooltipPlacement;
  trigger?: PopoverTrigger;
};

function Popover(props: PopoverProps) {
  const {
    width = 200,
    placement = 'bottomLeft',
    body,
    children,
    trigger = 'hover',
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const popoverRef = useRef<HTMLDivElement>(null);

  const handleToggle = (newIsOpen?: boolean) => {
    if (newIsOpen !== undefined)
      setIsOpen((prevState) => newIsOpen || !prevState);
  };

  const handleClose = () => setIsOpen(false);

  const content = () => (
    <div style={width ? { width: `${width}px` } : undefined} ref={popoverRef}>
      {body({ handleClose })}
    </div>
  );

  useEffect(() => {
    const handleClickInside = (event: Event) => {
      if (
        trigger === 'hover' &&
        popoverRef.current &&
        // @ts-ignore
        event?.target?.classList?.contains(MenuLinkClassName)
      ) {
        handleClose();
      }
    };

    // Attach the event listener to the popover element
    const element = popoverRef.current;
    if (element) {
      element.addEventListener('click', handleClickInside);
    }

    // Cleanup the event listener
    return () => {
      if (element) {
        element.removeEventListener('click', handleClickInside);
      }
    };
  }, [isOpen, trigger]);

  return (
    <ReactPopover
      arrow={false}
      content={content}
      onOpenChange={handleToggle}
      open={isOpen}
      placement={placement}
      trigger={trigger}
      overlayClassName={styles.rootPopover}
      destroyTooltipOnHide
    >
      {children({ handleToggle, isOpen })}
    </ReactPopover>
  );
}

export default Popover;
