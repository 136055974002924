import _get from 'lodash/get';
import { TFunction } from 'i18next';
import { Values } from '@lib/interfaces/form';
import { FormTemplateMode } from 'routes/FormTemplate/enums';

type Props = {
  formTemplateVersionData: Values;
  t: TFunction<'translation', undefined>;
  versionId: string;
  viewMode: FormTemplateMode;
};

export default function useSelectedVersionOption(props: Props) {
  const { formTemplateVersionData, t, versionId, viewMode } = props;
  const selectedVersionId = _get(formTemplateVersionData, 'id', versionId);
  const selectedVersionName = _get(
    formTemplateVersionData,
    'version',
  ) as string;
  const selectedVersionOption = {
    value: selectedVersionId,
    label: selectedVersionName ? `v${selectedVersionName}` : '-',
  };
  if (viewMode === FormTemplateMode.EditVersion && versionId === 'new') {
    selectedVersionOption.label = t('new');
  }
  return selectedVersionOption;
}
