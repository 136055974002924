import { TFunction } from 'i18next';
import Input from '@lib/components/Input/Input';
import { MAX_TEXT_FIELD_LENGTH } from '@lib/enums/form';
import { FieldItem } from '@lib/interfaces/form';
import {
  getEmailValidation,
  getFieldValidation,
} from '@lib/utils/validationUtils';
import { InputTypes } from '@lib/components/Input/enums';

type FormFieldsArgs = {
  t: TFunction<'translation', undefined>;
};

export default function getFormFields({ t }: FormFieldsArgs): FieldItem[] {
  return [
    {
      name: 'contactEmail',
      label: t('your-contact-email'),
      element: Input,
      validation: getEmailValidation(true, t),
    },
    {
      name: 'message',
      label: t('your-message-to-us'),
      element: Input,
      componentProps: {
        type: InputTypes.textarea,
      },
      validation: getFieldValidation(true, MAX_TEXT_FIELD_LENGTH, t),
    },
  ];
}
