import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Documentation } from 'graphql-common';
import toast from '@lib/components/Toast/Toast';
import { UseBulkSelectResult } from '@lib/hooks/useTableItemsBulkSelect';
import useSearchParam from '@lib/hooks/useSearchParam';
import usePaginationParams from '@lib/hooks/usePaginationParams';
import onDestroyListItemCallBack from '@lib/utils/onDestroyListItemCallBack';
import tableItemsBulkAction from '@lib/utils/tableItemsBulkAction';
import getAllTableItemsIds from '@lib/utils/getAllTableItemsIds';
import { NEW } from '@lib/enums/common';
import useDocumentationsQueryHook, {
  UseDocumentationsQueryHookResult,
} from 'utils/fetch/useDocumentationsQueryHook';
import { APP_URLS } from 'constants/urls';
import { downloadLinkId } from '@lib/enums/selectors';
import { AssetViewMode } from '../enums';
import useDocumentationMutations from './useDocumentationMutations';
import { UseModalManagementResult } from './useModalManagement';
import { UseActionsResult } from './useActions';

interface ActionsProps {
  id: string;
  useActionsResult: UseActionsResult;
  useBulkSelectResult?: UseBulkSelectResult<Documentation>;
  useModalManagementResult: UseModalManagementResult;
  viewMode: AssetViewMode;
}

export interface UseDocumentationActionsResult {
  documentationsQueryHook: UseDocumentationsQueryHookResult;
  onCloseModal: () => void;
  onConfirmedDocumentationDestroy: () => void;
  onDownload: (item?: Documentation) => void;
  onTagSelect: (v: string) => void;
  openDocumentForm: (item?: Documentation) => void;
  openDocumentationBulkDestroyModal: () => void;
  openDocumentationDestroyModal: (item?: Documentation) => void;
}

export default function useDocumentationActions(
  props: ActionsProps,
): UseDocumentationActionsResult {
  const {
    id,
    useActionsResult,
    useModalManagementResult,
    useBulkSelectResult,
    viewMode,
  } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const [searchQuery, setSearchQuery] = useSearchParam();
  const [paginationParams] = usePaginationParams();

  const useMutationsResult = useDocumentationMutations();
  const { assetId: currentAssetId, assetQueryHook } = useActionsResult;

  // Documentation
  const documentationsQueryHook = useDocumentationsQueryHook({
    paginationParams,
    orderingParams: paginationParams,
    searchQuery,
    assetId: currentAssetId,
    skip: viewMode !== AssetViewMode.Documentation,
  });

  // Actions
  const onGetAllIds = () =>
    getAllTableItemsIds<Documentation>({
      fetchIds: documentationsQueryHook.fetchIds,
      useBulkSelectResult,
    });

  const onCloseModal = () => {
    useBulkSelectResult?.handleUnselectAllTableItems();
    useModalManagementResult.closeAllModals();
  };

  const openDocumentationDestroyModal = (item?: Documentation) => {
    if (item?.id) {
      useBulkSelectResult?.handleSelectSingleTableItem(item);
      useModalManagementResult.openDocumentationDestroyModal();
    }
  };

  const openDocumentationBulkDestroyModal = () => {
    useModalManagementResult.openDocumentationDestroyModal();
  };

  const onConfirmedDocumentationDestroy = () => {
    useModalManagementResult.closeAllModals();
    toast({
      content: t('destroy-asset-documentation-success'),
      closeCallback: () => {
        if (currentAssetId) {
          tableItemsBulkAction<Documentation>({
            action: (idsValue?: string[]) => {
              if (idsValue) {
                useMutationsResult.documentationBulkDestroyMutation({
                  variables: { ids: idsValue, assetId: currentAssetId },
                  onCompleted: () => {
                    useBulkSelectResult?.handleUnselectAllTableItems();
                    onDestroyListItemCallBack({
                      collection: documentationsQueryHook.collection,
                      navigate,
                      pathname,
                      refetch: () => {
                        assetQueryHook.refetch();
                        documentationsQueryHook.refetch();
                      },
                      search,
                      totalPages: documentationsQueryHook.totalPages,
                    });
                  },
                });
              }
            },
            useBulkSelectResult,
            onGetAllIds,
          });
        }
      },
    });
  };

  const openDocumentForm = (item?: Documentation) => {
    navigate(
      APP_URLS.dashboard.assets.document.getDynamicPath({
        pathParts: { id, documentId: item?.id || NEW },
      }),
    );
  };

  const onDownload = (item?: Documentation) => {
    if (item?.id) {
      useBulkSelectResult?.handleSelectSingleTableItem(item);
      const link = document.getElementById(downloadLinkId);
      const downloadUrl =
        item?.attachmentAttached?.urls?.originalDirectDownload;
      if (downloadUrl && link) {
        link.setAttribute('href', downloadUrl);
        link.click();
        link.setAttribute('href', '');
      }
    }
  };

  const onTagSelect = (tag: string) => {
    setSearchQuery(tag);
  };

  return {
    documentationsQueryHook,
    onCloseModal,
    onConfirmedDocumentationDestroy,
    onDownload,
    onTagSelect,
    openDocumentForm,
    openDocumentationBulkDestroyModal,
    openDocumentationDestroyModal,
  };
}
