import _get from 'lodash/get';
import { Values } from '@lib/interfaces/form';
import AssetCounterFieldNames from '@lib/enums/fieldNames/assetCounterFieldNames';

type Args = {
  values: Values;
  isEdit?: boolean;
  assetId?: string;
};

export default function getPreparedAssetCounterDataFromValues<T>(
  args: Args,
): T {
  const { values, isEdit, assetId } = args;
  return {
    name: _get(values, AssetCounterFieldNames.Name),
    code: isEdit ? undefined : _get(values, AssetCounterFieldNames.Code),
    measureType: isEdit
      ? undefined
      : _get(values, [AssetCounterFieldNames.MeasureType, 'value']),
    measureUnit: isEdit
      ? undefined
      : _get(values, [AssetCounterFieldNames.MeasureUnit, 'value']),
    prohibitLowerIndexThanPrevious:
      _get(values, AssetCounterFieldNames.ProhibitLowerIndexThanPrevious) ||
      false,
    assetId,
  } as T;
}
