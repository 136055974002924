import React from 'react';
import { TFunction } from 'i18next';
import Button, { ButtonTypes } from '@lib/components/Button/Button';
import { materialSymbolsOutlined } from '@lib/enums/common';
import { FormTemplateScopeNameEnum } from 'graphql-common';

type ActionsForSelectedArgs = {
  formTemplateScope: FormTemplateScopeNameEnum;
  onBulkArchive: () => void;
  onBulkDestroy: () => void;
  onBulkRestore: () => void;
  t: TFunction<'translation', undefined>;
  isViewOnly: boolean;
};

export default function getTableActionsForSelected({
  formTemplateScope,
  onBulkArchive,
  onBulkDestroy,
  onBulkRestore,
  t,
  isViewOnly,
}: ActionsForSelectedArgs) {
  return isViewOnly
    ? []
    : [
        <Button
          key="bulk-delete"
          buttonText={t('delete')}
          buttonType={ButtonTypes.negativeOutlined}
          leftIcon="delete"
          leftIconClassName={materialSymbolsOutlined}
          onClick={onBulkDestroy}
        />,
        formTemplateScope === FormTemplateScopeNameEnum.Active ? (
          <Button
            key="bulk-archive"
            buttonText={t('archive')}
            buttonType={ButtonTypes.primaryOutlined}
            leftIcon="archive"
            leftIconClassName={materialSymbolsOutlined}
            onClick={onBulkArchive}
          />
        ) : undefined,
        formTemplateScope === FormTemplateScopeNameEnum.Archived ? (
          <Button
            key="bulk-restore"
            buttonText={t('restore')}
            buttonType={ButtonTypes.primaryOutlined}
            leftIcon="restore_page"
            leftIconClassName={materialSymbolsOutlined}
            onClick={onBulkRestore}
          />
        ) : undefined,
      ];
}
