import React from 'react';
import { useTranslation } from 'react-i18next';
import Tasks from 'components/Tasks/Tasks';
import TaskResultStateCounters from 'components/TaskResultStateCounters/TaskResultStateCounters';
import {
  Task,
  TaskCompletionStatusEnum,
  TaskScopeNameEnum,
} from 'graphql-common';
import { ApolloError } from '@apollo/client/errors';
import { TaskCounter } from '@lib/interfaces/task';
import {
  getOverviewTableColumns,
  getOverviewTableTabs,
  getTableActionsMenu,
} from './tableHelpers';

type Props = {
  isViewOnly: boolean;
  onCreateIntervention: (v?: Task) => void;
  onViewIntervention: (v?: Task) => void;
  onResultStateChangeHandler: (v: string, s: TaskCompletionStatusEnum) => void;
  onViewHandler: (v?: Task) => void;
  prevTabParam?: string;
  setTabParam: (v: string) => void;
  tabParam?: string;
  taskCounterData: TaskCounter;
  tasks: Task[];
  tasksError?: ApolloError;
  tasksFirstLoading: boolean;
  tasksLoading: boolean;
  totalTasksCount: number;
};

export default function TasksOverview(props: Props) {
  const { t } = useTranslation();
  const {
    isViewOnly,
    onCreateIntervention,
    onViewIntervention,
    onResultStateChangeHandler,
    onViewHandler,
    prevTabParam,
    setTabParam,
    tabParam,
    taskCounterData,
    tasks,
    tasksError,
    tasksFirstLoading,
    tasksLoading,
    totalTasksCount,
  } = props;
  const tableActionsMenu = getTableActionsMenu({
    t,
    onViewHandler,
    onCreateIntervention,
    onViewIntervention,
  });
  const tableColumns = getOverviewTableColumns({
    onResultStateChangeHandler,
    t,
    taskScope: (tasksLoading
      ? prevTabParam || tabParam
      : tabParam) as TaskScopeNameEnum,
  });
  const tableTabs = getOverviewTableTabs({ t, taskCounterData });
  return (
    <>
      <TaskResultStateCounters
        taskCounterData={taskCounterData}
        tasksError={tasksError}
        tasksFirstLoading={tasksFirstLoading}
      />
      <Tasks
        setTabParam={setTabParam}
        tabParam={tabParam}
        tableColumns={tableColumns}
        tableTabs={tableTabs}
        tableActionsMenu={tableActionsMenu}
        tasks={tasks}
        tasksError={tasksError}
        tasksFirstLoading={tasksFirstLoading}
        tasksLoading={tasksLoading}
        totalTasksCount={totalTasksCount}
        pageSubTitle={t('active-tasks-results')}
        isViewOnly={isViewOnly}
      />
    </>
  );
}
