import { StringParam, useQueryParams } from 'use-query-params';
import { GetUrlParams } from '@lib/enums/urls';

const usePaginationParams = () =>
  useQueryParams({
    [GetUrlParams.Page]: StringParam,
    [GetUrlParams.PerPage]: StringParam,
    [GetUrlParams.OrderingField]: StringParam,
    [GetUrlParams.OrderingDirection]: StringParam,
  });

export default usePaginationParams;
