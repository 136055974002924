import React from 'react';
import { useTranslation } from 'react-i18next';
import _startCase from 'lodash/startCase';
import _toLower from 'lodash/toLower';
import _upperFirst from 'lodash/upperFirst';
import {
  InterventionAction,
  InterventionActionStatusEnum,
} from 'graphql-common';
import Chip, { ChipSizes } from '@lib/components/Chip/Chip';
import ChipWithDropDown from '@lib/components/ChipWithDropDown/ChipWithDropDown';
import Menu, { NavItemTypeEnum } from '@lib/components/Menu/Menu';
import {
  getInterventionActionStatusChipType,
  getInterventionActionStatusOptions,
} from '@lib/utils/status';

type Props = {
  onStatusChangeHandler?: (
    id: string,
    newStatus: InterventionActionStatusEnum,
  ) => void;
  interventionAction: InterventionAction;
  isViewOnly?: boolean;
};

export default function InterventionActionStatusChip(props: Props) {
  const { t } = useTranslation();
  const { onStatusChangeHandler, interventionAction, isViewOnly } = props;
  const { id, status } = interventionAction;
  const options = getInterventionActionStatusOptions();
  let label: string | undefined = '-';
  if (status) {
    const selectedOption = options.find(({ value }) => value === status);
    label = selectedOption?.labelKey ? t(selectedOption.labelKey) : undefined;
  }
  if (label === undefined) label = _upperFirst(_toLower(_startCase(status)));
  const type = getInterventionActionStatusChipType(status);
  if (
    onStatusChangeHandler !== undefined &&
    status !== InterventionActionStatusEnum.Closed &&
    !isViewOnly
  ) {
    const chipLeftIcon = (statusItem: InterventionActionStatusEnum) => (
      <Chip
        leftIcon="circle"
        type={getInterventionActionStatusChipType(statusItem)}
        size={ChipSizes.smallLeftIconOnly}
      />
    );
    const getBody = () => (
      <Menu
        navItems={options
          .filter(({ value }) => value !== status)
          .map(({ value, labelKey }) => ({
            id: value,
            title: t(labelKey),
            onClick: () => onStatusChangeHandler(id, value),
            type: NavItemTypeEnum.Secondary,
            leftIcon: () => chipLeftIcon(value),
          }))}
      />
    );
    return (
      <div>
        <ChipWithDropDown
          label={label}
          type={type}
          leftIcon="circle"
          width={160}
          body={getBody}
          asBlockElement
        />
      </div>
    );
  }
  return <Chip type={type} label={label} leftIcon="circle" asBlockElement />;
}
