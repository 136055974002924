import React from 'react';
import _get from 'lodash/get';
import { useLocalStorage } from 'usehooks-ts';
import { Attachment } from 'graphql-common';
import ImageViewer from '@lib/components/ImageViewer/ImageViewer';
import Modal from '@lib/components/Modal/Modal';
import PDFViewer from '@lib/components/PDFViewer/PDFViewer';
import { ModalMode } from '@lib/components/Modal/enums';
import { FILE_PREVIEW_MODAL_MODE } from '@lib/enums/localStorageKeys';
import styles from './FileViewerModal.module.scss';

type Props = {
  attachment: Attachment | null | undefined;
  attachmentName?: string;
  isOpen: boolean;
  onClose: () => void;
};

export default function FileViewerModal(props: Props) {
  const [modalMode, setModalMode] = useLocalStorage(
    FILE_PREVIEW_MODAL_MODE,
    ModalMode.Default,
  );
  const { attachment, isOpen, onClose, attachmentName } = props;
  const downloadUrl = attachment?.urls?.originalDirectDownload || '';
  const fileUrl = attachment?.urls?.large || attachment?.urls?.original || '';
  const mimeType = _get(attachment, [
    'fileData',
    'data',
    'metadata',
    'mimeType',
  ]);
  const onFullScreenToggle = () =>
    setModalMode((prevState) =>
      prevState === ModalMode.Default
        ? ModalMode.FullScreen
        : ModalMode.Default,
    );
  const isFullScreen = modalMode === ModalMode.FullScreen;
  const isPdf = mimeType?.includes('pdf');
  const isImage = mimeType?.includes('image');
  const mode = isFullScreen ? ModalMode.FullScreen : ModalMode.Default;

  if (isPdf || isImage) {
    return (
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        caption={attachmentName}
        modalContentClassName={styles.modalContentClassName}
        modalBodyClassName={styles.modalBodyClassName}
        mode={mode}
      >
        <>
          {isPdf && (
            <PDFViewer
              fileUrl={fileUrl}
              downloadUrl={downloadUrl}
              onFullScreenToggle={onFullScreenToggle}
              isFullScreen={isFullScreen}
            />
          )}
          {isImage && (
            <ImageViewer
              fileUrl={fileUrl}
              downloadUrl={downloadUrl}
              onFullScreenToggle={onFullScreenToggle}
            />
          )}
        </>
      </Modal>
    );
  }
  return null;
}
