import { useTranslation } from 'react-i18next';
import _get from 'lodash/get';
import {
  QrCodesCreateInputObject,
  useJobStatusesLazyQuery,
} from 'graphql-common';
import toast from '@lib/components/Toast/Toast';
import { Values } from '@lib/interfaces/form';
import useDownloadQrCodeWatcher from 'utils/useDownloadQrCodeWatcher';
import getPreparedQrCodeDataFromValues from 'utils/getPreparedQrCodeDataFromValues';
import { FieldNames, FormType } from 'components/GetQrCodeForm/types';

// TODO: update types
type Args = {
  onCompleted: () => void;
  qrCodeCreateMutation: (v: object) => Promise<unknown>;
  variables?: object;
};

export default function useQrCodeActions(args: Args) {
  const { onCompleted, qrCodeCreateMutation, variables = {} } = args;
  const { t } = useTranslation();

  const [fetchJobStatus, { stopPolling }] = useJobStatusesLazyQuery({
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    pollInterval: 1000,
  });

  const { setJobId } = useDownloadQrCodeWatcher({
    fetchJobStatus,
    stopPolling,
  });

  const onQrCodeCreateAction = (values: Values, ids: string[]) => {
    const formType = _get(values, [FieldNames.formType, 'value']);
    const params = getPreparedQrCodeDataFromValues(
      values,
      ids,
    ) as QrCodesCreateInputObject;
    qrCodeCreateMutation({
      variables: {
        params,
        ...variables,
      },
      onCompleted,
    })
      .then((response) => {
        const job = _get(response, 'data.data');
        if (job && formType === FormType.download) {
          setJobId(job);
          toast({ content: t('get-qr-codes-download-success') });
        } else if (formType === FormType.email) {
          toast({ content: t('get-qr-codes-email-success') });
        }
      })
      .catch((error) => {
        const message = _get(error, 'message');
        let scopeMessage;
        error.graphQLErrors.forEach((errorItem) => {
          const errorMessageByScope = errorItem?.extensions?.details?.find(
            ({ path }) => path[0] === 'scope',
          )?.message;
          if (errorMessageByScope) scopeMessage = errorMessageByScope;
        });
        const content = scopeMessage || message || 'Oops! Something went wrong';
        toast({ content });
      });
  };

  return {
    onQrCodeCreateAction,
  };
}
