import { useTranslation } from 'react-i18next';
import {
  FormTemplateVersionQuery,
  useFormTemplateBulkArchiveMutation,
  useFormTemplateBulkDestroyMutation,
  useFormTemplateBulkRestoreMutation,
  useFormTemplateCreateMutation,
  useFormTemplateUpdateMutation,
} from 'graphql-common';
import { APP_URLS } from 'constants/urls';
import toast from '@lib/components/Toast/Toast';
import { showGraphQLErrors } from '@lib/utils/graphQLErrors';
import { useNavigate } from 'react-router-dom';
import { UseLoadingStateResult } from '@lib/hooks/useLoadingState';
import { FetchResult } from '@apollo/client';

type Props = {
  formTemplateVersionRefetch: () => Promise<
    FetchResult<FormTemplateVersionQuery>
  >;
  formTemplateRefetchHandler: () => void;
  useLoadingStateResult: UseLoadingStateResult;
};

export default function useTemplateMutations(props: Props) {
  const {
    useLoadingStateResult,
    formTemplateVersionRefetch,
    formTemplateRefetchHandler,
  } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [formTemplateCreateMutation, formTemplateCreateMutationResult] =
    useFormTemplateCreateMutation({
      onCompleted: (response) => {
        const { id: newId, currentVersion } = { ...response.data };
        const newVersionId = currentVersion?.id || '';
        if (newId && newVersionId) {
          navigate(
            APP_URLS.dashboard.formTemplates.viewVersion.getDynamicPath({
              pathParts: {
                id: newId,
                versionId: newVersionId,
              },
            }),
          );
        } else {
          navigate(APP_URLS.dashboard.formTemplates.index.getPathWithQuery());
        }
        toast({ content: t('create-form-template-success') });
      },
      onError: (error) => showGraphQLErrors(error, t),
    });

  const [formTemplateUpdateMutation, formTemplateUpdateMutationResult] =
    useFormTemplateUpdateMutation({
      onCompleted: (response) => {
        const { id: newId, currentVersion } = { ...response.data };
        const newVersionId = currentVersion?.id || '';
        if (newId) {
          navigate(
            APP_URLS.dashboard.formTemplates.viewVersion.getDynamicPath({
              pathParts: {
                id: newId,
                versionId: newVersionId,
              },
            }),
          );
        } else {
          navigate(APP_URLS.dashboard.formTemplates.index.getPathWithQuery());
        }
        toast({ content: t('update-form-template-success') });
      },
      onError: (error) => showGraphQLErrors(error, t),
    });

  const [formTemplateBulkArchiveMutation] = useFormTemplateBulkArchiveMutation({
    onCompleted: () => {
      formTemplateRefetchHandler();
    },
    onError: (error) => showGraphQLErrors(error, t),
  });

  const [formTemplateBulkRestoreMutation] = useFormTemplateBulkRestoreMutation({
    onCompleted: () => {
      toast({ content: t('restore-form-template-success') });
      formTemplateRefetchHandler();
      formTemplateVersionRefetch();
    },
    onError: (error) => {
      useLoadingStateResult.setLoadingState(false);
      showGraphQLErrors(error, t);
    },
  });

  const [destroyMutation] = useFormTemplateBulkDestroyMutation({
    onCompleted: () => {
      navigate(APP_URLS.dashboard.formTemplates.index.getPathWithQuery());
    },
    onError: (error) => showGraphQLErrors(error, t),
  });

  return {
    formTemplateCreateMutation,
    formTemplateCreateMutationResult,
    formTemplateUpdateMutation,
    formTemplateUpdateMutationResult,
    formTemplateBulkArchiveMutation,
    formTemplateBulkRestoreMutation,
    destroyMutation,
  };
}
