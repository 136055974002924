import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Intervention,
  InterventionScopeNameEnum,
  InterventionStatusEnum,
} from 'graphql-common';
import toast from '@lib/components/Toast/Toast';
import { UseBulkSelectResult } from '@lib/hooks/useTableItemsBulkSelect';
import usePaginationParams from '@lib/hooks/usePaginationParams';
import onDestroyListItemCallBack from '@lib/utils/onDestroyListItemCallBack';
import tableItemsBulkAction from '@lib/utils/tableItemsBulkAction';
import getAllTableItemsIds from '@lib/utils/getAllTableItemsIds';
import useInterventionsQueryHook, {
  UseAssetInterventionsQueryHookResult,
} from 'utils/fetch/useInterventionsQueryHook';
import { APP_URLS } from 'constants/urls';
import { TabBarItem as TabBarItemType } from '@lib/components/TabBar/constants';
import useTabParam from '@lib/hooks/useTabParam';
import getAppUrlWithDomain from '@lib/utils/getAppUrlWithDomain';
import { PATH_ID } from '@lib/enums/urls';
import useMutations from './useMutations';
import { UseModalManagementResult } from './useModalManagement';

const assigneeRedirectUrl = getAppUrlWithDomain(
  APP_URLS.dashboard.interventions.view.getDynamicPath({}),
).replace(PATH_ID, ':interventionId');

interface ActionsProps {
  assetId?: string;
  includeFullSearchFilters?: boolean;
  interventionScope?: InterventionScopeNameEnum;
  pathname: string;
  search: string;
  searchQuery?: string;
  skipInterventionsFetch?: boolean;
  taskId?: string;
  useBulkSelectResult?: UseBulkSelectResult<Intervention>;
  useModalManagementResult: UseModalManagementResult;
}

export interface UseInterventionActionsResult {
  interventionsQueryHook: UseAssetInterventionsQueryHookResult;
  onCloseModal: () => void;
  onConfirmedInterventionDestroy: () => void;
  openCreateInterventionPage: (params?: object) => void;
  openEditInterventionPage: (item?: Intervention) => void;
  openInterventionDestroyModal: (item: Intervention) => void;
  openInterventionCloseModal: (item: Intervention) => void;
  openViewInterventionPage: (item?: Intervention) => void;
  onTabChange: (item?: TabBarItemType) => void;
  tabParam: string;
  onConfirmedInterventionClose: () => void;
  onStatusChangeHandler: (
    interventionId: string,
    newStatus: InterventionStatusEnum,
  ) => void;
}

export default function useActions(
  props: ActionsProps,
): UseInterventionActionsResult {
  const {
    assetId,
    includeFullSearchFilters,
    interventionScope,
    pathname,
    search,
    searchQuery,
    skipInterventionsFetch,
    taskId,
    useBulkSelectResult,
    useModalManagementResult,
  } = props;

  const [tabParam = InterventionScopeNameEnum.Opened, setTabParam] =
    useTabParam(InterventionScopeNameEnum.Opened);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [paginationParams] = usePaginationParams();

  const useMutationsResult = useMutations();

  // Interventions
  const interventionsQueryHook = useInterventionsQueryHook({
    paginationParams,
    orderingParams: paginationParams,
    searchQuery,
    scopeName: interventionScope || (tabParam as InterventionScopeNameEnum),
    skip: skipInterventionsFetch,
    assetId,
    taskId,
    fetchPolicy: 'no-cache',
    includeFullSearchFilters,
  });

  // Actions
  const onGetAllIds = () =>
    getAllTableItemsIds<Intervention>({
      fetchIds: interventionsQueryHook.fetchIds,
      useBulkSelectResult,
    });

  const onCloseModal = () => {
    useBulkSelectResult?.handleUnselectAllTableItems();
    useModalManagementResult.closeAllModals();
  };

  const openInterventionDestroyModal = (item: Intervention) => {
    useBulkSelectResult?.handleSelectSingleTableItem(item);
    useModalManagementResult.openInterventionDestroyModal();
  };

  const openInterventionCloseModal = (item: Intervention) => {
    useBulkSelectResult?.handleSelectSingleTableItem(item);
    useModalManagementResult.openInterventionCloseModal();
  };

  const onConfirmedInterventionDestroy = () => {
    useModalManagementResult.closeAllModals();
    toast({
      content: t('destroy-intervention-success'),
      closeCallback: () => {
        tableItemsBulkAction<Intervention>({
          action: (idsValue?: string[]) => {
            if (idsValue) {
              useMutationsResult.interventionDestroyMutation({
                variables: { id: idsValue[0] },
                onCompleted: () => {
                  useBulkSelectResult?.handleUnselectAllTableItems();
                  onDestroyListItemCallBack({
                    collection: interventionsQueryHook.collection,
                    navigate,
                    pathname,
                    refetch: interventionsQueryHook.refetch,
                    search,
                    totalPages: interventionsQueryHook.totalPages,
                  });
                },
              });
            }
          },
          useBulkSelectResult,
          onGetAllIds,
        });
      },
    });
  };

  const openCreateInterventionPage = (params?: object) => {
    navigate(APP_URLS.dashboard.interventions.add.getPathWithQuery(params));
  };

  const openViewInterventionPage = (item?: Intervention) => {
    const id = item?.id;
    if (id) {
      navigate(
        APP_URLS.dashboard.interventions.view.getDynamicPath({
          pathParts: { id: item?.id },
        }),
      );
    }
  };

  const openEditInterventionPage = (item?: Intervention) => {
    const id = item?.id;
    if (id) {
      navigate(
        APP_URLS.dashboard.interventions.edit.getDynamicPath({
          pathParts: { id: item?.id },
        }),
      );
    }
  };

  const onTabChange = (item?: TabBarItemType) =>
    item?.value ? setTabParam(item.value) : undefined;

  const onConfirmedInterventionClose = () => {
    useModalManagementResult.closeAllModals();
    toast({
      content: t('close-interventions-success'),
      closeCallback: () => {
        tableItemsBulkAction<Intervention>({
          action: (idsValue?: string[]) => {
            if (idsValue) {
              useMutationsResult.interventionBulkCloseMutation({
                variables: { ids: idsValue },
                onCompleted: () => {
                  useBulkSelectResult?.handleUnselectAllTableItems();
                  interventionsQueryHook.refetch();
                },
              });
            }
          },
          useBulkSelectResult,
          onGetAllIds,
        });
      },
    });
  };

  const onStatusChangeHandler = (
    interventionId: string,
    newStatus: InterventionStatusEnum,
  ) => {
    if (interventionId && newStatus) {
      if (newStatus === InterventionStatusEnum.Closed) {
        openInterventionCloseModal({ id: interventionId } as Intervention);
      } else {
        useMutationsResult.interventionUpdateMutation({
          variables: {
            id: interventionId,
            attributes: { status: newStatus },
            assigneeRedirectUrl,
          },
          onCompleted: () => {
            toast({ content: t('update-intervention-success') });
            interventionsQueryHook.refetch();
          },
        });
      }
    }
  };

  return {
    interventionsQueryHook,
    onCloseModal,
    onConfirmedInterventionClose,
    onConfirmedInterventionDestroy,
    onStatusChangeHandler,
    onTabChange,
    openCreateInterventionPage,
    openEditInterventionPage,
    openInterventionCloseModal,
    openInterventionDestroyModal,
    openViewInterventionPage,
    tabParam,
  };
}
