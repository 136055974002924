import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import BreadCrumbs from '@lib/components/BreadCrumbs/BreadCrumbs';
import { FormRef } from '@lib/components/ReactHookForm/types';
import DashboardContentWithRightSidebar from '@lib/layouts/DashboardLayout/DashboardContentWithRightSidebar/DashboardContentWithRightSidebar';
import PageHeader from '@lib/layouts/DashboardLayout/PageHeader/PageHeader';
import IconButton, {
  IconButtonTypes,
} from '@lib/components/IconButton/IconButton';
import ActionsMenu from '@lib/components/ActionsMenu/ActionsMenu';
import PageBody from '@lib/layouts/DashboardLayout/PageBody/PageBody';
import Tooltip from '@lib/components/Tooltip/Tooltip';
import GraphIcon from '@lib/assets/icons/graph_icon.svg?react';
import TableIcon from '@lib/assets/icons/view_list_icon.svg?react';
import AssetCountersFilter from 'components/AssetCountersFilter/AssetCountersFilter';
import AssetCounterMeasuresTableView from 'components/AssetCounterMeasures/AssetCounterMeasuresTableView';
import { getBreadCrumbsDataByKey } from 'utils/breadcrumbs';
import AssetCounterIndexModalForm from 'components/AssetCounterIndexModalForm/AssetCounterIndexModalForm';
import AssetCounterMeasuresGraphView from 'components/AssetCounterMeasures/AssetCounterMeasuresGraphView';
import ListEmptyState from '@lib/components/ListEmptyState/ListEmptyState';
import CircleLoader from '@lib/components/CircleLoader/CircleLoader';
import Button, { ButtonTypes } from '@lib/components/Button/Button';
import getPageTitle from 'routes/Assets/AssetCounters/utils/getPageTitle';
import AssetsSidebar from './AssetsSibebar/AssetsSidebar';
import { CounterMonitoringView } from './enums';
import useModalManagement from './utils/useModalManagement';
import getCountersColumns from './utils/getCountersColumns';
import getAssetsColumns from './utils/getAssetsColumns';
import useActions from './utils/useActions';
import getCounterActionsMenu from './utils/getCounterActionsMenu';
import styles from './AssetCountersContainer.module.scss';

export default function AssetCountersContainer() {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const formRef = useRef<FormRef>();
  const [view, setView] = useState(CounterMonitoringView.Graph);

  const assetsTableColumns = getAssetsColumns({ t });
  const countersTableColumns = getCountersColumns({ t });
  const useModalManagementResult = useModalManagement();
  const useActionsResult = useActions({
    t,
    useModalManagementResult,
    view,
  });
  const {
    assetCounterIndexMutationError,
    assetCounterIndexMutationLoading,
    defaultCounterId,
    defaultPeriodValue,
    onAdd,
    onAddIndex,
    onCloseModal,
    onCounterIndexFormSubmit,
    onCreateIntervention,
    onSelectAsset,
    onSelectCounter,
    onShowSideBarAssets,
    sidebarView,
    useAssetCounterMeasureGraphDataQueryHookResult,
    useAssetCounterMeasuresQueryHookResult,
    useAssetQueryHookResult,
    useCounterQueryHookResult,
    useAssetsQueryHookResult,
  } = useActionsResult;

  const counterActionsMenu = getCounterActionsMenu({
    onAddIndex,
    onCreateIntervention,
    t,
  });

  const sidebar = (
    <AssetsSidebar
      defaultCounterId={defaultCounterId}
      assetValue={useAssetQueryHookResult.data}
      assetsTableColumns={assetsTableColumns}
      countersTableColumns={countersTableColumns}
      onSelectAsset={onSelectAsset}
      onSelectCounter={onSelectCounter}
      onShowSideBarAssets={onShowSideBarAssets}
      sidebarView={sidebarView}
    />
  );

  const isEmpty = useAssetsQueryHookResult.totalCount === 0;

  const pageSubTitle = getPageTitle(useCounterQueryHookResult.data, t);

  return (
    <>
      <BreadCrumbs data={getBreadCrumbsDataByKey({ pathname, t })} />
      {isEmpty ? (
        <>
          <PageHeader title={t('counter-monitoring')} />
          <PageBody>
            {useAssetCounterMeasureGraphDataQueryHookResult.loading ||
            useAssetCounterMeasuresQueryHookResult.loading ? (
              <CircleLoader />
            ) : (
              <ListEmptyState
                title={t('no-asset-counters-created-yet')}
                text={t('no-asset-counters-created-text')}
                actions={[
                  <Button
                    key="create"
                    onClick={() => onAdd()}
                    buttonType={ButtonTypes.primaryFilled}
                    buttonText={t('create-new-equipment')}
                    leftIcon="add"
                    fullWidth
                  />,
                ]}
              />
            )}
          </PageBody>
        </>
      ) : (
        <DashboardContentWithRightSidebar sidebar={sidebar}>
          <>
            <PageHeader title={t('counter-monitoring')} />
            <AssetCountersFilter defaultPeriodValue={defaultPeriodValue} />
            <>
              <PageHeader
                title={pageSubTitle}
                titleVariant="h3"
                className={styles.counterHeader}
              >
                <div className={styles.headerActions}>
                  <div className={styles.viewChangeActions}>
                    <Tooltip body={t('graph-view')}>
                      <IconButton
                        svgIcon={<GraphIcon />}
                        onClick={() => setView(CounterMonitoringView.Graph)}
                        type={
                          view === CounterMonitoringView.Graph
                            ? IconButtonTypes.PrimaryFilled
                            : IconButtonTypes.PrimaryOutlined
                        }
                      />
                    </Tooltip>
                    <Tooltip body={t('table-view')}>
                      <IconButton
                        svgIcon={<TableIcon />}
                        onClick={() => setView(CounterMonitoringView.Table)}
                        type={
                          view === CounterMonitoringView.Table
                            ? IconButtonTypes.PrimaryFilled
                            : IconButtonTypes.PrimaryOutlined
                        }
                      />
                    </Tooltip>
                  </div>
                  <ActionsMenu
                    actions={counterActionsMenu}
                    disabled={!!useCounterQueryHookResult.error}
                  />
                </div>
              </PageHeader>
              <PageBody>
                <div>
                  {view === CounterMonitoringView.Graph && (
                    <AssetCounterMeasuresGraphView
                      onAddIndex={onAddIndex}
                      data={useAssetCounterMeasureGraphDataQueryHookResult.data}
                      loading={
                        useAssetCounterMeasureGraphDataQueryHookResult.loading
                      }
                    />
                  )}
                  {view === CounterMonitoringView.Table && (
                    <AssetCounterMeasuresTableView
                      onAddIndex={onAddIndex}
                      loading={useAssetCounterMeasuresQueryHookResult.loading}
                      collection={
                        useAssetCounterMeasuresQueryHookResult.collection
                      }
                      totalEntries={
                        useAssetCounterMeasuresQueryHookResult.totalCount
                      }
                      counter={useCounterQueryHookResult.data}
                    />
                  )}
                </div>
              </PageBody>
            </>
          </>
        </DashboardContentWithRightSidebar>
      )}
      <AssetCounterIndexModalForm
        assetCounter={useCounterQueryHookResult.data}
        formRef={formRef}
        graphQLErrors={assetCounterIndexMutationError?.graphQLErrors}
        isOpen={useModalManagementResult.isCounterIndexFormModalOpened}
        loading={
          useCounterQueryHookResult.loading || assetCounterIndexMutationLoading
        }
        onCancel={onCloseModal}
        onClose={onCloseModal}
        onSubmit={onCounterIndexFormSubmit}
      />
    </>
  );
}
