import {
  AssetNomenclaturesQueryVariables,
  Nomenclature,
  NomenclatureFilterInputObject,
  useAssetNomenclaturesIdsLazyQuery,
  useAssetNomenclaturesQuery,
} from 'graphql-common';
import _get from 'lodash/get';
import { GetUrlParams } from '@lib/enums/urls';
import createSearchFilters from '@lib/utils/createSearchFilters';
import { ApolloError } from '@apollo/client/errors';
import { FetchIdsResponse } from '@lib/utils/getAllTableItemsIds';
import { ListQueryHookProps } from './types';

export type UseAssetNomenclaturesQueryHookResult = {
  collection: Nomenclature[];
  error: ApolloError | undefined;
  fetchIds: () => Promise<FetchIdsResponse<unknown>>;
  firstLoading: boolean;
  loading: boolean;
  refetch: () => Promise<unknown>;
  totalCount: number;
  totalPages: number;
};

type Args = ListQueryHookProps & { mainAssetId: string };

export default function useAssetNomenclaturesQueryHook({
  paginationParams,
  searchQuery,
  skip,
  mainAssetId,
}: Args): UseAssetNomenclaturesQueryHookResult {
  const page = _get(paginationParams, GetUrlParams.Page);
  const perPage = _get(paginationParams, GetUrlParams.PerPage);
  const orderingField = _get(paginationParams, GetUrlParams.OrderingField);
  const orderingDirection = _get(
    paginationParams,
    GetUrlParams.OrderingDirection,
  );
  const assetsQueryVariables: AssetNomenclaturesQueryVariables = {
    page: Number(page),
    limit: Number(perPage),
    mainAssetId,
  };
  const filters: NomenclatureFilterInputObject[] = [];
  if (searchQuery) {
    filters.push(
      ...createSearchFilters(searchQuery, [
        { fieldName: 'componentAssetName' },
        { fieldName: 'componentAssetCode' },
      ]),
    );
  }
  if (filters.length) {
    assetsQueryVariables.filters = filters;
  }
  if (orderingField && orderingDirection) {
    assetsQueryVariables.sorts = {
      [orderingField]: orderingDirection,
    };
  }
  const nomenclaturesQuery = useAssetNomenclaturesQuery({
    fetchPolicy: 'cache-and-network',
    variables: assetsQueryVariables,
    skip,
  });
  const [fetchIds] = useAssetNomenclaturesIdsLazyQuery({
    fetchPolicy: 'no-cache',
    variables: {
      onlyIds: true,
      filters: assetsQueryVariables.filters,
      mainAssetId,
    },
  });
  const { loading, data, previousData, refetch, error } = nomenclaturesQuery;
  const queryData = data || previousData;
  const firstLoading = loading && previousData === undefined;
  const collection = _get(queryData, 'data.collection', []) as Nomenclature[];
  const metadata = _get(queryData, 'data.metadata');
  const totalCount = _get(metadata, 'totalCount', 0);
  const totalPages = _get(metadata, 'totalPages', 1);

  return {
    fetchIds,
    refetch,
    collection,
    error,
    firstLoading,
    loading,
    totalPages,
    totalCount,
  };
}
