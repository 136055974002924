import React from 'react';
import { useTranslation } from 'react-i18next';
import { Site } from 'graphql-common';
import { Values } from '@lib/interfaces/form';
import DashboardContentMaxWidth from '@lib/layouts/DashboardLayout/DashboardContentMaxWidth/DashboardContentMaxWidth';
import PageBody from '@lib/layouts/DashboardLayout/PageBody/PageBody';
import Button, {
  ButtonSizes,
  ButtonTypes,
} from '@lib/components/Button/Button';
import CircleLoader from '@lib/components/CircleLoader/CircleLoader';
import ConductCodeView from 'components/ConductCodeView/ConductCodeView';
import ConductCodeForm from 'components/ConductCodeForm/ConductCodeForm';
import { materialSymbolsOutlined } from '@lib/enums/common';
import WhiteBox from '@lib/components/WhiteBox/WhiteBox';
// eslint-disable-next-line import/no-cycle
import { SiteViewMode } from 'components/Site/Site'; // TODO: fix this
import PageHeader from '@lib/layouts/DashboardLayout/PageHeader/PageHeader';
import Typography from '@lib/components/Typography/Typography';
import ConfirmationModal from '@lib/components/ConfirmationModal/ConfirmationModal';
import { ApolloError } from '@apollo/client/errors';
import ErrorPage from '@lib/components/ErrorPage/ErrorPage';
import getStatusFromError from '@lib/utils/getStatusFromError';
import { IconName } from '@lib/components/Modal/enums';
import styles from './Site.module.scss';

interface Props {
  closeCodeOfConductDeleteModal: () => void;
  isCodeOfConductDeleteModalOpened: boolean;
  onConfirmedDeleteCodeOfConduct: () => void;
  onDeleteCodeOfConduct: () => void;
  onEdit: (values: Values) => void;
  onEditCodeOfConduct: () => void;
  onViewCodeOfConduct: () => void;
  siteData: Site;
  siteError?: ApolloError;
  siteLoading?: boolean;
  updateSiteLoading: boolean;
  updateMutationError?: ApolloError;
  viewMode: SiteViewMode;
}

function SiteCodeOfConduct(props: Props) {
  const {
    closeCodeOfConductDeleteModal,
    isCodeOfConductDeleteModalOpened,
    onConfirmedDeleteCodeOfConduct,
    onDeleteCodeOfConduct,
    onEdit,
    onEditCodeOfConduct,
    onViewCodeOfConduct,
    siteData,
    siteError,
    siteLoading = false,
    updateSiteLoading,
    updateMutationError,
    viewMode,
  } = props;
  const { t } = useTranslation();

  const getTitle = () => {
    if (viewMode === SiteViewMode.viewCodeOfConduct)
      return t('view-code-of-conduct');
    if (viewMode === SiteViewMode.editCodeOfConduct)
      return t('edit-code-of-conduct');
    return t('code-of-conduct');
  };

  if (siteError) {
    return (
      <ErrorPage
        status={getStatusFromError(siteError)}
        description={siteError?.message}
      />
    );
  }

  return (
    <DashboardContentMaxWidth>
      <PageHeader title={getTitle()} />
      <PageBody>
        {siteLoading ? (
          <CircleLoader />
        ) : (
          <>
            <WhiteBox>
              {viewMode === SiteViewMode.viewCodeOfConduct && (
                <div className={styles.header}>
                  <Typography variant="h2" className={styles.title}>
                    {siteData?.name}
                  </Typography>
                  <Button
                    buttonText={t('edit')}
                    buttonType={ButtonTypes.primaryFilled}
                    leftIcon="edit"
                    onClick={onEditCodeOfConduct}
                  />
                </div>
              )}
              {viewMode === SiteViewMode.viewCodeOfConduct && (
                <ConductCodeView siteData={siteData} />
              )}
              {viewMode === SiteViewMode.editCodeOfConduct && (
                <ConductCodeForm
                  siteData={siteData as Values}
                  loading={updateSiteLoading}
                  onCancel={onViewCodeOfConduct}
                  onSubmit={onEdit}
                  graphQLErrors={updateMutationError?.graphQLErrors}
                />
              )}
            </WhiteBox>
            {siteData.siteConductCodeSections?.length > 0 && (
              <div className={styles.conductCodeSectionsFooter}>
                <Button
                  buttonText={t('delete-code-of-conduct')}
                  buttonType={ButtonTypes.negativeOutlined}
                  buttonSize={ButtonSizes.small}
                  leftIcon="delete"
                  leftIconClassName={materialSymbolsOutlined}
                  onClick={onDeleteCodeOfConduct}
                />
              </div>
            )}
          </>
        )}
      </PageBody>
      <ConfirmationModal
        confirmButtonLabel={t('yes-delete')}
        description={t('delete-code-of-conduct-text')}
        icon={IconName.Delete}
        isOpen={isCodeOfConductDeleteModalOpened}
        onClose={closeCodeOfConductDeleteModal}
        onConfirm={onConfirmedDeleteCodeOfConduct}
        title={t('are-you-sure')}
      />
    </DashboardContentMaxWidth>
  );
}

export default SiteCodeOfConduct;
