import React, { forwardRef, memo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import _get from 'lodash/get';
import Button, {
  ButtonProps,
  ButtonSizes,
  ButtonTypes,
} from '@lib/components/Button/Button';
import FileUploaderUppyAwsS3, {
  AttachmentValue,
  UploaderView,
} from '@lib/components/FileUploaderUppyXHR/FileUploaderUppyXHR';
import Placeholder from '@lib/assets/images/preview-placeholder.svg';
import Image from '@lib/components/Image/Image';
import { materialSymbolsOutlined } from '@lib/enums/common';
import useIsOnline from '@lib/hooks/useIsOnline';
import SupportiveMessage, {
  SupportiveMessageColor,
} from '@lib/components/SupportiveMessage/SupportiveMessage';
import IconButton, {
  IconButtonTypes,
} from '@lib/components/IconButton/IconButton';
import DocumentationPreview from '@lib/components/DocumentationPreview/DocumentationPreview';
import { FilesPreview } from '@lib/components/FilesUploader/enums';
import styles from './FilesUploader.module.scss';

export type FilesUploaderProps = {
  allowedFileTypes: string[];
  bodyClassName?: string;
  className?: string;
  disabled?: boolean;
  filesView?: FilesPreview;
  maxFileSize?: number;
  note?: string;
  onChange: (variables: AttachmentValue[]) => void;
  openUploaderModalOnInit?: boolean;
  showDeleteAllButton?: boolean;
  showOfflineMessage?: boolean;
  uploadButtonProps?: ButtonProps;
  value?: AttachmentValue[];
};

function ImagesView({ value }: { value: AttachmentValue[] }) {
  return (
    <div className={styles.images}>
      {value?.map((itemValue) => {
        const url =
          itemValue?.urls?.large || itemValue?.urls?.original || itemValue?.url;
        if (_get(itemValue, '_destroy')) return null;
        return (
          <div className={styles.img} key={uuidv4()}>
            {(url || Placeholder) && <Image src={url || Placeholder} />}
          </div>
        );
      })}
    </div>
  );
}

function DocumentsView({
  value,
  onDeleteAttachment,
}: {
  value: AttachmentValue[];
  onDeleteAttachment: (id?: string, itemValueFileId?: string) => void;
}) {
  return (
    <div className={styles.documentationsPreview}>
      {value.map((itemValue) => {
        const itemValueId = itemValue?.id;
        const itemValueFileId = itemValue?.file?.id;
        return (
          <div className={styles.documentationPreview} key={uuidv4()}>
            <DocumentationPreview
              // @ts-ignore
              attachment={itemValue}
              useDefaultPdfPreview={!itemValue.id}
            />
            {(itemValueId || itemValueFileId) && (
              <IconButton
                icon="close"
                symbolsOutlined
                rounded
                type={IconButtonTypes.TertiaryFilled}
                className={styles.deleteButton}
                onClick={() => onDeleteAttachment(itemValueId, itemValueFileId)}
              />
            )}
          </div>
        );
      })}
    </div>
  );
}

const FilesUploader = forwardRef(
  (props: FilesUploaderProps, ref: React.ForwardedRef<HTMLInputElement>) => {
    const { t } = useTranslation();
    const isOnline = useIsOnline();
    const {
      allowedFileTypes,
      bodyClassName,
      className,
      disabled,
      filesView = FilesPreview.Images,
      maxFileSize,
      note,
      onChange,
      openUploaderModalOnInit,
      showDeleteAllButton,
      showOfflineMessage,
      uploadButtonProps,
      value = [],
    } = props;

    const {
      leftIcon = 'edit',
      buttonSize = ButtonSizes.small,
      buttonType = ButtonTypes.primaryOutlined,
      buttonText = t('upload-file'),
      fullWidth = false,
      ...restUploadButtonProps
    } = uploadButtonProps || {};

    // @ts-ignore Field _destroy is custom field
    const preparedValue = value.filter(({ _destroy }) => !_destroy);
    const uploadButton = ({ onClick }: { onClick: () => void }) => (
      <Button
        onClick={onClick}
        buttonText={preparedValue?.length ? t('upload-more') : buttonText}
        buttonType={buttonType}
        leftIcon={leftIcon}
        leftIconClassName={materialSymbolsOutlined}
        buttonSize={buttonSize}
        disabled={disabled || !isOnline}
        fullWidth={fullWidth}
        {...restUploadButtonProps}
      />
    );

    const onDeleteAttachment = (id?: string, itemValueFileId?: string) => {
      let newValue = value.map((item) =>
        item.id === id ? { ...item, _destroy: true } : item,
      );
      if (itemValueFileId) {
        newValue = newValue.filter(
          (item) => item?.file?.id !== itemValueFileId,
        );
      }
      onChange(newValue);
    };

    const onDeleteAllAttachments = () => {
      const newValue = value.reduce(
        (acc: { id: string; _destroy: boolean }[], item) => {
          if (item.id) acc.push({ id: item.id, _destroy: true });
          return acc;
        },
        [],
      );
      onChange(newValue);
    };

    return (
      <div className={className}>
        {filesView === FilesPreview.Images && preparedValue?.length > 0 && (
          <ImagesView value={preparedValue} />
        )}
        {filesView === FilesPreview.Documents && preparedValue?.length > 0 && (
          <DocumentsView
            value={preparedValue}
            onDeleteAttachment={onDeleteAttachment}
          />
        )}
        <div
          className={classNames(
            styles.body,
            { [styles.bodyWithFullWidthBtn]: fullWidth },
            bodyClassName,
          )}
        >
          <FileUploaderUppyAwsS3
            allowedFileTypes={allowedFileTypes}
            maxNumberOfFiles={10}
            note={note}
            onChange={onChange}
            openButton={uploadButton}
            openUploaderModalOnInit={openUploaderModalOnInit}
            ref={ref}
            type={UploaderView.modal}
            value={value}
            maxFileSize={maxFileSize}
          />
          {showDeleteAllButton && !!preparedValue?.length && (
            <Button
              buttonText={t('delete-all')}
              buttonType={ButtonTypes.negativeOutlined}
              leftIcon="delete"
              leftIconClassName={materialSymbolsOutlined}
              buttonSize={ButtonSizes.small}
              onClick={() => onDeleteAllAttachments()}
              disabled={disabled}
            />
          )}
        </div>
        {!isOnline && showOfflineMessage && (
          <div style={{ marginTop: '16px' }}>
            <SupportiveMessage
              color={SupportiveMessageColor.Yellow}
              text={t('attachment-field-offline-short-text')}
              fullWidth
            />
          </div>
        )}
      </div>
    );
  },
);

export default memo(FilesUploader);
