import React from 'react';
import { TFunction } from 'i18next';
import _get from 'lodash/get';
import _toLower from 'lodash/toLower';
import _isEmpty from 'lodash/isEmpty';
import _upperFirst from 'lodash/upperFirst';
import { Task } from 'graphql-common';
import { CellProps, Column } from '@lib/components/Table/types';
import TextCell from '@lib/components/Table/commonComponents/TextCell/TextCell';
import ChipCell from '@lib/components/Table/commonComponents/ChipCell/ChipCell';
import TextLinkCell from '@lib/components/Table/commonComponents/TextLinkCell/TextLinkCell';
import Chip, { ChipTypes } from '@lib/components/Chip/Chip';
import { getFormattedDate } from '@lib/utils/dateTimeHelpers';
import doNotRepeatIconSrc from '@lib/assets/icons/custom_do_not_repeat.svg';
import { getPriorityChipType } from '@lib/utils/priority';
import { getValidityHintChipIcon } from '@lib/components/Select/components/ValidityHintOption';
import ResultStateChip from 'components/ResultStateChip/ResultStateChip';

type ColumnsArgs = {
  t: TFunction<'translation', undefined>;
  onViewTask: (taskId: string) => void;
};

export default function getTaskTableColumns({
  t,
  onViewTask,
}: ColumnsArgs): Column<Task>[] {
  return [
    {
      id: 'completion.validityHint',
      Header: ' ',
      accessor: 'completion.validityHint',
      Cell: ({ value }: CellProps<Task>) =>
        getValidityHintChipIcon(value as string),
      width: 44,
    },
    {
      id: 'recurringRule',
      Header: ' ',
      accessor: 'recurringRuleId',
      Cell: (props: CellProps<Task>) => {
        const isRepeatable = !_isEmpty(_get(props, 'value'));
        return (
          <Chip
            // className={styles.repeatStatusChip}
            type={isRepeatable ? ChipTypes.chipBlue : ChipTypes.chipGray}
            leftIcon={isRepeatable ? 'repeat' : undefined}
            leftImage={isRepeatable ? undefined : doNotRepeatIconSrc}
            tooltip={isRepeatable ? t('recurring') : t('non-recurring')}
            asBlockElement
          />
        );
      },
      width: 44,
      minWidth: 44,
      maxWidth: 44,
    },
    {
      id: 'name',
      Header: t('name'),
      accessor: 'name',
      Cell: (props: CellProps<Task>) => {
        const { item } = props;
        return <TextLinkCell {...props} onClick={() => onViewTask(item?.id)} />;
      },
      minWidth: 100,
      maxWidth: 300,
    },
    {
      id: 'completionStatus',
      Header: t('status'),
      accessor: 'resultState',
      Cell: ({ item }: CellProps<Task>) => <ResultStateChip task={item} />,
      width: 150,
      maxWidth: 150,
      minWidth: 100,
    },
    {
      id: 'completionSolvedAt',
      Header: t('date-solved'),
      accessorFn: ({ item }: CellProps<Task>) =>
        item.completion?.solvedAt
          ? getFormattedDate(item.completion.solvedAt)
          : '-',
      Cell: (props: CellProps<Task>) => <TextCell {...props} />,
      width: 150,
      maxWidth: 150,
      minWidth: 100,
    },
    {
      id: 'siteName',
      Header: t('site'),
      accessor: 'site',
      Cell: (props: CellProps<Task>) => {
        const label = _get(props, 'item.site.name', '');
        if (!label) return '-';
        return <ChipCell label={label} type={ChipTypes.chipGray} />;
      },
      width: 200,
      maxWidth: 200,
      minWidth: 100,
    },
    {
      id: 'siteAreaName',
      Header: t('area'),
      accessor: 'siteArea',
      Cell: (props: CellProps<Task>) => {
        const label = _get(props, 'item.siteArea.name', '');
        if (!label) return '-';
        return <ChipCell label={label} type={ChipTypes.chipGray} />;
      },
      width: 200,
      maxWidth: 200,
      minWidth: 100,
    },
    {
      id: 'priority',
      Header: t('priority'),
      accessor: 'priority',
      Cell: (props: CellProps<Task>) => {
        const priority = _get(props, 'item.priority');
        const label = _upperFirst(_toLower(priority));
        if (!label) return '-';
        return (
          <ChipCell
            label={label}
            type={getPriorityChipType(priority)}
            leftIcon="circle"
          />
        );
      },
      width: 120,
      maxWidth: 120,
      minWidth: 100,
    },
    {
      id: 'lastCheckAt',
      Header: t('last-check'),
      accessorFn: ({ item }: CellProps<Task>) =>
        item.lastCheckAt ? getFormattedDate(item.lastCheckAt) : '-',
      Cell: (props: CellProps<Task>) => <TextCell {...props} />,
      maxWidth: 150,
      minWidth: 100,
    },
    {
      id: 'deadlineAt',
      Header: t('next-check'),
      accessorFn: ({ item }: CellProps<Task>) =>
        item.deadlineAt ? getFormattedDate(item.deadlineAt) : '-',
      Cell: (props: CellProps<Task>) => <TextCell {...props} />,
      width: 150,
      maxWidth: 150,
      minWidth: 100,
    },
  ];
}
