import React from 'react';
import { IconButtonTypes } from '@lib/components/IconButton/IconButton';
import { NavItem } from '@lib/components/Menu/Menu';
import { PaginationProps } from '@lib/hooks/usePagination';
import { OrderingState, PaginationState } from '@lib/enums/urls';
import { TooltipProps } from '@lib/components/Tooltip/Tooltip';
import { UseBulkSelectResult } from '@lib/hooks/useTableItemsBulkSelect';

export interface BaseItem extends Record<string, unknown> {
  id: number | string;
}

export interface TableAction<T extends BaseItem> {
  id: string;
  onClick: (item: T) => void;
  icon: string;
  type: IconButtonTypes;
  predictorFnVisible?: (item: T) => boolean;
  predictorFnDisable?: (item: T) => boolean;
  predictorFnDisableTooltipProps?: (item: T) => Omit<TooltipProps, 'children'>;
}

export interface CellProps<T extends BaseItem> {
  item: T;
  value?: React.ReactNode;
  tableProps: TableProps<T>;
  disabled?: boolean;
  onClick?: () => void;
  currentUserId?: string;
}

export type Cell<T extends BaseItem> =
  | React.FC<CellProps<T>>
  | ((props: CellProps<T>) => React.JSX.Element);

export enum CellContentAlign {
  Start = 'start',
  Center = 'center',
  End = 'end',
}

export interface Column<T extends BaseItem> {
  Cell?: Cell<T>;
  Footer?: React.FC<{ data: T[] }>;
  Header: React.ReactNode;
  accessor?: string;
  accessorFn?: (props: CellContentProps<T>) => React.ReactNode;
  actions?: TableAction<T>[];
  actionsMenu?: NavItem<T>[];
  bordered?: boolean;
  cellClassName?: string;
  contentAlign?: CellContentAlign;
  hideHeader?: boolean;
  id?: string | number;
  isCustomHeader?: boolean;
  isSingleLineHeader?: boolean;
  maxWidth?: number;
  minWidth?: number;
  noWrap?: boolean;
  prohibitDisabled?: boolean;
  sortable?: boolean;
  width?: number;
}

type OnSortChangeFn = (
  newOrderingField?: string,
  newOrderingDirection?: string,
) => void;

type TableComponents = {
  TopHeadComponent: React.ElementType;
};

export interface TableProps<T extends BaseItem> extends PaginationProps {
  Footer?: React.FC;
  actions?: TableAction<T>[];
  actionsForSelected?: React.ReactNode[];
  actionsMenu?: NavItem<T>[];
  bordered?: boolean;
  borderedCells?: boolean;
  className?: string;
  columns: Column<T>[];
  components?: TableComponents;
  counterText?: string;
  data: T[];
  emptyState?: React.ReactNode;
  fullHeight?: boolean;
  getIsRowSelected?: (item: T) => boolean;
  getRowClassName?: (v: T) => string | undefined;
  id?: string | number;
  getIsRowDisabled?: (item: T) => boolean;
  loading?: boolean;
  onRowClick?: (item: T) => void;
  onSortChange?: OnSortChangeFn;
  orderingDirection?: string;
  orderingDirectionKey?: string;
  orderingField?: string;
  orderingFieldKey?: string;
  orderingState?: OrderingState;
  paginationState?: PaginationState;
  rounded?: boolean;
  tableContainerClassName?: string;
  useBulkSelectResult?: UseBulkSelectResult<T>;
}

export interface CellContentProps<T extends BaseItem> {
  column: Column<T>;
  value?: string;
  item: T;
  tableProps: TableProps<T>;
}
