import React from 'react';
import { useTranslation } from 'react-i18next';
import { UseFieldArrayAppend, FieldValues } from 'react-hook-form';
import Button, {
  ButtonSizes,
  ButtonTypes,
} from '@lib/components/Button/Button';
import { AppendValue } from '@lib/components/ReactHookForm/types';
import styles from './OptionsAddItemComponent.module.scss';

interface Props {
  append: UseFieldArrayAppend<FieldValues, string>;
  appendValue: AppendValue;
  isAddMoreVisible: boolean;
}

function OptionsAddItemComponent({
  append,
  appendValue,
  isAddMoreVisible,
}: Props) {
  const { t } = useTranslation();
  return (
    <div className={styles.addItemBtnWrapper}>
      <Button
        buttonSize={ButtonSizes.small}
        buttonText={t('add-option')}
        buttonType={ButtonTypes.secondaryOutlined}
        disabled={!isAddMoreVisible}
        leftIcon="add"
        onClick={() => append(appendValue)}
      />
    </div>
  );
}

export default OptionsAddItemComponent;
