import React from 'react';
import classNames from 'classnames';
import _get from 'lodash/get';
import {
  components as DefaultSelectComponents,
  OptionProps,
} from 'react-select';
import MaterialIcon from '@lib/components/MaterialIcon/MaterialIcon';
import styles from '../Select.module.scss';

function Option({ children, ...restProps }: OptionProps) {
  const { isSelected, isMulti, data } = restProps;
  const isCreateOption = _get(data, '__isNew__');
  return (
    <DefaultSelectComponents.Option
      {...restProps}
      className={classNames(styles.option, {
        active: isSelected,
        isCreateOption,
        [styles.optionWithCheckbox]: isMulti,
      })}
    >
      {isMulti && (
        <span className={styles.optionCheckbox}>
          <MaterialIcon
            icon={isSelected ? 'check_box' : 'check_box_outline_blank'}
            size="md-18"
          />
        </span>
      )}
      {children}
    </DefaultSelectComponents.Option>
  );
}

export default Option;
