import React, { forwardRef, memo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { SelectProps } from 'antd';
import _get from 'lodash/get';
import { FloatingLabelProps } from '@lib/hocs/withFloatingLabel';
import Typography from '@lib/components/Typography/Typography';
import Tooltip from '@lib/components/Tooltip/Tooltip';
import MaterialIcon from '@lib/components/MaterialIcon/MaterialIcon';
import FormItem from '@lib/components/ReactHookForm/FormItem';
import RadioButtonGroup, {
  RadioButtonWrapViews,
} from '@lib/components/RadioButtonGroup/RadioButtonGroup';
import RadioButtonAsButton from '@lib/components/RadioButtonAsButton/RadioButtonAsButton';
import InterventionCategoriesChipsListField from '@lib/components/InterventionCategoriesChipsListField/InterventionCategoriesChipsListField';
import AssetCategoriesChipsListField from '@lib/components/AssetCategoriesChipsListField/AssetCategoriesChipsListField';
import Select from '@lib/components/Select/Select';
import { GetLoadOptions } from '@lib/components/Select/types';
import MultiSelectValueContainer from '@lib/components/Select/components/MultiSelectValueContainer';
import {
  AccessesFieldType,
  getAccessesManagementOptions,
  getAccessLevelOptions,
  getAccessLevelShortOptions,
} from '@lib/utils/accessesManagement';
import CheckBoxSingleBool from '@lib/components/CheckBoxSingleBool/CheckBoxSingleBool';
import CheckBoxAsToggle from '@lib/components/CheckBoxAsToggle/CheckBoxAsToggle';
import FieldNames from '@lib/enums/fieldNames/userFieldNames';
import { Values } from '@lib/interfaces/form';
import { ComponentEnum, LevelEnum } from 'graphql-common';
import Chip, { ChipSizes, ChipTypes } from '@lib/components/Chip/Chip';
import { APP_URLS } from 'constants/urls';
import useOutletContext, { ContextProps } from 'hooks/useOutletContext';
import styles from './UserAccessField.module.scss';

type Props = {
  name: string;
  getAssetCategoriesLoadOptions: GetLoadOptions;
  getInterventionCategoriesLoadOptions: GetLoadOptions;
  personalInterventionCategoriesIds: string[];
  teamInterventionCategoriesIds: string[];
  personalAssetCategoriesIds: string[];
  teamAssetCategoriesIds: string[];
  values: Values;
};

const UserAccessField = forwardRef(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  (props: Props & FloatingLabelProps, ref: React.ForwardedRef<unknown>) => {
    const {
      name,
      getAssetCategoriesLoadOptions,
      getInterventionCategoriesLoadOptions,
      personalInterventionCategoriesIds,
      teamInterventionCategoriesIds,
      personalAssetCategoriesIds,
      teamAssetCategoriesIds,
      values: formValues,
    } = props;
    const { t } = useTranslation();
    const { getUserAccessByCompany }: ContextProps = useOutletContext();
    const isInterventionsLocked =
      getUserAccessByCompany(ComponentEnum.InterventionsManagement) ===
      LevelEnum.LimitedRead;

    return (
      <div>
        {getAccessesManagementOptions().map((option) => (
          <div key={option.value} className={styles.userAccessListItem}>
            <div
              className={classNames(styles.userAccessListItemContent, {
                [styles.userAccessListItemContentRow]:
                  option.value === ComponentEnum.InterventionsManagement &&
                  isInterventionsLocked,
              })}
            >
              <div className={styles.userAccessListItemTitleWrap}>
                <div className={styles.userAccessListItemTitle}>
                  <Typography variant="body" strong>
                    {t(option.labelTransKey)}
                  </Typography>
                  {!!option.descriptionTransKey && (
                    <div className={styles.userAccessListItemTitleInfo}>
                      <Tooltip body={t(option.descriptionTransKey)}>
                        <MaterialIcon
                          icon="info"
                          symbolsOutlined
                          size="md-16"
                        />
                      </Tooltip>
                    </div>
                  )}
                </div>
              </div>
              {option.value === ComponentEnum.InterventionsManagement &&
              isInterventionsLocked ? (
                <Tooltip body={t('lock-state-title-interventions')}>
                  <Chip
                    leftIcon="lock"
                    size={ChipSizes.small}
                    type={ChipTypes.chipBlue}
                    to={APP_URLS.dashboard.interventions.index.path}
                    asBlockElement
                  />
                </Tooltip>
              ) : (
                <>
                  <div
                    className={classNames(
                      styles.userAccessListItemField,
                      styles.row,
                    )}
                  >
                    <FormItem
                      name={`${name}.${option.value}.level`}
                      element={RadioButtonGroup}
                      componentProps={{
                        options: getAccessLevelOptions(),
                        RadioButtonComponent: RadioButtonAsButton,
                        wrapView: RadioButtonWrapViews.row,
                      }}
                      hideErrorsSpace
                      isVisibleFn={(values: Values) =>
                        !!_get(values, `${name}.${option.value}.isCustom`)
                      }
                    />
                    <FormItem
                      name={`${FieldNames.TeamAccesses}.${option.value}.level`}
                      element={RadioButtonGroup}
                      componentProps={{
                        options: getAccessLevelOptions(),
                        RadioButtonComponent: RadioButtonAsButton,
                        wrapView: RadioButtonWrapViews.row,
                        disabled: true,
                      }}
                      hideErrorsSpace
                      isVisibleFn={(values: Values) =>
                        !_get(values, `${name}.${option.value}.isCustom`)
                      }
                    />
                  </div>
                  <div className={styles.userAccessListItemField}>
                    <div className={styles.userAccessListItemFieldRow}>
                      <Typography variant="caption">{t('team')}</Typography>
                      <FormItem
                        name={`${name}.${option.value}.isCustom`}
                        element={CheckBoxSingleBool}
                        componentProps={{
                          CheckBoxComponent: CheckBoxAsToggle,
                          options: [{ label: 'checked', value: 'checked' }],
                          value: _get(
                            formValues,
                            `${name}.${option.value}.isCustom`,
                          ),
                        }}
                        hideErrorsSpace
                        isUseWatch
                      />
                      <Typography variant="caption">{t('custom')}</Typography>
                    </div>
                  </div>
                </>
              )}
            </div>
            {option.value === ComponentEnum.InterventionsManagement &&
            isInterventionsLocked
              ? null
              : !!option.subOptions?.length &&
                option.subOptions.map((subOption) => (
                  <div
                    key={subOption.value}
                    className={styles.userAccessListItemContent}
                  >
                    <div className={styles.userAccessListItemTitleWrap}>
                      <div className={styles.userAccessListItemTitle}>
                        <Typography variant="body">
                          {t(subOption.labelTransKey)}
                        </Typography>
                        {!!subOption.descriptionTransKey && (
                          <div className={styles.userAccessListItemTitleInfo}>
                            <Tooltip body={t(subOption.descriptionTransKey)}>
                              <MaterialIcon
                                icon="info"
                                symbolsOutlined
                                size="md-16"
                              />
                            </Tooltip>
                          </div>
                        )}
                      </div>
                    </div>
                    {subOption.fieldType === AccessesFieldType.FullOptions && (
                      <div
                        className={classNames(
                          styles.userAccessListItemField,
                          styles.row,
                        )}
                      >
                        <FormItem
                          name={`${name}.${subOption.value}.level`}
                          element={RadioButtonGroup}
                          componentProps={{
                            options: getAccessLevelOptions(),
                            RadioButtonComponent: RadioButtonAsButton,
                            wrapView: RadioButtonWrapViews.row,
                          }}
                          hideErrorsSpace
                          isVisibleFn={(values: Values) =>
                            !!_get(
                              values,
                              `${name}.${subOption.value}.isCustom`,
                            )
                          }
                        />
                        <FormItem
                          name={`${FieldNames.TeamAccesses}.${subOption.value}.level`}
                          element={RadioButtonGroup}
                          componentProps={{
                            options: getAccessLevelOptions(),
                            RadioButtonComponent: RadioButtonAsButton,
                            wrapView: RadioButtonWrapViews.row,
                            disabled: true,
                          }}
                          hideErrorsSpace
                          isVisibleFn={(values: Values) =>
                            !_get(values, `${name}.${subOption.value}.isCustom`)
                          }
                        />
                      </div>
                    )}
                    {subOption.fieldType === AccessesFieldType.ShortOptions && (
                      <div
                        className={classNames(
                          styles.userAccessListItemField,
                          styles.row,
                        )}
                      >
                        <FormItem
                          name={`${name}.${subOption.value}.level`}
                          element={RadioButtonGroup}
                          componentProps={{
                            options: getAccessLevelShortOptions(),
                            RadioButtonComponent: RadioButtonAsButton,
                            wrapView: RadioButtonWrapViews.row,
                          }}
                          hideErrorsSpace
                          isVisibleFn={(values: Values) =>
                            !!_get(
                              values,
                              `${name}.${subOption.value}.isCustom`,
                            )
                          }
                        />
                        <FormItem
                          name={`${FieldNames.TeamAccesses}.${subOption.value}.level`}
                          element={RadioButtonGroup}
                          componentProps={{
                            options: getAccessLevelShortOptions(),
                            RadioButtonComponent: RadioButtonAsButton,
                            wrapView: RadioButtonWrapViews.row,
                            disabled: true,
                          }}
                          hideErrorsSpace
                          isVisibleFn={(values: Values) =>
                            !_get(values, `${name}.${subOption.value}.isCustom`)
                          }
                        />
                      </div>
                    )}
                    {subOption.fieldType ===
                      AccessesFieldType.InterventionCategoriesSelect &&
                      !!_get(
                        formValues,
                        `${name}.${subOption.value}.isCustom`,
                      ) && (
                        <div
                          className={classNames(
                            styles.userAccessListItemField,
                            styles.row,
                          )}
                        >
                          <FormItem
                            name={`${name}.${subOption.value}.items`}
                            element={Select}
                            label={t('intervention-categories')}
                            componentProps={
                              {
                                getLoadOptions:
                                  getInterventionCategoriesLoadOptions,
                                isAsync: true,
                                isMulti: true,
                                defaultOptions: false,
                                components: {
                                  ValueContainer: MultiSelectValueContainer,
                                },
                              } as SelectProps
                            }
                            hideErrorsSpace
                            isUseWatch
                          />
                          <div
                            style={
                              _get(
                                formValues,
                                `${name}.${subOption.value}.items.length`,
                              )
                                ? undefined
                                : { display: 'none' }
                            }
                          >
                            <FormItem
                              name={`${name}.${subOption.value}.items-list`}
                              element={InterventionCategoriesChipsListField}
                              hideErrorsSpace
                              isUseWatch
                              componentProps={{
                                fieldName: `${name}.${subOption.value}.items`,
                                ids: personalInterventionCategoriesIds,
                              }}
                            />
                          </div>
                        </div>
                      )}
                    {subOption.fieldType ===
                      AccessesFieldType.InterventionCategoriesSelect &&
                      !_get(
                        formValues,
                        `${name}.${subOption.value}.isCustom`,
                      ) && (
                        <div
                          className={classNames(
                            styles.userAccessListItemField,
                            styles.row,
                          )}
                        >
                          <FormItem
                            name={`${FieldNames.TeamAccesses}.${subOption.value}.items`}
                            element={Select}
                            label={t('intervention-categories')}
                            componentProps={
                              {
                                getLoadOptions:
                                  getInterventionCategoriesLoadOptions,
                                isAsync: true,
                                isMulti: true,
                                defaultOptions: false,
                                components: {
                                  ValueContainer: MultiSelectValueContainer,
                                },
                                disabled: true,
                              } as SelectProps
                            }
                            hideErrorsSpace
                            isUseWatch
                          />
                          <div
                            style={
                              _get(
                                formValues,
                                `${FieldNames.TeamAccesses}.${subOption.value}.items.length`,
                              )
                                ? undefined
                                : { display: 'none' }
                            }
                          >
                            <FormItem
                              name={`${FieldNames.TeamAccesses}.${subOption.value}.items-list`}
                              element={InterventionCategoriesChipsListField}
                              hideErrorsSpace
                              isUseWatch
                              componentProps={{
                                fieldName: `${FieldNames.TeamAccesses}.${subOption.value}.items`,
                                ids: teamInterventionCategoriesIds,
                                disabled: true,
                              }}
                            />
                          </div>
                        </div>
                      )}
                    {subOption.fieldType ===
                      AccessesFieldType.AssetCategoriesSelect &&
                      !!_get(
                        formValues,
                        `${name}.${subOption.value}.isCustom`,
                      ) && (
                        <div
                          className={classNames(
                            styles.userAccessListItemField,
                            styles.row,
                          )}
                        >
                          <FormItem
                            name={`${name}.${subOption.value}.items`}
                            element={Select}
                            label={t('asset-categories')}
                            componentProps={
                              {
                                getLoadOptions: getAssetCategoriesLoadOptions,
                                isAsync: true,
                                isMulti: true,
                                defaultOptions: false,
                                components: {
                                  ValueContainer: MultiSelectValueContainer,
                                },
                              } as SelectProps
                            }
                            hideErrorsSpace
                            isUseWatch
                          />
                          <div
                            style={
                              _get(
                                formValues,
                                `${name}.${subOption.value}.items.length`,
                              )
                                ? undefined
                                : { display: 'none' }
                            }
                          >
                            <FormItem
                              name={`${name}.${subOption.value}.items-list`}
                              element={AssetCategoriesChipsListField}
                              hideErrorsSpace
                              isUseWatch
                              componentProps={{
                                fieldName: `${name}.${subOption.value}.items`,
                                ids: personalAssetCategoriesIds,
                              }}
                            />
                          </div>
                        </div>
                      )}
                    {subOption.fieldType ===
                      AccessesFieldType.AssetCategoriesSelect &&
                      !_get(
                        formValues,
                        `${name}.${subOption.value}.isCustom`,
                      ) && (
                        <div
                          className={classNames(
                            styles.userAccessListItemField,
                            styles.row,
                          )}
                        >
                          <FormItem
                            name={`${FieldNames.TeamAccesses}.${subOption.value}.items`}
                            element={Select}
                            label={t('asset-categories')}
                            componentProps={
                              {
                                getLoadOptions: getAssetCategoriesLoadOptions,
                                isAsync: true,
                                isMulti: true,
                                defaultOptions: false,
                                components: {
                                  ValueContainer: MultiSelectValueContainer,
                                },
                                disabled: true,
                              } as SelectProps
                            }
                            hideErrorsSpace
                            isUseWatch
                          />
                          <div
                            style={
                              _get(
                                formValues,
                                `${FieldNames.TeamAccesses}.${subOption.value}.items.length`,
                              )
                                ? undefined
                                : { display: 'none' }
                            }
                          >
                            <FormItem
                              name={`${FieldNames.TeamAccesses}.${subOption.value}.items-list`}
                              element={AssetCategoriesChipsListField}
                              hideErrorsSpace
                              isUseWatch
                              componentProps={{
                                fieldName: `${FieldNames.TeamAccesses}.${subOption.value}.items`,
                                ids: teamAssetCategoriesIds,
                                disabled: true,
                              }}
                            />
                          </div>
                        </div>
                      )}
                    <div className={styles.userAccessListItemField}>
                      <div
                        className={classNames(
                          styles.userAccessListItemFieldRow,
                          {
                            [styles.userAccessListItemFieldRowBig]:
                              subOption.fieldType ===
                                AccessesFieldType.AssetCategoriesSelect ||
                              subOption.fieldType ===
                                AccessesFieldType.InterventionCategoriesSelect,
                          },
                        )}
                      >
                        <Typography variant="caption">{t('team')}</Typography>
                        <FormItem
                          name={`${name}.${subOption.value}.isCustom`}
                          element={CheckBoxSingleBool}
                          componentProps={{
                            CheckBoxComponent: CheckBoxAsToggle,
                            options: [{ label: 'checked', value: 'checked' }],
                            value: _get(
                              formValues,
                              `${name}.${subOption.value}.isCustom`,
                            ),
                          }}
                          hideErrorsSpace
                          isUseWatch
                        />
                        <Typography variant="caption">{t('custom')}</Typography>
                      </div>
                    </div>
                  </div>
                ))}
          </div>
        ))}
      </div>
    );
  },
);

export default memo(UserAccessField);
