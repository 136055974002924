export const getFormatFromMimeType = (mimeType = ''): string =>
  mimeType.split('/')?.[1] || '';

export const getFileName = (url = '', withoutFileExtension = true): string => {
  try {
    const fileName = url.split('/').pop();

    if (!fileName || !fileName.includes('.')) {
      return url;
    }

    return withoutFileExtension ? fileName.split('.')[0] : fileName;
  } catch {
    return '';
  }
};

export const formatBytes = (bytes: number, decimals = 0): string => {
  if (!+bytes) return '0 Bytes';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};
