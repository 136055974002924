import {
  useSiteAreasSearchListLazyQuery,
  useSitesSearchListLazyQuery,
} from 'graphql-common';
import { getSitesLoadOptions } from 'utils/fetch/sitesSearchList';

export default function useQueries() {
  // Sites query
  const [fetchSitesSearchListLazyQuery] = useSitesSearchListLazyQuery({
    fetchPolicy: 'cache-and-network',
  });

  const sitesLoadOptions = getSitesLoadOptions(fetchSitesSearchListLazyQuery);

  // Site areas query
  const [fetchSiteAreasSearchListLazyQuery] = useSiteAreasSearchListLazyQuery({
    fetchPolicy: 'cache-and-network',
  });

  return {
    fetchSiteAreasSearchListLazyQuery,
    sitesLoadOptions,
  };
}
