import _get from 'lodash/get';
import _map from 'lodash/map';
import _difference from 'lodash/difference';
import { UseBulkSelectResult } from '@lib/hooks/useTableItemsBulkSelect';

export interface FetchIdsResponse<T> {
  data?: {
    data: {
      collection: T[];
    };
  };
}

type Args<T> = {
  fetchIds: () => Promise<FetchIdsResponse<unknown>>;
  useBulkSelectResult?: UseBulkSelectResult<T>;
};

export default function getAllTableItemsIds<T>({
  fetchIds,
  useBulkSelectResult,
}: Args<T>) {
  const { unselectedTableItems, isAllTableItemsSelected } =
    useBulkSelectResult || {};
  return fetchIds().then((res) => {
    const collectionIds = _get(res, 'data.data.collection', []);
    const allIds = _map(collectionIds, 'id');
    if (
      allIds?.length &&
      isAllTableItemsSelected &&
      unselectedTableItems?.length
    ) {
      const unselectedIds = _map(unselectedTableItems, 'id');
      return _difference(allIds, unselectedIds);
    }
    return allIds;
  });
}
