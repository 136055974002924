import React from 'react';
import { Site, User } from 'graphql-common';
import { Values } from '@lib/interfaces/form';
import { ApolloError } from '@apollo/client/errors';
// eslint-disable-next-line import/no-cycle
import SiteCodeOfConduct from 'components/Site/SiteCodeOfConduct'; // TODO fix circular dependency
import { LoadOptions } from '@lib/components/Select/types';
import { FormRefType } from '@lib/components/ReactHookForm/types';
import SiteAdd from './SiteAdd';
import SiteEdit from './SiteEdit';
import SiteView from './SiteView';

export enum SiteViewMode {
  add = 'add',
  edit = 'edit',
  view = 'view',
  viewCodeOfConduct = 'viewCodeOfConduct',
  editCodeOfConduct = 'editCodeOfConduct',
}

interface Props {
  closeCodeOfConductDeleteModal: () => void;
  closeSiteDeleteModal: () => void;
  closeUserFormModal: () => void;
  createMutationError?: ApolloError;
  createSiteLoading: boolean;
  countriesCodes: string[];
  formRef: FormRefType;
  isCodeOfConductDeleteModalOpened: boolean;
  isSiteDeleteModalOpened: boolean;
  isUserFormModalOpened: boolean;
  isViewOnly: boolean;
  onConfirmedDelete: () => void;
  onConfirmedDeleteCodeOfConduct: () => void;
  usersLoadOptions: LoadOptions;
  onCreate: (values: Values) => void;
  onCreateUserCallback: (v?: User | null) => void;
  onDelete: () => void;
  onDeleteCodeOfConduct: () => void;
  onEdit: (values: Values) => void;
  onEditCodeOfConduct: () => void;
  onViewCodeOfConduct: () => void;
  openUserFormModal: () => void;
  siteData: Site;
  siteError?: ApolloError;
  siteLoading?: boolean;
  updateMutationError?: ApolloError;
  updateSiteLoading: boolean;
  viewMode: SiteViewMode;
}

function SiteComponent(props: Props) {
  const {
    closeCodeOfConductDeleteModal,
    closeSiteDeleteModal,
    closeUserFormModal,
    countriesCodes,
    createMutationError,
    createSiteLoading,
    usersLoadOptions,
    formRef,
    isCodeOfConductDeleteModalOpened,
    isSiteDeleteModalOpened,
    isUserFormModalOpened,
    isViewOnly,
    onConfirmedDelete,
    onConfirmedDeleteCodeOfConduct,
    onCreate,
    onCreateUserCallback,
    onDelete,
    onDeleteCodeOfConduct,
    onEdit,
    onEditCodeOfConduct,
    onViewCodeOfConduct,
    openUserFormModal,
    siteData,
    siteError,
    siteLoading,
    updateMutationError,
    updateSiteLoading,
    viewMode,
  } = props;
  switch (viewMode) {
    case SiteViewMode.add:
      return (
        <SiteAdd
          closeUserFormModal={closeUserFormModal}
          countriesCodes={countriesCodes}
          createMutationError={createMutationError}
          createSiteLoading={createSiteLoading}
          formRef={formRef}
          isUserFormModalOpened={isUserFormModalOpened}
          onCreate={onCreate}
          onCreateUserCallback={onCreateUserCallback}
          openUserFormModal={openUserFormModal}
          siteError={siteError}
          usersLoadOptions={usersLoadOptions}
        />
      );
    case SiteViewMode.edit:
      return (
        <SiteEdit
          closeSiteDeleteModal={closeSiteDeleteModal}
          closeUserFormModal={closeUserFormModal}
          countriesCodes={countriesCodes}
          formRef={formRef}
          isSiteDeleteModalOpened={isSiteDeleteModalOpened}
          isUserFormModalOpened={isUserFormModalOpened}
          onConfirmedDelete={onConfirmedDelete}
          onCreateUserCallback={onCreateUserCallback}
          onDelete={onDelete}
          onEdit={onEdit}
          openUserFormModal={openUserFormModal}
          siteData={siteData}
          siteError={siteError}
          siteLoading={siteLoading}
          updateMutationError={updateMutationError}
          updateSiteLoading={updateSiteLoading}
          usersLoadOptions={usersLoadOptions}
        />
      );
    case SiteViewMode.view:
      return (
        <SiteView
          closeSiteDeleteModal={closeSiteDeleteModal}
          isSiteDeleteModalOpened={isSiteDeleteModalOpened}
          onConfirmedDelete={onConfirmedDelete}
          onDelete={onDelete}
          onEditCodeOfConduct={onEditCodeOfConduct}
          onViewCodeOfConduct={onViewCodeOfConduct}
          siteData={siteData}
          siteError={siteError}
          siteLoading={siteLoading}
          isViewOnly={isViewOnly}
        />
      );
    case SiteViewMode.viewCodeOfConduct:
    case SiteViewMode.editCodeOfConduct:
      return (
        <SiteCodeOfConduct
          isCodeOfConductDeleteModalOpened={isCodeOfConductDeleteModalOpened}
          closeCodeOfConductDeleteModal={closeCodeOfConductDeleteModal}
          onConfirmedDeleteCodeOfConduct={onConfirmedDeleteCodeOfConduct}
          onDeleteCodeOfConduct={onDeleteCodeOfConduct}
          onEdit={onEdit}
          onEditCodeOfConduct={onEditCodeOfConduct}
          onViewCodeOfConduct={onViewCodeOfConduct}
          siteData={siteData}
          siteError={siteError}
          siteLoading={siteLoading}
          updateSiteLoading={updateSiteLoading}
          updateMutationError={updateMutationError}
          viewMode={viewMode}
        />
      );
    default:
      return <div />;
  }
}

export default SiteComponent;
