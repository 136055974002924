import React from 'react';
import { useTranslation } from 'react-i18next';
import { ApolloError } from '@apollo/client/errors';
import _get from 'lodash/get';
import { Task } from 'graphql-common';
import PageBody from '@lib/layouts/DashboardLayout/PageBody/PageBody';
import { Values } from '@lib/interfaces/form';
import DashboardContentMaxWidth from '@lib/layouts/DashboardLayout/DashboardContentMaxWidth/DashboardContentMaxWidth';
import CircleLoader from '@lib/components/CircleLoader/CircleLoader';
import ErrorPage from '@lib/components/ErrorPage/ErrorPage';
import getStatusFromError from '@lib/utils/getStatusFromError';
import Table from '@lib/components/Table/Table';
import { UseBulkSelectResult } from '@lib/hooks/useTableItemsBulkSelect';
import ListEmptyState from '@lib/components/ListEmptyState/ListEmptyState';
import Trans from '@lib/components/Trans/Trans';
import { FormTemplateTasksQueryHookResult } from 'utils/fetch/useFormTemplateTasksQueryHook';
import Button, {
  ButtonSizes,
  ButtonTypes,
} from '@lib/components/Button/Button';
import getActionsForSelected from './utils/getFormTemplateTasksTableActionsForSelected';
import getFormTemplateTasksTableColumns from './utils/getFormTemplateTasksTableColumns';
import FormTemplateBuilderViewMenu from './FormTemplateBuilderViewMenu';
import FormTemplateBuilderMainBlockView from './FormTemplateBuilderMainBlockView';
import getTemplateActions from './utils/getTemplateActions';

interface Props {
  formTemplateId: string;
  formTemplateData: Values;
  formTemplateError?: ApolloError;
  formTemplateLoading: boolean;
  formTemplateVersionData: Values;
  loadingState: boolean;
  onCreateTaskHandler: () => void;
  onDuplicateHandler: () => void;
  onEditHandler: () => void;
  onFormTemplateArchiveHandler: () => void;
  openDestroyModal: () => void;
  onTaskFormTemplateVersionChange: (
    taskId: string,
    newFormTemplateVersionId: string,
  ) => void;
  onTaskFormTemplateVersionBulkChange: (
    newFormTemplateVersionId: string,
  ) => void;
  tasksQueryHook: FormTemplateTasksQueryHookResult;
  useBulkSelectResult: UseBulkSelectResult<Task>;
  isViewOnly: boolean;
}

export default function FormTemplateBuilderTasksView(props: Props) {
  const {
    formTemplateData,
    formTemplateError,
    formTemplateId,
    formTemplateLoading,
    formTemplateVersionData,
    loadingState,
    onCreateTaskHandler,
    onDuplicateHandler,
    onEditHandler,
    onFormTemplateArchiveHandler,
    onTaskFormTemplateVersionBulkChange,
    onTaskFormTemplateVersionChange,
    openDestroyModal,
    tasksQueryHook,
    useBulkSelectResult,
    isViewOnly,
  } = props;
  const { tasks, tasksLoading, totalTasksCount } = tasksQueryHook;
  const { getSelectedItemsCount } = useBulkSelectResult;

  const { t } = useTranslation();

  const selectedItemsCount = getSelectedItemsCount(totalTasksCount);

  const actions = getTemplateActions({
    onDuplicateHandler,
    onEditHandler: _get(formTemplateData, 'canUpdate', false)
      ? onEditHandler
      : undefined,
    openDestroyModal: _get(formTemplateData, 'canDestroy', false)
      ? openDestroyModal
      : undefined,
    onArchiveHandler: _get(formTemplateData, 'canArchive', false)
      ? onFormTemplateArchiveHandler
      : undefined,
    t,
  });

  const tableColumns = getFormTemplateTasksTableColumns({
    formTemplateId,
    formTemplateData,
    onTaskFormTemplateVersionChange,
    t,
  });

  const tableActionsForSelected = isViewOnly
    ? []
    : getActionsForSelected({
        formTemplateId,
        loadingState,
        onVersionChange: (newValue) => {
          const newVersionId = _get(newValue, 'value');
          if (newVersionId) {
            onTaskFormTemplateVersionBulkChange(newVersionId);
          }
        },
        t,
        selectedItemsCount,
      });

  if (formTemplateError) {
    return (
      <ErrorPage
        status={getStatusFromError(formTemplateError)}
        description={formTemplateError?.message}
      />
    );
  }

  const emptyState = (
    <ListEmptyState
      title={t('no-linked-tasks-yet')}
      text={<Trans i18nKey="no-linked-tasks-text" />}
      actions={
        isViewOnly
          ? []
          : [
              <Button
                key="create"
                buttonText={t('create-a-task')}
                buttonSize={ButtonSizes.large}
                buttonType={ButtonTypes.primaryFilled}
                leftIcon="task_alt"
                onClick={onCreateTaskHandler}
                disabled={loadingState}
              />,
            ]
      }
    />
  );

  return (
    <PageBody withoutHeader>
      <FormTemplateBuilderViewMenu formTemplateData={formTemplateData} />
      <DashboardContentMaxWidth>
        {formTemplateLoading ? (
          <CircleLoader />
        ) : (
          <>
            <FormTemplateBuilderMainBlockView
              actions={actions}
              formTemplateData={formTemplateData}
              formTemplateId={formTemplateId}
              formTemplateVersionData={formTemplateVersionData}
              loadingState={loadingState}
              onCreateTaskHandler={onCreateTaskHandler}
              onDuplicateHandler={onDuplicateHandler}
              isViewOnly={isViewOnly}
            />
            <Table
              actionsForSelected={tableActionsForSelected}
              columns={tableColumns}
              data={tasks}
              emptyState={emptyState}
              id="tasks"
              loading={loadingState || tasksLoading}
              totalEntries={totalTasksCount}
              useBulkSelectResult={useBulkSelectResult}
            />
          </>
        )}
      </DashboardContentMaxWidth>
    </PageBody>
  );
}
