import React from 'react';
import {
  components as DefaultSelectComponents,
  ValueContainerProps,
} from 'react-select';
import _get from 'lodash/get';
import _has from 'lodash/has';
import Chip, { ChipTypes } from '@lib/components/Chip/Chip';
import styles from '../Select.module.scss';

export default function ChipValueContainer(
  props: ValueContainerProps,
): JSX.Element {
  const { children, ...valueContainerProps } = props;
  const { selectProps } = valueContainerProps;
  const value = _get(selectProps, 'value');
  const isDisabled = _get(selectProps, 'isDisabled');

  if (Array.isArray(children) && _has(value, 'label') && _has(value, 'color')) {
    const type = isDisabled
      ? ChipTypes.chipDisabled
      : (_get(value, 'color') as ChipTypes);
    return (
      <DefaultSelectComponents.ValueContainer
        {...valueContainerProps}
        className={styles.valueContainer}
      >
        <span className={styles.singleValueWrap}>
          <Chip type={type} label={_get(value, 'label')} leftIcon="circle" />
          {/* {children[0]} */}
          <span>{children[1]}</span>
        </span>
      </DefaultSelectComponents.ValueContainer>
    );
  }

  return (
    <DefaultSelectComponents.ValueContainer
      {...valueContainerProps}
      className={styles.valueContainer}
    >
      {children}
    </DefaultSelectComponents.ValueContainer>
  );
}
