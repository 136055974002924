import React, {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import _get from 'lodash/get';
import { LazyQueryExecFunction } from '@apollo/client';
import classNames from 'classnames';
import Quill from 'quill';
import ReactQuill from 'react-quill-new';
// import 'react-quill-new/dist/quill.snow.css';
import { Mention, MentionBlot } from 'quill-mention';
import 'quill-paste-smart';
// import 'quill-mention/dist/quill.mention.css';
import { UsersSearchListQuery } from 'graphql-common';
import getFullName from '@lib/utils/getFullName';
import Collapse from '@lib/components/Collapse/Collapse';
import createSearchFilters from '@lib/utils/createSearchFilters';
import withFloatingLabel from '@lib/hocs/withFloatingLabel';
import FileUploaderUppyXHR, {
  AttachmentValue,
  UploaderView,
} from '../FileUploaderUppyXHR/FileUploaderUppyXHR';
import styles from './TextEditor.module.scss';
import './quill.show.scss';
import './quill.mention.scss';

const ImageFormat = Quill.import('formats/image');
// @ts-ignore
class CustomImage extends ImageFormat {
  static create(value) {
    const node = super.create();
    node.setAttribute('src', value?.src || '');
    if (value['data-image-id']) {
      node.setAttribute('data-image-id', value['data-image-id']);
    }
    return node;
  }

  static value(node) {
    return {
      src: node.getAttribute('src'),
      'data-image-id': node.getAttribute('data-image-id'),
    };
  }
}
// @ts-ignore
Quill.register(CustomImage, true);
Quill.register({ 'blots/mention': MentionBlot, 'modules/mention': Mention });

type ChangeEventType = React.ChangeEvent<
  HTMLInputElement | HTMLTextAreaElement
>;

type FetchUsersSearchListLazyQuery = LazyQueryExecFunction<
  UsersSearchListQuery,
  any
>;

type Props = {
  className?: string;
  disabled?: boolean;
  fetchImageUrl?: (id: string) => Promise<string>;
  fetchUsersSearchListLazyQuery?: FetchUsersSearchListLazyQuery;
  hasError?: boolean;
  name?: string;
  onBlur?: () => void;
  onChange?: (event: ChangeEventType) => void;
  readOnly?: boolean;
  useCropBlurGray?: boolean;
  useCropBlurWhite?: boolean;
  value: string;
};

const TextEditor = forwardRef(
  (props: Props, ref: React.ForwardedRef<HTMLInputElement>) => {
    const {
      className,
      fetchImageUrl,
      fetchUsersSearchListLazyQuery,
      hasError,
      name,
      onBlur,
      onChange,
      readOnly,
      disabled,
      value,
      useCropBlurWhite = true,
      useCropBlurGray,
    } = props;
    const quillRef = useRef<ReactQuill | null>(null);
    const [uploaderModalOpen, setUploaderModalOpen] = useState(false);

    const onChangeHandle = (newValue: string) => {
      let editorValue = newValue;
      if (
        [
          '<p><br></p>',
          '<h1><br></h1>',
          '<h2><br></h2>',
          '<h3><br></h3>',
        ].includes(newValue)
      ) {
        editorValue = '';
      }

      if (onChange && name) {
        const event = {
          target: {
            name,
            value: editorValue,
          },
        } as unknown as ChangeEventType;
        onChange(event);
      }
    };

    const handleImageUpload = () => {
      setUploaderModalOpen(true);
    };

    const handleImageUploadChange = (
      file: AttachmentValue | AttachmentValue[],
    ) => {
      const quillEditor = quillRef.current?.getEditor();
      if (quillEditor && !Array.isArray(file) && file?.file?.id) {
        const imageId = file.file.id;
        const range = quillEditor.getSelection(true);
        quillEditor.insertEmbed(range.index, 'image', {
          src: 'loading-placeholder.png',
          'data-image-id': imageId,
        });
        setUploaderModalOpen(false);
      }
    };

    const mentionSource = useCallback(
      async (
        searchTerm: string,
        renderItem: (
          arg0: { id: number; value: string }[] | undefined,
          arg1: any,
        ) => void,
        mentionChar: string,
      ) => {
        if (fetchUsersSearchListLazyQuery) {
          try {
            const results = await fetchUsersSearchListLazyQuery({
              variables: {
                filters: [
                  ...createSearchFilters(searchTerm, [
                    { fieldName: 'email' },
                    { fieldName: 'fullName' },
                    { fieldName: 'personalId' },
                  ]),
                ],
              },
            });
            const collection = _get(results, 'data.data.collection', []).map(
              (user) => ({ id: user?.id, value: getFullName(user) }),
            );
            renderItem(collection, searchTerm);
          } catch (error) {
            console.error('Error fetching mention data:', error);
            renderItem([], searchTerm);
          }
        } else {
          renderItem([], searchTerm);
        }
      },
      [fetchUsersSearchListLazyQuery],
    );

    const modules = {
      toolbar: !readOnly
        ? [
            [{ header: [1, 2, 3, false] }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['bold', 'italic', 'underline'],
            // [{ align: [] }],
            fetchImageUrl ? ['image'] : [],
            ['clean'],
          ]
        : false,
      clipboard: {
        matchVisual: false,
      },
      mention: {
        allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
        mentionDenotationChars: ['@'],
        source: mentionSource,
      },
    };

    useEffect(() => {
      if (quillRef.current && ref) {
        const editorRoot = quillRef.current.getEditor()
          .root as HTMLInputElement;
        if (typeof ref === 'function') {
          ref(editorRoot);
        } else if (ref && 'current' in ref) {
          // eslint-disable-next-line no-param-reassign
          ref.current = editorRoot;
        }
      }
    }, [ref]);

    useEffect(() => {
      if (quillRef.current && !readOnly) {
        const quillEditor = quillRef.current.getEditor();
        const toolbarModule = quillEditor.getModule('toolbar');
        if (toolbarModule) {
          // @ts-ignore
          toolbarModule?.addHandler('image', handleImageUpload);
        }
      }
    }, [readOnly]);

    useEffect(() => {
      const quillEditor = quillRef.current?.getEditor();
      if (quillEditor && fetchImageUrl) {
        const images = quillEditor.root.querySelectorAll('img[data-image-id]');
        images.forEach(async (img) => {
          const id = img.getAttribute('data-image-id');
          if (id) {
            const url = await fetchImageUrl(id);
            img.setAttribute('src', url);
          }
        });
      }
    }, [value, fetchImageUrl]);

    return (
      <div className={classNames(className, styles.rootEditor)}>
        {readOnly ? (
          <Collapse
            minHeight={120}
            useCropBlurGray={useCropBlurGray}
            useCropBlurWhite={useCropBlurGray ? false : useCropBlurWhite}
            className={classNames({ [styles.disabledEditor]: disabled })}
          >
            <ReactQuill
              ref={quillRef}
              onChange={onChangeHandle}
              value={value}
              readOnly
              onBlur={onBlur}
              modules={modules}
            />
          </Collapse>
        ) : (
          <ReactQuill
            ref={quillRef}
            onChange={onChangeHandle}
            value={value}
            onBlur={onBlur}
            modules={modules}
            className={hasError ? 'hasError' : ''}
          />
        )}
        {uploaderModalOpen && (
          <FileUploaderUppyXHR
            allowedFileTypes={['image/*']}
            type={UploaderView.modal}
            onChange={handleImageUploadChange}
            openButton={() => null}
            openUploaderModalOnInit
          />
        )}
      </div>
    );
  },
);

TextEditor.displayName = 'TextEditor';

export default memo(withFloatingLabel(TextEditor));
