import React from 'react';
import { useTranslation } from 'react-i18next';
import Button, { ButtonTypes } from '@lib/components/Button/Button';
import ActionsRow from '@lib/components/ActionsRow/ActionsRow';
import { ActionsProps } from '@lib/components/ReactHookForm/types';
import { SubmitAction } from '@lib/enums/form';

export interface FormProps extends ActionsProps {
  onCancel: () => void;
  isEditForm?: boolean;
  submitNewItemRef: React.MutableRefObject<SubmitAction | undefined>;
}

export default function AssetNomenclatureModalFormActions(props: FormProps) {
  const {
    loading,
    onCancel,
    isDirty,
    isEditForm,
    submitNewItemRef,
    onSubmitForm,
  } = props;
  const { t } = useTranslation();
  const actions = [
    <Button
      buttonText={t('cancel')}
      buttonType={ButtonTypes.secondaryOutlined}
      disabled={loading}
      fullWidth
      leftIcon="close"
      onClick={onCancel}
    />,
  ];
  if (isEditForm) {
    actions.push(
      <Button
        buttonText={t('save-changes')}
        buttonType={ButtonTypes.primaryFilled}
        disabled={!isDirty}
        fullWidth
        isLoading={loading}
        leftIcon="check"
        type="submit"
      />,
    );
  } else {
    actions.push(
      <div />,
      <Button
        buttonText={t('save-and-create-next')}
        buttonType={ButtonTypes.primaryOutlined}
        disabled={!isDirty}
        fullWidth
        isLoading={loading}
        onClick={() => {
          // eslint-disable-next-line no-param-reassign
          submitNewItemRef.current = SubmitAction.CreateAndNew;
          onSubmitForm();
        }}
      />,
      <Button
        buttonText={t('create')}
        buttonType={ButtonTypes.primaryFilled}
        disabled={!isDirty}
        fullWidth
        isLoading={loading}
        leftIcon="check"
        onClick={() => {
          // eslint-disable-next-line no-param-reassign
          submitNewItemRef.current = SubmitAction.CreateAndClose;
          onSubmitForm();
        }}
      />,
    );
  }
  return <ActionsRow actions={actions} />;
}
