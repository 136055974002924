import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import _get from 'lodash/get';
import {
  FormCategory,
  useAssetBulkDestroyMutation,
  useAssetBulkQrCodeCreateMutation,
  useAssetCategoryCreateMutation,
  useAssetCreateMutation,
  useAssetUpdateMutation,
} from 'graphql-common';
import { showGraphQLErrors } from '@lib/utils/graphQLErrors';
import toast from '@lib/components/Toast/Toast';
import { FormRefType } from '@lib/components/ReactHookForm/types';
import getIncrementedCode from '@lib/utils/getIncrementedCode';
import { APP_URLS } from 'constants/urls';
import { SubmitAction } from '@lib/enums/form';
import { UseModalManagementResult } from './useModalManagement';

interface Props {
  formRef: FormRefType;
  submitNewItemRef?: React.MutableRefObject<SubmitAction | undefined>;
  useModalManagementResult: UseModalManagementResult;
}

export default function useAssetMutations(props: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { formRef, submitNewItemRef, useModalManagementResult } = props;

  // Asset category
  const [assetCategoryCreateMutation, assetCategoryCreateMutationResult] =
    useAssetCategoryCreateMutation({
      onCompleted: (response) => {
        const category = { ...response.data } as FormCategory;
        const categoryId = category?.id;
        const categoryName = category?.name;
        const categoryColor = category?.color;
        if (categoryId && categoryName && formRef?.current?.setValue) {
          formRef.current.setValue(
            'category',
            {
              value: categoryId,
              label: categoryName,
              color: categoryColor,
            },
            { shouldDirty: true, shouldValidate: true },
          );
        }
        useModalManagementResult.closeCategoryFormModal();
        toast({ content: t('create-asset-category-success') });
      },
      onError: (error) => showGraphQLErrors(error, t),
    });

  // Asset mutations
  const [assetCreateMutation, assetCreateMutationResult] =
    useAssetCreateMutation({
      onCompleted: (response) => {
        const prevValues = formRef.current?.values;
        const newId = _get(response, 'data.id');
        if (newId) {
          if (submitNewItemRef?.current === SubmitAction.CreateAndNew) {
            const prevCode = prevValues?.code as string;
            const incrementCode = getIncrementedCode(prevCode);
            formRef.current?.reset({
              code: prevCode !== incrementCode ? incrementCode : '',
              site: prevValues?.site || null,
            });
          } else {
            navigate(
              APP_URLS.dashboard.assets.view.getDynamicPath({
                pathParts: {
                  id: newId,
                },
              }),
            );
          }
        } else {
          navigate(APP_URLS.dashboard.assets.index.getPathWithQuery());
        }
        toast({ content: t('create-asset-success') });
      },
      onError: (error) => showGraphQLErrors(error, t),
    });

  const [assetUpdateMutation, assetUpdateMutationResult] =
    useAssetUpdateMutation({
      onCompleted: (response) => {
        const newId = _get(response, 'data.id');
        if (newId) {
          navigate(
            APP_URLS.dashboard.assets.view.getDynamicPath({
              pathParts: {
                id: newId,
              },
            }),
          );
        } else {
          navigate(APP_URLS.dashboard.assets.index.getPathWithQuery());
        }
        toast({ content: t('update-asset-success') });
      },
      onError: (error) => showGraphQLErrors(error, t),
    });

  const [destroyAssetMutation] = useAssetBulkDestroyMutation({
    onCompleted: () => {
      navigate(APP_URLS.dashboard.assets.index.getPathWithQuery());
    },
    onError: (error) => showGraphQLErrors(error, t),
  });

  // QR code
  const [qrCodeCreateMutation, qrCodeCreateMutationResult] =
    useAssetBulkQrCodeCreateMutation();

  return {
    assetCategoryCreateMutation,
    assetCategoryCreateMutationResult,
    assetCreateMutation,
    assetCreateMutationResult,
    assetUpdateMutation,
    assetUpdateMutationResult,
    destroyAssetMutation,
    qrCodeCreateMutation,
    qrCodeCreateMutationResult,
  };
}
