import React, { memo } from 'react';
import _get from 'lodash/get';
import { Asset } from 'graphql-common';
import Chip, { ChipSizes, ChipTypes } from '@lib/components/Chip/Chip';
import Placeholder from '@lib/assets/icons/img-placeholder.svg';
import styles from './AssetChip.module.scss';

export interface Props {
  asset: Asset;
  disabled?: boolean;
  size?: ChipSizes;
  to?: string;
}

function AssetChip({ asset, disabled, size = ChipSizes.medium, to }: Props) {
  if (!asset) return null;
  const icon =
    _get(asset, 'iconAttached.urls.small') ||
    _get(asset, 'iconAttached.urls.original') ||
    _get(asset, 'iconAttached.fileTag') ||
    Placeholder;
  return (
    <Chip
      type={disabled ? ChipTypes.chipDisabled : ChipTypes.chipGray}
      label={asset.fullCode ? `${asset.fullCode} ${asset.name}` : asset.name}
      leftImage={icon}
      size={size}
      className={styles.root}
      to={to}
    />
  );
}

export default memo(AssetChip);
