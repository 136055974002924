import React from 'react';
import { Nomenclature } from 'graphql-common';
import { FormRefType } from '@lib/components/ReactHookForm/types';
import { SubmitAction } from '@lib/enums/form';
import { UseBulkSelectResult } from '@lib/hooks/useTableItemsBulkSelect';
import AssetNomenclature from 'components/AssetNomenclature/AssetNomenclature';
import useNomenclatureActions from './utils/useNomenclatureActions';
import { AssetViewMode } from './enums';
import { UseSearchListQueriesResult } from './utils/useSearchListQueries';
import { UseModalManagementResult } from './utils/useModalManagement';
import { UseActionsResult } from './utils/useActions';

type Props = {
  menuBar: JSX.Element;
  useBulkSelectResult?: UseBulkSelectResult<Nomenclature>;
  useActionsResult: UseActionsResult;
  useModalManagementResult: UseModalManagementResult;
  useSearchListQueriesResult: UseSearchListQueriesResult;
  submitNewItemRef: React.MutableRefObject<SubmitAction | undefined>;
  formRef: FormRefType;
  viewMode: AssetViewMode;
  isViewOnly: boolean;
};

export default function AssetNomenclatureContainer(props: Props) {
  const {
    formRef,
    menuBar,
    submitNewItemRef,
    useActionsResult,
    useBulkSelectResult,
    useModalManagementResult,
    useSearchListQueriesResult,
    viewMode,
    isViewOnly,
  } = props;

  const useNomenclatureActionsResult = useNomenclatureActions({
    formRef,
    submitNewItemRef,
    useActionsResult,
    useBulkSelectResult,
    useModalManagementResult,
    viewMode,
  });

  return (
    <AssetNomenclature
      formRef={formRef}
      menuBar={menuBar}
      submitNewItemRef={submitNewItemRef}
      useActionsResult={useActionsResult}
      useNomenclatureActionsResult={useNomenclatureActionsResult}
      useBulkSelectResult={useBulkSelectResult}
      useModalManagementResult={useModalManagementResult}
      useSearchListQueriesResult={useSearchListQueriesResult}
      isViewOnly={isViewOnly}
    />
  );
}
