import { useState } from 'react';
import { FormTemplate } from 'graphql-common';
import { UseBulkSelectResult } from '@lib/hooks/useTableItemsBulkSelect';

interface UseModalManagementProps {
  useBulkSelectResult: UseBulkSelectResult<FormTemplate>;
}

export interface UseModalManagementResult {
  closeArchiveModal: () => void;
  closeDestroyModal: () => void;
  isArchiveModalOpened: boolean;
  isDestroyModalOpened: boolean;
  openArchiveModal: () => void;
  openDestroyModal: () => void;
}

export default function useModalManagement(
  props: UseModalManagementProps,
): UseModalManagementResult {
  const { useBulkSelectResult } = props;
  const { handleSelectSingleTableItem } = useBulkSelectResult;

  // State
  const [isDestroyModalOpened, setDestroyModalOpened] = useState(false);
  const [isArchiveModalOpened, setArchiveModalOpened] = useState(false);

  // Actions
  const openDestroyModal = () => {
    setDestroyModalOpened(true);
  };
  const closeDestroyModal = () => {
    setDestroyModalOpened(false);
    handleSelectSingleTableItem(null);
  };
  const openArchiveModal = () => {
    setArchiveModalOpened(true);
  };
  const closeArchiveModal = () => {
    setArchiveModalOpened(false);
    handleSelectSingleTableItem(null);
  };

  return {
    closeArchiveModal,
    closeDestroyModal,
    isArchiveModalOpened,
    isDestroyModalOpened,
    openArchiveModal,
    openDestroyModal,
  };
}
