import React, { forwardRef, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _get from 'lodash/get';
import Select, { SelectProps } from '@lib/components/Select/Select';
import Tooltip from '@lib/components/Tooltip/Tooltip';
import IconButton from '@lib/components/IconButton/IconButton';
import AssetFieldNames from '@lib/enums/fieldNames/assetFieldNames';
import ConfirmationModal from '@lib/components/ConfirmationModal/ConfirmationModal';
import { ChipTypes } from '@lib/components/Chip/Chip';
import { useFormContext } from 'react-hook-form';
import { IconName } from '@lib/components/Modal/enums';
import styles from './QrCodePrefixField.module.scss';

const QrCodePrefixField = forwardRef(
  (props: SelectProps, ref: React.ForwardedRef<HTMLInputElement | null>) => {
    const { t } = useTranslation();
    const { setValue } = useFormContext();
    const [isConfirmModalOpened, setConfirmModalOpened] = useState(false);

    const prefixValue = _get(props, [
      'values',
      AssetFieldNames.QrCodePrefix,
      'value',
    ]);

    const selectedCat = _get(props, ['values', AssetFieldNames.Category]);
    const selectedCatId = selectedCat?.value;
    const selectedCatPrefix = selectedCat?.data?.prefix;

    const selectedDefaultCat = _get(props, [
      'values',
      AssetFieldNames.CategoryDefault,
    ]);
    const selectedDefaultCatId = selectedDefaultCat?.value;
    const selectedDefaultCatPrefix = selectedDefaultCat?.data?.prefix;

    const isNewCatSelected = selectedCatId !== selectedDefaultCatId;
    const isPrefixCanBeUpdated =
      selectedCatPrefix !== selectedDefaultCatPrefix ||
      prefixValue !== selectedDefaultCatPrefix;
    const isPrefixUpdated = prefixValue === selectedCatPrefix;
    const prefixUpdateAvailable = isNewCatSelected
      ? !isPrefixUpdated && isPrefixCanBeUpdated
      : isPrefixCanBeUpdated;

    const onPrefixChange = () => setConfirmModalOpened(true);
    const closeConfirmModal = () => setConfirmModalOpened(false);
    const onPrefixChangeConfirmed = () => {
      setValue(
        AssetFieldNames.QrCodePrefix,
        {
          value: selectedCatPrefix,
          label: selectedCatPrefix,
          color: ChipTypes.chipDisabled,
        },
        { shouldValidate: true, shouldDirty: true },
      );
      closeConfirmModal();
    };

    return (
      <>
        <div className={styles.root}>
          <Select ref={ref} {...props} />
          <div className={styles.icon}>
            {prefixUpdateAvailable ? (
              <Tooltip
                body={t('update-asset-prefix-from-category')}
                maxWidth={170}
              >
                <IconButton icon="autorenew" onClick={onPrefixChange} />
              </Tooltip>
            ) : (
              <Tooltip body={t('category-prefix-is-up-to-date')} maxWidth={194}>
                <IconButton icon="check" disabled />
              </Tooltip>
            )}
          </div>
        </div>
        <ConfirmationModal
          confirmButtonLabel={t('update-anyway')}
          description={t('update-asset-prefix-from-category-warning')}
          icon={IconName.Warning}
          isOpen={isConfirmModalOpened}
          onClose={closeConfirmModal}
          onConfirm={onPrefixChangeConfirmed}
          title={t('warning')}
        />
      </>
    );
  },
);

export default memo(QrCodePrefixField);
