import React, { memo } from 'react';
import Chip, { ChipTypes } from '@lib/components/Chip/Chip';
import Tooltip from '@lib/components/Tooltip/Tooltip';

interface Props {
  className?: string;
  label: string;
  leftIcon?: string;
  leftIconSymbolsOutlined?: boolean;
  leftImage?: string;
  leftImageClassName?: string;
  onClick?: () => void;
  tooltip?: string;
  type: ChipTypes;
}

function ChipCell(props: Props) {
  const {
    className,
    label,
    leftIcon,
    leftIconSymbolsOutlined,
    leftImage,
    leftImageClassName,
    onClick,
    tooltip,
    type,
  } = props;
  const chip = (
    <Chip
      className={className}
      label={label}
      leftIcon={leftIcon}
      leftIconSymbolsOutlined={leftIconSymbolsOutlined}
      leftImage={leftImage}
      leftImageClassName={leftImageClassName}
      onClick={onClick}
      type={type}
    />
  );
  return <div>{tooltip ? <Tooltip body={tooltip}>{chip}</Tooltip> : chip}</div>;
}

export default memo(ChipCell);
