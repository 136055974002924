import { TFunction } from 'i18next';
import _without from 'lodash/without';
import { NavItem, NavItemTypeEnum } from '@lib/components/Menu/Menu';
import Icons from '@lib/components/Icons/Icons';

type Args = {
  onArchiveHandler?: () => void;
  onDuplicateHandler: () => void;
  onEditHandler?: () => void;
  openDestroyModal?: () => void;
  t: TFunction<'translation', undefined>;
};

export default function getTemplateActions({
  onArchiveHandler,
  onDuplicateHandler,
  onEditHandler,
  openDestroyModal,
  t,
}: Args) {
  return _without(
    [
      onEditHandler
        ? {
            id: 'edit',
            title: t('edit'),
            leftIcon: Icons.edit,
            onClick: onEditHandler,
            type: NavItemTypeEnum.Secondary,
          }
        : undefined,
      {
        id: 'copy',
        title: t('duplicate'),
        leftIcon: Icons.copy,
        onClick: onDuplicateHandler,
        type: NavItemTypeEnum.Secondary,
      },
      onArchiveHandler
        ? {
            id: 'archive',
            title: t('archive'),
            leftIcon: Icons.archive,
            onClick: onArchiveHandler,
            type: NavItemTypeEnum.Secondary,
          }
        : undefined,
      openDestroyModal
        ? {
            id: 'delete',
            title: t('delete'),
            leftIcon: Icons.delete,
            onClick: openDestroyModal,
            type: NavItemTypeEnum.Secondary,
          }
        : undefined,
    ],
    undefined,
  ) as NavItem<unknown>[];
}
