import { ChipTypes } from '@lib/components/Chip/Chip';
import { TaskCompletionStatusEnum } from 'graphql-common';

export default function getResultStateChipType(
  status?: TaskCompletionStatusEnum | null,
) {
  let type = ChipTypes.chipDisabled;
  if (status === TaskCompletionStatusEnum.InProgress)
    type = ChipTypes.chipOrange;
  if (status === TaskCompletionStatusEnum.Unsolved) type = ChipTypes.chipRed;
  if (status === TaskCompletionStatusEnum.Solved) type = ChipTypes.chipGreen;
  return type;
}
