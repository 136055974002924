import _get from 'lodash/get';
import { Values } from '@lib/interfaces/form';
import FieldNames from '@lib/enums/fieldNames/interventionCategoryFieldNames';

export default function getPreparedAssetCategoryDataFromValues(values: Values) {
  return {
    name: _get(values, FieldNames.Name, '') as string,
    color: _get(values, `${FieldNames.Color}.value`, '') as string,
    description: _get(values, FieldNames.Description, '') as string,
  };
}
