enum FormTemplateFieldNames {
  AllowDisplayLastAnswer = 'allowDisplayLastAnswer',
  AttachmentsAttached = 'attachmentsAttached',
  Category = 'category',
  Condition = 'condition',
  CurrentVersion = 'currentVersion',
  Description = 'description',
  Fields = 'fields',
  Group = 'group',
  Id = 'id',
  Kind = 'kind',
  LastAllowedAnswers = 'lastAllowedAnswers',
  LimitToOneRespPerColumn = 'limitToOneRespPerColumn',
  LimitToOneRespPerRow = 'limitToOneRespPerRow',
  Name = 'name',
  Options = 'options',
  Position = 'position',
  Public = 'public',
  Questions = 'questions',
  Required = 'required',
  Settings = 'settings',
  ShowAttachments = 'showAttachments',
  ShowDescription = 'showDescription',
  Tasks = 'tasks',
  ValidityHint = 'validityHint',
  ValidityHintConfig = 'validityHintConfig',
  Values = 'values',
  Version = 'version',
  Versions = 'versions',
  VisibleLinkedTasksCount = 'visibleLinkedTasksCount',
}

export default FormTemplateFieldNames;
