import React from 'react';
import classNames from 'classnames';
import { BaseItem, CellProps } from '@lib/components/Table/types';
import TextCell from '@lib/components/Table/commonComponents/TextCell/TextCell';
import styles from './TextCellWithIcon.module.scss';

interface Props {
  className?: string;
  icon: React.ReactNode;
  iconPosition?: 'left' | 'right';
  multiLine?: boolean;
}

function TextCellWithIcon<T extends BaseItem>(props: CellProps<T> & Props) {
  const { className, icon, multiLine, value, iconPosition = 'left' } = props;
  return (
    <div
      className={classNames(
        iconPosition === 'right'
          ? styles.rootTextCellWithRightIcon
          : styles.rootTextCellWithLeftIcon,
        className,
      )}
    >
      <TextCell {...props} value={value} multiLine={multiLine} />
      <div className={styles.icon}>{icon}</div>
    </div>
  );
}

export default TextCellWithIcon;
