import _get from 'lodash/get';
import { TFunction } from 'i18next';
import { APP_URLS } from 'constants/urls';

type Args = {
  t: TFunction<'translation', undefined>;
  formTemplateCounter: object;
};

export default function getTableTabs({ t, formTemplateCounter }: Args) {
  return [
    {
      label: t('active'),
      value: APP_URLS.dashboard.formTemplates.index.getPathWithQuery(),
      counter: _get(formTemplateCounter, 'active', 0),
    },
    {
      label: t('archived'),
      value: APP_URLS.dashboard.formTemplates.archived.getPathWithQuery(),
      counter: _get(formTemplateCounter, 'archived', 0),
    },
  ];
}
