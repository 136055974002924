import React, { memo } from 'react';
import { TFunction } from 'i18next';
import _noop from 'lodash/noop';
import _get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import {
  FormTemplateQuestionField,
  FormTemplateQuestionFieldsKindEnum,
  GroupEnum,
} from 'graphql-common';
import SupportiveMessage, {
  SupportiveMessageColor,
  SupportiveMessageIconColor,
  SupportiveMessageIconType,
  SupportiveMessageType,
} from '@lib/components/SupportiveMessage/SupportiveMessage';
import Input from '@lib/components/Input/Input';
import { InputTypes } from '@lib/components/Input/enums';
import Select from '@lib/components/Select/Select';
import RadioButtonGroup, {
  RadioButtonWrapViews,
} from '@lib/components/RadioButtonGroup/RadioButtonGroup';
import CheckBoxGroup from '@lib/components/CheckBoxGroup/CheckBoxGroup';
import InputMinMax from '@lib/components/Input/InputMinMax';
import Rating from '@lib/components/Rating/Rating';
import Button, {
  ButtonSizes,
  ButtonTypes,
} from '@lib/components/Button/Button';
import DatePicker from '@lib/components/DateTimePicker/DatePicker';
import DateRangePicker from '@lib/components/DateTimePicker/DateRangePicker';
import DateTimePicker from '@lib/components/DateTimePicker/DateTimePicker';
import TimePicker from '@lib/components/DateTimePicker/TimePicker';
import TimeRangePicker from '@lib/components/DateTimePicker/TimeRangePicker';
import CheckboxButtonGrid from '@lib/components/CheckboxButtonGrid/CheckboxButtonGrid';
import { CheckboxButtonGroupOption } from '@lib/components/CheckboxButtonGrid/types';
import RadioButtonAsButton from '@lib/components/RadioButtonAsButton/RadioButtonAsButton';
import FormTemplateFieldNames from '@lib/enums/fieldNames/formTemplateFieldNames';

const defaultProps = {
  onChange: _noop,
  disabled: true,
};

type Props = Pick<
  FormTemplateQuestionField,
  | FormTemplateFieldNames.Kind
  | FormTemplateFieldNames.Options
  | FormTemplateFieldNames.Settings
  | FormTemplateFieldNames.Required
>;

interface ComponentProps extends Props {
  t: TFunction<'translation', undefined>;
}

const componentsMap = {
  [FormTemplateQuestionFieldsKindEnum.Input]: (props: ComponentProps) => {
    const { t } = props;
    return (
      <Input
        type={InputTypes.text}
        label={t('single-line-value')}
        name={`${FormTemplateQuestionFieldsKindEnum.Input}-preview`}
        {...defaultProps}
      />
    );
  },
  [FormTemplateQuestionFieldsKindEnum.TextArea]: ({ t }: ComponentProps) => (
    <Input
      type={InputTypes.textarea}
      label={t('multi-line-value')}
      name={`${FormTemplateQuestionFieldsKindEnum.Input}-preview`}
      {...defaultProps}
    />
  ),
  [FormTemplateQuestionFieldsKindEnum.RadioButton]: ({
    options,
  }: ComponentProps) => (
    <RadioButtonGroup
      options={options.map(({ values }) => ({
        value: values[0],
        label: values[0],
      }))}
      value={undefined}
      name={FormTemplateQuestionFieldsKindEnum.RadioButton}
      {...defaultProps}
    />
  ),
  [FormTemplateQuestionFieldsKindEnum.Checkbox]: ({
    options,
  }: ComponentProps) => (
    <CheckBoxGroup
      options={options.map(({ values }) => ({
        value: values[0],
        label: values[0],
      }))}
      value={undefined}
      name={FormTemplateQuestionFieldsKindEnum.Checkbox}
      {...defaultProps}
    />
  ),
  [FormTemplateQuestionFieldsKindEnum.SingleSelect]: ({
    options,
    t,
  }: ComponentProps) => (
    <Select
      {...defaultProps}
      options={options.map(({ values }) => ({
        value: values[0],
        label: values[0],
      }))}
      label={t('choose-option')}
    />
  ),
  [FormTemplateQuestionFieldsKindEnum.YesNo]: ({ options }: ComponentProps) => (
    <RadioButtonGroup
      options={options.map(({ group, values }) => ({
        value: group || '',
        label: values[0] || '',
        leftIcon: group === GroupEnum.Yes ? 'check_circle' : 'cancel',
      }))}
      value={undefined}
      name={FormTemplateQuestionFieldsKindEnum.RadioButton}
      RadioButtonComponent={RadioButtonAsButton}
      radioButtonComponentProps={{
        disabled: true,
        fullWidth: true,
      }}
      wrapView={RadioButtonWrapViews.list}
      {...defaultProps}
    />
  ),
  [FormTemplateQuestionFieldsKindEnum.NumberInput]: ({ t }: ComponentProps) => (
    <Input
      {...defaultProps}
      type={InputTypes.number}
      label={t('number-input')}
      name={`${FormTemplateQuestionFieldsKindEnum.Input}-preview`}
    />
  ),
  [FormTemplateQuestionFieldsKindEnum.MinMax]: () => (
    <InputMinMax {...defaultProps} value={undefined} />
  ),
  [FormTemplateQuestionFieldsKindEnum.Date]: ({ t }: ComponentProps) => (
    <DatePicker
      {...defaultProps}
      label={t('select-date')}
      type={InputTypes.date}
    />
  ),
  [FormTemplateQuestionFieldsKindEnum.DateRange]: ({ t }: ComponentProps) => (
    <DateRangePicker
      {...defaultProps}
      label={t('select-date-range')}
      type={InputTypes.dateRange}
    />
  ),
  [FormTemplateQuestionFieldsKindEnum.Time]: ({ t }: ComponentProps) => (
    <TimePicker {...defaultProps} label={t('select-time')} />
  ),
  [FormTemplateQuestionFieldsKindEnum.TimeRange]: ({ t }: ComponentProps) => (
    <TimeRangePicker {...defaultProps} label={t('select-time-range')} />
  ),
  [FormTemplateQuestionFieldsKindEnum.Datetime]: ({ t }: ComponentProps) => (
    <DateTimePicker
      {...defaultProps}
      label={t('select-date-and-time')}
      type={InputTypes.time}
    />
  ),
  [FormTemplateQuestionFieldsKindEnum.CheckboxButtonGrid]: (
    props: ComponentProps,
  ) => {
    const options = _get(
      props,
      FormTemplateFieldNames.Options,
      [],
    ) as CheckboxButtonGroupOption[];
    const limitToOneRespPerRow = _get(
      props,
      [
        FormTemplateFieldNames.Settings,
        FormTemplateFieldNames.LimitToOneRespPerRow,
      ],
      false,
    ) as boolean;
    const limitToOneRespPerColumn = _get(
      props,
      [
        FormTemplateFieldNames.Settings,
        FormTemplateFieldNames.LimitToOneRespPerColumn,
      ],
      false,
    ) as boolean;
    return (
      <CheckboxButtonGrid
        options={options}
        {...defaultProps}
        limitToOneRespPerRow={limitToOneRespPerRow}
        limitToOneRespPerColumn={limitToOneRespPerColumn}
      />
    );
  },
  [FormTemplateQuestionFieldsKindEnum.Rating]: () => (
    <Rating
      {...defaultProps}
      value={undefined}
      name={FormTemplateQuestionFieldsKindEnum.Rating}
    />
  ),
  [FormTemplateQuestionFieldsKindEnum.Attachment]: ({
    t,
    required = false,
  }: ComponentProps) => {
    return (
      <div>
        <Button
          buttonType={ButtonTypes.secondaryOutlined}
          buttonSize={ButtonSizes.large}
          buttonText={t('upload-file')}
          leftIcon="upload"
          disabled
        />
        {required && (
          <div style={{ marginTop: '16px' }}>
            <SupportiveMessage
              color={SupportiveMessageColor.Yellow}
              text={t('attachment-field-offline-text')}
            />
          </div>
        )}
      </div>
    );
  },
  [FormTemplateQuestionFieldsKindEnum.DecisionBox]: (props: ComponentProps) => {
    const { t } = props;
    const text = _get(
      props,
      `${FormTemplateFieldNames.Options}.0.${FormTemplateFieldNames.Values}.0`,
      '',
    );
    const options = _get(props, FormTemplateFieldNames.Options, []);
    return (
      <CheckBoxGroup
        options={options.map(({ values }) => ({
          value: values[0],
          label: text || t('decision-box-message'),
        }))}
        value={undefined}
        name={FormTemplateQuestionFieldsKindEnum.DecisionBox}
        {...defaultProps}
      />
    );
  },
  [FormTemplateQuestionFieldsKindEnum.ImportantNotice]: (
    props: ComponentProps,
  ) => {
    const { t } = props;
    const text = _get(
      props,
      `${FormTemplateFieldNames.Options}.0.${FormTemplateFieldNames.Values}.0`,
      '',
    );
    return (
      <SupportiveMessage
        text={text || t('important-notice-message')}
        leftIcon="warning"
        color={SupportiveMessageColor.Yellow}
        iconColor={SupportiveMessageIconColor.Inner}
        iconType={SupportiveMessageIconType.FilledWithBorder}
        type={SupportiveMessageType.FilledWithBorder}
      />
    );
  },
  [FormTemplateQuestionFieldsKindEnum.Section]: undefined,
};

function QuestionFieldPreviewByKind(props: Props) {
  const { kind } = props;
  const { t } = useTranslation();
  const Component = componentsMap[kind];
  if (Component === undefined) return null;
  return <Component {...props} t={t} />;
}

export default memo(QuestionFieldPreviewByKind);
