import { TaskCounter } from '@lib/interfaces/task';

export default function getTaskCounterData(data: any): TaskCounter {
  return {
    active: data?.active?.metadata?.totalCount,
    archived: data?.archived?.metadata?.totalCount,
    completed: data?.completed?.metadata?.totalCount,
    dashboardAll: data?.dashboardAll?.metadata?.totalCount,
    deadlineMissed: data?.deadlineMissed?.metadata?.totalCount,
    deadlineMissedWithoutReason:
      data?.deadlineMissedWithoutReason?.metadata?.totalCount,
    failureSolved: data?.failureSolved?.metadata?.totalCount,
    failureUnsolved: data?.failureUnsolved?.metadata?.totalCount,
    myCompleted: data?.myCompleted?.metadata?.totalCount,
    myTodoAvailableToComplete:
      data?.myTodoAvailableToComplete?.metadata?.totalCount,
    myTodoUpcoming: data?.myTodoUpcoming?.metadata?.totalCount,
    pending: data?.pending?.metadata?.totalCount,
    solved: data?.solved?.metadata?.totalCount,
    success: data?.success?.metadata?.totalCount,
    timelineAll: data?.timelineAll?.metadata?.totalCount,
    timelineFailure: data?.timelineFailure?.metadata?.totalCount,
    timelineSuccess: data?.timelineSuccess?.metadata?.totalCount,
    timelineWarning: data?.timelineWarning?.metadata?.totalCount,
    warningSolved: data?.warningSolved?.metadata?.totalCount,
    warningUnsolved: data?.warningUnsolved?.metadata?.totalCount,
  };
}
