import React from 'react';
import { useTranslation } from 'react-i18next';
import _omit from 'lodash/omit';
import { FetchResult } from '@apollo/client';
import { AuthenticationConfirmationsResendEmailConfirmationMutation } from 'graphql-common';
import { Values } from '@lib/interfaces/form';
import Typography from '@lib/components/Typography/Typography';
import Form from '@lib/components/ReactHookForm/FormContainer';
import replaceNullsWithUndefined from '@lib/utils/replaceNullsWithUndefined';
import { GraphQLErrors } from '@apollo/client/errors';
import ProfileFormActions from './ProfileFormActions';
import getProfileFormFields from './utils/getProfileFormFields';
import { OnUpdateProfile } from './types';
import styles from './ProfileForm.module.scss';

interface Props {
  countriesCodes: string[];
  defaultValues: Values;
  graphQLErrors?: GraphQLErrors;
  loading: boolean;
  onResendConfirmationHandler: () => Promise<
    FetchResult<AuthenticationConfirmationsResendEmailConfirmationMutation>
  >;
  onSubmit: OnUpdateProfile;
  unconfirmedEmail?: string | null;
}

const emptyDefaultValues = {
  avatarAttached: null,
  email: null,
  firstName: null,
  id: null,
  lastName: null,
  personalId: null,
  phoneNumber: null,
};

function ProfileForm(props: Props) {
  const {
    countriesCodes,
    defaultValues = emptyDefaultValues,
    graphQLErrors,
    loading,
    onResendConfirmationHandler,
    onSubmit,
    unconfirmedEmail,
  } = props;
  const { t } = useTranslation();

  const fields = getProfileFormFields({
    countriesCodes,
    onResendConfirmationHandler,
    onSubmit,
    t,
    unconfirmedEmail,
  });

  const preparedDefaultValues = {
    ...replaceNullsWithUndefined(_omit(defaultValues, 'lang'), [
      'avatarAttached',
    ]),
    avatarAttached: defaultValues.avatarAttached,
  };

  return (
    <div className={styles.wrapPrimary}>
      <Typography variant="h3" className={styles.title}>
        {t('personal-info')}
      </Typography>
      <Form
        actionsComponent={ProfileFormActions}
        defaultValues={preparedDefaultValues}
        fields={fields}
        graphQLErrors={graphQLErrors}
        loading={loading}
        onSubmit={onSubmit}
      />
    </div>
  );
}

export default ProfileForm;
