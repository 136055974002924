import React, { memo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import IconButton from '@lib/components/IconButton/IconButton';
import { BaseItem, Column, TableProps } from '@lib/components/Table/types';
import CellContent from '../CellContent';
import styles from './CellWithActions..module.scss';

interface CellWithActionsProps<T extends BaseItem> {
  column: Column<T>;
  item: T;
  tableProps: TableProps<T>;
}

function CellWithActions<T extends BaseItem>({
  column,
  item,
  tableProps,
}: CellWithActionsProps<T>) {
  return (
    <div className={styles.root}>
      <CellContent column={column} item={item} tableProps={tableProps} />
      <div className={styles.actions}>
        {(column.actions || []).map((action) => (
          <IconButton
            key={uuidv4()}
            {...action}
            onClick={() => action.onClick(item)}
          />
        ))}
      </div>
    </div>
  );
}

export default memo(CellWithActions) as <T extends BaseItem>(
  props: CellWithActionsProps<T>,
) => JSX.Element;
