import React from 'react';
import * as Yup from 'yup';
import { TFunction } from 'i18next';
import _get from 'lodash/get';
import _some from 'lodash/some';
import _without from 'lodash/without';
import {
  FormTemplateQuestionFieldsKindEnum,
  FormTemplateVersionStatusEnum,
} from 'graphql-common';
import Input from '@lib/components/Input/Input';
import { InputTypes } from '@lib/components/Input/enums';
import WhiteBox from '@lib/components/WhiteBox/WhiteBox';
import {
  MAX_TEXT_EDITOR_FIELD_LENGTH,
  MAX_TEXT_FIELD_LENGTH,
  Steps,
} from '@lib/enums/form';
import { getOptionShape } from '@lib/utils/yup';
import {
  FieldChangeCallBackArgs,
  FieldItem,
  Values,
} from '@lib/interfaces/form';
import FilesUploader from '@lib/components/FilesUploader/FilesUploader';
import Select, { SelectProps } from '@lib/components/Select/Select';
import QuestionFieldOptions from 'components/FormTemplateBuilder/QuestionFieldOptions/QuestionFieldOptions';
import QuestionFieldOption from 'components/FormTemplateBuilder/QuestionFieldOption/QuestionFieldOption';
import FormTemplateBuilderEmptyState from 'components/FormTemplateBuilder/FormTemplateBuilderEmptyState/FormTemplateBuilderEmptyState';
import QuestionFieldOptionsAddItemComponent from 'components/FormTemplateBuilder/QuestionFieldOptionsAddItemComponent/QuestionFieldOptionsAddItemComponent';
import FormTemplateBuilderQuestion from 'components/FormTemplateBuilder/FormTemplateBuilderQuestion/FormTemplateBuilderQuestion';
import style from 'components/FormTemplateBuilder/FormTemplateBuilderForm/FormTemplateBuilderForm.module.scss';
import QuestionField from 'components/FormTemplateBuilder/QuestionField/QuestionField';
import { GetLoadOptions, SelectOption } from '@lib/components/Select/types';
import ChipValueContainer from '@lib/components/Select/components/ChipValueContainer';
import { UppyImageAllowedFileTypes } from '@lib/enums/mimeTypes';
import formStyles from '@lib/assets/styles/Form.module.scss';
import QuestionFieldOptionsEmptyStateComponent from 'components/FormTemplateBuilder/QuestionFieldOptionsEmptyStateComponent/QuestionFieldOptionsEmptyStateComponent';
import FormTemplateFieldNames from '@lib/enums/fieldNames/formTemplateFieldNames';
import { FormTemplateMode } from 'routes/FormTemplate/enums';
import FormTemplateVersionSelect from 'components/FormTemplateVersionSelect/FormTemplateVersionSelect';
import FormTemplateTaskAssignField from 'components/FormTemplateTaskAssignField/FormTemplateTaskAssignField';
import TextEditor from '@lib/components/TextEditor/TextEditor';
import { getFieldValidation } from '@lib/utils/validationUtils';

type OnVersionFieldChangeCallBack = (
  newVersionId?: string,
  newVersionStatus?: FormTemplateVersionStatusEnum,
) => void;

type FormFieldsArgs = {
  formTemplateId: string;
  getFormCategoriesLoadOptions: GetLoadOptions;
  getTasksLoadOptions: GetLoadOptions;
  isCategoriesViewOnly: boolean;
  onNameFieldChangeCallBack?: (args: FieldChangeCallBackArgs) => void;
  onVersionFieldChangeCallBack?: OnVersionFieldChangeCallBack;
  openCategoryFormModal: () => void;
  selectedVersionOption?: SelectOption;
  step?: Steps;
  t: TFunction<'translation', undefined>;
  viewMode: FormTemplateMode;
};

export default function getFormTemplateFormFields(args: FormFieldsArgs) {
  const {
    formTemplateId,
    getFormCategoriesLoadOptions,
    getTasksLoadOptions,
    isCategoriesViewOnly,
    onNameFieldChangeCallBack,
    onVersionFieldChangeCallBack,
    openCategoryFormModal,
    selectedVersionOption,
    step,
    t,
    viewMode,
  } = args;
  const isTasksFieldVisible = viewMode === FormTemplateMode.EditVersion;
  const isDetailsFieldsDisabled = viewMode === FormTemplateMode.EditVersion;
  const isVersionsFieldVisible =
    viewMode !== FormTemplateMode.Add && viewMode !== FormTemplateMode.Edit;
  const isQuestionsFieldsVisible = viewMode !== FormTemplateMode.Edit;
  const detailsFields = {
    name: 'formInfo',
    element: WhiteBox,
    componentProps: {
      className: style.groupWhiteBox,
    },
    groupFields: _without(
      [
        {
          name: FormTemplateFieldNames.Name,
          label: t('form-template-name'),
          element: Input,
          validation: Yup.string()
            .trim()
            .max(MAX_TEXT_FIELD_LENGTH, t('max-text-length-field-error'))
            .required(t('required-field-error')),
          step,
          fieldChangeCallBack: onNameFieldChangeCallBack,
          componentProps: {
            disabled: isDetailsFieldsDisabled,
          },
          formItemClassName: isVersionsFieldVisible
            ? formStyles.formColumn10
            : undefined,
        },
        isVersionsFieldVisible
          ? {
              name: FormTemplateFieldNames.Version,
              step,
              customRender: () => (
                <div>
                  <FormTemplateVersionSelect
                    formTemplateId={formTemplateId}
                    onChange={(v) => {
                      const newVersionId = _get(v, 'value');
                      const newVersionStatus = _get(v, ['data', 'status']);
                      if (onVersionFieldChangeCallBack && newVersionId) {
                        onVersionFieldChangeCallBack(
                          newVersionId,
                          newVersionStatus,
                        );
                      }
                    }}
                    value={selectedVersionOption}
                    components={{
                      LoadingIndicator: undefined,
                    }}
                    useAddNewOption={viewMode === FormTemplateMode.EditVersion}
                    label={t('versions')}
                  />
                </div>
              ),
              formItemClassName: formStyles.formColumn2,
            }
          : undefined,
        {
          name: FormTemplateFieldNames.Category,
          label: t('form-category'),
          element: Select,
          validation: Yup.object()
            .shape(getOptionShape(t, true))
            .required(t('required-field-error')),
          componentProps: {
            isAsync: true,
            getLoadOptions: getFormCategoriesLoadOptions,
            defaultOptions: true,
            createNewOptionProps: isCategoriesViewOnly
              ? undefined
              : {
                  title: t('create-new'),
                  onCLick: openCategoryFormModal,
                },
            components: {
              ValueContainer: ChipValueContainer,
            },
            disabled: isDetailsFieldsDisabled,
          } as SelectProps,
          step,
        },
        {
          name: FormTemplateFieldNames.Description,
          label: t('template-description'),
          element: TextEditor,
          componentProps: {
            readOnly: isDetailsFieldsDisabled,
            disabled: isDetailsFieldsDisabled,
          },
          validation: getFieldValidation(
            false,
            MAX_TEXT_EDITOR_FIELD_LENGTH,
            t,
          ),
          step,
        },
        isTasksFieldVisible
          ? {
              name: `${FormTemplateFieldNames.Tasks}-box`,
              customRender: (values: Values) => (
                <FormTemplateTaskAssignField
                  getTasksLoadOptions={getTasksLoadOptions}
                  values={values}
                />
              ),
              step,
              isUseWatch: true,
              hideErrors: true,
            }
          : undefined,
        isTasksFieldVisible
          ? {
              name: `${FormTemplateFieldNames.Tasks}-enable`,
              step,
              fieldChangeCallBack: ({
                type,
                setValue,
                name,
              }: FieldChangeCallBackArgs) => {
                if (
                  type === 'change' &&
                  name === `${FormTemplateFieldNames.Tasks}-enable`
                ) {
                  setValue(FormTemplateFieldNames.Tasks, undefined, {
                    shouldValidate: false,
                  });
                }
              },
              isVisible: false,
            }
          : undefined,
        isTasksFieldVisible
          ? {
              name: FormTemplateFieldNames.Tasks,
              validation: Yup.array()
                .of(Yup.object().shape(getOptionShape(t, true)))
                .when(
                  `${FormTemplateFieldNames.Tasks}-enable`,
                  (deps, schema) =>
                    _get(deps, 0)
                      ? schema
                          .min(1, t('required-field-error'))
                          .required(t('required-field-error'))
                      : schema.nullable(),
                ),
              isOptionalFn: (values: Values) =>
                !_get(values, `${FormTemplateFieldNames.Tasks}-enable`, false),
              step,
              isVisible: false,
            }
          : undefined,
      ],
      undefined,
    ),
    step,
  };
  const questionsFields = {
    name: FormTemplateFieldNames.Questions,
    hideErrors: true,
    fieldComponents: {
      FieldArrayAddItemComponent: null,
      FieldArrayEmptyStateComponent: FormTemplateBuilderEmptyState,
      FieldArrayItemWrapperComponent: FormTemplateBuilderQuestion,
    },
    subFields: [
      {
        name: FormTemplateFieldNames.Name,
        getLabel: (values: Values, name?: string) => {
          const questionName = name?.replace(
            `.${FormTemplateFieldNames.Name}`,
            '',
          );
          if (questionName) {
            const question = _get(values, questionName);
            const kind = _get(
              question,
              `${FormTemplateFieldNames.Fields}.0.${FormTemplateFieldNames.Kind}`,
            );
            if (kind === FormTemplateQuestionFieldsKindEnum.Section) {
              return t('section-name');
            }
          }
          return t('question');
        },
        element: Input,
        isUnique: true,
        validation: Yup.string()
          .trim()
          .max(MAX_TEXT_FIELD_LENGTH, t('max-text-length-field-error'))
          .nullable()
          .test(
            'name-validation',
            t('required-field-error'),
            // @ts-ignore
            function matchTest(this, value: Values): boolean {
              const isQuestionNameOptional = this.parent.fields?.some(
                ({ kind }: Values) =>
                  kind === FormTemplateQuestionFieldsKindEnum.DecisionBox ||
                  kind === FormTemplateQuestionFieldsKindEnum.ImportantNotice,
              );
              if (isQuestionNameOptional) {
                return true;
              }
              return !!value?.length;
            },
          ),
        isOptionalFn: (values: Values, name?: string) => {
          if (name) {
            const fieldsName = name.replace(
              `.${FormTemplateFieldNames.Name}`,
              `.${FormTemplateFieldNames.Fields}`,
            );
            const fields = _get(values, fieldsName, []);
            const isQuestionNameOptional =
              Array.isArray(fields) &&
              _some(fields, [
                FormTemplateFieldNames.Kind,
                FormTemplateQuestionFieldsKindEnum.DecisionBox ||
                  FormTemplateQuestionFieldsKindEnum.ImportantNotice,
              ]);
            if (isQuestionNameOptional) {
              return true;
            }
          }
          return false;
        },
        isVisibleFn: (values: Values, name?: string) => {
          const questionName = name?.replace(
            `.${FormTemplateFieldNames.Name}`,
            '',
          );
          if (questionName) {
            const question = _get(values, questionName);
            const kind = _get(
              question,
              `${FormTemplateFieldNames.Fields}.0.${FormTemplateFieldNames.Kind}`,
            );
            if (
              kind === FormTemplateQuestionFieldsKindEnum.DecisionBox ||
              kind === FormTemplateQuestionFieldsKindEnum.ImportantNotice
            ) {
              return false;
            }
          }
          return true;
        },
      },
      {
        name: FormTemplateFieldNames.Description,
        label: t(FormTemplateFieldNames.Description),
        element: Input,
        componentProps: {
          type: InputTypes.textarea,
        },
        validation: Yup.string()
          .trim()
          .nullable()
          .max(MAX_TEXT_FIELD_LENGTH, t('max-text-length-field-error')),
        isVisibleFn: (values: Values, name?: string) => {
          if (name) {
            return !!_get(
              values,
              name?.replace(
                FormTemplateFieldNames.Description,
                FormTemplateFieldNames.ShowDescription,
              ),
              false,
            );
          }
          return true;
        },
      },
      {
        name: FormTemplateFieldNames.AttachmentsAttached,
        element: FilesUploader,
        componentProps: {
          uploadButtonProps: {
            buttonText: t('upload-image'),
          },
          allowedFileTypes: UppyImageAllowedFileTypes,
          note: t('image-upload-validation-msg', { size: 5 }),
          openUploaderModalOnInit: true,
          showDeleteAllButton: true,
        },
        isVisibleFn: (values: Values, name?: string) => {
          if (name) {
            return !!_get(
              values,
              name?.replace(
                FormTemplateFieldNames.AttachmentsAttached,
                FormTemplateFieldNames.ShowAttachments,
              ),
              false,
            );
          }
          return true;
        },
      },
      {
        name: FormTemplateFieldNames.Fields,
        fieldComponents: {
          FieldArrayAddItemComponent: null,
          FieldArrayEmptyStateComponent: null,
          FieldArrayItemWrapperComponent: QuestionFieldOptions,
        },
        subFields: [
          {
            name: FormTemplateFieldNames.Options,
            fieldComponents: {
              FieldArrayAddItemComponent: QuestionFieldOptionsAddItemComponent,
              FieldArrayEmptyStateComponent:
                QuestionFieldOptionsEmptyStateComponent,
              FieldArrayItemWrapperComponent: QuestionFieldOption,
              FieldArrayLoopComponent: QuestionField,
            },
            subFields: [
              {
                name: FormTemplateFieldNames.Values,
                validation: Yup.array().of(
                  Yup.string()
                    .trim()
                    .max(
                      MAX_TEXT_FIELD_LENGTH,
                      t('max-text-length-field-error'),
                    )
                    .required(t('required-field-error')),
                ),
              },
              {
                name: FormTemplateFieldNames.Group,
              },
              {
                name: FormTemplateFieldNames.Condition,
              },
              {
                name: FormTemplateFieldNames.ValidityHint,
                validation: Yup.object().shape(getOptionShape(t)),
              },
            ],
          },
          {
            name: FormTemplateFieldNames.AllowDisplayLastAnswer,
            element: undefined,
            isVisibleFn: () => false,
          },
          {
            name: FormTemplateFieldNames.LimitToOneRespPerRow,
            element: undefined,
            isVisibleFn: () => false,
          },
          {
            name: FormTemplateFieldNames.LimitToOneRespPerColumn,
            element: undefined,
            isVisibleFn: () => false,
          },
        ],
      },
      {
        name: FormTemplateFieldNames.Required,
        element: undefined,
        isVisibleFn: () => false,
      },
      {
        name: FormTemplateFieldNames.ShowDescription,
        element: undefined,
        isVisibleFn: () => false,
        fieldChangeCallBack: ({
          type,
          setValue,
          name,
        }: FieldChangeCallBackArgs) => {
          if (
            type === 'change' &&
            name?.includes(FormTemplateFieldNames.ShowDescription)
          ) {
            setValue(
              name?.replace(
                FormTemplateFieldNames.ShowDescription,
                FormTemplateFieldNames.Description,
              ),
              '',
              {
                shouldValidate: true,
              },
            );
          }
        },
      },
      {
        name: FormTemplateFieldNames.ShowAttachments,
        element: undefined,
        isVisibleFn: () => false,
        fieldChangeCallBack: ({ values, name = '', type, setValue }) => {
          if (
            type === 'change' &&
            name?.includes(FormTemplateFieldNames.ShowAttachments)
          ) {
            const attachmentsAttachedName = name.replace(
              FormTemplateFieldNames.ShowAttachments,
              FormTemplateFieldNames.AttachmentsAttached,
            );
            const prevValue = _get(values, attachmentsAttachedName, []) as {
              id: string;
              _destroy: boolean;
            }[];
            const nextValue = prevValue.reduce(
              (acc: { id: string; _destroy: boolean }[], item) => {
                if (item.id) acc.push({ id: item.id, _destroy: true });
                return acc;
              },
              [],
            );
            setValue(attachmentsAttachedName, nextValue, {
              shouldValidate: true,
            });
          }
        },
      },
    ],
    isDraggable: true,
    step,
  };
  return _without(
    [detailsFields, isQuestionsFieldsVisible ? questionsFields : undefined],
    undefined,
  ) as FieldItem[];
}
