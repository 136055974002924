import React from 'react';
import { TaskScopeNameEnum } from 'graphql-common';
import { ApolloError } from '@apollo/client/errors';
import classNames from 'classnames';
import CountUp from '@lib/components/CountUp/CountUp';
import PageBody from '@lib/layouts/DashboardLayout/PageBody/PageBody';
import CircleLoader from '@lib/components/CircleLoader/CircleLoader';
import Typography from '@lib/components/Typography/Typography';
import Trans from '@lib/components/Trans/Trans';
import MaterialIcon from '@lib/components/MaterialIcon/MaterialIcon';
import CountMark, { CountMarkTypes } from '@lib/components/CountMark/CountMark';
import Tooltip from '@lib/components/Tooltip/Tooltip';
import { useTranslation } from 'react-i18next';
import { TaskCounter } from '@lib/interfaces/task';
import styles from './TaskResultStateCounters.module.scss';

const getCounters = ({
  active = 0,
  dashboardAll = 0,
  deadlineMissedWithoutReason = 0,
  failureSolved = 0,
  failureUnsolved = 0,
  success = 0,
  warningSolved = 0,
  warningUnsolved = 0,
}: TaskCounter) => [
  {
    type: TaskScopeNameEnum.DashboardAll,
    title: 'completions',
    count: dashboardAll,
    icon: 'verified',
    iconClassName: classNames(styles.counterItemIcon, styles.completions),
  },
  {
    type: TaskScopeNameEnum.Active,
    title: 'active-tasks',
    count: active,
    icon: 'schedule',
    iconClassName: classNames(styles.counterItemIcon, styles.active),
    iconSymbolsOutlined: true,
    iconFilled: true,
  },
  {
    type: TaskScopeNameEnum.DeadlineMissed,
    title: 'deadlines-missed',
    count: deadlineMissedWithoutReason,
    icon: 'clock_loader_20',
    iconClassName: classNames(styles.counterItemIcon, styles.deadlinesMissed),
    iconSymbolsOutlined: true,
  },
  {
    type: TaskScopeNameEnum.Success,
    title: 'success-total',
    count: success,
    icon: 'check_circle',
    iconClassName: classNames(styles.counterItemIcon, styles.success),
  },
  {
    type: TaskScopeNameEnum.Warning,
    title: 'unresolved-warning',
    count: warningUnsolved,
    subCount: warningSolved,
    icon: 'warning',
    iconClassName: classNames(styles.counterItemIcon, styles.warning),
  },
  {
    type: TaskScopeNameEnum.Failure,
    title: 'unresolved-alert',
    count: failureUnsolved,
    subCount: failureSolved,
    icon: 'error',
    iconClassName: classNames(styles.counterItemIcon, styles.failure),
  },
];

type TaskResultStateCountersProps = {
  taskCounterData: TaskCounter;
  tasksFirstLoading: boolean;
  tasksError?: ApolloError;
};

export default function TaskResultStateCounters(
  props: TaskResultStateCountersProps,
) {
  const { taskCounterData, tasksFirstLoading, tasksError } = props;
  const { t } = useTranslation();
  if (tasksError) return null;
  if (tasksFirstLoading) return <CircleLoader />;
  const counters = getCounters(taskCounterData);
  return (
    <PageBody>
      <div className={styles.counters}>
        {counters.map(
          ({
            type,
            title,
            count,
            icon,
            iconClassName,
            iconSymbolsOutlined,
            iconFilled,
            subCount,
          }) => (
            <div
              key={type}
              data-test-type={type}
              className={styles.counterItem}
            >
              <div className={iconClassName}>
                <MaterialIcon
                  icon={icon}
                  symbolsOutlined={iconSymbolsOutlined}
                  filled={iconFilled}
                />
              </div>
              <div>
                <Typography variant="caption" strong className={styles.title}>
                  <Trans i18nKey={title} />
                </Typography>
                <div className={styles.countersRow}>
                  <Typography variant="h2" className={styles.counter}>
                    <CountUp end={count} duration={3} />
                  </Typography>
                  {!!subCount && (
                    <Tooltip body={t('solved')}>
                      <div className={styles.solvedCounter}>
                        <CountMark
                          value={subCount}
                          type={CountMarkTypes.blue}
                          disabled
                          useCountUp
                        />
                      </div>
                    </Tooltip>
                  )}
                </div>
              </div>
            </div>
          ),
        )}
      </div>
    </PageBody>
  );
}
