import React from 'react';
import { useTranslation } from 'react-i18next';
import { ApolloError, GraphQLErrors } from '@apollo/client/errors';
import DashboardContentMaxWidth from '@lib/layouts/DashboardLayout/DashboardContentMaxWidth/DashboardContentMaxWidth';
import PageHeader from '@lib/layouts/DashboardLayout/PageHeader/PageHeader';
import PageBody from '@lib/layouts/DashboardLayout/PageBody/PageBody';
import Form from '@lib/components/ReactHookForm/FormContainer';
import ErrorPage from '@lib/components/ErrorPage/ErrorPage';
import getStatusFromError from '@lib/utils/getStatusFromError';
import { Values } from '@lib/interfaces/form';
import { FormRefType } from '@lib/components/ReactHookForm/types';
import WhiteBox from '@lib/components/WhiteBox/WhiteBox';
import AssetDocumentFieldNames from '@lib/enums/fieldNames/assetDocumentFieldNames';
import { LoadOptions } from '@lib/components/Select/types';
import styles from 'components/Asset/AssetView/AssetView.module.scss';
import Button, { ButtonTypes } from '@lib/components/Button/Button';
import { materialSymbolsOutlined } from '@lib/enums/common';
import ConfirmationModal from '@lib/components/ConfirmationModal/ConfirmationModal';
import Trans from '@lib/components/Trans/Trans';
import { UseModalManagementResult } from 'routes/Assets/AssetDocumentation/utils/useModalManagement';
import { OnCreateTagFn } from '@lib/components/TagsInput/types';
import FileViewerModal from '@lib/components/FileViewerModal/FileViewerModal';
import { IconName } from '@lib/components/Modal/enums';
import getFormFields from './utils/getFormFields';
import AssetDocumentationFormActions from './AssetDocumentationFormActions';

interface Props {
  defaultValues: Values;
  documentError?: ApolloError;
  formRef: FormRefType;
  graphQLErrors?: GraphQLErrors;
  isCreatePage: boolean;
  loading: boolean;
  onConfirmedDocumentationDestroy: () => void;
  onCreateTag: OnCreateTagFn;
  onSubmit: (values: Values) => void;
  tagsLoadOptions: LoadOptions;
  useModalManagementResult: UseModalManagementResult;
}

export default function AssetDocumentation(props: Props) {
  const {
    defaultValues,
    documentError,
    formRef,
    graphQLErrors,
    isCreatePage,
    loading,
    onConfirmedDocumentationDestroy,
    onCreateTag,
    onSubmit,
    tagsLoadOptions,
    useModalManagementResult,
  } = props;

  const { t } = useTranslation();

  const fields = getFormFields({
    t,
    tagsLoadOptions,
    onCreateTag,
    onFilePreview: useModalManagementResult.openDocumentationPreviewModal,
    isCreatePage,
    prevValues: defaultValues,
  });

  if (documentError) {
    return (
      <ErrorPage
        status={getStatusFromError(documentError)}
        description={documentError?.message}
      />
    );
  }

  return (
    <>
      <DashboardContentMaxWidth>
        <PageHeader
          title={t(isCreatePage ? 'upload-document' : 'edit-document')}
        />
        <PageBody>
          <WhiteBox verticalPaddings="large">
            <Form
              actionsComponent={AssetDocumentationFormActions}
              actionsComponentProps={{ isCreatePage }}
              defaultValues={defaultValues}
              fields={fields}
              formId="site"
              formRef={formRef}
              graphQLErrors={graphQLErrors}
              loading={loading}
              onSubmit={onSubmit}
              sourceAppendValues={{
                [AssetDocumentFieldNames.Documents]: {
                  [AssetDocumentFieldNames.Document]: undefined,
                  [AssetDocumentFieldNames.Name]: '',
                  [AssetDocumentFieldNames.Tags]: [],
                },
              }}
            />
          </WhiteBox>
          {isCreatePage ? (
            <div />
          ) : (
            <div className={styles.actions}>
              <Button
                buttonText={t('delete-document')}
                buttonType={ButtonTypes.negativeOutlined}
                leftIcon="delete"
                leftIconClassName={materialSymbolsOutlined}
                onClick={useModalManagementResult.openDocumentationDestroyModal}
              />
            </div>
          )}
        </PageBody>
      </DashboardContentMaxWidth>
      <ConfirmationModal
        confirmButtonLabel={t('yes-delete')}
        description={<Trans i18nKey="asset-documentation-delete-text" />}
        icon={IconName.Delete}
        isOpen={useModalManagementResult.isDocumentationDestroyModalOpened}
        onClose={useModalManagementResult.closeAllModals}
        onConfirm={onConfirmedDocumentationDestroy}
        title={t('are-you-sure')}
      />
      <FileViewerModal
        attachment={
          useModalManagementResult.isDocumentationPreviewModalOpened
            ?.attachmentAttached
        }
        attachmentName={
          useModalManagementResult.isDocumentationPreviewModalOpened?.name
        }
        onClose={useModalManagementResult.closeAllModals}
        isOpen={
          !!useModalManagementResult.isDocumentationPreviewModalOpened
            ?.attachmentAttached?.id
        }
      />
    </>
  );
}
