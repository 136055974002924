import _get from 'lodash/get';
import _map from 'lodash/map';
import { UseBulkSelectResult } from '@lib/hooks/useTableItemsBulkSelect';

interface Args<T> {
  action: (ids: string[]) => void;
  onGetAllIds: () => Promise<string[]>;
  useBulkSelectResult?: UseBulkSelectResult<T>;
}

export default function tableItemsBulkAction<T>(args: Args<T>) {
  const { action, onGetAllIds, useBulkSelectResult } = args;
  const selectedTableItems = _get(useBulkSelectResult, 'selectedTableItems');
  const selectedSingleTableItemId = _get(useBulkSelectResult, [
    'selectedSingleTableItem',
    'id',
  ]);
  const isAllTableItemsSelected = _get(
    useBulkSelectResult,
    'isAllTableItemsSelected',
  );
  if (selectedSingleTableItemId) {
    action([selectedSingleTableItemId]);
  } else if (selectedTableItems?.length && !isAllTableItemsSelected) {
    action(_map(selectedTableItems, 'id'));
  } else if (isAllTableItemsSelected) {
    onGetAllIds().then((ids) => {
      if (ids?.length) {
        action(ids);
      }
    });
  }
}
