import React from 'react';
import { useTranslation } from 'react-i18next';
import PageHeader from '@lib/layouts/DashboardLayout/PageHeader/PageHeader';
import PageBody from '@lib/layouts/DashboardLayout/PageBody/PageBody';
import DashboardContentMaxWidth from '@lib/layouts/DashboardLayout/DashboardContentMaxWidth/DashboardContentMaxWidth';
import { FormRefType } from '@lib/components/ReactHookForm/types';
import AssetForm from 'components/Asset/AssetForm/AssetForm';
import { UseModalManagementResult } from 'routes/Assets/Asset/utils/useModalManagement';
import { UseSearchListQueriesResult } from 'routes/Assets/Asset/utils/useSearchListQueries';
import { SubmitAction } from '@lib/enums/form';
import { UseAssetActionsResult } from 'routes/Assets/Asset/utils/useAssetActions';

interface Props {
  formRef: FormRefType;
  submitNewAssetRef: React.MutableRefObject<SubmitAction | undefined>;
  useAssetActionsResult: UseAssetActionsResult;
  useModalManagementResult: UseModalManagementResult;
  useSearchListQueriesResult: UseSearchListQueriesResult;
  isCategoriesViewOnly: boolean;
}

const defaultValues = {
  name: '',
  code: '',
  category: null,
  site: null,
  siteArea: null,
  description: '',
  serviceDate: '',
  serialNumber: '',
  manufacturer: '',
  model: '',
  iconAttached: '',
};

export default function AssetAdd(props: Props) {
  const { t } = useTranslation();

  const {
    formRef,
    submitNewAssetRef,
    useModalManagementResult,
    useAssetActionsResult,
    useSearchListQueriesResult,
    isCategoriesViewOnly,
  } = props;
  const {
    assetCategoryCreateMutationError,
    assetCategoryCreateMutationLoading,
    assetMutationLoading,
    assetMutationError,
    onAssetCategoryCreate,
    onCreate,
  } = useAssetActionsResult;
  const {
    closeCategoryFormModal,
    isCategoryFormModalOpened,
    openCategoryFormModal,
  } = useModalManagementResult;
  const {
    getSiteAreasLoadOptions,
    getSitesLoadOptions,
    getAssetCategoriesLoadOptions,
  } = useSearchListQueriesResult;

  return (
    <DashboardContentMaxWidth>
      <PageHeader title={t('add-new-asset')} />
      <PageBody>
        <AssetForm
          getAssetCategoriesLoadOptions={getAssetCategoriesLoadOptions}
          categoryGraphQLErrors={
            assetCategoryCreateMutationError?.graphQLErrors
          }
          categoryLoading={assetCategoryCreateMutationLoading}
          closeCategoryFormModal={closeCategoryFormModal}
          defaultValues={defaultValues}
          formRef={formRef}
          graphQLErrors={assetMutationError?.graphQLErrors}
          isCategoryFormModalOpened={isCategoryFormModalOpened}
          loading={assetMutationLoading}
          onAssetCategoryCreate={onAssetCategoryCreate}
          onSubmit={onCreate}
          openCategoryFormModal={openCategoryFormModal}
          getSiteAreasLoadOptions={getSiteAreasLoadOptions}
          getSitesLoadOptions={getSitesLoadOptions}
          submitNewAssetRef={submitNewAssetRef}
          isCategoriesViewOnly={isCategoriesViewOnly}
        />
      </PageBody>
    </DashboardContentMaxWidth>
  );
}
