import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Asset, Site, SiteArea, Task } from 'graphql-common';
import Form from '@lib/components/ReactHookForm/FormContainer';
import DashboardContentMaxWidth from '@lib/layouts/DashboardLayout/DashboardContentMaxWidth/DashboardContentMaxWidth';
import PageHeader from '@lib/layouts/DashboardLayout/PageHeader/PageHeader';
import PageBody from '@lib/layouts/DashboardLayout/PageBody/PageBody';
import WhiteBox from '@lib/components/WhiteBox/WhiteBox';
import Button, { ButtonTypes } from '@lib/components/Button/Button';
import { materialSymbolsOutlined } from '@lib/enums/common';
import ActionsRow from '@lib/components/ActionsRow/ActionsRow';
import formStyles from '@lib/assets/styles/Form.module.scss';
import { FormRefType } from '@lib/components/ReactHookForm/types';
import useSearchListQueries from '../utils/useSearchListQueries';
import { UseModalManagementResult } from '../utils/useModalManagement';
import getInterventionFormFields from '../utils/getInterventionFormFields';
import getInterventionDefaultValuesFromQueryData from '../utils/getInterventionDefaultValuesFromQueryData';
import { UseInterventionActionsResult } from '../utils/useInterventionActions';
import getInterventionDefaultValues from '../utils/getInterventionDefaultValues';
import { InterventionViewMode } from '../enums';
import styles from './InterventionFormPage.module.scss';

type Props = {
  useModalManagementResult: UseModalManagementResult;
  useInterventionActionsResult: UseInterventionActionsResult;
  viewMode: InterventionViewMode;
  formRef: FormRefType;
  isCategoriesViewOnly: boolean;
};

export default function InterventionFormPage({
  useModalManagementResult,
  useInterventionActionsResult,
  viewMode,
  formRef,
  isCategoriesViewOnly,
}: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const useSearchListQueriesResult = useSearchListQueries();
  const {
    id,
    mutationError,
    interventionQueryHookResult,
    loading,
    onSubmit,
    onViewChange,
    usersQueryHookResult,
    interventionSitePrefillDataQueryHookResult,
    interventionSiteAreaPrefillDataQueryHookResult,
    interventionAssetPrefillDataQueryHookResult,
    interventionTaskPrefillDataQueryHookResult,
  } = useInterventionActionsResult;
  const { allIds: assigneeIds } = usersQueryHookResult;

  const isCreatePage = viewMode === InterventionViewMode.Add;

  const defaultValues = isCreatePage
    ? getInterventionDefaultValues({
        site: interventionSitePrefillDataQueryHookResult?.data?.site as Site,
        siteArea: interventionSiteAreaPrefillDataQueryHookResult?.data
          ?.siteArea as SiteArea,
        asset: interventionAssetPrefillDataQueryHookResult?.data
          ?.asset as Asset,
        task: interventionTaskPrefillDataQueryHookResult?.data?.task as Task,
      })
    : getInterventionDefaultValuesFromQueryData(
        interventionQueryHookResult.data,
        assigneeIds,
      );

  const fields = getInterventionFormFields({
    interventionId: id,
    t,
    isEditForm: viewMode === InterventionViewMode.Edit,
    getAssetsLoadOptions: useSearchListQueriesResult.getAssetsLoadOptions,
    getInterventionCategoriesLoadOptions:
      useSearchListQueriesResult.getInterventionCategoriesLoadOptions,
    getSiteAreasLoadOptions: useSearchListQueriesResult.getSiteAreasLoadOptions,
    getSitesLoadOptions: useSearchListQueriesResult.getSitesLoadOptions,
    getSupervisorLoadOptions:
      useSearchListQueriesResult.getSupervisorLoadOptions,
    getAssigneeLoadOptions: useSearchListQueriesResult.getAssigneeLoadOptions,
    getTasksLoadOptions: useSearchListQueriesResult.getTasksLoadOptions,
    openCategoryFormModal: useModalManagementResult.openCategoryFormModal,
    isCategoriesViewOnly,
  });

  const actionsComponent = () => (
    <ActionsRow
      className={formStyles.formActions}
      actions={[
        <Button
          onClick={() => navigate(-1)}
          buttonType={ButtonTypes.secondaryOutlined}
          buttonText={t('cancel')}
          leftIcon="close"
          fullWidth
        />,
        <Button
          buttonType={ButtonTypes.primaryFilled}
          buttonText={t(isCreatePage ? 'create' : 'save-changes')}
          leftIcon="check"
          type="submit"
          fullWidth
        />,
      ]}
    />
  );

  return (
    <DashboardContentMaxWidth>
      <PageHeader
        title={t(isCreatePage ? 'create-intervention' : 'edit-intervention')}
      >
        {isCreatePage ? undefined : (
          <Button
            buttonText={t('view')}
            buttonType={ButtonTypes.primaryOutlined}
            leftIcon="visibility"
            onClick={() => onViewChange(InterventionViewMode.View)}
          />
        )}
      </PageHeader>
      <PageBody>
        <WhiteBox verticalPaddings="large">
          <Form
            actionsComponent={actionsComponent}
            actionsComponentProps={{ isCreatePage }}
            defaultValues={defaultValues}
            fields={fields}
            formId="intervention"
            formRef={formRef}
            graphQLErrors={mutationError?.graphQLErrors}
            loading={loading}
            onSubmit={onSubmit}
          />
        </WhiteBox>
        {isCreatePage ? (
          <div />
        ) : (
          <div className={styles.actions}>
            <Button
              buttonText={t('delete-intervention')}
              buttonType={ButtonTypes.negativeOutlined}
              leftIcon="delete"
              leftIconClassName={materialSymbolsOutlined}
              onClick={useModalManagementResult.openInterventionDestroyModal}
            />
          </div>
        )}
      </PageBody>
    </DashboardContentMaxWidth>
  );
}
