import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import _get from 'lodash/get';
import { Attachment } from 'graphql-common';
import DashboardContentMaxWidth from '@lib/layouts/DashboardLayout/DashboardContentMaxWidth/DashboardContentMaxWidth';
import WhiteBox from '@lib/components/WhiteBox/WhiteBox';
import Typography from '@lib/components/Typography/Typography';
import Button, { ButtonTypes } from '@lib/components/Button/Button';
import Chip, { ChipTypes } from '@lib/components/Chip/Chip';
import MaterialIcon from '@lib/components/MaterialIcon/MaterialIcon';
import { materialSymbolsOutlined } from '@lib/enums/common';
import { getFormattedDate } from '@lib/utils/dateTimeHelpers';
import Image from '@lib/components/Image/Image';
import Trans from '@lib/components/Trans/Trans';
import OfflineLabel from '@lib/components/OfflineLabel/OfflineLabel';
import PageBody from '@lib/layouts/DashboardLayout/PageBody/PageBody';
import ConfirmationModal from '@lib/components/ConfirmationModal/ConfirmationModal';
import { UseModalManagementResult } from 'routes/Assets/Asset/utils/useModalManagement';
import { UseActionsResult } from 'routes/Assets/Asset/utils/useActions';
import Modal from '@lib/components/Modal/Modal';
import GetQrCodeForm from 'components/GetQrCodeForm/GetQrCodeForm';
import { UseSearchListQueriesResult } from 'routes/Assets/Asset/utils/useSearchListQueries';
import CircleLoaderWrapper from '@lib/components/CircleLoaderWrapper/CircleLoaderWrapper';
import DashboardTitleHeader from '@lib/layouts/DashboardLayout/DashboardTitleHeader/DashboardTitleHeader';
import { UseAssetActionsResult } from 'routes/Assets/Asset/utils/useAssetActions';
import ViewOnlyChip from '@lib/components/ViewOnlyChip/ViewOnlyChip';
import { IconName } from '@lib/components/Modal/enums';
import TextEditor from '@lib/components/TextEditor/TextEditor';
import styles from './AssetView.module.scss';

type Props = {
  menuBar: JSX.Element;
  useActionsResult: UseActionsResult;
  useAssetActionsResult: UseAssetActionsResult;
  useModalManagementResult: UseModalManagementResult;
  useSearchListQueriesResult: UseSearchListQueriesResult;
  isViewOnly: boolean;
};

export default function AssetView(props: Props) {
  const { t } = useTranslation();

  const {
    menuBar,
    useActionsResult,
    useAssetActionsResult,
    useModalManagementResult,
    useSearchListQueriesResult,
    isViewOnly,
  } = props;
  const { assetQueryHook } = useActionsResult;
  const {
    defaultValues,
    onConfirmedDelete,
    onAssetQrCodeCreate,
    onEditHandler,
    qrCodeCreateMutationResult,
  } = useAssetActionsResult;
  const {
    closeDeleteModal,
    isDeleteModalOpened,
    openGetQrCodeFormModal,
    openDeleteModal,
    closeGetQrCodeFormModal,
    isQrCodeFormModalOpened,
  } = useModalManagementResult;

  const { category, site, siteArea } = defaultValues;
  const name = defaultValues.name as string;
  const code = defaultValues.fullCode as string;
  const status = (_get(defaultValues, 'status') as string) || '-';
  const serviceDate = _get(defaultValues, 'serviceDate') as string;
  const serviceDateFormatted = serviceDate
    ? getFormattedDate(serviceDate)
    : '-';
  const serialNumber = (_get(defaultValues, 'serialNumber') as string) || '-';
  const model = (_get(defaultValues, 'model') as string) || '-';
  const manufacturer = (_get(defaultValues, 'manufacturer') as string) || '-';
  const iconAttached = _get(
    defaultValues,
    'iconAttached',
  ) as unknown as Attachment;
  const iconAttachedCdn = iconAttached?.fileTag;
  const description = _get(defaultValues, 'description') as string;
  const poorWifi = _get(defaultValues, 'poorWifi', false);

  return (
    <>
      <PageBody withoutHeader>
        {menuBar}
        <DashboardContentMaxWidth>
          <CircleLoaderWrapper isLoading={assetQueryHook.loading}>
            <WhiteBox>
              <DashboardTitleHeader
                title={name}
                actions={
                  <>
                    {isViewOnly ? (
                      <ViewOnlyChip key="view" />
                    ) : (
                      <Button
                        buttonText={t('edit')}
                        buttonType={ButtonTypes.primaryOutlined}
                        onClick={onEditHandler}
                        leftIcon="edit"
                        leftIconClassName={materialSymbolsOutlined}
                      />
                    )}
                    <Button
                      buttonText={t('get-qr-code')}
                      buttonType={ButtonTypes.primaryFilled}
                      onClick={openGetQrCodeFormModal}
                      leftIcon="qr_code"
                      leftIconClassName={materialSymbolsOutlined}
                    />
                  </>
                }
              >
                {!!poorWifi && (
                  <div className={styles.offlineLabel}>
                    <OfflineLabel />
                  </div>
                )}
              </DashboardTitleHeader>
              <div className={styles.infoList}>
                <div className={styles.infoItem}>
                  <p className={styles.infoItemLabel}>
                    <MaterialIcon icon="password" />
                    <Typography variant="caption">{t('asset-code')}</Typography>
                  </p>
                  <div className={styles.infoItemContent}>
                    <Typography variant="body">{code}</Typography>
                  </div>
                </div>
                <div className={styles.infoItem}>
                  <p className={styles.infoItemLabel}>
                    <MaterialIcon icon="clock_loader_20" symbolsOutlined />
                    <Typography variant="caption">
                      {t('current-status')}
                    </Typography>
                  </p>
                  <div className={styles.infoItemContent}>
                    <Typography variant="body">{status}</Typography>
                  </div>
                </div>
                <div className={styles.infoItem}>
                  <p className={styles.infoItemLabel}>
                    <MaterialIcon icon="category" symbolsOutlined />
                    <Typography variant="caption">{t('category')}</Typography>
                  </p>
                  <div className={styles.infoItemContent}>
                    {category ? (
                      <Chip
                        type={_get(category, 'color') as unknown as ChipTypes}
                        label={_get(category, 'label')}
                        leftIcon="circle"
                      />
                    ) : (
                      <Typography variant="body">-</Typography>
                    )}
                  </div>
                </div>
                <div className={styles.infoItem}>
                  <p className={styles.infoItemLabel}>
                    <MaterialIcon icon="location_on" symbolsOutlined />
                    <Typography variant="caption">
                      {t('site-comma-area')}
                    </Typography>
                  </p>
                  <div
                    className={classNames(
                      styles.infoItemContent,
                      styles.infoItemContentRow,
                    )}
                  >
                    {site ? (
                      <Chip
                        type={ChipTypes.chipGray}
                        label={_get(site, 'label')}
                      />
                    ) : (
                      <Typography variant="body">-</Typography>
                    )}
                    {siteArea ? (
                      <Chip
                        type={ChipTypes.chipGray}
                        label={_get(siteArea, 'label')}
                      />
                    ) : null}
                  </div>
                </div>
                <div className={styles.infoItem}>
                  <p className={styles.infoItemLabel}>
                    <MaterialIcon icon="calendar_today" symbolsOutlined />
                    <Typography variant="caption">
                      {t('put-into-service')}
                    </Typography>
                  </p>
                  <div className={styles.infoItemContent}>
                    <Typography variant="body">
                      {serviceDateFormatted}
                    </Typography>
                  </div>
                </div>
                <div className={styles.infoItem}>
                  <p className={styles.infoItemLabel}>
                    <MaterialIcon icon="barcode" symbolsOutlined />
                    <Typography variant="caption">
                      {t('serial-number')}
                    </Typography>
                  </p>
                  <div className={styles.infoItemContent}>
                    <Typography variant="body">{serialNumber}</Typography>
                  </div>
                </div>
                <div className={styles.infoItem}>
                  <p className={styles.infoItemLabel}>
                    <MaterialIcon icon="details" />
                    <Typography variant="caption">{t('model')}</Typography>
                  </p>
                  <div className={styles.infoItemContent}>
                    <Typography variant="body">{model}</Typography>
                  </div>
                </div>
                <div className={styles.infoItem}>
                  <p className={styles.infoItemLabel}>
                    <MaterialIcon icon="factory" symbolsOutlined />
                    <Typography variant="caption">
                      {t('manufacturer')}
                    </Typography>
                  </p>
                  <div className={styles.infoItemContent}>
                    <Typography variant="body">{manufacturer}</Typography>
                  </div>
                </div>
                <div className={styles.infoItem}>
                  <p className={styles.infoItemLabel}>
                    <MaterialIcon icon="image" symbolsOutlined />
                    <Typography variant="caption">{t('icon')}</Typography>
                  </p>
                  <div className={styles.infoItemContent}>
                    {iconAttachedCdn ? (
                      <Image src={iconAttachedCdn} className={styles.icon} />
                    ) : (
                      <Typography variant="body">-</Typography>
                    )}
                  </div>
                </div>
                {!!description && (
                  <>
                    <div className={styles.infoItem}>
                      <p className={styles.infoItemLabel}>
                        <MaterialIcon icon="description" symbolsOutlined />
                        <Typography variant="caption">
                          {t('description')}
                        </Typography>
                      </p>
                    </div>
                    <TextEditor
                      readOnly
                      value={description}
                      className={styles.description}
                    />
                  </>
                )}
              </div>
            </WhiteBox>
            {!isViewOnly && (
              <div className={styles.actions}>
                <Button
                  buttonText={t('delete-asset')}
                  buttonType={ButtonTypes.negativeOutlined}
                  leftIcon="delete"
                  leftIconClassName={materialSymbolsOutlined}
                  onClick={openDeleteModal}
                />
              </div>
            )}
          </CircleLoaderWrapper>
        </DashboardContentMaxWidth>
      </PageBody>
      <Modal
        isOpen={isQrCodeFormModalOpened}
        onClose={closeGetQrCodeFormModal}
        title={t('get-asset-qr-code')}
      >
        <GetQrCodeForm
          loading={false}
          onCancel={closeGetQrCodeFormModal}
          graphQLErrors={qrCodeCreateMutationResult.error?.graphQLErrors}
          onSubmit={onAssetQrCodeCreate}
          getRecipientsLoadOptions={
            useSearchListQueriesResult.getUsersLoadOptions
          }
        />
      </Modal>
      <ConfirmationModal
        confirmButtonLabel={t('yes-delete')}
        description={
          <Trans
            i18nKey="asset-delete-text"
            values={{ name: defaultValues?.name }}
          />
        }
        icon={IconName.Delete}
        isOpen={isDeleteModalOpened}
        onClose={closeDeleteModal}
        onConfirm={onConfirmedDelete}
        title={t('are-you-sure')}
      />
    </>
  );
}
