import React from 'react';
import _get from 'lodash/get';
import { CellContentProps, BaseItem } from '@lib/components/Table/types';

function CellContent<T extends BaseItem>(
  props: CellContentProps<T>,
): JSX.Element {
  const {
    column: { Cell, accessor, accessorFn },
    item,
    tableProps,
  } = props;
  let accessorContent = '' as React.ReactNode;
  if (accessor) {
    accessorContent = _get(item, accessor, '') as React.ReactNode;
  } else if (accessorFn) {
    accessorContent = accessorFn(props);
  }
  if (Cell) {
    return <Cell item={item} value={accessorContent} tableProps={tableProps} />;
  }
  return <div>{accessorContent}</div>;
}

export default CellContent;
