import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import _get from 'lodash/get';
import {
  Task,
  TaskDeadlineMissedReasonEnum,
  TaskStatusEnum,
  useDashboardTaskCounterQuery,
  useDashboardTaskScheduleQuery,
  useTaskBulkDeadlineMissedReasonUpdateMutation,
  useTaskBulkSchedulingMutation,
  useTaskBulkUpdateStatusMutation,
  useTaskDestroyMutation,
} from 'graphql-common';
import onDestroyListItemCallBack from '@lib/utils/onDestroyListItemCallBack';
import useDashboardTasksQueryHook from 'utils/fetch/useDashboardTasksQueryHook';
import { UseBulkSelectResult } from '@lib/hooks/useTableItemsBulkSelect';
import tableItemsBulkAction from '@lib/utils/tableItemsBulkAction';
import getAllTableItemsIds from '@lib/utils/getAllTableItemsIds';
import usePaginationParams from '@lib/hooks/usePaginationParams';
import { showGraphQLErrors } from '@lib/utils/graphQLErrors';
import useLoadingState from '@lib/hooks/useLoadingState';
import useSearchParam from '@lib/hooks/useSearchParam';
import toast from '@lib/components/Toast/Toast';
import { Values } from '@lib/interfaces/form';
import { getPreparedTaskScheduleDataFromValues } from 'routes/Task/utils/utils';
import { APP_URLS } from 'constants/urls';
import { UseModalManagementResult } from './useModalManagement';

interface UseActionsProps {
  id?: string | null;
  isApprovals?: boolean;
  tabParam?: string;
  useBulkSelectResult: UseBulkSelectResult<Task>;
  useModalManagementResult: UseModalManagementResult;
}

export interface UseActionsResult {
  countersLoading: boolean;
  loadingState: boolean;
  myApprovalsCounter: number;
  onArchiveHandler: (idValue?: string) => void;
  onBulkDeadlineMissedReasonChangeHandler: (
    deadlineMissedReason: TaskDeadlineMissedReasonEnum,
  ) => void;
  onCloseScheduleModal: () => void;
  onConfirmedBulkArchive: () => void;
  onConfirmedDelete: () => void;
  onDeadlineMissedReasonChangeHandler: (
    id: string,
    deadlineMissedReason: TaskDeadlineMissedReasonEnum,
  ) => void;
  onDeleteHandler: (v?: Task) => void;
  onDuplicateHandler: (idValue?: string) => void;
  onEditHandler: (idValue?: string) => void;
  onEditScheduleHandler: (v?: Task) => void;
  onRescheduleHandler: (values?: Values) => void;
  onStatusBulkChangeHandler: (status: TaskStatusEnum) => void;
  onStatusChangeHandler: (id: string, status: TaskStatusEnum) => void;
  onTaskArchiveOpen: (v?: Task) => void;
  onViewHandler: (idValue?: string) => void;
  tabParam?: string;
  taskBulkDeadlineMissedReasonUpdateError: any;
  taskBulkDeadlineMissedReasonUpdateLoading: boolean;
  taskBulkSchedulingError: any;
  taskBulkSchedulingLoading: boolean;
  taskBulkUpdateStatusError: any;
  taskBulkUpdateStatusLoading: boolean;
  taskCounterData: any;
  taskScheduleData: Task | undefined;
  taskScheduleLoading: boolean;
  tasks: Task[];
  tasksError: any;
  tasksFirstLoading: boolean;
  tasksLoading: boolean;
  totalTasksCount: number;
}

export default function useActions(props: UseActionsProps): UseActionsResult {
  const {
    isApprovals,
    tabParam,
    useBulkSelectResult,
    useModalManagementResult,
  } = props;
  const { loadingState, setLoadingState } = useLoadingState();
  const {
    closeArchiveModal,
    closeDeleteModal,
    closeScheduleModal,
    isScheduleModalOpened,
    openArchiveModal,
    openDeleteModal,
    openScheduleModal,
  } = useModalManagementResult;
  const {
    handleSelectSingleTableItem,
    handleUnselectAllTableItems,
    selectedSingleTableItem,
    selectedTableItems,
  } = useBulkSelectResult;

  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchQuery] = useSearchParam();
  const { search, pathname } = useLocation();
  const [paginationParams] = usePaginationParams();

  // Counters
  const countersQuery = useDashboardTaskCounterQuery();
  const countersLoading = _get(countersQuery, 'loading');
  const myApprovalsCounter = _get(
    countersQuery,
    'data.myApprovals.metadata.totalCount',
    0,
  );

  // Tasks
  const {
    fetchTaskIds,
    refetchTasks,
    taskCounterData,
    tasks,
    tasksError,
    tasksFirstLoading,
    tasksLoading,
    totalPages,
    totalTasksCount,
  } = useDashboardTasksQueryHook({
    isApprovals,
    searchQuery,
    paginationParams,
    tabParam,
  });

  let taskScheduleQueryId;
  if (selectedTableItems?.length === 1) {
    taskScheduleQueryId = _get(selectedTableItems, [0, 'id']);
  }
  if (selectedSingleTableItem?.id) {
    taskScheduleQueryId = selectedSingleTableItem?.id;
  }
  const skipTaskScheduleQuery = !taskScheduleQueryId || !isScheduleModalOpened;
  const taskScheduleQuery = useDashboardTaskScheduleQuery({
    skip: skipTaskScheduleQuery,
    variables: { id: taskScheduleQueryId || '' },
    fetchPolicy: 'no-cache',
  });
  const { loading: taskScheduleLoading, refetch: taskScheduleRefetch } =
    taskScheduleQuery;
  const taskScheduleData = _get(taskScheduleQuery, 'data.data') as Task;

  const onGetAllTaskIds = () =>
    getAllTableItemsIds<Task>({
      fetchIds: fetchTaskIds,
      useBulkSelectResult,
    });

  // Bulk Scheduling
  const [taskBulkSchedulingMutation, taskBulkSchedulingResult] =
    useTaskBulkSchedulingMutation({
      onCompleted: ({ data: responseData }) => {
        if (responseData) {
          toast({ content: t('task-frequency-successfully-updated') });
        }
        closeScheduleModal();
        refetchTasks();
        if (!skipTaskScheduleQuery) taskScheduleRefetch();
        handleSelectSingleTableItem(null);
        handleUnselectAllTableItems();
      },
      onError: (error) => showGraphQLErrors(error, t),
    });
  const { error: taskBulkSchedulingError, loading: taskBulkSchedulingLoading } =
    taskBulkSchedulingResult;

  // Bulk Update Status
  const [taskBulkUpdateStatusMutation, taskBulkUpdateStatusResult] =
    useTaskBulkUpdateStatusMutation({
      onCompleted: ({ data: responseData }, clientOptions) => {
        if (responseData) {
          toast({ content: t('tasks-status-successfully-updated') });
        }
        closeScheduleModal();
        handleUnselectAllTableItems();
        onDestroyListItemCallBack({
          callbackOnNavigate: () => setLoadingState(false),
          collection: tasks,
          collectionForDestroy: clientOptions?.variables?.ids || [],
          navigate,
          pathname,
          refetch: () => {
            refetchTasks().then(() => {
              setLoadingState(false);
            });
          },
          search,
          shouldCheckLastPage: false,
          totalPages,
        });
      },
      onError: (error) => {
        setLoadingState(false);
        showGraphQLErrors(error, t);
      },
    });
  const {
    error: taskBulkUpdateStatusError,
    loading: taskBulkUpdateStatusLoading,
  } = taskBulkUpdateStatusResult;

  // Task deadline missed reason change
  const [
    taskBulkDeadlineMissedReasonUpdateMutation,
    taskBulkDeadlineMissedReasonUpdateResult,
  ] = useTaskBulkDeadlineMissedReasonUpdateMutation({
    onCompleted: ({ data: responseData }) => {
      if (responseData) {
        toast({
          content: t('task-deadline-missed-reason-successfully-updated'),
        });
      }
      refetchTasks().then(() => {
        setLoadingState(false);
      });
      handleSelectSingleTableItem(null);
      handleUnselectAllTableItems();
    },
    onError: (error) => {
      setLoadingState(false);
      showGraphQLErrors(error, t);
    },
  });
  const {
    error: taskBulkDeadlineMissedReasonUpdateError,
    loading: taskBulkDeadlineMissedReasonUpdateLoading,
  } = taskBulkDeadlineMissedReasonUpdateResult;

  // Task destroy
  const [destroyTaskMutation] = useTaskDestroyMutation({
    onCompleted: (_response, clientOptions) => {
      closeDeleteModal();
      handleUnselectAllTableItems();
      onDestroyListItemCallBack({
        collection: tasks,
        collectionForDestroy:
          clientOptions?.variables?.input?.params?.ids || [],
        navigate,
        pathname,
        refetch: refetchTasks,
        search,
        totalPages,
      });
    },
    onError: (error) => showGraphQLErrors(error, t),
  });

  // Actions
  const onViewHandler = (idValue?: string) => {
    if (idValue) {
      navigate(
        APP_URLS.dashboard.tasks.view.getDynamicPath({
          pathParts: {
            id: `${idValue}`,
          },
        }),
      );
    }
  };

  const onEditHandler = (idValue?: string) => {
    if (idValue) {
      navigate(
        APP_URLS.dashboard.tasks.edit.getDynamicPath({
          pathParts: {
            id: `${idValue}`,
          },
        }),
      );
    }
  };

  const onDuplicateHandler = (idValue?: string) => {
    if (idValue) {
      navigate(
        APP_URLS.dashboard.tasks.duplicate.getDynamicPath({
          pathParts: {
            id: idValue,
          },
        }),
      );
    }
  };

  const onArchiveHandler = (idValue?: string) => {
    if (idValue) {
      setLoadingState(true);
      taskBulkUpdateStatusMutation({
        variables: {
          ids: [idValue],
          attributes: { status: TaskStatusEnum.Archived },
        },
      });
    }
  };

  const onEditScheduleHandler = (v?: Task) => {
    if (v) {
      handleSelectSingleTableItem(v);
      openScheduleModal();
    }
  };

  const onRescheduleHandler = (values?: Values) => {
    if (values) {
      const action = (ids: string[]) => {
        taskBulkSchedulingMutation({
          variables: {
            ids,
            attributes: getPreparedTaskScheduleDataFromValues(values),
          },
        });
      };
      tableItemsBulkAction<Task>({
        action,
        useBulkSelectResult,
        onGetAllIds: onGetAllTaskIds,
      });
    }
  };

  const onDeleteHandler = (v?: Task) => {
    if (v) {
      handleSelectSingleTableItem(v);
      openDeleteModal();
    }
  };

  const onDestroyHandler = () => {
    if (selectedSingleTableItem) {
      destroyTaskMutation({
        variables: { id: selectedSingleTableItem.id },
      });
    }
  };

  const onConfirmedDelete = () => {
    if (closeDeleteModal) closeDeleteModal();
    toast({
      content: t('destroy-task-success'),
      closeCallback: onDestroyHandler,
    });
  };

  const onStatusChangeHandler = (id: string, status: TaskStatusEnum) => {
    if (id && status) {
      setLoadingState(true);
      taskBulkUpdateStatusMutation({
        variables: { ids: [id], attributes: { status } },
      });
    }
  };

  const onStatusBulkChangeHandler = (status: TaskStatusEnum) => {
    const action = (ids: string[]) => {
      setLoadingState(true);
      taskBulkUpdateStatusMutation({
        variables: {
          ids,
          attributes: { status },
        },
      });
    };
    tableItemsBulkAction<Task>({
      action,
      useBulkSelectResult,
      onGetAllIds: onGetAllTaskIds,
    });
  };

  const onDeadlineMissedReasonChangeHandler = (
    id: string,
    deadlineMissedReason: TaskDeadlineMissedReasonEnum,
  ) => {
    if (id && deadlineMissedReason) {
      setLoadingState(true);
      taskBulkDeadlineMissedReasonUpdateMutation({
        variables: { ids: [id], attributes: { deadlineMissedReason } },
      });
    }
  };

  const onBulkDeadlineMissedReasonChangeHandler = (
    deadlineMissedReason: TaskDeadlineMissedReasonEnum,
  ) => {
    setLoadingState(true);
    const action = (ids: string[]) => {
      taskBulkDeadlineMissedReasonUpdateMutation({
        variables: {
          ids,
          attributes: { deadlineMissedReason },
        },
      });
    };
    tableItemsBulkAction<Task>({
      action,
      useBulkSelectResult,
      onGetAllIds: onGetAllTaskIds,
    });
  };

  const onBulkArchiveHandler = () => {
    const action = (ids: string[]) => {
      setLoadingState(true);
      taskBulkUpdateStatusMutation({
        variables: {
          ids,
          attributes: { status: TaskStatusEnum.Archived },
        },
      });
    };
    tableItemsBulkAction<Task>({
      action,
      useBulkSelectResult,
      onGetAllIds: onGetAllTaskIds,
    });
  };

  const onTaskArchiveOpen = (v?: Task) => {
    if (v?.id) {
      handleSelectSingleTableItem(v);
      openArchiveModal();
    }
  };

  const onConfirmedBulkArchive = () => {
    if (closeArchiveModal) closeArchiveModal();
    toast({
      content: t('archive-task-success'),
      closeCallback: onBulkArchiveHandler,
    });
  };

  const onCloseScheduleModal = () => {
    if (closeScheduleModal) closeScheduleModal();
    taskBulkSchedulingResult.reset();
  };

  return {
    countersLoading,
    loadingState,
    myApprovalsCounter,
    onArchiveHandler,
    onBulkDeadlineMissedReasonChangeHandler,
    onCloseScheduleModal,
    onConfirmedBulkArchive,
    onConfirmedDelete,
    onDeadlineMissedReasonChangeHandler,
    onDeleteHandler,
    onDuplicateHandler,
    onEditHandler,
    onEditScheduleHandler,
    onRescheduleHandler,
    onStatusBulkChangeHandler,
    onStatusChangeHandler,
    onTaskArchiveOpen,
    onViewHandler,
    tabParam,
    taskBulkDeadlineMissedReasonUpdateError,
    taskBulkDeadlineMissedReasonUpdateLoading,
    taskBulkSchedulingError,
    taskBulkSchedulingLoading,
    taskBulkUpdateStatusError,
    taskBulkUpdateStatusLoading,
    taskCounterData,
    taskScheduleData,
    taskScheduleLoading,
    tasks,
    tasksError,
    tasksFirstLoading,
    tasksLoading,
    totalTasksCount,
  };
}
