import { BaseItem, TableProps } from '@lib/components/Table/types';

export const checkHasSelectColumn = <T extends BaseItem>({
  useBulkSelectResult,
  actionsForSelected,
}: TableProps<T>): boolean =>
  !!useBulkSelectResult && !!actionsForSelected?.length;

export const checkHasActionsColumn = <T extends BaseItem>({
  actions,
}: TableProps<T>): boolean => !!actions?.length;

export const checkHasActionsMenuColumn = <T extends BaseItem>({
  actionsMenu,
}: TableProps<T>): boolean => !!actionsMenu?.length;

export const getColumnsCount = <T extends BaseItem>(
  props: TableProps<T>,
): number => {
  const { columns } = props;
  const hasSelectColumn = checkHasSelectColumn(props);
  const hasActionsColumn = checkHasActionsColumn(props);
  return (
    columns.length + (hasSelectColumn ? 1 : 0) + (hasActionsColumn ? 1 : 0)
  );
};

export const isItemsMatch = <T extends BaseItem>(a: T, b: T) => {
  if (a.id !== undefined && b.id !== undefined) return a.id === b.id;
  return a === b;
};
