import React, { forwardRef, memo } from 'react';
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import CheckBox from '@lib/components/CheckBox/CheckBox';
import Typography from '@lib/components/Typography/Typography';
import {
  CheckboxButtonGridValue,
  CheckboxButtonGroupOption,
} from '@lib/components/CheckboxButtonGrid/types';
import isValueExists from '@lib/components/CheckboxButtonGrid/utils/isValueExists';
import getNewValues from '@lib/components/CheckboxButtonGrid/utils/getNewValues';
import RadioButton from '@lib/components/RadioButton/RadioButton';
import styles from './CheckboxButtonGrid.module.scss';

export type CheckboxButtonGridProps = {
  disabled?: boolean;
  itemClass?: string;
  label?: React.ReactNode;
  limitToOneRespPerColumn?: boolean;
  limitToOneRespPerRow?: boolean;
  name?: string;
  onChange?: (v: CheckboxButtonGridValue) => void;
  options: CheckboxButtonGroupOption[];
  radioButtonComponentProps?: { [key: string]: unknown };
  value?: CheckboxButtonGridValue;
  wrapClass?: string;
};

const CheckboxButtonGrid = forwardRef(
  (
    props: CheckboxButtonGridProps,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ref: React.ForwardedRef<HTMLInputElement>,
  ) => {
    const {
      itemClass,
      label,
      limitToOneRespPerColumn,
      limitToOneRespPerRow,
      name: fieldName,
      onChange,
      options,
      radioButtonComponentProps = {},
      value: fieldValue = [],
      wrapClass,
      disabled,
      ...rest
    } = props;
    const hasLabel = !!label && typeof label === 'string';
    const hasCustomLabel = !!label && typeof label !== 'string';

    const FieldComponent =
      limitToOneRespPerColumn || limitToOneRespPerRow ? RadioButton : CheckBox;

    // Split options into rows and columns
    const rows = options.filter((option) => option?.group === 'ROWS');
    const columns = options.filter((option) => option?.group === 'COLUMNS');

    const onChangeHandler = (
      row: CheckboxButtonGroupOption,
      column: CheckboxButtonGroupOption,
    ) => {
      if (onChange) {
        onChange(
          getNewValues(
            fieldValue,
            row,
            column,
            limitToOneRespPerRow,
            limitToOneRespPerColumn,
          ),
        );
      }
    };

    return (
      <div
        className={classNames(styles.groupRoot, {
          [styles.rootWithLabel]: hasLabel,
        })}
      >
        {hasLabel && <span className={styles.groupLabel}>{label}</span>}
        {hasCustomLabel && label}
        <div className={styles.tableWrap}>
          <table className={classNames(styles.table, wrapClass)}>
            <thead>
              <tr>
                <th> </th>
                {columns.map((column) => (
                  <th key={uuidv4()} className={styles.headerLabelColumn}>
                    <Typography variant="caption">
                      {column?.label || column.values?.[0]}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {rows.map((row) => (
                <tr key={uuidv4()}>
                  <td className={styles.headerLabelColumn}>
                    <div className={styles.tdContent}>
                      <Typography variant="caption">
                        {row?.label || row.values?.[0]}
                      </Typography>
                    </div>
                  </td>
                  {columns.map((column) => (
                    <td
                      key={uuidv4()}
                      className={classNames(styles.itemColumn, itemClass)}
                    >
                      <div className={styles.tdContent}>
                        <FieldComponent
                          {...rest}
                          {...radioButtonComponentProps}
                          id={`row-${row.id}-column-${column.id}`}
                          onChange={() => onChangeHandler(row, column)}
                          checked={isValueExists(fieldValue, row, column)}
                          name={fieldName}
                          disabled={disabled}
                          wrapperClassName={styles.radioButton}
                        />
                      </div>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  },
);

export default memo(CheckboxButtonGrid);
