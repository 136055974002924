import { FetchResult } from '@apollo/client';
import { TFunction } from 'i18next';
import * as Yup from 'yup';
import { AuthenticationConfirmationsResendEmailConfirmationMutation } from 'graphql-common';
import { FieldItem } from '@lib/interfaces/form';
import AvatarUploader from '@lib/components/AvatarUploader/AvatarUploader';
import UserFieldNames from '@lib/enums/fieldNames/userFieldNames';
import Input, { InputProps } from '@lib/components/Input/Input';
import { EMAIL_REGEX, MAX_TEXT_FIELD_LENGTH } from '@lib/enums/form';
import { transformToUpperCase } from '@lib/utils/formHelpers';
import { InputTypes } from '@lib/components/Input/enums';
import UnconfirmedEmailBlock from '../UnconfirmedEmailBlock';
import { OnUpdateProfile } from '../types';
import styles from '../ProfileForm.module.scss';

type ProfileFormFieldsArgs = {
  onResendConfirmationHandler: () => Promise<
    FetchResult<AuthenticationConfirmationsResendEmailConfirmationMutation>
  >;
  onSubmit: OnUpdateProfile;
  countriesCodes: string[];
  t: TFunction<'translation', undefined>;
  unconfirmedEmail?: string | null;
};

export default function getProfileFormFields({
  onResendConfirmationHandler,
  onSubmit,
  countriesCodes,
  t,
  unconfirmedEmail,
}: ProfileFormFieldsArgs): FieldItem[] {
  return [
    {
      name: 'avatarAttached',
      element: AvatarUploader,
      formItemClassName: styles.avatarBlock,
    },
    {
      name: UserFieldNames.FirstName,
      label: t('first-name'),
      element: Input,
      validation: Yup.string()
        .trim()
        .max(MAX_TEXT_FIELD_LENGTH, t('max-text-length-field-error'))
        .required(t('required-field-error')),
      formItemClassName: styles.column,
    },
    {
      name: UserFieldNames.LastName,
      label: t('last-name'),
      element: Input,
      validation: Yup.string()
        .trim()
        .max(MAX_TEXT_FIELD_LENGTH, t('max-text-length-field-error'))
        .required(t('required-field-error')),
      formItemClassName: styles.column,
      componentProps: {
        beforeValueChange: transformToUpperCase,
      },
    },
    {
      name: UserFieldNames.Email,
      label: t('work-email'),
      description: t('my-email-description'),
      element: Input,
      validation: Yup.string()
        .trim()
        .max(MAX_TEXT_FIELD_LENGTH, t('max-text-length-field-error'))
        .matches(EMAIL_REGEX, t('email-field-error'))
        .required(t('required-field-error')),
    },
    {
      name: UserFieldNames.UnconfirmedEmail,
      customComponent: unconfirmedEmail ? UnconfirmedEmailBlock : undefined,
      customComponentProps: {
        unconfirmedEmail,
        onResendConfirmationHandler,
        onSubmit,
      },
    },
    {
      name: UserFieldNames.PhoneNumber,
      label: t('phone-number'),
      element: Input,
      validation: Yup.object()
        .shape({
          country: Yup.string(),
          number: Yup.string().required(t('required-field-error')),
        })
        .required(t('required-field-error')),
      componentProps: {
        type: InputTypes.tel,
        onlyCountries: countriesCodes,
      } as InputProps,
      formItemClassName: styles.column,
    },
    {
      name: UserFieldNames.PersonalId,
      label: t('personal-id'),
      element: Input,
      validation: Yup.string()
        .trim()
        .max(MAX_TEXT_FIELD_LENGTH, t('max-text-length-field-error'))
        .nullable(),
      formItemClassName: styles.column,
    },
  ];
}
