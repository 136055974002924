import { GroupEnum } from 'graphql-common';

export default function removeValidityHintConfigItem(
  position: number,
  group: GroupEnum,
  validityHintConfig: object,
) {
  const newConfig = {};
  Object.entries(validityHintConfig).forEach(([key, value]) => {
    const [rowIndex, columnIndex] = key.split('-').map(Number);
    if (group === GroupEnum.Rows && rowIndex !== position) {
      newConfig[key] = value;
    } else if (group === GroupEnum.Columns && columnIndex !== position) {
      newConfig[key] = value;
    }
  });
  return newConfig;
}
