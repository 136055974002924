import React from 'react';
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import Checkbox from '@lib/components/CheckBox/CheckBox';
import useOrdering from '@lib/hooks/useOrdering';
import { BaseItem, TableProps } from '@lib/components/Table/types';
import { GetUrlParams } from '@lib/enums/urls';
import { UseBulkSelectResult } from '@lib/hooks/useTableItemsBulkSelect';
import TableHeadCell from '@lib/components/Table/components/TableHead/TableHeadCell';
import styles from './TableHead.module.scss';

type Props = {
  hasActionsColumn: boolean;
  hasActionsMenuColumn: boolean;
  hasSelectColumn: boolean;
  isSelectAllCheckboxChecked: boolean;
  isSelectAllCheckboxIndeterminate: boolean;
};

function TableHead<T extends BaseItem>(props: TableProps<T> & Props) {
  const {
    borderedCells,
    columns,
    hasActionsColumn,
    hasActionsMenuColumn,
    hasSelectColumn,
    isSelectAllCheckboxChecked,
    isSelectAllCheckboxIndeterminate,
    onSortChange,
    orderingDirectionKey = GetUrlParams.OrderingDirection,
    orderingFieldKey = GetUrlParams.OrderingField,
    orderingState,
    useBulkSelectResult = {} as UseBulkSelectResult<T>,
  } = props;
  const { handleSelectAllTableItems, handleUnselectAllTableItems } =
    useBulkSelectResult;

  const { orderingDirection, orderingField, onOrderingChange } = useOrdering({
    onSortChange,
    orderingDirectionKey,
    orderingFieldKey,
    orderingState,
  });

  const handleSelectedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (hasSelectColumn) {
      if (isSelectAllCheckboxIndeterminate) {
        handleUnselectAllTableItems();
      } else if (event.target.checked) {
        handleSelectAllTableItems();
      } else {
        handleUnselectAllTableItems();
      }
    }
  };

  return (
    <thead
      key={columns.length}
      className={classNames(styles.head, {
        [styles.tableWithBorderedCells]: borderedCells,
      })}
    >
      <tr>
        {hasSelectColumn && (
          <th className={styles.selectCell}>
            <Checkbox
              id="table-head-checkbox"
              name="table-head-checkbox"
              checked={isSelectAllCheckboxChecked}
              indeterminate={isSelectAllCheckboxIndeterminate}
              onChange={handleSelectedChange}
              wrapperClassName={styles.selectCellCheckbox}
            />
          </th>
        )}
        {columns.map((column, index) => (
          <TableHeadCell
            key={uuidv4()}
            column={column}
            index={index}
            onOrderingChange={onOrderingChange}
            orderingDirection={orderingDirection}
            orderingField={orderingField}
          />
        ))}
        {(hasActionsColumn || hasActionsMenuColumn) && (
          <th className={styles.actionsCell}> </th>
        )}
      </tr>
    </thead>
  );
}

export default TableHead;
