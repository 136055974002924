import React from 'react';
import { useTranslation } from 'react-i18next';
import PageBody from '@lib/layouts/DashboardLayout/PageBody/PageBody';
import DashboardContentWithRightSidebar from '@lib/layouts/DashboardLayout/DashboardContentWithRightSidebar/DashboardContentWithRightSidebar';
import CircleLoader from '@lib/components/CircleLoader/CircleLoader';
import { UseInterventionActionActionsResult } from 'routes/Intervention/utils/useInterventionActionActions';
import WhiteBox from '@lib/components/WhiteBox/WhiteBox';
import DashboardTitleHeader from '@lib/layouts/DashboardLayout/DashboardTitleHeader/DashboardTitleHeader';
import Button, {
  ButtonSizes,
  ButtonTypes,
} from '@lib/components/Button/Button';
import InterventionActions from 'routes/Intervention/InterventionActions/InterventionActions';
import InterventionInfo from '../InterventionInfo/InterventionInfo';
import { UseInterventionActionsResult } from '../utils/useInterventionActions';
import { UseModalManagementResult } from '../utils/useModalManagement';
import styles from './InterventionActionViewPage.module.scss';

type Props = {
  useModalManagementResult: UseModalManagementResult;
  useInterventionActionsResult: UseInterventionActionsResult;
  useInterventionActionActionsResult: UseInterventionActionActionsResult;
  isViewOnly?: boolean;
};

export default function InterventionActionViewPage({
  useModalManagementResult,
  useInterventionActionsResult,
  useInterventionActionActionsResult,
  isViewOnly,
}: Props) {
  const { t } = useTranslation();

  const { interventionQueryHookResult } = useInterventionActionsResult;
  const { interventionActionQueryHookResult, onOpenInterventionActions } =
    useInterventionActionActionsResult;
  const { firstLoading, data: intervention } = interventionQueryHookResult;

  const sidebar = (
    <InterventionInfo
      useModalManagementResult={useModalManagementResult}
      useInterventionActionsResult={useInterventionActionsResult}
      isViewOnly={isViewOnly}
    />
  );

  return (
    <DashboardContentWithRightSidebar
      sidebar={sidebar}
      useSidebarHeader
      toggleBtnTop={62.5}
    >
      <PageBody withoutHeader>
        {firstLoading ? (
          <CircleLoader />
        ) : (
          <div>
            <div className={styles.backBtnWrap}>
              <Button
                buttonText={t('all-actions')}
                leftIcon="arrow_back"
                buttonType={ButtonTypes.primaryOutlined}
                buttonSize={ButtonSizes.small}
                onClick={() => onOpenInterventionActions()}
              />
            </div>
            <WhiteBox>
              <DashboardTitleHeader title={intervention.name} />
              <InterventionActions
                collection={[interventionActionQueryHookResult.data]}
                totalCount={1}
                useInterventionActionsResult={useInterventionActionsResult}
                useModalManagementResult={useModalManagementResult}
                isViewOnly={!!isViewOnly}
              />
            </WhiteBox>
          </div>
        )}
      </PageBody>
    </DashboardContentWithRightSidebar>
  );
}
