import React from 'react';
import Form from '@lib/components/ReactHookForm/FormContainer';
import { useTranslation } from 'react-i18next';
import { GraphQLErrors } from '@apollo/client/errors';
import { Values } from '@lib/interfaces/form';
import { RadioButtonGroupOption } from '@lib/components/RadioButtonGroup/RadioButtonGroup';
import { FieldNames, FormType } from 'components/GetQrCodeForm/types';
import { GetLoadOptions } from '@lib/components/Select/types';
import { getFormFields } from './utils';
import GetQrCodeFormActions from './GetQrCodeFormActions';

interface FormProps {
  graphQLErrors?: GraphQLErrors;
  loading: boolean;
  onCancel: () => void;
  onSubmit: (values: Values) => void;
  selectedItemsCount?: number;
  getRecipientsLoadOptions: GetLoadOptions;
}

const formTypeOptions: RadioButtonGroupOption[] = [
  { value: FormType.download, labelKey: 'download' },
  { value: FormType.email, labelKey: 'send-via-email' },
];

const defaultValues = {
  [FieldNames.formType]: formTypeOptions[0],
  [FieldNames.fileFormat]: undefined,
  [FieldNames.pageSize]: undefined,
  [FieldNames.userId]: undefined,
  [FieldNames.message]: '',
} as Values;

function GetQrCodeForm(props: FormProps) {
  const {
    graphQLErrors,
    loading,
    onCancel,
    onSubmit,
    selectedItemsCount,
    getRecipientsLoadOptions,
  } = props;
  const { t } = useTranslation();
  const fields = getFormFields({
    getRecipientsLoadOptions,
    t,
    selectedItemsCount,
    formTypeOptions,
  });
  const onSubmitHandler = (values: Values) => onSubmit(values);
  return (
    <div>
      <Form
        formId="qrcode"
        defaultValues={defaultValues}
        onSubmit={onSubmitHandler}
        fields={fields}
        actionsComponent={GetQrCodeFormActions}
        actionsComponentProps={{
          loading,
          onCancel,
        }}
        graphQLErrors={graphQLErrors}
        loading={loading}
        persistForm
      />
    </div>
  );
}

export default GetQrCodeForm;
