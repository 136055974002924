import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  ComponentEnum,
  Intervention,
  InterventionScopeNameEnum,
  LevelEnum,
} from 'graphql-common';
import { UseBulkSelectResult } from '@lib/hooks/useTableItemsBulkSelect';
import ListEmptyState from '@lib/components/ListEmptyState/ListEmptyState';
import Trans from '@lib/components/Trans/Trans';
import Button, { ButtonTypes } from '@lib/components/Button/Button';
import { materialSymbolsOutlined } from '@lib/enums/common';
import ErrorPage from '@lib/components/ErrorPage/ErrorPage';
import getStatusFromError from '@lib/utils/getStatusFromError';
import PageBody from '@lib/layouts/DashboardLayout/PageBody/PageBody';
import CircleLoader from '@lib/components/CircleLoader/CircleLoader';
import WhiteBox from '@lib/components/WhiteBox/WhiteBox';
import DashboardTitleHeader from '@lib/layouts/DashboardLayout/DashboardTitleHeader/DashboardTitleHeader';
import Search from '@lib/components/Search/Search';
import Table from '@lib/components/Table/Table';
import ConfirmationModal from '@lib/components/ConfirmationModal/ConfirmationModal';
import useSearchParam from '@lib/hooks/useSearchParam';
import { GetUrlParams } from '@lib/enums/urls';
import useActions from 'routes/Interventions/utils/useActions';
import { useLocation } from 'react-router-dom';
import useModalManagement from 'routes/Interventions/utils/useModalManagement';
import getTableActionsMenu from 'routes/Interventions/utils/getTableActionsMenu';
import getTableActionsForSelected from 'routes/Interventions/utils/getTableActionsForSelected';
import ViewOnlyChip from '@lib/components/ViewOnlyChip/ViewOnlyChip';
import { IconName } from '@lib/components/Modal/enums';
import useOutletContext, { ContextProps } from 'hooks/useOutletContext';
import { UseActionsResult } from './utils/useActions';
import { AssetViewMode } from './enums';
import getTableColumns from './utils/getInterventionsTableColumns';

type Props = {
  id: string;
  menuBar: JSX.Element;
  useBulkSelectResult: UseBulkSelectResult<Intervention>;
  useActionsResult: UseActionsResult;
  viewMode: AssetViewMode;
};

export default function AssetInterventionsContainer(props: Props) {
  const { id, menuBar, useBulkSelectResult, useActionsResult, viewMode } =
    props;
  const { t } = useTranslation();
  const { pathname, search } = useLocation();
  const [searchQuery] = useSearchParam();
  const useModalManagementResult = useModalManagement();

  const { getUserAccessByComponent }: ContextProps = useOutletContext();
  const isInterventionsViewOnly =
    getUserAccessByComponent(ComponentEnum.InterventionsManagement) ===
    LevelEnum.Read;

  const useInterventionActionsResult = useActions({
    assetId: id,
    useModalManagementResult,
    useBulkSelectResult,
    searchQuery,
    pathname,
    search,
    skipInterventionsFetch: viewMode !== AssetViewMode.Interventions,
    interventionScope: InterventionScopeNameEnum.All,
  });

  const { assetQueryHook } = useActionsResult;
  const {
    interventionsQueryHook,
    onCloseModal,
    onConfirmedInterventionDestroy,
    openCreateInterventionPage,
    onConfirmedInterventionClose,
  } = useInterventionActionsResult;

  const name = assetQueryHook.data?.name || '';
  const { totalCount } = interventionsQueryHook;
  const bulkSelectedItemsCount = totalCount
    ? useBulkSelectResult.getSelectedItemsCount(totalCount)
    : 0;

  const tableColumns = getTableColumns({
    onView: useInterventionActionsResult.openViewInterventionPage,
    onStatusChangeHandler: useInterventionActionsResult.onStatusChangeHandler,
    t,
  });
  const tableActionsMenu = getTableActionsMenu({
    onView: useInterventionActionsResult.openViewInterventionPage,
    onEdit: useInterventionActionsResult.openEditInterventionPage,
    onDelete: useInterventionActionsResult.openInterventionDestroyModal,
    onClose: useInterventionActionsResult.openInterventionCloseModal,
    isViewOnly: isInterventionsViewOnly,
    t,
  });
  const tableActionsForSelected = getTableActionsForSelected({
    onBulkClose: useModalManagementResult.openInterventionCloseModal,
    selectedItemsCount: useBulkSelectResult.getSelectedItemsCount(totalCount),
    isViewOnly: isInterventionsViewOnly,
    t,
  });

  const onCreateAssetIntervention = () => {
    openCreateInterventionPage({
      [GetUrlParams.AssetId]: assetQueryHook.data?.id,
      [GetUrlParams.SiteId]: assetQueryHook.data?.site?.id,
      [GetUrlParams.SiteAreaId]: assetQueryHook.data?.siteArea?.id,
    });
  };

  const emptyState = (
    <ListEmptyState
      title={t('no-interventions-yet')}
      text={<Trans i18nKey="no-interventions-text" />}
      actions={
        isInterventionsViewOnly
          ? []
          : [
              <Button
                key="create"
                buttonText={t('create-intervention')}
                buttonType={ButtonTypes.primaryFilled}
                leftIcon="add"
                onClick={onCreateAssetIntervention}
                leftIconClassName={materialSymbolsOutlined}
              />,
            ]
      }
    />
  );

  if (interventionsQueryHook.error) {
    return (
      <ErrorPage
        status={getStatusFromError(interventionsQueryHook.error)}
        description={interventionsQueryHook.error.message}
      />
    );
  }

  return (
    <>
      <PageBody withoutHeader>
        {menuBar}
        {interventionsQueryHook.firstLoading ? (
          <CircleLoader />
        ) : (
          <WhiteBox>
            <DashboardTitleHeader
              title={name}
              actions={
                isInterventionsViewOnly ? (
                  <ViewOnlyChip key="view" />
                ) : (
                  <Button
                    key="add"
                    buttonText={t('create-intervention')}
                    buttonType={ButtonTypes.primaryFilled}
                    onClick={onCreateAssetIntervention}
                    leftIcon="add"
                    leftIconClassName={materialSymbolsOutlined}
                  />
                )
              }
            />
            {(!!interventionsQueryHook.collection?.length || searchQuery) && (
              <Search
                placeholder={t('asset-interventions-search-placeholder')}
              />
            )}
            <Table
              actionsForSelected={tableActionsForSelected}
              actionsMenu={tableActionsMenu}
              columns={tableColumns}
              data={interventionsQueryHook.collection}
              emptyState={emptyState}
              id="interventions"
              loading={interventionsQueryHook.loading}
              totalEntries={interventionsQueryHook.totalCount}
              useBulkSelectResult={useBulkSelectResult}
            />
          </WhiteBox>
        )}
      </PageBody>
      <ConfirmationModal
        confirmButtonLabel={t('yes-delete')}
        description={<Trans i18nKey="asset-documentation-delete-text" />}
        icon={IconName.Delete}
        isOpen={useModalManagementResult.isInterventionDestroyModalOpened}
        onClose={onCloseModal}
        onConfirm={onConfirmedInterventionDestroy}
        title={t('are-you-sure')}
      />
      <ConfirmationModal
        confirmButtonLabel={t('yes-close')}
        description={
          <Trans
            i18nKey={
              bulkSelectedItemsCount > 1
                ? 'interventions-close-text'
                : 'intervention-close-text'
            }
          />
        }
        icon={IconName.Warning}
        isOpen={useModalManagementResult.isInterventionCloseModalOpened}
        onClose={useModalManagementResult.closeAllModals}
        onConfirm={onConfirmedInterventionClose}
        title={t('are-you-sure')}
      />
    </>
  );
}
