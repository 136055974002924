import React, { memo } from 'react';
import { useFormContext } from 'react-hook-form';
import classNames from 'classnames';
import _get from 'lodash/get';
import _replace from 'lodash/replace';
import FormItem from '@lib/components/ReactHookForm/FormItem';
import Select from '@lib/components/Select/Select';
import { Values } from '@lib/interfaces/form';
import ValidityHintOption from '@lib/components/Select/components/ValidityHintOption';
import FormTemplateFieldNames from '@lib/enums/fieldNames/formTemplateFieldNames';
import { FormTemplateQuestionFieldsKindEnum, GroupEnum } from 'graphql-common';
import ValidityHintSingleValue from '@lib/components/Select/components/ValidityHintOptionSingleValue';
import QuestionFieldOptionPreviewIcon from 'components/FormTemplateBuilder/QuestionFieldOption/QuestionFieldOptionPreviewIcon';
import QuestionFieldOptionFormItem from 'components/FormTemplateBuilder/QuestionFieldOption/QuestionFieldOptionFormItem';
import isDeleteBtnVisibleFn from './utils/isDeleteBtnVisibleFn';
import {
  kindFieldsWithCheckboxPreview,
  kindFieldsWithCondition,
  kindFieldsWithRadioButtonPreview,
  kindFieldsWithValidityHint,
} from '../constants';
import getValidityHintOptions from '../utils/getValidityHintOptions';
import QuestionFieldOptionDeleteBtn from './QuestionFieldOptionDeleteBtn';
import styles from './QuestionFieldOption.module.scss';

interface Props {
  onRemove: () => void;
  parentGroupIndex: number;
  parentIndex: number;
  parentName: string;
  parentValue: Values[];
}

function QuestionFieldOption(props: Props) {
  const { onRemove, parentGroupIndex, parentIndex, parentName, parentValue } =
    props;
  const { getValues } = useFormContext();
  const values = getValues();
  const fieldValue = _get(
    values,
    _replace(parentName, `.${FormTemplateFieldNames.Options}`, ''),
  ) as Values;
  const kind = _get(
    fieldValue,
    FormTemplateFieldNames.Kind,
  ) as FormTemplateQuestionFieldsKindEnum;
  const validityHintConfig = _get(
    fieldValue,
    FormTemplateFieldNames.ValidityHintConfig,
  ) as Values;
  const isConditionVisible = kindFieldsWithCondition.includes(kind);
  const isValidityHintVisible = kindFieldsWithValidityHint.includes(kind);
  const groupValue = _get(parentValue, [
    parentIndex,
    FormTemplateFieldNames.Group,
  ]) as GroupEnum;
  const isDeleteBtnVisible = isDeleteBtnVisibleFn(
    kind,
    parentValue,
    groupValue,
  );
  const rootClassname = classNames(styles.optionWrap, {
    [styles.optionWrapWithPreviewIcon]:
      isConditionVisible ||
      kindFieldsWithCheckboxPreview.includes(kind) ||
      kindFieldsWithRadioButtonPreview.includes(kind) ||
      kind === FormTemplateQuestionFieldsKindEnum.ImportantNotice,
    [styles.optionWrapWithHint]: isValidityHintVisible,
    [styles.optionWrapWithDelete]: isDeleteBtnVisible,
  });

  return (
    <div className={rootClassname}>
      <QuestionFieldOptionPreviewIcon
        kind={kind}
        parentIndex={parentIndex}
        parentValue={parentValue}
      />
      <QuestionFieldOptionFormItem
        kind={kind}
        parentValue={parentValue}
        parentIndex={parentIndex}
        parentName={parentName}
        parentGroupIndex={parentGroupIndex}
      />
      {isValidityHintVisible && (
        <FormItem
          name={`${parentName}.${parentIndex}.validityHint`}
          element={Select}
          componentProps={{
            options: getValidityHintOptions(),
            isClearable: false,
            isSearchable: false,
            fullWidthMenu: false,
            components: {
              Option: ValidityHintOption,
              SingleValue: ValidityHintSingleValue,
            },
          }}
          formItemClassName={styles.formItem}
        />
      )}
      {isDeleteBtnVisible && (
        <QuestionFieldOptionDeleteBtn
          kind={kind}
          validityHintConfig={validityHintConfig}
          onRemove={onRemove}
          parentIndex={parentIndex}
          parentName={parentName}
          parentValue={parentValue}
        />
      )}
    </div>
  );
}

export default memo(QuestionFieldOption);
