import {
  InterventionAction,
  InterventionActionsQueryVariables,
  InterventionFilterInputObject,
  useInterventionActionIdsLazyQuery,
  useInterventionActionsQuery,
} from 'graphql-common';
import _get from 'lodash/get';
import { GetUrlParams } from '@lib/enums/urls';
import createSearchFilters from '@lib/utils/createSearchFilters';
import { ApolloError } from '@apollo/client/errors';
import { FetchIdsResponse } from '@lib/utils/getAllTableItemsIds';
import { ListQueryHookProps } from './types';

export type UseAssetInterventionsQueryHookResult = {
  collection: InterventionAction[];
  error: ApolloError | undefined;
  fetchIds: () => Promise<FetchIdsResponse<unknown>>;
  firstLoading: boolean;
  loading: boolean;
  refetch: () => Promise<unknown>;
  totalCount: number;
  totalPages: number;
};

type Args = ListQueryHookProps & {
  interventionId?: string;
};

export default function useInterventionActionsQueryHook({
  fetchPolicy = 'cache-and-network',
  paginationParams,
  orderingParams,
  searchQuery,
  skip,
  usePrevious = true,
  interventionId = '',
}: Args): UseAssetInterventionsQueryHookResult {
  const page = _get(paginationParams, GetUrlParams.Page);
  const perPage = _get(paginationParams, GetUrlParams.PerPage);
  const orderingField = _get(orderingParams, GetUrlParams.OrderingField);
  const orderingDirection = _get(
    orderingParams,
    GetUrlParams.OrderingDirection,
  );
  const queryVariables: InterventionActionsQueryVariables = {
    page: Number(page),
    limit: Number(perPage),
    interventionId,
  };
  const filters: InterventionFilterInputObject[] = [];
  if (searchQuery) {
    filters.push(
      ...createSearchFilters(searchQuery, [
        { fieldName: 'name' },
        { fieldName: 'code' },
        { fieldName: 'assetName' },
        { fieldName: 'assetCode' },
        { fieldName: 'siteCode' },
        { fieldName: 'siteName' },
        { fieldName: 'siteAreaName' },
        { fieldName: 'siteAreaCode' },
        { fieldName: 'priority', predicateType: 'eq' },
      ]),
    );
  }
  if (filters.length) {
    queryVariables.filters = filters;
  }
  if (orderingField && orderingDirection) {
    queryVariables.sorts = {
      [orderingField]: orderingDirection,
    };
  }
  const query = useInterventionActionsQuery({
    fetchPolicy,
    variables: queryVariables,
    skip,
  });
  const [fetchIds] = useInterventionActionIdsLazyQuery({
    fetchPolicy: 'no-cache',
    variables: {
      onlyIds: true,
      filters: queryVariables.filters,
      interventionId,
    },
  });
  const { loading, data, previousData, refetch, error } = query;
  const queryData = usePrevious ? data || previousData : data;
  const firstLoading = loading && previousData === undefined;
  const collection = _get(
    queryData,
    'data.collection',
    [],
  ) as InterventionAction[];
  const metadata = _get(queryData, 'data.metadata');
  const totalCount = _get(metadata, 'totalCount', 0);
  const totalPages = _get(metadata, 'totalPages', 1);

  return {
    collection,
    error,
    fetchIds,
    firstLoading,
    loading,
    refetch,
    totalCount,
    totalPages,
  };
}
