import _get from 'lodash/get';
import { Value, Option } from '@lib/interfaces/form';
import { qsParse } from '@lib/utils/qsHelpers';

export const parseQueryParamsToSiteAndArea = (
  queryParamsString?: string,
): Value[] => {
  const result: Value[] = [];
  if (!queryParamsString) return result;
  const queryParams = qsParse(queryParamsString);
  const { siteIds = [], siteAreaIds = [] } = queryParams;

  if (Array.isArray(siteIds)) {
    siteIds.forEach((siteOption, index) => {
      const areas = _get(siteAreaIds, index);
      const areasValue = _get(areas, 'value') as unknown as string | string[];
      const areasLabel = _get(areas, 'label');
      let areaIdsArray: Option[] = [];
      if (typeof areasValue === 'string' && typeof areasLabel === 'string') {
        areaIdsArray = [{ value: `${areasValue}`, label: areasLabel }];
      } else if (Array.isArray(areasValue)) {
        areaIdsArray = areasValue.map((areaId: string, subIndex: number) => ({
          value: `${areaId}`,
          label: areasLabel?.[subIndex],
        }));
      }

      result.push({
        site: siteOption,
        siteAreaIds: areaIdsArray,
      } as unknown as Value);
    });
  }

  return result;
};

type ParamsObject = {
  [key: string]: string | string[];
};

export const createFilterParams = (filter: Value[]): ParamsObject => {
  const params: ParamsObject = {};

  filter.forEach((item, index) => {
    if (item) {
      const site = _get(item, 'site');
      const siteAreaIds = _get(item, 'siteAreaIds', []);
      if (site) {
        const siteKey = `siteIds[${index}]`;
        params[siteKey] = site;
        if (siteAreaIds.length > 0) {
          params[`siteAreaIds[${index}]`] = siteAreaIds;
        }
      }
    }
  });

  return params;
};
