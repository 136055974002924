import { TFunction } from 'i18next';
import * as Yup from 'yup';
import _isNaN from 'lodash/isNaN';
import { getOptionShape } from '@lib/utils/yup';
import { getMinutesCountFromTime } from '@lib/utils/getMinutesCountFromTime';
import {
  EMAIL_REGEX,
  MAX_MINUTES_VALUE,
  MAX_NUMBER_VALUE,
  MAX_TEXT_FIELD_LENGTH,
} from '@lib/enums/form';

export const getFieldValidation = (
  required: boolean,
  maxLength: number,
  t: TFunction<'translation', undefined>,
) => {
  let validation = Yup.string()
    .trim()
    .max(maxLength, t('max-text-length-field-error'));
  if (required) validation = validation.required(t('required-field-error'));
  return validation;
};

export const getOptionValidation = (
  required: boolean,
  t: TFunction<'translation', undefined>,
) =>
  required
    ? Yup.object()
        .shape(getOptionShape(t, true))
        .required(t('required-field-error'))
    : Yup.object().shape(getOptionShape(t)).nullable();

export const getRadioButtonGridValidation = (
  required: boolean,
  t: TFunction<'translation', undefined>,
) =>
  required
    ? Yup.array()
        .of(Yup.object().shape({}).required(t('required-field-error')))
        .min(1, t('required-field-error'))
        .required(t('required-field-error'))
    : Yup.array().of(Yup.object().shape({})).nullable();

export const getOptionsArrayValidation = (
  required: boolean,
  t: TFunction<'translation', undefined>,
) =>
  required
    ? Yup.array()
        .of(
          Yup.object()
            .shape(getOptionShape(t, true))
            .required(t('required-field-error')),
        )
        .min(1, t('required-field-error'))
        .required(t('required-field-error'))
    : Yup.array()
        .of(Yup.object().shape(getOptionShape(t)))
        .nullable();

export const getAttachmentsValidation = (
  required: boolean,
  t: TFunction<'translation', undefined>,
) =>
  required
    ? Yup.array()
        .of(
          Yup.object()
            .shape({ file: Yup.mixed().required(t('required-field-error')) })
            .required(t('required-field-error')),
        )
        .min(1, t('required-field-error'))
        .required(t('required-field-error'))
    : Yup.array().of(Yup.object().shape({})).nullable();

export const getOptionsValidation = (
  required: boolean,
  isArray: boolean,
  t: TFunction<'translation', undefined>,
) => {
  if (isArray) {
    return required
      ? Yup.array()
          .of(Yup.string().nullable())
          .min(1, t('required-field-error'))
          .required(t('required-field-error'))
      : Yup.array().of(Yup.string().nullable()).nullable();
  }
  return required
    ? Yup.object().shape({}).required(t('required-field-error'))
    : Yup.object().shape({}).nullable();
};

export const getTimeValidation = (
  required: boolean,
  t: TFunction<'translation', undefined>,
) => {
  const validation = Yup.string()
    .nullable()
    .test('isValidFormat', t('time-minutes-field-format-error'), (value) => {
      if (!value) return true; // Пустая строка допустима, если поле не обязательно
      return /^\d{1,}:[0-5]\d$/.test(value); // Проверка формата hh:mm
    })
    .test('isValidTime', t('time-minutes-field-format-error'), (value) => {
      if (!value) return true;
      const [hours, minutes] = value.split(':');
      return (
        Number(hours) >= 0 && Number(minutes) >= 0 && Number(minutes) <= 59
      );
    })
    .test('maxMinutes', t('time-minutes-field-max-error'), (value) => {
      if (!value) return true;
      try {
        const minutesCount = getMinutesCountFromTime(value);
        return minutesCount !== null && minutesCount <= MAX_MINUTES_VALUE;
      } catch (error) {
        return false;
      }
    });

  if (required) return validation.required(t('required-field-error'));
  return validation;
};

export const getEmailValidation = (
  required: boolean,
  t: TFunction<'translation', undefined>,
) => {
  const validation = Yup.string()
    .trim()
    .max(MAX_TEXT_FIELD_LENGTH, t('max-text-length-field-error'))
    .matches(EMAIL_REGEX, t('email-field-error'));

  if (required) return validation.required(t('required-field-error'));
  return validation.nullable();
};

export const getPhoneValidation = (
  required: boolean,
  t: TFunction<'translation', undefined>,
) => {
  const validation = Yup.object().shape({
    country: Yup.string(),
    number: Yup.string().required(t('required-field-error')),
  });

  if (required) return validation.required(t('required-field-error'));
  return validation.nullable();
};

type GetValidationUtilArgs = {
  t: TFunction<'translation', undefined>;
  required?: boolean;
};

export const getPriceValidation = ({ t, required }: GetValidationUtilArgs) => {
  let validation = Yup.string().test(
    'isValidNumber',
    t('number-field-error'),
    (value) => {
      if (!value) return true; // Skip number validation when value is empty
      const number = parseFloat(value);
      return !_isNaN(number) && number >= 0;
    },
  );
  if (required) {
    validation = validation
      .test('is-min', t('number-field-error-min-1'), (value) => {
        const number = value ? parseFloat(value) : 0;
        return number >= 1;
      })
      .test('is-max', t('number-field-error-max-number'), (value) => {
        const number = value ? parseFloat(value) : 0;
        return number <= MAX_NUMBER_VALUE;
      })
      .required(t('required-field-error'));
  }
  return validation
    .test('is-min', t('number-field-error-min-1'), (value) => {
      if (!value) return true;
      const number = value ? parseFloat(value) : 0;
      return number >= 1;
    })
    .test('is-max', t('number-field-error-max-number'), (value) => {
      if (!value) return true;
      const number = value ? parseFloat(value) : 0;
      return number <= MAX_NUMBER_VALUE;
    })
    .nullable();
};
