import { useLocation, useNavigate } from 'react-router-dom';
import { parseSearch, stringifyWithPrefix } from '@lib/utils/url';
import { BaseItem, Column } from '@lib/components/Table/types';
import {
  DEFAULT_ORDERING_DIRECTION,
  GetUrlParams,
  ORDERING_DIRECTION_ASC,
  ORDERING_DIRECTION_DESC,
  OrderingParams,
  OrderingState,
} from '@lib/enums/urls';
import _get from 'lodash/get';

interface OrderingProps {
  orderingDirection?: string;
  orderingField?: string;
  orderingFieldKey?: string;
  orderingDirectionKey?: string;
  orderingState?: OrderingState;
  onSortChange?: (
    newOrderingField?: string,
    newOrderingDirection?: string,
  ) => void;
}

const getOrderingDirection = (
  orderingField?: string | (string | null)[] | null | undefined,
  newOrderingField?: string,
  orderingDirection?: string | (string | null)[] | null | undefined,
) => {
  if (orderingField !== newOrderingField) return DEFAULT_ORDERING_DIRECTION;
  if (orderingDirection === ORDERING_DIRECTION_ASC)
    return ORDERING_DIRECTION_DESC;
  return ORDERING_DIRECTION_ASC;
};

const getOrderingData = (props: OrderingProps, search: string) => {
  const {
    orderingDirection,
    orderingField,
    orderingFieldKey,
    orderingDirectionKey,
  } = props;
  if (orderingField && orderingDirection)
    return { orderingField, orderingDirection };

  const params = parseSearch(search);
  return {
    orderingField: orderingFieldKey
      ? (params[orderingFieldKey] as string)
      : undefined,
    orderingDirection: orderingDirectionKey
      ? (params[orderingDirectionKey] as string)
      : undefined,
  };
};

const useOrdering = (props: OrderingProps) => {
  const {
    orderingFieldKey,
    orderingDirectionKey,
    onSortChange,
    orderingState = [],
  } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const [params, setParams] = orderingState; // custom state

  let orderingField: string | undefined;
  let orderingDirection: string | undefined;
  if (orderingState.length) {
    orderingField = _get(params, GetUrlParams.OrderingField, '') as string;
    orderingDirection = _get(
      params,
      GetUrlParams.OrderingDirection,
      '',
    ) as string;
  } else {
    const orderingData = getOrderingData(props, location.search);
    orderingField = orderingData.orderingField;
    orderingDirection = orderingData.orderingDirection;
  }

  const handleOrderingChange = <T extends BaseItem>(column: Column<T>) => {
    const newOrderingField = column.id as string;
    const newOrderingDirection = getOrderingDirection(
      orderingField,
      newOrderingField,
      orderingDirection,
    );
    if (onSortChange && newOrderingField) {
      onSortChange(newOrderingField, newOrderingDirection);
    } else if (setParams) {
      const newParams: OrderingParams = { ...params };
      if (orderingFieldKey) {
        // @ts-ignore
        newParams[orderingFieldKey] = newOrderingField;
      }
      if (orderingDirectionKey) {
        // @ts-ignore
        newParams[orderingDirectionKey] = newOrderingDirection;
      }
      setParams(newParams);
    } else {
      const newParams = parseSearch(location.search);
      if (orderingFieldKey) newParams[orderingFieldKey] = newOrderingField;
      if (orderingDirectionKey)
        newParams[orderingDirectionKey] = newOrderingDirection;
      const newUrl = `${location.pathname}${stringifyWithPrefix(newParams)}`;
      navigate(newUrl);
    }
  };

  return {
    orderingField,
    orderingDirection,
    onOrderingChange: handleOrderingChange,
  };
};

export default useOrdering;
