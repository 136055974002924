import React from 'react';
import { useTranslation } from 'react-i18next';
import Button, { ButtonTypes } from '@lib/components/Button/Button';
import ActionsRow from '@lib/components/ActionsRow/ActionsRow';
import { ActionsProps } from '@lib/components/ReactHookForm/types';

export interface FormProps extends ActionsProps {
  onCancel: () => void;
}

export default function AssetCounterIndexModalFormActions(props: FormProps) {
  const { loading, onCancel, isDirty } = props;
  const { t } = useTranslation();
  const actions = [
    <Button
      buttonText={t('cancel')}
      buttonType={ButtonTypes.secondaryOutlined}
      disabled={loading}
      fullWidth
      leftIcon="close"
      onClick={onCancel}
    />,
    <Button
      buttonText={t('save')}
      buttonType={ButtonTypes.primaryFilled}
      disabled={!isDirty}
      fullWidth
      isLoading={loading}
      leftIcon="check"
      type="submit"
    />,
  ];
  return <ActionsRow actions={actions} />;
}
