import React from 'react';
import { useTranslation } from 'react-i18next';
import Button, {
  ButtonSizes,
  ButtonTypes,
} from '@lib/components/Button/Button';
import SupportiveMessage from '@lib/components/SupportiveMessage/SupportiveMessage';
import { OnUpdateProfile } from 'components/Profile/types';

interface Props {
  unconfirmedEmail?: string | null;
  onResendConfirmationHandler?: () => void;
  onSubmit: OnUpdateProfile;
}

function UnconfirmedEmailBlock({
  unconfirmedEmail,
  onResendConfirmationHandler,
  onSubmit,
}: Props) {
  const { t } = useTranslation();
  if (!unconfirmedEmail) return null;
  return (
    <SupportiveMessage
      key="unconfirmedEmailBlock"
      text={
        <>
          {t('pending-my-email-confirm')} <strong>{unconfirmedEmail}</strong>
        </>
      }
      actions={
        <>
          <Button
            buttonText={t('cancel')}
            buttonType={ButtonTypes.primaryText}
            buttonSize={ButtonSizes.small}
            onClick={() => onSubmit({ cancelConfirmation: true })}
          />
          <Button
            buttonText={t('resend')}
            buttonType={ButtonTypes.primaryFilled}
            buttonSize={ButtonSizes.small}
            onClick={onResendConfirmationHandler}
          />
        </>
      }
    />
  );
}

export default UnconfirmedEmailBlock;
