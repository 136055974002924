import React, { memo } from 'react';
import { ButtonProps } from '@lib/components/Button/Button';
import { CheckboxProps } from '@lib/components/CheckBox/CheckBox';
import classNames from 'classnames';
import Typography from '@lib/components/Typography/Typography';
import styles from './CheckBoxAsCalendarMonth.module.scss';

interface Props {
  onChange?: () => void;
}

function CheckBoxAsButton(props: Props & CheckboxProps & ButtonProps) {
  const { label = '', checked, onChange, disabled } = props;
  return (
    <button
      type="button"
      disabled={disabled}
      onClick={() => onChange()}
      className={classNames(styles.calendarMonth, {
        [styles.calendarMonthSelected]: checked,
      })}
    >
      <Typography variant="body">{label}</Typography>
    </button>
  );
}

export default memo(CheckBoxAsButton);
