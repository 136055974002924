import _get from 'lodash/get';
import { Team } from 'graphql-common';
import {
  getAccessesValues,
  getEmptyAccessesValues,
} from '@lib/utils/accessesManagement';
import FieldNames from '@lib/enums/fieldNames/teamFieldNames';

export default function getTeamDefaultValuesFromQueryData(
  data: Team | undefined,
  membersIds: { id: string }[],
) {
  return {
    [FieldNames.Name]: data?.name || '',
    [FieldNames.Description]: data?.description || '',
    [FieldNames.Accesses]: data?.accesses
      ? {
          ...getEmptyAccessesValues(),
          ...getAccessesValues(data.accesses),
        }
      : getEmptyAccessesValues(),
    [FieldNames.Color]: data?.color
      ? { value: _get(data, 'color', null) }
      : undefined,
    [FieldNames.Users]:
      membersIds?.length > 0
        ? membersIds.map(({ id }) => ({ value: id }))
        : null,
  };
}
