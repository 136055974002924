import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import MaterialIcon from '@lib/components/MaterialIcon/MaterialIcon';
import Tooltip from '@lib/components/Tooltip/Tooltip';
import styles from './Menu.module.scss';

export const MenuLinkClassName = 'MenuLinkClassName';

export enum NavTypeEnum {
  default = 'default',
  appNav = 'appNav', // mobile footer menu
}

export enum NavItemTypeEnum {
  Primary = 'primary',
  PrimarySub = 'primarySub',
  PrimaryText = 'primaryText',
  Secondary = 'secondary',
  Error = 'error',
}

export interface NavItem<T> {
  children?: NavItem<T>[];
  disabled?: boolean;
  end?: boolean;
  hasSubMenu?: boolean;
  id: string;
  isCollapsed?: boolean;
  isSub?: boolean;
  itemClassName?: string;
  leftIcon?: (isCollapsed?: boolean) => React.ReactNode;
  onClick?: (v?: T) => void;
  onClickCallback?: () => void;
  predictorFnActive?: (item?: T) => boolean;
  predictorFnDisable?: (item?: T) => boolean;
  predictorFnTo?: (item?: T) => string;
  predictorFnVisible?: (item?: T) => boolean;
  rightIcon?: (isCollapsed?: boolean) => React.ReactNode;
  rightIconType?: string;
  title: React.ReactNode;
  to?: string;
  type: NavItemTypeEnum;
}

export interface MenuProps<T> {
  isCollapsed?: boolean;
  itemClassName?: string;
  itemWrapClassName?: string;
  listClassName?: string;
  navItems: NavItem<T>[];
  navType?: NavTypeEnum;
  onClickCallback?: () => void;
}

function MenuItem<T>(props: Omit<NavItem<T>, 'id'>) {
  const {
    disabled,
    end,
    hasSubMenu,
    isCollapsed,
    itemClassName,
    leftIcon,
    onClick,
    rightIcon,
    rightIconType,
    title,
    to,
    type,
    onClickCallback,
    predictorFnTo,
  } = props;

  const toValue = predictorFnTo ? predictorFnTo() : to;

  const hasSubMenuIcon = hasSubMenu && !isCollapsed;
  const className = classNames(
    MenuLinkClassName,
    styles.link,
    styles[type],
    {
      [styles.isCollapsed]: isCollapsed,
      [styles.hasLeftIcon]: leftIcon,
      [styles.hasRightIcon]: rightIcon || hasSubMenuIcon,
    },
    itemClassName,
  );

  const itemContent = isCollapsed ? (
    <>
      {leftIcon && (
        <span className={styles.leftIcon}>{leftIcon(isCollapsed)}</span>
      )}
      {rightIcon && (
        <span className={styles.rightIcon}>{rightIcon(isCollapsed)}</span>
      )}
    </>
  ) : (
    <>
      {leftIcon && (
        <span className={styles.leftIcon}>{leftIcon(isCollapsed)}</span>
      )}
      <span>{title}</span>
      {rightIcon && !hasSubMenuIcon && (
        <span className={classNames(styles.rightIcon, rightIconType)}>
          {rightIcon(isCollapsed)}
        </span>
      )}
      {hasSubMenuIcon && (
        <span className={classNames(styles.rightIcon, styles.rightExpandIcon)}>
          <MaterialIcon icon="expand_more" />
        </span>
      )}
    </>
  );

  if (toValue && isCollapsed && !hasSubMenu && !disabled) {
    return (
      <Tooltip body={title} placement="right">
        <NavLink to={toValue} className={className} end={end}>
          {itemContent}
        </NavLink>
      </Tooltip>
    );
  }

  if (toValue && !disabled) {
    return (
      <NavLink to={toValue} className={className} end={end}>
        {itemContent}
      </NavLink>
    );
  }

  if (!to && onClick) {
    return (
      <button
        type="button"
        onClick={() => {
          onClick();
          if (onClickCallback) onClickCallback();
        }}
        className={className}
        disabled={disabled}
      >
        {itemContent}
      </button>
    );
  }

  return <div className={className}>{itemContent}</div>;
}

function Menu<T>(props: MenuProps<T>) {
  const {
    isCollapsed,
    itemClassName,
    itemWrapClassName,
    listClassName,
    navItems,
    navType = NavTypeEnum.default,
    onClickCallback,
  } = props;

  const subMenuRefs = useRef<Record<string, HTMLDivElement | null>>({});
  const [activeOffsets, setActiveOffsets] = useState<Record<string, number>>(
    {},
  );
  const [menuInitialized, setMenuInitialized] = useState(false);

  const calculateOffsetTop = useCallback((menuId: string) => {
    const subMenuElement = subMenuRefs.current[menuId];
    if (!subMenuElement) return;

    const activeLink = subMenuElement.querySelector<HTMLElement>(
      `.${MenuLinkClassName}.active`,
    );
    if (activeLink) {
      const subMenuTop = subMenuElement.getBoundingClientRect().top;
      const activeLinkTop = activeLink.getBoundingClientRect().top;
      const offset = activeLinkTop - subMenuTop;
      setActiveOffsets((prev) => ({ ...prev, [menuId]: offset }));
    }
  }, []);

  useEffect(() => {
    Object.keys(subMenuRefs.current).forEach(calculateOffsetTop);
    setMenuInitialized(true);
  }, [navItems, calculateOffsetTop]);

  return (
    <ul
      className={classNames(
        styles.list,
        {
          [styles.defaultNav]: navType === NavTypeEnum.default,
          [styles.appNav]: navType === NavTypeEnum.appNav,
          [styles.defaultExpanded]: !isCollapsed,
          [styles.defaultCollapsed]: isCollapsed,
        },
        listClassName,
      )}
    >
      {navItems.map((item) => {
        if (
          item.predictorFnVisible !== undefined &&
          !item.predictorFnVisible()
        ) {
          return null;
        }

        const hasSubMenu = item.children && item.children.length > 0;
        const itemFullClassName = classNames(
          itemClassName,
          item.itemClassName,
          {
            active: item?.predictorFnActive ? item.predictorFnActive() : false,
          },
        );

        return (
          <li
            key={item.id}
            className={classNames(
              styles.menuItemWrap,
              {
                [styles.menuItemParentWrap]: hasSubMenu,
              },
              itemWrapClassName,
            )}
          >
            <MenuItem
              {...item}
              itemClassName={itemFullClassName}
              isCollapsed={isCollapsed}
              hasSubMenu={hasSubMenu}
              onClickCallback={onClickCallback}
              disabled={
                item?.predictorFnDisable ? item.predictorFnDisable() : undefined
              }
            />
            {item.children && item.children.length > 0 && (
              <div
                ref={(el) => {
                  subMenuRefs.current[item.id] = el;
                }}
                className={styles.subMenu}
              >
                {menuInitialized && activeOffsets[item.id] !== undefined && (
                  <div
                    className={styles.highlight}
                    style={{
                      transform: `translateY(${activeOffsets[item.id]}px)`,
                    }}
                  />
                )}
                <Menu
                  navItems={item.children}
                  itemWrapClassName={itemWrapClassName}
                />
              </div>
            )}
          </li>
        );
      })}
    </ul>
  );
}

export default memo(Menu) as <T>(props: MenuProps<T>) => JSX.Element;
