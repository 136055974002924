import { ChipTypes } from '@lib/components/Chip/Chip';
import { FormTemplateVersionStatusEnum } from 'graphql-common';

export default function getFormTemplateVersionStatusChipType(
  status?: FormTemplateVersionStatusEnum,
) {
  let type = ChipTypes.chipDisabled;
  if (status === FormTemplateVersionStatusEnum.Active)
    type = ChipTypes.chipGreen;
  if (status === FormTemplateVersionStatusEnum.Archived)
    type = ChipTypes.chipBlack;
  if (status === FormTemplateVersionStatusEnum.Unused)
    type = ChipTypes.chipGray;
  return type;
}
