import React from 'react';
import { useTranslation } from 'react-i18next';
import { Task, TaskStatusEnum } from 'graphql-common';
import { materialSymbolsOutlined } from '@lib/enums/common';
import Button, { ButtonTypes } from '@lib/components/Button/Button';
import { NavItem, NavItemTypeEnum } from '@lib/components/Menu/Menu';
import ActionsMenu from '@lib/components/ActionsMenu/ActionsMenu';
import Icons from '@lib/components/Icons/Icons';

type ActionsProps = {
  taskData: Task;
  onDeleteHandler: () => void;
  onDuplicateHandler: () => void;
  onEditHandler: () => void;
  openArchiveModal: () => void;
  openScheduleModal: () => void;
};

export default function TaskViewActions(props: ActionsProps) {
  const {
    taskData,
    onDeleteHandler,
    onDuplicateHandler,
    onEditHandler,
    openArchiveModal,
    openScheduleModal,
  } = props;
  const { t } = useTranslation();

  if (taskData?.status === TaskStatusEnum.Archived) {
    return (
      <Button
        buttonText={t('duplicate')}
        buttonType={ButtonTypes.primaryOutlined}
        leftIcon="content_copy"
        leftIconClassName={materialSymbolsOutlined}
        onClick={onDuplicateHandler}
      />
    );
  }

  const actions: NavItem<unknown>[] = [];
  if (taskData?.canReschedule?.value) {
    actions.push({
      id: 'reschedule',
      title: t('reschedule'),
      leftIcon: Icons.schedule,
      onClick: openScheduleModal,
      type: NavItemTypeEnum.Secondary,
    });
  }

  if (taskData?.status !== TaskStatusEnum.Completed) {
    actions.push({
      id: 'copy',
      title: t('duplicate'),
      leftIcon: Icons.copy,
      onClick: onDuplicateHandler,
      type: NavItemTypeEnum.Secondary,
    });
  }

  if (taskData?.canDestroy?.value) {
    actions.push({
      id: 'delete',
      title: t('delete'),
      leftIcon: Icons.delete,
      onClick: onDeleteHandler,
      type: NavItemTypeEnum.Secondary,
    });
  }

  if (taskData?.canArchive?.value) {
    actions.push({
      id: 'archive',
      title: t('archive'),
      leftIcon: Icons.archive,
      onClick: openArchiveModal,
      type: NavItemTypeEnum.Secondary,
    });
  }

  return (
    <>
      {taskData?.canUpdate?.value && (
        <Button
          buttonText={t('edit-task-details')}
          buttonType={ButtonTypes.primaryOutlined}
          onClick={onEditHandler}
          leftIcon="edit"
          leftIconClassName={materialSymbolsOutlined}
        />
      )}
      {taskData?.status === TaskStatusEnum.Completed && (
        <Button
          buttonText={t('duplicate')}
          buttonType={ButtonTypes.primaryOutlined}
          onClick={onDuplicateHandler}
          leftIcon="content_copy"
          leftIconClassName={materialSymbolsOutlined}
        />
      )}
      <ActionsMenu actions={actions} />
    </>
  );
}
