import React, { useRef } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FormRef } from '@lib/components/ReactHookForm/types';
import BreadCrumbs from '@lib/components/BreadCrumbs/BreadCrumbs';
import { getBreadCrumbsDataByKey } from 'utils/breadcrumbs';
import CircleLoader from '@lib/components/CircleLoader/CircleLoader';
import ErrorPage from '@lib/components/ErrorPage/ErrorPage';
import getStatusFromError from '@lib/utils/getStatusFromError';
import AssetAdd from 'components/Asset/AssetAdd/AssetAdd';
import AssetEdit from 'components/Asset/AssetEdit/AssetEdit';
import { SubmitAction } from '@lib/enums/form';
import { ComponentEnum, LevelEnum } from 'graphql-common';
import useOutletContext, { ContextProps } from 'hooks/useOutletContext';
import useSearchListQueries from './utils/useSearchListQueries';
import useModalManagement from './utils/useModalManagement';
import useAssetActions from './utils/useAssetActions';
import useActions from './utils/useActions';
import { AssetViewMode } from './enums';

type Props = {
  viewMode: AssetViewMode;
};

export default function AssetFormContainer({ viewMode }: Props) {
  const { t } = useTranslation();
  const { id = '' } = useParams();
  const { pathname } = useLocation();
  const formRef = useRef<FormRef>();
  const submitNewItemRef = useRef<SubmitAction>();
  const useModalManagementResult = useModalManagement();

  const { getUserAccessByComponent }: ContextProps = useOutletContext();
  const isCategoriesViewOnly =
    getUserAccessByComponent(ComponentEnum.AssetCategoriesManagement) ===
    LevelEnum.Read;

  const useActionsResult = useActions({
    id,
    viewMode,
  });
  const useAssetActionsResult = useAssetActions({
    formRef,
    useModalManagementResult,
    viewMode,
    useActionsResult,
    submitNewItemRef,
  });
  const useSearchListQueriesResult = useSearchListQueries();

  const { assetQueryHook } = useActionsResult;

  if (useActionsResult.assetQueryHook.firstLoading) {
    return <CircleLoader />;
  }

  if (assetQueryHook.error) {
    return (
      <ErrorPage
        status={getStatusFromError(assetQueryHook.error)}
        description={assetQueryHook.error.message}
      />
    );
  }

  return (
    <>
      <BreadCrumbs
        data={getBreadCrumbsDataByKey({
          pathname,
          t,
          entity: useAssetActionsResult.defaultValues,
        })}
      />
      {viewMode === AssetViewMode.Add && (
        <AssetAdd
          formRef={formRef}
          submitNewAssetRef={submitNewItemRef}
          useAssetActionsResult={useAssetActionsResult}
          useModalManagementResult={useModalManagementResult}
          useSearchListQueriesResult={useSearchListQueriesResult}
          isCategoriesViewOnly={isCategoriesViewOnly}
        />
      )}
      {viewMode === AssetViewMode.Edit && (
        <AssetEdit
          formRef={formRef}
          submitNewAssetRef={submitNewItemRef}
          useActionsResult={useActionsResult}
          useAssetActionsResult={useAssetActionsResult}
          useModalManagementResult={useModalManagementResult}
          useSearchListQueriesResult={useSearchListQueriesResult}
          isCategoriesViewOnly={isCategoriesViewOnly}
        />
      )}
    </>
  );
}
