import React from 'react';
import ReactPaginate from 'react-paginate';
import MaterialIcon from '@lib/components/MaterialIcon/MaterialIcon';
import usePagination, { PaginationProps } from '@lib/hooks/usePagination';
import isMobile from '@lib/utils/isMobile';
import styles from './Pagination.module.scss';

function Pagination(props: PaginationProps) {
  const { page, pageCount, onPageChange } = usePagination(props);
  const { phone } = isMobile();
  return (
    <ReactPaginate
      pageCount={+pageCount}
      onPageChange={({ selected: newPage }) => onPageChange(newPage + 1)}
      marginPagesDisplayed={phone ? 1 : 2}
      pageRangeDisplayed={phone ? 1 : 2}
      forcePage={Number(page) - 1}
      disableInitialCallback
      previousLabel={
        <MaterialIcon icon="arrow_back" size="md-20" symbolsOutlined />
      }
      nextLabel={
        <MaterialIcon icon="arrow_forward" size="md-20" symbolsOutlined />
      }
      containerClassName={styles.root}
    />
  );
}

export default Pagination;
