import React from 'react';
import { TFunction } from 'i18next';
import * as Yup from 'yup';
import { getCodeValidation, getOptionShape } from '@lib/utils/yup';
import _get from 'lodash/get';
import Input from '@lib/components/Input/Input';
import {
  FieldChangeCallBackArgs,
  FieldItem,
  Values,
} from '@lib/interfaces/form';
import { GetLoadOptions } from '@lib/components/Select/types';
import { MAX_TEXT_FIELD_LENGTH } from '@lib/enums/form';
import AssetCounterFieldNames from '@lib/enums/fieldNames/assetCounterFieldNames';
import Select, { SelectProps } from '@lib/components/Select/Select';
import {
  getMeasureTypeOptions,
  getMeasureUnitOptionsByType,
} from '@lib/utils/measure';
import styles from '@lib/assets/styles/Form.module.scss';
import ProhibitLowerIndexThanPreviousField from 'components/ProhibitLowerIndexThanPreviousField/ProhibitLowerIndexThanPreviousField';

type Args = {
  getAssetsLoadOptions: GetLoadOptions;
  isEditForm?: boolean;
  t: TFunction<'translation', undefined>;
};

export default function getFormFields({ isEditForm, t }: Args): FieldItem[] {
  return [
    {
      name: AssetCounterFieldNames.Name,
      label: t('name'),
      element: Input,
      validation: Yup.string()
        .trim()
        .max(MAX_TEXT_FIELD_LENGTH, t('max-text-length-field-error'))
        .required(t('required-field-error')),
    },
    {
      name: AssetCounterFieldNames.Code,
      label: t('counter-code'),
      element: Input,
      validation: getCodeValidation(t, 3, true),
      componentProps: {
        disabled: isEditForm,
      },
    },
    {
      name: AssetCounterFieldNames.MeasureType,
      label: t('measurement'),
      element: Select,
      validation: Yup.object()
        .shape(getOptionShape(t, true))
        .required(t('required-field-error')),
      componentProps: {
        options: getMeasureTypeOptions(),
        usePortal: true,
        isClearable: false,
        isSearchable: false,
        disabled: isEditForm,
      } as SelectProps,
      formItemClassName: styles.formColumn,
      fieldChangeCallBack: ({
        type,
        setValue,
        name,
      }: FieldChangeCallBackArgs) => {
        if (type === 'change' && name === AssetCounterFieldNames.MeasureType) {
          setValue(AssetCounterFieldNames.MeasureUnit, null, {
            shouldValidate: false,
          });
        }
      },
    },
    {
      name: AssetCounterFieldNames.MeasureUnit,
      label: t('unit'),
      element: Select,
      validation: Yup.object()
        .shape(getOptionShape(t, true))
        .required(t('required-field-error')),
      componentProps: {
        usePortal: true,
        isClearable: false,
        isSearchable: false,
      } as SelectProps,
      getComponentProps: (values: Values) => {
        const type = _get(values, [
          AssetCounterFieldNames.MeasureType,
          'value',
        ]);
        const options = getMeasureUnitOptionsByType(type);
        return {
          options,
        };
      },
      formItemClassName: styles.formColumn,
      isDisabledFn: (values: Values, name?: string) => {
        if (isEditForm) return true;
        if (!name) return false;
        const depsValue = _get(values, [
          AssetCounterFieldNames.MeasureType,
          'value',
        ]);
        return depsValue === undefined;
      },
    },
    {
      name: `${AssetCounterFieldNames.ProhibitLowerIndexThanPrevious}-field`,
      customRender: () => {
        return <ProhibitLowerIndexThanPreviousField />;
      },
    },
    {
      name: AssetCounterFieldNames.ProhibitLowerIndexThanPrevious,
    },
  ];
}
