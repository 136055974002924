import { useState } from 'react';
import { Asset } from 'graphql-common';
import { UseBulkSelectResult } from '@lib/hooks/useTableItemsBulkSelect';

interface UseModalManagementProps {
  useBulkSelectResult: UseBulkSelectResult<Asset>;
}

export interface UseModalManagementResult {
  closeAssetDeleteModal: () => void;
  closeGetQrCodeModal: () => void;
  isAssetDeleteModalOpened: boolean;
  isAssetQrCodeFormModalOpened: boolean;
  openAssetDeleteModal: () => void;
  openGetQrCodeModal: () => void;
}

export default function useModalManagement(
  props: UseModalManagementProps,
): UseModalManagementResult {
  const { useBulkSelectResult } = props;
  const { handleSelectSingleTableItem } = useBulkSelectResult;

  // State
  const [isAssetQrCodeFormModalOpened, setAssetQrCodeFormModalOpened] =
    useState(false);
  const [isAssetDeleteModalOpened, setAssetDeleteModalOpened] = useState(false);

  // Actions
  const openGetQrCodeModal = () => setAssetQrCodeFormModalOpened(true);
  const closeGetQrCodeModal = () => {
    setAssetQrCodeFormModalOpened(false);
    handleSelectSingleTableItem(null);
  };
  const openAssetDeleteModal = () => setAssetDeleteModalOpened(true);
  const closeAssetDeleteModal = () => {
    setAssetDeleteModalOpened(false);
    handleSelectSingleTableItem(null);
  };

  return {
    closeAssetDeleteModal,
    closeGetQrCodeModal,
    isAssetDeleteModalOpened,
    isAssetQrCodeFormModalOpened,
    openAssetDeleteModal,
    openGetQrCodeModal,
  };
}
