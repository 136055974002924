import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import _get from 'lodash/get';
import _noop from 'lodash/noop';
import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
} from 'react-beautiful-dnd';
import { FormTemplateQuestionFieldsKindEnum } from 'graphql-common';
import StrictModeDroppable from '@lib/components/StrictModeDroppable/StrictModeDroppable';
import getPrefilledQuestions from 'components/FormTemplateBuilder/utils/getPrefilledQuestions';
import MaterialIcon from '@lib/components/MaterialIcon/MaterialIcon';
import Typography from '@lib/components/Typography/Typography';
import { useFieldArrayContext } from '@lib/components/ReactHookForm/FormProvider';
import FormTemplateFieldNames from '@lib/enums/fieldNames/formTemplateFieldNames';
import { PrefilledQuestion } from '../types';
import styles from '../FormTemplateBuilderForm/FormTemplateBuilderForm.module.scss';

interface Props {
  fieldItem: PrefilledQuestion;
  index: number;
  onClick?: () => void;
  snapshot?: DraggableStateSnapshot;
  subProvided?: DraggableProvided;
}

function RenderSourceItem(props: Props) {
  const { t } = useTranslation();
  const { fieldItem, onClick, subProvided, snapshot } = props;
  const { icon, renderCustomIcon } = fieldItem;
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      onClick={onClick}
      className={classNames(styles.sourceItem, {
        [styles.sourceItemDragging]: snapshot?.isDragging,
      })}
    >
      <div {...subProvided?.dragHandleProps} className={styles.sourceItemGrid}>
        <span className={styles.sourceItemIcon}>
          {icon && <MaterialIcon icon={icon} symbolsOutlined />}
          {renderCustomIcon ? renderCustomIcon() : null}
        </span>
        <Typography variant="caption" className={styles.sourceItemName}>
          {t(fieldItem.nameTranslationKey)}
        </Typography>
      </div>
    </div>
  );
}

function FormTemplateBuilderDefaultQuestions() {
  const { fieldArrayMethodsByName } = useFieldArrayContext();
  const { t } = useTranslation();
  const methods = fieldArrayMethodsByName.questions;
  const handleAppend = _get(methods, 'append', _noop);

  const prefilledQuestions = getPrefilledQuestions({ t });

  return (
    <div className={styles.defaultQuestions}>
      <div className={styles.defaultQuestionsSticky}>
        <StrictModeDroppable
          droppableId="FieldArrayDroppable-questions-source"
          isDropDisabled
        >
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className={styles.sourceItems}
            >
              {prefilledQuestions.map((fieldItem, index) => (
                <Draggable
                  key={fieldItem.id}
                  draggableId={fieldItem.id}
                  index={index}
                >
                  {(subProvided, snapshot) => (
                    <div
                      className={classNames(styles.sourceItemContainer, {
                        [styles.sourceItemSectionContainer]:
                          _get(
                            fieldItem,
                            `${FormTemplateFieldNames.Fields}.0.${FormTemplateFieldNames.Kind}`,
                          ) === FormTemplateQuestionFieldsKindEnum.Section,
                      })}
                    >
                      <div
                        className={styles.sourceItemWrap}
                        ref={subProvided.innerRef}
                        {...subProvided.draggableProps}
                        {...subProvided.dragHandleProps}
                        style={
                          snapshot.isDragging
                            ? subProvided.draggableProps.style
                            : undefined
                        }
                      >
                        <RenderSourceItem
                          fieldItem={fieldItem}
                          onClick={() => {
                            handleAppend(fieldItem);
                            setTimeout(() => {
                              window.scrollTo({
                                left: 0,
                                top: document.body.scrollHeight,
                                behavior: 'smooth',
                              });
                            }, 100);
                          }}
                          index={index}
                          snapshot={snapshot}
                          subProvided={subProvided}
                        />
                      </div>
                      {snapshot.isDragging && (
                        <div className={styles.sourceItemPlaceholder}>
                          <RenderSourceItem
                            fieldItem={fieldItem}
                            index={index}
                          />
                        </div>
                      )}
                    </div>
                  )}
                </Draggable>
              ))}
              <div className={styles.hidden}>{provided.placeholder}</div>
            </div>
          )}
        </StrictModeDroppable>
      </div>
    </div>
  );
}

export default FormTemplateBuilderDefaultQuestions;
