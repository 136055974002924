import React from 'react';
import classNames from 'classnames';
import IconButton, {
  IconButtonTypes,
} from '@lib/components/IconButton/IconButton';
import { Values } from '@lib/interfaces/form';
import styles from './DashboardInfoFilter.module.scss';

type Props = {
  children: React.ReactNode;
  onRemove: () => void;
  parentValue: Values[];
};

function SiteAndAreaFieldOption(props: Props) {
  const { children, onRemove, parentValue } = props;
  const isDeleteBtnVisible = parentValue?.length > 1;
  return (
    <div
      className={classNames(styles.optionWrap, {
        [styles.optionWrapWithDelete]: isDeleteBtnVisible,
      })}
    >
      {children}
      {isDeleteBtnVisible && (
        <IconButton
          icon="close"
          rounded
          type={IconButtonTypes.TertiaryFilled}
          onClick={onRemove}
          className={styles.optionDeleteBtn}
        />
      )}
    </div>
  );
}

export default SiteAndAreaFieldOption;
