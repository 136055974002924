import React from 'react';
import { TFunction } from 'i18next';
import { PrefilledQuestion } from 'components/FormTemplateBuilder/types';
import {
  defaultFieldParams,
  defaultQuestionParams,
} from 'components/FormTemplateBuilder/constants';
import {
  FormTemplateQuestionFieldOptionsValidityHintEnum,
  FormTemplateQuestionFieldsKindEnum,
  GroupEnum,
} from 'graphql-common';
import FormTemplateFieldNames from '@lib/enums/fieldNames/formTemplateFieldNames';
import getValidityHintOptionByValue from 'components/FormTemplateBuilder/utils/getValidityHintOptionByValue';
import SingleLine from 'assets/images/field-single-line.svg?react';
import MultiLine from 'assets/images/field-multi-line.svg?react';
import YesNo from 'assets/images/field-yes-no.svg?react';

const successValidityHintOption = getValidityHintOptionByValue(
  FormTemplateQuestionFieldOptionsValidityHintEnum.Success,
);

type Args = {
  t: TFunction<'translation', undefined>;
};

const getPrefilledQuestions: (props: Args) => PrefilledQuestion[] = ({
  t,
}: Args) => [
  {
    ...defaultQuestionParams,
    id: FormTemplateQuestionFieldsKindEnum.Section,
    icon: 'add',
    nameTranslationKey: 'add-section',
    [FormTemplateFieldNames.Fields]: [
      {
        [FormTemplateFieldNames.Kind]:
          FormTemplateQuestionFieldsKindEnum.Section,
        ...defaultFieldParams,
      },
    ],
  },
  {
    ...defaultQuestionParams,
    id: FormTemplateQuestionFieldsKindEnum.Input,
    renderCustomIcon: () => <SingleLine />,
    nameTranslationKey: 'single-line',
    [FormTemplateFieldNames.Fields]: [
      {
        [FormTemplateFieldNames.Kind]: FormTemplateQuestionFieldsKindEnum.Input,
        ...defaultFieldParams,
      },
    ],
  },
  {
    ...defaultQuestionParams,
    id: FormTemplateQuestionFieldsKindEnum.TextArea,
    renderCustomIcon: () => <MultiLine />,
    nameTranslationKey: 'multi-line',
    [FormTemplateFieldNames.Fields]: [
      {
        [FormTemplateFieldNames.Kind]:
          FormTemplateQuestionFieldsKindEnum.TextArea,
        ...defaultFieldParams,
      },
    ],
  },
  {
    ...defaultQuestionParams,
    id: FormTemplateQuestionFieldsKindEnum.RadioButton,
    icon: 'radio_button_checked',
    nameTranslationKey: 'single-select',
    [FormTemplateFieldNames.Fields]: [
      {
        [FormTemplateFieldNames.Kind]:
          FormTemplateQuestionFieldsKindEnum.RadioButton,
        [FormTemplateFieldNames.Required]: false,
        [FormTemplateFieldNames.Options]: [
          {
            [FormTemplateFieldNames.Values]: ['Option 1'],
            [FormTemplateFieldNames.Condition]: undefined,
            [FormTemplateFieldNames.Group]: undefined,
            [FormTemplateFieldNames.ValidityHint]: {
              value: FormTemplateQuestionFieldOptionsValidityHintEnum.Success,
              label: FormTemplateQuestionFieldOptionsValidityHintEnum.Success,
            },
          },
          {
            [FormTemplateFieldNames.Values]: ['Option 2'],
            [FormTemplateFieldNames.Condition]: undefined,
            [FormTemplateFieldNames.Group]: undefined,
            [FormTemplateFieldNames.ValidityHint]: {
              value: FormTemplateQuestionFieldOptionsValidityHintEnum.Success,
              label: FormTemplateQuestionFieldOptionsValidityHintEnum.Success,
            },
          },
        ],
      },
    ],
  },
  {
    ...defaultQuestionParams,
    id: FormTemplateQuestionFieldsKindEnum.Checkbox,
    icon: 'check_box',
    nameTranslationKey: 'multi-select',
    [FormTemplateFieldNames.Fields]: [
      {
        [FormTemplateFieldNames.Kind]:
          FormTemplateQuestionFieldsKindEnum.Checkbox,
        [FormTemplateFieldNames.Required]: false,
        [FormTemplateFieldNames.Options]: [
          {
            [FormTemplateFieldNames.Values]: ['Option 1'],
            [FormTemplateFieldNames.Condition]: undefined,
            [FormTemplateFieldNames.Group]: undefined,
            [FormTemplateFieldNames.ValidityHint]: {
              value: FormTemplateQuestionFieldOptionsValidityHintEnum.Success,
              label: FormTemplateQuestionFieldOptionsValidityHintEnum.Success,
            },
          },
          {
            [FormTemplateFieldNames.Values]: ['Option 2'],
            [FormTemplateFieldNames.Condition]: undefined,
            [FormTemplateFieldNames.Group]: undefined,
            [FormTemplateFieldNames.ValidityHint]: {
              value: FormTemplateQuestionFieldOptionsValidityHintEnum.Success,
              label: FormTemplateQuestionFieldOptionsValidityHintEnum.Success,
            },
          },
        ],
      },
    ],
  },
  {
    ...defaultQuestionParams,
    id: FormTemplateQuestionFieldsKindEnum.SingleSelect,
    icon: 'arrow_drop_down_circle',
    nameTranslationKey: 'dropdown',
    [FormTemplateFieldNames.Fields]: [
      {
        [FormTemplateFieldNames.Kind]:
          FormTemplateQuestionFieldsKindEnum.SingleSelect,
        [FormTemplateFieldNames.Required]: false,
        [FormTemplateFieldNames.Options]: [
          {
            [FormTemplateFieldNames.Values]: ['Option 1'],
            [FormTemplateFieldNames.Condition]: undefined,
            [FormTemplateFieldNames.Group]: undefined,
            [FormTemplateFieldNames.ValidityHint]: {
              value: FormTemplateQuestionFieldOptionsValidityHintEnum.Success,
              label: FormTemplateQuestionFieldOptionsValidityHintEnum.Success,
            },
          },
          {
            [FormTemplateFieldNames.Values]: ['Option 2'],
            [FormTemplateFieldNames.Condition]: undefined,
            [FormTemplateFieldNames.Group]: undefined,
            [FormTemplateFieldNames.ValidityHint]: {
              value: FormTemplateQuestionFieldOptionsValidityHintEnum.Success,
              label: FormTemplateQuestionFieldOptionsValidityHintEnum.Success,
            },
          },
        ],
      },
    ],
  },
  {
    ...defaultQuestionParams,
    id: FormTemplateQuestionFieldsKindEnum.YesNo,
    renderCustomIcon: () => <YesNo />,
    nameTranslationKey: 'yes-no',
    [FormTemplateFieldNames.Fields]: [
      {
        [FormTemplateFieldNames.Kind]: FormTemplateQuestionFieldsKindEnum.YesNo,
        [FormTemplateFieldNames.Required]: false,
        [FormTemplateFieldNames.Options]: [
          {
            [FormTemplateFieldNames.Values]: [t('yes')],
            [FormTemplateFieldNames.Condition]: undefined,
            [FormTemplateFieldNames.Group]: GroupEnum.Yes,
            [FormTemplateFieldNames.ValidityHint]: {
              value: FormTemplateQuestionFieldOptionsValidityHintEnum.Success,
              label: FormTemplateQuestionFieldOptionsValidityHintEnum.Success,
            },
          },
          {
            [FormTemplateFieldNames.Values]: [t('no')],
            [FormTemplateFieldNames.Condition]: undefined,
            [FormTemplateFieldNames.Group]: GroupEnum.No,
            [FormTemplateFieldNames.ValidityHint]: {
              value: FormTemplateQuestionFieldOptionsValidityHintEnum.Failure,
              label: FormTemplateQuestionFieldOptionsValidityHintEnum.Failure,
            },
          },
        ],
      },
    ],
  },
  {
    ...defaultQuestionParams,
    id: FormTemplateQuestionFieldsKindEnum.NumberInput,
    icon: '123',
    nameTranslationKey: 'number-value',
    [FormTemplateFieldNames.Fields]: [
      {
        [FormTemplateFieldNames.Kind]:
          FormTemplateQuestionFieldsKindEnum.NumberInput,
        ...defaultFieldParams,
      },
    ],
  },
  {
    ...defaultQuestionParams,
    id: FormTemplateQuestionFieldsKindEnum.MinMax,
    icon: 'arrow_range',
    nameTranslationKey: 'number-range',
    [FormTemplateFieldNames.Fields]: [
      {
        [FormTemplateFieldNames.Kind]:
          FormTemplateQuestionFieldsKindEnum.MinMax,
        ...defaultFieldParams,
      },
    ],
  },
  {
    ...defaultQuestionParams,
    id: FormTemplateQuestionFieldsKindEnum.Date,
    icon: 'calendar_today',
    nameTranslationKey: 'date',
    [FormTemplateFieldNames.Fields]: [
      {
        [FormTemplateFieldNames.Kind]: FormTemplateQuestionFieldsKindEnum.Date,
        ...defaultFieldParams,
      },
    ],
  },
  {
    ...defaultQuestionParams,
    id: FormTemplateQuestionFieldsKindEnum.DateRange,
    icon: 'calendar_month',
    nameTranslationKey: 'date-range',
    [FormTemplateFieldNames.Fields]: [
      {
        [FormTemplateFieldNames.Kind]:
          FormTemplateQuestionFieldsKindEnum.DateRange,
        ...defaultFieldParams,
      },
    ],
  },
  {
    ...defaultQuestionParams,
    id: FormTemplateQuestionFieldsKindEnum.Time,
    icon: 'schedule',
    nameTranslationKey: 'time',
    [FormTemplateFieldNames.Fields]: [
      {
        [FormTemplateFieldNames.Kind]: FormTemplateQuestionFieldsKindEnum.Time,
        ...defaultFieldParams,
      },
    ],
  },
  {
    ...defaultQuestionParams,
    id: FormTemplateQuestionFieldsKindEnum.TimeRange,
    icon: 'timelapse',
    nameTranslationKey: 'time-range',
    [FormTemplateFieldNames.Fields]: [
      {
        [FormTemplateFieldNames.Kind]:
          FormTemplateQuestionFieldsKindEnum.TimeRange,
        ...defaultFieldParams,
      },
    ],
  },
  {
    ...defaultQuestionParams,
    id: FormTemplateQuestionFieldsKindEnum.Datetime,
    icon: 'calendar_clock',
    nameTranslationKey: 'date-and-time',
    [FormTemplateFieldNames.Fields]: [
      {
        [FormTemplateFieldNames.Kind]:
          FormTemplateQuestionFieldsKindEnum.Datetime,
        ...defaultFieldParams,
      },
    ],
  },
  {
    ...defaultQuestionParams,
    id: FormTemplateQuestionFieldsKindEnum.CheckboxButtonGrid,
    icon: 'view_list',
    nameTranslationKey: 'input-table',
    [FormTemplateFieldNames.Fields]: [
      {
        [FormTemplateFieldNames.Kind]:
          FormTemplateQuestionFieldsKindEnum.CheckboxButtonGrid,
        [FormTemplateFieldNames.Required]: false,
        [FormTemplateFieldNames.Options]: [
          {
            [FormTemplateFieldNames.Condition]: undefined,
            [FormTemplateFieldNames.Group]: GroupEnum.Rows,
            position: 0,
            [FormTemplateFieldNames.ValidityHint]: undefined,
            [FormTemplateFieldNames.Values]: ['Row 1'],
          },
          {
            [FormTemplateFieldNames.Condition]: undefined,
            [FormTemplateFieldNames.Group]: GroupEnum.Rows,
            position: 1,
            [FormTemplateFieldNames.ValidityHint]: undefined,
            [FormTemplateFieldNames.Values]: ['Row 2'],
          },
          {
            [FormTemplateFieldNames.Condition]: undefined,
            [FormTemplateFieldNames.Group]: GroupEnum.Columns,
            position: 2,
            [FormTemplateFieldNames.ValidityHint]: undefined,
            [FormTemplateFieldNames.Values]: ['Column 1'],
          },
          {
            [FormTemplateFieldNames.Condition]: undefined,
            [FormTemplateFieldNames.Group]: GroupEnum.Columns,
            position: 3,
            [FormTemplateFieldNames.ValidityHint]: undefined,
            [FormTemplateFieldNames.Values]: ['Column 2'],
          },
        ],
        validityHintConfig: {
          '0-2': successValidityHintOption,
          '0-3': successValidityHintOption,
          '1-2': successValidityHintOption,
          '1-3': successValidityHintOption,
        },
      },
    ],
  },
  {
    ...defaultQuestionParams,
    id: FormTemplateQuestionFieldsKindEnum.Rating,
    icon: 'star',
    nameTranslationKey: 'rating',
    [FormTemplateFieldNames.Fields]: [
      {
        [FormTemplateFieldNames.Kind]:
          FormTemplateQuestionFieldsKindEnum.Rating,
        ...defaultFieldParams,
      },
    ],
  },
  {
    ...defaultQuestionParams,
    id: FormTemplateQuestionFieldsKindEnum.Attachment,
    icon: 'image',
    nameTranslationKey: 'attachment',
    [FormTemplateFieldNames.Fields]: [
      {
        [FormTemplateFieldNames.Kind]:
          FormTemplateQuestionFieldsKindEnum.Attachment,
        ...defaultFieldParams,
      },
    ],
  },
  {
    ...defaultQuestionParams,
    id: FormTemplateQuestionFieldsKindEnum.DecisionBox,
    icon: 'check_box_outline_blank',
    nameTranslationKey: 'decision-box',
    [FormTemplateFieldNames.Fields]: [
      {
        [FormTemplateFieldNames.Kind]:
          FormTemplateQuestionFieldsKindEnum.DecisionBox,
        [FormTemplateFieldNames.Required]: false,
        [FormTemplateFieldNames.Options]: [
          {
            [FormTemplateFieldNames.Values]: [''],
            [FormTemplateFieldNames.Condition]: undefined,
            [FormTemplateFieldNames.Group]: undefined,
            [FormTemplateFieldNames.ValidityHint]: undefined,
          },
        ],
      },
    ],
  },
  {
    ...defaultQuestionParams,
    id: FormTemplateQuestionFieldsKindEnum.ImportantNotice,
    icon: 'warning',
    nameTranslationKey: 'important-notice',
    [FormTemplateFieldNames.Fields]: [
      {
        [FormTemplateFieldNames.Kind]:
          FormTemplateQuestionFieldsKindEnum.ImportantNotice,
        [FormTemplateFieldNames.Required]: false,
        [FormTemplateFieldNames.Options]: [
          {
            [FormTemplateFieldNames.Values]: [''],
            [FormTemplateFieldNames.Condition]: undefined,
            [FormTemplateFieldNames.Group]: undefined,
            [FormTemplateFieldNames.ValidityHint]: undefined,
          },
        ],
      },
    ],
  },
];

export default getPrefilledQuestions;
