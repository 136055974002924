import { LazyQueryExecFunction } from '@apollo/client';

import {
  SiteArea,
  SiteAreaFilterInputObject,
  SiteAreasSearchListQuery,
  SiteAreasSearchListQueryVariables,
  SortDirectionEnum,
} from 'graphql-common';
import { DEFAULT_SEARCH_LIST_VARIABLES } from '@lib/enums/urls';
import getLoadOptionsResult from '@lib/utils/getLoadOptionsResult';
import createSearchFilters from '@lib/utils/createSearchFilters';
import { LoadOptionsParams } from '@lib/components/Select/types';

type FetchLazyQuery = LazyQueryExecFunction<SiteAreasSearchListQuery, any>;

const fetchSearchList = async (
  searchQuery: string,
  fetchLazyQuery: FetchLazyQuery,
  page: number,
  siteId?: string,
) => {
  if (!siteId) return [];
  const options: { variables: SiteAreasSearchListQueryVariables } = {
    variables: {
      ...DEFAULT_SEARCH_LIST_VARIABLES,
      page,
      siteId,
      sorts: { name: SortDirectionEnum.Asc },
    },
  };
  const filters: SiteAreaFilterInputObject[] = [];
  if (searchQuery) {
    filters.push(
      ...createSearchFilters(searchQuery, [
        { fieldName: 'name' },
        { fieldName: 'code' },
      ]),
    );
  }
  if (filters.length && options.variables) {
    options.variables.filters = filters;
  }
  const { data } = await fetchLazyQuery(options);
  return getLoadOptionsResult(
    data,
    (dataItem: SiteArea) => ({
      value: dataItem.id,
      label: dataItem.name,
      data: dataItem,
    }),
    searchQuery,
  );
};

export const getSiteAreasLoadOptions =
  (fetchLazyQuery: FetchLazyQuery, siteId?: string) =>
  (searchQuery: string, loadedOptions: unknown, params: LoadOptionsParams) =>
    fetchSearchList(searchQuery, fetchLazyQuery, params?.page || 1, siteId);
