import React from 'react';
import _get from 'lodash/get';
import {
  FormTemplateQuestion,
  FormTemplateQuestionFieldsKindEnum,
} from 'graphql-common';
import WhiteBox from '@lib/components/WhiteBox/WhiteBox';
import FormTemplateFieldNames from '@lib/enums/fieldNames/formTemplateFieldNames';
import QuestionTypeChip from 'components/FormTemplateBuilder/QuestionTypeChip/QuestionTypeChip';
import QuestionFieldsPreview from 'components/FormTemplateBuilder/QuestionFieldsPreview/QuestionFieldsPreview';
import FormTemplateBuilderQuestionView from 'components/FormTemplateBuilder/FormTemplateBuilderQuestionView/FormTemplateBuilderQuestionView';
import { Values } from '@lib/interfaces/form';
import styles from './FormTemplateBuilderView.module.scss';

interface Props {
  formTemplateVersionData: Values;
}

export default function FormTemplateBuilderQuestionsView(props: Props) {
  const { formTemplateVersionData } = props;

  const questions = _get(
    formTemplateVersionData,
    'questions',
    [],
  ) as FormTemplateQuestion[];

  return (
    questions?.length > 0 &&
    questions.map((question) => {
      const kind = _get(question, [
        FormTemplateFieldNames.Fields,
        0,
        FormTemplateFieldNames.Kind,
      ]);
      return (
        <WhiteBox className={styles.questionBlock} key={question.position}>
          <QuestionTypeChip
            kind={kind}
            className={
              kind === FormTemplateQuestionFieldsKindEnum.ImportantNotice
                ? styles.questionTypeChip
                : undefined
            }
          />
          <FormTemplateBuilderQuestionView value={question}>
            <QuestionFieldsPreview fields={question.fields} value={question} />
          </FormTemplateBuilderQuestionView>
        </WhiteBox>
      );
    })
  );
}
