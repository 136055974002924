import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Sites from 'components/Sites/Sites';
import BreadCrumbs from '@lib/components/BreadCrumbs/BreadCrumbs';
import usePaginationParams from '@lib/hooks/usePaginationParams';
import useSearchParam from '@lib/hooks/useSearchParam';
import useSitesQueryHook from 'utils/fetch/useSitesQueryHook';
import useOutletContext, { ContextProps } from 'hooks/useOutletContext';
import { getBreadCrumbsDataByKey } from 'utils/breadcrumbs';
import { ComponentEnum, LevelEnum } from 'graphql-common';

function SitesContainer() {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [searchQuery] = useSearchParam();
  const [paginationParams] = usePaginationParams();

  const { getUserAccessByComponent }: ContextProps = useOutletContext();
  const isViewOnly =
    getUserAccessByComponent(ComponentEnum.SitesManagement) === LevelEnum.Read;

  // Sites
  const {
    collection,
    sitesError,
    sitesFirstLoading,
    sitesLoading,
    totalCount,
  } = useSitesQueryHook({
    paginationParams,
    searchQuery,
  });

  return (
    <>
      <BreadCrumbs data={getBreadCrumbsDataByKey({ pathname, t })} />
      <Sites
        collection={collection}
        sitesError={sitesError}
        sitesFirstLoading={sitesFirstLoading}
        sitesLoading={sitesLoading}
        totalEntries={totalCount}
        isViewOnly={isViewOnly}
      />
    </>
  );
}

export default SitesContainer;
