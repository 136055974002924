import {
  Attachment,
  FormTemplate,
  FormTemplateQuestion,
  FormTemplateQuestionField,
  FormTemplateQuestionFieldOption,
  FormTemplateQuestionFieldValidityHintConfigInputObject,
  FormTemplateVersion,
} from 'graphql-common';
import _get from 'lodash/get';
import _pick from 'lodash/pick';
import _sortBy from 'lodash/sortBy';
import _isEmpty from 'lodash/isEmpty';
import { Values } from '@lib/interfaces/form';
import replaceNullsWithUndefined from '@lib/utils/replaceNullsWithUndefined';
import FormTemplateFieldNames from '@lib/enums/fieldNames/formTemplateFieldNames';
import getValidityHintOptionByValue from 'components/FormTemplateBuilder/utils/getValidityHintOptionByValue';
import getValidityHintOptions from 'components/FormTemplateBuilder/utils/getValidityHintOptions';

const emptyDefaultValues = {
  name: '',
  description: '',
  questions: [],
};

function getQuestionValidityHintConfig(
  validityHintConfig: FormTemplateQuestionFieldValidityHintConfigInputObject[],
) {
  const config = {};
  validityHintConfig.forEach(({ optionPositions, validityHint }) => {
    if (validityHint && optionPositions.length) {
      config[optionPositions.join('-')] =
        getValidityHintOptionByValue(validityHint);
    }
  });
  return config;
}

function getQuestionFieldOptionsValues(
  options: FormTemplateQuestionFieldOption[],
) {
  if (!options) return [];
  return _sortBy(
    options.map((option) => ({
      validityHint: getValidityHintOptions().find(
        ({ value }) =>
          value?.toLowerCase() === option.validityHint?.toLowerCase(),
      ),
      ..._pick(option, [
        FormTemplateFieldNames.Id,
        FormTemplateFieldNames.Values,
        FormTemplateFieldNames.Condition,
        FormTemplateFieldNames.Group,
        FormTemplateFieldNames.Position,
      ]),
    })),
    FormTemplateFieldNames.Position,
  );
}

function getQuestionFieldsValues(fields: FormTemplateQuestionField[]) {
  return _sortBy(
    fields.map((field) => {
      return {
        [FormTemplateFieldNames.Options]: getQuestionFieldOptionsValues(
          field?.options,
        ),
        [FormTemplateFieldNames.ValidityHintConfig]:
          getQuestionValidityHintConfig(field?.validityHintConfig),
        [FormTemplateFieldNames.AllowDisplayLastAnswer]:
          _get(field, [
            FormTemplateFieldNames.Settings,
            FormTemplateFieldNames.AllowDisplayLastAnswer,
          ]) || false,
        [FormTemplateFieldNames.LimitToOneRespPerRow]:
          _get(field, [
            FormTemplateFieldNames.Settings,
            FormTemplateFieldNames.LimitToOneRespPerRow,
          ]) || false,
        [FormTemplateFieldNames.LimitToOneRespPerColumn]:
          _get(field, [
            FormTemplateFieldNames.Settings,
            FormTemplateFieldNames.LimitToOneRespPerColumn,
          ]) || false,
        ..._pick(field, [
          FormTemplateFieldNames.Id,
          FormTemplateFieldNames.Kind,
          FormTemplateFieldNames.Required,
          FormTemplateFieldNames.Position,
        ]),
      };
    }),
    FormTemplateFieldNames.Position,
  );
}

function getAttachmentsAttachedValues(
  attachmentsAttached: Attachment[],
  isApply?: boolean,
) {
  if (!attachmentsAttached?.length) return [];
  return attachmentsAttached.map((attachment) => {
    const filename = _get(attachment, 'filename');
    const data = _get(attachment, ['fileData', 'data']);
    const url = _get(attachment, ['fileData', 'url']);
    if (isApply && data && filename) {
      return {
        filename,
        file: { ...data },
        url,
      };
    }
    return attachment;
  });
}

export function getQuestionsValues(
  questions: FormTemplateQuestion[],
  isApply?: boolean,
) {
  if (!questions) return [];
  return _sortBy(
    questions.map((question) => ({
      ..._pick(question, [
        FormTemplateFieldNames.Id,
        FormTemplateFieldNames.Name,
        FormTemplateFieldNames.Description,
        FormTemplateFieldNames.AttachmentsAttached,
        FormTemplateFieldNames.Position,
      ]),
      attachmentsAttached: getAttachmentsAttachedValues(
        question?.attachmentsAttached,
        isApply,
      ),
      showDescription: !!question.description,
      showAttachments: !!question.attachmentsAttached?.length,
      required: question.fields.some(({ required }) => required),
      fields: getQuestionFieldsValues(question.fields),
    })),
    FormTemplateFieldNames.Position,
  );
}

export function getFormTemplateVersionValuesFromQueryData(
  formTemplateVersionData?: FormTemplateVersion,
  isApply?: boolean,
): Values {
  if (!formTemplateVersionData) return { questions: [] };
  return replaceNullsWithUndefined({
    canArchive: formTemplateVersionData.canArchive?.value,
    canDestroy: formTemplateVersionData.canDestroy?.value,
    canRestore: formTemplateVersionData.canRestore?.value,
    canUpdate: formTemplateVersionData.canUpdate?.value,
    createdAt: formTemplateVersionData.createdAt,
    [FormTemplateFieldNames.Id]: formTemplateVersionData.id,
    [FormTemplateFieldNames.Questions]: getQuestionsValues(
      formTemplateVersionData.questions,
      isApply,
    ),
    status: formTemplateVersionData.status,
    archivedAt: formTemplateVersionData.archivedAt,
    [FormTemplateFieldNames.Version]: formTemplateVersionData.version,
    [FormTemplateFieldNames.VisibleLinkedTasksCount]:
      formTemplateVersionData.visibleLinkedTasksCount,
  });
}

export default function getFormTemplateValuesFromQueryData(args: {
  formTemplateData?: FormTemplate;
}): Values {
  const { formTemplateData } = args;
  if (_isEmpty(formTemplateData)) return emptyDefaultValues;
  const categoryColor = _get(formTemplateData, 'category.color');
  const categoryId = _get(formTemplateData, 'category.id');
  const categoryName = _get(formTemplateData, 'category.name');
  const description = _get(formTemplateData, 'description');
  return replaceNullsWithUndefined({
    canArchive: formTemplateData.canArchive?.value,
    canDestroy: formTemplateData.canDestroy?.value,
    canRestore: formTemplateData.canRestore?.value,
    canUpdate: formTemplateData.canUpdate?.value,
    archivedAt: formTemplateData.archivedAt,
    [FormTemplateFieldNames.Category]: {
      value: categoryId,
      label: categoryName,
      color: categoryColor,
    },
    [FormTemplateFieldNames.Description]: _isEmpty(description)
      ? ''
      : description,
    [FormTemplateFieldNames.Id]: _get(formTemplateData, 'id'),
    [FormTemplateFieldNames.Name]: _get(formTemplateData, 'name'),
    [FormTemplateFieldNames.Public]: _get(formTemplateData, 'public'),
    [FormTemplateFieldNames.CurrentVersion]: _get(
      formTemplateData,
      'currentVersion',
    ),
    [FormTemplateFieldNames.VisibleLinkedTasksCount]: _get(
      formTemplateData,
      'visibleLinkedTasksCount',
    ),
  });
}
