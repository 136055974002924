import React from 'react';
import AssetView from 'components/Asset/AssetView/AssetView';
import { FormRefType } from '@lib/components/ReactHookForm/types';
import { AssetViewMode } from './enums';
import { UseSearchListQueriesResult } from './utils/useSearchListQueries';
import { UseModalManagementResult } from './utils/useModalManagement';
import { UseActionsResult } from './utils/useActions';
import useAssetActions from './utils/useAssetActions';

type Props = {
  formRef: FormRefType;
  menuBar: JSX.Element;
  useActionsResult: UseActionsResult;
  useModalManagementResult: UseModalManagementResult;
  useSearchListQueriesResult: UseSearchListQueriesResult;
  viewMode: AssetViewMode;
  isViewOnly: boolean;
};

export default function AssetDetailsContainer(props: Props) {
  const {
    formRef,
    menuBar,
    useActionsResult,
    useModalManagementResult,
    useSearchListQueriesResult,
    viewMode,
    isViewOnly,
  } = props;

  const useAssetActionsResult = useAssetActions({
    formRef,
    useModalManagementResult,
    viewMode,
    useActionsResult,
  });

  return (
    <AssetView
      menuBar={menuBar}
      useActionsResult={useActionsResult}
      useAssetActionsResult={useAssetActionsResult}
      useModalManagementResult={useModalManagementResult}
      useSearchListQueriesResult={useSearchListQueriesResult}
      isViewOnly={isViewOnly}
    />
  );
}
