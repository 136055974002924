import React from 'react';
import { TFunction } from 'i18next';
import { Documentation } from 'graphql-common';
import TextCell from '@lib/components/Table/commonComponents/TextCell/TextCell';
import { CellProps } from '@lib/components/Table/types';
import { getFormattedDate } from '@lib/utils/dateTimeHelpers';
import { dateTimeFormatEU } from '@lib/enums/dateTime';
import UserPreviewCell from '@lib/components/Table/commonComponents/UserPreviewCell/UserPreviewCell';
import CollapseCell from '@lib/components/Table/commonComponents/CollapseCell/CollapseCell';
import Chip, { ChipTypes } from '@lib/components/Chip/Chip';
import DocumentationPreview from '@lib/components/DocumentationPreview/DocumentationPreview';

type ColumnsArgs = {
  onOpen: (item: Documentation | null) => void;
  onTagSelect: (tag: string) => void;
  t: TFunction<'translation', undefined>;
};

export default function getTableColumns({
  onOpen,
  onTagSelect,
  t,
}: ColumnsArgs) {
  return [
    {
      id: 'name',
      Header: t('document-name'),
      Cell: ({ item }: CellProps<Documentation>) => (
        <DocumentationPreview
          attachment={item.attachmentAttached}
          customName={item.name}
          onClick={() => onOpen(item)}
        />
      ),
      minWidth: 350,
      maxWidth: 350,
      width: 350,
      sortable: true,
    },
    {
      id: 'tags',
      Header: t('tags'),
      accessorFn: ({ item }: CellProps<Documentation>) => {
        const { tags } = item;
        return tags?.map(({ id, name }) => (
          <Chip
            key={id}
            type={ChipTypes.chipBlack}
            label={name}
            onClick={() => onTagSelect(name)}
          />
        ));
      },
      Cell: (props: CellProps<Documentation>) => (
        <CollapseCell {...props} minHeight={24} />
      ),
      maxWidth: 350,
    },
    {
      id: 'createdAt',
      Header: t('uploading-date'),
      accessorFn: ({ item }: CellProps<Documentation>) =>
        getFormattedDate(item.createdAt, dateTimeFormatEU),
      Cell: (props: CellProps<Documentation>) => <TextCell {...props} />,
      minWidth: 150,
      maxWidth: 150,
      width: 150,
      sortable: true,
    },
    {
      id: 'createdByFullName',
      Header: t('name'),
      Cell: (props: CellProps<Documentation>) => {
        const { item } = props;
        const user = item.createdBy;
        if (!user) return <TextCell {...props} value="-" />;
        return (
          <UserPreviewCell
            avatar={
              user.avatarAttached?.urls?.small ||
              user.avatarAttached?.urls?.original
            }
            name={`${user.firstName} ${user.lastName}`}
            email={user.email}
          />
        );
      },
      minWidth: 308,
      width: 308,
      sortable: true,
    },
  ];
}
