import { RouteBuilder } from '@lib/utils/routeBuilder';
import {
  DEFAULT_LIST_PARAMS,
  GetUrlParams,
  PATH_ACCESS,
  PATH_ACTION_ID,
  PATH_ACTIONS,
  PATH_ADD,
  PATH_APPROVALS,
  PATH_AREAS,
  PATH_ASSETS,
  PATH_AUTH,
  PATH_CATEGORIES,
  PATH_CODE_OF_CONDUCT,
  PATH_COUNTER,
  PATH_COUNTERS,
  PATH_DASHBOARD,
  PATH_DOCUMENT_ID,
  PATH_DOCUMENTATION,
  PATH_DUPLICATE,
  PATH_EDIT,
  PATH_FORM_TEMPLATES,
  PATH_ID,
  PATH_INTERVENTIONS,
  PATH_NOMENCLATURE,
  PATH_OVERVIEW,
  PATH_PROFILE,
  PATH_REPORTING,
  PATH_SETUP,
  PATH_SITES,
  PATH_TASKS,
  PATH_TEAMS,
  PATH_TIMELINE,
  PATH_USERS,
  PATH_VERSION_ID,
  PATH_VIEW,
  PER_PAGE_KEY_SITES,
} from '@lib/enums/urls';
import {
  FormTemplateScopeNameEnum,
  InterventionScopeNameEnum,
  TaskScopeNameEnum,
  TaskTimelineGroupByEnum,
} from 'graphql-common';

export const APP_URLS = {
  root: new RouteBuilder({
    path: '/',
  }),
  auth: {
    index: new RouteBuilder({
      path: `/${PATH_AUTH}`,
    }),
    login: new RouteBuilder({
      path: `/${PATH_AUTH}/login`,
    }),
    forgotPassword: new RouteBuilder({
      path: `/${PATH_AUTH}/forgot-password`,
    }),
    resetPassword: new RouteBuilder({
      path: `/${PATH_AUTH}/reset-password`,
    }),
    logout: new RouteBuilder({
      path: `/${PATH_AUTH}/logout`,
    }),
  },
  setup: {
    index: new RouteBuilder({
      path: `/${PATH_SETUP}`,
    }),
    finishRegistration: new RouteBuilder({
      path: `/${PATH_SETUP}/finish-registration`,
    }),
    confirmEmail: new RouteBuilder({
      path: `/${PATH_SETUP}/confirm-email`,
    }),
    updatePassword: new RouteBuilder({
      path: `/${PATH_SETUP}/update-password`,
    }),
  },
  dashboard: {
    index: new RouteBuilder({
      path: `/${PATH_DASHBOARD}`,
    }),
    overview: new RouteBuilder({
      path: `/${PATH_DASHBOARD}/${PATH_OVERVIEW}`,
      defaultParams: {
        ...DEFAULT_LIST_PARAMS,
        tab: TaskScopeNameEnum.DashboardAll,
      },
    }),
    timeline: new RouteBuilder({
      path: `/${PATH_DASHBOARD}/${PATH_TIMELINE}`,
      defaultParams: {
        ...DEFAULT_LIST_PARAMS,
        tab: TaskScopeNameEnum.TimelineAll,
        view: TaskTimelineGroupByEnum.Year,
      },
    }),
    profile: new RouteBuilder({
      path: `/${PATH_PROFILE}`,
    }),
    users: {
      index: new RouteBuilder({
        path: `/${PATH_USERS}`,
        defaultParams: {
          ...DEFAULT_LIST_PARAMS,
        },
      }),
      view: new RouteBuilder({
        path: `/${PATH_USERS}/${PATH_ID}/${PATH_VIEW}`,
      }),
      viewAccess: new RouteBuilder({
        path: `/${PATH_USERS}/${PATH_ID}/${PATH_VIEW}-${PATH_ACCESS}`,
      }),
      editAccess: new RouteBuilder({
        path: `/${PATH_USERS}/${PATH_ID}/${PATH_EDIT}-${PATH_ACCESS}`,
      }),
    },
    teams: {
      index: new RouteBuilder({
        path: `/${PATH_TEAMS}`,
        defaultParams: {
          ...DEFAULT_LIST_PARAMS,
        },
      }),
      add: new RouteBuilder({
        path: `/${PATH_TEAMS}/${PATH_ADD}`,
      }),
      edit: new RouteBuilder({
        path: `/${PATH_TEAMS}/${PATH_ID}/${PATH_EDIT}`,
      }),
      view: new RouteBuilder({
        path: `/${PATH_TEAMS}/${PATH_ID}/${PATH_VIEW}`,
      }),
      members: new RouteBuilder({
        path: `/${PATH_TEAMS}/${PATH_ID}/${PATH_USERS}`,
      }),
    },
    sites: {
      index: new RouteBuilder({
        path: `/${PATH_SITES}`,
        defaultParams: {
          ...DEFAULT_LIST_PARAMS,
          [GetUrlParams.PerPage]: PER_PAGE_KEY_SITES,
        },
      }),
      add: new RouteBuilder({
        path: `/${PATH_SITES}/${PATH_ADD}`,
      }),
      edit: new RouteBuilder({
        path: `/${PATH_SITES}/${PATH_ID}/${PATH_EDIT}`,
      }),
      viewCodeOfConduct: new RouteBuilder({
        path: `/${PATH_SITES}/${PATH_ID}/${PATH_VIEW}-${PATH_CODE_OF_CONDUCT}`,
      }),
      editCodeOfConduct: new RouteBuilder({
        path: `/${PATH_SITES}/${PATH_ID}/${PATH_EDIT}-${PATH_CODE_OF_CONDUCT}`,
      }),
      view: new RouteBuilder({
        path: `/${PATH_SITES}/${PATH_ID}/${PATH_VIEW}`,
      }),
      areas: new RouteBuilder({
        path: `/${PATH_SITES}/${PATH_ID}/${PATH_AREAS}`,
      }),
      users: new RouteBuilder({
        path: `/${PATH_SITES}/${PATH_ID}/${PATH_USERS}`,
      }),
    },
    formTemplates: {
      index: new RouteBuilder({
        path: `/${PATH_FORM_TEMPLATES}-${FormTemplateScopeNameEnum.Active.toLowerCase()}`,
        defaultParams: {
          ...DEFAULT_LIST_PARAMS,
        },
      }),
      archived: new RouteBuilder({
        path: `/${PATH_FORM_TEMPLATES}-${FormTemplateScopeNameEnum.Archived.toLowerCase()}`,
        defaultParams: {
          ...DEFAULT_LIST_PARAMS,
        },
      }),
      add: new RouteBuilder({
        path: `/${PATH_FORM_TEMPLATES}/${PATH_ADD}`,
      }),
      edit: new RouteBuilder({
        path: `/${PATH_FORM_TEMPLATES}/${PATH_ID}/${PATH_EDIT}`,
      }),
      editVersion: new RouteBuilder({
        path: `/${PATH_FORM_TEMPLATES}/${PATH_ID}/${PATH_EDIT}-version/${PATH_VERSION_ID}`,
      }),
      duplicate: new RouteBuilder({
        path: `/${PATH_FORM_TEMPLATES}/${PATH_ID}/${PATH_DUPLICATE}/${PATH_VERSION_ID}`,
      }),
      viewVersion: new RouteBuilder({
        path: `/${PATH_FORM_TEMPLATES}/${PATH_ID}/${PATH_VIEW}/${PATH_VERSION_ID}`,
      }),
      viewLinkedTasks: new RouteBuilder({
        path: `/${PATH_FORM_TEMPLATES}/${PATH_ID}/${PATH_VIEW}-linked-tasks/${PATH_VERSION_ID}`,
        defaultParams: {
          ...DEFAULT_LIST_PARAMS,
        },
      }),
      categories: new RouteBuilder({
        path: `/${PATH_FORM_TEMPLATES}/${PATH_CATEGORIES}`,
        defaultParams: {
          ...DEFAULT_LIST_PARAMS,
        },
      }),
    },
    assets: {
      index: new RouteBuilder({
        path: `/${PATH_ASSETS}`,
        defaultParams: {
          ...DEFAULT_LIST_PARAMS,
        },
      }),
      add: new RouteBuilder({
        path: `/${PATH_ASSETS}/${PATH_ADD}`,
      }),
      edit: new RouteBuilder({
        path: `/${PATH_ASSETS}/${PATH_ID}/${PATH_EDIT}`,
      }),
      view: new RouteBuilder({
        path: `/${PATH_ASSETS}/${PATH_ID}/${PATH_VIEW}`,
      }),
      nomenclature: new RouteBuilder({
        path: `/${PATH_ASSETS}/${PATH_ID}/${PATH_NOMENCLATURE}`,
        defaultParams: {
          ...DEFAULT_LIST_PARAMS,
        },
      }),
      counter: new RouteBuilder({
        path: `/${PATH_ASSETS}/${PATH_ID}/${PATH_COUNTER}`,
      }),
      documentation: new RouteBuilder({
        path: `/${PATH_ASSETS}/${PATH_ID}/${PATH_DOCUMENTATION}`,
      }),
      document: new RouteBuilder({
        path: `/${PATH_ASSETS}/${PATH_ID}/${PATH_DOCUMENTATION}/${PATH_DOCUMENT_ID}`,
      }),
      interventions: new RouteBuilder({
        path: `/${PATH_ASSETS}/${PATH_ID}/${PATH_INTERVENTIONS}`,
      }),
      categories: new RouteBuilder({
        path: `/${PATH_ASSETS}/${PATH_CATEGORIES}`,
        defaultParams: {
          ...DEFAULT_LIST_PARAMS,
        },
      }),
      counters: new RouteBuilder({
        path: `/${PATH_ASSETS}/${PATH_COUNTERS}`,
        defaultParams: {
          ...DEFAULT_LIST_PARAMS,
        },
      }),
    },
    tasks: {
      index: new RouteBuilder({
        path: `/${PATH_TASKS}`,
        defaultParams: {
          ...DEFAULT_LIST_PARAMS,
          tab: TaskScopeNameEnum.Active,
        },
      }),
      approvals: new RouteBuilder({
        path: `/${PATH_APPROVALS}`,
        defaultParams: {
          ...DEFAULT_LIST_PARAMS,
        },
      }),
      add: new RouteBuilder({
        path: `/${PATH_TASKS}/${PATH_ADD}`,
      }),
      edit: new RouteBuilder({
        path: `/${PATH_TASKS}/${PATH_ID}/${PATH_EDIT}`,
      }),
      duplicate: new RouteBuilder({
        path: `/${PATH_TASKS}/${PATH_ID}/${PATH_DUPLICATE}`,
      }),
      view: new RouteBuilder({
        path: `/${PATH_TASKS}/${PATH_ID}/${PATH_VIEW}`,
      }),
      formView: new RouteBuilder({
        path: `/${PATH_TASKS}/${PATH_ID}/form-${PATH_VIEW}`,
      }),
      reporting: new RouteBuilder({
        path: `/${PATH_TASKS}/${PATH_ID}/${PATH_REPORTING}`,
      }),
      interventions: new RouteBuilder({
        path: `/${PATH_TASKS}/${PATH_ID}/${PATH_INTERVENTIONS}`,
        defaultParams: {
          ...DEFAULT_LIST_PARAMS,
        },
      }),
    },
    interventions: {
      index: new RouteBuilder({
        path: `/${PATH_INTERVENTIONS}`,
        defaultParams: {
          ...DEFAULT_LIST_PARAMS,
          tab: InterventionScopeNameEnum.Opened,
        },
      }),
      add: new RouteBuilder({
        path: `/${PATH_INTERVENTIONS}/${PATH_ADD}`,
      }),
      edit: new RouteBuilder({
        path: `/${PATH_INTERVENTIONS}/${PATH_ID}/${PATH_EDIT}`,
      }),
      view: new RouteBuilder({
        path: `/${PATH_INTERVENTIONS}/${PATH_ID}/${PATH_VIEW}`,
      }),
      viewActions: new RouteBuilder({
        path: `/${PATH_INTERVENTIONS}/${PATH_ID}/${PATH_VIEW}-${PATH_ACTIONS}`,
        defaultParams: {
          ...DEFAULT_LIST_PARAMS,
        },
      }),
      actionAdd: new RouteBuilder({
        path: `/${PATH_INTERVENTIONS}/${PATH_ID}/${PATH_ACTIONS}/${PATH_ADD}`,
      }),
      actionEdit: new RouteBuilder({
        path: `/${PATH_INTERVENTIONS}/${PATH_ID}/${PATH_ACTIONS}/${PATH_ACTION_ID}/${PATH_EDIT}`,
      }),
      actionView: new RouteBuilder({
        path: `/${PATH_INTERVENTIONS}/${PATH_ID}/${PATH_ACTIONS}/${PATH_ACTION_ID}/${PATH_VIEW}`,
      }),
      categories: new RouteBuilder({
        path: `/${PATH_INTERVENTIONS}/${PATH_CATEGORIES}`,
        defaultParams: {
          ...DEFAULT_LIST_PARAMS,
        },
      }),
    },
    notFound: new RouteBuilder({
      path: `/not-found`,
    }),
    unauthorized: new RouteBuilder({
      path: `/unauthorized`,
    }),
    accessDenied: new RouteBuilder({
      path: `/access-denied`,
    }),
  },
  notFound: new RouteBuilder({
    path: '/not-found',
  }),
};
