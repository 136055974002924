import React from 'react';
import { TFunction } from 'i18next';
import { Counter, MeasureTypeEnum, MeasureUnitEnum } from 'graphql-common';
import TextCell from '@lib/components/Table/commonComponents/TextCell/TextCell';
import TextLinkCell from '@lib/components/Table/commonComponents/TextLinkCell/TextLinkCell';
import { CellProps } from '@lib/components/Table/types';
import Tooltip from '@lib/components/Tooltip/Tooltip';
import IconButton, {
  IconButtonTypes,
} from '@lib/components/IconButton/IconButton';
import { getMeasurementLabel } from '@lib/utils/measure';
import {
  convertDecimalHoursToTime,
  convertDecimalMinutesToTime,
  getFormattedDate,
} from '@lib/utils/dateTimeHelpers';
import { dateTimeFormatEU } from '@lib/enums/dateTime';
import convertToDecimal from '@lib/utils/convertToDecimal';

type ColumnsArgs = {
  getQrCode: (v: Counter) => void;
  onOpen: (counterId: string) => void;
  t: TFunction<'translation', undefined>;
};

export default function getTableColumns({ getQrCode, onOpen, t }: ColumnsArgs) {
  return [
    {
      id: 'code',
      Header: t('code'),
      accessor: 'code',
      Cell: (props: CellProps<Counter>) => (
        <TextLinkCell {...props} onClick={() => onOpen(props?.item?.id)} />
      ),
      minWidth: 150,
      maxWidth: 150,
      width: 150,
      sortable: true,
    },
    {
      id: 'name',
      Header: t('name'),
      accessor: 'name',
      Cell: (props: CellProps<Counter>) => (
        <TextLinkCell {...props} onClick={() => onOpen(props?.item?.id)} />
      ),
      maxWidth: 350,
      sortable: true,
    },
    {
      id: 'measurement',
      Header: t('measurement'),
      accessorFn: ({ item }: CellProps<Counter>) =>
        getMeasurementLabel(item.measureType, item.measureUnit, t),
      Cell: (props: CellProps<Counter>) => <TextCell {...props} />,
      minWidth: 150,
      width: 150,
    },
    {
      id: 'latestIndex',
      Header: t('latest-index'),
      accessorFn: ({ item }: CellProps<Counter>) => {
        if (
          item.measureType === MeasureTypeEnum.Time &&
          item.measureUnit === MeasureUnitEnum.Hour
        ) {
          return typeof item.latestIndex === 'number'
            ? convertDecimalHoursToTime(item.latestIndex)
            : undefined;
        }
        if (
          item.measureType === MeasureTypeEnum.Time &&
          item.measureUnit === MeasureUnitEnum.Minute
        ) {
          return typeof item.latestIndex === 'number'
            ? convertDecimalMinutesToTime(item.latestIndex)
            : undefined;
        }
        return item.latestIndex
          ? convertToDecimal(`${item.latestIndex}`, 2)
          : undefined;
      },
      Cell: (props: CellProps<Counter>) => <TextCell {...props} />,
      minWidth: 150,
      width: 150,
      sortable: true,
    },
    {
      id: 'lastCheckAt',
      Header: t('check-date'),
      accessorFn: ({ item }: CellProps<Counter>) =>
        getFormattedDate(item.lastCheckAt, dateTimeFormatEU),
      Cell: (props: CellProps<Counter>) => <TextCell {...props} />,
      minWidth: 150,
      width: 150,
      sortable: true,
    },
    {
      id: 'fullCode',
      Header: t('qr-code'),
      accessor: 'fullCode',
      Cell: (props: CellProps<Counter>) => <TextCell {...props} />,
      minWidth: 150,
      width: 150,
    },
    {
      id: 'qrCode',
      Header: '',
      accessor: 'qrCode',
      Cell: ({ item }: CellProps<Counter>) => (
        <div>
          <Tooltip body={t('download-qr-code')}>
            <IconButton
              type={IconButtonTypes.PrimaryFilled}
              onClick={() => getQrCode(item)}
              icon="download"
              symbolsOutlined
            />
          </Tooltip>
        </div>
      ),
      width: 1,
    },
  ];
}
