import React from 'react';
import { useTranslation } from 'react-i18next';
import { Site } from 'graphql-common';
import { APP_URLS } from 'constants/urls';
import { ApolloError } from '@apollo/client/errors';
import Button, { ButtonTypes } from '@lib/components/Button/Button';
import CircleLoader from '@lib/components/CircleLoader/CircleLoader';
import PageHeader from '@lib/layouts/DashboardLayout/PageHeader/PageHeader';
import Search from '@lib/components/Search/Search';
import PageBody from '@lib/layouts/DashboardLayout/PageBody/PageBody';
import SitePreview from 'components/SitePreview/SitePreview';
import Pagination from '@lib/components/Pagination/Pagination';
import useSearchParam from '@lib/hooks/useSearchParam';
import EmptyState from '@lib/components/EmptyState/EmptyState';
import CircleLoaderWrapper, {
  CircleLoaderWrapperType,
} from '@lib/components/CircleLoaderWrapper/CircleLoaderWrapper';
import ErrorPage from '@lib/components/ErrorPage/ErrorPage';
import getStatusFromError from '@lib/utils/getStatusFromError';
import ViewOnlyChip from '@lib/components/ViewOnlyChip/ViewOnlyChip';
import { IconName } from '@lib/components/Modal/enums';
import styles from './Sites.module.scss';

interface Props {
  collection: Site[];
  sitesError?: ApolloError;
  sitesFirstLoading: boolean;
  sitesLoading: boolean;
  totalEntries: number;
  isViewOnly: boolean;
}

function Sites(props: Props) {
  const {
    collection,
    sitesError,
    sitesFirstLoading,
    sitesLoading,
    totalEntries,
    isViewOnly,
  } = props;
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useSearchParam();
  const isContentVisible = !!totalEntries && !!collection.length;
  const isEmptyStateVisible = !isContentVisible && !sitesFirstLoading;
  const isPaginationVisible =
    !!totalEntries && !!collection.length && totalEntries > collection.length;
  const isEmptyState = isEmptyStateVisible && !searchQuery;
  const isEmptyStateByFilter = isEmptyStateVisible && !!searchQuery;

  if (sitesError) {
    return (
      <ErrorPage
        status={getStatusFromError(sitesError)}
        description={sitesError.message}
      />
    );
  }

  return (
    <>
      <PageHeader title={t('sites')}>
        {isViewOnly ? (
          <ViewOnlyChip />
        ) : (
          <Button
            buttonText={t('create-new-site')}
            leftIcon="add"
            to={APP_URLS.dashboard.sites.add.path}
          />
        )}
      </PageHeader>
      <PageBody>
        <>
          <div>
            {!isEmptyState && (
              <Search placeholder={t('sites-search-placeholder')} />
            )}
          </div>
          {isEmptyStateVisible ? (
            <div>
              {isEmptyStateByFilter && (
                <EmptyState
                  title={t('no-results-found')}
                  text={t('no-results-found-text')}
                  icon={IconName.SearchOff}
                  actions={[
                    <Button
                      key="back"
                      buttonText={t('go-back')}
                      buttonType={ButtonTypes.primaryFilled}
                      leftIcon="arrow_back"
                      onClick={() => setSearchQuery('')}
                    />,
                  ]}
                />
              )}
              {isEmptyState && (
                <EmptyState
                  title={t('no-sites-yet')}
                  text={t('no-sites-text')}
                  icon={IconName.Cancel}
                  actions={
                    isViewOnly
                      ? []
                      : [
                          <Button
                            key="back"
                            buttonText={t('create-site')}
                            buttonType={ButtonTypes.primaryFilled}
                            leftIcon="add"
                            to={APP_URLS.dashboard.sites.add.path}
                          />,
                        ]
                  }
                />
              )}
            </div>
          ) : (
            <div>
              {sitesFirstLoading ? (
                <CircleLoader />
              ) : (
                <CircleLoaderWrapper
                  isLoading={sitesLoading}
                  type={CircleLoaderWrapperType.blur}
                >
                  <div className={styles.sitesGrid}>
                    {collection.map((site) => (
                      <div key={site.id}>
                        <SitePreview {...site} />
                      </div>
                    ))}
                  </div>
                  {isPaginationVisible && (
                    <div className={styles.pagination}>
                      <Pagination totalEntries={totalEntries} />
                    </div>
                  )}
                </CircleLoaderWrapper>
              )}
            </div>
          )}
        </>
      </PageBody>
    </>
  );
}

export default Sites;
