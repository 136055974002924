import React from 'react';
import { useTranslation } from 'react-i18next';
import WhiteBox from '@lib/components/WhiteBox/WhiteBox';
import DashboardContentMaxWidth from '@lib/layouts/DashboardLayout/DashboardContentMaxWidth/DashboardContentMaxWidth';
import DashboardTitleHeader from '@lib/layouts/DashboardLayout/DashboardTitleHeader/DashboardTitleHeader';
import Button, { ButtonTypes } from '@lib/components/Button/Button';
import MaterialIcon from '@lib/components/MaterialIcon/MaterialIcon';
import Typography from '@lib/components/Typography/Typography';
import EntityViewStyles from '@lib/assets/styles/EntityView.module.scss';
import CircleLoaderWrapper from '@lib/components/CircleLoaderWrapper/CircleLoaderWrapper';
import styles from 'routes/Intervention/InterventionFormPage/InterventionFormPage.module.scss';
import { materialSymbolsOutlined } from '@lib/enums/common';
import {
  Modals,
  UseModalManagementResult,
} from '@lib/hooks/useModalManagement';
import formStyles from '@lib/assets/styles/Form.module.scss';
import { UseTeamQueriesResult } from 'routes/Teams/utils/useTeamQueries';
import ViewOnlyChip from '@lib/components/ViewOnlyChip/ViewOnlyChip';
import TextEditor from '@lib/components/TextEditor/TextEditor';
import _without from 'lodash/without';
import { UseTeamActionsResult } from '../utils/useTeamActions';
import { TeamViewMode } from '../enums';
import TeamAccessView from '../TeamAccess/TeamAccessView';

type Props = {
  useModalManagementResult: UseModalManagementResult;
  useTeamActionsResult: UseTeamActionsResult;
  useTeamQueriesResult: UseTeamQueriesResult;
  isViewOnly: boolean;
};

export default function TeamViewDetails({
  useModalManagementResult,
  useTeamActionsResult,
  useTeamQueriesResult,
  isViewOnly,
}: Props) {
  const { t } = useTranslation();
  const { loading, onViewChange } = useTeamActionsResult;
  const { useTeamQueryHookResult } = useTeamQueriesResult;
  const { data } = useTeamQueryHookResult;
  const { name, description, canDestroy, canUpdate, accesses } = data || {};
  const isDestroyAvailable = canDestroy?.value;
  const isUpdateAvailable = canUpdate?.value;
  const onDelete = () =>
    useModalManagementResult.openModal(Modals.TeamDestroyModal);

  return (
    <DashboardContentMaxWidth>
      <CircleLoaderWrapper isLoading={loading}>
        <WhiteBox>
          <DashboardTitleHeader
            title={name}
            actions={_without(
              [
                isUpdateAvailable && !isViewOnly ? (
                  <Button
                    key="edit"
                    buttonText={t('edit')}
                    buttonType={ButtonTypes.primaryOutlined}
                    onClick={() => onViewChange(TeamViewMode.Edit)}
                    leftIcon="edit"
                    disabled={loading}
                  />
                ) : undefined,
                isViewOnly ? <ViewOnlyChip /> : undefined,
              ],
              undefined,
            )}
          />
          {!!description && (
            <div className={EntityViewStyles.infoItemCol}>
              <p className={EntityViewStyles.infoItemTitle}>
                <MaterialIcon icon="description" symbolsOutlined />
                <Typography variant="caption">{t('description')}</Typography>
              </p>
              <div className={EntityViewStyles.infoItemContent}>
                <TextEditor
                  readOnly
                  value={description}
                  className={EntityViewStyles.description}
                />
              </div>
            </div>
          )}
          <div>
            <Typography
              variant="h3"
              key="team-access-rights"
              className={formStyles.blockStart}
            >
              {t('team-access-rights')}
            </Typography>
            <div className={formStyles.blockStart}>
              <TeamAccessView accesses={accesses} />
            </div>
          </div>
        </WhiteBox>
        {isDestroyAvailable && !isViewOnly && (
          <div className={styles.actions}>
            <Button
              buttonText={t('delete-team')}
              buttonType={ButtonTypes.negativeOutlined}
              leftIcon="delete"
              leftIconClassName={materialSymbolsOutlined}
              onClick={onDelete}
            />
          </div>
        )}
      </CircleLoaderWrapper>
    </DashboardContentMaxWidth>
  );
}
