import React from 'react';
import _get from 'lodash/get';
import { User } from 'graphql-common';
import Image from '@lib/components/Image/Image';
import AvatarIcon from '@lib/assets/icons/avatar.svg?react';
import Typography from '@lib/components/Typography/Typography';
import Tooltip from '@lib/components/Tooltip/Tooltip';
import getFullName from '@lib/utils/getFullName';
import UserChip from '@lib/components/UserChip/UserChip';
import styles from './UsersAvatarsPreview.module.scss';

export function UserAvatar({ user }: { user: User }) {
  const { avatarAttached } = user || {};
  const url =
    _get(avatarAttached, 'urls.small') || _get(avatarAttached, 'urls.original');
  return (
    <div className={styles.avatar}>
      {url ? <Image src={url} /> : <AvatarIcon />}
    </div>
  );
}

export default function UsersAvatarsPreview({
  users,
  usersTotalCount,
  usersVisibleCount,
  onClick,
  useChipForSingleUser,
}: {
  users: User[];
  usersTotalCount: number;
  usersVisibleCount: number;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  useChipForSingleUser?: boolean;
}) {
  const visibleUsers = users.slice(0, usersVisibleCount);
  const remainingUsersCount = usersTotalCount - usersVisibleCount;

  const showSingleChip = useChipForSingleUser && visibleUsers?.length === 1;

  const defaultOnClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
  };

  const onPreviewClick = remainingUsersCount > 0 ? onClick : defaultOnClick;

  if (visibleUsers?.length) {
    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events
      <div
        className={styles.usersAvatarsPreviewRow}
        onClick={onPreviewClick}
        role="button"
        tabIndex={0}
      >
        {showSingleChip ? (
          <UserChip user={visibleUsers[0]} />
        ) : (
          <>
            {visibleUsers.map((user) => (
              <div key={user.id}>
                <Tooltip body={getFullName(user)}>
                  <UserAvatar user={user} />
                </Tooltip>
              </div>
            ))}
            {remainingUsersCount > 0 && (
              <div>
                <Typography className={styles.counter} variant="label">
                  +{remainingUsersCount}
                </Typography>
              </div>
            )}
          </>
        )}
      </div>
    );
  }
  return null;
}
