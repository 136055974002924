import React from 'react';
import { TFunction } from 'i18next';
import _upperFirst from 'lodash/upperFirst';
import _toLower from 'lodash/toLower';
import _get from 'lodash/get';
import { Intervention } from 'graphql-common';
import TextCell from '@lib/components/Table/commonComponents/TextCell/TextCell';
import TextLinkCell from '@lib/components/Table/commonComponents/TextLinkCell/TextLinkCell';
import { CellProps } from '@lib/components/Table/types';
import { getFormattedDate } from '@lib/utils/dateTimeHelpers';
import { dateTimeFormatEU } from '@lib/enums/dateTime';
import { ChipSizes, ChipTypes } from '@lib/components/Chip/Chip';
import AssetChip from '@lib/components/AssetChip/AssetChip';
import ChipCell from '@lib/components/Table/commonComponents/ChipCell/ChipCell';
import { getPriorityChipType } from '@lib/utils/priority';
import { APP_URLS } from 'constants/urls';

type ColumnsArgs = {
  t: TFunction<'translation', undefined>;
};

const cellWidthProps = {
  minWidth: 150,
  maxWidth: 150,
  width: 150,
};

export default function getTableColumns({ t }: ColumnsArgs) {
  return [
    {
      id: 'code',
      Header: t('code'),
      accessor: 'code',
      Cell: (props: CellProps<Intervention>) => <TextCell {...props} />,
      minWidth: 110,
      maxWidth: 110,
      width: 110,
      sortable: true,
    },
    {
      id: 'name',
      Header: t('name'),
      accessor: 'name',
      Cell: (props: CellProps<Intervention>) => {
        const { item } = props;
        const to = APP_URLS.dashboard.interventions.view.getDynamicPath({
          pathParts: { id: item?.id },
        });
        return <TextLinkCell {...props} to={to} />;
      },
      minWidth: 250,
      maxWidth: 250,
      width: 250,
      sortable: true,
    },
    {
      id: 'assetName',
      Header: t('asset'),
      Cell: ({ item, ...restProps }: CellProps<Intervention>) =>
        item.asset ? (
          <AssetChip asset={item.asset} size={ChipSizes.small} />
        ) : (
          <TextCell {...restProps} item={item} value={undefined} />
        ),
      sortable: true,
      ...cellWidthProps,
    },
    {
      id: 'categoryName',
      Header: t('category'),
      Cell: (props: CellProps<Intervention>) => {
        const label = _get(props, 'item.category.name', '');
        const color = _get(props, 'item.category.color', '') as ChipTypes;
        if (!label) return '-';
        return <ChipCell label={label} type={color} leftIcon="circle" />;
      },
      sortable: true,
      ...cellWidthProps,
    },
    {
      id: 'siteName',
      Header: t('site'),
      Cell: (props: CellProps<Intervention>) => {
        const label = _get(props, 'item.site.name', '');
        if (!label) return '-';
        return <ChipCell label={label} type={ChipTypes.chipGray} />;
      },
      sortable: true,
      ...cellWidthProps,
    },
    {
      id: 'siteAreaName',
      Header: t('area'),
      accessor: 'siteArea',
      Cell: (props: CellProps<Intervention>) => {
        const label = _get(props, 'item.siteArea.name', '');
        if (!label) return '-';
        return <ChipCell label={label} type={ChipTypes.chipGray} />;
      },
      sortable: true,
      ...cellWidthProps,
    },
    {
      id: 'priority',
      Header: t('priority'),
      accessor: 'priority',
      Cell: (props: CellProps<Intervention>) => {
        const priority = _get(props, 'item.priority');
        const label = _upperFirst(_toLower(priority));
        if (!label) return '-';
        return (
          <ChipCell
            label={label}
            type={getPriorityChipType(priority)}
            leftIcon="circle"
          />
        );
      },
      sortable: true,
      ...cellWidthProps,
    },
    {
      id: 'createdAt',
      Header: t('date-created'),
      accessorFn: ({ item }: CellProps<Intervention>) =>
        getFormattedDate(item.createdAt, dateTimeFormatEU),
      Cell: (props: CellProps<Intervention>) => <TextCell {...props} />,
      sortable: true,
      ...cellWidthProps,
    },
  ];
}
