import React from 'react';
import { TFunction } from 'i18next';
import { Counter } from 'graphql-common';
import TextCell from '@lib/components/Table/commonComponents/TextCell/TextCell';
import { CellProps } from '@lib/components/Table/types';

type ColumnsArgs = {
  t: TFunction<'translation', undefined>;
};

export default function getAssetsColumns({ t }: ColumnsArgs) {
  return [
    {
      id: 'code',
      Header: t('code'),
      accessor: 'code',
      Cell: (props: CellProps<Counter>) => <TextCell {...props} />,
      minWidth: 110,
      maxWidth: 110,
      width: 110,
      sortable: true,
    },
    {
      id: 'name',
      Header: t('name'),
      accessor: 'name',
      Cell: (props: CellProps<Counter>) => <TextCell {...props} />,
      maxWidth: 270,
      sortable: true,
    },
  ];
}
