import {
  FormTemplateQuestionFieldOptionsValidityHintEnum,
  GroupEnum,
} from 'graphql-common';
import getNewValidityHintConfigKeys from 'components/FormTemplateBuilder/QuestionCheckboxButtonGridField/utils/getNewValidityHintConfigKeys';
import _replace from 'lodash/replace';
import getValidityHintOptionByValue from 'components/FormTemplateBuilder/utils/getValidityHintOptionByValue';
import { Values } from '@lib/interfaces/form';
import FormTemplateFieldNames from '@lib/enums/fieldNames/formTemplateFieldNames';

export default function getAppendCallback(
  group: GroupEnum,
  lastPosition: number,
  validityHintConfig: Values,
  parentName: string,
  setValue: (key: string, value?: Values) => void,
) {
  return () => {
    const newKeys = getNewValidityHintConfigKeys(
      validityHintConfig,
      group,
      lastPosition,
    );
    newKeys.forEach((newKey) => {
      const name = _replace(
        parentName,
        `.${FormTemplateFieldNames.Options}`,
        `.${FormTemplateFieldNames.ValidityHintConfig}.${newKey}`,
      );
      setValue(
        name,
        getValidityHintOptionByValue(
          FormTemplateQuestionFieldOptionsValidityHintEnum.Success,
        ),
      );
    });
  };
}
