import React from 'react';
import { User } from 'graphql-common';
import Modal from '@lib/components/Modal/Modal';
import UserForm from 'components/UserForm/UserFormContainer';

type Props = {
  isOpen: boolean;
  isSiteFieldRequired?: boolean;
  onClose: () => void;
  onSubmitUserFormCallback: (v?: User | null) => void;
  selectedUserId?: string | null;
  title: string;
};

function UserModalForm(props: Props) {
  const {
    isOpen,
    isSiteFieldRequired,
    onClose,
    onSubmitUserFormCallback,
    selectedUserId,
    title,
  } = props;

  return (
    <Modal isOpen={isOpen} title={title} onClose={onClose}>
      <UserForm
        isOpen={isOpen}
        isSiteFieldRequired={isSiteFieldRequired}
        onCancel={onClose}
        onSubmitCallback={onSubmitUserFormCallback}
        selectedUserId={selectedUserId}
      />
    </Modal>
  );
}

export default UserModalForm;
