import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button, { ButtonTypes } from '@lib/components/Button/Button';
import ActionsRow from '@lib/components/ActionsRow/ActionsRow';
import { ActionsProps } from '@lib/components/ReactHookForm/types';
import { APP_URLS } from 'constants/urls';
import { SubmitAction } from '@lib/enums/form';
import formStyles from '@lib/assets/styles/Form.module.scss';

export interface Props extends ActionsProps {
  isEditForm: boolean;
  submitNewAssetRef: React.MutableRefObject<SubmitAction | undefined>;
}

function AssetFormActions({
  isDirty,
  isEditForm,
  loading,
  onSubmitForm,
  submitNewAssetRef,
}: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const actions = isEditForm
    ? [
        <Button
          buttonText={t('cancel')}
          buttonType={ButtonTypes.secondaryOutlined}
          disabled={loading}
          fullWidth
          leftIcon="close"
          onClick={() =>
            navigate(APP_URLS.dashboard.assets.index.getPathWithQuery())
          }
        />,
        <Button
          buttonText={t('save-changes')}
          buttonType={ButtonTypes.primaryFilled}
          disabled={!isDirty}
          fullWidth
          isLoading={loading}
          leftIcon="check"
          type="submit"
        />,
      ]
    : [
        <Button
          buttonText={t('cancel')}
          buttonType={ButtonTypes.secondaryOutlined}
          disabled={loading}
          fullWidth
          leftIcon="close"
          onClick={() =>
            navigate(APP_URLS.dashboard.assets.index.getPathWithQuery())
          }
        />,
        <div />,
        <Button
          buttonText={t('save-and-create-next')}
          buttonType={ButtonTypes.primaryOutlined}
          disabled={!isDirty}
          fullWidth
          isLoading={loading}
          onClick={() => {
            // eslint-disable-next-line no-param-reassign
            submitNewAssetRef.current = SubmitAction.CreateAndNew;
            onSubmitForm();
          }}
        />,
        <Button
          buttonText={t('create')}
          buttonType={ButtonTypes.primaryFilled}
          disabled={!isDirty}
          fullWidth
          isLoading={loading}
          leftIcon="check"
          onClick={() => {
            // eslint-disable-next-line no-param-reassign
            submitNewAssetRef.current = SubmitAction.CreateAndClose;
            onSubmitForm();
          }}
        />,
      ];

  return <ActionsRow className={formStyles.formActions} actions={actions} />;
}

export default AssetFormActions;
