import React from 'react';
import classNames from 'classnames';
import _get from 'lodash/get';
import { SystemAdmin, User } from 'graphql-common';
import MaterialIcon from '@lib/components/MaterialIcon/MaterialIcon';
import Popover from '@lib/components/Popover/Popover';
import Typography from '@lib/components/Typography/Typography';
import Menu, { NavItem } from '@lib/components/Menu/Menu';
import Image from '@lib/components/Image/Image';
import AvatarIcon from '@lib/assets/icons/avatar.svg?react';
import styles from './AccountPreview.module.scss';

interface Props<T> {
  user: User | SystemAdmin;
  menuItems: NavItem<T>[];
}

function AccountPreview<T>(props: Props<T>) {
  const { user, menuItems } = props;
  const { avatarAttached, firstName, lastName, email } = user || {};
  const url =
    _get(avatarAttached, 'urls.small') || _get(avatarAttached, 'urls.original');
  const userAvatar = url ? <Image src={url} /> : <AvatarIcon />;

  const getBody = () => (
    <div className={styles.body}>
      <div className={styles.userWrap}>
        <div className={styles.userAvatar}>{userAvatar}</div>
        <div className={styles.userInfo}>
          <Typography variant="body" strong>
            {firstName} {lastName}
          </Typography>
          <Typography variant="caption" className={styles.userEmail}>
            {email}
          </Typography>
        </div>
      </div>
      <Menu navItems={menuItems} listClassName={styles.menuList} />
    </div>
  );

  return (
    <Popover body={getBody} placement="bottomRight" width={220}>
      {({ handleToggle, isOpen }) => (
        <button
          type="button"
          className={classNames(styles.root, { [styles.active]: isOpen })}
          onClick={() => handleToggle()}
        >
          {userAvatar}
          <MaterialIcon icon="expand_more" />
        </button>
      )}
    </Popover>
  );
}

export default AccountPreview;
