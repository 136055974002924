import { FormTemplateQuestionFieldsKindEnum, GroupEnum } from 'graphql-common';
import { Values } from '@lib/interfaces/form';
import {
  kindFieldsWithDeleteOption,
  kindFieldsWithoutDeleteOption,
} from 'components/FormTemplateBuilder/constants';

export default function isDeleteBtnVisibleFn(
  kind: FormTemplateQuestionFieldsKindEnum,
  parentValue: Values[],
  groupValue: GroupEnum,
) {
  if (kindFieldsWithoutDeleteOption.includes(kind)) return false;
  if (kindFieldsWithDeleteOption.includes(kind)) return true;
  if (
    kind === FormTemplateQuestionFieldsKindEnum.CheckboxButtonGrid &&
    Array.isArray(parentValue)
  ) {
    return (
      parentValue?.filter((itemValue) => itemValue?.group === groupValue)
        ?.length > 1
    );
  }
  return parentValue?.length > 1;
}
