import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Tooltip from '@lib/components/Tooltip/Tooltip';
import IconButton, {
  IconButtonSizes,
  IconButtonTypes,
} from '@lib/components/IconButton/IconButton';
import { COUNTER_MONITORING_SIDEBAR_IS_COLLAPSED } from '@lib/enums/localStorageKeys';
import classNames from 'classnames';
import styles from './DashboardContentWithRightSidebar.module.scss';

interface Props {
  children: React.ReactNode;
  sidebar: React.ReactNode;
  useSidebarHeader?: boolean;
  toggleBtnTop?: number;
}

const isCollapsedFromStorage = localStorage.getItem(
  COUNTER_MONITORING_SIDEBAR_IS_COLLAPSED,
);

function DashboardContentWithRightSidebar({
  children,
  sidebar,
  useSidebarHeader,
  toggleBtnTop,
}: Props) {
  const { t } = useTranslation();
  const isCollapsedValueFromStorage = isCollapsedFromStorage
    ? JSON.parse(isCollapsedFromStorage)
    : false;
  const [isCollapsed, setCollapsed] = useState(isCollapsedValueFromStorage);
  const handleExpanded = () => {
    setCollapsed((prevState: boolean) => !prevState);
  };

  useEffect(() => {
    localStorage.setItem(
      COUNTER_MONITORING_SIDEBAR_IS_COLLAPSED,
      JSON.stringify(isCollapsed),
    );
  }, [isCollapsed]);

  return (
    <div
      className={classNames(styles.root, {
        [styles.rootCollapsed]: isCollapsed,
      })}
    >
      {children}
      <div
        className={classNames(styles.sidebar, {
          [styles.sidebarWithHeader]: useSidebarHeader,
        })}
      >
        <div className={styles.sidebarWrap}>
          <div className={styles.sidebarSticky}>
            <div
              className={styles.toggleBtn}
              style={toggleBtnTop ? { top: toggleBtnTop } : undefined}
            >
              <Tooltip
                body={t(isCollapsed ? 'expand' : 'collapse')}
                key={isCollapsed ? 'expand' : 'collapse'}
                placement="left"
              >
                <IconButton
                  icon={isCollapsed ? 'chevron_left' : 'chevron_right'}
                  onClick={handleExpanded}
                  rounded
                  type={IconButtonTypes.SecondaryOutlined}
                  size={IconButtonSizes.Small}
                />
              </Tooltip>
            </div>
            <div className={styles.sidebarContentWrap}>
              <div className={styles.sidebarContent}>
                {useSidebarHeader && <div className={styles.sidebarHeader} />}
                {sidebar}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardContentWithRightSidebar;
