import { TFunction } from 'i18next';
import _without from 'lodash/without';
import { Intervention } from 'graphql-common';
import { NavItem, NavItemTypeEnum } from '@lib/components/Menu/Menu';
import Icons from '@lib/components/Icons/Icons';

type ActionsMenuArgs = {
  onClose?: (v: Intervention) => void;
  onDelete?: (v: Intervention) => void;
  onEdit?: (v: Intervention) => void;
  onView: (v: Intervention) => void;
  t: TFunction<'translation', undefined>;
  isViewOnly: boolean;
};

export default function getTableActionsMenu({
  onDelete,
  onView,
  onEdit,
  onClose,
  isViewOnly,
  t,
}: ActionsMenuArgs) {
  return _without(
    [
      {
        id: 'view',
        title: t('view'),
        onClick: (item: Intervention) => onView(item),
        leftIcon: Icons.view,
        type: NavItemTypeEnum.Secondary,
      },
      onEdit
        ? {
            id: 'edit-action',
            title: t('edit'),
            onClick: (item: Intervention) => onEdit(item),
            leftIcon: Icons.edit,
            type: NavItemTypeEnum.Secondary,
            predictorFnVisible: (item?: Intervention) =>
              !!item?.canUpdate?.value,
            predictorFnDisabled: () => isViewOnly,
          }
        : undefined,
      onClose
        ? {
            id: 'close-action',
            title: t('close'),
            onClick: (item: Intervention) => onClose(item),
            leftIcon: Icons.close,
            type: NavItemTypeEnum.Secondary,
            predictorFnVisible: (item?: Intervention) =>
              !!item?.canUpdate?.value,
            predictorFnDisabled: () => isViewOnly,
          }
        : undefined,
      onDelete
        ? {
            id: 'delete-action',
            title: t('delete'),
            onClick: (item: Intervention) => onDelete(item),
            leftIcon: Icons.delete,
            type: NavItemTypeEnum.Secondary,
            predictorFnVisible: (item?: Intervention) =>
              !!item?.canDestroy?.value,
            predictorFnDisabled: () => isViewOnly,
          }
        : undefined,
    ],
    undefined,
  ) as NavItem<Intervention>[];
}
