import React from 'react';
import {
  components as DefaultSelectComponents,
  SingleValueProps,
} from 'react-select';
import _get from 'lodash/get';
import { getValidityHintIcon } from './ValidityHintOption';
import styles from '../Select.module.scss';

export default function ValidityHintSingleValue({
  children,
  ...props
}: SingleValueProps) {
  const { selectProps } = props;
  const value = _get(selectProps, 'value.value', '');
  const icon = getValidityHintIcon(value);
  return (
    <DefaultSelectComponents.SingleValue {...props}>
      <div className={styles.singleValueBox}>{icon}</div>
    </DefaultSelectComponents.SingleValue>
  );
}
