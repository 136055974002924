import React from 'react';
import { useTranslation } from 'react-i18next';
import _get from 'lodash/get';
import {
  History,
  HistoryKindEnum,
  InterventionActionHistoryDataType,
  User,
} from 'graphql-common';
import getFullName from '@lib/utils/getFullName';
import Chip, { ChipTypes } from '@lib/components/Chip/Chip';
import Image from '@lib/components/Image/Image';
import AvatarIcon from '@lib/assets/icons/avatar.svg?react';
import SystemLogo from '@lib/assets/images/datizy-logo-avatar.jpg';
import { getFormattedDate, transformDate } from '@lib/utils/dateTimeHelpers';
import Typography from '@lib/components/Typography/Typography';
import { dateTimeFormatEU } from '@lib/enums/dateTime';
import { getInterventionActionStatusOption } from '@lib/utils/status';
import { getTimeFromMinutesCount } from '@lib/utils/getTimeFromMinutesCount';
import MaterialIcon from '@lib/components/MaterialIcon/MaterialIcon';
import Tooltip from '@lib/components/Tooltip/Tooltip';
import styles from './InterventionActionHistoryView.module.scss';

const systemUser = {
  id: 'system',
  firstName: 'Datizy',
  lastName: 'SYSTEM',
  avatarAttached: {
    urls: {
      original: SystemLogo,
    },
  },
};

function UserPreview({ user }: { user: User | null }) {
  if (!user?.id) return null;
  const fullName = getFullName(user);
  const url = _get(user, 'avatarAttached.urls.original') || '';
  const userAvatar = url ? <Image src={url} /> : <AvatarIcon />;
  return (
    <div className={styles.userPreview}>
      {userAvatar}{' '}
      <Typography variant="caption" strong>
        {fullName}
      </Typography>
    </div>
  );
}

function ChangeHistoryItem({
  label,
  prevValue,
  newValue,
}: {
  label: string;
  prevValue: string | null;
  newValue: string | null;
}) {
  return (
    <div>
      <Typography variant="caption" className={styles.historyValuesTitle}>
        {label}
      </Typography>
      <div className={styles.historyValues}>
        {!!prevValue && (
          <span className={styles.historyValue}>
            <MaterialIcon
              icon="schedule"
              className={styles.historyValueIcon}
              size="md-16"
            />
            <Typography variant="caption" strikethrough>
              {prevValue}
            </Typography>
          </span>
        )}
        {!!prevValue && !!newValue && (
          <MaterialIcon
            icon="arrow_forward"
            size="md-16"
            className={styles.historyValuesArrow}
          />
        )}
        {!!newValue && (
          <span className={styles.historyValue}>
            <MaterialIcon
              icon="schedule"
              className={styles.historyValueIcon}
              size="md-16"
            />
            <Typography variant="caption">{newValue}</Typography>
          </span>
        )}
      </div>
    </div>
  );
}

export default function InterventionActionHistoryView({
  history,
}: {
  history: History;
}) {
  const { t } = useTranslation();
  const { user, occurredAt, data, kind, system } = history;
  const { status, durationTime, estimatedDate, estimatedTime, logisticTime } =
    data as InterventionActionHistoryDataType;

  const prevStatus =
    status?.[0] && getInterventionActionStatusOption(status[0]);
  const nextStatus =
    status?.[1] && getInterventionActionStatusOption(status[1]);

  const prevTimeSpent = durationTime?.[0]
    ? getTimeFromMinutesCount(durationTime[0])
    : '';
  const nextTimeSpent = durationTime?.[1]
    ? getTimeFromMinutesCount(durationTime[1])
    : '';

  const prevEstimatedDate = estimatedDate?.[0]
    ? getFormattedDate(transformDate(new Date(estimatedDate[0]), false, true))
    : '';
  const nextEstimatedDate = estimatedDate?.[1]
    ? getFormattedDate(transformDate(new Date(estimatedDate[1]), false, true))
    : '';

  const prevEstimatedTime = estimatedTime?.[0]
    ? getTimeFromMinutesCount(estimatedTime[0])
    : '';
  const nextEstimatedTime = estimatedTime?.[1]
    ? getTimeFromMinutesCount(estimatedTime[1])
    : '';

  const prevLogisticTime = logisticTime?.[0]
    ? getTimeFromMinutesCount(logisticTime[0])
    : '';
  const nextLogisticTime = logisticTime?.[1]
    ? getTimeFromMinutesCount(logisticTime[1])
    : '';

  const hasBodyContent = [
    status,
    durationTime,
    estimatedDate,
    estimatedTime,
    logisticTime,
  ].some((value) => value && value.length > 1);

  return (
    <div className={styles.actionHistoryView}>
      <div className={styles.actionHistoryViewHeader}>
        <UserPreview user={system ? (systemUser as unknown as User) : user} />
        <Typography variant="caption" className={styles.dateUpdated}>
          <span>
            {kind === HistoryKindEnum.Created
              ? t('action-created').toLowerCase()
              : t('edited')}
          </span>
          <span />
          <span>{getFormattedDate(occurredAt, dateTimeFormatEU)}</span>
        </Typography>
      </div>
      {hasBodyContent && (
        <div className={styles.actionHistoryViewBody}>
          {!!status?.length && (
            <div>
              <Typography
                variant="caption"
                className={styles.historyValuesTitle}
              >
                {t('status')}
              </Typography>
              <div className={styles.historyValues}>
                <Chip
                  leftIcon="circle"
                  label={t(prevStatus?.labelKey || '')}
                  type={prevStatus?.color || ChipTypes.chipDisabled}
                  strikethrough={nextStatus !== undefined}
                />
                {nextStatus && (
                  <>
                    <MaterialIcon
                      icon="arrow_forward"
                      size="md-16"
                      className={styles.historyValuesArrow}
                    />
                    <Chip
                      leftIcon="circle"
                      label={t(nextStatus?.labelKey || '')}
                      type={nextStatus?.color || ChipTypes.chipDisabled}
                    />
                    {system && (
                      <div>
                        <Tooltip
                          body={t('closed-intervention-action-description')}
                        >
                          <MaterialIcon
                            icon="info"
                            size="md-16"
                            symbolsOutlined
                            className={styles.infoIcon}
                          />
                        </Tooltip>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          )}
          {!!durationTime?.length && (
            <ChangeHistoryItem
              label={t('time-spent')}
              prevValue={prevTimeSpent}
              newValue={nextTimeSpent}
            />
          )}
          {!!estimatedDate?.length && (
            <ChangeHistoryItem
              label={t('estimated-date')}
              prevValue={prevEstimatedDate}
              newValue={nextEstimatedDate}
            />
          )}
          {!!estimatedTime?.length && (
            <ChangeHistoryItem
              label={t('estimated-time')}
              prevValue={prevEstimatedTime}
              newValue={nextEstimatedTime}
            />
          )}
          {!!logisticTime?.length && (
            <ChangeHistoryItem
              label={t('logistic-time')}
              prevValue={prevLogisticTime}
              newValue={nextLogisticTime}
            />
          )}
        </div>
      )}
    </div>
  );
}
