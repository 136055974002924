export default function numberToOrdinal(n: number) {
  if (n === 0) return n.toString();

  const lastDigit = n % 10;
  const lastTwoDigits = n % 100;

  if (lastDigit === 1 && lastTwoDigits !== 11) {
    return `${n}st`;
  }
  if (lastDigit === 2 && lastTwoDigits !== 12) {
    return `${n}nd`;
  }
  if (lastDigit === 3 && lastTwoDigits !== 13) {
    return `${n}rd`;
  }
  return `${n}th`;
}
