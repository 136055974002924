import React, { memo } from 'react';
import classNames from 'classnames';
import styles from './SupportiveMessage.module.scss';

export enum SupportiveMessageColor {
  Blue = 'blue',
  Yellow = 'yellow',
}

export interface MessageBoxProps {
  className?: string;
  fullWidth?: boolean;
  hasBottomSpace?: boolean;
  children?: React.ReactNode;
  color?: SupportiveMessageColor;
}

function SupportiveMessageBox(props: MessageBoxProps) {
  const {
    className,
    fullWidth,
    hasBottomSpace = true,
    children,
    color = SupportiveMessageColor.Blue,
  } = props;
  if (!children) return null;
  const rootClassName = classNames(
    styles.messageRoot,
    {
      [styles.messageRootFullWidth]: fullWidth,
      [styles.messageRootFullBottomPadding]: hasBottomSpace,
    },
    className,
  );
  const messageClassName = classNames(styles.message, styles[color]);
  return (
    <div className={rootClassName}>
      <div className={messageClassName}>{children}</div>
    </div>
  );
}

export default memo(SupportiveMessageBox);
