import { useEffect, useRef } from 'react';
import { debounce } from 'lodash';

type EventType = 'resize' | 'scroll' | 'orientationchange';

const useEventListener = (
  event: EventType,
  listener: () => void,
  useCapture?: boolean,
  delay = 300,
) => {
  const debouncedListener = useRef(debounce(listener, delay)).current;

  useEffect(() => {
    if (debouncedListener) {
      debouncedListener();
      window.addEventListener(event, debouncedListener, useCapture);

      return () => {
        window.removeEventListener(event, debouncedListener, useCapture);
        debouncedListener.cancel();
      };
    }

    return () => {};
  }, [event, debouncedListener, useCapture]);

  useEffect(() => {
    return () => debouncedListener.cancel();
  }, [debouncedListener]);
};

export default useEventListener;
