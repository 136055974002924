import React, { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Asset, Counter } from 'graphql-common';
import Button, { ButtonTypes } from '@lib/components/Button/Button';
import Table from '@lib/components/Table/Table';
import Typography from '@lib/components/Typography/Typography';
import CircleLoader from '@lib/components/CircleLoader/CircleLoader';
import EmptyState from '@lib/components/EmptyState/EmptyState';
import ListEmptyState from '@lib/components/ListEmptyState/ListEmptyState';
import Search from '@lib/components/Search/Search';
import ErrorPage from '@lib/components/ErrorPage/ErrorPage';
import getStatusFromError from '@lib/utils/getStatusFromError';
import { Column } from '@lib/components/Table/types';
import { GetUrlParams } from '@lib/enums/urls';
import { InputSizes } from '@lib/components/Input/enums';
import IconButton from '@lib/components/IconButton/IconButton';
import usePrevious from '@lib/hooks/usePrevious';
import { IconName } from '@lib/components/Modal/enums';
import useSidebarActions from '../utils/useSidebarActions';
import styles from './AssetsSibebar.module.scss';
import { AssetsSidebarView } from '../enums';

interface Props {
  defaultCounterId?: string;
  assetValue?: Asset | null;
  assetsTableColumns: Column<Asset>[];
  countersTableColumns: Column<Counter>[];
  onSelectAsset: (v: Asset) => void;
  onSelectCounter: (v: Counter, a?: Asset) => void;
  onShowSideBarAssets: () => void;
  sidebarView: AssetsSidebarView;
}

function AssetsSidebar(props: Props) {
  const { t } = useTranslation();
  const {
    defaultCounterId,
    assetValue,
    assetsTableColumns,
    countersTableColumns,
    onSelectAsset,
    onSelectCounter,
    onShowSideBarAssets,
    sidebarView,
  } = props;

  const prevSidebarView = usePrevious(sidebarView);

  const {
    defaultAssetId,
    assetsOrderingState,
    assetsPaginationState,
    assetsSearchState,
    countersOrderingState,
    countersPaginationState,
    useAssetsQueryHookResult,
    useCountersQueryHookResult,
  } = useSidebarActions();

  const {
    totalCount: assetsTotalCount,
    error: assetsError,
    firstLoading: assetsFirstLoading,
    loading: assetsLoading,
    collection: assets,
  } = useAssetsQueryHookResult;
  const [assetsSearchQuery, setAssetsSearchQuery] = assetsSearchState;

  const {
    totalCount: countersTotalCount,
    error: countersError,
    firstLoading: countersFirstLoading,
    loading: countersLoading,
    collection: counters,
  } = useCountersQueryHookResult;

  useEffect(() => {
    if (prevSidebarView !== sidebarView) {
      setAssetsSearchQuery({ [GetUrlParams.Search]: undefined });
    }
  }, [prevSidebarView, setAssetsSearchQuery, sidebarView]);

  const assetsEmptyState = (
    <EmptyState
      title={t('no-results-found')}
      text={t('no-results-found-text')}
      icon={IconName.SearchOff}
      actions={[
        <Button
          key="back"
          buttonText={t('go-back')}
          buttonType={ButtonTypes.primaryFilled}
          leftIcon="arrow_back"
          onClick={() =>
            setAssetsSearchQuery({ [GetUrlParams.Search]: undefined })
          }
        />,
      ]}
    />
  );

  const countersEmptyState = (
    <ListEmptyState
      title={t('no-asset-counters-yet')}
      text={t('no-asset-counters-text')}
      actions={[]}
    />
  );

  if (assetsError && sidebarView === AssetsSidebarView.Assets) {
    return (
      <ErrorPage
        status={getStatusFromError(assetsError)}
        description={assetsError?.message}
      />
    );
  }

  if (countersError && sidebarView === AssetsSidebarView.Counters) {
    return (
      <ErrorPage
        status={getStatusFromError(countersError)}
        description={countersError?.message}
      />
    );
  }

  if (sidebarView === AssetsSidebarView.Assets) {
    return assetsFirstLoading ? (
      <CircleLoader />
    ) : (
      <>
        <div className={styles.header}>
          {(!!assets?.length || assetsSearchQuery) && (
            <Search
              value={assetsSearchQuery?.[GetUrlParams.Search] || ''}
              placeholder={t('search')}
              onChange={(v) => {
                setAssetsSearchQuery({ [GetUrlParams.Search]: v });
              }}
              inputSize={InputSizes.medium}
              useBottomMargin={false}
              useSearchParam={false}
            />
          )}
        </div>
        <Table
          bordered={false}
          className={
            assets?.length ? styles.tableWrap : styles.tableWrapWhenEmpty
          }
          columns={assetsTableColumns}
          data={assets}
          emptyState={assetsEmptyState}
          fullHeight
          id="assets"
          loading={assetsLoading}
          orderingState={assetsOrderingState}
          paginationState={assetsPaginationState}
          rounded={false}
          tableContainerClassName={styles.tableContainer}
          totalEntries={assetsTotalCount}
          onRowClick={onSelectAsset}
          getIsRowSelected={(asset) => asset.id === defaultAssetId}
        />
      </>
    );
  }

  if (sidebarView === AssetsSidebarView.Counters) {
    return countersFirstLoading ? (
      <CircleLoader />
    ) : (
      <>
        <div className={styles.headerWithBackBtn}>
          <IconButton
            icon="arrow_back"
            onClick={onShowSideBarAssets}
            className={styles.headerBackBtn}
          />
          {!!assetValue && (
            <Typography
              variant="body"
              className={styles.title}
            >{`${assetValue.fullCode} ${assetValue.name}`}</Typography>
          )}
        </div>
        <Table
          bordered={false}
          className={
            counters?.length ? styles.tableWrap : styles.tableWrapWhenEmpty
          }
          columns={countersTableColumns}
          data={counters}
          emptyState={countersEmptyState}
          fullHeight
          id="counters"
          loading={countersLoading}
          orderingState={countersOrderingState}
          paginationState={countersPaginationState}
          rounded={false}
          tableContainerClassName={styles.tableContainer}
          totalEntries={countersTotalCount}
          onRowClick={
            assetValue
              ? (counter) => onSelectCounter(counter, assetValue)
              : undefined
          }
          getIsRowSelected={(counter) => counter.id === defaultCounterId}
        />
      </>
    );
  }

  return null;
}

export default memo(AssetsSidebar);
