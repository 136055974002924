export const UppyImageAllowedFileTypes = [
  '.jpg',
  '.jpeg',
  '.png',
  '.webp',
  '.jfif',
];
export const UppyDocumentationAllowedFileTypes = [
  '.jpg',
  '.jpeg',
  '.png',
  '.webp',
  '.jfif',
  '.pdf',
];
export const fileSizeLimits = {
  '.jpg': 5 * 1024 * 1024,
  '.jpeg': 5 * 1024 * 1024,
  '.png': 5 * 1024 * 1024,
  '.webp': 5 * 1024 * 1024,
  '.jfif': 5 * 1024 * 1024,
  '.pdf': 25 * 1024 * 1024,
};

export const getMaxFileSize = (fileType: string) => fileSizeLimits[fileType];
