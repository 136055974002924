import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import _get from 'lodash/get';
import { Asset, ComponentEnum, LevelEnum } from 'graphql-common';
import BreadCrumbs from '@lib/components/BreadCrumbs/BreadCrumbs';
import Assets from 'components/Assets/Assets';
import useSearchParam from '@lib/hooks/useSearchParam';
import ConfirmationModal from '@lib/components/ConfirmationModal/ConfirmationModal';
import Modal from '@lib/components/Modal/Modal';
import { useTableItemsBulkSelect } from '@lib/hooks/useTableItemsBulkSelect';
import { getBreadCrumbsDataByKey } from 'utils/breadcrumbs';
import GetQrCodeForm from 'components/GetQrCodeForm/GetQrCodeForm';
import useOutletContext, { ContextProps } from 'hooks/useOutletContext';
import { IconName } from '@lib/components/Modal/enums';
import getActionsMenu from './utils/getActionsMenu';
import getActionsForSelected from './utils/getActionsForSelected';
import getColumns from './utils/getColumns';
import useActions from './utils/useActions';
import useModalManagement from './utils/useModalManagement';

function AssetListContainer() {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [searchQuery] = useSearchParam();

  const { getUserAccessByComponent }: ContextProps = useOutletContext();
  const isViewOnly =
    getUserAccessByComponent(ComponentEnum.AssetsManagement) === LevelEnum.Read;

  const useBulkSelectResult = useTableItemsBulkSelect<Asset>();
  const {
    getSelectedItemsCount,
    isAllTableItemsSelected,
    selectedSingleTableItem,
    selectedTableItems,
  } = useBulkSelectResult;

  const useModalManagementResult = useModalManagement({
    useBulkSelectResult,
  });
  const {
    closeAssetDeleteModal,
    closeGetQrCodeModal,
    isAssetDeleteModalOpened,
    isAssetQrCodeFormModalOpened,
  } = useModalManagementResult;

  const {
    assetsError,
    assetsFirstLoading,
    assetsLoading,
    collection,
    getQrCode,
    getQrCodesForSelected,
    getUsersLoadOptions,
    onAdd,
    onBulkDelete,
    onConfirmedDelete,
    onDelete,
    onEditHandler,
    onQrCodeCreate,
    onViewHandler,
    qrCodeCreateMutationResult,
    totalCount,
  } = useActions({
    useBulkSelectResult,
    useModalManagementResult,
  });

  const selectedItemsCount = getSelectedItemsCount(totalCount);

  const columns = getColumns({ t });
  const tableActionsMenu = getActionsMenu({
    onViewHandler,
    onEditHandler,
    onDelete,
    getQrCode,
    t,
    isViewOnly,
  });
  const tableActionsForSelected = getActionsForSelected({
    getQrCodesForSelected,
    onBulkDelete,
    selectedItemsCount,
    t,
    isViewOnly,
  });

  return (
    <>
      <BreadCrumbs data={getBreadCrumbsDataByKey({ pathname, t })} />
      <Assets
        assetsError={assetsError}
        assetsFirstLoading={assetsFirstLoading}
        assetsLoading={assetsLoading}
        collection={collection}
        onAdd={onAdd}
        searchQuery={searchQuery}
        tableActionsForSelected={tableActionsForSelected}
        tableActionsMenu={tableActionsMenu}
        tableColumns={columns}
        totalEntries={totalCount}
        useBulkSelectResult={useBulkSelectResult}
        isViewOnly={isViewOnly}
      />
      <Modal
        isOpen={isAssetQrCodeFormModalOpened}
        onClose={closeGetQrCodeModal}
        title={
          selectedItemsCount > 1 || isAllTableItemsSelected
            ? t('get-asset-qr-codes')
            : t('get-asset-qr-code')
        }
      >
        <GetQrCodeForm
          getRecipientsLoadOptions={getUsersLoadOptions}
          graphQLErrors={qrCodeCreateMutationResult.error?.graphQLErrors}
          loading={false}
          onCancel={closeGetQrCodeModal}
          onSubmit={onQrCodeCreate}
          selectedItemsCount={selectedItemsCount}
        />
      </Modal>
      <ConfirmationModal
        confirmButtonLabel={t('yes-delete')}
        description={
          <Trans
            i18nKey={
              selectedItemsCount > 1 || isAllTableItemsSelected
                ? 'assets-delete-text'
                : 'asset-delete-text'
            }
            components={{ bold: <strong /> }}
            values={{
              name: selectedSingleTableItem
                ? selectedSingleTableItem.name
                : _get(selectedTableItems, [0, 'name']),
              count: selectedItemsCount,
            }}
          />
        }
        icon={IconName.Delete}
        isOpen={isAssetDeleteModalOpened}
        onClose={closeAssetDeleteModal}
        onConfirm={onConfirmedDelete}
        title={t('are-you-sure')}
      />
    </>
  );
}

export default AssetListContainer;
