import React from 'react';
import { useTranslation } from 'react-i18next';
import { GraphQLErrors } from '@apollo/client/errors';
import { Values } from '@lib/interfaces/form';
import Form, {
  ActionsProps,
  FormRef,
} from '@lib/components/ReactHookForm/FormContainer';
import replaceNullsWithUndefined from '@lib/utils/replaceNullsWithUndefined';
import Button, { ButtonTypes } from '@lib/components/Button/Button';
import ActionsRow from '@lib/components/ActionsRow/ActionsRow';
import { getAreaFields } from 'components/Site/utils';
import { SubmitAction } from '@lib/enums/form';

export interface FormProps {
  areaData?: Values;
  formNewAreaRef?: React.MutableRefObject<FormRef | undefined>;
  graphQLErrors?: GraphQLErrors;
  loading: boolean;
  onCancel: () => void;
  onSubmit: (values: Values) => void;
  submitNewAreaRef: React.MutableRefObject<SubmitAction | undefined>;
}

function AreaCreateForm(props: FormProps) {
  const {
    areaData,
    formNewAreaRef,
    graphQLErrors,
    loading,
    onCancel,
    onSubmit,
    submitNewAreaRef,
  } = props;
  const { t } = useTranslation();
  const fields = getAreaFields({
    t,
  });

  const actions: (props: ActionsProps) => React.JSX.Element = ({
    onSubmitForm,
    isDirty,
  }) => (
    <ActionsRow
      actions={[
        <Button
          buttonText={t('cancel')}
          buttonType={ButtonTypes.secondaryOutlined}
          disabled={loading}
          fullWidth
          leftIcon="close"
          onClick={onCancel}
        />,
        <div />,
        <Button
          buttonText={t('save-and-create-next')}
          buttonType={ButtonTypes.primaryOutlined}
          fullWidth
          isLoading={loading}
          onClick={() => {
            submitNewAreaRef.current = SubmitAction.CreateAndNew;
            onSubmitForm();
          }}
          disabled={!isDirty}
        />,
        <Button
          buttonText={t('create')}
          buttonType={ButtonTypes.primaryFilled}
          fullWidth
          leftIcon="check"
          isLoading={loading}
          onClick={() => {
            submitNewAreaRef.current = SubmitAction.CreateAndClose;
            onSubmitForm();
          }}
          disabled={!isDirty}
        />,
      ]}
    />
  );

  return (
    <Form
      actions={actions}
      defaultValues={areaData ? replaceNullsWithUndefined(areaData) : {}}
      fields={fields}
      formId="area"
      formRef={formNewAreaRef}
      graphQLErrors={graphQLErrors}
      graphQLErrorsSkipPath={['areas', '0']}
      loading={loading}
      onSubmit={onSubmit}
    />
  );
}

export default AreaCreateForm;
