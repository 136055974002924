import React from 'react';
import { TFunction } from 'i18next';
import _get from 'lodash/get';
import _upperFirst from 'lodash/upperFirst';
import _toLower from 'lodash/toLower';
import { Intervention, InterventionStatusEnum } from 'graphql-common';
import TextCell from '@lib/components/Table/commonComponents/TextCell/TextCell';
import TextLinkCell from '@lib/components/Table/commonComponents/TextLinkCell/TextLinkCell';
import { CellProps } from '@lib/components/Table/types';
import { getFormattedDate } from '@lib/utils/dateTimeHelpers';
import { dateTimeFormatEU } from '@lib/enums/dateTime';
import { getPriorityChipType } from '@lib/utils/priority';
import { ChipTypes } from '@lib/components/Chip/Chip';
import ChipCell from '@lib/components/Table/commonComponents/ChipCell/ChipCell';
import InterventionStatusChip from '@lib/components/InterventionStatusChip/InterventionStatusChip';

type ColumnsArgs = {
  onView: (item: Intervention) => void;
  t: TFunction<'translation', undefined>;
  onStatusChangeHandler: (
    interventionId: string,
    newStatus: InterventionStatusEnum,
  ) => void;
};

const cellWidthProps = {
  minWidth: 150,
  maxWidth: 150,
  width: 150,
};

export default function getInterventionsTableColumns({
  onView,
  onStatusChangeHandler,
  t,
}: ColumnsArgs) {
  return [
    {
      id: 'code',
      Header: t('code'),
      accessor: 'code',
      Cell: (props: CellProps<Intervention>) => <TextCell {...props} />,
      minWidth: 110,
      maxWidth: 110,
      width: 110,
      sortable: true,
    },
    {
      id: 'name',
      Header: t('name'),
      accessor: 'name',
      Cell: (props: CellProps<Intervention>) => {
        const { item } = props;
        return <TextLinkCell {...props} onClick={() => onView(item)} />;
      },
      minWidth: 250,
      maxWidth: 250,
      width: 250,
      sortable: true,
    },
    {
      id: 'categoryName',
      Header: t('category'),
      Cell: (props: CellProps<Intervention>) => {
        const label = _get(props, 'item.category.name', '');
        const color = _get(props, 'item.category.color', '') as ChipTypes;
        if (!label) return '-';
        return <ChipCell label={label} type={color} leftIcon="circle" />;
      },
      sortable: true,
      ...cellWidthProps,
    },
    {
      id: 'priority',
      Header: t('priority'),
      accessor: 'priority',
      Cell: (props: CellProps<Intervention>) => {
        const priority = _get(props, 'item.priority');
        const label = _upperFirst(_toLower(priority));
        if (!label) return '-';
        return (
          <ChipCell
            label={label}
            type={getPriorityChipType(priority)}
            leftIcon="circle"
          />
        );
      },
      sortable: true,
      ...cellWidthProps,
    },
    {
      id: 'status',
      Header: t('status'),
      Cell: (props: CellProps<Intervention>) => {
        const { item: intervention } = props;
        const status = _get(props, 'item.status');
        return (
          <InterventionStatusChip
            intervention={intervention}
            onStatusChangeHandler={onStatusChangeHandler}
            isViewOnly={status === InterventionStatusEnum.Closed}
          />
        );
      },
      sortable: true,
      ...cellWidthProps,
    },
    {
      id: 'createdAt',
      Header: t('date-created'),
      accessorFn: ({ item }: CellProps<Intervention>) =>
        getFormattedDate(item.createdAt, dateTimeFormatEU),
      Cell: (props: CellProps<Intervention>) => <TextCell {...props} />,
      sortable: true,
      ...cellWidthProps,
    },
    {
      id: 'closedAt',
      Header: t('date-closed'),
      accessorFn: ({ item }: CellProps<Intervention>) =>
        getFormattedDate(item.closedAt, dateTimeFormatEU),
      Cell: (props: CellProps<Intervention>) => <TextCell {...props} />,
      sortable: true,
      ...cellWidthProps,
    },
  ];
}
