import React from 'react';
import { useTranslation } from 'react-i18next';
import Button, { ButtonTypes } from '@lib/components/Button/Button';
import styles from './ProfileForm.module.scss';

interface Props {
  isDirty: boolean;
  loading?: boolean;
}

function ProfileFormActions(props: Props) {
  const { isDirty, loading } = props;
  const { t } = useTranslation();

  return (
    <div className={styles.action}>
      <Button
        type="submit"
        buttonType={ButtonTypes.primaryFilled}
        buttonText={t('save-changes')}
        leftIcon="check"
        disabled={!isDirty}
        isLoading={loading}
      />
    </div>
  );
}

export default ProfileFormActions;
