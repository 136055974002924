import { GroupEnum } from 'graphql-common';
import { Values } from '@lib/interfaces/form';

export default function getNewValidityHintConfigKeys(
  validityHintConfig: Values,
  group: GroupEnum,
  lastPosition: number,
) {
  const validityHintConfigKeys = Object.keys(validityHintConfig);
  const newKeysSet: Set<string> = new Set();

  validityHintConfigKeys.forEach((key) => {
    const [rowIndex, columnIndex] = key.split('-').map(Number);

    if (group === GroupEnum.Rows) {
      newKeysSet.add(`${lastPosition}-${columnIndex}`);
    } else if (group === GroupEnum.Columns) {
      newKeysSet.add(`${rowIndex}-${lastPosition}`);
    }
  });

  return Array.from(newKeysSet);
}
