import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import _get from 'lodash/get';
import _noop from 'lodash/noop';
import { ScopeEnum, User } from 'graphql-common';
import toast from '@lib/components/Toast/Toast';
import onDestroyListItemCallBack from '@lib/utils/onDestroyListItemCallBack';
import getAppUrlWithDomain from '@lib/utils/getAppUrlWithDomain';
import { UseBulkSelectResult } from '@lib/hooks/useTableItemsBulkSelect';
import getAllTableItemsIds from '@lib/utils/getAllTableItemsIds';
import tableItemsBulkAction from '@lib/utils/tableItemsBulkAction';
import {
  Modals,
  UseModalManagementResult,
} from '@lib/hooks/useModalManagement';
import useOutletContext, { ContextProps } from 'hooks/useOutletContext';
import { APP_URLS } from 'constants/urls';
import { ListQueryHookResult } from 'utils/fetch/types';
import useSearchListQueries from './useSearchListQueries';
import useUsersMutations from './useUsersMutations';

const { VITE_AUTH_PLATFORM_URL: AUTH_PLATFORM } = import.meta.env;

interface UseActionsProps {
  refetchWhoami?: () => void;
  useBulkSelectResult: UseBulkSelectResult<User>;
  useModalManagementResult: UseModalManagementResult;
  useUsersQueryHookResult: ListQueryHookResult<User>;
}

export default function useUsersActions({
  refetchWhoami = _noop,
  useBulkSelectResult,
  useModalManagementResult,
  useUsersQueryHookResult,
}: UseActionsProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { search, pathname } = useLocation();
  const { selectedSingleTableItem, handleSelectSingleTableItem } =
    useBulkSelectResult;
  const { openModal, closeAllModals, getModalData } = useModalManagementResult;

  const selectedUserId = selectedSingleTableItem?.id || null;
  const outletContext: ContextProps = useOutletContext();
  const currentUserId = _get(outletContext, 'user.id');

  // Users
  const {
    refetch: refetchUsers,
    collection,
    totalPages,
    fetchIds,
    refetchAllIds: refetchUsersAllIds,
  } = useUsersQueryHookResult;

  const {
    resendRegistrationConfirmationMutation,
    resendEmailConfirmationMutation,
    destroyMutation,
    bulkActivateMutation,
    bulkDeactivateMutation,
    teamUpdateMutation,
  } = useUsersMutations();

  const { teamsLoadOptions } = useSearchListQueries();

  // Actions
  const onGetAllIds = () =>
    getAllTableItemsIds<User>({
      fetchIds,
      useBulkSelectResult,
    });

  const onDestroyHandler = () => {
    if (selectedUserId) {
      destroyMutation({
        variables: {
          id: selectedUserId,
        },
        onCompleted: () => {
          closeAllModals();
          onDestroyListItemCallBack({
            collection,
            navigate,
            pathname,
            refetch: () => {
              refetchUsers();
              refetchWhoami();
            },
            search,
            totalPages,
          });
        },
      });
    }
  };

  const onView = (item?: User) => {
    if (item?.id) {
      navigate(
        APP_URLS.dashboard.users.view.getDynamicPath({
          pathParts: { id: item?.id },
        }),
      );
    }
  };

  const onEdit = (item?: User) => {
    if (item?.id) {
      handleSelectSingleTableItem(item);
      openModal(Modals.UserFormModal);
    }
  };

  const onDelete = (item?: User) => {
    if (item?.id) {
      handleSelectSingleTableItem(item);
      openModal(Modals.UserDeleteModal);
    }
  };

  const onConfirmedDelete = () => {
    closeAllModals();
    toast({
      content: t('destroy-team-member-success'),
      closeCallback: onDestroyHandler,
    });
  };

  const onActivate = (item?: User) => {
    if (item?.id) {
      bulkActivateMutation({
        variables: { input: { ids: [item.id] } },
        onCompleted: () => {
          refetchUsers();
          refetchWhoami();
          toast({ content: t('activate-user-success') });
        },
      });
    }
  };

  const onDeactivate = (item?: User) => {
    if (item?.id) {
      bulkDeactivateMutation({
        variables: { input: { ids: [item.id] } },
        onCompleted: () => {
          refetchUsers();
          refetchWhoami();
          toast({ content: t('deactivate-user-success') });
        },
      });
    }
  };

  const onBulkActivate = () => {
    tableItemsBulkAction<User>({
      action: (idsValue?: string[]) => {
        if (idsValue) {
          bulkActivateMutation({
            variables: { input: { ids: idsValue } },
            onCompleted: () => {
              useBulkSelectResult?.handleUnselectAllTableItems();
              onDestroyListItemCallBack({
                collection,
                navigate,
                pathname,
                refetch: () => {
                  refetchUsers();
                  refetchWhoami();
                },
                search,
                totalPages,
              });
            },
          });
        }
      },
      useBulkSelectResult,
      onGetAllIds,
    });
  };

  const onBulkDeactivate = () => {
    tableItemsBulkAction<User>({
      action: (idsValue?: string[]) => {
        if (idsValue) {
          bulkDeactivateMutation({
            variables: { input: { ids: idsValue } },
            onCompleted: () => {
              useBulkSelectResult?.handleUnselectAllTableItems();
              onDestroyListItemCallBack({
                collection,
                navigate,
                pathname,
                refetch: () => {
                  refetchUsers();
                  refetchWhoami();
                },
                search,
                totalPages,
              });
            },
          });
        }
      },
      useBulkSelectResult,
      onGetAllIds,
    });
  };

  const onTeamMembersUpdate = (teamId: string) => {
    openModal(Modals.MoveMembersToTeamModal, { teamId });
  };

  const onConfirmedTeamMembersUpdate = () => {
    const modalData = getModalData(Modals.MoveMembersToTeamModal);
    const teamId = _get(modalData, 'teamId', '') as string;
    closeAllModals();
    tableItemsBulkAction<User>({
      action: (idsValue?: string[]) => {
        if (idsValue) {
          teamUpdateMutation({
            variables: {
              id: teamId,
              attributes: {
                users: idsValue.map((idValue) => ({ id: idValue })),
              },
            },
            onCompleted: () => {
              useBulkSelectResult?.handleUnselectAllTableItems();
              onDestroyListItemCallBack({
                collection,
                navigate,
                pathname,
                refetch: () => {
                  refetchUsers();
                  refetchUsersAllIds();
                },
                search,
                totalPages,
              });
            },
          });
        }
      },
      useBulkSelectResult,
      onGetAllIds,
    });
  };

  const onResendRegistrationConfirmationHandler = (id: string) =>
    resendRegistrationConfirmationMutation({
      variables: {
        input: {
          scope: ScopeEnum.User,
          id,
          redirectUrl: getAppUrlWithDomain(
            APP_URLS.setup.finishRegistration.path,
            AUTH_PLATFORM,
          ),
        },
      },
    });

  const onResendEmailConfirmationHandler = (id: string) =>
    resendEmailConfirmationMutation({
      variables: {
        input: {
          scope: ScopeEnum.User,
          id,
          redirectUrl: getAppUrlWithDomain(
            APP_URLS.setup.confirmEmail.path,
            AUTH_PLATFORM,
          ),
        },
      },
    });

  const onSubmitUserFormCallback = () => {
    closeAllModals();
    refetchUsers();
  };

  const openTeamPage = (teamId: string) => {
    navigate(
      APP_URLS.dashboard.teams.view.getDynamicPath({
        pathParts: { id: teamId },
      }),
    );
  };

  const openUserViewPage = (user?: User) => {
    if (user?.id) {
      navigate(
        APP_URLS.dashboard.users.view.getDynamicPath({
          pathParts: { id: user.id },
        }),
      );
    }
  };

  const openUserEditAccessesPage = (user?: User) => {
    if (user?.id) {
      navigate(
        APP_URLS.dashboard.users.editAccess.getDynamicPath({
          pathParts: { id: user.id },
        }),
      );
    }
  };

  return {
    currentUserId,
    onActivate,
    onBulkActivate,
    onBulkDeactivate,
    onConfirmedDelete,
    onConfirmedTeamMembersUpdate,
    onDeactivate,
    onDelete,
    onEdit,
    onResendEmailConfirmationHandler,
    onResendRegistrationConfirmationHandler,
    onSubmitUserFormCallback,
    onTeamMembersUpdate,
    onView,
    openTeamPage,
    openUserEditAccessesPage,
    openUserViewPage,
    selectedUserId,
    teamsLoadOptions,
  };
}
