import React from 'react';
import {
  components as DefaultSelectComponents,
  DropdownIndicatorProps,
} from 'react-select';
import classNames from 'classnames';
import _isEmpty from 'lodash/isEmpty';
import MaterialIcon from '@lib/components/MaterialIcon/MaterialIcon';
import Tooltip from '@lib/components/Tooltip/Tooltip';
import { TooltipShape } from '@lib/components/Input/enums';
import styles from '../Select.module.scss';

interface Props {
  selectProps: {
    disabled?: boolean;
    infoTooltipProps?: TooltipShape;
  };
}

function ErrorIndicator(props: DropdownIndicatorProps & Props): JSX.Element {
  const { selectProps } = props;
  const { disabled, infoTooltipProps } = selectProps;
  const isTooltipVisible = !_isEmpty(infoTooltipProps);
  return (
    <DefaultSelectComponents.DropdownIndicator {...props}>
      {isTooltipVisible ? (
        // @ts-ignore
        <Tooltip
          key="disabled-icon-tooltip"
          maxWidth={160}
          contentClassName={styles.rightTooltipContentError}
          {...infoTooltipProps}
        >
          <div className={styles.errorIndicatorIcon}>
            <MaterialIcon
              icon="error"
              size="md-24"
              className={classNames({ disabled })}
            />
          </div>
        </Tooltip>
      ) : (
        <div className={styles.dropdownIndicatorIcon}>
          <MaterialIcon
            icon={disabled ? 'lock' : 'arrow_drop_down'}
            symbolsOutlined
            size="md-24"
            className={classNames({ disabled })}
          />
        </div>
      )}
    </DefaultSelectComponents.DropdownIndicator>
  );
}

export default ErrorIndicator;
