import React from 'react';
import classNames from 'classnames';
import {
  components as DefaultSelectComponents,
  OptionProps,
} from 'react-select';
import _get from 'lodash/get';
import MaterialIcon from '@lib/components/MaterialIcon/MaterialIcon';
import { SelectOption } from '@lib/components/Select/types';
import styles from '../Select.module.scss';

function CheckedOption({ children, ...restProps }: OptionProps): JSX.Element {
  // @ts-ignore
  const { selectProps, value, data } = restProps;
  // @ts-ignore
  const { values } = selectProps;
  const isSelected = !!values?.some((v: SelectOption) => v.value === value);
  const isCreateOption = _get(data, '__isNew__');
  const icon = _get(data, 'data.icon');
  return (
    <DefaultSelectComponents.Option
      {...restProps}
      className={classNames(styles.option, styles.checkedOption, {
        active: isSelected,
        [styles.checkedOptionWithLeftIcon]: !!icon,
      })}
    >
      {icon && (
        <span className={styles.optionCheckbox}>
          <MaterialIcon icon={icon} size="md-24" symbolsOutlined />
        </span>
      )}
      {children}
      {isSelected && (
        <span className={styles.optionCheckbox}>
          <MaterialIcon icon="check" size="md-18" />
        </span>
      )}
      {isCreateOption && (
        <span className={styles.optionCheckbox}>
          <MaterialIcon icon="keyboard_return" size="md-18" />
        </span>
      )}
    </DefaultSelectComponents.Option>
  );
}

export default CheckedOption;
