import {
  AssetCounterMeasuresQueryVariables,
  CounterMeasure,
  CounterMeasureFilterInputObject,
  DateRangeInputObject,
  FilterGroupingEnum,
  useAssetCounterMeasuresQuery,
} from 'graphql-common';
import _get from 'lodash/get';
import { GetUrlParams } from '@lib/enums/urls';
import { ApolloError } from '@apollo/client/errors';
import { ListQueryHookProps } from './types';

export type UseAssetCountersQueryHookResult = {
  collection: CounterMeasure[];
  error: ApolloError | undefined;
  firstLoading: boolean;
  loading: boolean;
  refetch: () => Promise<unknown>;
  totalCount: number;
  totalPages: number;
};

type Args = ListQueryHookProps & {
  assetId: string;
  counterId: string;
  dateRange: DateRangeInputObject;
};

export default function useAssetCounterMeasuresQueryHook({
  assetId,
  counterId,
  fetchPolicy = 'cache-and-network',
  paginationParams,
  filterParams,
  skip,
  usePrevious = true,
  dateRange,
}: Args): UseAssetCountersQueryHookResult {
  const page = _get(paginationParams, GetUrlParams.Page);
  const perPage = _get(paginationParams, GetUrlParams.PerPage);
  const orderingField = _get(paginationParams, GetUrlParams.OrderingField);
  const orderingDirection = _get(
    paginationParams,
    GetUrlParams.OrderingDirection,
  );
  const periodStart =
    (_get(filterParams, GetUrlParams.PeriodStart) as string) ||
    dateRange?.startDate;
  const periodEnd =
    (_get(filterParams, GetUrlParams.PeriodEnd) as string) ||
    dateRange?.endDate;
  const queryVariables: AssetCounterMeasuresQueryVariables = {
    page: Number(page),
    limit: Number(perPage),
    assetId,
    counterId,
  };
  const filters: CounterMeasureFilterInputObject[] = [];
  if (periodStart && periodEnd) {
    filters.push({
      createdAt: {
        grouping: FilterGroupingEnum.And,
        predicate: { gteq: periodStart },
        filterGroup: 'completedAt',
      },
    });
    filters.push({
      createdAt: {
        grouping: FilterGroupingEnum.And,
        predicate: { lteq: periodEnd },
        filterGroup: 'completedAt',
      },
    });
  }
  if (filters.length) {
    queryVariables.filters = filters;
  }
  if (orderingField && orderingDirection) {
    queryVariables.sorts = {
      [orderingField]: orderingDirection,
    };
  }
  const query = useAssetCounterMeasuresQuery({
    fetchPolicy,
    variables: queryVariables,
    skip,
  });
  const { loading, data, previousData, refetch, error } = query;
  const queryData = usePrevious ? data || previousData : data;
  const firstLoading = loading && previousData === undefined;
  const collection = _get(queryData, 'data.collection', []) as CounterMeasure[];
  const metadata = _get(queryData, 'data.metadata');
  const totalCount = _get(metadata, 'totalCount', 0);
  const totalPages = _get(metadata, 'totalPages', 1);

  return {
    refetch,
    collection,
    error,
    firstLoading,
    loading,
    totalPages,
    totalCount,
  };
}
