import React from 'react';
import { Documentation } from 'graphql-common';
import { UseBulkSelectResult } from '@lib/hooks/useTableItemsBulkSelect';
import AssetDocumentations from 'components/AssetDocumentations/AssetDocumentations';
import { AssetViewMode } from './enums';
import useDocumentationActions from './utils/useDocumentationActions';
import { UseModalManagementResult } from './utils/useModalManagement';
import { UseActionsResult } from './utils/useActions';

type Props = {
  id: string;
  menuBar: JSX.Element;
  useBulkSelectResult?: UseBulkSelectResult<Documentation>;
  useActionsResult: UseActionsResult;
  useModalManagementResult: UseModalManagementResult;
  viewMode: AssetViewMode;
  isViewOnly: boolean;
};

export default function AssetDocumentationsContainer(props: Props) {
  const {
    id,
    menuBar,
    useBulkSelectResult,
    useActionsResult,
    useModalManagementResult,
    viewMode,
    isViewOnly,
  } = props;

  const useDocumentationActionsResult = useDocumentationActions({
    id,
    useActionsResult,
    useModalManagementResult,
    useBulkSelectResult,
    viewMode,
  });

  return (
    <AssetDocumentations
      menuBar={menuBar}
      useActionsResult={useActionsResult}
      useBulkSelectResult={useBulkSelectResult}
      useDocumentationActionsResult={useDocumentationActionsResult}
      useModalManagementResult={useModalManagementResult}
      isViewOnly={isViewOnly}
    />
  );
}
