import React from 'react';
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import { Link } from 'react-router-dom';
import Typography from '@lib/components/Typography/Typography';
import { useBreadcrumbContext } from './BreadCrumbsContext';
import styles from './BreadCrumbs.module.scss';

interface Props {
  className?: string;
}

export default function BreadCrumbs({ className }: Props) {
  const { breadcrumbs } = useBreadcrumbContext();
  return (
    <ul className={classNames(styles.list, className)}>
      {breadcrumbs.map(({ label, path }) => (
        <li key={uuidv4()} className={styles.listItem}>
          {path ? (
            <Link to={path} className={styles.link}>
              <Typography variant="label" component="span">
                {label}
              </Typography>
            </Link>
          ) : (
            <Typography variant="label" component="span">
              {label}
            </Typography>
          )}
        </li>
      ))}
    </ul>
  );
}
