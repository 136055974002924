import { useCallback, useState } from 'react';

export interface UseLoadingStateResult {
  loadingState: boolean;
  setLoadingState: (loading: boolean) => void;
}

const useLoadingState = (defaultLoading = false): UseLoadingStateResult => {
  const [loadingState, setLoading] = useState(defaultLoading);

  const setLoadingState = useCallback((v: boolean) => {
    setLoading(v);
  }, []);

  return { loadingState, setLoadingState };
};

export default useLoadingState;
