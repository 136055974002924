import React from 'react';
import {
  components as DefaultSelectComponents,
  ValueContainerProps,
} from 'react-select';
import classNames from 'classnames';
import _get from 'lodash/get';
import styles from '../Select.module.scss';
import CountryOption from './CountryOption';

function ValueContainer(props: ValueContainerProps): JSX.Element {
  const { children, ...valueContainerProps } = props;
  const { isMulti, selectProps } = valueContainerProps;
  const OptionComponent = _get(props, 'selectProps.components.Option');

  if (isMulti && Array.isArray(children) && children.length) {
    return (
      <DefaultSelectComponents.ValueContainer
        {...valueContainerProps}
        className={styles.valueContainer}
      >
        <span className={styles.multiValueWrap}>
          {Array.isArray(children[0]) && children[0].length && (
            <>
              {children[0]}
              {children[0].length > 1 && (
                <span className={styles.valuesCount}>
                  +{children[0].length - 1}
                </span>
              )}
            </>
          )}
          <span>{children[1]}</span>
        </span>
      </DefaultSelectComponents.ValueContainer>
    );
  }

  if (OptionComponent?.name === CountryOption?.name) {
    const value = _get(selectProps, 'value.value', '');
    return (
      <DefaultSelectComponents.ValueContainer
        {...valueContainerProps}
        className={styles.valueCountryContainer}
      >
        <span
          className={classNames(styles.flag, styles[value.toLowerCase()])}
        />
        {children}
      </DefaultSelectComponents.ValueContainer>
    );
  }

  return (
    <DefaultSelectComponents.ValueContainer
      {...valueContainerProps}
      className={styles.valueContainer}
    >
      {children}
    </DefaultSelectComponents.ValueContainer>
  );
}

export default ValueContainer;
