import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FormTemplateQuestion,
  FormTemplateQuestionFieldsKindEnum,
} from 'graphql-common';
import _get from 'lodash/get';
import Typography from '@lib/components/Typography/Typography';
import Placeholder from '@lib/assets/images/preview-placeholder.svg?react';
import Image from '@lib/components/Image/Image';
import FormTemplateFieldNames from '@lib/enums/fieldNames/formTemplateFieldNames';
import styles from './FormTemplateBuilderQuestionView.module.scss';

interface Props {
  children: React.ReactNode;
  value: FormTemplateQuestion;
}

function FormTemplateBuilderQuestionView(props: Props) {
  const { children, value } = props;
  const name = _get(value, FormTemplateFieldNames.Name);
  const description = _get(value, FormTemplateFieldNames.Description);
  const attachmentsAttached = _get(
    value,
    FormTemplateFieldNames.AttachmentsAttached,
  );
  const kind = _get(value, 'fields.0.kind');
  const isNameVisible = kind
    ? ![
        FormTemplateQuestionFieldsKindEnum.DecisionBox,
        FormTemplateQuestionFieldsKindEnum.ImportantNotice,
      ].includes(kind)
    : true;
  const isDescriptionVisible = !!description;
  const isAttachmentsVisible =
    attachmentsAttached &&
    // @ts-ignore
    attachmentsAttached.filter(({ _destroy }) => !_destroy).length > 0;
  const isContentVisible = kind
    ? ![FormTemplateQuestionFieldsKindEnum.Section].includes(kind)
    : true;

  const { t } = useTranslation();

  const namePlaceholder =
    kind === FormTemplateQuestionFieldsKindEnum.Section
      ? t('section-name')
      : t('question');
  const nameTypographyVariant =
    kind === FormTemplateQuestionFieldsKindEnum.Section ? 'h3' : 'body';

  return (
    <div className={styles.root}>
      {isNameVisible && (
        <div className={styles.titleBlock}>
          <Typography variant={nameTypographyVariant} strong>
            {name || namePlaceholder}
          </Typography>
        </div>
      )}
      {isDescriptionVisible && (
        <div className={styles.textBlock}>
          <Typography variant="body">{description}</Typography>
        </div>
      )}
      {isAttachmentsVisible && (
        <div className={styles.attachmentsBlock}>
          {attachmentsAttached.map((attachment) =>
            // @ts-ignore
            // eslint-disable-next-line no-underscore-dangle
            attachment?._destroy ? null : (
              // @ts-ignore
              <div key={attachment.id || attachment?.file?.id}>
                <Image
                  src={
                    attachment?.urls?.large ||
                    attachment?.urls?.original ||
                    // @ts-ignore
                    attachment?.url ||
                    Placeholder
                  }
                />
              </div>
            ),
          )}
        </div>
      )}
      {isContentVisible && (
        <div className={styles.contentBlock}>{children}</div>
      )}
    </div>
  );
}

export default memo(FormTemplateBuilderQuestionView);
