import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import TaskFormView from 'components/Task/TaskFormView';
import BreadCrumbs from '@lib/components/BreadCrumbs/BreadCrumbs';
import { getBreadCrumbsDataByKey } from 'utils/breadcrumbs';
import useActions from './utils/useActions';

export default function TaskFormViewContainer() {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const {
    idParam,
    onOpenEditTaskDetails,
    taskData,
    taskFormTemplate,
    taskFormTemplateError,
    taskFormTemplateLoading,
    taskFormTemplateVersion,
  } = useActions({});

  return (
    <>
      <BreadCrumbs
        data={getBreadCrumbsDataByKey({
          pathname,
          t,
          entity: { id: taskData?.id, name: taskData?.name },
        })}
      />
      <TaskFormView
        idParam={idParam}
        onOpenEditTaskDetails={onOpenEditTaskDetails}
        taskData={taskData}
        taskFormTemplate={taskFormTemplate}
        taskFormTemplateVersion={taskFormTemplateVersion}
        taskFormTemplateGraphQLErrors={taskFormTemplateError}
        taskFormTemplateLoading={taskFormTemplateLoading}
      />
    </>
  );
}
