import _get from 'lodash/get';
import {
  useAssetCategoriesSearchListLazyQuery,
  useAssetsSearchListLazyQuery,
  useSiteAreasSearchListLazyQuery,
  useSitesSearchListLazyQuery,
  useUsersSearchListLazyQuery,
} from 'graphql-common';
import { GetLoadOptions } from '@lib/components/Select/types';
import { getAssetCategoriesLoadOptions as getAssetCategoriesLoadOptionsFn } from 'utils/fetch/assetCategoriesSearchList';
import AssetFieldNames from '@lib/enums/fieldNames/assetFieldNames';
import { getSiteAreasLoadOptions as getSiteAreasLoadOptionsFn } from 'utils/fetch/siteAreasSearchList';
import { getUsersLoadOptions as getUsersLoadOptionsFn } from 'utils/fetch/usersSearchList';
import { getSitesLoadOptions as getSitesLoadOptionsFn } from 'utils/fetch/sitesSearchList';
import { getAssetsLoadOptions as getAssetsLoadOptionsFn } from 'utils/fetch/assetsSearchList';

export type UseSearchListQueriesResult = {
  getAssetsLoadOptions: GetLoadOptions;
  getAssetCategoriesLoadOptions: GetLoadOptions;
  getSiteAreasLoadOptions: GetLoadOptions;
  getSitesLoadOptions: GetLoadOptions;
  getUsersLoadOptions: GetLoadOptions;
};

export default function useSearchListQueries(): UseSearchListQueriesResult {
  // Assets
  const [fetchAssetsLazyQuery] = useAssetsSearchListLazyQuery({
    fetchPolicy: 'cache-and-network',
  });
  const getAssetsLoadOptions = () =>
    getAssetsLoadOptionsFn(fetchAssetsLazyQuery);

  // Asset categories
  const [fetchAssetCategoriesLazyQuery] = useAssetCategoriesSearchListLazyQuery(
    {
      fetchPolicy: 'cache-and-network',
    },
  );
  const getAssetCategoriesLoadOptions = () =>
    getAssetCategoriesLoadOptionsFn(fetchAssetCategoriesLazyQuery);

  // Site query
  const [fetchSitesSearchListLazyQuery] = useSitesSearchListLazyQuery({
    fetchPolicy: 'cache-and-network',
  });
  const getSitesLoadOptions: GetLoadOptions = () =>
    getSitesLoadOptionsFn(fetchSitesSearchListLazyQuery);

  // Site area query
  const [fetchSiteAreasSearchListLazyQuery] = useSiteAreasSearchListLazyQuery({
    fetchPolicy: 'cache-and-network',
  });
  const getSiteAreasLoadOptions: GetLoadOptions = (name, values) => {
    const selectedSiteId = _get(values, [AssetFieldNames.Site, 'value']);
    return getSiteAreasLoadOptionsFn(
      fetchSiteAreasSearchListLazyQuery,
      selectedSiteId,
    );
  };

  // Users
  const [fetchUsersSearchListLazyQuery] = useUsersSearchListLazyQuery({
    fetchPolicy: 'cache-and-network',
  });
  const getUsersLoadOptions = () =>
    getUsersLoadOptionsFn(fetchUsersSearchListLazyQuery);

  return {
    getAssetsLoadOptions,
    getAssetCategoriesLoadOptions,
    getSiteAreasLoadOptions,
    getSitesLoadOptions,
    getUsersLoadOptions,
  };
}
