const generateSafeRedirect = (
  internalRoute: string,
  origin = window.location.origin,
) => {
  const decodedInternalRoute = decodeURIComponent(internalRoute);
  const sanitizedRoute = decodedInternalRoute.replace(
    /[^a-zA-Z0-9-_?=&/#.@:[\]]/g,
    '',
  );
  const routeWithLeadingSlash =
    sanitizedRoute.indexOf('/') === 0 ? sanitizedRoute : `/${sanitizedRoute}`;
  return encodeURIComponent(`${origin}${routeWithLeadingSlash}`);
};

export default generateSafeRedirect;
