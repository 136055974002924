import { useState } from 'react';
import { Task } from 'graphql-common';
import { UseBulkSelectResult } from '@lib/hooks/useTableItemsBulkSelect';

interface UseModalManagementProps {
  useBulkSelectResult: UseBulkSelectResult<Task>;
}

export interface UseModalManagementResult {
  closeArchiveModal: () => void;
  closeDeleteModal: () => void;
  closeScheduleModal: () => void;
  isArchiveModalOpened: boolean;
  isDeleteModalOpened: boolean;
  isScheduleModalOpened: boolean;
  openArchiveModal: () => void;
  openDeleteModal: () => void;
  openScheduleModal: () => void;
}

export default function useModalManagement(
  props: UseModalManagementProps,
): UseModalManagementResult {
  const { useBulkSelectResult } = props;
  const { handleSelectSingleTableItem } = useBulkSelectResult;

  // State
  const [isScheduleModalOpened, setScheduleModalOpened] = useState(false);
  const [isDeleteModalOpened, setDeleteModalOpened] = useState(false);
  const [isArchiveModalOpened, setArchiveModalOpened] = useState(false);

  // Actions
  const openScheduleModal = () => setScheduleModalOpened(true);
  const closeScheduleModal = () => {
    setScheduleModalOpened(false);
    handleSelectSingleTableItem(null);
  };
  const openDeleteModal = () => setDeleteModalOpened(true);
  const closeDeleteModal = () => {
    setDeleteModalOpened(false);
    handleSelectSingleTableItem(null);
  };
  const openArchiveModal = () => setArchiveModalOpened(true);
  const closeArchiveModal = () => {
    setArchiveModalOpened(false);
    handleSelectSingleTableItem(null);
  };

  return {
    closeArchiveModal,
    closeDeleteModal,
    closeScheduleModal,
    isArchiveModalOpened,
    isDeleteModalOpened,
    isScheduleModalOpened,
    openArchiveModal,
    openDeleteModal,
    openScheduleModal,
  };
}
