import { InterventionAction } from 'graphql-common';
import FieldNames from '@lib/enums/fieldNames/interventionActionFieldNames';
import { getInterventionActionStatusOption } from '@lib/utils/status';
import { getTimeFromMinutesCount } from '@lib/utils/getTimeFromMinutesCount';
import { transformDate } from '@lib/utils/dateTimeHelpers';

export const DEFAULT_VALUES = {
  [FieldNames.Description]: '',
};

export default function getInterventionDefaultValuesFromQueryData(
  data: InterventionAction,
  assigneeIds: { id: string }[],
) {
  return {
    [FieldNames.Description]: data?.text || '',
    [FieldNames.AttachmentsAttached]: data?.attachmentsAttached || [],
    [FieldNames.Status]: data?.status
      ? getInterventionActionStatusOption(data.status)
      : null,
    [FieldNames.Assignee]:
      assigneeIds?.length > 0
        ? assigneeIds.map(({ id }) => ({ value: id }))
        : null,
    [FieldNames.EstimatedTime]: data?.estimatedTime
      ? getTimeFromMinutesCount(data.estimatedTime)
      : '',
    [FieldNames.EstimatedDate]: data?.estimatedDate
      ? transformDate(new Date(data.estimatedDate), false, true)
      : '',
    [FieldNames.LogisticTime]: data?.logisticTime
      ? getTimeFromMinutesCount(data.logisticTime)
      : '',
    [FieldNames.DurationTime]: data?.durationTime
      ? getTimeFromMinutesCount(data.durationTime)
      : '',
  };
}
