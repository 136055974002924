import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ComponentEnum,
  LevelEnum,
  Task,
  TaskScopeNameEnum,
} from 'graphql-common';
import { useLocation, useNavigate } from 'react-router-dom';
import Trans from '@lib/components/Trans/Trans';
import ConfirmationModal from '@lib/components/ConfirmationModal/ConfirmationModal';
import BreadCrumbs from '@lib/components/BreadCrumbs/BreadCrumbs';
import { getBreadCrumbsDataByKey } from 'utils/breadcrumbs';
import { APP_URLS } from 'constants/urls';
import useTabParam from '@lib/hooks/useTabParam';
import usePrevious from '@lib/hooks/usePrevious';
import { useTableItemsBulkSelect } from '@lib/hooks/useTableItemsBulkSelect';
import Tasks from 'components/Tasks/Tasks';
import ScheduleTask from 'components/ScheduleTask/ScheduleTask';
import useOutletContext, { ContextProps } from 'hooks/useOutletContext';
import { IconName } from '@lib/components/Modal/enums';
import useTableHelpers from './utils/useTableHelpers';
import useActions from './utils/useActions';
import useModalManagement from './utils/useModalManagement';

interface Props {
  isApprovals?: boolean;
}

function TasksContainer({ isApprovals }: Props) {
  const useBulkSelectResult = useTableItemsBulkSelect<Task>();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [tabParam, setTabParam] = useTabParam(
    isApprovals ? TaskScopeNameEnum.MyApprovals : TaskScopeNameEnum.Active,
  );
  const prevTabParam = usePrevious(tabParam);

  const { getUserAccessByComponent }: ContextProps = useOutletContext();
  const isViewOnly =
    getUserAccessByComponent(ComponentEnum.TasksManagement) === LevelEnum.Read;

  const useModalManagementResult = useModalManagement({
    useBulkSelectResult,
  });
  const {
    closeArchiveModal,
    closeDeleteModal,
    isArchiveModalOpened,
    isDeleteModalOpened,
    isScheduleModalOpened,
  } = useModalManagementResult;

  const useActionsResult = useActions({
    isApprovals,
    tabParam,
    useBulkSelectResult,
    useModalManagementResult,
  });
  const {
    countersLoading,
    loadingState,
    myApprovalsCounter,
    onCloseScheduleModal,
    onConfirmedBulkArchive,
    onConfirmedDelete,
    onRescheduleHandler,
    taskBulkSchedulingError,
    taskBulkSchedulingLoading,
    taskScheduleData,
    taskScheduleLoading,
    tasks,
    tasksError,
    tasksFirstLoading,
    tasksLoading,
    totalTasksCount,
  } = useActionsResult;

  const taskScope = (
    tasksLoading ? prevTabParam || tabParam : tabParam
  ) as TaskScopeNameEnum;

  const { tableColumns, tableActionsMenu, tableActionsForSelected, tableTabs } =
    useTableHelpers({
      isApprovals,
      taskScope,
      useActionsResult,
      useBulkSelectResult,
      useModalManagementResult,
      isViewOnly,
    });

  useEffect(() => {
    if (!countersLoading && myApprovalsCounter === 0 && isApprovals) {
      navigate(APP_URLS.notFound.path);
    }
  }, [countersLoading, isApprovals, myApprovalsCounter, navigate]);

  return (
    <>
      <BreadCrumbs data={getBreadCrumbsDataByKey({ pathname, t })} />
      <Tasks
        fullHeight
        isApprovals={isApprovals}
        loadingState={loadingState}
        pageTitle={isApprovals ? t('approvals') : t('tasks')}
        setTabParam={setTabParam}
        showCreateBtn={!isApprovals}
        tabParam={tabParam}
        tableActionsForSelected={tableActionsForSelected}
        tableActionsMenu={tableActionsMenu}
        tableColumns={tableColumns}
        tableTabs={isApprovals ? [] : tableTabs}
        tasks={tasks}
        tasksError={tasksError}
        tasksFirstLoading={tasksFirstLoading}
        tasksLoading={tasksLoading}
        totalTasksCount={totalTasksCount}
        useBulkSelectResult={useBulkSelectResult}
        isViewOnly={isViewOnly}
      />
      <ConfirmationModal
        confirmButtonLabel={t('yes-delete')}
        description={<Trans i18nKey="delete-task-text" />}
        icon={IconName.Delete}
        isOpen={isDeleteModalOpened}
        onClose={closeDeleteModal}
        onConfirm={onConfirmedDelete}
        title={t('are-you-sure')}
      />
      <ConfirmationModal
        confirmButtonLabel={t('yes-archive')}
        description={<Trans i18nKey="archive-task-text" />}
        icon={IconName.Deactivate}
        isOpen={isArchiveModalOpened}
        onClose={closeArchiveModal}
        onConfirm={onConfirmedBulkArchive}
        title={t('are-you-sure')}
      />
      <ScheduleTask
        isOpen={isScheduleModalOpened}
        onClose={onCloseScheduleModal}
        onSubmit={onRescheduleHandler}
        taskBulkSchedulingError={taskBulkSchedulingError}
        taskBulkSchedulingLoading={taskBulkSchedulingLoading}
        taskScheduleData={taskScheduleData}
        taskScheduleLoading={taskScheduleLoading}
      />
    </>
  );
}

export default TasksContainer;
