import React from 'react';
import {
  components as DefaultSelectComponents,
  IndicatorsContainerProps,
} from 'react-select';
import styles from '../Select.module.scss';

function IndicatorsContainer(props: IndicatorsContainerProps) {
  const { children } = props;
  return (
    <DefaultSelectComponents.IndicatorsContainer
      {...props}
      className={styles.indicatorsContainer}
    >
      {children}
    </DefaultSelectComponents.IndicatorsContainer>
  );
}

export default IndicatorsContainer;
