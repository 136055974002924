import React from 'react';
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { BaseItem, TableProps } from '@lib/components/Table/types';
import {
  checkHasActionsColumn,
  checkHasActionsMenuColumn,
  checkHasSelectColumn,
  getColumnsCount,
} from '@lib/components/Table/utils';
import Typography from '@lib/components/Typography/Typography';
import TableBodyRow from '@lib/components/Table/components/TableBody/TableBodyRow';
import styles from './TableBody.module.scss';

function TableBody<T extends BaseItem>(props: TableProps<T>) {
  const { data, borderedCells } = props;
  const { t } = useTranslation();
  const hasSelectColumn = checkHasSelectColumn(props);
  const hasActionsColumn = checkHasActionsColumn(props);
  const hasActionsMenuColumn = checkHasActionsMenuColumn(props);
  const className = classNames(styles.body, {
    [styles.tableWithBorderedCells]: borderedCells,
  });
  return (
    <tbody className={className}>
      {data.length ? (
        data.map((item) => {
          return (
            <TableBodyRow
              {...props}
              key={uuidv4()}
              hasActionsColumn={hasActionsColumn}
              hasActionsMenuColumn={hasActionsMenuColumn}
              hasSelectColumn={hasSelectColumn}
              rowItem={item}
            />
          );
        })
      ) : (
        <tr>
          <td colSpan={getColumnsCount(props)}>
            <div className={styles.tdContent}>
              <Typography variant="body">{t('no-results')}</Typography>
            </div>
          </td>
        </tr>
      )}
    </tbody>
  );
}

export default TableBody;
