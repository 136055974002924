import _get from 'lodash/get';
import _without from 'lodash/without';
import { Option, Values } from '@lib/interfaces/form';
import { PredefinedValues } from '@lib/enums/form';
import mergeAndUnlinkMultiSelectValues from '@lib/utils/mergeAndUnlinkMultiSelectValues';
import UserFieldNames from '@lib/enums/fieldNames/userFieldNames';

export default function getSiteMemberships<T>(values: Values, prevValues: T) {
  const siteMemberships = _get(
    values,
    UserFieldNames.SiteMemberships,
  ) as Values;
  const newSiteMemberships = Array.isArray(siteMemberships)
    ? (_without(
        siteMemberships.map((siteMembershipsItem) => {
          if (!siteMembershipsItem) return undefined;
          return { id: siteMembershipsItem.value };
        }),
        undefined,
      ) as { id: string }[])
    : [];
  const isSelectAllOptionSelected = newSiteMemberships.some(
    ({ id }) => id === PredefinedValues.all,
  );
  const prevSites = _get(
    prevValues,
    UserFieldNames.SiteMemberships,
    [],
  ) as Option[];
  const prevIds = prevSites
    .filter(({ value }) => value !== PredefinedValues.all)
    .map(({ value }) => ({ id: value as string }));
  return isSelectAllOptionSelected
    ? null
    : mergeAndUnlinkMultiSelectValues(prevIds, newSiteMemberships);
}
