import React from 'react';
import { useTranslation } from 'react-i18next';
import { SiteArea } from 'graphql-common';
import Typography from '@lib/components/Typography/Typography';
import Button, { ButtonTypes } from '@lib/components/Button/Button';
import MaterialIcon from '@lib/components/MaterialIcon/MaterialIcon';
import { materialSymbolsOutlined } from '@lib/enums/common';
import TextEditor from '@lib/components/TextEditor/TextEditor';
import styles from './SiteView.module.scss';

interface Props {
  areaData?: SiteArea;
  onEdit: () => void;
}

function AreaView(props: Props) {
  const { t } = useTranslation();
  const { areaData, onEdit } = props;
  const { code, description, address } = areaData || {};
  return (
    <>
      <div className={styles.infoList}>
        <div className={styles.infoItem}>
          <p className={styles.infoItemLabel}>
            <MaterialIcon icon="password" symbolsOutlined />
            <Typography variant="caption">{t('code')}</Typography>
          </p>
          <div className={styles.infoItemContent}>
            <Typography variant="body">{code}</Typography>
          </div>
        </div>
        <div className={styles.infoItem}>
          <p className={styles.infoItemLabel}>
            <MaterialIcon icon="location_on" symbolsOutlined />
            <Typography variant="caption">{t('address')}</Typography>
          </p>
          <div className={styles.infoItemContent}>
            <Typography variant="body">{address}</Typography>
          </div>
        </div>
        {!!description && (
          <>
            <div className={styles.infoItem}>
              <p className={styles.infoItemLabel}>
                <MaterialIcon icon="description" symbolsOutlined />
                <Typography variant="caption">{t('description')}</Typography>
              </p>
            </div>
            <TextEditor
              readOnly
              value={description}
              className={styles.description}
            />
          </>
        )}
      </div>
      <Button
        buttonText={t('edit-site-area')}
        buttonType={ButtonTypes.primaryOutlined}
        leftIcon="edit"
        leftIconClassName={materialSymbolsOutlined}
        onClick={onEdit}
        fullWidth
      />
    </>
  );
}

export default AreaView;
