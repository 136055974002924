import { useState } from 'react';

export interface UseModalManagementResult {
  closeAllModals: () => void;
  isInterventionDestroyModalOpened: boolean;
  isCategoryFormModalOpened: boolean;
  closeCategoryFormModal: () => void;
  openCategoryFormModal: () => void;
  openInterventionDestroyModal: () => void;
  closeInterventionDestroyModal: () => void;
  isInterventionCloseModalOpened: boolean;
  openInterventionCloseModal: () => void;
  closeInterventionCloseModal: () => void;
}

export default function useModalManagement(): UseModalManagementResult {
  const [isCategoryFormModalOpened, setCategoryFormModalOpened] =
    useState(false);
  const openCategoryFormModal = () => setCategoryFormModalOpened(true);
  const closeCategoryFormModal = () => setCategoryFormModalOpened(false);

  const [isInterventionDestroyModalOpened, setInterventionDestroyModalOpened] =
    useState(false);
  const openInterventionDestroyModal = () =>
    setInterventionDestroyModalOpened(true);
  const closeInterventionDestroyModal = () =>
    setInterventionDestroyModalOpened(false);

  const [isInterventionCloseModalOpened, setInterventionCloseModalOpened] =
    useState(false);
  const openInterventionCloseModal = () =>
    setInterventionCloseModalOpened(true);
  const closeInterventionCloseModal = () =>
    setInterventionCloseModalOpened(false);

  const closeAllModals = () => {
    closeCategoryFormModal();
    closeInterventionDestroyModal();
    closeInterventionCloseModal();
  };

  return {
    closeAllModals,
    closeCategoryFormModal,
    openCategoryFormModal,
    openInterventionDestroyModal,
    closeInterventionDestroyModal,
    isInterventionDestroyModalOpened,
    isCategoryFormModalOpened,
    isInterventionCloseModalOpened,
    openInterventionCloseModal,
    closeInterventionCloseModal,
  };
}
