import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { StringParam, useQueryParams } from 'use-query-params';
import _get from 'lodash/get';
import BreadCrumbs from '@lib/components/BreadCrumbs/BreadCrumbs';
import { SelectProps } from '@lib/components/Select/Select';
import useSelectedVersionOption from 'routes/Task/utils/useSelectedVersionOption';
import { TaskReportingView } from 'components/TaskReporting/TaskReportingView';
import { getBreadCrumbsDataByKey } from 'utils/breadcrumbs';
import useReporting from './utils/useReporting';

export default function TaskReportingViewContainer() {
  const { id } = useParams();
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const [versionParams, setVersionParams] = useQueryParams({
    versionId: StringParam,
    version: StringParam,
  });

  const {
    reportingError,
    reportingInfo,
    reportingLoading,
    taskData,
    taskError,
    taskLoading,
  } = useReporting({ taskId: id, versionParams });

  const selectedVersionOption = useSelectedVersionOption({
    taskData,
    versionParams,
  });

  const onVersionChange: SelectProps['onChange'] = (newValue) => {
    setVersionParams({
      version: _get(newValue, 'label', ''),
      versionId: _get(newValue, 'value', ''),
    });
  };

  return (
    <>
      <BreadCrumbs
        data={getBreadCrumbsDataByKey({ pathname, t, entity: taskData })}
      />
      <TaskReportingView
        id={id}
        onVersionChange={onVersionChange}
        reportingError={reportingError}
        reportingInfo={reportingInfo}
        reportingLoading={reportingLoading}
        selectedVersionOption={selectedVersionOption}
        taskData={taskData}
        taskError={taskError}
        taskLoading={taskLoading}
      />
    </>
  );
}
