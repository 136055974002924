import React from 'react';
import Chip, { ChipSizes } from '@lib/components/Chip/Chip';
import getDeadlineMissedReasonChipType from 'utils/getDeadlindMissedReasonChipType';
import _upperFirst from 'lodash/upperFirst';
import _toLower from 'lodash/toLower';
import _startCase from 'lodash/startCase';
import { TaskDeadlineMissedReasonEnum, Task } from 'graphql-common';
import ChipWithDropDown from '@lib/components/ChipWithDropDown/ChipWithDropDown';
import Menu, { NavItemTypeEnum } from '@lib/components/Menu/Menu';
import { useTranslation } from 'react-i18next';

type Props = {
  loading?: boolean;
  onDeadlineMissedReasonChangeHandler?: (
    id: string,
    v: TaskDeadlineMissedReasonEnum,
  ) => void;
  task: Task;
};

export default function TaskDeadlineMissedReasonChip(props: Props) {
  const { loading, onDeadlineMissedReasonChangeHandler, task } = props;
  const { id, deadlineMissedReason } = task;
  const { t } = useTranslation();
  const options = [
    {
      value: TaskDeadlineMissedReasonEnum.SiteClosed,
      title: t('site-closed'),
    },
    {
      value: TaskDeadlineMissedReasonEnum.UnderConstruction,
      title: t('under-construction'),
    },
    {
      value: TaskDeadlineMissedReasonEnum.Inaccessible,
      title: t('inaccessible'),
    },
    {
      value: TaskDeadlineMissedReasonEnum.StaffProblem,
      title: t('staff-problem'),
    },
    {
      value: TaskDeadlineMissedReasonEnum.Other,
      title: t('other'),
    },
  ];
  let label: string | undefined = t('unknown');
  if (deadlineMissedReason) {
    label = options.find(({ value }) => value === deadlineMissedReason)?.title;
  }
  if (label === undefined && deadlineMissedReason)
    label = _upperFirst(_toLower(_startCase(deadlineMissedReason)));
  const type = getDeadlineMissedReasonChipType(deadlineMissedReason);
  if (onDeadlineMissedReasonChangeHandler !== undefined) {
    const chipLeftIcon = (
      deadlineMissedReasonItem: TaskDeadlineMissedReasonEnum,
    ) => (
      <Chip
        leftIcon="circle"
        type={getDeadlineMissedReasonChipType(deadlineMissedReasonItem)}
        size={ChipSizes.smallLeftIconOnly}
      />
    );
    const getBody = () => (
      <Menu
        navItems={options
          .filter(({ value }) => value !== deadlineMissedReason)
          .map(({ value, title }) => ({
            id: value,
            title,
            onClick: () => onDeadlineMissedReasonChangeHandler(id, value),
            type: NavItemTypeEnum.Secondary,
            leftIcon: () => chipLeftIcon(value),
          }))}
      />
    );
    return (
      <div>
        <ChipWithDropDown
          label={label}
          type={type}
          leftIcon="circle"
          width={210}
          body={getBody}
          disabled={loading}
        />
      </div>
    );
  }
  return <Chip type={type} label={label} leftIcon="circle" />;
}
