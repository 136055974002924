import React from 'react';
import * as Yup from 'yup';
import { TFunction } from 'i18next';
import Input from '@lib/components/Input/Input';
import { InputTypes } from '@lib/components/Input/enums';
import { MAX_TEXT_FIELD_LENGTH } from '@lib/enums/form';
import RadioButtonGroup, {
  RadioButtonGroupOption,
  RadioButtonGroupProps,
  RadioButtonWrapViews,
} from '@lib/components/RadioButtonGroup/RadioButtonGroup';
import Typography from '@lib/components/Typography/Typography';
import Select from '@lib/components/Select/Select';
import { getOptionShape } from '@lib/utils/yup';
import {
  QrCodeFileFormatEnum,
  QrCodeFileLayoutEnum,
  QrCodePageSizeEnum,
} from 'graphql-common';
import { FieldNames, FormType } from 'components/GetQrCodeForm/types';
import { GetLoadOptions } from '@lib/components/Select/types';
import RadioButtonAsButton from '@lib/components/RadioButtonAsButton/RadioButtonAsButton';
import { FieldItem, Values } from '@lib/interfaces/form';
import _get from 'lodash/get';
import styles from './GetQrCodeForm.module.scss';

type FileFormatOptionsArgs = {
  selectedItemsCount?: number;
  t: TFunction<'translation', undefined>;
};

function getFileFormatOptionLabel(value: QrCodeFileFormatEnum) {
  switch (value) {
    case QrCodeFileFormatEnum.Pdf:
      return '.pdf';
    case QrCodeFileFormatEnum.Png:
      return '.png';
    case QrCodeFileFormatEnum.Svg:
      return '.svg';
    case QrCodeFileFormatEnum.Eps:
      return '.eps';
    default:
      return '';
  }
}

function getPageSizeOptionLabel(value: QrCodePageSizeEnum) {
  switch (value) {
    case QrCodePageSizeEnum.W148H210:
      return 'A5 (148 x 210mm)';
    case QrCodePageSizeEnum.W67H76:
      return '67 x 76mm';
    case QrCodePageSizeEnum.W60H80:
      return '60 x 80mm';
    case QrCodePageSizeEnum.W29H39:
      return '29 x 39mm';
    default:
      return '';
  }
}

function getPageSizeOptions(): RadioButtonGroupOption[] {
  return [
    {
      label: getPageSizeOptionLabel(QrCodePageSizeEnum.W148H210),
      value: QrCodePageSizeEnum.W148H210,
    },
    {
      label: getPageSizeOptionLabel(QrCodePageSizeEnum.W67H76),
      value: QrCodePageSizeEnum.W67H76,
    },
    {
      label: getPageSizeOptionLabel(QrCodePageSizeEnum.W60H80),
      value: QrCodePageSizeEnum.W60H80,
    },
    {
      label: getPageSizeOptionLabel(QrCodePageSizeEnum.W29H39),
      value: QrCodePageSizeEnum.W29H39,
    },
  ];
}

function getFileFormatOptions(
  args: FileFormatOptionsArgs,
): RadioButtonGroupOption[] {
  const { selectedItemsCount = 0, t } = args;
  const defaultOptions = [
    QrCodeFileFormatEnum.Pdf,
    QrCodeFileFormatEnum.Png,
    QrCodeFileFormatEnum.Svg,
    QrCodeFileFormatEnum.Eps,
  ].map((value) => ({
    label: getFileFormatOptionLabel(value),
    value,
  }));
  if (selectedItemsCount > 1) {
    return [
      {
        label: `${getFileFormatOptionLabel(QrCodeFileFormatEnum.Pdf)} (${t('as-single-pages')})`,
        value: `${QrCodeFileFormatEnum.Pdf}.${QrCodeFileLayoutEnum.SinglePage}`,
      },
      {
        label: `${getFileFormatOptionLabel(QrCodeFileFormatEnum.Pdf)} (${t('as-one-file')})`,
        value: `${QrCodeFileFormatEnum.Pdf}.${QrCodeFileLayoutEnum.SingleFile}`,
      },
      ...defaultOptions.filter(
        ({ value }) => value !== QrCodeFileFormatEnum.Pdf,
      ),
    ];
  }
  return defaultOptions;
}

type FormFieldsArgs = {
  selectedItemsCount?: number;
  getRecipientsLoadOptions: GetLoadOptions;
  t: TFunction<'translation', undefined>;
  formTypeOptions: RadioButtonGroupOption[];
};

export function getFormFields(args: FormFieldsArgs): FieldItem[] {
  const { selectedItemsCount, getRecipientsLoadOptions, formTypeOptions, t } =
    args;
  const fileFormatOptions = getFileFormatOptions({ selectedItemsCount, t });
  return [
    {
      name: FieldNames.formType,
      label: '',
      element: RadioButtonGroup,
      validation: Yup.object()
        .shape(getOptionShape(t, true))
        .required(t('required-field-error')),
      componentProps: {
        options: formTypeOptions,
        RadioButtonComponent: RadioButtonAsButton,
        wrapView: RadioButtonWrapViews.row,
        wrapClass: styles.formTypeSwitcher,
      },
    },
    {
      name: FieldNames.fileFormat,
      label: t('file-format'),
      element: RadioButtonGroup,
      validation: Yup.object()
        .shape(getOptionShape(t, true))
        .test('option-exists', t('required-field-error'), (value) =>
          fileFormatOptions.some((option) => option.value === value?.value),
        )
        .required(t('required-field-error')),
      componentProps: {
        options: fileFormatOptions,
        label: (
          <Typography
            variant="body"
            strong
            key="role-title"
            className={styles.titleColumn}
          >
            {t('file-format')}
          </Typography>
        ),
      } as RadioButtonGroupProps,
    },
    {
      name: FieldNames.pageSize,
      label: t('page-size'),
      element: Select,
      validation: Yup.object()
        .shape(getOptionShape(t, true))
        .required(t('required-field-error')),
      componentProps: {
        options: getPageSizeOptions(),
        usePortal: true,
      },
    },
    {
      name: FieldNames.userId,
      label: t('recipient-email'),
      element: Select,
      componentProps: {
        isAsync: true,
        getLoadOptions: getRecipientsLoadOptions,
        defaultOptions: true,
      },
      validation: Yup.object().when(FieldNames.formType, (deps, schema) =>
        _get(deps, [0, 'value']) === FormType.email
          ? schema
              .shape(getOptionShape(t, true))
              .required(t('required-field-error'))
          : schema.shape(getOptionShape(t)).nullable(),
      ),
      isOptionalFn: (values: Values) =>
        _get(values, [FieldNames.formType, 'value']) !== FormType.email,
      isVisibleFn: (values: Values, name?: string) => {
        if (name) {
          return (
            _get(values, [FieldNames.formType, 'value']) === FormType.email
          );
        }
        return true;
      },
    },
    {
      name: FieldNames.message,
      label: t('message'),
      element: Input,
      componentProps: {
        type: InputTypes.textarea,
      },
      validation: Yup.string()
        .trim()
        .max(MAX_TEXT_FIELD_LENGTH, t('max-text-length-field-error')),
      isVisibleFn: (values: Values, name?: string) => {
        if (name) {
          return (
            _get(values, [FieldNames.formType, 'value']) === FormType.email
          );
        }
        return true;
      },
    },
  ];
}
