import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TabBar from '@lib/components/TabBar/TabBar';
import { TabBarItem as TabBarItemType } from '@lib/components/TabBar/constants';
import getTabs from 'components/TaskMenuBar/utils/getTabs';
import useOutletContext, { ContextProps } from 'hooks/useOutletContext';
import { ComponentEnum, LevelEnum } from 'graphql-common';
import styles from './TaskMenuBar.module.scss';

interface Props {
  id?: string;
}

function TaskMenuBar({ id }: Props) {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { getUserAccessByComponent, getUserAccessByCompany }: ContextProps =
    useOutletContext();
  const isInterventionsLocked =
    getUserAccessByCompany(ComponentEnum.InterventionsManagement) ===
      LevelEnum.LimitedRead ||
    getUserAccessByComponent(ComponentEnum.InterventionsManagement) ===
      LevelEnum.LimitedRead;
  const tabs = getTabs({ isInterventionsLocked, t, id });
  const onActiveChange = ({ value }: TabBarItemType) => {
    navigate(value);
  };
  return (
    <div className={styles.menuRoot}>
      <TabBar
        items={tabs}
        active={pathname}
        onActiveChange={onActiveChange}
        useSidePaddings
      />
    </div>
  );
}

export default TaskMenuBar;
