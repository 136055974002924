import React from 'react';
import { TooltipPlacement } from 'antd/es/tooltip';
import Popover, {
  PopoverBody,
  PopoverStateProps,
  PopoverTrigger,
} from '@lib/components/Popover/Popover';
import Chip, { ChipProps } from '@lib/components/Chip/Chip';
import MaterialIcon from '@lib/components/MaterialIcon/MaterialIcon';
import styles from './ChipWithDropDown.module.scss';

const rightIconComponent = (isOpen: boolean) => (
  <MaterialIcon
    icon={isOpen ? 'arrow_drop_up' : 'arrow_drop_down'}
    className={styles.rightDropDownIcon}
  />
);

interface Props extends ChipProps {
  body: PopoverBody;
  disabled?: boolean;
  width?: number;
  trigger?: PopoverTrigger;
  placement?: TooltipPlacement;
}

export default function ChipWithDropDown(props: Props) {
  const {
    body,
    width = 200,
    trigger,
    placement = 'bottomLeft',
    ...rest
  } = props;
  const getBody = (bodyProps: PopoverStateProps) => (
    <div className={styles.body}>{body(bodyProps)}</div>
  );
  return (
    <div className={styles.root}>
      <Popover
        body={getBody}
        placement={placement}
        width={width}
        trigger={trigger}
      >
        {({ handleToggle, isOpen }) => (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events
          <div onClick={() => handleToggle()} role="button" tabIndex={0}>
            <Chip
              {...rest}
              asBlockElement
              clickable
              isActive={isOpen}
              rightIconComponent={rightIconComponent(isOpen)}
            />
          </div>
        )}
      </Popover>
    </div>
  );
}
