import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import _omit from 'lodash/omit';
import _get from 'lodash/get';
import {
  Site,
  SiteCreateInputObject,
  SiteUpdateInputObject,
  useCountriesQuery,
  User,
  useSiteCreateMutation,
  useSiteDestroyMutation,
  useSiteQuery,
  useSiteUpdateMutation,
  useUsersSearchListLazyQuery,
} from 'graphql-common';
import toast from '@lib/components/Toast/Toast';
import getFullName from '@lib/utils/getFullName';
import { Values } from '@lib/interfaces/form';
import { showGraphQLErrors } from '@lib/utils/graphQLErrors';
import { APP_URLS } from 'constants/urls';
import { getUsersLoadOptions } from 'utils/fetch/usersSearchList';
import { FormRefType } from '@lib/components/ReactHookForm/types';
import getSitePreparedDataFromValues from './getSitePreparedDataFromValues';

interface UseActionsProps {
  closeSiteDeleteModal: () => void;
  closeUserFormModal: () => void;
  formRef: FormRefType;
  id?: string | null;
}

export default function useSiteActions(props: UseActionsProps) {
  const { id, closeSiteDeleteModal, formRef, closeUserFormModal } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const siteQuery = useSiteQuery({
    skip: !id,
    variables: {
      findBy: { id: id || '' },
    },
  });

  const [fetchUsersSearchListLazyQuery] = useUsersSearchListLazyQuery({
    fetchPolicy: 'cache-and-network',
  });

  const usersLoadOptions = getUsersLoadOptions(fetchUsersSearchListLazyQuery);

  const countriesQuery = useCountriesQuery({
    fetchPolicy: 'cache-first',
  });
  const countries = _get(countriesQuery, 'data.data', []);
  const countriesCodes = countries.map(({ code }) => code.toLowerCase());

  const [createMutation, createMutationResult] = useSiteCreateMutation({
    onCompleted: (response) => {
      const { id: newId } = { ...response.data } as Site;
      if (newId) {
        navigate(
          APP_URLS.dashboard.sites.view.getDynamicPath({
            pathParts: {
              id: newId,
            },
          }),
        );
      } else {
        navigate(APP_URLS.dashboard.sites.index.getPathWithQuery());
      }
      toast({ content: t('create-site-success') });
    },
    onError: (error) => showGraphQLErrors(error, t),
  });

  const [updateMutation, updateMutationResult] = useSiteUpdateMutation({
    onCompleted: () => {
      toast({ content: t('update-site-success') });
    },
    onError: (error) => showGraphQLErrors(error, t),
  });

  const onCreateUserCallback = (newUser?: User | null) => {
    const newUserId = newUser?.id;
    const newUserFullName = getFullName(newUser);
    if (newUserId && formRef?.current?.setValue) {
      formRef.current.setValue(
        'userId',
        {
          value: newUserId,
          label: newUserFullName,
          data: newUser,
        },
        {
          shouldDirty: true,
          shouldValidate: true,
        },
      );
    }
    closeUserFormModal();
  };

  const [destroyMutation] = useSiteDestroyMutation({
    onCompleted: () => {
      closeSiteDeleteModal();
      navigate(APP_URLS.dashboard.sites.index.getPathWithQuery());
    },
    onError: (error) => showGraphQLErrors(error, t),
  });

  const siteData = _get(siteQuery, 'data.site') as Site;
  const siteLoading = _get(siteQuery, 'loading');
  const siteError = _get(siteQuery, 'error');

  const { error: createMutationError, loading: createSiteLoading } =
    createMutationResult;
  const { error: updateMutationError, loading: updateSiteLoading } =
    updateMutationResult;

  const onCreate = (values: Values) => {
    createMutation({
      variables: {
        attributes: getSitePreparedDataFromValues(
          values,
        ) as SiteCreateInputObject,
      },
    });
  };

  const onEdit = (values: Values) => {
    if (id) {
      updateMutation({
        variables: {
          id,
          attributes: _omit(
            getSitePreparedDataFromValues(values),
            'code',
          ) as SiteUpdateInputObject,
        },
      });
    }
  };

  const onDestroyHandler = () => {
    if (id) {
      destroyMutation({
        variables: { id },
      });
    }
  };

  const onConfirmedDelete = () => {
    closeSiteDeleteModal();
    toast({
      content: t('destroy-site-success'),
      closeCallback: onDestroyHandler,
    });
  };

  const onDestroyCodeOfConductHandler = () => {
    if (id) {
      updateMutation({
        variables: { id, attributes: { conductCodeSections: [] } },
      });
    }
  };

  return {
    countriesCodes,
    createMutationError,
    createSiteLoading,
    usersLoadOptions,
    onConfirmedDelete,
    onCreate,
    onCreateUserCallback,
    onDestroyCodeOfConductHandler,
    onEdit,
    siteData,
    siteError,
    siteLoading,
    updateMutationError,
    updateSiteLoading,
  };
}
