import { useState } from 'react';

export { Modals } from '@lib/enums/modals';

type ModalName = string;

// Data attached to a modal can be any structure you need:
export type ModalCustomData = Record<string, unknown>;

interface SingleModalState {
  isOpen: boolean;
  data?: ModalCustomData;
}

interface ModalState {
  [key: ModalName]: SingleModalState;
}

export interface UseModalManagementResult {
  /**
   * Check if a modal is open
   */
  isModalOpened: (name: ModalName) => boolean;

  /**
   * Get custom data for a modal
   */
  getModalData: (name: ModalName) => ModalCustomData | undefined;

  /**
   * Open a modal with optional data
   */
  openModal: (name: ModalName, data?: ModalCustomData) => void;

  /**
   * Close a modal (optionally clearing or retaining data)
   */
  closeModal: (name: ModalName) => void;

  /**
   * Close all modals (and optionally clear all data)
   */
  closeAllModals: () => void;
}

export default function useModalManagement(
  modalNames: ModalName[],
  closeCallback?: () => void,
): UseModalManagementResult {
  // Build the initial state for each modal: closed, with no data
  const initialState = modalNames.reduce<ModalState>((state, name) => {
    // eslint-disable-next-line no-param-reassign
    state[name] = { isOpen: false };
    return state;
  }, {});

  const [modalState, setModalState] = useState<ModalState>(initialState);

  const openModal = (name: ModalName, data?: ModalCustomData) => {
    setModalState((prevState) => ({
      ...prevState,
      [name]: {
        isOpen: true,
        data,
      },
    }));
  };

  const closeModal = (name: ModalName) => {
    // Decide whether to clear data or keep it. Here we keep the data.
    // If you prefer clearing data upon closing, set `data: undefined`.
    setModalState((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        isOpen: false,
        // data: undefined, // uncomment if you want to clear data on close
      },
    }));
  };

  const closeAllModals = () => {
    // Decide whether to clear data for all modals. Here we keep data.
    // If you want to clear data, remove the `data: prevState[name].data`.
    setModalState((prevState) =>
      Object.keys(prevState).reduce<ModalState>((acc, modalName) => {
        acc[modalName] = {
          ...prevState[modalName],
          isOpen: false,
          // data: undefined, // uncomment if you want to clear data on close
        };
        return acc;
      }, {}),
    );
    if (closeCallback) closeCallback();
  };

  const isModalOpened = (name: ModalName) => {
    return modalState[name]?.isOpen ?? false;
  };

  const getModalData = (name: ModalName) => {
    return modalState[name]?.data;
  };

  return {
    isModalOpened,
    getModalData,
    openModal,
    closeModal,
    closeAllModals,
  };
}
