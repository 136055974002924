import { TFunction } from 'i18next';
import { FormTemplate } from 'graphql-common';
import { NavItem, NavItemTypeEnum } from '@lib/components/Menu/Menu';
import { UseActionsResult } from 'routes/FormTemplates/utils/useActions';
import Icons from '@lib/components/Icons/Icons';

type ActionsMenuArgs = {
  onArchiveHandler: UseActionsResult['onArchiveHandler'];
  onDestroyHandler: UseActionsResult['onDestroyHandler'];
  onDuplicateHandler: UseActionsResult['onDuplicateHandler'];
  onEditHandler: UseActionsResult['onEditHandler'];
  onViewHandler: UseActionsResult['onViewHandler'];
  t: TFunction<'translation', undefined>;
  isViewOnly: boolean;
};

export default function getTableActionsMenu({
  onArchiveHandler,
  onDestroyHandler,
  onDuplicateHandler,
  onEditHandler,
  onViewHandler,
  isViewOnly,
  t,
}: ActionsMenuArgs) {
  const result: NavItem<FormTemplate>[] = [
    {
      id: 'view-action',
      title: t('view'),
      onClick: (item?: FormTemplate) => onViewHandler(item),
      leftIcon: Icons.view,
      type: NavItemTypeEnum.Secondary,
    },
  ];
  if (!isViewOnly) {
    result.push(
      {
        id: 'edit-action',
        title: t('edit'),
        onClick: (item?: FormTemplate) => onEditHandler(item),
        leftIcon: Icons.edit,
        type: NavItemTypeEnum.Secondary,
        predictorFnVisible: (item) => !!item?.canUpdate?.value,
      },
      {
        id: 'duplicate-action',
        title: t('duplicate'),
        onClick: (item?: FormTemplate) => onDuplicateHandler(item),
        leftIcon: Icons.copy,
        type: NavItemTypeEnum.Secondary,
      },
      {
        id: 'archive-action',
        title: t('archive'),
        onClick: (item?: FormTemplate) => onArchiveHandler(item),
        leftIcon: Icons.archive,
        type: NavItemTypeEnum.Secondary,
        predictorFnVisible: (item) => !!item?.canArchive?.value,
      },
      {
        id: 'delete-action',
        title: t('delete'),
        onClick: (item?: FormTemplate) => onDestroyHandler(item),
        leftIcon: Icons.delete,
        type: NavItemTypeEnum.Secondary,
        predictorFnVisible: (item) => !!item?.canDestroy?.value,
      },
    );
  }
  return result;
}
