import { TFunction } from 'i18next';
import _without from 'lodash/without';
import { NavItem, NavItemTypeEnum } from '@lib/components/Menu/Menu';
import Icons from '@lib/components/Icons/Icons';

type Args = {
  onAddHandler?: () => void;
  onArchiveHandler?: () => void;
  onDuplicateHandler?: () => void;
  onEditHandler?: () => void;
  openDestroyModal?: () => void;
  t: TFunction<'translation', undefined>;
};

export default function getVersionActions(args: Args) {
  const {
    onAddHandler,
    onArchiveHandler,
    onDuplicateHandler,
    onEditHandler,
    openDestroyModal,
    t,
  } = args;
  return _without(
    [
      onAddHandler
        ? {
            id: 'add',
            title: t('create-new'),
            leftIcon: Icons.add,
            onClick: onAddHandler,
            type: NavItemTypeEnum.Secondary,
          }
        : undefined,
      onEditHandler
        ? {
            id: 'edit',
            title: t('edit'),
            leftIcon: Icons.edit,
            onClick: onEditHandler,
            type: NavItemTypeEnum.Secondary,
          }
        : undefined,
      onDuplicateHandler
        ? {
            id: 'duplicate',
            title: t('edit-and-create-new'),
            leftIcon: Icons.copy,
            onClick: onDuplicateHandler,
            type: NavItemTypeEnum.Secondary,
          }
        : undefined,
      onArchiveHandler
        ? {
            id: 'archive',
            title: t('archive'),
            leftIcon: Icons.archive,
            onClick: onArchiveHandler,
            type: NavItemTypeEnum.Secondary,
          }
        : undefined,
      openDestroyModal
        ? {
            id: 'delete',
            title: t('delete'),
            leftIcon: Icons.delete,
            onClick: openDestroyModal,
            type: NavItemTypeEnum.Secondary,
          }
        : undefined,
    ],
    undefined,
  ) as NavItem<unknown>[];
}
