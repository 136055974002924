import React, { ComponentType, forwardRef, memo, useMemo } from 'react';
import ReactSelect, {
  ActionMeta,
  ContainerProps,
  ControlProps,
  DropdownIndicatorProps,
  GroupBase,
  IndicatorsContainerProps,
  LoadingIndicatorProps,
  MenuPlacement,
  OptionProps,
  Options,
  Props,
  ValueContainerProps,
} from 'react-select';
import Creatable from 'react-select/creatable';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { AsyncPaginate } from 'react-select-async-paginate';
import _get from 'lodash/get';
import _has from 'lodash/has';
import _noop from 'lodash/noop';
import _isFunction from 'lodash/isFunction';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import IndicatorsContainer from '@lib/components/Select/components/IndicatorsContainer';
import Input from '@lib/components/Select/components/Input';
import Menu from '@lib/components/Select/components/Menu';
import { InputSizes, TooltipShape } from '@lib/components/Input/enums';
import withFloatingLabel, {
  FloatingLabelProps,
} from '@lib/hocs/withFloatingLabel';
import SelectContainer from './components/SelectContainer';
import Control from './components/Control';
import DropdownIndicator from './components/DropdownIndicator';
import ClearIndicator from './components/ClearIndicator';
import MultiValueContainer from './components/MultiValueContainer';
import MultiValueRemove from './components/MultiValueRemove';
import ValueContainer from './components/ValueContainer';
import Option from './components/Option';
import SingleValueComponent from './components/SingleValue';
import { GetLoadOptions, LoadOptions, SelectOption } from './types';
import styles from './Select.module.scss';

export type { OnChangeValue, SingleValue } from 'react-select';

export type SelectComponents = {
  Option?:
    | ComponentType<OptionProps<unknown, boolean, GroupBase<unknown>>>
    | undefined;
  SelectContainer?:
    | ComponentType<ContainerProps<unknown, boolean, GroupBase<unknown>>>
    | undefined;
  ValueContainer?:
    | ComponentType<ValueContainerProps<unknown, boolean, GroupBase<unknown>>>
    | undefined;
  Control?:
    | ComponentType<ControlProps<unknown, boolean, GroupBase<unknown>>>
    | undefined;
  LoadingIndicator?:
    | ComponentType<LoadingIndicatorProps<unknown, boolean, GroupBase<unknown>>>
    | undefined;
  IndicatorsContainer?:
    | ComponentType<
        IndicatorsContainerProps<unknown, boolean, GroupBase<unknown>>
      >
    | undefined;
  DropdownIndicator?:
    | ComponentType<
        DropdownIndicatorProps<unknown, boolean, GroupBase<unknown>>
      >
    | undefined;
};

export interface CreateNewOptionProps {
  title: string;
  onCLick: () => void;
}

export interface SelectProps extends Props {
  additional?: { page: number };
  buttonText?: string;
  components?: SelectComponents;
  createNewOptionProps?: CreateNewOptionProps;
  defaultOptions?: boolean;
  disabled?: boolean;
  disabledTooltipProps?: TooltipShape;
  error?: string;
  fullWidthMenu?: boolean;
  getLoadOptions?: GetLoadOptions;
  hasBorder?: boolean;
  hasError?: boolean;
  infoTooltipProps?: TooltipShape;
  isAsync?: boolean;
  isClearable?: boolean;
  isCreatable?: boolean;
  loadOptions?: LoadOptions;
  menuListHasSpaces?: boolean;
  menuPlacement?: MenuPlacement | undefined;
  name?: string;
  options?: Options<SelectOption>;
  size?: InputSizes;
  usePortal?: boolean;
  values?: SelectOption[];
  controlHasLeftPadding?: boolean;
}

function getSelectValue(value: unknown, options: Options<SelectOption>) {
  let newValue;
  if (typeof value === 'string' && options.length) {
    newValue = options.find((option) => _get(option, 'value') === value) || {
      value,
    };
  }
  if (_has(value, 'value')) {
    newValue = options.find(
      (option) => _get(option, 'value') === _get(value, 'value'),
    );
  }
  return newValue || value;
}

function getOptionLabel(
  option: SelectOption,
  t: TFunction<'translation', undefined>,
) {
  if (option?.labelKey) return t(option.labelKey);
  const label = _get(option, 'label');
  if (_isFunction(label)) {
    // @ts-ignore
    return `${label()}`;
  }
  return `${label}`;
}

const Select = forwardRef(
  (
    props: SelectProps & FloatingLabelProps,
    ref: React.ForwardedRef<unknown | null>,
  ) => {
    const {
      components = {},
      createNewOptionProps,
      defaultOptions,
      disabled,
      disabledTooltipProps,
      getLoadOptions,
      hasBorder = true,
      hasError,
      isAsync,
      isClearable = true,
      isCreatable,
      isMulti,
      loadOptions,
      menuPlacement = 'auto',
      name = '',
      onChange = _noop,
      options = [],
      size = InputSizes.large,
      usePortal = false,
      menuPortalTarget,
      value,
      values,
      setIsFocused,
      setIsComponentFocused,
    } = props;
    const { t } = useTranslation();

    const preparedOptions = useMemo(
      () =>
        options.map((option) => ({
          ...option,
          label: getOptionLabel(option, t),
        })),
      [options, t],
    ) as Options<SelectOption>;

    const selectValue = useMemo(
      () => getSelectValue(value, preparedOptions),
      [value, preparedOptions],
    ) as SelectOption;

    let SelectComponent = ReactSelect;
    const preparedProps: SelectProps = {
      defaultOptions: undefined,
      loadOptions: undefined,
    };
    if (isCreatable) SelectComponent = Creatable;
    if (isAsync) {
      // @ts-ignore
      SelectComponent = AsyncPaginate;
      preparedProps.loadOptions = getLoadOptions
        ? getLoadOptions(name, values)
        : loadOptions;
      preparedProps.additional = { page: 1 };
    }
    if (isAsync && isCreatable) SelectComponent = AsyncCreatableSelect;
    if (isAsync && !disabled) preparedProps.defaultOptions = defaultOptions;

    const onChangeHandler = (
      newValue: unknown,
      actionMeta: ActionMeta<unknown>,
    ) => {
      onChange(newValue, actionMeta);
    };

    const selectComponents = {
      ClearIndicator,
      Control,
      DropdownIndicator,
      IndicatorsContainer,
      Input,
      Menu,
      MultiValueContainer,
      MultiValueRemove,
      Option,
      SelectContainer,
      SingleValue: SingleValueComponent,
      ValueContainer,
      ...components,
    };

    let selectMenuPortalTarget;
    if (usePortal) {
      selectMenuPortalTarget = document.body;
    }
    if (menuPortalTarget) {
      selectMenuPortalTarget = menuPortalTarget;
    }

    const selectStyles = { menuPortal: (base) => ({ ...base, zIndex: 9999 }) };

    return (
      <div className={styles.root}>
        <div className={styles.selectWrapper}>
          <SelectComponent
            unstyled
            menuPortalTarget={selectMenuPortalTarget}
            name={name}
            captureMenuScroll
            menuPlacement={menuPlacement}
            closeMenuOnSelect={!isMulti}
            hideSelectedOptions={false}
            {...props}
            {...preparedProps}
            // @ts-ignore TODO: fix it (https://react-select.com/typescript#custom-select-props)
            hasBorder={hasBorder}
            isDisabled={disabled}
            isClearable={isClearable}
            placeholder={false}
            // @ts-ignore TODO: fix it (https://react-select.com/typescript#custom-select-props)
            disabledTooltipProps={disabledTooltipProps}
            onChange={onChangeHandler}
            options={preparedOptions}
            value={selectValue}
            hasError={hasError}
            createNewOptionProps={createNewOptionProps}
            size={size}
            styles={selectStyles}
            components={selectComponents}
            onBlur={() => setIsFocused(false)}
            onMenuOpen={() => setIsComponentFocused(true)}
            onMenuClose={() => setIsComponentFocused(false)}
          />
        </div>
      </div>
    );
  },
);

Select.displayName = 'Select';

export default memo(withFloatingLabel(Select));
