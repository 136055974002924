import {
  ConditionEnum,
  FormTemplateQuestionFieldsKindEnum,
} from 'graphql-common';

export const defaultQuestionParams = {
  attachmentsAttached: [],
  description: '',
  name: '',
  required: false,
  showAttachments: false,
  showDescription: false,
};

export const defaultFieldParams = {
  required: false,
  options: [],
};

export const kindFieldsWithValidityHint = [
  FormTemplateQuestionFieldsKindEnum.Checkbox,
  FormTemplateQuestionFieldsKindEnum.RadioButton,
  FormTemplateQuestionFieldsKindEnum.SingleSelect,
  FormTemplateQuestionFieldsKindEnum.MinMax,
  FormTemplateQuestionFieldsKindEnum.YesNo,
  FormTemplateQuestionFieldsKindEnum.NumberInput,
];

export const kindFieldsWithoutPreviewInEditMode = [
  FormTemplateQuestionFieldsKindEnum.SingleSelect,
  FormTemplateQuestionFieldsKindEnum.RadioButton,
  FormTemplateQuestionFieldsKindEnum.Checkbox,
  FormTemplateQuestionFieldsKindEnum.YesNo,
  FormTemplateQuestionFieldsKindEnum.CheckboxButtonGrid,
  FormTemplateQuestionFieldsKindEnum.DecisionBox,
  FormTemplateQuestionFieldsKindEnum.ImportantNotice,
  FormTemplateQuestionFieldsKindEnum.ImportantNotice,
  FormTemplateQuestionFieldsKindEnum.Section,
];

export const kindFieldsWithAddOption = [
  FormTemplateQuestionFieldsKindEnum.Checkbox,
  FormTemplateQuestionFieldsKindEnum.MinMax,
  FormTemplateQuestionFieldsKindEnum.NumberInput,
  FormTemplateQuestionFieldsKindEnum.RadioButton,
  FormTemplateQuestionFieldsKindEnum.CheckboxButtonGrid,
  FormTemplateQuestionFieldsKindEnum.SingleSelect,
];

export const kindFieldsWithPreviewForEditMode = [
  FormTemplateQuestionFieldsKindEnum.MinMax,
  FormTemplateQuestionFieldsKindEnum.NumberInput,
];

export const kindFieldsWithNumericInputType = [
  FormTemplateQuestionFieldsKindEnum.MinMax,
  FormTemplateQuestionFieldsKindEnum.NumberInput,
];

export const kindFieldsWithCondition = [
  FormTemplateQuestionFieldsKindEnum.MinMax,
  FormTemplateQuestionFieldsKindEnum.NumberInput,
];

export const kindFieldsWithCheckboxPreview = [
  FormTemplateQuestionFieldsKindEnum.Checkbox,
  FormTemplateQuestionFieldsKindEnum.DecisionBox,
];

export const kindFieldsWithRadioButtonPreview = [
  FormTemplateQuestionFieldsKindEnum.RadioButton,
];

export const kindFieldsWithoutDeleteOption = [
  FormTemplateQuestionFieldsKindEnum.YesNo,
];

export const kindFieldsWithDeleteOption = [
  FormTemplateQuestionFieldsKindEnum.MinMax,
  FormTemplateQuestionFieldsKindEnum.NumberInput,
];

export const kindFieldsWithoutRequiredField = [
  FormTemplateQuestionFieldsKindEnum.Section,
  FormTemplateQuestionFieldsKindEnum.ImportantNotice,
];

export const kindFieldsWithAllowDisplayLastAnswer = [
  FormTemplateQuestionFieldsKindEnum.NumberInput,
  FormTemplateQuestionFieldsKindEnum.MinMax,
];

export const kindFieldsWithResponseLimitByGroup = [
  FormTemplateQuestionFieldsKindEnum.CheckboxButtonGrid,
];

export const fieldLabelByCondition = {
  [ConditionEnum.Between]: 'eg-label',
  [ConditionEnum.Equal]: 'eg-label',
  [ConditionEnum.GreaterThan]: 'if-more-than-label',
  [ConditionEnum.LessThan]: 'if-less-than-label',
  [ConditionEnum.NotEqual]: 'eg-label',
};

export const conditionNavItemLabelByCondition = {
  [ConditionEnum.Between]: 'condition-between',
  [ConditionEnum.Equal]: 'condition-equal',
  [ConditionEnum.GreaterThan]: 'condition-greater-than',
  [ConditionEnum.LessThan]: 'condition-less-than',
  [ConditionEnum.NotEqual]: 'condition-not-equal',
};

export const conditionIcons = {
  [ConditionEnum.Between]: '(...)',
  [ConditionEnum.Equal]: '=',
  [ConditionEnum.GreaterThan]: '>',
  [ConditionEnum.LessThan]: '<',
  [ConditionEnum.NotEqual]: '≠',
};

export const conditionNavigateTitleSuffix = {
  [ConditionEnum.Between]: '(...)',
  [ConditionEnum.Equal]: '=',
  [ConditionEnum.GreaterThan]: '>',
  [ConditionEnum.LessThan]: '<',
  [ConditionEnum.NotEqual]: '≠',
};
