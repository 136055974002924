import { TFunction } from 'i18next';
import { InterventionActionViewMode } from '../enums';

type Args = {
  t: TFunction<'translation', undefined>;
};

export default function getInterventionActionViewTabs({ t }: Args) {
  return [
    {
      label: t('details'),
      value: InterventionActionViewMode.Details,
    },
    {
      label: t('timeline'),
      value: InterventionActionViewMode.Timeline,
    },
    {
      label: t('history'),
      value: InterventionActionViewMode.History,
    },
  ];
}
