import { useEffect, useState } from 'react';
import {
  GetUrlParams,
  ORDERING_PARAMS,
  OrderingParams,
  PAGINATION_PARAMS,
  PaginationParams,
  SEARCH_PARAMS,
  SearchParams,
} from '@lib/enums/urls';
import useAssetsQueryHook from 'utils/fetch/useAssetsQueryHook';
import useAssetCountersQueryHook from 'utils/fetch/useAssetCountersQueryHook';
import { useLocalStorage } from 'usehooks-ts';
import {
  COUNTER_MONITORING_LAST_ASSET_ID,
  COUNTER_MONITORING_LAST_COUNTER_IDS,
} from '@lib/enums/localStorageKeys';
import usePrevious from '@lib/hooks/usePrevious';

export default function useSidebarActions() {
  const [defaultAssetId, setDefaultAssetId] = useLocalStorage(
    COUNTER_MONITORING_LAST_ASSET_ID,
    '',
  );
  const [defaultCounterIdsMap, setDefaultCounterIdsMap] = useLocalStorage(
    COUNTER_MONITORING_LAST_COUNTER_IDS,
    {},
  );

  // State
  const assetsSearchState = useState<SearchParams>(SEARCH_PARAMS);
  const assetsPaginationState = useState<PaginationParams>(PAGINATION_PARAMS);
  const assetsOrderingState = useState<OrderingParams>(ORDERING_PARAMS);
  const countersPaginationState = useState<PaginationParams>(PAGINATION_PARAMS);
  const countersOrderingState = useState<OrderingParams>(ORDERING_PARAMS);

  const [assetsSearchParams] = assetsSearchState;
  const assetsSearchQuery = assetsSearchParams[GetUrlParams.Search] || '';
  const prevAssetsSearchQuery = usePrevious(assetsSearchQuery);

  // Assets
  const useAssetsQueryHookResult = useAssetsQueryHook({
    paginationParams: assetsPaginationState[0],
    orderingParams: assetsOrderingState[0],
    searchQuery: assetsSearchQuery,
    withCountersOnly: true,
  });

  // Counters
  const useCountersQueryHookResult = useAssetCountersQueryHook({
    paginationParams: countersPaginationState[0],
    orderingParams: countersOrderingState[0],
    assetId: defaultAssetId,
    skip: !defaultAssetId,
    usePrevious: false,
    onError: () => {
      useAssetsQueryHookResult.refetch();
    },
  });

  const { collection: assets } = useAssetsQueryHookResult;
  const { collection: counters } = useCountersQueryHookResult;

  const defaultCounterId =
    defaultCounterIdsMap && defaultAssetId
      ? defaultCounterIdsMap[defaultAssetId]
      : undefined;

  useEffect(() => {
    if (
      !defaultCounterId &&
      !defaultCounterIdsMap?.[defaultAssetId] &&
      counters.length
    ) {
      setDefaultCounterIdsMap({
        ...defaultCounterIdsMap,
        [defaultAssetId]: counters[0].id,
      });
    }
  }, [
    counters,
    defaultAssetId,
    defaultCounterId,
    defaultCounterIdsMap,
    setDefaultCounterIdsMap,
  ]);

  useEffect(() => {
    if (!defaultAssetId && assets.length) {
      setDefaultAssetId(assets[0].id);
    }
  }, [defaultAssetId, assets, setDefaultAssetId]);

  useEffect(() => {
    const [assetsPaginationParams, setAssetsPaginationParams] =
      assetsPaginationState;
    if (
      assetsSearchQuery !== prevAssetsSearchQuery &&
      assetsPaginationParams.page !== 1
    ) {
      setAssetsPaginationParams({ ...PAGINATION_PARAMS });
    }
  }, [assetsSearchQuery, prevAssetsSearchQuery, assetsPaginationState]);

  return {
    defaultAssetId,
    assetsOrderingState,
    assetsPaginationState,
    assetsSearchState,
    countersOrderingState,
    countersPaginationState,
    useAssetsQueryHookResult,
    useCountersQueryHookResult,
  };
}
