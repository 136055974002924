import React from 'react';
import { TaskTimelineGroupByEnum, Timeline, TimelineRow } from 'graphql-common';
import { TFunction } from 'i18next';
import _get from 'lodash/get';
import _filter from 'lodash/filter';
import { v4 as uuidv4 } from 'uuid';
import { getDaysInMonth } from 'date-fns';
import {
  BaseItem,
  CellContentAlign,
  CellProps,
  Column,
} from '@lib/components/Table/types';
import StatusBox from '@lib/components/StatusBox/StatusBox';
import ValidityHint from '@lib/enums/validityHint';
import styles from './columns.module.scss';

const getColumnsData = (
  view: TaskTimelineGroupByEnum,
  t: TFunction,
  endDate: Date,
) => {
  const columnsData: {
    name: string;
    subName?: string;
    id: string;
    isWeekend?: boolean;
  }[] = [];

  const totalColumns = {
    [TaskTimelineGroupByEnum.Year]: 12,
    [TaskTimelineGroupByEnum.Month]: getDaysInMonth(endDate),
    [TaskTimelineGroupByEnum.Week]: 7,
  }[view];

  const currentDate = new Date(endDate);
  if (view === TaskTimelineGroupByEnum.Month) {
    currentDate.setDate(1);
  } else if (view === TaskTimelineGroupByEnum.Week) {
    const currentDayOfWeek = currentDate.getDay();
    const diffToMonday = (currentDayOfWeek + 6) % 7; // Difference to Monday
    currentDate.setDate(currentDate.getDate() - diffToMonday);
  } else {
    currentDate.setDate(currentDate.getDate() - totalColumns + 1);
  }

  for (let i = 0; i < totalColumns; i += 1) {
    if (view === TaskTimelineGroupByEnum.Year) {
      const monthDate = new Date(currentDate.getFullYear(), i, 1);
      const monthName = t(`month-${monthDate.getMonth() + 1}`);
      columnsData.push({
        name: `${monthName}`,
        id: `${monthDate.getFullYear()}-${String(monthDate.getMonth() + 1).padStart(2, '0')}`,
      });
    } else {
      const weekDay = currentDate.getDay();
      const dayName = t(`weekday-${weekDay}`);
      const monthName = t(`month-${currentDate.getMonth() + 1}`);
      columnsData.push({
        isWeekend: weekDay === 0 || weekDay === 6,
        subName: dayName,
        name: `${monthName} ${currentDate.getDate()}`,
        id: `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-${String(currentDate.getDate()).padStart(2, '0')}`,
      });
      currentDate.setDate(currentDate.getDate() + 1);
    }
  }

  return columnsData;
};

export const getAdditionalCell =
  (id: string) => (props: CellProps<Timeline & BaseItem>) => {
    const rows = _get(props, 'item.timeline.rows', []) as TimelineRow[];
    const filteredRows: TimelineRow[] = _filter(rows, (row) => {
      return row.date === id;
    });

    if (filteredRows.length) {
      return filteredRows.map(({ validityHintCounters }) => (
        <div className={styles.rowStatusBoxes} key={uuidv4()}>
          {validityHintCounters.none ? (
            <StatusBox
              text={validityHintCounters.none}
              type={ValidityHint.None}
            />
          ) : null}
          {validityHintCounters.failure ? (
            <StatusBox
              text={validityHintCounters.failure}
              type={ValidityHint.Failure}
            />
          ) : null}
          {validityHintCounters.warning ? (
            <StatusBox
              text={validityHintCounters.warning}
              type={ValidityHint.Warning}
            />
          ) : null}
          {validityHintCounters.success ? (
            <StatusBox
              text={validityHintCounters.success}
              type={ValidityHint.Success}
            />
          ) : null}
        </div>
      ));
    }
    return null;
  };

type AdditionalColumnsArgs = {
  endDateParam: string | null | undefined;
  t: TFunction<'translation', undefined>;
  view: TaskTimelineGroupByEnum;
};

export const getAdditionalColumns = ({
  endDateParam,
  t,
  view,
}: AdditionalColumnsArgs) => {
  const nowDate = endDateParam ? new Date(endDateParam) : new Date();
  const columns: Column<Timeline & BaseItem>[] = [];
  const columnsData = getColumnsData(view, t, nowDate);
  // eslint-disable-next-line no-restricted-syntax
  for (const { name, subName, id, isWeekend } of columnsData) {
    const isCustomHeader = !!subName;
    const column = {
      id: `column-${id}`,
      Header: isCustomHeader ? (
        <div className={styles.dayCellHead}>
          <div>{subName}</div>
          <div>{name}</div>
        </div>
      ) : (
        name
      ),
      isCustomHeader,
      cellClassName: isWeekend ? styles.weekendCell : undefined,
      contentAlign: CellContentAlign.Center,
      Cell: getAdditionalCell(id),
      minWidth: 124,
      bordered: true,
    };
    columns.push(column);
  }
  return columns;
};
