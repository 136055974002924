import React from 'react';
import { TFunction } from 'i18next';
import * as Yup from 'yup';
import { FieldItem, Values } from '@lib/interfaces/form';
import Input from '@lib/components/Input/Input';
import TagsInput, { TagsInputProps } from '@lib/components/TagsInput/TagsInput';
import AssetDocumentFieldNames from '@lib/enums/fieldNames/assetDocumentFieldNames';
import { MAX_TEXT_FIELD_LENGTH } from '@lib/enums/form';
import AssetDocumentationFieldArrayItemWrapper from 'components/AssetDocumentation/AssetDocumentationFieldArrayItemWrapper/AssetDocumentationFieldArrayItemWrapper';
import AddDocumentationAction from 'components/AssetDocumentation/AddDocumentationAction/AddDocumentationAction';
import { LoadOptions } from '@lib/components/Select/types';
import { OnCreateTagFn } from '@lib/components/TagsInput/types';
import DocumentationPreviewWrap from 'components/AssetDocumentation/DocumentationPreviewWrap/DocumentationPreviewWrap';
import { getOptionShape } from '@lib/utils/yup';
import { Documentation } from 'graphql-common';

type Args = {
  prevValues: Values;
  onFilePreview: (file: Documentation) => void;
  isCreatePage: boolean;
  t: TFunction<'translation', undefined>;
  tagsLoadOptions: LoadOptions;
  onCreateTag: OnCreateTagFn;
};

function getDocumentFields({
  onCreateTag,
  prevValues,
  t,
  tagsLoadOptions,
  isCreatePage,
  onFilePreview,
}: Args): FieldItem[] {
  return [
    {
      name: AssetDocumentFieldNames.Document,
      customRender: (values: Values, name: string) => {
        return (
          <DocumentationPreviewWrap
            values={values as Documentation}
            name={name}
            prevValues={prevValues as Documentation}
            onPreviewAction={isCreatePage ? undefined : onFilePreview}
            isCreatePage={isCreatePage}
          />
        );
      },
      isUseWatch: true,
    },
    {
      name: AssetDocumentFieldNames.Name,
      label: t('document-name'),
      element: Input,
      validation: Yup.string()
        .trim()
        .max(MAX_TEXT_FIELD_LENGTH, t('max-text-length-field-error'))
        .required(t('required-field-error')),
    },
    {
      name: AssetDocumentFieldNames.Tags,
      label: t('tags'),
      element: TagsInput,
      componentProps: {
        tagsLoadOptions,
        onCreateTag,
        hasErrorAsTooltip: true,
      } as TagsInputProps,
      validation: Yup.array()
        .of(Yup.object().shape(getOptionShape(t)))
        .nullable(),
      hideErrors: true,
    },
  ];
}

export default function getFormFields(args: Args): FieldItem[] {
  const { isCreatePage, t } = args;
  if (isCreatePage) {
    return [
      {
        name: AssetDocumentFieldNames.Documents,
        subFieldTitle: t('document'),
        subFieldsEntity: 'document',
        subFieldsMinLength: 0,
        fieldComponents: {
          FieldArrayAddItemComponent: AddDocumentationAction,
          FieldArrayEmptyStateComponent: AddDocumentationAction,
          FieldArrayItemWrapperComponent:
            AssetDocumentationFieldArrayItemWrapper,
        },
        subFields: getDocumentFields(args),
      },
    ];
  }
  return getDocumentFields(args);
}
