import React, { memo } from 'react';
import classNames from 'classnames';
import _get from 'lodash/get';
import Button, {
  ButtonProps,
  ButtonTypes,
} from '@lib/components/Button/Button';
import { RadioButtonProps } from '@lib/components/RadioButton/RadioButton';
import { materialSymbolsOutlined } from '@lib/enums/common';
import { useTranslation } from 'react-i18next';
import styles from './RadioButtonAsButton.module.scss';

function RadioButtonAsButton(props: RadioButtonProps & ButtonProps) {
  const {
    label = '',
    labelTransKey = '',
    checked,
    onChange,
    disabled,
    leftIcon,
    fullWidth,
    buttonSize,
    leftIconClassName,
  } = props;
  const isNegative = _get(props, 'data.isNegative', false);
  const { t } = useTranslation();
  const onClick = (e: unknown) => {
    const v = e as React.ChangeEvent<HTMLInputElement>;
    onChange(v);
  };
  let buttonType = ButtonTypes.secondaryOutlined;
  if (checked) {
    buttonType = isNegative
      ? ButtonTypes.negativeFilled
      : ButtonTypes.primaryFilled;
  }
  return (
    <Button
      buttonText={labelTransKey ? t(labelTransKey) : label}
      buttonSize={buttonSize}
      buttonType={buttonType}
      onClick={disabled ? undefined : onClick}
      className={styles.chip}
      disabled={checked ? undefined : disabled}
      leftIcon={leftIcon}
      leftIconClassName={classNames(
        materialSymbolsOutlined,
        !checked && leftIconClassName,
      )}
      fullWidth={fullWidth}
      useRipple={!disabled}
    />
  );
}

export default memo(RadioButtonAsButton);
