import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import _get from 'lodash/get';
import { Values } from '@lib/interfaces/form';
import TabBar from '@lib/components/TabBar/TabBar';
import getTabs from './utils/getTabs';
import styles from './FormTemplateBuilderView.module.scss';

interface Props {
  formTemplateData: Values;
}

export default function FormTemplateBuilderViewMenu(props: Props) {
  const { formTemplateData } = props;
  const { id, versionId } = useParams();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const tabs = getTabs({
    id,
    versionId,
    visibleLinkedTasksCount: _get(
      formTemplateData,
      'visibleLinkedTasksCount',
      0,
    ) as number,
    t,
  });

  return (
    <div className={styles.menuRoot}>
      <TabBar
        items={tabs}
        active={pathname}
        onActiveChange={(item) => navigate(item.value)}
        useSidePaddings
      />
    </div>
  );
}
