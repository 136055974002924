import React from 'react';
import {
  components as DefaultSelectComponents,
  ValueContainerProps,
} from 'react-select';
import _get from 'lodash/get';
import _has from 'lodash/has';
import styles from '../Select.module.scss';

export default function ChipValueContainerForChipControl(
  props: ValueContainerProps,
): JSX.Element {
  const { children, ...valueContainerProps } = props;
  const { selectProps } = valueContainerProps;
  const value = _get(selectProps, 'value');

  if (Array.isArray(children) && _has(value, 'label')) {
    return (
      <DefaultSelectComponents.ValueContainer
        {...valueContainerProps}
        className={styles.valueContainer}
      >
        <span>
          {_get(value, 'label')}
          <span>{children[1]}</span>
        </span>
      </DefaultSelectComponents.ValueContainer>
    );
  }

  return (
    <DefaultSelectComponents.ValueContainer
      {...valueContainerProps}
      className={styles.valueContainer}
    >
      {children}
    </DefaultSelectComponents.ValueContainer>
  );
}
