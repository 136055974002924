import { Counter } from 'graphql-common';
import assetCounterFieldNames from '@lib/enums/fieldNames/assetCounterFieldNames';
import { TFunction } from 'i18next';
import {
  getMeasureTypeOptions,
  getMeasureUnitOptionsByType,
} from '@lib/utils/measure';

export default function getDefaultCounterFormValuesFromQueryData(
  data: Counter,
  t: TFunction<'translation', undefined>,
) {
  const measureType = data?.measureType;
  const measureUnit = data?.measureUnit;
  const typeLabelKey = getMeasureTypeOptions().find(
    ({ value }) => value === measureType,
  )?.labelKey;
  const unitLabelKey = getMeasureUnitOptionsByType(measureType).find(
    ({ value }) => value === measureUnit,
  )?.labelKey;
  return {
    [assetCounterFieldNames.Name]: data?.name,
    [assetCounterFieldNames.Code]: data?.code,
    [assetCounterFieldNames.MeasureType]:
      typeLabelKey && measureType
        ? {
            label: t(typeLabelKey),
            value: measureType,
          }
        : undefined,
    [assetCounterFieldNames.MeasureUnit]:
      unitLabelKey && measureUnit
        ? {
            label: t(unitLabelKey),
            value: measureUnit,
          }
        : undefined,
    [assetCounterFieldNames.ProhibitLowerIndexThanPrevious]:
      data?.prohibitLowerIndexThanPrevious,
  };
}
