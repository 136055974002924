import React from 'react';
import classNames from 'classnames';
import {
  components as DefaultSelectComponents,
  OptionProps,
} from 'react-select';
import _get from 'lodash/get';
import MaterialIcon from '@lib/components/MaterialIcon/MaterialIcon';
import Chip, { ChipTypes } from '@lib/components/Chip/Chip';
import ValidityHint from '@lib/enums/validityHint';
import styles from '../Select.module.scss';

export function getValidityHintIcon(value?: string | null) {
  if (!value) return null;
  if (value === ValidityHint.Failure) {
    return (
      <MaterialIcon
        icon="error"
        className={styles.validityHintOptionIconError}
        block
      />
    );
  }
  if (value === ValidityHint.Warning) {
    return (
      <MaterialIcon
        icon="warning"
        className={styles.validityHintOptionIconWarning}
        block
      />
    );
  }
  return (
    <MaterialIcon
      icon="check_circle"
      className={styles.validityHintOptionIconCheck}
      block
    />
  );
}
export function getValidityHintChipIcon(value?: string | null) {
  if (!value) return null;
  if (value === ValidityHint.Failure) {
    return <Chip leftIcon="error" type={ChipTypes.chipRed} />;
  }
  if (value === ValidityHint.Warning) {
    return <Chip leftIcon="warning" type={ChipTypes.chipOrange} />;
  }
  return <Chip leftIcon="check_circle" type={ChipTypes.chipGreen} />;
}

function ValidityHintOption({
  children,
  ...restProps
}: OptionProps): JSX.Element {
  const { isSelected, isMulti } = restProps;
  const value = _get(restProps, 'value', '') as string;
  return (
    <DefaultSelectComponents.Option
      {...restProps}
      className={classNames(styles.validityHintOption, {
        active: isSelected,
        [styles.optionWithCheckbox]: isMulti,
      })}
    >
      {getValidityHintIcon(value)}
      {children}
    </DefaultSelectComponents.Option>
  );
}

export default ValidityHintOption;
