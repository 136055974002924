enum InterventionFieldNames {
  Asset = 'asset',
  Assignee = 'assignee',
  AttachmentsAttached = 'attachmentsAttached',
  Category = 'category',
  Code = 'code',
  Description = 'description',
  DurationTime = 'durationTime',
  EstimatedDate = 'estimatedDate',
  EstimatedTime = 'estimatedTime',
  Id = 'id',
  LogisticTime = 'logisticTime',
  Name = 'name',
  Priority = 'priority',
  Site = 'site',
  SiteArea = 'siteArea',
  Status = 'status',
  Supervisor = 'supervisor',
  Task = 'task',
}

export default InterventionFieldNames;
