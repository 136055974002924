import React from 'react';
import Typography from '@lib/components/Typography/Typography';
import classNames from 'classnames';
import ValidityHint from '@lib/enums/validityHint';
import styles from './StatusBox.module.scss';

type Props = {
  type: ValidityHint;
  text: string | number;
};

export default function StatusBox(props: Props) {
  const { type, text } = props;
  return (
    <div
      className={classNames(styles.rootStatusBox, {
        [styles.critical]: type === ValidityHint.Failure,
        [styles.optimal]: type === ValidityHint.Success,
        [styles.warning]: type === ValidityHint.Warning,
        [styles.none]: type === ValidityHint.None,
      })}
    >
      <Typography variant="caption">{text}</Typography>
    </div>
  );
}
