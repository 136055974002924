import React from 'react';
import { useTranslation } from 'react-i18next';
import { User } from 'graphql-common';
import CircleLoader from '@lib/components/CircleLoader/CircleLoader';
import ErrorPage from '@lib/components/ErrorPage/ErrorPage';
import getStatusFromError from '@lib/utils/getStatusFromError';
import getDefaultValuesFromQueryData from 'routes/Users/utils/getDefaultValuesFromQueryData';
import useActions from './utils/useActions';
import UserForm from './UserForm';

interface Props {
  isOpen: boolean;
  isSiteFieldRequired?: boolean;
  onCancel: () => void;
  onSubmitCallback: (v?: User | null) => void;
  selectedUserId?: string | null;
}

function UserFormContainer(props: Props) {
  const {
    isOpen,
    isSiteFieldRequired,
    onCancel,
    onSubmitCallback,
    selectedUserId,
  } = props;
  const { t } = useTranslation();

  const {
    countriesCodes,
    countriesError,
    graphQLErrors,
    loading,
    onSubmitUserForm,
    selectedUser,
    sitesLoadOptions,
    userError,
    userLoading,
    teamsLoadOptions,
  } = useActions({
    selectedUserId,
    isOpen,
    onSubmitCallback,
  });

  if (userLoading) {
    return <CircleLoader />;
  }

  if (userError) {
    return (
      <ErrorPage
        status={getStatusFromError(userError)}
        description={userError?.message}
      />
    );
  }

  if (countriesError) {
    return (
      <ErrorPage
        status={getStatusFromError(countriesError)}
        description={countriesError?.message}
      />
    );
  }

  const defaultValues = selectedUser
    ? getDefaultValuesFromQueryData(t, {
        ...selectedUser,
      })
    : undefined;

  return (
    <UserForm
      countriesCodes={countriesCodes}
      defaultValues={defaultValues}
      graphQLErrors={graphQLErrors}
      isSiteFieldRequired={isSiteFieldRequired}
      loading={loading}
      onCancel={onCancel}
      onSubmit={onSubmitUserForm}
      sitesLoadOptions={sitesLoadOptions}
      teamsLoadOptions={teamsLoadOptions}
    />
  );
}

export default UserFormContainer;
