import React from 'react';
import _get from 'lodash/get';
import { TFunction } from 'i18next';
import { PolicyEnum, User } from 'graphql-common';
import UserPreviewCell from '@lib/components/Table/commonComponents/UserPreviewCell/UserPreviewCell';
import TextCell from '@lib/components/Table/commonComponents/TextCell/TextCell';
import UserSetupCell from '@lib/components/Table/commonComponents/UserSetupCell/UserSetupCell';
import { CellProps } from '@lib/components/Table/types';
import { getRoleOption } from '@lib/utils/roles';
import Chip, { ChipTypes } from '@lib/components/Chip/Chip';

type ColumnsArgs = {
  currentUserId?: string;
  isViewOnly: boolean;
  onResendEmailConfirmationHandler: (id: string) => void;
  onResendRegistrationConfirmationHandler: (id: string) => void;
  t: TFunction<'translation', undefined>;
};

export default function getTeamMembersTableColumns({
  currentUserId,
  isViewOnly,
  onResendEmailConfirmationHandler,
  onResendRegistrationConfirmationHandler,
  t,
}: ColumnsArgs) {
  return [
    {
      id: 'personalId',
      Header: 'ID',
      accessor: 'personalId',
      Cell: (props: CellProps<User>) => <TextCell {...props} />,
      minWidth: 80,
      width: 80,
      sortable: true,
    },
    {
      id: 'fullName',
      Header: t('name'),
      Cell: ({ item }: CellProps<User>) => (
        <UserPreviewCell
          avatar={
            item.avatarAttached?.urls?.small ||
            item.avatarAttached?.urls?.original
          }
          name={`${item.firstName} ${item.lastName}`}
          email={item.email}
        />
      ),
      minWidth: 308,
      width: 308,
      sortable: true,
    },
    {
      id: 'role',
      Header: t('type'),
      accessorFn: ({ item }: CellProps<User>) => {
        const role = getRoleOption(item.role)?.labelKey;
        return role ? t(role) : '-';
      },
      Cell: (props: CellProps<User>) => <TextCell {...props} />,
      maxWidth: 150,
      minWidth: 150,
      width: 150,
      sortable: true,
    },
    {
      id: 'accesses',
      Header: t('access-rights'),
      accessorFn: ({ item }: CellProps<User>) => {
        const { finalAccesses } = item;
        if (finalAccesses) {
          const isCustom = finalAccesses.some(
            ({ policy }) => policy === PolicyEnum.User,
          );
          return (
            <div>
              <Chip
                type={isCustom ? ChipTypes.chipYellow : ChipTypes.chipBlue}
                label={t(isCustom ? 'custom' : 'team')}
              />
            </div>
          );
        }
        return '-';
      },
      Cell: (props: CellProps<User>) => <TextCell {...props} />,
      maxWidth: 150,
      minWidth: 150,
      width: 150,
    },
    {
      id: 'setup',
      Header: 'Setup',
      Cell: (props: CellProps<User>) => (
        <UserSetupCell
          {...props}
          isViewOnly={isViewOnly}
          currentUserId={currentUserId}
          onResendEmailConfirmationHandler={() =>
            onResendEmailConfirmationHandler(_get(props, 'item.id'))
          }
          onResendRegistrationConfirmationHandler={() =>
            onResendRegistrationConfirmationHandler(_get(props, 'item.id'))
          }
        />
      ),
      hideHeader: true,
      minWidth: 204,
      width: 204,
      prohibitDisabled: true,
    },
  ];
}
