import { useTranslation } from 'react-i18next';
import {
  useAuthenticationConfirmationsResendEmailConfirmationMutation,
  useAuthenticationConfirmationsResendRegistrationConfirmationMutation,
  useTeamUpdateMutation,
  useUserBulkActivateMutation,
  useUserBulkDeactivateMutation,
  useUserDestroyMutation,
  useUserUpdateMutation,
} from 'graphql-common';
import { showGraphQLErrors } from '@lib/utils/graphQLErrors';
import toast from '@lib/components/Toast/Toast';

export default function useUsersMutations() {
  const { t } = useTranslation();

  const [resendRegistrationConfirmationMutation] =
    useAuthenticationConfirmationsResendRegistrationConfirmationMutation({
      onCompleted: (response) => {
        const content =
          response?.data?.message || t('resend-confirmation-success');
        toast({ content });
      },
      onError: (error) => {
        toast({ content: error.message || 'Oops! Something went wrong' });
      },
    });

  const [resendEmailConfirmationMutation] =
    useAuthenticationConfirmationsResendEmailConfirmationMutation({
      onCompleted: (response) => {
        const content =
          response?.data?.message || t('resend-confirmation-success');
        toast({ content });
      },
      onError: (error) => {
        toast({ content: error.message || 'Oops! Something went wrong' });
      },
    });

  const [destroyMutation] = useUserDestroyMutation({
    onError: (error) => {
      toast({ content: error.message || 'Oops! Something went wrong' });
    },
  });

  const [bulkActivateMutation] = useUserBulkActivateMutation({
    onError: (error) => {
      toast({ content: error.message || 'Oops! Something went wrong' });
    },
  });

  const [bulkDeactivateMutation] = useUserBulkDeactivateMutation({
    onError: (error) => {
      toast({ content: error.message || 'Oops! Something went wrong' });
    },
  });

  const [teamUpdateMutation, teamUpdateMutationResult] = useTeamUpdateMutation({
    onError: (error) => showGraphQLErrors(error, t),
  });

  const [userUpdateMutation, userUpdateMutationResult] = useUserUpdateMutation({
    onError: (error) => {
      toast({ content: error.message || 'Oops! Something went wrong' });
    },
  });

  return {
    bulkActivateMutation,
    bulkDeactivateMutation,
    destroyMutation,
    resendEmailConfirmationMutation,
    resendRegistrationConfirmationMutation,
    teamUpdateMutation,
    teamUpdateMutationResult,
    userUpdateMutation,
    userUpdateMutationResult,
  };
}
