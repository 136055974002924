import React from 'react';
import { useTranslation } from 'react-i18next';
import IconButton, {
  IconButtonTypes,
} from '@lib/components/IconButton/IconButton';
import Select from '@lib/components/Select/Select';
import { InputSizes } from '@lib/components/Input/enums';
import { TaskTimelineGroupByEnum } from 'graphql-common';
import {
  getFormattedDate,
  revertTransformDate,
  transformDate,
} from '@lib/utils/dateTimeHelpers';
import {
  dateFormatForApi,
  datePickerShortDateFormat,
  datePickerYearFormat,
} from '@lib/enums/dateTime';
import DatePicker from '@lib/components/DateTimePicker/DatePicker';
import CheckBoxSingleBool from '@lib/components/CheckBoxSingleBool/CheckBoxSingleBool';
import {
  getDecrementedNextEndDate,
  getIncrementedNextEndDate,
  getIsDecrementDisabled,
  getIsIncrementDisabled,
  getMaxDate,
  getMinDate,
  getPeriodEndDate,
  getPeriodStartDate,
} from './utils/getPeriodDate';
import styles from './TableTopHeadComponent.module.scss';

type Props = {
  endDate?: string | null;
  isFullTableView: boolean;
  onToggleFullTableView: () => void;
  onEndDateChange: (v: string) => void;
  onViewChange: (v: unknown) => void;
  periodEndParam?: string | null;
  periodStartParam?: string | null;
  view: TaskTimelineGroupByEnum;
};

export default function TableTopHeadComponent(props: Props) {
  const {
    endDate,
    isFullTableView,
    onEndDateChange,
    onToggleFullTableView,
    onViewChange,
    periodEndParam,
    periodStartParam,
    view,
  } = props;
  const { t } = useTranslation();
  const options = [
    {
      value: TaskTimelineGroupByEnum.Year,
      label: t('year'),
    },
    {
      value: TaskTimelineGroupByEnum.Month,
      label: t('month'),
    },
    {
      value: TaskTimelineGroupByEnum.Week,
      label: t('week'),
    },
  ];
  const selectedOption = options.find(({ value }) => {
    return value === view;
  });

  const datePickerType = (() => {
    if (view === TaskTimelineGroupByEnum.Year) {
      return 'year';
    }
    if (view === TaskTimelineGroupByEnum.Month) {
      return 'month';
    }
    if (view === TaskTimelineGroupByEnum.Week) {
      return 'week';
    }
    return undefined;
  })();

  const datePickerFormat =
    view === TaskTimelineGroupByEnum.Year
      ? datePickerYearFormat
      : datePickerShortDateFormat;

  const isDecrementDisabled = getIsDecrementDisabled(
    endDate,
    periodStartParam,
    view,
  );

  const isIncrementDisabled = getIsIncrementDisabled(
    endDate,
    periodEndParam,
    view,
  );

  const minDate = getMinDate(periodStartParam, view);

  const maxDate = getMaxDate(periodEndParam, view);

  const decrementEndDate = () => {
    if (endDate) {
      const nextEndDate = getDecrementedNextEndDate(
        revertTransformDate(endDate),
        view,
      );
      const nextEndDateFormatted = getFormattedDate(
        nextEndDate,
        dateFormatForApi,
      );
      if (nextEndDateFormatted) onEndDateChange(nextEndDateFormatted);
    }
  };

  const incrementEndDate = () => {
    if (endDate) {
      const nextEndDate = getIncrementedNextEndDate(
        revertTransformDate(endDate),
        view,
      );
      const nextEndDateFormatted = getFormattedDate(
        nextEndDate,
        dateFormatForApi,
      );
      if (nextEndDateFormatted) onEndDateChange(nextEndDateFormatted);
    }
  };

  const onDateChange = (v: string) => {
    const nextEndDate = v
      ? getFormattedDate(getPeriodEndDate(v, view), dateFormatForApi)
      : getFormattedDate(
          getPeriodEndDate(new Date().toISOString(), view),
          dateFormatForApi,
        );
    if (nextEndDate) {
      onEndDateChange(nextEndDate);
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.leftCell}>
        <CheckBoxSingleBool
          name="show-task-details"
          value={isFullTableView}
          options={[{ label: 'checked', value: 'checked' }]}
          onChange={onToggleFullTableView}
          label={t('show-task-details')}
        />
      </div>
      <div className={styles.rightCell}>
        <IconButton
          icon="chevron_left"
          rounded
          type={IconButtonTypes.SecondaryFilled}
          onClick={decrementEndDate}
          disabled={isDecrementDisabled}
        />
        <div>
          <DatePicker
            format={datePickerFormat}
            onChange={onDateChange}
            pickerType={datePickerType}
            size="middle"
            value={
              endDate
                ? transformDate(getPeriodStartDate(endDate, view))
                : undefined
            }
            minDate={minDate}
            maxDate={maxDate}
          />
        </div>
        <IconButton
          icon="chevron_right"
          rounded
          type={IconButtonTypes.SecondaryFilled}
          onClick={incrementEndDate}
          disabled={isIncrementDisabled}
        />
        <div>
          <Select
            name="view"
            value={selectedOption}
            isSearchable={false}
            isClearable={false}
            size={InputSizes.medium}
            onChange={onViewChange}
            options={options}
            menuPlacement="bottom"
          />
        </div>
      </div>
    </div>
  );
}
