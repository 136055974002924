import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import _get from 'lodash/get';
import dayjs from 'dayjs';
import { TaskTimelineGroupByEnum } from 'graphql-common';
import { Value, Values } from '@lib/interfaces/form';
import PageBody from '@lib/layouts/DashboardLayout/PageBody/PageBody';
import WhiteBox from '@lib/components/WhiteBox/WhiteBox';
import ChipWithDropDown from '@lib/components/ChipWithDropDown/ChipWithDropDown';
import { ChipSizes, ChipTypes } from '@lib/components/Chip/Chip';
import Button, {
  ButtonSizes,
  ButtonTypes,
} from '@lib/components/Button/Button';
import useFilterParams from '@lib/hooks/useFilterParams';
import { GetUrlParams } from '@lib/enums/urls';
import MaterialIcon from '@lib/components/MaterialIcon/MaterialIcon';
import SiteAndAreaFilterForm from 'components/DashboardInfoFilter/SiteAndAreaFilterForm';
import { PopoverStateProps } from '@lib/components/Popover/Popover';
import DateRangePicker from '@lib/components/DateTimePicker/DateRangePicker';
import { qsStringify } from '@lib/utils/qsHelpers';
import { InputTypes } from '@lib/components/Input/enums';
import {
  getFormattedDate,
  revertTransformDate,
  transformDate,
} from '@lib/utils/dateTimeHelpers';
import { getPeriodEndDate } from 'components/TasksTimeline/utils/getPeriodDate';
import { dateFormatForApi } from '@lib/enums/dateTime';
import TasksFilterFieldNames from '@lib/enums/fieldNames/tasksFilterFieldNames';
import { APP_URLS } from 'constants/urls';
import usePaginationParams from '@lib/hooks/usePaginationParams';
import { createFilterParams, parseQueryParamsToSiteAndArea } from './utils';
import styles from './DashboardInfoFilter.module.scss';

const rightIconComponent = (isOpen?: boolean) => (
  <MaterialIcon icon={isOpen ? 'arrow_drop_up' : 'arrow_drop_down'} />
);

export default function DashboardInfoFilter() {
  const { t } = useTranslation();
  const dateRangePickerRef = useRef();
  const [filterParams, setFilterParams] = useFilterParams();
  const [paginationParams, setPaginationParams] = usePaginationParams();
  const { pathname } = useLocation();
  const isTimeline = pathname === APP_URLS.dashboard.timeline.path;

  const siteAndArea = filterParams[GetUrlParams.SiteAndArea];
  const periodStart = filterParams[GetUrlParams.PeriodStart];
  const periodEnd = filterParams[GetUrlParams.PeriodEnd];
  const view = filterParams[GetUrlParams.View] || TaskTimelineGroupByEnum.Year;
  const isFilterFiller = !!siteAndArea || !!periodStart || !!periodEnd;
  let periodValue;
  if (periodStart && periodEnd) {
    periodValue = [periodStart, periodEnd];
  }

  const siteAndAreaDefaultValues = siteAndArea
    ? { filter: parseQueryParamsToSiteAndArea(siteAndArea) }
    : undefined;

  const onSiteAndAreaFilterChange = (v: Values) => {
    const filter = _get(v, TasksFilterFieldNames.Filter, []) as Value[];
    const params = filter ? createFilterParams(filter) : undefined;
    setPaginationParams({ ...paginationParams, [GetUrlParams.Page]: '1' });
    setFilterParams(
      {
        ...filterParams,
        [GetUrlParams.SiteAndArea]: qsStringify(params),
      },
      'pushIn',
    );
  };

  const onTimeFilterChange = (v: string[]) => {
    setPaginationParams({ ...paginationParams, [GetUrlParams.Page]: '1' });
    if (Array.isArray(v) && v[0] && v[1]) {
      const periodEndDate = dayjs(revertTransformDate(v[1]));
      const periodEndValue = periodEndDate.isAfter(dayjs())
        ? getPeriodEndDate(
            transformDate(new Date()),
            view as TaskTimelineGroupByEnum,
          )
        : periodEndDate.toDate();
      setFilterParams(
        {
          ...filterParams,
          [GetUrlParams.PeriodStart]: `${v[0]}`,
          [GetUrlParams.PeriodEnd]: `${v[1]}`,
          [GetUrlParams.EndDate]: isTimeline
            ? `${getFormattedDate(periodEndValue, dateFormatForApi)}`
            : undefined,
        },
        'pushIn',
      );
    } else {
      setFilterParams(
        {
          ...filterParams,
          [GetUrlParams.PeriodStart]: undefined,
          [GetUrlParams.PeriodEnd]: undefined,
          [GetUrlParams.EndDate]: undefined,
        },
        'pushIn',
      );
    }
  };

  const onResetFilter = () => {
    setPaginationParams({ ...paginationParams, [GetUrlParams.Page]: '1' });
    setFilterParams({
      [GetUrlParams.EndDate]: undefined,
      [GetUrlParams.PeriodStart]: undefined,
      [GetUrlParams.PeriodEnd]: undefined,
      [GetUrlParams.SiteAndArea]: undefined,
    });
  };

  const getSiteAndAreaFilterForm = (popoverState: PopoverStateProps) => (
    <SiteAndAreaFilterForm
      onSubmit={onSiteAndAreaFilterChange}
      defaultValues={siteAndAreaDefaultValues}
      {...popoverState}
    />
  );

  return (
    <PageBody className={styles.filterWrap}>
      <WhiteBox className={styles.filter} verticalPaddings="small">
        <div>
          <ChipWithDropDown
            body={getSiteAndAreaFilterForm}
            counter={
              siteAndAreaDefaultValues
                ? siteAndAreaDefaultValues[TasksFilterFieldNames.Filter].length
                : undefined
            }
            label={t('site-and-area')}
            leftIcon="location_on"
            leftIconSymbolsOutlined
            rightIconComponent={rightIconComponent()}
            size={ChipSizes.large}
            trigger="click"
            type={ChipTypes.chipFilter}
            width={704}
          />
        </div>
        <div className={styles.dateRangePickerCol}>
          <DateRangePicker
            label={t('period')}
            onChange={onTimeFilterChange}
            ref={dateRangePickerRef}
            size="middle"
            type={InputTypes.dateRange}
            value={periodValue}
          />
        </div>
        {isFilterFiller && (
          <Button
            buttonSize={ButtonSizes.large}
            buttonText={t('reset')}
            buttonType={ButtonTypes.primaryText}
            onClick={onResetFilter}
          />
        )}
      </WhiteBox>
    </PageBody>
  );
}
