import _get from 'lodash/get';
import { InputTelValue } from '@lib/components/Input/InputTel';
import { Values } from '@lib/interfaces/form';
import UserFieldNames from '@lib/enums/fieldNames/userFieldNames';

export default function getDefaultValuesFromQueryData(data?: Values): Values {
  return {
    ...data,
    [UserFieldNames.PhoneNumber]: {
      number: _get(data, 'phoneNumber.international'),
      country: _get(data, 'phoneNumber.country'),
    } as InputTelValue,
  };
}
