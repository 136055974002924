import React from 'react';
import { useTranslation } from 'react-i18next';
import Trans from '@lib/components/Trans/Trans';
import ConfirmationModal from '@lib/components/ConfirmationModal/ConfirmationModal';
import { IconName } from '@lib/components/Modal/enums';
import { ModalManagementResult } from './utils/useModalManagement';
import { UseActionsResults } from './utils/useActions';

interface Props {
  useActionsResult: UseActionsResults;
  useModalManagementResult: ModalManagementResult;
}

export default function FormTemplateModals(props: Props) {
  const { useActionsResult, useModalManagementResult } = props;
  const { t } = useTranslation();

  const {
    closeArchiveModal,
    closeDestroyModal,
    closeVersionArchiveModal,
    closeVersionDestroyModal,
    isArchiveModalOpened,
    isDestroyModalOpened,
    isVersionArchiveModalOpened,
    isVersionDestroyModalOpened,
  } = useModalManagementResult;

  const {
    onConfirmedDestroy,
    onConfirmedVersionDestroy,
    onFormTemplateConfirmedArchive,
    onFormTemplateVersionConfirmedArchive,
  } = useActionsResult;

  return (
    <>
      <ConfirmationModal
        confirmButtonLabel={t('yes-delete')}
        description={<Trans i18nKey="confirm-delete-form-template-text" />}
        icon={IconName.Delete}
        isOpen={isDestroyModalOpened}
        onClose={closeDestroyModal}
        onConfirm={onConfirmedDestroy}
        title={t('are-you-sure')}
      />
      <ConfirmationModal
        confirmButtonLabel={t('yes-delete')}
        description={
          <Trans i18nKey="confirm-delete-form-template-version-text" />
        }
        icon={IconName.Delete}
        isOpen={isVersionDestroyModalOpened}
        onClose={closeVersionDestroyModal}
        onConfirm={onConfirmedVersionDestroy}
        title={t('are-you-sure')}
      />
      <ConfirmationModal
        cancelButtonLabel={t('cancel')}
        confirmButtonLabel={t('yes-archive')}
        description={<Trans i18nKey="confirm-archive-form-template-text" />}
        icon={IconName.Deactivate}
        isOpen={isArchiveModalOpened}
        onClose={closeArchiveModal}
        onConfirm={onFormTemplateConfirmedArchive}
        title={t('are-you-sure')}
      />
      <ConfirmationModal
        cancelButtonLabel={t('cancel')}
        confirmButtonLabel={t('yes-archive')}
        description={
          <Trans i18nKey="confirm-archive-form-template-version-text" />
        }
        icon={IconName.Deactivate}
        isOpen={isVersionArchiveModalOpened}
        onClose={closeVersionArchiveModal}
        onConfirm={onFormTemplateVersionConfirmedArchive}
        title={t('are-you-sure')}
      />
    </>
  );
}
