import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Site, User } from 'graphql-common';
import { Values } from '@lib/interfaces/form';
import { ApolloError } from '@apollo/client/errors';
import DashboardContentMaxWidth from '@lib/layouts/DashboardLayout/DashboardContentMaxWidth/DashboardContentMaxWidth';
import PageHeader from '@lib/layouts/DashboardLayout/PageHeader/PageHeader';
import PageBody from '@lib/layouts/DashboardLayout/PageBody/PageBody';
import Button, { ButtonTypes } from '@lib/components/Button/Button';
import { APP_URLS } from 'constants/urls';
import ConfirmationModal from '@lib/components/ConfirmationModal/ConfirmationModal';
import { materialSymbolsOutlined } from '@lib/enums/common';
import WhiteBox from '@lib/components/WhiteBox/WhiteBox';
import Form from '@lib/components/ReactHookForm/FormContainer';
import replaceNullsWithUndefined from '@lib/utils/replaceNullsWithUndefined';
import { Steps } from '@lib/enums/form';
import UserModalForm from 'components/UserModalForm/UserModalForm';
import { LoadOptions } from '@lib/components/Select/types';
import ErrorPage from '@lib/components/ErrorPage/ErrorPage';
import getStatusFromError from '@lib/utils/getStatusFromError';
import CircleLoader from '@lib/components/CircleLoader/CircleLoader';
import { FormRefType } from '@lib/components/ReactHookForm/types';
import { IconName } from '@lib/components/Modal/enums';
import SiteEditFormActions from './SiteEditFormActions';
import { getDefaultValuesFromQueryData, getSiteFields } from './utils';
import styles from './Site.module.scss';

interface Props {
  closeSiteDeleteModal: () => void;
  closeUserFormModal: () => void;
  countriesCodes: string[];
  formRef: FormRefType;
  isSiteDeleteModalOpened: boolean;
  isUserFormModalOpened: boolean;
  onConfirmedDelete: () => void;
  onCreateUserCallback: (v?: User | null) => void;
  onDelete: () => void;
  onEdit: (values: Values) => void;
  openUserFormModal: () => void;
  siteData: Site;
  siteError?: ApolloError;
  siteLoading?: boolean;
  updateMutationError?: ApolloError;
  updateSiteLoading: boolean;
  usersLoadOptions: LoadOptions;
}

function SiteComponent(props: Props) {
  const {
    closeSiteDeleteModal,
    closeUserFormModal,
    countriesCodes,
    formRef,
    isSiteDeleteModalOpened,
    isUserFormModalOpened,
    onConfirmedDelete,
    onCreateUserCallback,
    onDelete,
    onEdit,
    openUserFormModal,
    siteData,
    siteError,
    siteLoading = false,
    updateMutationError,
    updateSiteLoading,
    usersLoadOptions,
  } = props;
  const { t } = useTranslation();

  const fields = getSiteFields({
    countriesCodes,
    usersLoadOptions,
    openUserFormModal,
    t,
    isEditForm: true,
  });

  const defaultValues = replaceNullsWithUndefined(
    getDefaultValuesFromQueryData(siteData as Values),
  );

  if (siteError) {
    return (
      <ErrorPage
        status={getStatusFromError(siteError)}
        description={siteError?.message}
      />
    );
  }

  return (
    <DashboardContentMaxWidth>
      <PageHeader title={t('edit-site')}>
        <Button
          buttonText={t('view-site')}
          buttonType={ButtonTypes.primaryOutlined}
          leftIcon="visibility"
          to={APP_URLS.dashboard.sites.view.getDynamicPath({
            pathParts: {
              id: `${siteData?.id}`,
            },
          })}
        />
      </PageHeader>
      <PageBody>
        {siteLoading ? (
          <CircleLoader />
        ) : (
          <>
            <WhiteBox verticalPaddings="large">
              <Form
                actionsComponent={SiteEditFormActions}
                defaultValues={defaultValues}
                fields={fields}
                formId={`site-${siteData.id}`}
                formRef={formRef}
                graphQLErrors={updateMutationError?.graphQLErrors}
                loading={updateSiteLoading}
                onSubmit={onEdit}
                steps={[
                  { title: t('site-step-1') },
                  { title: t('site-step-2'), optional: true },
                  { title: t('site-step-3'), optional: true },
                ]}
                stepsCount={Steps.step3}
              />
            </WhiteBox>
            <div className={styles.actions}>
              <Button
                buttonText={t('delete-site')}
                buttonType={ButtonTypes.negativeOutlined}
                leftIcon="delete"
                leftIconClassName={materialSymbolsOutlined}
                onClick={onDelete}
              />
            </div>
          </>
        )}
      </PageBody>
      <UserModalForm
        isOpen={isUserFormModalOpened}
        onClose={closeUserFormModal}
        onSubmitUserFormCallback={onCreateUserCallback}
        title={t('add-team-member')}
        isSiteFieldRequired={false}
      />
      <ConfirmationModal
        confirmButtonLabel={t('yes-delete')}
        description={
          <Trans i18nKey="delete-site-text" components={{ br: <br /> }} />
        }
        icon={IconName.Delete}
        isOpen={isSiteDeleteModalOpened}
        onClose={closeSiteDeleteModal}
        onConfirm={onConfirmedDelete}
        title={t('are-you-sure')}
      />
    </DashboardContentMaxWidth>
  );
}

export default SiteComponent;
