import { TFunction } from 'i18next';
import { Task, TaskScopeNameEnum } from 'graphql-common';
import { NavItem, NavItemTypeEnum } from '@lib/components/Menu/Menu';
import Icons from '@lib/components/Icons/Icons';

type ActionsMenuArgs = {
  onTaskArchiveOpen: (v?: Task) => void;
  onDuplicateHandler: (v?: string) => void;
  onEditHandler: (v?: string) => void;
  onEditScheduleHandler: (v?: Task) => void;
  onViewHandler: (v?: string) => void;
  onDeleteHandler: (v?: Task) => void;
  t: TFunction<'translation', undefined>;
  taskScope?: TaskScopeNameEnum;
  isViewOnly: boolean;
};

export default function getActionsMenu({
  onTaskArchiveOpen,
  onDuplicateHandler,
  onEditHandler,
  onEditScheduleHandler,
  onViewHandler,
  onDeleteHandler,
  t,
  isViewOnly,
}: ActionsMenuArgs) {
  const result: NavItem<Task>[] = [
    {
      id: 'view-action',
      title: t('view'),
      onClick: (task?: Task) => onViewHandler(task?.id),
      leftIcon: Icons.view,
      type: NavItemTypeEnum.Secondary,
    },
  ];
  if (!isViewOnly) {
    result.push(
      {
        id: 'edit-action',
        title: t('edit-task-details'),
        onClick: (task?: Task) => onEditHandler(task?.id),
        leftIcon: Icons.edit,
        type: NavItemTypeEnum.Secondary,
        predictorFnVisible: (task) => !!task?.canUpdate?.value,
      },
      {
        id: 'edit-schedule-action',
        title: t('reschedule'),
        onClick: (v?: Task) => onEditScheduleHandler(v),
        leftIcon: Icons.schedule,
        type: NavItemTypeEnum.Secondary,
        predictorFnVisible: (task) => !!task?.canReschedule?.value,
      },
      {
        id: 'duplicate-action',
        title: t('duplicate'),
        onClick: (task?: Task) => onDuplicateHandler(task?.id),
        leftIcon: Icons.copy,
        type: NavItemTypeEnum.Secondary,
      },
      {
        id: 'archive-action',
        title: t('archive'),
        onClick: (task?: Task) => onTaskArchiveOpen(task),
        leftIcon: Icons.archive,
        type: NavItemTypeEnum.Secondary,
        predictorFnVisible: (task) => !!task?.canArchive?.value,
      },
      {
        id: 'delete-action',
        title: t('delete'),
        onClick: (task?: Task) => onDeleteHandler(task),
        leftIcon: Icons.delete,
        type: NavItemTypeEnum.Secondary,
        predictorFnVisible: (task) => !!task?.canDestroy?.value,
      },
    );
  }
  return result;
}
