import { TaskScopeNameEnum } from 'graphql-common';
import _get from 'lodash/get';
import { TFunction } from 'i18next';
import { CountMarkTypes } from '@lib/components/CountMark/CountMark';
import { TabBarItemTypes } from '@lib/components/TabBar/constants';
import { TaskCounter } from '@lib/interfaces/task';

type Args = {
  t: TFunction<'translation', undefined>;
  taskCounterData: TaskCounter;
};

export default function getTableTabs({ t, taskCounterData }: Args) {
  return [
    {
      label: t('active'),
      value: TaskScopeNameEnum.Active,
      counter: _get(taskCounterData, 'active'),
    },
    {
      label: t('pending'),
      value: TaskScopeNameEnum.Pending,
      counter: _get(taskCounterData, 'pending'),
    },
    {
      label: t('deadline-missed'),
      value: TaskScopeNameEnum.DeadlineMissed,
      counter: _get(taskCounterData, 'deadlineMissed'),
      counterType: CountMarkTypes.red,
      type: TabBarItemTypes.red,
    },
    {
      label: t('completed'),
      value: TaskScopeNameEnum.Completed,
      counter: _get(taskCounterData, 'completed'),
    },
    {
      label: t('archived'),
      value: TaskScopeNameEnum.Archived,
      counter: _get(taskCounterData, 'archived'),
    },
  ];
}
