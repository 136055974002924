import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormTemplate, FormTemplateScopeNameEnum } from 'graphql-common';
import Button, { ButtonTypes } from '@lib/components/Button/Button';
import Table from '@lib/components/Table/Table';
import CircleLoader from '@lib/components/CircleLoader/CircleLoader';
import PageHeader from '@lib/layouts/DashboardLayout/PageHeader/PageHeader';
import Search from '@lib/components/Search/Search';
import PageBody from '@lib/layouts/DashboardLayout/PageBody/PageBody';
import ConfirmationModal from '@lib/components/ConfirmationModal/ConfirmationModal';
import { ApolloError } from '@apollo/client/errors';
import ListEmptyState from '@lib/components/ListEmptyState/ListEmptyState';
import useSearchParam from '@lib/hooks/useSearchParam';
import ErrorPage from '@lib/components/ErrorPage/ErrorPage';
import Trans from '@lib/components/Trans/Trans';
import getStatusFromError from '@lib/utils/getStatusFromError';
import { UseBulkSelectResult } from '@lib/hooks/useTableItemsBulkSelect';
import TabBar from '@lib/components/TabBar/TabBar';
import { APP_URLS } from 'constants/urls';
import { NavItem } from '@lib/components/Menu/Menu';
import { Column } from '@lib/components/Table/types';
import { TabBarItem as TabBarItemType } from '@lib/components/TabBar/constants';
import getEmptyStateTextKeys from 'components/FormTemplates/utils/getEmptyStateTextKeys';
import { useLocation, useNavigate } from 'react-router-dom';
import ViewOnlyChip from '@lib/components/ViewOnlyChip/ViewOnlyChip';
import { IconName } from '@lib/components/Modal/enums';

interface Props {
  closeArchiveModal: () => void;
  closeDestroyModal: () => void;
  formTemplateScope?: FormTemplateScopeNameEnum;
  formTemplates: FormTemplate[];
  formTemplatesError?: ApolloError;
  formTemplatesFirstLoading: boolean;
  formTemplatesLoading: boolean;
  isArchiveModalOpened: boolean;
  isDestroyModalOpened: boolean;
  onConfirmedArchive: () => void;
  onConfirmedDestroy: () => void;
  tableActionsForSelected?: React.ReactNode[];
  tableActionsMenu?: NavItem<FormTemplate>[];
  tableColumns: Column<FormTemplate>[];
  tableTabs?: TabBarItemType[];
  totalFormTemplatesCount: number;
  useBulkSelectResult: UseBulkSelectResult<FormTemplate>;
  isViewOnly: boolean;
}

function FormTemplates(props: Props) {
  const {
    closeArchiveModal,
    closeDestroyModal,
    formTemplateScope,
    formTemplates,
    formTemplatesError,
    formTemplatesFirstLoading,
    formTemplatesLoading,
    isArchiveModalOpened,
    isDestroyModalOpened,
    onConfirmedArchive,
    onConfirmedDestroy,
    tableActionsForSelected,
    tableActionsMenu,
    tableColumns,
    tableTabs = [],
    totalFormTemplatesCount,
    useBulkSelectResult,
    isViewOnly,
  } = props;
  const { t } = useTranslation();
  const [searchQuery] = useSearchParam();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  if (formTemplatesError) {
    return (
      <ErrorPage
        status={getStatusFromError(formTemplatesError)}
        description={formTemplatesError.message}
      />
    );
  }

  const [emptyStateTitleKey, emptyStateTextKey] =
    getEmptyStateTextKeys(formTemplateScope);
  const emptyState = (
    <ListEmptyState
      title={<Trans i18nKey={emptyStateTitleKey} />}
      text={<Trans i18nKey={emptyStateTextKey} />}
      actions={
        formTemplateScope === FormTemplateScopeNameEnum.Active && !isViewOnly
          ? [
              <Button
                key="add"
                buttonText={t('create-form-template')}
                buttonType={ButtonTypes.primaryFilled}
                leftIcon="add"
                to={APP_URLS.dashboard.formTemplates.add.path}
              />,
            ]
          : []
      }
    />
  );

  return (
    <>
      <PageHeader title={t('form-templates')}>
        {isViewOnly ? (
          <ViewOnlyChip />
        ) : (
          <Button
            buttonText={t('create-form-template')}
            leftIcon="add"
            to={APP_URLS.dashboard.formTemplates.add.path}
          />
        )}
      </PageHeader>
      <PageBody>
        {formTemplatesFirstLoading ? (
          <CircleLoader />
        ) : (
          <>
            <TabBar
              items={tableTabs}
              active={pathname}
              onActiveChange={(item) => navigate(item.value)}
            />
            {(!!formTemplates?.length || searchQuery) && (
              <Search placeholder={t('form-templates-search-placeholder')} />
            )}
            <Table
              actionsForSelected={tableActionsForSelected}
              actionsMenu={tableActionsMenu}
              columns={tableColumns}
              counterText={t('form-templates')}
              data={formTemplates}
              emptyState={emptyState}
              fullHeight
              id="form-templates"
              loading={formTemplatesLoading}
              totalEntries={totalFormTemplatesCount}
              useBulkSelectResult={useBulkSelectResult}
            />
          </>
        )}
        <ConfirmationModal
          cancelButtonLabel={t('cancel')}
          confirmButtonLabel={t('yes-delete')}
          description={<Trans i18nKey="confirm-delete-form-template-text" />}
          icon={IconName.Delete}
          isOpen={isDestroyModalOpened}
          onClose={closeDestroyModal}
          onConfirm={onConfirmedDestroy}
          title={t('are-you-sure')}
        />
        <ConfirmationModal
          cancelButtonLabel={t('cancel')}
          confirmButtonLabel={t('yes-archive')}
          description={<Trans i18nKey="confirm-archive-form-template-text" />}
          icon={IconName.Deactivate}
          isOpen={isArchiveModalOpened}
          onClose={closeArchiveModal}
          onConfirm={onConfirmedArchive}
          title={t('are-you-sure')}
        />
      </PageBody>
    </>
  );
}

export default FormTemplates;
