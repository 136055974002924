import * as Yup from 'yup';
import { TFunction } from 'i18next';
import Input from '@lib/components/Input/Input';
import RadioButtonGroup, {
  RadioButtonGroupOption,
  RadioButtonWrapViews,
} from '@lib/components/RadioButtonGroup/RadioButtonGroup';
import RadioButtonAsChipColor from '@lib/components/RadioButtonAsChipColor/RadioButtonAsChipColor';
import { ChipTypes } from '@lib/components/Chip/Chip';
import { MAX_TEXT_FIELD_LENGTH } from '@lib/enums/form';
import { getOptionShape } from '@lib/utils/yup';
import { FieldItem } from '@lib/interfaces/form';
import FormTemplateCategoryFieldNames from '@lib/enums/fieldNames/formTemplateCategoryFieldNames';

type IndustryFormFieldsArgs = {
  t: TFunction<'translation', undefined>;
};

export default function getCategoryFormFields({
  t,
}: IndustryFormFieldsArgs): FieldItem[] {
  return [
    {
      name: FormTemplateCategoryFieldNames.Name,
      label: t('category-name'),
      element: Input,
      validation: Yup.string()
        .trim()
        .max(MAX_TEXT_FIELD_LENGTH, t('max-text-length-field-error'))
        .required(t('required-field-error')),
      componentProps: {
        autoFocus: true,
      },
    },
    {
      name: FormTemplateCategoryFieldNames.Color,
      label: t('label-color'),
      element: RadioButtonGroup,
      validation: Yup.object()
        .shape(getOptionShape(t, true))
        .required(t('required-field-error')),
      componentProps: {
        options: (Object.keys(ChipTypes) as Array<keyof typeof ChipTypes>).map(
          (chipType) => ({
            value: ChipTypes[chipType],
            label: ChipTypes[chipType],
          }),
        ) as RadioButtonGroupOption[],
        RadioButtonComponent: RadioButtonAsChipColor,
        wrapView: RadioButtonWrapViews.colors,
      },
    },
  ];
}
