import { Task } from 'graphql-common';
import getTaskScheduleValuesFromQueryData from './getTaskScheduleValuesFromQueryData';
import getTaskValuesFormFromQueryData from './getTaskValuesFormFromQueryData';

export default function getFullTaskFormValuesFromQueryData(taskData: Task) {
  return {
    // Step 1
    ...getTaskValuesFormFromQueryData(taskData),
    // Step 2
    ...getTaskScheduleValuesFromQueryData(taskData),
  };
}
