import _get from 'lodash/get';
import { Values } from '@lib/interfaces/form';
import AssetNomenclatureFieldNames from '@lib/enums/fieldNames/assetNomenclatureFieldNames';

type Args = {
  values: Values;
  isEdit?: boolean;
  mainAssetId?: string;
};

export default function getPreparedAssetNomenclatureDataFromValues<T>(
  args: Args,
): T {
  const { values, isEdit, mainAssetId } = args;
  const quantity = _get(values, AssetNomenclatureFieldNames.Quantity);
  return {
    quantity: quantity ? Number(quantity) : 0,
    mainAssetId,
    componentAssetId: isEdit
      ? undefined
      : _get(values, [AssetNomenclatureFieldNames.ComponentAsset, 'value'], ''),
  } as T;
}
