import React, { memo } from 'react';
import classNames from 'classnames';
import MaterialIcon from '@lib/components/MaterialIcon/MaterialIcon';
import { useTranslation } from 'react-i18next';
import styles from './RadioButton.module.scss';

export interface RadioButtonProps {
  checked: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  hasError?: boolean;
  iconClassName?: string;
  id: string | number;
  index?: number;
  label?: string | React.JSX.Element;
  labelTransKey?: string;
  labelClassName?: string;
  name?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  wrapperClassName?: string;
}

function RadioButton(props: RadioButtonProps): JSX.Element {
  const {
    checked,
    disabled = false,
    fullWidth = false,
    hasError = false,
    iconClassName = '',
    id,
    index,
    label = '',
    labelTransKey = '',
    labelClassName = '',
    name,
    onChange,
    wrapperClassName = '',
  } = props;
  const { t } = useTranslation();

  const inputId = `${name}.${id}`;

  const wrapperClass = classNames(styles.wrapper, {
    [styles.wrapper]: !fullWidth,
    [styles.disabled]: disabled,
    [styles.fullWidthWrapper]: fullWidth,
    [wrapperClassName]: !!wrapperClassName,
  });

  const labelClass = classNames({
    [styles.label]: true,
    [styles.labelWhenError]: hasError,
    [labelClassName]: !!labelClassName,
  });

  const iconClass = classNames(styles.icon, {
    [styles.checkedIcon]: checked,
    [styles.withErrorIcon]: hasError,
    [styles.disabledIcon]: disabled,
    [iconClassName]: !!iconClassName,
  });

  return (
    <div id={`${name}-${index}`} tabIndex={-1} className={wrapperClass}>
      <input
        id={inputId}
        name={name}
        type="radio"
        checked={checked}
        onChange={onChange}
        className={styles.input}
      />
      <label className={labelClass} htmlFor={inputId}>
        <span className={iconClass} role="button" tabIndex={0}>
          <MaterialIcon
            icon={checked ? 'radio_button_checked' : 'radio_button_unchecked'}
          />
        </span>
        {labelTransKey && (
          <span className={styles.text}>{t(labelTransKey)}</span>
        )}
        {label && <span className={styles.text}>{label}</span>}
      </label>
    </div>
  );
}

export default memo(RadioButton);
