import React from 'react';
import {
  FormAnswer,
  Task,
  TaskCompletionStatusEnum,
  TaskScopeNameEnum,
} from 'graphql-common';
import { TFunction } from 'i18next';
import _without from 'lodash/without';
import _get from 'lodash/get';
import _upperFirst from 'lodash/upperFirst';
import _toLower from 'lodash/toLower';
import { CellProps, Column } from '@lib/components/Table/types';
import { ChipTypes } from '@lib/components/Chip/Chip';
import TextLinkCell from '@lib/components/Table/commonComponents/TextLinkCell/TextLinkCell';
import { APP_URLS } from 'constants/urls';
import ChipCell from '@lib/components/Table/commonComponents/ChipCell/ChipCell';
import { getPriorityChipType } from '@lib/utils/priority';
import { getFormattedDate } from '@lib/utils/dateTimeHelpers';
import TextCell from '@lib/components/Table/commonComponents/TextCell/TextCell';
import { CountMarkTypes } from '@lib/components/CountMark/CountMark';
import { getValidityHintChipIcon } from '@lib/components/Select/components/ValidityHintOption';
import ResultStateChip from 'components/ResultStateChip/ResultStateChip';
import { TabBarItemTypes } from '@lib/components/TabBar/constants';
import { NavItem, NavItemTypeEnum } from '@lib/components/Menu/Menu';
import Icons from '@lib/components/Icons/Icons';
import { TaskCounter } from '@lib/interfaces/task';

type InfoTableColumnsArgs = {
  onResultStateChangeHandler: (id: string, v: TaskCompletionStatusEnum) => void;
  t: TFunction<'translation', undefined>;
  taskScope?: TaskScopeNameEnum;
};

export const getOverviewTableColumns = ({
  t,
  taskScope,
  onResultStateChangeHandler,
}: InfoTableColumnsArgs) =>
  _without(
    [
      {
        id: 'completion.validityHint',
        Header: ' ',
        accessor: 'completion.validityHint',
        Cell: ({ value }: CellProps<FormAnswer>) =>
          getValidityHintChipIcon(value as string),
        width: 44,
      },
      {
        id: 'name',
        Header: t('name'),
        accessor: 'name',
        Cell: (props: CellProps<Task>) => (
          <TextLinkCell
            {...props}
            to={APP_URLS.dashboard.tasks.view.getDynamicPath({
              pathParts: {
                id: props?.item.id,
              },
            })}
          />
        ),
        sortable: true,
        minWidth: 200,
        maxWidth: 350,
      },
      {
        id: 'siteName',
        Header: t('site'),
        accessor: 'site',
        Cell: (props: CellProps<Task>) => {
          const label = _get(props, 'item.site.name', '');
          if (!label) return '-';
          return <ChipCell label={label} type={ChipTypes.chipGray} />;
        },
        sortable: true,
        width: 200,
        maxWidth: 250,
      },
      {
        id: 'siteAreaName',
        Header: t('area'),
        accessor: 'siteArea',
        Cell: (props: CellProps<Task>) => {
          const label = _get(props, 'item.siteArea.name', '');
          if (!label) return '-';
          return <ChipCell label={label} type={ChipTypes.chipGray} />;
        },
        sortable: true,
        width: 200,
        maxWidth: 250,
      },
      {
        id: 'priority',
        Header: t('priority'),
        accessor: 'priority',
        Cell: (props: CellProps<Task>) => {
          const priority = _get(props, 'item.priority');
          const label = _upperFirst(_toLower(priority));
          if (!label) return '-';
          return (
            <ChipCell
              label={label}
              type={getPriorityChipType(priority)}
              leftIcon="circle"
            />
          );
        },
        sortable: true,
        maxWidth: 150,
      },
      {
        id: 'completedAt',
        Header: t('last-check'),
        accessorFn: ({ item }: CellProps<Task>) =>
          item.completedAt ? getFormattedDate(item.completedAt) : '-',
        Cell: (props: CellProps<Task>) => <TextCell {...props} />,
        sortable: true,
        isSingleLineHeader: true,
        maxWidth: 150,
      },
      {
        id: 'deadlineAt',
        Header: t('next-check'),
        accessorFn: ({ item }: CellProps<Task>) => {
          const isRepeatable = !!item.recurringRuleId;
          if (!isRepeatable || !item.deadlineAt) return '-';
          if (!isRepeatable || !item.nextDeadlineAt) return '-';
          return getFormattedDate(item.nextDeadlineAt);
        },
        Cell: (props: CellProps<Task>) => <TextCell {...props} />,
        sortable: true,
        isSingleLineHeader: true,
        maxWidth: 150,
      },
      taskScope &&
      [
        TaskScopeNameEnum.Completed,
        TaskScopeNameEnum.FailureUnsolved,
        TaskScopeNameEnum.WarningUnsolved,
        TaskScopeNameEnum.DashboardAll,
      ].includes(taskScope)
        ? {
            id: 'completionStatus',
            Header: t('status'),
            accessor: 'resultState',
            Cell: ({ item }: CellProps<Task>) => (
              <ResultStateChip
                task={item}
                onResultStateChangeHandler={
                  item.completion?.canStatusUpdate?.value
                    ? onResultStateChangeHandler
                    : undefined
                }
              />
            ),
            sortable: true,
            isSingleLineHeader: true,
            width: 200,
            maxWidth: 250,
          }
        : undefined,
      taskScope &&
      [
        TaskScopeNameEnum.Completed,
        TaskScopeNameEnum.Solved,
        TaskScopeNameEnum.DashboardAll,
      ].includes(taskScope)
        ? {
            id: 'completionSolvedAt',
            Header: t('date-solved'),
            accessorFn: ({ item }: CellProps<Task>) =>
              item.completion?.solvedAt
                ? getFormattedDate(item.completion.solvedAt)
                : '-',
            Cell: (props: CellProps<Task>) => <TextCell {...props} />,
            sortable: true,
            isSingleLineHeader: true,
            maxWidth: 150,
          }
        : undefined,
    ],
    undefined,
  ) as Column<Task>[];

type OverviewTableTabsArgs = {
  t: TFunction<'translation', undefined>;
  taskCounterData?: TaskCounter;
};

export const getOverviewTableTabs = ({
  t,
  taskCounterData,
}: OverviewTableTabsArgs) => [
  {
    label: t('all'),
    value: TaskScopeNameEnum.DashboardAll,
    counter: _get(taskCounterData, 'dashboardAll'),
  },
  {
    label: t('failure'),
    value: TaskScopeNameEnum.FailureUnsolved,
    counter: _get(taskCounterData, 'failureUnsolved'),
    counterType: CountMarkTypes.red,
    type: TabBarItemTypes.red,
  },
  {
    label: t('warning'),
    value: TaskScopeNameEnum.WarningUnsolved,
    counter: _get(taskCounterData, 'warningUnsolved'),
    counterType: CountMarkTypes.orange,
    type: TabBarItemTypes.orange,
  },
  {
    label: t('success'),
    value: TaskScopeNameEnum.Success,
    counter: _get(taskCounterData, 'success'),
    counterType: CountMarkTypes.green,
    type: TabBarItemTypes.green,
  },
  {
    label: t('solved'),
    value: TaskScopeNameEnum.Solved,
    counter: _get(taskCounterData, 'solved'),
  },
];

export const getTableActionsMenu = ({
  t,
  onViewHandler,
  onCreateIntervention,
  onViewIntervention,
}: {
  t: TFunction<'translation', undefined>;
  onViewHandler: (item?: Task) => void;
  onCreateIntervention: (item?: Task) => void;
  onViewIntervention: (item?: Task) => void;
}): NavItem<Task>[] => {
  return [
    {
      id: 'view-action',
      title: t('view'),
      onClick: (item) => onViewHandler(item),
      leftIcon: Icons.view,
      type: NavItemTypeEnum.Secondary,
    },
    {
      id: 'create-intervention',
      title: t('create-intervention'),
      onClick: onCreateIntervention
        ? (item) => onCreateIntervention(item)
        : undefined,
      leftIcon: Icons.build,
      type: NavItemTypeEnum.Secondary,
      predictorFnVisible: (item) => !!_get(item, 'canInterventionCreate.value'),
    },
    {
      id: 'view-intervention',
      title: t('view-intervention'),
      onClick: onViewIntervention
        ? (item) => onViewIntervention(item)
        : undefined,
      leftIcon: Icons.build,
      type: NavItemTypeEnum.Secondary,
      predictorFnVisible: (item) => !!_get(item, 'intervention.id'),
    },
  ];
};
