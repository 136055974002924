import React from 'react';
import { useTranslation } from 'react-i18next';
import _get from 'lodash/get';
import { RoleEnum, User } from 'graphql-common';
import Typography from '@lib/components/Typography/Typography';
import Image from '@lib/components/Image/Image';
import AvatarIcon from '@lib/assets/icons/avatar.svg?react';
import styles from './UserInfoView.module.scss';

export default function UserInfoHeader({ user }: { user: User }) {
  const { t } = useTranslation();
  const { firstName, lastName, avatarAttached, role } = user;
  const url =
    _get(avatarAttached, 'urls.small') || _get(avatarAttached, 'urls.original');
  const userAvatar = url ? <Image src={url} /> : <AvatarIcon />;
  const isFullAdmin = role === RoleEnum.FullAdmin;

  return (
    <div className={styles.userWrap}>
      <div className={styles.userAvatar}>{userAvatar}</div>
      <div className={styles.userInfo}>
        <Typography variant="body" strong>
          {firstName} {lastName}
        </Typography>
        <Typography variant="caption" className={styles.userRole}>
          {isFullAdmin ? t('full-admin') : t('user')}
        </Typography>
      </div>
    </div>
  );
}
