import React from 'react';
import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { RoleEnum } from 'graphql-common';
import _get from 'lodash/get';
import Input, { InputProps } from '@lib/components/Input/Input';
import { InputTypes } from '@lib/components/Input/enums';
import {
  EMAIL_REGEX,
  getAllOption,
  MAX_PASSWORD_FIELD_LENGTH,
  MAX_TEXT_FIELD_LENGTH,
  MAX_USERNAME_FIELD_LENGTH,
  MIN_PASSWORD_FIELD_LENGTH,
  MIN_USERNAME_FIELD_LENGTH,
  USER_NAME_REGEX,
} from '@lib/enums/form';
import { LoadOptions } from '@lib/components/Select/types';
import { getRolesOptions } from '@lib/utils/roles';
import Select from '@lib/components/Select/Select';
import { getOptionShape } from '@lib/utils/yup';
import SupportiveMessage, {
  SupportiveMessageColor,
} from '@lib/components/SupportiveMessage/SupportiveMessage';
import {
  FieldChangeCallBackArgs,
  FieldItem,
  Values,
} from '@lib/interfaces/form';
import InputPassword from '@lib/components/Input/InputPassword';
import Button, {
  ButtonSizes,
  ButtonTypes,
} from '@lib/components/Button/Button';
import toast from '@lib/components/Toast/Toast';
import CheckBoxGroup, {
  CheckBoxGroupProps,
} from '@lib/components/CheckBoxGroup/CheckBoxGroup';
import CheckBoxAsButton from '@lib/components/CheckBoxAsButton/CheckBoxAsButton';
import {
  transformToLowerCase,
  transformToUpperCase,
} from '@lib/utils/formHelpers';
import UserFieldNames from '@lib/enums/fieldNames/userFieldNames';
import formStyles from '@lib/assets/styles/Form.module.scss';
import Typography from '@lib/components/Typography/Typography';
import RadioButtonGroup, {
  RadioButtonWrapViews,
} from '@lib/components/RadioButtonGroup/RadioButtonGroup';
import RadioButtonAsButton from '@lib/components/RadioButtonAsButton/RadioButtonAsButton';
import Separator from '@lib/components/Separator/Separator';
import ChipValueContainer from '@lib/components/Select/components/ChipValueContainer';
import {
  getOptionValidation,
  getPhoneValidation,
} from '@lib/utils/validationUtils';
import ChipCircleOption from '@lib/components/Select/components/ChipCircleOption';
import _isEqual from 'lodash/isEqual';

type FormFieldsArgs = {
  countriesCodes: string[];
  isSiteFieldRequired?: boolean;
  sitesLoadOptions: LoadOptions;
  teamsLoadOptions: LoadOptions;
  t: TFunction<'translation', undefined>;
  prevValues: Values;
};

export default function getUserFormFields({
  countriesCodes,
  sitesLoadOptions,
  teamsLoadOptions,
  t,
  isSiteFieldRequired,
  prevValues,
}: FormFieldsArgs): FieldItem[] {
  const isVisibleForUserOnly = (values: Values) =>
    _get(values, [UserFieldNames.Role, 'value']) === RoleEnum.User;
  const isVisibleForFullAdminOnly = (values: Values) =>
    _get(values, [UserFieldNames.Role, 'value']) === RoleEnum.FullAdmin;
  return [
    {
      name: UserFieldNames.Role,
      element: RadioButtonGroup,
      validation: Yup.object()
        .shape(getOptionShape(t, true))
        .required(t('required-field-error')),
      componentProps: {
        options: getRolesOptions(),
        RadioButtonComponent: RadioButtonAsButton,
        wrapView: RadioButtonWrapViews.rowCentered,
      },
      message: {
        getTextFn: (values: Values) => {
          const roleValue = _get(values, [
            UserFieldNames.Role,
            'value',
          ]) as RoleEnum;
          const texts = {
            [RoleEnum.FullAdmin]: t('role-full-admin-description'),
            [RoleEnum.User]: t('role-user-description'),
          };
          return roleValue ? texts[roleValue] : undefined;
        },
      },
      isUseWatch: true,
      fieldChangeCallBack: ({
        type,
        values,
        setValue,
        name,
      }: FieldChangeCallBackArgs) => {
        if (type === 'change' && name === UserFieldNames.Role) {
          if (
            _get(values, [UserFieldNames.Role, 'value']) === RoleEnum.FullAdmin
          ) {
            setValue(UserFieldNames.SiteMemberships, [getAllOption(t)], {
              shouldValidate: true,
            });
          } else {
            setValue(UserFieldNames.SiteMemberships, [], {
              shouldValidate: false,
            });
          }
        }
      },
    },
    {
      name: 'login-credentials',
      customRender: () => (
        <Typography variant="h3">{t('login-credentials')}</Typography>
      ),
    },
    {
      name: UserFieldNames.Email,
      label: t('work-email'),
      element: Input,
      validation: Yup.string()
        .trim()
        .max(MAX_TEXT_FIELD_LENGTH, t('max-text-length-field-error'))
        .when(
          [UserFieldNames.Role, UserFieldNames.Username],
          ([role, username], schema) => {
            if (role?.value === RoleEnum.User) {
              if (username) {
                return schema.nullable();
              }
              return schema
                .matches(EMAIL_REGEX, t('email-field-error'))
                .required(t('required-field-error'));
            }
            return schema
              .matches(EMAIL_REGEX, t('email-field-error'))
              .required(t('required-field-error'));
          },
        ),
      isUseWatch: true,
      hideErrorsSpace: true,
      isOptionalFn: () => false,
      fieldChangeCallBack: ({
        values,
        type,
        setValue,
        name,
      }: FieldChangeCallBackArgs) => {
        if (type === 'change' && name === UserFieldNames.Email) {
          setValue(
            `${UserFieldNames.Email}-copy`,
            _get(values, UserFieldNames.Email),
            { shouldValidate: false },
          );
          setValue(
            UserFieldNames.Username,
            _get(values, UserFieldNames.Username),
            { shouldValidate: true },
          );
        }
      },
    },
    {
      name: `${UserFieldNames.Email}-copy`,
      label: t('work-email'),
      element: Input,
      isUseWatch: true,
      isVisibleFn: () => false,
    },
    {
      name: 'separator',
      customRender: () => <Separator text={t('or-and')} />,
      hideErrorsSpace: true,
      isVisibleFn: isVisibleForUserOnly,
    },
    {
      name: 'separator-empty',
      customRender: () => <div />,
      isVisibleFn: isVisibleForFullAdminOnly,
    },
    {
      name: UserFieldNames.Username,
      label: t('username'),
      element: Input,
      componentProps: {
        beforeValueChange: transformToLowerCase,
      },
      validation: Yup.string()
        .trim()
        .when(
          [UserFieldNames.Role, `${UserFieldNames.Email}-copy`],
          ([role, email], schema) => {
            const newSchema = schema
              .max(
                MAX_USERNAME_FIELD_LENGTH,
                t(`max-text-length-field-error-${MAX_USERNAME_FIELD_LENGTH}`),
              )
              .test(
                'username-or-empty',
                t('username-field-error'),
                // eslint-disable-next-line func-names
                function (value) {
                  const { createError } = this; // Use Yup's context to create errors

                  // Validation for User
                  if (role?.value === RoleEnum.User) {
                    if (email) {
                      // If email exists, allow empty strings or validate USER_NAME_REGEX
                      if (!value) return true; // Allow empty
                      if (value.length < MIN_USERNAME_FIELD_LENGTH) {
                        return createError({
                          message: t(
                            `min-text-length-field-error-${MIN_USERNAME_FIELD_LENGTH}`,
                          ),
                        });
                      }
                      return USER_NAME_REGEX.test(value); // Validate regex
                    }

                    // If email doesn't exist, validate min length and regex
                    if (!value || value.length < MIN_USERNAME_FIELD_LENGTH) {
                      return createError({
                        message: t(
                          `min-text-length-field-error-${MIN_USERNAME_FIELD_LENGTH}`,
                        ),
                      });
                    }

                    return USER_NAME_REGEX.test(value); // Validate regex
                  }

                  // Validation for Admin (field is optional)
                  if (value && value.length < MIN_USERNAME_FIELD_LENGTH) {
                    return createError({
                      message: t(
                        `min-text-length-field-error-${MIN_USERNAME_FIELD_LENGTH}`,
                      ),
                    });
                  }
                  if (value) {
                    return USER_NAME_REGEX.test(value);
                  }

                  return true;
                },
              );

            if (email) {
              return newSchema.nullable();
            }
            if (role?.value === RoleEnum.User) {
              return newSchema.required(t('required-field-error'));
            }
            return schema.nullable();
          },
        ),
      isUseWatch: true,
      isOptionalFn: (values: Values) =>
        _get(values, [UserFieldNames.Role, 'value']) !== RoleEnum.User,
      fieldChangeCallBack: ({
        values,
        type,
        setValue,
        name,
      }: FieldChangeCallBackArgs) => {
        if (type === 'change' && name === UserFieldNames.Username) {
          setValue(UserFieldNames.Email, _get(values, UserFieldNames.Email), {
            shouldValidate: true,
          });
        }
      },
    },
    {
      name: UserFieldNames.Password,
      label: t('password'),
      element: InputPassword,
      validation: Yup.string()
        .trim()
        .when(
          [
            UserFieldNames.Role,
            UserFieldNames.Email,
            UserFieldNames.GenerateNewPassword,
            'id',
          ],
          (deps, schema) =>
            deps?.[0]?.value === RoleEnum.User &&
            !deps?.[1] &&
            (!!deps[2]?.length || !deps[3])
              ? schema
                  .min(MIN_PASSWORD_FIELD_LENGTH, t('password-field-min-error'))
                  .max(MAX_PASSWORD_FIELD_LENGTH, t('password-field-max-error'))
                  .required(t('required-field-error'))
              : schema.nullable(),
        ),
      isVisibleFn: (values: Values) => {
        const isEdit = !!_get(values, 'id');
        const isUser =
          _get(values, [UserFieldNames.Role, 'value']) === RoleEnum.User;
        if (!isEdit && isUser) return true;
        const isGenerateAvailable = !!_get(values, [
          UserFieldNames.GenerateNewPassword,
          0,
        ]);
        return isUser && isGenerateAvailable;
      },
      isOptionalFn: (values: Values) =>
        _get(values, [UserFieldNames.Role, 'value']) !== RoleEnum.User &&
        !!_get(values, UserFieldNames.Email),
      componentProps: {
        showGenerateBtn: true,
      },
      message: {
        text: t('user-password-description'),
        getActions: (values: Values) => {
          const userName = _get(values, UserFieldNames.Username, '');
          const password = _get(values, UserFieldNames.Password, '');
          const copyText = `UserName: ${userName} & Password: ${password}`;

          // This is the function we wrote earlier
          async function copyTextToClipboard(text: string) {
            if ('clipboard' in navigator) {
              const clipboard = navigator.clipboard as Clipboard;
              return clipboard.writeText(text);
            }
            return document.execCommand('copy', true, text);
          }

          // onClick handler function for the copy button
          const handleCopyClick = () => {
            // Asynchronously call copyTextToClipboard
            copyTextToClipboard(copyText)
              .then(() => {
                // If successful, update the isCopied state value
                toast({ content: t('copied-successful') });
              })
              .catch((err) => {
                // eslint-disable-next-line no-console
                console.error(err);
              });
          };

          return (
            <>
              <input type="text" value={copyText} readOnly hidden />
              <Button
                buttonText={t('copy-credentials')}
                buttonSize={ButtonSizes.small}
                buttonType={ButtonTypes.primaryFilled}
                onClick={handleCopyClick}
                disabled={!password}
              />
            </>
          );
        },
      },
    },
    {
      name: UserFieldNames.GenerateNewPassword,
      element: CheckBoxGroup,
      componentProps: {
        options: [{ label: t('generate-new-password'), value: 'generate' }],
        CheckBoxComponent: (props) => (
          <CheckBoxAsButton {...props} fullWidth leftIcon="autorenew" />
        ),
      } as CheckBoxGroupProps,
      isVisibleFn: (values: Values) => {
        const isEdit = !!_get(values, 'id');
        const isUser =
          _get(values, [UserFieldNames.Role, 'value']) === RoleEnum.User;
        const isGenerateAvailable = !_get(values, [
          UserFieldNames.GenerateNewPassword,
          0,
        ]);
        if (!isEdit) return false;
        return isUser && isGenerateAvailable;
      },
    },
    {
      name: 'personal-info',
      customRender: () => (
        <Typography variant="h3">{t('personal-info')}</Typography>
      ),
    },
    {
      name: UserFieldNames.FirstName,
      label: t('first-name'),
      element: Input,
      validation: Yup.string()
        .trim()
        .max(MAX_TEXT_FIELD_LENGTH, t('max-text-length-field-error'))
        .required(t('required-field-error')),
      formItemClassName: formStyles.formColumn,
    },
    {
      name: UserFieldNames.LastName,
      label: t('last-name'),
      element: Input,
      validation: Yup.string()
        .trim()
        .max(MAX_TEXT_FIELD_LENGTH, t('max-text-length-field-error'))
        .required(t('required-field-error')),
      formItemClassName: formStyles.formColumn,
      componentProps: {
        beforeValueChange: transformToUpperCase,
      },
    },
    {
      name: UserFieldNames.PhoneNumber,
      label: t('phone-number'),
      element: Input,
      validation: getPhoneValidation(true, t),
      componentProps: {
        type: InputTypes.tel,
        onlyCountries: countriesCodes,
      } as InputProps,
    },
    {
      name: UserFieldNames.PersonalId,
      label: t('personal-id'),
      element: Input,
      validation: Yup.string().trim().nullable(),
      hideErrorsSpace: true,
    },
    {
      name: `${UserFieldNames.Assignations}-title`,
      customRender: () => (
        <Typography variant="h3" className={formStyles.blockStart}>
          {t('assignation')}
        </Typography>
      ),
    },
    {
      name: UserFieldNames.SiteMemberships,
      label: t('sites-label'),
      element: Select,
      validation: Yup.array()
        .of(Yup.object().shape(getOptionShape(t, true)))
        .when(UserFieldNames.Role, (deps, schema) =>
          isSiteFieldRequired === false || deps[0] === RoleEnum.FullAdmin
            ? schema.nullable()
            : schema
                .min(1, t('required-field-error'))
                .required(t('required-field-error')),
        ),
      componentProps: {
        isMulti: true,
        isAsync: true,
        hasSelectAll: true,
        loadOptions: sitesLoadOptions,
        defaultOptions: false,
        usePortal: true,
        menuPlacement: 'top',
      },
      isOptionalFn: () => false,
      isDisabledFn: (values: Values) =>
        _get(values, [UserFieldNames.Role, 'value']) === RoleEnum.FullAdmin,
      getFieldKeyFn: (values: Values) =>
        _get(values, [UserFieldNames.Role, 'value']) === RoleEnum.FullAdmin
          ? `${UserFieldNames.SiteMemberships}-for-${RoleEnum.FullAdmin}`
          : `${UserFieldNames.SiteMemberships}`,
    },
    {
      name: UserFieldNames.Team,
      label: t('team'),
      element: Select,
      validation: getOptionValidation(false, t),
      componentProps: {
        isAsync: true,
        hasSelectAll: true,
        loadOptions: teamsLoadOptions,
        defaultOptions: false,
        usePortal: true,
        menuPlacement: 'top',
        components: {
          ValueContainer: ChipValueContainer,
          Option: ChipCircleOption,
        },
      },
      description: t('user-team-field-description'),
    },
    {
      name: `${UserFieldNames.Team}-warning`,
      customRender: () => (
        <SupportiveMessage
          text={t('user-team-field-warning')}
          color={SupportiveMessageColor.Yellow}
        />
      ),
      isVisibleFn: (values: Values) => {
        return !_isEqual(
          _get(values, [UserFieldNames.Team, 'value']),
          _get(prevValues, [UserFieldNames.Team, 'value']),
        );
      },
    },
  ];
}
