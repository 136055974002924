import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  InterventionCategory,
  useInterventionCategoryCreateMutation,
  useInterventionCategoryDestroyMutation,
  useInterventionCategoryQuery,
  useInterventionCategoryUpdateMutation,
} from 'graphql-common';
import toast from '@lib/components/Toast/Toast';
import usePaginationParams from '@lib/hooks/usePaginationParams';
import onDestroyListItemCallBack from '@lib/utils/onDestroyListItemCallBack';
import { showGraphQLErrors } from '@lib/utils/graphQLErrors';
import useSearchParam from '@lib/hooks/useSearchParam';
import { Values } from '@lib/interfaces/form';
import useInterventionCategoriesQueryHook from 'utils/fetch/useInterventionCategoriesQueryHook';
import { useCallback } from 'react';
import getPreparedAssetCategoryDataFromValues from './getPreparedAssetCategoryDataFromValues';

interface UseActionsProps {
  closeCategoryModal: () => void;
  closeDeleteModal: () => void;
  id?: string | null;
  openCategoryModal: () => void;
  openDeleteModal: () => void;
  selectedCategory: InterventionCategory | null;
  setSelectedCategory: (v: InterventionCategory) => void;
}

export default function useActions(props: UseActionsProps) {
  const {
    closeCategoryModal,
    closeDeleteModal,
    openCategoryModal,
    openDeleteModal,
    selectedCategory,
    setSelectedCategory,
  } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { search, pathname } = useLocation();
  const [searchQuery] = useSearchParam();
  const [paginationParams] = usePaginationParams();

  // Intervention categories
  const useInterventionCategoriesQueryHookResult =
    useInterventionCategoriesQueryHook({
      searchQuery,
      paginationParams,
    });

  // Intervention category
  const categoryQuery = useInterventionCategoryQuery({
    skip: selectedCategory === null,
    variables: {
      id: selectedCategory?.id || '',
    },
  });
  const [categoryCreateMutation, categoryCreateMutationResult] =
    useInterventionCategoryCreateMutation({
      onCompleted: () => {
        useInterventionCategoriesQueryHookResult.refetch();
        if (closeCategoryModal) closeCategoryModal();
        toast({ content: t('create-category-success') });
      },
      onError: (error) => showGraphQLErrors(error, t),
    });
  const [categoryUpdateMutation, categoryUpdateMutationResult] =
    useInterventionCategoryUpdateMutation({
      onCompleted: () => {
        useInterventionCategoriesQueryHookResult.refetch();
        if (closeCategoryModal) closeCategoryModal();
        toast({ content: t('update-category-success') });
      },
      onError: (error) => showGraphQLErrors(error, t),
    });

  // Delete
  const [destroyMutation] = useInterventionCategoryDestroyMutation({
    onCompleted: () => {
      closeDeleteModal();
      onDestroyListItemCallBack({
        collection: useInterventionCategoriesQueryHookResult.collection,
        navigate,
        pathname,
        refetch: useInterventionCategoriesQueryHookResult.refetch,
        search,
        totalPages: useInterventionCategoriesQueryHookResult.totalPages,
      });
    },
    onError: (error) => showGraphQLErrors(error, t),
  });

  const onEditHandler = (v?: InterventionCategory) => {
    if (v) {
      setSelectedCategory(v);
      openCategoryModal();
    }
  };

  const onDeleteHandler = (v: InterventionCategory) => {
    setSelectedCategory(v);
    openDeleteModal();
  };

  const onDestroyHandler = (id?: string) => {
    if (id) {
      destroyMutation({
        variables: {
          id,
        },
      });
    }
  };

  const onConfirmedDelete = () => {
    closeDeleteModal();
    toast({
      content: t('destroy-category-success'),
      closeCallback: () => {
        if (selectedCategory) {
          onDestroyHandler(selectedCategory?.id);
        }
      },
    });
  };

  const onAssetCategoryCreate = (values: Values) => {
    const attributes = getPreparedAssetCategoryDataFromValues(values);
    categoryCreateMutation({ variables: { attributes } });
  };

  const onAssetCategoryUpdate = (values: Values) => {
    const attributes = getPreparedAssetCategoryDataFromValues(values);
    categoryUpdateMutation({
      variables: {
        attributes,
        id: selectedCategory?.id || '',
      },
    });
  };

  const {
    error: categoryCreateMutationError,
    loading: categoryCreateMutationLoading,
    reset: categoryCreateMutationReset,
  } = categoryCreateMutationResult;
  const {
    error: categoryUpdateMutationError,
    loading: categoryUpdateMutationLoading,
    reset: categoryUpdateMutationReset,
  } = categoryUpdateMutationResult;

  const categoryMutationError = selectedCategory
    ? categoryUpdateMutationError
    : categoryCreateMutationError;
  const categoryMutationLoading = selectedCategory
    ? categoryUpdateMutationLoading
    : categoryCreateMutationLoading;
  const categoryMutationReset = useCallback(() => {
    categoryUpdateMutationReset();
    categoryCreateMutationReset();
  }, [categoryUpdateMutationReset, categoryCreateMutationReset]);
  const onSubmitAssetCategoryForm = selectedCategory
    ? onAssetCategoryUpdate
    : onAssetCategoryCreate;

  return {
    categoryQuery,
    categoryMutationError,
    categoryMutationLoading,
    categoryMutationReset,
    onConfirmedDelete,
    onDeleteHandler,
    onEditHandler,
    onSubmitAssetCategoryForm,
    useInterventionCategoriesQueryHookResult,
  };
}
