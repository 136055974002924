import React, { forwardRef, memo, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Button, {
  ButtonSizes,
  ButtonTypes,
} from '@lib/components/Button/Button';
import Placeholder from '@lib/assets/icons/img-placeholder.svg?react';
import { AttachmentValue } from '@lib/components/FileUploaderUppyXHR/FileUploaderUppyXHR';
import Image from '@lib/components/Image/Image';
import { materialSymbolsOutlined } from '@lib/enums/common';
import Modal from '@lib/components/Modal/Modal';
import ActionsRow from '@lib/components/ActionsRow/ActionsRow';
import MaterialIcon from '@lib/components/MaterialIcon/MaterialIcon';
import styles from './IconUploader.module.scss';

export interface AvatarUploaderProps {
  className?: string;
  value?: AttachmentValue;
  onChange: (variables: unknown) => void;
}

const icons = [
  'bell',
  'bolt',
  'bug',
  'building',
  'bullhorn',
  'car-side',
  'city',
  'computer',
  'fan',
  'faucet-drip',
  'fire',
  'fire-extinguisher',
  'flask',
  'gas-pump',
  'gear',
  'house',
  'industry',
  'kit-medical',
  'microscope',
  'mobile-screen',
  'network-wired',
  'phone',
  'robot',
  'ship',
  'shower',
  'snowplow',
  'solar-panel',
  'temperature-half',
  'tractor',
  'truck',
  'tv',
  'video-camera',
  'wheat',
  'wrench',
];

function getCdnUrl(name: string) {
  return `https://cdn.stage.datizy.com/assets/icons/${name}.svg`;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const IconUploader = forwardRef(
  (props: AvatarUploaderProps, ref: React.ForwardedRef<HTMLInputElement>) => {
    const { className, value, onChange } = props;
    const url =
      value?.urls?.small ||
      value?.urls?.original ||
      value?.url ||
      value?.fileTag ||
      null;
    const { t } = useTranslation();
    const [selected, setSelected] = useState<string | null>(url);
    const [isModalOpen, setModalOpen] = useState(false);
    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);
    const onCancel = () => {
      closeModal();
      setSelected(null);
    };
    const onSave = () => {
      onChange(selected ? { fileTag: selected } : null);
      closeModal();
    };
    return (
      <>
        <div className={classNames(styles.root, className)}>
          <div className={styles.img}>
            {url ? <Image src={url} /> : <Placeholder />}
          </div>
          <div>
            <div className={styles.actions}>
              <Button
                onClick={openModal}
                buttonText={url ? t('edit-image') : t('choose-image')}
                buttonType={ButtonTypes.primaryOutlined}
                leftIcon="edit"
                leftIconClassName={materialSymbolsOutlined}
                buttonSize={ButtonSizes.large}
              />
              {url !== undefined && (
                <Button
                  buttonText={t('delete')}
                  buttonType={ButtonTypes.negativeOutlined}
                  leftIcon="delete"
                  leftIconClassName={materialSymbolsOutlined}
                  buttonSize={ButtonSizes.large}
                  onClick={() => onChange(null)}
                />
              )}
            </div>
          </div>
        </div>
        <Modal
          isOpen={isModalOpen}
          onClose={onCancel}
          title={t('choose-image')}
          actions={
            <ActionsRow
              actions={[
                <Button
                  onClick={onCancel}
                  buttonType={ButtonTypes.secondaryOutlined}
                  buttonText={t('cancel')}
                  leftIcon="close"
                  fullWidth
                />,
                <Button
                  onClick={onSave}
                  buttonType={ButtonTypes.primaryFilled}
                  buttonText={t('save')}
                  leftIcon="check"
                  fullWidth
                />,
              ]}
            />
          }
        >
          <div>
            <div className={styles.icons}>
              <div className={styles.iconsScroll}>
                <div className={styles.iconsGrid}>
                  <button
                    type="button"
                    className={classNames(styles.icon, {
                      [styles.iconSelected]: selected === null,
                    })}
                    onClick={() => setSelected(null)}
                  >
                    <MaterialIcon
                      icon="block"
                      size="md-24"
                      className={styles.blockIcon}
                    />
                  </button>
                  {icons.map((icon: string) => {
                    const src = getCdnUrl(icon);
                    return (
                      <button
                        className={classNames(styles.icon, {
                          [styles.iconSelected]: selected === src,
                        })}
                        key={icon}
                        type="button"
                        onClick={() => setSelected(src)}
                      >
                        <Image src={src} />
                      </button>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  },
);

export default memo(IconUploader);
