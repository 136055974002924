import React from 'react';
import { TFunction } from 'i18next';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';
import {
  RecurringRuleCountdownTypeEnum,
  RecurringRuleRuleTypeEnum,
  RecurringRuleSchemaOptionEnum,
} from 'graphql-common';
import _get from 'lodash/get';
import _isNaN from 'lodash/isNaN';
import _isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import { getOptionShape } from '@lib/utils/yup';
import { EndRepeatUnit, FieldNames } from 'routes/Task/enums';
import Typography from '@lib/components/Typography/Typography';
import {
  DEFAULT_NUMBER_INPUT_PROPS,
  MAX_NUMBER_VALUE,
  Steps,
} from '@lib/enums/form';
import DatePicker from '@lib/components/DateTimePicker/DatePicker';
import FrequencyFormFieldsWrapper, {
  FrequencyFormFieldsWrapperProps,
} from 'components/FrequencyFormFields/FrequencyFormFields';
import CheckBoxSingleBool from '@lib/components/CheckBoxSingleBool/CheckBoxSingleBool';
import CheckBoxAsToggle from '@lib/components/CheckBoxAsToggle/CheckBoxAsToggle';
import {
  FieldChangeCallBackArgs,
  FieldItem,
  Values,
} from '@lib/interfaces/form';
import Input from '@lib/components/Input/Input';
import { InputTypes } from '@lib/components/Input/enums';
import Select from '@lib/components/Select/Select';
import RadioButtonGroup, {
  RadioButtonWrapViews,
} from '@lib/components/RadioButtonGroup/RadioButtonGroup';
import RadioButtonAsButton from '@lib/components/RadioButtonAsButton/RadioButtonAsButton';
import { ButtonSizes } from '@lib/components/Button/Button';
import CheckBoxGroup, {
  CheckBoxGroupProps,
  CheckBoxWrapViews,
} from '@lib/components/CheckBoxGroup/CheckBoxGroup';
import CheckBoxAsButton from '@lib/components/CheckBoxAsButton/CheckBoxAsButton';
import CheckBoxAsCalendarDay from '@lib/components/CheckBoxAsCalendarDay/CheckBoxAsCalendarDay';
import { SupportiveMessageColor } from '@lib/components/SupportiveMessage/SupportiveMessage';
import OptionsAddItemComponent from '@lib/components/ReactHookForm/OptionsAddItemComponent';
import OptionsFieldOption from '@lib/components/ReactHookForm/OptionsFieldOption';
import CheckBoxAsCalendarMonth from '@lib/components/CheckBoxAsCalendarMonth/CheckBoxAsCalendarMonth';
import FrequencyFormMessage from 'components/FrequencyFormMessage/FrequencyFormMessage';
import {
  endOfDayFn,
  revertTransformDate,
  subDaysFn,
} from '@lib/utils/dateTimeHelpers';
import formStyles from '@lib/assets/styles/Form.module.scss';
import Tooltip from '@lib/components/Tooltip/Tooltip';
import MaterialIcon from '@lib/components/MaterialIcon/MaterialIcon';
import styles from './TaskFields.module.scss';
import {
  countdownTypeOptions,
  earlyCompletionUnitOptions,
  endRepeatOptions,
  monthOptions,
  recurringRuleDayOfMonthOptionDayOfWeekOptions,
  recurringRuleDayOfMonthOptions,
  repeatDayOptions,
  repeatMonthDaysOptions,
  repeatMonthlyUnitOptions,
  repeatYearlyUnitOptions,
  ruleTypeOptions,
} from './options';

const isVisibleExecutingDays = (values: Values) =>
  !(
    _get(values, `${FieldNames.recurringRuleRuleType}.value`) ===
      RecurringRuleRuleTypeEnum.Weekly ||
    (_get(values, `${FieldNames.recurringRuleRuleType}.value`) ===
      RecurringRuleRuleTypeEnum.Monthly &&
      _get(values, `${FieldNames.repeatMonthlyType}.value`) ===
        RecurringRuleSchemaOptionEnum.OnThe) ||
    (_get(values, `${FieldNames.recurringRuleRuleType}.value`) ===
      RecurringRuleRuleTypeEnum.Yearly &&
      _get(values, `${FieldNames.repeatYearlyType}.value`) ===
        RecurringRuleSchemaOptionEnum.OnThe)
  );

type ScheduleFieldsArgs = {
  step?: Steps;
  t: TFunction<'translation', undefined>;
};

function getEarlyCompletionFields({ step, t }: ScheduleFieldsArgs): FieldItem {
  return {
    name: FieldNames.earlyCompletion,
    element: FrequencyFormFieldsWrapper,
    componentProps: {
      title: t('early-completion'),
      description: t('early-completion-description'),
      name: FieldNames.earlyCompletion,
    },
    step,
    groupFields: [
      {
        name: FieldNames.earlyCompletionEnabled,
        element: CheckBoxSingleBool,
        componentProps: {
          CheckBoxComponent: CheckBoxAsToggle,
          options: [{ label: 'checked', value: 'checked' }],
        },
        isVisibleFn: () => false,
        fieldChangeCallBack: ({
          type,
          setValue,
          name,
        }: FieldChangeCallBackArgs) => {
          if (type === 'change' && name === FieldNames.earlyCompletionEnabled) {
            setValue(FieldNames.earlyCompletionAmount, 1, {
              shouldValidate: true,
            });
            setValue(
              FieldNames.earlyCompletionType,
              earlyCompletionUnitOptions[0],
              { shouldValidate: true },
            );
          }
        },
        step,
      },
      {
        name: FieldNames.earlyCompletionAmount,
        element: Input,
        componentProps: {
          ...DEFAULT_NUMBER_INPUT_PROPS,
          hasErrorAsTooltip: true,
        },
        formItemClassName: formStyles.formColumn4,
        validation: Yup.string().when(
          FieldNames.earlyCompletionEnabled,
          (deps, schema) =>
            _get(deps, 0)
              ? schema
                  .test(
                    'is-number',
                    t('number-field-error'),
                    (value) => !_isNaN(value),
                  )
                  .test('is-min', t('number-field-error-min-1'), (value) => {
                    const number = value ? parseFloat(value) : 0;
                    return number >= 1;
                  })
                  .test(
                    'is-max',
                    t('number-field-error-max-number'),
                    (value) => {
                      const number = value ? parseFloat(value) : 0;
                      return number <= MAX_NUMBER_VALUE;
                    },
                  )
                  .required(t('required-field-error'))
              : schema.nullable(),
        ),
        step,
        hideErrorsSpace: true,
        hideErrors: true,
      },
      {
        name: FieldNames.earlyCompletionType,
        element: Select,
        componentProps: {
          options: earlyCompletionUnitOptions,
          isClearable: false,
        },
        formItemClassName: formStyles.formColumn8,
        validation: Yup.object()
          .shape(getOptionShape(t))
          .when(FieldNames.earlyCompletionEnabled, (deps, schema) =>
            _get(deps, 0)
              ? schema.required(t('required-field-error'))
              : schema.nullable(),
          ),
        step,
        hideErrorsSpace: true,
      },
    ],
  };
}

function getRecurringRuleFields({ step, t }: ScheduleFieldsArgs): FieldItem {
  return {
    name: FieldNames.recurringRule,
    element: FrequencyFormFieldsWrapper,
    componentProps: {
      title: t('repeat'),
      name: FieldNames.recurringRule,
    },
    groupFields: [
      // Common
      {
        name: FieldNames.recurringRuleEnabled,
        element: CheckBoxSingleBool,
        componentProps: {
          CheckBoxComponent: CheckBoxAsToggle,
          options: [{ label: 'checked', value: 'checked' }],
        },
        isVisibleFn: () => false,
        fieldChangeCallBack: ({
          type,
          setValue,
          name,
        }: FieldChangeCallBackArgs) => {
          if (type === 'change' && name === FieldNames.recurringRuleEnabled) {
            setValue(FieldNames.recurringRuleRuleType, ruleTypeOptions[0], {
              shouldValidate: true,
            });
            setValue(FieldNames.recurringRuleInterval, 1, {
              shouldValidate: true,
            });
            setValue(FieldNames.recurringRuleDayOfWeek, [], {
              shouldValidate: true,
            });
            setValue(
              FieldNames.recurringRuleCountdownType,
              countdownTypeOptions[0],
              {
                shouldValidate: true,
              },
            );
            setValue(
              FieldNames.repeatMonthlyType,
              repeatMonthlyUnitOptions[0],
              { shouldValidate: true },
            );
            setValue(FieldNames.repeatMonthlyEachDayOfMonth, [], {
              shouldValidate: true,
            });
            setValue(FieldNames.endRepeat, [], { shouldValidate: true });
            setValue(FieldNames.endRepeatEnabled, false, {
              shouldValidate: true,
            });
          }
        },
        step,
      },
      {
        name: FieldNames.recurringRuleRuleType,
        element: RadioButtonGroup,
        componentProps: {
          RadioButtonComponent: RadioButtonAsButton,
          radioButtonComponentProps: {
            fullWidth: true,
            buttonSize: ButtonSizes.small,
          },
          wrapView: RadioButtonWrapViews.row,
          options: ruleTypeOptions,
        },
        fieldChangeCallBack: ({
          type,
          setValue,
          name,
          values,
        }: FieldChangeCallBackArgs) => {
          if (type === 'change' && name === FieldNames.recurringRuleRuleType) {
            if (_isEmpty(_get(values, FieldNames.recurringRuleRuleType))) {
              setValue(
                FieldNames.repeatMonthlyType,
                repeatMonthlyUnitOptions[0],
                { shouldValidate: true },
              );
            }
            if (_isEmpty(_get(values, FieldNames.repeatYearlyType))) {
              setValue(
                FieldNames.repeatYearlyType,
                repeatYearlyUnitOptions[0],
                { shouldValidate: true },
              );
            }
            if (
              _isEmpty(_get(values, FieldNames.repeatMonthlyRepeatOnOptions))
            ) {
              setValue(
                FieldNames.repeatMonthlyRepeatOnOptions,
                [
                  {
                    [FieldNames.repeatMonthlyRepeatOnOption1]:
                      recurringRuleDayOfMonthOptions[0],
                    [FieldNames.repeatMonthlyRepeatOnOption2]:
                      recurringRuleDayOfMonthOptionDayOfWeekOptions[0],
                  },
                ],
                { shouldValidate: true },
              );
            }
            if (
              _isEmpty(_get(values, FieldNames.repeatYearlyRepeatOnOptions))
            ) {
              setValue(
                FieldNames.repeatYearlyRepeatOnOptions,
                [
                  {
                    [FieldNames.repeatYearlyRepeatOnOption1]:
                      recurringRuleDayOfMonthOptions[0],
                    [FieldNames.repeatYearlyRepeatOnOption2]:
                      recurringRuleDayOfMonthOptionDayOfWeekOptions[0],
                  },
                ],
                { shouldValidate: true },
              );
            }
            if (_isEmpty(_get(values, FieldNames.repeatMonthlyType))) {
              setValue(
                FieldNames.repeatMonthlyType,
                repeatMonthlyUnitOptions[0],
                { shouldValidate: true },
              );
            }
            if (
              !_isEmpty(_get(values, FieldNames.recurringRuleExcludedDayOfWeek))
            ) {
              setValue(FieldNames.recurringRuleExcludedDayOfWeek, [], {
                shouldValidate: true,
              });
            }
          }
        },
        step,
      },
      {
        name: FieldNames.recurringRuleInterval,
        element: Input,
        getLeftLabel: () => t('every'),
        getRightLabel: (values) => {
          const recurringRuleRuleType = _get(
            values,
            [FieldNames.recurringRuleRuleType, 'value'],
            '',
          ) as string;
          return recurringRuleRuleType
            ? t(`repeat-every-${recurringRuleRuleType.toLowerCase()}`)
            : undefined;
        },
        isUseWatch: true,
        componentProps: {
          ...DEFAULT_NUMBER_INPUT_PROPS,
          hasErrorAsTooltip: true,
        },
        validation: Yup.string().when(
          FieldNames.recurringRuleEnabled,
          (deps, schema) =>
            _get(deps, 0)
              ? schema
                  .test(
                    'is-number',
                    t('number-field-error'),
                    (value) => !_isNaN(value),
                  )
                  .test('is-min', t('number-field-error-min-1'), (value) => {
                    const number = value ? parseFloat(value) : 0;
                    return number >= 1;
                  })
                  .test(
                    'is-max',
                    t('number-field-error-max-number'),
                    (value) => {
                      const number = value ? parseFloat(value) : 0;
                      return number <= MAX_NUMBER_VALUE;
                    },
                  )
                  .required(t('required-field-error'))
              : schema.nullable(),
        ),
        step,
        hideErrors: true,
        hideErrorsSpace: true,
        formItemClassName: formStyles.formColumnAuto,
        formItemElementWrapClassName: styles.inputNumber,
      },
      {
        name: 'line-1',
        customRender: () => <hr className={formStyles.hr} />,
        isVisibleFn: (values: Values) =>
          _get(values, `${FieldNames.recurringRuleRuleType}.value`) !==
          RecurringRuleRuleTypeEnum.Daily,
        step,
        hideErrorsSpace: true,
      },
      // Weekly
      {
        name: FieldNames.recurringRuleDayOfWeek,
        element: CheckBoxGroup,
        componentProps: {
          CheckBoxComponent: (props: any) => (
            <CheckBoxAsButton {...props} buttonSize={ButtonSizes.small} />
          ),
          wrapView: CheckBoxWrapViews.row,
          options: repeatDayOptions,
        } as unknown as CheckBoxGroupProps,
        isVisibleFn: (values: Values) =>
          _get(values, `${FieldNames.recurringRuleRuleType}.value`) ===
          RecurringRuleRuleTypeEnum.Weekly,
        validation: Yup.array()
          .of(Yup.object().shape(getOptionShape(t)))
          .when(
            [FieldNames.recurringRuleEnabled, FieldNames.recurringRuleRuleType],
            (deps, schema) =>
              _get(deps, 0) &&
              _get(deps, [1, 'value']) === RecurringRuleRuleTypeEnum.Weekly
                ? schema.min(1, t('required-field-error'))
                : schema.min(0),
          ),
        step,
        hideErrorsSpace: true,
      },
      // Monthly:
      {
        name: FieldNames.repeatMonthlyType,
        element: RadioButtonGroup,
        componentProps: {
          RadioButtonComponent: RadioButtonAsButton,
          radioButtonComponentProps: {
            fullWidth: true,
            buttonSize: ButtonSizes.small,
          },
          wrapView: RadioButtonWrapViews.row,
          options: repeatMonthlyUnitOptions,
        },
        isVisibleFn: (values: Values) =>
          _get(values, `${FieldNames.recurringRuleRuleType}.value`) ===
          RecurringRuleRuleTypeEnum.Monthly,
        fieldChangeCallBack: ({
          type,
          setValue,
          name,
        }: FieldChangeCallBackArgs) => {
          if (type === 'change' && name === FieldNames.repeatMonthlyType) {
            setValue(FieldNames.repeatMonthlyEachDayOfMonth, [], {
              shouldValidate: true,
            });
            setValue(
              FieldNames.repeatMonthlyRepeatOnOptions,
              [
                {
                  [FieldNames.repeatMonthlyRepeatOnOption1]:
                    recurringRuleDayOfMonthOptions[0],
                  [FieldNames.repeatMonthlyRepeatOnOption2]:
                    recurringRuleDayOfMonthOptionDayOfWeekOptions[0],
                },
              ],
              { shouldValidate: true },
            );
            setValue(FieldNames.recurringRuleExcludedDayOfWeek, [], {
              shouldValidate: true,
            });
          }
        },
        step,
      },
      // Monthly: Each
      {
        name: FieldNames.repeatMonthlyEachDayOfMonth,
        element: CheckBoxGroup,
        componentProps: {
          CheckBoxComponent: CheckBoxAsCalendarDay,
          wrapView: CheckBoxWrapViews.calendar,
          options: repeatMonthDaysOptions,
        } as unknown as CheckBoxGroupProps,
        validation: Yup.array()
          .of(Yup.object().shape(getOptionShape(t)))
          .when(
            [
              FieldNames.recurringRuleEnabled,
              FieldNames.recurringRuleRuleType,
              FieldNames.repeatMonthlyType,
            ],
            (deps, schema) =>
              _get(deps, 0) &&
              _get(deps, [1, 'value']) === RecurringRuleRuleTypeEnum.Monthly &&
              _get(deps, [2, 'value']) === RecurringRuleSchemaOptionEnum.Each
                ? schema
                    .min(1, t('required-field-error'))
                    .required(t('required-field-error'))
                : schema.min(0),
          ),
        isVisibleFn: (values: Values) =>
          _get(values, `${FieldNames.recurringRuleRuleType}.value`) ===
            RecurringRuleRuleTypeEnum.Monthly &&
          _get(values, `${FieldNames.repeatMonthlyType}.value`) ===
            RecurringRuleSchemaOptionEnum.Each,
        step,
        hideErrorsSpace: true,
        message: {
          color: SupportiveMessageColor.Yellow,
          getTextFn: (values: Values) => {
            const daysOptions = _get(
              values,
              FieldNames.repeatMonthlyEachDayOfMonth,
              [],
            );
            if (
              Array.isArray(daysOptions) &&
              daysOptions.some(({ value }) => value >= 29)
            ) {
              return t('last-month-days-warning');
            }
            return undefined;
          },
        },
      },
      // Monthly: On the
      {
        name: FieldNames.repeatMonthlyRepeatOnOptions,
        step,
        isVisibleFn: (values: Values) =>
          _get(values, `${FieldNames.recurringRuleRuleType}.value`) ===
            RecurringRuleRuleTypeEnum.Monthly &&
          _get(values, `${FieldNames.repeatMonthlyType}.value`) ===
            RecurringRuleSchemaOptionEnum.OnThe,
        subFieldsEntity: 'option',
        subFieldsMinLength: 1,
        subFieldsMaxLength: 2,
        subFields: [
          {
            name: FieldNames.repeatMonthlyRepeatOnOption1,
            element: Select,
            componentProps: {
              options: recurringRuleDayOfMonthOptions,
              isClearable: false,
            },
            formItemClassName: formStyles.formColumn,
            step,
            hideErrorsSpace: true,
          },
          {
            name: FieldNames.repeatMonthlyRepeatOnOption2,
            element: Select,
            componentProps: {
              options: recurringRuleDayOfMonthOptionDayOfWeekOptions,
              isClearable: false,
            },
            formItemClassName: formStyles.formColumn,
            step,
            hideErrorsSpace: true,
          },
        ],
        fieldComponents: {
          FieldArrayAddItemComponent: OptionsAddItemComponent,
          FieldArrayEmptyStateComponent: OptionsAddItemComponent,
          FieldArrayItemWrapperComponent: OptionsFieldOption,
        },
      },
      // Yearly:
      {
        name: FieldNames.repeatYearlyType,
        element: RadioButtonGroup,
        componentProps: {
          RadioButtonComponent: RadioButtonAsButton,
          radioButtonComponentProps: {
            fullWidth: true,
            buttonSize: ButtonSizes.small,
          },
          wrapView: RadioButtonWrapViews.row,
          options: repeatYearlyUnitOptions,
        },
        isVisibleFn: (values: Values) =>
          _get(values, `${FieldNames.recurringRuleRuleType}.value`) ===
          RecurringRuleRuleTypeEnum.Yearly,
        fieldChangeCallBack: ({
          type,
          setValue,
          name,
        }: FieldChangeCallBackArgs) => {
          if (type === 'change' && name === FieldNames.repeatYearlyType) {
            setValue(FieldNames.repeatYearlyEachDayOfMonth, [], {
              shouldValidate: true,
            });
            setValue(
              FieldNames.repeatYearlyRepeatOnOptions,
              [
                {
                  [FieldNames.repeatYearlyRepeatOnOption1]:
                    recurringRuleDayOfMonthOptions[0],
                  [FieldNames.repeatYearlyRepeatOnOption2]:
                    recurringRuleDayOfMonthOptionDayOfWeekOptions[0],
                },
              ],
              { shouldValidate: true },
            );
            setValue(FieldNames.recurringRuleExcludedDayOfWeek, [], {
              shouldValidate: true,
            });
          }
        },
        step,
      },
      // Yearly: Each
      {
        name: FieldNames.repeatYearlyEachMonthOfYear,
        element: CheckBoxGroup,
        componentProps: {
          CheckBoxComponent: CheckBoxAsCalendarMonth,
          wrapView: CheckBoxWrapViews.monthList,
          options: monthOptions,
        } as unknown as CheckBoxGroupProps,
        validation: Yup.array()
          .of(Yup.object().shape(getOptionShape(t)))
          .when(
            [
              FieldNames.recurringRuleEnabled,
              FieldNames.recurringRuleRuleType,
              FieldNames.repeatYearlyType,
            ],
            (deps, schema) =>
              _get(deps, 0) &&
              _get(deps, [1, 'value']) === RecurringRuleRuleTypeEnum.Yearly &&
              _get(deps, [2, 'value']) === RecurringRuleSchemaOptionEnum.Each
                ? schema
                    .min(1, t('required-field-error'))
                    .required(t('required-field-error'))
                : schema.min(0),
          ),
        isOptionalFn: () => false,
        isVisibleFn: (values: Values) =>
          _get(values, `${FieldNames.recurringRuleRuleType}.value`) ===
            RecurringRuleRuleTypeEnum.Yearly &&
          _get(values, `${FieldNames.repeatYearlyType}.value`) ===
            RecurringRuleSchemaOptionEnum.Each,
        step,
      },
      {
        name: FieldNames.repeatYearlyEachDayOfMonth,
        element: CheckBoxGroup,
        label: `${t('on-the-day')}:`,
        componentProps: {
          CheckBoxComponent: CheckBoxAsCalendarDay,
          wrapView: CheckBoxWrapViews.calendar,
          options: repeatMonthDaysOptions,
        } as unknown as CheckBoxGroupProps,
        validation: Yup.array()
          .of(Yup.object().shape(getOptionShape(t)))
          .when(
            [
              FieldNames.recurringRuleEnabled,
              FieldNames.recurringRuleRuleType,
              FieldNames.repeatYearlyType,
            ],
            (deps, schema) =>
              _get(deps, 0) &&
              _get(deps, [1, 'value']) === RecurringRuleRuleTypeEnum.Yearly &&
              _get(deps, [2, 'value']) === RecurringRuleSchemaOptionEnum.Each
                ? schema
                    .min(1, t('required-field-error'))
                    .required(t('required-field-error'))
                : schema.min(0),
          ),
        isOptionalFn: () => false,
        isVisibleFn: (values: Values) =>
          _get(values, `${FieldNames.recurringRuleRuleType}.value`) ===
            RecurringRuleRuleTypeEnum.Yearly &&
          _get(values, `${FieldNames.repeatYearlyType}.value`) ===
            RecurringRuleSchemaOptionEnum.Each,
        step,
        hideErrorsSpace: true,
        message: {
          color: SupportiveMessageColor.Yellow,
          getTextFn: (values: Values) => {
            const daysOptions = _get(
              values,
              FieldNames.repeatYearlyEachDayOfMonth,
              [],
            );
            if (
              Array.isArray(daysOptions) &&
              daysOptions.some(({ value }) => value >= 29)
            ) {
              return t('last-month-days-warning');
            }
            return undefined;
          },
        },
      },
      // Yearly: On the
      {
        name: FieldNames.repeatYearlyRepeatOnOptions,
        step,
        isVisibleFn: (values: Values) =>
          _get(values, `${FieldNames.recurringRuleRuleType}.value`) ===
            RecurringRuleRuleTypeEnum.Yearly &&
          _get(values, `${FieldNames.repeatYearlyType}.value`) ===
            RecurringRuleSchemaOptionEnum.OnThe,
        subFieldsEntity: 'option',
        subFieldsMinLength: 1,
        subFieldsMaxLength: 2,
        subFields: [
          {
            name: FieldNames.repeatYearlyRepeatOnOption1,
            element: Select,
            componentProps: {
              options: recurringRuleDayOfMonthOptions,
              isClearable: false,
            },
            formItemClassName: formStyles.formColumn,
            step,
            hideErrorsSpace: true,
          },
          {
            name: FieldNames.repeatYearlyRepeatOnOption2,
            element: Select,
            componentProps: {
              options: recurringRuleDayOfMonthOptionDayOfWeekOptions,
              isClearable: false,
            },
            formItemClassName: formStyles.formColumn,
            step,
            hideErrorsSpace: true,
          },
        ],
        fieldComponents: {
          FieldArrayAddItemComponent: OptionsAddItemComponent,
          FieldArrayEmptyStateComponent: OptionsAddItemComponent,
          FieldArrayItemWrapperComponent: OptionsFieldOption,
        },
      },
      {
        name: FieldNames.repeatYearlyRepeatOnMonthOfYear,
        element: CheckBoxGroup,
        label: t('of'),
        componentProps: {
          CheckBoxComponent: CheckBoxAsCalendarMonth,
          wrapView: CheckBoxWrapViews.monthList,
          options: monthOptions,
        } as unknown as CheckBoxGroupProps,
        validation: Yup.array()
          .of(Yup.object().shape(getOptionShape(t)))
          .when(
            [
              FieldNames.recurringRuleEnabled,
              FieldNames.recurringRuleRuleType,
              FieldNames.repeatYearlyType,
            ],
            (deps, schema) =>
              _get(deps, 0) &&
              _get(deps, [1, 'value']) === RecurringRuleRuleTypeEnum.Yearly &&
              _get(deps, [2, 'value']) === RecurringRuleSchemaOptionEnum.OnThe
                ? schema
                    .min(1, t('required-field-error'))
                    .required(t('required-field-error'))
                : schema.min(0),
          ),
        isOptionalFn: () => false,
        isVisibleFn: (values: Values) =>
          _get(values, `${FieldNames.recurringRuleRuleType}.value`) ===
            RecurringRuleRuleTypeEnum.Yearly &&
          _get(values, `${FieldNames.repeatYearlyType}.value`) ===
            RecurringRuleSchemaOptionEnum.OnThe,
        step,
        formItemClassName: classNames(
          formStyles.formColumnFullWidth,
          formStyles.blockStart16,
        ),
      },
      // Settings
      {
        name: 'line-2',
        customRender: () => <hr className={formStyles.hr} />,
        step,
        hideErrorsSpace: true,
      },
      {
        name: uuidv4(),
        customRender: () => (
          <Typography
            variant="body"
            strong
            key={uuidv4()}
            className={formStyles.fieldTitle}
          >
            {t('countdown-start-point')}
          </Typography>
        ),
        step,
        hideErrorsSpace: true,
      },
      {
        name: FieldNames.recurringRuleCountdownType,
        element: RadioButtonGroup,
        componentProps: {
          RadioButtonComponent: RadioButtonAsButton,
          radioButtonComponentProps: {
            fullWidth: true,
            buttonSize: ButtonSizes.small,
          },
          wrapView: CheckBoxWrapViews.row,
          options: countdownTypeOptions,
        } as unknown as CheckBoxGroupProps,
        step,
        getDescription: (values: Values) =>
          _get(values, [FieldNames.recurringRuleCountdownType, 'value']) ===
          RecurringRuleCountdownTypeEnum.Completion
            ? t('countdown-start-point-completion')
            : t('countdown-start-point-deadline'),
      },
      {
        name: uuidv4(),
        customRender: () => (
          <div className={formStyles.fieldTitle}>
            <Typography variant="body" strong key={uuidv4()}>
              {t('excluding-days-of-the-week')}
            </Typography>
            <div className={formStyles.fieldTitleIcon}>
              <Tooltip body={t('excluding-days-of-the-week-desc')}>
                <MaterialIcon icon="info" symbolsOutlined size="md-16" />
              </Tooltip>
            </div>
          </div>
        ),
        step,
        hideErrorsSpace: true,
        isVisibleFn: isVisibleExecutingDays,
      },
      {
        name: FieldNames.recurringRuleExcludedDayOfWeek,
        element: CheckBoxGroup,
        componentProps: {
          CheckBoxComponent: (props: any) => (
            <CheckBoxAsButton {...props} buttonSize={ButtonSizes.small} />
          ),
          radioButtonComponentProps: {
            fullWidth: true,
            buttonSize: ButtonSizes.small,
          },
          wrapView: RadioButtonWrapViews.row,
          options: repeatDayOptions,
        },
        validation: Yup.array()
          .of(Yup.object().shape(getOptionShape(t)))
          .max(6, t('frequency-repeat-excluded-days-error')),
        step,
        isVisibleFn: isVisibleExecutingDays,
      },
    ],
    step,
  };
}

function getEndRepeatFields({ step, t }: ScheduleFieldsArgs): FieldItem {
  const currentDay = endOfDayFn(new Date());
  return {
    name: FieldNames.endRepeat,
    element: ({ children, ...props }: FrequencyFormFieldsWrapperProps) => (
      <FrequencyFormFieldsWrapper
        {...props}
        isVisibleFn={(values: Values) =>
          !!_get(values, FieldNames.recurringRuleEnabled)
        }
      >
        {children}
      </FrequencyFormFieldsWrapper>
    ),
    componentProps: {
      title: t('end-repeat'),
      name: FieldNames.endRepeat,
    },
    groupFields: [
      {
        name: FieldNames.endRepeatEnabled,
        element: CheckBoxSingleBool,
        componentProps: {
          CheckBoxComponent: CheckBoxAsToggle,
          options: [{ label: 'checked', value: 'checked' }],
        },
        isVisibleFn: () => false,
        fieldChangeCallBack: ({
          type,
          setValue,
          name,
        }: FieldChangeCallBackArgs) => {
          if (type === 'change' && name === FieldNames.endRepeatEnabled) {
            setValue(FieldNames.endRepeatType, endRepeatOptions[0], {
              shouldValidate: true,
            });
            setValue(FieldNames.endRepeatDate, '', { shouldValidate: false });
            setValue(FieldNames.endRepeatAfterOccurrences, 1, {
              shouldValidate: true,
            });
          }
        },
        step,
      },
      {
        name: FieldNames.endRepeatType,
        element: RadioButtonGroup,
        componentProps: {
          RadioButtonComponent: RadioButtonAsButton,
          radioButtonComponentProps: {
            fullWidth: true,
            buttonSize: ButtonSizes.small,
          },
          wrapView: RadioButtonWrapViews.row,
          options: endRepeatOptions,
        },
        fieldChangeCallBack: ({
          type,
          setValue,
          name,
          values,
        }: FieldChangeCallBackArgs) => {
          if (type === 'change' && name === FieldNames.endRepeatType) {
            if (_isEmpty(_get(values, FieldNames.endRepeatDate))) {
              setValue(FieldNames.endRepeatDate, '', {
                shouldValidate: false,
              });
            }
            if (_isEmpty(_get(values, FieldNames.endRepeatAfterOccurrences))) {
              setValue(FieldNames.endRepeatAfterOccurrences, 1, {
                shouldValidate: true,
              });
            }
          }
        },
        step,
      },
      {
        name: FieldNames.endRepeatDate,
        label: t('select-date'),
        element: DatePicker,
        componentProps: {
          minDate: currentDay,
          selectEndOfTheDay: true,
          type: InputTypes.date,
        },
        getComponentProps: (values: Values) => {
          const deadlineAt = values[FieldNames.deadlineAt] as string;
          if (!deadlineAt) return undefined;
          const minDate = subDaysFn(revertTransformDate(deadlineAt), 0);
          return { minDate };
        },
        validation: Yup.string()
          .trim()
          .test(
            'min-date-match',
            t('frequency-end-repeat-min-date-error'),
            function matchTest(this, value?: string): boolean {
              const deadlineAt = this.parent[FieldNames.deadlineAt];
              if (!deadlineAt || !value) return true;
              return new Date(deadlineAt) < new Date(value);
            },
          )
          .when(
            [FieldNames.endRepeatEnabled, FieldNames.endRepeatType],
            (deps, schema) =>
              _get(deps, 0) && _get(deps, [1, 'value']) === EndRepeatUnit.date
                ? schema.required(t('required-field-error'))
                : schema.nullable(),
          ),
        isOptionalFn: () => false,
        isVisibleFn: (values: Values) =>
          _get(values, `${FieldNames.endRepeatType}.value`) ===
          EndRepeatUnit.date,
        step,
        hideErrorsSpace: true,
      },
      {
        name: FieldNames.endRepeatAfterOccurrences,
        element: Input,
        getLeftLabel: () => t('after'),
        getRightLabel: () => t('completions-label'),
        componentProps: {
          ...DEFAULT_NUMBER_INPUT_PROPS,
          hasErrorAsTooltip: true,
        },
        validation: Yup.string().when(
          [FieldNames.endRepeatEnabled, FieldNames.endRepeatType],
          (deps, schema) =>
            _get(deps, 0) &&
            _get(deps, [1, 'value']) === EndRepeatUnit.completions
              ? schema
                  .test(
                    'is-number',
                    t('number-field-error'),
                    (value) => !_isNaN(value),
                  )
                  .test('is-min', t('number-field-error-min-1'), (value) => {
                    const number = value ? parseFloat(value) : 0;
                    return number >= 1;
                  })
                  .test(
                    'is-max',
                    t('number-field-error-max-number'),
                    (value) => {
                      const number = value ? parseFloat(value) : 0;
                      return number <= MAX_NUMBER_VALUE;
                    },
                  )
                  .required(t('required-field-error'))
              : schema.nullable(),
        ),
        isVisibleFn: (values: Values) =>
          _get(values, `${FieldNames.endRepeatType}.value`) ===
          EndRepeatUnit.completions,
        step,
        hideErrors: true,
        hideErrorsSpace: true,
        formItemClassName: formStyles.formColumnAuto,
        formItemElementWrapClassName: styles.inputNumber,
      },
    ],
    step,
  };
}

export default function getScheduleFields(
  args: ScheduleFieldsArgs,
): FieldItem[] {
  const { step, t } = args;
  const isSingleForm = step === undefined || step === Steps.step1;
  const prevDay = subDaysFn(endOfDayFn(new Date()), 1);
  return [
    ...(isSingleForm
      ? [
          {
            name: FieldNames.deadlineAt,
            label: t('on-date'),
            element: DatePicker,
            componentProps: {
              minDate: prevDay,
              selectEndOfTheDay: true,
              type: InputTypes.date,
            },
            validation: Yup.string().trim().required(t('required-field-error')),
            step,
          },
        ]
      : [
          {
            name: 'schedule-task',
            customRender: () => (
              <Typography variant="h3" key="schedule-task">
                {t('schedule-task')}
              </Typography>
            ),
            step,
          },
          {
            name: FieldNames.deadlineAt,
            label: t('on-date'),
            element: DatePicker,
            componentProps: {
              minDate: prevDay,
              selectEndOfTheDay: true,
              type: InputTypes.date,
            },
            validation: Yup.string().trim().required(t('required-field-error')),
            formItemClassName: formStyles.formColumn,
            step,
          },
        ]),
    getEarlyCompletionFields(args),
    getRecurringRuleFields(args),
    getEndRepeatFields(args),
    {
      name: 'schedule-message',
      customRender: FrequencyFormMessage,
      step,
      formItemClassName: formStyles.formColumnBlockStart,
      isUseWatch: true,
    },
  ];
}
