import {
  FilterGroupingEnum,
  Intervention,
  InterventionFilterInputObject,
  InterventionScopeNameEnum,
  InterventionsQueryVariables,
  useInterventionsIdsLazyQuery,
  useInterventionsQuery,
} from 'graphql-common';
import _get from 'lodash/get';
import { GetUrlParams } from '@lib/enums/urls';
import createSearchFilters, {
  FilterSchema,
} from '@lib/utils/createSearchFilters';
import { ApolloError } from '@apollo/client/errors';
import { FetchIdsResponse } from '@lib/utils/getAllTableItemsIds';
import { ListQueryHookProps } from './types';

export type UseAssetInterventionsQueryHookResult = {
  collection: Intervention[];
  error: ApolloError | undefined;
  fetchIds: () => Promise<FetchIdsResponse<unknown>>;
  firstLoading: boolean;
  loading: boolean;
  refetch: () => Promise<unknown>;
  totalCount: number;
  totalPages: number;
  counters: {
    closed: number;
    opened: number;
  };
};

type Args = ListQueryHookProps & {
  scopeName: InterventionScopeNameEnum;
  assetId?: string;
  taskId?: string;
  includeFullSearchFilters?: boolean;
};

export default function useInterventionsQueryHook({
  fetchPolicy = 'cache-and-network',
  paginationParams,
  orderingParams,
  searchQuery,
  skip,
  usePrevious = true,
  scopeName,
  assetId,
  taskId,
  includeFullSearchFilters,
}: Args): UseAssetInterventionsQueryHookResult {
  const page = _get(paginationParams, GetUrlParams.Page);
  const perPage = _get(paginationParams, GetUrlParams.PerPage);
  const orderingField = _get(orderingParams, GetUrlParams.OrderingField);
  const orderingDirection = _get(
    orderingParams,
    GetUrlParams.OrderingDirection,
  );
  const queryVariables: InterventionsQueryVariables = {
    page: Number(page),
    limit: Number(perPage),
    scopeName,
  };
  const filters: InterventionFilterInputObject[] = [];
  if (searchQuery) {
    const schema: FilterSchema[] = [
      { fieldName: 'name' },
      { fieldName: 'code' },
      { fieldName: 'priority', predicateType: 'eq' },
    ];
    if (includeFullSearchFilters) {
      schema.push(
        { fieldName: 'assetName' },
        { fieldName: 'assetCode' },
        { fieldName: 'siteCode' },
        { fieldName: 'siteName' },
        { fieldName: 'siteAreaName' },
        { fieldName: 'siteAreaCode' },
      );
    } else {
      schema.push({ fieldName: 'status', predicateType: 'eq' });
    }
    filters.push(...createSearchFilters(searchQuery, schema));
  }
  if (assetId) {
    filters.push({
      assetId: {
        grouping: FilterGroupingEnum.And,
        predicate: {
          eq: assetId,
        },
        filterGroup: 'assetFilter',
      },
    });
  }
  if (taskId) {
    filters.push({
      taskGroupKey: {
        grouping: FilterGroupingEnum.And,
        predicate: {
          eq: taskId,
        },
        filterGroup: 'taskFilter',
      },
    });
  }
  if (filters.length) {
    queryVariables.filters = filters;
  }
  if (orderingField && orderingDirection) {
    queryVariables.sorts = {
      [orderingField]: orderingDirection,
    };
  }
  const query = useInterventionsQuery({
    fetchPolicy,
    variables: queryVariables,
    skip,
  });
  const [fetchIds] = useInterventionsIdsLazyQuery({
    fetchPolicy: 'no-cache',
    variables: {
      scopeName: queryVariables.scopeName,
      onlyIds: true,
      filters: queryVariables.filters,
    },
  });
  const { loading, data, previousData, refetch, error } = query;
  const queryData = usePrevious ? data || previousData : data;
  const firstLoading = loading && previousData === undefined;
  const collection = _get(queryData, 'data.collection', []) as Intervention[];
  const metadata = _get(queryData, 'data.metadata');
  const totalCount = _get(metadata, 'totalCount', 0);
  const totalPages = _get(metadata, 'totalPages', 1);
  const closedCounter = _get(
    queryData,
    ['closedCounter', 'metadata', 'totalCount'],
    0,
  );
  const openedCounter = _get(
    queryData,
    ['openedCounter', 'metadata', 'totalCount'],
    0,
  );
  const counters = {
    closed: closedCounter,
    opened: openedCounter,
  };

  return {
    fetchIds,
    refetch,
    collection,
    error,
    firstLoading,
    loading,
    totalPages,
    totalCount,
    counters,
  };
}
