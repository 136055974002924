import React from 'react';
import { useTranslation } from 'react-i18next';
import { Documentation } from 'graphql-common';
import Button, { ButtonTypes } from '@lib/components/Button/Button';
import Table from '@lib/components/Table/Table';
import CircleLoader from '@lib/components/CircleLoader/CircleLoader';
import PageBody from '@lib/layouts/DashboardLayout/PageBody/PageBody';
import ListEmptyState from '@lib/components/ListEmptyState/ListEmptyState';
import Trans from '@lib/components/Trans/Trans';
import ErrorPage from '@lib/components/ErrorPage/ErrorPage';
import getStatusFromError from '@lib/utils/getStatusFromError';
import { UseBulkSelectResult } from '@lib/hooks/useTableItemsBulkSelect';
import WhiteBox from '@lib/components/WhiteBox/WhiteBox';
import { materialSymbolsOutlined } from '@lib/enums/common';
import ConfirmationModal from '@lib/components/ConfirmationModal/ConfirmationModal';
import DashboardTitleHeader from '@lib/layouts/DashboardLayout/DashboardTitleHeader/DashboardTitleHeader';
import { UseModalManagementResult } from 'routes/Assets/Asset/utils/useModalManagement';
import { UseActionsResult } from 'routes/Assets/Asset/utils/useActions';
import { UseDocumentationActionsResult } from 'routes/Assets/Asset/utils/useDocumentationActions';
import Search from '@lib/components/Search/Search';
import useSearchParam from '@lib/hooks/useSearchParam';
import FileViewerModal from '@lib/components/FileViewerModal/FileViewerModal';
import ViewOnlyChip from '@lib/components/ViewOnlyChip/ViewOnlyChip';
import { IconName } from '@lib/components/Modal/enums';
import getTableActionsForSelected from './utils/getTableActionsForSelected';
import getTableActionsMenu from './utils/getTableActionsMenu';
import getTableColumns from './utils/getTableColumns';

interface Props {
  menuBar: JSX.Element;
  useActionsResult: UseActionsResult;
  useBulkSelectResult?: UseBulkSelectResult<Documentation>;
  useDocumentationActionsResult: UseDocumentationActionsResult;
  useModalManagementResult: UseModalManagementResult;
  isViewOnly: boolean;
}

export default function AssetDocumentations(props: Props) {
  const {
    menuBar,
    useActionsResult,
    useBulkSelectResult,
    useDocumentationActionsResult,
    useModalManagementResult,
    isViewOnly,
  } = props;
  const { t } = useTranslation();
  const [searchQuery] = useSearchParam();

  const { assetQueryHook } = useActionsResult;
  const {
    documentationsQueryHook,
    onCloseModal,
    onConfirmedDocumentationDestroy,
    onDownload,
    onTagSelect,
    openDocumentForm,
    openDocumentationBulkDestroyModal,
    openDocumentationDestroyModal,
  } = useDocumentationActionsResult;

  const name = assetQueryHook.data?.name || '';

  const tableColumns = getTableColumns({
    onOpen: useModalManagementResult.openDocumentationPreviewModal,
    onTagSelect,
    t,
  });
  const tableActionsMenu = getTableActionsMenu({
    onDownload,
    onEdit: openDocumentForm,
    onDelete: openDocumentationDestroyModal,
    isViewOnly,
    t,
  });
  const tableActionsForSelected = getTableActionsForSelected({
    onBulkDelete: openDocumentationBulkDestroyModal,
    selectedItemsCount: useBulkSelectResult?.getSelectedItemsCount(
      documentationsQueryHook.totalCount,
    ),
    isViewOnly,
    t,
  });

  const emptyState = (
    <ListEmptyState
      title={t('no-asset-documentation-yet')}
      text={<Trans i18nKey="no-asset-documentation-text" />}
      actions={
        isViewOnly
          ? []
          : [
              <Button
                key="upload"
                buttonText={t('upload-document')}
                buttonType={ButtonTypes.primaryFilled}
                leftIcon="upload"
                onClick={() => openDocumentForm()}
                leftIconClassName={materialSymbolsOutlined}
              />,
            ]
      }
    />
  );

  if (documentationsQueryHook.error) {
    return (
      <ErrorPage
        status={getStatusFromError(documentationsQueryHook.error)}
        description={documentationsQueryHook.error.message}
      />
    );
  }

  return (
    <>
      <PageBody withoutHeader>
        {menuBar}
        {documentationsQueryHook.firstLoading ? (
          <CircleLoader />
        ) : (
          <WhiteBox>
            <DashboardTitleHeader
              title={name}
              actions={
                isViewOnly ? (
                  <ViewOnlyChip key="view" />
                ) : (
                  <Button
                    key="add"
                    buttonText={t('upload-document')}
                    buttonType={ButtonTypes.primaryFilled}
                    onClick={() => openDocumentForm()}
                    leftIcon="upload"
                    leftIconClassName={materialSymbolsOutlined}
                  />
                )
              }
            />
            {(!!documentationsQueryHook.collection?.length || searchQuery) && (
              <Search
                placeholder={t('asset-documentations-search-placeholder')}
              />
            )}
            <Table
              actionsForSelected={tableActionsForSelected}
              actionsMenu={tableActionsMenu}
              columns={tableColumns}
              data={documentationsQueryHook.collection}
              emptyState={emptyState}
              id="documentations"
              loading={documentationsQueryHook.loading}
              totalEntries={documentationsQueryHook.totalCount}
              useBulkSelectResult={useBulkSelectResult}
            />
          </WhiteBox>
        )}
      </PageBody>
      <ConfirmationModal
        confirmButtonLabel={t('yes-delete')}
        description={<Trans i18nKey="asset-documentation-delete-text" />}
        icon={IconName.Delete}
        isOpen={useModalManagementResult.isDocumentationDestroyModalOpened}
        onClose={onCloseModal}
        onConfirm={onConfirmedDocumentationDestroy}
        title={t('are-you-sure')}
      />
      <FileViewerModal
        attachment={
          useModalManagementResult.isDocumentationPreviewModalOpened
            ?.attachmentAttached
        }
        attachmentName={
          useModalManagementResult.isDocumentationPreviewModalOpened?.name
        }
        onClose={useModalManagementResult.closeAllModals}
        isOpen={
          !!useModalManagementResult.isDocumentationPreviewModalOpened
            ?.attachmentAttached?.id
        }
      />
    </>
  );
}
