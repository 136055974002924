import { TFunction } from 'i18next';
import { APP_URLS } from 'constants/urls';

type Args = {
  id?: string;
  t: TFunction<'translation', undefined>;
  versionId?: string;
  visibleLinkedTasksCount: number;
};

export default function getTabs({
  id = '',
  t,
  versionId = '',
  visibleLinkedTasksCount,
}: Args) {
  return [
    {
      label: t('form-details'),
      value: APP_URLS.dashboard.formTemplates.viewVersion.getDynamicPath({
        pathParts: { id, versionId },
      }),
    },
    {
      label: t('linked-tasks'),
      value: APP_URLS.dashboard.formTemplates.viewLinkedTasks.getDynamicPath({
        pathParts: { id, versionId },
      }),
      counter: visibleLinkedTasksCount,
    },
  ];
}
