import _get from 'lodash/get';
import { QrCodeFileLayoutEnum } from 'graphql-common';
import { Values } from '@lib/interfaces/form';
import {
  FieldNames as GetQrCodeFormFieldNames,
  FormType,
} from 'components/GetQrCodeForm/types';

export default function getPreparedQrCodeDataFromValues(
  values: Values,
  ids: string[],
) {
  const formType = _get(
    values,
    `${GetQrCodeFormFieldNames.formType}.value`,
  ) as string;
  const fileFormat = _get(
    values,
    `${GetQrCodeFormFieldNames.fileFormat}.value`,
  ) as string;
  const fileFormatArray = fileFormat.split('.');
  const pageSize = _get(values, `${GetQrCodeFormFieldNames.pageSize}.value`);
  const userId = _get(values, `${GetQrCodeFormFieldNames.userId}.value`);
  const message = _get(values, GetQrCodeFormFieldNames.message);
  return {
    fileFormat: fileFormatArray[0],
    fileLayout: fileFormatArray[1] || QrCodeFileLayoutEnum.SingleFile,
    ids,
    message: formType === FormType.email ? message : undefined,
    pageSize,
    userId: formType === FormType.email ? userId : undefined,
  };
}
