import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import BreadCrumbs from '@lib/components/BreadCrumbs/BreadCrumbs';
import ConfirmationModal from '@lib/components/ConfirmationModal/ConfirmationModal';
import Trans from '@lib/components/Trans/Trans';
import ErrorPage from '@lib/components/ErrorPage/ErrorPage';
import getStatusFromError from '@lib/utils/getStatusFromError';
import FileViewerModal from '@lib/components/FileViewerModal/FileViewerModal';
import InterventionAssigneesModal from 'components/InterventionAssigneesModal/InterventionAssigneesModal';
import Modal from '@lib/components/Modal/Modal';
import CategoryForm from 'components/InterventionCategory/CategoryForm';
import { FormRef } from '@lib/components/ReactHookForm/types';
import useOutletContext, { ContextProps } from 'hooks/useOutletContext';
import { ComponentEnum, LevelEnum } from 'graphql-common';
import { IconName } from '@lib/components/Modal/enums';
import InterventionFormPage from './InterventionFormPage/InterventionFormPage';
import InterventionViewPage from './InterventionViewPage/InterventionViewPage';
import useInterventionActions from './utils/useInterventionActions';
import useModalManagement from './utils/useModalManagement';
import { InterventionViewMode } from './enums';

type Props = {
  viewMode: InterventionViewMode;
};

export default function InterventionContainer({ viewMode }: Props) {
  const { t } = useTranslation();
  const formRef = useRef<FormRef>();

  const { getUserAccessByComponent }: ContextProps = useOutletContext();
  const isViewOnly =
    getUserAccessByComponent(ComponentEnum.InterventionsManagement) ===
    LevelEnum.Read;
  const isCategoriesViewOnly =
    getUserAccessByComponent(ComponentEnum.InterventionCategoriesManagement) ===
    LevelEnum.Read;
  const useModalManagementResult = useModalManagement();
  const useInterventionActionsResult = useInterventionActions({
    formRef,
    viewMode,
    useModalManagementResult,
  });
  const {
    breadCrumbsData,
    interventionQueryHookResult,
    onConfirmedInterventionDestroy,
    onConfirmedInterventionClose,
    onConfirmedInterventionActionDestroy,
    onConfirmedInterventionActionClose,
    onViewChange,
    categoryCreateMutationError,
    categoryCreateMutationLoading,
    onAssetCategoryCreate,
  } = useInterventionActionsResult;
  const {
    closeAllModals,
    isAttachmentPreviewModalOpened,
    isInterventionActionAssigneesModalOpened,
    isInterventionAssigneesModalOpened,
    isInterventionCloseModalOpened,
    isInterventionDestroyModalOpened,
    isInterventionActionDestroyModalOpened,
    isInterventionActionCloseModalOpened,
    isCategoryFormModalOpened,
  } = useModalManagementResult;

  const isForm =
    viewMode === InterventionViewMode.Add ||
    viewMode === InterventionViewMode.Edit;
  const isView =
    viewMode === InterventionViewMode.View ||
    viewMode === InterventionViewMode.ViewActions;
  const canUpdate = interventionQueryHookResult?.data?.canUpdate;

  useEffect(() => {
    if (
      viewMode === InterventionViewMode.Edit &&
      !!canUpdate &&
      !canUpdate.value
    ) {
      onViewChange(InterventionViewMode.View);
    }
  }, [canUpdate, onViewChange, viewMode]);

  if (interventionQueryHookResult.error) {
    return (
      <ErrorPage
        status={getStatusFromError(interventionQueryHookResult.error)}
        description={interventionQueryHookResult.error?.message}
      />
    );
  }

  return (
    <>
      <BreadCrumbs data={breadCrumbsData} />
      {isForm && (
        <InterventionFormPage
          formRef={formRef}
          viewMode={viewMode}
          useInterventionActionsResult={useInterventionActionsResult}
          useModalManagementResult={useModalManagementResult}
          isCategoriesViewOnly={isCategoriesViewOnly}
        />
      )}
      {isView && (
        <InterventionViewPage
          viewMode={viewMode}
          useInterventionActionsResult={useInterventionActionsResult}
          useModalManagementResult={useModalManagementResult}
          isViewOnly={isViewOnly}
        />
      )}
      <Modal
        isOpen={isCategoryFormModalOpened}
        title={t('create-new-intervention-category')}
        onClose={closeAllModals}
      >
        <CategoryForm
          defaultValues={{}}
          graphQLErrors={categoryCreateMutationError?.graphQLErrors}
          loading={categoryCreateMutationLoading}
          onCancel={closeAllModals}
          onSubmit={onAssetCategoryCreate}
        />
      </Modal>
      <ConfirmationModal
        confirmButtonLabel={t('yes-delete')}
        description={<Trans i18nKey="intervention-delete-text" />}
        icon={IconName.Delete}
        isOpen={isInterventionDestroyModalOpened}
        onClose={closeAllModals}
        onConfirm={onConfirmedInterventionDestroy}
        title={t('are-you-sure')}
      />
      <ConfirmationModal
        confirmButtonLabel={t('yes-delete')}
        description={<Trans i18nKey="intervention-action-delete-text" />}
        icon={IconName.Delete}
        isOpen={!!isInterventionActionDestroyModalOpened?.id}
        onClose={closeAllModals}
        onConfirm={onConfirmedInterventionActionDestroy}
        title={t('are-you-sure')}
      />
      <ConfirmationModal
        confirmButtonLabel={t('yes-close')}
        description={<Trans i18nKey="intervention-close-text" />}
        icon={IconName.Warning}
        isOpen={isInterventionCloseModalOpened}
        onClose={closeAllModals}
        onConfirm={onConfirmedInterventionClose}
        title={t('are-you-sure')}
      />
      <ConfirmationModal
        confirmButtonLabel={t('yes-close')}
        description={<Trans i18nKey="intervention-action-close-text" />}
        icon={IconName.Warning}
        isOpen={!!isInterventionActionCloseModalOpened?.id}
        onClose={closeAllModals}
        onConfirm={onConfirmedInterventionActionClose}
        title={t('are-you-sure')}
      />
      <FileViewerModal
        attachment={isAttachmentPreviewModalOpened}
        attachmentName={isAttachmentPreviewModalOpened?.filename || ''}
        onClose={closeAllModals}
        isOpen={!!isAttachmentPreviewModalOpened?.id}
      />
      <InterventionAssigneesModal
        caption={t('all-assignees')}
        interventionId={
          isInterventionActionAssigneesModalOpened?.id
            ? undefined
            : useInterventionActionsResult.id
        }
        interventionActionId={isInterventionActionAssigneesModalOpened?.id}
        isOpen={
          isInterventionAssigneesModalOpened ||
          !!isInterventionActionAssigneesModalOpened?.id
        }
        onClose={closeAllModals}
      />
    </>
  );
}
