import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Values } from '@lib/interfaces/form';
import classNames from 'classnames';
import { FetchResult } from '@apollo/client';
import { GraphQLErrors } from '@apollo/client/errors';
import { AuthenticationPasswordsForgotMutation } from 'graphql-common';
import Typography from '@lib/components/Typography/Typography';
import Form from '@lib/components/ReactHookForm/FormContainer';
import Button, {
  ButtonSizes,
  ButtonTypes,
} from '@lib/components/Button/Button';
import Modal from '@lib/components/Modal/Modal';
import { materialSymbolsOutlined } from '@lib/enums/common';
import { IconName } from '@lib/components/Modal/enums';
import getPasswordFormFields from './utils/getPasswordFormFields';
import PasswordFormActions from './PasswordFormActions';
import { OnUpdatePassword } from './types';
import styles from './ProfileForm.module.scss';

interface Props {
  graphQLErrors?: GraphQLErrors;
  loading: boolean;
  onSendPasswordResetHandler: () => Promise<
    FetchResult<AuthenticationPasswordsForgotMutation>
  >;
  onSubmit: OnUpdatePassword;
  updateMutationReset: () => void;
}

const defaultValues = {
  passwordChallenge: '',
  password: '',
  passwordConfirmation: '',
};

function PasswordForm(props: Props) {
  const {
    graphQLErrors,
    onSendPasswordResetHandler,
    onSubmit,
    loading,
    updateMutationReset,
  } = props;
  const [isChangePasswordModalOpened, setChangePasswordModalOpened] =
    useState(false);
  const { t } = useTranslation();

  const openChangePasswordModal = () => setChangePasswordModalOpened(true);
  const closeChangePasswordModal = useCallback(() => {
    setChangePasswordModalOpened(false);
  }, []);

  const fields = getPasswordFormFields({
    t,
    onSendPasswordResetHandler,
    closeChangePasswordModal,
  });

  const onSubmitHandler = (values: Values) => {
    onSubmit(values).then(({ errors }) => {
      if (!errors) {
        closeChangePasswordModal();
      }
    });
  };

  useEffect(() => {
    if (!isChangePasswordModalOpened) {
      updateMutationReset();
    }
  }, [updateMutationReset, isChangePasswordModalOpened]);

  return (
    <>
      <div className={classNames(styles.wrapSecondary, styles.passwordRow)}>
        <Typography variant="h3">{t('security-and-password')}</Typography>
        <Button
          buttonText={t('change-password')}
          buttonType={ButtonTypes.primaryOutlined}
          onClick={openChangePasswordModal}
          leftIcon="edit"
          leftIconClassName={materialSymbolsOutlined}
          buttonSize={ButtonSizes.large}
        />
      </div>
      <Modal
        isOpen={isChangePasswordModalOpened}
        title={t('change-password')}
        icon={IconName.ChangePassword}
        onClose={closeChangePasswordModal}
      >
        <Form
          actionsComponent={PasswordFormActions}
          actionsComponentProps={{
            closeChangePasswordModal,
          }}
          defaultValues={defaultValues}
          fields={fields}
          formClassName={styles.form}
          graphQLErrors={graphQLErrors}
          loading={loading}
          onSubmit={onSubmitHandler}
        />
      </Modal>
    </>
  );
}

export default PasswordForm;
