import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { ComponentEnum, LevelEnum, User } from 'graphql-common';
import BreadCrumbs from '@lib/components/BreadCrumbs/BreadCrumbs';
import { Values } from '@lib/interfaces/form';
import { useTableItemsBulkSelect } from '@lib/hooks/useTableItemsBulkSelect';
import { getBreadCrumbsDataByKey } from 'utils/breadcrumbs';
import SiteUsers from 'components/SiteUsers/SiteUsers';
import useOutletContext, { ContextProps } from 'hooks/useOutletContext';
import useSiteUsersModalManagement from './utils/useSiteUsersModalManagement';
import useSiteUsersActions from './utils/useSiteUsersActions';

function SiteUsersContainer() {
  const { t } = useTranslation();
  const { id: siteId = '' } = useParams();
  const { pathname } = useLocation();

  const { getUserAccessByComponent }: ContextProps = useOutletContext();
  const isViewOnly =
    getUserAccessByComponent(ComponentEnum.UsersManagement) === LevelEnum.Read;
  const useBulkSelectResult = useTableItemsBulkSelect<User>();
  const useModalManagementResult = useSiteUsersModalManagement({
    useBulkSelectResult,
  });
  const { closeUserFormModal, isUserFormModalOpened } =
    useModalManagementResult;
  const {
    collection,
    currentUserId,
    openUserEditModal,
    onSubmitUserFormCallback,
    openUserViewPage,
    openTeamPage,
    previousData,
    selectedUserId,
    siteData,
    siteError,
    siteLoading,
    siteUsersLoading,
    totalCount,
  } = useSiteUsersActions({
    useModalManagementResult,
    useBulkSelectResult,
  });

  return (
    <>
      <BreadCrumbs
        data={getBreadCrumbsDataByKey({
          pathname,
          t,
          entity: siteData as Values,
        })}
      />
      <SiteUsers
        closeUserFormModal={closeUserFormModal}
        collection={collection}
        currentUserId={currentUserId}
        isUserFormModalOpened={isUserFormModalOpened}
        isViewOnly={isViewOnly}
        onSubmitUserFormCallback={onSubmitUserFormCallback}
        openTeamPage={openTeamPage}
        openUserEditModal={openUserEditModal}
        openUserViewPage={openUserViewPage}
        selectedUserId={selectedUserId}
        siteData={siteData}
        siteError={siteError}
        siteId={siteId}
        siteLoading={siteLoading}
        siteUsersCalled={previousData !== undefined}
        siteUsersLoading={siteUsersLoading}
        totalEntries={totalCount}
      />
    </>
  );
}

export default SiteUsersContainer;
