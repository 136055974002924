import { Values } from '@lib/interfaces/form';
import _get from 'lodash/get';
import { TeamCreateInputObject } from 'graphql-common';
import FieldNames from '@lib/enums/fieldNames/teamFieldNames';
import mergeAndUnlinkMultiSelectValues from '@lib/utils/mergeAndUnlinkMultiSelectValues';
import { SelectOption } from '@lib/components/Select/types';
import {
  AccessesFieldValues,
  getAccessesPreparedDataFromValues,
} from '@lib/utils/accessesManagement';

export default function getTeamPreparedDataFromValues(
  values: Values,
  prevValues: Values = {},
) {
  const assigneesValue = (_get(values, FieldNames.Users) ||
    []) as SelectOption[];
  const prevAssigneesValue = (_get(prevValues, FieldNames.Users) ||
    []) as SelectOption[];
  const members = assigneesValue.map(({ value }) => ({
    id: `${value}`,
  }));
  const prevMembers = prevAssigneesValue.map(({ value }) => ({
    id: `${value}`,
  }));
  const accesses = getAccessesPreparedDataFromValues(
    _get(values, FieldNames.Accesses, {}) as AccessesFieldValues,
    _get(prevValues, FieldNames.Accesses, {}) as AccessesFieldValues,
  );

  return {
    name: _get(values, FieldNames.Name),
    description: _get(values, FieldNames.Description),
    accesses,
    color: _get(values, [FieldNames.Color, 'value']),
    users: mergeAndUnlinkMultiSelectValues(prevMembers, members),
  } as TeamCreateInputObject;
}
