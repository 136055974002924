import React from 'react';
import { TFunction } from 'i18next';
import {
  Counter,
  CounterMeasure,
  MeasureTypeEnum,
  MeasureUnitEnum,
} from 'graphql-common';
import TextCell from '@lib/components/Table/commonComponents/TextCell/TextCell';
import { CellProps } from '@lib/components/Table/types';
import {
  convertDecimalHoursToTime,
  convertDecimalMinutesToTime,
  convertDecimalSecondsToTime,
  getFormattedDate,
} from '@lib/utils/dateTimeHelpers';
import { dateTimeFormatEU } from '@lib/enums/dateTime';
import TextCellWithIcon from '@lib/components/Table/commonComponents/TextCell/TextCellWithIcon';
import Chip, { ChipTypes } from '@lib/components/Chip/Chip';
import ResetIndexIcon from '@lib/assets/icons/reset_index_icon.svg?react';
import Tooltip from '@lib/components/Tooltip/Tooltip';
import convertToDecimal from '@lib/utils/convertToDecimal';

type ColumnsArgs = {
  counter?: Counter;
  t: TFunction<'translation', undefined>;
};

export default function getTableColumns({ counter, t }: ColumnsArgs) {
  const isTimeHour =
    counter?.measureType === MeasureTypeEnum.Time &&
    counter?.measureUnit === MeasureUnitEnum.Hour;
  const isTimeMinute =
    counter?.measureType === MeasureTypeEnum.Time &&
    counter?.measureUnit === MeasureUnitEnum.Minute;
  const isTimeSeconds =
    counter?.measureType === MeasureTypeEnum.Time &&
    counter?.measureUnit === MeasureUnitEnum.Second;

  const renderIndex = (indexValue?: number | null) => {
    if (indexValue === null || indexValue === undefined) {
      return undefined;
    }
    if (isTimeHour) {
      return convertDecimalHoursToTime(indexValue);
    }
    if (isTimeMinute) {
      return convertDecimalMinutesToTime(indexValue);
    }
    if (isTimeSeconds) {
      return convertDecimalSecondsToTime(indexValue);
    }
    return convertToDecimal(`${indexValue}`, 2);
  };

  return [
    {
      id: 'createdAt',
      Header: t('check-date'),
      accessorFn: ({ item }: CellProps<CounterMeasure>) =>
        getFormattedDate(item.createdAt, dateTimeFormatEU),
      Cell: (props: CellProps<CounterMeasure>) => <TextCell {...props} />,
      minWidth: 200,
      maxWidth: 200,
      width: 200,
      sortable: true,
    },
    {
      id: 'index',
      Header: t('index'),
      accessorFn: ({ item }: CellProps<CounterMeasure>) =>
        renderIndex(item.index),
      Cell: (props: CellProps<CounterMeasure>) =>
        props?.item?.resetToZero ? (
          <TextCellWithIcon
            {...props}
            icon={
              <Tooltip body={t('reset-to-zero')}>
                <Chip
                  type={ChipTypes.chipBlue}
                  leftIconComponent={<ResetIndexIcon />}
                />
              </Tooltip>
            }
            iconPosition="right"
          />
        ) : (
          <TextCell {...props} />
        ),
      minWidth: 150,
      maxWidth: 150,
      width: 150,
    },
    {
      id: 'gap',
      Header: t('gap'),
      accessorFn: ({ item }: CellProps<CounterMeasure>) =>
        renderIndex(item.gap),
      Cell: (props: CellProps<CounterMeasure>) => <TextCell {...props} />,
      minWidth: 150,
      maxWidth: 150,
      width: 150,
    },
    {
      id: 'cumulativeUsage',
      Header: t('cumulative-usage'),
      accessorFn: ({ item }: CellProps<CounterMeasure>) =>
        renderIndex(item.cumulativeUsage),
      Cell: (props: CellProps<CounterMeasure>) => <TextCell {...props} />,
      minWidth: 150,
      maxWidth: 150,
      width: 150,
    },
  ];
}
