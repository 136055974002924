import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _get from 'lodash/get';
import {
  Task,
  TaskScopeNameEnum,
  TaskTimelineGroupByEnum,
  Timeline,
} from 'graphql-common';
import Button, { ButtonTypes } from '@lib/components/Button/Button';
import Table from '@lib/components/Table/Table';
import CircleLoader from '@lib/components/CircleLoader/CircleLoader';
import Search from '@lib/components/Search/Search';
import PageBody from '@lib/layouts/DashboardLayout/PageBody/PageBody';
import { ApolloError } from '@apollo/client/errors';
import ListEmptyState from '@lib/components/ListEmptyState/ListEmptyState';
import useSearchParam from '@lib/hooks/useSearchParam';
import Trans from '@lib/components/Trans/Trans';
import TabBar from '@lib/components/TabBar/TabBar';
import ErrorPage from '@lib/components/ErrorPage/ErrorPage';
import { TableAction } from '@lib/components/Table/types';
import { NavItem } from '@lib/components/Menu/Menu';
import getStatusFromError from '@lib/utils/getStatusFromError';
import useFilterParams from '@lib/hooks/useFilterParams';
import { GetUrlParams } from '@lib/enums/urls';
import { dateFormatForApi } from '@lib/enums/dateTime';
import { getFormattedDate, transformDate } from '@lib/utils/dateTimeHelpers';
import {
  getPeriodEndDate,
  getPeriodStartDate,
} from 'components/TasksTimeline/utils/getPeriodDate';
import { APP_URLS } from 'constants/urls';
import getEmptyStateTextKeys from 'components/TasksTimeline/utils/getEmptyStateTextKeys';
import { TaskCounter } from '@lib/interfaces/task';
import { getAdditionalColumns } from './utils/getAdditionalColumns';
import mergeTasksWithTimeline from './utils/mergeTasksWithTimeline';
import getTimelineTableTabs from './utils/getTimelineTableTabs';
import getTimelineTableColumns from './utils/getTimelineTableColumns';
import TableTopHeadComponent from './TableTopHeadComponent';

interface Props {
  fullHeight?: boolean;
  setTabParam: (v: string) => void;
  tabParam?: string;
  tableActions?: TableAction<Task>[];
  tableActionsForSelected?: React.ReactNode[];
  tableActionsMenu?: NavItem<Task>[];
  taskCounterData: TaskCounter;
  tasks: Task[];
  tasksError?: ApolloError;
  tasksFirstLoading: boolean;
  tasksLoading: boolean;
  timelineData?: Timeline[];
  timelineGroupBy?: TaskTimelineGroupByEnum;
  timelineLoading: boolean;
  totalTasksCount: number;
}

function TasksTimeline(props: Props) {
  const {
    fullHeight,
    setTabParam,
    tabParam,
    tableActions,
    tableActionsForSelected,
    tableActionsMenu,
    taskCounterData,
    tasks,
    tasksError,
    tasksFirstLoading,
    tasksLoading,
    timelineData = [],
    timelineGroupBy = TaskTimelineGroupByEnum.Year,
    timelineLoading,
    totalTasksCount,
  } = props;
  const { t } = useTranslation();
  const [isFullTableView, setFullTableView] = useState(false);
  const [searchQuery] = useSearchParam();
  const [filterParams, setFilterParams] = useFilterParams();
  const view = (filterParams[GetUrlParams.View] ||
    TaskTimelineGroupByEnum.Year) as TaskTimelineGroupByEnum;
  const basePeriodStartParam = filterParams[GetUrlParams.PeriodStart];
  const basePeriodEndParam = filterParams[GetUrlParams.PeriodEnd];
  const periodStartParam = basePeriodStartParam
    ? getFormattedDate(
        getPeriodStartDate(basePeriodStartParam, view),
        dateFormatForApi,
      )
    : undefined;
  const periodEndParam = basePeriodEndParam
    ? getFormattedDate(
        getPeriodEndDate(basePeriodEndParam, view),
        dateFormatForApi,
      )
    : undefined;
  const endDateParam = filterParams[GetUrlParams.EndDate];

  const onToggleFullTableView = () => {
    setFullTableView((prevState) => !prevState);
  };

  const onViewChange = useCallback(
    (option: unknown) => {
      const newView = _get(option, 'value');
      if (newView) {
        setFilterParams(
          {
            [GetUrlParams.View]: newView,
            [GetUrlParams.EndDate]: basePeriodEndParam
              ? getFormattedDate(
                  getPeriodEndDate(basePeriodEndParam, newView),
                  dateFormatForApi,
                )
              : getFormattedDate(
                  getPeriodEndDate(transformDate(new Date()), newView),
                  dateFormatForApi,
                ),
          },
          'pushIn',
        );
      }
    },
    [basePeriodEndParam, setFilterParams],
  );

  const onEndDateChange = useCallback(
    (date: string) => {
      if (date) setFilterParams({ [GetUrlParams.EndDate]: date }, 'pushIn');
    },
    [setFilterParams],
  );

  const tableColumns = getTimelineTableColumns({
    columns: getAdditionalColumns({
      t,
      view: timelineGroupBy,
      endDateParam,
    }),
    isFullTableView,
    t,
  });
  const tableTabs = getTimelineTableTabs({ t, taskCounterData });

  const [emptyStateTitleKey, emptyStateTextKey] =
    getEmptyStateTextKeys(tabParam);

  const emptyStateActions: React.ReactNode[] = [];
  if (
    tabParam === TaskScopeNameEnum.Active ||
    tabParam === TaskScopeNameEnum.Pending
  ) {
    emptyStateActions.push(
      <Button
        key="add"
        buttonText={t('create-task')}
        buttonType={ButtonTypes.primaryFilled}
        leftIcon="add"
        to={APP_URLS.dashboard.tasks.add.path}
      />,
    );
  }

  const emptyState = (
    <ListEmptyState
      title={t(emptyStateTitleKey)}
      text={<Trans i18nKey={emptyStateTextKey} />}
      actions={emptyStateActions}
    />
  );

  const topHeadComponent = () => (
    <TableTopHeadComponent
      endDate={endDateParam}
      isFullTableView={isFullTableView}
      onEndDateChange={onEndDateChange}
      onToggleFullTableView={onToggleFullTableView}
      onViewChange={onViewChange}
      periodEndParam={periodEndParam}
      periodStartParam={periodStartParam}
      view={view}
    />
  );

  useEffect(() => {
    if (endDateParam === undefined) {
      const nowDate = new Date();
      const periodEndDate = getFormattedDate(
        getPeriodEndDate(nowDate.toISOString(), view),
        dateFormatForApi,
      );
      if (periodEndDate) onEndDateChange(periodEndDate);
    }
  }, [endDateParam, onEndDateChange, view]);

  if (tasksError) {
    return (
      <ErrorPage
        status={getStatusFromError(tasksError)}
        description={tasksError.message}
      />
    );
  }

  return (
    <PageBody>
      {tasksFirstLoading ? (
        <CircleLoader />
      ) : (
        <>
          {tableTabs.length > 0 && (
            <TabBar
              items={tableTabs}
              active={tabParam}
              onActiveChange={(item) => setTabParam(item.value)}
            />
          )}
          {(!!tasks?.length || searchQuery) && (
            <Search placeholder={t('tasks-timeline-placeholder')} />
          )}
          <Table
            actionsForSelected={tableActionsForSelected}
            actionsMenu={tableActionsMenu}
            actions={tableActions}
            columns={tableColumns}
            data={mergeTasksWithTimeline(tasks, timelineData)}
            emptyState={emptyState}
            fullHeight={fullHeight}
            id="tasks"
            loading={tasksLoading || timelineLoading}
            totalEntries={totalTasksCount}
            components={{
              TopHeadComponent: topHeadComponent,
            }}
          />
        </>
      )}
    </PageBody>
  );
}

export default TasksTimeline;
