import React, { memo, useEffect, useRef, useState } from 'react';
import Input from '@lib/components/Input/Input';
import _debounce from 'lodash/debounce';
import useSearchParamHook from '@lib/hooks/useSearchParam';
import { InputSizes } from '@lib/components/Input/enums';
import styles from './Search.module.scss';

interface Props {
  value?: string;
  inputSize?: InputSizes;
  onChange?: (value: string) => void;
  placeholder?: string;
  useBottomMargin?: boolean;
  useSearchParam?: boolean;
}

function Search(props: Props) {
  const {
    inputSize,
    onChange,
    placeholder,
    useBottomMargin = true,
    useSearchParam = true,
    value,
  } = props;
  const [searchQueryValue, setSearchQuery] = useSearchParamHook();
  const searchQuery = searchQueryValue;

  let defaultSearchValue = '';
  if (useSearchParam && searchQuery) {
    defaultSearchValue = searchQuery;
  } else if (value) {
    defaultSearchValue = value;
  }

  const [inputValue, setInputValue] = useState(defaultSearchValue);
  const debouncedValChange = useRef(
    _debounce((v) => {
      if (onChange) {
        onChange(v);
      } else {
        setSearchQuery(v);
      }
    }, 500),
  ).current;

  const onChangeHandler = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { value: newValue } = e.target;
    debouncedValChange.cancel();
    debouncedValChange(newValue);
    setInputValue(newValue);
  };

  const onClearHandler = () => {
    setInputValue('');
    debouncedValChange('');
  };

  useEffect(() => {
    if (useSearchParam) setInputValue(searchQuery || '');
  }, [searchQuery, useSearchParam]);

  useEffect(() => {
    if (value === '') setInputValue(value);
  }, [value]);

  return (
    <div className={useBottomMargin ? styles.root : undefined}>
      <Input
        value={inputValue}
        placeholder={placeholder}
        onChange={onChangeHandler}
        leftIcon="search"
        onClear={onClearHandler}
        size={inputSize}
      />
    </div>
  );
}

export default memo(Search);
