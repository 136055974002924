import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _get from 'lodash/get';
import {
  FilterGroupingEnum,
  InterventionCategoriesSearchListQueryVariables,
  InterventionCategory,
  InterventionCategoryFilterInputObject,
  useInterventionCategoriesSearchListQuery,
} from 'graphql-common';
import {
  GetUrlParams,
  ORDERING_DIRECTION_ASC,
  OrderingParams,
  PAGINATION_PARAMS,
  PaginationParams,
} from '@lib/enums/urls';
import LinkComponent, { LinkSizes, LinkTypes } from '@lib/components/Link/Link';
import Chip, { ChipTypes } from '@lib/components/Chip/Chip';
import styles from './InterventionCategoriesChipsList.module.scss';

function InterventionCategoriesChipsList({ ids }: { ids: string[] }) {
  const { t } = useTranslation();
  const [paginationParams, setPaginationParams] = useState<
    PaginationParams & OrderingParams
  >({
    ...PAGINATION_PARAMS,
    [GetUrlParams.OrderingField]: 'name',
    [GetUrlParams.OrderingDirection]: ORDERING_DIRECTION_ASC,
  });
  const [allItems, setAllItems] = useState<InterventionCategory[]>([]);

  const page = _get(paginationParams, GetUrlParams.Page);
  const perPage = _get(paginationParams, GetUrlParams.PerPage);
  const orderingField = _get(paginationParams, GetUrlParams.OrderingField);
  const orderingDirection = _get(
    paginationParams,
    GetUrlParams.OrderingDirection,
  );

  const queryVariables: InterventionCategoriesSearchListQueryVariables = {
    page: Number(page),
    limit: Number(perPage),
  };

  const filters: InterventionCategoryFilterInputObject[] = [];
  if (ids?.length) {
    filters.push({
      id: {
        grouping: FilterGroupingEnum.Or,
        predicate: {
          in: ids,
        },
      },
    });
  }
  if (filters.length) {
    queryVariables.filters = filters;
  }
  if (orderingField && orderingDirection) {
    queryVariables.sorts = {
      [orderingField]: orderingDirection,
    };
  }

  const query = useInterventionCategoriesSearchListQuery({
    fetchPolicy: 'no-cache',
    variables: queryVariables,
  });

  const { loading, data, previousData, error } = query;
  const queryData = data || previousData;
  const firstLoading = loading && previousData === undefined;
  const newItems = _get(
    queryData,
    'data.collection',
    [],
  ) as InterventionCategory[];
  const metadata = _get(queryData, 'data.metadata');
  const totalPages = _get(metadata, 'totalPages', 1);
  const currentPage = _get(metadata, 'currentPage', 1);

  const handleLoadMore = () => {
    if (currentPage < totalPages) {
      setPaginationParams((prev) => ({
        ...prev,
        [GetUrlParams.Page]: currentPage + 1,
      }));
    }
  };

  useEffect(() => {
    if (newItems.length) {
      setAllItems((prevUsers) => [...prevUsers, ...newItems]);
    }
  }, [newItems]);

  if (error || firstLoading) return null;

  return (
    <div>
      <div className={styles.chipsList}>
        {allItems?.length
          ? allItems.map((item) => (
              <Chip
                label={item.name}
                key={item.id}
                type={item.color as ChipTypes}
                leftIcon="circle"
              />
            ))
          : null}
      </div>
      {!!allItems?.length && currentPage < totalPages && (
        <LinkComponent
          size={LinkSizes.small}
          type={LinkTypes.secondaryGray}
          onClick={handleLoadMore}
        >
          {t('show-more')}
        </LinkComponent>
      )}
    </div>
  );
}

export default memo(InterventionCategoriesChipsList);
