import { transformDate } from '@lib/utils/dateTimeHelpers';
import dayjs from 'dayjs';

export function getDefaultPeriod() {
  return {
    startDate: transformDate(
      dayjs().subtract(1, 'month').add(1, 'day').startOf('day').toDate(),
    ),
    endDate: transformDate(dayjs().toDate(), true),
  };
}
