import React, { forwardRef, memo, useEffect, useState } from 'react';
import classNames from 'classnames';
import RadioButtonGroup, {
  RadioButtonGroupOption,
  RadioButtonWrapViews,
} from '@lib/components/RadioButtonGroup/RadioButtonGroup';
import MaterialIcon from '@lib/components/MaterialIcon/MaterialIcon';
import { Option } from '@lib/interfaces/form';
import useHover from '@lib/hooks/useHover';
import styles from './Rating.module.scss';

const options = [
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
];

type StarProps = {
  checked?: boolean;
  disabled?: boolean;
  hoveredIndex: number;
  id: number;
  value: number;
  setHoveredIndex: (v: number) => void;
  onChange: (v: React.ChangeEvent<HTMLInputElement>) => void;
};

function Star(props: StarProps) {
  const {
    disabled,
    onChange,
    checked,
    id,
    hoveredIndex,
    setHoveredIndex,
    value,
  } = props;
  const [hoverRef, isHovered] = useHover<HTMLButtonElement>();

  const onClick = (e: unknown) => {
    const v = e as React.ChangeEvent<HTMLInputElement>;
    onChange(v);
  };

  const className = classNames(styles.starWrap, {
    [styles.starWrapDisabled]: disabled,
    [styles.starWrapDisabledHover]: !setHoveredIndex,
  });

  let isFilled = false;
  if (checked || hoveredIndex >= id) isFilled = true;
  if (value > id) isFilled = true;

  useEffect(() => {
    if (isHovered && setHoveredIndex) {
      setHoveredIndex(id);
    }
  }, [disabled, id, isHovered, setHoveredIndex]);

  return (
    <button
      type="button"
      onClick={onClick}
      className={className}
      ref={hoverRef}
      disabled={disabled}
    >
      <MaterialIcon icon="star" symbolsOutlined={!isFilled} />
    </button>
  );
}

type Props = {
  disabled?: boolean;
  disabledHover?: boolean;
  name?: string;
  onChange?: (v: RadioButtonGroupOption) => void;
  value?: Option;
};

const Rating = forwardRef(
  (
    props: Props,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ref: React.ForwardedRef<HTMLInputElement>,
  ) => {
    const { disabled, disabledHover, name, onChange, value } = props;
    const [hoveredIndex, setHoveredIndex] = useState(0);
    const [hoverRef, isHovered] = useHover<HTMLDivElement>();

    useEffect(() => {
      if (!isHovered) {
        setHoveredIndex(0);
      }
    }, [isHovered]);

    return (
      <div ref={hoverRef}>
        <RadioButtonGroup
          disabled={disabled}
          RadioButtonComponent={Star}
          radioButtonComponentProps={{
            hoveredIndex,
            setHoveredIndex:
              disabled || disabledHover ? undefined : setHoveredIndex,
            value: value?.value,
          }}
          name={name}
          onChange={onChange}
          options={options}
          value={options.find(
            ({ value: optionValue }) => optionValue === value?.value,
          )}
          wrapView={RadioButtonWrapViews.rating}
        />
      </div>
    );
  },
);

export default memo(Rating);
