import React, { memo, useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import LinkComponent, { LinkSizes, LinkTypes } from '@lib/components/Link/Link';
import { BaseItem, CellProps } from '@lib/components/Table/types';
import Tooltip from '@lib/components/Tooltip/Tooltip';
import isTextCropped from '@lib/utils/isTextCropped';
import styles from './TextLinkCell.module.scss';

interface Props {
  to?: string;
}

function TextLinkCell<T extends BaseItem>(props: Props & CellProps<T>) {
  const { disabled, value, onClick, to } = props;
  const textRef = useRef<HTMLDivElement>(null);
  const [isTooltipDisabled, setIsTooltipDisabled] = useState(true);

  useEffect(() => {
    const textRefEl = textRef.current;
    if (textRefEl) {
      setIsTooltipDisabled(!value || !isTextCropped(textRefEl));
    }
  }, [value]);

  return (
    <div>
      <Tooltip body={value} disabled={isTooltipDisabled}>
        <LinkComponent
          component={NavLink}
          onClick={onClick}
          to={to}
          type={LinkTypes.tertiaryBlack}
          underline={false}
          size={LinkSizes.small}
          className={classNames(styles.rootTextCell, {
            [styles.disabled]: disabled,
          })}
        >
          <div ref={textRef} className={styles.croppedText}>
            {value}
          </div>
        </LinkComponent>
      </Tooltip>
    </div>
  );
}

export default memo(TextLinkCell) as <T extends BaseItem>(
  props: CellProps<T> & Props,
) => JSX.Element;
