import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Task, TaskScopeNameEnum } from 'graphql-common';
import { APP_URLS } from 'constants/urls';
import Button, { ButtonTypes } from '@lib/components/Button/Button';
import Table from '@lib/components/Table/Table';
import CircleLoader from '@lib/components/CircleLoader/CircleLoader';
import PageHeader from '@lib/layouts/DashboardLayout/PageHeader/PageHeader';
import Search from '@lib/components/Search/Search';
import PageBody from '@lib/layouts/DashboardLayout/PageBody/PageBody';
import { ApolloError } from '@apollo/client/errors';
import ListEmptyState from '@lib/components/ListEmptyState/ListEmptyState';
import useSearchParam from '@lib/hooks/useSearchParam';
import Trans from '@lib/components/Trans/Trans';
import TabBar from '@lib/components/TabBar/TabBar';
import ErrorPage from '@lib/components/ErrorPage/ErrorPage';
import { Column, TableAction } from '@lib/components/Table/types';
import { NavItem } from '@lib/components/Menu/Menu';
import { TabBarItem as TabBarItemType } from '@lib/components/TabBar/constants';
import Typography from '@lib/components/Typography/Typography';
import getStatusFromError from '@lib/utils/getStatusFromError';
import { UseBulkSelectResult } from '@lib/hooks/useTableItemsBulkSelect';
import ViewOnlyChip from '@lib/components/ViewOnlyChip/ViewOnlyChip';
import getEmptyStateTextKeys from './utils/getEmptyStateTextKeys';
import styles from './Tasks.module.scss';

interface Props {
  fullHeight?: boolean;
  isApprovals?: boolean;
  loadingState?: boolean;
  pageSubTitle?: string;
  pageTitle?: string;
  setTabParam: (v: string) => void;
  showCreateBtn?: boolean;
  tabParam?: string;
  tableActions?: TableAction<Task>[];
  tableActionsForSelected?: React.ReactNode[];
  tableActionsMenu?: NavItem<Task>[];
  tableColumns: Column<Task>[];
  tableTabs?: TabBarItemType[];
  tasks: Task[];
  tasksError?: ApolloError;
  tasksFirstLoading: boolean;
  tasksLoading: boolean;
  totalTasksCount: number;
  useBulkSelectResult?: UseBulkSelectResult<Task>;
  isViewOnly: boolean;
}

function Tasks(props: Props) {
  const {
    fullHeight,
    isApprovals,
    loadingState,
    pageSubTitle,
    pageTitle,
    setTabParam,
    showCreateBtn,
    tabParam,
    tableActions,
    tableActionsForSelected,
    tableActionsMenu,
    tableColumns,
    tableTabs = [],
    tasks,
    tasksError,
    tasksFirstLoading,
    tasksLoading,
    totalTasksCount,
    useBulkSelectResult,
    isViewOnly,
  } = props;
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [searchQuery] = useSearchParam();

  const [emptyStateTitleKey, emptyStateTextKey] =
    getEmptyStateTextKeys(tabParam);

  const emptyStateActions: React.ReactNode[] = [];
  if (
    (tabParam === TaskScopeNameEnum.Active ||
      tabParam === TaskScopeNameEnum.Pending) &&
    !isViewOnly
  ) {
    emptyStateActions.push(
      <Button
        key="add"
        buttonText={t('create-task')}
        buttonType={ButtonTypes.primaryFilled}
        leftIcon="add"
        to={APP_URLS.dashboard.tasks.add.path}
      />,
    );
  }

  const emptyState = (
    <ListEmptyState
      title={t(emptyStateTitleKey)}
      text={<Trans i18nKey={emptyStateTextKey} />}
      actions={emptyStateActions}
    />
  );

  if (tasksError) {
    return (
      <ErrorPage
        status={getStatusFromError(tasksError)}
        description={tasksError.message}
      />
    );
  }

  const searchPlaceholder = t(
    APP_URLS.dashboard.overview.path === pathname
      ? 'tasks-dashboard-search-placeholder'
      : 'tasks-search-placeholder',
  );

  return (
    <>
      {pageTitle && (
        <PageHeader title={pageTitle}>
          <>
            {showCreateBtn && !isViewOnly ? (
              <Button
                buttonText={t('create-task')}
                leftIcon="add"
                to={APP_URLS.dashboard.tasks.add.path}
              />
            ) : undefined}
            {isViewOnly && <ViewOnlyChip />}
          </>
        </PageHeader>
      )}
      <PageBody>
        {tasksFirstLoading ? (
          <CircleLoader />
        ) : (
          <>
            {pageSubTitle && (
              <Typography variant="h3" className={styles.pageSubTitle}>
                {pageSubTitle}
              </Typography>
            )}
            {tableTabs.length > 0 && (
              <TabBar
                items={tableTabs}
                active={tabParam}
                onActiveChange={(item) => setTabParam(item.value)}
              />
            )}
            {(!!tasks?.length || searchQuery) && (
              <Search placeholder={searchPlaceholder} />
            )}
            <Table
              actions={tableActions}
              actionsForSelected={tableActionsForSelected}
              actionsMenu={tableActionsMenu}
              columns={tableColumns}
              data={tasks}
              emptyState={emptyState}
              fullHeight={fullHeight}
              id={isApprovals ? 'approvals' : 'tasks'}
              loading={loadingState || tasksLoading}
              totalEntries={totalTasksCount}
              useBulkSelectResult={useBulkSelectResult}
            />
          </>
        )}
      </PageBody>
    </>
  );
}

export default Tasks;
