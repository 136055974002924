import React from 'react';
import { Values } from '@lib/interfaces/form';
import Form from '@lib/components/ReactHookForm/FormContainer';
import TasksFilterFieldNames from '@lib/enums/fieldNames/tasksFilterFieldNames';
import useQueries from './utils/useQueries';
import useFilterFields from './utils/useFilterFields';
import styles from './DashboardInfoFilter.module.scss';

type Props = {
  defaultValues?: Values;
  handleClose?: () => void;
  onSubmit: (v: Values) => void;
};

const emptyValues = {
  [TasksFilterFieldNames.Filter]: [
    {
      [TasksFilterFieldNames.Site]: undefined,
      [TasksFilterFieldNames.SiteAreaIds]: [],
    },
  ],
};

export default function SiteAndAreaFilterForm(props: Props) {
  const { defaultValues, handleClose, onSubmit } = props;

  const { fetchSiteAreasSearchListLazyQuery, sitesLoadOptions } = useQueries();

  const fields = useFilterFields({
    sitesLoadOptions,
    fetchSiteAreasSearchListLazyQuery,
    handleClose,
  });

  const onFormSubmit = (values: Values) => {
    onSubmit(values);
    if (handleClose) handleClose();
  };

  return (
    <div className={styles.siteAreaFilter}>
      <Form
        defaultValues={defaultValues || emptyValues}
        disableAwayConfirmation
        fields={fields}
        formId="siteAreaFilter"
        onSubmit={onFormSubmit}
      />
    </div>
  );
}
