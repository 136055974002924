import React, { forwardRef, memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';
import {
  FilterGroupingEnum,
  User,
  UserFilterInputObject,
  UsersSearchListQueryVariables,
  useUsersSearchListQuery,
} from 'graphql-common';
import {
  GetUrlParams,
  PAGINATION_PARAMS,
  PaginationParams,
} from '@lib/enums/urls';
import LinkComponent, { LinkSizes, LinkTypes } from '@lib/components/Link/Link';
import UserChip from '@lib/components/UserChip/UserChip';
import MaterialIcon from '@lib/components/MaterialIcon/MaterialIcon';
import { FloatingLabelProps } from '@lib/hocs/withFloatingLabel';
import { Values } from '@lib/interfaces/form';
import { SelectOption } from '@lib/components/Select/types';
import usePrevious from '@lib/hooks/usePrevious';
import styles from './UserChipsListField.module.scss';

type Props = {
  actionId?: string;
  fieldName?: string;
  interventionId?: string;
  teamId?: string;
  values: Values;
  ids?: string[];
};

const UserChipsListField = forwardRef(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  (props: Props & FloatingLabelProps, ref: React.ForwardedRef<unknown>) => {
    const {
      actionId = '',
      fieldName = '',
      interventionId = '',
      teamId = '',
      values,
      ids,
    } = props;
    const prevIds = usePrevious(ids);
    const { setValue } = useFormContext();
    const { t } = useTranslation();
    const [paginationParams, setPaginationParams] =
      useState<PaginationParams>(PAGINATION_PARAMS);
    const [allItems, setAllItems] = useState<User[]>([]);

    const page = _get(paginationParams, GetUrlParams.Page);
    const perPage = _get(paginationParams, GetUrlParams.PerPage);
    const orderingField = _get(paginationParams, GetUrlParams.OrderingField);
    const orderingDirection = _get(
      paginationParams,
      GetUrlParams.OrderingDirection,
    );

    const queryVariables: UsersSearchListQueryVariables = {
      page: Number(page),
      limit: Number(perPage),
    };

    const filters: UserFilterInputObject[] = [];
    if (interventionId) {
      filters.push({
        interventionAssigneesInterventionId: {
          grouping: FilterGroupingEnum.Or,
          predicate: { eq: interventionId },
        },
      });
    }
    if (actionId) {
      filters.push({
        interventionActionAssigneesActionId: {
          grouping: FilterGroupingEnum.Or,
          predicate: { eq: actionId },
        },
      });
    }
    if (teamId) {
      filters.push({
        teamId: {
          grouping: FilterGroupingEnum.Or,
          predicate: { eq: teamId },
        },
      });
    }
    if (filters.length) {
      queryVariables.filters = filters;
    }
    if (orderingField && orderingDirection) {
      queryVariables.sorts = {
        [orderingField]: orderingDirection,
      };
    }

    const query = useUsersSearchListQuery({
      fetchPolicy: 'no-cache',
      variables: queryVariables,
      skip: !(interventionId || actionId || teamId),
    });

    const { loading, data, previousData, error } = query;
    const queryData = data || previousData;
    const firstLoading = loading && previousData === undefined;
    const newItems = _get(queryData, 'data.collection', []) as User[];
    const metadata = _get(queryData, 'data.metadata');
    const totalPages = _get(metadata, 'totalPages', 1);
    const currentPage = _get(metadata, 'currentPage', 1);

    useEffect(() => {
      if (newItems.length) {
        setAllItems((prevUsers) => [...prevUsers, ...newItems]);
      }
    }, [newItems]);

    const handleLoadMore = () => {
      if (currentPage < totalPages) {
        setPaginationParams((prev) => ({
          ...prev,
          [GetUrlParams.Page]: currentPage + 1,
        }));
      }
    };

    const handleRemove = (id: string) => {
      const itemsValue = _get(values, fieldName, []) as SelectOption[];
      const newData = itemsValue.filter(
        ({ value: optionValue }: SelectOption) => optionValue !== id,
      );
      if (fieldName) {
        setValue(fieldName, newData);
      }
    };

    const formFieldValue = (_get(values, fieldName) || []) as SelectOption[];
    const visibleItems = allItems.filter((item) =>
      formFieldValue.some(({ value: optionValue }) => item.id === optionValue),
    );
    const visibleItemsIds = visibleItems.map((item) => item.id);
    let newFormFieldValue = formFieldValue;
    if (ids?.length) {
      newFormFieldValue = formFieldValue.filter(
        (item) => !ids.includes(item.value as string),
      );
    }
    if (visibleItemsIds.length) {
      newFormFieldValue = newFormFieldValue.filter(
        (item) => !visibleItemsIds.includes(item.value as string),
      );
    }

    useEffect(() => {
      if (newItems.length) {
        setAllItems((prevItems) => {
          const combined = [...prevItems, ...newItems];
          return combined.filter(
            (item, index, self) =>
              index === self.findIndex((subItem) => subItem.id === item.id),
          );
        });
      }
    }, [newItems]);

    useEffect(() => {
      if (!_isEqual(ids, prevIds)) {
        setAllItems([]);
        query.refetch();
      }
    }, [ids, prevIds, query]);

    if (error || firstLoading) return null;

    return (
      <div>
        <div className={styles.chipsList}>
          {newFormFieldValue?.length
            ? newFormFieldValue.map((formFieldItem: SelectOption) => (
                <UserChip
                  user={formFieldItem.data as User}
                  key={formFieldItem.value as string}
                  rightIconComponent={
                    <MaterialIcon
                      icon="cancel"
                      onClick={() =>
                        handleRemove(formFieldItem.value as string)
                      }
                    />
                  }
                />
              ))
            : null}
          {visibleItems?.length
            ? visibleItems.map((visibleItem) => (
                <UserChip
                  user={visibleItem}
                  key={visibleItem.id}
                  rightIconComponent={
                    <MaterialIcon
                      icon="cancel"
                      onClick={() => handleRemove(visibleItem.id)}
                    />
                  }
                />
              ))
            : null}
        </div>
        {currentPage < totalPages && (
          <LinkComponent
            size={LinkSizes.small}
            type={LinkTypes.secondaryGray}
            onClick={handleLoadMore}
          >
            {t('show-more')}
          </LinkComponent>
        )}
      </div>
    );
  },
);

export default memo(UserChipsListField);
