import { parseSearch, stringifyWithPrefix } from '@lib/utils/url';
import { GetUrlParams, PER_PAGE_KEY_DEFAULT } from '@lib/enums/urls';

type ListItemCallbackArgs = {
  callbackOnNavigate?: () => void;
  collection: unknown[];
  collectionForDestroy?: unknown[];
  navigate: (v: string) => void;
  pathname: string;
  refetch: () => void;
  search: string;
  shouldCheckLastPage?: boolean;
  totalPages: number;
};

function onDestroyListItemCallBack({
  callbackOnNavigate,
  collection,
  collectionForDestroy,
  navigate,
  pathname,
  refetch,
  search,
  shouldCheckLastPage = true,
  totalPages,
}: ListItemCallbackArgs) {
  const params = parseSearch(search);
  const {
    [GetUrlParams.Page]: page = '1',
    [GetUrlParams.PerPage]: perPage = PER_PAGE_KEY_DEFAULT,
  } = params;
  const pageNumber = Number(page);
  const perPageNumber = Number(perPage);
  const collectionLength = collection.length;
  const collectionForDestroyLength = collectionForDestroy?.length || 0;
  const isLastPage = pageNumber === totalPages;
  const isFirstPage = pageNumber === 1;
  const isSingleLastPageElementDeleted = collectionLength === 1;
  const isAllLastPageElementsDeleted =
    collectionLength === collectionForDestroyLength;

  let newPage = pageNumber;

  if (shouldCheckLastPage) {
    if (isLastPage && !isFirstPage) {
      if (isSingleLastPageElementDeleted || isAllLastPageElementsDeleted) {
        newPage = pageNumber - 1;
      } else if (collectionForDestroyLength > collectionLength) {
        // Calculate the new page based on the total number of items to be deleted
        const pagesToMoveBack = Math.ceil(
          collectionForDestroyLength / perPageNumber,
        );
        newPage = pageNumber - pagesToMoveBack;
        if (newPage < 1) newPage = 1;
      }
    } else {
      newPage = 1;
    }

    if (newPage !== pageNumber) {
      params[GetUrlParams.Page] = String(newPage);
      const newUrl = `${pathname}${stringifyWithPrefix(params)}`;
      navigate(newUrl);
      if (callbackOnNavigate) callbackOnNavigate();
    } else {
      refetch();
    }
  } else if (isFirstPage) {
    refetch();
  } else {
    params[GetUrlParams.Page] = '1';
    const newUrl = `${pathname}${stringifyWithPrefix(params)}`;
    navigate(newUrl);
    if (callbackOnNavigate) callbackOnNavigate();
  }
}

export default onDestroyListItemCallBack;
