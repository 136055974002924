import _get from 'lodash/get';
import _upperFirst from 'lodash/upperFirst';
import _lowerCase from 'lodash/lowerCase';
import _isEqual from 'lodash/isEqual';
import {
  RecurringRuleRuleTypeEnum,
  RecurringRuleSchemaOptionEnum,
  Task,
} from 'graphql-common';
import { Option, Values } from '@lib/interfaces/form';
import {
  countdownTypeOptions,
  monthOptions,
  recurringRuleDayOfMonthOptionDayOfWeekOptions,
  recurringRuleDayOfMonthOptions,
  repeatDayOptions,
} from 'routes/Task/utils/useFields/options';
import { EndRepeatUnit, FieldNames } from 'routes/Task/enums';

export default function getRecurringRuleValuesFromQueryData(taskData: Task) {
  const recurringRule = _get(taskData, 'recurringRule');
  const values: Values = {};

  if (!recurringRule) return undefined;

  if (recurringRule.endTime) {
    values[FieldNames.endRepeatEnabled] = true;
    values[FieldNames.endRepeatDate] = recurringRule.endTime;
    values[FieldNames.endRepeatType] = { value: EndRepeatUnit.date }; // Assuming we have a way to distinguish this from completions
  }

  if (recurringRule.endsAfterOccurrences) {
    values[FieldNames.endRepeatEnabled] = true;
    values[FieldNames.endRepeatAfterOccurrences] =
      recurringRule.endsAfterOccurrences;
    values[FieldNames.endRepeatType] = { value: EndRepeatUnit.completions };
  }

  const excludedWdays: Option[] = [];
  (recurringRule.excludedWdays ?? []).forEach((value) => {
    const option = repeatDayOptions.find(
      (dayOption) => value === dayOption.value,
    );
    if (option) excludedWdays.push(option);
  });

  values[FieldNames.recurringRuleEnabled] = true;
  values[FieldNames.deadlineAt] = recurringRule.startTime;
  values[FieldNames.recurringRuleExcludedDayOfWeek] = excludedWdays;
  values[FieldNames.recurringRuleCountdownType] = countdownTypeOptions.find(
    ({ value }) => value === recurringRule.countdownType,
  );
  values[FieldNames.recurringRuleDayOfWeek] = [];
  values[FieldNames.repeatMonthlyRepeatOnOptions] = [];
  values[FieldNames.repeatYearlyRepeatOnOptions] = [];

  const schemaOption = recurringRule.schema.metadata?.schemaOption;

  recurringRule.schema.rrules.forEach((rule) => {
    const { validations, ruleType } = rule;

    values[`${FieldNames.recurringRuleRuleType}`] = {
      value: ruleType,
      label: _upperFirst(_lowerCase(ruleType)),
    };
    values[FieldNames.recurringRuleInterval] = rule.interval.toString();

    if (validations) {
      const { day, dayOfMonth, dayOfWeek, monthOfYear } = validations;

      if (ruleType === RecurringRuleRuleTypeEnum.Weekly) {
        const dayValues: Option[] = [];
        (day ?? []).forEach((value) => {
          const option = repeatDayOptions.find(
            (dayOption) => value === dayOption.value,
          );
          if (option) dayValues.push(option);
        });
        values[FieldNames.recurringRuleDayOfWeek] = dayValues;
      }

      if (schemaOption && ruleType === RecurringRuleRuleTypeEnum.Monthly) {
        values[FieldNames.repeatMonthlyType] = { value: schemaOption };
      }

      if (schemaOption && ruleType === RecurringRuleRuleTypeEnum.Yearly) {
        values[FieldNames.repeatYearlyType] = { value: schemaOption };
      }

      if (
        ruleType === RecurringRuleRuleTypeEnum.Monthly &&
        _isEqual(schemaOption, RecurringRuleSchemaOptionEnum.Each)
      ) {
        values[FieldNames.repeatMonthlyEachDayOfMonth] = (dayOfMonth ?? []).map(
          (value) => ({ value }),
        );
      }

      if (
        ruleType === RecurringRuleRuleTypeEnum.Monthly &&
        _isEqual(schemaOption, RecurringRuleSchemaOptionEnum.OnThe)
      ) {
        values[FieldNames.repeatMonthlyEachDayOfMonth] = (dayOfMonth ?? []).map(
          (value) => ({ value }),
        );
        if (dayOfMonth?.length && dayOfMonth[0] === -1) {
          if (Array.isArray(values[FieldNames.repeatMonthlyRepeatOnOptions])) {
            values[FieldNames.repeatMonthlyRepeatOnOptions].push({
              [FieldNames.repeatMonthlyRepeatOnOption1]:
                recurringRuleDayOfMonthOptions.find(
                  ({ value }) => value === -1,
                ),
              [FieldNames.repeatMonthlyRepeatOnOption2]:
                recurringRuleDayOfMonthOptionDayOfWeekOptions.find(
                  ({ value }) => value === -1,
                ),
            });
          }
        } else if (dayOfMonth?.length) {
          dayOfMonth.forEach((dayItem) => {
            if (
              Array.isArray(values[FieldNames.repeatMonthlyRepeatOnOptions])
            ) {
              values[FieldNames.repeatMonthlyRepeatOnOptions].push({
                [FieldNames.repeatMonthlyRepeatOnOption1]:
                  recurringRuleDayOfMonthOptions.find(
                    ({ value }) => value === dayItem,
                  ),
                [FieldNames.repeatMonthlyRepeatOnOption2]:
                  recurringRuleDayOfMonthOptionDayOfWeekOptions.find(
                    ({ value }) => value === -1,
                  ),
              });
            }
          });
        }
        if (dayOfWeek) {
          Object.keys(dayOfWeek).forEach((key) => {
            if (
              Array.isArray(values[FieldNames.repeatMonthlyRepeatOnOptions])
            ) {
              const optionValue = _get(rule, [
                'validations',
                'dayOfWeek',
                key,
                0,
              ]);
              values[FieldNames.repeatMonthlyRepeatOnOptions].push({
                [FieldNames.repeatMonthlyRepeatOnOption1]:
                  recurringRuleDayOfMonthOptions.find(
                    ({ value }) => value === optionValue,
                  ),
                [FieldNames.repeatMonthlyRepeatOnOption2]:
                  recurringRuleDayOfMonthOptionDayOfWeekOptions.find(
                    ({ value }) => value === +key,
                  ),
              });
            }
          });
        }
      }

      if (
        ruleType === RecurringRuleRuleTypeEnum.Yearly &&
        _isEqual(schemaOption, RecurringRuleSchemaOptionEnum.Each)
      ) {
        values[FieldNames.repeatYearlyEachMonthOfYear] = (
          monthOfYear ?? []
        ).map((value) =>
          monthOptions.find(({ value: optionValue }) => value === optionValue),
        );
        values[FieldNames.repeatYearlyEachDayOfMonth] = (dayOfMonth ?? []).map(
          (value) => ({ value }),
        );
      }

      if (
        ruleType === RecurringRuleRuleTypeEnum.Yearly &&
        _isEqual(schemaOption, RecurringRuleSchemaOptionEnum.OnThe)
      ) {
        values[FieldNames.repeatYearlyRepeatOnMonthOfYear] = (
          monthOfYear ?? []
        ).map((value) =>
          monthOptions.find(({ value: optionValue }) => value === optionValue),
        );
        if (dayOfMonth?.length && dayOfMonth[0] === -1) {
          if (Array.isArray(values[FieldNames.repeatYearlyRepeatOnOptions])) {
            values[FieldNames.repeatYearlyRepeatOnOptions].push({
              [FieldNames.repeatYearlyRepeatOnOption1]:
                recurringRuleDayOfMonthOptions.find(
                  ({ value }) => value === -1,
                ),
              [FieldNames.repeatYearlyRepeatOnOption2]:
                recurringRuleDayOfMonthOptionDayOfWeekOptions.find(
                  ({ value }) => value === -1,
                ),
            });
          }
        } else if (dayOfMonth?.length) {
          dayOfMonth.forEach((dayItem) => {
            if (Array.isArray(values[FieldNames.repeatYearlyRepeatOnOptions])) {
              values[FieldNames.repeatYearlyRepeatOnOptions].push({
                [FieldNames.repeatYearlyRepeatOnOption1]:
                  recurringRuleDayOfMonthOptions.find(
                    ({ value }) => value === dayItem,
                  ),
                [FieldNames.repeatYearlyRepeatOnOption2]:
                  recurringRuleDayOfMonthOptionDayOfWeekOptions.find(
                    ({ value }) => value === -1,
                  ),
              });
            }
          });
        }
        if (dayOfWeek) {
          Object.keys(dayOfWeek).forEach((key) => {
            if (Array.isArray(values[FieldNames.repeatYearlyRepeatOnOptions])) {
              const optionValue = _get(rule, [
                'validations',
                'dayOfWeek',
                key,
                0,
              ]);
              values[FieldNames.repeatYearlyRepeatOnOptions].push({
                [FieldNames.repeatYearlyRepeatOnOption1]:
                  recurringRuleDayOfMonthOptions.find(
                    ({ value }) => value === optionValue,
                  ),
                [FieldNames.repeatYearlyRepeatOnOption2]:
                  recurringRuleDayOfMonthOptionDayOfWeekOptions.find(
                    ({ value }) => value === +key,
                  ),
              });
            }
          });
        }
      }
    }
  });

  return values;
}
