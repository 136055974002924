import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { SelectOption } from '@lib/components/Select/types';
import Chip, { ChipTypes } from '@lib/components/Chip/Chip';
import styles from './TagsInput.module.scss';

type Props = {
  tagsValue: SelectOption[];
  handleRemoveTag: (v: string) => void;
};

export default function TagsRow({ tagsValue, handleRemoveTag }: Props) {
  return (
    <div className={styles.tagsRow}>
      {tagsValue?.map((tag: SelectOption) => (
        <div key={uuidv4()}>
          <Chip
            label={tag?.label}
            type={ChipTypes.chipBlack}
            onClose={() => handleRemoveTag(tag?.value as string)}
          />
        </div>
      ))}
    </div>
  );
}
