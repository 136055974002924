import { FormTemplateScopeNameEnum } from 'graphql-common';

export default function getEmptyStateTextKeys(tab?: string) {
  switch (tab) {
    case FormTemplateScopeNameEnum.Active:
      return ['no-form-templates-yet', 'no-form-templates-text'];
    case FormTemplateScopeNameEnum.Archived:
      return [
        'no-form-templates-archived-yet',
        'no-form-templates-archived-text',
      ];
    default:
      return ['no-tasks-yet', 'no-tasks-text'];
  }
}
