import React from 'react';
import _get from 'lodash/get';
import { TFunction } from 'i18next';
import { RoleEnum, User } from 'graphql-common';
import UserPreviewCell from '@lib/components/Table/commonComponents/UserPreviewCell/UserPreviewCell';
import TextCell from '@lib/components/Table/commonComponents/TextCell/TextCell';
import UserSetupCell from '@lib/components/Table/commonComponents/UserSetupCell/UserSetupCell';
import CollapseCell from '@lib/components/Table/commonComponents/CollapseCell/CollapseCell';
import { CellProps } from '@lib/components/Table/types';
import Chip, { ChipTypes } from '@lib/components/Chip/Chip';
import { getRoleOption } from '@lib/utils/roles';

type ColumnsArgs = {
  currentUserId?: string;
  isViewOnly: boolean;
  onResendEmailConfirmationHandler: (id: string) => void;
  onResendRegistrationConfirmationHandler: (id: string) => void;
  openTeamPage: (id: string) => void;
  t: TFunction<'translation', undefined>;
};

export default function getUsersTableColumns({
  currentUserId,
  isViewOnly,
  onResendEmailConfirmationHandler,
  onResendRegistrationConfirmationHandler,
  openTeamPage,
  t,
}: ColumnsArgs) {
  return [
    {
      id: 'personalId',
      Header: 'ID',
      accessor: 'personalId',
      Cell: (props: CellProps<User>) => <TextCell {...props} />,
      minWidth: 80,
      width: 80,
      sortable: true,
    },
    {
      id: 'fullName',
      Header: t('name'),
      Cell: ({ item }: CellProps<User>) => (
        <UserPreviewCell
          avatar={
            item.avatarAttached?.urls?.small ||
            item.avatarAttached?.urls?.original
          }
          name={`${item.firstName} ${item.lastName}`}
          email={item.email}
        />
      ),
      minWidth: 308,
      width: 308,
      sortable: true,
    },
    {
      id: 'role',
      Header: t('role'),
      accessorFn: ({ item }: CellProps<User>) => {
        const role = getRoleOption(item.role)?.labelKey;
        return role ? t(role) : '-';
      },
      Cell: (props: CellProps<User>) => <TextCell {...props} />,
      maxWidth: 150,
      minWidth: 150,
      width: 150,
      sortable: true,
    },
    {
      id: 'teamName',
      Header: t('team'),
      accessorFn: ({ item }: CellProps<User>) => {
        const { team } = item;
        if (team?.id) {
          return (
            <div>
              <Chip
                type={team.color as ChipTypes}
                label={team.name}
                leftIcon="circle"
                onClick={() => openTeamPage(team.id)}
              />
            </div>
          );
        }
        return '-';
      },
      maxWidth: 150,
      minWidth: 150,
      width: 150,
      sortable: true,
    },
    {
      id: 'site',
      Header: t('site'),
      accessorFn: ({ item }: CellProps<User>) => {
        const { sites } = item;
        return sites.collection.map(({ id, name }) => (
          <div style={{ maxWidth: '100%' }} key={id}>
            <Chip key={id} type={ChipTypes.chipGray} label={name} />
          </div>
        ));
      },
      Cell: (props: CellProps<User>) => {
        const role = _get(props, 'item.role');
        if (role === RoleEnum.FullAdmin) {
          return (
            <div>
              <Chip type={ChipTypes.chipGray} label={t('all')} />
            </div>
          );
        }
        return <CollapseCell {...props} minHeight={24} />;
      },
      maxWidth: 200,
      minWidth: 200,
    },
    {
      id: 'setup',
      Header: 'Setup',
      Cell: (props: CellProps<User>) => (
        <UserSetupCell
          {...props}
          isViewOnly={isViewOnly}
          currentUserId={currentUserId}
          onResendEmailConfirmationHandler={() =>
            onResendEmailConfirmationHandler(_get(props, 'item.id'))
          }
          onResendRegistrationConfirmationHandler={() =>
            onResendRegistrationConfirmationHandler(_get(props, 'item.id'))
          }
        />
      ),
      hideHeader: true,
      minWidth: 204,
      width: 204,
      prohibitDisabled: true,
    },
  ];
}
