import { User, useUserQuery } from 'graphql-common';
import { SingleQueryHookProps, SingleQueryHookResult } from 'utils/fetch/types';

type Args = {
  includeFinalAccesses?: boolean;
  includeFullData?: boolean;
  includePersonalAccesses?: boolean;
  includeTeamAccesses?: boolean;
};

export default function useUserQueryHook(
  args: SingleQueryHookProps & Args,
): SingleQueryHookResult<User> {
  const {
    fetchPolicy = 'network-only',
    id = '',
    includeFinalAccesses,
    includeFullData,
    includePersonalAccesses,
    includeTeamAccesses,
    skip = false,
  } = args;
  const query = useUserQuery({
    skip,
    variables: {
      id,
      includeFinalAccesses,
      includeFullData,
      includePersonalAccesses,
      includeTeamAccesses,
    },
    fetchPolicy,
  });
  const { refetch, error, loading, data: queryData, previousData } = query;
  const data = queryData || previousData;
  const firstLoading = loading && queryData === undefined;

  return {
    data: data?.data as User,
    loading,
    error,
    refetch,
    firstLoading,
  };
}
