import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import _get from 'lodash/get';
import _noop from 'lodash/noop';
import { FormTemplateVersionStatusEnum } from 'graphql-common';
import { Values } from '@lib/interfaces/form';
import WhiteBox from '@lib/components/WhiteBox/WhiteBox';
import Typography from '@lib/components/Typography/Typography';
import ActionsMenu from '@lib/components/ActionsMenu/ActionsMenu';
import Button, {
  ButtonSizes,
  ButtonTypes,
} from '@lib/components/Button/Button';
import MaterialIcon from '@lib/components/MaterialIcon/MaterialIcon';
import Chip from '@lib/components/Chip/Chip';
import VersionIcon from '@lib/assets/icons/version_icon.svg?react';
import entityViewStyles from '@lib/assets/styles/EntityView.module.scss';
import { getFormattedDate } from '@lib/utils/dateTimeHelpers';
import Tooltip from '@lib/components/Tooltip/Tooltip';
import { SelectProps } from '@lib/components/Select/Select';
import FormTemplateStatusChip from 'components/FormTemplateStatusChip/FormTemplateStatusChip';
import { NavItem } from '@lib/components/Menu/Menu';
import { materialSymbolsOutlined } from '@lib/enums/common';
import FormTemplateVersionSelect from 'components/FormTemplateVersionSelect/FormTemplateVersionSelect';
import { InputSizes } from '@lib/components/Input/enums';
import { SelectOption } from '@lib/components/Select/types';
import IconButton from '@lib/components/IconButton/IconButton';
import ChipControl from '@lib/components/Select/components/ChipControl';
import ChipValueContainerForChipControl from '@lib/components/Select/components/ChipValueContainerForChipControl';
import DashboardTitleHeader from '@lib/layouts/DashboardLayout/DashboardTitleHeader/DashboardTitleHeader';
import ViewOnlyChip from '@lib/components/ViewOnlyChip/ViewOnlyChip';
import TextEditor from '@lib/components/TextEditor/TextEditor';
import getTemplateData from './utils/getTemplateData';
import styles from './FormTemplateBuilderView.module.scss';

interface Props {
  actions: NavItem<unknown>[];
  formTemplateData: Values;
  formTemplateId: string;
  formTemplateVersionData: Values;
  loadingState: boolean;
  onCreateNewFormTemplateVersion?: () => void;
  onCreateTaskHandler: () => void;
  onDuplicateHandler?: () => void;
  onFormTemplateBulkRestoreHandler?: () => void;
  onFormTemplateVersionRestoreHandler?: () => void;
  onViewFormTemplateVersion?: (versionId: string) => void;
  selectedVersionOption?: SelectOption;
  showVersionInfo?: boolean;
  versionActions?: NavItem<unknown>[];
  isViewOnly: boolean;
}

export default function FormTemplateBuilderMainBlockView(props: Props) {
  const {
    actions,
    formTemplateData,
    formTemplateId,
    formTemplateVersionData,
    loadingState,
    onCreateNewFormTemplateVersion,
    onCreateTaskHandler,
    onDuplicateHandler,
    onFormTemplateBulkRestoreHandler,
    onFormTemplateVersionRestoreHandler,
    onViewFormTemplateVersion = _noop,
    selectedVersionOption,
    showVersionInfo,
    versionActions = [],
    isViewOnly,
  } = props;

  const { t } = useTranslation();

  const onVersionChange: SelectProps['onChange'] = (newValue) => {
    onViewFormTemplateVersion(_get(newValue, 'value', ''));
  };

  const {
    archivedAt,
    categoryColor,
    categoryName,
    createdAt,
    description,
    name,
    status,
    visibleLinkedTasksCount,
  } = getTemplateData({ formTemplateData, formTemplateVersionData, t });

  const getHeaderActions = () => {
    if (isViewOnly) return <ViewOnlyChip />;
    return formTemplateData?.archivedAt ? (
      <>
        <Button
          buttonText={t('restore')}
          buttonSize={ButtonSizes.large}
          buttonType={ButtonTypes.primaryFilled}
          leftIcon="restore_page"
          leftIconClassName={materialSymbolsOutlined}
          onClick={onFormTemplateBulkRestoreHandler}
          disabled={loadingState}
        />
        <IconButton
          icon="content_copy"
          onClick={onDuplicateHandler}
          disabled={loadingState}
        />
      </>
    ) : (
      <>
        <Button
          buttonText={t('create-a-task')}
          buttonSize={ButtonSizes.large}
          buttonType={ButtonTypes.primaryFilled}
          leftIcon="task_alt"
          onClick={onCreateTaskHandler}
          disabled={
            loadingState || status === FormTemplateVersionStatusEnum.Archived
          }
        />
        <ActionsMenu actions={actions} disabled={loadingState} />
      </>
    );
  };

  return (
    <WhiteBox className={styles.mainBlock}>
      <DashboardTitleHeader
        actions={getHeaderActions()}
        title={name}
        withBottomSpace={false}
      />
      {categoryName && (
        <div>
          <Chip type={categoryColor} label={categoryName} leftIcon="circle" />
        </div>
      )}
      {description && (
        <div>
          <TextEditor
            readOnly
            value={description}
            className={styles.description}
          />
        </div>
      )}
      {showVersionInfo && (
        <>
          <hr className={entityViewStyles.hrLine} />
          <div
            className={classNames(
              entityViewStyles.infoItem,
              entityViewStyles.infoItemCentered,
            )}
          >
            <p className={entityViewStyles.infoItemLabel}>
              <VersionIcon />
              <Typography variant="caption">{t('version')}</Typography>
            </p>
            {isViewOnly ? (
              <div className={entityViewStyles.infoItemContentBox}>
                <Typography variant="body">
                  {selectedVersionOption?.label || '-'}
                </Typography>
              </div>
            ) : (
              <div className={entityViewStyles.infoItemContentBox}>
                <div className={styles.versionTools}>
                  {selectedVersionOption &&
                  selectedVersionOption.label !== '-' ? (
                    <FormTemplateVersionSelect
                      disabled={loadingState}
                      formTemplateId={formTemplateId}
                      onChange={onVersionChange}
                      value={selectedVersionOption}
                      components={{
                        Control: ChipControl,
                        ValueContainer: ChipValueContainerForChipControl,
                      }}
                      size={InputSizes.large}
                    />
                  ) : (
                    <Typography variant="body">-</Typography>
                  )}
                  {!formTemplateData?.archivedAt && (
                    <div className={styles.versionActions}>
                      {status === FormTemplateVersionStatusEnum.Archived ? (
                        <Button
                          buttonText={t('restore-version')}
                          buttonSize={ButtonSizes.large}
                          buttonType={ButtonTypes.primaryOutlined}
                          leftIcon="restore_page"
                          leftIconClassName={materialSymbolsOutlined}
                          onClick={onFormTemplateVersionRestoreHandler}
                          disabled={loadingState}
                        />
                      ) : (
                        <Button
                          buttonText={t('create-new-version')}
                          buttonSize={ButtonSizes.large}
                          buttonType={ButtonTypes.primaryOutlined}
                          leftIcon="edit"
                          leftIconClassName={materialSymbolsOutlined}
                          onClick={onCreateNewFormTemplateVersion}
                          disabled={loadingState}
                        />
                      )}
                      {versionActions.length > 0 && (
                        <ActionsMenu
                          actions={versionActions}
                          disabled={loadingState}
                        />
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className={entityViewStyles.infoItem}>
            <p className={entityViewStyles.infoItemLabel}>
              <MaterialIcon icon="clock_loader_20" symbolsOutlined />
              <Typography variant="caption">{t('status')}</Typography>
            </p>
            <div className={entityViewStyles.infoItemContent}>
              {status ? (
                <FormTemplateStatusChip status={status} />
              ) : (
                <Typography variant="body">-</Typography>
              )}
            </div>
          </div>
          <div className={entityViewStyles.infoItem}>
            <p className={entityViewStyles.infoItemLabel}>
              <MaterialIcon icon="calendar_today" symbolsOutlined />
              <Typography variant="caption">{t('date-created')}</Typography>
            </p>
            <div className={entityViewStyles.infoItemContent}>
              <Typography variant="body">
                {createdAt ? getFormattedDate(createdAt) : '-'}
              </Typography>
            </div>
          </div>
          {status === FormTemplateVersionStatusEnum.Archived && (
            <div className={entityViewStyles.infoItem}>
              <p className={entityViewStyles.infoItemLabel}>
                <MaterialIcon icon="archive" symbolsOutlined />
                <Typography variant="caption">{t('archiving-date')}</Typography>
              </p>
              <div className={entityViewStyles.infoItemContent}>
                <Typography variant="body">
                  {archivedAt ? getFormattedDate(archivedAt) : '-'}
                </Typography>
              </div>
            </div>
          )}
          {status !== FormTemplateVersionStatusEnum.Archived && (
            <div className={entityViewStyles.infoItem}>
              <p className={entityViewStyles.infoItemLabel}>
                <MaterialIcon icon="task_alt" symbolsOutlined />
                <Typography variant="caption">{t('linked-tasks')}</Typography>
              </p>
              <div className={entityViewStyles.infoItemContent}>
                <div className={styles.infoBox}>
                  {visibleLinkedTasksCount === undefined ? (
                    <Typography variant="body">-</Typography>
                  ) : (
                    <>
                      <Typography variant="body">
                        {visibleLinkedTasksCount}
                      </Typography>
                      <Tooltip body={t('linked-tasks-desc')} maxWidth={310}>
                        <MaterialIcon
                          icon="info"
                          size="md-16"
                          symbolsOutlined
                          className={styles.infoBoxIcon}
                        />
                      </Tooltip>
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </WhiteBox>
  );
}
