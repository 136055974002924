import React from 'react';

export function transformToUpperCase(
  event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
) {
  const newValue = event.target.value as string;
  return {
    ...event,
    target: { value: newValue.toUpperCase() },
  } as React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;
}

export function transformToLowerCase(
  event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
) {
  const newValue = event.target.value as string;
  return {
    ...event,
    target: { value: newValue.toLowerCase() },
  } as React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;
}
