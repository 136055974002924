import { Values } from '@lib/interfaces/form';
import { TFunction } from 'i18next';
import _get from 'lodash/get';
import _find from 'lodash/find';
import _toInteger from 'lodash/toInteger';
import FormTemplateFieldNames from '@lib/enums/fieldNames/formTemplateFieldNames';
import {
  FormTemplateCreateInputObject,
  FormTemplateQuestionFieldsKindEnum,
  FormTemplateQuestionFieldValidityHintConfigInputObject,
  FormTemplateQuestionUpdateInputObject,
  FormTemplateUpdateInputObject,
  FormTemplateVersionCuInputObject,
  GroupEnum,
} from 'graphql-common';
import {
  kindFieldsWithAllowDisplayLastAnswer,
  kindFieldsWithNumericInputType,
  kindFieldsWithResponseLimitByGroup,
} from 'components/FormTemplateBuilder/constants';
import convertToDecimal from '@lib/utils/convertToDecimal';
import getPrefilledQuestions from 'components/FormTemplateBuilder/utils/getPrefilledQuestions';
import { SelectOption } from '@lib/components/Select/types';
import mergeAndUnlinkAttachedFiles from '@lib/utils/mergeAndUnlinkAttachedFiles';

function getPreparedFormTemplateQuestionFieldOptions(field: Values) {
  const kind = _get(
    field,
    FormTemplateFieldNames.Kind,
  ) as FormTemplateQuestionFieldsKindEnum;
  const options = _get(field, FormTemplateFieldNames.Options, []) as Values[];
  const validityHintConfig = _get(
    field,
    FormTemplateFieldNames.ValidityHintConfig,
  ) as Values;
  if (!options) return { options: [], validityHintConfig: undefined };
  let newValidityHintConfig:
    | FormTemplateQuestionFieldValidityHintConfigInputObject[]
    | undefined;
  if (kind === FormTemplateQuestionFieldsKindEnum.CheckboxButtonGrid) {
    newValidityHintConfig = Object.keys(validityHintConfig).map((key) => ({
      optionPositions: key.split('-').map((str) => _toInteger(str)),
      validityHint: _get(validityHintConfig, [key, 'value']),
    }));
  }
  const newOptions: Values[] = [];
  options.forEach((option, index) => {
    let newValues: string[] | undefined;
    const values = _get(option, FormTemplateFieldNames.Values, []) as string[];
    const condition =
      _get(option, FormTemplateFieldNames.Condition) || undefined;
    const group = _get(option, FormTemplateFieldNames.Group) || undefined;
    const position = _get(option, FormTemplateFieldNames.Position);
    if (newValidityHintConfig?.length) {
      const optionPositionsIndexToChange = group === GroupEnum.Columns ? 1 : 0;
      newValidityHintConfig.forEach(
        (item: FormTemplateQuestionFieldValidityHintConfigInputObject) => {
          const { optionPositions } = item;
          if (optionPositions[optionPositionsIndexToChange] === position) {
            optionPositions[optionPositionsIndexToChange] = index;
          }
        },
      );
    }
    if (
      values !== null &&
      kind &&
      kindFieldsWithNumericInputType.includes(kind)
    ) {
      newValues = values.map((value: string) => `${convertToDecimal(value)}`);
    } else if (kind === FormTemplateQuestionFieldsKindEnum.YesNo) {
      newValues = undefined;
    } else {
      newValues = values;
    }
    newOptions.push({
      [FormTemplateFieldNames.Condition]: condition,
      [FormTemplateFieldNames.Group]: group,
      [FormTemplateFieldNames.Position]: index,
      [FormTemplateFieldNames.ValidityHint]: _get(
        option,
        `${FormTemplateFieldNames.ValidityHint}.value`,
      ),
      [FormTemplateFieldNames.Values]: newValues,
    });
  });
  return {
    [FormTemplateFieldNames.Options]: newOptions,
    [FormTemplateFieldNames.Settings]: _get(
      field,
      FormTemplateFieldNames.Settings,
    ),
    [FormTemplateFieldNames.ValidityHintConfig]: newValidityHintConfig,
  };
}

function getPreparedFormTemplateQuestionFields(
  fields: Values[],
  required: boolean,
) {
  if (!fields || !Array.isArray(fields)) return [];
  return fields.map((field, index) => {
    const kind = _get(
      field,
      FormTemplateFieldNames.Kind,
    ) as FormTemplateQuestionFieldsKindEnum;
    const { options, validityHintConfig } =
      getPreparedFormTemplateQuestionFieldOptions(field);
    return {
      [FormTemplateFieldNames.Id]: field.id,
      [FormTemplateFieldNames.Kind]: field.kind,
      [FormTemplateFieldNames.Options]: options,
      [FormTemplateFieldNames.ValidityHintConfig]: validityHintConfig,
      [FormTemplateFieldNames.Settings]: {
        [FormTemplateFieldNames.AllowDisplayLastAnswer]:
          kindFieldsWithAllowDisplayLastAnswer.includes(kind)
            ? _get(field, FormTemplateFieldNames.AllowDisplayLastAnswer)
            : undefined,
        [FormTemplateFieldNames.LimitToOneRespPerRow]:
          kindFieldsWithResponseLimitByGroup.includes(kind)
            ? _get(field, FormTemplateFieldNames.LimitToOneRespPerRow)
            : undefined,
        [FormTemplateFieldNames.LimitToOneRespPerColumn]:
          kindFieldsWithResponseLimitByGroup.includes(kind)
            ? _get(field, FormTemplateFieldNames.LimitToOneRespPerColumn)
            : undefined,
      },
      [FormTemplateFieldNames.Position]: index,
      [FormTemplateFieldNames.Required]: required,
    };
  });
}

function getPreparedFormTemplateQuestion(
  question: Values,
  index: number,
  prevValue: Values,
  t: TFunction<'translation', undefined>,
) {
  const { required, fields } = question;

  const attachmentsAttached = mergeAndUnlinkAttachedFiles(
    question,
    prevValue,
    FormTemplateFieldNames.AttachmentsAttached,
  );

  const prefilledQuestions = getPrefilledQuestions({ t });
  return {
    [FormTemplateFieldNames.Fields]: getPreparedFormTemplateQuestionFields(
      fields as Values[],
      !!required,
    ),
    [FormTemplateFieldNames.Id]: prefilledQuestions
      .map(({ id }) => id)
      .includes(question.id as string)
      ? undefined
      : question.id,
    [FormTemplateFieldNames.Name]: question.name,
    [FormTemplateFieldNames.Description]: question.description,
    [FormTemplateFieldNames.AttachmentsAttached]: attachmentsAttached.length
      ? attachmentsAttached
      : undefined,
    [FormTemplateFieldNames.Position]: index,
  };
}

export function getPreparedFormTemplateVersionDataFromValues(args: {
  values: Values;
  prevValues: Values;
  t: TFunction<'translation', undefined>;
}): FormTemplateVersionCuInputObject {
  const { values, prevValues, t } = args;
  const questionsValues = _get(
    values,
    FormTemplateFieldNames.Questions,
    [],
  ) as Values[];
  const prevQuestions = _get(
    prevValues,
    FormTemplateFieldNames.Questions,
    [],
  ) as Values[];
  const questions = questionsValues.map((question, index) => {
    const prevQuestionValues = _find(prevQuestions, { id: question.id }) || {};
    return getPreparedFormTemplateQuestion(
      question,
      index,
      prevQuestionValues,
      t,
    );
  }) as FormTemplateQuestionUpdateInputObject[];
  const taskIds = (
    _get(values, FormTemplateFieldNames.Tasks, []) as SelectOption[]
  ).map(({ value }) => value) as string[];

  return {
    questions,
    taskIds,
  };
}

export default function getPreparedFormTemplateDataFromValues(args: {
  values: Values;
  prevValues: Values;
  t: TFunction<'translation', undefined>;
  isCreate?: boolean;
}): FormTemplateCreateInputObject | FormTemplateUpdateInputObject {
  const { values, prevValues, isCreate, t } = args;
  return {
    name: _get(values, FormTemplateFieldNames.Name) as string,
    description: _get(values, FormTemplateFieldNames.Description) as string,
    categoryId: _get(
      values,
      `${FormTemplateFieldNames.Category}.value`,
    ) as string,
    version: isCreate
      ? getPreparedFormTemplateVersionDataFromValues({ values, prevValues, t })
      : undefined,
  };
}
