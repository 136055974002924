export const QR_CODE_JIDS = 'QR_CODE_JIDS';
export const USER_QRCODE_DATA = 'USER_QRCODE_DATA';
export const USER_SELECTED_SCANNER_ACTION = 'USER_SELECTED_SCANNER_ACTION';
export const USER_SELECTED_CAMERA_ID = 'USER_SELECTED_CAMERA_ID';
export const USER_COMPLETABLE_TODO_TASKS = 'USER_COMPLETABLE_TODO_TASKS_NEW';
export const DASHBOARD_LAYOUT_IS_COLLAPSED = 'DASHBOARD_LAYOUT_IS_COLLAPSED';
export const COUNTER_MONITORING_SIDEBAR_IS_COLLAPSED =
  'COUNTER_MONITORING_SIDEBAR_IS_COLLAPSED';
export const COUNTER_MONITORING_LAST_ASSET_ID =
  'COUNTER_MONITORING_LAST_ASSET_ID';
export const COUNTER_MONITORING_LAST_COUNTER_IDS =
  'COUNTER_MONITORING_LAST_COUNTER_IDS';
export const FILE_PREVIEW_MODAL_MODE = 'FILE_PREVIEW_MODAL_MODE';
export const TASK_FILTER_FORM = 'TASK_FILTER_FORM';
export const INTERVENTION_FILTER_FORM = 'INTERVENTION_FILTER_FORM';
export const UPGRADE_PLAN_BANNER = 'UPGRADE_PLAN_BANNER';
