import React from 'react';
import {
  components as DefaultSelectComponents,
  DropdownIndicatorProps,
} from 'react-select';
import classNames from 'classnames';
import _isEmpty from 'lodash/isEmpty';
import MaterialIcon from '@lib/components/MaterialIcon/MaterialIcon';
import Tooltip from '@lib/components/Tooltip/Tooltip';
import { TooltipShape } from '@lib/components/Input/enums';
import styles from '../Select.module.scss';

interface Props {
  selectProps: {
    disabled?: boolean;
    disabledTooltipProps?: TooltipShape;
  };
}

function DropdownIndicator(props: DropdownIndicatorProps & Props): JSX.Element {
  const { selectProps } = props;
  const { disabled, disabledTooltipProps } = selectProps;
  const isDisabledTooltipVisible = disabled && !_isEmpty(disabledTooltipProps);
  const icon = (
    <div className={styles.dropdownIndicatorIcon}>
      <MaterialIcon
        icon={disabled ? 'lock' : 'arrow_drop_down'}
        symbolsOutlined
        size="md-24"
        className={classNames({ disabled })}
      />
    </div>
  );
  return (
    <DefaultSelectComponents.DropdownIndicator {...props}>
      {isDisabledTooltipVisible ? (
        // @ts-ignore
        <Tooltip
          key="disabled-icon-tooltip"
          maxWidth={160}
          {...disabledTooltipProps}
        >
          {icon}
        </Tooltip>
      ) : (
        icon
      )}
    </DefaultSelectComponents.DropdownIndicator>
  );
}

export default DropdownIndicator;
