import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  FieldValues,
  UseFieldArrayAppend,
  useFormContext,
} from 'react-hook-form';
import _get from 'lodash/get';
import classNames from 'classnames';
import Button, {
  ButtonSizes,
  ButtonTypes,
} from '@lib/components/Button/Button';
import Tooltip from '@lib/components/Tooltip/Tooltip';
import MaterialIcon from '@lib/components/MaterialIcon/MaterialIcon';
import { AppendValue } from '@lib/components/ReactHookForm/types';
import styles from './TaskAssignationsOptionsAddItemComponent.module.scss';

interface Props {
  append: UseFieldArrayAppend<FieldValues, string>;
  appendCallback?: () => void;
  appendValue: AppendValue;
  isAddMoreVisible: boolean;
  parentName: string;
}

function TaskAssignationsOptionsAddItemComponent({
  append,
  appendCallback,
  appendValue = {},
  isAddMoreVisible,
  parentName,
}: Props) {
  const { t } = useTranslation();
  const { getValues } = useFormContext();
  const values = getValues();
  const fieldValue = _get(values, parentName);
  const className = classNames(
    styles.optionAddBtn,
    styles.optionAddBtnWithBottomPadding,
    {
      [styles.optionAddBtnWithTopPadding]: fieldValue?.length,
    },
  );
  if (!isAddMoreVisible || !append) return null;

  return (
    <div className={className}>
      <Button
        buttonText={t('add-more')}
        buttonType={ButtonTypes.secondaryOutlined}
        buttonSize={ButtonSizes.small}
        leftIcon="add"
        onClick={() => {
          append(appendValue);
          if (appendCallback) appendCallback();
        }}
      />
      <Tooltip body={t('task-assignations-description')} maxWidth={200}>
        <MaterialIcon icon="info" symbolsOutlined className={styles.infoIcon} />
      </Tooltip>
    </div>
  );
}

export default TaskAssignationsOptionsAddItemComponent;
