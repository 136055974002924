import React from 'react';
import { useTranslation } from 'react-i18next';
import _get from 'lodash/get';
import { GraphQLErrors } from '@apollo/client/errors';
import { Values, Value } from '@lib/interfaces/form';
import Form, {
  ActionsProps,
} from '@lib/components/ReactHookForm/FormContainer';
import replaceNullsWithUndefined from '@lib/utils/replaceNullsWithUndefined';
import Button, { ButtonTypes } from '@lib/components/Button/Button';
import ActionsRow from '@lib/components/ActionsRow/ActionsRow';
import { getCodeConductFields } from 'components/Site/utils';

export interface FormProps {
  siteData: Values;
  graphQLErrors?: GraphQLErrors;
  loading: boolean;
  onCancel: () => void;
  onSubmit: (values: Values) => void;
}

function ConductCodeForm(props: FormProps) {
  const { siteData, graphQLErrors, loading, onCancel, onSubmit } = props;
  const { t } = useTranslation();
  const fields = getCodeConductFields({ t });

  const siteConductCodeSections = _get(
    siteData,
    'siteConductCodeSections',
    [],
  ) as Value[];

  const actions = ({ isDirty }: ActionsProps) => (
    <ActionsRow
      actions={[
        <Button
          buttonType={ButtonTypes.secondaryOutlined}
          buttonText={t('cancel')}
          leftIcon="close"
          onClick={onCancel}
          fullWidth
          disabled={loading}
        />,
        <Button
          type="submit"
          buttonType={ButtonTypes.primaryFilled}
          buttonText={t('save-changes')}
          leftIcon="check"
          fullWidth
          isLoading={loading}
          disabled={!isDirty}
        />,
      ]}
    />
  );

  const preparedDefaultValues = replaceNullsWithUndefined({
    conductCodeSections: siteConductCodeSections,
  });

  return (
    <Form
      formId="user"
      defaultValues={preparedDefaultValues}
      onSubmit={onSubmit}
      fields={fields}
      actions={actions}
      graphQLErrors={graphQLErrors}
      loading={loading}
      sourceAppendValues={{
        conductCodeSections: { title: '', body: '' },
      }}
    />
  );
}

export default ConductCodeForm;
