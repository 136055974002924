import _upperFirst from 'lodash/upperFirst';
import _toLower from 'lodash/toLower';
import { Task } from 'graphql-common';
import getFullName from '@lib/utils/getFullName';
import { Values } from '@lib/interfaces/form';
import { FieldNames } from 'routes/Task/enums';

export default function getTaskValuesFormFromQueryData(taskData: Task): Values {
  return {
    [FieldNames.assignations]: [
      {
        siteArea: taskData.siteArea?.id
          ? {
              label: taskData.siteArea.name,
              value: taskData.siteArea.id,
              data: taskData.siteArea,
            }
          : undefined,
        asset: taskData.asset?.id
          ? {
              label: taskData.asset.name,
              value: taskData.asset.id,
              data: taskData.asset,
            }
          : undefined,
      },
    ],
    [FieldNames.taskName]: taskData.name,
    [FieldNames.taskDescription]: taskData.description || '',
    [FieldNames.template]: taskData.formTemplate
      ? {
          value: taskData.formTemplate?.id,
          label: taskData.formTemplate?.name,
        }
      : undefined,
    [FieldNames.templateVersion]: taskData.formTemplateVersion
      ? {
          value: taskData.formTemplateVersion?.id,
          label: taskData.formTemplateVersion?.version,
        }
      : undefined,
    [FieldNames.priority]: taskData.priority
      ? {
          label: _upperFirst(_toLower(taskData.priority)),
          value: taskData.priority,
        }
      : undefined,
    [FieldNames.site]: taskData.site
      ? { label: taskData.site.name, value: taskData.site.id }
      : undefined,
    [FieldNames.approver]: taskData.approver
      ? {
          label: getFullName(taskData.approver),
          value: taskData.approver.id,
          data: taskData.approver,
        }
      : undefined,
  };
}
