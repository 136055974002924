import { TFunction } from 'i18next';
import { TeamViewMode } from '../enums';

type Args = {
  t: TFunction<'translation', undefined>;
  membersCounter: number;
};

export default function getTableTabs({ t, membersCounter = 0 }: Args) {
  return [
    {
      label: t('team-details'),
      value: TeamViewMode.ViewDetails,
    },
    {
      label: t('members'),
      value: TeamViewMode.ViewMembers,
      counter: membersCounter,
    },
  ];
}
