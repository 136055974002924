import { useTranslation } from 'react-i18next';
import {
  useTeamCreateMutation,
  useTeamDestroyMutation,
  useTeamUpdateMutation,
} from 'graphql-common';
import { showGraphQLErrors } from '@lib/utils/graphQLErrors';

export default function useMutations() {
  const { t } = useTranslation();

  const [teamCreateMutation, teamCreateMutationResult] = useTeamCreateMutation({
    onError: (error) => showGraphQLErrors(error, t),
  });

  const [teamUpdateMutation, teamUpdateMutationResult] = useTeamUpdateMutation({
    onError: (error) => showGraphQLErrors(error, t),
  });

  const [teamDestroyMutation] = useTeamDestroyMutation({
    onError: (error) => showGraphQLErrors(error, t),
  });

  return {
    teamCreateMutation,
    teamCreateMutationResult,
    teamDestroyMutation,
    teamUpdateMutation,
    teamUpdateMutationResult,
  };
}
