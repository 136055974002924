import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Typography from '@lib/components/Typography/Typography';
import Placeholder from '@lib/assets/icons/img-placeholder.svg?react';
import styles from './AssetPreview.module.scss';

interface Props {
  icon?: string | null;
  url?: string;
  name: string;
  disabled?: boolean;
  code?: string | null;
}

function AssetPreview(props: Props) {
  const { icon, url, name, disabled, code } = props;
  const iconRender = icon ? <img src={icon} alt="" /> : <Placeholder />;

  const content = (
    <div
      className={classNames(styles.wrap, { [styles.wrapDisabled]: disabled })}
    >
      <div className={styles.icon}>{iconRender}</div>
      <div className={styles.info}>
        <Typography variant="caption" strong className={styles.name}>
          {name}
        </Typography>
        {code && (
          <Typography variant="caption" className={styles.code}>
            {code}
          </Typography>
        )}
      </div>
    </div>
  );

  if (url) {
    return <Link to={url}>{content}</Link>;
  }

  return content;
}

export default AssetPreview;
