import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button, { ButtonTypes } from '@lib/components/Button/Button';
import ActionsRow from '@lib/components/ActionsRow/ActionsRow';
import { APP_URLS } from 'constants/urls';
import styles from './Site.module.scss';

interface Props {
  isDirty: boolean;
  loading?: boolean;
}

function SiteCreateFormActions({ isDirty, loading }: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <ActionsRow
      className={styles.formActions}
      actions={[
        <Button
          key="close"
          buttonType={ButtonTypes.secondaryOutlined}
          buttonText={t('cancel')}
          leftIcon="close"
          fullWidth
          onClick={() =>
            navigate(APP_URLS.dashboard.sites.index.getPathWithQuery())
          }
          disabled={loading}
        />,
        <Button
          buttonText={t('save-changes')}
          buttonType={ButtonTypes.primaryFilled}
          disabled={!isDirty}
          fullWidth
          isLoading={loading}
          key="submit"
          leftIcon="check"
          type="submit"
        />,
      ]}
    />
  );
}

export default SiteCreateFormActions;
