import { ChipTypes } from '@lib/components/Chip/Chip';
import { TaskDeadlineMissedReasonEnum } from 'graphql-common';

export default function getDeadlineMissedReasonChipType(
  status: TaskDeadlineMissedReasonEnum | null,
) {
  switch (status) {
    case TaskDeadlineMissedReasonEnum.SiteClosed:
      return ChipTypes.chipYellow;
    case TaskDeadlineMissedReasonEnum.UnderConstruction:
      return ChipTypes.chipBlue;
    case TaskDeadlineMissedReasonEnum.Inaccessible:
      return ChipTypes.chipPink;
    case TaskDeadlineMissedReasonEnum.StaffProblem:
      return ChipTypes.chipViolet;
    case TaskDeadlineMissedReasonEnum.Other:
      return ChipTypes.chipGray;
    default:
      return ChipTypes.chipRed;
  }
}
