import React from 'react';
import { useTranslation } from 'react-i18next';
import { Values } from '@lib/interfaces/form';
import { GraphQLErrors } from '@apollo/client/errors';
import Form from '@lib/components/ReactHookForm/FormContainer';
import replaceNullsWithUndefined from '@lib/utils/replaceNullsWithUndefined';
import AssetFieldNames from '@lib/enums/fieldNames/assetCategoryFieldNames';
import getCategoryFormFields from './utils/getCategoryFormFields';
import CategoryFormActions from './CategoryFormActions';

interface Props {
  defaultValues?: Values;
  graphQLErrors?: GraphQLErrors;
  loading: boolean;
  onCancel: () => void;
  onSubmit: (values: Values) => void;
}

const emptyDefaultValues = {
  [AssetFieldNames.Color]: null,
  [AssetFieldNames.Id]: null,
  [AssetFieldNames.Name]: null,
  [AssetFieldNames.Prefix]: null,
  [AssetFieldNames.RefreshQrPrefixForAssets]: null,
};

function CategoryForm(props: Props) {
  const {
    defaultValues = emptyDefaultValues,
    graphQLErrors,
    loading,
    onCancel,
    onSubmit,
  } = props;
  const { t } = useTranslation();
  const isEditForm = !!defaultValues?.id;
  const fields = getCategoryFormFields({
    t,
  });

  return (
    <Form
      defaultValues={replaceNullsWithUndefined(defaultValues)}
      onSubmit={onSubmit}
      fields={fields}
      actionsComponent={CategoryFormActions}
      actionsComponentProps={{
        isEditForm,
        loading,
        onCancel,
      }}
      graphQLErrors={graphQLErrors}
      loading={loading}
    />
  );
}

export default CategoryForm;
