import {
  Site,
  SiteFilterInputObject,
  SitesQueryVariables,
  useSitesQuery,
} from 'graphql-common';
import _get from 'lodash/get';
import { GetUrlParams } from '@lib/enums/urls';
import createSearchFilters from '@lib/utils/createSearchFilters';
import { ListQueryHookProps } from './types';

export default function useSitesQueryHook({
  searchQuery,
  paginationParams,
}: ListQueryHookProps) {
  const page = _get(paginationParams, GetUrlParams.Page);
  const perPage = _get(paginationParams, GetUrlParams.PerPage);
  const orderingField = _get(paginationParams, GetUrlParams.OrderingField);
  const orderingDirection = _get(
    paginationParams,
    GetUrlParams.OrderingDirection,
  );
  const sitesQueryVariables: SitesQueryVariables = {
    page: Number(page),
    limit: Number(perPage),
  };
  if (orderingField && orderingDirection) {
    sitesQueryVariables.sorts = {
      [orderingField]: orderingDirection,
    };
  }
  const filters: SiteFilterInputObject[] = [];
  if (searchQuery) {
    filters.push(
      ...createSearchFilters(searchQuery, [
        { fieldName: 'areasCode' },
        { fieldName: 'areasName' },
        { fieldName: 'code' },
        { fieldName: 'name' },
        { fieldName: 'userFullName' },
      ]),
    );
  }
  if (filters.length) {
    sitesQueryVariables.filters = filters;
  }
  const sitesQuery = useSitesQuery({
    fetchPolicy: 'cache-and-network',
    variables: sitesQueryVariables,
  });
  const {
    loading: sitesLoading,
    data,
    previousData,
    error: sitesError,
  } = sitesQuery;
  const queryData = data || previousData;
  const sitesFirstLoading = sitesLoading && previousData === undefined;
  const collection = _get(queryData, 'data.collection', []) as Site[];
  const metadata = _get(queryData, 'data.metadata');
  const totalCount = _get(metadata, 'totalCount', 0);

  return {
    collection,
    sitesError,
    sitesFirstLoading,
    sitesLoading,
    totalCount,
  };
}
