import React from 'react';
import { TFunction } from 'i18next';
import { NavLink } from 'react-router-dom';
import _get from 'lodash/get';
import { AssetCategory } from 'graphql-common';
import TextCell from '@lib/components/Table/commonComponents/TextCell/TextCell';
import ChipCell from '@lib/components/Table/commonComponents/ChipCell/ChipCell';
import { CellProps, Column, TableAction } from '@lib/components/Table/types';
import { ChipTypes } from '@lib/components/Chip/Chip';
import { IconButtonTypes } from '@lib/components/IconButton/IconButton';
import LinkComponent from '@lib/components/Link/Link';
import CollapseCell from '@lib/components/Table/commonComponents/CollapseCell/CollapseCell';
import { APP_URLS } from 'constants/urls';

type ColumnsArgs = {
  onEditHandler: (v: AssetCategory) => void;
  t: TFunction<'translation', undefined>;
  isAssetCategoriesViewOnly?: boolean;
  isAssetsLocked?: boolean;
};

export const getColumns = ({
  isAssetsLocked,
  onEditHandler,
  t,
  isAssetCategoriesViewOnly,
}: ColumnsArgs) => {
  const result: Column<AssetCategory>[] = [
    {
      id: 'name',
      Header: t('name'),
      accessor: 'name',
      Cell: ({ item }: CellProps<AssetCategory>) => {
        const label = _get(item, 'name');
        const type = _get(item, 'color') as ChipTypes;
        if (!label || !type) return '-';
        return (
          <ChipCell
            label={label}
            type={type}
            leftIcon="circle"
            onClick={
              isAssetCategoriesViewOnly ? undefined : () => onEditHandler(item)
            }
          />
        );
      },
      maxWidth: 250,
      minWidth: 250,
      width: 250,
      sortable: true,
    },
  ];
  if (!isAssetsLocked) {
    result.push(
      {
        id: 'visibleAssetsCount',
        Header: t('assets'),
        accessorFn: ({ item }: CellProps<AssetCategory>) =>
          item.visibleAssetsCount || '0',
        Cell: (props: CellProps<AssetCategory>) => <TextCell {...props} />,
        maxWidth: 250,
        minWidth: 250,
        width: 250,
        sortable: true,
      },
      {
        id: 'linkedAssets',
        Header: t('linked-assets'),
        accessorFn: ({ item }: CellProps<AssetCategory>) => {
          const { assets } = item;
          return assets.collection.map(({ id, name }) => (
            <LinkComponent
              key={id}
              to={APP_URLS.dashboard.assets.view.getDynamicPath({
                pathParts: { id },
              })}
              component={NavLink}
            >
              {name}
            </LinkComponent>
          ));
        },
        Cell: (props: CellProps<AssetCategory>) => (
          <CollapseCell {...props} minHeight={20} />
        ),
      },
    );
  }
  return result;
};

type ActionsMenuArgs = {
  onEditHandler: (v: AssetCategory) => void;
  onDelete: (v: AssetCategory) => void;
  t: TFunction<'translation', undefined>;
  isAssetCategoriesViewOnly: boolean;
};

export function getActions({
  onEditHandler,
  onDelete,
  isAssetCategoriesViewOnly,
  t,
}: ActionsMenuArgs): TableAction<AssetCategory>[] {
  return [
    {
      id: 'edit-action',
      icon: 'edit',
      onClick: (item: AssetCategory) => onEditHandler(item),
      type: IconButtonTypes.PrimaryOutlined,
      predictorFnVisible: () => !isAssetCategoriesViewOnly,
    },
    {
      id: 'delete-action',
      icon: 'delete',
      onClick: (item: AssetCategory) => onDelete(item),
      type: IconButtonTypes.PrimaryOutlined,
      predictorFnDisable: (item: AssetCategory) =>
        item.visibleAssetsCount > 0 || !item.canDestroy.value,
      predictorFnVisible: () => !isAssetCategoriesViewOnly,
      predictorFnDisableTooltipProps: () => ({
        body: t('cannot-delete-asset-category'),
        placement: 'left',
        maxWidth: 150,
      }),
    },
  ];
}
