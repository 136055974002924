import { useState } from 'react';

export default function useModalManagement() {
  const [isScheduleModalOpened, setScheduleModalOpened] = useState(false);
  const [isTaskDeleteModalOpened, setTaskDeleteModalOpened] = useState(false);
  const [isArchiveModalOpened, setArchiveModalOpened] = useState(false);

  const openTaskDeleteModal = () => setTaskDeleteModalOpened(true);
  const closeTaskDeleteModal = () => setTaskDeleteModalOpened(false);
  const openScheduleModal = () => setScheduleModalOpened(true);
  const closeScheduleModal = () => setScheduleModalOpened(false);
  const openArchiveModal = () => setArchiveModalOpened(true);
  const closeArchiveModal = () => setArchiveModalOpened(false);

  return {
    closeArchiveModal,

    closeScheduleModal,
    closeTaskDeleteModal,

    isArchiveModalOpened,

    isScheduleModalOpened,
    isTaskDeleteModalOpened,
    openArchiveModal,
    openScheduleModal,
    openTaskDeleteModal,
  };
}
