import { GroupEnum } from 'graphql-common';
import { FieldArrayItemType } from '@lib/components/ReactHookForm/types';
import { Values } from '@lib/interfaces/form';

type PreparedFields = {
  fieldItem: FieldArrayItemType;
  index: number;
}[];

export default function getSortedFields(
  fields: FieldArrayItemType[],
  options: Values[],
) {
  const isOptionVisible = (index: number, group: GroupEnum) =>
    options?.[index]?.group === group;

  const rows: PreparedFields = [];
  const columns: PreparedFields = [];
  fields.forEach((fieldItem, index) => {
    if (isOptionVisible(index, GroupEnum.Rows)) rows.push({ fieldItem, index });
    if (isOptionVisible(index, GroupEnum.Columns))
      columns.push({ fieldItem, index });
  });

  return { rows, columns };
}
