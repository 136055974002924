import React from 'react';
import { TFunction } from 'i18next';
import * as Yup from 'yup';
import { getCodeValidation, getOptionShape } from '@lib/utils/yup';
import _get from 'lodash/get';
import Input from '@lib/components/Input/Input';
import { InputTypes } from '@lib/components/Input/enums';
import { FieldItem, Value, Values } from '@lib/interfaces/form';
import {
  EMAIL_REGEX,
  MAX_TEXT_EDITOR_FIELD_LENGTH,
  MAX_TEXT_FIELD_LENGTH,
  Steps,
} from '@lib/enums/form';
import Typography from '@lib/components/Typography/Typography';
import Select, { SelectComponents } from '@lib/components/Select/Select';
import ImageUploader from '@lib/components/ImageUploader/ImageUploader';
import getFullName from '@lib/utils/getFullName';
import UserOption from '@lib/components/Select/components/UserOption';
import { LoadOptions } from '@lib/components/Select/types';
import UserSingleValue from '@lib/components/Select/components/UserSingleValue';
import { FieldNames } from 'components/Site/enums';
import CheckBoxSingleBool from '@lib/components/CheckBoxSingleBool/CheckBoxSingleBool';
import MaterialIcon from '@lib/components/MaterialIcon/MaterialIcon';
import TextEditor from '@lib/components/TextEditor/TextEditor';
import { getFieldValidation } from '@lib/utils/validationUtils';
import styles from './Site.module.scss';

type AreaFormFieldsArgs = {
  isEditForm?: boolean;
  t: TFunction<'translation', undefined>;
};

export function getAreaFields({
  isEditForm,
  t,
}: AreaFormFieldsArgs): FieldItem[] {
  return [
    {
      name: FieldNames.name,
      label: t('area-name'),
      element: Input,
      isUnique: true,
      validation: Yup.string()
        .trim()
        .max(MAX_TEXT_FIELD_LENGTH, t('max-text-length-field-error'))
        .required(t('required-field-error')),
    },
    {
      name: FieldNames.code,
      label: t('area-code'),
      element: Input,
      isUnique: true,
      validation: getCodeValidation(t, 10, true),
      componentProps: {
        disabled: isEditForm,
      },
    },
    {
      name: FieldNames.address,
      label: t('area-address'),
      element: Input,
      validation: Yup.string()
        .trim()
        .max(MAX_TEXT_FIELD_LENGTH, t('max-text-length-field-error')),
    },
    {
      name: FieldNames.description,
      label: t('area-description'),
      element: TextEditor,
      validation: getFieldValidation(false, MAX_TEXT_EDITOR_FIELD_LENGTH, t),
    },
    {
      name: FieldNames.poorWifi,
      label: t('area-poor-wifi-label'),
      element: CheckBoxSingleBool,
      componentProps: {
        options: [{ label: 'checked', value: 'checked' }],
        rightLabelIcon: <MaterialIcon icon="wifi_off" size="md-16" />,
      },
    },
  ];
}

type FormFieldsArgs = {
  countriesCodes: string[];
  usersLoadOptions: LoadOptions;
  isEditForm?: boolean;
  openUserFormModal: () => void;
  t: TFunction<'translation', undefined>;
};

export function getSiteFields({
  countriesCodes,
  usersLoadOptions,
  isEditForm,
  openUserFormModal,
  t,
}: FormFieldsArgs): FieldItem[] {
  return [
    {
      name: 'site-details',
      customRender: () => (
        <Typography variant="h3" key="site-details">
          {t('site-details')}
        </Typography>
      ),
      step: Steps.step1,
    },
    {
      name: FieldNames.name,
      label: t('site-name'),
      element: Input,
      validation: Yup.string()
        .trim()
        .max(MAX_TEXT_FIELD_LENGTH, t('max-text-length-field-error'))
        .required(t('required-field-error')),
      step: Steps.step1,
    },
    {
      name: FieldNames.code,
      label: t('site-code'),
      element: Input,
      validation: getCodeValidation(t, 10, true),
      step: Steps.step1,
      componentProps: {
        disabled: isEditForm,
      },
    },
    {
      name: FieldNames.description,
      label: t('site-description'),
      element: TextEditor,
      validation: getFieldValidation(false, MAX_TEXT_EDITOR_FIELD_LENGTH, t),
      step: Steps.step1,
    },
    {
      name: 'contact-info',
      customRender: () => (
        <Typography
          variant="h3"
          key="contact-info"
          className={styles.blockStart}
        >
          {t('contact-info')}
        </Typography>
      ),
      step: Steps.step1,
    },
    {
      name: FieldNames.phoneNumber,
      label: t('site-phone-number'),
      element: Input,
      componentProps: {
        type: InputTypes.tel,
        onlyCountries: countriesCodes,
      },
      validation: Yup.object()
        .shape({
          country: Yup.string(),
          number: Yup.string().required(t('required-field-error')),
        })
        .required(t('required-field-error')),
      formItemClassName: styles.formColumn,
      step: Steps.step1,
    },
    {
      name: FieldNames.email,
      label: t('site-email'),
      element: Input,
      validation: Yup.string()
        .matches(EMAIL_REGEX, t('email-field-error'))
        .trim()
        .max(MAX_TEXT_FIELD_LENGTH, t('max-text-length-field-error'))
        .required(t('required-field-error')),
      formItemClassName: styles.formColumn,
      step: Steps.step1,
    },
    {
      name: FieldNames.userId,
      label: t('responsible-person'),
      description: t('responsible-person-description'),
      element: Select,
      validation: Yup.object()
        .shape(getOptionShape(t, true))
        .required(t('required-field-error')),
      componentProps: {
        isAsync: true,
        loadOptions: usersLoadOptions,
        defaultOptions: true,
        components: {
          Option: UserOption,
          SingleValue: UserSingleValue,
        } as unknown as SelectComponents,
        createNewOptionProps: {
          title: t('create-new'),
          onCLick: openUserFormModal,
        },
      },
      step: Steps.step1,
    },
    {
      name: 'location-info',
      customRender: () => (
        <Typography
          variant="h3"
          key="location-info"
          className={styles.blockStart}
        >
          {t('location-info')}
        </Typography>
      ),
      step: Steps.step1,
    },
    {
      name: FieldNames.address,
      label: t('site-address'),
      element: Input,
      validation: Yup.string()
        .trim()
        .max(MAX_TEXT_FIELD_LENGTH, t('max-text-length-field-error'))
        .required(t('required-field-error')),
      step: Steps.step1,
    },
    {
      name: FieldNames.lat,
      label: t('gps-coordinates-latitude'),
      element: Input,
      validation: Yup.number()
        .min(-90, t('latitude-min-error'))
        .max(90, t('latitude-max-error'))
        .typeError(t('number-field-error'))
        .required(t('required-field-error')),
      formItemClassName: styles.formColumn,
      step: Steps.step1,
    },
    {
      name: FieldNames.lon,
      label: t('gps-coordinates-longitude'),
      element: Input,
      validation: Yup.number()
        .min(-180, t('longitude-min-error'))
        .max(180, t('longitude-max-error'))
        .typeError(t('number-field-error'))
        .required(t('required-field-error')),
      formItemClassName: styles.formColumn,
      step: Steps.step1,
    },
    {
      name: 'location-photo',
      customRender: () => (
        <Typography
          variant="h3"
          key="location-photo"
          className={styles.blockStart}
        >
          {t('upload-location-photo-optional')}
        </Typography>
      ),
      step: Steps.step1,
    },
    {
      name: FieldNames.locationPhotoAttached,
      element: ImageUploader,
      step: Steps.step1,
    },
  ];
}

type CodeConductFormFieldsArgs = {
  step?: Steps;
  subFieldEmptyStateText?: string;
  subFieldEmptyStateTitle?: string;
  t: TFunction<'translation', undefined>;
};

export function getCodeConductFields({
  step,
  subFieldEmptyStateTitle,
  subFieldEmptyStateText,
  t,
}: CodeConductFormFieldsArgs): FieldItem[] {
  return [
    {
      name: FieldNames.conductCodeSections,
      subFieldEmptyStateTitle,
      subFieldEmptyStateText,
      subFieldTitle: t('section'),
      subFieldsEntity: 'section',
      subFields: [
        {
          name: FieldNames.title,
          label: t('section-title'),
          element: Input,
          isUnique: true,
          validation: Yup.string()
            .trim()
            .max(MAX_TEXT_FIELD_LENGTH, t('max-text-length-field-error'))
            .required(t('required-field-error')),
        },
        {
          name: FieldNames.body,
          label: t('section-text'),
          element: Input,
          componentProps: {
            type: InputTypes.textarea,
          },
          isUnique: true,
          validation: Yup.string().trim().required(t('required-field-error')),
        },
      ],
      step,
      isDraggable: true,
    },
  ];
}

export function getFormFields({
  countriesCodes,
  usersLoadOptions,
  openUserFormModal,
  t,
}: FormFieldsArgs): FieldItem[] {
  return [
    ...getSiteFields({
      countriesCodes,
      usersLoadOptions,
      openUserFormModal,
      t,
    }),
    {
      name: FieldNames.areas,
      subFieldEmptyStateTitle: t('create-areas-empty-state-title'),
      subFieldEmptyStateText: t('create-areas-empty-state-text'),
      subFieldTitle: t('area'),
      subFieldsEntity: 'area',
      subFields: getAreaFields({ t }),
      step: Steps.step2,
    },
    ...getCodeConductFields({
      t,
      step: Steps.step3,
      subFieldEmptyStateTitle: t('create-code-of-conduct-empty-state-title'),
      subFieldEmptyStateText: t('create-code-of-conduct-empty-state-text'),
    }),
  ];
}

export function getDefaultValuesFromQueryData(data?: Values): Values {
  const locationPhotoAttached = _get(data, 'locationPhotoAttached') || '';
  const newUserIdValue = _get(data, 'userId', undefined);
  const user = _get(data, 'user');
  const userIdValue = _get(user, 'id');
  const userFirstName = _get(data, 'user.firstName') as string;
  const userLastName = _get(data, 'user.lastName') as string;
  const userFullName = getFullName({
    firstName: userFirstName,
    lastName: userLastName,
  });
  const userId =
    newUserIdValue === undefined && userIdValue
      ? ({
          label: userFullName,
          value: userIdValue,
          data: user,
        } as Value)
      : newUserIdValue;
  const phoneNumberValue = _get(data, 'phoneNumber.international');
  const phoneNumberCountry = _get(data, 'phoneNumber.country');
  const phoneNumber = {
    country: phoneNumberCountry,
    number: phoneNumberValue,
  } as Value;
  const lat = _get(data, 'address.lat') as string;
  const lon = _get(data, 'address.lon') as string;
  return {
    name: data?.name || '',
    code: data?.code || '',
    description: data?.description || '',
    email: data?.email || '',
    address: _get(data, 'address.string') || '',
    lat: lat ? parseFloat(lat) : '',
    locationPhotoAttached,
    lon: lon ? parseFloat(lon) : '',
    phoneNumber,
    userId,
  };
}
