import { useState } from 'react';
import { InterventionCategory } from 'graphql-common';

interface UseIModalManagementProps {
  setSelectedCategory: (value: InterventionCategory | null) => void;
}

export default function useModalManagement(props: UseIModalManagementProps) {
  const { setSelectedCategory } = props;

  const [isDeleteModalOpened, setDeleteModalOpened] = useState(false);
  const [isCategoryModalOpened, setCategoryModalOpened] = useState(false);

  const openDeleteModal = () => {
    setDeleteModalOpened(true);
  };

  const closeDeleteModal = () => {
    setDeleteModalOpened(false);
    setSelectedCategory(null);
  };

  const openCategoryModal = () => {
    setCategoryModalOpened(true);
  };

  const closeCategoryModal = () => {
    setCategoryModalOpened(false);
    setSelectedCategory(null);
  };

  return {
    closeCategoryModal,
    closeDeleteModal,
    isCategoryModalOpened,
    isDeleteModalOpened,
    openCategoryModal,
    openDeleteModal,
  };
}
