import { TaskScopeNameEnum } from 'graphql-common';

export default function getEmptyStateTextKeys(tab?: string) {
  switch (tab) {
    case TaskScopeNameEnum.Active:
      return ['no-tasks-active-yet', 'no-tasks-active-text'];
    case TaskScopeNameEnum.Completed:
      return ['no-tasks-completed-yet', 'no-tasks-completed-text'];
    case TaskScopeNameEnum.DeadlineMissed:
      return ['no-tasks-deadline-missed-yet', 'no-tasks-deadline-missed-text'];
    case TaskScopeNameEnum.Pending:
      return ['no-tasks-pending-yet', 'no-tasks-pending-text'];
    case TaskScopeNameEnum.Archived:
      return ['no-tasks-archived-yet', 'no-tasks-archived-text'];
    case TaskScopeNameEnum.Failure:
      return ['no-tasks-failure-yet', 'no-tasks-failure-text'];
    case TaskScopeNameEnum.Warning:
      return ['no-tasks-warning-yet', 'no-tasks-warning-text'];
    case TaskScopeNameEnum.Success:
      return ['no-tasks-success-yet', 'no-tasks-success-text'];
    case TaskScopeNameEnum.Solved:
      return ['no-tasks-solved-yet', 'no-tasks-solved-text'];
    default:
      return ['no-tasks-yet', 'no-tasks-text'];
  }
}
