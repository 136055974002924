import {
  Asset,
  FilterGroupingEnum,
  InterventionScopeNameEnum,
  useAssetWithCountersQuery,
} from 'graphql-common';
import { ApolloError } from '@apollo/client/errors';

type Args = {
  id: string;
  includeFullData: boolean;
  onError?: (error: ApolloError) => void;
};

export type AssetCounters = {
  counters: number;
  nomenclature: number;
  documentations: number;
  interventions: number;
};

export type UseAssetQueryHookResult = {
  counters: AssetCounters;
  data: Asset | null | undefined;
  error: ApolloError | undefined;
  firstLoading: boolean;
  loading: boolean;
  refetch: () => Promise<unknown>;
};

export default function useAssetWithCountersQueryHook(
  args: Args,
): UseAssetQueryHookResult {
  const { id, includeFullData, onError } = args;
  const query = useAssetWithCountersQuery({
    skip: !id,
    variables: {
      findBy: { id: id || '' },
      includeFullData,
      mainAssetId: id,
      nomenclaturesFilters: undefined,
      interventionsFilters: [
        {
          assetId: {
            grouping: FilterGroupingEnum.And,
            predicate: {
              eq: id,
            },
            filterGroup: 'assetFilter',
          },
        },
      ],
      interventionsScopeName: InterventionScopeNameEnum.All,
    },
    fetchPolicy: 'cache-and-network',
    onError,
  });
  const { data: queryData, refetch, loading, error, previousData } = query;
  const data = queryData || previousData;
  const assetData = data?.data as Asset;
  const firstLoading = loading && queryData === undefined;
  const counters = {
    nomenclature: queryData?.nomenclatures?.metadata?.totalCount || 0,
    counters: queryData?.counters?.metadata?.totalCount || 0,
    documentations: queryData?.documentations?.metadata?.totalCount || 0,
    interventions: queryData?.interventions?.metadata?.totalCount || 0,
  };

  return {
    counters,
    data: assetData,
    error,
    firstLoading,
    loading,
    refetch,
  };
}
