import _get from 'lodash/get';
import { AssetCategory } from 'graphql-common';
import { Values } from '@lib/interfaces/form';
import AssetFieldNames from '@lib/enums/fieldNames/assetCategoryFieldNames';

export default function getDefaultValuesFromQueryData(
  data: AssetCategory,
): Values {
  return {
    [AssetFieldNames.Id]: _get(data, 'id', null),
    [AssetFieldNames.Name]: _get(data, 'name', null),
    [AssetFieldNames.Prefix]: _get(data, 'prefix', null),
    [AssetFieldNames.Color]: { value: _get(data, 'color', null) },
    [AssetFieldNames.RefreshQrPrefixForAssets]: _get(
      data,
      'refreshQrPrefixForAssets',
      false,
    ),
  };
}
