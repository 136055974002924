import { TFunction } from 'i18next';
import { DEFAULT_LIST_PARAMS } from '@lib/enums/urls';
import { APP_URLS } from 'constants/urls';
import { UseAssetQueryHookResult } from 'utils/fetch/useAssetWithCountersQueryHook';

type Args = {
  counters: UseAssetQueryHookResult['counters'];
  id: string;
  isInterventionsLocked: boolean;
  t: TFunction<'translation', undefined>;
};

export default function getTabs({
  counters,
  id,
  isInterventionsLocked,
  t,
}: Args) {
  const tabs = [
    {
      label: t('details'),
      value: APP_URLS.dashboard.assets.view.getDynamicPath({
        pathParts: {
          id: `${id}`,
        },
      }),
    },
    {
      label: t('nomenclature'),
      value: APP_URLS.dashboard.assets.nomenclature.getDynamicPath({
        pathParts: {
          id: `${id}`,
        },
        params: { ...DEFAULT_LIST_PARAMS },
      }),
      counter: counters?.nomenclature,
    },
    {
      label: t('counters'),
      value: APP_URLS.dashboard.assets.counter.getDynamicPath({
        pathParts: {
          id: `${id}`,
        },
        params: { ...DEFAULT_LIST_PARAMS },
      }),
      counter: counters?.counters,
    },
    {
      label: t('documentation'),
      value: APP_URLS.dashboard.assets.documentation.getDynamicPath({
        pathParts: {
          id: `${id}`,
        },
        params: { ...DEFAULT_LIST_PARAMS },
      }),
      counter: counters?.documentations,
    },
  ];
  if (!isInterventionsLocked) {
    tabs.push({
      label: t('interventions'),
      value: APP_URLS.dashboard.assets.interventions.getDynamicPath({
        pathParts: {
          id: `${id}`,
        },
        params: { ...DEFAULT_LIST_PARAMS },
      }),
      counter: counters?.interventions,
    });
  }
  return tabs;
}
