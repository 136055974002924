import {
  FormCategoriesQueryVariables,
  FormCategory,
  FormCategoryFilterInputObject,
  useFormCategoriesQuery,
} from 'graphql-common';
import _get from 'lodash/get';
import { GetUrlParams } from '@lib/enums/urls';
import createSearchFilters from '@lib/utils/createSearchFilters';
import { ListQueryHookProps } from './types';

export default function useFormCategoriesQueryHook({
  paginationParams,
  searchQuery,
}: ListQueryHookProps) {
  const page = _get(paginationParams, GetUrlParams.Page);
  const perPage = _get(paginationParams, GetUrlParams.PerPage);
  const orderingField = _get(paginationParams, GetUrlParams.OrderingField);
  const orderingDirection = _get(
    paginationParams,
    GetUrlParams.OrderingDirection,
  );
  const formCategoriesQueryVariables: FormCategoriesQueryVariables = {
    page: Number(page),
    limit: Number(perPage),
  };
  const filters: FormCategoryFilterInputObject[] = [];
  if (searchQuery) {
    filters.push(...createSearchFilters(searchQuery, [{ fieldName: 'name' }]));
  }
  if (filters.length) {
    formCategoriesQueryVariables.filters = filters;
  }
  if (orderingField && orderingDirection) {
    formCategoriesQueryVariables.sorts = {
      [orderingField]: orderingDirection,
    };
  }
  const formCategoriesQuery = useFormCategoriesQuery({
    fetchPolicy: 'cache-and-network',
    variables: formCategoriesQueryVariables,
  });
  const {
    loading: formCategoriesLoading,
    data,
    previousData,
    refetch: refetchFormCategories,
    error: formCategoriesError,
  } = formCategoriesQuery;
  const queryData = data || previousData;
  const formCategoriesFirstLoading =
    formCategoriesLoading && previousData === undefined;
  const formCategories = _get(
    queryData,
    'data.collection',
    [],
  ) as FormCategory[];
  const metadata = _get(queryData, 'data.metadata');
  const totalFormCategoriesCount = _get(metadata, 'totalCount', 0);
  const totalPages = _get(metadata, 'totalPages', 1);
  return {
    refetchFormCategories,
    formCategoriesError,
    formCategoriesFirstLoading,
    formCategories,
    totalFormCategoriesCount,
    totalPages,
    formCategoriesLoading,
  };
}
