import React from 'react';
import MaterialIcon from '@lib/components/MaterialIcon/MaterialIcon';

const createIcon = (icon: string, symbolsOutlined?: boolean) =>
  function getIcon() {
    return <MaterialIcon icon={icon} symbolsOutlined={symbolsOutlined} />;
  };

const Icons = {
  activate: createIcon('restart_alt', true),
  person: createIcon('person', true),
  login: createIcon('login', true),
  chevronRight: createIcon('chevron_right', true),
  add: createIcon('add', true),
  archive: createIcon('archive', true),
  build: createIcon('build', true),
  close: createIcon('check_circle', true),
  copy: createIcon('content_copy', true),
  dashboard: createIcon('dashboard', true),
  deactivate: createIcon('do_not_disturb_on', true),
  delete: createIcon('delete', true),
  download: createIcon('download', true),
  edit: createIcon('edit', true),
  interventions: createIcon('build', true),
  inventory: createIcon('inventory', true),
  lock: createIcon('lock', false),
  qrCode: createIcon('qr_code', true),
  schedule: createIcon('schedule', true),
  sites: createIcon('location_on', true),
  tasks: createIcon('task_alt', true),
  team: createIcon('group_add', true),
  view: createIcon('visibility', true),
};

export default Icons;
