import React, { memo } from 'react';
import Typography from '@lib/components/Typography/Typography';
import AvatarIcon from '@lib/assets/icons/avatar.svg?react';
import classNames from 'classnames';
import styles from './UserPreviewCell.module.scss';

interface Props {
  avatar?: string | null;
  disabled?: boolean;
  email?: string | null;
  name: string;
}

function UserPreviewCell(props: Props) {
  const { avatar, name, email, disabled } = props;
  const userAvatar = avatar ? <img src={avatar} alt="" /> : <AvatarIcon />;

  return (
    <div
      className={classNames(styles.userWrap, {
        [styles.userWrapDisabled]: disabled,
      })}
    >
      <div className={styles.userAvatar}>{userAvatar}</div>
      <div className={styles.userInfo}>
        <Typography variant="caption" strong>
          {name}
        </Typography>
        {!!email && (
          <Typography variant="caption" className={styles.userEmail}>
            {email}
          </Typography>
        )}
      </div>
    </div>
  );
}

export default memo(UserPreviewCell) as (props: Props) => JSX.Element;
