import React, { forwardRef, memo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectInstance } from 'react-select';
import { useFormContext } from 'react-hook-form';
import _get from 'lodash/get';
import _noop from 'lodash/noop';
import { GraphQLErrors } from '@apollo/client/errors';
import { Tag } from 'graphql-common';
import { MAX_TEXT_FIELD_LENGTH } from '@lib/enums/form';
import { FloatingLabelProps } from '@lib/hocs/withFloatingLabel';
import Select, { SelectProps } from '@lib/components/Select/Select';
import { LoadOptions, SelectOption } from '@lib/components/Select/types';
import Typography from '@lib/components/Typography/Typography';
import formStyles from '@lib/components/ReactHookForm/Form.module.scss';
import TagsRow from '@lib/components/TagsInput/TagsRow';
import { OnCreateTagFn } from '@lib/components/TagsInput/types';
import ErrorIndicator from '@lib/components/Select/components/ErrorIndicator';
import CheckedOption from '@lib/components/Select/components/CheckedOption';

export type TagsInputProps = {
  tagsLoadOptions: LoadOptions;
  onCreateTag: OnCreateTagFn;
};

function isTagStringValid(tag: string): boolean {
  return (
    !!tag && /^[a-zA-Z0-9]*$/.test(tag) && tag.length <= MAX_TEXT_FIELD_LENGTH
  );
}

function isTagSelected(
  tagsValue: SelectOption[],
  selectedTagIdOrName: string,
): boolean {
  return tagsValue.some(
    (tag) =>
      tag?.value === selectedTagIdOrName || tag?.label === selectedTagIdOrName,
  );
}

const TagsInput = forwardRef(
  (
    props: SelectProps & FloatingLabelProps & TagsInputProps,
    ref: React.ForwardedRef<HTMLInputElement | null>,
  ) => {
    const {
      onChange = _noop,
      value = [],
      tagsLoadOptions,
      ...restProps
    } = props;
    const { onCreateTag, name, hasError, error } = restProps;
    const tagsValue = value && Array.isArray(value) ? value : [];

    const { t } = useTranslation();
    const { setError } = useFormContext();
    const [inputValue, setInputValue] = React.useState('');
    const selectRef = useRef<SelectInstance<SelectOption> | null>(null);

    const handleChange = (newValue: unknown) => {
      const selectedTagId = _get(newValue, 'value', '');
      if (newValue && !isTagSelected(tagsValue, selectedTagId)) {
        onChange([newValue, ...tagsValue]);
      }
    };
    const handleCreateTag = (v: string) => {
      if (isTagSelected(tagsValue, v)) {
        setInputValue('');
      } else {
        onCreateTag(
          v,
          (newTag: Tag | null) => {
            if (newTag) {
              onChange([
                { value: newTag?.id, label: newTag?.name },
                ...tagsValue,
              ]);
              setInputValue('');
            }
          },
          (errors: GraphQLErrors) => {
            if (Array.isArray(errors) && errors.length) {
              const message = _get(
                errors,
                [0, 'extensions', 'details', 0, 'message'],
                '',
              );
              const errorMetaForTag = _get(
                errors,
                [0, 'extensions', 'details', 0, 'meta', 'tag'],
                {},
              );
              const { id, reason } = errorMetaForTag;
              const isTagAlreadyExists = reason === 'taken';
              if (isTagAlreadyExists) {
                onChange([{ value: id, label: v }, ...tagsValue]);
                setInputValue('');
              } else if (!isTagAlreadyExists && message && name) {
                setError(name, { type: 'custom', message });
              }
            }
          },
        );
      }
    };
    const handleInputChange = (newInputValue: string) => {
      if (isTagStringValid(newInputValue)) {
        setInputValue(newInputValue);
      } else if (newInputValue === '') {
        setInputValue('');
      }
    };
    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        const newTag = _get(e, ['target', 'value'], '') as string;
        if (isTagStringValid(newTag)) {
          handleCreateTag(newTag);
        }
      }
    };
    const handleRemoveTag = (id: string) => {
      const newTags = tagsValue.filter(
        (tag: SelectOption) => tag?.value !== id,
      );
      onChange(newTags);
    };
    return (
      <div>
        <Select
          {...restProps}
          ref={selectRef}
          onChange={handleChange}
          inputValue={inputValue}
          onInputChange={handleInputChange}
          onKeyDown={handleKeyDown}
          blurInputOnSelect
          // @ts-ignore TODO: fix it
          onCreateOption={handleCreateTag}
          loadOptions={tagsLoadOptions}
          isAsync
          isCreatable
          value={[]}
          values={value as SelectOption[] | undefined}
          inputRef={ref}
          menuIsOpen={inputValue?.length ? undefined : false}
          components={{
            DropdownIndicator: hasError ? ErrorIndicator : () => null,
            Option: CheckedOption,
          }}
          infoTooltipProps={
            hasError && error
              ? {
                  body: error,
                }
              : undefined
          }
          formatCreateLabel={(v: string) => v}
        />
        <Typography variant="label" className={formStyles.formItemDescription}>
          {t('documentation-tags-description')}
        </Typography>
        <TagsRow tagsValue={tagsValue} handleRemoveTag={handleRemoveTag} />
      </div>
    );
  },
);

export default memo(TagsInput);
