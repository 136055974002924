import React, { forwardRef, memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { DatePicker } from 'antd';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import { PickerRef } from 'rc-picker/lib/interface';
import { PickerMode } from 'rc-picker/es/interface';
import dayjs, { Dayjs } from 'dayjs';
import { isAfter, isBefore } from 'date-fns';
import { datePickerDateFormat } from '@lib/enums/dateTime';
import { revertTransformDate, transformDate } from '@lib/utils/dateTimeHelpers';
import MaterialIcon from '@lib/components/MaterialIcon/MaterialIcon';
import withFloatingLabel, {
  FloatingLabelProps,
} from '@lib/hocs/withFloatingLabel';
import Button, {
  ButtonSizes,
  ButtonTypes,
} from '@lib/components/Button/Button';
import isMobile from '@lib/utils/isMobile';
import styles from './DateTimePicker.module.scss';

type Props = {
  disabled?: boolean;
  format?: string;
  hasError?: boolean;
  maxDate?: Date;
  minDate?: Date;
  name?: string;
  onChange: (v: string) => void;
  pickerType?: PickerMode;
  selectEndOfTheDay?: boolean;
  size?: SizeType;
  value?: string;
};

const DatePickerCommon = forwardRef(
  (props: Props & FloatingLabelProps, ref: React.ForwardedRef<PickerRef>) => {
    const {
      disabled,
      format,
      hasError,
      maxDate,
      minDate,
      name,
      onChange,
      pickerType,
      selectEndOfTheDay,
      setIsFocused,
      size = 'large',
      value,
    } = props;
    const { t } = useTranslation();
    const [isOpen, setOpen] = useState(false);
    const { phone, tablet } = isMobile();
    const isMobileDevice = phone || tablet;

    let selected: Dayjs | null = null;
    const isValueExists = !!value;
    if (isValueExists) {
      selected = dayjs(revertTransformDate(value));
    }

    const onBlurHandler = (e) => {
      const className =
        e?.relatedTarget?.className || e?.target?.className || '';
      if (className.indexOf('ant-picker') === -1) {
        setIsFocused(false);
      }
    };

    const onFocusHandler = () => {
      setOpen(true);
      setIsFocused(true);
    };

    const onChangeHandler = useCallback(
      (v: Dayjs | null) => {
        let newValue = '';
        if (v) {
          newValue = transformDate(v.toDate(), selectEndOfTheDay);
        } else if (isMobileDevice) {
          setIsFocused(false);
        }
        onChange(newValue);
        setTimeout(() => {
          setOpen(false);
        }, 0);
      },
      [isMobileDevice, onChange, selectEndOfTheDay, setIsFocused],
    );

    const disabledDate = (current) => {
      if (!current) return false;
      if (pickerType === 'year') {
        const startOfYear = dayjs(current).startOf('year').toDate();
        const endOfYear = dayjs(current).endOf('year').toDate();
        if (minDate && isBefore(endOfYear, minDate)) return true;
        if (maxDate && isAfter(startOfYear, maxDate)) return true;
        return false;
      }
      if (pickerType === 'month') {
        const startOfMonth = dayjs(current).startOf('month').toDate();
        const endOfMonth = dayjs(current).endOf('month').toDate();
        if (minDate && isBefore(endOfMonth, minDate)) return true;
        if (maxDate && isAfter(startOfMonth, maxDate)) return true;
        return false;
      }
      const currentDate = current.toDate();
      if (minDate && isBefore(currentDate, minDate)) return true;
      if (maxDate && isAfter(currentDate, maxDate)) return true;
      return false;
    };

    const renderExtraFooter = useCallback(() => {
      const today = dayjs().startOf('day');
      if (
        (maxDate &&
          (today.isSame(dayjs(maxDate), 'day') ||
            today.isAfter(dayjs(maxDate), 'day'))) ||
        (minDate &&
          (today.isSame(dayjs(minDate), 'day') ||
            today.isBefore(dayjs(minDate), 'day')))
      ) {
        // Do not render footer if today is maxDate or minDate
        return null;
      }

      const isSelected = selected && selected.isSame(today, 'day');
      return (
        <div className={styles.dateRangePickerFooter}>
          <Button
            buttonText={t('today')}
            buttonSize={ButtonSizes.small}
            buttonType={
              isSelected
                ? ButtonTypes.primaryFilled
                : ButtonTypes.primaryOutlined
            }
            onClick={() => {
              onChangeHandler(today);
            }}
            useRipple={false}
          />
        </div>
      );
    }, [onChangeHandler, selected, t, maxDate, minDate]);

    const className = classNames(styles.picker, {
      [styles.datePickerFilled]: isValueExists,
    });

    return (
      <div>
        <DatePicker
          allowClear={{ clearIcon: <MaterialIcon icon="cancel" /> }}
          className={className}
          disabled={disabled}
          disabledDate={disabledDate}
          format={format || datePickerDateFormat}
          name={name}
          needConfirm={false}
          onBlur={onBlurHandler}
          onChange={onChangeHandler}
          onFocus={onFocusHandler}
          picker={pickerType}
          placeholder=""
          popupClassName={styles.datePickerPopup}
          ref={ref}
          size={size}
          status={hasError ? 'error' : undefined}
          value={selected}
          renderExtraFooter={renderExtraFooter}
          showNow={false}
          open={isOpen}
          inputReadOnly={isMobileDevice}
        />
        {isOpen && (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/control-has-associated-label,jsx-a11y/no-static-element-interactions
          <div className={styles.mask} onClick={() => setOpen(false)} />
        )}
      </div>
    );
  },
);

export default memo(withFloatingLabel(DatePickerCommon));
