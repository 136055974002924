import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Counter, CounterMeasure } from 'graphql-common';
import Table from '@lib/components/Table/Table';
import ListEmptyState from '@lib/components/ListEmptyState/ListEmptyState';
import getTableColumns from 'components/AssetCounterMeasures/utils/getTableColumns';
import Button, { ButtonTypes } from '@lib/components/Button/Button';

type Props = {
  collection: CounterMeasure[];
  loading?: boolean;
  onAddIndex: () => void;
  totalEntries: number;
  counter?: Counter;
};

export default function AssetCounterMeasuresTableView(props: Props) {
  const { onAddIndex, loading, collection, totalEntries, counter } = props;
  const { t } = useTranslation();
  const tableColumns = getTableColumns({ counter, t });
  const tableEmptyState = (
    <ListEmptyState
      title={t('no-measures-yet')}
      text={<Trans i18nKey="no-measures-text" components={{ br: <br /> }} />}
      actions={[
        <Button
          key="add"
          leftIcon="add"
          buttonType={ButtonTypes.primaryFilled}
          buttonText={t('add-index')}
          onClick={onAddIndex}
          fullWidth
        />,
      ]}
    />
  );
  return (
    <Table
      columns={tableColumns}
      data={collection}
      emptyState={tableEmptyState}
      fullHeight
      id="measures"
      loading={loading}
      totalEntries={totalEntries}
      getIsRowSelected={(item) => item.resetToZero}
    />
  );
}
