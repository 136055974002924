import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TabBar from '@lib/components/TabBar/TabBar';
import { TabBarItem as TabBarItemType } from '@lib/components/TabBar/constants';
import getTabs from 'components/SiteMenuBar/utils/getTabs';
import { ComponentEnum, LevelEnum } from 'graphql-common';
import useOutletContext, { ContextProps } from 'hooks/useOutletContext';
import styles from './SiteMenuBar.module.scss';

interface Props {
  id: string;
}

function SiteMenuBar({ id }: Props) {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { getUserAccessByComponent }: ContextProps = useOutletContext();
  const isUsersManagementLocked =
    getUserAccessByComponent(ComponentEnum.UsersManagement) ===
    LevelEnum.LimitedRead;
  const tabs = getTabs({ t, id, isUsersManagementLocked });
  const onActiveChange = ({ value }: TabBarItemType) => {
    navigate(value);
  };
  return (
    <div className={styles.menuRoot}>
      <TabBar
        items={tabs}
        active={pathname}
        onActiveChange={onActiveChange}
        useSidePaddings
      />
    </div>
  );
}

export default SiteMenuBar;
