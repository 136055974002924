import { LazyQueryExecFunction } from '@apollo/client';
import {
  SortDirectionEnum,
  User,
  UserFilterInputObject,
  UserScopeInputObject,
  UsersSearchListQuery,
  UsersSearchListQueryVariables,
} from 'graphql-common';
import { DEFAULT_SEARCH_LIST_VARIABLES } from '@lib/enums/urls';
import getFullName from '@lib/utils/getFullName';
import getLoadOptionsResult from '@lib/utils/getLoadOptionsResult';
import createSearchFilters from '@lib/utils/createSearchFilters';
import { LoadOptionsParams } from '@lib/components/Select/types';

type FetchLazyQuery = LazyQueryExecFunction<UsersSearchListQuery, any>;

const fetchSearchList = async (
  searchQuery: string,
  fetchLazyQuery: FetchLazyQuery,
  page: number,
  scope?: UserScopeInputObject,
) => {
  const options: { variables: UsersSearchListQueryVariables } = {
    variables: {
      ...DEFAULT_SEARCH_LIST_VARIABLES,
      page,
      sorts: { fullName: SortDirectionEnum.Asc },
      scope,
    },
  };
  const filters: UserFilterInputObject[] = [];
  if (searchQuery) {
    filters.push(
      ...createSearchFilters(searchQuery, [
        { fieldName: 'fullName' },
        { fieldName: 'email' },
        { fieldName: 'role', predicateType: 'eq' },
        { fieldName: 'personalId' },
      ]),
    );
  }
  if (filters.length) {
    options.variables.filters = filters;
  }
  const { data } = await fetchLazyQuery(options);
  return getLoadOptionsResult(
    data,
    (dataItem: User) => ({
      value: dataItem.id,
      label: getFullName(dataItem),
      data: dataItem,
    }),
    searchQuery,
  );
};

export const getUsersLoadOptions =
  (fetchLazyQuery: FetchLazyQuery, scope?: UserScopeInputObject) =>
  (searchQuery: string, loadedOptions: unknown, params: LoadOptionsParams) =>
    fetchSearchList(searchQuery, fetchLazyQuery, params?.page || 1, scope);
