import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { ComponentEnum, LevelEnum, RoleEnum, User } from 'graphql-common';
import BreadCrumbs from '@lib/components/BreadCrumbs/BreadCrumbs';
import { useTableItemsBulkSelect } from '@lib/hooks/useTableItemsBulkSelect';
import CircleLoaderWrapper from '@lib/components/CircleLoaderWrapper/CircleLoaderWrapper';
import CircleLoader from '@lib/components/CircleLoader/CircleLoader';
import PageBody from '@lib/layouts/DashboardLayout/PageBody/PageBody';
import ConfirmationModal from '@lib/components/ConfirmationModal/ConfirmationModal';
import ErrorPage from '@lib/components/ErrorPage/ErrorPage';
import getStatusFromError from '@lib/utils/getStatusFromError';
import useModalManagement, { Modals } from '@lib/hooks/useModalManagement';
import TabBar from '@lib/components/TabBar/TabBar';
import { TabBarItem as TabBarItemType } from '@lib/components/TabBar/constants';
import WhiteBox from '@lib/components/WhiteBox/WhiteBox';
import DashboardTitleHeader from '@lib/layouts/DashboardLayout/DashboardTitleHeader/DashboardTitleHeader';
import DashboardContentMaxWidth from '@lib/layouts/DashboardLayout/DashboardContentMaxWidth/DashboardContentMaxWidth';
import ActionsMenu from '@lib/components/ActionsMenu/ActionsMenu';
import { getBreadCrumbsDataByKey } from 'utils/breadcrumbs';
import usePrevious from '@lib/hooks/usePrevious';
import useUserQueryHook from 'utils/fetch/useUserQueryHook';
import UserModalForm from 'components/UserModalForm/UserModalForm';
import { APP_URLS } from 'constants/urls';
import SupportiveMessage, {
  SupportiveMessageColor,
} from '@lib/components/SupportiveMessage/SupportiveMessage';
import useOutletContext, { ContextProps } from 'hooks/useOutletContext';
import { IconName } from '@lib/components/Modal/enums';
import UserAccessView from './UserAccessView/UserAccessView';
import FAQAccesses from './FAQAccesses/FAQAccesses';
import UserAccessesForm from './UserAccessesForm/UserAccessesForm';
import getUserActionsMenu from './utils/getUserActionsMenu';
import UserInfoHeader from './UserInfoView/UserInfoHeader';
import UserInfoView from './UserInfoView/UserInfoView';
import useUserActions from './utils/useUserActions';
import getUserViewPageTabs from './utils/getUserViewPageTabs';
import { UserViewMode } from './enums';

const modals = [Modals.UserFormModal, Modals.UserDeleteModal];

function UsersContainer({ viewMode }: { viewMode: UserViewMode }) {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const prevPathname = usePrevious(pathname);
  const { id = '' } = useParams();

  const { getUserAccessByComponent }: ContextProps = useOutletContext();
  const isViewOnly =
    getUserAccessByComponent(ComponentEnum.UsersManagement) === LevelEnum.Read;

  const useUserQueryHookResult = useUserQueryHook({
    id,
    includeFullData: viewMode === UserViewMode.View,
    includeFinalAccesses:
      viewMode === UserViewMode.ViewAccess ||
      viewMode === UserViewMode.EditAccess,
    includePersonalAccesses:
      viewMode === UserViewMode.EditAccess ||
      viewMode === UserViewMode.ViewAccess,
    includeTeamAccesses:
      viewMode === UserViewMode.EditAccess ||
      viewMode === UserViewMode.ViewAccess,
    fetchPolicy: 'cache-and-network',
  });
  const isUserDeactivated = !!useUserQueryHookResult.data?.deactivatedAt;
  const role = useUserQueryHookResult.data?.role;

  const useBulkSelectResult = useTableItemsBulkSelect<User>();
  const useModalManagementResult = useModalManagement(modals, () =>
    useBulkSelectResult.handleSelectSingleTableItem(null),
  );
  const { isModalOpened, closeModal } = useModalManagementResult;
  const {
    onConfirmedDelete,
    onSubmitUserFormCallback,
    selectedUserId,
    onViewChange,
    onActivate,
    onDeactivate,
    onDelete,
    onEdit,
    openTeamPage,
    onUserAccessChange,
    userUpdateMutationResult,
  } = useUserActions({
    id,
    useUserQueryHookResult,
    useModalManagementResult,
    useBulkSelectResult,
  });
  const updateMutationResultError = userUpdateMutationResult.error;

  const tabs = getUserViewPageTabs({
    t,
  });
  const actionsMenu = getUserActionsMenu({
    currentUserId: id,
    onActivate: isUserDeactivated
      ? () => onActivate(useUserQueryHookResult.data)
      : undefined,
    onDeactivate: isUserDeactivated
      ? undefined
      : () => onDeactivate(useUserQueryHookResult.data),
    onDelete: () => onDelete(useUserQueryHookResult.data),
    onEdit: () => onEdit(useUserQueryHookResult.data),
    onEditAccesses:
      role === RoleEnum.FullAdmin ||
      APP_URLS.dashboard.users.editAccess.isTheSameUrlAs(pathname, {
        pathParts: { id },
      })
        ? undefined
        : () => onViewChange(UserViewMode.EditAccess),
    t,
    isViewOnly,
  });

  useEffect(() => {
    if (pathname !== prevPathname) {
      useUserQueryHookResult.refetch();
    }
  }, [pathname, prevPathname, useUserQueryHookResult]);

  return (
    <>
      <BreadCrumbs
        data={getBreadCrumbsDataByKey({
          pathname,
          t,
          entity: useUserQueryHookResult.data,
        })}
      />
      {useUserQueryHookResult.error ? (
        <ErrorPage
          status={getStatusFromError(useUserQueryHookResult.error)}
          description={useUserQueryHookResult.error?.message}
        />
      ) : (
        <PageBody withoutHeader>
          {useUserQueryHookResult.firstLoading ? (
            <CircleLoader />
          ) : (
            <>
              <TabBar
                items={tabs}
                active={
                  viewMode === UserViewMode.EditAccess
                    ? UserViewMode.ViewAccess
                    : viewMode
                }
                onActiveChange={({ value }: TabBarItemType) =>
                  onViewChange(value)
                }
              />
              <DashboardContentMaxWidth>
                <CircleLoaderWrapper isLoading={useUserQueryHookResult.loading}>
                  <WhiteBox>
                    <DashboardTitleHeader
                      actions={
                        viewMode === UserViewMode.EditAccess
                          ? []
                          : [
                              <ActionsMenu
                                key="actions-menu"
                                actions={actionsMenu}
                                disabled={useUserQueryHookResult.loading}
                              />,
                            ]
                      }
                    >
                      <UserInfoHeader user={useUserQueryHookResult.data} />
                    </DashboardTitleHeader>
                    {viewMode === UserViewMode.View && (
                      <UserInfoView
                        user={useUserQueryHookResult.data}
                        openTeamPage={openTeamPage}
                      />
                    )}
                    {viewMode === UserViewMode.EditAccess &&
                      role === RoleEnum.User && (
                        <>
                          <FAQAccesses />
                          <UserAccessesForm
                            loading={
                              useUserQueryHookResult.loading ||
                              userUpdateMutationResult.loading
                            }
                            user={useUserQueryHookResult.data}
                            onCancel={() =>
                              onViewChange(UserViewMode.ViewAccess)
                            }
                            onSubmit={onUserAccessChange}
                            graphQLErrors={
                              updateMutationResultError?.graphQLErrors
                            }
                          />
                        </>
                      )}
                    {viewMode === UserViewMode.ViewAccess &&
                      role === RoleEnum.User && (
                        <>
                          <FAQAccesses />
                          <UserAccessView user={useUserQueryHookResult.data} />
                        </>
                      )}
                    {(viewMode === UserViewMode.EditAccess ||
                      viewMode === UserViewMode.ViewAccess) &&
                      role === RoleEnum.FullAdmin && (
                        <SupportiveMessage
                          color={SupportiveMessageColor.Yellow}
                          text={t('faq-full-admin-text')}
                        />
                      )}
                  </WhiteBox>
                </CircleLoaderWrapper>
              </DashboardContentMaxWidth>
            </>
          )}
          <UserModalForm
            isOpen={isModalOpened(Modals.UserFormModal)}
            onClose={() => closeModal(Modals.UserFormModal)}
            onSubmitUserFormCallback={onSubmitUserFormCallback}
            selectedUserId={id}
            title={
              selectedUserId ? t('edit-team-member') : t('add-team-member')
            }
          />
          <ConfirmationModal
            confirmButtonLabel={t('yes-delete')}
            description={t('delete-user-text')}
            icon={IconName.Delete}
            isOpen={isModalOpened(Modals.UserDeleteModal)}
            onClose={() => closeModal(Modals.UserDeleteModal)}
            onConfirm={onConfirmedDelete}
            title={t('are-you-sure')}
          />
        </PageBody>
      )}
    </>
  );
}

export default UsersContainer;
