import _get from 'lodash/get';
import { Task } from 'graphql-common';
import { SelectOption } from '@lib/components/Select/types';

type Props = {
  taskData: Task;
  versionParams?: { version?: string | null; versionId?: string | null };
};

export default function useSelectedVersionOption(props: Props) {
  const { taskData, versionParams } = props;
  const selectedVersionOption: SelectOption =
    versionParams?.version && versionParams.versionId
      ? {
          label: `v${versionParams.version}`,
          value: versionParams.versionId,
        }
      : {
          label: `v${_get(taskData, 'formTemplateVersion.version', '')}`,
          value: _get(taskData, 'formTemplateVersion.id', '') as string,
        };
  return selectedVersionOption;
}
