import React, { forwardRef } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Button, {
  ButtonTypes,
  ButtonSizes,
} from '@lib/components/Button/Button';
import AvatarIcon from '@lib/assets/icons/avatar.svg?react';
import Typography from '@lib/components/Typography/Typography';
import FileUploaderUppyAwsS3, {
  AttachmentValue,
  UploaderView,
} from '@lib/components/FileUploaderUppyXHR/FileUploaderUppyXHR';
import { UppyImageAllowedFileTypes } from '@lib/enums/mimeTypes';
import Image from '@lib/components/Image/Image';
import { materialSymbolsOutlined } from '@lib/enums/common';
import styles from './AvatarUploader.module.scss';

export interface AvatarUploaderProps {
  className?: string;
  value?: AttachmentValue;
  onChange: (variables: unknown) => void;
  disabled?: boolean;
}

const AvatarUploader = forwardRef(
  (props: AvatarUploaderProps, ref: React.ForwardedRef<HTMLInputElement>) => {
    const { className, value, onChange, disabled } = props;
    const url = value?.urls?.small || value?.urls?.original || value?.url;
    const { t } = useTranslation();
    const uploadButton = ({ onClick }: { onClick: () => void }) => (
      <Button
        onClick={onClick}
        buttonText={url ? t('edit-image') : t('upload-image')}
        buttonType={ButtonTypes.primaryOutlined}
        leftIcon="edit"
        leftIconClassName={materialSymbolsOutlined}
        buttonSize={ButtonSizes.small}
      />
    );
    return (
      <div className={classNames(styles.root, className)}>
        <div className={styles.img}>
          {url ? <Image src={url} /> : <AvatarIcon />}
        </div>
        {!disabled && (
          <div>
            <div className={styles.content}>
              <Typography
                variant="caption"
                className={styles.validationMsg}
                component="div"
              >
                {t('image-upload-validation-msg', { size: 5 })}
              </Typography>
            </div>
            <div className={styles.actions}>
              <FileUploaderUppyAwsS3
                allowedFileTypes={UppyImageAllowedFileTypes}
                note={t('image-upload-validation-msg', { size: 5 })}
                onChange={onChange}
                openButton={uploadButton}
                ref={ref}
                type={UploaderView.modal}
              />
              {url !== undefined && (
                <Button
                  buttonText={t('delete')}
                  buttonType={ButtonTypes.negativeOutlined}
                  leftIcon="delete"
                  leftIconClassName={materialSymbolsOutlined}
                  buttonSize={ButtonSizes.small}
                  onClick={() => onChange(null)}
                />
              )}
            </div>
          </div>
        )}
      </div>
    );
  },
);

export default AvatarUploader;
