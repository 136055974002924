import React from 'react';
import { useTranslation } from 'react-i18next';
import { FetchResult } from '@apollo/client';
import { GraphQLErrors } from '@apollo/client/errors';
import {
  AuthenticationConfirmationsResendEmailConfirmationMutation,
  AuthenticationPasswordsForgotMutation,
  User,
} from 'graphql-common';
import PageHeader from '@lib/layouts/DashboardLayout/PageHeader/PageHeader';
import PageBody from '@lib/layouts/DashboardLayout/PageBody/PageBody';
import CircleLoader from '@lib/components/CircleLoader/CircleLoader';
import { Values } from '@lib/interfaces/form';
import ProfileForm from './ProfileForm';
import LangForm from './LangForm';
import PasswordForm from './PasswordForm';
import getDefaultValuesFromQueryData from './utils/getDefaultValuesFromQueryData';
import { OnUpdatePassword, OnUpdateProfile } from './types';
import styles from './Profile.module.scss';

export interface Props {
  countriesCodes: string[];
  currentUser?: User;
  graphQLErrors?: GraphQLErrors;
  loading: boolean;
  // onConfirmedDelete: () => void;
  onResendConfirmationHandler: () => Promise<
    FetchResult<AuthenticationConfirmationsResendEmailConfirmationMutation>
  >;
  onSendPasswordResetHandler: () => Promise<
    FetchResult<AuthenticationPasswordsForgotMutation>
  >;
  onUpdateHandler: OnUpdateProfile;
  onUpdatePasswordHandler: OnUpdatePassword;
  passwordResetGraphQLErrors?: GraphQLErrors;
  passwordResetMutationLoading: boolean;
  passwordResetMutationReset: () => void;
  unconfirmedEmail?: string | null;
}

function Profile(props: Props) {
  const { t } = useTranslation();
  const {
    countriesCodes,
    currentUser,
    graphQLErrors,
    loading,
    // onConfirmedDelete,
    onResendConfirmationHandler,
    onSendPasswordResetHandler,
    onUpdateHandler,
    onUpdatePasswordHandler,
    unconfirmedEmail,
    passwordResetGraphQLErrors,
    passwordResetMutationLoading,
    passwordResetMutationReset,
  } = props;

  if (loading && !currentUser) {
    return <CircleLoader />;
  }

  return (
    <>
      <PageHeader title={t('profile')} />
      <PageBody>
        <div className={styles.body}>
          <ProfileForm
            countriesCodes={countriesCodes}
            defaultValues={getDefaultValuesFromQueryData(
              currentUser as unknown as Values,
            )}
            graphQLErrors={graphQLErrors}
            loading={loading}
            onResendConfirmationHandler={onResendConfirmationHandler}
            onSubmit={onUpdateHandler}
            unconfirmedEmail={unconfirmedEmail}
          />
          <LangForm
            defaultValues={currentUser as unknown as Values}
            loading={loading}
            onSubmit={onUpdateHandler}
          />
          <PasswordForm
            graphQLErrors={passwordResetGraphQLErrors}
            loading={passwordResetMutationLoading}
            onSendPasswordResetHandler={onSendPasswordResetHandler}
            onSubmit={onUpdatePasswordHandler}
            updateMutationReset={passwordResetMutationReset}
          />
          {/* <DeleteProfileForm */}
          {/*  onConfirmedDelete={onConfirmedDelete} */}
          {/* /> */}
        </div>
      </PageBody>
    </>
  );
}

export default Profile;
