import { APP_URLS } from 'constants/urls';
import { DEFAULT_LIST_PARAMS } from '@lib/enums/urls';
import { TFunction } from 'i18next';

type Args = {
  t: TFunction<'translation', undefined>;
  id: string;
  isUsersManagementLocked: boolean;
};

export default function getTabs({ t, id, isUsersManagementLocked }: Args) {
  const tabs = [
    {
      label: t('about'),
      value: APP_URLS.dashboard.sites.view.getDynamicPath({
        pathParts: {
          id: `${id}`,
        },
      }),
    },
    {
      label: t('areas'),
      value: APP_URLS.dashboard.sites.areas.getDynamicPath({
        pathParts: {
          id: `${id}`,
        },
        params: DEFAULT_LIST_PARAMS,
      }),
    },
  ];
  if (!isUsersManagementLocked) {
    tabs.push({
      label: t('team'),
      value: APP_URLS.dashboard.sites.users.getDynamicPath({
        pathParts: {
          id: `${id}`,
        },
        params: DEFAULT_LIST_PARAMS,
      }),
    });
  }
  return tabs;
}
