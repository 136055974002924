import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Form, { FormRef } from '@lib/components/ReactHookForm/FormContainer';
import DashboardContentMaxWidth from '@lib/layouts/DashboardLayout/DashboardContentMaxWidth/DashboardContentMaxWidth';
import PageHeader from '@lib/layouts/DashboardLayout/PageHeader/PageHeader';
import PageBody from '@lib/layouts/DashboardLayout/PageBody/PageBody';
import WhiteBox from '@lib/components/WhiteBox/WhiteBox';
import Button, { ButtonTypes } from '@lib/components/Button/Button';
import { materialSymbolsOutlined } from '@lib/enums/common';
import ActionsRow from '@lib/components/ActionsRow/ActionsRow';
import {
  Modals,
  UseModalManagementResult,
} from '@lib/hooks/useModalManagement';
import formStyles from '@lib/assets/styles/Form.module.scss';
import { UseTeamQueriesResult } from 'routes/Teams/utils/useTeamQueries';
import useSearchListQueries from '../utils/useSearchListQueries';
import getTeamFormFields from '../utils/getTeamFormFields';
import { UseTeamActionsResult } from '../utils/useTeamActions';
import { TeamViewMode } from '../enums';
import styles from './TeamFormPage.module.scss';

type Props = {
  useModalManagementResult: UseModalManagementResult;
  useTeamActionsResult: UseTeamActionsResult;
  useTeamQueriesResult: UseTeamQueriesResult;
};

export default function TeamFormPage({
  useModalManagementResult,
  useTeamActionsResult,
  useTeamQueriesResult,
}: Props) {
  const { t } = useTranslation();
  const formRef = useRef<FormRef>();
  const navigate = useNavigate();
  const useSearchListQueriesResult = useSearchListQueries();
  const {
    id,
    mutationError,
    loading,
    onSubmit,
    onViewChange,
    defaultValues,
    isCreatePage,
  } = useTeamActionsResult;
  const { useTeamQueryHookResult } = useTeamQueriesResult;

  const onDelete = () =>
    useModalManagementResult.openModal(Modals.TeamDestroyModal);

  const isDestroyAvailable = useTeamQueryHookResult.data?.canDestroy?.value;

  const fields = getTeamFormFields({
    teamId: id,
    t,
    useTeamQueriesResult,
    getTeamMembersLoadOptions: useSearchListQueriesResult.getUsersLoadOptions,
    getAssetCategoriesLoadOptions:
      useSearchListQueriesResult.getAssetCategoriesLoadOptions,
    getInterventionCategoriesLoadOptions:
      useSearchListQueriesResult.getInterventionCategoriesLoadOptions,
  });

  const actionsComponent = () => (
    <ActionsRow
      className={formStyles.formActions}
      actions={[
        <Button
          onClick={() => navigate(-1)}
          buttonType={ButtonTypes.secondaryOutlined}
          buttonText={t('cancel')}
          leftIcon="close"
          fullWidth
        />,
        <Button
          buttonType={ButtonTypes.primaryFilled}
          buttonText={t(isCreatePage ? 'create' : 'save-changes')}
          leftIcon="check"
          type="submit"
          fullWidth
        />,
      ]}
    />
  );

  return (
    <DashboardContentMaxWidth>
      <PageHeader title={t(isCreatePage ? 'create-team' : 'edit-team')}>
        {isCreatePage ? undefined : (
          <Button
            buttonText={t('view')}
            buttonType={ButtonTypes.primaryOutlined}
            leftIcon="visibility"
            onClick={() => onViewChange(TeamViewMode.ViewDetails)}
          />
        )}
      </PageHeader>
      <PageBody>
        <>
          <WhiteBox verticalPaddings="large">
            <Form
              actionsComponent={actionsComponent}
              actionsComponentProps={{ isCreatePage }}
              defaultValues={defaultValues}
              fields={fields}
              formId="team"
              formRef={formRef}
              graphQLErrors={mutationError?.graphQLErrors}
              loading={loading}
              onSubmit={onSubmit}
            />
          </WhiteBox>
          {isDestroyAvailable && (
            <div className={styles.actions}>
              <Button
                buttonText={t('delete-team')}
                buttonType={ButtonTypes.negativeOutlined}
                leftIcon="delete"
                leftIconClassName={materialSymbolsOutlined}
                onClick={onDelete}
              />
            </div>
          )}
        </>
      </PageBody>
    </DashboardContentMaxWidth>
  );
}
