import React from 'react';
import { TFunction } from 'i18next';
import { Task } from 'graphql-common';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import TextLinkCell from '@lib/components/Table/commonComponents/TextLinkCell/TextLinkCell';
import { CellProps } from '@lib/components/Table/types';
import Chip, { ChipTypes } from '@lib/components/Chip/Chip';
import doNotRepeatIconSrc from '@lib/assets/icons/custom_do_not_repeat.svg';
import ChipCell from '@lib/components/Table/commonComponents/ChipCell/ChipCell';
import ChipControl from '@lib/components/Select/components/ChipControl';
import { Values } from '@lib/interfaces/form';
import { APP_URLS } from 'constants/urls';
import styles from 'routes/Tasks/TasksContainer.module.scss';
import FormTemplateVersionSelect from 'components/FormTemplateVersionSelect/FormTemplateVersionSelect';
import TaskStatusChip from '@lib/components/TaskStatusChip/TaskStatusChip';
import ChipValueContainerForChipControl from '@lib/components/Select/components/ChipValueContainerForChipControl';
import { InputSizes } from '@lib/components/Input/enums';

type ColumnsArgs = {
  formTemplateId: string;
  formTemplateData: Values;
  onTaskFormTemplateVersionChange: (
    taskId: string,
    newFormTemplateVersionId: string,
  ) => void;
  t: TFunction<'translation', undefined>;
};

export default function getFormTemplateTasksTableColumns({
  formTemplateId,
  onTaskFormTemplateVersionChange,
  t,
}: ColumnsArgs) {
  return [
    {
      id: 'recurringRule',
      Header: ' ',
      accessor: 'recurringRuleId',
      Cell: (props: CellProps<Task>) => {
        const isRepeatable = !_isEmpty(_get(props, 'value'));
        return (
          <Chip
            className={styles.repeatStatusChip}
            type={isRepeatable ? ChipTypes.chipBlue : ChipTypes.chipGray}
            leftIcon={isRepeatable ? 'repeat' : undefined}
            leftImage={isRepeatable ? undefined : doNotRepeatIconSrc}
            tooltip={isRepeatable ? t('recurring') : t('non-recurring')}
            asBlockElement
          />
        );
      },
      width: 44,
      maxWidth: 44,
      minWidth: 44,
    },
    {
      id: 'name',
      Header: t('name'),
      accessor: 'name',
      Cell: (props: CellProps<Task>) => (
        <TextLinkCell
          {...props}
          to={APP_URLS.dashboard.tasks.view.getDynamicPath({
            pathParts: {
              id: props?.item.id,
            },
          })}
        />
      ),
      sortable: true,
      maxWidth: 200,
      minWidth: 200,
    },
    {
      id: 'status',
      Header: t('status'),
      accessor: 'status',
      Cell: ({ item }: CellProps<Task>) => <TaskStatusChip task={item} />,
      sortable: true,
      maxWidth: 130,
      minWidth: 130,
    },
    {
      id: 'siteName',
      Header: t('site'),
      accessor: 'site',
      Cell: (props: CellProps<Task>) => {
        const label = _get(props, 'item.site.name', '');
        if (!label) return '-';
        return <ChipCell label={label} type={ChipTypes.chipGray} />;
      },
      sortable: true,
      maxWidth: 130,
      minWidth: 130,
    },
    {
      id: 'siteAreaName',
      Header: t('area'),
      accessor: 'siteArea',
      Cell: (props: CellProps<Task>) => {
        const label = _get(props, 'item.siteArea.name', '');
        if (!label) return '-';
        return <ChipCell label={label} type={ChipTypes.chipGray} />;
      },
      sortable: true,
      maxWidth: 130,
      minWidth: 130,
    },
    {
      id: 'templateVersionVersion',
      Header: t('version'),
      accessor: 'version',
      Cell: (props: CellProps<Task>) => {
        const { item } = props;
        const value = {
          value: item.formTemplateVersion.id,
          label: `v${item.formTemplateVersion.version}` || '-',
        };
        const onVersionChange = (newValue) => {
          const newVersionId = _get(newValue, 'value');
          if (newVersionId) {
            onTaskFormTemplateVersionChange(item.id, newVersionId);
          }
        };
        return (
          <FormTemplateVersionSelect
            components={{
              Control: ChipControl,
              ValueContainer: ChipValueContainerForChipControl,
            }}
            disabled={!item?.canFormTemplateVersionUpdate?.value}
            formTemplateId={formTemplateId}
            fullWidthMenu={false}
            hideArchived
            onChange={onVersionChange}
            value={value}
            size={InputSizes.medium}
          />
        );
      },
      sortable: true,
      minWidth: 100,
    },
  ];
}
