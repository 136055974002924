import { ConditionEnum } from 'graphql-common';
import { TFunction } from 'i18next';
import {
  conditionNavigateTitleSuffix,
  conditionNavItemLabelByCondition,
} from 'components/FormTemplateBuilder/constants';
import { NavItemTypeEnum } from '@lib/components/Menu/Menu';

export default function getConditionNavItems(
  onClick: (v: ConditionEnum) => void,
  t: TFunction<'translation', undefined>,
) {
  return Object.values(ConditionEnum).map((condition) => ({
    title: `${t(conditionNavItemLabelByCondition[condition])} ${conditionNavigateTitleSuffix[condition]}`,
    id: condition,
    type: NavItemTypeEnum.Secondary,
    onClick: () => onClick(condition),
  }));
}
