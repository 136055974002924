import React from 'react';
import { ClearIndicatorProps } from 'react-select';
import MaterialIcon from '@lib/components/MaterialIcon/MaterialIcon';
import styles from '../Select.module.scss';

function ClearIndicator(props: ClearIndicatorProps): JSX.Element {
  const {
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <div {...restInnerProps} ref={ref} className={styles.clearIconWrap}>
      <MaterialIcon icon="cancel" className={styles.clearIndicator} />
    </div>
  );
}

export default ClearIndicator;
