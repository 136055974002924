import { useOutletContext } from 'react-router-dom';
import { ComponentEnum, LevelEnum, User } from 'graphql-common';
import { PlanState } from '@lib/enums/plan';

export type ContextProps = {
  getUserAccessByCompany: (component: ComponentEnum) => LevelEnum;
  getUserAccessByComponent: (component: ComponentEnum) => LevelEnum;
  getUserAccessByUrl: (pathname: string) => LevelEnum;
  getUserAccessItemIdsByComponent: (component: ComponentEnum) => string[];
  handleOpenPlanModal: (state: PlanState) => void;
  onLogoutHandler: () => void;
  user: User;
};

export default function useOutletContextHook() {
  const outletContext: ContextProps = useOutletContext();
  return outletContext;
}
