import { useTranslation } from 'react-i18next';
import {
  useDocumentationBulkCreateMutation,
  useDocumentationBulkDestroyMutation,
  useDocumentationUpdateMutation,
  useTagCreateMutation,
  useTagDestroyMutation,
} from 'graphql-common';
import { showGraphQLErrors } from '@lib/utils/graphQLErrors';

export default function useMutations() {
  const { t } = useTranslation();

  const [documentationBulkCreateMutation, documentationCreateMutationResult] =
    useDocumentationBulkCreateMutation({
      onError: (error) => showGraphQLErrors(error, t),
    });

  const [documentationUpdateMutation, documentationUpdateMutationResult] =
    useDocumentationUpdateMutation({
      onError: (error) => showGraphQLErrors(error, t),
    });

  const [documentationBulkDestroyMutation] =
    useDocumentationBulkDestroyMutation({
      onError: (error) => showGraphQLErrors(error, t),
    });

  const [tagCreateMutation, tagCreateMutationResult] = useTagCreateMutation();

  const [tagDestroyMutation] = useTagDestroyMutation({
    onError: (error) => showGraphQLErrors(error, t),
  });

  return {
    documentationBulkCreateMutation,
    documentationCreateMutationResult,
    documentationBulkDestroyMutation,
    documentationUpdateMutation,
    documentationUpdateMutationResult,
    tagCreateMutation,
    tagCreateMutationResult,
    tagDestroyMutation,
  };
}
