import Chip, { ChipSizes } from '@lib/components/Chip/Chip';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Task, TaskCompletionStatusEnum } from 'graphql-common';
import ChipWithDropDown from '@lib/components/ChipWithDropDown/ChipWithDropDown';
import Menu, { NavItemTypeEnum } from '@lib/components/Menu/Menu';
import getResultStateChipType from 'utils/getResultStateChipType';

type Props = {
  onResultStateChangeHandler?: (
    id: string,
    v: TaskCompletionStatusEnum,
  ) => void;
  task: Task;
};

export default function ResultStateChip(props: Props) {
  const { onResultStateChangeHandler, task } = props;
  const { id, completion } = task;
  const { status: resultState } = completion || {};
  const { t } = useTranslation();
  const options = [
    { value: TaskCompletionStatusEnum.Solved, title: t('solved') },
    { value: TaskCompletionStatusEnum.Unsolved, title: t('unsolved') },
    { value: TaskCompletionStatusEnum.InProgress, title: t('in-progress') },
  ];
  const label = resultState
    ? options.find(({ value }) => value === resultState)?.title
    : undefined;
  if (!label || resultState === null) return '-';
  const type = getResultStateChipType(resultState);
  if (onResultStateChangeHandler !== undefined) {
    const chipLeftIcon = (statusItem: TaskCompletionStatusEnum) => (
      <Chip
        leftIcon="circle"
        type={getResultStateChipType(statusItem)}
        size={ChipSizes.smallLeftIconOnly}
      />
    );
    const getBody = () => (
      <Menu
        navItems={options
          .filter(({ value }) => value !== resultState)
          .map(({ value, title }) => ({
            id: value,
            title,
            onClick: () => onResultStateChangeHandler(id, value),
            type: NavItemTypeEnum.Secondary,
            leftIcon: () => chipLeftIcon(value),
          }))}
      />
    );
    return (
      <div>
        <ChipWithDropDown
          label={label}
          type={type}
          leftIcon="circle"
          width={160}
          body={getBody}
        />
      </div>
    );
  }
  return <Chip type={type} label={label} leftIcon="circle" />;
}
