import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import _get from 'lodash/get';
import {
  FormTemplate,
  FormTemplateScopeNameEnum,
  useFormTemplateBulkArchiveMutation,
  useFormTemplateBulkDestroyMutation,
  useFormTemplateBulkRestoreMutation,
} from 'graphql-common';
import toast from '@lib/components/Toast/Toast';
import usePaginationParams from '@lib/hooks/usePaginationParams';
import onDestroyListItemCallBack from '@lib/utils/onDestroyListItemCallBack';
import { showGraphQLErrors } from '@lib/utils/graphQLErrors';
import useSearchParam from '@lib/hooks/useSearchParam';
import { UseBulkSelectResult } from '@lib/hooks/useTableItemsBulkSelect';
import { APP_URLS } from 'constants/urls';
import useFormTemplatesQueryHook from 'utils/fetch/useFormTemplatesQueryHook';
import tableItemsBulkAction from '@lib/utils/tableItemsBulkAction';
import getAllTableItemsIds from '@lib/utils/getAllTableItemsIds';
import { ApolloError } from '@apollo/client/errors';
import { UseModalManagementResult } from './useModalManagement';

interface UseActionsProps {
  tabParam?: FormTemplateScopeNameEnum;
  useBulkSelectResult: UseBulkSelectResult<FormTemplate>;
  useModalManagementResult: UseModalManagementResult;
}

export interface UseActionsResult {
  formTemplateCounter: object;
  formTemplates: FormTemplate[];
  formTemplatesError: ApolloError | undefined;
  formTemplatesFirstLoading: boolean;
  formTemplatesLoading: boolean;
  onArchiveHandler: (v?: FormTemplate) => void;
  onConfirmedArchive: () => void;
  onConfirmedDestroy: () => void;
  onCreateTaskHandler: (v?: FormTemplate) => void;
  onDestroyHandler: (v?: FormTemplate) => void;
  onDuplicateHandler: (v?: FormTemplate) => void;
  onEditHandler: (v?: FormTemplate) => void;
  onRestoreHandler: (v?: FormTemplate) => void;
  onViewHandler: (v?: FormTemplate) => void;
  totalFormTemplatesCount: number;
}

export default function useActions(props: UseActionsProps): UseActionsResult {
  const { tabParam, useBulkSelectResult, useModalManagementResult } = props;
  const { handleUnselectAllTableItems, handleSelectSingleTableItem } =
    useBulkSelectResult;
  const {
    closeArchiveModal,
    closeDestroyModal,
    openArchiveModal,
    openDestroyModal,
  } = useModalManagementResult;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { search, pathname } = useLocation();
  const [searchQuery] = useSearchParam();
  const [paginationParams] = usePaginationParams();

  // Form templates
  const {
    fetchFormTemplateIds,
    formTemplateCounter,
    formTemplates,
    formTemplatesError,
    formTemplatesFirstLoading,
    formTemplatesLoading,
    refetchFormTemplates,
    totalFormTemplatesCount,
    totalPages,
  } = useFormTemplatesQueryHook({
    paginationParams,
    searchQuery,
    tabParam,
  });

  const onGetAllFormTemplateIds = () =>
    getAllTableItemsIds<FormTemplate>({
      fetchIds: fetchFormTemplateIds,
      useBulkSelectResult,
    });

  // Destroy
  const [destroyMutation] = useFormTemplateBulkDestroyMutation({
    onCompleted: (_response, clientOptions) => {
      closeDestroyModal();
      handleUnselectAllTableItems();
      onDestroyListItemCallBack({
        collection: formTemplates,
        collectionForDestroy: clientOptions?.variables?.ids || [],
        navigate,
        pathname,
        refetch: refetchFormTemplates,
        search,
        totalPages,
      });
    },
    onError: (error) => showGraphQLErrors(error, t),
  });

  // Archive
  const [archiveMutation] = useFormTemplateBulkArchiveMutation({
    onCompleted: (_response, clientOptions) => {
      closeArchiveModal();
      handleUnselectAllTableItems();
      onDestroyListItemCallBack({
        collection: formTemplates,
        collectionForDestroy: clientOptions?.variables?.ids || [],
        navigate,
        pathname,
        refetch: refetchFormTemplates,
        search,
        totalPages,
      });
    },
    onError: (error) => showGraphQLErrors(error, t),
  });

  // Restore
  const [restoreMutation] = useFormTemplateBulkRestoreMutation({
    onCompleted: (_response, clientOptions) => {
      handleUnselectAllTableItems();
      toast({
        content: t('restore-form-template-success'),
      });
      onDestroyListItemCallBack({
        collection: formTemplates,
        collectionForDestroy: clientOptions?.variables?.ids || [],
        navigate,
        pathname,
        refetch: refetchFormTemplates,
        search,
        totalPages,
      });
    },
    onError: (error) => showGraphQLErrors(error, t),
  });

  const onViewHandler = (v?: FormTemplate) => {
    const id = _get(v, 'id');
    const versionId = _get(v, 'currentVersion.id');
    if (id && versionId) {
      navigate(
        APP_URLS.dashboard.formTemplates.viewVersion.getDynamicPath({
          pathParts: { id, versionId },
        }),
      );
    }
  };

  const onEditHandler = (v?: FormTemplate) => {
    const id = _get(v, 'id');
    const versionId = _get(v, 'currentVersion.id');
    if (id && versionId) {
      navigate(
        APP_URLS.dashboard.formTemplates.editVersion.getDynamicPath({
          pathParts: { id, versionId },
        }),
      );
    }
  };

  const onDuplicateHandler = (v?: FormTemplate) => {
    const id = _get(v, 'id');
    const versionId = _get(v, 'currentVersion.id');
    if (id && versionId) {
      navigate(
        APP_URLS.dashboard.formTemplates.duplicate.getDynamicPath({
          pathParts: { id, versionId },
        }),
      );
    }
  };

  const onConfirmedDestroy = () => {
    closeDestroyModal();
    toast({
      content: t('destroy-form-template-success'),
      closeCallback: () => {
        tableItemsBulkAction<FormTemplate>({
          action: (idsValue?: string[]) => {
            if (idsValue) {
              destroyMutation({
                variables: {
                  ids: idsValue,
                },
              });
            }
          },
          useBulkSelectResult,
          onGetAllIds: onGetAllFormTemplateIds,
        });
      },
    });
  };

  const onConfirmedArchive = () => {
    closeArchiveModal();
    toast({
      content: t('archive-form-template-success'),
      closeCallback: () => {
        tableItemsBulkAction<FormTemplate>({
          action: (idsValue?: string[]) => {
            if (idsValue) {
              archiveMutation({
                variables: {
                  ids: idsValue,
                },
              });
            }
          },
          useBulkSelectResult,
          onGetAllIds: onGetAllFormTemplateIds,
        });
      },
    });
  };

  const onCreateTaskHandler = (v?: FormTemplate) => {
    navigate(
      APP_URLS.dashboard.tasks.add.getPathWithQuery({
        formTemplateId: _get(v, 'id'),
        formTemplateName: _get(v, 'name'),
        formTemplateVersionId: _get(v, 'currentVersion.id'),
        formTemplateVersionName: _get(v, 'currentVersion.version'),
      }),
    );
  };

  const onDestroyHandler = (v?: FormTemplate) => {
    if (v?.id) {
      handleSelectSingleTableItem(v);
      openDestroyModal();
    }
  };

  const onArchiveHandler = (v?: FormTemplate) => {
    if (v?.id) {
      handleSelectSingleTableItem(v);
      openArchiveModal();
    }
  };

  const onRestoreHandler = (v?: FormTemplate) => {
    if (v) handleSelectSingleTableItem(v);
    tableItemsBulkAction<FormTemplate>({
      action: (idsValue?: string[]) => {
        if (idsValue) {
          restoreMutation({
            variables: {
              ids: idsValue,
            },
          });
        }
      },
      useBulkSelectResult: {
        ...useBulkSelectResult,
        selectedSingleTableItem: v || null,
      },
      onGetAllIds: onGetAllFormTemplateIds,
    });
  };

  return {
    formTemplateCounter,
    formTemplates,
    formTemplatesError,
    formTemplatesFirstLoading,
    formTemplatesLoading,
    onArchiveHandler,
    onConfirmedArchive,
    onConfirmedDestroy,
    onCreateTaskHandler,
    onDestroyHandler,
    onDuplicateHandler,
    onEditHandler,
    onRestoreHandler,
    onViewHandler,
    totalFormTemplatesCount,
  };
}
