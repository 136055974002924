import React, { forwardRef, memo } from 'react';
import { useTranslation } from 'react-i18next';
import _get from 'lodash/get';
import Input from '@lib/components/Input/Input';
import { InputTypes } from '@lib/components/Input/enums';
import { Values } from '@lib/interfaces/form';
import formStyles from '@lib/assets/styles/Form.module.scss';

type ChangeEventType = React.ChangeEvent<
  HTMLInputElement | HTMLTextAreaElement
>;

interface Props {
  disabled?: boolean;
  hasError?: boolean;
  labels?: string[];
  onChange?: (v: Values[]) => void;
  value?: number[];
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const InputMinMax = forwardRef(
  (props: Props, ref: React.ForwardedRef<HTMLInputElement>) => {
    const { disabled, labels, value, onChange, hasError } = props;
    const { t } = useTranslation();
    const firstLabel = _get(labels, 0, t('min'));
    const secondLabel = _get(labels, 1, t('max'));
    const onChangeHandler = (v: ChangeEventType, index: number) => {
      const newRangeValue = [];
      // @ts-ignore // TODO: fix this
      if (Array.isArray(value)) newRangeValue.push(...value);
      if (v) {
        const newValue = v.target.value;
        // @ts-ignore // TODO: fix this
        newRangeValue[index] = newValue?.length ? newValue : undefined;
      }
      if (onChange) {
        onChange(newRangeValue);
      }
    };
    const onChangeMinHandler = (v: ChangeEventType) => {
      onChangeHandler(v, 0);
    };
    const onChangeMaxHandler = (v: ChangeEventType) => {
      onChangeHandler(v, 1);
    };
    return (
      <div className={formStyles.fieldWithTwoColumns}>
        <Input
          type={InputTypes.number}
          value={value?.[0]}
          label={firstLabel}
          disabled={disabled}
          onChange={onChangeMinHandler}
          max={value?.[1] ? parseInt(`${value[1]}`, 10) - 1 : undefined}
          hasError={hasError}
          precision={2}
        />
        <Input
          type={InputTypes.number}
          value={value?.[1]}
          label={secondLabel}
          disabled={disabled}
          onChange={onChangeMaxHandler}
          min={value?.[0] ? parseInt(`${value[0]}`, 10) + 1 : undefined}
          hasError={hasError}
          precision={2}
        />
      </div>
    );
  },
);

export default memo(InputMinMax);
