import { ChipTypes } from '@lib/components/Chip/Chip';
import { TaskStatusEnum } from 'graphql-common';

export default function getTaskStatusChipType(status: TaskStatusEnum) {
  let type = ChipTypes.chipDisabled;
  if (status === TaskStatusEnum.OnValidation) type = ChipTypes.chipOrange;
  if (status === TaskStatusEnum.Rejected) type = ChipTypes.chipRed;
  if (status === TaskStatusEnum.Validated) type = ChipTypes.chipGreen;
  if (status === TaskStatusEnum.Completed) type = ChipTypes.chipGray;
  if (status === TaskStatusEnum.Archived) type = ChipTypes.chipGray;
  return type;
}
