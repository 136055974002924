import React, { memo } from 'react';
import { BaseItem, CellProps } from '@lib/components/Table/types';
import TextCell from '@lib/components/Table/commonComponents/TextCell/TextCell';
import Button, { ButtonTypes } from '@lib/components/Button/Button';
import { useTranslation } from 'react-i18next';
import Chip, { ChipTypes } from '@lib/components/Chip/Chip';
import styles from './UserSetupCell.module.scss';

interface Props {
  isViewOnly?: boolean;
  onResendEmailConfirmationHandler: () => void;
  onResendRegistrationConfirmationHandler: () => void;
}

function UserSetupCell<T extends BaseItem>(props: CellProps<T> & Props) {
  const {
    currentUserId,
    isViewOnly = false,
    item,
    onResendEmailConfirmationHandler,
    onResendRegistrationConfirmationHandler,
  } = props;
  const { id, setup, unconfirmedEmail, verified, deactivatedAt, email } = item;
  const { t } = useTranslation();
  if (id === currentUserId) return null;
  if (deactivatedAt) {
    return (
      <div className={styles.root}>
        <Chip
          type={ChipTypes.chipDisabled}
          label={t('deactivated')}
          leftIconSymbolsOutlined
          leftIcon="do_not_disturb_on"
        />
      </div>
    );
  }
  if (!setup && !verified) {
    return (
      <div className={styles.root}>
        <TextCell {...props} disabled value={t('invitation-pending')} />
        {!isViewOnly && email ? (
          <Button
            buttonText={t('resend-invitation')}
            buttonType={ButtonTypes.primaryOutlined}
            onClick={onResendRegistrationConfirmationHandler}
          />
        ) : null}
      </div>
    );
  }
  if (!setup) {
    return (
      <div className={styles.root}>
        <TextCell
          {...props}
          disabled
          value={t('finish-registration-pending')}
        />
        {!isViewOnly && email ? (
          <Button
            buttonText={t('resend-invitation')}
            buttonType={ButtonTypes.primaryOutlined}
            onClick={onResendRegistrationConfirmationHandler}
          />
        ) : null}
      </div>
    );
  }
  if (unconfirmedEmail) {
    return (
      <div className={styles.root}>
        <Button
          buttonText={t('resend-email-confirmation')}
          buttonType={ButtonTypes.primaryOutlined}
          onClick={onResendEmailConfirmationHandler}
        />
      </div>
    );
  }
  return null;
}

export default memo(UserSetupCell) as <T extends BaseItem>(
  props: CellProps<T> & Props,
) => JSX.Element;
