import React from 'react';
import _get from 'lodash/get';
import _find from 'lodash/find';
import classNames from 'classnames';
import {
  components as DefaultSelectComponents,
  OptionProps,
} from 'react-select';
import { SelectOption } from '@lib/components/Select/types';
import MaterialIcon from '@lib/components/MaterialIcon/MaterialIcon';
import Chip, { ChipSizes, ChipTypes } from '@lib/components/Chip/Chip';
import styles from '../Select.module.scss';

export default function ChipCircleOption({
  children,
  ...restProps
}: OptionProps): JSX.Element {
  const { isSelected, isMulti, options } = restProps;
  const value = _get(restProps, 'value', '');
  const selectedOption = _find(
    options,
    ({ value: optionValue }) => value === optionValue,
  ) as SelectOption;
  const color = _get(selectedOption, 'color', ChipTypes.chipGray);
  return (
    <DefaultSelectComponents.Option
      {...restProps}
      className={classNames(
        styles.chipCircleOption,
        styles.entityOptionWithLeftIcon,
        {
          active: isSelected,
          [styles.optionWithCheckbox]: isMulti,
        },
      )}
    >
      {isMulti && (
        <span className={styles.optionCheckbox}>
          <MaterialIcon
            icon={isSelected ? 'check_box' : 'check_box_outline_blank'}
            size="md-18"
          />
        </span>
      )}
      <span className={styles.chipOptionIcon}>
        <Chip
          leftIcon="circle"
          type={color}
          size={ChipSizes.smallLeftIconOnly}
        />
      </span>
      {children}
    </DefaultSelectComponents.Option>
  );
}
