import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import _get from 'lodash/get';
import _without from 'lodash/without';
import classNames from 'classnames';
import {
  AccessesFieldType,
  getAccessCategoriesScopeIds,
  getAccessesManagementOptions,
  getAccessesValues,
  getAccessLevelOptions,
  getAccessLevelShortOptions,
  getEmptyAccessesValues,
} from '@lib/utils/accessesManagement';
import Typography from '@lib/components/Typography/Typography';
import Tooltip from '@lib/components/Tooltip/Tooltip';
import MaterialIcon from '@lib/components/MaterialIcon/MaterialIcon';
import RadioButtonGroup, {
  RadioButtonWrapViews,
} from '@lib/components/RadioButtonGroup/RadioButtonGroup';
import RadioButtonAsButton from '@lib/components/RadioButtonAsButton/RadioButtonAsButton';
import InterventionCategoriesChipsList from '@lib/components/InterventionCategoriesChipsList/InterventionCategoriesChipsList';
import AssetCategoriesChipsList from '@lib/components/AssetCategoriesChipsList/AssetCategoriesChipsList';
import { ComponentEnum, LevelEnum, User } from 'graphql-common';
import Chip, { ChipSizes, ChipTypes } from '@lib/components/Chip/Chip';
import { APP_URLS } from 'constants/urls';
import useOutletContext, { ContextProps } from 'hooks/useOutletContext';
import styles from './UserAccessView.module.scss';

function getListItemContentClassName(fieldType: AccessesFieldType) {
  if (
    fieldType === AccessesFieldType.FullOptions ||
    fieldType === AccessesFieldType.ShortOptions
  ) {
    return classNames(
      styles.teamAccessListItemContent,
      styles.teamAccessListItemContentRow,
    );
  }
  return classNames(
    styles.teamAccessListItemContent,
    styles.teamAccessListItemContentCol,
  );
}

type Props = {
  user: User;
};

function UserAccessView(props: Props) {
  const { user } = props;
  const { t } = useTranslation();
  const { getUserAccessByCompany }: ContextProps = useOutletContext();
  const isInterventionsLocked =
    getUserAccessByCompany(ComponentEnum.InterventionsManagement) ===
    LevelEnum.LimitedRead;

  const { personalAccesses = [], team } = user;
  const { accesses: teamAccesses = [] } = team || {};
  const accessesValues = {
    ...getEmptyAccessesValues(),
    ...getAccessesValues(teamAccesses),
    ...getAccessesValues(personalAccesses),
  };

  // Intervention categories ids
  const interventionCategoriesIds = getAccessCategoriesScopeIds(
    _without(
      [accessesValues[ComponentEnum.InterventionsManagementCategoriesScope]],
      undefined,
    ),
    ComponentEnum.InterventionsManagementCategoriesScope,
  );
  // Asset categories ids
  const assetCategoriesIds = getAccessCategoriesScopeIds(
    _without(
      [accessesValues[ComponentEnum.AssetsManagementCategoriesScope]],
      undefined,
    ),
    ComponentEnum.AssetsManagementCategoriesScope,
  );

  return (
    <div>
      {getAccessesManagementOptions().map((option) => (
        <div key={option.value} className={styles.teamAccessListItem}>
          <div className={getListItemContentClassName(option.fieldType)}>
            <div className={styles.teamAccessListItemTitleWrap}>
              <div className={styles.teamAccessListItemTitle}>
                <Typography variant="body" strong>
                  {t(option.labelTransKey)}
                </Typography>
                {!!option.descriptionTransKey && (
                  <div className={styles.teamAccessListItemTitleInfo}>
                    <Tooltip body={t(option.descriptionTransKey)}>
                      <MaterialIcon icon="info" symbolsOutlined size="md-16" />
                    </Tooltip>
                  </div>
                )}
              </div>
            </div>
            <div className={styles.col}>
              <div className={styles.teamAccessListItemField}>
                {option.value === ComponentEnum.InterventionsManagement &&
                isInterventionsLocked ? (
                  <Tooltip body={t('lock-state-title-interventions')}>
                    <Chip
                      leftIcon="lock"
                      size={ChipSizes.small}
                      type={ChipTypes.chipBlue}
                      to={APP_URLS.dashboard.interventions.index.path}
                      asBlockElement
                    />
                  </Tooltip>
                ) : (
                  <RadioButtonGroup
                    options={getAccessLevelOptions()}
                    RadioButtonComponent={RadioButtonAsButton}
                    wrapView={RadioButtonWrapViews.row}
                    disabled
                    value={_get(accessesValues, [option.value, 'level'])}
                  />
                )}
              </div>
            </div>
          </div>
          {option.value === ComponentEnum.InterventionsManagement &&
          isInterventionsLocked
            ? null
            : !!option.subOptions?.length &&
              option.subOptions.map((subOption) => (
                <div
                  key={subOption.value}
                  className={getListItemContentClassName(subOption.fieldType)}
                >
                  <div className={styles.teamAccessListItemTitleWrap}>
                    <div className={styles.teamAccessListItemTitle}>
                      <Typography variant="body">
                        {t(subOption.labelTransKey)}
                      </Typography>
                      {!!subOption.descriptionTransKey && (
                        <div className={styles.teamAccessListItemTitleInfo}>
                          <Tooltip body={t(subOption.descriptionTransKey)}>
                            <MaterialIcon
                              icon="info"
                              symbolsOutlined
                              size="md-16"
                            />
                          </Tooltip>
                        </div>
                      )}
                    </div>
                  </div>
                  {subOption.fieldType === AccessesFieldType.FullOptions && (
                    <div className={styles.col}>
                      <div className={styles.teamAccessListItemField}>
                        <RadioButtonGroup
                          options={getAccessLevelOptions()}
                          RadioButtonComponent={RadioButtonAsButton}
                          wrapView={RadioButtonWrapViews.row}
                          disabled
                          value={_get(accessesValues, [
                            subOption.value,
                            'level',
                          ])}
                        />
                      </div>
                    </div>
                  )}
                  {subOption.fieldType === AccessesFieldType.ShortOptions && (
                    <div className={styles.col}>
                      <div className={styles.teamAccessListItemField}>
                        <RadioButtonGroup
                          options={getAccessLevelShortOptions()}
                          RadioButtonComponent={RadioButtonAsButton}
                          wrapView={RadioButtonWrapViews.row}
                          disabled
                          value={_get(accessesValues, [
                            subOption.value,
                            'level',
                          ])}
                        />
                      </div>
                    </div>
                  )}
                  {subOption.fieldType ===
                    AccessesFieldType.InterventionCategoriesSelect && (
                    <div>
                      {interventionCategoriesIds?.length ? (
                        <div className={styles.teamAccessListItemField}>
                          <InterventionCategoriesChipsList
                            ids={interventionCategoriesIds}
                            key={interventionCategoriesIds.length}
                          />
                        </div>
                      ) : (
                        <div className={styles.teamAccessListItemField}>
                          <Chip
                            type={ChipTypes.chipWhite}
                            label={t('all-categories')}
                          />
                        </div>
                      )}
                    </div>
                  )}
                  {subOption.fieldType ===
                    AccessesFieldType.AssetCategoriesSelect && (
                    <div>
                      {assetCategoriesIds?.length ? (
                        <div className={styles.teamAccessListItemField}>
                          <AssetCategoriesChipsList
                            ids={assetCategoriesIds}
                            key={assetCategoriesIds.length}
                          />
                        </div>
                      ) : (
                        <div className={styles.teamAccessListItemField}>
                          <Chip
                            type={ChipTypes.chipWhite}
                            label={t('all-categories')}
                          />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              ))}
        </div>
      ))}
    </div>
  );
}

export default memo(UserAccessView);
