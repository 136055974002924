import React from 'react';
import _get from 'lodash/get';
import _has from 'lodash/has';
import _find from 'lodash/find';
import { User } from 'graphql-common';
import {
  components as DefaultSelectComponents,
  SingleValueProps,
} from 'react-select';
import { SelectOption } from '@lib/components/Select/types';
import UserChip from '@lib/components/UserChip/UserChip';
import styles from '../Select.module.scss';

function UserSingleValue({
  children,
  ...restProps
}: SingleValueProps): JSX.Element {
  const { options } = restProps;
  const selectValue = _get(restProps, 'selectProps.value');
  const value = _get(selectValue, 'value', '');
  let selectedOption;
  if (_has(selectValue, 'value') && _has(selectValue, 'label')) {
    selectedOption = selectValue;
  } else if (_has(selectValue, 'value')) {
    selectedOption = _find(
      options,
      ({ value: optionValue }) => value === optionValue,
    ) as SelectOption;
  }
  const data = _get(selectedOption, 'data', {}) as User;
  return (
    <DefaultSelectComponents.SingleValue
      {...restProps}
      className={styles.singleValue}
    >
      <div className={styles.singleValueBox}>
        <UserChip user={data} />
      </div>
    </DefaultSelectComponents.SingleValue>
  );
}

export default UserSingleValue;
