import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button, { ButtonTypes } from '@lib/components/Button/Button';
import ActionsRow from '@lib/components/ActionsRow/ActionsRow';
import useStepParam from '@lib/hooks/useStepParam';
import { APP_URLS } from 'constants/urls';
import { Steps } from '@lib/enums/form';
import styles from '@lib/assets/styles/Form.module.scss';

export interface Props {
  stepsCount: Steps;
}

interface Action1Props {
  navigateToPreviousStep: () => void;
  step: number;
}

interface Action4Props {
  isLastStep: boolean;
  navigateToNextStep: () => void;
}

export function Action1({ step, navigateToPreviousStep }: Action1Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  if (step > Steps.step1) {
    return (
      <Button
        key="back"
        buttonType={ButtonTypes.primaryOutlined}
        buttonText={t('back')}
        leftIcon="arrow_back"
        fullWidth
        onClick={navigateToPreviousStep}
      />
    );
  }

  return (
    <Button
      key="close"
      buttonType={ButtonTypes.secondaryOutlined}
      buttonText={t('cancel')}
      leftIcon="close"
      fullWidth
      onClick={() =>
        navigate(APP_URLS.dashboard.sites.index.getPathWithQuery())
      }
    />
  );
}

export function Action4({ isLastStep, navigateToNextStep }: Action4Props) {
  const { t } = useTranslation();
  if (isLastStep) {
    return (
      <Button
        key="submit"
        type="submit"
        buttonType={ButtonTypes.primaryFilled}
        buttonText={t('create-task')}
        leftIcon="check"
        fullWidth
      />
    );
  }
  return (
    <Button
      key="next"
      buttonType={ButtonTypes.primaryFilled}
      buttonText={t('continue')}
      rightIcon="arrow_forward"
      fullWidth
      onClick={navigateToNextStep}
    />
  );
}

function TaskAddFormActions({ stepsCount }: Props) {
  const [step, setStep] = useStepParam();
  const isLastStep = step === stepsCount;
  const navigateToStep = (newStep: number) => setStep(newStep);
  const navigateToPreviousStep = () => {
    if (step > 1) {
      navigateToStep(step - 1);
    }
  };
  const navigateToNextStep = () => {
    const nextStep = step + 1;
    if (nextStep <= stepsCount) {
      navigateToStep(step + 1);
    }
  };

  return (
    <ActionsRow
      className={styles.formActions}
      actions={[
        <Action1 step={step} navigateToPreviousStep={navigateToPreviousStep} />,
        <div />,
        <div />,
        <Action4
          isLastStep={isLastStep}
          navigateToNextStep={navigateToNextStep}
        />,
      ]}
    />
  );
}

export default TaskAddFormActions;
