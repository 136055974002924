import { LazyQueryExecFunction } from '@apollo/client';
import {
  SortDirectionEnum,
  User,
  UserFilterInputObject,
  UserScopeArgsAccessInputObject,
  UsersSearchListQuery,
  UsersSearchListQueryVariables,
} from 'graphql-common';
import { DEFAULT_SEARCH_LIST_VARIABLES } from '@lib/enums/urls';
import getFullName from '@lib/utils/getFullName';
import getLoadOptionsResult from '@lib/utils/getLoadOptionsResult';
import createSearchFilters from '@lib/utils/createSearchFilters';
import { LoadOptionsParams } from '@lib/components/Select/types';

type FetchLazyQuery = LazyQueryExecFunction<UsersSearchListQuery, any>;

const fetchSearchList = async (
  searchQuery: string,
  fetchUsersSearchListLazyQuery: FetchLazyQuery,
  page: number,
  siteId?: string,
  customFilters?: UserFilterInputObject[],
  accessScope?: UserScopeArgsAccessInputObject,
) => {
  if (!siteId) return [];
  const options: { variables: UsersSearchListQueryVariables } = {
    variables: {
      scope: {
        custom: {
          byCore: {
            siteId,
            onlyActive: true,
            access: accessScope,
          },
        },
      },
      ...DEFAULT_SEARCH_LIST_VARIABLES,
      page,
      sorts: { fullName: SortDirectionEnum.Asc },
    },
  };
  const filters: UserFilterInputObject[] = [];
  if (searchQuery) {
    filters.push(
      ...createSearchFilters(searchQuery, [
        { fieldName: 'fullName' },
        { fieldName: 'email' },
        { fieldName: 'role', predicateType: 'eq' },
        { fieldName: 'personalId' },
      ]),
    );
  }
  if (customFilters?.length) {
    filters.push(...customFilters);
  }
  if (filters.length) {
    options.variables.filters = filters;
  }
  const { data } = await fetchUsersSearchListLazyQuery(options);
  return getLoadOptionsResult(
    data,
    (dataItem: User) => ({
      value: dataItem.id,
      label: getFullName(dataItem),
      data: dataItem,
    }),
    searchQuery,
  );
};

export const getSiteMembershipsLoadOptions =
  (
    fetchLazyQuery: FetchLazyQuery,
    siteId?: string,
    customFilters?: UserFilterInputObject[],
    accessScope?: UserScopeArgsAccessInputObject,
  ) =>
  (searchInput: string, loadedOptions: unknown, params: LoadOptionsParams) =>
    fetchSearchList(
      searchInput,
      fetchLazyQuery,
      params?.page || 1,
      siteId,
      customFilters,
      accessScope,
    );
