import React from 'react';
import _get from 'lodash/get';
import { TFunction } from 'i18next';
import { User } from 'graphql-common';
import UserPreviewCell from '@lib/components/Table/commonComponents/UserPreviewCell/UserPreviewCell';
import TextCell from '@lib/components/Table/commonComponents/TextCell/TextCell';
import { CellProps } from '@lib/components/Table/types';
import { getRoleOption } from '@lib/utils/roles';
import Chip, { ChipTypes } from '@lib/components/Chip/Chip';

type ColumnsArgs = {
  t: TFunction<'translation', undefined>;
  openTeamPage: (id: string) => void;
};

export default function getTableColumns({ openTeamPage, t }: ColumnsArgs) {
  return [
    {
      id: 'fullName',
      Header: t('name'),
      Cell: ({ item }: CellProps<User>) => (
        <UserPreviewCell
          avatar={
            item.avatarAttached?.urls?.small ||
            item.avatarAttached?.urls?.original
          }
          name={`${item.firstName} ${item.lastName}`}
          email={item.email}
        />
      ),
      minWidth: 308,
      width: 308,
      sortable: true,
    },
    {
      id: 'role',
      Header: t('role'),
      accessorFn: ({ item }: CellProps<User>) => {
        const role = getRoleOption(item.role)?.labelKey;
        return role ? t(role) : '-';
      },
      Cell: (props: CellProps<User>) => (
        <TextCell {...props} disabled={!_get(props, 'item.setup')} />
      ),
      maxWidth: 150,
      minWidth: 150,
      width: 150,
      sortable: true,
    },
    {
      id: 'teamName',
      Header: t('team'),
      accessorFn: ({ item }: CellProps<User>) => {
        const { team } = item;
        if (team?.id) {
          return (
            <div>
              <Chip
                type={team.color as ChipTypes}
                label={team.name}
                leftIcon="circle"
                onClick={() => openTeamPage(team.id)}
              />
            </div>
          );
        }
        return '-';
      },
      maxWidth: 150,
      minWidth: 150,
      width: 150,
      sortable: true,
    },
  ];
}
