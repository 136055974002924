import React, { useState } from 'react';
import LinkComponent from '@lib/components/Link/Link';
import { useTranslation } from 'react-i18next';
import MaterialIcon from '@lib/components/MaterialIcon/MaterialIcon';
import Modal from '@lib/components/Modal/Modal';
import Trans from '@lib/components/Trans/Trans';
import Button from '@lib/components/Button/Button';
import { IconName } from '@lib/components/Modal/enums';
import styles from './FAQAccesses.module.scss';

export default function FAQAccesses() {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);
  return (
    <>
      <div className={styles.linkContainer}>
        <MaterialIcon icon="help" symbolsOutlined size="md-18" />
        <LinkComponent onClick={openModal}>
          {t('faq-team-link-text')}
        </LinkComponent>
      </div>
      <Modal
        isOpen={isOpen}
        onClose={closeModal}
        title={t('faq-team-modal-title')}
        icon={IconName.Help}
        description={<Trans i18nKey="faq-team-modal-text" />}
        descriptionAlign="left"
        actions={
          <Button
            onClick={closeModal}
            buttonText={t('ok')}
            fullWidth
            leftIcon="check"
          />
        }
      />
    </>
  );
}
