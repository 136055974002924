import { useTranslation } from 'react-i18next';
import { FormTemplateScopeNameEnum } from 'graphql-common';
import { UseModalManagementResult } from './useModalManagement';
import { UseActionsResult } from './useActions';
import getTableTabs from './getTableTabs';
import getTableColumns from './getTableColumns';
import getTableActionsMenu from './getTableActionsMenu';
import getTableActionsForSelected from './getTableActionsForSelected';

type Args = {
  formTemplateScope: FormTemplateScopeNameEnum;
  useActionsResult: UseActionsResult;
  useModalManagementResult: UseModalManagementResult;
  isViewOnly: boolean;
};

export default function useTableHelpers(args: Args) {
  const {
    formTemplateScope,
    useActionsResult,
    useModalManagementResult,
    isViewOnly,
  } = args;
  const {
    formTemplateCounter,
    onArchiveHandler,
    onCreateTaskHandler,
    onDestroyHandler,
    onDuplicateHandler,
    onEditHandler,
    onRestoreHandler,
    onViewHandler,
  } = useActionsResult;
  const { openDestroyModal, openArchiveModal } = useModalManagementResult;

  const { t } = useTranslation();

  const tableTabs = getTableTabs({ t, formTemplateCounter });
  const tableColumns = getTableColumns({
    formTemplateScope,
    t,
    onCreateTaskHandler,
    onRestoreHandler,
    isViewOnly,
  });
  const tableActionsMenu = getTableActionsMenu({
    onViewHandler,
    onEditHandler,
    onDestroyHandler,
    onDuplicateHandler,
    onArchiveHandler,
    isViewOnly,
    t,
  });
  const tableActionsForSelected = getTableActionsForSelected({
    formTemplateScope,
    onBulkArchive: openArchiveModal,
    onBulkDestroy: openDestroyModal,
    onBulkRestore: onRestoreHandler,
    isViewOnly,
    t,
  });

  return {
    tableColumns,
    tableActionsMenu,
    tableActionsForSelected,
    tableTabs,
  };
}
