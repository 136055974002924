import { RecurringRuleRuleTypeEnum } from 'graphql-common';

export enum EndRepeatUnit {
  date = 'date',
  completions = 'completions',
}

export enum RepeatType {
  each = 'repeatEach',
  onThe = 'repeatOn',
}

export enum Steps {
  's1' = 1,
  's2' = 2,
  's3' = 3,
}

export enum ScheduleGroups {
  earlyCompletion = 'earlyCompletion',
  repeat = 'repeat',
  endRepeat = 'endRepeat',
}

export enum Validations {
  day = 'dayOfWeek',
  dayOfMonth = 'dayOfMonth',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  dayOfWeek = 'dayOfWeek',
  monthOfYear = 'monthOfYear',
}

export enum FieldNames {
  // Step 1
  taskName = 'taskName',
  taskDescription = 'taskDescription',
  priority = 'priority',
  site = 'site',
  assignations = 'assignations',
  siteArea = 'siteArea',
  asset = 'asset',
  approver = 'approver',
  template = 'template',
  templateVersion = 'templateVersion',
  // Step 2
  deadlineAt = 'deadlineAt',
  // Step 2: early completion
  earlyCompletion = ScheduleGroups.earlyCompletion,
  earlyCompletionAmount = `${ScheduleGroups.earlyCompletion}_amount`,
  earlyCompletionEnabled = `${ScheduleGroups.earlyCompletion}_enabled`,
  earlyCompletionType = `${ScheduleGroups.earlyCompletion}_type`,
  // Step 2: repeat
  recurringRule = ScheduleGroups.repeat,
  recurringRuleEnabled = `${ScheduleGroups.repeat}_enabled`,
  recurringRuleRuleType = `${ScheduleGroups.repeat}_type`,
  recurringRuleInterval = `${ScheduleGroups.repeat}_interval`,
  recurringRuleDayOfWeek = `${ScheduleGroups.repeat}_${Validations.dayOfWeek}`,
  recurringRuleCountdownType = `${ScheduleGroups.repeat}_countdownType`,
  recurringRuleExcludedDayOfWeek = `${ScheduleGroups.repeat}_${Validations.dayOfWeek}_excluded`,
  repeatMonthlyType = `${ScheduleGroups.repeat}_${RecurringRuleRuleTypeEnum.Monthly}_type`, // Type, don't send
  repeatMonthlyEachDayOfMonth = `${ScheduleGroups.repeat}_${RecurringRuleRuleTypeEnum.Monthly}_${RepeatType.each}__${Validations.dayOfMonth}`,
  repeatMonthlyRepeatOnOptions = `${ScheduleGroups.repeat}_${RecurringRuleRuleTypeEnum.Monthly}_${RepeatType.onThe}_options`,
  repeatMonthlyRepeatOnOption1 = `${ScheduleGroups.repeat}_${RecurringRuleRuleTypeEnum.Monthly}_${RepeatType.onThe}_option1`,
  repeatMonthlyRepeatOnOption2 = `${ScheduleGroups.repeat}_${RecurringRuleRuleTypeEnum.Monthly}_${RepeatType.onThe}_option2`,
  repeatYearlyType = `${ScheduleGroups.repeat}_${RecurringRuleRuleTypeEnum.Yearly}_type`, // Type, don't send
  repeatYearlyEachMonthOfYear = `${ScheduleGroups.repeat}_${RecurringRuleRuleTypeEnum.Yearly}_${RepeatType.each}_${Validations.monthOfYear}`,
  repeatYearlyEachDayOfMonth = `${ScheduleGroups.repeat}_${RecurringRuleRuleTypeEnum.Yearly}_${RepeatType.each}_${Validations.dayOfMonth}`,
  repeatYearlyRepeatOnOptions = `${ScheduleGroups.repeat}_${RecurringRuleRuleTypeEnum.Yearly}_${RepeatType.onThe}_options`,
  repeatYearlyRepeatOnOption1 = `${ScheduleGroups.repeat}_${RecurringRuleRuleTypeEnum.Yearly}_${RepeatType.onThe}_option1`,
  repeatYearlyRepeatOnOption2 = `${ScheduleGroups.repeat}_${RecurringRuleRuleTypeEnum.Yearly}_${RepeatType.onThe}_option2`,
  repeatYearlyRepeatOnMonthOfYear = `${ScheduleGroups.repeat}_${RecurringRuleRuleTypeEnum.Yearly}_${RepeatType.onThe}_${Validations.monthOfYear}`,
  // Step 2: end repeat
  endRepeat = ScheduleGroups.endRepeat,
  endRepeatEnabled = `${ScheduleGroups.endRepeat}_enabled`,
  endRepeatType = `${ScheduleGroups.endRepeat}_type`,
  endRepeatDate = `${ScheduleGroups.endRepeat}_date`,
  endRepeatAfterOccurrences = `${ScheduleGroups.endRepeat}_afterOccurrences`,
}
