import _get from 'lodash/get';

export default function getLoadOptionsResult(
  data: unknown,
  mapper: (value: never, index: number, array: never[]) => unknown,
  searchQuery: unknown,
) {
  const collection = _get(data, 'data.collection', []);
  const totalCount = _get(data, 'data.metadata.totalCount', 0);
  const limitValue = _get(data, 'data.metadata.limitValue', 1);
  const currentPage = _get(data, 'data.metadata.currentPage', 1);
  return {
    options: collection.map(mapper),
    hasMore: Math.ceil(totalCount / limitValue) > currentPage,
    additional: {
      page: searchQuery ? 1 : currentPage + 1,
    },
  };
}
