import _get from 'lodash/get';
import _toNumber from 'lodash/toNumber';
import {
  EarlyCompletionUnitEnum,
  RecurringRuleCountdownTypeEnum,
  RecurringRuleInputObject,
  RecurringRuleRuleTypeEnum,
  RecurringRuleSchemaInputObject,
  RecurringRuleSchemaMetadataInputObject,
  RecurringRuleSchemaOptionEnum,
  RecurringRuleSchemaRuleInputObject,
  Task,
  TaskBulkCreateSameInputObject,
  TaskBulkSchedulingInputObject,
  TaskPriorityEnum,
  TaskUpdateInputObject,
} from 'graphql-common';
import { Option, Value, Values } from '@lib/interfaces/form';
import { SelectOption } from '@lib/components/Select/types';
import { EndRepeatUnit, FieldNames } from '../enums';

export const getEarlyCompletionFields = (values: Values) => {
  const enable = _get(values, FieldNames.earlyCompletionEnabled, false);
  const unit = _get(
    values,
    `${FieldNames.earlyCompletionType}.value`,
  ) as EarlyCompletionUnitEnum;
  const amount = _get(values, FieldNames.earlyCompletionAmount, 0) as number;
  if (enable) {
    return {
      earlyCompletion: +amount,
      earlyCompletionUnit: unit,
    };
  }
  return {};
};

export const getRecurringRule = (
  values: Values,
): RecurringRuleInputObject | undefined => {
  const enabled = _get(values, FieldNames.recurringRuleEnabled, false);
  const endRepeatEnabled = _get(values, FieldNames.endRepeatEnabled, false);
  const deadlineAt = _get(values, FieldNames.deadlineAt) as string;
  const ruleType = _get(values, `${FieldNames.recurringRuleRuleType}.value`);
  const recurringRuleCountdownType = _get(
    values,
    `${FieldNames.recurringRuleCountdownType}.value`,
  ) as RecurringRuleCountdownTypeEnum;
  const recurringRuleExcludedDayOfWeek = (
    _get(
      values,
      FieldNames.recurringRuleExcludedDayOfWeek,
      [],
    ) as SelectOption[]
  ).map(({ value }) => _toNumber(value) as number);
  const intervalValue = _get(values, FieldNames.recurringRuleInterval);
  const repeatMonthlyUnit = _get(
    values,
    `${FieldNames.repeatMonthlyType}.value`,
  );
  const repeatYearlyUnit = _get(values, `${FieldNames.repeatYearlyType}.value`);
  const endTimeType = _get(values, `${FieldNames.endRepeatType}.value`);
  const endTime = _get(values, FieldNames.endRepeatDate);
  const endsAfterOccurrences = _get(
    values,
    FieldNames.endRepeatAfterOccurrences,
  );

  const rrules: RecurringRuleSchemaRuleInputObject[] = [];
  let metadata: RecurringRuleSchemaMetadataInputObject = {};
  const interval = intervalValue ? Number(intervalValue) : 1;
  if (ruleType === RecurringRuleRuleTypeEnum.Daily) {
    rrules.push({
      ruleType,
      interval,
    });
  }
  if (ruleType === RecurringRuleRuleTypeEnum.Weekly) {
    const dayOfWeek = _get(
      values,
      FieldNames.recurringRuleDayOfWeek,
    ) as Option[];
    const day =
      ruleType === RecurringRuleRuleTypeEnum.Weekly && Array.isArray(dayOfWeek)
        ? dayOfWeek.map(({ value }) => Number(value))
        : undefined;
    if (day) {
      const validations = { day };
      rrules.push({ ruleType, interval, validations });
    }
  }
  if (
    ruleType === RecurringRuleRuleTypeEnum.Monthly &&
    repeatMonthlyUnit === RecurringRuleSchemaOptionEnum.Each
  ) {
    metadata = {
      schemaOption: RecurringRuleSchemaOptionEnum.Each,
    };
    const dayOfMonth = _get(
      values,
      FieldNames.repeatMonthlyEachDayOfMonth,
    ) as Option[];
    if (dayOfMonth.length) {
      const validations = {
        dayOfMonth: dayOfMonth.map(({ value }) => Number(value)),
      };
      rrules.push({ ruleType, interval, validations });
    }
  }
  if (
    ruleType === RecurringRuleRuleTypeEnum.Monthly &&
    repeatMonthlyUnit === RecurringRuleSchemaOptionEnum.OnThe
  ) {
    metadata = {
      schemaOption: RecurringRuleSchemaOptionEnum.OnThe,
    };
    const repeatMonthlyRepeatOnOptions = _get(
      values,
      FieldNames.repeatMonthlyRepeatOnOptions,
      [],
    ) as Option[];
    if (repeatMonthlyRepeatOnOptions.length) {
      repeatMonthlyRepeatOnOptions.forEach((option) => {
        const optionVal1 = _get(
          option,
          `${FieldNames.repeatMonthlyRepeatOnOption1}.value`,
        );
        const optionVal2 = _get(
          option,
          `${FieldNames.repeatMonthlyRepeatOnOption2}.value`,
        );
        if (optionVal1 !== undefined && optionVal2 !== undefined) {
          const validations =
            optionVal2 === -1
              ? { dayOfMonth: [optionVal1] }
              : { dayOfWeek: { [`${optionVal2}`]: [optionVal1] } };
          rrules.push({ ruleType, interval, validations });
        }
      });
    }
  }
  if (
    ruleType === RecurringRuleRuleTypeEnum.Yearly &&
    repeatYearlyUnit === RecurringRuleSchemaOptionEnum.Each
  ) {
    metadata = {
      schemaOption: RecurringRuleSchemaOptionEnum.Each,
    };
    const monthOfYearValue = _get(
      values,
      FieldNames.repeatYearlyEachMonthOfYear,
    );
    const dayOfMonthValue = _get(values, FieldNames.repeatYearlyEachDayOfMonth);
    const monthOfYear = Array.isArray(monthOfYearValue)
      ? monthOfYearValue.map(({ value }) => Number(value))
      : undefined;
    const dayOfMonth = Array.isArray(dayOfMonthValue)
      ? dayOfMonthValue.map(({ value }) => Number(value))
      : undefined;
    if (monthOfYear || dayOfMonth) {
      const validations = { monthOfYear, dayOfMonth };
      rrules.push({ ruleType, interval, validations });
    }
  }
  if (
    ruleType === RecurringRuleRuleTypeEnum.Yearly &&
    repeatYearlyUnit === RecurringRuleSchemaOptionEnum.OnThe
  ) {
    metadata = {
      schemaOption: RecurringRuleSchemaOptionEnum.OnThe,
    };
    const monthOfYearValue = _get(
      values,
      FieldNames.repeatYearlyRepeatOnMonthOfYear,
    );
    const dayOfMonthValue = _get(
      values,
      FieldNames.repeatYearlyRepeatOnOptions,
      [],
    ) as Option[];
    const monthOfYear = Array.isArray(monthOfYearValue)
      ? monthOfYearValue.map(({ value }) => Number(value))
      : undefined;
    if (dayOfMonthValue.length) {
      dayOfMonthValue.forEach((option) => {
        const optionVal1 = _get(
          option,
          `${FieldNames.repeatYearlyRepeatOnOption1}.value`,
        );
        const optionVal2 = _get(
          option,
          `${FieldNames.repeatYearlyRepeatOnOption2}.value`,
        );
        if (optionVal1 !== undefined && optionVal2 !== undefined) {
          const validations =
            optionVal2 === -1
              ? { monthOfYear, dayOfMonth: [optionVal1] }
              : { monthOfYear, dayOfWeek: { [`${optionVal2}`]: [optionVal1] } };
          rrules.push({ ruleType, interval, validations });
        }
      });
    }
  }

  const schema: RecurringRuleSchemaInputObject = {
    rrules,
    metadata: Object.keys(metadata).length ? metadata : undefined,
  };
  if (enabled && (endTime || deadlineAt || rrules.length)) {
    return {
      schema,
      countdownType: recurringRuleCountdownType,
      excludedWdays: recurringRuleExcludedDayOfWeek,
      startTime: deadlineAt,
      endTime:
        endRepeatEnabled && endTimeType === EndRepeatUnit.date
          ? endTime
          : undefined,
      endsAfterOccurrences:
        endRepeatEnabled && endTimeType === EndRepeatUnit.completions
          ? Number(endsAfterOccurrences)
          : undefined,
    };
  }
  return undefined;
};

export function getPreparedTaskDataFromValues(
  values: Values,
  prevData: Task | undefined,
): TaskBulkCreateSameInputObject | TaskUpdateInputObject {
  const isEdit = !!prevData?.id;
  const getApproverId = () =>
    _get(values, `${FieldNames.approver}.value`) as string;
  const name = _get(values, FieldNames.taskName) as string;
  const description = _get(values, FieldNames.taskDescription) as string;
  const priority = _get(
    values,
    `${FieldNames.priority}.value`,
  ) as TaskPriorityEnum;
  const siteId = _get(values, `${FieldNames.site}.value`) as string;
  const templateId = _get(values, `${FieldNames.template}.value`) as string;
  const templateVersionId = _get(
    values,
    `${FieldNames.templateVersion}.value`,
  ) as string;

  const assignations = _get(values, FieldNames.assignations, []) as Value[];
  const assignationsValue = isEdit
    ? undefined
    : assignations.map((assignation) => ({
        siteAreaId: _get(assignation, [FieldNames.siteArea, 'value']),
        assetId: _get(assignation, [FieldNames.asset, 'value']),
      }));
  const assetId = isEdit
    ? _get(assignations, [0, FieldNames.asset, 'value'], null)
    : undefined;
  const siteAreaId = isEdit
    ? _get(assignations, [0, FieldNames.siteArea, 'value'], null)
    : undefined;

  const taskData = {
    name,
    description,
    priority,
    siteId,
    assignations: assignationsValue,
    approverId: getApproverId(),
    assetId,
    siteAreaId,
    templateId,
    templateVersionId,
  };

  if (prevData?.id) {
    return taskData;
  }

  return {
    // Step 1
    ...taskData,
    // Step 2
    deadlineAt: _get(values, FieldNames.deadlineAt),
    ...getEarlyCompletionFields(values),
    recurringRule: getRecurringRule(values) as RecurringRuleInputObject,
  };
}

export function getPreparedTaskScheduleDataFromValues(
  values: Values,
): TaskBulkSchedulingInputObject {
  return {
    deadlineAt: _get(values, FieldNames.deadlineAt),
    ...getEarlyCompletionFields(values),
    recurringRule: getRecurringRule(values) as RecurringRuleInputObject,
  };
}
