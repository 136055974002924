import React from 'react';
import _get from 'lodash/get';
import _find from 'lodash/find';
import {
  components as DefaultSelectComponents,
  SingleValueProps,
} from 'react-select';
import { Asset } from 'graphql-common';
import { SelectOption } from '@lib/components/Select/types';
import AssetChip from '@lib/components/AssetChip/AssetChip';
import { Option } from '@lib/interfaces/form';
import styles from '../Select.module.scss';

function AssetSingleValue({
  children,
  ...restProps
}: SingleValueProps): JSX.Element {
  const { options } = restProps;
  const isDisabled = _get(restProps, 'selectProps.isDisabled');
  let option = _get(restProps, 'selectProps.value') as Option;
  if (!option?.value || !option?.label) {
    const value = _get(restProps, 'selectProps.value.value', '');
    const selectedOption = _find(
      options,
      ({ value: optionValue }) => value === optionValue,
    ) as SelectOption;
    if (selectedOption) option = selectedOption;
  }
  const data = _get(option, 'data') as Asset;
  return (
    <DefaultSelectComponents.SingleValue
      {...restProps}
      className={styles.singleValue}
    >
      <div className={styles.singleValueBox}>
        <AssetChip asset={data} disabled={isDisabled} />
      </div>
    </DefaultSelectComponents.SingleValue>
  );
}

export default AssetSingleValue;
