import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import _get from 'lodash/get';
import {
  FormTemplate,
  FormTemplateQuestion,
  FormTemplateQuestionFieldsKindEnum,
  FormTemplateVersion,
  Task,
  TaskStatusEnum,
} from 'graphql-common';
import { ApolloError } from '@apollo/client/errors';
import PageBody from '@lib/layouts/DashboardLayout/PageBody/PageBody';
import DashboardContentMaxWidth from '@lib/layouts/DashboardLayout/DashboardContentMaxWidth/DashboardContentMaxWidth';
import WhiteBox from '@lib/components/WhiteBox/WhiteBox';
import Typography from '@lib/components/Typography/Typography';
import Button, {
  ButtonSizes,
  ButtonTypes,
} from '@lib/components/Button/Button';
import Chip, { ChipSizes, ChipTypes } from '@lib/components/Chip/Chip';
import CircleLoader from '@lib/components/CircleLoader/CircleLoader';
import QuestionFieldsPreview from 'components/FormTemplateBuilder/QuestionFieldsPreview/QuestionFieldsPreview';
import FormTemplateBuilderQuestionView from 'components/FormTemplateBuilder/FormTemplateBuilderQuestionView/FormTemplateBuilderQuestionView';
import TaskMenuBar from 'components/TaskMenuBar/TaskMenuBar';
import Tooltip from '@lib/components/Tooltip/Tooltip';
import ErrorPage from '@lib/components/ErrorPage/ErrorPage';
import getStatusFromError from '@lib/utils/getStatusFromError';
import FormTemplateFieldNames from '@lib/enums/fieldNames/formTemplateFieldNames';
import QuestionTypeChip from 'components/FormTemplateBuilder/QuestionTypeChip/QuestionTypeChip';
import entityViewStyles from '@lib/assets/styles/EntityView.module.scss';
import VersionIcon from '@lib/assets/icons/version_icon.svg?react';
import DashboardTitleHeader from '@lib/layouts/DashboardLayout/DashboardTitleHeader/DashboardTitleHeader';
import TextEditor from '@lib/components/TextEditor/TextEditor';
import styles from './TaskFormView.module.scss';

interface Props {
  idParam: string;
  onOpenEditTaskDetails: () => void;
  taskData: Task;
  taskFormTemplate: FormTemplate;
  taskFormTemplateGraphQLErrors?: ApolloError;
  taskFormTemplateLoading: boolean;
  taskFormTemplateVersion: FormTemplateVersion;
}

type ActionsProps = Pick<Props, 'taskData' | 'onOpenEditTaskDetails'> & {
  canUpdateFormTemplate: boolean;
};

function TaskFormViewActions(props: ActionsProps) {
  const { canUpdateFormTemplate, onOpenEditTaskDetails, taskData } = props;
  const { t } = useTranslation();
  if (
    taskData.status === TaskStatusEnum.Archived ||
    taskData.status === TaskStatusEnum.Completed
  ) {
    return null;
  }
  return canUpdateFormTemplate ? (
    <Button
      buttonText={t('edit-form')}
      buttonSize={ButtonSizes.large}
      buttonType={ButtonTypes.primaryOutlined}
      leftIcon="edit"
      onClick={onOpenEditTaskDetails}
    />
  ) : (
    <Tooltip body={t('tooltip-disable-form-edit')} maxWidth={190}>
      <Button
        buttonText={t('edit-form')}
        buttonSize={ButtonSizes.large}
        buttonType={ButtonTypes.primaryOutlined}
        leftIcon="lock"
        disabled
      />
    </Tooltip>
  );
}

export default function TaskFormView(props: Props) {
  const { t } = useTranslation();
  const {
    idParam,
    onOpenEditTaskDetails,
    taskData,
    taskFormTemplate,
    taskFormTemplateGraphQLErrors,
    taskFormTemplateLoading,
    taskFormTemplateVersion,
  } = props;

  const name = taskFormTemplate?.name;
  const description = taskFormTemplate?.description;
  const categoryName = taskFormTemplate?.category?.name;
  const categoryColor = taskFormTemplate?.category?.color as ChipTypes;
  const canUpdateFormTemplate = taskData?.formTemplate?.canUpdate?.value;
  const formTemplateVersion = taskFormTemplateVersion?.version;
  const questions = (taskFormTemplateVersion?.questions ||
    []) as FormTemplateQuestion[];

  if (taskFormTemplateGraphQLErrors) {
    return (
      <ErrorPage
        status={getStatusFromError(taskFormTemplateGraphQLErrors)}
        description={taskFormTemplateGraphQLErrors?.message}
      />
    );
  }

  return (
    <PageBody withoutHeader>
      <TaskMenuBar id={idParam} />
      <DashboardContentMaxWidth>
        {taskFormTemplateLoading ? (
          <CircleLoader />
        ) : (
          <div>
            <WhiteBox className={styles.mainBlock}>
              <DashboardTitleHeader
                title={name}
                actions={
                  <TaskFormViewActions
                    canUpdateFormTemplate={canUpdateFormTemplate}
                    onOpenEditTaskDetails={onOpenEditTaskDetails}
                    taskData={taskData}
                  />
                }
                withBottomSpace={false}
              />
              <div>
                <Chip
                  type={categoryColor}
                  label={categoryName}
                  leftIcon="circle"
                />
              </div>
              {description && (
                <div>
                  <TextEditor
                    readOnly
                    value={description}
                    className={styles.description}
                  />
                </div>
              )}
              <hr className={entityViewStyles.hrLine} />
              <div
                className={classNames(
                  entityViewStyles.infoItem,
                  entityViewStyles.infoItemCentered,
                )}
              >
                <p className={entityViewStyles.infoItemLabel}>
                  <VersionIcon />
                  <Typography variant="caption">{t('version')}</Typography>
                </p>
                <div className={entityViewStyles.infoItemContentBox}>
                  <Chip
                    label={
                      formTemplateVersion ? `v${formTemplateVersion}` : '-'
                    }
                    type={ChipTypes.chipGray}
                    size={ChipSizes.large}
                  />
                </div>
              </div>
            </WhiteBox>
            {questions?.map((question) => {
              const kind = _get(question, [
                FormTemplateFieldNames.Fields,
                0,
                FormTemplateFieldNames.Kind,
              ]);
              return (
                <WhiteBox
                  className={styles.questionBlock}
                  key={question.position}
                >
                  <QuestionTypeChip
                    kind={kind}
                    className={
                      kind ===
                      FormTemplateQuestionFieldsKindEnum.ImportantNotice
                        ? styles.questionTypeChip
                        : undefined
                    }
                  />
                  <FormTemplateBuilderQuestionView value={question}>
                    <QuestionFieldsPreview
                      fields={question.fields}
                      value={question}
                    />
                  </FormTemplateBuilderQuestionView>
                </WhiteBox>
              );
            })}
          </div>
        )}
      </DashboardContentMaxWidth>
    </PageBody>
  );
}
