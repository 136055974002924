import { Access, ComponentEnum, LevelEnum } from 'graphql-common';
import {
  PATH_ADD,
  PATH_ASSETS,
  PATH_CATEGORIES,
  PATH_COUNTERS,
  PATH_DASHBOARD,
  PATH_EDIT,
  PATH_FORM_TEMPLATES,
  PATH_FORM_TEMPLATES_ACTIVE,
  PATH_FORM_TEMPLATES_ARCHIVED,
  PATH_INTERVENTIONS,
  PATH_PERFORM_TASKS,
  PATH_SITES,
  PATH_TASKS,
  PATH_TEAMS,
  PATH_USERS,
} from '@lib/enums/urls';

function matchPath(pathTemplate: string, actualPath: string): boolean {
  const normalizedPathname = actualPath.startsWith('/')
    ? actualPath.slice(1)
    : actualPath;
  const regex = new RegExp(`^${pathTemplate.replace(/:[^/]+/g, '[^/]+')}$`);
  return (
    normalizedPathname.startsWith(pathTemplate) ||
    regex.test(normalizedPathname)
  );
}

const ACCESSES_MAP = [
  {
    path: `${PATH_INTERVENTIONS}/${PATH_CATEGORIES}`,
    component: ComponentEnum.InterventionCategoriesManagement,
  },
  {
    path: PATH_INTERVENTIONS,
    component: ComponentEnum.InterventionsManagement,
  },
  {
    path: `${PATH_TASKS}/:ID/${PATH_INTERVENTIONS}`,
    component: ComponentEnum.InterventionsManagement,
  },
  {
    path: `${PATH_ASSETS}/:ID/${PATH_INTERVENTIONS}`,
    component: ComponentEnum.InterventionsManagement,
  },
  {
    path: PATH_PERFORM_TASKS,
    component: ComponentEnum.TasksPerforming,
  },
  {
    path: PATH_TASKS,
    component: ComponentEnum.TasksManagement,
  },
  {
    path: `${PATH_FORM_TEMPLATES}/${PATH_CATEGORIES}`,
    component: ComponentEnum.FormCategoriesManagement,
  },
  {
    path: PATH_FORM_TEMPLATES,
    component: ComponentEnum.TasksManagement,
  },
  {
    path: PATH_FORM_TEMPLATES_ACTIVE,
    component: ComponentEnum.TasksManagement,
  },
  {
    path: PATH_FORM_TEMPLATES_ARCHIVED,
    component: ComponentEnum.TasksManagement,
  },
  {
    path: PATH_SITES,
    component: ComponentEnum.SitesManagement,
  },
  {
    path: PATH_USERS,
    component: ComponentEnum.UsersManagement,
  },
  {
    path: PATH_TEAMS,
    component: ComponentEnum.UsersManagement,
  },
  {
    path: `${PATH_ASSETS}/${PATH_CATEGORIES}`,
    component: ComponentEnum.AssetCategoriesManagement,
  },
  {
    path: `${PATH_ASSETS}/${PATH_COUNTERS}`,
    component: ComponentEnum.AssetsCounterReading,
  },
  {
    path: PATH_ASSETS,
    component: ComponentEnum.AssetsManagement,
  },
  {
    path: PATH_DASHBOARD,
    component: ComponentEnum.TasksManagement,
  },
  {
    path: PATH_FORM_TEMPLATES,
    component: ComponentEnum.TasksManagement,
  },
];

export function checkUserAccessForPage(
  accesses: Access[] | undefined,
  pathname: string,
) {
  if (!pathname || !accesses?.length) return undefined;

  const matchedRoute = ACCESSES_MAP.find(({ path }) =>
    matchPath(path, pathname),
  );

  if (!matchedRoute) return undefined;

  const { component } = matchedRoute;

  const access = accesses.find(
    ({ component: userComponent }) => userComponent === component,
  );

  if (!access) return undefined;

  const { level } = access;

  // Check for PATH_ADD or PATH_EDIT in the pathname
  const isEditingOrAdding = [PATH_ADD, PATH_EDIT].some((subPath) =>
    pathname.includes(`/${subPath}`),
  );

  if (isEditingOrAdding && level === LevelEnum.Read) {
    return LevelEnum.LimitedRead;
  }

  return level || LevelEnum.LimitedRead;
}

export function checkUserAccessByComponent(
  accesses: Access[] | undefined,
  findBy: ComponentEnum,
) {
  if (!findBy || !accesses?.length) return undefined;
  const access = accesses.find(({ component }) => component === findBy);
  if (access?.level) return access.level;
  return LevelEnum.LimitedRead;
}

export function checkUserAccessItemIdsByComponent(
  accesses: Access[] | undefined,
  findBy: ComponentEnum,
) {
  if (!findBy || !accesses?.length) return undefined;
  const access = accesses.find(({ component }) => component === findBy);
  return access?.itemIds;
}
