import React from 'react';
import { useTranslation } from 'react-i18next';
import _get from 'lodash/get';
import { useWatch } from 'react-hook-form';
import Button, { ButtonTypes } from '@lib/components/Button/Button';
import ActionsRow from '@lib/components/ActionsRow/ActionsRow';
import { materialSymbolsOutlined } from '@lib/enums/common';
import { FieldNames, FormType } from 'components/GetQrCodeForm/types';

interface Props {
  loading: boolean;
  onCancel: () => void;
}

function GetQrCodeFormActions(props: Props) {
  const { loading, onCancel } = props;
  const watch = useWatch();
  const formType = _get(watch, [FieldNames.formType, 'value']);
  const { t } = useTranslation();
  return (
    <ActionsRow
      actions={[
        <Button
          key="cancel"
          buttonType={ButtonTypes.secondaryOutlined}
          buttonText={t('cancel')}
          leftIcon="close"
          onClick={onCancel}
          fullWidth
          disabled={loading}
        />,
        <Button
          key="submit"
          type="submit"
          buttonType={ButtonTypes.primaryFilled}
          buttonText={
            formType === FormType.download ? t('download') : t('send-via-email')
          }
          leftIcon={formType === FormType.download ? 'download' : 'send'}
          leftIconClassName={materialSymbolsOutlined}
          fullWidth
          isLoading={loading}
        />,
      ]}
    />
  );
}

export default GetQrCodeFormActions;
