import _get from 'lodash/get';
import { Values } from '@lib/interfaces/form';
import AssetFieldNames from '@lib/enums/fieldNames/assetCategoryFieldNames';

export default function getPreparedAssetCategoryDataFromValues(values: Values) {
  return {
    name: _get(values, AssetFieldNames.Name, '') as string,
    color: _get(values, `${AssetFieldNames.Color}.value`, '') as string,
    prefix: _get(values, AssetFieldNames.Prefix, '') as string,
  };
}
