import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '@lib/components/Modal/Modal';
import Form from '@lib/components/ReactHookForm/FormContainer';
import getScheduleFields from 'routes/Task/utils/useFields/getScheduleFields';
import { Values } from '@lib/interfaces/form';
import { FieldNames } from 'routes/Task/enums';
import ScheduleTaskActions from 'components/ScheduleTask/ScheduleTaskActions';
import { ApolloError } from '@apollo/client/errors';
import { Task } from 'graphql-common';
import getTaskScheduleValuesFromQueryData from 'components/Task/utils/getTaskScheduleValuesFromQueryData';
import {
  recurringRuleDayOfMonthOptionDayOfWeekOptions,
  recurringRuleDayOfMonthOptions,
} from 'routes/Task/utils/useFields/options';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (v: Values) => void;
  taskScheduleData?: Task;
  taskBulkSchedulingError?: ApolloError;
  taskBulkSchedulingLoading?: boolean;
  taskScheduleLoading?: boolean;
}

const emptyDefaultValues = {
  [FieldNames.deadlineAt]: '',
  [FieldNames.endRepeatDate]: '',
};

export default function ScheduleTask(props: Props) {
  const {
    isOpen,
    onSubmit,
    taskScheduleData,
    taskScheduleLoading,
    onClose,
    taskBulkSchedulingError,
    taskBulkSchedulingLoading,
  } = props;
  const { t } = useTranslation();
  const fieldsArray = getScheduleFields({ t });

  const defaultValues = taskScheduleData
    ? getTaskScheduleValuesFromQueryData(taskScheduleData)
    : emptyDefaultValues;

  return (
    <Modal isOpen={isOpen} title={t('schedule-task')} onClose={onClose}>
      <Form
        actionsComponent={ScheduleTaskActions}
        actionsComponentProps={{
          onCancel: onClose,
          loading: taskScheduleLoading || taskBulkSchedulingLoading,
        }}
        formId="schedule-task"
        fields={fieldsArray}
        onSubmit={onSubmit}
        defaultValues={defaultValues}
        loading={taskScheduleLoading || taskBulkSchedulingLoading}
        graphQLErrors={taskBulkSchedulingError?.graphQLErrors}
        graphQLErrorsReplacePathMap={{
          'recurringRule.startTime': 'startTime',
          'recurringRule.endTime': FieldNames.endRepeatDate,
          'recurringRule.schema.rrules': FieldNames.recurringRuleDayOfWeek,
        }}
        sourceAppendValues={{
          [FieldNames.repeatMonthlyRepeatOnOptions]: {
            [FieldNames.repeatMonthlyRepeatOnOption1]:
              recurringRuleDayOfMonthOptions[0],
            [FieldNames.repeatMonthlyRepeatOnOption2]:
              recurringRuleDayOfMonthOptionDayOfWeekOptions[0],
          },
          [FieldNames.repeatYearlyRepeatOnOptions]: {
            [FieldNames.repeatYearlyRepeatOnOption1]:
              recurringRuleDayOfMonthOptions[0],
            [FieldNames.repeatYearlyRepeatOnOption2]:
              recurringRuleDayOfMonthOptionDayOfWeekOptions[0],
          },
        }}
      />
    </Modal>
  );
}
