import React from 'react';
import classNames from 'classnames';
import Typography from '@lib/components/Typography/Typography';
import ModalIcon from '@lib/components/Modal/ModalIcon';
import { IconName } from '@lib/components/Modal/enums';
import styles from './EmptyState.module.scss';

interface Props {
  actions?: React.ReactNode[];
  icon?: IconName;
  isFullWidthActions?: boolean;
  outlined?: boolean;
  rootClassName?: string;
  text: React.ReactNode;
  title: React.ReactNode;
}

function EmptyState(props: Props) {
  const {
    title,
    text,
    icon,
    isFullWidthActions = false,
    actions,
    rootClassName,
    outlined = true,
  } = props;
  return (
    <div
      className={classNames(
        styles.root,
        { [styles.rootOutlined]: outlined },
        rootClassName,
      )}
    >
      {icon !== undefined && (
        <div>
          <ModalIcon icon={icon} rootClassName={styles.icon} />
        </div>
      )}
      <div>
        <Typography variant="h3">{title}</Typography>
        <Typography variant="body" className={styles.text}>
          {text}
        </Typography>
      </div>
      {actions && actions.length > 0 && (
        <div
          className={classNames(styles.actions, {
            [styles.actionsColumn]: isFullWidthActions,
          })}
        >
          {actions.map((action) => action)}
        </div>
      )}
    </div>
  );
}

export default EmptyState;
