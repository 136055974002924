import React from 'react';
import {
  components as DefaultSelectComponents,
  ValueContainerProps,
} from 'react-select';
import { useTranslation } from 'react-i18next';
import styles from '../Select.module.scss';

export default function MultiSelectValueContainer(
  props: ValueContainerProps,
): JSX.Element {
  const { children, ...valueContainerProps } = props;
  const { isMulti, selectProps } = valueContainerProps;
  const { menuIsOpen } = selectProps;
  const { t } = useTranslation();

  if (isMulti && Array.isArray(children) && children.length) {
    return (
      <DefaultSelectComponents.ValueContainer
        {...valueContainerProps}
        className={styles.valueContainer}
      >
        <span className={styles.multiValueWrap}>
          {!menuIsOpen && Array.isArray(children[0]) && children[0].length && (
            <>
              {/* {children[0]} */}
              {t('selected')}
              {!!children[0].length && ` (${children[0].length})`}
            </>
          )}
          <span>{children[1]}</span>
        </span>
      </DefaultSelectComponents.ValueContainer>
    );
  }

  return (
    <DefaultSelectComponents.ValueContainer
      {...valueContainerProps}
      className={styles.valueContainer}
    >
      {children}
    </DefaultSelectComponents.ValueContainer>
  );
}
