import { useTranslation } from 'react-i18next';
import { useDocumentationBulkDestroyMutation } from 'graphql-common';
import { showGraphQLErrors } from '@lib/utils/graphQLErrors';

export default function useDocumentationMutations() {
  const { t } = useTranslation();

  const [
    documentationBulkDestroyMutation,
    documentationBulkDestroyMutationResult,
  ] = useDocumentationBulkDestroyMutation({
    onError: (error) => showGraphQLErrors(error, t),
  });

  return {
    documentationBulkDestroyMutation,
    documentationBulkDestroyMutationResult,
  };
}
