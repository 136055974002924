import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Asset,
  useAssetBulkDestroyMutation,
  useAssetBulkQrCodeCreateMutation,
  useUsersSearchListLazyQuery,
} from 'graphql-common';
import toast from '@lib/components/Toast/Toast';
import usePaginationParams from '@lib/hooks/usePaginationParams';
import onDestroyListItemCallBack from '@lib/utils/onDestroyListItemCallBack';
import useSearchParam from '@lib/hooks/useSearchParam';
import { Values } from '@lib/interfaces/form';
import { UseBulkSelectResult } from '@lib/hooks/useTableItemsBulkSelect';
import getAllTableItemsIds from '@lib/utils/getAllTableItemsIds';
import tableItemsBulkAction from '@lib/utils/tableItemsBulkAction';
import { getUsersLoadOptions as getUsersLoadOptionsFn } from 'utils/fetch/usersSearchList';
import useAssetsQueryHook from 'utils/fetch/useAssetsQueryHook';
import { APP_URLS } from 'constants/urls';
import useQrCodeActions from 'routes/Assets/Asset/utils/useQrCodeActions';
import { UseModalManagementResult } from './useModalManagement';

interface UseActionsProps {
  useBulkSelectResult: UseBulkSelectResult<Asset>;
  useModalManagementResult: UseModalManagementResult;
}

export default function useActions(props: UseActionsProps) {
  const { useBulkSelectResult, useModalManagementResult } = props;
  const { handleUnselectAllTableItems, handleSelectSingleTableItem } =
    useBulkSelectResult;
  const {
    closeAssetDeleteModal,
    closeGetQrCodeModal,
    openAssetDeleteModal,
    openGetQrCodeModal,
  } = useModalManagementResult;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { search, pathname } = useLocation();
  const [searchQuery] = useSearchParam();
  const [params] = usePaginationParams();

  // Assets
  const {
    fetchIds: fetchAssetIds,
    refetch: refetchAssets,
    collection,
    error: assetsError,
    firstLoading: assetsFirstLoading,
    loading: assetsLoading,
    totalPages,
    totalCount,
  } = useAssetsQueryHook({
    paginationParams: params,
    orderingParams: params,
    searchQuery,
  });

  const onGetAllAssetIds = () =>
    getAllTableItemsIds<Asset>({
      fetchIds: fetchAssetIds,
      useBulkSelectResult,
    });

  // Users
  const [fetchUsersSearchListLazyQuery] = useUsersSearchListLazyQuery({
    fetchPolicy: 'cache-and-network',
  });

  const getUsersLoadOptions = () =>
    getUsersLoadOptionsFn(fetchUsersSearchListLazyQuery);

  const [destroyMutation] = useAssetBulkDestroyMutation({
    onCompleted: (_response, clientOptions) => {
      closeAssetDeleteModal();
      handleUnselectAllTableItems();
      onDestroyListItemCallBack({
        collection,
        collectionForDestroy:
          clientOptions?.variables?.input?.params?.ids || [],
        navigate,
        pathname,
        refetch: refetchAssets,
        search,
        totalPages,
      });
    },
    onError: (error) => {
      toast({ content: error.message || 'Oops! Something went wrong' });
    },
  });

  const onDestroyHandler = (ids: string[]) => {
    if (ids.length) {
      destroyMutation({
        variables: {
          input: {
            ids,
          },
        },
      });
    }
  };

  const onDelete = (item?: Asset) => {
    if (item?.id) {
      handleSelectSingleTableItem(item);
      openAssetDeleteModal();
    }
  };

  const onBulkDelete = () => {
    openAssetDeleteModal();
  };

  const onConfirmedDelete = () => {
    closeAssetDeleteModal();
    toast({
      content: t('destroy-team-member-success'),
      closeCallback: () => {
        tableItemsBulkAction<Asset>({
          action: onDestroyHandler,
          useBulkSelectResult,
          onGetAllIds: onGetAllAssetIds,
        });
      },
    });
  };

  // Get QR codes
  const [qrCodeCreateMutation, qrCodeCreateMutationResult] =
    useAssetBulkQrCodeCreateMutation();
  const { onQrCodeCreateAction } = useQrCodeActions({
    onCompleted: () => {
      closeGetQrCodeModal();
      handleUnselectAllTableItems();
    },
    qrCodeCreateMutation,
  });

  const onQrCodeCreate = (values: Values) => {
    const action = (ids: string[]) => onQrCodeCreateAction(values, ids);
    tableItemsBulkAction<Asset>({
      action,
      useBulkSelectResult,
      onGetAllIds: onGetAllAssetIds,
    });
  };

  const onAdd = () => {
    navigate(APP_URLS.dashboard.assets.add.path);
  };

  const onEditHandler = (item?: Asset) => {
    if (item?.id) {
      navigate(
        APP_URLS.dashboard.assets.edit.getDynamicPath({
          pathParts: {
            id: `${item.id}`,
          },
        }),
      );
    }
  };

  const onViewHandler = (item?: Asset) => {
    if (item?.id) {
      navigate(
        APP_URLS.dashboard.assets.view.getDynamicPath({
          pathParts: {
            id: `${item.id}`,
          },
        }),
      );
    }
  };

  const getQrCode = (item?: Asset) => {
    if (item?.id) {
      handleSelectSingleTableItem(item);
      openGetQrCodeModal();
    }
  };

  const getQrCodesForSelected = () => {
    openGetQrCodeModal();
  };

  return {
    assetsError,
    assetsFirstLoading,
    assetsLoading,
    collection,
    getQrCode,
    getQrCodesForSelected,
    onAdd,
    onBulkDelete,
    onConfirmedDelete,
    onDelete,
    onEditHandler,
    onQrCodeCreate,
    onViewHandler,
    totalCount,
    getUsersLoadOptions,
    qrCodeCreateMutationResult,
  };
}
