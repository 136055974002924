import React from 'react';
import { useTranslation } from 'react-i18next';
import { StringParam, useQueryParams } from 'use-query-params';
import { ApolloError } from '@apollo/client/errors';
import { Task } from 'graphql-common';
import { FieldItem, Values } from '@lib/interfaces/form';
import PageBody from '@lib/layouts/DashboardLayout/PageBody/PageBody';
import Form from '@lib/components/ReactHookForm/FormContainer';
import { pageStepsId } from '@lib/enums/selectors';
import getPrefilledQuestions from 'components/FormTemplateBuilder/utils/getPrefilledQuestions';
import DashboardContentMaxWidth from '@lib/layouts/DashboardLayout/DashboardContentMaxWidth/DashboardContentMaxWidth';
import CircleLoader from '@lib/components/CircleLoader/CircleLoader';
import ErrorPage from '@lib/components/ErrorPage/ErrorPage';
import getStatusFromError from '@lib/utils/getStatusFromError';
import removeIdFields from '@lib/utils/removeIdsFromObject';
import WhiteBox from '@lib/components/WhiteBox/WhiteBox';
import { FormRefType } from '@lib/components/ReactHookForm/types';
import getFullTaskFormValuesFromQueryData from 'components/Task/utils/getFullTaskFormValuesFromQueryData';
import { FieldNames } from 'routes/Task/enums';
import TaskAddFormActions from './TaskAddFormActions';

const getEmptyDefaultValues = (values?: Values): Values => ({
  // step 1
  taskName: '',
  taskDescription: '',
  priority: undefined,
  site: undefined,
  template: undefined,
  templateVersion: undefined,
  assignations: [
    {
      siteArea: undefined,
      asset: undefined,
    },
  ],
  approver: undefined,
  performers: undefined,
  // step 2
  [FieldNames.deadlineAt]: '',
  [FieldNames.earlyCompletionType]: undefined,
  [FieldNames.earlyCompletionEnabled]: false,
  [FieldNames.earlyCompletionAmount]: 1,
  [FieldNames.endRepeatEnabled]: false,
  [FieldNames.repeatMonthlyRepeatOnOptions]: [],
  [FieldNames.repeatYearlyRepeatOnOptions]: [],
  // Custom
  ...values,
});

interface Props {
  createMutationError?: ApolloError;
  createTaskLoading?: boolean;
  duplicatedFormTemplateDataFromTask?: Values;
  formFields: FieldItem[];
  formRef: FormRefType;
  fullTaskData?: Task;
  fullTaskError?: ApolloError;
  fullTaskLoading?: boolean;
  isDuplicate?: boolean;
  onSubmit: (values: Values) => void;
}

function TaskAdd(props: Props) {
  const {
    createMutationError,
    createTaskLoading,
    duplicatedFormTemplateDataFromTask = {},
    formFields,
    formRef,
    fullTaskData,
    fullTaskError,
    fullTaskLoading,
    isDuplicate,
    onSubmit,
  } = props;
  const { t } = useTranslation();
  const [paramsForDuplicate] = useQueryParams({
    formTemplateId: StringParam,
    formTemplateName: StringParam,
    formTemplateVersionId: StringParam,
    formTemplateVersionName: StringParam,
  });
  const steps = [{ title: t('task-step-1') }, { title: t('task-step-3') }];

  const prefilledQuestions = getPrefilledQuestions({ t });

  const defaultValues =
    isDuplicate && fullTaskData
      ? getFullTaskFormValuesFromQueryData(fullTaskData)
      : getEmptyDefaultValues({
          ...removeIdFields(duplicatedFormTemplateDataFromTask, [
            'attachmentsAttached',
          ]),
          [FieldNames.taskName]: duplicatedFormTemplateDataFromTask.name,
        });

  if (
    paramsForDuplicate?.formTemplateId &&
    paramsForDuplicate?.formTemplateName &&
    paramsForDuplicate?.formTemplateVersionId &&
    paramsForDuplicate?.formTemplateVersionName
  ) {
    defaultValues[FieldNames.template] = {
      value: paramsForDuplicate.formTemplateId,
      label: paramsForDuplicate.formTemplateName,
    };
    defaultValues[FieldNames.templateVersion] = {
      value: paramsForDuplicate.formTemplateVersionId,
      label: paramsForDuplicate.formTemplateVersionName,
    };
  }

  if (fullTaskError) {
    return (
      <ErrorPage
        status={getStatusFromError(fullTaskError)}
        description={fullTaskError?.message}
      />
    );
  }

  return (
    <>
      <div id={pageStepsId} />
      <DashboardContentMaxWidth>
        <PageBody withSteps>
          {fullTaskLoading ? (
            <CircleLoader />
          ) : (
            <WhiteBox>
              <Form
                actionsComponent={TaskAddFormActions}
                defaultValues={defaultValues}
                fields={formFields}
                formId="task-add"
                formRef={formRef}
                graphQLErrors={createMutationError?.graphQLErrors}
                graphQLErrorsReplacePathMap={{
                  name: FieldNames.taskName,
                  description: FieldNames.taskDescription,
                  'formTemplate.name': 'name',
                  'formTemplate.description': 'description',
                  'formTemplate.questions': 'questions',
                  'recurringRule.startTime': FieldNames.deadlineAt,
                  'recurringRule.endTime': FieldNames.endRepeatDate,
                }}
                loading={createTaskLoading}
                onSubmit={onSubmit}
                steps={steps}
                stepsCount={steps.length}
                sourceFields={{
                  questions: prefilledQuestions,
                }}
                sourceAppendValues={{
                  [FieldNames.repeatMonthlyRepeatOnOptions]: {
                    [FieldNames.repeatMonthlyRepeatOnOption1]: {
                      label: t('order-option-first'),
                      value: 1,
                    },
                    [FieldNames.repeatMonthlyRepeatOnOption2]: {
                      label: t('day-monday'),
                      value: 1,
                    },
                  },
                  [FieldNames.repeatYearlyRepeatOnOptions]: {
                    [FieldNames.repeatYearlyRepeatOnOption1]: {
                      label: t('order-option-first'),
                      value: 1,
                    },
                    [FieldNames.repeatYearlyRepeatOnOption2]: {
                      label: t('day-monday'),
                      value: 1,
                    },
                  },
                }}
              />
            </WhiteBox>
          )}
        </PageBody>
      </DashboardContentMaxWidth>
    </>
  );
}

export default TaskAdd;
