import usePrevious from '@lib/hooks/usePrevious';
import { useTranslation } from 'react-i18next';
import { UseBulkSelectResult } from '@lib/hooks/useTableItemsBulkSelect';
import { Task, TaskScopeNameEnum } from 'graphql-common';
import { UseModalManagementResult } from 'routes/Tasks/utils/useModalManagement';
import { UseActionsResult } from './useActions';
import getTableTabs from './getTableTabs';
import getTableColumns from './getTableColumns';
import getTableActionsMenu from './getTableActionsMenu';
import getTableActionsForSelected from './getTableActionsForSelected';

type Args = {
  isApprovals?: boolean;
  taskScope: TaskScopeNameEnum;
  useActionsResult: UseActionsResult;
  useBulkSelectResult: UseBulkSelectResult<Task>;
  useModalManagementResult: UseModalManagementResult;
  isViewOnly: boolean;
};

export default function useTableHelpers(args: Args) {
  const {
    isApprovals,
    taskScope,
    useActionsResult,
    useBulkSelectResult,
    useModalManagementResult,
    isViewOnly,
  } = args;
  const { isAllTableItemsSelected, selectedTableItems, unselectedTableItems } =
    useBulkSelectResult;
  const {
    onBulkDeadlineMissedReasonChangeHandler,
    onDeadlineMissedReasonChangeHandler,
    onDeleteHandler,
    onDuplicateHandler,
    onEditHandler,
    onEditScheduleHandler,
    onStatusChangeHandler,
    onStatusBulkChangeHandler,
    onTaskArchiveOpen,
    onViewHandler,
    taskBulkDeadlineMissedReasonUpdateLoading,
    taskCounterData,
    tasksLoading,
    totalTasksCount,
  } = useActionsResult;
  const { openArchiveModal, openScheduleModal } = useModalManagementResult;

  const { t } = useTranslation();

  const tableColumns = getTableColumns({
    isApprovals,
    onDeadlineMissedReasonChangeHandler: isViewOnly
      ? undefined
      : onDeadlineMissedReasonChangeHandler,
    onStatusChangeHandler: isViewOnly ? undefined : onStatusChangeHandler,
    t,
    taskBulkDeadlineMissedReasonUpdateLoading,
    taskScope,
  });

  const tableActionsMenu = getTableActionsMenu({
    onTaskArchiveOpen,
    onDuplicateHandler,
    onEditHandler,
    onEditScheduleHandler,
    onViewHandler,
    onDeleteHandler,
    t,
    taskScope,
    isViewOnly,
  });

  const tableActionsForSelected = isViewOnly
    ? []
    : getTableActionsForSelected({
        isApprovals,
        onBulkDeadlineMissedReasonChangeHandler,
        onStatusBulkChangeHandler,
        openArchiveModal,
        openScheduleModal,
        selectedTasksCount: isAllTableItemsSelected
          ? totalTasksCount - unselectedTableItems.length
          : selectedTableItems.length,
        t,
        taskScope,
      });

  const tableTabs = getTableTabs({ t, taskCounterData });

  const prevTableColumns = usePrevious(tableColumns);
  const prevTableActionsMenu = usePrevious(tableActionsMenu);
  const prevTableActionsForSelected = usePrevious(tableActionsForSelected);

  return {
    tableColumns: tasksLoading
      ? prevTableColumns || tableColumns
      : tableColumns,
    tableActionsMenu: tasksLoading
      ? prevTableActionsMenu || tableActionsMenu
      : tableActionsMenu,
    tableActionsForSelected: tasksLoading
      ? prevTableActionsForSelected || tableActionsForSelected
      : tableActionsForSelected,
    tableTabs,
  };
}
