import React from 'react';
import { useTranslation } from 'react-i18next';
import { Counter } from 'graphql-common';
import { GraphQLErrors } from '@apollo/client/errors';
import { FieldItem, Values } from '@lib/interfaces/form';
import Form, {
  ActionsProps,
} from '@lib/components/ReactHookForm/FormContainer';
import Modal from '@lib/components/Modal/Modal';
import AssetCounterIndexFieldNames from '@lib/enums/fieldNames/assetCounterIndexFieldNames';
import AssetCounterIndexModalFormActions from 'components/AssetCounterIndexModalForm/AssetCounterIndexModalFormActions';
import { FormRefType } from '@lib/components/ReactHookForm/types';
import getFormFields from './utils/getFormFields';

export interface FormProps {
  assetCounter: Counter;
  formRef: FormRefType;
  graphQLErrors?: GraphQLErrors;
  isOpen: boolean;
  loading: boolean;
  onCancel: () => void;
  onClose: () => void;
  onSubmit: (values: Values, isEditForm?: boolean) => void;
}

const defaultValues = {
  [AssetCounterIndexFieldNames.Index]: null,
  [AssetCounterIndexFieldNames.Action]: { value: 'index' },
};

export default function AssetCounterIndexModalForm(props: FormProps) {
  const {
    assetCounter,
    formRef,
    graphQLErrors,
    isOpen,
    loading,
    onCancel,
    onClose,
    onSubmit,
  } = props;
  const { t } = useTranslation();

  const fields: FieldItem[] = getFormFields({
    assetCounter,
    t,
  });

  const actionsComponent: (props: ActionsProps) => React.JSX.Element = (
    actionsComponentProps,
  ) => (
    <AssetCounterIndexModalFormActions
      {...actionsComponentProps}
      onCancel={onCancel}
    />
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t('add-index')}
      titleAlign="left"
    >
      <Form
        actions={actionsComponent}
        actionsComponentProps={{ onCancel }}
        defaultValues={defaultValues}
        fields={fields}
        formId="counter-index"
        formRef={formRef}
        graphQLErrors={graphQLErrors}
        loading={loading}
        onSubmit={onSubmit}
        graphQLErrorsReplacePathMap={{
          counter: AssetCounterIndexFieldNames.Index,
        }}
      />
    </Modal>
  );
}
