import React from 'react';
import { TFunction } from 'i18next';
import _get from 'lodash/get';
import {
  FieldChangeCallBackArgs,
  FieldItem,
  Values,
} from '@lib/interfaces/form';
import Input from '@lib/components/Input/Input';
import FieldNames from '@lib/enums/fieldNames/interventionFieldNames';
import {
  MAX_TEXT_EDITOR_FIELD_LENGTH,
  MAX_TEXT_FIELD_LENGTH,
} from '@lib/enums/form';
import { InputTypes } from '@lib/components/Input/enums';
import { getCodeValidation } from '@lib/utils/yup';
import Select, {
  SelectComponents,
  SelectProps,
} from '@lib/components/Select/Select';
import ChipValueContainer from '@lib/components/Select/components/ChipValueContainer';
import { GetLoadOptions } from '@lib/components/Select/types';
import formStyles from '@lib/assets/styles/Form.module.scss';
import UserChipsList from '@lib/components/UserChipsListField/UserChipsListField';
import Typography from '@lib/components/Typography/Typography';
import AreaOption from '@lib/components/Select/components/AreaOption';
import ChipSingleValue from '@lib/components/Select/components/ChipSingleValue';
import AssetOption from '@lib/components/Select/components/AssetOption';
import AssetSingleValue from '@lib/components/Select/components/AssetSingleValue';
import { getInterventionPriorityOptions } from '@lib/utils/priority';
import UserOption from '@lib/components/Select/components/UserOption';
import UserSingleValue from '@lib/components/Select/components/UserSingleValue';
import DatePicker from '@lib/components/DateTimePicker/DatePicker';
import {
  getAttachmentsValidation,
  getFieldValidation,
  getOptionsArrayValidation,
  getOptionValidation,
  getTimeValidation,
} from '@lib/utils/validationUtils';
import { getInterventionStatusOptions } from '@lib/utils/status';
import { TIME_MASK } from '@lib/components/Input/masks';
import ChipCircleOption from '@lib/components/Select/components/ChipCircleOption';
import FilesUploader from '@lib/components/FilesUploader/FilesUploader';
import { UppyDocumentationAllowedFileTypes } from '@lib/enums/mimeTypes';
import { ButtonSizes } from '@lib/components/Button/Button';
import { FilesPreview } from '@lib/components/FilesUploader/enums';
import MultiSelectValueContainer from '@lib/components/Select/components/MultiSelectValueContainer';
import TextEditor from '@lib/components/TextEditor/TextEditor';

const getDefaultSelectEntityBySiteIdProps = (isEditForm: boolean) => ({
  isUseWatch: true,
  isDisabledFn: isEditForm
    ? undefined
    : (values: Values) =>
        _get(values, `${FieldNames.Site}.value`) === undefined,
  getFieldKeyFn: isEditForm
    ? undefined
    : (values: Values, name?: string) => {
        const selectedSiteId = _get(values, `${FieldNames.Site}.value`);
        if (selectedSiteId) return `${name}-site-${selectedSiteId}`;
        return FieldNames.Site;
      },
});

type Args = {
  interventionId?: string;
  getAssetsLoadOptions: GetLoadOptions;
  getInterventionCategoriesLoadOptions: GetLoadOptions;
  getSiteAreasLoadOptions: GetLoadOptions;
  getSitesLoadOptions: GetLoadOptions;
  getSupervisorLoadOptions: GetLoadOptions;
  getAssigneeLoadOptions: GetLoadOptions;
  getTasksLoadOptions: GetLoadOptions;
  isEditForm: boolean;
  openCategoryFormModal: () => void;
  t: TFunction<'translation', undefined>;
  isCategoriesViewOnly: boolean;
};

export default function getInterventionFormFields(args: Args): FieldItem[] {
  const {
    getAssetsLoadOptions,
    getInterventionCategoriesLoadOptions,
    getSiteAreasLoadOptions,
    getSupervisorLoadOptions,
    getAssigneeLoadOptions,
    getSitesLoadOptions,
    getTasksLoadOptions,
    isEditForm,
    openCategoryFormModal,
    interventionId,
    isCategoriesViewOnly,
    t,
  } = args;
  const defaultSelectEntityBySiteIdProps =
    getDefaultSelectEntityBySiteIdProps(isEditForm);
  return [
    {
      name: 'details-title',
      customRender: () => (
        <Typography variant="h3" key="details">
          {t('details')}
        </Typography>
      ),
    },
    {
      name: FieldNames.Name,
      label: t('intervention-name'),
      element: Input,
      validation: getFieldValidation(true, MAX_TEXT_FIELD_LENGTH, t),
    },
    {
      name: FieldNames.Description,
      label: t('description'),
      element: TextEditor,
      validation: getFieldValidation(true, MAX_TEXT_EDITOR_FIELD_LENGTH, t),
    },
    {
      name: FieldNames.AttachmentsAttached,
      label: '',
      validation: getAttachmentsValidation(false, t),
      element: FilesUploader,
      componentProps: {
        filesView: FilesPreview.Documents,
        allowedFileTypes: UppyDocumentationAllowedFileTypes,
        note: t('files-upload-validation-msg', { sizeImg: 5, sizePdf: 25 }),
        maxFileSize: 26214400,
        uploadButtonProps: {
          buttonText: t('upload-files'),
          buttonSize: ButtonSizes.large,
          leftIcon: 'upload',
          fullWidth: true,
        },
      },
    },
    {
      name: FieldNames.Code,
      label: t('intervention-code'),
      element: Input,
      validation: getCodeValidation(t, 30),
      componentProps: {
        disabled: isEditForm,
      },
    },
    {
      name: FieldNames.Category,
      label: t('category'),
      element: Select,
      validation: getOptionValidation(true, t),
      componentProps: {
        isAsync: true,
        getLoadOptions: getInterventionCategoriesLoadOptions,
        defaultOptions: false,
        createNewOptionProps: isCategoriesViewOnly
          ? undefined
          : {
              title: t('create-new'),
              onCLick: openCategoryFormModal,
            },
        components: {
          ValueContainer: ChipValueContainer,
          Option: ChipCircleOption,
        },
      },
      fieldChangeCallBack: ({
        type,
        setValue,
        name,
      }: FieldChangeCallBackArgs) => {
        if (type === 'change' && name === FieldNames.Category) {
          setValue(FieldNames.Supervisor, null, { shouldValidate: false });
          setValue(FieldNames.Assignee, null, { shouldValidate: false });
        }
      },
    },
    {
      name: FieldNames.Priority,
      label: t('priority'),
      element: Select,
      validation: getOptionValidation(true, t),
      componentProps: {
        options: getInterventionPriorityOptions(),
        isSearchable: false,
        isClearable: false,
        components: {
          ValueContainer: ChipValueContainer,
          Option: ChipCircleOption,
        },
      },
      formItemClassName: formStyles.formColumn,
    },
    {
      name: FieldNames.Status,
      label: t('status'),
      element: Select,
      validation: getOptionValidation(true, t),
      componentProps: {
        options: getInterventionStatusOptions(),
        isSearchable: false,
        isClearable: false,
        components: {
          ValueContainer: ChipValueContainer,
          Option: ChipCircleOption,
        },
      },
      formItemClassName: formStyles.formColumn,
    },
    {
      name: 'subjects-title',
      customRender: () => (
        <Typography variant="h3" key="subjects">
          {t('subjects')}
        </Typography>
      ),
    },
    {
      name: FieldNames.Site,
      label: t('site'),
      element: Select,
      validation: getOptionValidation(true, t),
      componentProps: {
        isAsync: true,
        getLoadOptions: getSitesLoadOptions,
        defaultOptions: false,
        disabled: isEditForm,
        components: {
          SingleValue: ChipSingleValue,
        },
      },
      formItemClassName: formStyles.formColumn,
      fieldChangeCallBack: ({
        type,
        setValue,
        name,
      }: FieldChangeCallBackArgs) => {
        if (type === 'change' && name === FieldNames.Site) {
          setValue(FieldNames.SiteArea, null, { shouldValidate: false });
          setValue(FieldNames.Asset, null, { shouldValidate: false });
          setValue(FieldNames.Task, null, { shouldValidate: false });
          setValue(FieldNames.Supervisor, null, { shouldValidate: false });
          setValue(FieldNames.Assignee, null, { shouldValidate: false });
        }
      },
    },
    {
      name: FieldNames.SiteArea,
      label: t('area'),
      element: Select,
      validation: getOptionValidation(false, t),
      componentProps: {
        isAsync: true,
        getLoadOptions: getSiteAreasLoadOptions,
        defaultOptions: false,
        components: {
          Option: AreaOption,
          SingleValue: ChipSingleValue,
        },
        disabled: isEditForm,
        disabledTooltipProps: isEditForm
          ? undefined
          : {
              body: t('choose-site-first'),
            },
      },
      formItemClassName: formStyles.formColumn,
      ...defaultSelectEntityBySiteIdProps,
      fieldChangeCallBack: ({
        type,
        setValue,
        name,
      }: FieldChangeCallBackArgs) => {
        if (type === 'change' && name === FieldNames.SiteArea) {
          setValue(FieldNames.Task, null, { shouldValidate: false });
          setValue(FieldNames.Asset, null, { shouldValidate: false });
        }
      },
    },
    {
      name: FieldNames.Asset,
      label: t('related-equipment'),
      element: Select,
      validation: getOptionValidation(false, t),
      componentProps: {
        isAsync: true,
        getLoadOptions: getAssetsLoadOptions,
        defaultOptions: false,
        components: {
          Option: AssetOption,
          SingleValue: AssetSingleValue,
        } as unknown as SelectComponents,
        disabled: isEditForm,
        disabledTooltipProps: isEditForm
          ? undefined
          : {
              body: t('choose-site-first'),
            },
      } as SelectProps,
      ...defaultSelectEntityBySiteIdProps,
      getFieldKeyFn: isEditForm
        ? undefined
        : (values: Values, name?: string) => {
            const selectedSiteId = _get(values, `${FieldNames.Site}.value`, '');
            const selectedSiteAreaId = _get(
              values,
              `${FieldNames.SiteArea}.value`,
              '',
            );
            if (!!selectedSiteId || !!selectedSiteAreaId) {
              return `${name}-site-${selectedSiteId}-area-${selectedSiteAreaId}`;
            }
            return FieldNames.Site;
          },
      fieldChangeCallBack: ({
        type,
        setValue,
        name,
      }: FieldChangeCallBackArgs) => {
        if (type === 'change' && name === FieldNames.Asset) {
          setValue(FieldNames.Task, null, { shouldValidate: false });
        }
      },
    },
    {
      name: FieldNames.Task,
      label: t('related-task'),
      element: Select,
      validation: getOptionValidation(false, t),
      componentProps: {
        isAsync: true,
        getLoadOptions: getTasksLoadOptions,
        defaultOptions: false,
        components: {
          SingleValue: ChipSingleValue,
        } as unknown as SelectComponents,
        disabled: isEditForm,
        disabledTooltipProps: isEditForm
          ? undefined
          : {
              body: t('choose-site-first'),
            },
      } as SelectProps,
      ...defaultSelectEntityBySiteIdProps,
      getFieldKeyFn: (values: Values, name?: string) => {
        const selectedSiteId = _get(values, `${FieldNames.Site}.value`);
        const selectedSiteAreaId = _get(values, `${FieldNames.SiteArea}.value`);
        const selectedAssetId = _get(values, `${FieldNames.Asset}.value`);
        return `${name}-site-${selectedSiteId}-area-${selectedSiteAreaId}-asset-${selectedAssetId}`;
      },
    },
    {
      name: 'assignation-title',
      customRender: () => (
        <Typography variant="h3" key="assignation">
          {t('assignation')}
        </Typography>
      ),
    },
    {
      name: FieldNames.Supervisor,
      label: t('supervisor'),
      element: Select,
      validation: getOptionValidation(false, t),
      componentProps: {
        isAsync: true,
        getLoadOptions: getSupervisorLoadOptions,
        defaultOptions: false,
        components: {
          Option: UserOption,
          SingleValue: UserSingleValue,
        } as unknown as SelectComponents,
        disabledTooltipProps: {
          body: t('choose-site-first'),
        },
      } as SelectProps,
      ...defaultSelectEntityBySiteIdProps,
    },
    {
      name: FieldNames.Assignee,
      label: t('assignees-label'),
      element: Select,
      validation: getOptionsArrayValidation(false, t),
      componentProps: {
        isAsync: true,
        isMulti: true,
        getLoadOptions: getAssigneeLoadOptions,
        defaultOptions: false,
        components: {
          Option: UserOption,
          ValueContainer: MultiSelectValueContainer,
        } as unknown as SelectComponents,
        disabledTooltipProps: {
          body: t('choose-site-first'),
        },
      } as SelectProps,
      hideErrorsSpace: true,
      ...defaultSelectEntityBySiteIdProps,
    },
    {
      name: `${FieldNames.Assignee}-list`,
      element: UserChipsList,
      componentProps: {
        fieldName: FieldNames.Assignee,
        interventionId,
      },
      isUseWatch: true,
    },
    {
      name: 'timing-title',
      customRender: () => (
        <Typography variant="h3" key="timing">
          {t('timing')}
        </Typography>
      ),
    },
    {
      name: FieldNames.EstimatedTime,
      label: `${t('estimated-time')} hh:mm`,
      validation: getTimeValidation(false, t),
      element: Input,
      formItemClassName: formStyles.formColumn,
      componentProps: {
        mask: TIME_MASK,
      },
    },
    {
      name: FieldNames.EstimatedDate,
      label: t('estimated-date'),
      element: DatePicker,
      componentProps: {
        type: InputTypes.date,
      },
      validation: getFieldValidation(false, MAX_TEXT_FIELD_LENGTH, t),
      formItemClassName: formStyles.formColumn,
    },
    {
      name: FieldNames.LogisticTime,
      label: `${t('logistic-time')} hh:mm`,
      validation: getTimeValidation(false, t),
      element: Input,
      formItemClassName: formStyles.formColumn,
      componentProps: {
        mask: TIME_MASK,
      },
    },
    {
      name: FieldNames.DurationTime,
      label: `${t('time-spent')} hh:mm`,
      description: t('time-spent-description'),
      validation: getTimeValidation(false, t),
      element: Input,
      formItemClassName: formStyles.formColumn,
      componentProps: {
        mask: TIME_MASK,
      },
    },
  ];
}
