import { LanguageEnum } from 'graphql-common';

export function getLangLabel(lang: LanguageEnum) {
  switch (lang) {
    case LanguageEnum.En:
      return 'English';
    case LanguageEnum.Fr:
      return 'French';
    default:
      return '-';
  }
}

export const langOptions = Object.values(LanguageEnum).map((lang) => ({
  label: getLangLabel(lang),
  value: lang,
}));
