import React from 'react';
import { TFunction } from 'i18next';
import { SelectProps } from '@lib/components/Select/Select';
import { SelectOption } from '@lib/components/Select/types';
import ButtonControl from '@lib/components/Select/components/ButtonControl';
import FormTemplateVersionSelect from 'components/FormTemplateVersionSelect/FormTemplateVersionSelect';
import ButtonValueContainer from '@lib/components/Select/components/ButtonValueContainer';

type ActionsForSelectedArgs = {
  formTemplateId: string;
  loadingState: boolean;
  onVersionChange: SelectProps['onChange'];
  selectedVersionOption?: SelectOption;
  t: TFunction<'translation', undefined>;
  selectedItemsCount: number;
};

export default function getFormTemplateTasksTableActionsForSelected(
  args: ActionsForSelectedArgs,
) {
  const {
    formTemplateId,
    loadingState,
    onVersionChange,
    selectedVersionOption,
    selectedItemsCount,
    t,
  } = args;
  return [
    <div key="version-select">
      <FormTemplateVersionSelect
        disabled={loadingState}
        hideArchived
        formTemplateId={formTemplateId}
        onChange={onVersionChange}
        value={selectedVersionOption}
        components={{
          Control: ButtonControl,
          ValueContainer: ButtonValueContainer,
        }}
        valueContainerLabel={`${t('apply-version')} (${selectedItemsCount})`}
      />
    </div>,
  ];
}
