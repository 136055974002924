import React from 'react';
import {
  components as DefaultSelectComponents,
  ControlProps,
} from 'react-select';
import classNames from 'classnames';
import Chip, { ChipSizes, ChipTypes } from '@lib/components/Chip/Chip';
import MaterialIcon from '@lib/components/MaterialIcon/MaterialIcon';
import { InputSizes } from '@lib/components/Input/enums';
import styles from '../Select.module.scss';

const rightIconComponent = (isOpen: boolean) => (
  <MaterialIcon icon={isOpen ? 'arrow_drop_up' : 'arrow_drop_down'} />
);

export default function ChipControl(props: ControlProps): JSX.Element {
  const { children, ...restProps } = props;
  const { selectProps, isFocused } = restProps;
  // @ts-ignore TODO: fix it (https://react-select.com/typescript#custom-select-props)
  const { menuIsOpen, isDisabled, size } = selectProps;
  const controlClassName = classNames(styles.control, styles.chipControl, {
    isFocused,
    isDisabled,
    menuIsOpen,
  });
  let chipSize = ChipSizes.small;
  if (size === InputSizes.large) {
    chipSize = ChipSizes.large;
  }
  return (
    <DefaultSelectComponents.Control
      {...restProps}
      className={controlClassName}
    >
      <Chip
        label={children}
        rightIconComponent={
          isDisabled ? undefined : rightIconComponent(menuIsOpen)
        }
        size={chipSize}
        type={ChipTypes.chipGray}
        tooltipForCroppedTextDisabled
      />
    </DefaultSelectComponents.Control>
  );
}
