import { Nomenclature } from 'graphql-common';
import assetNomenclatureFieldNames from '@lib/enums/fieldNames/assetNomenclatureFieldNames';

export default function getDefaultNomenclatureFormValuesFromQueryData(
  data: Nomenclature,
) {
  return {
    [assetNomenclatureFieldNames.ComponentAsset]: {
      label: data?.componentAsset?.name,
      value: data?.componentAsset?.id,
      data: data?.componentAsset,
    },
    [assetNomenclatureFieldNames.Quantity]: data?.quantity,
  };
}
