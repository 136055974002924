import { MutableRefObject, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
  TaskBulkCreateSameInputObject,
  TaskStatusEnum,
  TaskUpdateInputObject,
} from 'graphql-common';
import { FormRef } from '@lib/components/ReactHookForm/types';
import toast from '@lib/components/Toast/Toast';
import { Values } from '@lib/interfaces/form';
import { APP_URLS } from 'constants/urls';
import useMutations from 'routes/Task/utils/useMutations';
import useTaskQueries from 'routes/Task/utils/useTaskQueries';
import getData from 'routes/Task/utils/getData';
import {
  getPreparedTaskDataFromValues,
  getPreparedTaskScheduleDataFromValues,
} from './utils';

interface UseActionsProps {
  closeArchiveModal?: () => void;
  closeScheduleModal?: () => void;
  closeTaskDeleteModal?: () => void;
  formRef?: MutableRefObject<FormRef | undefined>;
  isScheduleModalOpened?: boolean;
  openArchiveModal?: () => void;
  setSelectedSiteAreaIds?: (v: { [key: string]: string }) => void;
  setSelectedSiteId?: (v?: string) => void;
}

export default function useActions(props: UseActionsProps) {
  const {
    closeArchiveModal,
    closeScheduleModal,
    closeTaskDeleteModal,
    isScheduleModalOpened,
    openArchiveModal,
    setSelectedSiteAreaIds,
    setSelectedSiteId,
  } = props;
  const { id = '' } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const navigateToTaskFormView = () => {
    navigate(
      APP_URLS.dashboard.tasks.formView.getDynamicPath({
        pathParts: { id: `${id}` },
      }),
    );
  };

  // Task
  const { taskQuery, taskFormTemplateQuery, fullTaskQuery, taskScheduleQuery } =
    useTaskQueries({
      isScheduleModalOpened,
    });

  // Mutations
  const useMutationsResult = useMutations({
    closeScheduleModal,
    taskQueryRefetch: taskQuery?.refetch,
    taskScheduleQueryRefetch: taskScheduleQuery?.refetch,
  });
  const {
    createMutation,
    createMutationResult,
    destroyTaskMutation,
    taskBulkSchedulingMutation,
    taskBulkSchedulingResult,
    taskBulkUpdateStatusMutation,
    taskBulkUpdateStatusResult,
    updateMutation,
    updateMutationResult,
  } = useMutationsResult;

  const {
    createMutationError,
    createTaskLoading,
    fullTaskData,
    fullTaskError,
    fullTaskLoading,
    taskBulkSchedulingError,
    taskBulkSchedulingLoading,
    taskBulkUpdateStatusError,
    taskBulkUpdateStatusLoading,
    taskData,
    taskDataForFormTemplate,
    taskDataSiteAreaId,
    taskDataSiteId,
    taskError,
    taskFormTemplate,
    taskFormTemplateError,
    taskFormTemplateId,
    taskFormTemplateLoading,
    taskFormTemplateVersion,
    taskFormTemplateVersionId,
    taskLoading,
    taskScheduleData,
    taskScheduleError,
    taskScheduleLoading,
    updateMutationError,
    updateTaskLoading,
  } = getData({
    createMutationResult,
    fullTaskQuery,
    taskBulkSchedulingResult,
    taskBulkUpdateStatusResult,
    taskFormTemplateQuery,
    taskQuery,
    taskScheduleQuery,
    updateMutationResult,
  });

  const onCreate = (values: Values) => {
    createMutation({
      variables: {
        attributes: getPreparedTaskDataFromValues(
          values,
          undefined,
        ) as TaskBulkCreateSameInputObject,
      },
    });
  };

  const onUpdate = (values: Values) => {
    if (id) {
      updateMutation({
        variables: {
          id,
          attributes: getPreparedTaskDataFromValues(
            values,
            taskData,
          ) as TaskUpdateInputObject,
        },
      });
    }
  };

  const onDestroyHandler = () => {
    if (id) {
      destroyTaskMutation({
        variables: { id },
      });
    }
  };

  const onConfirmedDelete = () => {
    if (closeTaskDeleteModal) closeTaskDeleteModal();
    toast({
      content: t('destroy-task-success'),
      closeCallback: onDestroyHandler,
    });
  };

  const onEditHandler = () => {
    navigate(
      APP_URLS.dashboard.tasks.edit.getDynamicPath({
        pathParts: {
          id: `${id}`,
        },
      }),
    );
  };

  const onDuplicateHandler = () => {
    navigate(
      APP_URLS.dashboard.tasks.duplicate.getDynamicPath({
        pathParts: {
          id: `${id}`,
        },
      }),
    );
  };

  const onRescheduleHandler = (values?: Values) => {
    if (id && values) {
      taskBulkSchedulingMutation({
        variables: {
          ids: [id],
          attributes: getPreparedTaskScheduleDataFromValues(values),
        },
      });
    }
  };

  const onArchiveHandler = () => {
    if (id) {
      taskBulkUpdateStatusMutation({
        variables: {
          ids: [id],
          attributes: { status: TaskStatusEnum.Archived },
        },
      });
    }
  };

  const onStatusChangeHandler = (idValue: string, status: TaskStatusEnum) => {
    if (idValue && status) {
      taskBulkUpdateStatusMutation({
        variables: { ids: [idValue], attributes: { status } },
      });
    }
  };

  const onBulkArchiveOpen = () => {
    if (id && openArchiveModal) {
      openArchiveModal();
    }
  };

  const onConfirmedBulkArchive = () => {
    if (closeArchiveModal) closeArchiveModal();
    toast({
      content: t('archive-task-success'),
      closeCallback: onArchiveHandler,
    });
  };

  const onOpenEditTaskDetails = () => {
    navigate(
      APP_URLS.dashboard.formTemplates.editVersion.getDynamicPath({
        pathParts: {
          id: taskFormTemplateId,
          versionId: taskFormTemplateVersionId,
        },
      }),
    );
  };

  const onCreateNewFormTemplate = () => {
    window.open(APP_URLS.dashboard.formTemplates.add.path, '_blank');
  };

  const onViewFormTemplate = (
    formTemplateId: string,
    formTemplateVersionId: string,
  ) => {
    window.open(
      APP_URLS.dashboard.formTemplates.viewVersion.getDynamicPath({
        pathParts: {
          id: formTemplateId,
          versionId: formTemplateVersionId,
        },
      }),
      '_blank',
    );
  };

  const onCloseScheduleModal = () => {
    if (closeScheduleModal) closeScheduleModal();
    taskBulkSchedulingResult.reset();
  };

  useEffect(() => {
    if (taskDataSiteId && setSelectedSiteId) {
      setSelectedSiteId(taskDataSiteId);
    }
  }, [setSelectedSiteId, taskDataSiteId]);

  useEffect(() => {
    if (taskDataSiteAreaId && setSelectedSiteAreaIds) {
      setSelectedSiteAreaIds({ 'assignations.0.siteArea': taskDataSiteAreaId });
    }
  }, [setSelectedSiteAreaIds, taskDataSiteAreaId]);

  return {
    createMutationError,
    createTaskLoading,
    fullTaskData,
    fullTaskError,
    fullTaskLoading,
    idParam: id,
    navigateToTaskFormView,
    onBulkArchiveOpen,
    onCloseScheduleModal,
    onConfirmedBulkArchive,
    onConfirmedDelete,
    onCreate,
    onCreateNewFormTemplate,
    onDuplicateHandler,
    onEditHandler,
    onOpenEditTaskDetails,
    onRescheduleHandler,
    onStatusChangeHandler,
    onUpdate,
    onViewFormTemplate,
    taskBulkSchedulingError,
    taskBulkSchedulingLoading,
    taskBulkUpdateStatusError,
    taskBulkUpdateStatusLoading,
    taskData: taskData || taskDataForFormTemplate,
    taskError,
    taskFormTemplate,
    taskFormTemplateError,
    taskFormTemplateLoading,
    taskFormTemplateVersion,
    taskLoading,
    taskScheduleData,
    taskScheduleError,
    taskScheduleLoading,
    updateMutationError,
    updateTaskLoading,
  };
}
