import { LazyQueryExecFunction } from '@apollo/client';
import {
  FilterGroupingEnum,
  FormTemplateVersion,
  FormTemplateVersionFilterInputObject,
  FormTemplateVersionsSearchListQuery,
  FormTemplateVersionsSearchListQueryVariables,
  SortDirectionEnum,
} from 'graphql-common';
import { DEFAULT_SEARCH_LIST_VARIABLES } from '@lib/enums/urls';
import getLoadOptionsResult from '@lib/utils/getLoadOptionsResult';
import createSearchFilters from '@lib/utils/createSearchFilters';
import { LoadOptionsParams } from '@lib/components/Select/types';

type FetchLazyQuery = LazyQueryExecFunction<
  FormTemplateVersionsSearchListQuery,
  any
>;

const fetchSearchList = async (
  searchQuery: string,
  fetchLazyQuery: FetchLazyQuery,
  page: number,
  formTemplateId: string,
  hideArchived?: boolean,
) => {
  const options: { variables: FormTemplateVersionsSearchListQueryVariables } = {
    variables: {
      ...DEFAULT_SEARCH_LIST_VARIABLES,
      page,
      formTemplateId,
      sorts: {
        version: SortDirectionEnum.Desc,
        createdAt: SortDirectionEnum.Desc,
      },
    },
  };
  const filters: FormTemplateVersionFilterInputObject[] = [];
  if (hideArchived) {
    filters.push({
      archivedAt: {
        grouping: FilterGroupingEnum.Or,
        predicate: {
          null: true,
        },
      },
    });
  }
  if (searchQuery) {
    filters.push(
      ...createSearchFilters(searchQuery, [
        { fieldName: 'version', predicateType: 'eq' },
      ]),
    );
  }
  if (filters.length) {
    options.variables.filters = filters;
  }
  const { data } = await fetchLazyQuery(options);
  return getLoadOptionsResult(
    data,
    (dataItem: FormTemplateVersion) => ({
      value: dataItem.id,
      label: dataItem.version,
      data: {
        status: dataItem.status,
      },
    }),
    searchQuery,
  );
};

export const getFormTemplateVersionsLoadOptions =
  (
    fetchLazyQuery: FetchLazyQuery,
    formTemplateId: string,
    hideArchived?: boolean,
  ) =>
  (searchQuery: string, loadedOptions: unknown, params: LoadOptionsParams) =>
    fetchSearchList(
      searchQuery,
      fetchLazyQuery,
      params?.page || 1,
      formTemplateId,
      hideArchived,
    );
