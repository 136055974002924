import React, { forwardRef, memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';
import {
  FilterGroupingEnum,
  InterventionCategoriesSearchListQueryVariables,
  InterventionCategory,
  InterventionCategoryFilterInputObject,
  useInterventionCategoriesSearchListQuery,
} from 'graphql-common';
import {
  GetUrlParams,
  ORDERING_DIRECTION_ASC,
  OrderingParams,
  PAGINATION_PARAMS,
  PaginationParams,
} from '@lib/enums/urls';
import LinkComponent, { LinkSizes, LinkTypes } from '@lib/components/Link/Link';
import MaterialIcon from '@lib/components/MaterialIcon/MaterialIcon';
import { FloatingLabelProps } from '@lib/hocs/withFloatingLabel';
import { Values } from '@lib/interfaces/form';
import { SelectOption } from '@lib/components/Select/types';
import Chip, { ChipTypes } from '@lib/components/Chip/Chip';
import usePrevious from '@lib/hooks/usePrevious';
import styles from './InterventionCategoriesChipsListField.module.scss';

type Props = {
  fieldName?: string;
  values: Values;
  ids?: string[];
};

const InterventionCategoriesChipsListField = forwardRef(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  (props: Props & FloatingLabelProps, ref: React.ForwardedRef<unknown>) => {
    const { fieldName = '', values, ids } = props;
    const prevIds = usePrevious(ids);
    const { setValue } = useFormContext();
    const { t } = useTranslation();
    const [paginationParams, setPaginationParams] = useState<
      PaginationParams & OrderingParams
    >({
      ...PAGINATION_PARAMS,
      [GetUrlParams.OrderingField]: 'name',
      [GetUrlParams.OrderingDirection]: ORDERING_DIRECTION_ASC,
    });
    const [allItems, setAllItems] = useState<InterventionCategory[]>([]);

    const page = _get(paginationParams, GetUrlParams.Page);
    const perPage = _get(paginationParams, GetUrlParams.PerPage);
    const orderingField = _get(paginationParams, GetUrlParams.OrderingField);
    const orderingDirection = _get(
      paginationParams,
      GetUrlParams.OrderingDirection,
    );

    const queryVariables: InterventionCategoriesSearchListQueryVariables = {
      page: Number(page),
      limit: Number(perPage),
    };

    const filters: InterventionCategoryFilterInputObject[] = [];
    if (ids?.length) {
      filters.push({
        id: {
          grouping: FilterGroupingEnum.Or,
          predicate: {
            in: ids,
          },
        },
      });
    }
    if (filters.length) {
      queryVariables.filters = filters;
    }
    if (orderingField && orderingDirection) {
      queryVariables.sorts = {
        [orderingField]: orderingDirection,
      };
    }

    const query = useInterventionCategoriesSearchListQuery({
      fetchPolicy: 'no-cache',
      variables: queryVariables,
      skip: !ids?.length,
    });

    const { loading, data, previousData, error } = query;
    const queryData = data || previousData;
    const firstLoading = loading && previousData === undefined;
    const newItems = _get(
      queryData,
      'data.collection',
      [],
    ) as InterventionCategory[];
    const metadata = _get(queryData, 'data.metadata');
    const totalPages = _get(metadata, 'totalPages', 1);
    const currentPage = _get(metadata, 'currentPage', 1);

    const handleLoadMore = () => {
      if (currentPage < totalPages) {
        setPaginationParams((prev) => ({
          ...prev,
          [GetUrlParams.Page]: currentPage + 1,
        }));
      }
    };

    const handleRemove = (id: string) => {
      const itemsValue = _get(values, fieldName, []) as SelectOption[];
      const newData = itemsValue.filter(
        ({ value: optionValue }: SelectOption) => optionValue !== id,
      );
      if (fieldName) {
        setValue(fieldName, newData);
      }
    };

    const formFieldValue = (_get(values, fieldName) || []) as SelectOption[];
    const visibleItems = allItems.filter((item) =>
      formFieldValue.some(({ value: optionValue }) => item.id === optionValue),
    );
    const visibleItemsIds = visibleItems.map((item) => item.id);
    let newFormFieldValue = formFieldValue;
    if (ids?.length) {
      newFormFieldValue = formFieldValue.filter(
        (item) => !ids.includes(item.value as string),
      );
    }
    if (visibleItemsIds.length) {
      newFormFieldValue = newFormFieldValue.filter(
        (item) => !visibleItemsIds.includes(item.value as string),
      );
    }

    useEffect(() => {
      if (newItems.length) {
        setAllItems((prevItems) => {
          const combined = [...prevItems, ...newItems];
          return combined.filter(
            (item, index, self) =>
              index === self.findIndex((subItem) => subItem.id === item.id),
          );
        });
      }
    }, [newItems]);

    useEffect(() => {
      if (!_isEqual(ids, prevIds)) {
        setAllItems([]);
        query.refetch();
      }
    }, [ids, prevIds, query]);

    if (error || firstLoading) return null;

    return (
      <div>
        <div className={styles.chipsList}>
          {newFormFieldValue?.length
            ? newFormFieldValue.map((formFieldItem: SelectOption) => (
                <Chip
                  label={formFieldItem.label}
                  type={
                    _get(
                      formFieldItem,
                      'color',
                      ChipTypes.chipGray,
                    ) as ChipTypes
                  }
                  key={formFieldItem.value as string}
                  rightIconComponent={
                    <MaterialIcon
                      icon="cancel"
                      onClick={() =>
                        handleRemove(formFieldItem.value as string)
                      }
                    />
                  }
                />
              ))
            : null}
          {visibleItems?.length
            ? visibleItems.map((visibleItem) => (
                <Chip
                  label={_get(visibleItem, 'name')}
                  type={
                    _get(visibleItem, 'color', ChipTypes.chipGray) as ChipTypes
                  }
                  leftIcon="circle"
                  key={visibleItem.id}
                  rightIconComponent={
                    <MaterialIcon
                      icon="cancel"
                      onClick={() => handleRemove(visibleItem.id)}
                    />
                  }
                />
              ))
            : null}
        </div>
        {currentPage < totalPages && (
          <LinkComponent
            size={LinkSizes.small}
            type={LinkTypes.secondaryGray}
            onClick={handleLoadMore}
          >
            {t('show-more')}
          </LinkComponent>
        )}
      </div>
    );
  },
);

export default memo(InterventionCategoriesChipsListField);
