import { useLocation, useNavigate } from 'react-router-dom';
import _get from 'lodash/get';
import {
  getPageCount,
  getPaginationParamsByPathname,
} from '@lib/utils/pagination';
import { parseSearch, stringifyWithPrefix } from '@lib/utils/url';
import {
  GetUrlParams,
  PaginationParams,
  PaginationState,
} from '@lib/enums/urls';
import usePaginationParams from '@lib/hooks/usePaginationParams';

export type PaginationProps = {
  onPageChange?: (newPage: number) => void;
  pageKey?: typeof GetUrlParams.Page;
  paginationState?: PaginationState;
  totalEntries?: number;
};

const usePagination = (props: PaginationProps) => {
  const {
    onPageChange,
    pageKey = GetUrlParams.Page,
    paginationState = [], // result of useState hook
    totalEntries,
  } = props;
  const { search, pathname } = useLocation();
  const navigate = useNavigate();
  const [
    { [GetUrlParams.Page]: pageUrlParam, [GetUrlParams.PerPage]: perPage },
  ] = usePaginationParams();
  const [params, setParams] = paginationState; // custom state

  let page;
  let pageCount;
  if (paginationState.length) {
    page = Number(_get(params, GetUrlParams.Page, 1));
    pageCount = totalEntries
      ? getPageCount(
          totalEntries,
          Number(_get(params, GetUrlParams.PerPage, 1)),
        )
      : 1;
  } else {
    page = pageUrlParam ? +pageUrlParam : 1;
    pageCount = totalEntries ? getPageCount(totalEntries, Number(perPage)) : 1;
  }

  const handlePageChange = (newPage: number) => {
    if (onPageChange) {
      onPageChange(newPage);
    } else if (setParams) {
      const newParams: PaginationParams = { ...params };
      if (pageKey) newParams[pageKey] = String(newPage);
      setParams(newParams);
    } else {
      const newParams = search
        ? parseSearch(search)
        : { ...getPaginationParamsByPathname(pathname) };
      if (pageKey) newParams[pageKey] = String(newPage);
      const newUrl = `${pathname}${stringifyWithPrefix(newParams)}`;
      navigate(newUrl);
    }
  };

  return {
    page,
    pageCount,
    onPageChange: handlePageChange,
  };
};

export default usePagination;
