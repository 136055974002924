import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ApolloError } from '@apollo/client/errors';
import _get from 'lodash/get';
import {
  Attachment,
  FilterGroupingEnum,
  InterventionAction,
  InterventionActionStatusEnum,
  InterventionAssetPrefillDataQuery,
  InterventionAssetPrefillDataQueryVariables,
  InterventionCategory,
  InterventionCreateInputObject,
  InterventionSiteAreaPrefillDataQuery,
  InterventionSiteAreaPrefillDataQueryVariables,
  InterventionSitePrefillDataQuery,
  InterventionSitePrefillDataQueryVariables,
  InterventionStatusEnum,
  InterventionTaskPrefillDataQuery,
  InterventionTaskPrefillDataQueryVariables,
  InterventionUpdateInputObject,
  useInterventionAssetPrefillDataQuery,
  useInterventionSiteAreaPrefillDataQuery,
  useInterventionSitePrefillDataQuery,
  useInterventionTaskPrefillDataQuery,
  User,
} from 'graphql-common';
import { Values } from '@lib/interfaces/form';
import toast from '@lib/components/Toast/Toast';
import { BreadCrumb } from '@lib/components/BreadCrumbs/types';
import getAppUrlWithDomain from '@lib/utils/getAppUrlWithDomain';
import useInterventionQueryHook, {
  UseAssetInterventionQueryHookResult,
} from 'utils/fetch/useInterventionQueryHook';
import { APP_URLS } from 'constants/urls';
import useTaskQueryHook, {
  UseTaskQueryHookResult,
} from 'utils/fetch/useTaskQueryHook';
import FieldNames from '@lib/enums/fieldNames/interventionFieldNames';
import { getBreadCrumbsDataByKey } from 'utils/breadcrumbs';
import useUsersQueryHook from 'utils/fetch/useUsersQueryHook';
import useSearchParam from '@lib/hooks/useSearchParam';
import { GetUrlParams, PATH_ID } from '@lib/enums/urls';
import useInterventionActionsQueryHook, {
  UseAssetInterventionsQueryHookResult,
} from 'utils/fetch/useInterventionActionsQueryHook';
import usePaginationParams from '@lib/hooks/usePaginationParams';
import useFilterParams from '@lib/hooks/useFilterParams';
import { QueryResult } from '@apollo/client';
import { ListQueryHookResult } from 'utils/fetch/types';
import getPreparedAssetCategoryDataFromValues from 'routes/InterventionCategories/utils/getPreparedAssetCategoryDataFromValues';
import { FormRefType } from '@lib/components/ReactHookForm/types';
import { UseModalManagementResult } from './useModalManagement';
import useMutations from './useMutations';
import getInterventionPreparedUpdateDataFromValues from './getInterventionPreparedUpdateDataFromValues';
import getInterventionPreparedCreateDataFromValues from './getInterventionPreparedCreateDataFromValues';
import { InterventionViewMode } from '../enums';

const assigneeRedirectUrl = getAppUrlWithDomain(
  APP_URLS.dashboard.interventions.view.getDynamicPath({}),
).replace(PATH_ID, ':interventionId');

type Args = {
  formRef?: FormRefType;
  useModalManagementResult: UseModalManagementResult;
  viewMode: InterventionViewMode;
};

export type UseInterventionActionsResult = {
  breadCrumbsData: BreadCrumb[];
  id: string;
  interventionActionsQueryHookResult: UseAssetInterventionsQueryHookResult;
  interventionAssetPrefillDataQueryHookResult: QueryResult<
    InterventionAssetPrefillDataQuery,
    InterventionAssetPrefillDataQueryVariables
  >;
  interventionTaskPrefillDataQueryHookResult: QueryResult<
    InterventionTaskPrefillDataQuery,
    InterventionTaskPrefillDataQueryVariables
  >;
  interventionQueryHookResult: UseAssetInterventionQueryHookResult;
  interventionSiteAreaPrefillDataQueryHookResult: QueryResult<
    InterventionSiteAreaPrefillDataQuery,
    InterventionSiteAreaPrefillDataQueryVariables
  >;
  interventionSitePrefillDataQueryHookResult: QueryResult<
    InterventionSitePrefillDataQuery,
    InterventionSitePrefillDataQueryVariables
  >;
  loading: boolean;
  mutationError: ApolloError | undefined;
  onActionStatusChangeHandler: (
    id: string,
    newStatus: InterventionActionStatusEnum,
  ) => void;
  onAttachFiles: (files: Attachment[]) => void;
  onConfirmedInterventionActionClose: () => void;
  onConfirmedInterventionActionDestroy: () => void;
  onConfirmedInterventionClose: () => void;
  onConfirmedInterventionDestroy: () => void;
  onStatusChangeHandler: (
    id: string,
    newStatus: InterventionStatusEnum,
  ) => void;
  onSubmit: (values: Values) => void;
  onViewChange: (newViewMode: string) => void;
  onViewTask: (taskId: string) => void;
  openCreateActionPage: () => void;
  openEditActionPage: (interventionAction: InterventionAction) => void;
  taskQueryHookResult: UseTaskQueryHookResult;
  usersQueryHookResult: ListQueryHookResult<User>;
  onAssetCategoryCreate: (values: Values) => void;
  categoryCreateMutationError?: ApolloError;
  categoryCreateMutationLoading: boolean;
};

export default function useInterventionActions({
  formRef,
  viewMode,
  useModalManagementResult,
}: Args): UseInterventionActionsResult {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchQuery] = useSearchParam();
  const [filterParams] = useFilterParams();
  const [paginationParams] = usePaginationParams();
  const { id = '' } = useParams();
  const { t } = useTranslation();
  const useMutationsResult = useMutations();

  const {
    [GetUrlParams.SiteId]: siteIdUrlParam,
    [GetUrlParams.SiteAreaId]: siteAreaIdUrlParam,
    [GetUrlParams.AssetId]: assetIdUrlParam,
    [GetUrlParams.TaskId]: taskIdUrlParam,
  } = filterParams;

  // Intervention
  const interventionQueryHookResult = useInterventionQueryHook({
    id,
    skip:
      viewMode === InterventionViewMode.Add ||
      viewMode === InterventionViewMode.AddAction ||
      viewMode === InterventionViewMode.EditAction,
    fetchPolicy: 'cache-and-network',
  });

  const interventionActionsQueryHookResult = useInterventionActionsQueryHook({
    paginationParams,
    orderingParams: paginationParams,
    searchQuery,
    skip: viewMode !== InterventionViewMode.ViewActions,
    interventionId: id,
  });

  const interventionSitePrefillDataQueryHookResult =
    useInterventionSitePrefillDataQuery({
      skip: !siteIdUrlParam,
      variables: {
        siteFindBy: {
          id: siteIdUrlParam,
        },
      },
    });
  const interventionSiteAreaPrefillDataQueryHookResult =
    useInterventionSiteAreaPrefillDataQuery({
      skip: !siteIdUrlParam || !siteAreaIdUrlParam,
      variables: {
        siteFindBy: {
          id: siteIdUrlParam,
        },
        siteAreaFindBy: {
          id: siteAreaIdUrlParam,
        },
      },
    });
  const interventionAssetPrefillDataQueryHookResult =
    useInterventionAssetPrefillDataQuery({
      skip: !assetIdUrlParam,
      variables: {
        assetFindBy: {
          id: assetIdUrlParam,
        },
      },
    });
  const interventionTaskPrefillDataQueryHookResult =
    useInterventionTaskPrefillDataQuery({
      skip: !taskIdUrlParam,
      variables: {
        id: taskIdUrlParam || '',
      },
    });

  // Task
  const interventionTaskId =
    _get(interventionQueryHookResult, 'data.task.id') || '';
  const taskQueryHookResult = useTaskQueryHook({
    id: interventionTaskId,
    skip: viewMode !== InterventionViewMode.View,
  });

  // Users (intervention assignees) for view
  const usersQueryHookResult = useUsersQueryHook({
    paginationParams: {
      [GetUrlParams.Page]: '1',
      [GetUrlParams.PerPage]: '4',
    },
    skip: !(
      viewMode === InterventionViewMode.View ||
      viewMode === InterventionViewMode.ViewActions ||
      viewMode === InterventionViewMode.ViewAction
    ),
    skipGetAllIds: viewMode !== InterventionViewMode.Edit,
    additionalFilters: [
      {
        interventionAssigneesInterventionId: {
          grouping: FilterGroupingEnum.Or,
          predicate: { eq: id },
        },
      },
    ],
    getWithAllIds: true,
  });
  const { allIds: assigneeIds } = usersQueryHookResult;

  const onInterventionCreate = (values: InterventionCreateInputObject) => {
    useMutationsResult.interventionCreateMutation({
      variables: {
        attributes: values,
        assigneeRedirectUrl,
      },
      onCompleted: (res) => {
        const newId = res?.data?.id;
        toast({ content: t('create-intervention-success') });
        if (newId) {
          navigate(
            APP_URLS.dashboard.interventions.view.getDynamicPath({
              pathParts: { id: newId },
            }),
          );
        }
      },
    });
  };

  const onInterventionUpdate = (values: InterventionUpdateInputObject) => {
    useMutationsResult.interventionUpdateMutation({
      variables: {
        id,
        attributes: values,
        assigneeRedirectUrl,
      },
      onCompleted: () => {
        toast({ content: t('update-intervention-success') });
        navigate(
          APP_URLS.dashboard.interventions.view.getDynamicPath({
            pathParts: { id },
          }),
        );
      },
    });
  };

  const onSubmit = (values: Values) => {
    if (viewMode === InterventionViewMode.Add) {
      onInterventionCreate(getInterventionPreparedCreateDataFromValues(values));
    } else if (viewMode === InterventionViewMode.Edit) {
      onInterventionUpdate(
        getInterventionPreparedUpdateDataFromValues(values, {
          ...interventionQueryHookResult.data,
          [FieldNames.Assignee]: assigneeIds.map(({ id: assigneeId }) => ({
            value: assigneeId,
          })),
        } as Values),
      );
    }
  };

  const onConfirmedInterventionDestroy = () => {
    useModalManagementResult.closeAllModals();
    toast({
      content: t('destroy-intervention-success'),
      closeCallback: () => {
        useMutationsResult.interventionDestroyMutation({
          variables: { id },
          onCompleted: () => {
            navigate(APP_URLS.dashboard.interventions.index.getPathWithQuery());
          },
        });
      },
    });
  };

  const onConfirmedInterventionClose = () => {
    useModalManagementResult.closeAllModals();
    toast({
      content: t('close-intervention-success'),
      closeCallback: () => {
        useMutationsResult.interventionBulkCloseMutation({
          variables: { ids: [id] },
          onCompleted: () => {
            interventionQueryHookResult.refetch();
          },
        });
      },
    });
  };

  const onConfirmedInterventionActionClose = () => {
    const interventionActionId =
      useModalManagementResult.isInterventionActionCloseModalOpened?.id;
    if (interventionActionId) {
      useModalManagementResult.closeAllModals();
      toast({
        content: t('close-intervention-success'),
        closeCallback: () => {
          useMutationsResult.interventionActionUpdateMutation({
            variables: {
              id: interventionActionId,
              interventionId: id,
              attributes: { status: InterventionActionStatusEnum.Closed },
              assigneeRedirectUrl,
              mentionRedirectUrl: getAppUrlWithDomain(
                APP_URLS.dashboard.interventions.view.getDynamicPath({
                  pathParts: { id: interventionActionId },
                }),
              ),
            },
            onCompleted: () => {
              interventionQueryHookResult.refetch();
            },
          });
        },
      });
    }
  };

  const onConfirmedInterventionActionDestroy = () => {
    const actionId =
      useModalManagementResult.isInterventionActionDestroyModalOpened?.id;
    if (actionId) {
      useModalManagementResult.closeAllModals();
      toast({
        content: t('destroy-intervention-action-success'),
        closeCallback: () => {
          useMutationsResult.interventionActionDestroyMutation({
            variables: { id: actionId, interventionId: id },
            onCompleted: () => {
              if (viewMode === InterventionViewMode.ViewActions) {
                interventionActionsQueryHookResult.refetch();
              } else if (viewMode === InterventionViewMode.ViewAction) {
                navigate(
                  APP_URLS.dashboard.interventions.view.getDynamicPath({
                    pathParts: { id },
                  }),
                );
              }
            },
          });
        },
      });
    }
  };

  const onViewChange = (newViewMode: string) => {
    if (newViewMode === InterventionViewMode.View) {
      navigate(
        APP_URLS.dashboard.interventions.view.getDynamicPath({
          pathParts: { id },
        }),
      );
    } else if (newViewMode === InterventionViewMode.ViewActions) {
      navigate(
        APP_URLS.dashboard.interventions.viewActions.getDynamicPath({
          pathParts: { id },
        }),
      );
    } else if (newViewMode === InterventionViewMode.Edit) {
      navigate(
        APP_URLS.dashboard.interventions.edit.getDynamicPath({
          pathParts: { id },
        }),
      );
    }
  };

  const onViewTask = (taskId: string) => {
    navigate(
      APP_URLS.dashboard.tasks.view.getDynamicPath({
        pathParts: {
          id: taskId,
        },
      }),
    );
  };

  const onStatusChangeHandler = (
    interventionId: string,
    newStatus: InterventionStatusEnum,
  ) => {
    if (id === interventionId && newStatus) {
      if (newStatus === InterventionStatusEnum.Closed) {
        useModalManagementResult.openInterventionCloseModal();
      } else {
        onInterventionUpdate({ status: newStatus });
      }
    }
  };

  const onActionStatusChangeHandler = (
    interventionActionId: string,
    newStatus: InterventionActionStatusEnum,
  ) => {
    if (interventionActionId && newStatus) {
      if (newStatus === InterventionActionStatusEnum.Closed) {
        useModalManagementResult.openInterventionActionCloseModal({
          id: interventionActionId,
        } as InterventionAction);
      } else {
        useMutationsResult.interventionActionUpdateMutation({
          variables: {
            id: interventionActionId,
            interventionId: id,
            attributes: { status: newStatus },
            assigneeRedirectUrl,
            mentionRedirectUrl: getAppUrlWithDomain(
              APP_URLS.dashboard.interventions.view.getDynamicPath({
                pathParts: { id: interventionActionId },
              }),
            ),
          },
          onCompleted: () => {
            toast({ content: t('update-intervention-action-success') });
          },
        });
      }
    }
  };

  const onAttachFiles = (files: Attachment[]) => {
    if (Array.isArray(files) && files.length) {
      onInterventionUpdate(
        getInterventionPreparedUpdateDataFromValues(
          { [FieldNames.AttachmentsAttached]: files },
          interventionQueryHookResult.data as Values,
        ),
      );
    }
  };

  const openCreateActionPage = () => {
    navigate(
      APP_URLS.dashboard.interventions.actionAdd.getDynamicPath({
        pathParts: { id },
      }),
    );
  };

  const openEditActionPage = (interventionAction: InterventionAction) => {
    navigate(
      APP_URLS.dashboard.interventions.actionEdit.getDynamicPath({
        pathParts: { id, actionId: interventionAction.id },
      }),
    );
  };

  const onAssetCategoryCreate = (values: Values) => {
    const attributes = getPreparedAssetCategoryDataFromValues(values);
    useMutationsResult.categoryCreateMutation({
      variables: { attributes },
      onCompleted: (response) => {
        const category = { ...response.data } as InterventionCategory;
        const categoryId = category?.id;
        const categoryName = category?.name;
        const categoryColor = category?.color;
        if (categoryId && categoryName && formRef?.current?.setValue) {
          formRef.current.setValue(
            FieldNames.Category,
            {
              value: categoryId,
              label: categoryName,
              color: categoryColor,
            },
            { shouldDirty: true, shouldValidate: true },
          );
        }
        useModalManagementResult.closeAllModals();
        toast({ content: t('create-category-success') });
      },
    });
  };

  const loading =
    interventionQueryHookResult.loading ||
    useMutationsResult.interventionCreateMutationResult.loading ||
    useMutationsResult.interventionUpdateMutationResult.loading;

  const mutationError =
    useMutationsResult.interventionCreateMutationResult.error ||
    useMutationsResult.interventionUpdateMutationResult.error;

  const {
    error: categoryCreateMutationError,
    loading: categoryCreateMutationLoading,
  } = useMutationsResult.categoryCreateMutationResult;

  const breadCrumbsData = getBreadCrumbsDataByKey({
    pathname,
    t,
    entity: interventionQueryHookResult.data || { id },
  });

  return {
    breadCrumbsData,
    categoryCreateMutationError,
    categoryCreateMutationLoading,
    id,
    interventionActionsQueryHookResult,
    interventionAssetPrefillDataQueryHookResult,
    interventionQueryHookResult,
    interventionSiteAreaPrefillDataQueryHookResult,
    interventionSitePrefillDataQueryHookResult,
    interventionTaskPrefillDataQueryHookResult,
    loading,
    mutationError,
    onActionStatusChangeHandler,
    onAssetCategoryCreate,
    onAttachFiles,
    onConfirmedInterventionActionClose,
    onConfirmedInterventionActionDestroy,
    onConfirmedInterventionClose,
    onConfirmedInterventionDestroy,
    onStatusChangeHandler,
    onSubmit,
    onViewChange,
    onViewTask,
    openCreateActionPage,
    openEditActionPage,
    taskQueryHookResult,
    usersQueryHookResult,
  };
}
