import { useLocation, useParams } from 'react-router-dom';
import _isEmpty from 'lodash/isEmpty';
import {
  useDashboardFullTaskQuery,
  useDashboardTaskFormTemplateQuery,
  useDashboardTaskQuery,
  useDashboardTaskScheduleQuery,
} from 'graphql-common';
import { APP_URLS } from 'constants/urls';

interface Props {
  isScheduleModalOpened?: boolean;
}

export default function useTaskQueries(props: Props) {
  const { isScheduleModalOpened } = props;
  const { id = '' } = useParams();
  const { pathname } = useLocation();

  const dynamicPath = { pathParts: { id } };
  const variables = id ? { id } : undefined;
  const taskQueryUrls = [
    APP_URLS.dashboard.tasks.view.getDynamicPath(dynamicPath),
    APP_URLS.dashboard.tasks.edit.getDynamicPath(dynamicPath),
  ];
  const taskQuery = useDashboardTaskQuery({
    skip: _isEmpty(id) || !(id && taskQueryUrls.includes(pathname)),
    variables,
    fetchPolicy: 'network-only',
  });

  const taskFormTemplateQueryUrls = [
    APP_URLS.dashboard.tasks.formView.getDynamicPath(dynamicPath),
  ];
  const taskFormTemplateQuery = useDashboardTaskFormTemplateQuery({
    skip: _isEmpty(id) || !(id && taskFormTemplateQueryUrls.includes(pathname)),
    variables,
    fetchPolicy: 'network-only',
  });

  const taskDuplicateQueryUrls = [
    APP_URLS.dashboard.tasks.duplicate.getDynamicPath(dynamicPath),
  ];
  const fullTaskQuery = useDashboardFullTaskQuery({
    skip: _isEmpty(id) || !(id && taskDuplicateQueryUrls.includes(pathname)),
    variables,
    fetchPolicy: 'network-only',
  });

  const taskScheduleQuery = useDashboardTaskScheduleQuery({
    skip: _isEmpty(id) || !isScheduleModalOpened,
    variables,
    fetchPolicy: 'no-cache',
  });

  return {
    taskQuery,
    taskFormTemplateQuery,
    fullTaskQuery,
    taskScheduleQuery,
  };
}
