import { TaskScopeNameEnum } from 'graphql-common';

export default function getEmptyStateTextKeys(tab?: string) {
  switch (tab) {
    case TaskScopeNameEnum.TimelineFailure:
      return ['no-tasks-failure-yet', 'no-tasks-failure-text'];
    case TaskScopeNameEnum.TimelineWarning:
      return ['no-tasks-warning-yet', 'no-tasks-warning-text'];
    case TaskScopeNameEnum.TimelineSuccess:
      return ['no-tasks-success-yet', 'no-tasks-success-text'];
    default:
      return ['no-tasks-yet', 'no-tasks-text'];
  }
}
