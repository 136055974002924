import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import BreadCrumbs from '@lib/components/BreadCrumbs/BreadCrumbs';
import { getBreadCrumbsDataByKey } from 'utils/breadcrumbs';
import FormTemplateBuilderView from 'components/FormTemplateBuilder/FormTemplateBuilderView/FormTemplateBuilderView';
import useSelectedVersionOption from './utils/useSelectedVersionOption';
import FormTemplateModals from './FormTemplateModals';
import { FormTemplateMode } from './enums';
import { ModalManagementResult } from './utils/useModalManagement';
import { UseActionsResults } from './utils/useActions';

interface Props {
  useActionsResult: UseActionsResults;
  useModalManagementResult: ModalManagementResult;
  viewMode: FormTemplateMode;
  isViewOnly: boolean;
}

export default function FormTemplateDetailsViewContainer(props: Props) {
  const { useActionsResult, useModalManagementResult, viewMode, isViewOnly } =
    props;
  const { id = '', versionId = '' } = useParams();
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const { openDestroyModal, openVersionDestroyModal } =
    useModalManagementResult;

  const {
    formTemplateData,
    formTemplateError,
    formTemplateLoading,
    formTemplateVersionData,
    loadingState,
    onCreateNewFormTemplateVersion,
    onCreateTaskHandler,
    onDuplicateHandler,
    onDuplicateVersionHandler,
    onEditHandler,
    onEditVersionHandler,
    onFormTemplateArchiveHandler,
    onFormTemplateBulkRestoreHandler,
    onFormTemplateVersionArchiveHandler,
    onFormTemplateVersionRestoreHandler,
    onViewFormTemplateVersion,
  } = useActionsResult;

  const selectedVersionOption = useSelectedVersionOption({
    formTemplateVersionData,
    t,
    versionId,
    viewMode,
  });

  const breadCrumbsData = getBreadCrumbsDataByKey({
    entity: {
      versionId: formTemplateData.versionId || versionId,
      id: formTemplateData.id || id,
      name: formTemplateData.name,
    },
    pathname,
    t,
  });

  return (
    <>
      <BreadCrumbs data={breadCrumbsData} />
      <FormTemplateBuilderView
        selectedVersionOption={selectedVersionOption}
        formTemplateId={id}
        formTemplateData={formTemplateData}
        formTemplateError={formTemplateError}
        formTemplateLoading={formTemplateLoading}
        formTemplateVersionData={formTemplateVersionData}
        loadingState={loadingState}
        onFormTemplateVersionRestoreHandler={
          onFormTemplateVersionRestoreHandler
        }
        onCreateNewFormTemplateVersion={onCreateNewFormTemplateVersion}
        onCreateTaskHandler={onCreateTaskHandler}
        onDuplicateHandler={onDuplicateHandler}
        onEditHandler={onEditHandler}
        onEditVersionHandler={onEditVersionHandler}
        onFormTemplateArchiveHandler={onFormTemplateArchiveHandler}
        onFormTemplateVersionArchiveHandler={
          onFormTemplateVersionArchiveHandler
        }
        onDuplicateVersionHandler={onDuplicateVersionHandler}
        onViewFormTemplateVersion={onViewFormTemplateVersion}
        openDestroyModal={openDestroyModal}
        openVersionDestroyModal={openVersionDestroyModal}
        onFormTemplateBulkRestoreHandler={onFormTemplateBulkRestoreHandler}
        isViewOnly={isViewOnly}
      />
      <FormTemplateModals
        useActionsResult={useActionsResult}
        useModalManagementResult={useModalManagementResult}
      />
    </>
  );
}
