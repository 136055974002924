import { TFunction } from 'i18next';
import { Counter } from 'graphql-common';
import { NavItem, NavItemTypeEnum } from '@lib/components/Menu/Menu';
import Icons from '@lib/components/Icons/Icons';

type ActionsMenuArgs = {
  onAddIndex?: (v?: Counter) => void;
  onDelete?: (v?: Counter) => void;
  onEdit?: (v?: Counter) => void;
  t: TFunction<'translation', undefined>;
};

export default function getTableActionsMenu({
  onAddIndex,
  onDelete,
  onEdit,
  t,
}: ActionsMenuArgs) {
  const result: NavItem<Counter>[] = [];
  if (onEdit) {
    result.push({
      id: 'edit-action',
      title: t('edit'),
      onClick: (item?: Counter) => onEdit(item),
      leftIcon: Icons.edit,
      type: NavItemTypeEnum.Secondary,
    });
  }
  if (onAddIndex) {
    result.push({
      id: 'add-index',
      title: t('add-index'),
      onClick: (item?: Counter) => onAddIndex(item),
      leftIcon: Icons.add,
      type: NavItemTypeEnum.Secondary,
    });
  }
  if (onDelete) {
    result.push({
      id: 'delete-action',
      title: t('delete'),
      onClick: (item?: Counter) => onDelete(item),
      leftIcon: Icons.delete,
      type: NavItemTypeEnum.Secondary,
    });
  }
  return result;
}
