import React from 'react';
import { TFunction } from 'i18next';
import * as Yup from 'yup';
import _get from 'lodash/get';
import { getDefaultNumberValidation, getOptionShape } from '@lib/utils/yup';
import Input from '@lib/components/Input/Input';
import { FieldChangeCallBackArgs, FieldItem } from '@lib/interfaces/form';
import Select, {
  SelectComponents,
  SelectProps,
} from '@lib/components/Select/Select';
import AssetOption from '@lib/components/Select/components/AssetOption';
import AssetSingleValue from '@lib/components/Select/components/AssetSingleValue';
import styles from '@lib/assets/styles/Form.module.scss';
import AssetNomenclatureFieldNames from '@lib/enums/fieldNames/assetNomenclatureFieldNames';
import { GetLoadOptions } from '@lib/components/Select/types';
import { DEFAULT_NUMBER_INPUT_PROPS, MAX_NUMBER_VALUE } from '@lib/enums/form';
import SupportiveMessage, {
  SupportiveMessageColor,
  SupportiveMessageIconColor,
  SupportiveMessageType,
} from '@lib/components/SupportiveMessage/SupportiveMessage';

type Args = {
  getAssetsLoadOptions: GetLoadOptions;
  isEditForm?: boolean;
  t: TFunction<'translation', undefined>;
};

export default function getFormFields({
  getAssetsLoadOptions,
  isEditForm,
  t,
}: Args): FieldItem[] {
  return [
    {
      name: AssetNomenclatureFieldNames.ComponentAsset,
      label: t('asset'),
      element: Select,
      validation: isEditForm
        ? Yup.object().shape(getOptionShape(t)).nullable()
        : Yup.object()
            .shape(getOptionShape(t, true))
            .required(t('required-field-error')),
      componentProps: {
        isAsync: true,
        getLoadOptions: getAssetsLoadOptions,
        defaultOptions: false,
        components: {
          Option: AssetOption,
          SingleValue: AssetSingleValue,
        } as unknown as SelectComponents,
        disabled: isEditForm,
        usePortal: true,
      } as SelectProps,
      formItemClassName: styles.formColumn8,
      fieldChangeCallBack: ({
        type,
        values,
        setValue,
        name,
      }: FieldChangeCallBackArgs) => {
        if (
          type === 'change' &&
          name === AssetNomenclatureFieldNames.ComponentAsset &&
          !_get(values, AssetNomenclatureFieldNames.Quantity)
        ) {
          setValue(AssetNomenclatureFieldNames.Quantity, 1);
        }
      },
    },
    {
      name: AssetNomenclatureFieldNames.Quantity,
      label: t('quantity'),
      element: Input,
      componentProps: { ...DEFAULT_NUMBER_INPUT_PROPS },
      formItemClassName: styles.formColumn4,
      validation: getDefaultNumberValidation(t, true, MAX_NUMBER_VALUE),
    },
    {
      name: `${AssetNomenclatureFieldNames.ComponentAsset}-text`,
      customRender: () => {
        return (
          <SupportiveMessage
            text={t('asset-as-nomenclature-text')}
            leftIcon="warning"
            color={SupportiveMessageColor.Yellow}
            iconColor={SupportiveMessageIconColor.Inner}
            type={SupportiveMessageType.Transparent}
            hasBottomSpace={false}
          />
        );
      },
      isVisibleFn: (values) =>
        _get(
          values,
          [
            AssetNomenclatureFieldNames.ComponentAsset,
            'data',
            'usesAsNomenclatureCount',
          ],
          false,
        ),
    },
  ];
}
