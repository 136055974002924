import React from 'react';
import { useTranslation } from 'react-i18next';
import _get from 'lodash/get';
import MessageBox from '@lib/components/SupportiveMessage/SupportiveMessageBox';
import FormItem from '@lib/components/ReactHookForm/FormItem';
import FormTemplateFieldNames from '@lib/enums/fieldNames/formTemplateFieldNames';
import CheckBoxSingleBool from '@lib/components/CheckBoxSingleBool/CheckBoxSingleBool';
import Select, { SelectProps } from '@lib/components/Select/Select';
import { Values } from '@lib/interfaces/form';
import { GetLoadOptions } from '@lib/components/Select/types';
import TaskWithFormTemplateVersionOption from '@lib/components/Select/components/TaskWithFormTemplateVersionOption';

type Props = {
  getTasksLoadOptions: GetLoadOptions;
  values: Values;
};

export default function FormTemplateTaskAssignField(props: Props) {
  const { getTasksLoadOptions, values } = props;
  const { t } = useTranslation();

  return (
    <MessageBox hasBottomSpace={false}>
      <FormItem
        name={`${FormTemplateFieldNames.Tasks}-enable`}
        element={CheckBoxSingleBool}
        componentProps={{
          label: t('update-tasks-form-template-version-label'),
          options: [{ label: 'checked', value: 'checked' }],
          fullWidth: false,
        }}
        hideErrorsSpace={
          !_get(values, `${FormTemplateFieldNames.Tasks}-enable`, false)
        }
      />
      <FormItem
        name={FormTemplateFieldNames.Tasks}
        element={Select}
        label={t('select-tasks')}
        isVisibleFn={(formValues: Values) =>
          _get(
            formValues,
            [`${FormTemplateFieldNames.Tasks}-enable`],
            false,
          ) as boolean
        }
        componentProps={
          {
            isAsync: true,
            isMulti: true,
            getLoadOptions: getTasksLoadOptions,
            defaultOptions: true,
            components: {
              Option: TaskWithFormTemplateVersionOption,
            },
          } as SelectProps
        }
      />
    </MessageBox>
  );
}
