const getUniqueMentionIds = (htmlString) => {
  const mentionRegex = /data-id="([^"]+)"/g;
  const mentionIds: string[] = [];
  let match;
  // eslint-disable-next-line no-cond-assign
  while ((match = mentionRegex.exec(htmlString)) !== null) {
    // Wrap assignment in parentheses
    mentionIds.push(match[1]);
  }
  return [...new Set(mentionIds)];
};

export default getUniqueMentionIds;
