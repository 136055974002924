import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Site } from 'graphql-common';
import Typography from '@lib/components/Typography/Typography';
import Button, {
  ButtonSizes,
  ButtonTypes,
} from '@lib/components/Button/Button';
import MaterialIcon from '@lib/components/MaterialIcon/MaterialIcon';
import LinkComponent, { LinkTypes } from '@lib/components/Link/Link';
import { APP_URLS } from 'constants/urls';
import IconButton, {
  IconButtonTypes,
} from '@lib/components/IconButton/IconButton';
import {
  getFormattedDate,
  getLastTimeFromArray,
} from '@lib/utils/dateTimeHelpers';
import ClipboardCopy from '@lib/components/ClipboardCopy/ClipboardCopy';
import Image from '@lib/components/Image/Image';
import { materialSymbolsOutlined } from '@lib/enums/common';
import WhiteBox from '@lib/components/WhiteBox/WhiteBox';
import UserChip from '@lib/components/UserChip/UserChip';
import DashboardTitleHeader from '@lib/layouts/DashboardLayout/DashboardTitleHeader/DashboardTitleHeader';
import ViewOnlyChip from '@lib/components/ViewOnlyChip/ViewOnlyChip';
import TextEditor from '@lib/components/TextEditor/TextEditor';
import styles from './SiteView.module.scss';

interface Props {
  onEditCodeOfConduct: () => void;
  onViewCodeOfConduct: () => void;
  siteData: Site;
  isViewOnly: boolean;
}

function SiteView(props: Props) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { onViewCodeOfConduct, onEditCodeOfConduct, siteData, isViewOnly } =
    props;
  const {
    address,
    code,
    description,
    email,
    id,
    locationPhotoAttached,
    name,
    phoneNumber,
    siteConductCodeSections = [],
    user,
  } = siteData || {};
  const siteConductCodeSectionsUpdatedAt = getFormattedDate(
    getLastTimeFromArray(
      siteConductCodeSections.map(({ updatedAt }) => updatedAt as string),
    ),
  );

  const locationPhotoUrl =
    locationPhotoAttached?.urls?.large || locationPhotoAttached?.urls?.original;

  const onEditHandler = () =>
    navigate(
      APP_URLS.dashboard.sites.edit.getDynamicPath({
        pathParts: {
          id: `${id}`,
        },
      }),
    );

  return (
    <WhiteBox>
      <DashboardTitleHeader
        title={name}
        actions={
          isViewOnly ? (
            <ViewOnlyChip key="view" />
          ) : (
            <Button
              buttonText={t('edit')}
              buttonType={ButtonTypes.primaryFilled}
              onClick={onEditHandler}
              leftIcon="edit"
              leftIconClassName={materialSymbolsOutlined}
            />
          )
        }
      />
      <div className={styles.infoList}>
        <div className={styles.infoItem}>
          <p className={styles.infoItemLabel}>
            <MaterialIcon icon="password" symbolsOutlined />
            <Typography variant="caption">{t('site-code')}</Typography>
          </p>
          <div className={styles.infoItemContent}>
            <Typography variant="body">{code || t('no-code')}</Typography>
          </div>
        </div>
        <div className={styles.infoItem}>
          <p className={styles.infoItemLabel}>
            <MaterialIcon icon="call" symbolsOutlined />
            <Typography variant="caption">{t('phone-number')}</Typography>
          </p>
          <div className={styles.infoItemContent}>
            <Typography variant="body">
              {phoneNumber?.international || '-'}
            </Typography>
          </div>
        </div>
        <div className={styles.infoItem}>
          <p className={styles.infoItemLabel}>
            <MaterialIcon icon="mail" symbolsOutlined />
            <Typography variant="caption">{t('email')}</Typography>
          </p>
          <div className={styles.infoItemContent}>
            <LinkComponent type={LinkTypes.secondaryGray}>
              {email}
            </LinkComponent>
          </div>
        </div>
        <div className={styles.infoItem}>
          <p className={styles.infoItemLabel}>
            <MaterialIcon icon="location_on" symbolsOutlined />
            <Typography variant="caption">{t('site-address')}</Typography>
          </p>
          <div className={styles.infoItemContent}>
            <Typography variant="body">{address?.string || '-'}</Typography>
          </div>
        </div>
        <div className={styles.infoItem}>
          <p className={styles.infoItemLabel}>
            <MaterialIcon icon="language" symbolsOutlined />
            <Typography variant="caption">{t('gps-coordinates')}</Typography>
          </p>
          <div className={styles.infoItemContent}>
            {address?.lat && address?.lon ? (
              <ClipboardCopy copyText={`${address?.lat} ${address?.lon}`}>
                <Typography variant="body">
                  {address?.lat} {address?.lon}
                </Typography>
              </ClipboardCopy>
            ) : (
              <Typography variant="body">-</Typography>
            )}
          </div>
        </div>
        {!!description && (
          <>
            <div className={styles.infoItem}>
              <p className={styles.infoItemLabel}>
                <MaterialIcon icon="description" symbolsOutlined />
                <Typography variant="caption">{t('description')}</Typography>
              </p>
            </div>
            <TextEditor
              readOnly
              value={description}
              className={styles.description}
            />
          </>
        )}
      </div>
      <div className={styles.infoList}>
        <Typography variant="h3" className={styles.infoListTitle}>
          {t('responsible-person')}
        </Typography>
        <div className={styles.infoItem}>
          <p className={styles.infoItemLabel}>
            <MaterialIcon icon="person" symbolsOutlined />
            <Typography variant="caption">{t('name')}</Typography>
          </p>
          <div className={styles.infoItemContent}>
            {user ? (
              <UserChip user={user} />
            ) : (
              <Typography variant="body">-</Typography>
            )}
          </div>
        </div>
        <div className={styles.infoItem}>
          <p className={styles.infoItemLabel}>
            <MaterialIcon icon="call" symbolsOutlined />
            <Typography variant="caption">{t('phone-number')}</Typography>
          </p>
          <div className={styles.infoItemContent}>
            <Typography variant="body">
              {user?.phoneNumber?.international || '-'}
            </Typography>
          </div>
        </div>
        <div className={styles.infoItem}>
          <p className={styles.infoItemLabel}>
            <MaterialIcon icon="mail" symbolsOutlined />
            <Typography variant="caption">{t('email')}</Typography>
          </p>
          <div className={styles.infoItemContent}>
            {user?.email ? (
              <LinkComponent type={LinkTypes.secondaryGray}>
                {user.email}
              </LinkComponent>
            ) : (
              <Typography variant="body">-</Typography>
            )}
          </div>
        </div>
      </div>
      {locationPhotoUrl && (
        <div className={styles.locationPhoto}>
          <Image src={locationPhotoUrl} alt={name} />
        </div>
      )}
      {siteConductCodeSections.length > 0 && (
        <div className={styles.conductCodeSections}>
          <div className={styles.conductCodeSectionsIcon}>
            <MaterialIcon icon="attach_file" />
          </div>
          <div>
            <Typography variant="body">{t('code-of-conduct')}</Typography>
            <Typography
              variant="caption"
              className={styles.conductCodeSectionsDate}
            >
              {t('updated-on')} {siteConductCodeSectionsUpdatedAt}
            </Typography>
          </div>
          <div className={styles.conductCodeSectionsActions}>
            <IconButton
              icon="visibility"
              onClick={onViewCodeOfConduct}
              symbolsOutlined
              type={IconButtonTypes.PrimaryOutlined}
            />
            {!isViewOnly && (
              <IconButton
                icon="edit"
                onClick={onEditCodeOfConduct}
                symbolsOutlined
                type={IconButtonTypes.PrimaryOutlined}
              />
            )}
          </div>
        </div>
      )}
      {siteConductCodeSections.length === 0 && !isViewOnly && (
        <div className={styles.conductCodeAddBtn}>
          <Button
            buttonText={t('add-code-of-conduct')}
            buttonType={ButtonTypes.primaryOutlined}
            buttonSize={ButtonSizes.small}
            leftIcon="add"
            onClick={onEditCodeOfConduct}
          />
        </div>
      )}
    </WhiteBox>
  );
}

export default SiteView;
