import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import _get from 'lodash/get';
import { ApolloError } from '@apollo/client/errors';
import toast from '@lib/components/Toast/Toast';
import { APP_URLS } from 'constants/urls';
import {
  FormCategory,
  FormTemplateCreateInputObject,
  FormTemplateVersionStatusEnum,
  Task,
  useFormCategoryCreateMutation,
} from 'graphql-common';
import { Values } from '@lib/interfaces/form';
import { showGraphQLErrors } from '@lib/utils/graphQLErrors';
import removeIdFields from '@lib/utils/removeIdsFromObject';
import useSearchParam from '@lib/hooks/useSearchParam';
import usePaginationParams from '@lib/hooks/usePaginationParams';
import {
  UseBulkSelectResult,
  useTableItemsBulkSelect,
} from '@lib/hooks/useTableItemsBulkSelect';
import useLoadingState from '@lib/hooks/useLoadingState';
import useFormTemplateTasksQueryHook, {
  FormTemplateTasksQueryHookResult,
} from 'utils/fetch/useFormTemplateTasksQueryHook';
import { ModalManagementResult } from 'routes/FormTemplate/utils/useModalManagement';
import FormTemplateFieldNames from '@lib/enums/fieldNames/formTemplateFieldNames';
import useFilterParams from '@lib/hooks/useFilterParams';
import { GetUrlParams } from '@lib/enums/urls';
import tableItemsBulkAction from '@lib/utils/tableItemsBulkAction';
import getAllTableItemsIds from '@lib/utils/getAllTableItemsIds';
import { NEW } from '@lib/enums/common';
import useTemplateVersionMutations from 'routes/FormTemplate/utils/useTemplateVersionMutations';
import useTemplateMutations from 'routes/FormTemplate/utils/useTemplateMutations';
import useFormTemplateVersionQueryHook from 'utils/fetch/useFormTemplateVersionQueryHook';
import useFormTemplateQueryHook from 'utils/fetch/useFormTemplateQueryHook';
import { FormRefType } from '@lib/components/ReactHookForm/types';
import getPreparedFormCategoryDataFromValues from './getPreparedFormCategoryDataFromValues';
import getPreparedFormTemplateDataFromValues, {
  getPreparedFormTemplateVersionDataFromValues,
} from './getPreparedFormTemplateDataFromValues';
import { FormTemplateMode } from '../enums';

interface UseActionsProps {
  formRef: FormRefType;
  id?: string;
  versionId?: string;
  viewMode: FormTemplateMode;
  useModalManagementResult: ModalManagementResult;
}

export interface UseActionsResults {
  formCategoryCreateMutationError?: ApolloError;
  formCategoryCreateMutationLoading: boolean;
  formTemplateCreateMutationLoading: boolean;
  formTemplateData: Values;
  formTemplateError?: ApolloError;
  formTemplateGraphQLErrors?: ApolloError;
  formTemplateLoading: boolean;
  formTemplateUpdateMutationLoading: boolean;
  formTemplateVersionData: Values;
  formTemplateVersionLoading: boolean;
  loadingState: boolean;
  onCancelForm: () => void;
  onConfirmedDestroy: () => void;
  onConfirmedVersionDestroy: () => void;
  onCreateNewFormTemplateVersion: () => void;
  onCreateTaskHandler: () => void;
  onDuplicateHandler: () => void;
  onDuplicateVersionHandler: () => void;
  onEditHandler: () => void;
  onEditVersionHandler: () => void;
  onFormCategoryCreate: (values: Values) => void;
  onFormTemplateArchiveHandler: () => void;
  onFormTemplateBulkRestoreHandler: () => void;
  onFormTemplateConfirmedArchive: () => void;
  onFormTemplateVersionArchiveHandler: () => void;
  onFormTemplateVersionConfirmedArchive: () => void;
  onFormTemplateVersionRestoreHandler: () => void;
  onSubmitForm: (values: Values) => void;
  onTaskFormTemplateVersionBulkChange: (
    newFormTemplateVersionId: string,
  ) => void;
  onTaskFormTemplateVersionChange: (
    taskId: string,
    newFormTemplateVersionId: string,
  ) => void;
  onVersionFieldChangeCallBack: (
    newVersionId?: string,
    newVersionStatus?: string,
  ) => void;
  onViewFormTemplateVersion: (versionIdSrt: string) => void;
  onViewHandler: () => void;
  tasksQueryHook: FormTemplateTasksQueryHookResult;
  useBulkSelectResult: UseBulkSelectResult<Task>;
}

export default function useActions(props: UseActionsProps): UseActionsResults {
  const {
    formRef,
    id = '',
    versionId = '',
    viewMode,
    useModalManagementResult,
  } = props;
  const {
    closeArchiveModal,
    closeCategoryFormModal,
    closeDestroyModal,
    closeVersionArchiveModal,
    closeVersionDestroyModal,
    openArchiveModal,
    openVersionArchiveModal,
  } = useModalManagementResult;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchQuery] = useSearchParam();
  const [filterParams] = useFilterParams();
  const [paginationParams] = usePaginationParams();
  const useLoadingStateResult = useLoadingState();
  const { loadingState, setLoadingState } = useLoadingStateResult;
  const useBulkSelectResult = useTableItemsBulkSelect<Task>();

  const duplicatedVersionId = _get(
    filterParams,
    GetUrlParams.DuplicatedVersionId,
    '',
  ) as string;

  // Form Template query
  const useFormTemplateQueryHookResult = useFormTemplateQueryHook({
    id,
    viewMode,
  });
  const {
    formTemplateData,
    formTemplateError,
    formTemplateLoading,
    formTemplateRefetch,
  } = useFormTemplateQueryHookResult;

  const formTemplateRefetchHandler = () => {
    if (formTemplateRefetch) {
      formTemplateRefetch().finally(() => {
        setLoadingState(false);
      });
    } else {
      setLoadingState(false);
    }
  };

  // Form Template Version
  const formTemplateVersionQueryHook = useFormTemplateVersionQueryHook({
    id,
    versionId,
    duplicatedVersionId,
    viewMode,
  });
  const {
    formTemplateVersion,
    formTemplateVersionData,
    formTemplateVersionDataStatus,
    formTemplateVersionError,
    formTemplateVersionLoading,
    formTemplateVersionRefetch,
  } = formTemplateVersionQueryHook;

  // Linked tasks
  const tasksQueryHook = useFormTemplateTasksQueryHook({
    searchQuery,
    paginationParams,
    customScope: {
      groupedLinkedToFormTemplate: { templateId: id },
    },
    skip: !versionId || viewMode !== FormTemplateMode.ViewLinkedTasks,
  });

  // Form Template mutations
  const useTemplateMutationsResult = useTemplateMutations({
    formTemplateRefetchHandler,
    formTemplateVersionRefetch,
    useLoadingStateResult,
  });
  const {
    formTemplateCreateMutation,
    formTemplateCreateMutationResult,
    formTemplateUpdateMutation,
    formTemplateUpdateMutationResult,
    formTemplateBulkArchiveMutation,
    formTemplateBulkRestoreMutation,
    destroyMutation,
  } = useTemplateMutationsResult;

  // Form Template Version mutations
  const useTemplateVersionMutationsResult = useTemplateVersionMutations({
    formTemplateRefetch,
    formTemplateRefetchHandler,
    id,
    tasksQueryHook,
    useBulkSelectResult,
    useLoadingStateResult,
    versionId,
  });
  const {
    formTemplateVersionArchiveMutation,
    formTemplateVersionCreateMutation,
    formTemplateVersionCreateMutationResult,
    formTemplateVersionDestroyMutation,
    formTemplateVersionRestoreMutation,
    formTemplateVersionUpdateMutation,
    formTemplateVersionUpdateMutationResult,
    taskBulkTemplateVersionUpdateMutation,
  } = useTemplateVersionMutationsResult;

  // Form category
  const [formCategoryCreateMutation, formCategoryCreateMutationResult] =
    useFormCategoryCreateMutation({
      onCompleted: (response) => {
        const category = { ...response.data } as FormCategory;
        const categoryId = category?.id;
        const categoryName = category?.name;
        const categoryColor = category?.color;
        if (categoryId && categoryName && formRef?.current?.setValue) {
          formRef.current.setValue(
            'category',
            {
              value: categoryId,
              label: categoryName,
              color: categoryColor,
            },
            { shouldDirty: true, shouldValidate: true },
          );
        }
        closeCategoryFormModal();
        toast({ content: t('create-form-template-category-success') });
      },
      onError: (error) => showGraphQLErrors(error, t),
    });

  // Actions
  const onFormTemplateCreate = (values: Values) => {
    const attributes = getPreparedFormTemplateDataFromValues({
      values,
      prevValues: formTemplateData as Values,
      t,
      isCreate: true,
    }) as FormTemplateCreateInputObject;
    formTemplateCreateMutation({ variables: { attributes } });
  };

  const onFormTemplateVersionCreate = (values: Values) => {
    const attributes = getPreparedFormTemplateVersionDataFromValues({
      values: removeIdFields(values, ['attachmentsAttached']),
      prevValues: {},
      t,
    });
    formTemplateVersionCreateMutation({
      variables: { attributes, formTemplateId: id },
    });
  };

  const onFormTemplateUpdate = (values: Values) => {
    if (id) {
      const attributes = getPreparedFormTemplateDataFromValues({
        values,
        prevValues: formTemplateData as Values,
        t,
      });
      formTemplateUpdateMutation({ variables: { attributes, id } });
    }
  };

  const onFormTemplateVersionUpdate = (values: Values) => {
    if (id) {
      const attributes = getPreparedFormTemplateVersionDataFromValues({
        values,
        prevValues: formTemplateVersionData as Values,
        t,
      });
      formTemplateVersionUpdateMutation({
        variables: {
          attributes,
          id: versionId,
          formTemplateId: id,
        },
      });
    }
  };

  const onFormTemplateDuplicate = (values: Values) => {
    if (id) {
      const attributes = getPreparedFormTemplateDataFromValues({
        isCreate: true,
        prevValues: {},
        t,
        values: removeIdFields(values, ['attachmentsAttached']),
      }) as FormTemplateCreateInputObject;
      formTemplateCreateMutation({ variables: { attributes } });
    }
  };

  const onFormCategoryCreate = (values: Values) => {
    const attributes = getPreparedFormCategoryDataFromValues(values);
    formCategoryCreateMutation({ variables: { attributes } });
  };

  const onDuplicateHandler = () => {
    if (id && versionId) {
      navigate(
        APP_URLS.dashboard.formTemplates.duplicate.getDynamicPath({
          pathParts: { id, versionId },
        }),
      );
    }
  };

  const onDuplicateVersionHandler = () => {
    if (id && versionId) {
      navigate(
        APP_URLS.dashboard.formTemplates.editVersion.getDynamicPath({
          pathParts: { id, versionId: NEW },
          params: { [GetUrlParams.DuplicatedVersionId]: versionId },
        }),
      );
    }
  };

  const onConfirmedDestroy = () => {
    closeDestroyModal();
    toast({
      content: t('destroy-form-template-success'),
      closeCallback: () => {
        if (id) {
          destroyMutation({
            variables: { ids: [id] },
          });
        }
      },
    });
  };

  const onConfirmedVersionDestroy = () => {
    closeVersionDestroyModal();
    toast({
      content: t('destroy-form-template-version-success'),
      closeCallback: () => {
        if (id) {
          setLoadingState(true);
          formTemplateVersionDestroyMutation({
            variables: { id: versionId, formTemplateId: id },
          });
        }
      },
    });
  };

  const onFormTemplateArchiveHandler = () => {
    openArchiveModal();
  };

  const onFormTemplateVersionArchiveHandler = () => {
    openVersionArchiveModal();
  };

  const onFormTemplateConfirmedArchive = () => {
    closeArchiveModal();
    toast({
      content: t('archive-form-template-success'),
      closeCallback: () => {
        if (id && versionId) {
          setLoadingState(true);
          formTemplateBulkArchiveMutation({
            variables: {
              ids: [id],
            },
          });
        }
      },
    });
  };

  const onFormTemplateVersionConfirmedArchive = () => {
    closeVersionArchiveModal();
    toast({
      content: t('archive-form-template-version-success'),
      closeCallback: () => {
        if (id && versionId) {
          setLoadingState(true);
          formTemplateVersionArchiveMutation({
            variables: {
              id: versionId,
              formTemplateId: id,
            },
          });
        }
      },
    });
  };

  const onFormTemplateVersionRestoreHandler = () => {
    if (id && versionId) {
      setLoadingState(true);
      formTemplateVersionRestoreMutation({
        variables: {
          id: versionId,
          formTemplateId: id,
        },
      });
    }
  };

  const onFormTemplateBulkRestoreHandler = () => {
    if (id) {
      setLoadingState(true);
      formTemplateBulkRestoreMutation({
        variables: {
          ids: [id],
        },
      });
    }
  };

  const onViewHandler = () => {
    const formTemplateVersionId =
      versionId || _get(formTemplateData, ['currentVersion', 'id']);
    if (id && formTemplateVersionId) {
      navigate(
        APP_URLS.dashboard.formTemplates.viewVersion.getDynamicPath({
          pathParts: {
            id,
            versionId:
              formTemplateVersionId === NEW
                ? (_get(formTemplateData, [
                    FormTemplateFieldNames.CurrentVersion,
                    'id',
                  ]) as string)
                : formTemplateVersionId,
          },
        }),
      );
    }
  };

  const onEditHandler = () => {
    if (id) {
      navigate(
        APP_URLS.dashboard.formTemplates.edit.getDynamicPath({
          pathParts: {
            id,
          },
        }),
      );
    }
  };

  const onEditVersionHandler = () => {
    if (id) {
      navigate(
        APP_URLS.dashboard.formTemplates.editVersion.getDynamicPath({
          pathParts: {
            id,
            versionId,
          },
        }),
      );
    }
  };

  const onSubmitForm = (values: Values) => {
    if (viewMode === FormTemplateMode.Add) {
      onFormTemplateCreate(values);
    } else if (viewMode === FormTemplateMode.Edit && !versionId) {
      onFormTemplateUpdate(values);
    } else if (viewMode === FormTemplateMode.EditVersion && versionId === NEW) {
      onFormTemplateVersionCreate(values);
    } else if (
      viewMode === FormTemplateMode.EditVersion &&
      versionId &&
      versionId !== NEW
    ) {
      onFormTemplateVersionUpdate(values);
    } else if (viewMode === FormTemplateMode.Duplicate) {
      onFormTemplateDuplicate(values);
    }
  };

  const onCancelForm = () => {
    if (viewMode === FormTemplateMode.Add) {
      navigate(APP_URLS.dashboard.formTemplates.index.getPathWithQuery());
    } else {
      onViewHandler();
    }
  };

  const onCreateTaskHandler = () => {
    navigate(
      APP_URLS.dashboard.tasks.add.getPathWithQuery({
        formTemplateId: formTemplateData.id,
        formTemplateName: formTemplateData.name,
        formTemplateVersionId: formTemplateVersion.id,
        formTemplateVersionName: formTemplateVersion.version,
      }),
    );
  };

  const onViewFormTemplateVersion = (versionIdSrt: string) => {
    navigate(
      APP_URLS.dashboard.formTemplates.viewVersion.getDynamicPath({
        pathParts: {
          id,
          versionId: versionIdSrt,
        },
      }),
    );
  };

  const onVersionFieldChangeCallBack = (
    newVersionId?: string,
    newVersionStatus?: string,
  ) => {
    if (newVersionId === NEW) {
      navigate(
        APP_URLS.dashboard.formTemplates.editVersion.getDynamicPath({
          pathParts: {
            id,
            versionId: NEW,
          },
        }),
      );
    } else if (newVersionId && viewMode === FormTemplateMode.Duplicate) {
      navigate(
        APP_URLS.dashboard.formTemplates.duplicate.getDynamicPath({
          pathParts: {
            id,
            versionId: newVersionId,
          },
        }),
      );
    } else if (
      newVersionId &&
      (newVersionStatus === FormTemplateVersionStatusEnum.Active ||
        newVersionStatus === FormTemplateVersionStatusEnum.Unused)
    ) {
      navigate(
        APP_URLS.dashboard.formTemplates.editVersion.getDynamicPath({
          pathParts: {
            id,
            versionId: newVersionId,
          },
        }),
      );
    } else if (
      newVersionId &&
      newVersionStatus === FormTemplateVersionStatusEnum.Archived
    ) {
      navigate(
        APP_URLS.dashboard.formTemplates.viewVersion.getDynamicPath({
          pathParts: {
            id,
            versionId: newVersionId,
          },
        }),
        { replace: true },
      );
    }
  };

  const onCreateNewFormTemplateVersion = () => {
    navigate(
      APP_URLS.dashboard.formTemplates.editVersion.getDynamicPath({
        pathParts: {
          id,
          versionId: NEW,
        },
      }),
    );
  };

  const onTaskFormTemplateVersionChange = (
    taskId: string,
    newFormTemplateVersionId: string,
  ) => {
    setLoadingState(true);
    taskBulkTemplateVersionUpdateMutation({
      variables: {
        input: {
          ids: [taskId],
          templateId: id,
          attributes: { templateVersionId: newFormTemplateVersionId },
        },
      },
    });
  };

  const onTaskFormTemplateVersionBulkChange = (
    newFormTemplateVersionId: string,
  ) => {
    setLoadingState(true);
    tableItemsBulkAction<Task>({
      action: (idsValue?: string[]) => {
        if (idsValue) {
          taskBulkTemplateVersionUpdateMutation({
            variables: {
              input: {
                ids: idsValue,
                templateId: id,
                attributes: { templateVersionId: newFormTemplateVersionId },
              },
            },
          });
        }
      },
      useBulkSelectResult,
      onGetAllIds: () =>
        getAllTableItemsIds<Task>({
          fetchIds: tasksQueryHook.fetchTaskIds,
          useBulkSelectResult,
        }),
    });
  };

  const formTemplateCreateMutationError =
    formTemplateCreateMutationResult?.error;
  const formTemplateCreateMutationLoading =
    formTemplateCreateMutationResult?.loading;
  const formTemplateVersionCreateMutationError =
    formTemplateVersionCreateMutationResult?.error;
  // const formTemplateVersionCreateMutationLoading = formTemplateVersionCreateMutationResult?.loading;
  const formTemplateUpdateMutationError =
    formTemplateUpdateMutationResult?.error;
  const formTemplateUpdateMutationLoading =
    formTemplateUpdateMutationResult?.loading;
  const formTemplateVersionUpdateMutationError =
    formTemplateVersionUpdateMutationResult?.error;
  // const formTemplateVersionUpdateMutationLoading = formTemplateVersionUpdateMutationResult?.loading;

  const {
    error: formCategoryCreateMutationError,
    loading: formCategoryCreateMutationLoading,
  } = formCategoryCreateMutationResult;
  const formTemplateGraphQLErrors =
    formTemplateCreateMutationError ||
    formTemplateUpdateMutationError ||
    formTemplateVersionCreateMutationError ||
    formTemplateVersionUpdateMutationError;

  useEffect(() => {
    if (
      formTemplateVersionDataStatus === FormTemplateVersionStatusEnum.Active &&
      viewMode === FormTemplateMode.Edit
    ) {
      navigate(
        APP_URLS.dashboard.formTemplates.viewVersion.getDynamicPath({
          pathParts: { id, versionId },
        }),
      );
    }
  }, [formTemplateVersionDataStatus, id, navigate, versionId, viewMode]);

  useEffect(() => {
    if (viewMode === FormTemplateMode.View && formTemplateVersionRefetch) {
      formTemplateVersionRefetch();
    }
  }, [formTemplateVersionRefetch, viewMode]);

  return {
    formCategoryCreateMutationError,
    formCategoryCreateMutationLoading,
    formTemplateCreateMutationLoading,
    formTemplateData,
    formTemplateError: formTemplateError || formTemplateVersionError,
    formTemplateGraphQLErrors,
    formTemplateLoading: formTemplateLoading || formTemplateVersionLoading,
    formTemplateUpdateMutationLoading,
    formTemplateVersionData,
    formTemplateVersionLoading,
    loadingState,
    onCancelForm,
    onConfirmedDestroy,
    onConfirmedVersionDestroy,
    onCreateNewFormTemplateVersion,
    onCreateTaskHandler,
    onDuplicateHandler,
    onDuplicateVersionHandler,
    onEditHandler,
    onEditVersionHandler,
    onFormCategoryCreate,
    onFormTemplateArchiveHandler,
    onFormTemplateBulkRestoreHandler,
    onFormTemplateConfirmedArchive,
    onFormTemplateVersionArchiveHandler,
    onFormTemplateVersionConfirmedArchive,
    onFormTemplateVersionRestoreHandler,
    onSubmitForm,
    onTaskFormTemplateVersionBulkChange,
    onTaskFormTemplateVersionChange,
    onVersionFieldChangeCallBack,
    onViewFormTemplateVersion,
    onViewHandler,
    tasksQueryHook,
    useBulkSelectResult,
  };
}
