import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import PageBody from '@lib/layouts/DashboardLayout/PageBody/PageBody';
import PageHeader from '@lib/layouts/DashboardLayout/PageHeader/PageHeader';
import BreadCrumbs from '@lib/components/BreadCrumbs/BreadCrumbs';
import { getBreadCrumbsDataByKey } from 'utils/breadcrumbs';
import DashboardInfoFilter from 'components/DashboardInfoFilter/DashboardInfoFilter';
import CircleLoader from '@lib/components/CircleLoader/CircleLoader';
import TabBar from '@lib/components/TabBar/TabBar';
import { APP_URLS } from 'constants/urls';
import TasksTimeline from 'components/TasksTimeline/TasksTimeline';
import TasksOverview from 'components/TasksOverview/TasksOverview';
import useOutletContext, { ContextProps } from 'hooks/useOutletContext';
import { ComponentEnum, LevelEnum } from 'graphql-common';
import useActions from './utils/useActions';
import styles from './Dashboard.module.scss';

export default function DashboardContainer() {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { getUserAccessByComponent }: ContextProps = useOutletContext();
  const isViewOnly =
    getUserAccessByComponent(ComponentEnum.TasksManagement) === LevelEnum.Read;

  const {
    onCreateIntervention,
    onViewIntervention,
    onResultStateChangeHandler,
    onViewHandler,
    prevTabParam,
    setTabParam,
    tabParam,
    taskCounterData,
    tasks,
    tasksError,
    tasksFirstLoading,
    tasksLoading,
    timelineData,
    timelineGroupBy,
    timelineLoading,
    totalTasksCount,
  } = useActions();

  const pageTabs = [
    {
      label: t('overview'),
      value: APP_URLS.dashboard.overview.getPathWithQuery(),
    },
    {
      label: t('timeline'),
      value: APP_URLS.dashboard.timeline.getPathWithQuery(),
    },
  ];

  return (
    <>
      <BreadCrumbs data={getBreadCrumbsDataByKey({ pathname, t })} />
      <PageHeader title={t('dashboard')} />
      {tasksFirstLoading ? (
        <CircleLoader />
      ) : (
        <>
          <PageBody className={styles.topTabs}>
            <TabBar
              items={pageTabs}
              active={pathname}
              onActiveChange={({ value }) => navigate(value)}
            />
          </PageBody>
          <DashboardInfoFilter />
          {pathname === APP_URLS.dashboard.overview.path && (
            <TasksOverview
              {...{
                isViewOnly,
                onCreateIntervention,
                onViewIntervention,
                onResultStateChangeHandler,
                onViewHandler,
                prevTabParam,
                setTabParam,
                tabParam,
                taskCounterData,
                tasks,
                tasksError,
                tasksFirstLoading,
                tasksLoading,
                totalTasksCount,
              }}
            />
          )}
          {pathname === APP_URLS.dashboard.timeline.path && (
            <TasksTimeline
              {...{
                setTabParam,
                tabParam,
                taskCounterData,
                tasks,
                tasksError,
                tasksFirstLoading,
                tasksLoading,
                timelineData,
                timelineGroupBy,
                timelineLoading,
                totalTasksCount,
              }}
            />
          )}
        </>
      )}
    </>
  );
}
