import { LazyQueryExecFunction } from '@apollo/client';
import {
  InterventionCategoriesSearchListQuery,
  InterventionCategoriesSearchListQueryVariables,
  InterventionCategory,
  InterventionCategoryFilterInputObject,
  SortDirectionEnum,
} from 'graphql-common';
import { DEFAULT_SEARCH_LIST_VARIABLES } from '@lib/enums/urls';
import getLoadOptionsResult from '@lib/utils/getLoadOptionsResult';
import createSearchFilters from '@lib/utils/createSearchFilters';
import { LoadOptionsParams } from '@lib/components/Select/types';

type FetchLazyQuery = LazyQueryExecFunction<
  InterventionCategoriesSearchListQuery,
  any
>;

const fetchSearchList = async (
  searchQuery: string,
  fetchLazyQuery: FetchLazyQuery,
  page: number,
) => {
  const options: { variables: InterventionCategoriesSearchListQueryVariables } =
    {
      variables: {
        ...DEFAULT_SEARCH_LIST_VARIABLES,
        page,
        sorts: { name: SortDirectionEnum.Asc },
      },
    };
  const filters: InterventionCategoryFilterInputObject[] = [];
  if (searchQuery) {
    filters.push(...createSearchFilters(searchQuery, [{ fieldName: 'name' }]));
  }
  if (filters.length) {
    options.variables.filters = filters;
  }
  const { data } = await fetchLazyQuery(options);
  return getLoadOptionsResult(
    data,
    (dataItem: InterventionCategory) => ({
      value: dataItem.id,
      label: dataItem.name,
      color: dataItem.color,
    }),
    searchQuery,
  );
};

export const getInterventionCategoriesLoadOptions =
  (fetchLazyQuery: FetchLazyQuery) =>
  (searchQuery: string, loadedOptions: unknown, params: LoadOptionsParams) =>
    fetchSearchList(searchQuery, fetchLazyQuery, params?.page || 1);
