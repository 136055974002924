import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { History } from 'graphql-common';
import {
  GetUrlParams,
  ORDERING_DIRECTION_DESC,
  PAGINATION_PARAMS,
  PaginationParams,
} from '@lib/enums/urls';
import ListEmptyState from '@lib/components/ListEmptyState/ListEmptyState';
import ErrorPage from '@lib/components/ErrorPage/ErrorPage';
import getStatusFromError from '@lib/utils/getStatusFromError';
import CircleLoaderWrapper from '@lib/components/CircleLoaderWrapper/CircleLoaderWrapper';
import CircleLoader from '@lib/components/CircleLoader/CircleLoader';
import LinkComponent, { LinkSizes, LinkTypes } from '@lib/components/Link/Link';
import useInterventionActionHistoriesQueryHook from 'utils/fetch/useInterventionActionHistoriesQueryHook';
import InterventionActionHistoryView from '../InterventionActionHistoryView/InterventionActionHistoryView';
import styles from './InterventionActionHistory.module.scss';

function InterventionActionHistory({ id }: { id: string }) {
  const { t } = useTranslation();
  const [paginationParams, setPaginationParams] =
    useState<PaginationParams>(PAGINATION_PARAMS);
  const [allHistory, setAllHistory] = useState<History[]>([]);
  const [isShowAll, setIsShowAll] = useState(true);

  const historyToShow = isShowAll ? allHistory : allHistory.slice(0, 10);

  const {
    collection: newHistory,
    loading,
    firstLoading,
    error,
    totalPages,
    currentPage,
  } = useInterventionActionHistoriesQueryHook({
    fetchPolicy: 'no-cache',
    interventionActionId: id,
    paginationParams,
    orderingParams: {
      [GetUrlParams.OrderingField]: 'occurredAt',
      [GetUrlParams.OrderingDirection]: ORDERING_DIRECTION_DESC,
    },
  });

  const isListFull = currentPage === totalPages;

  const handleLoadMore = () => {
    if (isListFull) {
      setIsShowAll(true);
    } else if (currentPage < totalPages) {
      setPaginationParams((prev) => ({
        ...prev,
        [GetUrlParams.Page]: currentPage + 1,
      }));
    }
  };

  const toggleShowAll = () => {
    setIsShowAll((prevState) => !prevState);
  };

  const isEmptyStateVisible = newHistory.length === 0 && !error && !loading;
  const emptyState = isEmptyStateVisible ? (
    <ListEmptyState
      title={t('no-actions-yet')}
      text={t('no-actions-text')}
      actions={[]}
    />
  ) : null;

  const isErrorStateVisible = !!error && !loading;
  const errorState = isErrorStateVisible ? (
    <ErrorPage
      status={getStatusFromError(error)}
      description={error?.message}
    />
  ) : null;

  const isContentVisible = !error && !firstLoading && newHistory?.length;
  const content = isContentVisible ? (
    <CircleLoaderWrapper isLoading={loading}>
      <div className={styles.actionHistory}>
        {historyToShow.map((history) => (
          <InterventionActionHistoryView key={history.id} history={history} />
        ))}
        {!!allHistory?.length &&
          !!newHistory?.length &&
          currentPage < totalPages && (
            <div>
              <LinkComponent
                size={LinkSizes.small}
                type={LinkTypes.secondaryGray}
                onClick={handleLoadMore}
              >
                {t('show-more')}
              </LinkComponent>
            </div>
          )}
        {!!newHistory?.length &&
          totalPages > 1 &&
          currentPage === totalPages && (
            <div>
              <LinkComponent
                size={LinkSizes.small}
                type={LinkTypes.secondaryGray}
                onClick={toggleShowAll}
              >
                {t(isShowAll ? 'show-less' : 'show-more')}
              </LinkComponent>
            </div>
          )}
      </div>
    </CircleLoaderWrapper>
  ) : null;

  useEffect(() => {
    if (newHistory.length) {
      setAllHistory((prevHistory) => [...prevHistory, ...newHistory]);
    }
  }, [newHistory]);

  return (
    <div>
      {firstLoading && <CircleLoader />}
      {emptyState}
      {errorState}
      {content}
    </div>
  );
}

export default memo(InterventionActionHistory);
