import React from 'react';
import { TFunction } from 'i18next';
import Button, { ButtonTypes } from '@lib/components/Button/Button';
import { materialSymbolsOutlined } from '@lib/enums/common';
import { InterventionScopeNameEnum } from 'graphql-common';

type Args = {
  onBulkClose: () => void;
  selectedItemsCount?: number;
  isViewOnly: boolean;
  t: TFunction<'translation', undefined>;
  tabParam?: InterventionScopeNameEnum | string;
};

export default function getTableActionsForSelected(args: Args) {
  const { onBulkClose, selectedItemsCount = 0, t, isViewOnly, tabParam } = args;
  if (tabParam === InterventionScopeNameEnum.Closed || isViewOnly) return [];
  return [
    <Button
      key="bulk-close"
      buttonText={`${t('close-selected')} (${selectedItemsCount})`}
      buttonType={ButtonTypes.primaryOutlined}
      leftIcon="check_circle"
      leftIconClassName={materialSymbolsOutlined}
      onClick={onBulkClose}
    />,
  ];
}
