import React from 'react';
import { useTranslation } from 'react-i18next';
import TabBar from '@lib/components/TabBar/TabBar';
import PageBody from '@lib/layouts/DashboardLayout/PageBody/PageBody';
import DashboardContentWithRightSidebar from '@lib/layouts/DashboardLayout/DashboardContentWithRightSidebar/DashboardContentWithRightSidebar';
import { TabBarItem as TabBarItemType } from '@lib/components/TabBar/constants';
import CircleLoader from '@lib/components/CircleLoader/CircleLoader';
import InterventionInfo from '../InterventionInfo/InterventionInfo';
import { InterventionViewMode } from '../enums';
import getInterventionViewPageTabs from '../utils/getInterventionViewPageTabs';
import { UseInterventionActionsResult } from '../utils/useInterventionActions';
import { UseModalManagementResult } from '../utils/useModalManagement';
import InterventionViewDetails from './InterventionViewDetails';
import InterventionViewActions from './InterventionViewActions';

type Props = {
  useModalManagementResult: UseModalManagementResult;
  useInterventionActionsResult: UseInterventionActionsResult;
  viewMode: InterventionViewMode;
  isViewOnly: boolean;
};

export default function InterventionViewPage({
  viewMode,
  useModalManagementResult,
  useInterventionActionsResult,
  isViewOnly,
}: Props) {
  const { t } = useTranslation();
  const { interventionQueryHookResult, onViewChange } =
    useInterventionActionsResult;
  const { firstLoading, actionsCounter } = interventionQueryHookResult;

  const tabs = getInterventionViewPageTabs({ t, actionsCounter });
  const sidebar = (
    <InterventionInfo
      useModalManagementResult={useModalManagementResult}
      useInterventionActionsResult={useInterventionActionsResult}
      isViewOnly={isViewOnly}
    />
  );

  return (
    <DashboardContentWithRightSidebar
      sidebar={sidebar}
      useSidebarHeader
      toggleBtnTop={62.5}
    >
      <PageBody withoutHeader>
        {firstLoading ? (
          <CircleLoader />
        ) : (
          <>
            <TabBar
              items={tabs}
              active={viewMode}
              onActiveChange={({ value }: TabBarItemType) =>
                onViewChange(value)
              }
            />
            {viewMode === InterventionViewMode.View && (
              <InterventionViewDetails
                useModalManagementResult={useModalManagementResult}
                useInterventionActionsResult={useInterventionActionsResult}
                isViewOnly={isViewOnly}
              />
            )}
            {viewMode === InterventionViewMode.ViewActions && (
              <InterventionViewActions
                useModalManagementResult={useModalManagementResult}
                useInterventionActionsResult={useInterventionActionsResult}
                isViewOnly={isViewOnly}
              />
            )}
          </>
        )}
      </PageBody>
    </DashboardContentWithRightSidebar>
  );
}
