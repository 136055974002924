import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { FieldError, useFormContext } from 'react-hook-form';
import _get from 'lodash/get';
import Typography from '@lib/components/Typography/Typography';
import ControllerErrorRender from '@lib/components/ReactHookForm/ControllerErrorRender';
import FormTemplateFieldNames from '@lib/enums/fieldNames/formTemplateFieldNames';
import styles from './FormTemplateBuilderEmptyState.module.scss';

interface Props {
  isUsingPlaceholder?: boolean;
}

function FormTemplateBuilderEmptyState({ isUsingPlaceholder }: Props) {
  const { t } = useTranslation();
  const { formState } = useFormContext();
  const { errors } = formState;
  const questionsError = _get(
    errors,
    FormTemplateFieldNames.Questions,
  ) as FieldError;
  const className = classNames(styles.questionFormEmptyState, {
    [styles.questionFormEmptyStateActive]: isUsingPlaceholder,
    [styles.questionFormEmptyStateHasError]: !!questionsError,
  });
  return (
    <div>
      <div className={className}>
        <Typography
          variant="body"
          strong
          className={styles.questionFormEmptyStateTitle}
        >
          {t('add-new-question')}
        </Typography>
        <Typography
          variant="caption"
          className={styles.questionFormEmptyStateText}
        >
          {t('add-new-question-text')}
        </Typography>
      </div>
      {!!questionsError && (
        <ControllerErrorRender
          error={{
            ...questionsError,
            message: t('the-form-question-error'),
          }}
        />
      )}
    </div>
  );
}

export default FormTemplateBuilderEmptyState;
