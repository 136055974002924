import React from 'react';
import classNames from 'classnames';
import Typography, { Variant } from '@lib/components/Typography/Typography';
import styles from './PageHeader.module.scss';

interface Props {
  title: string;
  titleVariant?: Variant;
  children?: React.JSX.Element | React.JSX.Element[];
  centered?: boolean;
  className?: string;
}

function PageHeader(props: Props) {
  const { title, titleVariant = 'h2', children, centered, className } = props;
  return (
    <div
      className={classNames(
        styles.root,
        { [styles.rootCentered]: centered },
        className,
      )}
    >
      <Typography variant={titleVariant}>{title}</Typography>
      {children && <div>{children}</div>}
    </div>
  );
}

export default PageHeader;
