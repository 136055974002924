import React from 'react';
import { CountMarkTypes } from '@lib/components/CountMark/CountMark';

export enum TabBarItemTypes {
  green = 'green',
  blue = 'blue',
  red = 'red',
  orange = 'orange',
}

export type ActiveItem = string;

export interface TabBarItem {
  component?: () => React.JSX.Element;
  counter?: number;
  counterType?: CountMarkTypes;
  disabled?: boolean;
  hasError?: boolean;
  label?: React.ReactNode;
  value: ActiveItem;
  type?: TabBarItemTypes;
}

export interface TabBarItemElement extends HTMLDivElement {
  dataset: {
    id: string;
  };
}
