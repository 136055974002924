import React from 'react';
import { TFunction } from 'i18next';
import { ComponentEnum, LevelEnum } from 'graphql-common';
import { APP_URLS } from 'constants/urls';
import { NavItem, NavItemTypeEnum } from '@lib/components/Menu/Menu';
import { NEW } from '@lib/enums/common';
import Icons from '@lib/components/Icons/Icons';
import _without from 'lodash/without';
import Chip, { ChipSizes, ChipTypes } from '@lib/components/Chip/Chip';

type UrlParams = {
  versionId?: string;
  documentId?: string;
  actionId?: string;
};

type Args = {
  getUserAccessByCompany: (component: ComponentEnum) => undefined | LevelEnum;
  getUserAccessByComponent: (component: ComponentEnum) => undefined | LevelEnum;
  id?: string;
  myApprovalsCounter: number;
  pathname: string;
  t: TFunction<'translation', undefined>;
  urlParams?: UrlParams;
};

function getDashboardNavItem(args: Args) {
  const { t, pathname, getUserAccessByComponent } = args;
  const isTasksAvailable =
    getUserAccessByComponent(ComponentEnum.TasksManagement) !==
    LevelEnum.LimitedRead;
  return {
    id: 'dashboard',
    title: t('dashboard'),
    leftIcon: Icons.dashboard,
    to: APP_URLS.dashboard.overview.getPathWithQuery(),
    type: NavItemTypeEnum.Primary,
    predictorFnVisible: () => isTasksAvailable,
    predictorFnActive: () => {
      return (
        pathname === APP_URLS.dashboard.overview.path ||
        pathname === APP_URLS.dashboard.timeline.path
      );
    },
  };
}

function getWorkflowsNavItem(args: Args) {
  const {
    id = '',
    myApprovalsCounter,
    pathname,
    t,
    urlParams,
    getUserAccessByComponent,
  } = args;
  const isTasksAvailable =
    getUserAccessByComponent(ComponentEnum.TasksManagement) !==
    LevelEnum.LimitedRead;
  const isFormCategoriesAvailable =
    getUserAccessByComponent(ComponentEnum.FormCategoriesManagement) !==
    LevelEnum.LimitedRead;
  const children: NavItem<unknown>[] = [];
  let to: string | undefined;
  if (isTasksAvailable) {
    to = APP_URLS.dashboard.tasks.index.getPathWithQuery();
    children.push(
      {
        id: 'tasks',
        title: t('tasks'),
        to: APP_URLS.dashboard.tasks.index.getPathWithQuery(),
        type: NavItemTypeEnum.PrimarySub,
        end: true,
        predictorFnActive: () => {
          return (
            APP_URLS.dashboard.tasks.view.isTheSameUrlAs(pathname, {
              pathParts: { id },
            }) ||
            APP_URLS.dashboard.tasks.formView.isTheSameUrlAs(pathname, {
              pathParts: { id },
            }) ||
            APP_URLS.dashboard.tasks.reporting.isTheSameUrlAs(pathname, {
              pathParts: { id },
            }) ||
            APP_URLS.dashboard.tasks.interventions.isTheSameUrlAs(pathname, {
              pathParts: { id },
            }) ||
            APP_URLS.dashboard.tasks.edit.isTheSameUrlAs(pathname, {
              pathParts: { id },
            }) ||
            APP_URLS.dashboard.tasks.add.isTheSameUrlAs(pathname) ||
            APP_URLS.dashboard.formTemplates.archived.isTheSameUrlAs(pathname)
          );
        },
        predictorFnVisible: () => isTasksAvailable,
      },
      {
        id: 'approvals',
        title: t('approvals'),
        to: APP_URLS.dashboard.tasks.approvals.getPathWithQuery(),
        type: NavItemTypeEnum.PrimarySub,
        end: true,
        predictorFnVisible: () => !!myApprovalsCounter,
      },
      {
        id: 'all-form-templates',
        title: t('forms'),
        to: APP_URLS.dashboard.formTemplates.index.getPathWithQuery(),
        type: NavItemTypeEnum.PrimarySub,
        end: true,
        predictorFnActive: () => {
          return (
            APP_URLS.dashboard.formTemplates.viewVersion.isTheSameUrlAs(
              pathname,
              {
                pathParts: { id, versionId: urlParams?.versionId },
              },
            ) ||
            APP_URLS.dashboard.formTemplates.viewLinkedTasks.isTheSameUrlAs(
              pathname,
              {
                pathParts: { id, versionId: urlParams?.versionId },
              },
            ) ||
            APP_URLS.dashboard.formTemplates.editVersion.isTheSameUrlAs(
              pathname,
              {
                pathParts: { id, versionId: urlParams?.versionId },
              },
            ) ||
            APP_URLS.dashboard.formTemplates.duplicate.isTheSameUrlAs(
              pathname,
              {
                pathParts: { id, versionId: urlParams?.versionId },
              },
            ) ||
            APP_URLS.dashboard.formTemplates.add.isTheSameUrlAs(pathname) ||
            APP_URLS.dashboard.formTemplates.archived.isTheSameUrlAs(pathname)
          );
        },
        predictorFnVisible: () => isTasksAvailable,
      },
    );
  }
  if (isFormCategoriesAvailable) {
    if (!to) {
      to = APP_URLS.dashboard.formTemplates.categories.getPathWithQuery();
    }
    children.push({
      id: 'form-template-categories',
      title: t('categories'),
      to: APP_URLS.dashboard.formTemplates.categories.getPathWithQuery(),
      type: NavItemTypeEnum.PrimarySub,
      end: true,
      predictorFnVisible: () => isFormCategoriesAvailable,
    });
  }
  return {
    id: 'workflows',
    title: t('workflows'),
    leftIcon: Icons.tasks,
    to,
    type: NavItemTypeEnum.Primary,
    predictorFnVisible: () => isTasksAvailable || isFormCategoriesAvailable,
    predictorFnActive: () => {
      return (
        APP_URLS.dashboard.tasks.index.isTheSameUrlAs(pathname) ||
        APP_URLS.dashboard.tasks.approvals.isTheSameUrlAs(pathname) ||
        APP_URLS.dashboard.tasks.add.isTheSameUrlAs(pathname) ||
        APP_URLS.dashboard.tasks.edit.isTheSameUrlAs(pathname, {
          pathParts: { id },
        }) ||
        APP_URLS.dashboard.formTemplates.index.isTheSameUrlAs(pathname) ||
        APP_URLS.dashboard.formTemplates.archived.isTheSameUrlAs(pathname) ||
        APP_URLS.dashboard.formTemplates.categories.isTheSameUrlAs(pathname) ||
        APP_URLS.dashboard.formTemplates.add.isTheSameUrlAs(pathname) ||
        APP_URLS.dashboard.formTemplates.viewVersion.isTheSameUrlAs(pathname, {
          pathParts: { id, versionId: urlParams?.versionId },
        }) ||
        APP_URLS.dashboard.formTemplates.viewLinkedTasks.isTheSameUrlAs(
          pathname,
          {
            pathParts: { id, versionId: urlParams?.versionId },
          },
        ) ||
        APP_URLS.dashboard.formTemplates.editVersion.isTheSameUrlAs(pathname, {
          pathParts: { id, versionId: urlParams?.versionId },
        }) ||
        APP_URLS.dashboard.formTemplates.duplicate.isTheSameUrlAs(pathname, {
          pathParts: { id, versionId: urlParams?.versionId },
        })
      );
    },
    children,
  };
}

function getInterventionsNavItem(args: Args) {
  const {
    id = '',
    pathname,
    t,
    urlParams,
    getUserAccessByComponent,
    getUserAccessByCompany,
  } = args;
  const isInterventionsAvailable =
    getUserAccessByComponent(ComponentEnum.InterventionsManagement) !==
    LevelEnum.LimitedRead;
  const isInterventionsLocked =
    getUserAccessByCompany(ComponentEnum.InterventionsManagement) ===
    LevelEnum.LimitedRead;
  const isInterventionCategoriesAvailable =
    getUserAccessByComponent(ComponentEnum.InterventionCategoriesManagement) !==
    LevelEnum.LimitedRead;
  const children: NavItem<unknown>[] = [];
  let to = APP_URLS.dashboard.interventions.index.getPathWithQuery();
  if (isInterventionsAvailable) {
    children.push({
      id: 'all-interventions',
      title: t('all-interventions'),
      to: APP_URLS.dashboard.interventions.index.getPathWithQuery(),
      type: NavItemTypeEnum.PrimarySub,
      end: true,
      predictorFnActive: () => {
        return (
          APP_URLS.dashboard.interventions.add.isTheSameUrlAs(pathname) ||
          APP_URLS.dashboard.interventions.edit.isTheSameUrlAs(pathname, {
            pathParts: { id },
          }) ||
          APP_URLS.dashboard.interventions.view.isTheSameUrlAs(pathname, {
            pathParts: { id },
          }) ||
          APP_URLS.dashboard.interventions.viewActions.isTheSameUrlAs(
            pathname,
            {
              pathParts: { id },
            },
          ) ||
          APP_URLS.dashboard.interventions.actionAdd.isTheSameUrlAs(pathname, {
            pathParts: { id },
          }) ||
          APP_URLS.dashboard.interventions.actionEdit.isTheSameUrlAs(pathname, {
            pathParts: { id, actionId: urlParams?.actionId },
          })
        );
      },
    });
  }
  if (isInterventionCategoriesAvailable) {
    children.push({
      id: 'intervention-categories',
      title: t('categories'),
      to: APP_URLS.dashboard.interventions.categories.getPathWithQuery(),
      type: NavItemTypeEnum.PrimarySub,
      end: true,
      predictorFnVisible: () => isInterventionCategoriesAvailable,
    });
    if (!isInterventionsAvailable) {
      to = APP_URLS.dashboard.interventions.categories.getPathWithQuery();
    }
  }
  return {
    id: 'interventions',
    title: t('interventions'),
    leftIcon: Icons.interventions,
    rightIcon: isInterventionsLocked
      ? (isCollapsed: boolean) => (
          <Chip
            leftIcon="lock"
            size={isCollapsed ? ChipSizes.extraSmall : ChipSizes.small}
            type={ChipTypes.chipBlue}
          />
        )
      : undefined,
    to,
    type: NavItemTypeEnum.Primary,
    predictorFnVisible: () =>
      isInterventionsAvailable ||
      isInterventionsLocked ||
      isInterventionCategoriesAvailable,
    predictorFnActive: () => {
      return (
        APP_URLS.dashboard.interventions.index.isTheSameUrlAs(pathname) ||
        APP_URLS.dashboard.interventions.categories.isTheSameUrlAs(pathname) ||
        APP_URLS.dashboard.interventions.add.isTheSameUrlAs(pathname) ||
        APP_URLS.dashboard.interventions.edit.isTheSameUrlAs(pathname, {
          pathParts: { id },
        }) ||
        APP_URLS.dashboard.interventions.view.isTheSameUrlAs(pathname, {
          pathParts: { id },
        }) ||
        APP_URLS.dashboard.interventions.viewActions.isTheSameUrlAs(pathname, {
          pathParts: { id },
        })
      );
    },
    children,
  };
}

function getSitesNavItem(args: Args) {
  const { t, getUserAccessByComponent } = args;
  const isSitesAvailable =
    getUserAccessByComponent(ComponentEnum.SitesManagement) !==
    LevelEnum.LimitedRead;
  return {
    id: 'sites',
    title: t('sites'),
    leftIcon: Icons.sites,
    to: APP_URLS.dashboard.sites.index.getPathWithQuery(),
    type: NavItemTypeEnum.Primary,
    predictorFnVisible: () => isSitesAvailable,
  };
}

function getUsersNavItem(args: Args) {
  const { id = '', pathname, t, getUserAccessByComponent } = args;
  const isUsersAvailable =
    getUserAccessByComponent(ComponentEnum.UsersManagement) !==
    LevelEnum.LimitedRead;
  return {
    id: 'manage-users',
    title: t('manage-users'),
    leftIcon: Icons.team,
    to: APP_URLS.dashboard.users.index.getPathWithQuery(),
    type: NavItemTypeEnum.Primary,
    predictorFnVisible: () => isUsersAvailable,
    predictorFnActive: () => {
      return (
        APP_URLS.dashboard.teams.index.isTheSameUrlAs(pathname) ||
        APP_URLS.dashboard.teams.add.isTheSameUrlAs(pathname) ||
        APP_URLS.dashboard.teams.edit.isTheSameUrlAs(pathname, {
          pathParts: { id },
        }) ||
        APP_URLS.dashboard.teams.view.isTheSameUrlAs(pathname, {
          pathParts: { id },
        }) ||
        APP_URLS.dashboard.teams.members.isTheSameUrlAs(pathname, {
          pathParts: { id },
        })
      );
    },
    children: [
      {
        id: 'members',
        title: t('members'),
        to: APP_URLS.dashboard.users.index.getPathWithQuery(),
        type: NavItemTypeEnum.PrimarySub,
        end: true,
        predictorFnActive: () => {
          return (
            APP_URLS.dashboard.users.viewAccess.isTheSameUrlAs(pathname, {
              pathParts: { id },
            }) ||
            APP_URLS.dashboard.users.editAccess.isTheSameUrlAs(pathname, {
              pathParts: { id },
            })
          );
        },
      },
      {
        id: 'teams',
        title: t('teams'),
        to: APP_URLS.dashboard.teams.index.getPathWithQuery(),
        type: NavItemTypeEnum.PrimarySub,
        end: true,
        predictorFnActive: () => {
          return (
            APP_URLS.dashboard.teams.add.isTheSameUrlAs(pathname) ||
            APP_URLS.dashboard.teams.edit.isTheSameUrlAs(pathname, {
              pathParts: { id },
            }) ||
            APP_URLS.dashboard.teams.view.isTheSameUrlAs(pathname, {
              pathParts: { id },
            }) ||
            APP_URLS.dashboard.teams.members.isTheSameUrlAs(pathname, {
              pathParts: { id },
            })
          );
        },
      },
    ],
  };
}

function getAssetsNavItem(args: Args) {
  const { id = '', pathname, t, urlParams, getUserAccessByComponent } = args;
  const isAssetsAvailable =
    getUserAccessByComponent(ComponentEnum.AssetsManagement) !==
    LevelEnum.LimitedRead;
  const isAssetCategoriesAvailable =
    getUserAccessByComponent(ComponentEnum.AssetCategoriesManagement) !==
    LevelEnum.LimitedRead;
  const isAssetCountersAvailable =
    getUserAccessByComponent(ComponentEnum.AssetsCounterReading) !==
    LevelEnum.LimitedRead;
  const children: NavItem<unknown>[] = [];
  let to: string | undefined;
  if (isAssetsAvailable) {
    to = APP_URLS.dashboard.assets.index.getPathWithQuery();
    children.push({
      id: 'all-assets',
      title: t('all-assets'),
      to: APP_URLS.dashboard.assets.index.getPathWithQuery(),
      type: NavItemTypeEnum.PrimarySub,
      end: true,
      predictorFnActive: () => {
        return (
          APP_URLS.dashboard.assets.view.isTheSameUrlAs(pathname, {
            pathParts: { id },
          }) ||
          APP_URLS.dashboard.assets.edit.isTheSameUrlAs(pathname, {
            pathParts: { id },
          }) ||
          APP_URLS.dashboard.assets.add.isTheSameUrlAs(pathname) ||
          APP_URLS.dashboard.assets.nomenclature.isTheSameUrlAs(pathname, {
            pathParts: { id },
          }) ||
          APP_URLS.dashboard.assets.counter.isTheSameUrlAs(pathname, {
            pathParts: { id },
          }) ||
          APP_URLS.dashboard.assets.document.isTheSameUrlAs(pathname, {
            pathParts: { id, documentId: urlParams?.documentId || NEW },
          }) ||
          APP_URLS.dashboard.assets.interventions.isTheSameUrlAs(pathname, {
            pathParts: { id },
          })
        );
      },
    });
  }
  if (isAssetCountersAvailable) {
    if (!to) to = APP_URLS.dashboard.assets.counters.getPathWithQuery();
    children.push({
      id: 'counter-monitoring',
      title: t('counter-monitoring'),
      to: APP_URLS.dashboard.assets.counters.getPathWithQuery(),
      type: NavItemTypeEnum.PrimarySub,
      end: true,
    });
  }
  if (isAssetCategoriesAvailable) {
    if (!to) to = APP_URLS.dashboard.assets.categories.getPathWithQuery();
    children.push({
      id: 'asset-categories',
      title: t('categories'),
      to: APP_URLS.dashboard.assets.categories.getPathWithQuery(),
      type: NavItemTypeEnum.PrimarySub,
      end: true,
    });
  }
  if (to) {
    return {
      id: 'assets',
      title: t('assets'),
      leftIcon: Icons.inventory,
      to,
      type: NavItemTypeEnum.Primary,
      predictorFnVisible: () =>
        isAssetsAvailable ||
        isAssetCountersAvailable ||
        isAssetCategoriesAvailable,
      children,
    };
  }
  return undefined;
}

export default function getNavItems(args: Args) {
  return _without(
    [
      getDashboardNavItem(args),
      getWorkflowsNavItem(args),
      getInterventionsNavItem(args),
      getSitesNavItem(args),
      getUsersNavItem(args),
      getAssetsNavItem(args),
    ],
    undefined,
  ) as NavItem<undefined>[];
}
