import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import useOutletContext, { ContextProps } from 'hooks/useOutletContext';
import { ComponentEnum, LevelEnum } from 'graphql-common';
import Trans from '@lib/components/Trans/Trans';
import TaskView from 'components/Task/TaskView';
import ConfirmationModal from '@lib/components/ConfirmationModal/ConfirmationModal';
import ScheduleTask from 'components/ScheduleTask/ScheduleTask';
import BreadCrumbs from '@lib/components/BreadCrumbs/BreadCrumbs';
import { getBreadCrumbsDataByKey } from 'utils/breadcrumbs';
import { IconName } from '@lib/components/Modal/enums';
import useActions from './utils/useActions';
import useModalManagement from './utils/useModalManagement';
import useSearchListQueries from './utils/useSearchListQueries';

export default function TaskViewContainer() {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const { getUserAccessByComponent }: ContextProps = useOutletContext();
  const isViewOnly =
    getUserAccessByComponent(ComponentEnum.TasksManagement) === LevelEnum.Read;

  const {
    closeArchiveModal,
    closeScheduleModal,
    closeTaskDeleteModal,
    isArchiveModalOpened,
    isScheduleModalOpened,
    isTaskDeleteModalOpened,
    openArchiveModal,
    openScheduleModal,
    openTaskDeleteModal,
  } = useModalManagement();

  const {
    onCloseScheduleModal,
    onConfirmedBulkArchive,
    onConfirmedDelete,
    onDuplicateHandler,
    onEditHandler,
    onRescheduleHandler,
    onStatusChangeHandler,
    taskBulkSchedulingError,
    taskBulkSchedulingLoading,
    taskData,
    taskError,
    taskLoading = false,
    taskScheduleData,
    taskScheduleLoading,
  } = useActions({
    closeArchiveModal,
    closeScheduleModal,
    closeTaskDeleteModal,
    isScheduleModalOpened,
    openArchiveModal,
  });

  const { currentUserId } = useSearchListQueries();

  return (
    <>
      <BreadCrumbs
        data={getBreadCrumbsDataByKey({
          pathname,
          t,
          entity: { id: taskData?.id, name: taskData?.name },
        })}
      />
      <TaskView
        currentUserId={currentUserId}
        isViewOnly={isViewOnly}
        onDeleteHandler={openTaskDeleteModal}
        onDuplicateHandler={onDuplicateHandler}
        onEditHandler={onEditHandler}
        onStatusChangeHandler={onStatusChangeHandler}
        openArchiveModal={openArchiveModal}
        openScheduleModal={openScheduleModal}
        taskData={taskData}
        taskError={taskError}
        taskLoading={taskLoading}
      />
      <ConfirmationModal
        confirmButtonLabel={t('yes-delete')}
        description={<Trans i18nKey="delete-task-text" />}
        icon={IconName.Delete}
        isOpen={isTaskDeleteModalOpened}
        onClose={closeTaskDeleteModal}
        onConfirm={onConfirmedDelete}
        title={t('are-you-sure')}
      />
      <ConfirmationModal
        confirmButtonLabel={t('yes-archive')}
        description={<Trans i18nKey="archive-task-text" />}
        icon={IconName.Deactivate}
        isOpen={isArchiveModalOpened}
        onClose={closeArchiveModal}
        onConfirm={onConfirmedBulkArchive}
        title={t('are-you-sure')}
      />
      <ScheduleTask
        isOpen={isScheduleModalOpened}
        onClose={onCloseScheduleModal}
        onSubmit={onRescheduleHandler}
        taskBulkSchedulingError={taskBulkSchedulingError}
        taskBulkSchedulingLoading={taskBulkSchedulingLoading}
        taskScheduleData={taskScheduleData}
        taskScheduleLoading={taskScheduleLoading}
      />
    </>
  );
}
