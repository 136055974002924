import React from 'react';
import { TFunction } from 'i18next';
import {
  TaskDeadlineMissedReasonEnum,
  TaskScopeNameEnum,
  TaskStatusEnum,
} from 'graphql-common';
import Button, { ButtonTypes } from '@lib/components/Button/Button';
import { materialSymbolsOutlined } from '@lib/enums/common';
import { NavItemTypeEnum } from '@lib/components/Menu/Menu';
import Chip, { ChipSizes } from '@lib/components/Chip/Chip';
import ButtonWithDropDown from '@lib/components/ButtonWithDropDown/ButtonWithDropDown';
import getTaskStatusChipType from '@lib/utils/getTaskStatusChipType';
import getDeadlineMissedReasonChipType from 'utils/getDeadlindMissedReasonChipType';

type ActionsForSelectedArgs = {
  isApprovals?: boolean;
  onBulkDeadlineMissedReasonChangeHandler: (
    reason: TaskDeadlineMissedReasonEnum,
  ) => void;
  onStatusBulkChangeHandler: (status: TaskStatusEnum) => void;
  openArchiveModal: () => void;
  openScheduleModal: () => void;
  selectedTasksCount?: number;
  t: TFunction<'translation', undefined>;
  taskScope?: TaskScopeNameEnum;
};

export default function getActionsForSelected({
  isApprovals,
  onBulkDeadlineMissedReasonChangeHandler,
  onStatusBulkChangeHandler,
  openArchiveModal,
  openScheduleModal,
  selectedTasksCount = 0,
  t,
  taskScope,
}: ActionsForSelectedArgs) {
  if (isApprovals) {
    const chipLeftIcon = (statusItem: TaskStatusEnum) => (
      <Chip
        leftIcon="circle"
        type={getTaskStatusChipType(statusItem)}
        size={ChipSizes.smallLeftIconOnly}
      />
    );
    const options = [
      { value: TaskStatusEnum.Validated, title: t('validated') },
      { value: TaskStatusEnum.OnValidation, title: t('on-validation') },
      { value: TaskStatusEnum.Rejected, title: t('rejected') },
    ];
    return [
      <ButtonWithDropDown
        key="change-status"
        popoverProps={{
          width: 155,
        }}
        buttonProps={{
          buttonText: `${t('change-status')} (${selectedTasksCount})`,
          buttonType: ButtonTypes.primaryOutlined,
        }}
        navItems={options.map(({ value, title }) => ({
          id: value,
          title,
          onClick: () => onStatusBulkChangeHandler(value),
          type: NavItemTypeEnum.Secondary,
          leftIcon: () => chipLeftIcon(value),
        }))}
      />,
    ];
  }
  if (taskScope === TaskScopeNameEnum.Pending) return [];
  if (taskScope === TaskScopeNameEnum.MyApprovals) return [];
  if (taskScope === TaskScopeNameEnum.Completed) {
    return [
      <Button
        key="archive"
        buttonText={t('archive')}
        buttonType={ButtonTypes.primaryOutlined}
        leftIcon="archive"
        leftIconClassName={materialSymbolsOutlined}
        onClick={openArchiveModal}
      />,
    ];
  }
  if (taskScope === TaskScopeNameEnum.Active) {
    return [
      <Button
        key="change-schedule"
        buttonText={t('reschedule')}
        buttonType={ButtonTypes.primaryOutlined}
        leftIcon="schedule"
        leftIconClassName={materialSymbolsOutlined}
        onClick={openScheduleModal}
      />,
      <Button
        key="archive"
        buttonText={t('archive')}
        buttonType={ButtonTypes.primaryOutlined}
        leftIcon="archive"
        leftIconClassName={materialSymbolsOutlined}
        onClick={openArchiveModal}
      />,
    ];
  }
  if (taskScope === TaskScopeNameEnum.DeadlineMissed) {
    const chipLeftIcon = (statusItem: TaskDeadlineMissedReasonEnum) => (
      <Chip
        leftIcon="circle"
        type={getDeadlineMissedReasonChipType(statusItem)}
        size={ChipSizes.smallLeftIconOnly}
      />
    );
    const options = [
      {
        value: TaskDeadlineMissedReasonEnum.SiteClosed,
        title: t('site-closed'),
      },
      {
        value: TaskDeadlineMissedReasonEnum.UnderConstruction,
        title: t('under-construction'),
      },
      {
        value: TaskDeadlineMissedReasonEnum.Inaccessible,
        title: t('inaccessible'),
      },
      {
        value: TaskDeadlineMissedReasonEnum.StaffProblem,
        title: t('staff-problem'),
      },
      {
        value: TaskDeadlineMissedReasonEnum.Other,
        title: t('other'),
      },
    ];
    return [
      <ButtonWithDropDown
        key="change-reason"
        popoverProps={{
          width: 170,
        }}
        buttonProps={{
          buttonText: `${t('change-reason')} (${selectedTasksCount})`,
          buttonType: ButtonTypes.primaryOutlined,
        }}
        navItems={options.map(({ value, title }) => ({
          id: value,
          title,
          onClick: () => onBulkDeadlineMissedReasonChangeHandler(value),
          type: NavItemTypeEnum.Secondary,
          leftIcon: () => chipLeftIcon(value),
        }))}
      />,
    ];
  }
  return [];
}
