import {
  useFormCategoriesSearchListLazyQuery,
  useTasksSearchListLazyQuery,
} from 'graphql-common';
import { getFormCategoriesLoadOptions as getFormCategoriesLoadOptionsFn } from 'utils/fetch/formCategoriesSearchList';
import { getTasksLoadOptions as getTasksLoadOptionsFn } from 'utils/fetch/tasksSearchList';
import { GetLoadOptions } from '@lib/components/Select/types';

interface Props {
  id: string;
}

export default function useSearchListQueries(props: Props) {
  const { id } = props;

  // Form category
  const [fetchFormCategoriesLazyQuery] = useFormCategoriesSearchListLazyQuery({
    fetchPolicy: 'no-cache',
  });

  const getFormCategoriesLoadOptions: GetLoadOptions = () =>
    getFormCategoriesLoadOptionsFn(fetchFormCategoriesLazyQuery);

  const [fetchTasksLazyQuery] = useTasksSearchListLazyQuery({
    fetchPolicy: 'no-cache',
  });

  const getTasksLoadOptions: GetLoadOptions = () =>
    getTasksLoadOptionsFn({
      fetchLazyQuery: fetchTasksLazyQuery,
      scope: {
        custom: {
          groupedLinkedToFormTemplate: { templateId: id },
        },
      },
      selectedSiteIds: [],
    });

  return {
    getFormCategoriesLoadOptions,
    getTasksLoadOptions,
  };
}
