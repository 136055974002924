import { Values } from '@lib/interfaces/form';
import _get from 'lodash/get';
import { InterventionCreateInputObject } from 'graphql-common';
import FieldNames from '@lib/enums/fieldNames/interventionFieldNames';
import mergeAndUnlinkMultiSelectValues from '@lib/utils/mergeAndUnlinkMultiSelectValues';
import { getMinutesCountFromTime } from '@lib/utils/getMinutesCountFromTime';
import { SelectOption } from '@lib/components/Select/types';
import mergeAndUnlinkAttachedFiles from '@lib/utils/mergeAndUnlinkAttachedFiles';

export default function getInterventionPreparedUpdateDataFromValues(
  values: Values,
  prevValues: Values,
) {
  const assigneesValue = (_get(values, FieldNames.Assignee) ||
    []) as SelectOption[];
  const prevAssigneesValue =
    (_get(prevValues, FieldNames.Assignee) as SelectOption[]) || [];
  const assignees = assigneesValue.map(({ value }) => ({
    id: `${value}`,
  }));
  const prevAssignees = prevAssigneesValue.map(({ value }) => ({
    id: `${value}`,
  }));

  const attachmentsAttached = mergeAndUnlinkAttachedFiles(
    values,
    prevValues,
    FieldNames.AttachmentsAttached,
  );

  return {
    name: _get(values, FieldNames.Name),
    description: _get(values, FieldNames.Description),
    attachmentsAttached: attachmentsAttached.length
      ? attachmentsAttached
      : undefined,
    code: _get(values, FieldNames.Code),
    durationTime: getMinutesCountFromTime(
      _get(values, FieldNames.DurationTime, '') as string,
    ),
    estimatedTime: getMinutesCountFromTime(
      _get(values, FieldNames.EstimatedTime, '') as string,
    ),
    estimatedDate: _get(values, FieldNames.EstimatedDate),
    logisticTime: getMinutesCountFromTime(
      _get(values, FieldNames.LogisticTime, '') as string,
    ),
    status: _get(values, [FieldNames.Status, 'value']),
    priority: _get(values, [FieldNames.Priority, 'value']),
    supervisorId: _get(values, [FieldNames.Supervisor, 'value'], null),
    categoryId: _get(values, [FieldNames.Category, 'value']),
    assignees: mergeAndUnlinkMultiSelectValues(prevAssignees, assignees),
  } as InterventionCreateInputObject;
}
