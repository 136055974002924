import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FetchResult } from '@apollo/client';
import { AuthenticationPasswordsForgotMutation } from 'graphql-common';
import Typography from '@lib/components/Typography/Typography';
import styles from './ProfileForm.module.scss';

interface Props {
  closeChangePasswordModal: () => void;
  onSendPasswordResetHandler: () => Promise<
    FetchResult<AuthenticationPasswordsForgotMutation>
  >;
}

function ForgotPasswordLink({
  onSendPasswordResetHandler,
  closeChangePasswordModal,
}: Props) {
  const { t } = useTranslation();
  return (
    <div className={styles.forgotPassword} key="forgot-password">
      <Typography
        variant="link"
        component={NavLink}
        onClick={() => {
          onSendPasswordResetHandler().then(() => {
            closeChangePasswordModal();
          });
        }}
      >
        {t('forgot-password-link')}
      </Typography>
    </div>
  );
}

export default ForgotPasswordLink;
