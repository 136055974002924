import React, { forwardRef, memo } from 'react';
import CheckBox from '@lib/components/CheckBox/CheckBox';

export enum CheckBoxWrapViews {
  row = 'row',
  list = 'list',
}

export interface CheckBoxGroupOption {
  label: string;
  value: string;
}

export interface CheckBoxSingleBoolProps {
  CheckBoxComponent?: React.JSX.ElementType;
  itemClass?: string;
  label?: React.ReactNode;
  name: string;
  onChange: (v: boolean) => void;
  options: CheckBoxGroupOption[];
  value?: boolean;
  wrapClass?: string;
  wrapView?: CheckBoxWrapViews;
  iconProps?: {
    icon: string;
    iconChecked: string;
    symbolsOutlined?: boolean;
  };
}

const CheckBoxSingleBool = forwardRef(
  (
    props: CheckBoxSingleBoolProps,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ref: React.ForwardedRef<HTMLInputElement>,
  ) => {
    const {
      CheckBoxComponent = CheckBox,
      iconProps,
      itemClass,
      label,
      name: fieldName,
      onChange,
      options,
      value: fieldValue,
      wrapClass,
      wrapView,
      ...rest
    } = props;

    const onChangeHandler = (v: React.ChangeEvent<HTMLInputElement>) => {
      onChange(v.target.checked);
    };

    return (
      <CheckBoxComponent
        {...rest}
        checked={!!fieldValue}
        iconProps={iconProps}
        id={fieldName}
        label={label}
        name={fieldName}
        onChange={onChangeHandler}
      />
    );
  },
);

export default memo(CheckBoxSingleBool);
