import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
  TaskScopeNameEnum,
  useTaskBulkCreateSameMutation,
  useTaskBulkSchedulingMutation,
  useTaskBulkUpdateStatusMutation,
  useTaskDestroyMutation,
  useTaskUpdateMutation,
} from 'graphql-common';
import toast from '@lib/components/Toast/Toast';
import { showGraphQLErrors } from '@lib/utils/graphQLErrors';
import { APP_URLS } from 'constants/urls';

interface Props {
  closeScheduleModal?: () => void;
  taskQueryRefetch?: () => void;
  taskScheduleQueryRefetch?: () => void;
}

export default function useMutations(props: Props) {
  const { closeScheduleModal, taskQueryRefetch, taskScheduleQueryRefetch } =
    props;
  const { id = '' } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [createMutation, createMutationResult] = useTaskBulkCreateSameMutation({
    onCompleted: ({ data }) => {
      if (Array.isArray(data) && data.length === 1) {
        navigate(
          APP_URLS.dashboard.tasks.view.getDynamicPath({
            pathParts: {
              id: data[0],
            },
          }),
        );
      } else {
        navigate(
          APP_URLS.dashboard.tasks.index.getDynamicPath({
            params: {
              tab: TaskScopeNameEnum.Pending,
            },
          }),
        );
      }
      toast({ content: t('create-task-success') });
    },
    onError: (error) => showGraphQLErrors(error, t),
  });

  const [updateMutation, updateMutationResult] = useTaskUpdateMutation({
    onCompleted: () => {
      navigate(
        APP_URLS.dashboard.tasks.view.getDynamicPath({ pathParts: { id } }),
      );
      toast({ content: t('update-task-success') });
    },
    onError: (error) => showGraphQLErrors(error, t),
  });

  const [destroyTaskMutation] = useTaskDestroyMutation({
    onCompleted: () => {
      navigate(APP_URLS.dashboard.tasks.index.getPathWithQuery());
    },
    onError: (error) => showGraphQLErrors(error, t),
  });

  const [taskBulkUpdateStatusMutation, taskBulkUpdateStatusResult] =
    useTaskBulkUpdateStatusMutation({
      onCompleted: () => {
        if (taskQueryRefetch) taskQueryRefetch();
        toast({ content: t('task-status-updated-successfully') });
      },
      onError: (error) => showGraphQLErrors(error, t),
    });

  const [taskBulkSchedulingMutation, taskBulkSchedulingResult] =
    useTaskBulkSchedulingMutation({
      onCompleted: ({ data: responseData }) => {
        if (responseData) {
          toast({ content: t('task-frequency-successfully-updated') });
        }
        if (taskQueryRefetch) taskQueryRefetch();
        if (taskScheduleQueryRefetch) taskScheduleQueryRefetch();
        if (closeScheduleModal) closeScheduleModal();
      },
      onError: (error) => showGraphQLErrors(error, t),
    });

  return {
    createMutation,
    createMutationResult,
    destroyTaskMutation,
    taskBulkSchedulingMutation,
    taskBulkSchedulingResult,
    taskBulkUpdateStatusMutation,
    taskBulkUpdateStatusResult,
    updateMutation,
    updateMutationResult,
  };
}
