import React from 'react';
import { TFunction } from 'i18next';
import _get from 'lodash/get';
import Select from '@lib/components/Select/Select';
import { LoadOptions } from '@lib/components/Select/types';
import ChipCircleOption from '@lib/components/Select/components/ChipCircleOption';
import { InputSizes } from '@lib/components/Input/enums';
import ButtonControl from '@lib/components/Select/components/ButtonControl';
import ButtonValueContainer from '@lib/components/Select/components/ButtonValueContainer';

type Args = {
  onBulkActivate: () => void;
  onBulkDeactivate: () => void;
  onTeamMembersUpdate: (id: string) => void;
  selectedItemsCount?: number;
  t: TFunction<'translation', undefined>;
  teamsLoadOptions: LoadOptions;
  isViewOnly: boolean;
};

export default function getUsersTableActionsForSelected(args: Args) {
  const {
    onBulkActivate,
    onBulkDeactivate,
    onTeamMembersUpdate,
    selectedItemsCount = 0,
    t,
    teamsLoadOptions,
    isViewOnly,
  } = args;
  return isViewOnly
    ? []
    : [
        <div key="bulk-move-to-team">
          <Select
            name="team"
            onChange={(v) => onTeamMembersUpdate(_get(v, 'value', ''))}
            loadOptions={teamsLoadOptions}
            isAsync
            defaultOptions={false}
            components={{
              Control: ButtonControl,
              ValueContainer: ButtonValueContainer,
              Option: ChipCircleOption,
            }}
            isClearable={false}
            size={InputSizes.medium}
            buttonText={`${t('move-to-team')} (${selectedItemsCount})`}
            isSearchable={false}
            usePortal
          />
        </div>,
        <div key="bulk-status-change">
          <Select
            name="team"
            onChange={(v) => {
              const value = _get(v, 'value');
              if (value === 'active') {
                onBulkActivate();
              } else if (value === 'deactivated') {
                onBulkDeactivate();
              }
            }}
            options={[
              { value: 'active', label: t('active') },
              { value: 'deactivated', label: t('deactivated') },
            ]}
            components={{
              Control: ButtonControl,
              ValueContainer: ButtonValueContainer,
            }}
            isClearable={false}
            size={InputSizes.medium}
            buttonText={`${t('status')} (${selectedItemsCount})`}
            isSearchable={false}
            usePortal
          />
        </div>,
      ];
}
