import { InterventionPriorityEnum, TaskPriorityEnum } from 'graphql-common';
import { ChipTypes } from '@lib/components/Chip/Chip';

export function getTaskPriorityOptions() {
  return [
    {
      labelKey: 'priority-high',
      value: TaskPriorityEnum.High,
      color: ChipTypes.chipRed,
    },
    {
      labelKey: 'priority-medium',
      value: TaskPriorityEnum.Medium,
      color: ChipTypes.chipYellow,
    },
    {
      labelKey: 'priority-low',
      value: TaskPriorityEnum.Low,
      color: ChipTypes.chipGreen,
    },
  ];
}

export function getTaskPriorityOption(value: TaskPriorityEnum) {
  const options = getTaskPriorityOptions();
  return options.find((option) => option.value === value);
}

export function getInterventionPriorityOptions() {
  return [
    {
      labelKey: 'priority-high',
      value: InterventionPriorityEnum.High,
      color: ChipTypes.chipRed,
    },
    {
      labelKey: 'priority-medium',
      value: InterventionPriorityEnum.Medium,
      color: ChipTypes.chipYellow,
    },
    {
      labelKey: 'priority-low',
      value: InterventionPriorityEnum.Low,
      color: ChipTypes.chipGreen,
    },
  ];
}

export function getInterventionPriorityOption(value: InterventionPriorityEnum) {
  const options = getInterventionPriorityOptions();
  return options.find((option) => option.value === value);
}

export function getPriorityChipType(
  priority: TaskPriorityEnum | InterventionPriorityEnum,
) {
  let type = ChipTypes.chipDisabled;
  if (
    priority === TaskPriorityEnum.High ||
    priority === InterventionPriorityEnum.High
  )
    type = ChipTypes.chipRed;
  if (
    priority === TaskPriorityEnum.Medium ||
    priority === InterventionPriorityEnum.Medium
  )
    type = ChipTypes.chipOrange;
  if (
    priority === TaskPriorityEnum.Low ||
    priority === InterventionPriorityEnum.Low
  )
    type = ChipTypes.chipGreen;
  return type;
}
