import React from 'react';
import Collapse from '@lib/components/Collapse/Collapse';
import { BaseItem, CellProps } from '@lib/components/Table/types';

interface Props {
  minHeight: number;
}

function CollapseCell<T extends BaseItem>({
  value,
  minHeight,
}: CellProps<T> & Props) {
  return <Collapse minHeight={minHeight}>{value}</Collapse>;
}

export default CollapseCell;
