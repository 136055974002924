export enum FormType {
  download = 'download',
  email = 'email',
}

export enum FieldNames {
  fileFormat = 'fileFormat',
  formType = 'formType',
  message = 'message',
  pageSize = 'pageSize',
  userId = 'userId',
}
