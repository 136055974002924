import React from 'react';
import { useTranslation } from 'react-i18next';
import { ApolloError } from '@apollo/client/errors';
import { Asset } from 'graphql-common';
import Button, { ButtonTypes } from '@lib/components/Button/Button';
import Table from '@lib/components/Table/Table';
import CircleLoader from '@lib/components/CircleLoader/CircleLoader';
import PageHeader from '@lib/layouts/DashboardLayout/PageHeader/PageHeader';
import PageBody from '@lib/layouts/DashboardLayout/PageBody/PageBody';
import ListEmptyState from '@lib/components/ListEmptyState/ListEmptyState';
import Search from '@lib/components/Search/Search';
import ErrorPage from '@lib/components/ErrorPage/ErrorPage';
import getStatusFromError from '@lib/utils/getStatusFromError';
import { NavItem } from '@lib/components/Menu/Menu';
import { Column } from '@lib/components/Table/types';
import { UseBulkSelectResult } from '@lib/hooks/useTableItemsBulkSelect';
import ViewOnlyChip from '@lib/components/ViewOnlyChip/ViewOnlyChip';

interface Props {
  assetsError?: ApolloError;
  assetsFirstLoading: boolean;
  assetsLoading: boolean;
  collection: Asset[];
  onAdd: () => void;
  searchQuery?: string;
  tableActionsForSelected?: React.ReactNode[];
  tableActionsMenu?: NavItem<Asset>[];
  tableColumns: Column<Asset>[];
  totalEntries: number;
  useBulkSelectResult: UseBulkSelectResult<Asset>;
  isViewOnly: boolean;
}

function Assets(props: Props) {
  const {
    assetsError,
    assetsFirstLoading,
    assetsLoading,
    collection,
    onAdd,
    searchQuery,
    tableActionsForSelected,
    tableActionsMenu,
    tableColumns,
    totalEntries,
    useBulkSelectResult,
    isViewOnly,
  } = props;
  const { t } = useTranslation();

  const emptyState = (
    <ListEmptyState
      title={t('no-assets-yet')}
      text={t('no-assets-text')}
      actions={
        isViewOnly
          ? []
          : [
              <Button
                key="add"
                buttonText={t('add-new-asset')}
                buttonType={ButtonTypes.primaryFilled}
                leftIcon="add"
                onClick={onAdd}
              />,
            ]
      }
    />
  );

  if (assetsError) {
    return (
      <ErrorPage
        status={getStatusFromError(assetsError)}
        description={assetsError?.message}
      />
    );
  }

  return (
    <>
      <PageHeader title={t('assets')}>
        {isViewOnly ? (
          <ViewOnlyChip />
        ) : (
          <Button
            buttonText={t('add-new-asset')}
            leftIcon="add"
            onClick={onAdd}
          />
        )}
      </PageHeader>
      <PageBody>
        {assetsFirstLoading ? (
          <CircleLoader />
        ) : (
          <>
            {(!!collection?.length || searchQuery) && (
              <Search placeholder={t('assets-search-placeholder')} />
            )}
            <Table
              actionsForSelected={tableActionsForSelected}
              actionsMenu={tableActionsMenu}
              columns={tableColumns}
              counterText={t('assets')}
              data={collection}
              emptyState={emptyState}
              fullHeight
              id="assets"
              loading={assetsLoading}
              totalEntries={totalEntries}
              useBulkSelectResult={useBulkSelectResult}
            />
          </>
        )}
      </PageBody>
    </>
  );
}

export default Assets;
