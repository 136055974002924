import React from 'react';
import { useTranslation } from 'react-i18next';
import TabBar from '@lib/components/TabBar/TabBar';
import PageBody from '@lib/layouts/DashboardLayout/PageBody/PageBody';
import { TabBarItem as TabBarItemType } from '@lib/components/TabBar/constants';
import CircleLoader from '@lib/components/CircleLoader/CircleLoader';
import { UseModalManagementResult } from '@lib/hooks/useModalManagement';
import { UseTeamQueriesResult } from 'routes/Teams/utils/useTeamQueries';
import { TeamViewMode } from '../enums';
import getTeamViewPageTabs from '../utils/getTeamViewPageTabs';
import { UseTeamActionsResult } from '../utils/useTeamActions';
import TeamViewDetails from './TeamViewDetails';
import TeamViewMembers from './TeamViewMembers';

type Props = {
  useModalManagementResult: UseModalManagementResult;
  useTeamActionsResult: UseTeamActionsResult;
  useTeamQueriesResult: UseTeamQueriesResult;
  viewMode: TeamViewMode;
  isViewOnly: boolean;
};

export default function TeamViewPage({
  useModalManagementResult,
  useTeamActionsResult,
  useTeamQueriesResult,
  viewMode,
  isViewOnly,
}: Props) {
  const { t } = useTranslation();
  const { useUsersQueryHookResult, useTeamQueryHookResult } =
    useTeamQueriesResult;
  const { onViewChange } = useTeamActionsResult;
  const { firstLoading } = useTeamQueryHookResult;
  const { allIds } = useUsersQueryHookResult;

  const tabs = getTeamViewPageTabs({ t, membersCounter: allIds.length });

  return (
    <PageBody withoutHeader>
      {firstLoading ? (
        <CircleLoader />
      ) : (
        <>
          <TabBar
            items={tabs}
            active={viewMode}
            onActiveChange={({ value }: TabBarItemType) =>
              onViewChange(value as TeamViewMode)
            }
          />
          {viewMode === TeamViewMode.ViewDetails && (
            <TeamViewDetails
              useModalManagementResult={useModalManagementResult}
              useTeamActionsResult={useTeamActionsResult}
              useTeamQueriesResult={useTeamQueriesResult}
              isViewOnly={isViewOnly}
            />
          )}
          {viewMode === TeamViewMode.ViewMembers && (
            <TeamViewMembers
              useModalManagementResult={useModalManagementResult}
              useTeamActionsResult={useTeamActionsResult}
              useTeamQueriesResult={useTeamQueriesResult}
              isViewOnly={isViewOnly}
            />
          )}
        </>
      )}
    </PageBody>
  );
}
