import _get from 'lodash/get';
import {
  ComponentEnum,
  LevelEnum,
  useAssetsSearchListLazyQuery,
  useFormTemplatesSearchListLazyQuery,
  useFormTemplateVersionsSearchListLazyQuery,
  useSiteAreasSearchListLazyQuery,
  useSitesSearchListLazyQuery,
  useUsersSearchListLazyQuery,
} from 'graphql-common';
import { GetLoadOptions } from '@lib/components/Select/types';
import { FieldNames } from 'routes/Task/enums';
import useOutletContext, { ContextProps } from 'hooks/useOutletContext';
import { getSitesLoadOptions as getSitesLoadOptionsFn } from 'utils/fetch/sitesSearchList';
import { getSiteAreasLoadOptions as getSiteAreasLoadOptionsFn } from 'utils/fetch/siteAreasSearchList';
import { getAssetsLoadOptions as getAssetsLoadOptionsFn } from 'utils/fetch/assetsSearchList';
import { getSiteMembershipsLoadOptions as getSiteMembershipsLoadOptionsFn } from 'utils/fetch/siteMembershipsSearchList';
import { getFormTemplatesLoadOptions as getFormTemplatesLoadOptionsFn } from 'utils/fetch/formTemplatesSearchList';
import { getFormTemplateVersionsLoadOptions as getFormTemplateVersionsLoadOptionsFn } from 'utils/fetch/formTemplateVersionsSearchList';

export default function useSearchListQueries() {
  const outletContext: ContextProps = useOutletContext();
  const currentUserId = _get(outletContext, 'user.id');

  // Site Memberships
  const [fetchSiteMembershipsSearchListLazyQuery] = useUsersSearchListLazyQuery(
    {
      fetchPolicy: 'network-only',
    },
  );

  const getApproversLoadOptions: GetLoadOptions = (name, values) =>
    getSiteMembershipsLoadOptionsFn(
      fetchSiteMembershipsSearchListLazyQuery,
      _get(values, `${FieldNames.site}.value`, '') as string,
      undefined,
      {
        component: ComponentEnum.TasksManagement,
        level: LevelEnum.Write,
      },
    );

  // Sites query
  const [fetchSitesSearchListLazyQuery] = useSitesSearchListLazyQuery({
    fetchPolicy: 'network-only',
  });
  const getSitesLoadOptions: GetLoadOptions = () =>
    getSitesLoadOptionsFn(fetchSitesSearchListLazyQuery);

  // Site areas query
  const [fetchSiteAreasSearchListLazyQuery] = useSiteAreasSearchListLazyQuery({
    fetchPolicy: 'network-only',
  });
  const getSiteAreasLoadOptions: GetLoadOptions = (name, values) =>
    getSiteAreasLoadOptionsFn(
      fetchSiteAreasSearchListLazyQuery,
      _get(values, [FieldNames.site, 'value'], '') as string,
    );

  // Assets query
  const [fetchAssetsSearchListLazyQuery] = useAssetsSearchListLazyQuery({
    fetchPolicy: 'network-only',
  });
  const getAssetsLoadOptions: GetLoadOptions = (name, values) => {
    const selectedSiteId = _get(values, [FieldNames.site, 'value']);
    const siteAreaId = _get(
      values,
      name.replace(FieldNames.asset, `${FieldNames.siteArea}.value`),
      '',
    ) as string;
    return getAssetsLoadOptionsFn(
      fetchAssetsSearchListLazyQuery,
      selectedSiteId,
      siteAreaId,
    );
  };

  // Form Templates query
  const [formTemplatesLazyQuery] = useFormTemplatesSearchListLazyQuery({
    fetchPolicy: 'network-only',
  });

  const getTemplatesLoadOptions: GetLoadOptions = () =>
    getFormTemplatesLoadOptionsFn(formTemplatesLazyQuery);

  // Form Template versions query
  const [formTemplateVersionsLazyQuery] =
    useFormTemplateVersionsSearchListLazyQuery({
      fetchPolicy: 'network-only',
    });

  const getTemplateVersionsLoadOptions: GetLoadOptions = (name, values) => {
    return getFormTemplateVersionsLoadOptionsFn(
      formTemplateVersionsLazyQuery,
      _get(values, [FieldNames.template, 'value'], '') as string,
      true,
    );
  };

  return {
    currentUserId,
    getApproversLoadOptions,
    getAssetsLoadOptions,
    getSiteAreasLoadOptions,
    getSitesLoadOptions,
    getTemplateVersionsLoadOptions,
    getTemplatesLoadOptions,
  };
}
