import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import _get from 'lodash/get';
import _toNumber from 'lodash/toNumber';
import { MutationResult } from '@apollo/client';
import { ApolloError } from '@apollo/client/errors';
import { useLocalStorage } from 'usehooks-ts';
import {
  AssetCounterBulkQrCodeCreateMutation,
  Counter,
  CounterCreateInputObject,
  CounterUpdateInputObject,
} from 'graphql-common';
import toast from '@lib/components/Toast/Toast';
import { Values } from '@lib/interfaces/form';
import { FormRefType } from '@lib/components/ReactHookForm/types';
import { UseBulkSelectResult } from '@lib/hooks/useTableItemsBulkSelect';
import useSearchParam from '@lib/hooks/useSearchParam';
import usePaginationParams from '@lib/hooks/usePaginationParams';
import AssetCounterFieldNames from '@lib/enums/fieldNames/assetCounterFieldNames';
import onDestroyListItemCallBack from '@lib/utils/onDestroyListItemCallBack';
import tableItemsBulkAction from '@lib/utils/tableItemsBulkAction';
import getAllTableItemsIds from '@lib/utils/getAllTableItemsIds';
import { SubmitAction } from '@lib/enums/form';
import useAssetCountersQueryHook, {
  UseAssetCountersQueryHookResult,
} from 'utils/fetch/useAssetCountersQueryHook';
import useAssetCounterQueryHook, {
  UseAssetCounterQueryHookResult,
} from 'utils/fetch/useAssetCounterQueryHook';
import AssetCounterIndexFieldNames from '@lib/enums/fieldNames/assetCounterIndexFieldNames';
import { CounterIndexAction } from 'components/AssetCounterIndexModalForm/enums';
import { APP_URLS } from 'constants/urls';
import {
  COUNTER_MONITORING_LAST_ASSET_ID,
  COUNTER_MONITORING_LAST_COUNTER_IDS,
} from '@lib/enums/localStorageKeys';
import getPreparedAssetCounterDataFromValues from './getPreparedAssetCounterDataFromValues';
import { UseModalManagementResult } from './useModalManagement';
import { UseActionsResult } from './useActions';
import { AssetViewMode } from '../enums';
import useCounterMutations from './useCounterMutations';
import useQrCodeActions from './useQrCodeActions';

interface UseCounterActionsProps {
  useActionsResult: UseActionsResult;
  formRef: FormRefType;
  submitNewItemRef: React.MutableRefObject<SubmitAction | undefined>;
  useModalManagementResult: UseModalManagementResult;
  useBulkSelectResult?: UseBulkSelectResult<Counter>;
  viewMode: AssetViewMode;
}

export interface UseCounterActionsResult {
  assetCounterIndexMutationError: ApolloError | undefined;
  assetCounterIndexMutationLoading: boolean;
  assetCounterMutationError: ApolloError | undefined;
  assetCounterMutationLoading: boolean;
  counterQueryHook: UseAssetCounterQueryHookResult;
  countersQueryHook: UseAssetCountersQueryHookResult;
  onCloseModal: () => void;
  onConfirmedCounterDestroy: () => void;
  onCounterFormSubmit: (values: Values) => void;
  onCounterIndexFormSubmit: (values: Values) => void;
  onCounterQrCodeCreate: (values: Values) => void;
  openCounterBulkDestroyModal: () => void;
  openCounterDestroyModal: (item?: Counter) => void;
  openCounterEditModal: (item?: Counter) => void;
  openCounterIndexFormModal: (item?: Counter) => void;
  openCounterMonitoring: (counterId: string) => void;
  openGetQrCodeFormModal: (item?: Counter) => void;
  qrCodeCreateMutationResult: MutationResult<AssetCounterBulkQrCodeCreateMutation>;
}

export default function useCounterActions(
  props: UseCounterActionsProps,
): UseCounterActionsResult {
  const {
    formRef,
    submitNewItemRef,
    useActionsResult,
    useModalManagementResult,
    useBulkSelectResult,
    viewMode,
  } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const [searchQuery] = useSearchParam();
  const [paginationParams] = usePaginationParams();

  const [, setDefaultAssetId] = useLocalStorage(
    COUNTER_MONITORING_LAST_ASSET_ID,
    '',
  );
  const [defaultCounterIdsMap, setDefaultCounterIdsMap] = useLocalStorage(
    COUNTER_MONITORING_LAST_COUNTER_IDS,
    {},
  );

  const useMutationsResult = useCounterMutations();
  const { assetQueryHook, assetId: currentAssetId } = useActionsResult;

  // Counters
  const counterQueryHook = useAssetCounterQueryHook({
    id: useBulkSelectResult?.selectedSingleTableItem?.id,
    assetId: currentAssetId,
    skip:
      !(currentAssetId && useBulkSelectResult?.selectedSingleTableItem?.id) ||
      viewMode !== AssetViewMode.Counters,
  });
  const countersQueryHook = useAssetCountersQueryHook({
    paginationParams,
    orderingParams: paginationParams,
    searchQuery,
    skip: viewMode !== AssetViewMode.Counters,
    assetId: currentAssetId,
  });

  // Actions
  const onGetAllIds = () =>
    getAllTableItemsIds<Counter>({
      fetchIds: countersQueryHook.fetchIds,
      useBulkSelectResult,
    });

  const onCloseModal = () => {
    useBulkSelectResult?.handleUnselectAllTableItems();
    useModalManagementResult.closeAllModals();
    useMutationsResult.assetCounterCreateMutationResult?.reset();
    useMutationsResult.assetCounterUpdateMutationResult?.reset();
    useMutationsResult.assetCounterMeasureCreateMutationResult?.reset();
    useMutationsResult.assetCounterResetToZeroMutationResult?.reset();
  };

  const onCounterCreate = (values: Values) => {
    useMutationsResult.assetCounterCreateMutation({
      variables: {
        attributes:
          getPreparedAssetCounterDataFromValues<CounterCreateInputObject>({
            values,
            assetId: currentAssetId,
          }),
      },
      onCompleted: () => {
        if (submitNewItemRef.current === SubmitAction.CreateAndNew) {
          formRef.current?.reset({
            [AssetCounterFieldNames.Name]: null,
          });
        } else {
          onCloseModal();
        }
        assetQueryHook.refetch();
        countersQueryHook.refetch();
        toast({ content: t('create-asset-counter-success') });
      },
    });
  };

  const onCounterEdit = (values: Values) => {
    if (currentAssetId) {
      useMutationsResult.assetCounterUpdateMutation({
        variables: {
          id: _get(useBulkSelectResult, ['selectedSingleTableItem', 'id'], ''),
          assetId: currentAssetId,
          attributes:
            getPreparedAssetCounterDataFromValues<CounterUpdateInputObject>({
              values,
              isEdit: true,
            }),
        },
        onCompleted: () => {
          onCloseModal();
          countersQueryHook.refetch();
          toast({ content: t('update-asset-counter-success') });
        },
      });
    }
  };

  const onCounterFormSubmit = (values: Values, isEditForm?: boolean) => {
    if (isEditForm) {
      onCounterEdit(values);
    } else {
      onCounterCreate(values);
    }
  };

  const openCounterDestroyModal = (item?: Counter) => {
    if (item?.id) {
      useBulkSelectResult?.handleSelectSingleTableItem(item);
      useModalManagementResult.openCounterDestroyModal();
    }
  };

  const openCounterBulkDestroyModal = () => {
    useModalManagementResult.openCounterDestroyModal();
  };

  const openCounterEditModal = (item?: Counter) => {
    if (item?.id) {
      useBulkSelectResult?.handleSelectSingleTableItem(item);
      useModalManagementResult.openCounterFormModal();
    }
  };

  const onConfirmedCounterDestroy = () => {
    useModalManagementResult.closeCounterDestroyModal();
    toast({
      content: t('destroy-asset-counter-success'),
      closeCallback: () => {
        if (currentAssetId) {
          tableItemsBulkAction<Counter>({
            action: (idsValue?: string[]) => {
              if (idsValue) {
                useMutationsResult.assetCounterDestroyMutation({
                  variables: { ids: idsValue, assetId: currentAssetId },
                  onCompleted: () => {
                    useBulkSelectResult?.handleUnselectAllTableItems();
                    onDestroyListItemCallBack({
                      collection: countersQueryHook.collection,
                      navigate,
                      pathname,
                      refetch: () => {
                        assetQueryHook.refetch();
                        countersQueryHook.refetch();
                      },
                      search,
                      totalPages: countersQueryHook.totalPages,
                    });
                  },
                });
              }
            },
            useBulkSelectResult,
            onGetAllIds,
          });
        }
      },
    });
  };

  const onCounterMeasureCreate = (values: Values) => {
    useMutationsResult.assetCounterMeasureCreateMutation({
      variables: {
        assetId: currentAssetId,
        attributes: {
          index: _toNumber(_get(values, AssetCounterIndexFieldNames.Index)),
          counterId: _get(
            useBulkSelectResult,
            ['selectedSingleTableItem', 'id'],
            '',
          ) as string,
        },
      },
      onCompleted: () => {
        onCloseModal();
        assetQueryHook.refetch();
        countersQueryHook.refetch();
        toast({ content: t('update-asset-counter-success') });
      },
    });
  };

  const onCounterResetToZero = () => {
    useMutationsResult.assetCounterResetToZeroMutation({
      variables: {
        assetId: currentAssetId,
        id: _get(
          useBulkSelectResult,
          ['selectedSingleTableItem', 'id'],
          '',
        ) as string,
      },
      onCompleted: () => {
        onCloseModal();
        assetQueryHook.refetch();
        countersQueryHook.refetch();
        toast({ content: t('update-asset-counter-success') });
      },
    });
  };

  const onCounterIndexFormSubmit = (values: Values) => {
    const action = _get(values, [AssetCounterIndexFieldNames.Action, 'value']);
    if (action === CounterIndexAction.Index) {
      onCounterMeasureCreate(values);
    } else if (action === CounterIndexAction.Zero) {
      onCounterResetToZero();
    }
  };

  const openCounterIndexFormModal = (item?: Counter) => {
    if (item?.id) {
      useBulkSelectResult?.handleSelectSingleTableItem(item);
      useModalManagementResult.openCounterIndexFormModal();
    }
  };

  const openCounterMonitoring = (counterId: string) => {
    setDefaultAssetId(currentAssetId);
    setDefaultCounterIdsMap({
      ...defaultCounterIdsMap,
      [currentAssetId]: counterId,
    });
    window.open(
      APP_URLS.dashboard.assets.counters.getPathWithQuery(),
      '_blank',
    );
  };

  // Get QR codes
  const { onQrCodeCreateAction } = useQrCodeActions({
    onCompleted: onCloseModal,
    qrCodeCreateMutation: useMutationsResult.qrCodeCreateMutation,
    variables: { assetId: currentAssetId },
  });

  const onCounterQrCodeCreate = (values: Values) => {
    const action = (ids: string[]) => onQrCodeCreateAction(values, ids);
    tableItemsBulkAction<Counter>({
      action,
      useBulkSelectResult,
      onGetAllIds,
    });
  };

  const openGetQrCodeFormModal = (item?: Counter) => {
    if (item) useBulkSelectResult?.handleSelectSingleTableItem(item);
    useModalManagementResult.openGetQrCodeFormModal();
  };

  // Data
  const assetCounterMutationLoading =
    _get(useMutationsResult, ['assetCounterCreateMutationResult', 'loading']) ||
    _get(useMutationsResult, ['assetCounterUpdateMutationResult', 'loading']);
  const assetCounterMutationError: ApolloError | undefined =
    _get(useMutationsResult, ['assetCounterCreateMutationResult', 'error']) ||
    _get(useMutationsResult, ['assetCounterUpdateMutationResult', 'error']);
  const assetCounterIndexMutationLoading =
    _get(useMutationsResult, [
      'assetCounterMeasureCreateMutationResult',
      'loading',
    ]) ||
    _get(useMutationsResult, [
      'assetCounterResetToZeroMutationResult',
      'loading',
    ]);
  const assetCounterIndexMutationError: ApolloError | undefined =
    _get(useMutationsResult, [
      'assetCounterMeasureCreateMutationResult',
      'error',
    ]) ||
    _get(useMutationsResult, [
      'assetCounterResetToZeroMutationResult',
      'error',
    ]);

  return {
    assetCounterIndexMutationError,
    assetCounterIndexMutationLoading,
    assetCounterMutationError,
    assetCounterMutationLoading,
    counterQueryHook,
    countersQueryHook,
    onCloseModal,
    onConfirmedCounterDestroy,
    onCounterFormSubmit,
    onCounterIndexFormSubmit,
    onCounterQrCodeCreate,
    openCounterBulkDestroyModal,
    openCounterDestroyModal,
    openCounterEditModal,
    openCounterIndexFormModal,
    openCounterMonitoring,
    openGetQrCodeFormModal,
    qrCodeCreateMutationResult: useMutationsResult.qrCodeCreateMutationResult,
  };
}
