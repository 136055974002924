import _pick from 'lodash/pick';
import _get from 'lodash/get';
import { SiteCreateInputObject, SiteUpdateInputObject } from 'graphql-common';
import { Values } from '@lib/interfaces/form';

export default function getSitePreparedDataFromValues(values: Values) {
  const newValues = _pick(values, [
    'code',
    'description',
    'email',
    'industry',
    'name',
    'phoneNumber',
  ]);

  const conductCodeSections = _get(values, 'conductCodeSections');
  let newConductCodeSections;
  if (conductCodeSections && Array.isArray(conductCodeSections)) {
    newConductCodeSections = conductCodeSections.length
      ? conductCodeSections.map((item, index) => ({
          title: _get(item, 'title'),
          body: _get(item, 'body'),
          position: index + 1,
        }))
      : [];
  }

  const areas = _get(values, 'areas');
  let newAreas;
  if (areas && Array.isArray(areas)) {
    newAreas = areas.length ? areas : undefined;
  }

  let locationPhotoAttached;
  if (_get(values, 'locationPhotoAttached') === null)
    locationPhotoAttached = { _destroy: true };
  if (_get(values, 'locationPhotoAttached.file.id')) {
    locationPhotoAttached = {
      file: _get(values, 'locationPhotoAttached.file'),
      filename: _get(values, 'locationPhotoAttached.filename'),
    };
  }

  let address;
  const addressString = _get(values, 'address');
  const addressLat = _get(values, 'lat');
  const addressLon = _get(values, 'lon');
  if (addressString || addressLat || addressLon) {
    address = {
      string: addressString,
      lat: addressLat,
      lon: addressLon,
    };
  }

  return {
    ...newValues,
    address,
    areas: newAreas,
    userId: _get(values, 'userId.value'),
    conductCodeSections: newConductCodeSections,
    locationPhotoAttached,
  } as SiteCreateInputObject | SiteUpdateInputObject;
}
