import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _get from 'lodash/get';
import {
  FormAnswer,
  FormTemplateQuestionFieldOptionsValidityHintEnum,
  FormTemplateQuestionFieldsKindEnum,
  useFormAnswerReportingQuery,
} from 'graphql-common';
import WhiteBox from '@lib/components/WhiteBox/WhiteBox';
import Typography from '@lib/components/Typography/Typography';
import ErrorPage from '@lib/components/ErrorPage/ErrorPage';
import Table from '@lib/components/Table/Table';
import useOnScreen from '@lib/hooks/useOnScreen';
import {
  GetUrlParams,
  ORDERING_PARAMS,
  OrderingParams,
  PAGINATION_PARAMS,
  PaginationParams,
} from '@lib/enums/urls';
import getStatusFromError from '@lib/utils/getStatusFromError';
import QuestionTypeChip from 'components/FormTemplateBuilder/QuestionTypeChip/QuestionTypeChip';
import SupportiveMessage, {
  SupportiveMessageColor,
  SupportiveMessageIconColor,
  SupportiveMessageIconType,
  SupportiveMessageType,
} from '@lib/components/SupportiveMessage/SupportiveMessage';
import { getColumns } from './tableHelpers';
import styles from './TaskReportingView.module.scss';

function getRowClassName({ validityHint }: FormAnswer) {
  if (
    validityHint === FormTemplateQuestionFieldOptionsValidityHintEnum.Failure
  ) {
    return styles.criticalRow;
  }
  if (
    validityHint === FormTemplateQuestionFieldOptionsValidityHintEnum.Warning
  ) {
    return styles.warningRow;
  }
  return undefined;
}

type ItemProps = {
  fieldId: string;
  kind: FormTemplateQuestionFieldsKindEnum;
  questionDescription?: string | null;
  questionName?: string | null;
  taskId: string;
  templateVersionId: string;
};

export default function TaskReportingViewByQuestion(props: ItemProps) {
  const {
    fieldId,
    kind,
    questionDescription,
    questionName,
    taskId,
    templateVersionId,
  } = props;
  const { t } = useTranslation();
  const ref = useRef(null);
  const { isShowed } = useOnScreen(ref);
  const paginationState = useState<PaginationParams>(PAGINATION_PARAMS);
  const orderingState = useState<OrderingParams>(ORDERING_PARAMS);
  const [paginationParams] = paginationState;
  const [orderingParams] = orderingState;
  const page = Number(_get(paginationParams, GetUrlParams.Page, 1));
  const limit = Number(_get(paginationParams, GetUrlParams.PerPage, 1));
  const orderingField = _get(orderingParams, GetUrlParams.OrderingField);
  const orderingDirection = _get(
    orderingParams,
    GetUrlParams.OrderingDirection,
  );
  let sorts;
  if (orderingField && orderingDirection) {
    sorts = { [orderingField]: orderingDirection };
  }
  const variables = {
    fieldId,
    limit,
    page,
    sorts,
    taskId,
    templateVersionId,
  };
  const { data, previousData, loading, error } = useFormAnswerReportingQuery({
    skip: !isShowed,
    variables,
    fetchPolicy: 'cache-and-network',
  });
  const queryData = data || previousData;
  const collection = _get(queryData, 'data.collection', []) as FormAnswer[];
  const hasValidityHint = collection.some(({ validityHint }) => !!validityHint);
  const metadata = _get(queryData, 'data.metadata');
  const totalCount = _get(metadata, 'totalCount', 0);
  const options = _get(collection, [0, 'field', 'options'], []);

  const columns = getColumns({
    hasValidityHint,
    kind,
    options,
    t,
  });

  if (error) {
    return (
      <WhiteBox className={styles.block}>
        <ErrorPage
          status={getStatusFromError(error)}
          description={error?.message}
        />
      </WhiteBox>
    );
  }

  if (kind === FormTemplateQuestionFieldsKindEnum.Section) {
    return (
      <div className={styles.block}>
        {questionName && <Typography variant="h2">{questionName}</Typography>}
        {questionDescription && (
          <Typography variant="body" className={styles.description}>
            {questionDescription}
          </Typography>
        )}
      </div>
    );
  }

  if (kind === FormTemplateQuestionFieldsKindEnum.ImportantNotice) {
    const option = _get(collection, [0, 'resp', 0, 'option']);
    if (!option) return null;
    return (
      <WhiteBox className={styles.block} ref={ref}>
        <div className={styles.chipsRow}>
          <QuestionTypeChip kind={kind} />
        </div>
        <SupportiveMessage
          text={option}
          leftIcon="warning"
          color={SupportiveMessageColor.Yellow}
          iconColor={SupportiveMessageIconColor.Inner}
          iconType={SupportiveMessageIconType.FilledWithBorder}
          type={SupportiveMessageType.FilledWithBorder}
        />
      </WhiteBox>
    );
  }

  return (
    <WhiteBox className={styles.block} ref={ref}>
      <div className={styles.chipsRow}>
        <QuestionTypeChip kind={kind} />
      </div>
      {questionName && <Typography variant="h3">{questionName}</Typography>}
      {questionDescription && (
        <Typography variant="body" className={styles.description}>
          {questionDescription}
        </Typography>
      )}
      <Table
        className={styles.table}
        columns={columns}
        data={collection}
        getRowClassName={getRowClassName}
        id={`${taskId}-${fieldId}-${kind}`}
        loading={loading}
        orderingState={orderingState}
        paginationState={paginationState}
        totalEntries={totalCount}
      />
    </WhiteBox>
  );
}
