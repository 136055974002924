import React from 'react';
import { useTranslation } from 'react-i18next';
import ActionsRow from '@lib/components/ActionsRow/ActionsRow';
import Button, { ButtonTypes } from '@lib/components/Button/Button';

interface Props {
  closeChangePasswordModal: () => void;
  isDirty?: boolean;
  loading?: boolean;
}

function PasswordFormActions(props: Props) {
  const { closeChangePasswordModal, isDirty, loading } = props;
  const { t } = useTranslation();
  return (
    <ActionsRow
      actions={[
        <Button
          buttonText={t('cancel')}
          buttonType={ButtonTypes.secondaryOutlined}
          disabled={loading}
          fullWidth
          leftIcon="close"
          onClick={closeChangePasswordModal}
        />,
        <Button
          buttonText={t('submit')}
          buttonType={ButtonTypes.primaryFilled}
          disabled={!isDirty}
          fullWidth
          isLoading={loading}
          leftIcon="check"
          type="submit"
        />,
      ]}
    />
  );
}

export default PasswordFormActions;
