import React, { memo } from 'react';
import classNames from 'classnames';
import MaterialIcon from '@lib/components/MaterialIcon/MaterialIcon';
import styles from './TabBar.module.scss';
import TabBarItem from './TabBarItem';
import { ActiveItem, TabBarItem as TabBarItemType } from './constants';

type Props = {
  items: TabBarItemType[];
  active?: ActiveItem;
  onActiveChange: (item: TabBarItemType) => void;
};

function TabBarMoreItems({ items, active, onActiveChange }: Props) {
  const hasActive = items.some(
    (item) => active && item.value.indexOf(active) > -1,
  );
  const hasError = items.some((item) => item.hasError);

  return (
    <div
      className={classNames(styles.moreItem, styles.item, {
        [styles.active]: hasActive,
        [styles.hasError]: hasError,
      })}
    >
      <MaterialIcon
        icon={`filter_${items.length > 9 ? '9+' : items.length}`}
        size="md-20"
      />
      <div className={styles.moreWrapper}>
        <div className={styles.moreContent}>
          {items.map((item) => (
            <TabBarItem
              isVisible
              key={item.value}
              item={item}
              active={active}
              onActiveChange={onActiveChange}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default memo(TabBarMoreItems);
