import React from 'react';
import { useTranslation } from 'react-i18next';
import ActionsRow from '@lib/components/ActionsRow/ActionsRow';
import Button, { ButtonTypes } from '@lib/components/Button/Button';
import { FormTemplateMode } from 'routes/FormTemplate/enums';
import styles from '../FormTemplateBuilderForm/FormTemplateBuilderForm.module.scss';

interface Props {
  isDirty: boolean;
  loading?: boolean;
  onCancelForm: () => void;
  viewMode: FormTemplateMode;
}

function FormTemplateBuilderActions({
  loading,
  onCancelForm,
  isDirty,
  viewMode,
}: Props) {
  const { t } = useTranslation();
  const isDuplicatePage = viewMode === FormTemplateMode.Duplicate;
  const isSubmitBtnDisabled = isDuplicatePage ? undefined : !isDirty;
  return (
    <ActionsRow
      actions={[
        <Button
          buttonText={t('cancel')}
          buttonType={ButtonTypes.secondaryOutlined}
          disabled={loading}
          fullWidth
          leftIcon="close"
          onClick={onCancelForm}
        />,
        <Button
          buttonText={t('save-template')}
          buttonType={ButtonTypes.primaryFilled}
          disabled={isSubmitBtnDisabled}
          fullWidth
          isLoading={loading}
          leftIcon="check"
          type="submit"
        />,
      ]}
      className={styles.actions}
    />
  );
}

export default FormTemplateBuilderActions;
