import React from 'react';
import { useFormContext } from 'react-hook-form';
import _replace from 'lodash/replace';
import IconButton, {
  IconButtonTypes,
} from '@lib/components/IconButton/IconButton';
import { Values } from '@lib/interfaces/form';
import { FormTemplateQuestionFieldsKindEnum, GroupEnum } from 'graphql-common';
import FormTemplateFieldNames from '@lib/enums/fieldNames/formTemplateFieldNames';
import removeValidityHintConfigItem from './utils/removeValidityHintConfigItem';
import styles from './QuestionFieldOption.module.scss';

interface Props {
  kind: FormTemplateQuestionFieldsKindEnum;
  validityHintConfig: Values;
  onRemove: () => void;
  parentIndex: number;
  parentName: string;
  parentValue: Values[];
}

export default function QuestionFieldOptionDeleteBtn(props: Props) {
  const {
    kind,
    validityHintConfig,
    onRemove,
    parentIndex,
    parentName,
    parentValue,
  } = props;
  const { setValue } = useFormContext();

  const getDeleteBtnIcon = () => {
    if (kind === FormTemplateQuestionFieldsKindEnum.CheckboxButtonGrid) {
      return 'cancel';
    }
    return 'close';
  };

  const onRemoveHandler = () => {
    if (validityHintConfig) {
      const deletedOption = parentValue[parentIndex];
      const deletedOptionGroup = deletedOption.group as GroupEnum;
      const deletedOptionPosition = deletedOption.position as number;
      const newValidityHintConfig = removeValidityHintConfigItem(
        deletedOptionPosition,
        deletedOptionGroup,
        validityHintConfig,
      );
      setValue(
        _replace(
          parentName,
          `.${FormTemplateFieldNames.Options}`,
          `.${FormTemplateFieldNames.ValidityHintConfig}`,
        ),
        newValidityHintConfig,
      );
    }
    onRemove();
  };

  return (
    <IconButton
      icon={getDeleteBtnIcon()}
      rounded
      type={IconButtonTypes.TertiaryFilled}
      onClick={onRemoveHandler}
      className={styles.optionDeleteBtn}
    />
  );
}
