import React from 'react';
import { TFunction } from 'i18next';
import { SiteArea } from 'graphql-common';
import TextLinkCell from '@lib/components/Table/commonComponents/TextLinkCell/TextLinkCell';
import TextCell from '@lib/components/Table/commonComponents/TextCell/TextCell';
import { CellProps } from '@lib/components/Table/types';
import IconButton, {
  IconButtonTypes,
} from '@lib/components/IconButton/IconButton';
import Tooltip from '@lib/components/Tooltip/Tooltip';

type ColumnsArgs = {
  getQrCode: (item: SiteArea) => void;
  onViewHandler: (area: SiteArea) => void;
  t: TFunction<'translation', undefined>;
};

export default function getSiteAreasColumns({
  getQrCode,
  onViewHandler,
  t,
}: ColumnsArgs) {
  return [
    {
      id: 'code',
      Header: t('area-code'),
      accessor: 'code',
      Cell: (props: CellProps<SiteArea>) => <TextCell {...props} />,
      minWidth: 110,
      maxWidth: 110,
      width: 110,
      sortable: true,
    },
    {
      id: 'name',
      Header: t('name'),
      accessor: 'name',
      Cell: (props: CellProps<SiteArea>) => (
        <TextLinkCell {...props} onClick={() => onViewHandler(props?.item)} />
      ),
      maxWidth: 300,
      sortable: true,
    },
    {
      id: 'fullCode',
      Header: t('qr-code'),
      accessor: 'fullCode',
      Cell: (props: CellProps<SiteArea>) => <TextCell {...props} />,
      minWidth: 182,
      maxWidth: 182,
      width: 182,
      sortable: false,
    },
    {
      id: 'qrCode',
      Header: '',
      accessor: 'qrCode',
      Cell: ({ item }: CellProps<SiteArea>) => (
        <div>
          <Tooltip body={t('download-qr-code')}>
            <IconButton
              type={IconButtonTypes.PrimaryFilled}
              onClick={() => getQrCode(item)}
              icon="download"
              symbolsOutlined
            />
          </Tooltip>
        </div>
      ),
      width: 1,
    },
  ];
}
