import React from 'react';
import {
  InterventionAction,
  InterventionActionStatusEnum,
} from 'graphql-common';
import InterventionActionView from 'routes/Intervention/InterventionActionView/InterventionActionView';
import { UseModalManagementResult } from 'routes/Intervention/utils/useModalManagement';
import { ChipTypes } from '@lib/components/Chip/Chip';
import { getInterventionActionStatusChipType } from '@lib/utils/status';
import MaterialIcon from '@lib/components/MaterialIcon/MaterialIcon';
import classNames from 'classnames';
import Pagination from '@lib/components/Pagination/Pagination';
import { UseInterventionActionsResult } from 'routes/Intervention/utils/useInterventionActions';
import styles from './InterventionActions.module.scss';

function InterventionActionItemIcon({
  status,
}: {
  status: InterventionActionStatusEnum;
}) {
  const type = getInterventionActionStatusChipType(status);
  let icon = 'info';
  if (type === ChipTypes.chipBlue) {
    icon = 'pending_actions';
  } else if (type === ChipTypes.chipOrange) {
    icon = 'schedule';
  } else if (type === ChipTypes.chipGreen) {
    icon = 'check_circle';
  } else if (type === ChipTypes.chipViolet) {
    icon = 'package_2';
  }
  return (
    <div
      className={classNames(styles.actionItemIcon, {
        [styles.actionItemIconBlue]: type === ChipTypes.chipBlue,
        [styles.actionItemIconOrange]: type === ChipTypes.chipOrange,
        [styles.actionItemIconGreen]: type === ChipTypes.chipGreen,
        [styles.actionItemIconViolet]: type === ChipTypes.chipViolet,
      })}
    >
      <MaterialIcon icon={icon} symbolsOutlined size="md-16" filled />
    </div>
  );
}

export default function InterventionActions({
  collection,
  totalCount,
  useModalManagementResult,
  useInterventionActionsResult,
  isViewOnly,
}: {
  collection: InterventionAction[];
  totalCount: number;
  useModalManagementResult: UseModalManagementResult;
  useInterventionActionsResult: UseInterventionActionsResult;
  isViewOnly: boolean;
}) {
  const isPaginationVisible =
    !!totalCount && !!collection.length && totalCount > collection.length;
  const pagination = isPaginationVisible ? (
    <div className={styles.pagination}>
      <Pagination totalEntries={totalCount} />
    </div>
  ) : null;
  return (
    <div>
      <div className={styles.actions}>
        {collection.map((action) => (
          <div key={action.id} className={styles.actionWrap}>
            <div className={styles.actionIconWrap}>
              <InterventionActionItemIcon status={action.status} />
            </div>
            <InterventionActionView
              useInterventionActionsResult={useInterventionActionsResult}
              interventionAction={action}
              useModalManagementResult={useModalManagementResult}
              isViewOnly={isViewOnly}
            />
          </div>
        ))}
      </div>
      <div>{pagination}</div>
    </div>
  );
}
