import React from 'react';
import {
  components as DefaultSelectComponents,
  ControlProps,
} from 'react-select';
import classNames from 'classnames';
import { InputSizes } from '@lib/components/Input/enums';
import styles from '../Select.module.scss';

function Control(props: ControlProps): JSX.Element {
  const { children, ...restProps } = props;
  const { selectProps, isFocused } = restProps;
  // TODO: fix it (https://react-select.com/typescript#custom-select-props)
  const {
    menuIsOpen,
    // @ts-ignore
    label,
    isDisabled,
    isClearable,
    // @ts-ignore
    size,
    // @ts-ignore
    hasBorder,
    // @ts-ignore
    controlHasLeftPadding = true,
  } = selectProps;
  const controlClassName = classNames(styles.control, {
    [styles.withoutBorder]: !hasBorder,
    [styles.controlClearable]: isClearable,
    [styles.controlLarge]: size === InputSizes.large,
    [styles.controlMedium]: size === InputSizes.medium,
    [styles.controlWithoutLeftPadding]: !controlHasLeftPadding,
    isFocused,
    isDisabled,
    menuIsOpen,
  });
  return (
    <DefaultSelectComponents.Control
      {...restProps}
      className={controlClassName}
    >
      {label && <span className={styles.selectLabel}>{label}</span>}
      {children}
    </DefaultSelectComponents.Control>
  );
}

export default Control;
