import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import _get from 'lodash/get';
import { ScopeEnum, User } from 'graphql-common';
import toast from '@lib/components/Toast/Toast';
import getAppUrlWithDomain from '@lib/utils/getAppUrlWithDomain';
import { UseBulkSelectResult } from '@lib/hooks/useTableItemsBulkSelect';
import {
  Modals,
  UseModalManagementResult,
} from '@lib/hooks/useModalManagement';
import useOutletContext, { ContextProps } from 'hooks/useOutletContext';
import { APP_URLS } from 'constants/urls';
import { SingleQueryHookResult } from 'utils/fetch/types';
import { UserViewMode } from 'routes/Users/enums';
import { Values } from '@lib/interfaces/form';
import {
  AccessesFieldValues,
  getAccessesPreparedDataFromValues,
  getAccessesValues,
} from '@lib/utils/accessesManagement';
import FieldNames from '@lib/enums/fieldNames/userFieldNames';
import useSearchListQueries from './useSearchListQueries';
import useUsersMutations from './useUsersMutations';

const { VITE_AUTH_PLATFORM_URL: AUTH_PLATFORM } = import.meta.env;

interface UseActionsProps {
  id: string;
  useBulkSelectResult: UseBulkSelectResult<User>;
  useModalManagementResult: UseModalManagementResult;
  useUserQueryHookResult: SingleQueryHookResult<User>;
}

export default function useUserActions({
  id,
  useBulkSelectResult,
  useModalManagementResult,
  useUserQueryHookResult,
}: UseActionsProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { selectedSingleTableItem, handleSelectSingleTableItem } =
    useBulkSelectResult;
  const { openModal, closeAllModals } = useModalManagementResult;

  const selectedUserId = selectedSingleTableItem?.id || null;
  const outletContext: ContextProps = useOutletContext();
  const currentUserId = _get(outletContext, 'user.id');

  const {
    resendRegistrationConfirmationMutation,
    resendEmailConfirmationMutation,
    destroyMutation,
    bulkActivateMutation,
    bulkDeactivateMutation,
    teamUpdateMutation,
    userUpdateMutation,
    userUpdateMutationResult,
  } = useUsersMutations();

  const { teamsLoadOptions } = useSearchListQueries();

  // Actions
  const onDestroyHandler = () => {
    if (selectedUserId) {
      destroyMutation({
        variables: {
          id,
        },
        onCompleted: () => {
          closeAllModals();
          useUserQueryHookResult.refetch();
        },
      });
    }
  };

  const onEdit = (item?: User) => {
    if (item?.id) {
      handleSelectSingleTableItem(item);
      openModal(Modals.UserFormModal);
      useUserQueryHookResult.refetch();
    }
  };

  const onDelete = (item?: User) => {
    if (item?.id) {
      handleSelectSingleTableItem(item);
      openModal(Modals.UserDeleteModal);
    }
  };

  const onConfirmedDelete = () => {
    closeAllModals();
    toast({
      content: t('destroy-team-member-success'),
      closeCallback: onDestroyHandler,
    });
  };

  const onActivate = (item?: User) => {
    if (item?.id) {
      bulkActivateMutation({
        variables: { input: { ids: [item.id] } },
        onCompleted: () => {
          useUserQueryHookResult.refetch();
          toast({ content: t('activate-user-success') });
        },
      });
    }
  };

  const onDeactivate = (item?: User) => {
    if (item?.id) {
      bulkDeactivateMutation({
        variables: { input: { ids: [item.id] } },
        onCompleted: () => {
          useUserQueryHookResult.refetch();
          toast({ content: t('deactivate-user-success') });
        },
      });
    }
  };

  const onTeamMembersUpdate = (teamId: string) => {
    teamUpdateMutation({
      variables: {
        id: teamId,
        attributes: {
          users: [{ id }],
        },
      },
      onCompleted: () => {
        useUserQueryHookResult.refetch();
      },
    });
  };

  const onResendRegistrationConfirmationHandler = () =>
    resendRegistrationConfirmationMutation({
      variables: {
        input: {
          scope: ScopeEnum.User,
          id,
          redirectUrl: getAppUrlWithDomain(
            APP_URLS.setup.finishRegistration.path,
            AUTH_PLATFORM,
          ),
        },
      },
    });

  const onResendEmailConfirmationHandler = () =>
    resendEmailConfirmationMutation({
      variables: {
        input: {
          scope: ScopeEnum.User,
          id,
          redirectUrl: getAppUrlWithDomain(
            APP_URLS.setup.confirmEmail.path,
            AUTH_PLATFORM,
          ),
        },
      },
    });

  const onSubmitUserFormCallback = () => {
    closeAllModals();
    useUserQueryHookResult.refetch();
  };

  const openTeamPage = (teamId: string) => {
    navigate(
      APP_URLS.dashboard.teams.view.getDynamicPath({
        pathParts: { id: teamId },
      }),
    );
  };

  const onViewChange = (newViewMode: string) => {
    if (newViewMode === UserViewMode.View) {
      navigate(
        APP_URLS.dashboard.users.view.getDynamicPath({
          pathParts: { id },
        }),
      );
    } else if (newViewMode === UserViewMode.ViewAccess) {
      navigate(
        APP_URLS.dashboard.users.viewAccess.getDynamicPath({
          pathParts: { id },
        }),
      );
    } else if (newViewMode === UserViewMode.EditAccess) {
      navigate(
        APP_URLS.dashboard.users.editAccess.getDynamicPath({
          pathParts: { id },
        }),
      );
    }
  };

  const onUserAccessChange = (values: Values) => {
    const newPersonalAccesses = _get(
      values,
      FieldNames.Accesses,
      {},
    ) as AccessesFieldValues;
    const newPersonalAccessValues: AccessesFieldValues = Object.fromEntries(
      Object.entries(newPersonalAccesses).filter(([, value]) => value.isCustom),
    ) as unknown as AccessesFieldValues;

    userUpdateMutation({
      variables: {
        finishRegistrationRedirectUrl: getAppUrlWithDomain(
          APP_URLS.setup.finishRegistration.path,
          AUTH_PLATFORM,
        ),
        confirmEmailRedirectUrl: getAppUrlWithDomain(
          APP_URLS.setup.confirmEmail.path,
          AUTH_PLATFORM,
        ),
        id,
        attributes: {
          accesses: getAccessesPreparedDataFromValues(
            newPersonalAccessValues,
            getAccessesValues(
              useUserQueryHookResult.data?.personalAccesses || [],
            ) as AccessesFieldValues,
            true,
          ),
        },
      },
      onCompleted: () => {
        toast({ content: t('update-team-member-success') });
        onViewChange(UserViewMode.ViewAccess);
      },
    });
  };

  return {
    currentUserId,
    onActivate,
    onConfirmedDelete,
    onDeactivate,
    onDelete,
    onEdit,
    onResendEmailConfirmationHandler,
    onResendRegistrationConfirmationHandler,
    onSubmitUserFormCallback,
    onTeamMembersUpdate,
    onUserAccessChange,
    onViewChange,
    openTeamPage,
    selectedUserId,
    teamsLoadOptions,
    userUpdateMutationResult,
  };
}
