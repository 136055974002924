import React from 'react';
import { useTranslation } from 'react-i18next';
import { Counter } from 'graphql-common';
import Button, { ButtonTypes } from '@lib/components/Button/Button';
import Table from '@lib/components/Table/Table';
import CircleLoader from '@lib/components/CircleLoader/CircleLoader';
import PageBody from '@lib/layouts/DashboardLayout/PageBody/PageBody';
import ListEmptyState from '@lib/components/ListEmptyState/ListEmptyState';
import Trans from '@lib/components/Trans/Trans';
import ErrorPage from '@lib/components/ErrorPage/ErrorPage';
import getStatusFromError from '@lib/utils/getStatusFromError';
import { UseBulkSelectResult } from '@lib/hooks/useTableItemsBulkSelect';
import WhiteBox from '@lib/components/WhiteBox/WhiteBox';
import { materialSymbolsOutlined } from '@lib/enums/common';
import { FormRefType } from '@lib/components/ReactHookForm/types';
import ConfirmationModal from '@lib/components/ConfirmationModal/ConfirmationModal';
import Modal from '@lib/components/Modal/Modal';
import DashboardTitleHeader from '@lib/layouts/DashboardLayout/DashboardTitleHeader/DashboardTitleHeader';
import AssetCounterModalForm from 'components/AssetCounterModalForm/AssetCounterModalForm';
import { UseSearchListQueriesResult } from 'routes/Assets/Asset/utils/useSearchListQueries';
import { UseModalManagementResult } from 'routes/Assets/Asset/utils/useModalManagement';
import GetQrCodeForm from 'components/GetQrCodeForm/GetQrCodeForm';
import { UseActionsResult } from 'routes/Assets/Asset/utils/useActions';
import { SubmitAction } from '@lib/enums/form';
import { UseCounterActionsResult } from 'routes/Assets/Asset/utils/useCounterActions';
import AssetCounterIndexModalForm from 'components/AssetCounterIndexModalForm/AssetCounterIndexModalForm';
import ViewOnlyChip from '@lib/components/ViewOnlyChip/ViewOnlyChip';
import { IconName } from '@lib/components/Modal/enums';
import getTableActionsForSelected from './utils/getTableActionsForSelected';
import getTableActionsMenu from './utils/getTableActionsMenu';
import getTableColumns from './utils/getTableColumns';

interface Props {
  formRef: FormRefType;
  loadingState?: boolean;
  menuBar: JSX.Element;
  submitNewItemRef: React.MutableRefObject<SubmitAction | undefined>;
  useActionsResult: UseActionsResult;
  useBulkSelectResult?: UseBulkSelectResult<Counter>;
  useCounterActionsResult: UseCounterActionsResult;
  useModalManagementResult: UseModalManagementResult;
  useSearchListQueriesResult: UseSearchListQueriesResult;
  isViewOnly: boolean;
  isCounterViewOnly: boolean;
}

export default function AssetCounters(props: Props) {
  const {
    formRef,
    loadingState,
    menuBar,
    submitNewItemRef,
    useActionsResult,
    useBulkSelectResult,
    useCounterActionsResult,
    useModalManagementResult,
    useSearchListQueriesResult,
    isViewOnly,
    isCounterViewOnly,
  } = props;
  const { t } = useTranslation();

  const { assetQueryHook } = useActionsResult;
  const {
    assetCounterIndexMutationError,
    assetCounterIndexMutationLoading,
    assetCounterMutationError,
    assetCounterMutationLoading,
    counterQueryHook,
    countersQueryHook,
    onCloseModal,
    onConfirmedCounterDestroy,
    onCounterFormSubmit,
    onCounterIndexFormSubmit,
    onCounterQrCodeCreate,
    openCounterBulkDestroyModal,
    openCounterDestroyModal,
    openCounterEditModal,
    openCounterIndexFormModal,
    openCounterMonitoring,
    openGetQrCodeFormModal,
    qrCodeCreateMutationResult,
  } = useCounterActionsResult;

  const name = assetQueryHook.data?.name || '';

  const tableColumns = getTableColumns({
    getQrCode: openGetQrCodeFormModal,
    onOpen: openCounterMonitoring,
    t,
  });
  const tableActionsMenu = getTableActionsMenu({
    onEdit: isViewOnly ? undefined : openCounterEditModal,
    onDelete: isViewOnly ? undefined : openCounterDestroyModal,
    onAddIndex: isCounterViewOnly ? undefined : openCounterIndexFormModal,
    t,
  });
  const tableActionsForSelected = getTableActionsForSelected({
    getQrCodesForSelected: openGetQrCodeFormModal,
    onBulkDelete: openCounterBulkDestroyModal,
    selectedItemsCount: useBulkSelectResult?.getSelectedItemsCount(
      countersQueryHook.totalCount,
    ),
    isViewOnly,
    t,
  });

  const emptyState = (
    <ListEmptyState
      title={t('no-asset-counters-yet')}
      text={<Trans i18nKey="no-asset-counters-text" />}
      actions={
        isViewOnly
          ? []
          : [
              <Button
                key="add"
                buttonText={t('add-counter')}
                buttonType={ButtonTypes.primaryFilled}
                leftIcon="add"
                onClick={useModalManagementResult.openCounterFormModal}
              />,
            ]
      }
    />
  );

  if (countersQueryHook.error) {
    return (
      <ErrorPage
        status={getStatusFromError(countersQueryHook.error)}
        description={countersQueryHook.error.message}
      />
    );
  }

  return (
    <>
      <PageBody withoutHeader>
        {menuBar}
        {countersQueryHook.firstLoading ? (
          <CircleLoader />
        ) : (
          <WhiteBox>
            <DashboardTitleHeader
              title={name}
              actions={
                isViewOnly ? (
                  <ViewOnlyChip key="view" />
                ) : (
                  <Button
                    key="add"
                    buttonText={t('add-counter')}
                    buttonType={ButtonTypes.primaryFilled}
                    onClick={useModalManagementResult.openCounterFormModal}
                    leftIcon="add"
                    leftIconClassName={materialSymbolsOutlined}
                  />
                )
              }
            />
            <Table
              actionsForSelected={tableActionsForSelected}
              actionsMenu={tableActionsMenu}
              columns={tableColumns}
              data={countersQueryHook.collection}
              emptyState={emptyState}
              id="counters"
              loading={loadingState || countersQueryHook.loading}
              totalEntries={countersQueryHook.totalCount}
              useBulkSelectResult={useBulkSelectResult}
            />
          </WhiteBox>
        )}
      </PageBody>
      <Modal
        isOpen={useModalManagementResult.isQrCodeFormModalOpened}
        onClose={onCloseModal}
        title={t('get-counter-qr-code')}
      >
        <GetQrCodeForm
          loading={false}
          selectedItemsCount={useBulkSelectResult?.getSelectedItemsCount(
            countersQueryHook.totalCount,
          )}
          graphQLErrors={qrCodeCreateMutationResult?.error?.graphQLErrors}
          onCancel={onCloseModal}
          onSubmit={onCounterQrCodeCreate}
          getRecipientsLoadOptions={
            useSearchListQueriesResult.getUsersLoadOptions
          }
        />
      </Modal>
      <ConfirmationModal
        confirmButtonLabel={t('yes-delete')}
        description={<Trans i18nKey="asset-counter-delete-text" />}
        icon={IconName.Delete}
        isOpen={useModalManagementResult.isCounterDestroyModalOpened}
        onClose={onCloseModal}
        onConfirm={onConfirmedCounterDestroy}
        title={t('are-you-sure')}
      />
      <AssetCounterModalForm
        assetCounter={counterQueryHook.data}
        formRef={formRef}
        getAssetsLoadOptions={useSearchListQueriesResult.getAssetsLoadOptions}
        graphQLErrors={assetCounterMutationError?.graphQLErrors}
        isEditForm={!!useBulkSelectResult?.selectedSingleTableItem?.id}
        isOpen={useModalManagementResult.isCounterFormModalOpened}
        loading={counterQueryHook.loading || assetCounterMutationLoading}
        onCancel={onCloseModal}
        onClose={onCloseModal}
        onSubmit={onCounterFormSubmit}
        submitNewItemRef={submitNewItemRef}
      />
      <AssetCounterIndexModalForm
        assetCounter={counterQueryHook.data}
        formRef={formRef}
        graphQLErrors={assetCounterIndexMutationError?.graphQLErrors}
        isOpen={useModalManagementResult.isCounterIndexFormModalOpened}
        loading={counterQueryHook.loading || assetCounterIndexMutationLoading}
        onCancel={onCloseModal}
        onClose={onCloseModal}
        onSubmit={onCounterIndexFormSubmit}
      />
    </>
  );
}
