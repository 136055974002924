import React from 'react';
import classNames from 'classnames';
import { RadioButtonProps } from '@lib/components/RadioButton/RadioButton';
import MaterialIcon from '@lib/components/MaterialIcon/MaterialIcon';
import styles from './RadioButtonAsChipColor.module.scss';

function RadioButtonAsChipColor(props: RadioButtonProps) {
  const { id, checked, onChange } = props;

  const onClick = (e: unknown) => {
    const v = e as React.ChangeEvent<HTMLInputElement>;
    onChange(v);
  };

  return (
    <button
      type="button"
      className={classNames(styles.root, styles[id], styles.smallLeftIconOnly, {
        [styles.checked]: checked,
      })}
      onClick={onClick}
    >
      <MaterialIcon
        icon={checked ? 'check_circle' : 'circle'}
        className={styles.leftIcon}
      />
    </button>
  );
}

export default RadioButtonAsChipColor;
