import React from 'react';
import { useTranslation } from 'react-i18next';
import Button, { ButtonTypes } from '@lib/components/Button/Button';
import Table from '@lib/components/Table/Table';
import CircleLoader from '@lib/components/CircleLoader/CircleLoader';
import PageHeader from '@lib/layouts/DashboardLayout/PageHeader/PageHeader';
import Search from '@lib/components/Search/Search';
import PageBody from '@lib/layouts/DashboardLayout/PageBody/PageBody';
import ConfirmationModal from '@lib/components/ConfirmationModal/ConfirmationModal';
import { ApolloError } from '@apollo/client/errors';
import ListEmptyState from '@lib/components/ListEmptyState/ListEmptyState';
import useSearchParam from '@lib/hooks/useSearchParam';
import ErrorPage from '@lib/components/ErrorPage/ErrorPage';
import Trans from '@lib/components/Trans/Trans';
import { FormCategory } from 'graphql-common';
import getStatusFromError from '@lib/utils/getStatusFromError';
import Modal from '@lib/components/Modal/Modal';
import CategoryForm from 'components/FormCategory/CategoryForm';
import { Values } from '@lib/interfaces/form';
import { getDefaultValuesFromQueryData } from 'components/FormTemplateCategories/utils';
import ViewOnlyChip from '@lib/components/ViewOnlyChip/ViewOnlyChip';
import { IconName } from '@lib/components/Modal/enums';
import { getActions, getColumns } from './tableHelpers';

interface Props {
  closeCategoryModal: () => void;
  closeDeleteModal: () => void;
  formCategories: FormCategory[];
  formCategoriesError?: ApolloError;
  formCategoriesFirstLoading: boolean;
  formCategoriesLoading: boolean;
  formCategoryMutationError?: ApolloError;
  formCategoryMutationLoading: boolean;
  formCategoryData?: FormCategory | null;
  formCategoryQueryError?: ApolloError;
  formCategoryQueryLoading: boolean;
  isCategoryModalOpened: boolean;
  isDeleteModalOpened: boolean;
  onAddHandler: () => void;
  onConfirmedDelete: () => void;
  onDelete: (item: FormCategory) => void;
  onEditHandler: (item?: FormCategory) => void;
  onSubmitFormCategoryForm: (values: Values) => void;
  selectedCategory: FormCategory | null;
  totalFormCategoriesCount: number;
  isViewOnly: boolean;
  isTasksAvailable: boolean;
}

function FormTemplateCategories(props: Props) {
  const {
    closeCategoryModal,
    closeDeleteModal,
    formCategories,
    formCategoriesError,
    formCategoriesFirstLoading,
    formCategoriesLoading,
    formCategoryMutationError,
    formCategoryMutationLoading,
    formCategoryData,
    formCategoryQueryError,
    formCategoryQueryLoading,
    selectedCategory,
    isCategoryModalOpened,
    isDeleteModalOpened,
    onAddHandler,
    onConfirmedDelete,
    onDelete,
    onEditHandler,
    onSubmitFormCategoryForm,
    totalFormCategoriesCount,
    isViewOnly,
    isTasksAvailable,
  } = props;
  const { t } = useTranslation();
  const [searchQuery] = useSearchParam();

  const tableColumns = getColumns({
    onEditHandler,
    t,
    isTasksAvailable,
    isViewOnly,
  });
  const tableActions = getActions({ t, onEditHandler, onDelete, isViewOnly });

  if (formCategoriesError) {
    return (
      <ErrorPage
        status={getStatusFromError(formCategoriesError)}
        description={formCategoriesError.message}
      />
    );
  }

  const emptyState = (
    <ListEmptyState
      title={t('no-categories-yet')}
      text={t('no-categories-text')}
      actions={
        isViewOnly
          ? []
          : [
              <Button
                key="add"
                buttonText={t('create-new-category')}
                buttonType={ButtonTypes.primaryFilled}
                leftIcon="add"
                onClick={onAddHandler}
              />,
            ]
      }
    />
  );

  return (
    <>
      <PageHeader title={t('form-categories')}>
        {isViewOnly ? (
          <ViewOnlyChip />
        ) : (
          <Button
            buttonText={t('create-new-category')}
            leftIcon="add"
            onClick={onAddHandler}
          />
        )}
      </PageHeader>
      <PageBody>
        {formCategoriesFirstLoading ? (
          <CircleLoader />
        ) : (
          <>
            {(!!formCategories?.length || searchQuery) && (
              <Search
                placeholder={t('form-template-categories-search-placeholder')}
              />
            )}
            <Table
              actions={tableActions}
              columns={tableColumns}
              counterText={t('categories')}
              data={formCategories}
              emptyState={emptyState}
              fullHeight
              id="form-template-categories"
              loading={formCategoriesLoading}
              totalEntries={totalFormCategoriesCount}
            />
          </>
        )}
        <Modal
          isOpen={isCategoryModalOpened}
          title={
            selectedCategory
              ? t('edit-form-category')
              : t('create-new-form-category')
          }
          onClose={closeCategoryModal}
        >
          <CategoryForm
            defaultValues={
              selectedCategory && formCategoryData
                ? getDefaultValuesFromQueryData(formCategoryData)
                : {}
            }
            graphQLErrors={
              (formCategoryQueryError || formCategoryMutationError)
                ?.graphQLErrors
            }
            loading={formCategoryQueryLoading || formCategoryMutationLoading}
            onCancel={closeCategoryModal}
            onSubmit={onSubmitFormCategoryForm}
          />
        </Modal>
        <ConfirmationModal
          isOpen={isDeleteModalOpened}
          onClose={closeDeleteModal}
          onConfirm={onConfirmedDelete}
          title={t('are-you-sure')}
          description={
            <Trans
              i18nKey="delete-category-text"
              values={{ name: selectedCategory?.name }}
            />
          }
          confirmButtonLabel={t('yes-delete')}
          icon={IconName.Delete}
          cancelButtonLabel={t('cancel')}
        />
      </PageBody>
    </>
  );
}

export default FormTemplateCategories;
