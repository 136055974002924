import {
  FilterGroupingEnum,
  InterventionCategoriesQueryVariables,
  InterventionCategory,
  InterventionCategoryFilterInputObject,
  useInterventionCategoriesQuery,
} from 'graphql-common';
import _get from 'lodash/get';
import { GetUrlParams } from '@lib/enums/urls';
import createSearchFilters from '@lib/utils/createSearchFilters';
import { ApolloError } from '@apollo/client/errors';
import { ListQueryHookProps } from './types';

export type UseInterventionCategoriesQueryHookResult = {
  collection: InterventionCategory[];
  error?: ApolloError;
  firstLoading: boolean;
  loading: boolean;
  refetch: () => void;
  totalCount: number;
  totalPages: number;
};

export default function useInterventionCategoriesQueryHook({
  paginationParams,
  searchQuery,
  ids,
  skip,
}: ListQueryHookProps): UseInterventionCategoriesQueryHookResult {
  const page = _get(paginationParams, GetUrlParams.Page);
  const perPage = _get(paginationParams, GetUrlParams.PerPage);
  const orderingField = _get(paginationParams, GetUrlParams.OrderingField);
  const orderingDirection = _get(
    paginationParams,
    GetUrlParams.OrderingDirection,
  );
  const variables: InterventionCategoriesQueryVariables = {
    page: Number(page),
    limit: Number(perPage),
  };
  const filters: InterventionCategoryFilterInputObject[] = [];
  if (searchQuery) {
    filters.push(...createSearchFilters(searchQuery, [{ fieldName: 'name' }]));
  }
  if (ids?.length) {
    filters.push({
      id: {
        grouping: FilterGroupingEnum.Or,
        predicate: {
          in: ids,
        },
      },
    });
  }
  if (filters.length) {
    variables.filters = filters;
  }
  if (orderingField && orderingDirection) {
    variables.sorts = {
      [orderingField]: orderingDirection,
    };
  }
  const query = useInterventionCategoriesQuery({
    fetchPolicy: 'cache-and-network',
    variables,
    skip,
  });
  const { loading, data, previousData, refetch, error } = query;
  const queryData = data || previousData;
  const collection = _get(
    queryData,
    'data.collection',
    [],
  ) as InterventionCategory[];
  return {
    refetch,
    error,
    firstLoading: loading && previousData === undefined,
    collection,
    totalCount: _get(queryData, 'data.metadata.totalCount', 0),
    totalPages: _get(queryData, 'data.metadata.totalPages', 1),
    loading,
  };
}
