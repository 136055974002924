import {
  useAssetCategoriesSearchListLazyQuery,
  useInterventionCategoriesSearchListLazyQuery,
  useTeamsSearchListLazyQuery,
  useUsersSearchListLazyQuery,
} from 'graphql-common';
import { GetLoadOptions, LoadOptions } from '@lib/components/Select/types';
import { getTeamsLoadOptions as getTeamsLoadOptionsFn } from 'utils/fetch/teamsSearchList';
import { getInterventionCategoriesLoadOptions as getInterventionCategoriesLoadOptionsFn } from 'utils/fetch/interventionCategoriesSearchList';
import { getAssetCategoriesLoadOptions as getAssetCategoriesLoadOptionsFn } from 'utils/fetch/assetCategoriesSearchList';
import { getUsersLoadOptions as getUsersLoadOptionsFn } from 'utils/fetch/usersSearchList';

export type UseSearchListQueriesResult = {
  teamsLoadOptions: LoadOptions;
  getInterventionCategoriesLoadOptions: GetLoadOptions;
  getAssetCategoriesLoadOptions: GetLoadOptions;
  getUsersLoadOptions: GetLoadOptions;
};

export default function useSearchListQueries(): UseSearchListQueriesResult {
  // Teams
  const [fetchTeamsSearchListLazyQuery] = useTeamsSearchListLazyQuery({
    fetchPolicy: 'cache-and-network',
  });
  const teamsLoadOptions = getTeamsLoadOptionsFn(fetchTeamsSearchListLazyQuery);

  // Users
  const [fetchUsersSearchListLazyQuery] = useUsersSearchListLazyQuery({
    fetchPolicy: 'cache-and-network',
  });
  const getUsersLoadOptions = () => {
    return getUsersLoadOptionsFn(fetchUsersSearchListLazyQuery);
  };

  // Intervention categories
  const [fetchInterventionCategoriesLazyQuery] =
    useInterventionCategoriesSearchListLazyQuery({
      fetchPolicy: 'cache-and-network',
    });
  const getInterventionCategoriesLoadOptions = () =>
    getInterventionCategoriesLoadOptionsFn(
      fetchInterventionCategoriesLazyQuery,
    );

  // Asset categories
  const [fetchAssetCategoriesLazyQuery] = useAssetCategoriesSearchListLazyQuery(
    {
      fetchPolicy: 'cache-and-network',
    },
  );
  const getAssetCategoriesLoadOptions = () =>
    getAssetCategoriesLoadOptionsFn(fetchAssetCategoriesLazyQuery);

  return {
    teamsLoadOptions,
    getInterventionCategoriesLoadOptions,
    getAssetCategoriesLoadOptions,
    getUsersLoadOptions,
  };
}
