import React from 'react';
import { useTranslation } from 'react-i18next';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { Values } from '@lib/interfaces/form';
import { GraphQLErrors } from '@apollo/client/errors';
import Form from '@lib/components/ReactHookForm/FormContainer';
import replaceNullsWithUndefined from '@lib/utils/replaceNullsWithUndefined';
import getUserFormFields from 'components/UserForm/utils/getUserFormFields';
import UserFormActions from 'components/UserForm/UserFormActions';
import { LoadOptions } from '@lib/components/Select/types';
import Chip, { ChipSizes, ChipTypes } from '@lib/components/Chip/Chip';
import UserFieldNames from '@lib/enums/fieldNames/userFieldNames';
import { getRolesOptions } from '@lib/utils/roles';
import styles from './UserForm.module.scss';

export interface UserFormProps {
  countriesCodes: string[];
  defaultValues?: Values;
  graphQLErrors?: GraphQLErrors;
  isSiteFieldRequired?: boolean;
  loading: boolean;
  onCancel: () => void;
  onSubmit: (values: Values) => void;
}

interface Props {
  sitesLoadOptions: LoadOptions;
  teamsLoadOptions: LoadOptions;
}

const emptyDefaultValues = {
  id: null,
  [UserFieldNames.FirstName]: null,
  [UserFieldNames.LastName]: null,
  [UserFieldNames.Email]: null,
  [UserFieldNames.Role]: getRolesOptions()[0],
};

function UserForm(props: UserFormProps & Props) {
  const {
    countriesCodes,
    defaultValues = emptyDefaultValues,
    graphQLErrors,
    loading,
    isSiteFieldRequired,
    onCancel,
    onSubmit,
    sitesLoadOptions,
    teamsLoadOptions,
  } = props;
  const isDeactivated = !!_get(defaultValues, 'deactivatedAt');
  const { t } = useTranslation();
  const fields = getUserFormFields({
    countriesCodes,
    teamsLoadOptions,
    sitesLoadOptions,
    t,
    isSiteFieldRequired,
    prevValues: defaultValues,
  });

  return (
    <>
      {isDeactivated && (
        <div className={styles.deactivatedContent}>
          <Chip
            type={ChipTypes.chipRed}
            label={t('deactivated')}
            leftIcon="do_not_disturb_on"
            size={ChipSizes.medium}
          />
        </div>
      )}
      <Form
        formId="member"
        defaultValues={replaceNullsWithUndefined(defaultValues)}
        onSubmit={onSubmit}
        fields={fields}
        actionsComponent={UserFormActions}
        actionsComponentProps={{
          isEditForm: !_isEmpty(defaultValues),
          onCancel,
        }}
        graphQLErrors={graphQLErrors}
        graphQLErrorsReplacePathMap={{
          username: UserFieldNames.Username,
        }}
        loading={loading}
      />
    </>
  );
}

export default UserForm;
