import { Intervention, useInterventionQuery } from 'graphql-common';
import { WatchQueryFetchPolicy } from '@apollo/client';
import { ApolloError } from '@apollo/client/errors';

type Args = {
  id?: string;
  skip?: boolean;
  fetchPolicy?: WatchQueryFetchPolicy;
};

export type UseAssetInterventionQueryHookResult = {
  data: Intervention;
  loading: boolean;
  error: ApolloError | undefined;
  refetch: () => void;
  firstLoading: boolean;
  actionsCounter: number;
};

export default function useInterventionQueryHook(
  args: Args,
): UseAssetInterventionQueryHookResult {
  const { id = '', skip = true, fetchPolicy = 'network-only' } = args;
  const query = useInterventionQuery({
    skip,
    variables: { id },
    fetchPolicy,
  });
  const { refetch, error, loading, data: queryData, previousData } = query;
  const data = queryData || previousData;
  const firstLoading = loading && queryData === undefined;

  return {
    data: data?.data as Intervention,
    actionsCounter: data?.actionsCounter?.metadata?.totalCount || 0,
    loading,
    error,
    refetch,
    firstLoading,
  };
}
