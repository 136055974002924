import React from 'react';
import { useTranslation } from 'react-i18next';
import { GraphQLErrors } from '@apollo/client/errors';
import { Values, FieldItem } from '@lib/interfaces/form';
import Form from '@lib/components/ReactHookForm/FormContainer';
import replaceNullsWithUndefined from '@lib/utils/replaceNullsWithUndefined';
import { getAreaFields } from 'components/Site/utils';
import AreaEditFormActions from './AreaEditFormActions';

export interface FormProps {
  areaData?: Values;
  graphQLErrors?: GraphQLErrors;
  loading: boolean;
  onCancel: () => void;
  onSubmit: (values: Values) => void;
}

function AreaEditForm(props: FormProps) {
  const { areaData, graphQLErrors, loading, onCancel, onSubmit } = props;
  const { t } = useTranslation();
  const fields: FieldItem[] = getAreaFields({
    isEditForm: true,
    t,
  });

  return (
    <Form
      actionsComponent={AreaEditFormActions}
      actionsComponentProps={{ onCancel }}
      defaultValues={areaData ? replaceNullsWithUndefined(areaData) : {}}
      fields={fields}
      formId="area-edit"
      graphQLErrors={graphQLErrors}
      loading={loading}
      onSubmit={onSubmit}
    />
  );
}

export default AreaEditForm;
