import { Documentation } from 'graphql-common';
import AssetDocumentFieldNames from '@lib/enums/fieldNames/assetDocumentFieldNames';

export const DEFAULT_BULK_VALUES = {
  [AssetDocumentFieldNames.Documents]: [],
};

export default function getDefaultValuesFromQueryData(data: Documentation) {
  return {
    [AssetDocumentFieldNames.Name]: data?.name || '',
    [AssetDocumentFieldNames.Tags]: (data?.tags || []).map(({ id, name }) => ({
      value: id,
      label: name,
    })),
    [AssetDocumentFieldNames.Document]: data?.attachmentAttached,
  };
}
