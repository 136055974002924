import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import { v4 as uuidv4 } from 'uuid';
import IconButton, {
  IconButtonTypes,
} from '@lib/components/IconButton/IconButton';
import styles from './Carousel.module.scss';

type Props = {
  children: React.ReactNode[];
  withWrap?: boolean;
  spaceBetween?: number;
  slidesPerView?: number | 'auto';
};

export default function Carousel(props: Props) {
  const { children, withWrap, spaceBetween, slidesPerView = 1 } = props;
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);
  const [swiperInstance, setSwiperInstance] = useState<any>(null);

  useEffect(() => {
    if (swiperInstance?.params?.navigation) {
      swiperInstance.params.navigation.prevEl = navigationPrevRef.current;
      swiperInstance.params.navigation.nextEl = navigationNextRef.current;
      swiperInstance.navigation.update();
    }
  }, [swiperInstance, children]);

  return (
    <div
      className={classNames(styles.carousel, {
        [styles.carouselWithWrap]: withWrap,
      })}
    >
      <Swiper
        onSwiper={setSwiperInstance}
        className={uuidv4()}
        modules={[Navigation]}
        spaceBetween={spaceBetween}
        slidesPerView={slidesPerView}
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
        }}
        pagination={{ clickable: true }}
        autoplay={{ delay: 1000 }}
        loop={false}
      >
        {children.map((child) => (
          <SwiperSlide key={uuidv4()} style={{ width: 144 }}>
            {child}
          </SwiperSlide>
        ))}
      </Swiper>
      <div className={styles.leftNavWrap}>
        <IconButton
          ref={navigationPrevRef}
          icon="chevron_left"
          rounded
          type={IconButtonTypes.PrimaryFilled}
        />
      </div>
      <div className={styles.rightNavWrap}>
        <IconButton
          ref={navigationNextRef}
          icon="chevron_right"
          rounded
          type={IconButtonTypes.PrimaryFilled}
        />
      </div>
    </div>
  );
}
