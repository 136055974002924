import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import { GetUrlParams } from '@lib/enums/urls';
import usePaginationParams from '@lib/hooks/usePaginationParams';

function useSearchParam(defaultTab?: string) {
  const [searchParam, setSearchParam] = useQueryParam(
    GetUrlParams.Search,
    withDefault(StringParam, defaultTab),
  );
  const [paginationParams, setPaginationParams] = usePaginationParams();
  const onSetSearchParam = (v: string) => {
    setSearchParam(v);
    setPaginationParams({
      ...paginationParams,
      [GetUrlParams.Page]: '1',
    });
  };
  return [searchParam, onSetSearchParam] as const;
}

export default useSearchParam;
