import React from 'react';
import styles from './Separator.module.scss';

export default function Separator({ text }: { text: string }) {
  return (
    <div className={styles.separator}>
      <div>{text}</div>
    </div>
  );
}
