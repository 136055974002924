import React from 'react';
import { TFunction } from 'i18next';
import * as Yup from 'yup';
import _get from 'lodash/get';
import _without from 'lodash/without';
import Select from '@lib/components/Select/Select';
import Input from '@lib/components/Input/Input';
import { InputTypes } from '@lib/components/Input/enums';
import Typography from '@lib/components/Typography/Typography';
import {
  FieldChangeCallBackArgs,
  FieldItem,
  Values,
} from '@lib/interfaces/form';
import { getCodeValidation, getOptionShape } from '@lib/utils/yup';
import {
  MAX_TEXT_EDITOR_FIELD_LENGTH,
  MAX_TEXT_FIELD_LENGTH,
} from '@lib/enums/form';
import { GetLoadOptions } from '@lib/components/Select/types';
import ChipValueContainer from '@lib/components/Select/components/ChipValueContainer';
import DatePicker from '@lib/components/DateTimePicker/DatePicker';
import IconUploader from '@lib/components/IconUploader/IconUploader';
import CheckBoxSingleBool from '@lib/components/CheckBoxSingleBool/CheckBoxSingleBool';
import MaterialIcon from '@lib/components/MaterialIcon/MaterialIcon';
import AreaOption from '@lib/components/Select/components/AreaOption';
import ChipSingleValue from '@lib/components/Select/components/ChipSingleValue';
import AssetFieldNames from '@lib/enums/fieldNames/assetFieldNames';
import QrCodePrefixField from 'components/QrCodePrefixField/QrCodePrefixField';
import { ChipTypes } from '@lib/components/Chip/Chip';
import QrCodePrefix from '@lib/enums/qrCodePrefix';
import formStyles from '@lib/assets/styles/Form.module.scss';
import TextEditor from '@lib/components/TextEditor/TextEditor';
import { getFieldValidation } from '@lib/utils/validationUtils';

type FormFieldsArgs = {
  getAssetCategoriesLoadOptions: GetLoadOptions;
  isCategoriesViewOnly: boolean;
  isEditForm: boolean;
  openCategoryFormModal: () => void;
  getSiteAreasLoadOptions: GetLoadOptions;
  getSitesLoadOptions: GetLoadOptions;
  t: TFunction<'translation', undefined>;
};

export function getFormFields({
  getAssetCategoriesLoadOptions,
  isEditForm,
  openCategoryFormModal,
  getSiteAreasLoadOptions,
  getSitesLoadOptions,
  t,
  isCategoriesViewOnly,
}: FormFieldsArgs): FieldItem[] {
  return _without(
    [
      {
        name: 'asset-details-title',
        customRender: () => (
          <Typography variant="h3" key="asset-details">
            {t('asset-details')}
          </Typography>
        ),
      },
      {
        name: AssetFieldNames.Name,
        label: t('name'),
        element: Input,
        validation: Yup.string()
          .trim()
          .max(MAX_TEXT_FIELD_LENGTH, t('max-text-length-field-error'))
          .required(t('required-field-error')),
      },
      {
        name: AssetFieldNames.Code,
        label: t('code'),
        element: Input,
        validation: getCodeValidation(t, 10, true),
        componentProps: {
          disabled: isEditForm,
        },
      },
      {
        name: AssetFieldNames.Category,
        label: t('category'),
        element: Select,
        validation: Yup.object()
          .shape(getOptionShape(t, true))
          .required(t('required-field-error')),
        componentProps: {
          isAsync: true,
          getLoadOptions: getAssetCategoriesLoadOptions,
          defaultOptions: true,
          createNewOptionProps: isCategoriesViewOnly
            ? undefined
            : {
                title: t('create-new'),
                onCLick: openCategoryFormModal,
              },
          components: {
            ValueContainer: ChipValueContainer,
          },
        },
        formItemClassName: isEditForm ? formStyles.formColumn : undefined,
        fieldChangeCallBack: ({
          values,
          type,
          setValue,
          name,
        }: FieldChangeCallBackArgs) => {
          if (type === 'change' && name === AssetFieldNames.Category) {
            setValue(
              AssetFieldNames.QrCodePrefix,
              _get(values, AssetFieldNames.QrCodePrefixDefault),
              { shouldValidate: false },
            );
          }
        },
      },
      isEditForm
        ? {
            name: AssetFieldNames.QrCodePrefix,
            label: t('qr-code-prefix'),
            element: QrCodePrefixField,
            validation: Yup.object().shape(getOptionShape(t)),
            componentProps: {
              components: {
                SingleValue: ChipSingleValue,
              },
            },
            getComponentProps: (values: Values) => {
              const category = _get(values, AssetFieldNames.Category);
              const categoryId = _get(category, `value`);
              const categoryPrefix = _get(category, `data.prefix`);
              const defaultCategory = _get(
                values,
                AssetFieldNames.CategoryDefault,
              );
              const defaultCategoryId = _get(defaultCategory, `value`);
              const defaultCategoryPrefix = _get(
                defaultCategory,
                `data.prefix`,
              );
              return {
                options: [
                  {
                    value: QrCodePrefix.Asset,
                    label: QrCodePrefix.Asset,
                    color: ChipTypes.chipDisabled,
                  },
                  {
                    value: categoryId,
                    label: categoryPrefix,
                    color: ChipTypes.chipDisabled,
                  },
                  {
                    value: defaultCategoryId,
                    label: defaultCategoryPrefix,
                    color: ChipTypes.chipDisabled,
                  },
                ],
                disabled: true,
                categoryPrefix,
              };
            },
            formItemClassName: formStyles.formColumn,
            isUseWatch: true,
          }
        : undefined,
      {
        name: AssetFieldNames.Site,
        label: t('site'),
        element: Select,
        validation: Yup.object()
          .shape(getOptionShape(t, true))
          .required(t('required-field-error')),
        componentProps: {
          isAsync: true,
          getLoadOptions: getSitesLoadOptions,
          defaultOptions: true,
          disabled: isEditForm,
        },
        formItemClassName: formStyles.formColumn,
        fieldChangeCallBack: ({
          type,
          setValue,
          name,
        }: FieldChangeCallBackArgs) => {
          if (type === 'change' && name === AssetFieldNames.Site) {
            setValue(AssetFieldNames.SiteArea, null, { shouldValidate: false });
          }
        },
      },
      {
        name: AssetFieldNames.SiteArea,
        label: t('area'),
        element: Select,
        validation: Yup.object().shape(getOptionShape(t)).nullable(),
        componentProps: {
          isAsync: true,
          getLoadOptions: getSiteAreasLoadOptions,
          defaultOptions: true,
          disabledTooltipProps: {
            body: t('choose-site-first'),
          },
          components: {
            Option: AreaOption,
            SingleValue: ChipSingleValue,
          },
        },
        formItemClassName: formStyles.formColumn,
        isDisabledFn: (values: Values) =>
          _get(values, [AssetFieldNames.Site, 'value']) === undefined,
        getFieldKeyFn: (values: Values) => {
          const selectedSiteId = _get(values, [AssetFieldNames.Site, 'value']);
          if (selectedSiteId)
            return `${AssetFieldNames.Site}-${selectedSiteId}`;
          return AssetFieldNames.SiteArea;
        },
      },
      {
        name: AssetFieldNames.Description,
        label: t('description'),
        element: TextEditor,
        validation: getFieldValidation(false, MAX_TEXT_EDITOR_FIELD_LENGTH, t),
      },
      {
        name: 'specification-title',
        customRender: () => (
          <Typography
            variant="h3"
            key="specification-title"
            className={formStyles.blockStart}
          >
            {t('specification')}
          </Typography>
        ),
      },
      {
        name: AssetFieldNames.ServiceDate,
        label: t('date-put-into-service'),
        element: DatePicker,
        validation: Yup.string()
          .trim()
          .max(MAX_TEXT_FIELD_LENGTH, t('max-text-length-field-error')),
        formItemClassName: formStyles.formColumn,
        componentProps: {
          type: InputTypes.date,
        },
      },
      {
        name: AssetFieldNames.SerialNumber,
        label: t('serial-number'),
        element: Input,
        validation: Yup.string()
          .trim()
          .max(MAX_TEXT_FIELD_LENGTH, t('max-text-length-field-error')),
        formItemClassName: formStyles.formColumn,
      },
      {
        name: AssetFieldNames.Manufacturer,
        label: t('manufacturer'),
        element: Input,
        validation: Yup.string()
          .trim()
          .max(MAX_TEXT_FIELD_LENGTH, t('max-text-length-field-error')),
        formItemClassName: formStyles.formColumn,
      },
      {
        name: AssetFieldNames.Model,
        label: t('model'),
        element: Input,
        validation: Yup.string()
          .trim()
          .max(MAX_TEXT_FIELD_LENGTH, t('max-text-length-field-error')),
        formItemClassName: formStyles.formColumn,
      },
      {
        name: 'asset-icon-title',
        customRender: () => (
          <Typography
            variant="h3"
            key="asset-icon"
            className={formStyles.blockStart}
          >
            {t('asset-icon')} ({t('optional')})
          </Typography>
        ),
      },
      {
        name: AssetFieldNames.IconAttached,
        label: '',
        element: IconUploader,
      },
      {
        name: 'space-block',
        customRender: () => <div className={formStyles.blockStart} />,
        hideErrors: true,
      },
      {
        name: AssetFieldNames.PoorWifi,
        label: t('asset-poor-wifi-label'),
        element: CheckBoxSingleBool,
        componentProps: {
          options: [{ label: 'checked', value: 'checked' }],
          rightLabelIcon: <MaterialIcon icon="wifi_off" size="md-16" />,
        },
      },
    ],
    undefined,
  ) as FieldItem[];
}
