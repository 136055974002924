import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Task, TaskStatusEnum } from 'graphql-common';
import _get from 'lodash/get';
import _upperFirst from 'lodash/upperFirst';
import _toLower from 'lodash/toLower';
import { ApolloError } from '@apollo/client/errors';
import DashboardContentMaxWidth from '@lib/layouts/DashboardLayout/DashboardContentMaxWidth/DashboardContentMaxWidth';
import PageBody from '@lib/layouts/DashboardLayout/PageBody/PageBody';
import Button, {
  ButtonSizes,
  ButtonTypes,
} from '@lib/components/Button/Button';
import CircleLoader from '@lib/components/CircleLoader/CircleLoader';
import { materialSymbolsOutlined } from '@lib/enums/common';
import WhiteBox from '@lib/components/WhiteBox/WhiteBox';
import Typography from '@lib/components/Typography/Typography';
import MaterialIcon from '@lib/components/MaterialIcon/MaterialIcon';
import UserChip from '@lib/components/UserChip/UserChip';
import Chip, { ChipTypes } from '@lib/components/Chip/Chip';
import EntityViewStyles from '@lib/assets/styles/EntityView.module.scss';
import { getFormattedDate } from '@lib/utils/dateTimeHelpers';
import AssetPreview from '@lib/components/AssetPreview/AssetPreview';
import SupportiveMessage, {
  SupportiveMessageColor,
} from '@lib/components/SupportiveMessage/SupportiveMessage';
import ErrorPage from '@lib/components/ErrorPage/ErrorPage';
import CountMark, {
  CountMarkSizes,
  CountMarkTypes,
} from '@lib/components/CountMark/CountMark';
import getStatusFromError from '@lib/utils/getStatusFromError';
import { getPriorityChipType } from '@lib/utils/priority';
import { APP_URLS } from 'constants/urls';
import TaskMenuBar from 'components/TaskMenuBar/TaskMenuBar';
import TaskStatusChip from '@lib/components/TaskStatusChip/TaskStatusChip';
import TaskViewActions from 'components/Task/TaskViewActions';
import DashboardTitleHeader from '@lib/layouts/DashboardLayout/DashboardTitleHeader/DashboardTitleHeader';
import TextEditor from '@lib/components/TextEditor/TextEditor';
import ViewOnlyChip from '@lib/components/ViewOnlyChip/ViewOnlyChip';

interface Props {
  currentUserId?: string;
  isViewOnly?: boolean;
  onDeleteHandler: () => void;
  onDuplicateHandler: () => void;
  onEditHandler: () => void;
  onStatusChangeHandler?: (id: string, v: TaskStatusEnum) => void;
  openArchiveModal: () => void;
  openScheduleModal: () => void;
  taskData: Task;
  taskError?: ApolloError;
  taskLoading?: boolean;
}

function TaskView(props: Props) {
  const {
    currentUserId,
    isViewOnly,
    onDeleteHandler,
    onDuplicateHandler,
    onEditHandler,
    onStatusChangeHandler,
    openArchiveModal,
    openScheduleModal,
    taskData,
    taskError,
    taskLoading = false,
  } = props;
  const { t } = useTranslation();
  const {
    approver,
    archivedAt,
    asset,
    completedAt,
    deadlineAt,
    lateFor,
    description,
    id,
    lastDeadlineAt,
    name,
    originalCreatedAt,
    priority,
    site,
    siteArea,
    status,
  } = taskData || {};

  if (taskError) {
    return (
      <ErrorPage
        status={getStatusFromError(taskError)}
        description={taskError.message}
      />
    );
  }

  let onStatusChangeHandlerFn = onStatusChangeHandler;
  if (
    approver?.id !== currentUserId ||
    status === TaskStatusEnum.Completed ||
    status === TaskStatusEnum.Archived
  ) {
    onStatusChangeHandlerFn = undefined;
  }

  return (
    <PageBody withoutHeader>
      <TaskMenuBar id={id} />
      {taskLoading ? (
        <CircleLoader />
      ) : (
        <DashboardContentMaxWidth>
          <WhiteBox>
            <DashboardTitleHeader
              title={name}
              actions={
                isViewOnly ? (
                  <ViewOnlyChip key="view" />
                ) : (
                  <TaskViewActions
                    key="actions"
                    onDeleteHandler={onDeleteHandler}
                    onDuplicateHandler={onDuplicateHandler}
                    onEditHandler={onEditHandler}
                    openArchiveModal={openArchiveModal}
                    openScheduleModal={openScheduleModal}
                    taskData={taskData}
                  />
                )
              }
              withBottomSpace={false}
            />
            <div className={EntityViewStyles.infoList}>
              <div className={EntityViewStyles.infoItem}>
                <p className={EntityViewStyles.infoItemLabel}>
                  <MaterialIcon icon="segment" symbolsOutlined />
                  <Typography variant="caption">{t('priority')}</Typography>
                </p>
                <div className={EntityViewStyles.infoItemContent}>
                  {priority ? (
                    <Chip
                      type={getPriorityChipType(priority)}
                      label={_upperFirst(_toLower(priority))}
                      leftIcon="circle"
                    />
                  ) : (
                    <Typography variant="body">-</Typography>
                  )}
                </div>
              </div>
              <div className={EntityViewStyles.infoItem}>
                <p className={EntityViewStyles.infoItemLabel}>
                  <MaterialIcon icon="clock_loader_20" symbolsOutlined />
                  <Typography variant="caption">{t('status')}</Typography>
                </p>
                <div className={EntityViewStyles.infoItemContent}>
                  {status ? (
                    <TaskStatusChip
                      task={taskData}
                      onStatusChangeHandler={
                        isViewOnly ? undefined : onStatusChangeHandlerFn
                      }
                    />
                  ) : (
                    <Typography variant="body">-</Typography>
                  )}
                </div>
              </div>
              <div className={EntityViewStyles.infoItem}>
                <p className={EntityViewStyles.infoItemLabel}>
                  <MaterialIcon icon="home_pin" symbolsOutlined />
                  <Typography variant="caption">
                    {t('site-comma-area')}
                  </Typography>
                </p>
                <div
                  className={classNames(
                    EntityViewStyles.infoItemContent,
                    EntityViewStyles.infoItemContentRow,
                  )}
                >
                  {site ? (
                    <Chip
                      type={ChipTypes.chipGray}
                      label={_get(site, 'name')}
                    />
                  ) : (
                    <Typography variant="body">-</Typography>
                  )}
                  {siteArea ? (
                    <Chip
                      type={ChipTypes.chipGray}
                      label={_get(siteArea, 'name')}
                    />
                  ) : (
                    <Typography variant="body">-</Typography>
                  )}
                </div>
              </div>
              <div className={EntityViewStyles.infoItem}>
                <p className={EntityViewStyles.infoItemLabel}>
                  <MaterialIcon icon="calendar_today" symbolsOutlined />
                  <Typography variant="caption">{t('date-created')}</Typography>
                </p>
                <div className={EntityViewStyles.infoItemContent}>
                  <Typography variant="body">
                    {originalCreatedAt
                      ? getFormattedDate(originalCreatedAt as string)
                      : '-'}
                  </Typography>
                </div>
              </div>
              <div className={EntityViewStyles.infoItem}>
                <p className={EntityViewStyles.infoItemLabel}>
                  <MaterialIcon icon="history" symbolsOutlined />
                  <Typography variant="caption">
                    {t('last-task-order')}
                  </Typography>
                </p>
                <div className={EntityViewStyles.infoItemContent}>
                  <Typography variant="body">
                    {lastDeadlineAt
                      ? getFormattedDate(lastDeadlineAt as string)
                      : '-'}
                  </Typography>
                </div>
              </div>
              {status === TaskStatusEnum.Completed && (
                <div className={EntityViewStyles.infoItem}>
                  <p className={EntityViewStyles.infoItemLabel}>
                    <MaterialIcon icon="assignment_turned_in" symbolsOutlined />
                    <Typography variant="caption">
                      {t('completion-date')}
                    </Typography>
                  </p>
                  <div className={EntityViewStyles.infoItemContent}>
                    <Typography variant="body">
                      {completedAt
                        ? getFormattedDate(completedAt as string)
                        : '-'}
                    </Typography>
                  </div>
                </div>
              )}
              {status !== TaskStatusEnum.Completed &&
                status !== TaskStatusEnum.Archived && (
                  <div className={EntityViewStyles.infoItem}>
                    <p className={EntityViewStyles.infoItemLabel}>
                      <MaterialIcon icon="update" symbolsOutlined />
                      <Typography variant="caption">
                        {t('next-task-order')}
                      </Typography>
                    </p>
                    <div className={EntityViewStyles.infoItemContent}>
                      <Typography variant="body">
                        {deadlineAt
                          ? getFormattedDate(deadlineAt as string)
                          : '-'}
                      </Typography>
                    </div>
                  </div>
                )}
              {lateFor &&
                status !== TaskStatusEnum.Completed &&
                status !== TaskStatusEnum.Archived && (
                  <div className={EntityViewStyles.infoItem}>
                    <p className={EntityViewStyles.infoItemLabel}>
                      <MaterialIcon icon="clock_loader_20" symbolsOutlined />
                      <Typography variant="caption">{t('late-for')}</Typography>
                    </p>
                    <div className={EntityViewStyles.infoItemContent}>
                      <CountMark
                        value={`${lateFor} ${t('day-short')}`}
                        type={CountMarkTypes.red}
                        size={CountMarkSizes.large}
                      />
                    </div>
                  </div>
                )}
              {status === TaskStatusEnum.Archived && (
                <div className={EntityViewStyles.infoItem}>
                  <p className={EntityViewStyles.infoItemLabel}>
                    <MaterialIcon icon="archive" symbolsOutlined />
                    <Typography variant="caption">
                      {t('archiving-date')}
                    </Typography>
                  </p>
                  <div className={EntityViewStyles.infoItemContent}>
                    <Typography variant="body">
                      {archivedAt
                        ? getFormattedDate(archivedAt as string)
                        : '-'}
                    </Typography>
                  </div>
                </div>
              )}
              <div
                className={classNames(EntityViewStyles.infoItem, {
                  [EntityViewStyles.infoItemCentered]: !approver,
                })}
              >
                <p className={EntityViewStyles.infoItemLabel}>
                  <MaterialIcon icon="how_to_reg" symbolsOutlined />
                  <Typography variant="caption">{t('approver')}</Typography>
                </p>
                <div
                  className={classNames(
                    EntityViewStyles.infoItemContent,
                    EntityViewStyles.infoItemContentFullWidth,
                  )}
                >
                  {approver ? (
                    <UserChip user={approver} />
                  ) : (
                    <SupportiveMessage
                      text={t('approver-removed-from-system')}
                      color={SupportiveMessageColor.Yellow}
                      actions={
                        <Button
                          buttonText={t('edit')}
                          buttonType={ButtonTypes.primaryFilled}
                          buttonSize={ButtonSizes.small}
                          onClick={onEditHandler}
                        />
                      }
                      fullWidth
                      hasBottomSpace={false}
                    />
                  )}
                </div>
              </div>
              <div className={EntityViewStyles.infoItem}>
                <p className={EntityViewStyles.infoItemLabel}>
                  <MaterialIcon icon="inventory" symbolsOutlined />
                  <Typography variant="caption">{t('asset')}</Typography>
                </p>
                <div className={EntityViewStyles.infoItemContent}>
                  {asset ? (
                    <AssetPreview
                      url={APP_URLS.dashboard.assets.view.getDynamicPath({
                        pathParts: {
                          id: `${asset.id}`,
                        },
                      })}
                      name={asset.name}
                      code={asset?.fullCode}
                      icon={
                        asset.iconAttached?.urls?.small ||
                        asset.iconAttached?.urls?.original ||
                        asset.iconAttached?.fileTag
                      }
                    />
                  ) : (
                    <Typography variant="body">-</Typography>
                  )}
                </div>
              </div>
              {!!description && (
                <>
                  <div className={EntityViewStyles.infoItem}>
                    <p className={EntityViewStyles.infoItemLabel}>
                      <MaterialIcon icon="description" symbolsOutlined />
                      <Typography variant="caption">
                        {t('description')}
                      </Typography>
                    </p>
                  </div>
                  <TextEditor
                    readOnly
                    value={description}
                    className={EntityViewStyles.textEditor}
                  />
                </>
              )}
            </div>
          </WhiteBox>
          {taskData?.canDestroy?.value && (
            <div className={EntityViewStyles.actions}>
              <Button
                buttonText={t('delete-task')}
                buttonType={ButtonTypes.negativeOutlined}
                leftIcon="delete"
                leftIconClassName={materialSymbolsOutlined}
                onClick={onDeleteHandler}
              />
            </div>
          )}
        </DashboardContentMaxWidth>
      )}
    </PageBody>
  );
}

export default TaskView;
