import _get from 'lodash/get';
import _pick from 'lodash/pick';
import { RoleEnum, User } from 'graphql-common';
import { getAllOption } from '@lib/enums/form';
import { TFunction } from 'i18next';
import { InputTelValue } from '@lib/components/Input/InputTel';
import { SelectOption } from '@lib/components/Select/types';
import { Values } from '@lib/interfaces/form';
import UserFieldNames from '@lib/enums/fieldNames/userFieldNames';
import { getRoleOption } from '@lib/utils/roles';

export default function getDefaultValuesFromQueryData(
  t: TFunction<'translation', undefined>,
  data?: User,
): Values {
  if (data === undefined) return {};
  const roleValue = _get(data, 'role');
  const siteMemberships = _get(data, 'sites.collection', []) as unknown as {
    id: string;
    name: string;
  }[];
  let siteMembershipsArray = siteMemberships.map(({ id, name }) => ({
    label: name,
    value: id,
  }));
  if (siteMembershipsArray.length === 0 && roleValue === RoleEnum.FullAdmin) {
    siteMembershipsArray = [getAllOption(t)];
  }
  return {
    ..._pick(data, [
      'id',
      UserFieldNames.Email,
      UserFieldNames.FirstName,
      UserFieldNames.LastName,
      UserFieldNames.PersonalId,
      'deactivatedAt',
    ]),
    [UserFieldNames.Username]: _get(data, 'username'),
    [`${UserFieldNames.Email}-copy`]: _get(data, 'email'),
    [UserFieldNames.Role]: getRoleOption(_get(data, 'role')),
    [UserFieldNames.PhoneNumber]: {
      number: _get(data, 'phoneNumber.international', '') as string,
      country: _get(data, 'phoneNumber.country', '') as string,
    } as InputTelValue,
    [UserFieldNames.SiteMemberships]: siteMembershipsArray,
    [UserFieldNames.Team]: {
      value: _get(data, 'team.id'),
      label: _get(data, 'team.name'),
      color: _get(data, 'team.color'),
    } as SelectOption,
  };
}
