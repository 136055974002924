import { TFunction } from 'i18next';
import Icons from '@lib/components/Icons/Icons';
import { APP_URLS } from 'constants/urls';
import { NavItem, NavItemTypeEnum } from '@lib/components/Menu/Menu';

type Args = {
  onLogoutHandler: () => void;
  t: TFunction<'translation', undefined>;
};

export default function getProfileMenuItems({
  onLogoutHandler,
  t,
}: Args): NavItem<undefined>[] {
  return [
    {
      id: 'edit-profile',
      title: t('edit-profile'),
      leftIcon: Icons.person,
      rightIcon: Icons.chevronRight,
      to: APP_URLS.dashboard.profile.path,
      type: NavItemTypeEnum.Secondary,
    },
    {
      id: 'logout',
      title: t('logout'),
      leftIcon: Icons.login,
      onClick: onLogoutHandler,
      type: NavItemTypeEnum.Error,
    },
  ];
}
