import { useState } from 'react';
import { User } from 'graphql-common';
import { UseBulkSelectResult } from '@lib/hooks/useTableItemsBulkSelect';

interface UseModalManagementProps {
  useBulkSelectResult: UseBulkSelectResult<User>;
}

export interface UseModalManagementResult {
  closeUserFormModal: () => void;
  isUserFormModalOpened: boolean;
  openUserFormModal: () => void;
}

export default function useSiteUsersModalManagement(
  props: UseModalManagementProps,
): UseModalManagementResult {
  const { useBulkSelectResult } = props;
  const { handleSelectSingleTableItem } = useBulkSelectResult;

  // State
  const [isUserFormModalOpened, setUserFormModalOpened] = useState(false);

  // Actions
  const openUserFormModal = () => setUserFormModalOpened(true);
  const closeUserFormModal = () => {
    setUserFormModalOpened(false);
    handleSelectSingleTableItem(null);
  };

  return {
    closeUserFormModal,
    isUserFormModalOpened,
    openUserFormModal,
  };
}
