import Chip, { ChipSizes } from '@lib/components/Chip/Chip';
import ChipWithDropDown from '@lib/components/ChipWithDropDown/ChipWithDropDown';
import Menu, { NavItemTypeEnum } from '@lib/components/Menu/Menu';
import { Task, TaskStatusEnum } from 'graphql-common';
import _startCase from 'lodash/startCase';
import _toLower from 'lodash/toLower';
import _upperFirst from 'lodash/upperFirst';
import React from 'react';
import { useTranslation } from 'react-i18next';
import getTaskStatusChipType from '@lib/utils/getTaskStatusChipType';

type Props = {
  onStatusChangeHandler?: (id: string, v: TaskStatusEnum) => void;
  task: Task;
};

export default function TaskStatusChip(props: Props) {
  const { onStatusChangeHandler, task } = props;
  const { id, status } = task;
  const { t } = useTranslation();
  const options = [
    { value: TaskStatusEnum.Validated, title: t('validated') },
    { value: TaskStatusEnum.OnValidation, title: t('on-validation') },
    { value: TaskStatusEnum.Rejected, title: t('rejected') },
  ];
  let label: string | undefined = '-';
  if (status) {
    label = options.find(({ value }) => value === status)?.title;
  }
  if (label === undefined) label = _upperFirst(_toLower(_startCase(status)));
  const type = getTaskStatusChipType(status);
  if (onStatusChangeHandler !== undefined) {
    const chipLeftIcon = (statusItem: TaskStatusEnum) => (
      <Chip
        leftIcon="circle"
        type={getTaskStatusChipType(statusItem)}
        size={ChipSizes.smallLeftIconOnly}
      />
    );
    const getBody = () => (
      <Menu
        navItems={options
          .filter(({ value }) => value !== status)
          .map(({ value, title }) => ({
            id: value,
            title,
            onClick: () => onStatusChangeHandler(id, value),
            type: NavItemTypeEnum.Secondary,
            leftIcon: () => chipLeftIcon(value),
          }))}
      />
    );
    return (
      <div>
        <ChipWithDropDown
          label={label}
          type={type}
          leftIcon="circle"
          width={160}
          body={getBody}
        />
      </div>
    );
  }
  return <Chip type={type} label={label} leftIcon="circle" />;
}
