import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import _get from 'lodash/get';
import IconButton, {
  IconButtonTypes,
} from '@lib/components/IconButton/IconButton';
import Tooltip from '@lib/components/Tooltip/Tooltip';
import CheckBoxSingleBool from '@lib/components/CheckBoxSingleBool/CheckBoxSingleBool';
import FormItem from '@lib/components/ReactHookForm/FormItem';
import FormTemplateFieldNames from '@lib/enums/fieldNames/formTemplateFieldNames';
import { kindFieldsWithoutRequiredField } from 'components/FormTemplateBuilder/constants';
import { FormTemplateQuestionFieldsKindEnum } from 'graphql-common';
import classNames from 'classnames';
import styles from './FormTemplateBuilderQuestionEdit.module.scss';
import { ExtendedQuestion } from '../types';

interface Props {
  children: React.ReactNode;
  onDuplicate: () => void;
  onRemove: () => void;
  parentIndex: number;
  parentName: string;
  value: ExtendedQuestion;
}

function FormTemplateBuilderQuestionEdit(props: Props) {
  const { children, onDuplicate, onRemove, parentIndex, parentName, value } =
    props;
  const { t } = useTranslation();
  const { required, showDescription, showAttachments } = value || {};
  const kind = _get(
    value,
    `${FormTemplateFieldNames.Fields}.0.${FormTemplateFieldNames.Kind}`,
    '',
  ) as FormTemplateQuestionFieldsKindEnum;
  const isRequiredVisible = !kindFieldsWithoutRequiredField.includes(kind);
  const descriptionBody = showDescription
    ? t('remove-description')
    : t('add-description');
  const attachmentsBody = showAttachments ? t('remove-image') : t('add-image');

  return (
    <>
      <div>{children}</div>
      <div
        className={classNames(styles.questionFormFooter, {
          [styles.questionFormFooterWithRequired]: isRequiredVisible,
        })}
      >
        <div>
          <Tooltip body={descriptionBody}>
            <FormItem
              name={`${parentName}.${parentIndex}.${FormTemplateFieldNames.ShowDescription}`}
              element={CheckBoxSingleBool}
              componentProps={{
                checked: !!showDescription,
                id: `${parentName}-${FormTemplateFieldNames.ShowDescription}`,
                iconProps: {
                  icon: 'description',
                  iconChecked: 'description',
                  symbolsOutlined: true,
                },
              }}
              hideErrors
              formItemClassName={styles.questionFormFooterBtn}
            />
          </Tooltip>
        </div>
        <div>
          <Tooltip body={attachmentsBody}>
            <FormItem
              name={`${parentName}.${parentIndex}.${FormTemplateFieldNames.ShowAttachments}`}
              element={CheckBoxSingleBool}
              componentProps={{
                checked: !!showAttachments,
                id: `${parentName}-${FormTemplateFieldNames.ShowAttachments}`,
                iconProps: {
                  icon: 'add_photo_alternate',
                  iconChecked: 'add_photo_alternate',
                  symbolsOutlined: true,
                },
              }}
              hideErrors
              formItemClassName={styles.questionFormFooterBtn}
            />
          </Tooltip>
        </div>
        {isRequiredVisible ? (
          <>
            <span className={styles.questionFormFooterSeparator} />
            <div className={styles.questionFormFooterRequiredField}>
              <FormItem
                name={`${parentName}.${parentIndex}.${FormTemplateFieldNames.Required}`}
                label={t('required')}
                element={CheckBoxSingleBool}
                componentProps={{
                  checked: !!required,
                  id: `${parentName}-${FormTemplateFieldNames.Required}`,
                }}
                hideErrors
              />
            </div>
          </>
        ) : (
          <div />
        )}
        <div>
          <Tooltip
            body={t('copy-question')}
            contentClassName={styles.questionFormFooterBtn}
          >
            <IconButton
              // className={styles.subFieldDeleteBtn}
              icon="content_copy"
              onClick={onDuplicate}
              symbolsOutlined
              rounded
              type={IconButtonTypes.SecondaryFilled}
            />
          </Tooltip>
        </div>
        <div>
          <Tooltip
            body={t('delete')}
            contentClassName={styles.questionFormFooterBtn}
          >
            <IconButton
              // className={styles.subFieldDeleteBtn}
              icon="delete"
              onClick={onRemove}
              symbolsOutlined
              rounded
              type={IconButtonTypes.SecondaryFilled}
            />
          </Tooltip>
        </div>
      </div>
    </>
  );
}

export default memo(FormTemplateBuilderQuestionEdit);
