export enum FieldNames {
  address = 'address',
  areas = 'areas',
  body = 'body',
  code = 'code',
  conductCodeSections = 'conductCodeSections',
  description = 'description',
  email = 'email',
  lat = 'lat',
  locationPhotoAttached = 'locationPhotoAttached',
  lon = 'lon',
  name = 'name',
  phoneNumber = 'phoneNumber',
  poorWifi = 'poorWifi',
  title = 'title',
  userId = 'userId',
}
