import React from 'react';
import { TFunction } from 'i18next';
import Button, { ButtonTypes } from '@lib/components/Button/Button';
import { materialSymbolsOutlined } from '@lib/enums/common';

type Args = {
  onBulkDelete: () => void;
  selectedItemsCount?: number;
  t: TFunction<'translation', undefined>;
  isViewOnly: boolean;
};

export default function getTableActionsForSelected(args: Args) {
  const { onBulkDelete, selectedItemsCount = 0, t, isViewOnly } = args;
  return isViewOnly
    ? []
    : [
        <Button
          key="bulk-delete"
          buttonText={`${t('delete-selected')} (${selectedItemsCount})`}
          buttonType={ButtonTypes.negativeOutlined}
          leftIcon="delete"
          leftIconClassName={materialSymbolsOutlined}
          onClick={onBulkDelete}
        />,
      ];
}
