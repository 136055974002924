import React from 'react';
import { TFunction } from 'i18next';
import * as Yup from 'yup';
import { FieldItem } from '@lib/interfaces/form';
import Input from '@lib/components/Input/Input';
import FieldNames from '@lib/enums/fieldNames/teamFieldNames';
import {
  MAX_TEXT_EDITOR_FIELD_LENGTH,
  MAX_TEXT_FIELD_LENGTH,
} from '@lib/enums/form';
import Select, {
  SelectComponents,
  SelectProps,
} from '@lib/components/Select/Select';
import { GetLoadOptions } from '@lib/components/Select/types';
import UserChipsListField from '@lib/components/UserChipsListField/UserChipsListField';
import Typography from '@lib/components/Typography/Typography';
import UserOption from '@lib/components/Select/components/UserOption';
import {
  getFieldValidation,
  getOptionsArrayValidation,
} from '@lib/utils/validationUtils';
import MultiSelectValueContainer from '@lib/components/Select/components/MultiSelectValueContainer';
import RadioButtonGroup, {
  RadioButtonGroupOption,
  RadioButtonWrapViews,
} from '@lib/components/RadioButtonGroup/RadioButtonGroup';
import { getOptionShape } from '@lib/utils/yup';
import { ChipTypes } from '@lib/components/Chip/Chip';
import RadioButtonAsChipColor from '@lib/components/RadioButtonAsChipColor/RadioButtonAsChipColor';
import formStyles from '@lib/assets/styles/Form.module.scss';
import SupportiveMessage, {
  SupportiveMessageColor,
} from '@lib/components/SupportiveMessage/SupportiveMessage';
import TextEditor from '@lib/components/TextEditor/TextEditor';
import { UseTeamQueriesResult } from './useTeamQueries';
import TeamAccessField from '../TeamAccess/TeamAccessField';

type Args = {
  teamId?: string;
  getTeamMembersLoadOptions: GetLoadOptions;
  getAssetCategoriesLoadOptions: GetLoadOptions;
  getInterventionCategoriesLoadOptions: GetLoadOptions;
  t: TFunction<'translation', undefined>;
  useTeamQueriesResult: UseTeamQueriesResult;
};

export default function getTeamFormFields(args: Args): FieldItem[] {
  const {
    getTeamMembersLoadOptions,
    getAssetCategoriesLoadOptions,
    getInterventionCategoriesLoadOptions,
    teamId,
    useTeamQueriesResult,
    t,
  } = args;
  return [
    {
      name: 'details-title',
      customRender: () => (
        <Typography variant="h3" key="details">
          {t('team-details')}
        </Typography>
      ),
    },
    {
      name: FieldNames.Name,
      label: t('team-name'),
      element: Input,
      validation: getFieldValidation(true, MAX_TEXT_FIELD_LENGTH, t),
    },
    {
      name: FieldNames.Description,
      label: t('description'),
      element: TextEditor,
      validation: getFieldValidation(false, MAX_TEXT_EDITOR_FIELD_LENGTH, t),
    },
    {
      name: FieldNames.Color,
      label: t('label-color'),
      element: RadioButtonGroup,
      validation: Yup.object()
        .shape(getOptionShape(t, true))
        .required(t('required-field-error')),
      componentProps: {
        options: (Object.keys(ChipTypes) as Array<keyof typeof ChipTypes>).map(
          (chipType) => ({
            value: ChipTypes[chipType],
            label: ChipTypes[chipType],
          }),
        ) as RadioButtonGroupOption[],
        RadioButtonComponent: RadioButtonAsChipColor,
        wrapView: RadioButtonWrapViews.colors,
      },
    },
    {
      name: 'members-assignation-title',
      customRender: () => (
        <Typography
          variant="h3"
          key="members-assignation-title"
          className={formStyles.blockStart}
        >
          {t('members-assignation-title')}
        </Typography>
      ),
    },
    {
      name: FieldNames.Users,
      label: t('find-team-members'),
      element: Select,
      validation: getOptionsArrayValidation(false, t),
      componentProps: {
        isAsync: true,
        isMulti: true,
        getLoadOptions: getTeamMembersLoadOptions,
        defaultOptions: false,
        components: {
          Option: UserOption,
          ValueContainer: MultiSelectValueContainer,
        } as unknown as SelectComponents,
        disabledTooltipProps: {
          body: t('choose-site-first'),
        },
      } as SelectProps,
      hideErrorsSpace: true,
    },
    {
      name: `${FieldNames.Users}-list`,
      element: UserChipsListField,
      componentProps: {
        fieldName: FieldNames.Users,
        teamId,
      },
      isUseWatch: true,
    },
    {
      name: 'members-assignation-message',
      customRender: () => (
        <SupportiveMessage
          text={t('team-members-field-message')}
          color={SupportiveMessageColor.Yellow}
        />
      ),
    },
    {
      name: 'team-access-rights',
      customRender: () => (
        <Typography
          variant="h3"
          key="team-access-rights"
          className={formStyles.blockStart}
        >
          {t('team-access-rights')}
        </Typography>
      ),
    },
    {
      name: FieldNames.Accesses,
      element: TeamAccessField,
      componentProps: {
        getAssetCategoriesLoadOptions,
        getInterventionCategoriesLoadOptions,
        useTeamQueryHookResult: useTeamQueriesResult.useTeamQueryHookResult,
      },
      isUseWatch: true,
      validation: Yup.object(),
    },
    {
      name: 'team-accesses-message',
      customRender: () => (
        <SupportiveMessage
          text={t('team-accesses-field-message')}
          color={SupportiveMessageColor.Yellow}
        />
      ),
    },
  ];
}
