import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import _get from 'lodash/get';
import FormItem from '@lib/components/ReactHookForm/FormItem';
import Input from '@lib/components/Input/Input';
import { InputTypes } from '@lib/components/Input/enums';
import { Values } from '@lib/interfaces/form';
import {
  ConditionEnum,
  FormTemplateQuestionFieldsKindEnum,
  GroupEnum,
} from 'graphql-common';
import Typography from '@lib/components/Typography/Typography';
import Button, {
  ButtonSizes,
  ButtonTypes,
} from '@lib/components/Button/Button';
import { materialSymbolsOutlined } from '@lib/enums/common';
import FormTemplateFieldNames from '@lib/enums/fieldNames/formTemplateFieldNames';
import { fieldLabelByCondition } from '../constants';
import styles from './QuestionFieldOption.module.scss';

interface Props {
  kind: FormTemplateQuestionFieldsKindEnum;
  parentGroupIndex: number;
  parentIndex: number;
  parentName: string;
  parentValue: Values[];
}

function QuestionFieldOptionFormItem(props: Props) {
  const { kind, parentGroupIndex, parentIndex, parentName, parentValue } =
    props;
  const { t } = useTranslation();
  const optionValues = _get(
    parentValue,
    [parentIndex, FormTemplateFieldNames.Values],
    [],
  ) as string[];
  const groupValue = _get(
    parentValue,
    [parentIndex, FormTemplateFieldNames.Group],
    '',
  ) as GroupEnum;
  const conditionValue = _get(
    parentValue,
    [parentIndex, FormTemplateFieldNames.Condition],
    '',
  ) as ConditionEnum;

  const getLabelText = () => {
    if (groupValue === GroupEnum.Columns) return t('column');
    if (groupValue === GroupEnum.Rows) return t('row');
    return t('option');
  };

  const getLabel = () => {
    if (conditionValue) return t(fieldLabelByCondition[conditionValue]);
    if (kind === FormTemplateQuestionFieldsKindEnum.ImportantNotice)
      return t('important-notice-message');
    if (kind === FormTemplateQuestionFieldsKindEnum.DecisionBox)
      return t('decision-box-message');
    if (parentGroupIndex !== undefined)
      return `${getLabelText()} ${parentGroupIndex + 1}`;
    return `${getLabelText()} ${parentIndex + 1}`;
  };

  if (kind === FormTemplateQuestionFieldsKindEnum.YesNo) {
    return (
      <div className={styles.yesNoFieldOption}>
        <Button
          buttonType={ButtonTypes.primaryOutlined}
          buttonText={optionValues[0]}
          buttonSize={ButtonSizes.large}
          leftIcon={groupValue === GroupEnum.Yes ? 'check_circle' : 'cancel'}
          leftIconClassName={materialSymbolsOutlined}
          fullWidth
          disabled
        />
      </div>
    );
  }
  if (conditionValue === ConditionEnum.Between) {
    return (
      <div className={styles.conditionFields}>
        <FormItem
          name={`${parentName}.${parentIndex}.${FormTemplateFieldNames.Values}.0`}
          label={t(fieldLabelByCondition[conditionValue])}
          element={Input}
          componentProps={{
            type: InputTypes.number,
            name: `${parentName}.${parentIndex}.values.0`,
            precision: 2,
          }}
          formItemClassName={styles.formItem}
        />
        <div className={styles.conditionFieldsSeparator}>
          <Typography variant="body" strong>
            -
          </Typography>
        </div>
        <FormItem
          name={`${parentName}.${parentIndex}.${FormTemplateFieldNames.Values}.1`}
          label={t(fieldLabelByCondition[conditionValue])}
          element={Input}
          componentProps={{
            type: InputTypes.number,
            name: `${parentName}.${parentIndex}.${FormTemplateFieldNames.Values}.1`,
            precision: 2,
          }}
          formItemClassName={styles.formItem}
        />
      </div>
    );
  }
  if (conditionValue) {
    return (
      <FormItem
        name={`${parentName}.${parentIndex}.${FormTemplateFieldNames.Values}.0`}
        label={getLabel()}
        element={Input}
        componentProps={{
          type: InputTypes.number,
          name: `${parentName}.${parentIndex}.${FormTemplateFieldNames.Values}.0`,
          precision: 2,
        }}
        formItemClassName={styles.formItem}
      />
    );
  }
  if (kind === FormTemplateQuestionFieldsKindEnum.CheckboxButtonGrid) {
    return (
      <FormItem
        name={`${parentName}.${parentIndex}.${FormTemplateFieldNames.Values}.0`}
        element={Input}
        componentProps={{
          hasBorder: false,
          hasErrorAsTooltip: true,
          name: `${parentName}.${parentIndex}.${FormTemplateFieldNames.Values}.0`,
          resize: true,
          type: InputTypes.textarea,
        }}
        formItemClassName={styles.formItem}
        hideErrors
      />
    );
  }
  return (
    <FormItem
      name={`${parentName}.${parentIndex}.${FormTemplateFieldNames.Values}.0`}
      label={getLabel()}
      element={Input}
      componentProps={{
        name: `${parentName}.${parentIndex}.${FormTemplateFieldNames.Values}.0`,
      }}
      formItemClassName={styles.formItem}
    />
  );
}

export default memo(QuestionFieldOptionFormItem);
