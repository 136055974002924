import React from 'react';
import Popover from '@lib/components/Popover/Popover';
import IconButton, {
  IconButtonTypes,
} from '@lib/components/IconButton/IconButton';
import Menu, { NavItem } from '@lib/components/Menu/Menu';
import styles from './ActionsMenu.module.scss';

interface Props<T> {
  actions: NavItem<T>[];
  disabled?: boolean;
  iconButtonRounded?: boolean;
  iconButtonType?: IconButtonTypes;
}

function ActionsMenu<T>(props: Props<T>) {
  const {
    actions,
    disabled,
    iconButtonType = IconButtonTypes.PrimaryOutlined,
    iconButtonRounded,
  } = props;
  if (!actions?.length) return null;
  const getBody = () => <Menu navItems={actions} listClassName={styles.body} />;
  return (
    <Popover body={getBody} placement="bottomRight" width={196}>
      {({ handleToggle, isOpen }) => (
        <div>
          <IconButton
            icon="more_horiz"
            rounded={iconButtonRounded}
            onClick={() => handleToggle()}
            isFocused={isOpen}
            type={iconButtonType}
            disabled={disabled}
          />
        </div>
      )}
    </Popover>
  );
}

export default ActionsMenu;
