import { LazyQueryExecFunction } from '@apollo/client';

import {
  AssetCategoriesSearchListQuery,
  AssetCategoriesSearchListQueryVariables,
  AssetCategory,
  AssetCategoryFilterInputObject,
  SortDirectionEnum,
} from 'graphql-common';
import { DEFAULT_SEARCH_LIST_VARIABLES } from '@lib/enums/urls';
import getLoadOptionsResult from '@lib/utils/getLoadOptionsResult';
import createSearchFilters from '@lib/utils/createSearchFilters';
import QrCodePrefix from '@lib/enums/qrCodePrefix';
import { LoadOptionsParams } from '@lib/components/Select/types';

type FetchLazyQuery = LazyQueryExecFunction<
  AssetCategoriesSearchListQuery,
  any
>;

const fetchSearchList = async (
  searchQuery: string,
  fetchLazyQuery: FetchLazyQuery,
  page: number,
) => {
  const options: { variables: AssetCategoriesSearchListQueryVariables } = {
    variables: {
      ...DEFAULT_SEARCH_LIST_VARIABLES,
      page,
      sorts: { name: SortDirectionEnum.Asc },
    },
  };
  const filters: AssetCategoryFilterInputObject[] = [];
  if (searchQuery) {
    filters.push(...createSearchFilters(searchQuery, [{ fieldName: 'name' }]));
  }
  if (filters.length) {
    options.variables.filters = filters;
  }
  const { data } = await fetchLazyQuery(options);
  return getLoadOptionsResult(
    data,
    (dataItem: AssetCategory) => ({
      value: dataItem.id,
      label: dataItem.name,
      color: dataItem.color,
      data: {
        prefix: dataItem.prefix || QrCodePrefix.Asset,
      },
    }),
    searchQuery,
  );
};

export const getAssetCategoriesLoadOptions =
  (fetchLazyQuery: FetchLazyQuery) =>
  (searchQuery: string, loadedOptions: unknown, params: LoadOptionsParams) =>
    fetchSearchList(searchQuery, fetchLazyQuery, params?.page || 1);
