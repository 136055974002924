import React, { useEffect, useRef } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ComponentEnum, LevelEnum, SiteArea } from 'graphql-common';
import SiteAreas from 'components/SiteAreas/SiteAreas';
import BreadCrumbs from '@lib/components/BreadCrumbs/BreadCrumbs';
import { getBreadCrumbsDataByKey } from 'utils/breadcrumbs';
import ConfirmationModal from '@lib/components/ConfirmationModal/ConfirmationModal';
import { Values } from '@lib/interfaces/form';
import Modal from '@lib/components/Modal/Modal';
import { SubmitAction } from '@lib/enums/form';
import AreaEditForm from 'components/SiteArea/AreaEditForm/AreaEditForm';
import AreaView from 'components/SiteArea/AreaView/AreaView';
import AreaCreateForm from 'components/SiteArea/AreaCreateForm/AreaCreateForm';
import { FormRef } from '@lib/components/ReactHookForm/FormContainer';
import GetQrCodeForm from 'components/GetQrCodeForm/GetQrCodeForm';
import OfflineLabel from '@lib/components/OfflineLabel/OfflineLabel';
import { useTableItemsBulkSelect } from '@lib/hooks/useTableItemsBulkSelect';
import useOutletContext, { ContextProps } from 'hooks/useOutletContext';
import { IconName } from '@lib/components/Modal/enums';
import Trans from '@lib/components/Trans/Trans';
import useSiteAreasModalManagement from './utils/useSiteAreasModalManagement';
import useSiteAreasActions from './utils/useSiteAreasActions';
import getSiteAreasActionsForSelected from './utils/getSiteAreasActionsForSelected';
import getSiteAreasActionsMenu from './utils/getSiteAreasActionsMenu';
import getSiteAreasColumns from './utils/getSiteAreasColumns';
import styles from './SiteAreasContainer.module.scss';

function SiteAreasContainer() {
  const { t } = useTranslation();
  const submitNewAreaRef = useRef<SubmitAction>();
  const formNewAreaRef = useRef<FormRef>();
  const { id: siteId = '' } = useParams();
  const { pathname } = useLocation();

  const { getUserAccessByComponent }: ContextProps = useOutletContext();
  const isViewOnly =
    getUserAccessByComponent(ComponentEnum.SitesManagement) === LevelEnum.Read;

  const useBulkSelectResult = useTableItemsBulkSelect<SiteArea>();
  const { getSelectedItemsCount, isAllTableItemsSelected } =
    useBulkSelectResult;

  const useModalManagementResult = useSiteAreasModalManagement({
    submitNewAreaRef,
    useBulkSelectResult,
  });
  const {
    closeGetQrCodeModal,
    closeSiteAreaCreateModal,
    closeSiteAreaDeleteModal,
    closeSiteAreaModal,
    isGetQrCodeModalOpened,
    isSiteAreaCreateModalOpened,
    isSiteAreaDeleteModalOpened,
    isSiteAreaEditModalOpened,
    isSiteAreaViewModalOpened,
    openSiteAreaCreateModal,
    openSiteAreaEditModal,
    openSiteAreaViewModal,
  } = useModalManagementResult;

  const {
    collection,
    createSiteAreaLoading,
    createSiteAreaReset,
    getQrCode,
    getQrCodesForSelected,
    getSiteMembershipsLoadOptions,
    graphQLErrors,
    onBulkDelete,
    onConfirmedDelete,
    onCreate,
    onDelete,
    onEditHandler,
    onQrCodeCreate,
    onUpdateHandler,
    onViewHandler,
    previousSiteAreas,
    siteAreaData,
    siteAreaError,
    siteAreaName,
    siteAreasLoading,
    siteData,
    siteError,
    siteLoading,
    totalCount,
    updateSiteAreaMutationLoading,
    updateSiteAreaReset,
    qrCodeCreateMutationResult,
  } = useSiteAreasActions({
    formNewAreaRef,
    siteId,
    submitNewAreaRef,
    useModalManagementResult,
    useBulkSelectResult,
  });

  const selectedItemsCount = getSelectedItemsCount(totalCount);

  const tableColumns = getSiteAreasColumns({ getQrCode, onViewHandler, t });
  const tableActionsMenu = getSiteAreasActionsMenu({
    onViewHandler,
    onEditHandler,
    onDelete,
    t,
    isViewOnly,
  });
  const tableActionsForSelected = getSiteAreasActionsForSelected({
    getQrCodesForSelected,
    onBulkDelete,
    selectedItemsCount,
    t,
    isViewOnly,
  });

  const toggleSiteAreaModal = () => {
    if (isSiteAreaEditModalOpened) {
      openSiteAreaViewModal();
    } else if (isSiteAreaViewModalOpened) {
      openSiteAreaEditModal();
    }
  };

  useEffect(() => {
    if (!isSiteAreaCreateModalOpened) {
      createSiteAreaReset();
    }
    if (!isSiteAreaEditModalOpened) {
      updateSiteAreaReset();
    }
  }, [
    createSiteAreaReset,
    isSiteAreaCreateModalOpened,
    isSiteAreaEditModalOpened,
    updateSiteAreaReset,
  ]);

  return (
    <>
      <BreadCrumbs
        data={getBreadCrumbsDataByKey({
          pathname,
          t,
          entity: siteData as Values,
        })}
      />
      <SiteAreas
        collection={collection}
        openSiteAreaCreateModal={openSiteAreaCreateModal}
        siteAreaError={siteAreaError}
        siteAreasCalled={previousSiteAreas !== undefined}
        siteAreasLoading={siteAreasLoading}
        siteData={siteData}
        siteError={siteError}
        siteId={siteId}
        siteLoading={siteLoading}
        tableActionsForSelected={tableActionsForSelected}
        tableActionsMenu={tableActionsMenu}
        tableColumns={tableColumns}
        totalEntries={totalCount}
        useBulkSelectResult={useBulkSelectResult}
        isViewOnly={isViewOnly}
      />
      <Modal
        isOpen={isSiteAreaViewModalOpened || isSiteAreaEditModalOpened}
        onClose={closeSiteAreaModal}
        title={isSiteAreaEditModalOpened ? t('edit-site-area') : siteAreaName}
        titleAlign="left"
        descriptionChildren={
          isSiteAreaViewModalOpened && siteAreaData?.poorWifi ? (
            <div className={styles.offlineLabel}>
              <OfflineLabel />
            </div>
          ) : undefined
        }
      >
        <div>
          {isSiteAreaEditModalOpened && (
            <AreaEditForm
              areaData={siteAreaData as Values}
              graphQLErrors={graphQLErrors}
              loading={updateSiteAreaMutationLoading}
              onCancel={closeSiteAreaModal}
              onSubmit={onUpdateHandler}
            />
          )}
          {isSiteAreaViewModalOpened && (
            <AreaView areaData={siteAreaData} onEdit={toggleSiteAreaModal} />
          )}
        </div>
      </Modal>
      <Modal
        isOpen={isSiteAreaCreateModalOpened}
        onClose={closeSiteAreaCreateModal}
        title={t('create-new-site-area')}
        titleAlign="left"
      >
        <AreaCreateForm
          formNewAreaRef={formNewAreaRef}
          graphQLErrors={graphQLErrors}
          loading={createSiteAreaLoading}
          onCancel={closeSiteAreaCreateModal}
          onSubmit={onCreate}
          submitNewAreaRef={submitNewAreaRef}
        />
      </Modal>
      <Modal
        isOpen={isGetQrCodeModalOpened}
        onClose={closeGetQrCodeModal}
        title={
          selectedItemsCount > 1 || isAllTableItemsSelected
            ? t('get-area-qr-codes')
            : t('get-area-qr-code')
        }
      >
        <GetQrCodeForm
          getRecipientsLoadOptions={getSiteMembershipsLoadOptions}
          graphQLErrors={qrCodeCreateMutationResult?.error?.graphQLErrors}
          loading={false}
          onCancel={closeGetQrCodeModal}
          onSubmit={onQrCodeCreate}
          selectedItemsCount={selectedItemsCount}
        />
      </Modal>
      <ConfirmationModal
        cancelButtonLabel={t('cancel')}
        confirmButtonLabel={t('yes-delete')}
        description={
          <Trans
            i18nKey={
              selectedItemsCount > 1
                ? 'delete-site-areas-text'
                : 'delete-site-area-text'
            }
            values={{ count: selectedItemsCount }}
          />
        }
        icon={IconName.Delete}
        isOpen={isSiteAreaDeleteModalOpened}
        onClose={closeSiteAreaDeleteModal}
        onConfirm={onConfirmedDelete}
        title={t('are-you-sure')}
      />
    </>
  );
}

export default SiteAreasContainer;
