import _get from 'lodash/get';
import { FormTemplate, useFormTemplateQuery } from 'graphql-common';
import getFormTemplateValuesFromQueryData from 'routes/FormTemplate/utils/getFormTemplateValuesFromQueryData';
import { FormTemplateMode } from 'routes/FormTemplate/enums';
import { Values } from '@lib/interfaces/form';
import removeIdFields from '@lib/utils/removeIdsFromObject';

type Args = {
  id: string;
  viewMode: FormTemplateMode;
};

export default function useFormTemplateQueryHook(args: Args) {
  const { id, viewMode } = args;

  const formTemplateQuery = useFormTemplateQuery({
    fetchPolicy: 'network-only',
    skip: !id,
    variables: { id },
  });
  const formTemplateQueryData = _get(
    formTemplateQuery,
    'data.data',
  ) as FormTemplate;
  const formTemplateLoading = _get(formTemplateQuery, 'loading');
  const formTemplateError = _get(formTemplateQuery, 'error');
  const formTemplateRefetch = _get(formTemplateQuery, 'refetch');

  const formTemplateData: Values =
    formTemplateQueryData && viewMode === FormTemplateMode.Duplicate
      ? removeIdFields(
          getFormTemplateValuesFromQueryData({
            formTemplateData: formTemplateQueryData,
          }),
          ['attachmentsAttached'],
        )
      : getFormTemplateValuesFromQueryData({
          formTemplateData: formTemplateQueryData,
        });

  return {
    formTemplateData,
    formTemplateError,
    formTemplateLoading,
    formTemplateRefetch,
  };
}
