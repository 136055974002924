import {
  Asset,
  AssetFilterInputObject,
  AssetsQueryVariables,
  FilterGroupingEnum,
  useAssetIdsLazyQuery,
  useAssetsQuery,
} from 'graphql-common';
import _get from 'lodash/get';
import { GetUrlParams } from '@lib/enums/urls';
import createSearchFilters from '@lib/utils/createSearchFilters';
import { ApolloError } from '@apollo/client/errors';
import { FetchIdsResponse } from '@lib/utils/getAllTableItemsIds';
import { ListQueryHookProps } from './types';

export type UseAssetsQueryHookResult = {
  collection: Asset[];
  error: ApolloError | undefined;
  fetchIds: () => Promise<FetchIdsResponse<unknown>>;
  firstLoading: boolean;
  loading: boolean;
  refetch: () => Promise<unknown>;
  totalCount: number;
  totalPages: number;
};

export default function useAssetsQueryHook({
  paginationParams,
  orderingParams,
  searchQuery,
  withCountersOnly,
}: ListQueryHookProps & {
  withCountersOnly?: boolean;
}): UseAssetsQueryHookResult {
  const page = _get(paginationParams, GetUrlParams.Page);
  const perPage = _get(paginationParams, GetUrlParams.PerPage);
  const orderingField = _get(orderingParams, GetUrlParams.OrderingField);
  const orderingDirection = _get(
    orderingParams,
    GetUrlParams.OrderingDirection,
  );
  const assetsQueryVariables: AssetsQueryVariables = {
    page: Number(page),
    limit: Number(perPage),
  };
  const filters: AssetFilterInputObject[] = [];
  if (searchQuery) {
    filters.push(
      ...createSearchFilters(searchQuery, [
        { fieldName: 'name' },
        { fieldName: 'code' },
        { fieldName: 'manufacturer' },
        { fieldName: 'serialNumber' },
        { fieldName: 'siteName' },
        { fieldName: 'siteAreaName' },
        { fieldName: 'categoryName' },
      ]),
    );
  }
  if (withCountersOnly) {
    filters.push({
      countersCount: {
        grouping: FilterGroupingEnum.And,
        predicate: { gt: '0' },
      },
    });
  }
  if (filters.length) {
    assetsQueryVariables.filters = filters;
  }
  if (orderingField && orderingDirection) {
    assetsQueryVariables.sorts = {
      [orderingField]: orderingDirection,
    };
  }
  const assetsQuery = useAssetsQuery({
    fetchPolicy: 'cache-and-network',
    variables: assetsQueryVariables,
  });
  const [fetchIds] = useAssetIdsLazyQuery({
    fetchPolicy: 'no-cache',
    variables: {
      onlyIds: true,
      filters: assetsQueryVariables.filters,
    },
  });
  const { loading, data, previousData, refetch, error } = assetsQuery;
  const queryData = data || previousData;
  const firstLoading = loading && previousData === undefined;
  const collection = _get(queryData, 'data.collection', []) as Asset[];
  const metadata = _get(queryData, 'data.metadata');
  const totalCount = _get(metadata, 'totalCount', 0);
  const totalPages = _get(metadata, 'totalPages', 1);

  return {
    fetchIds,
    refetch,
    collection,
    error,
    firstLoading,
    loading,
    totalPages,
    totalCount,
  };
}
