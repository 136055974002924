import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import PageBody from '@lib/layouts/DashboardLayout/PageBody/PageBody';
import WhiteBox from '@lib/components/WhiteBox/WhiteBox';
import useFilterParams from '@lib/hooks/useFilterParams';
import { GetUrlParams } from '@lib/enums/urls';
import DateRangePicker from '@lib/components/DateTimePicker/DateRangePicker';
import { InputTypes } from '@lib/components/Input/enums';
import usePaginationParams from '@lib/hooks/usePaginationParams';
import styles from './AssetCountersFilter.module.scss';

type Props = {
  defaultPeriodValue?: string[];
};

export default function AssetCountersFilter({ defaultPeriodValue }: Props) {
  const { t } = useTranslation();
  const dateRangePickerRef = useRef();
  const [filterParams, setFilterParams] = useFilterParams();
  const [paginationParams, setPaginationParams] = usePaginationParams();

  const periodStart = filterParams[GetUrlParams.PeriodStart];
  const periodEnd = filterParams[GetUrlParams.PeriodEnd];
  let periodValue = defaultPeriodValue;
  if (periodStart && periodEnd) {
    periodValue = [periodStart, periodEnd];
  }

  const onTimeFilterChange = (v: string[]) => {
    if (Array.isArray(v) && v[0] && v[1]) {
      setFilterParams(
        {
          ...filterParams,
          [GetUrlParams.PeriodStart]: `${v[0]}`,
          [GetUrlParams.PeriodEnd]: `${v[1]}`,
        },
        'pushIn',
      );
    } else {
      setFilterParams(
        {
          ...filterParams,
          [GetUrlParams.PeriodStart]: undefined,
          [GetUrlParams.PeriodEnd]: undefined,
        },
        'pushIn',
      );
    }
    setPaginationParams({
      ...paginationParams,
      [GetUrlParams.Page]: '1',
    });
  };

  return (
    <PageBody className={styles.filterWrap}>
      <WhiteBox className={styles.filter} verticalPaddings="small">
        <div className={styles.dateRangePickerCol}>
          <DateRangePicker
            label={t('period')}
            onChange={onTimeFilterChange}
            ref={dateRangePickerRef}
            size="middle"
            type={InputTypes.dateRange}
            value={periodValue}
            allowClear={false}
          />
        </div>
      </WhiteBox>
    </PageBody>
  );
}
