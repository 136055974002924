import React, { forwardRef, memo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { SelectProps } from 'antd';
import _get from 'lodash/get';
import { Values } from '@lib/interfaces/form';
import { ComponentEnum, LevelEnum, Team } from 'graphql-common';
import { FloatingLabelProps } from '@lib/hocs/withFloatingLabel';
import Typography from '@lib/components/Typography/Typography';
import Tooltip from '@lib/components/Tooltip/Tooltip';
import MaterialIcon from '@lib/components/MaterialIcon/MaterialIcon';
import FormItem from '@lib/components/ReactHookForm/FormItem';
import RadioButtonGroup, {
  RadioButtonWrapViews,
} from '@lib/components/RadioButtonGroup/RadioButtonGroup';
import RadioButtonAsButton from '@lib/components/RadioButtonAsButton/RadioButtonAsButton';
import InterventionCategoriesChipsListField from '@lib/components/InterventionCategoriesChipsListField/InterventionCategoriesChipsListField';
import AssetCategoriesChipsListField from '@lib/components/AssetCategoriesChipsListField/AssetCategoriesChipsListField';
import Select from '@lib/components/Select/Select';
import { GetLoadOptions } from '@lib/components/Select/types';
import MultiSelectValueContainer from '@lib/components/Select/components/MultiSelectValueContainer';
import {
  AccessesFieldType,
  getAccessCategoriesScopeIds,
  getAccessesManagementOptions,
  getAccessLevelOptions,
  getAccessLevelShortOptions,
} from '@lib/utils/accessesManagement';
import { SingleQueryHookResult } from 'utils/fetch/types';
import useOutletContext, { ContextProps } from 'hooks/useOutletContext';
import Chip, { ChipSizes, ChipTypes } from '@lib/components/Chip/Chip';
import { APP_URLS } from 'constants/urls';
import styles from './TeamAccess.module.scss';

function getListItemContentClassName(fieldType: AccessesFieldType) {
  if (
    fieldType === AccessesFieldType.FullOptions ||
    fieldType === AccessesFieldType.ShortOptions
  ) {
    return classNames(
      styles.teamAccessListItemContent,
      styles.teamAccessListItemContentRow,
    );
  }
  return classNames(
    styles.teamAccessListItemContent,
    styles.teamAccessListItemContentCol,
  );
}

type Props = {
  name: string;
  getAssetCategoriesLoadOptions: GetLoadOptions;
  getInterventionCategoriesLoadOptions: GetLoadOptions;
  useTeamQueryHookResult: SingleQueryHookResult<Team>;
  values: Values;
};

const TeamAccessField = forwardRef(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  (props: Props & FloatingLabelProps, ref: React.ForwardedRef<unknown>) => {
    const {
      name,
      getAssetCategoriesLoadOptions,
      getInterventionCategoriesLoadOptions,
      useTeamQueryHookResult,
      values,
    } = props;
    const { t } = useTranslation();
    const { getUserAccessByCompany }: ContextProps = useOutletContext();
    const isInterventionsLocked =
      getUserAccessByCompany(ComponentEnum.InterventionsManagement) ===
      LevelEnum.LimitedRead;

    // Intervention categories ids
    const interventionCategoriesIds = getAccessCategoriesScopeIds(
      useTeamQueryHookResult.data?.accesses,
      ComponentEnum.InterventionsManagementCategoriesScope,
    );
    // Asset categories ids
    const assetCategoriesIds = getAccessCategoriesScopeIds(
      useTeamQueryHookResult.data?.accesses,
      ComponentEnum.AssetsManagementCategoriesScope,
    );

    return (
      <div>
        {getAccessesManagementOptions().map((option) => {
          return (
            <div key={option.value} className={styles.teamAccessListItem}>
              <div className={getListItemContentClassName(option.fieldType)}>
                <div className={styles.teamAccessListItemTitleWrap}>
                  <div className={styles.teamAccessListItemTitle}>
                    <Typography variant="body" strong>
                      {t(option.labelTransKey)}
                    </Typography>
                    {!!option.descriptionTransKey && (
                      <div className={styles.teamAccessListItemTitleInfo}>
                        <Tooltip body={t(option.descriptionTransKey)}>
                          <MaterialIcon
                            icon="info"
                            symbolsOutlined
                            size="md-16"
                          />
                        </Tooltip>
                      </div>
                    )}
                  </div>
                </div>
                <div className={styles.col}>
                  <div className={styles.teamAccessListItemField}>
                    {option.value === ComponentEnum.InterventionsManagement &&
                    isInterventionsLocked ? (
                      <Tooltip body={t('lock-state-title-interventions')}>
                        <Chip
                          leftIcon="lock"
                          size={ChipSizes.small}
                          type={ChipTypes.chipBlue}
                          to={APP_URLS.dashboard.interventions.index.path}
                          asBlockElement
                        />
                      </Tooltip>
                    ) : (
                      <FormItem
                        name={`${name}.${option.value}.level`}
                        element={RadioButtonGroup}
                        componentProps={{
                          options: getAccessLevelOptions(),
                          RadioButtonComponent: RadioButtonAsButton,
                          wrapView: RadioButtonWrapViews.row,
                        }}
                        hideErrorsSpace
                        formItemClassName={styles.teamAccessListItemFieldEmpty}
                      />
                    )}
                  </div>
                </div>
              </div>
              {option.value === ComponentEnum.InterventionsManagement &&
              isInterventionsLocked
                ? null
                : !!option.subOptions?.length &&
                  option.subOptions.map((subOption) => {
                    return (
                      <div
                        key={subOption.value}
                        className={getListItemContentClassName(
                          subOption.fieldType,
                        )}
                      >
                        <div className={styles.teamAccessListItemTitleWrap}>
                          <div className={styles.teamAccessListItemTitle}>
                            <Typography variant="body">
                              {t(subOption.labelTransKey)}
                            </Typography>
                            {!!subOption.descriptionTransKey && (
                              <div
                                className={styles.teamAccessListItemTitleInfo}
                              >
                                <Tooltip
                                  body={t(subOption.descriptionTransKey)}
                                >
                                  <MaterialIcon
                                    icon="info"
                                    symbolsOutlined
                                    size="md-16"
                                  />
                                </Tooltip>
                              </div>
                            )}
                          </div>
                        </div>
                        {subOption.fieldType ===
                          AccessesFieldType.FullOptions && (
                          <div className={styles.col}>
                            <div className={styles.teamAccessListItemField}>
                              <FormItem
                                name={`${name}.${subOption.value}.level`}
                                element={RadioButtonGroup}
                                componentProps={{
                                  options: getAccessLevelOptions(),
                                  RadioButtonComponent: RadioButtonAsButton,
                                  wrapView: RadioButtonWrapViews.row,
                                }}
                                hideErrorsSpace
                                formItemClassName={
                                  styles.teamAccessListItemFieldEmpty
                                }
                              />
                            </div>
                          </div>
                        )}
                        {subOption.fieldType ===
                          AccessesFieldType.ShortOptions && (
                          <div className={styles.col}>
                            <div
                              className={classNames(
                                styles.teamAccessListItemField,
                                styles.row,
                              )}
                            >
                              <FormItem
                                name={`${name}.${subOption.value}.level`}
                                element={RadioButtonGroup}
                                componentProps={{
                                  options: getAccessLevelShortOptions(),
                                  RadioButtonComponent: RadioButtonAsButton,
                                  wrapView: RadioButtonWrapViews.row,
                                }}
                                hideErrorsSpace
                                formItemClassName={
                                  styles.teamAccessListItemFieldEmpty
                                }
                              />
                            </div>
                          </div>
                        )}
                        {subOption.fieldType ===
                          AccessesFieldType.InterventionCategoriesSelect && (
                          <div className={styles.col}>
                            <div
                              className={classNames(
                                styles.teamAccessListItemField,
                                styles.teamAccessListItemFieldWithChipList,
                                styles.row,
                              )}
                            >
                              <FormItem
                                name={`${name}.${subOption.value}.items`}
                                element={Select}
                                label={t('intervention-categories')}
                                componentProps={
                                  {
                                    getLoadOptions:
                                      getInterventionCategoriesLoadOptions,
                                    isAsync: true,
                                    isMulti: true,
                                    defaultOptions: false,
                                    components: {
                                      ValueContainer: MultiSelectValueContainer,
                                    },
                                  } as SelectProps
                                }
                                hideErrorsSpace
                                isUseWatch
                                formItemClassName={
                                  styles.teamAccessListItemFieldEmpty
                                }
                              />
                              <div
                                style={
                                  _get(
                                    values,
                                    `${name}.${subOption.value}.items.length`,
                                  )
                                    ? undefined
                                    : { display: 'none' }
                                }
                              >
                                <FormItem
                                  name={`${name}.${subOption.value}.items-list`}
                                  element={InterventionCategoriesChipsListField}
                                  hideErrorsSpace
                                  isUseWatch
                                  componentProps={{
                                    fieldName: `${name}.${subOption.value}.items`,
                                    ids: interventionCategoriesIds,
                                  }}
                                  formItemClassName={
                                    styles.teamAccessListItemFieldEmpty
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        )}
                        {subOption.fieldType ===
                          AccessesFieldType.AssetCategoriesSelect && (
                          <div className={styles.col}>
                            <div
                              className={classNames(
                                styles.teamAccessListItemField,
                                styles.teamAccessListItemFieldWithChipList,
                                styles.row,
                              )}
                            >
                              <FormItem
                                name={`${name}.${subOption.value}.items`}
                                element={Select}
                                label={t('asset-categories')}
                                componentProps={
                                  {
                                    getLoadOptions:
                                      getAssetCategoriesLoadOptions,
                                    isAsync: true,
                                    isMulti: true,
                                    defaultOptions: false,
                                    components: {
                                      ValueContainer: MultiSelectValueContainer,
                                    },
                                  } as SelectProps
                                }
                                hideErrorsSpace
                                isUseWatch
                                formItemClassName={
                                  styles.teamAccessListItemFieldEmpty
                                }
                              />
                              <div
                                style={
                                  _get(
                                    values,
                                    `${name}.${subOption.value}.items.length`,
                                  )
                                    ? undefined
                                    : { display: 'none' }
                                }
                              >
                                <FormItem
                                  name={`${name}.${subOption.value}.items-list`}
                                  element={AssetCategoriesChipsListField}
                                  hideErrorsSpace
                                  isUseWatch
                                  componentProps={{
                                    fieldName: `${name}.${subOption.value}.items`,
                                    ids: assetCategoriesIds,
                                  }}
                                  formItemClassName={
                                    styles.teamAccessListItemFieldEmpty
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
            </div>
          );
        })}
      </div>
    );
  },
);

export default memo(TeamAccessField);
