import { Values } from '@lib/interfaces/form';
import _get from 'lodash/get';
import AssetFieldNames from '@lib/enums/fieldNames/assetFieldNames';
import { AssetCreateInputObject, AssetUpdateInputObject } from 'graphql-common';
import QrCodePrefix from '@lib/enums/qrCodePrefix';

export default function getPreparedAssetDataFromValues(
  values: Values,
  isCreate?: boolean,
) {
  let iconAttached: object | undefined;
  if (_get(values, AssetFieldNames.IconAttached) === null)
    iconAttached = { _destroy: true };
  if (_get(values, `${AssetFieldNames.IconAttached}.fileTag`)) {
    iconAttached = {
      fileTag: _get(values, `${AssetFieldNames.IconAttached}.fileTag`),
    };
  }
  if (_get(values, `${AssetFieldNames.IconAttached}.file.id`)) {
    iconAttached = {
      file: {
        id: _get(values, `${AssetFieldNames.LocationPhotoAttached}.file.id`),
        storage: _get(
          values,
          `${AssetFieldNames.LocationPhotoAttached}.file.storage`,
        ),
        metadata: _get(
          values,
          `${AssetFieldNames.LocationPhotoAttached}.file.metadata`,
        ),
      },
    };
  }
  // qrPrefix is existed asset prefix (edit mode) or prefix from selected category (add mode)
  const qrPrefix =
    _get(values, `${AssetFieldNames.QrCodePrefix}.value`) ||
    _get(values, `${AssetFieldNames.Category}.data.prefix`);
  return {
    categoryId: _get(values, `${AssetFieldNames.Category}.value`),
    code: isCreate ? _get(values, AssetFieldNames.Code) : undefined,
    description: _get(values, AssetFieldNames.Description),
    iconAttached,
    manufacturer: _get(values, AssetFieldNames.Manufacturer),
    model: _get(values, AssetFieldNames.Model) || '',
    name: _get(values, AssetFieldNames.Name),
    poorWifi: _get(values, AssetFieldNames.PoorWifi),
    qrPrefix: qrPrefix === QrCodePrefix.Asset ? null : qrPrefix,
    serialNumber: _get(values, AssetFieldNames.SerialNumber) || '',
    serviceDate: _get(values, AssetFieldNames.ServiceDate),
    siteAreaId: _get(values, `${AssetFieldNames.SiteArea}.value`, null),
    siteId: isCreate
      ? _get(values, `${AssetFieldNames.Site}.value`)
      : undefined,
  } as AssetCreateInputObject | AssetUpdateInputObject;
}
