import React from 'react';
import { useTranslation } from 'react-i18next';
import { Nomenclature } from 'graphql-common';
import Button, { ButtonTypes } from '@lib/components/Button/Button';
import Table from '@lib/components/Table/Table';
import CircleLoader from '@lib/components/CircleLoader/CircleLoader';
import Search from '@lib/components/Search/Search';
import PageBody from '@lib/layouts/DashboardLayout/PageBody/PageBody';
import ListEmptyState from '@lib/components/ListEmptyState/ListEmptyState';
import useSearchParam from '@lib/hooks/useSearchParam';
import Trans from '@lib/components/Trans/Trans';
import ErrorPage from '@lib/components/ErrorPage/ErrorPage';
import getStatusFromError from '@lib/utils/getStatusFromError';
import { UseBulkSelectResult } from '@lib/hooks/useTableItemsBulkSelect';
import { UseActionsResult } from 'routes/Assets/Asset/utils/useActions';
import { UseModalManagementResult } from 'routes/Assets/Asset/utils/useModalManagement';
import WhiteBox from '@lib/components/WhiteBox/WhiteBox';
import { materialSymbolsOutlined } from '@lib/enums/common';
import AssetNomenclatureModalForm from 'components/AssetNomenclatureModalForm/AssetNomenclatureModalForm';
import { UseSearchListQueriesResult } from 'routes/Assets/Asset/utils/useSearchListQueries';
import getTableColumns from 'components/AssetNomenclature/utils/getTableColumns';
import { FormRefType } from '@lib/components/ReactHookForm/types';
import getTableActionsMenu from 'components/AssetNomenclature/utils/getTableActionsMenu';
import getTableActionsForSelected from 'components/AssetNomenclature/utils/getTableActionsForSelected';
import ConfirmationModal from '@lib/components/ConfirmationModal/ConfirmationModal';
import Modal from '@lib/components/Modal/Modal';
import GetQrCodeForm from 'components/GetQrCodeForm/GetQrCodeForm';
import DashboardTitleHeader from '@lib/layouts/DashboardLayout/DashboardTitleHeader/DashboardTitleHeader';
import { SubmitAction } from '@lib/enums/form';
import { UseNomenclatureActionsResult } from 'routes/Assets/Asset/utils/useNomenclatureActions';
import ViewOnlyChip from '@lib/components/ViewOnlyChip/ViewOnlyChip';
import { IconName } from '@lib/components/Modal/enums';

interface Props {
  menuBar: JSX.Element;
  loadingState?: boolean;
  useBulkSelectResult?: UseBulkSelectResult<Nomenclature>;
  useActionsResult: UseActionsResult;
  useNomenclatureActionsResult: UseNomenclatureActionsResult;
  useModalManagementResult: UseModalManagementResult;
  useSearchListQueriesResult: UseSearchListQueriesResult;
  submitNewItemRef: React.MutableRefObject<SubmitAction | undefined>;
  formRef: FormRefType;
  isViewOnly: boolean;
}

export default function AssetNomenclature(props: Props) {
  const {
    loadingState,
    menuBar,
    submitNewItemRef,
    useActionsResult,
    useBulkSelectResult,
    useModalManagementResult,
    useSearchListQueriesResult,
    useNomenclatureActionsResult,
    formRef,
    isViewOnly,
  } = props;
  const { t } = useTranslation();
  const [searchQuery] = useSearchParam();

  const { assetQueryHook } = useActionsResult;
  const {
    assetNomenclatureMutationError,
    assetNomenclatureMutationLoading,
    nomenclatureQueryHook,
    nomenclaturesQueryHook,
    onCloseModal,
    onConfirmedNomenclatureDestroy,
    onNomenclatureFormSubmit,
    openAsset,
    openNomenclatureBulkDestroyModal,
    openNomenclatureDestroyModal,
    openNomenclatureEditModal,
    onQrCodeFormSubmit,
    qrCodeCreateMutationResult,
  } = useNomenclatureActionsResult;

  const name = assetQueryHook.data?.name || '';

  const tableColumns = getTableColumns({
    getQrCode: useNomenclatureActionsResult.openGetQrCodeFormModal,
    t,
  });
  const tableActionsMenu = getTableActionsMenu({
    onViewHandler: openAsset,
    onEditHandler: openNomenclatureEditModal,
    onDelete: openNomenclatureDestroyModal,
    t,
    isViewOnly,
  });
  const tableActionsForSelected = getTableActionsForSelected({
    getQrCodesForSelected: useNomenclatureActionsResult.openGetQrCodeFormModal,
    onBulkDelete: openNomenclatureBulkDestroyModal,
    selectedItemsCount: useBulkSelectResult?.getSelectedItemsCount(
      nomenclaturesQueryHook.totalCount,
    ),
    t,
    isViewOnly,
  });

  const emptyState = (
    <ListEmptyState
      title={t('no-asset-nomenclature-yet')}
      text={<Trans i18nKey="no-asset-nomenclature-text" />}
      actions={
        isViewOnly
          ? []
          : [
              <Button
                key="add"
                buttonText={t('add-nomenclature')}
                buttonType={ButtonTypes.primaryFilled}
                leftIcon="add"
                onClick={useModalManagementResult.openNomenclatureFormModal}
              />,
            ]
      }
    />
  );

  if (nomenclaturesQueryHook.error) {
    return (
      <ErrorPage
        status={getStatusFromError(nomenclaturesQueryHook.error)}
        description={nomenclaturesQueryHook.error.message}
      />
    );
  }

  return (
    <>
      <PageBody withoutHeader>
        {menuBar}
        {nomenclaturesQueryHook.firstLoading ? (
          <CircleLoader />
        ) : (
          <WhiteBox>
            <DashboardTitleHeader
              title={name}
              actions={
                isViewOnly ? (
                  <ViewOnlyChip key="view" />
                ) : (
                  <Button
                    key="add"
                    buttonText={t('add-nomenclature')}
                    buttonType={ButtonTypes.primaryFilled}
                    onClick={useModalManagementResult.openNomenclatureFormModal}
                    leftIcon="add"
                    leftIconClassName={materialSymbolsOutlined}
                  />
                )
              }
            />
            {(!!nomenclaturesQueryHook.collection?.length || searchQuery) && (
              <Search
                placeholder={t('asset-nomenclature-search-placeholder')}
              />
            )}
            <Table
              actionsForSelected={tableActionsForSelected}
              actionsMenu={tableActionsMenu}
              columns={tableColumns}
              data={nomenclaturesQueryHook.collection}
              emptyState={emptyState}
              id="nomenclatures"
              loading={loadingState || nomenclaturesQueryHook.loading}
              totalEntries={nomenclaturesQueryHook.totalCount}
              useBulkSelectResult={useBulkSelectResult}
            />
          </WhiteBox>
        )}
      </PageBody>
      <Modal
        isOpen={useModalManagementResult.isQrCodeFormModalOpened}
        onClose={onCloseModal}
        title={t('get-asset-qr-code')}
      >
        <GetQrCodeForm
          getRecipientsLoadOptions={
            useSearchListQueriesResult.getUsersLoadOptions
          }
          graphQLErrors={qrCodeCreateMutationResult?.error?.graphQLErrors}
          loading={false}
          onCancel={onCloseModal}
          onSubmit={onQrCodeFormSubmit}
        />
      </Modal>
      <ConfirmationModal
        confirmButtonLabel={t('yes-delete')}
        description={<Trans i18nKey="asset-nomenclature-delete-text" />}
        icon={IconName.Delete}
        isOpen={useModalManagementResult.isNomenclatureDestroyModalOpened}
        onClose={onCloseModal}
        onConfirm={onConfirmedNomenclatureDestroy}
        title={t('are-you-sure')}
      />
      <AssetNomenclatureModalForm
        formRef={formRef}
        submitNewItemRef={submitNewItemRef}
        assetNomenclature={nomenclatureQueryHook.data}
        getAssetsLoadOptions={useSearchListQueriesResult.getAssetsLoadOptions}
        graphQLErrors={assetNomenclatureMutationError?.graphQLErrors}
        isOpen={useModalManagementResult.isNomenclatureFormModalOpened}
        loading={
          nomenclatureQueryHook.loading || assetNomenclatureMutationLoading
        }
        onCancel={onCloseModal}
        onClose={onCloseModal}
        onSubmit={onNomenclatureFormSubmit}
        isEditForm={!!useBulkSelectResult?.selectedSingleTableItem?.id}
      />
    </>
  );
}
