import React from 'react';
import { useTranslation } from 'react-i18next';
import EmptyState from '@lib/components/EmptyState/EmptyState';
import Button, { ButtonTypes } from '@lib/components/Button/Button';
import useSearchParam from '@lib/hooks/useSearchParam';
import { BaseItem, TableProps } from '@lib/components/Table/types';
import { IconName } from '@lib/components/Modal/enums';

function TableEmptyState<T extends BaseItem>(props: TableProps<T>) {
  const { emptyState, loading } = props;
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useSearchParam();

  let render: React.ReactNode = (
    <EmptyState
      title={t('no-data-yet')}
      text={t('no-data-text')}
      icon={IconName.Cancel}
    />
  );

  if (searchQuery) {
    render = (
      <EmptyState
        title={t('no-results-found')}
        text={t('no-results-found-text')}
        icon={IconName.SearchOff}
        actions={[
          <Button
            key="back"
            buttonText={t('go-back')}
            buttonType={ButtonTypes.primaryFilled}
            leftIcon="arrow_back"
            onClick={() => setSearchQuery('')}
          />,
        ]}
      />
    );
  }

  if (emptyState) {
    render = emptyState;
  }

  return <div style={loading ? { opacity: 0 } : undefined}>{render}</div>;
}

export default TableEmptyState;
