import React from 'react';
import classNames from 'classnames';
import IconButton, {
  IconButtonTypes,
} from '@lib/components/IconButton/IconButton';
import styles from './AssetDocumentationFieldArrayItemWrapper.module.scss';

interface Props {
  children: React.ReactNode;
  onRemove: () => void;
}

function AssetDocumentationFieldArrayItemWrapper(props: Props) {
  const { children, onRemove } = props;
  return (
    <div className={classNames(styles.optionWrap)}>
      {children}
      <IconButton
        icon="close"
        type={IconButtonTypes.PrimaryOutlined}
        onClick={onRemove}
        className={styles.optionDeleteBtn}
      />
    </div>
  );
}

export default AssetDocumentationFieldArrayItemWrapper;
