import React from 'react';
import classNames from 'classnames';
import { components as DefaultSelectComponents, MenuProps } from 'react-select';
import MaterialIcon from '@lib/components/MaterialIcon/MaterialIcon';
import styles from '../Select.module.scss';

function Menu(props: MenuProps) {
  const { children, selectProps, options, isLoading } = props;
  // TODO: fix it (https://react-select.com/typescript#custom-select-props)
  const {
    // @ts-ignore
    fullWidthMenu = true,
    // @ts-ignore
    createNewOptionProps,
    // @ts-ignore
    menuListHasSpaces,
  } = selectProps;
  const { title, onCLick } = createNewOptionProps || {};

  const loader = (
    // @ts-ignore
    <DefaultSelectComponents.LoadingIndicator
      {...props}
      innerProps={{}}
      isRtl={false}
      size={4}
    />
  );
  return (
    <DefaultSelectComponents.Menu
      {...props}
      className={classNames(styles.menu, {
        [styles.menuAutoWidth]: !fullWidthMenu,
        [styles.menuEmptyWithCreateNewOption]:
          options.length === 0 && !!createNewOptionProps,
        [styles.menuEmpty]: options.length === 0 && !createNewOptionProps,
        [styles.menuListWithSpaces]: menuListHasSpaces,
      })}
    >
      {!!createNewOptionProps && (
        <button
          type="button"
          className={classNames(styles.option, styles.createNewOptionBtn)}
          onClick={onCLick}
        >
          {title}
          <MaterialIcon icon="add" className={styles.createNewOptionBtnIcon} />
        </button>
      )}
      {options.length ? (
        <>
          <div className={styles.menuListWrap}>{children}</div>
          {isLoading && !!options.length && (
            <div className={styles.menuLoader}>{loader}</div>
          )}
        </>
      ) : (
        <div className={styles.menuListWrap}>
          {isLoading ? (
            <div className={styles.menuLoaderCentered}>{loader}</div>
          ) : (
            children
          )}
        </div>
      )}
    </DefaultSelectComponents.Menu>
  );
}

export default Menu;
