import { TFunction } from 'i18next';
import { TaskScopeNameEnum } from 'graphql-common';
import _get from 'lodash/get';
import { CountMarkTypes } from '@lib/components/CountMark/CountMark';
import { TabBarItemTypes } from '@lib/components/TabBar/constants';
import { TaskCounter } from '@lib/interfaces/task';

type TimelineTableTabsArgs = {
  t: TFunction<'translation', undefined>;
  taskCounterData?: TaskCounter;
};

const getTimelineTableTabs = ({
  t,
  taskCounterData,
}: TimelineTableTabsArgs) => [
  {
    label: t('all'),
    value: TaskScopeNameEnum.TimelineAll,
    counter: _get(taskCounterData, 'timelineAll'),
  },
  {
    label: t('failure'),
    value: TaskScopeNameEnum.TimelineFailure,
    counter: _get(taskCounterData, 'timelineFailure'),
    counterType: CountMarkTypes.red,
    type: TabBarItemTypes.red,
  },
  {
    label: t('warning'),
    value: TaskScopeNameEnum.TimelineWarning,
    counter: _get(taskCounterData, 'timelineWarning'),
    counterType: CountMarkTypes.orange,
    type: TabBarItemTypes.orange,
  },
  {
    label: t('success'),
    value: TaskScopeNameEnum.TimelineSuccess,
    counter: _get(taskCounterData, 'timelineSuccess'),
    counterType: CountMarkTypes.green,
    type: TabBarItemTypes.green,
  },
];

export default getTimelineTableTabs;
