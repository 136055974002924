import React from 'react';
import * as Yup from 'yup';
import { FieldItem } from '@lib/interfaces/form';
import FieldNames from '@lib/enums/fieldNames/userFieldNames';
import { GetLoadOptions } from '@lib/components/Select/types';
import UserAccessField from 'routes/Users/UserAccessField/UserAccessField';
import UserAccessFieldReset from 'routes/Users/UserAccessField/UserAccessFieldReset';

type Args = {
  getAssetCategoriesLoadOptions: GetLoadOptions;
  getInterventionCategoriesLoadOptions: GetLoadOptions;
  personalInterventionCategoriesIds: string[];
  teamInterventionCategoriesIds: string[];
  personalAssetCategoriesIds: string[];
  teamAssetCategoriesIds: string[];
};

export default function getUserAccessesFormFields(args: Args): FieldItem[] {
  const {
    getAssetCategoriesLoadOptions,
    getInterventionCategoriesLoadOptions,
    personalInterventionCategoriesIds,
    teamInterventionCategoriesIds,
    personalAssetCategoriesIds,
    teamAssetCategoriesIds,
  } = args;
  return [
    {
      name: FieldNames.Accesses,
      element: UserAccessField,
      componentProps: {
        getAssetCategoriesLoadOptions,
        getInterventionCategoriesLoadOptions,
        personalInterventionCategoriesIds,
        teamInterventionCategoriesIds,
        personalAssetCategoriesIds,
        teamAssetCategoriesIds,
      },
      isUseWatch: true,
      validation: Yup.object(),
    },
    {
      name: 'team-accesses-message',
      customRender: () => <UserAccessFieldReset name={FieldNames.Accesses} />,
    },
  ];
}
