import React from 'react';
import { useTranslation } from 'react-i18next';
import EmptyState from '@lib/components/EmptyState/EmptyState';
import Button, { ButtonTypes } from '@lib/components/Button/Button';
import useSearchParam from '@lib/hooks/useSearchParam';
import { IconName } from '@lib/components/Modal/enums';

type Props = {
  title: React.ReactNode;
  text: React.ReactNode;
  actions: React.ReactNode[];
};

export default function ListEmptyState({ title, text, actions }: Props) {
  const [searchQuery, setSearchQuery] = useSearchParam();
  const { t } = useTranslation();
  return searchQuery ? (
    <EmptyState
      title={t('no-results-found')}
      text={t('no-results-found-text')}
      icon={IconName.SearchOff}
      actions={[
        <Button
          key="back"
          buttonText={t('go-back')}
          buttonType={ButtonTypes.primaryFilled}
          leftIcon="arrow_back"
          onClick={() => setSearchQuery('')}
        />,
      ]}
    />
  ) : (
    <EmptyState
      title={title}
      text={text}
      icon={IconName.Cancel}
      actions={actions}
    />
  );
}
