import { LazyQueryExecFunction } from '@apollo/client';

import {
  Asset,
  AssetFilterInputObject,
  AssetsSearchListQuery,
  AssetsSearchListQueryVariables,
  FilterGroupingEnum,
  SortDirectionEnum,
} from 'graphql-common';
import { DEFAULT_SEARCH_LIST_VARIABLES } from '@lib/enums/urls';
import getLoadOptionsResult from '@lib/utils/getLoadOptionsResult';
import createSearchFilters from '@lib/utils/createSearchFilters';
import { LoadOptionsParams } from '@lib/components/Select/types';

type FetchLazyQuery = LazyQueryExecFunction<AssetsSearchListQuery, any>;

const fetchSearchList = async (
  searchQuery: string,
  fetchLazyQuery: FetchLazyQuery,
  page: number,
  selectedSiteId?: string,
  selectedSiteAreaId?: string,
) => {
  const options: { variables: AssetsSearchListQueryVariables } = {
    variables: {
      ...DEFAULT_SEARCH_LIST_VARIABLES,
      page,
      sorts: { name: SortDirectionEnum.Asc },
    },
  };
  const filters: AssetFilterInputObject[] = [];
  if (selectedSiteId) {
    filters.push({
      siteId: {
        grouping: FilterGroupingEnum.And,
        predicate: { eq: selectedSiteId },
      },
    });
  }
  if (selectedSiteAreaId) {
    filters.push(
      ...[
        {
          siteAreaId: {
            grouping: FilterGroupingEnum.And,
            predicate: { eq: selectedSiteAreaId },
          },
        },
      ],
    );
  }
  if (searchQuery) {
    filters.push(
      ...createSearchFilters(searchQuery, [
        { fieldName: 'name' },
        { fieldName: 'code' },
        { fieldName: 'manufacturer' },
        { fieldName: 'serialNumber' },
        { fieldName: 'siteName' },
        { fieldName: 'siteAreaName' },
        { fieldName: 'categoryName' },
      ]),
    );
  }
  if (filters.length) {
    options.variables.filters = filters;
  }
  const { data } = await fetchLazyQuery(options);
  return getLoadOptionsResult(
    data,
    (dataItem: Asset) => ({
      value: dataItem.id,
      label: `${dataItem.fullCode} ${dataItem.name}`,
      data: dataItem,
    }),
    searchQuery,
  );
};

export const getAssetsLoadOptions =
  (
    fetchLazyQuery: FetchLazyQuery,
    selectedSiteId?: string,
    selectedSiteAreaId?: string,
  ) =>
  (searchQuery: string, loadedOptions: unknown, params: LoadOptionsParams) =>
    fetchSearchList(
      searchQuery,
      fetchLazyQuery,
      params?.page || 1,
      selectedSiteId,
      selectedSiteAreaId,
    );
