import React from 'react';
import { TFunction } from 'i18next';
import TextCell from '@lib/components/Table/commonComponents/TextCell/TextCell';
import { CellProps } from '@lib/components/Table/types';
import { Asset } from 'graphql-common';
import ChipCell from '@lib/components/Table/commonComponents/ChipCell/ChipCell';
import _get from 'lodash/get';
import { ChipTypes } from '@lib/components/Chip/Chip';
import AssetPreviewCell from '@lib/components/Table/commonComponents/AssetPreviewCell/AssetPreviewCell';
import { APP_URLS } from 'constants/urls';

type ColumnsArgs = {
  t: TFunction<'translation', undefined>;
};

const columnWidthSettings = {
  minWidth: 130,
  maxWidth: 250,
};

export default function getColumns({ t }: ColumnsArgs) {
  return [
    {
      id: 'code',
      Header: t('code'),
      accessor: 'code',
      Cell: (props: CellProps<Asset>) => <TextCell {...props} />,
      minWidth: 110,
      maxWidth: 110,
      width: 110,
      sortable: true,
    },
    {
      id: 'name',
      Header: t('name'),
      accessor: 'name',
      Cell: ({ item }: CellProps<Asset>) => (
        <AssetPreviewCell
          icon={
            item.iconAttached?.urls?.small ||
            item.iconAttached?.urls?.original ||
            item.iconAttached?.fileTag
          }
          name={item.name}
          url={APP_URLS.dashboard.assets.view.getDynamicPath({
            pathParts: {
              id: `${item.id}`,
            },
          })}
        />
      ),
      minWidth: 210,
      width: 310,
      sortable: true,
    },
    {
      id: 'siteName',
      Header: t('site'),
      accessor: 'site',
      Cell: (props: CellProps<Asset>) => {
        const label = _get(props, 'item.site.name', '');
        if (!label) return '-';
        return <ChipCell label={label} type={ChipTypes.chipGray} />;
      },
      sortable: true,
      ...columnWidthSettings,
    },
    {
      id: 'siteAreaName',
      Header: t('area'),
      accessor: 'siteArea',
      Cell: (props: CellProps<Asset>) => {
        const label = _get(props, 'item.siteArea.name', '');
        if (!label) return '-';
        return <ChipCell label={label} type={ChipTypes.chipGray} />;
      },
      sortable: true,
      ...columnWidthSettings,
    },
    {
      id: 'categoryName',
      Header: t('category'),
      accessor: 'category',
      Cell: (props: CellProps<Asset>) => {
        const label = _get(props, 'item.category.name', '');
        const color = _get(props, 'item.category.color', '') as ChipTypes;
        if (!label) return '-';
        return <ChipCell label={label} type={color} leftIcon="circle" />;
      },
      sortable: true,
      ...columnWidthSettings,
    },
    {
      id: 'fullCode',
      Header: t('qr-code'),
      accessor: 'fullCode',
      Cell: (props: CellProps<Asset>) => <TextCell {...props} />,
      ...columnWidthSettings,
    },
    {
      id: 'manufacturer',
      Header: t('manufacturer'),
      accessor: 'manufacturer',
      Cell: (props: CellProps<Asset>) => <TextCell {...props} />,
      sortable: true,
      ...columnWidthSettings,
    },
    {
      id: 'serialNumber',
      Header: t('serial-number'),
      accessor: 'serialNumber',
      Cell: (props: CellProps<Asset>) => <TextCell {...props} />,
      sortable: true,
      ...columnWidthSettings,
    },
  ];
}
