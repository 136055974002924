import { RouteBuilder } from '@lib/utils/routeBuilder';
import { DEFAULT_LIST_PARAMS } from '@lib/enums/urls';

export const PATH_AUTH = 'auth';
export const PATH_SETUP = 'setup';
export const PATH_DASHBOARD = 'dashboard';

export const SYSTEM_APP_URLS = {
  root: new RouteBuilder({
    path: '/',
  }),
  auth: {
    index: new RouteBuilder({
      path: `/${PATH_AUTH}`,
    }),
    login: new RouteBuilder({
      path: `/${PATH_AUTH}/login`,
    }),
    forgotPassword: new RouteBuilder({
      path: `/${PATH_AUTH}/forgot-password`,
    }),
    resetPassword: new RouteBuilder({
      path: `/${PATH_AUTH}/reset-password`,
    }),
    logout: new RouteBuilder({
      path: `/${PATH_AUTH}/logout`,
    }),
  },
  setup: {
    index: new RouteBuilder({
      path: `/${PATH_SETUP}`,
    }),
    finishRegistration: new RouteBuilder({
      path: `/${PATH_SETUP}/finish-registration`,
    }),
    confirmEmail: new RouteBuilder({
      path: `/${PATH_SETUP}/confirm-email`,
    }),
    updatePassword: new RouteBuilder({
      path: `/${PATH_SETUP}/update-password`,
    }),
  },
  dashboard: {
    index: new RouteBuilder({
      path: `/${PATH_DASHBOARD}`,
    }),
    companies: {
      index: new RouteBuilder({
        path: `/${PATH_DASHBOARD}/accounts`,
        defaultParams: { ...DEFAULT_LIST_PARAMS },
      }),
      add: new RouteBuilder({
        path: `/${PATH_DASHBOARD}/accounts/add`,
      }),
      edit: new RouteBuilder({
        path: `/${PATH_DASHBOARD}/accounts/:id/edit`,
      }),
      view: new RouteBuilder({
        path: `/${PATH_DASHBOARD}/accounts/:id/view`,
      }),
    },
    industries: {
      index: new RouteBuilder({
        path: `/${PATH_DASHBOARD}/industries`,
        defaultParams: { ...DEFAULT_LIST_PARAMS },
      }),
    },
    systemAdmins: {
      index: new RouteBuilder({
        path: `/${PATH_DASHBOARD}/system-admins`,
        defaultParams: { ...DEFAULT_LIST_PARAMS },
      }),
    },
    profile: new RouteBuilder({
      path: `/${PATH_DASHBOARD}/profile`,
    }),
  },
  notFound: new RouteBuilder({
    path: '/not-found',
  }),
};
