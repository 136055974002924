import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Form, { FormRef } from '@lib/components/ReactHookForm/FormContainer';
import DashboardContentMaxWidth from '@lib/layouts/DashboardLayout/DashboardContentMaxWidth/DashboardContentMaxWidth';
import PageHeader from '@lib/layouts/DashboardLayout/PageHeader/PageHeader';
import PageBody from '@lib/layouts/DashboardLayout/PageBody/PageBody';
import WhiteBox from '@lib/components/WhiteBox/WhiteBox';
import Button, { ButtonTypes } from '@lib/components/Button/Button';
import ActionsRow from '@lib/components/ActionsRow/ActionsRow';
import formStyles from '@lib/assets/styles/Form.module.scss';
import FieldNames from '@lib/enums/fieldNames/interventionActionFieldNames';
import CircleLoader from '@lib/components/CircleLoader/CircleLoader';
import { APP_URLS } from 'constants/urls';
import getInterventionActionFormFields from '../utils/getInterventionActionFormFields';
import getInterventionActionDefaultValuesFromQueryData, {
  DEFAULT_VALUES,
} from '../utils/getInterventionActionDefaultValuesFromQueryData';
import { UseInterventionActionActionsResult } from '../utils/useInterventionActionActions';
import useSearchListQueries from '../utils/useSearchListQueries';
import { InterventionViewMode } from '../enums';

type Props = {
  useInterventionActionActionsResult: UseInterventionActionActionsResult;
  viewMode: InterventionViewMode;
};

export default function InterventionActionFormPage({
  useInterventionActionActionsResult,
  viewMode,
}: Props) {
  const { t } = useTranslation();
  const formRef = useRef<FormRef>();
  const navigate = useNavigate();
  const useSearchListQueriesResult = useSearchListQueries();
  const {
    actionId,
    interventionActionQueryHookResult,
    interventionQueryHookResult,
    loading,
    mutationError,
    onSubmit,
    usersQueryHookResult,
    fetchUsersSearchListLazyQuery,
  } = useInterventionActionActionsResult;
  const { allIds: assigneeIds } = usersQueryHookResult;

  const isCreatePage = viewMode === InterventionViewMode.AddAction;

  const interventionId = interventionQueryHookResult.data?.data?.id || '';
  const siteId = interventionQueryHookResult.data?.data?.siteId || '';
  const categoryId = interventionQueryHookResult.data?.data?.categoryId || '';

  const defaultValues = isCreatePage
    ? DEFAULT_VALUES
    : getInterventionActionDefaultValuesFromQueryData(
        interventionActionQueryHookResult.data,
        assigneeIds,
      );
  const formDefaultValues = {
    ...defaultValues,
    [FieldNames.Category]: categoryId
      ? { value: categoryId, label: categoryId }
      : null,
    [FieldNames.Site]: siteId ? { value: siteId, label: siteId } : null,
  };

  const fields = getInterventionActionFormFields({
    actionId,
    t,
    getAssigneeLoadOptions: useSearchListQueriesResult.getAssigneeLoadOptions,
    fetchUsersSearchListLazyQuery,
  });

  const actionsComponent = () => (
    <ActionsRow
      className={formStyles.formActions}
      actions={[
        <Button
          onClick={() =>
            navigate(
              APP_URLS.dashboard.interventions.viewActions.getDynamicPath({
                pathParts: { id: interventionId },
              }),
            )
          }
          buttonType={ButtonTypes.secondaryOutlined}
          buttonText={t('cancel')}
          leftIcon="close"
          fullWidth
        />,
        <Button
          buttonType={ButtonTypes.primaryFilled}
          buttonText={t(isCreatePage ? 'create' : 'save-changes')}
          leftIcon="check"
          type="submit"
          fullWidth
          disabled={loading}
        />,
      ]}
    />
  );

  return (
    <DashboardContentMaxWidth>
      <PageHeader
        title={t(isCreatePage ? 'create-an-action' : 'edit-an-action')}
      />
      <PageBody>
        <WhiteBox>
          {interventionQueryHookResult?.loading ? (
            <CircleLoader />
          ) : (
            <Form
              actionsComponent={actionsComponent}
              actionsComponentProps={{ isCreatePage }}
              defaultValues={formDefaultValues}
              fields={fields}
              formId="intervention-action"
              formRef={formRef}
              graphQLErrors={mutationError?.graphQLErrors}
              loading={loading}
              onSubmit={onSubmit}
            />
          )}
        </WhiteBox>
      </PageBody>
    </DashboardContentMaxWidth>
  );
}
