import {
  FormTemplate,
  FormTemplateFilterInputObject,
  FormTemplateScopeNameEnum,
  FormTemplatesQueryVariables,
  useFormTemplateIdsLazyQuery,
  useFormTemplatesQuery,
} from 'graphql-common';
import _get from 'lodash/get';
import { GetUrlParams } from '@lib/enums/urls';
import createSearchFilters from '@lib/utils/createSearchFilters';
import { ListQueryHookProps } from './types';

export default function useFormTemplatesQueryHook({
  paginationParams,
  searchQuery,
  tabParam = FormTemplateScopeNameEnum.Active,
}: ListQueryHookProps) {
  const page = _get(paginationParams, GetUrlParams.Page);
  const perPage = _get(paginationParams, GetUrlParams.PerPage);
  const orderingField = _get(paginationParams, GetUrlParams.OrderingField);
  const orderingDirection = _get(
    paginationParams,
    GetUrlParams.OrderingDirection,
  );
  const formTemplatesQueryVariables: FormTemplatesQueryVariables = {
    limit: Number(perPage),
    page: Number(page),
    scopeName: tabParam as FormTemplateScopeNameEnum,
  };
  const filters: FormTemplateFilterInputObject[] = [];
  if (searchQuery) {
    filters.push(
      ...createSearchFilters(searchQuery, [
        { fieldName: 'name' },
        { fieldName: 'categoryName' },
      ]),
    );
  }
  if (filters.length) {
    formTemplatesQueryVariables.filters = filters;
  }
  if (orderingField && orderingDirection) {
    formTemplatesQueryVariables.sorts = {
      [orderingField]: orderingDirection,
    };
  }
  const formTemplatesQuery = useFormTemplatesQuery({
    fetchPolicy: 'cache-and-network',
    variables: formTemplatesQueryVariables,
  });
  const [fetchFormTemplateIds] = useFormTemplateIdsLazyQuery({
    fetchPolicy: 'no-cache',
    variables: {
      filters: formTemplatesQueryVariables.filters,
      onlyIds: true,
      scopeName: tabParam as FormTemplateScopeNameEnum,
    },
  });
  const {
    loading: formTemplatesLoading,
    data,
    previousData,
    refetch: refetchFormTemplates,
    error: formTemplatesError,
  } = formTemplatesQuery;
  const queryData = data || previousData;
  const formTemplatesFirstLoading =
    formTemplatesLoading && previousData === undefined;
  const formTemplates = _get(
    queryData,
    'data.collection',
    [],
  ) as FormTemplate[];
  const formTemplateCounter = {
    active: _get(queryData, 'activeCounter.metadata.totalCount', 0),
    archived: _get(queryData, 'archivedCounter.metadata.totalCount', 0),
  };
  const metadata = _get(queryData, 'data.metadata');
  const totalFormTemplatesCount = _get(metadata, 'totalCount', 0);
  const totalPages = _get(metadata, 'totalPages', 1);
  return {
    fetchFormTemplateIds,
    formTemplateCounter,
    formTemplates,
    formTemplatesError,
    formTemplatesFirstLoading,
    formTemplatesLoading,
    refetchFormTemplates,
    totalFormTemplatesCount,
    totalPages,
  };
}
