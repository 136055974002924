import React from 'react';
import { useTranslation } from 'react-i18next';
import { User } from 'graphql-common';
import { Values } from '@lib/interfaces/form';
import { ApolloError } from '@apollo/client/errors';
import DashboardContentMaxWidth from '@lib/layouts/DashboardLayout/DashboardContentMaxWidth/DashboardContentMaxWidth';
import PageBody from '@lib/layouts/DashboardLayout/PageBody/PageBody';
import UserModalForm from 'components/UserModalForm/UserModalForm';
import Form from '@lib/components/ReactHookForm/FormContainer';
import { Steps } from '@lib/enums/form';
import WhiteBox from '@lib/components/WhiteBox/WhiteBox';
import { pageStepsId } from '@lib/enums/selectors';
import { LoadOptions } from '@lib/components/Select/types';
import ErrorPage from '@lib/components/ErrorPage/ErrorPage';
import getStatusFromError from '@lib/utils/getStatusFromError';
import { FormRefType } from '@lib/components/ReactHookForm/types';
import { getDefaultValuesFromQueryData, getFormFields } from './utils';
import SiteAddFormActions from './SiteAddFormActions';

interface Props {
  closeUserFormModal: () => void;
  countriesCodes: string[];
  createMutationError?: ApolloError;
  createSiteLoading: boolean;
  formRef: FormRefType;
  isUserFormModalOpened: boolean;
  onCreate: (values: Values) => void;
  onCreateUserCallback: (v?: User | null) => void;
  openUserFormModal: () => void;
  siteError?: ApolloError;
  usersLoadOptions: LoadOptions;
}

function SiteAdd(props: Props) {
  const {
    closeUserFormModal,
    countriesCodes,
    createMutationError,
    createSiteLoading,
    formRef,
    isUserFormModalOpened,
    onCreate,
    onCreateUserCallback,
    openUserFormModal,
    siteError,
    usersLoadOptions,
  } = props;
  const { t } = useTranslation();

  const fields = getFormFields({
    countriesCodes,
    usersLoadOptions,
    openUserFormModal,
    t,
  });

  const defaultValues = getDefaultValuesFromQueryData({
    areas: [],
    conductCodeSections: [],
  });

  if (siteError) {
    return (
      <ErrorPage
        status={getStatusFromError(siteError)}
        description={siteError?.message}
      />
    );
  }

  return (
    <>
      <div id={pageStepsId} />
      <DashboardContentMaxWidth>
        <PageBody withSteps>
          <WhiteBox verticalPaddings="large">
            <Form
              actionsComponent={SiteAddFormActions}
              defaultValues={defaultValues}
              fields={fields}
              formId="site"
              formRef={formRef}
              graphQLErrors={createMutationError?.graphQLErrors}
              loading={createSiteLoading}
              onSubmit={onCreate}
              steps={[
                { title: t('site-step-1') },
                { title: t('site-step-2'), optional: true },
                { title: t('site-step-3'), optional: true },
              ]}
              stepsCount={Steps.step3}
              sourceAppendValues={{
                areas: {
                  name: '',
                  code: '',
                  address: '',
                  description: '',
                },
                conductCodeSections: { title: '', body: '' },
              }}
            />
          </WhiteBox>
        </PageBody>
      </DashboardContentMaxWidth>
      <UserModalForm
        isOpen={isUserFormModalOpened}
        onClose={closeUserFormModal}
        onSubmitUserFormCallback={onCreateUserCallback}
        title={t('add-team-member')}
        isSiteFieldRequired={false}
      />
    </>
  );
}

export default SiteAdd;
