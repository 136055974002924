export const dateFormat = 'dd/MM/yyyy';
export const dateFormat2 = 'dd MMMM yyyy';
export const dateTimeFormatEU = 'dd/MM/yyyy HH:mm';
export const dateFormatForApi = 'yyyy-MM-dd';
export const dateFormatForApiWithoutDays = 'yyyy-MM';
export const dateTimePickerDateFormatForApi = "yyyy-MM-dd'T'HH:mm:ss";
export const timeFormatForApi = 'HH:mm:ss';
export const dayDateFormat = 'dd MMMM';
export const dayShortDateFormat = 'dd MMM';
export const shortDateFormat = 'MMM yyyy';
// ANT design date and time pickers formats
export const datePickerYearFormat = 'YYYY';
export const datePickerShortDateFormat = 'MMM YYYY';
export const datePickerDateFormat = 'DD/MM/YYYY';
export const timePickerDateFullFormat = 'HH:mm:ss';
export const timePickerDateFormat = 'HH:mm';
export const dateTimePickerDateFormat = 'DD/MM/YYYY HH:mm';
