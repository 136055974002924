import React from 'react';
import { useTranslation } from 'react-i18next';
import { Values } from '@lib/interfaces/form';
import { GraphQLErrors } from '@apollo/client/errors';
import Form from '@lib/components/ReactHookForm/FormContainer';
import replaceNullsWithUndefined from '@lib/utils/replaceNullsWithUndefined';
import getCategoryFormFields from './utils/getCategoryFormFields';
import CategoryFormActions from './CategoryFormActions';

interface Props {
  defaultValues?: Values;
  graphQLErrors?: GraphQLErrors;
  loading: boolean;
  onCancel: () => void;
  onSubmit: (values: Values) => void;
}

const emptyDefaultValues = {
  id: null,
  name: null,
  companies: null,
  color: null,
};

function CategoryForm(props: Props) {
  const {
    defaultValues = emptyDefaultValues,
    graphQLErrors,
    loading,
    onCancel,
    onSubmit,
  } = props;
  const { t } = useTranslation();
  const fields = getCategoryFormFields({
    t,
  });

  return (
    <Form
      formId="form-category"
      defaultValues={replaceNullsWithUndefined(defaultValues)}
      onSubmit={onSubmit}
      fields={fields}
      actionsComponent={CategoryFormActions}
      actionsComponentProps={{
        isEditForm: !!defaultValues?.id,
        loading,
        onCancel,
      }}
      graphQLErrors={graphQLErrors}
      loading={loading}
    />
  );
}

export default CategoryForm;
