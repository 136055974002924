import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  FormTemplateQuestionFieldOptionsValidityHintEnum,
  Task,
  TaskScopeNameEnum,
  TaskTimelineGroupByEnum,
  Timeline,
  useTasksTimelineLazyQuery,
} from 'graphql-common';
import { isAfter, isBefore } from 'date-fns';
import { GetUrlParams } from '@lib/enums/urls';
import { APP_URLS } from 'constants/urls';
import { getFormattedDate } from '@lib/utils/dateTimeHelpers';
import { captureException } from 'utils/captureException';
import { getPeriodStartDate } from 'components/TasksTimeline/utils/getPeriodDate';
import { dateFormatForApi } from '@lib/enums/dateTime';
import { ListQueryHookProps } from './types';

function getValidityHints(timelineTab: TaskScopeNameEnum) {
  if (timelineTab === TaskScopeNameEnum.TimelineFailure) {
    return [FormTemplateQuestionFieldOptionsValidityHintEnum.Failure];
  }
  if (timelineTab === TaskScopeNameEnum.TimelineWarning) {
    return [FormTemplateQuestionFieldOptionsValidityHintEnum.Warning];
  }
  if (timelineTab === TaskScopeNameEnum.TimelineSuccess) {
    return [FormTemplateQuestionFieldOptionsValidityHintEnum.Success];
  }
  return undefined;
}

export default function useDashboardTasksCompletionsQueryHook({
  filterParams = {},
  loading: defaultLoading,
  skip,
  tasks,
}: ListQueryHookProps & { tasks: Task[] }) {
  const { pathname } = useLocation();
  const [data, setData] = useState<Timeline[]>();
  const [dataGroupBy, setDataGroupBy] = useState<TaskTimelineGroupByEnum>();
  const [loading, setLoading] = useState(false);
  const [fetchTimeline] = useTasksTimelineLazyQuery();

  const timelineView = (filterParams[GetUrlParams.View] ||
    TaskTimelineGroupByEnum.Year) as TaskTimelineGroupByEnum;
  const timelineTab = filterParams[GetUrlParams.Tab] as TaskScopeNameEnum;
  const endDateParam = filterParams[GetUrlParams.EndDate] as string;
  const periodStartParam = filterParams[GetUrlParams.PeriodStart] as string;
  const periodEndParam = filterParams[GetUrlParams.PeriodEnd] as string;

  useEffect(() => {
    if (defaultLoading) {
      setData([]);
    }
  }, [defaultLoading]);

  useEffect(() => {
    if (
      !skip &&
      pathname === APP_URLS.dashboard.timeline.path &&
      tasks.length &&
      endDateParam
    ) {
      setData([]);
      setLoading(true);
      const ids = tasks.map(({ id }) => id);
      const validityHints = getValidityHints(timelineTab);

      const periodStart = getFormattedDate(
        periodStartParam,
        dateFormatForApi,
      ) as string;
      const periodEnd = getFormattedDate(
        periodEndParam,
        dateFormatForApi,
      ) as string;
      const timelineStart = getFormattedDate(
        getPeriodStartDate(endDateParam, timelineView),
        dateFormatForApi,
      ) as string;
      const timelineEnd = endDateParam;
      const dateRange = {
        startDate:
          periodStart && isAfter(new Date(periodStart), new Date(timelineStart))
            ? periodStart
            : timelineStart,
        endDate:
          periodEnd && isBefore(new Date(periodEnd), new Date(timelineEnd))
            ? periodEnd
            : timelineEnd,
      };
      fetchTimeline({
        fetchPolicy: 'no-cache',
        variables: {
          ids,
          groupBy: timelineView,
          dateRange,
          validityHints,
        },
      })
        .then((res) => {
          setDataGroupBy(res?.variables?.groupBy);
          setData(res?.data?.tasksTimeline);
        })
        .catch((e) => {
          captureException(e);
          setData([]);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [
    endDateParam,
    fetchTimeline,
    pathname,
    periodEndParam,
    periodStartParam,
    skip,
    tasks,
    timelineTab,
    timelineView,
  ]);

  return { loading, data, dataGroupBy };
}
