import React, { useRef } from 'react';
import { FormRef } from '@lib/components/ReactHookForm/types';
import { useParams } from 'react-router-dom';
import useModalManagement from 'routes/FormTemplate/utils/useModalManagement';
import useActions from 'routes/FormTemplate/utils/useActions';
import useOutletContext, { ContextProps } from 'hooks/useOutletContext';
import { ComponentEnum, LevelEnum } from 'graphql-common';
import { FormTemplateMode } from './enums';
import FormTemplateDetailsViewContainer from './FormTemplateDetailsViewContainer';
import FormTemplateTasksViewContainer from './FormTemplateTasksViewContainer';

interface Props {
  viewMode: FormTemplateMode;
}

export default function FormTemplateViewContainer(props: Props) {
  const { viewMode } = props;
  const formRef = useRef<FormRef>();
  const { id = '', versionId = '' } = useParams();

  const { getUserAccessByComponent }: ContextProps = useOutletContext();
  const isViewOnly =
    getUserAccessByComponent(ComponentEnum.TasksManagement) === LevelEnum.Read;

  const useModalManagementResult = useModalManagement();

  const useActionsResult = useActions({
    useModalManagementResult,
    formRef,
    id,
    versionId,
    viewMode,
  });

  if (viewMode === FormTemplateMode.View) {
    return (
      <FormTemplateDetailsViewContainer
        useActionsResult={useActionsResult}
        useModalManagementResult={useModalManagementResult}
        viewMode={viewMode}
        isViewOnly={isViewOnly}
      />
    );
  }

  if (viewMode === FormTemplateMode.ViewLinkedTasks) {
    return (
      <FormTemplateTasksViewContainer
        useActionsResult={useActionsResult}
        useModalManagementResult={useModalManagementResult}
        isViewOnly={isViewOnly}
      />
    );
  }

  return null;
}
