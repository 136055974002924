import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  FieldValues,
  UseFieldArrayAppend,
  useFormContext,
} from 'react-hook-form';
import _get from 'lodash/get';
import _replace from 'lodash/replace';
import classNames from 'classnames';
import Button, {
  ButtonSizes,
  ButtonTypes,
} from '@lib/components/Button/Button';
import FilterFormActions from 'components/DashboardInfoFilter/FilterFormActions';
import { AppendValue } from '@lib/components/ReactHookForm/types';
import styles from './DashboardInfoFilter.module.scss';

export type AddItemComponentProps = {
  append: UseFieldArrayAppend<FieldValues, string>;
  appendValue: AppendValue;
  buttonSize: ButtonSizes;
  isAddMoreVisible: boolean;
  onCancel?: () => void;
  parentName: string;
};

function SiteAndAreaOptionsAddItemComponent(props: AddItemComponentProps) {
  const {
    append,
    appendValue = {},
    buttonSize,
    isAddMoreVisible,
    onCancel,
    parentName,
  } = props;
  const { t } = useTranslation();
  const { getValues } = useFormContext();
  const values = getValues();
  const fieldValue = _get(values, _replace(parentName, '.options', ''));

  const options = _get(fieldValue, 'options', []);
  const className = classNames(styles.optionAddBtn, {
    [styles.optionAddBtnWithTopPadding]: options?.length,
  });
  if (!append) return null;
  return (
    <div className={className}>
      <Button
        buttonSize={ButtonSizes.small}
        buttonText={t('site-add-more')}
        buttonType={ButtonTypes.secondaryOutlined}
        disabled={!isAddMoreVisible}
        leftIcon="add"
        onClick={() => append(appendValue)}
      />
      <FilterFormActions
        onCancel={onCancel}
        buttonSize={buttonSize}
        className={styles.actions}
      />
    </div>
  );
}

export default SiteAndAreaOptionsAddItemComponent;
