import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Site } from 'graphql-common';
import DashboardContentMaxWidth from '@lib/layouts/DashboardLayout/DashboardContentMaxWidth/DashboardContentMaxWidth';
import PageBody from '@lib/layouts/DashboardLayout/PageBody/PageBody';
import SiteViewComponent from 'components/SiteView/SiteView';
import Button, { ButtonTypes } from '@lib/components/Button/Button';
import CircleLoader from '@lib/components/CircleLoader/CircleLoader';
import ConfirmationModal from '@lib/components/ConfirmationModal/ConfirmationModal';
import SiteMenuBar from 'components/SiteMenuBar/SiteMenuBar';
import { materialSymbolsOutlined } from '@lib/enums/common';
import { ApolloError } from '@apollo/client/errors';
import ErrorPage from '@lib/components/ErrorPage/ErrorPage';
import getStatusFromError from '@lib/utils/getStatusFromError';
import { IconName } from '@lib/components/Modal/enums';
import styles from './Site.module.scss';

interface Props {
  closeSiteDeleteModal: () => void;
  isSiteDeleteModalOpened: boolean;
  onConfirmedDelete: () => void;
  onDelete: () => void;
  onViewCodeOfConduct: () => void;
  onEditCodeOfConduct: () => void;
  siteData: Site;
  siteError?: ApolloError;
  siteLoading?: boolean;
  isViewOnly: boolean;
}

function SiteView(props: Props) {
  const {
    closeSiteDeleteModal,
    isSiteDeleteModalOpened,
    onConfirmedDelete,
    onDelete,
    onViewCodeOfConduct,
    onEditCodeOfConduct,
    siteData,
    siteError,
    siteLoading = false,
    isViewOnly,
  } = props;
  const { t } = useTranslation();
  if (siteError) {
    return (
      <ErrorPage
        status={getStatusFromError(siteError)}
        description={siteError?.message}
      />
    );
  }
  return (
    <>
      <PageBody withoutHeader>
        <SiteMenuBar id={siteData?.id} />
        {siteLoading ? (
          <CircleLoader />
        ) : (
          <DashboardContentMaxWidth>
            <SiteViewComponent
              siteData={siteData}
              onViewCodeOfConduct={onViewCodeOfConduct}
              onEditCodeOfConduct={onEditCodeOfConduct}
              isViewOnly={isViewOnly}
            />
            {!isViewOnly && (
              <div className={styles.actions}>
                <Button
                  buttonText={t('delete-site')}
                  buttonType={ButtonTypes.negativeOutlined}
                  leftIcon="delete"
                  leftIconClassName={materialSymbolsOutlined}
                  onClick={onDelete}
                />
              </div>
            )}
          </DashboardContentMaxWidth>
        )}
      </PageBody>
      <ConfirmationModal
        confirmButtonLabel={t('yes-delete')}
        description={
          <Trans i18nKey="delete-site-text" components={{ br: <br /> }} />
        }
        icon={IconName.Delete}
        isOpen={isSiteDeleteModalOpened}
        onClose={closeSiteDeleteModal}
        onConfirm={onConfirmedDelete}
        title={t('are-you-sure')}
      />
    </>
  );
}

export default SiteView;
