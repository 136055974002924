import { APP_URLS } from 'constants/urls';
import { TFunction } from 'i18next';

type Args = {
  id?: string;
  isInterventionsLocked?: boolean;
  t: TFunction<'translation', undefined>;
};

export default function getTabs({ t, id, isInterventionsLocked }: Args) {
  const tabs = [
    {
      label: t('task-details'),
      value: APP_URLS.dashboard.tasks.view.getDynamicPath({
        pathParts: {
          id: `${id}`,
        },
      }),
    },
    {
      label: t('form-details'),
      value: APP_URLS.dashboard.tasks.formView.getDynamicPath({
        pathParts: {
          id: `${id}`,
        },
      }),
    },
    {
      label: t('reporting'),
      value: APP_URLS.dashboard.tasks.reporting.getDynamicPath({
        pathParts: {
          id: `${id}`,
        },
      }),
    },
  ];
  if (!isInterventionsLocked) {
    tabs.push({
      label: t('interventions'),
      value: APP_URLS.dashboard.tasks.interventions.getDynamicPath({
        pathParts: {
          id: `${id}`,
        },
      }),
    });
  }
  return tabs;
}
