import {
  DashboardOverviewTasksQueryVariables,
  FilterGroupingEnum,
  Task,
  TaskFilterInputObject,
  TaskScopeNameEnum,
  useDashboardTimelineTasksQuery,
} from 'graphql-common';
import { parseQueryParamsToSiteAndArea } from 'components/DashboardInfoFilter/utils';
import _get from 'lodash/get';
import { Option } from '@lib/interfaces/form';
import { GetUrlParams } from '@lib/enums/urls';
import createSearchFilters from '@lib/utils/createSearchFilters';
import getTaskCounterData from '@lib/utils/getTaskCounterData';
import { ListQueryHookProps } from './types';

export default function useDashboardTimelineTasksQueryHook({
  filterParams,
  paginationParams,
  searchQuery,
  skip,
  tabParam,
}: ListQueryHookProps) {
  const page = _get(paginationParams, GetUrlParams.Page);
  const perPage = _get(paginationParams, GetUrlParams.PerPage);
  const orderingField = _get(paginationParams, GetUrlParams.OrderingField);
  const orderingDirection = _get(
    paginationParams,
    GetUrlParams.OrderingDirection,
  );
  const periodStart = _get(filterParams, GetUrlParams.PeriodStart) as string;
  const periodEnd = _get(filterParams, GetUrlParams.PeriodEnd) as string;
  const siteAndArea = _get(filterParams, GetUrlParams.SiteAndArea) as string;
  const tasksQueryVariables: DashboardOverviewTasksQueryVariables = {
    page: Number(page),
    limit: Number(perPage),
    scope: { name: tabParam as TaskScopeNameEnum },
  };
  const filters: TaskFilterInputObject[] = [];
  if (searchQuery) {
    filters.push(
      ...createSearchFilters(searchQuery, [
        { fieldName: 'name' },
        { fieldName: 'formCategoryName' },
        { fieldName: 'siteName' },
        { fieldName: 'siteAreaName' },
        { fieldName: 'siteCode' },
        { fieldName: 'siteAreaCode' },
        { fieldName: 'priority', predicateType: 'eq' },
      ]),
    );
  }
  if (periodStart && periodEnd) {
    filters.push({
      completedAt: {
        grouping: FilterGroupingEnum.And,
        predicate: { gteq: periodStart },
        filterGroup: 'completedAt',
      },
    });
    filters.push({
      completedAt: {
        grouping: FilterGroupingEnum.And,
        predicate: { lteq: periodEnd },
        filterGroup: 'completedAt',
      },
    });
  }
  if (siteAndArea) {
    const filterData = parseQueryParamsToSiteAndArea(siteAndArea);
    const siteIdsForFilter: string[] = [];
    const siteAreaIdsForFilter: string[] = [];
    filterData.forEach((item) => {
      const site = _get(item, 'site', {}) as Option;
      const siteAreaIds = _get(item, 'siteAreaIds', []) as Option[];
      if (site && siteAreaIds.length === 0) {
        siteIdsForFilter.push(site.value as string);
      } else if (siteAreaIds.length > 0) {
        siteAreaIds.forEach(({ value }) =>
          siteAreaIdsForFilter.push(value as string),
        );
      }
    });
    if (siteIdsForFilter.length) {
      filters.push({
        siteId: {
          grouping: FilterGroupingEnum.Or,
          predicate: { in: siteIdsForFilter },
          filterGroup: 'siteAndArea',
        },
      });
    }
    if (siteAreaIdsForFilter.length) {
      filters.push({
        siteAreaId: {
          grouping: FilterGroupingEnum.Or,
          predicate: { in: siteAreaIdsForFilter },
          filterGroup: 'siteAndArea',
        },
      });
    }
  }
  if (filters.length) {
    tasksQueryVariables.filters = filters;
  }
  if (orderingField && orderingDirection) {
    tasksQueryVariables.sorts = {
      [orderingField]: orderingDirection,
    };
  }
  const tasksQuery = useDashboardTimelineTasksQuery({
    fetchPolicy: 'no-cache',
    variables: tasksQueryVariables,
    skip,
  });
  const {
    loading: tasksLoading,
    data,
    previousData,
    refetch: refetchTasks,
    error: tasksError,
  } = tasksQuery;
  const queryData = data || previousData;
  const { tasks, ...taskCounterData } = queryData || {};
  const tasksFirstLoading = tasksLoading && previousData === undefined;
  const metadata = _get(queryData, 'tasks.metadata');
  const totalTasksCount = _get(metadata, 'totalCount', 0);
  const totalPages = _get(metadata, 'totalPages', 1);
  return {
    refetchTasks,
    taskCounterData: getTaskCounterData(taskCounterData),
    tasks: _get(tasks, 'collection', []) as Task[],
    tasksError,
    tasksFirstLoading,
    tasksLoading,
    totalPages,
    totalTasksCount,
  };
}
