import React, { forwardRef, memo } from 'react';
import { useTranslation } from 'react-i18next';
import passwordGenerator from 'generate-password-browser';
import Input, { InputProps } from '@lib/components/Input/Input';
import { InputTypes } from '@lib/components/Input/enums';
import Button, {
  ButtonSizes,
  ButtonTypes,
} from '@lib/components/Button/Button';
import styles from './InputPassword.module.scss';

interface Props extends InputProps {
  showGenerateBtn: boolean;
}

const InputPassword = forwardRef(
  (props: Props, ref: React.ForwardedRef<HTMLInputElement>) => {
    const { onChange, showGenerateBtn } = props;
    const { t } = useTranslation();
    const onGenerate = () => {
      const result = passwordGenerator.generate({
        length: 8,
        numbers: true,
        lowercase: true,
        symbols: true,
        uppercase: true,
        strict: true,
      });
      if (onChange)
        onChange({
          target: { value: result },
        } as React.ChangeEvent<HTMLInputElement>);
    };
    // @ts-ignore TODO: fix this
    const input = <Input {...props} ref={ref} type={InputTypes.password} />;
    return (
      <div className={styles.inputPasswordRoot}>
        {input}
        {showGenerateBtn && (
          <Button
            buttonText={t('generate')}
            buttonType={ButtonTypes.primaryOutlined}
            buttonSize={ButtonSizes.small}
            onClick={onGenerate}
          />
        )}
      </div>
    );
  },
);

export default memo(InputPassword);
