export function getTimeFromMinutesCount(
  minutesCount: number | null,
): string | null {
  if (minutesCount == null || minutesCount < 0) {
    return null;
  }

  const hours = Math.floor(minutesCount / 60);
  const minutes = minutesCount % 60;

  const formattedHours = hours.toString();
  const formattedMinutes = minutes.toString().padStart(2, '0');

  return `${formattedHours}:${formattedMinutes}`;
}
