import _get from 'lodash/get';
import FormTemplateFieldNames from '@lib/enums/fieldNames/formTemplateFieldNames';
import { ChipTypes } from '@lib/components/Chip/Chip';
import {
  FormTemplateQuestion,
  FormTemplateVersionStatusEnum,
} from 'graphql-common';
import { Values } from '@lib/interfaces/form';
import { TFunction } from 'i18next';

type Args = {
  formTemplateData: Values;
  formTemplateVersionData: Values;
  t: TFunction<'translation', undefined>;
};

export default function getTemplateData({
  formTemplateData,
  formTemplateVersionData,
}: Args) {
  const name = _get(formTemplateData, FormTemplateFieldNames.Name) as string;
  const currentVersion = _get(formTemplateData, 'currentVersion') as string;
  const description = _get(
    formTemplateData,
    FormTemplateFieldNames.Description,
  ) as string;
  const categoryName = _get(
    formTemplateData,
    `${FormTemplateFieldNames.Category}.label`,
  ) as string;
  const categoryColor = _get(
    formTemplateData,
    `${FormTemplateFieldNames.Category}.color`,
  ) as ChipTypes;
  const questions = _get(
    formTemplateData,
    FormTemplateFieldNames.Questions,
  ) as FormTemplateQuestion[];
  const visibleLinkedTasksCount = _get(
    formTemplateVersionData,
    'visibleLinkedTasksCount',
  ) as number;
  const createdAt = _get(formTemplateVersionData, 'createdAt') as string;
  const archivedAt = _get(formTemplateVersionData, 'archivedAt') as string;
  const status = _get(
    formTemplateVersionData,
    'status',
  ) as FormTemplateVersionStatusEnum;
  const selectedVersionOption = {
    value: _get(currentVersion, 'id'),
    label: `v${_get(currentVersion, 'version')}`,
  };
  return {
    archivedAt,
    categoryColor,
    categoryName,
    createdAt,
    description,
    name,
    questions,
    selectedVersionOption,
    status,
    visibleLinkedTasksCount,
    canArchiveVersion: !!formTemplateVersionData.canArchive,
    canDestroyVersion: !!formTemplateVersionData.canDestroy,
    canUpdateVersion: !!formTemplateVersionData.canUpdate,
  };
}
