import {
  GetUrlParams,
  PAGINATION_PARAMS,
  PER_PAGE_KEY_SITES,
} from '@lib/enums/urls';

export const getPageCount = (totalEntries?: number, perPage?: number) => {
  if (!totalEntries || !perPage) return 0;
  return Math.ceil(totalEntries / perPage) || 0;
};

export const getPaginationParamsByPathname = (pathname: string) => {
  if (pathname.includes('sites')) {
    return {
      ...PAGINATION_PARAMS,
      [GetUrlParams.PerPage]: PER_PAGE_KEY_SITES,
    };
  }
  return PAGINATION_PARAMS;
};
