import React from 'react';
import Button, {
  ButtonProps,
  ButtonTypes,
} from '@lib/components/Button/Button';
import { CheckboxProps } from '@lib/components/CheckBox/CheckBox';
import { materialSymbolsOutlined } from '@lib/enums/common';

interface Props {
  onChange?: () => void;
}

function CheckBoxAsButton(props: Props & CheckboxProps & ButtonProps) {
  const {
    label = '',
    checked,
    onChange,
    disabled,
    leftIcon,
    fullWidth,
    buttonSize,
  } = props;
  return (
    <Button
      buttonSize={buttonSize}
      buttonText={label}
      buttonType={
        checked ? ButtonTypes.primaryFilled : ButtonTypes.secondaryOutlined
      }
      disabled={disabled}
      fullWidth={fullWidth}
      leftIcon={leftIcon}
      leftIconClassName={materialSymbolsOutlined}
      onClick={() => onChange()}
    />
  );
}

export default CheckBoxAsButton;
