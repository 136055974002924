import _get from 'lodash/get';
import { Task } from 'graphql-common';
import { Values } from '@lib/interfaces/form';
import { FieldNames } from 'routes/Task/enums';

export default function getEarlyCompletionValuesFromQueryData(
  taskData: Task,
): Values {
  const earlyCompletion = _get(taskData, 'earlyCompletion');
  const earlyCompletionUnit = _get(taskData, 'earlyCompletionUnit');
  if (!earlyCompletion && !earlyCompletionUnit) return {};
  return {
    [FieldNames.earlyCompletionEnabled]: true,
    [FieldNames.earlyCompletionType]: { value: earlyCompletionUnit },
    [FieldNames.earlyCompletionAmount]: earlyCompletion,
  };
}
