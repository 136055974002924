import { Team, useTeamQuery } from 'graphql-common';
import { WatchQueryFetchPolicy } from '@apollo/client';
import { SingleQueryHookResult } from 'utils/fetch/types';

type Args = {
  id?: string;
  skip?: boolean;
  fetchPolicy?: WatchQueryFetchPolicy;
};

export default function useTeamQueryHook(
  args: Args,
): SingleQueryHookResult<Team> {
  const { id = '', skip = true, fetchPolicy = 'network-only' } = args;
  const query = useTeamQuery({
    skip,
    variables: { id },
    fetchPolicy,
  });
  const { refetch, error, loading, data: queryData, previousData } = query;
  const data = queryData || previousData;
  const firstLoading = loading && queryData === undefined;

  return {
    data: data?.data as Team,
    loading,
    error,
    refetch,
    firstLoading,
  };
}
