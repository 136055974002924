import { Access, ComponentEnum, LevelEnum } from 'graphql-common';
import { checkUserAccessByComponent } from '@lib/utils/accesses';
import { APP_URLS } from 'constants/urls';

function isAccessClosed(accesses: Access[], component: ComponentEnum) {
  return (
    checkUserAccessByComponent(accesses, component) !== LevelEnum.LimitedRead
  );
}

export default function getHomePageUrl(accesses: Access[]) {
  if (isAccessClosed(accesses, ComponentEnum.TasksManagement)) {
    return APP_URLS.dashboard.overview.getPathWithQuery();
  }
  if (isAccessClosed(accesses, ComponentEnum.FormCategoriesManagement)) {
    return APP_URLS.dashboard.formTemplates.categories.getPathWithQuery();
  }
  if (isAccessClosed(accesses, ComponentEnum.InterventionsManagement)) {
    return APP_URLS.dashboard.interventions.index.getPathWithQuery();
  }
  if (
    isAccessClosed(accesses, ComponentEnum.InterventionCategoriesManagement)
  ) {
    return APP_URLS.dashboard.interventions.categories.getPathWithQuery();
  }
  if (isAccessClosed(accesses, ComponentEnum.SitesManagement)) {
    return APP_URLS.dashboard.sites.index.getPathWithQuery();
  }
  if (isAccessClosed(accesses, ComponentEnum.SitesManagement)) {
    return APP_URLS.dashboard.sites.index.getPathWithQuery();
  }
  if (isAccessClosed(accesses, ComponentEnum.UsersManagement)) {
    return APP_URLS.dashboard.users.index.getPathWithQuery();
  }
  if (isAccessClosed(accesses, ComponentEnum.AssetsManagement)) {
    return APP_URLS.dashboard.assets.index.getPathWithQuery();
  }
  if (isAccessClosed(accesses, ComponentEnum.AssetsCounterReading)) {
    return APP_URLS.dashboard.assets.counters.getPathWithQuery();
  }
  if (isAccessClosed(accesses, ComponentEnum.AssetCategoriesManagement)) {
    return APP_URLS.dashboard.assets.categories.getPathWithQuery();
  }
  return APP_URLS.dashboard.profile.path;
}
