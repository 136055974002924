import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  ComponentEnum,
  FormTemplate,
  FormTemplateScopeNameEnum,
  LevelEnum,
} from 'graphql-common';
import FormTemplates from 'components/FormTemplates/FormTemplates';
import BreadCrumbs from '@lib/components/BreadCrumbs/BreadCrumbs';
import { getBreadCrumbsDataByKey } from 'utils/breadcrumbs';
import { useTableItemsBulkSelect } from '@lib/hooks/useTableItemsBulkSelect';
import useTableHelpers from 'routes/FormTemplates/utils/useTableHelpers';
import { APP_URLS } from 'constants/urls';
import useOutletContext, { ContextProps } from 'hooks/useOutletContext';
import useActions from './utils/useActions';
import useModalManagement from './utils/useModalManagement';

function FormTemplatesContainer() {
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const { getUserAccessByComponent }: ContextProps = useOutletContext();
  const isViewOnly =
    getUserAccessByComponent(ComponentEnum.TasksManagement) === LevelEnum.Read;

  const formTemplateScope = (() => {
    if (pathname === APP_URLS.dashboard.formTemplates.archived.path) {
      return FormTemplateScopeNameEnum.Archived;
    }
    return FormTemplateScopeNameEnum.Active;
  })();

  const useBulkSelectResult = useTableItemsBulkSelect<FormTemplate>();
  const useModalManagementResult = useModalManagement({ useBulkSelectResult });
  const {
    closeArchiveModal,
    closeDestroyModal,
    isArchiveModalOpened,
    isDestroyModalOpened,
  } = useModalManagementResult;

  const useActionsResult = useActions({
    tabParam: formTemplateScope,
    useModalManagementResult,
    useBulkSelectResult,
  });
  const {
    formTemplates,
    formTemplatesFirstLoading,
    formTemplatesLoading,
    onConfirmedArchive,
    onConfirmedDestroy,
    totalFormTemplatesCount,
  } = useActionsResult;

  const { tableColumns, tableActionsMenu, tableTabs, tableActionsForSelected } =
    useTableHelpers({
      formTemplateScope,
      useActionsResult,
      useModalManagementResult,
      isViewOnly,
    });

  return (
    <>
      <BreadCrumbs data={getBreadCrumbsDataByKey({ pathname, t })} />
      <FormTemplates
        closeArchiveModal={closeArchiveModal}
        closeDestroyModal={closeDestroyModal}
        formTemplateScope={formTemplateScope}
        formTemplates={formTemplates}
        formTemplatesFirstLoading={formTemplatesFirstLoading}
        formTemplatesLoading={formTemplatesLoading}
        isArchiveModalOpened={isArchiveModalOpened}
        isDestroyModalOpened={isDestroyModalOpened}
        onConfirmedArchive={onConfirmedArchive}
        onConfirmedDestroy={onConfirmedDestroy}
        tableActionsForSelected={tableActionsForSelected}
        tableActionsMenu={tableActionsMenu}
        tableColumns={tableColumns}
        tableTabs={tableTabs}
        totalFormTemplatesCount={totalFormTemplatesCount}
        useBulkSelectResult={useBulkSelectResult}
        isViewOnly={isViewOnly}
      />
    </>
  );
}

export default FormTemplatesContainer;
