enum AssetFieldNames {
  Category = 'category',
  CategoryDefault = 'category-default',
  Code = 'code',
  QrCodePrefix = 'qrCodePrefix',
  QrCodePrefixDefault = 'qrCodePrefix-default',
  Description = 'description',
  FullCode = 'fullCode',
  IconAttached = 'iconAttached',
  Id = 'id',
  LocationPhotoAttached = 'locationPhotoAttached',
  Manufacturer = 'manufacturer',
  Model = 'model',
  Name = 'name',
  PoorWifi = 'poorWifi',
  SerialNumber = 'serialNumber',
  ServiceDate = 'serviceDate',
  Site = 'site',
  SiteArea = 'siteArea',
}

export default AssetFieldNames;
