import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormTemplateVersionsSearchListLazyQuery } from 'graphql-common';
import Select, { SelectProps } from '@lib/components/Select/Select';
import VersionOption from 'components/VersionOption/VersionOption';
import { InputSizes } from '@lib/components/Input/enums';
import { GetLoadOptions, SelectOption } from '@lib/components/Select/types';
import { NEW } from '@lib/enums/common';
import { getFormTemplateVersionsLoadOptions } from 'utils/fetch/formTemplateVersionsSearchList';
import { emptyHiddenInputId } from '@lib/enums/selectors';

type Props = {
  components?: SelectProps['components'];
  disabled?: boolean;
  formTemplateId: string;
  fullWidthMenu?: boolean;
  hideArchived?: boolean;
  onChange: SelectProps['onChange'];
  size?: InputSizes;
  value?: SelectOption;
  valueContainerLabel?: string;
  label?: string;
  useAddNewOption?: boolean;
};

export default function FormTemplateVersionSelect(props: Props) {
  const {
    components = {},
    disabled,
    formTemplateId,
    fullWidthMenu,
    hideArchived,
    onChange,
    size,
    value,
    valueContainerLabel,
    useAddNewOption,
    label,
  } = props;
  const { t } = useTranslation();

  const [formTemplateVersionsLazyQuery] =
    useFormTemplateVersionsSearchListLazyQuery({
      fetchPolicy: 'cache-and-network',
    });

  const getTemplateVersionsLoadOptions: GetLoadOptions = () =>
    getFormTemplateVersionsLoadOptions(
      formTemplateVersionsLazyQuery,
      formTemplateId,
      hideArchived,
    );

  const selectComponents = {
    Option: VersionOption,
    ...components,
  };

  const createNewOptionProps =
    useAddNewOption && onChange
      ? {
          title: t('new'),
          onCLick: () => {
            onChange(
              { value: NEW, label: t('new') },
              {
                action: 'select-option',
                option: undefined,
                name: 'version',
              },
            );
            const emptyHiddenInput =
              document.getElementById(emptyHiddenInputId);
            if (emptyHiddenInput) {
              emptyHiddenInput.focus();
            }
          },
        }
      : undefined;

  return (
    <Select
      buttonText={valueContainerLabel}
      components={selectComponents}
      createNewOptionProps={createNewOptionProps}
      disabled={disabled}
      fullWidthMenu={fullWidthMenu}
      getLoadOptions={getTemplateVersionsLoadOptions}
      isAsync
      isClearable={false}
      isSearchable={false}
      label={label}
      name="version"
      onChange={onChange}
      size={size}
      usePortal
      value={value}
    />
  );
}
