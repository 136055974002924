import {
  useAssetCategoriesSearchListLazyQuery,
  useInterventionCategoriesSearchListLazyQuery,
  useUsersSearchListLazyQuery,
} from 'graphql-common';
import { getUsersLoadOptions as getUsersLoadOptionsFn } from 'utils/fetch/usersSearchList';
import { getInterventionCategoriesLoadOptions as getInterventionCategoriesLoadOptionsFn } from 'utils/fetch/interventionCategoriesSearchList';
import { getAssetCategoriesLoadOptions as getAssetCategoriesLoadOptionsFn } from 'utils/fetch/assetCategoriesSearchList';

export default function useSearchListQueries() {
  // Users
  const [fetchUsersSearchListLazyQuery] = useUsersSearchListLazyQuery({
    fetchPolicy: 'cache-and-network',
  });
  const getUsersLoadOptions = () => {
    return getUsersLoadOptionsFn(fetchUsersSearchListLazyQuery);
  };

  // Intervention categories
  const [fetchInterventionCategoriesLazyQuery] =
    useInterventionCategoriesSearchListLazyQuery({
      fetchPolicy: 'cache-and-network',
    });
  const getInterventionCategoriesLoadOptions = () =>
    getInterventionCategoriesLoadOptionsFn(
      fetchInterventionCategoriesLazyQuery,
    );

  // Asset categories
  const [fetchAssetCategoriesLazyQuery] = useAssetCategoriesSearchListLazyQuery(
    {
      fetchPolicy: 'cache-and-network',
    },
  );
  const getAssetCategoriesLoadOptions = () =>
    getAssetCategoriesLoadOptionsFn(fetchAssetCategoriesLazyQuery);

  return {
    getUsersLoadOptions,
    getInterventionCategoriesLoadOptions,
    getAssetCategoriesLoadOptions,
    fetchUsersSearchListLazyQuery,
    fetchInterventionCategoriesLazyQuery,
    fetchAssetCategoriesLazyQuery,
  };
}
