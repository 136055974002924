import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import TaskAdd from 'components/Task/TaskAdd';
import { FormRef } from '@lib/components/ReactHookForm/types';
import BreadCrumbs from '@lib/components/BreadCrumbs/BreadCrumbs';
import { getBreadCrumbsDataByKey } from 'utils/breadcrumbs';
import useFields from 'routes/Task/utils/useFields/useFields';
import useActions from './utils/useActions';
import useSearchListQueries from './utils/useSearchListQueries';

interface Props {
  isDuplicate?: boolean;
}

export default function TaskCreateContainer({ isDuplicate }: Props) {
  const { t } = useTranslation();
  const formRef = useRef<FormRef>();
  const { pathname } = useLocation();

  const {
    createMutationError,
    createTaskLoading,
    fullTaskData,
    fullTaskError,
    fullTaskLoading,
    onCreate,
    onCreateNewFormTemplate,
    onViewFormTemplate,
  } = useActions({
    formRef,
  });

  const {
    getApproversLoadOptions,
    getAssetsLoadOptions,
    getSiteAreasLoadOptions,
    getSitesLoadOptions,
    getTemplateVersionsLoadOptions,
    getTemplatesLoadOptions,
  } = useSearchListQueries();

  const formFields = useFields({
    getApproversLoadOptions,
    getAssetsLoadOptions,
    getSiteAreasLoadOptions,
    getSitesLoadOptions,
    getTemplateVersionsLoadOptions,
    getTemplatesLoadOptions,
    isCreateForm: true,
    onCreateNewFormTemplate,
    onViewFormTemplate,
    t,
  });

  return (
    <>
      <BreadCrumbs
        data={getBreadCrumbsDataByKey({ pathname, t, entity: fullTaskData })}
      />
      <TaskAdd
        createMutationError={createMutationError}
        createTaskLoading={createTaskLoading}
        formFields={formFields}
        formRef={formRef}
        fullTaskData={fullTaskData}
        fullTaskError={fullTaskError}
        fullTaskLoading={fullTaskLoading}
        isDuplicate={isDuplicate}
        onSubmit={onCreate}
      />
    </>
  );
}
