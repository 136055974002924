import React, { forwardRef, memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { DatePicker, DatePickerProps } from 'antd';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import { PickerRef } from 'rc-picker/lib/interface';
import dayjs, { Dayjs } from 'dayjs';
import { datePickerDateFormat } from '@lib/enums/dateTime';
import { revertTransformDate, transformDate } from '@lib/utils/dateTimeHelpers';
import MaterialIcon from '@lib/components/MaterialIcon/MaterialIcon';
import withFloatingLabel, {
  FloatingLabelProps,
} from '@lib/hocs/withFloatingLabel';
import Button, {
  ButtonSizes,
  ButtonTypes,
} from '@lib/components/Button/Button';
import isMobile from '@lib/utils/isMobile';
import styles from './DateTimePicker.module.scss';

type Props = {
  allowClear?: boolean;
  disabled?: boolean;
  hasError?: boolean;
  minDate?: DatePickerProps['minDate'];
  name?: string;
  onChange: (v: string[]) => void;
  size?: SizeType;
  value?: string[];
};

const DateRangePickerCommon = forwardRef(
  (props: Props & FloatingLabelProps, ref: React.ForwardedRef<PickerRef>) => {
    const {
      allowClear = true,
      disabled,
      hasError,
      minDate,
      name,
      onChange,
      setIsFocused,
      size = 'large',
      value,
    } = props;
    const { t } = useTranslation();
    const [isOpen, setOpen] = useState(false);
    const { phone, tablet } = isMobile();
    const isMobileDevice = phone || tablet;

    let selected: [Dayjs | null, Dayjs | null] | null = null;
    const isValueExists = !!(Array.isArray(value) && value?.[0] && value?.[1]);
    if (isValueExists) {
      selected = [
        dayjs(revertTransformDate(value[0])),
        dayjs(revertTransformDate(value[1])),
      ];
    }

    const onBlurHandler = (e) => {
      const className =
        e?.relatedTarget?.className || e?.target?.className || '';
      if (className.indexOf('ant-picker') === -1) {
        setIsFocused(false);
      }
    };

    const onFocusHandler = () => {
      setOpen(true);
      setIsFocused(true);
    };

    const onChangeHandler = useCallback(
      (v: [Dayjs | null, Dayjs | null] | null) => {
        const newValue = ['', ''];
        if (v && Array.isArray(v) && v.length) {
          v.forEach((date, index) => {
            if (date) {
              newValue[index] = transformDate(date.toDate(), index > 0);
            }
          });
        } else if (isMobileDevice) {
          setIsFocused(false);
        }
        onChange(newValue);
        setTimeout(() => {
          setOpen(false);
        }, 0);
      },
      [isMobileDevice, onChange, setIsFocused],
    );

    const renderExtraFooter = useCallback(() => {
      const today = dayjs().endOf('day');
      const dateRanges: { label: string; dates: [Dayjs, Dayjs] }[] = [
        { label: t('today'), dates: [today.startOf('day'), today] },
        {
          label: t('week'),
          dates: [today.subtract(6, 'days').startOf('day'), today], // Exactly 7 days (including today)
        },
        {
          label: t('month'),
          dates: [
            today.subtract(1, 'month').add(1, 'day').startOf('day'),
            today,
          ], // Month range excluding the first day of the previous month
        },
        {
          label: t('quarter'),
          dates: [
            today.subtract(3, 'month').add(1, 'day').startOf('day'),
            today,
          ], // 3-month range excluding the first day of the previous 3 months
        },
        {
          label: t('year'),
          dates: [
            today.subtract(1, 'year').add(1, 'day').startOf('day'),
            today,
          ], // Year range excluding the first day of the previous year
        },
      ];

      return (
        <div className={styles.dateRangePickerFooter}>
          {dateRanges.map(({ label, dates }) => {
            const isSelected =
              selected &&
              selected[0]?.isSame(dates[0], 'day') &&
              selected[1]?.isSame(dates[1], 'day');

            return (
              <Button
                key={label}
                buttonText={label}
                buttonSize={ButtonSizes.small}
                buttonType={
                  isSelected
                    ? ButtonTypes.primaryFilled
                    : ButtonTypes.primaryOutlined
                }
                onClick={() => {
                  onChangeHandler(dates);
                }}
                useRipple={false}
              />
            );
          })}
        </div>
      );
    }, [onChangeHandler, selected, t]);

    const className = classNames(styles.picker, {
      [styles.datePickerFilled]: isValueExists,
    });

    return (
      <div>
        <DatePicker.RangePicker
          allowClear={
            allowClear ? { clearIcon: <MaterialIcon icon="cancel" /> } : false
          }
          allowEmpty
          className={className}
          disabled={disabled}
          format={datePickerDateFormat}
          minDate={minDate}
          name={name}
          needConfirm={false}
          onBlur={onBlurHandler}
          onChange={onChangeHandler}
          onFocus={onFocusHandler}
          placeholder={['', '']}
          popupClassName={styles.dateRangePickerPopup}
          ref={ref}
          size={size}
          status={hasError ? 'error' : undefined}
          value={selected}
          renderExtraFooter={renderExtraFooter}
          showNow={false}
          open={isOpen}
          inputReadOnly={isMobileDevice}
        />
        {isOpen && (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/control-has-associated-label,jsx-a11y/no-static-element-interactions
          <div className={styles.mask} onClick={() => setOpen(false)} />
        )}
      </div>
    );
  },
);

export default memo(withFloatingLabel(DateRangePickerCommon));
